import { FC, PropsWithChildren } from 'react';
import { Button, Card, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';

import ProductCard from 'components/cards/ProductCard';
import { RamifyTipCard } from 'components/cards/RamifyTipCard';
import { ActionCardProps } from 'pages/Fiscalite/OptimiseFiscalite/OptimiseFiscalite';
import { displayMoneyNoDigits } from 'utils/rendering';

const ImproveTaxationCard: FC<PropsWithChildren<Omit<ActionCardProps, 'progressData'>>> = ({
	title,
	potentialTaxEconomy,
	icon,
	cta,
	product,
	buttonText,
	onClickButton,
	ramifyTip,
	children,
}): JSX.Element => {
	return (
		<Card w="100%" h="100%">
			<VStack align="start" spacing="md" w="100%">
				<HStack spacing="16px">
					<Image boxSize="24px" src={icon} />
					<Heading variant="Title-L-Bold">{title}</Heading>
				</HStack>
				{children}
				{potentialTaxEconomy > -1 && (
					<ProductCard variant={product} size={{ base: 'sm', md: 'md' }} w="100%" onClick={onClickButton}>
						<VStack w="100%" align="start" spacing="sm">
							<Heading variant="Title-L-SemiBold">{cta}</Heading>
							<VStack align="start">
								<Text variant="Text-M-Medium" color="grey.900">
									Potentiel défiscalisable
								</Text>
								<Heading variant="Title-L-SemiBold">{displayMoneyNoDigits(potentialTaxEconomy)}</Heading>
							</VStack>
							<Button variant="primary">{buttonText}</Button>
						</VStack>
					</ProductCard>
				)}
				<RamifyTipCard size="none" border="none">
					<Text variant="Text-M-Regular">{ramifyTip}</Text>
				</RamifyTipCard>
			</VStack>
		</Card>
	);
};
export default ImproveTaxationCard;
