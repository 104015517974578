import { HStack, Image, Text, VStack } from '@chakra-ui/react';

import NoVersement from 'assets/shapes/noVersement.svg';

const EmptyVersementHistory = (): JSX.Element => (
	<HStack spacing="24px" align="center" w="100%" justify="center" py="16px">
		<Image src={NoVersement} alt="" filter="grayscale(100%)" />
		<VStack w="100%" align="start" spacing="12px">
			<Text variant="body">Vous n'avez pas encore réalisé de versement sur vos contrats.</Text>
			<Text variant="body">Tous vos prélèvements bancaires apparaîtront dans cette section.</Text>
		</VStack>
	</HStack>
);

export default EmptyVersementHistory;
