import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import FoyerFiscalFormContext from 'formContexts/FoyerFiscal';

import { ArrowButton } from 'components/Button/Button';
import useThemedToast from 'hooks/useThemedToast';
import { useGetKYCQuery, useUpdateKycFoyerFiscalMutation } from 'services/requests/kyc';
import { IncomePeriod } from 'store/referentiels/incomePeriod';
import { IncomeType } from 'store/referentiels/incomeType';
import { FoyerFiscalType } from 'store/types/KYC.type';

const ModalSituationFiscalite = ({ onClose, isOpen }: { onClose: () => void; isOpen: boolean }): JSX.Element => {
	const toast = useThemedToast();
	const { data: kycData, isSuccess: isKycSuccess } = useGetKYCQuery();
	const [updateFoyerFiscal] = useUpdateKycFoyerFiscalMutation();

	const methods = useForm<FoyerFiscalType>({
		mode: 'onChange',
		shouldUnregister: true,
		defaultValues: {
			childNumber: 0,
			revenuType: IncomeType.GROSS,
			revenuPeriod: IncomePeriod.ANNUALLY,
			revenuSpouseType: IncomeType.GROSS,
			revenuSpousePeriod: IncomePeriod.ANNUALLY,
		},
	});
	const { handleSubmit, setValue } = methods;

	useEffect(() => {
		if (isKycSuccess && kycData) {
			if (kycData.childNumber) setValue('childNumber', kycData.childNumber);
			if (kycData.professionalSituation) setValue('professionalSituation', kycData.professionalSituation);

			if (kycData.familySituation) setValue('familySituation', kycData.familySituation);
			if (kycData.matrimonialRegime) setValue('regimeMatrimonial', kycData.matrimonialRegime);

			if (kycData.revenuSpouse !== undefined) setValue('revenu', kycData.revenu);
			if (kycData.revenuType) setValue('revenuType', kycData.revenuType);
			if (kycData.revenuPeriod) setValue('revenuPeriod', kycData.revenuPeriod);

			if (kycData.revenuSpouse !== undefined) setValue('revenuSpouse', kycData.revenuSpouse);
			if (kycData.revenuSpouseType) setValue('revenuSpouseType', kycData.revenuSpouseType);
			if (kycData.revenuSpousePeriod) setValue('revenuSpousePeriod', kycData.revenuSpousePeriod);
		}
	}, [kycData, isKycSuccess, setValue]);

	const onSubmit = handleSubmit((data: FoyerFiscalType) => {
		updateFoyerFiscal(data)
			.unwrap()
			.then(() => {
				onClose();
				toast({
					title: 'Modification enregistrée',
					description: 'Vos informations sont mises à jour.',
					status: 'success',
					duration: 4500,
					isClosable: true,
				});
			})
			.catch(() => {
				toast({
					title: "Une erreur s'est produite",
					description: 'Nous ne pouvons pas mettre à jour vos préférences.',
					status: 'error',
					duration: 4500,
					isClosable: true,
				});
			});
	});

	return (
		<Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap blockScrollOnMount>
			<ModalOverlay />
			<ModalContent>
				<FormProvider {...methods}>
					<form onSubmit={onSubmit}>
						<ModalHeader mr="16px">Mettre à jour ma situation fiscale</ModalHeader>
						<ModalCloseButton />

						<ModalBody pb={6}>
							<FoyerFiscalFormContext />
						</ModalBody>

						<ModalFooter>
							<ArrowButton type="submit" variant="primary" w="100%">
								Mettre à jour ma situation
							</ArrowButton>
						</ModalFooter>
					</form>
				</FormProvider>
			</ModalContent>
		</Modal>
	);
};
export default ModalSituationFiscalite;
