import { TextProps } from '@chakra-ui/react';

import colors from './colors';

export const baseTextStyle = {
	color: colors.primary.black,
	lineHeight: 'base',
	fontFamily: 'Whyte',
};

export const baseHeadingStyle = {
	color: colors.primary.black,
	lineHeight: 'base',
	fontFamily: 'Poppins',
};

const textStyles = {
	// Headings
	'Title-XL-Bold': {
		...baseHeadingStyle,
		fontSize: '32px',
		fontWeight: 'bold',
	},
	'Title-XL-SemiBold': {
		...baseHeadingStyle,
		fontSize: '32px',
		fontWeight: '600',
	},
	'Title-L-Bold': {
		...baseHeadingStyle,
		fontSize: '24px',
		fontWeight: 'bold',
	},
	'Title-L-SemiBold': {
		...baseHeadingStyle,
		fontSize: '24px',
		fontWeight: '600',
	},
	'Title-M-Bold': {
		...baseHeadingStyle,
		fontSize: '20px',
		fontWeight: 'bold',
	},
	'Title-M-SemiBold': {
		...baseHeadingStyle,
		fontSize: '20px',
		fontWeight: '600',
	},
	'Title-S-Bold': {
		...baseHeadingStyle,
		fontSize: '16px',
		fontWeight: 'bold',
	},
	'Title-S-SemiBold': {
		...baseHeadingStyle,
		fontSize: '16px',
		fontWeight: '600',
	},

	// Texts
	'Text-M-Bold': {
		...baseTextStyle,
		fontSize: '16px',
		fontWeight: 'bold',
	},
	'Text-M-Medium': {
		...baseTextStyle,
		fontSize: '16px',
		fontWeight: 'medium',
	},
	'Text-M-Regular': {
		...baseTextStyle,
		fontSize: '16px',
		fontWeight: 'normal',
	},
	'Text-S-Bold': {
		...baseTextStyle,
		fontSize: '14px',
		fontWeight: 'bold',
	},
	'Text-S-Medium': {
		...baseTextStyle,
		fontSize: '14px',
		fontWeight: 'medium',
	},
	'Text-S-Regular': {
		...baseTextStyle,
		fontSize: '14px',
		fontWeight: 'normal',
	},
	'Text-XS-Bold': {
		...baseTextStyle,
		fontSize: '12px',
		fontWeight: 'bold',
	},
	'Text-XS-Medium': {
		...baseTextStyle,
		fontSize: '12px',
		fontWeight: 'medium',
	},
	'Text-XS-Regular': {
		...baseTextStyle,
		fontSize: '12px',
		fontWeight: 'normal',
	},

	// Variants
	Caption: {
		...baseTextStyle,
		fontSize: '14px',
		fontWeight: 'medium',
		textTransform: 'uppercase',
	},
	'Button-SemiBold': {
		...baseHeadingStyle,
		fontSize: '14px',
		fontWeight: '600',
	},
	'Button-Medium': {
		...baseHeadingStyle,
		fontFamily: 'Poppins',
		fontSize: '14px',
		fontWeight: 'medium',
	},
} satisfies Record<string, TextProps>;

export default textStyles;
