import { ListItem, Text, UnorderedList } from '@chakra-ui/react';

const TooltipTextConditions = (): JSX.Element => (
	<>
		<Text fontSize="14px" mb="16px" color="white">
			Il est possible de récupérer son épargne en capital de façon anticipée dans les cas suivants :
		</Text>
		<UnorderedList
			ml={{ base: '24px !important', md: '32px !important' }}
			fontSize="14px"
			spacing={{ base: '4px', md: '6px', lg: '8px' }}
		>
			<ListItem>Acquisition de la résidence principale.</ListItem>
			<ListItem>Expiration des droits aux allocations chômage.</ListItem>
			<ListItem>Invalidité du titulaire, de ses enfants, de son époux ou épouse ou de son partenaire de Pacs.</ListItem>
			<ListItem>Décès de l'époux ou l'épouse ou du partenaire de Pacs.</ListItem>
			<ListItem>Surendettement</ListItem>
			<ListItem>Cessation d'activité non salariée à la suite d'un jugement de liquidation judiciaire.</ListItem>
		</UnorderedList>
	</>
);

export default TooltipTextConditions;
