import { useEffect, useMemo } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from 'hooks/useStore';
import { setLastInnerStepAvailable } from 'onboarding/Stepper/stepper.slice';
import { useGetKYCQuery } from 'services/requests/kyc';
import { useGetSavingsAccountQuery } from 'services/requests/savingsAccounts';
import { isNone, isNotNone } from 'utils/functions';

import { kycStep } from '../steps';

import Introduction from './steps/Introduction';
import Justificatifs from './steps/Justificatifs';
import Reglementaire from './steps/Reglementaire';
import Signature from './steps/Signature';
import { justificatifsStep, reglementaireStep, signatureStep } from './steps';

const Souscription = (): JSX.Element => {
	const { id } = useParams();
	const { data: savingsAccount, isLoading: isAccountLoading } = useGetSavingsAccountQuery(id!);
	const { data: kyc, isFetching: isKYCFetching } = useGetKYCQuery();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const mostAdvancedStep = useMemo(() => {
		if (isAccountLoading || isKYCFetching) return undefined;

		if (isNone(kyc) || isNone(savingsAccount)) {
			navigate(`../${kycStep.url}`);
			return undefined;
		}

		if (isNone(kyc.nif)) {
			return reglementaireStep;
		}

		if (isNone(savingsAccount.bankInformationId)) {
			return justificatifsStep;
		}

		return signatureStep;
	}, [isAccountLoading, isKYCFetching, kyc, navigate, savingsAccount]);

	useEffect(() => {
		if (isNotNone(mostAdvancedStep)) dispatch(setLastInnerStepAvailable(mostAdvancedStep.id));
	}, [mostAdvancedStep, dispatch]);

	return (
		<Routes>
			<Route index element={<Introduction />} />
			<Route path="reglementaire" element={<Reglementaire />} />
			<Route path="justificatifs/*" element={<Justificatifs />} />
			<Route path="signature" element={<Signature />} />
		</Routes>
	);
};

export default Souscription;
