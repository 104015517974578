import { FC, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Skeleton, VStack } from '@chakra-ui/react';

import FinalizingSubscriptionList from 'components/cards/subscriptions/FinalizingSubscriptionList';
import ValidatingSubscriptionList from 'components/cards/subscriptions/ValidatingSubscriptionList';
import { useGetSavingsAccountsSubscriptionsQuery } from 'services/requests/savingsAccounts';
import { ProductSubscriptionType, ProductType } from 'store/types/global.type';
import { SavingsAccountStatus } from 'store/types/savingsAccount.type';
import { isNotNone } from 'utils/functions';

const CashSubscriptionsRoutes: FC = () => (
	<Routes>
		<Route path=":id?" element={<CashSubscriptions />} />
	</Routes>
);

const CashSubscriptions: FC = () => {
	const { data: subscriptions, isFetching: isFetchingSubscriptions } = useGetSavingsAccountsSubscriptionsQuery();

	const formattedSubscriptions = useMemo<ProductSubscriptionType[] | undefined>(() => {
		if (!subscriptions) return undefined;

		return subscriptions.map((sub) => ({
			...sub,
			typeProject: ProductType.CASH,
		}));
	}, [subscriptions]);

	if (isFetchingSubscriptions || !subscriptions) return null;

	return (
		<Skeleton isLoaded={isNotNone(formattedSubscriptions)} w="100%" minH={isFetchingSubscriptions ? '140px' : '0px'}>
			<VStack w="100%" align="start" spacing="lg">
				<FinalizingSubscriptionList
					subscriptions={formattedSubscriptions?.filter((sub) => sub.status === SavingsAccountStatus.REQUESTED)}
				/>
				<ValidatingSubscriptionList
					subscriptions={formattedSubscriptions?.filter((sub) => sub.status !== SavingsAccountStatus.REQUESTED)}
				/>
			</VStack>
		</Skeleton>
	);
};

export default CashSubscriptionsRoutes;
