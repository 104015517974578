import { backendApi } from 'services/apiService';
import { EnvelopeType } from 'store/types/global.type';
import { PortfolioProjections, TaxEconomy } from 'store/types/portfolio.type';
import { InvestSubscription, PopulatedInvestSubscription } from 'store/types/subscription.type';

type SubscriptionIdDto = { subscriptionId: string };

export type GenerateSubscriptionDTO = {
	envelopeType: EnvelopeType;
};

type SignatureRequestDto = SubscriptionIdDto;

type SignSubscriptionDTO = SubscriptionIdDto & {
	code: string;
};

type UpdateInvestSubscriptionObjectiveDto = Pick<InvestSubscription, 'projectType'> & {
	recurrentDepositAmount: number;
	initialDepositAmount: number;
};

type CreateInvestSubscriptionObjectiveDto = UpdateInvestSubscriptionObjectiveDto & {
	envelope: EnvelopeType;
};

export type UpdateInvestSubscriptionBeneficiary = Pick<InvestSubscription, 'beneficiary' | 'freeBeneficiaryClause'>;

export type UpdateInvestSubscriptionVersement = Pick<
	InvestSubscription,
	'fundOriginType' | 'recurrentDepositperiod'
> & {
	recurrentDepositAmount: number;
	initialDepositAmount: number;
};

export type UpdateInvestSubscriptionDeduction = Pick<InvestSubscription, 'representativeType'>;

export type UpdateInvestSubscriptionBankAccount = Pick<InvestSubscription, 'bankInformationId'>;

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllSubscriptions: builder.query<PopulatedInvestSubscription[], void>({
			query: () => '/subscriptions',
			providesTags: () => ['InvestSubscriptions'],
		}),
		getSubscription: builder.query<PopulatedInvestSubscription, SubscriptionIdDto>({
			query: ({ subscriptionId }) => ({
				url: `/subscriptions/id/${subscriptionId}`,
			}),
			providesTags: () => ['InvestSubscriptions'],
		}),
		deleteSouscription: builder.mutation<void, SubscriptionIdDto>({
			query: ({ subscriptionId }) => ({
				url: `/subscriptions/${subscriptionId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['InvestSubscriptions'],
		}),

		getPerSubscriptionTaxEconomy: builder.query<TaxEconomy, SubscriptionIdDto>({
			query: ({ subscriptionId }) => ({
				url: `/subscriptions/${subscriptionId}/tax-economy`,
			}),
		}),
		getProjections: builder.query<PortfolioProjections, SubscriptionIdDto>({
			query: ({ subscriptionId }) => ({
				url: `/subscriptions/${subscriptionId}/projections`,
			}),
		}),

		create: builder.mutation<InvestSubscription, CreateInvestSubscriptionObjectiveDto>({
			query: ({ ...body }) => ({
				url: '/subscriptions',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['InvestSubscriptions'],
		}),
		updateObjective: builder.mutation<InvestSubscription, UpdateInvestSubscriptionObjectiveDto & SubscriptionIdDto>({
			query: ({ subscriptionId, ...body }) => ({
				url: `/subscriptions/${subscriptionId}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['InvestSubscriptions'],
		}),
		updateBeneficiary: builder.mutation<InvestSubscription, UpdateInvestSubscriptionBeneficiary & SubscriptionIdDto>({
			query: ({ subscriptionId, ...body }) => ({
				url: `/subscriptions/${subscriptionId}/update-beneficiary`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['InvestSubscriptions'],
		}),
		updateVersement: builder.mutation<InvestSubscription, UpdateInvestSubscriptionVersement & SubscriptionIdDto>({
			query: ({ subscriptionId, ...body }) => ({
				url: `/subscriptions/${subscriptionId}/update-versement`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['InvestSubscriptions'],
		}),
		updateDeduction: builder.mutation<InvestSubscription, UpdateInvestSubscriptionDeduction & SubscriptionIdDto>({
			query: ({ subscriptionId, ...body }) => ({
				url: `/subscriptions/${subscriptionId}/update-deduction`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['InvestSubscriptions'],
		}),
		updateBankAccount: builder.mutation<InvestSubscription, UpdateInvestSubscriptionBankAccount & SubscriptionIdDto>({
			query: ({ subscriptionId, ...body }) => ({
				url: `/subscriptions/${subscriptionId}/update-bank-account`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['InvestSubscriptions'],
		}),
		generateBulletin: builder.mutation<string, SubscriptionIdDto>({
			query: ({ subscriptionId }) => ({
				url: `/subscriptions/generate-bulletin/${subscriptionId}`,
				method: 'POST',
				responseHandler: (response) => response.blob().then((blob) => URL.createObjectURL(blob)),
			}),
			invalidatesTags: ['InvestSubscriptions'],
		}),

		sendSignatureCode: builder.query<{ codeSent: true }, SignatureRequestDto>({
			query: ({ subscriptionId }) => ({
				url: `/subscriptions/signature/${subscriptionId}`,
				method: 'GET',
			}),
		}),
		signSouscription: builder.mutation<InvestSubscription, SignSubscriptionDTO>({
			query: ({ subscriptionId, code }) => ({
				url: `/subscriptions/signature/${subscriptionId}`,
				method: 'POST',
				body: { code },
			}),
			invalidatesTags: ['InvestSubscriptions'],
		}),
	}),
});

export const {
	useGetSubscriptionQuery,
	useSignSouscriptionMutation,
	useLazySendSignatureCodeQuery,
	useGetAllSubscriptionsQuery,
	useCreateMutation,
	useUpdateObjectiveMutation,
	useUpdateBeneficiaryMutation,
	useUpdateVersementMutation,
	useUpdateDeductionMutation,
	useUpdateBankAccountMutation,
	useGenerateBulletinMutation,
	useDeleteSouscriptionMutation,
	useGetPerSubscriptionTaxEconomyQuery,
	useGetProjectionsQuery,
} = extendedApi;
