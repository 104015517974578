import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Heading, HStack, Image, Stack, Text, VStack } from '@chakra-ui/react';

import CashIcon from 'assets/icons/ProductsColoredIcons/Cash.svg';
import ImmoIcon from 'assets/icons/ProductsColoredIcons/Immo.svg';
import InvestIcon from 'assets/icons/ProductsColoredIcons/Invest.svg';
import PeIcon from 'assets/icons/ProductsColoredIcons/Pe.svg';
import { SubscriptionCardProps } from 'components/cards/SubscriptionCard';
import CurrentSubscriptionDetails from 'components/Metrics/CurrentSubscriptionDetails';
import { Stepper } from 'components/navigation/Stepper';
import productSubscriptionSteps from 'components/products/SCPI-PE/subscriptionSteps';
import { getRoundedTimeHorizon } from 'onboarding/Invest/Shared/Objective/projectData';
import { PrivateEquitySubscription, SCPISubscription } from 'store/types/airtable.type';
import { ProductType } from 'store/types/global.type';
import { SavingsAccountInformation, SavingsAccountType } from 'store/types/savingsAccount.type';
import { listPE, listSCPI } from 'utils/automatedFundTypes';
import { isNotNone } from 'utils/functions';
import { displayCapitalized, displayMoney } from 'utils/rendering';

export const variants: Record<ProductType, string> = {
	INVEST: InvestIcon,
	SCPI: ImmoIcon,
	PE: PeIcon,
	CASH: CashIcon,
};

export const isFundFoundAutomated = (fundName: string, projectType: ProductType): boolean =>
	Object.values(projectType === ProductType.PE ? listPE : listSCPI).includes(fundName);

const ValidatingSubscription = ({ showAmount, ...data }: SubscriptionCardProps): JSX.Element => {
	const navigate = useNavigate();
	const timeHorizonPlan = useMemo(
		() => ('timeHorizon' in data && isNotNone(data.timeHorizon) ? getRoundedTimeHorizon(data.timeHorizon) : undefined),
		[data],
	);

	const goToDetailsPage = useCallback(() => navigate(data.id, { preventScrollReset: true }), [data.id, navigate]);

	return (
		<Card w="100%" p="24px" _hover={{ borderColor: 'gray.900' }} cursor="pointer" onClick={goToDetailsPage}>
			<Stack
				align={{ base: 'start', md: 'center' }}
				direction={{ base: 'column', md: 'row' }}
				spacing="md"
				justify="space-between"
			>
				<VStack align="start">
					<HStack spacing="sm">
						<Image boxSize="32px" src={variants[data.typeProject]} />
						<Heading variant="Title-M-Bold">
							{data.typeProject === ProductType.INVEST ? (
								<>
									{data.envelope.type === 'AV' ? 'Assurance Vie' : 'PER'} - {displayMoney(data.initialDepositAmount)}
									{+data.recurrentDepositAmount > 0 && <> - {displayMoney(data.recurrentDepositAmount)}/mois</>}
								</>
							) : data.typeProject === ProductType.CASH ? (
								<>
									{data.type === SavingsAccountType.CER ? 'Livret épargne' : 'Compte à terme'}
									{showAmount &&
										(data as SavingsAccountInformation).initialAUM &&
										` - ${displayMoney(data.initialAUM!)}`}
								</>
							) : (
								<>
									{data.fundName}
									{showAmount && data.amountVersement && ` - ${displayMoney(data.amountVersement)}`}
								</>
							)}
						</Heading>
					</HStack>
					<Text variant="Text-M-Medium">
						{data.typeProject === ProductType.CASH ? (
							<>Cash Ramify</>
						) : data.typeProject === ProductType.INVEST ? (
							<>
								{data.investmentPreferences?.portfolioType && (
									<>{displayCapitalized(data.investmentPreferences.portfolioType)} - </>
								)}
								{data.investmentPreferences?.risk && <>Risque {data.investmentPreferences.risk} - </>}
								{data.investmentPreferences?.temperature && (
									<>Ramify Green {data.investmentPreferences.temperature} °C - </>
								)}
								{timeHorizonPlan && <>Horizon {timeHorizonPlan.label} </>}
							</>
						) : (
							<>
								{data.fundPartnerName} - {'fundType' in data ? data.fundType?.join(', ') : 'SCPI'}
							</>
						)}
					</Text>
				</VStack>

				{/* drawer details */}
				{/* todo: add unique drawer */}
				<CurrentSubscriptionDetails {...data} />

				{data.typeProject !== ProductType.INVEST && (
					<Stepper
						width={{ base: '100%', md: 'auto' }}
						variant="subscription"
						showIndexes={false}
						showLabels={false}
						steps={productSubscriptionSteps(
							(data as SCPISubscription | PrivateEquitySubscription)?.fundName ?? '',
							data.status,
						)[data.typeProject as Exclude<ProductType, ProductType.INVEST>].map((step) => ({
							title: step.label,
						}))}
						index={productSubscriptionSteps(
							(data as SCPISubscription | PrivateEquitySubscription)?.fundName ?? '',
							data.status,
						)[data.typeProject as Exclude<ProductType, ProductType.INVEST>].findIndex((e) => e.value === data.status)}
					/>
				)}

				<Button alignSelf={{ base: 'flex-end', md: 'center' }} size="md" variant="secondary">
					Suivre ma souscription
				</Button>
			</Stack>
		</Card>
	);
};

export default ValidatingSubscription;
