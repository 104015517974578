import { FC, useEffect, useRef } from 'react';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Navigate,
	Outlet,
	Route,
	RouterProvider,
	ScrollRestoration,
	useLocation,
	useRouteError,
} from 'react-router-dom';
import { Center } from '@chakra-ui/react';
import { ReportDialog } from '@highlight-run/react';
import { DotWave } from '@uiball/loaders';

import { getAppEnvironment } from 'hooks/getAppEnvironment';
import useAuthentication from 'hooks/useAuthentication';
import useVerifyEmailValidated from 'hooks/useVerifyEmailValidated';
import CashOnboardingRoutes from 'onboarding/Cash/Routes';
import InvestSimulationRoutes from 'onboarding/Invest/Simulation/Routes';
import NewInvestSubscriptionRoutesWrapper from 'onboarding/Invest/Subscription/CreateWrapper.Routes';
import InvestSubscriptionRoutes from 'onboarding/Invest/Subscription/Routes';
import PhoenixOnboardingRoutes from 'onboarding/Phoenix/Routes';
import PEOnboardingRoutes from 'onboarding/PrivateEquity/Routes';
import REOnboardingRoutes from 'onboarding/RealEstate/Routes';
import UpdateOnboardingRoutes from 'onboarding/UpdateKYC/Routes';
import Account from 'pages/Account/Routes';
import AffiliatePage from 'pages/Affiliates';
import ApplyResetPassword from 'pages/Auth/ApplyResetPassword';
import ApplyValidate from 'pages/Auth/ApplyValidate';
import Autologin from 'pages/Auth/Autologin';
import CgpAuth from 'pages/Auth/CgpAuth';
import Login from 'pages/Auth/Login';
import Register from 'pages/Auth/Register';
import ResetPassword from 'pages/Auth/ResetPassword';
import Validate from 'pages/Auth/Validate';
import WelcomePage from 'pages/Auth/WelcomePage/WelcomePage';
import Cash from 'pages/Cash/Routes';
import Corporate from 'pages/Corporate/Routes';
import Fiscalite from 'pages/Fiscalite';
import { Home } from 'pages/Home';
import Invest from 'pages/Invest/Routes';
import OffreBlack from 'pages/OffreBlack';
import Phoenix from 'pages/Phoenix';
import PrivateEquity from 'pages/PrivateEquity/Routes';
import RealEstate from 'pages/RealEstate/Routes';
import SubscriptionsRouter from 'pages/Subscriptions/Routes';
import eventTracker from 'services/events/eventTracker';
import { useGetUserSCPISubscriptionsQuery } from 'services/requests/realEstate/subscriptions';
import { FundSubscriptionStatus } from 'store/types/airtable.type';

import AppLayout from './Layout/App/Layout';
import AuthenticationLayout from './Layout/Auth/Layout';
import OnboardingLayout from './Layout/Onboarding';

export const RedirectFromLocation = ({ to }: { to: string }) => {
	const location = useLocation();

	return <Navigate to={to} state={{ from: location }} replace />;
};

// Redirect the user to the auth page if he is not logged in and on the email validation page if he did not validate his email
const AuthenticatedAndVerifiedGuard = (): JSX.Element => {
	const { isAuthenticated, isLoading: isAuthLoading, userData } = useAuthentication();
	const { isVerified: isEmailVerified, isLoading: isEmailVerificationLoading } = useVerifyEmailValidated();
	const { data: subscriptions, isLoading: isSubscriptionloading } = useGetUserSCPISubscriptionsQuery();

	if (isAuthLoading || isEmailVerificationLoading || isSubscriptionloading) {
		return (
			<Center bg="grey.100" w="100vw" h="100vh">
				<DotWave />
			</Center>
		);
	}

	if (!isAuthenticated) return <RedirectFromLocation to="/connexion" />;
	if (!isEmailVerified) return <RedirectFromLocation to="/valider-email" />;
	if (
		userData?.isPhoenix &&
		(!subscriptions ||
			!subscriptions?.find((s) =>
				[
					FundSubscriptionStatus.SIGNED,
					FundSubscriptionStatus.RECEIVED_FUNDS,
					FundSubscriptionStatus.REGISTERED,
					FundSubscriptionStatus.COMPLETED,
				].includes(s.status),
			))
	)
		return <RedirectFromLocation to="/phoenix" />;
	return <Outlet />;
};

const AuthenticatedGuard = ({ shouldBeAuthenticated }: { shouldBeAuthenticated: boolean }): JSX.Element => {
	const { isAuthenticated, isLoading: isAuthLoading } = useAuthentication();

	if (isAuthLoading) {
		return (
			<Center bg="grey.100" w="100vw" h="100vh">
				<DotWave />
			</Center>
		);
	}

	// User is trying to access an authenticated route without being authenticated
	if (!isAuthenticated && shouldBeAuthenticated) return <RedirectFromLocation to="/connexion" />;

	// User is on a authentication route while already being authenticated
	if (isAuthenticated && !shouldBeAuthenticated) return <RedirectFromLocation to="/" />;

	return <Outlet />;
};

const NotVerifiedGuard = (): JSX.Element => {
	const { isVerified: isEmailVerified, isLoading: isEmailVerificationLoading } = useVerifyEmailValidated();
	const { search } = useLocation();

	const product = new URLSearchParams(search).get('redirect') ?? '/';

	if (isEmailVerificationLoading)
		return (
			<Center bg="grey.100" w="100vw" h="100vh">
				<DotWave />
			</Center>
		);

	if (isEmailVerified) return <RedirectFromLocation to={product} />;

	return <Outlet />;
};

const PhoenixGuard = (): JSX.Element => {
	const { isAuthenticated, isLoading: isAuthLoading, userData } = useAuthentication();
	const { data: subscriptions, isLoading: isSubscriptionloading } = useGetUserSCPISubscriptionsQuery();

	if (isAuthLoading || isSubscriptionloading) {
		return (
			<Center bg="grey.100" w="100vw" h="100vh">
				<DotWave />
			</Center>
		);
	}
	if (!isAuthenticated) return <RedirectFromLocation to="/connexion" />;
	if (!userData?.isPhoenix) return <RedirectFromLocation to="/" />;
	if (userData?.isPhoenix && !subscriptions?.find((s) => ['REQUESTED', 'PENDING', 'CONTRACT SENT'].includes(s.status)))
		return <RedirectFromLocation to="/" />;

	return <Outlet />;
};

const BrowserWrapper: FC = () => {
	const location = useLocation();
	const isFirstRender = useRef(true);

	// The first event 'pageVisit' was triggered twice, so we block the first render, using this method and not a react state
	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}
		eventTracker.pageVisit(location.pathname);
	}, [location.pathname]);

	return (
		<>
			<ScrollRestoration />
			<Outlet />
		</>
	);
};

const ErrorPage = () => {
	const error = useRouteError() as { statusText: string; data: string };
	return (
		<ReportDialog
			error={new Error(`${error.statusText}: ${error.data}`)}
			hideHighlightBranding
			successSubtitle="Merci pour votre aide !"
			labelSubmit="Envoyer"
			labelClose=""
			title="Une erreur est survenue"
			subtitle="Nos équipes ont été alertées et travaillent à la résolution du problème."
			subtitle2="Si vous souhaitez nous aider à identifier la cause de ce problème, pouvez-vous nous décrire l'action que vous avez tenté d'effectuer ?"
			labelName="Nom - Prénom"
			labelEmail="Email"
			labelComments="Commentaires"
			placeholderComments="Veuillez décrire l'action que vous avez tenté d'effectuer"
		/>
	);
};

const { isProductionOrStaging } = getAppEnvironment();

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<BrowserWrapper />} ErrorBoundary={isProductionOrStaging ? ErrorPage : null}>
			<Route path="autologin" element={<Autologin />} />

			{/* [Authentification] - Routes */}
			<Route element={<AuthenticatedGuard shouldBeAuthenticated={false} />}>
				<Route element={<AuthenticationLayout />}>
					<Route path="cgp" element={<CgpAuth />} />
					<Route path="bienvenue" element={<WelcomePage />} />
					<Route path="connexion" element={<Login />} />
					<Route path="creer-un-compte" element={<Register />} />
					<Route path="mot-de-passe-oublie" element={<ResetPassword />} />
					<Route path="mot-de-passe-oublie/:token" element={<ApplyResetPassword />} />
				</Route>
			</Route>

			{/* Validation d'email */}
			<Route element={<NotVerifiedGuard />}>
				<Route element={<AuthenticationLayout />}>
					<Route element={<AuthenticatedGuard shouldBeAuthenticated />}>
						<Route path="valider-email" element={<Validate />} />
					</Route>
					<Route path="valider-email/:token" element={<ApplyValidate />} />
				</Route>
			</Route>
			{/* Redirect people accessing old routes on new routes, for example every link on old affiliation platforms */}
			{/* <LegacyAuthRedirect /> */}

			{/* Authguard verify you are authentified */}
			<Route element={<AuthenticatedAndVerifiedGuard />}>
				{/* [Onboarding] - Routes */}
				<Route element={<OnboardingLayout />}>
					<Route path="onboarding/*" element={<InvestSimulationRoutes />} />
					<Route path="invest/souscription/:id/*" element={<InvestSubscriptionRoutes />} />
					<Route
						path="invest/ouverture-assurance-vie/*"
						element={<NewInvestSubscriptionRoutesWrapper envelope="AV" />}
					/>
					<Route
						path="invest/ouverture-per-individuel/*"
						element={<NewInvestSubscriptionRoutesWrapper envelope="PER" />}
					/>
					<Route path="actualisation-situation/*" element={<UpdateOnboardingRoutes />} />
					<Route path="immobilier/onboarding/:id/*" element={<REOnboardingRoutes />} />
					<Route path="private-equity/onboarding/:id/*" element={<PEOnboardingRoutes />} />
					<Route path="cash/onboarding/:id/*" element={<CashOnboardingRoutes />} />
				</Route>

				{/* [AppRoutes] */}
				<Route element={<AppLayout />}>
					{/* [Non client access] */}
					<Route index element={<Home />} />
					<Route path="compte/*" element={<Account />} />
					<Route path="parrainage/*" element={<AffiliatePage />} />
					<Route path="souscriptions/*" element={<SubscriptionsRouter />} />
					<Route path="immobilier/*" element={<RealEstate />} />
					<Route path="private-equity/*" element={<PrivateEquity />} />
					<Route path="invest/*" element={<Invest />} />
					<Route path="fiscalite/*" element={<Fiscalite />} />
					<Route path="cash/*" element={<Cash />} />
					<Route path="bspce/*" element={<Corporate />} />
					<Route path="offre-black/*" element={<OffreBlack />} />
				</Route>
			</Route>

			{/* [Phoenix] - Routes */}
			<Route element={<PhoenixGuard />}>
				{/* Phoenix parcours with no layout */}
				<Route path="phoenix" element={<Phoenix />} />
				<Route element={<OnboardingLayout />}>
					<Route path="phoenix/onboarding/:subId/*" element={<PhoenixOnboardingRoutes />} />
				</Route>
			</Route>

			{/* 404 redirect to "/" by default */}
			<Route path="*" element={<Navigate to="/" replace />} />
		</Route>,
	),
);

const AppRouter: FC = () => <RouterProvider router={router} />;

export default AppRouter;
