import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, chakra, Heading, Spinner, Text, useBoolean, VStack } from '@chakra-ui/react';
import MoralPersonBlockerCard from 'features/MoralPersonBlockerCard';
import PrivateEquityIntention from 'formContexts/PrivateEquityIntention';

import { SponsorActivation } from 'components/contracts/SponsorActivation';
import CustomModal from 'components/Modal';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useGetPEFundByIdQuery } from 'services/requests/privateEquity/funds';
import {
	useGetUserPESubscriptionByIdQuery,
	useUpdatePEIntentionMutation,
} from 'services/requests/privateEquity/subscriptions';
import { PeIntention } from 'store/types/peKyc.type';
import { isNotNone } from 'utils/functions';
import { displayMoney } from 'utils/rendering';

const PeProject = (): JSX.Element => {
	const navigate = useNavigate();
	const toast = useThemedToast();
	const { id } = useParams<{ id: string }>();
	const { data: subscription, isError } = useGetUserPESubscriptionByIdQuery({ id: id! });
	const [isModifying, modify] = useBoolean();
	const [isMoralPersonOpen, setMoralPersonOpen] = useBoolean();

	const [update, { isLoading: isUpdating }] = useUpdatePEIntentionMutation();
	const { data: fund } = useGetPEFundByIdQuery(subscription?.fund as string);

	const methods = useForm<PeIntention>({
		mode: 'onChange',
	});
	const { handleSubmit, setValue } = methods;

	useEffect(() => {
		if (subscription) {
			if (isNotNone(subscription.amountVersement)) setValue('amountVersement', subscription.amountVersement);
			if (isNotNone(subscription.legalEntity)) setValue('legalEntity', subscription.legalEntity);
		}
	}, [subscription, setValue]);

	useEffect(() => {
		if (isError) {
			toast({
				title: 'Une erreur est survenue',
				status: 'error',
				duration: 4500,
				isClosable: true,
			});
		}
	}, [isError, toast]);

	useEffect(() => {
		eventTracker.mixpanel.track('PE Onboarding - Projet');
	}, []);

	if (!subscription || !fund) return <Spinner />;

	const onSubmit = handleSubmit((data) => {
		const navNext = () => {
			if (data.legalEntity === 'MORALE') {
				modify.off();
				setMoralPersonOpen.on();
			} else {
				navigate(`/private-equity/onboarding/${id}/connaissance-client`);
			}
		};

		if (isModifying) {
			update({ ...data, id: id!, amountVersement: +data.amountVersement })
				.unwrap()
				.then(navNext)
				.catch((error) => {
					toast({
						title: 'Une erreur est survenue',
						description: error.data.message,
						status: 'error',
						duration: 4500,
						isClosable: true,
					});
				});
		} else {
			navNext();
		}
	});

	return (
		<VStack w="100%" align="start" spacing="40px">
			<VStack w="100%" align="start" spacing="24px">
				<Heading variant="Title-L-Bold">Mon investissement - {subscription.fundName}</Heading>
				<Text variant="Text-M-Regular" color="grey.900">
					Afin de finaliser votre souscription à {subscription.fundName}, nous avons besoin de collecter certaines
					informations.
				</Text>
			</VStack>

			<Card w="100%">
				<VStack spacing="24px" align="start" w="100%">
					<VStack align="start" w="100%" spacing="12px">
						<Heading variant="Title-S-SemiBold">Récapitulatif de mon projet</Heading>

						{!isModifying && (
							<Text variant="Text-M-Regular" color="grey.900">
								Vous allez investir dans le fonds <b>{subscription.fundName}</b> pour un montant total de{' '}
								<b>{displayMoney(subscription.amountVersement)}</b>.
							</Text>
						)}
					</VStack>

					<FormProvider {...methods}>
						<chakra.form w="100%" onSubmit={onSubmit}>
							{isModifying ? (
								<PrivateEquityIntention fund={fund} />
							) : (
								<Button size="lg" variant="secondary" onClick={modify.on}>
									Modifier mon projet
								</Button>
							)}

							<BottomStepBar showPrev={false} textNext="Commencer" nextSubmit isNextLoading={isUpdating} />
						</chakra.form>
					</FormProvider>
				</VStack>
			</Card>

			<CustomModal
				headersProps={{
					pt: '24px',
					children: `Investir dans ${fund.name}`,
				}}
				isOpen={isMoralPersonOpen}
				onClose={setMoralPersonOpen.off}
			>
				<MoralPersonBlockerCard />
			</CustomModal>

			<Card>
				<SponsorActivation />
			</Card>
		</VStack>
	);
};

export default PeProject;
