import { useMemo, useState } from 'react';
import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, VStack, Wrap } from '@chakra-ui/react';

import { Stepper } from 'components/navigation/Stepper';
import { PopulatedInvestContract } from 'store/types/contract.type';
import { displayMoneyNoDigits } from 'utils/rendering';

import UpgradeEliteConfirmation from './Steps/Confirmation';
import UpgradeEliteInformation from './Steps/Information';
import UpgradeElitePreview from './Steps/Preview';

type UpgradeEliteModalProps = {
	isOpen: boolean;
	onClose: () => void;
	contract: PopulatedInvestContract;
};

const UpgradeEliteModal = ({ isOpen, onClose, contract }: UpgradeEliteModalProps) => {
	// ---

	const [step, setStep] = useState<'informations' | 'preview' | 'sign'>('informations');
	const [amountDeposit, setAmountDeposit] = useState<number>(0);
	const [selectedBankAccountId, setSelectedBankAccountId] = useState<string>('');

	const steps = useMemo<{ title: string; internal: typeof step }[]>(
		() => [
			{ internal: 'informations', title: 'Informations' },
			{ internal: 'preview', title: 'Aperçu' },
			{ internal: 'sign', title: 'Confirmation' },
		],
		[],
	);
	const currentStep = useMemo(() => steps.find((s) => s.internal === step), [step, steps]);

	return (
		<Modal size={{ base: 'md', md: '3xl' }} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<VStack align="start" w="100%" spacing="16px">
						<Wrap shouldWrapChildren>
							<Text variant="Title-S-Bold">
								{contract.envelope.type === 'AV' ? 'Assurance Vie' : 'PER individuel'}{' '}
								{displayMoneyNoDigits(+contract.amount)}
							</Text>
							<Text variant="Title-S-Bold">-</Text>
							<Text variant="Title-S-Bold">Passer à Ramify Elite</Text>
						</Wrap>
						<Stepper w="100%" steps={steps} index={steps.findIndex((s) => s.internal === step)} />
					</VStack>
				</ModalHeader>
				<ModalCloseButton />
				{(() => {
					if (currentStep?.internal === 'informations')
						return (
							<UpgradeEliteInformation
								contract={contract}
								onNext={({ bankAccountId: infoBankAccountId, amountDeposit: infoAmountDeposit }) => {
									setSelectedBankAccountId(infoBankAccountId);
									setAmountDeposit(infoAmountDeposit);
									setStep('preview');
								}}
							/>
						);
					if (currentStep?.internal === 'preview')
						return (
							<UpgradeElitePreview
								contract={contract}
								onPrev={() => setStep('informations')}
								onNext={() => setStep('sign')}
								amountDeposit={amountDeposit}
							/>
						);
					if (currentStep?.internal === 'sign')
						return (
							<UpgradeEliteConfirmation
								contract={contract}
								amountDeposit={amountDeposit}
								bankAccountId={selectedBankAccountId}
								onPrev={() => setStep('preview')}
								onConfirm={() => onClose()}
							/>
						);
				})()}
			</ModalContent>
		</Modal>
	);
};

export default UpgradeEliteModal;
