import { Box, Heading, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { useRiskDisplay } from 'hooks/useRiskDisplay';
import { RecommandedRiskTooltip } from 'onboarding/Invest/Shared/Portfolio/Steps/Recapitulatif/Recapitulatif';
import { ArbitrageEligible } from 'services/requests/invest/arbitrage';
import { PopulatedInvestContract } from 'store/types/contract.type';

import ContractRiskCard from './Sections/ContractRiskCard';
import ContractTemperatureCard from './Sections/ContractTempCard';
import TypePortfolioCard from './Sections/TypePortfolioCard';

const ContractInvestmentPreferences = ({
	contract,
}: {
	contract: PopulatedInvestContract;
	eligibleData?: ArbitrageEligible;
}): JSX.Element => {
	const risk = useRiskDisplay(contract.investmentPreferences ? contract.investmentPreferences.risk! : 0);

	return (
		<VStack w="100%" spacing="16px" align="start">
			<Heading variant="Title-L-SemiBold">Préférences d’investissement</Heading>
			<Wrap spacing="16px" w="100%">
				<WrapItem minW="200px" flex="1">
					<TypePortfolioCard type={contract.investmentPreferences!.portfolioType!} />
				</WrapItem>
				<WrapItem minW="200px" flex="1">
					<ContractRiskCard risk={risk} toolTipText={<RecommandedRiskTooltip />} />
				</WrapItem>
			</Wrap>
			<Box w={{ base: '100%', xl: '50%' }}>
				{contract.investmentPreferences!.esg && (
					<ContractTemperatureCard temperature={contract.investmentPreferences!.temperature ?? 2.5} />
				)}
			</Box>
		</VStack>
	);
};

export default ContractInvestmentPreferences;
