import { FC, useMemo } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Heading, Tab, TabList, Tabs, VStack } from '@chakra-ui/react';

import AirtableBanner from 'components/Banners/AirtableBanner';

import BankAccountManager from './BankInformations';
import Docs from './Documents';
import PersonalInformations from './PersonalInformations';
import Account from './Security';

const AccountLayout: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const menu = useMemo(
		() =>
			[
				{
					label: 'Pièces justificatives',
					route: `/compte/document`,
				},
				{
					label: 'Sécurité',
					route: `/compte`,
				},
				{
					label: 'Comptes bancaires',
					route: `/compte/comptes-bancaires`,
				},
				{
					label: 'Informations personnelles',
					route: `/compte/informations-personnelles`,
				},
			].map((item) => ({ active: location.pathname === item.route, ...item })),
		[location.pathname],
	);

	const tabIndex = useMemo(() => menu.findIndex((item) => item.active), [menu]);

	return (
		<VStack w="100%" spacing="lg" align="start" py="56px">
			<VStack align="start" spacing="md" w="100%">
				<Heading variant="Title-XL-SemiBold">Compte</Heading>

				<Tabs w="100%" index={tabIndex}>
					<TabList>
						{menu.map((item) => (
							<Tab key={item.label} onClick={() => navigate(item.route)}>
								{item.label}
							</Tab>
						))}
					</TabList>
				</Tabs>
			</VStack>

			<AirtableBanner />

			<Outlet />
		</VStack>
	);
};

const AccountRouter = (): JSX.Element => (
	<VStack
		h="100%"
		align="start"
		spacing={{ base: '24px', md: '32px', lg: '40px' }}
		pb={{ base: '80px', sm: undefined }}
	>
		<Routes>
			<Route element={<AccountLayout />}>
				<Route index element={<Account />} />
				<Route path="document" element={<Docs />} />
				<Route path="comptes-bancaires" element={<BankAccountManager />} />
				<Route path="informations-personnelles" element={<PersonalInformations />} />
			</Route>
		</Routes>
	</VStack>
);

export default AccountRouter;
