import { VStack } from '@chakra-ui/react';

import BannerOffreBlack from './sections/BannerOffreblack';
import BonusOffreBlack from './sections/BonusOffreBlack';
import FooterOffreBlack from './sections/FooterOffreBlack';
import HeaderOffreBlack from './sections/HeaderOffreBlack';
import PartenersOffreBlack from './sections/PartnersOfferBlack';
import ServicesProductsOffreBlack from './sections/ServicesProductsCardsOffreBlack';

const DiscoverPageOffreBlack = (): JSX.Element => (
	<VStack w="100%" py="56px" spacing="54px">
		<HeaderOffreBlack />
		<BannerOffreBlack />
		<BonusOffreBlack />
		<ServicesProductsOffreBlack />
		<PartenersOffreBlack />
		<FooterOffreBlack />
	</VStack>
);
export default DiscoverPageOffreBlack;
