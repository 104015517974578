import { FC } from 'react';
import { PropsOf, useDisclosure } from '@chakra-ui/react';

import Banner from 'components/Banners/Banner';
import ModalActualization from 'pages/Invest/features/Actualization/Modal';
import { VersementEligibility } from 'services/requests/versement';

const bannerDefaultProps = {
	variant: 'information',
	avatar: 'amelie',
	description: 'Amélie, directrice des opérations',
} satisfies Partial<PropsOf<typeof Banner>>;

export const VersementEligibilityError: FC<{
	versementEligibility?: VersementEligibility;
}> = ({ versementEligibility }) => {
	const { isOpen: isOpenKYCUpdate, onOpen: onOpenKYCUpdate, onClose: onCloseKYCUpdate } = useDisclosure();

	if (versementEligibility === VersementEligibility.ELIGIBLE) return null;

	if (versementEligibility === VersementEligibility.RESILIATION_PERIOD)
		return (
			<Banner
				title="Votre contrat est en délai de rétractation, vous ne pouvez pas effectuer d'opération pour l'instant."
				cta="En savoir plus"
				onClick={() => window.open('https://help.ramify.fr/fr/articles/5767847-y-a-t-il-un-delai-de-retractation')}
				{...bannerDefaultProps}
			/>
		);

	if (versementEligibility === VersementEligibility.CUSTOM_PORTFOLIO)
		return (
			<Banner
				title="Votre contrat ne vous permet pas de réaliser cette opération. Merci de contacter le support."
				cta="Contacter le support"
				onClick={() => window.Intercom('showNewMessage')}
				{...bannerDefaultProps}
			/>
		);

	if (versementEligibility === VersementEligibility.KYC_OUTDATED)
		return (
			<>
				<Banner
					title="Vos informations personnelles sont obsolètes, vous ne pouvez pas effectuer de versement pour l'instant."
					cta="Mettre à jour"
					onClick={onOpenKYCUpdate}
					{...bannerDefaultProps}
				/>
				<ModalActualization isOpen={isOpenKYCUpdate} onClose={onCloseKYCUpdate} />
			</>
		);

	if (versementEligibility === VersementEligibility.APICIL_INELIGIBLE)
		return (
			<Banner
				title="Votre contrat ne vous permet pas de réaliser cette opération. Merci de contacter le support."
				cta="Contacter le support"
				onClick={() => window.Intercom('showNewMessage')}
				{...bannerDefaultProps}
			/>
		);

	if (versementEligibility === VersementEligibility.OPERATION_PENDING)
		return (
			<Banner
				title="Une opération est en cours sur votre contrat, vous ne pouvez pas effectuer d'opération pour l'instant."
				{...bannerDefaultProps}
			/>
		);

	return null;
};
