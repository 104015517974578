import { PropsWithChildren } from 'react';
import { Box, SlideFade } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';

import { DesktopTopBarHeight, ProgressBarHeight } from '../constants';

const OnboardingSideBar = ({ children }: PropsWithChildren): JSX.Element => {
	const top = `${DesktopTopBarHeight + ProgressBarHeight}px`;

	return (
		<SlideFade in offsetX="-120px" offsetY="0px" style={{ position: 'sticky', left: '0px', top }}>
			<Box bg="white" w="354px" h={`calc(100vh - ${top})`} borderRight={`1px solid ${colors.grey[300]}`}>
				{children}
			</Box>
		</SlideFade>
	);
};

export default OnboardingSideBar;
