import { backendApi } from 'services/apiService';
import { PEFund, PrivateEquitySubscription } from 'store/types/airtable.type';

export interface CapitalEvolution {
	date: Date[];
	value_no_div_no_tax: number[];
	value_no_div_tax?: number[];
}

export interface CashFlowEvolution {
	cashflow: number[];
	cumulative_cashflow: number[];
	dates: Date[];
}

export interface PrivateEquitySubscriptionMetrics {
	tri: number;
	tri_annualised: number | undefined;
	capital_value: number;
	added_value: number;
	tax_reduction: number; // estimated tax reduction
	reduc_tac: number; // realised tax reduction
	revalo: number; // revaluation
	capital_evolution: CapitalEvolution;
	cash_flow_evolution_dictionary: CashFlowEvolution;
}

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getPrivateEquityDashboard: builder.query<PrivateEquitySubscriptionMetrics, void>({
			query: () => `/private-equity/dashboard/`,
			providesTags: ['PE_SUBSCRIPTIONS'],
		}),

		getPrivateEquityDashboardSubscriptions: builder.query<(PrivateEquitySubscriptionMetrics & { id: string })[], void>({
			query: () => `/private-equity/dashboard/souscriptiondetails`,
			providesTags: ['PE_SUBSCRIPTIONS'],
		}),

		getUserPEGuideDeclaration: builder.query<(PrivateEquitySubscription & { fund: PEFund })[], void>({
			query: () => '/private-equity/getUserFiscalPeSubscriptionsLastYear',
			providesTags: ['PE_SUBSCRIPTIONS'],
		}),
	}),
});

export const {
	useGetUserPEGuideDeclarationQuery,
	useGetPrivateEquityDashboardQuery,
	useGetPrivateEquityDashboardSubscriptionsQuery,
} = extendedApi;
