export const checkEmail = (email: string): boolean =>
	/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
		email,
	);

export const emailPattern =
	/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const getCodePromo = (): string | undefined => {
	const code = window.sessionStorage.getItem('codepromo');

	if (!code) return undefined;
	return code.toUpperCase();
};

export const confirmCodePromoRules = (codePromo: string | undefined): boolean => {
	if (!codePromo) return true;
	return /^[A-Z0-9_]+$/.test(codePromo);
};
