import { Text, TextProps } from '@chakra-ui/react';

type SuccessMessageType = { warning?: boolean };

const SuccessMessage = ({ warning, ...rest }: SuccessMessageType & TextProps): JSX.Element => (
	<Text
		data-cy="cy-success"
		mt="8px !important"
		padding="8px"
		w="100%"
		textAlign="center"
		borderRadius="base"
		border="2px solid #46D684"
		bg="rgba(218, 252, 219, 0.56)"
		fontSize="13px"
		{...rest}
	/>
);

export default SuccessMessage;
