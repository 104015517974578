import { createIcon } from '@chakra-ui/react';

export const EllipseIcon = createIcon({
	displayName: 'EllipseIcon',
	viewBox: '0 0 24 24',
	path: (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="12" cy="12" r="12" fill="#2D3748" />
		</svg>
	),
});

export const DocumentIcon = createIcon({
	displayName: 'DocumentIcon',
	viewBox: '0 0 16 16',
	path: (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.3125 4.6875C9.70941 4.6875 9.21875 4.19684 9.21875 3.59375V0H3.625C2.67728 0 1.90625 0.771031 1.90625 1.71875V14.2812C1.90625 15.229 2.67728 16 3.625 16H12.375C13.3227 16 14.0938 15.229 14.0938 14.2812V4.6875H10.3125ZM4.46875 11.25H6.74125C7.00013 11.25 7.21 11.4599 7.21 11.7188C7.21 11.9776 7.00013 12.1875 6.74125 12.1875H4.46875C4.20987 12.1875 4 11.9776 4 11.7188C4 11.4599 4.20987 11.25 4.46875 11.25ZM4 9.21875C4 8.95987 4.20987 8.75 4.46875 8.75H11.3438C11.6026 8.75 11.8125 8.95987 11.8125 9.21875C11.8125 9.47763 11.6026 9.6875 11.3438 9.6875H4.46875C4.20987 9.6875 4 9.47763 4 9.21875ZM11.3438 6.25C11.6026 6.25 11.8125 6.45987 11.8125 6.71875C11.8125 6.97763 11.6026 7.1875 11.3438 7.1875H4.46875C4.20987 7.1875 4 6.97763 4 6.71875C4 6.45987 4.20987 6.25 4.46875 6.25H11.3438Z"
				fill="currentColor"
			/>
			<path
				d="M10.1562 3.59368C10.1562 3.67984 10.2263 3.74993 10.3125 3.74993H13.8848C13.7991 3.59162 13.6888 3.44621 13.5562 3.3208L10.543 0.470117C10.4261 0.359492 10.2958 0.267211 10.1563 0.194336V3.59368H10.1562Z"
				fill="currentColor"
			/>
		</svg>
	),
});

export const TrashIcon = createIcon({
	displayName: 'TrashIcon',
	viewBox: '0 0 21 21',
	path: (
		<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M15.5 5.5H5.5V17.1667C5.5 17.6269 5.8731 18 6.33333 18H14.6667C15.1269 18 15.5 17.6269 15.5 17.1667V5.5Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.83337 5.5H17.1667"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13 3H7.99996C7.53972 3 7.16663 3.3731 7.16663 3.83333V5.5H13.8333V3.83333C13.8333 3.3731 13.4602 3 13 3Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
});

export const CalendarIcon = createIcon({
	displayName: 'CalendarIcon',
	viewBox: '0 0 40 41',
	path: (
		<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="20" cy="20.5" r="20" fill="#C0D7FE" />
			<g clipPath="url(#clip0_7216_45477)">
				<path
					d="M29.25 13.75H28.25V12.75C28.25 12.15 27.85 11.75 27.25 11.75C26.65 11.75 26.25 12.15 26.25 12.75V13.75H16.25V12.75C16.25 12.15 15.85 11.75 15.25 11.75C14.65 11.75 14.25 12.15 14.25 12.75V13.75H12.25C11.75 13.75 11.25 14.15 11.25 14.75V28.75C11.25 29.35 11.75 29.75 12.25 29.75H18.35C17.65 28.55 17.25 27.15 17.25 25.75C17.25 21.35 20.85 17.75 25.25 17.75C27.15 17.75 28.85 18.45 30.25 19.55V14.75C30.25 14.25 29.75 13.75 29.25 13.75Z"
					fill="#314EB4"
				/>
				<path
					d="M25.25 19.75C21.95 19.75 19.25 22.45 19.25 25.75C19.25 29.05 21.95 31.75 25.25 31.75C28.55 31.75 31.25 29.05 31.25 25.75C31.25 22.45 28.55 19.75 25.25 19.75ZM27.25 26.75H25.25C24.65 26.75 24.25 26.35 24.25 25.75V22.75C24.25 22.15 24.65 21.75 25.25 21.75C25.85 21.75 26.25 22.15 26.25 22.75V24.75H27.25C27.85 24.75 28.25 25.15 28.25 25.75C28.25 26.35 27.85 26.75 27.25 26.75Z"
					fill="#314EB4"
				/>
			</g>
			<defs>
				<clipPath id="clip0_7216_45477">
					<rect width="20" height="20" fill="white" transform="translate(11.25 11.75)" />
				</clipPath>
			</defs>
		</svg>
	),
});
