import { useMemo } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Tab, TabList, Tabs, VStack } from '@chakra-ui/react';

import AirtableBanner from 'components/Banners/AirtableBanner';
import HeaderProductPage from 'components/HeaderProductPage';
import RealEstateDetails from 'pages/RealEstate/Details';
import RealEstate from 'pages/RealEstate/Discover';
import SCPIRecommandationHome from 'pages/RealEstate/Simulations';
import SCPIRecommandationResult from 'pages/RealEstate/Simulations/Results';
import SCPIRecommandationSurvey from 'pages/RealEstate/Simulations/Survey';
import SubscriptionsImmo from 'pages/RealEstate/Subscriptions';
import { useGetUserSCPISubscriptionsQuery } from 'services/requests/realEstate/subscriptions';
import { isNone } from 'utils/functions';

import RealEstateDashboardRouter from './Dashboard/Routes';

type MenuItem = {
	label: string;
	route: string;
	active: boolean;
};

const RealEstateLayout = ({
	hasCompletedSubscription,
	hasRequestedSubscription,
}: {
	hasCompletedSubscription: boolean;
	hasRequestedSubscription: boolean;
}): JSX.Element => {
	const navigate = useNavigate();
	const location = useLocation();

	const menu = useMemo<MenuItem[]>(
		() =>
			[
				{
					label: 'Découvrir',
					route: `/immobilier`,
				},
				...(hasCompletedSubscription
					? [
							{
								label: 'Dashboard',
								route: `/immobilier/dashboard`,
							},
					  ]
					: []),
				...(hasRequestedSubscription
					? [
							{
								label: 'Souscriptions',
								route: `/immobilier/souscriptions`,
							},
					  ]
					: []),
				{
					label: 'Simulations',
					route: `/immobilier/simulation`,
				},
			].map((item) => ({
				active:
					item.route === '/immobilier' ? location.pathname === item.route : location.pathname.startsWith(item.route),
				...item,
			})),
		[hasCompletedSubscription, hasRequestedSubscription, location.pathname],
	);

	const tabIndex = useMemo(() => menu.findIndex((item) => item.active), [menu]);

	return (
		<>
			<VStack w="100%" spacing="24px" position="sticky" top="0px" zIndex={2} pt="46px" bg="white">
				<HeaderProductPage variant="SCPI" />

				<Tabs w="100%" index={tabIndex}>
					<TabList>
						{menu.map((item) => (
							<Tab key={item.label} onClick={() => navigate(item.route)}>
								{item.label}
							</Tab>
						))}
					</TabList>
				</Tabs>
			</VStack>

			<AirtableBanner />

			<Outlet />
		</>
	);
};

const RealEstateRouter = (): JSX.Element => {
	const { data: subscriptions, isLoading: isSubscriptionLoading } = useGetUserSCPISubscriptionsQuery();

	const hasCompletedSubscription = useMemo(() => {
		if (isNone(subscriptions)) return false;
		return subscriptions.some((sub) => sub.status === 'COMPLETED');
	}, [subscriptions]);

	const hasRequestedSubscription = useMemo(() => {
		if (isNone(subscriptions)) return false;
		return subscriptions.some((sub) => sub.status !== 'COMPLETED');
	}, [subscriptions]);

	return (
		<VStack w="100%" spacing="32px" align="start" pb="56px">
			<Routes>
				<Route
					element={
						<RealEstateLayout
							hasCompletedSubscription={hasCompletedSubscription}
							hasRequestedSubscription={hasRequestedSubscription}
						/>
					}
				>
					{isSubscriptionLoading || hasCompletedSubscription ? (
						<Route path="dashboard/*" element={<RealEstateDashboardRouter />} />
					) : (
						<Route path="dashboard/*" element={<Navigate to=".." />} />
					)}

					{isSubscriptionLoading || hasRequestedSubscription ? (
						<Route path="souscriptions/*" element={<SubscriptionsImmo />} />
					) : (
						<Route path="souscriptions/*" element={<Navigate to=".." />} />
					)}

					<Route path="simulation/*">
						<Route index element={<SCPIRecommandationHome />} />
						<Route path="nouvelle" element={<SCPIRecommandationSurvey />} />
						<Route path=":id/modifier" element={<SCPIRecommandationSurvey />} />
						<Route path=":id/resultats/*" element={<SCPIRecommandationResult />} />
						<Route path="*" element={<Navigate to="/immobilier" />} />
					</Route>

					<Route
						path=":id?"
						element={
							<>
								<RealEstate />
								<RealEstateDetails />
							</>
						}
					/>
				</Route>

				{/* <Route path=":id" element={<RealEstateDetails />} /> */}
				<Route path="*" element={<Navigate to="" />} />
			</Routes>
		</VStack>
	);
};

export default RealEstateRouter;
