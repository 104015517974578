import { useMemo } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Heading, HStack, Tab, TabList, Tabs, Text, VStack } from '@chakra-ui/react';

import AirtableBanner from 'components/Banners/AirtableBanner';
import FullscreenLoader from 'components/FullscreenLoader';
import { ProjectBarMenuItem } from 'components/navigation/Submenu/SubmenuNavigation';
import useSubscriptionList from 'hooks/useSubscriptionList';
import { useGetLatestProposalQuery } from 'services/requests/proposal';

import isProposalSubscription from './features/isPropalSubscription';
import Proposals from './Proposals';
import Subscriptions from './Subscriptions';

const SubscriptionsLayout = (): JSX.Element => {
	const navigate = useNavigate();
	const location = useLocation();

	const { data: proposal } = useGetLatestProposalQuery();
	const { subscriptionList, isLoading } = useSubscriptionList();

	const proposalSubscriptions = useMemo(
		() => subscriptionList.filter((sub) => isProposalSubscription(proposal, sub.id)),
		[proposal, subscriptionList],
	);

	const menu: (ProjectBarMenuItem & { route: string })[] = useMemo(
		() =>
			[
				...(proposalSubscriptions.length > 0
					? [
							{
								label: "Propositions d'investissement",
								route: '/souscriptions/propositions',
								badgeNumber: proposalSubscriptions.length,
							},
					  ]
					: []),
				...(subscriptionList.length - proposalSubscriptions.length > 0
					? [
							{
								label: 'Mes souscriptions',
								route: '/souscriptions',
								badgeNumber: subscriptionList.length - proposalSubscriptions.length,
							},
					  ]
					: []),
			].map((item) => {
				if (item.route === '/souscriptions') return location.pathname === item.route ? { active: true, ...item } : item;
				return location.pathname.startsWith(item.route) ? { active: true, ...item } : item;
			}),
		[location.pathname, proposalSubscriptions, subscriptionList],
	);

	const tabIndex = useMemo(() => menu.findIndex((item) => item.active), [menu]);

	if (isLoading) return <FullscreenLoader />;
	if (subscriptionList.length === 0) return <Navigate to="/" replace />;

	return (
		<VStack w="100%" align="start" py="56px" spacing="48px">
			<VStack w="100%" align="start" spacing="16px">
				<Heading variant="Title-XL-SemiBold">Souscriptions</Heading>

				<Tabs w="100%" index={tabIndex}>
					<TabList>
						{menu.map((item) => (
							<Tab key={item.label} onClick={() => navigate(item.route)}>
								<HStack w="100%">
									<Text color="inherit">{item.label}</Text>
									<Text
										variant="Text-S-Bold"
										borderWidth="1px"
										color="inherit"
										borderColor={item.active ? 'primary.yellow' : 'grey.500'}
										px="8px"
										py="2px"
										borderRadius="sm"
									>
										{item.badgeNumber}
									</Text>
								</HStack>
							</Tab>
						))}
					</TabList>
				</Tabs>
			</VStack>

			<AirtableBanner />

			<Outlet />
		</VStack>
	);
};

const SubscriptionsRouter = (): JSX.Element => (
	<Routes>
		<Route element={<SubscriptionsLayout />}>
			<Route path=":id?" element={<Subscriptions />} />
			<Route
				path="propositions/*"
				element={
					<Routes>
						<Route path=":id?" element={<Proposals />} />
					</Routes>
				}
			/>
		</Route>
	</Routes>
);

export default SubscriptionsRouter;
