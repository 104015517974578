import { useState } from 'react';
import { Button, Input, Stack, Text, VStack } from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';

type FundType = 'PE' | 'SCPI';

const FundRequest = ({ type }: { type: FundType }) => {
	const [wishList, setWishList] = useState('');
	const toast = useThemedToast();

	const addWishListToAirTable = () => {
		if (wishList.length === 0) {
			toast({
				title: 'Veuillez entrer une demande',
				status: 'error',
				duration: 5000,
				isClosable: true,
			});
			return false;
		}
		eventTracker.pipedream.addWishListToAirTable(type, new Date().toISOString(), wishList).then(() =>
			toast({
				title: 'Merci pour votre contribution !',
				description: 'Votre demande a bien été prise en compte.',
				status: 'success',
				duration: 5000,
				isClosable: true,
			}),
		);
		setWishList('');
	};

	return (
		<VStack w="100%" mt="32px !important" spacing="sm">
			<Text>Vous ne trouvez pas {type === 'SCPI' ? 'la SCPI' : 'le fonds de Private Equity'} de vos rêves ?</Text>
			<Stack w="100%" spacing="sm" direction={{ base: 'column', sm: 'row' }}>
				<Input
					value={wishList}
					placeholder={`${type === 'SCPI' ? 'SCPI(s) souhaitée(s)' : 'Fonds de Private Equity souhaité(s)'}`}
					onChange={(event) => setWishList(event.target.value)}
				/>
				<Button size="lg" onClick={addWishListToAirTable}>
					Envoyer
				</Button>
			</Stack>
		</VStack>
	);
};

export default FundRequest;
