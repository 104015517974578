import { Outlet } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import TopBar from 'app/Layout/Onboarding/elements/TopBar';
import { usePositionIntercomBubble } from 'hooks/usePositionIntercomBubble';

const OnboardingLayout = (): JSX.Element => {
	usePositionIntercomBubble('onboarding');

	return (
		<VStack minH="100vh" bg="grey.100" spacing="0px">
			<TopBar />
			<Outlet />
		</VStack>
	);
};

export default OnboardingLayout;
