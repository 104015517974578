import { ReactNode } from 'react';
import { Badge, Card, HStack, Image, Text, Tooltip, VStack } from '@chakra-ui/react';

// import AvIcon from 'assets/icons/envelopeType/AV-icon-64-yellow.svg';
// import OptimizationIcon from 'assets/icons/envelopeType/optimization.svg';
// import PerIcon from 'assets/icons/envelopeType/PER-icon-64-yellow.svg';
import AvIcon from 'assets/icons/Invest/Assurance Vie.svg';
import OptimizationIcon from 'assets/icons/Invest/Optimisation.svg';
import PerIcon from 'assets/icons/Invest/PER Individuel.svg';
import { SimulationEnvelopeType } from 'store/types/investSimulation';

import TooltipTextConditions from './TooltipTextConditions';

const infos = {
	OPTIM: {
		icon: OptimizationIcon,
		title: (
			<>
				Optimisation Ramify <br />
				<Text as="span" color="grey.900" fontSize="12px">
					(Assurance vie & PER)
				</Text>
			</>
		),
		liquidity: (
			<>
				Les fonds de l’Assurance Vie sont déblocables en 72h en moyenne (jusqu’à 60 jours). Les fonds du PER sont
				déblocables seulement{'	'}
				<Tooltip
					label={<TooltipTextConditions />}
					cursor="pointer"
					borderRadius="base"
					maxW="88vw"
					p={4}
					border="base"
					color="white"
					bg="primary.black"
				>
					<u>sous conditions</u>
				</Tooltip>
				{'	'}(voir l’encadré du PER).
			</>
		),
		description:
			"Choisissez cette solution si vous souhaitez que Ramify répartisse intelligemment vos investissements entre une assurance vie et un PER individuel, afin de maximiser votre capital net d'impôt à terme de votre horizon de placement.",
		color: 'grey.700',
	},
	AV: {
		icon: AvIcon,
		title: 'Assurance vie',
		description:
			"Idéal pour se constituer un capital tout en conservant la liquidité de ses fonds. Vous ne payez pas d'impôt tant que vous n'effectuez pas de retrait. Au bout de 8 ans, vous bénéficiez d'impôts réduits sur les gains.",

		liquidity: "Déblocage à tout moment, en 72h en moyenne (jusqu'à 60 jours)",
		color: 'red.500',
	},
	PER: {
		icon: PerIcon,
		title: 'PER individuel',
		description:
			"Un PER individuel vous permet de défiscaliser une partie de vos revenus investis avant qu'ils soient prélevés de l'impôt sur le revenu (dans les limites de déductibilité prévues aux articles 163 quatervicies et 154 ter du CGI). L'investissement n'est imposé qu'au moment du retrait.",
		liquidity: (
			<>
				Déblocable soit à horizon retraite (date de liquidation de vos régimes de retraite ou âge légal de départ en
				retraite) soit de manière anticipée {'	'}
				<Tooltip
					label={<TooltipTextConditions />}
					cursor="pointer"
					borderRadius="base"
					maxW="88vw"
					p={4}
					border="base"
					color="white"
					bg="primary.black"
				>
					<u>sous conditions</u>
				</Tooltip>
				{'	'}(cas de sorties limitativement énoncés par l'article L224 4 du CMF L) .
			</>
		),
		color: 'yellow.500',
	},
} satisfies Record<
	SimulationEnvelopeType,
	{
		icon: string;
		color: string;
		title: ReactNode;
		description: ReactNode;
		liquidity: ReactNode;
	}
>;

type OfferCardProps = {
	recommended?: boolean;
	envelope: SimulationEnvelopeType;
	selected: boolean;
	isRetired?: boolean;
	onClick: () => void;
};

const EnvelopeCard = ({ recommended = false, envelope, selected, onClick, isRetired }: OfferCardProps): JSX.Element => (
	<Card
		overflow="visible"
		w="100%"
		variant={isRetired && envelope === 'PER' ? 'grey' : 'white'}
		cursor={isRetired && envelope === 'PER' ? 'not-allowed' : 'pointer'}
		borderColor={selected ? 'primary.yellow' : 'grey.300'}
		onClick={() => {
			if (isRetired && envelope === 'PER') return;
			onClick();
		}}
		_hover={{ borderColor: !selected && 'grey.700' }}
	>
		<VStack align="start" w="100%" spacing="24px">
			{recommended && (
				<Badge
					position="absolute"
					fontSize="14px"
					fontWeight="medium"
					color="green.700"
					top="-25px"
					right="10px"
					bg="green.100"
					border="base"
					borderColor="green.700"
					p="8px 16px"
					borderRadius="sm"
				>
					Recommandé
				</Badge>
			)}
			<VStack spacing="12px" align="start" mt="0px !important">
				<HStack spacing="24px">
					<Image src={infos[envelope].icon} height={recommended ? '32px' : '24px'} />
					<Text variant="Title-S-SemiBold">{infos[envelope].title}</Text>
				</HStack>

				<Text variant="Text-S-Regular">{infos[envelope].description}</Text>
			</VStack>

			<VStack spacing="8px" align="start">
				<Text variant="Text-S-Bold">Liquidité des fonds :</Text>
				<Text variant="Text-S-Regular">{infos[envelope].liquidity}</Text>
			</VStack>
		</VStack>
	</Card>
);

export default EnvelopeCard;
