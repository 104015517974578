import { Box } from '@chakra-ui/react';

import { DesktopTopBarHeight, ZIndexOnboardingLayout } from '../constants';

type OnboardingProgressBarProps = {
	progressPercentage: number;
};

const OnboardingProgressBar = ({ progressPercentage }: OnboardingProgressBarProps) => (
	<Box
		w="100%"
		h="4px"
		bg="grey.300"
		position="sticky"
		top={`${DesktopTopBarHeight}px`}
		zIndex={ZIndexOnboardingLayout}
	>
		<Box w={`${progressPercentage.toFixed(2)}%`} h="4px" bg="primary.yellow" transition="width 0.5s ease-in-out" />
	</Box>
);

export default OnboardingProgressBar;
