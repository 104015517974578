import { useEffect, useState } from 'react';
import { Heading, Text, VStack } from '@chakra-ui/react';

import PersonIcon from 'assets/icons/Justificatif/cardId.svg';
// Documents images
import HomeIcon from 'assets/icons/Justificatif/residence.svg';
import Banner from 'components/Banners/Banner';
import ErrorFieldMessage from 'components/inputs/ErrorMessage';
// Components
import BottomStepBar from 'components/steppers/BottomStepBar';
// Components
import useThemedToast from 'hooks/useThemedToast';
import { StatusPieceJustificatives, useGetKYCJustificatifQuery } from 'services/requests/kyc';

import { KycStepProps } from '../../../utils';
import { JustificatifType } from '../type';

import JustificatifCard from './JustificatifCard';

type MandatoryDocumentType = {
	Piece_identite: boolean;
	Justificatif_domicile: boolean;
	Justificatif_Kbis: boolean;
};

export const getMandatoryDocument = (): MandatoryDocumentType => ({
	Piece_identite: true,
	Justificatif_domicile: true,
	Justificatif_Kbis: false,
});

export const hasUploadRequired = (
	mandatoryDocument: MandatoryDocumentType,
	justificatif: StatusPieceJustificatives | undefined,
): boolean => {
	for (const [file, required] of Object.entries(mandatoryDocument))
		if (required && required !== !!justificatif?.[file]?.uploaded) return false;
	return true;
};

const JustificatifList = ({
	onNext,
	onPrev,
	onClickAdd,
}: KycStepProps & { onClickAdd: (x: JustificatifType) => void }): JSX.Element => {
	const { isError: isErrorJustificatif, data: justificatif } = useGetKYCJustificatifQuery();
	const toast = useThemedToast();
	const [hasSubmitted, setHasSubmitted] = useState(false);
	const mandatoryDocument = getMandatoryDocument();
	const uploadrequired = hasUploadRequired(mandatoryDocument, justificatif);

	useEffect(() => {
		if (isErrorJustificatif) {
			toast({
				title: 'Une erreur est survenue',
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
		}
	}, [isErrorJustificatif, toast]);

	const onSubmit = () => {
		setHasSubmitted(true);
		if (uploadrequired) onNext();
	};

	return (
		<>
			<Heading variant="Title-XL-Bold">Mes pièces justificatives</Heading>
			<VStack spacing="32px" align="start" w="100%" maxW="720px">
				<VStack spacing="8px" align="start" w="100%">
					<JustificatifCard
						logo={PersonIcon}
						title="Pièce d'identité"
						onClickUpload={() => onClickAdd('identite')}
						existingDocument={justificatif?.Piece_identite}
					/>
					{hasSubmitted && !justificatif?.Piece_identite?.uploaded && (
						<ErrorFieldMessage children="Ce document est nécessaire pour continuer" />
					)}
				</VStack>
				<VStack spacing="8px" align="start" w="100%">
					<JustificatifCard
						logo={HomeIcon}
						title="Justificatif de domicile"
						onClickUpload={() => onClickAdd('domicile')}
						existingDocument={justificatif?.Justificatif_domicile}
					/>
					{hasSubmitted && !justificatif?.Justificatif_domicile?.uploaded && (
						<ErrorFieldMessage children="Ce document est nécessaire pour continuer" />
					)}
				</VStack>
				{justificatif?.Justificatif_domicile && justificatif?.Piece_identite && (
					<Banner
						variant="information"
						title="Nous avons vos documents, pensez à vérifier leur validité"
						description={
							<Text variant="Text-S-Regular" color="grey.900">
								Les documents suivants sont acceptés :<br />
								- Facture d'énergie ou de téléphone (de moins de 3 mois)
								<br />- Relevé d'imposition (de moins de 12 mois)
							</Text>
						}
					/>
				)}
			</VStack>
			<BottomStepBar showPrev onPrev={onPrev} showNext onNext={onSubmit} />
		</>
	);
};

export default JustificatifList;
