import colors from 'theme/foundations/colors';

const Select = {
	baseStyle: {
		field: {
			border: `1px solid ${colors.grey[300]}`,
			cursor: 'pointer',
			outline: 'none !important',
			_hover: {
				borderColor: colors.grey[900],
			},
			_focus: {
				borderColor: colors.primary.yellow,
			},
			_invalid: {
				border: `1px solid ${colors.negative[900]}`,
			},
		},
	},
	sizes: {
		md: {
			field: {
				borderRadius: 'sm',
				fontSize: '14px',
				height: '52px',
				px: '16px',
			},
			icon: {
				insetEnd: '10px',
			},
		},
	},
	defaultProps: {
		// https://github.com/chakra-ui/chakra-ui/issues/2347#issuecomment-917375118
		variant: null,
		size: 'md',
	},
};

export default Select;
