import { FC } from 'react';
import { ChartMaximum, ChartStepper, CheckmarkFilled, Compare, Package } from '@carbon/icons-react';
import { Button, Heading, HStack, Stack, Text, VStack } from '@chakra-ui/react';

import MetricCard from 'components/cards/MetricCard';

import useCERCallToAction from './useCERCallToAction';

const GlobalCharacteristics = ({ sentences }: { sentences: string[] }) => (
	<VStack spacing="32px" w="224px" flexShrink="0" my="auto">
		{sentences.map((sentence) => (
			<HStack key={sentence}>
				<CheckmarkFilled size={16} style={{ minWidth: '16px' }} />
				<Text variant="Text-M-Regular" color="grey.900">
					{sentence}
				</Text>
			</HStack>
		))}
	</VStack>
);

export const CharacteristicsCERSection: FC<{ onDetails: () => void }> = ({ onDetails }) => {
	const { cta } = useCERCallToAction();
	const characteristicsCERListData = [
		{
			icon: Package,
			title: 'Dépôt minimum',
			value: '500 €',
			label: "À l'ouverture du livret",
		},
		{
			icon: ChartMaximum,
			title: 'Plafond de dépôt',
			value: '1 000 000 €',
		},
		{
			icon: Compare,
			title: 'Transaction minimum',
			value: '10 €',
			label: 'Versement ou rachat partiel',
		},
	];

	return (
		<VStack w="100%" align="start" spacing="32px">
			<Stack w="100%" direction={{ base: 'column', md: 'row' }} justify="space-between">
				<Heading variant="Title-L-SemiBold">Livret épargne</Heading>
				<Stack direction={{ base: 'column', md: 'row' }}>
					<Button variant="secondary" onClick={onDetails}>
						Voir les détails
					</Button>
					<Button variant={cta.variant} onClick={cta.onClick}>
						{cta.label}
					</Button>
				</Stack>
			</Stack>
			{/* Brut height */}
			<HStack overflowX="auto" width="100%" spacing="24px" alignItems="stretch">
				<GlobalCharacteristics
					sentences={['Argent disponible à tout moment, sans condition', 'Taux boosté à 3,5%* les 2 premiers mois']}
				/>
				{characteristicsCERListData.map((data) => (
					<MetricCard key={data.title} {...data} />
				))}
			</HStack>
		</VStack>
	);
};

export const CharacteristicsCATSection: FC<{ onDetails: () => void }> = ({ onDetails }) => {
	const characteristicsCATListData = [
		{
			icon: Package,
			title: 'Dépôt minimum',
			value: '5 000 €',
			label: 'Pour chaque ouverture',
		},
		{
			icon: ChartMaximum,
			title: 'Plafond de dépôt',
			value: '20 000 000 €',
		},
		{
			icon: ChartStepper,
			title: 'Taux variables',
			value: 'jusqu’à 3,5%',
			label: 'selon la durée',
		},
	];

	return (
		<VStack w="100%" align="start" spacing="32px" pb="32px">
			<Stack w="100%" direction={{ base: 'column', md: 'row' }} justify="space-between">
				<Heading variant="Title-L-SemiBold">Comptes à terme</Heading>
				<Stack direction={{ base: 'column', md: 'row' }}>
					<Button variant="secondary" onClick={onDetails}>
						Voir les détails
					</Button>
					<Button isDisabled variant="secondary" onClick={() => {}}>
						Bientôt disponible
					</Button>
				</Stack>
			</Stack>
			{/* Brut height */}
			<HStack overflowX="auto" width="100%" spacing="24px" alignItems="stretch">
				<GlobalCharacteristics
					sentences={[
						'Argent disponible à tout moment, sans condition',
						'Taux de rémunération proportionnel à la durée de placement',
					]}
				/>
				{characteristicsCATListData.map((data) => (
					<MetricCard key={data.title} {...data} />
				))}
			</HStack>
		</VStack>
	);
};
