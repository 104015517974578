import { ReactNode, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Button,
	Card,
	Center,
	Heading,
	HStack,
	Image,
	ListItem,
	Text,
	UnorderedList,
	useBoolean,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { DotWave } from '@uiball/loaders';

import MandatIcon from 'assets/phoenix/mandat.svg';
import SignIcon from 'assets/phoenix/sign.svg';
import CustomModal from 'components/Modal';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useCreatePhoenixDocumentsSignLinkQuery, useGetSignedDocumentStatusQuery } from 'services/requests/phoenix';
import { isNotNone } from 'utils/functions';

const SignDocumentCard = ({
	isSigned,
	boxIcon,
	name,
	description,
	isLoading,
	onClick,
}: {
	isSigned: boolean;
	boxIcon: string;
	name: string;
	description: ReactNode;
	isLoading: boolean;
	onClick: () => void;
}): JSX.Element => (
	<Card position="relative" size="sm" maxW="352px">
		<VStack align="start" justifyContent="space-between" spacing="16px">
			<HStack spacing="12px">
				<Image h="40px" w="40px" src={boxIcon} />
				<Text variant="label" fontWeight="semibold">
					{name}
				</Text>
			</HStack>
			<Text variant="body">{description}</Text>
			<Button
				onClick={!isSigned ? onClick : undefined}
				cursor={isSigned ? 'default' : 'pointer'}
				variant={isSigned ? 'tertiary' : 'primary'}
				w="100%"
				isLoading={isLoading}
			>
				{isSigned ? 'Documents signés' : 'Signer les documents'}
				<Image src={isSigned ? undefined : SignIcon} h="20px" ml="16px" />
			</Button>
		</VStack>
	</Card>
);

const Documents = (): JSX.Element => {
	const navigate = useNavigate();
	const toast = useThemedToast();
	const { subId } = useParams<{ subId: string }>();
	const { isOpen: isDocsOpen, onOpen: onDocsOpen, onClose: onDocsClose } = useDisclosure();
	const [isDocsSigned, setIsDocsSigned] = useBoolean(false);

	const { data: docs, isFetching: isDocsFetcing, isError: docsError } = useCreatePhoenixDocumentsSignLinkQuery(subId!);
	const { data: signedDocs, isFetching: isSignedDocsFetching } = useGetSignedDocumentStatusQuery(subId!);

	useEffect(() => {
		if (docsError) toast({ title: 'Une erreur est survenue', status: 'error', duration: 4500, isClosable: true });
	}, [docsError, toast]);

	useEffect(() => {
		if (
			isNotNone(signedDocs) &&
			signedDocs?.fatca === true &&
			signedDocs?.mandat === true &&
			signedDocs?.fundOrigin === true
		)
			setIsDocsSigned.on();
	}, [signedDocs, setIsDocsSigned]);

	const handleSubmit = () => {
		if (!isDocsSigned)
			toast({ title: 'Veuillez signer tous les documents', status: 'error', duration: 4500, isClosable: true });
		else navigate(`/phoenix/onboarding/${subId}/test`);
	};

	// add event listener on iframe to know when the user has signed the document
	useEffect(() => {
		eventTracker.mixpanel.track('Phoenix Onboarding - Documents');
		const handler = (e: MessageEvent<{ signature_request_id: string; type: string; event: string }>) => {
			if (e.origin === 'https://yousign.app' && e.data.type === 'yousign') {
				if (e.data.event === 'success' && e.data.signature_request_id === docs?.signature_request_id) {
					onDocsClose();
					setIsDocsSigned.on();
				}
				if (e.data.event === 'error') {
					toast({
						title: 'Erreur lors de la signature de votre document',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				}
			}
		};
		window.addEventListener('message', handler);
		return () => window.removeEventListener('message', handler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [docs]);

	return (
		<VStack w="100%" align="start" spacing="40px">
			<VStack spacing="8px" align="start">
				<Heading variant="Title-L-Bold">Documents à signer</Heading>
				<Text>
					Afin de pouvoir mettre en place l’investissement en SCPI périodique, nous avons besoin de certains documents.
				</Text>
			</VStack>

			<VStack spacing="16px" align="start">
				<SignDocumentCard
					isSigned={isDocsSigned}
					name="Documents en attente de signature"
					description={
						<>
							<Text>Voici la liste des documents nécessaires à la finalisation de votre dossier:</Text>
							<UnorderedList ml="20px" mt="8px" spacing="8px">
								<ListItem>Déclaration d'origine des fonds</ListItem>
								<ListItem>Lettre de pouvoir</ListItem>
								<ListItem>Questionnaire FATCA</ListItem>
							</UnorderedList>
						</>
					}
					boxIcon={MandatIcon}
					onClick={() => onDocsOpen()}
					isLoading={isSignedDocsFetching}
				/>

				<CustomModal
					isOpen={isDocsOpen}
					onClose={onDocsClose}
					size={{ base: 'full', md: '6xl' }}
					contentProps={{ style: { display: 'flex', height: 900 } }}
				>
					{isDocsFetcing && (
						<Center w="100%" h="100%" flexDirection="column">
							<Text mb="24px" fontSize="16px">
								Document(s) en cours de génération
							</Text>
							<DotWave size={70} />
						</Center>
					)}
					{docs && (
						<iframe
							style={{ flexGrow: 1, border: 'none', margin: 0, padding: '20px 20px 0px 0px' }}
							// disable_domain_validation=true is ignored in production, only prupose is dev/staging
							src={`${docs.signature_link}&disable_domain_validation=true`}
						/>
					)}
				</CustomModal>

				<BottomStepBar
					showPrev
					textNext="Suivant"
					onNext={() => handleSubmit()}
					onPrev={() => navigate(`/phoenix/onboarding/${subId}/introduction`)}
				/>
			</VStack>
		</VStack>
	);
};

export default Documents;
