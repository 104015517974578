import { Card, Heading, Text, VStack } from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import { displayMoney } from 'utils/rendering';

type ActionCardProps = {
	title: string;
	montant: number;
	description: string;
	textbtn: string;
	btnCallback: () => void;
};

const ActionCard = ({ title, montant, description, textbtn, btnCallback }: ActionCardProps) => {
	return (
		<Card w="100%" align="start">
			<VStack w="100%" align="start" spacing="md">
				<VStack align="start" spacing="8px">
					<Text variant="Text-M-Medium" color="grey.900">
						{title}
					</Text>
					<Heading variant="Title-L-Bold">
						{displayMoney(montant)}&nbsp;
						<Heading as="span" variant="Title-S-SemiBold">
							{description}
						</Heading>
					</Heading>
				</VStack>
				<ArrowButton variant="tertiary" onClick={btnCallback} alignSelf="end">
					{textbtn}
				</ArrowButton>
			</VStack>
		</Card>
	);
};
export default ActionCard;
