import { Skeleton, VStack } from '@chakra-ui/react';

import { useGetKYCQuery } from 'services/requests/kyc';

import ContactInformationsEditable from './ContactInformationsEditable';
import EtatCivilEditable from './EtatCivilEditable';
import PatrimonyEditable from './PatrimonyEditable';
import ProfessionalSituationEditable from './ProfessionalSituationEditable';

const PersonalInformations = (): JSX.Element => {
	const { data: kycData, isLoading, isSuccess } = useGetKYCQuery();

	return (
		<Skeleton w="100%" isLoaded={!isLoading && isSuccess}>
			<VStack align="start" w="100%" spacing="lg" pb="56px">
				<EtatCivilEditable kyc={kycData} />
				<ProfessionalSituationEditable kyc={kycData} />
				<ContactInformationsEditable kyc={kycData} />
				<PatrimonyEditable kyc={kycData} />
			</VStack>
		</Skeleton>
	);
};

export default PersonalInformations;
