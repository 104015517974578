import {
	Card,
	Circle,
	HStack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import Banner from 'components/Banners/Banner';
import { GraphiqueLine } from 'components/charts/GraphiqueLine';
import CustomTooltip from 'components/CustomTooltip';
import { StrategyRepartition } from 'services/requests/invest/simulation';
import { displayMoney } from 'utils/rendering';

type EnveloppesProps = {
	recommandedRepartition: StrategyRepartition;
};

const SuggestedRepartition = ({ recommandedRepartition }: EnveloppesProps): JSX.Element => {
	const datasets = {
		'Investissement initial': [
			{ color: 'yellow.500', value: recommandedRepartition.initialAmountAV, name: 'Assurance Vie' },
			{ color: 'primary.yellow', value: recommandedRepartition.initialAmountPER, name: 'PER' },
		],
		'Versements programmés': [
			{ color: 'grey.700', value: recommandedRepartition.amountAV, name: 'Assurance Vie' },
			{ color: 'grey.500', value: recommandedRepartition.amountPER, name: 'PER' },
		],
	};

	return (
		<VStack>
			<Card w="100%">
				<VStack align="start" spacing="24px">
					<HStack>
						<Text variant="Title-M-SemiBold">Répartition recommandée par l’optimisation Ramify</Text>
						<CustomTooltip text="Ramify répartit intelligemment vos investissements entre une assurance vie et un PER individuel, afin de maximiser votre capital net d'impôt à terme de votre horizon de placement" />
					</HStack>

					<VStack align="start" spacing="24px">
						<Wrap w="100%" spacingX="64px" spacingY="32px">
							{Object.entries(datasets).map(([datasetName, dataset]) => (
								<WrapItem minW="300px" flex={1} key={datasetName}>
									<VStack align="start" spacing="24px" w="100%">
										<Text variant="Title-S-Regular">{datasetName}</Text>
										<GraphiqueLine data={dataset} />

										<TableContainer>
											<Table>
												<Thead>
													<Tr>
														<Th>Montant</Th>
														<Th>Contrat</Th>
													</Tr>
												</Thead>
												<Tbody>
													{dataset.map((r) => (
														<Tr key={r.name}>
															<Td>
																<HStack>
																	<Circle size="12px" bg={r.color} />
																	<Text variant="Text-M-Medium">{displayMoney(r.value)}</Text>
																</HStack>
															</Td>
															<Td fontWeight="600">
																<HStack>
																	<Text variant="Text-M-Regular">{r.name}</Text>
																</HStack>
															</Td>
														</Tr>
													))}
												</Tbody>
											</Table>
										</TableContainer>
									</VStack>
								</WrapItem>
							))}
						</Wrap>
						<Banner
							title="Vous avez décidé de faire confiance à l'équipe de recherche de Ramify"
							description="La répartition de votre capital recommandée maximise votre rendement net de frais et d’impôt. Vous pourrez néanmoins modifier les montants des versements avant la signature de vos contrats"
							avatar="samy"
							variant="information"
						/>
					</VStack>
				</VStack>
			</Card>
		</VStack>
	);
};

export default SuggestedRepartition;
