import { useNavigate } from 'react-router-dom';
import { Card, Show, Stack, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { ArrowButton } from 'components/Button/Button';
import { PrivateEquityObjective, PrivateEquitySimulation } from 'services/requests/privateEquity/simulations';
import { RealEstateSimulation } from 'services/requests/realEstate/simulations';

export const peSimulationObjectives: Record<PrivateEquityObjective, string> = {
	'150_0_b_ter': "Investir dans le cadre d'une cession",
	defiscate: 'Réduire mon impôt',
	improve_portfolio: 'Dynamiser mon portefeuille',
};

export const SimulationTable = ({
	simulations,
	type,
}: {
	type: 'SCPI' | 'PE';
	simulations: RealEstateSimulation[] | PrivateEquitySimulation[];
}): JSX.Element => {
	const navigate = useNavigate();

	return (
		<VStack w="100%" spacing="sm">
			{simulations.map(({ payload, id, createdAt }) => (
				<Card
					w="100%"
					key={id}
					cursor="pointer"
					onClick={() =>
						navigate(
							type === 'PE' ? `/private-equity/simulation/${id}/resultats` : `/immobilier/simulation/${id}/resultats`,
						)
					}
					_hover={{
						borderColor: 'grey.900',
					}}
				>
					<Stack
						direction={{ base: 'column', md: 'row' }}
						align={{ base: 'start', md: 'center' }}
						w="100%"
						justify="space-between"
						spacing={{ base: 'sm', md: undefined }}
					>
						<Text variant="Caption" color="grey.900">
							{dayjs(new Date(createdAt)).format('DD MMMM YYYY')}
						</Text>

						<Show above="md">
							{'objective' in payload && (
								<VStack spacing="4px" align="start" flex="1">
									<Text variant="Text-M-Regular" color="grey.900">
										Objectif
									</Text>
									<Text variant="Text-M-Medium">{peSimulationObjectives[payload.objective]}</Text>
								</VStack>
							)}
						</Show>

						<VStack spacing="4px" align="start" flex="1">
							<Text variant="Text-M-Regular" color="grey.900">
								Montant à investir
							</Text>
							<Text variant="Text-M-Medium">{payload.initialAUM} €</Text>
						</VStack>

						<Show above="md">
							<VStack spacing="4px" align="start" flex="1">
								<Text variant="Text-M-Regular" color="grey.900">
									Durée de l'investissement
								</Text>
								<Text variant="Text-M-Medium">{payload.timeHorizon} ans</Text>
							</VStack>
						</Show>

						<ArrowButton alignSelf={{ base: 'end', md: 'center' }} right variant="tertiary">
							Revoir le résultat
						</ArrowButton>
					</Stack>
				</Card>
			))}
		</VStack>
	);
};
