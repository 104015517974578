import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

import CardCatalogue from 'components/cards/CardCatalogue';
import { SubscriptionModal } from 'pages/PrivateEquity/Details';
import ModalToBecomeAssimilatedProfessional from 'pages/PrivateEquity/Details/ModalToBecomeAssimilatedProfessional';
import ModalToBecomeProfessional from 'pages/PrivateEquity/Details/ModalToBecomeProfessional';
import { PEFundTaxAdvantageType, peTagsDisplay } from 'pages/PrivateEquity/Discover/options.constants';
import eventTracker from 'services/events/eventTracker';
import { useGetUserQuery } from 'services/requests/user';
import { PEFund } from 'store/types/airtable.type';
import { isNotNone } from 'utils/functions';
import { displayMoneyNoDigits } from 'utils/rendering';

type CardPropsType = {
	fund: PEFund;
	disableAnimation?: boolean;
	mixpanel?: boolean;
};

const buildFundCloseDate = (fund: PEFund): string => {
	if (fund.fundOpen === 'Non') return 'Fonds fermé';
	if (!fund.subscriptionEndDate) return 'N/D';
	if (fund.subscriptionEndDate === 'evergreen') return 'Toujours ouverte';
	return `Jusqu'au ${fund.subscriptionEndDate}`;
};

const PrivateEquityCard = ({ fund, disableAnimation, mixpanel }: CardPropsType): JSX.Element => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const { data: user } = useGetUserQuery();
	const {
		isOpen: isOpenModalToBecomeAssimilatedProfessional,
		onOpen: onOpenModalToBecomeAssimilatedProfessional,
		onClose: OnCloseModalToBecomeAssimilatedProfessional,
	} = useDisclosure();
	const {
		isOpen: isOpenModalToBecomeProfessional,
		onOpen: onOpenModalToBecomeProfessional,
		onClose: OnCloseModalToBecomeProfessional,
	} = useDisclosure();

	const requiresAssimilatedProfessional = fund.InvestorCategory === 'ASSIMILTED_PROFESSIONAL';
	const requiresProfessional = fund.InvestorCategory === 'PROFESSIONAL';

	const isUserAssimilatedProfessional =
		user?.legalEntityType === 'ASSIMILTED_PROFESSIONAL' || user?.legalEntityType === 'PROFESSIONAL';
	const isUserProfessional = user?.legalEntityType === 'PROFESSIONAL';

	const userDoesntMatchAssimilatedProfessional = requiresAssimilatedProfessional && !isUserAssimilatedProfessional;
	const userDoesntMatchProfessional = requiresProfessional && !isUserProfessional;

	const checkProtectedFunds = () => {
		if (userDoesntMatchAssimilatedProfessional) {
			onOpenModalToBecomeAssimilatedProfessional();
		} else if (userDoesntMatchProfessional) {
			onOpenModalToBecomeProfessional();
		} else {
			return true;
		}
	};

	const features = useMemo(
		() =>
			[
				{
					name: 'A partir de',
					value: displayMoneyNoDigits(fund.ticket),
				},
				{
					name: 'Souscription',
					value: buildFundCloseDate(fund),
				},
				fund.FundType
					? {
							name: 'Type de fonds',
							value: fund.FundType,
					  }
					: undefined,
				fund.regions
					? {
							name: 'Géographie',
							value: fund.regions.join(', '),
					  }
					: undefined,
				fund.sectors
					? {
							name: 'Secteur',
							value: fund.sectors.join(', '),
					  }
					: undefined,
				fund.strategy
					? {
							name: 'Stratégie',
							value: fund.strategy.join(', '),
					  }
					: undefined,
			].filter(isNotNone),
		[fund],
	);

	const tags = useMemo(
		() =>
			Object.entries(peTagsDisplay)
				.map(([tag, { name }]) => (fund[tag as PEFundTaxAdvantageType] ? name : undefined))
				.filter(isNotNone),
		[fund],
	);

	return (
		<>
			<SubscriptionModal isOpen={isOpen} onClose={onClose} fund={fund} />
			{requiresAssimilatedProfessional && (
				<ModalToBecomeAssimilatedProfessional
					isOpenModalToBecomeAssimilatedProfessional={isOpenModalToBecomeAssimilatedProfessional}
					OnCloseModalToBecomeAssimilatedProfessional={OnCloseModalToBecomeAssimilatedProfessional}
				/>
			)}
			{requiresProfessional && (
				<ModalToBecomeProfessional
					isOpenModalToBecomeProfessional={isOpenModalToBecomeProfessional}
					OnCloseModalToBecomeProfessional={OnCloseModalToBecomeProfessional}
				/>
			)}
			<CardCatalogue
				name={fund.name}
				partner={fund.partner}
				description={{
					content: fund.description,
					notifyToggle: () => {
						eventTracker.pipedream.peInterest();
						checkProtectedFunds();
					},
				}}
				coverPicture={fund.fundCover?.[0]?.url}
				access={{
					hasAccess: !(userDoesntMatchAssimilatedProfessional || userDoesntMatchProfessional),
					forbiddenText: userDoesntMatchProfessional
						? 'Réservé aux investisseurs professionnels'
						: 'Réservé aux investisseurs avertis',
				}}
				tags={tags}
				details={{
					available: fund.DetailsAvailable,
					onPlaquetteClick: () => {
						if (checkProtectedFunds()) {
							if (fund.fundFlyer?.[0]?.url) window.open(fund.fundFlyer?.[0]?.url);
						}
					},
					onDicClick: () => {
						if (checkProtectedFunds()) {
							if (fund.fundDICI?.[0]?.url) window.open(fund.fundDICI?.[0]?.url);
						}
					},
					onDetailsClick: () => {
						if (checkProtectedFunds()) {
							eventTracker.pipedream.peInterest();
							navigate(fund.id, { preventScrollReset: true });
						}
					},
				}}
				features={features}
				onInvest={() => {
					if (checkProtectedFunds()) {
						onOpen();
						eventTracker.pipedream.peInterest();
					}
					if (mixpanel) {
						// eventTracker.resultSimulationPageClickedOnExtraInfo('PE'); // paul t'as changé qlch ici ?
					}
				}}
				animate={!disableAnimation}
				closed={fund.fundOpen === 'Non'}
			/>
		</>
	);
};

export default PrivateEquityCard;
