import { Button, Image, Text, useDisclosure, VStack } from '@chakra-ui/react';

import ballsOffreBlack from 'assets/icons/OffreBlack/ballsOffreBlack.png';

import { CalendlyModalBlack } from '../Modal';

const FooterOffreBlack = (): JSX.Element => {
	const { isOpen: isCalendlyOpen, onClose: onCalendlyClose, onOpen: onCalendlyOpen } = useDisclosure();

	return (
		<VStack w="100%" position="relative">
			<CalendlyModalBlack isOpen={isCalendlyOpen} onClose={onCalendlyClose} />

			<Image w="100%" position="absolute" src={ballsOffreBlack} alt="icon-ramify" />
			<VStack w={{ base: '100%', xl: '60%' }} position="relative" spacing="24px">
				<Text variant="Title-L-Bold" color="white">
					Expérimentez le meilleur du conseil financier
				</Text>
				<Text variant="Title-M-Medium" color="white" textAlign="center">
					Profitez d'une expérience d'investissement unique, à partir de 100 000 € d’encours. Accédez en priorité à des
					produits réservés à une minorité d'investisseurs.
				</Text>
				<Button variant="super" size="xl" onClick={onCalendlyOpen}>
					Devenir membre
				</Button>
			</VStack>
		</VStack>
	);
};
export default FooterOffreBlack;
