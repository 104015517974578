import { FC } from 'react';
import { Card, HStack, Text, VStack } from '@chakra-ui/react';

export const Properties: FC<{ properties: { label: string; value: string }[] }> = ({ properties }) => (
	<Card w="100%">
		<VStack w="100%" align="start" spacing="16px">
			{properties.map((property) => (
				<HStack key={property.label} w="100%" justify="space-between">
					<Text variant="Text-M-Regular" color="grey.900">
						{property.label}
					</Text>
					<Text variant="Text-M-Regular" color="grey.900">
						{property.value}
					</Text>
				</HStack>
			))}
		</VStack>
	</Card>
);
