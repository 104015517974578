import { MainStepType, OnboardingStep } from 'onboarding/Stepper/stepper.slice';

export enum ReOnboardingSteps {
	PROJECT = 'project',
	KYC = 'kyc',
	REGLEMENTARY = 'reglementary',
	SUBSCRIPTION = 'subscription',
	CONFIRMATION = 'confirmation',
}

const steps: OnboardingStep<ReOnboardingSteps>[] = [
	{
		id: ReOnboardingSteps.PROJECT,
		name: 'Mon projet',
		url: 'projet',
		type: MainStepType.START,
	},
	{
		id: ReOnboardingSteps.KYC,
		name: 'Informations personnelles',
		url: 'connaissance-client',
		type: MainStepType.COURSE,
		innerSteps: [
			{
				id: 'etatCivil',
				name: 'État civil',
				url: 'connaissance-client/etatCivil',
			},
			{
				id: 'foyerFiscal',
				name: 'Foyer fiscal',
				url: 'connaissance-client/foyerFiscal',
			},
			{
				id: 'professionnelle',
				name: 'Situation professionnelle',
				url: 'connaissance-client/professionelle',
			},
			{
				id: 'coordonnees',
				name: 'Coordonnées',
				url: 'connaissance-client/coordonnees',
			},
			{
				id: 'patrimoine',
				name: 'Patrimoine',
				url: 'connaissance-client/patrimoine',
			},
			{
				id: 'justificatif',
				name: 'Justificatifs',
				url: 'connaissance-client/justificatif',
			},
			{
				id: 'recapitulatif',
				name: 'Validation',
				url: 'connaissance-client/recapitulatif',
			},
		],
	},
	{
		id: ReOnboardingSteps.REGLEMENTARY,
		name: 'Réglementaire',
		url: 'reglementaire',
		type: MainStepType.COURSE,
		innerSteps: [
			{
				id: 'informations',
				name: 'Informations réglementaires',
				url: 'reglementaire/informations',
			},
			{
				id: 'informations-produit',
				name: 'Informations relatives au produit',
				url: 'reglementaire/informations-produit',
			},
			{
				id: 'test',
				name: 'Test d’adéquation',
				url: 'reglementaire/test',
			},
		],
	},
	{
		id: ReOnboardingSteps.SUBSCRIPTION,
		name: 'Souscription',
		url: 'souscription',
		type: MainStepType.COURSE,
	},
	{
		id: ReOnboardingSteps.CONFIRMATION,
		name: 'Confirmation',
		url: 'confirmation',
		type: MainStepType.END,
	},
];

export default steps;
