import { Controller, useFormContext } from 'react-hook-form';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Radio,
	RadioGroup,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import NumberInput from 'components/inputs/NumberInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import { PeScpiKyc, UpdateKycFromScpiPeDTO } from 'store/types/KYC.type';

type TempToRefacto = Pick<PeScpiKyc & UpdateKycFromScpiPeDTO, 'nif' | 'savingCapacity' | 'primaryResidenceStatus'>;

const PersonalPrecisionsKycFormContext = () => {
	const {
		control,
		register,
		formState: { errors },
	} = useFormContext<TempToRefacto>();

	const primaryResidenceOptions = Object.entries({
		TENANT: 'Locataire',
		OWNER_WITH_LOAN: 'Propriétaire avec emprunt',
		OWNER_WITHOUT_LOAN: 'Propriétaire sans emprunt',
		FREELY_HOSTED: 'Hébergé à titre gratuit',
	} as Record<PeScpiKyc['primaryResidenceStatus'], string>) as [PeScpiKyc['primaryResidenceStatus'], string][];

	return (
		<VStack w="100%" align="start" spacing="24px">
			<Heading variant="Title-L-Bold">Ma situation</Heading>

			<Wrap w="100%" spacingX="56px" spacingY="24px">
				<WrapItem w={onboardingInputWidth}>
					<FormControl isInvalid={!!errors.primaryResidenceStatus}>
						<FormLabel>Quelle est votre situation d'hébergement ?</FormLabel>
						<Controller
							name="primaryResidenceStatus"
							control={control}
							rules={{ required: true }}
							render={({ field: { onChange, ...rest } }) => (
								<RadioGroup variant="single" size="sm" onChange={(newValue) => onChange(newValue)} {...rest}>
									<VStack spacing="8px">
										{primaryResidenceOptions.map(([key, display]) => (
											<Radio key={key} value={key}>
												{display}
											</Radio>
										))}
									</VStack>
								</RadioGroup>
							)}
						/>
						{errors.primaryResidenceStatus?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
					</FormControl>
				</WrapItem>

				<WrapItem w={onboardingInputWidth}>
					<VStack w="100%" align="start" spacing="24px">
						<FormControl isInvalid={!!errors.nif}>
							<FormLabel>Quel est votre numéro d’identification fiscale (NIF)</FormLabel>

							<Input
								{...register('nif', {
									required: true,
									minLength: 13,
									maxLength: 13,
									pattern: {
										value: /^(\d*)$/,
										message: "Le NIF ne peut contenir que des chiffres, et ne doit pas contenir d'espaces",
									},
								})}
								placeholder="numéro fiscal de référence à 13 chiffres"
							/>

							{errors.nif?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
							{(errors.nif?.type === 'minLength' || errors.nif?.type === 'maxLength') && (
								<FormErrorMessage>Le NIF doit être composé de 13 chiffres</FormErrorMessage>
							)}
							{(errors.nif?.type === 'maxLength' || errors.nif?.type === 'maxLength') && (
								<FormErrorMessage>Le NIF doit être composé de 13 chiffres</FormErrorMessage>
							)}
							{errors.nif?.type === 'pattern' && <FormErrorMessage>{errors.nif.message}</FormErrorMessage>}
						</FormControl>

						<FormControl isInvalid={!!errors.savingCapacity}>
							<FormLabel>Quelle est votre capacité d'épargne mensuelle ?</FormLabel>

							<Controller
								name="savingCapacity"
								control={control}
								rules={{ required: true, min: 0 }}
								render={({ field }) => <NumberInput {...field} placeholder="Montant mensuel" suffix=" €" />}
							/>

							{errors.savingCapacity?.type === 'min' && (
								<FormErrorMessage>Le montant ne peut pas être négatif</FormErrorMessage>
							)}
							{errors.savingCapacity?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
					</VStack>
				</WrapItem>
			</Wrap>
		</VStack>
	);
};

export default PersonalPrecisionsKycFormContext;
