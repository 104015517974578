import { backendApi } from 'services/apiService';
import { UserPEKyc, UserPeKycTest } from 'store/types/peKyc.type';

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		// KYC
		getPEKYC: builder.query<UserPEKyc, void>({
			query: () => '/private-equity/kyc',
			providesTags: ['PE-KYC'],
		}),
		updatePEKYC: builder.mutation<void, UserPEKyc>({
			query: (params) => ({
				url: `/private-equity/kyc`,
				method: 'POST',
				body: params,
			}),
			invalidatesTags: ['PE-KYC', 'KYC'],
		}),
		getPEKYCTest: builder.query<UserPeKycTest, void>({
			query: () => '/private-equity/kyc/test',
			providesTags: ['PE-KYC-TEST'],
		}),
		updatePEKYCTest: builder.mutation<void, UserPeKycTest>({
			query: (params) => ({
				url: `/private-equity/kyc/test`,
				method: 'POST',
				body: params,
			}),
			invalidatesTags: ['PE-KYC-TEST'],
		}),
	}),
});

export const {
	// KYC
	useGetPEKYCQuery,
	useUpdatePEKYCMutation,
	// Test
	useGetPEKYCTestQuery,
	useUpdatePEKYCTestMutation,
} = extendedApi;
