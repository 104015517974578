import { FC } from 'react';
import { ChartMedian } from '@carbon/icons-react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Card,
	Heading,
	HStack,
	Icon,
	Text,
	VStack,
} from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import { PerformanceBadge } from 'components/Metrics/PerformanceBadge';
import { PrivateEquitySubscriptionMetrics } from 'services/requests/privateEquity';
import { displayMoney } from 'utils/rendering';

const PlusValueCard: FC<{ subscription: PrivateEquitySubscriptionMetrics }> = ({ subscription }) => (
	<Card w="100%">
		<VStack w="100%" align="start" spacing="16px">
			<Icon boxSize="32px" as={ChartMedian} />

			<HStack w="100%">
				<Text variant="Text-M-Medium" color="grey.900">
					Plus-values
				</Text>
				<CustomTooltip text="Le taux de rendement personnalisé est défini comme le rapport entre le gain et la valeur moyenne du capital investi dans le support." />
			</HStack>

			{/* todo: use <Collapse/> */}
			<Accordion allowToggle w="100%">
				<AccordionItem border="none">
					<AccordionButton px="0">
						<HStack w="100%" justify="space-between">
							<PerformanceBadge isTitle amount={subscription.added_value} percentage={subscription.tri} />
							<HStack>
								<Text variant="Button-SemiBold">Détail</Text>
								<AccordionIcon />
							</HStack>
						</HStack>
					</AccordionButton>
					<AccordionPanel p={0}>
						<HStack w="100%">
							<PerformanceBadge amount={subscription.revalo} />

							<Text variant="Text-M-Medium" color="grey.900">
								Revalorisation des parts
							</Text>
						</HStack>

						{!!subscription.reduc_tac && (
							<HStack w="100%">
								<PerformanceBadge amount={subscription.reduc_tac} />

								<Text variant="Text-M-Medium" color="grey.900">
									Réduction d'impôt
								</Text>
							</HStack>
						)}
					</AccordionPanel>
				</AccordionItem>
			</Accordion>

			{/* {isAfterOneAugust && (
            <Wrap w="100%" spacing="38px">
                <WrapItem flex="0.5">
                    <Text variant="label" color={subscription.tax_reduction >= 0 ? colors.green[700] : colors.red[500]}>
                        {displayMoney(subscription.tax_reduction)}
                    </Text>
                </WrapItem>
                <WrapItem flex="1">
                    <Text variant="label" color="grey.600">
                        Réduction des impots
                    </Text>
                </WrapItem>
            </Wrap>
        )} */}
		</VStack>
	</Card>
);

export default PlusValueCard;
