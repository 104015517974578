import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup, VStack } from '@chakra-ui/react';

import NumberInput from 'components/inputs/NumberInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import { PEFund } from 'store/types/airtable.type';
import { PeIntention } from 'store/types/peKyc.type';
import { displayMoneyNoDigits } from 'utils/rendering';

type PrivateEquityIntentionProps = {
	fund: PEFund;
};

const PrivateEquityIntention = ({ fund }: PrivateEquityIntentionProps): JSX.Element => {
	const {
		formState: { errors },
		control,
	} = useFormContext<PeIntention>();

	return (
		<VStack spacing="24px" align="start">
			<VStack align="start" spacing="16px">
				<FormControl w={onboardingInputWidth} isInvalid={!!errors.legalEntity}>
					<FormLabel>Vous souhaitez investir en tant que</FormLabel>
					<Controller
						name="legalEntity"
						control={control}
						// rules={{ validate: { required: (value) => isNotNone(value) } }}
						rules={{ required: true }}
						render={({ field }) => (
							<RadioGroup variant="single" size="sm" {...field}>
								<VStack spacing="8px">
									<Radio value="PHYSIQUE">Personne physique</Radio>
									<Radio value="MORALE">Personne morale</Radio>
								</VStack>
							</RadioGroup>
						)}
					/>
				</FormControl>
				{errors.legalEntity?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
			</VStack>

			<FormControl w={onboardingInputWidth} isInvalid={!!errors.amountVersement}>
				<FormLabel>Quel montant souhaitez-vous investir ?</FormLabel>
				<Controller
					control={control}
					name="amountVersement"
					rules={{
						required: { value: true, message: 'Ce champ est nécessaire' },
						min: { value: fund.ticket, message: `Minimum ${displayMoneyNoDigits(fund.ticket)}` },
					}}
					render={({ field }) => (
						<NumberInput {...field} placeholder={`Minimum ${displayMoneyNoDigits(fund.ticket)}`} suffix=" €" />
					)}
				/>
				{errors.amountVersement && <FormErrorMessage>{errors.amountVersement.message}</FormErrorMessage>}
			</FormControl>
		</VStack>
	);
};

export default PrivateEquityIntention;
