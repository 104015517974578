import { useMemo } from 'react';

import { RealEstateSimulationResult } from 'services/requests/realEstate/simulations';
import { SCPIFund } from 'store/types/airtable.type';

const useScpiFundRepartition = (data: RealEstateSimulationResult | undefined, funds: SCPIFund[] | undefined) =>
	useMemo(() => {
		if (!data) return [];

		const montantTotal = Object.keys(data.allocation).reduce<number>(
			(acc, key) => data.allocation[key].montant_nu + data.allocation[key].montant_plein + acc,
			0,
		);

		return Object.keys(data.allocation)
			.map((key) => [
				{
					type: 'Nue propriété',
					value: (data.allocation[key].montant_nu / montantTotal) * 100,
					nbParts: data.allocation[key].parts_nue,
					montant: data.allocation[key].montant_nu,
					fundCover: data.allocation[key].fundCover,
					partner: data.allocation[key].partner,
					id: data.allocation[key].id_airtable,
				},
				{
					type: 'Pleine propriété',
					value: (data.allocation[key].montant_plein / montantTotal) * 100,
					nbParts: data.allocation[key].parts_plein,
					montant: data.allocation[key].montant_plein,
					fundCover: data.allocation[key].fundCover,
					partner: data.allocation[key].partner,
					id: data.allocation[key].id_airtable,
				},
			])
			.flat()
			.filter((i) => i.nbParts !== 0)
			.sort((a, b) => b.value - a.value)
			.map((infoPart) => {
				const fundFound = funds?.find((fund) => fund.id === infoPart.id);
				const ticket = fundFound ? fundFound.lastShareValuation * fundFound.minPPShares : undefined;

				return {
					...infoPart,
					minPPShares: fundFound?.minPPShares,
					minNPShares: fundFound?.minNPShares,
					lastShareValuation: fundFound?.lastShareValuation,
					sectors: fundFound?.sectors,
					tags: fundFound?.tags,
					description: fundFound?.description,
					name: fundFound?.name,
					expectedDistributionRate: fundFound?.expectedDistributionRate,
					regions: fundFound?.regions,
					ticket: ticket,
					meanRevalorisation: fundFound?.meanRevalorisation,
				};
			});
	}, [data, funds]);

export default useScpiFundRepartition;
