import { useEffect, useState } from 'react';

export default function useOnScreen(ref: HTMLElement | null): boolean {
	const [isIntersecting, setIntersecting] = useState(false);

	// eslint-disable-next-line react-hooks/exhaustive-deps -- observer must refresh
	const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

	useEffect((): undefined | (() => void) => {
		if (ref) {
			observer.observe(ref);
			return () => {
				observer.disconnect();
			};
		}
		return undefined;
	}, [ref, observer]);

	return isIntersecting;
}
