import React from 'react';
import { VStack } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';

import { StepStatus } from './type';

type TickStepBoxProps = {
	children: React.ReactNode;
	status: StepStatus;
	hideTick?: boolean;
};

export const TickStepBox = ({ children, status, hideTick }: TickStepBoxProps): JSX.Element => {
	if (hideTick) return <>{children}</>;
	return (
		<VStack
			align="start"
			spacing="8px"
			ml="17px"
			my="4px"
			py="12px"
			borderLeft={`2px solid ${
				status === StepStatus.Unavailable || status === StepStatus.Available ? colors.grey[700] : colors.grey[900]
			}`}
		>
			{children}
		</VStack>
	);
};

export default TickStepBox;
