import { useEffect, useState } from 'react';
import { Button, Heading, HStack, SlideFade, Text, VStack } from '@chakra-ui/react';

import ErrorFieldMessage from 'components/inputs/ErrorMessage';
import SuccessMessage from 'components/inputs/SuccessMessage';
import { ValidationTextInput } from 'components/inputs/TextInput';
import useThemedToast from 'hooks/useThemedToast';
import { AuthInputStyle } from 'pages/Auth/style';
import { confirmCodePromoRules } from 'pages/Auth/utils';
import { useActivateSponsorshipMutation, useGetSponsoringStatusQuery } from 'services/requests/sponsorship';
import api, { NonValidityType } from 'utils/api';

export const SponsorActivation = (): JSX.Element => {
	const toast = useThemedToast();
	const [viewField, setViewField] = useState(false);
	const [codePromo, setCodePromo] = useState('');
	const [invalidSyntax, setInvalidSyntax] = useState<string>();
	const [typeNonValidity, setTypeNonValidity] = useState<NonValidityType>();
	const [promoMessage, setPromoMessage] = useState<string>();

	const { data: sponsored } = useGetSponsoringStatusQuery();
	const [activateSponsorship, { isLoading }] = useActivateSponsorshipMutation();

	const onSubmit = () => {
		if (!confirmCodePromoRules(codePromo)) {
			setInvalidSyntax('Ce code est invalide');
			return;
		}
		activateSponsorship({ code: codePromo })
			.unwrap()
			.then((response) => {
				if (!response)
					toast({
						title: 'Une erreur est survenue',
						status: 'error',
						isClosable: true,
						duration: 3000,
					});
				else {
					toast({
						title: 'Votre code promo a été appliqué',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
					setCodePromo('');
				}
			})
			.catch(() => {
				toast({
					title: 'Une erreur est survenue',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
	};

	useEffect(() => {
		if (sponsored && sponsored.activated) {
			setViewField(sponsored.activated);
			setCodePromo(sponsored.by!);
		}
	}, [sponsored]);

	return (
		<VStack align="start" spacing="16px" w="100%">
			<Heading variant="Title-S-SemiBold">Offre promotionnelle</Heading>

			{/* <Card w="100%"> */}
			<VStack align="start" w="100%" spacing="24px">
				<Text variant="Text-M-Regular" color="grey.900">
					Venez-vous dans le cadre d'un parrainage ou d'une offre promotionnelle ?
				</Text>
				<HStack align="start" w="100%">
					<Button
						onClick={() => setViewField(true)}
						variant={viewField ? 'primary' : 'secondary'}
						isDisabled={sponsored?.activated ?? false}
						isActive={viewField}
					>
						Oui
					</Button>
					<Button
						onClick={() => setViewField(false)}
						variant={!viewField ? 'primary' : 'secondary'}
						isDisabled={sponsored?.activated ?? false}
						isActive={!viewField}
					>
						Non
					</Button>
				</HStack>
				{viewField && (
					<SlideFade in={viewField}>
						<HStack align="start" alignItems="flex-end" w="100%">
							<VStack align="start" spacing="8px">
								<Text variant="Text-S-Regular">Renseignez votre code promotionnel ci-dessous</Text>
								<HStack align="start" spacing="8px">
									<VStack align="end" spacing="8px">
										<ValidationTextInput
											{...AuthInputStyle}
											hideLabel
											isDisabled={!viewField || (sponsored?.activated ?? false)}
											isInvalid={!!invalidSyntax}
											placeholder="Ex: RAMIFY1234"
											validate={async (str) => {
												const syntaxValid = !!confirmCodePromoRules(str);
												if (!syntaxValid) {
													return false;
												}
												return api
													.isCodeValidForUser(str)
													.then(({ data: validity }) => {
														setTypeNonValidity(validity.reason);
														return validity.valid;
													})
													.catch((err) => {
														console.error(err);
														return false;
													});
											}}
											value={codePromo}
											// @ts-ignore -- ON CHANGE REF BAD TYPE
											onChange={(e) => {
												setCodePromo(e.target.value.toUpperCase());
											}}
											onValidated={(valid) => {
												if (valid === undefined) {
													setInvalidSyntax(undefined);
													setPromoMessage(undefined);
												} else if (!valid) {
													setInvalidSyntax('Ce code est invalide');
													setPromoMessage(undefined);
												} else {
													setInvalidSyntax(undefined);
													setPromoMessage('Votre offre de parrainage est valide !');
												}
											}}
										/>
										{promoMessage && <SuccessMessage>{promoMessage}</SuccessMessage>}
										{invalidSyntax && <ErrorFieldMessage>Ce code est invalide</ErrorFieldMessage>}
										{typeNonValidity === 'SAME_USER' && (
											<ErrorFieldMessage>
												Vous ne pouvez pas utiliser votre propre code de parrainage!
											</ErrorFieldMessage>
										)}
									</VStack>
									<Button
										isDisabled={!viewField || (sponsored?.activated ?? false)}
										isLoading={isLoading}
										onClick={onSubmit}
										variant="primary"
										size="lg"
									>
										{!sponsored?.activated ? 'ACTIVER' : 'DÉJÀ ACTIVÉ'}
									</Button>
								</HStack>
							</VStack>
						</HStack>
					</SlideFade>
				)}
			</VStack>
			{/* </Card> */}
		</VStack>
	);
};
