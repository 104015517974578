import { useNavigate } from 'react-router-dom';
import { ChevronRight } from '@carbon/icons-react';
import { Box, Card, Hide, HStack, IconButton, Image, Show, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import CashIcon from 'assets/icons/SideBar/Cash.svg';
import ImmoIcon from 'assets/icons/SideBar/Immo.svg';
import InvestIcon from 'assets/icons/SideBar/Invest.svg';
import PeIcon from 'assets/icons/SideBar/Pe.svg';
import { ArrowButton } from 'components/Button/Button';
import { ProductName } from 'components/cards/CorporateInvestmentProductCard';
import { PerformanceBadge } from 'components/Metrics/PerformanceBadge';
import { ProductData } from 'services/requests/product';
import { isNotNone } from 'utils/functions';
import { displayMoney, formatDateStandart } from 'utils/rendering';

export const variants: Record<ProductName, string> = {
	Invest: InvestIcon,
	Immobilier: ImmoIcon,
	'Private Equity': PeIcon,
	Cash: CashIcon,
};

type ProductCardMetricsProps = {
	data: ProductData;
	date?: Parameters<typeof formatDateStandart>[0];
};

export const ProductMetrics = ({ data, date }: ProductCardMetricsProps): JSX.Element => {
	const displayValuation = (data.valuation ?? 0) + (data.awaitingAUM ?? 0);

	return (
		<>
			<Show above="md">
				<VStack align="start" spacing={0} justifyContent="center" flex={1}>
					<Wrap align="center">
						<WrapItem>
							<Text variant="title" fontWeight="semibold" whiteSpace="nowrap">
								{displayMoney(displayValuation)}
							</Text>
						</WrapItem>
						{!!data.awaitingAUM && (
							<WrapItem>
								<Card border="none" p="2px 4px" bg="blue.50">
									<Text variant="Text-S-Medium" color="blue.900">
										dont {displayMoney(data.awaitingAUM)} en attente
									</Text>
								</Card>
							</WrapItem>
						)}
					</Wrap>

					{isNotNone(date) ? (
						<Text minW="164px" color="grey.900" variant="Text-M-Regular">
							Valorisé le {formatDateStandart(date)}
						</Text>
					) : (
						<Text minW="164px" color="grey.900" variant="Text-M-Regular">
							Valorisation
						</Text>
					)}
				</VStack>
				{isNotNone(data.performance?.value) || isNotNone(data.performance?.percentage) ? (
					<VStack align="start" spacing={0} justifyContent="center" flex={1}>
						<PerformanceBadge amount={data.performance?.value} percentage={data.performance?.percentage} />

						<Text color="grey.900" variant="Text-M-Regular">
							Évolution depuis ouverture
						</Text>
					</VStack>
				) : (
					// keep alignment
					<Box flex={1} />
				)}
			</Show>
			<Hide above="md">
				<VStack align="end" spacing="4px" justifyContent="center">
					<Text variant="title" fontWeight="semibold" whiteSpace="nowrap">
						{displayMoney(displayValuation)}
					</Text>

					{!!data.awaitingAUM && (
						<Card border="none" p="2px 4px" bg="blue.50">
							<Text variant="Text-S-Medium" color="blue.900" textAlign="end">
								dont {displayMoney(data.awaitingAUM)} en attente
							</Text>
						</Card>
					)}
					<PerformanceBadge percentage={data.performance?.percentage} />
				</VStack>
			</Hide>
		</>
	);
};

type ProductCardProps = {
	data: ProductData;
	linkCTA: string;
	variant: keyof typeof variants;
};

const InvestmentProductCard = ({ data, linkCTA, variant }: ProductCardProps): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Card size="sm" w="100%" cursor="pointer" _hover={{ borderColor: 'grey.900' }} onClick={() => navigate(linkCTA)}>
			<HStack transition="all 0.3s ease-out" justify="space-between" spacing="16px" bg="white">
				<HStack
					spacing={{ base: '8px', md: '16px' }}
					minW={{ base: undefined, md: '200px' }}
					flex={{ base: 1, md: undefined }}
				>
					<Image boxSize="32px" src={variants[variant]} />
					<Text variant="Text-M-Medium" fontWeight="semibold">
						{variant}
					</Text>
				</HStack>

				<ProductMetrics data={data} />

				<Show above="md">
					<ArrowButton variant="tertiary">Voir</ArrowButton>
				</Show>

				<Hide above="md">
					<IconButton variant="tertiary" boxSize="24px" aria-label="details" as={ChevronRight} />
				</Hide>
			</HStack>
		</Card>
	);
};

export default InvestmentProductCard;
