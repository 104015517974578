import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, chakra, Heading, Input, Stack, Text, VStack } from '@chakra-ui/react';

import BubblesBackground from 'assets/backgrounds/bubbles.png';
import useInviteAffiliate from 'hooks/useInviteAffiliate';

type FormType = {
	email: string;
};

export const SponsorshipInviteCard: FC = () => {
	const { sendEmail, isLoading: sendingInvitation } = useInviteAffiliate();

	const { register, handleSubmit, setValue } = useForm<FormType>();

	const submit = handleSubmit((data) => {
		sendEmail(data.email).then((success) => {
			if (success) setValue('email', '');
		});
	});

	return (
		<Card
			w="100%"
			border="none"
			size="lg"
			backgroundColor="grey.300"
			backgroundImage={`url(${BubblesBackground})`}
			backgroundPosition="top right"
			backgroundSize="contain"
			backgroundRepeat="no-repeat"
		>
			<VStack align="start" spacing="24px">
				<VStack align="start" spacing="16px" maxW={{ base: '500px', '3xl': 'none' }}>
					<Heading variant="Title-L-SemiBold">Parrainer mes proches chez Ramify</Heading>
					<Text color="grey.900">
						Invitez vos proches à rejoindre Ramify. Pour chaque personne parrainée, vous et votre filleul(e) profitez
						d’une prime de parrainage
					</Text>
				</VStack>
				<chakra.form onSubmit={submit} w={{ base: '100%', sm: 'inherit' }}>
					<Stack direction={{ base: 'column', sm: 'row' }}>
						<Input
							minW="270px"
							flex={{ base: undefined, sm: '1' }}
							placeholder="mesproches@email.com"
							{...register('email', { required: true })}
						/>
						<Button type="submit" isLoading={sendingInvitation} size="lg">
							Envoyer l'invitation
						</Button>
					</Stack>
				</chakra.form>
			</VStack>
		</Card>
	);
};
