import { useLocation, useNavigate } from 'react-router-dom';
import { Center, Heading, Stack, Text, VStack } from '@chakra-ui/react';

import InvestmentCard from './InvestmentCard';
import welcomePageCardsData from './welcomePageCardsData';

const WelcomePage = (): JSX.Element => {
	const navigate = useNavigate();

	const { search } = useLocation();
	const emailUrl = new URLSearchParams(search).get('email') ?? undefined;
	const codePromoUrl = new URLSearchParams(search).get('code') ?? undefined;
	const sponsor = new URLSearchParams(search).get('sponsor') ?? undefined;
	const partnerUrl = new URLSearchParams(search).get('partner') ?? undefined;
	const product = new URLSearchParams(search).get('redirect') ?? undefined;

	const onClick = (redirectParam = '/') => {
		navigate(
			`/creer-un-compte?redirect=${product ?? redirectParam}${
				codePromoUrl !== undefined ? `&code=${codePromoUrl}` : ''
			}${sponsor !== undefined ? `&sponsor=${sponsor}` : ''}${emailUrl !== undefined ? `&email=${emailUrl}` : ''}${
				partnerUrl !== undefined ? `&partner=${partnerUrl}` : ''
			}`,
		);
	};

	return (
		<Center w="100%" h="100%" bg="#F9FBFD">
			<Stack spacing="24px" w="880px" m="40px 24px">
				<Stack>
					<Heading variant="Title-M-SemiBold">Un projet d'investissement ?</Heading>
					<Text variant="Text-M-Regular" color="grey.900">
						Choisissez la classe d'actif qui vous intéresse et laissez vous guider.
					</Text>
				</Stack>
				<VStack spacing="16px" w="100%">
					{welcomePageCardsData.map((card) => (
						<Stack w="100%" cursor="pointer" onClick={() => onClick(card.redirectParam)} key={card.title}>
							<InvestmentCard investmentData={card} />
						</Stack>
					))}
				</VStack>
			</Stack>
		</Center>
	);
};

export default WelcomePage;
