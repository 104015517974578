import { Alert, AlertIcon, Button, Card, Heading, HStack, Image, Stack, Text, VStack } from '@chakra-ui/react';
import { DotSpinner } from '@uiball/loaders';

import Thermal from 'assets/icons/Thermal.svg';
import { MentionUC } from 'components/compliance/MentionUC';
import CustomTooltip from 'components/CustomTooltip';
import FullscreenLoader from 'components/FullscreenLoader';
import ListCompositionPortfolio from 'components/ListCompositionPortfolio';
import ESGPart from 'components/portfolio/ESGPart';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { useRiskDisplay } from 'hooks/useRiskDisplay';
import { useAppSelector } from 'hooks/useStore';
import { useTemperatureDisplay } from 'hooks/useTemperatureDisplay';
import type { OnboardingUpdateStepProps } from 'onboarding/Stepper/stepper.slice';
import { useGetCustomPortfolioQuery } from 'services/requests/invest/preferences';

const Recapitulatif = ({ object: onboardingObject, onNext, onPrev }: OnboardingUpdateStepProps): JSX.Element => {
	const { goBackToPreviousStep } = useAppSelector((state) => state.onboarding.stepper);
	const isMobile = useAppResponsive({ base: true, md: false }) ?? false;

	const {
		data: portfolio,
		isFetching,
		isLoading,
		refetch: triggerRefetchPortfolio,
	} = useGetCustomPortfolioQuery({ id: onboardingObject.id, entityName: onboardingObject.table });

	const risk = useRiskDisplay(onboardingObject.investmentPreferences?.risk ?? 0);
	const temperature = useTemperatureDisplay(onboardingObject.investmentPreferences?.temperature ?? 0);

	if (isLoading || isFetching)
		return (
			<FullscreenLoader>
				<VStack w="70%" spacing="32px">
					<DotSpinner />
					<Text fontWeight={600} align="center">
						À partir d'une liste de plus de 3000 actifs financiers de toutes natures, nous construisons le portefeuille
						qui maximise votre rendement net de frais.
					</Text>
				</VStack>
			</FullscreenLoader>
		);

	if (!portfolio)
		return (
			<Alert variant="left-accent" status="error">
				<AlertIcon />
				<Text>
					Une erreur est survenue.{' '}
					<Button variant="link" onClick={triggerRefetchPortfolio}>
						Réessayer
					</Button>
				</Text>
			</Alert>
		);

	return (
		<VStack w="100%" align="start" spacing="32px">
			<Heading variant="Title-S-SemiBold" fontSize={{ base: 16, md: 20 }}>
				Mon portefeuille sur mesure
			</Heading>

			<Stack w="100%" direction={{ base: 'column', lg: 'row' }} spacing="24px">
				<Card flex={1} variant="yellow">
					<VStack align="start" spacing="0px">
						<Text variant="Text-S-Bold">Votre profil de risque:</Text>
						<HStack spacing="24px">
							<Heading variant="Title-S-SemiBold" color={risk.color}>
								{risk.name}
							</Heading>
							<HStack spacing="11px">
								<HStack align="baseline">
									<Text fontWeight={700} fontSize="32px" color="primary.black">
										{onboardingObject.investmentPreferences!.risk!.toLocaleString('fr-FR')}
									</Text>
									<Text fontWeight={700} fontSize="16px" color="primary.black">
										/10
									</Text>
								</HStack>
							</HStack>
						</HStack>
						<Text variant="body">{risk.description}</Text>
					</VStack>
				</Card>
				<Card flex={1} w="100%">
					<VStack align="start" spacing="0px">
						<HStack>
							<Image boxSize="32px" src={Thermal} alt="Thermomètre" />
							<Text variant="Text-S-Bold" color="green.700">
								Mon impact en température :
							</Text>
						</HStack>
						<HStack spacing="11px">
							<HStack align={{ base: 'center', md: 'baseline' }}>
								<Text color="green.700" fontWeight={700} fontSize="32px" whiteSpace="nowrap">
									{temperature.name}
								</Text>
								<Text color="green.700" fontWeight={700} fontSize="16px">
									°C
								</Text>
								<Text color="green.700">{temperature.description}</Text>
							</HStack>
							<CustomTooltip text="L'objectif à long terme de l'accord de Paris en matière de température est de maintenir l'augmentation de la température moyenne de la planète bien en dessous de 2 °C par rapport aux niveaux préindustriels." />
						</HStack>
					</VStack>
				</Card>
			</Stack>

			<Card w="100%">
				<VStack w="100%" align="start" spacing="24px">
					<VStack w="100%" align="start" spacing="8px">
						<Text variant="Title-M-SemiBold">Composition de votre portefeuille</Text>
						<Text variant="Text-M-Regular" color="grey.900">
							En % de vos investissements
						</Text>
					</VStack>
					<ListCompositionPortfolio assets={portfolio.assets} isLoading={isFetching} />
					<MentionUC />
				</VStack>
			</Card>

			{onboardingObject.investmentPreferences!.esg && <ESGPart />}

			<BottomStepBar
				onPrev={onPrev}
				onNext={onNext}
				showNext={!isFetching}
				textNext={goBackToPreviousStep && !isMobile ? 'Reprendre mon parcours' : undefined}
			/>
		</VStack>
	);
};

export default Recapitulatif;
