export enum ProfessionalSituation {
	EMPLOYEE = 'EMPLOYEE',
	INDEPENDANT = 'INDEPENDANT',
	RETIRED = 'RETIRED',
	STUDENT = 'STUDENT',
	NO_ACTIVITY = 'NO_ACTIVITY',
}

const ProfessionalSituationReferentiel: Record<ProfessionalSituation, string> = {
	EMPLOYEE: 'Salarié',
	INDEPENDANT: 'Travailleur non salarié',
	RETIRED: 'Retraité(e)',
	STUDENT: 'Etudiant(e)',
	NO_ACTIVITY: 'Sans activité',
};

export default ProfessionalSituationReferentiel;
