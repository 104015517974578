import { useFormContext } from 'react-hook-form';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	VStack,
} from '@chakra-ui/react';

import { ControlledNumberInput } from 'components/inputs/NumberInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import { PrivateEquityObjective } from 'services/requests/privateEquity/simulations';

const SimulationPeProjectInput = ({ objectiveType }: { objectiveType: PrivateEquityObjective }): JSX.Element => {
	const {
		register,
		formState: { errors },
		control,
	} = useFormContext<{ initialAUM: number; timeHorizon: number }>();

	const typeObjective = {
		defiscate: {
			min: 1000,
			recommended: '5000',
		},
		improve_portfolio: {
			min: 1000,
			recommended: '10 000',
		},
		'150_0_b_ter': {
			min: 10000,
			recommended: '100 000',
		},
	};

	return (
		<VStack spacing="24px" w="100%" align="start">
			<FormControl isInvalid={!!errors.initialAUM} w={onboardingInputWidth}>
				<FormLabel>Quel est votre montant d’investissement ?</FormLabel>

				<ControlledNumberInput
					name="initialAUM"
					control={control}
					rules={{
						required: { value: true, message: 'Ce champ est obligatoire' },
						min: {
							value: typeObjective[objectiveType].min,
							message: `Le minimum d'investissement est de ${typeObjective[objectiveType].min} €`,
						},
					}}
					placeholder={`Minimum recommandé ${typeObjective[objectiveType].recommended} €`}
				/>

				{!!errors.initialAUM && <FormErrorMessage>{errors.initialAUM.message}</FormErrorMessage>}
			</FormControl>

			<FormControl isInvalid={!!errors.timeHorizon} w={onboardingInputWidth}>
				<FormLabel>Durée maximum de l'épargne </FormLabel>

				<InputGroup>
					<Input
						{...register('timeHorizon', {
							required: { value: true, message: 'Ce champ est obligatoire' },
							min: { value: 6, message: "La durée minimale d'investissement est de 6 ans" },
							max: { value: 20, message: "La durée maximale d'investissement est de 20 ans" },
							valueAsNumber: true,
						})}
						type="number"
						onWheel={(event) => event.currentTarget.blur()}
						placeholder="6 - 20"
						min={6}
						max={20}
					/>
					<InputRightElement w="128px">ans maximum</InputRightElement>
				</InputGroup>

				{!!errors.timeHorizon && <FormErrorMessage>{errors.timeHorizon.message}</FormErrorMessage>}
			</FormControl>
		</VStack>
	);
};

export default SimulationPeProjectInput;
