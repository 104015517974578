import axios from 'axios';
import { H } from 'highlight.run';
import Cookies from 'js-cookie';

import { getAppEnvironment } from 'hooks/getAppEnvironment';
import { EnvelopeType } from 'store/types/global.type';
import { getOfferFromLeadConverted } from 'utils/affiliate';
import { backendConstants, environmentConstants, highlightConstants, mixpanelConstants } from 'utils/constants';

import { MixpanelService } from './mixpanel';
import { PipedreamService } from './pipedream';

const { isProductionOrStaging } = getAppEnvironment();

interface Metadata {
	[key: string]: string | boolean | number;
}

class EventTracker {
	private userId?: string;

	private email?: string;

	private sessionRunning = false;

	public pipedream: PipedreamService;

	public mixpanel: MixpanelService;

	constructor() {
		this.pipedream = new PipedreamService();
		this.mixpanel = new MixpanelService();

		const methods = Object.getOwnPropertyNames(EventTracker.prototype);
		methods.forEach((methodName) => {
			// @ts-ignore
			if (methodName !== 'constructor' && typeof EventTracker.prototype[methodName] === 'function') {
				// @ts-ignore
				const old = EventTracker.prototype[methodName];
				// @ts-ignore
				EventTracker.prototype[methodName] = (...args) => {
					if (isProductionOrStaging) {
						return old.apply(this, args);
					}
				};
			}
		});
	}

	init() {
		this.mixpanel.init();

		H.init(highlightConstants.projectId, {
			environment: environmentConstants.environment,
			// disableConsoleRecording: environmentConstants.nodeEnv === 'development', // disable in local
			enableStrictPrivacy: false,
			manualStart: true,
			networkRecording: {
				enabled: true,
				destinationDomains: [backendConstants.backendUrl],
				recordHeadersAndBody: true,
			},
			integrations: {
				intercom: {
					disabled: true,
				},
				mixpanel: {
					projectToken: mixpanelConstants.token,
					disabled: true,
				},
			},
		});
	}

	emailValidated(email: string) {
		// Affilae
		// @ts-ignore -- Affilae
		window.dataLayer = window.dataLayer || [];
		// @ts-ignore -- Affilae
		window.dataLayer.push({
			event: 'emailValidated',
			utm_source: Cookies.get('utm_source'),
			email,
		});
	}

	// track(action: string, metadata?: Metadata) {
	// 	mixpanel.track(action, metadata);
	// }

	identify(userId: string, email: string) {
		this.userId = userId;
		this.email = email;

		// Mixpanel
		this.mixpanel.identify(this.userId);
		// Once user was identify with user ID, merge mixpanel cookie if present
		const mixpanelCookie = Cookies.get(mixpanelConstants.cookieId);
		if (mixpanelCookie) this.mixpanel.identify(JSON.parse(mixpanelCookie).distinct_id);

		// Highlight
		H.identify(this.userId, { email: this.email });
		this.startSession();

		// Pipedream
		this.pipedream.identify(this.email);
	}

	unidentify() {
		this.userId = undefined;
		this.email = undefined;
		this.pipedream.unidentify();
		this.stopSession();
	}

	contractSigned(metadata: { subscriptionId: string; montant: number; emailCustomer: string }) {
		const payloadMetadatas = {
			...metadata,
		};

		this.mixpanel.track('Contract signed', payloadMetadatas);
		H.track('Contract signed', payloadMetadatas);

		// Affilae
		// @ts-ignore -- Affilae
		window.dataLayer = window.dataLayer || [];
		// @ts-ignore -- Affilae
		window.dataLayer.push({
			event: 'subscriptionSigned',
			utm_source: Cookies.get('utm_source'),
			email: metadata.emailCustomer,
			signature: {
				id: metadata.subscriptionId,
				montant: metadata.montant,
				customer: metadata.emailCustomer,
				key: getOfferFromLeadConverted(metadata.montant),
			},
		});
	}

	pageVisit(name: string, metadata?: Metadata) {
		this.mixpanel.track(`Page visited`, {
			page: name,
			...metadata,
		});
		H.track(`Page visited: ${name}`, metadata);
	}

	// Uses Hubspot Forms to send hubspot_utk to hubspot for contact tracking
	createHubspotForm(email: string, partnerName: string) {
		const portailId = 25284613;
		const formGuid = 'cf86f6f7-462b-426d-b97e-64e927f7e1ba';
		const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portailId}/${formGuid}`;
		const data = {
			fields: [
				{
					objectTypeId: '0-1',
					name: 'email',
					value: email,
				},
			],
			context: {
				hutk: Cookies.get('hubspotutk'),
				pageUri: window.location.href,
				pageName: document.title,
			},
		};
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
		};

		if (partnerName !== '') {
			data.fields.push({
				objectTypeId: '0-1',
				name: 'partnername',
				value: partnerName,
			});
		}
		if (environmentConstants.environment === 'production') {
			axios.post(url, data, config);
		}
	}

	startSession() {
		if (!this.sessionRunning) {
			this.sessionRunning = true;
			H.start();
		}
	}

	stopSession() {
		if (this.sessionRunning) {
			this.sessionRunning = false;
			H.stop();
		}
	}

	askPERTransfer() {
		this.pipedream.perTransferInterest();
		this.mixpanel.track('Action - Demande Transfert PER');
	}

	signVersementError({
		operationType,
		...data
	}: {
		envelopeType: EnvelopeType;
		amount: number;
		operationType: 'programme' | 'ponctuel' | 'updatevp' | 'deletevp';
	}) {
		const operationTypeLabel = {
			programme: 'tentative de mise en place de versement programmé',
			ponctuel: 'tentative de mise en place de versement complementaire',
			updatevp: 'tentative de mise à jour de versement programmé',
			deletevp: 'tentative de suppression de versement programmé',
		};

		this.mixpanel.track('Versement - sms timeout error');
		this.pipedream.signVersementError({
			...data,
			operationType: operationType === 'ponctuel' ? 'COMPLEMENTAIRE' : 'PROGRAMME',
			errorMessage: 'Erreur de timeout lors de la signature du versement',
			message: `⛔ Echec possible A VERIFIER 👀 👀 - ${operationTypeLabel[operationType]}`,
		});
	}
}

export default new EventTracker();
