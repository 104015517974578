import { useEffect, useState } from 'react';

import { useGetUserQuery } from 'services/requests/user';

interface UseVerifyEmailValidatedReturnType {
	isVerified: boolean;
	isLoading: boolean;
}

const useVerifyEmailValidated = (): UseVerifyEmailValidatedReturnType => {
	const [isFirstTime, setIsFirstTime] = useState(true);
	const [isVerified, setIsVerified] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const { data: userData, isLoading: isUserLoading, isSuccess, isError } = useGetUserQuery();

	useEffect(() => {
		if (isUserLoading) {
			// Problem to fix isLoading problem on RTK ---
			if (isFirstTime) {
				setIsLoading(true);
				setIsFirstTime(false);
			}
		} else if (userData) {
			setIsVerified(isSuccess && userData.emailValidated);
			setIsLoading(false);
		} else if (isError) {
			setIsVerified(false);
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUserLoading, setIsLoading, isSuccess, isError, userData]);

	return { isVerified, isLoading };
};

export default useVerifyEmailValidated;
