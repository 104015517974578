export const ContainerAuthStyle = {
	width: { base: '100%', md: '480px' },
	spacing: { base: '32px', md: '32px' },
	padding: { base: '16px', sm: '32px' },
	pt: '40px',
	pb: '80px',
};

export const AuthInputLabelStyle = {
	fontSize: '14px',
	color: 'grey.700',
	fontWeight: 'medium',
};

export const AuthInputStyle = {
	width: '100%',
	maxH: '48px',
	errorBorderColor: '#FE7B78',
	borderRadius: '5px',
	bg: 'white',
	_focus: { color: '#2A2A2A', border: '1px solid #2D3748 !important' },
	border: '1px solid #E2E8F0 !important',
	_hover: { border: '1px solid #2D3748 !important' },
	_placeholder: { color: 'grey.400', fontSize: 14 },
	color: 'black',
	fontSize: 16,
};

export const ButtonAuthStyle = {
	width: '100%',
	bg: 'yellow.500',
	color: 'grey.700',
	fontWeight: 'medium',
	fontSize: '14px',
	borderRadius: '8px',
	height: '56px !important',
	padding: '0px !important',
};

export const InitiationAuthStyle = {
	width: '100%',
	maxH: '56px',
	maxW: '231px',
	bg: 'yellow.500',
	color: 'grey.700',
	fontWeight: '500',
	fontSize: '14px',
	borderRadius: '8px',
	height: '48px !important',
	padding: '0px !important',
};
