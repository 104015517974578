import { useNavigate } from 'react-router-dom';
import { Box, Text, VStack } from '@chakra-ui/react';

import { useAppResponsive } from 'hooks/useAppResponsive';
import { OnboardingStep, StepType } from 'onboarding/Stepper/stepper.slice';
import { thinScrollbar } from 'theme/scrollbar';

import InnerStep from './elements/InnerStep';
import Step from './elements/Step';
import TickStepBox from './elements/Tick';
import { StepStatus } from './elements/type';

type StepperProps = {
	currentStep: StepType;
	lastAvailableStepId: string;
	lastAvailableInnerStepId: string;
	stepper: OnboardingStep<string>[];
	headerTitle?: string;
	headerSubtitle?: string;
	setCollapsed: (c: boolean) => void;
};

const Stepper = ({
	currentStep,
	lastAvailableStepId,
	lastAvailableInnerStepId,
	stepper,
	headerTitle,
	headerSubtitle,
	setCollapsed,
}: StepperProps): JSX.Element => {
	const navigate = useNavigate();
	const isMobile = useAppResponsive({ base: true, lg: false });

	const getStepStatus = (stepToEvaluate: string): StepStatus => {
		const listSteps = stepper.map((s) => s.id);

		// error handling
		if (listSteps.indexOf(stepToEvaluate) === -1) return StepStatus.Unavailable;
		// current step
		if (listSteps.indexOf(stepToEvaluate) === listSteps.indexOf(currentStep.id)) return StepStatus.Active;

		// --- condition completion
		const isCurrentStepAfterStepToEvaluate = listSteps.indexOf(stepToEvaluate) < listSteps.indexOf(currentStep.id);
		const isStepToEvaluateBeforeLastStepAvailable =
			listSteps.indexOf(stepToEvaluate) < listSteps.indexOf(lastAvailableStepId);

		const isStepToEvaluateAtLastStepAvailable =
			listSteps.indexOf(stepToEvaluate) === listSteps.indexOf(lastAvailableStepId);

		if (isStepToEvaluateAtLastStepAvailable) return StepStatus.Available;
		if (isCurrentStepAfterStepToEvaluate || isStepToEvaluateBeforeLastStepAvailable) return StepStatus.Completed;
		return StepStatus.Unavailable;
	};

	const getInnerStepStatus = (parentStepToEvaluate: string, innerStepToEvaluate: string): StepStatus => {
		const listSteps = stepper.map((s) => s.id);

		// error handling
		const parentStep = stepper.find((s) => s.id === parentStepToEvaluate);
		if (!parentStep) return StepStatus.Unavailable;
		const listInnerSteps = parentStep.innerSteps?.map((s) => s.id);
		if (!listInnerSteps) return StepStatus.Unavailable;

		// Current innerStep
		if (listInnerSteps.indexOf(innerStepToEvaluate) === listInnerSteps.indexOf(currentStep.innerStepId))
			return StepStatus.Active;

		// If parentStep il already completed, then the innerStep is too
		const isLastStepAvailableAfterStepToEvaluate =
			listSteps.indexOf(parentStepToEvaluate) < listSteps.indexOf(lastAvailableStepId);
		if (isLastStepAvailableAfterStepToEvaluate) return StepStatus.Completed;

		// --- condition completion
		const isCurrentStepAfterInnerStepToEvaluate =
			listInnerSteps.indexOf(innerStepToEvaluate) < listInnerSteps.indexOf(currentStep.innerStepId);
		const isLastStepAvailableAfterInnerStepToEvaluate =
			listInnerSteps.indexOf(innerStepToEvaluate) <= listInnerSteps.indexOf(lastAvailableInnerStepId);
		return isCurrentStepAfterInnerStepToEvaluate || isLastStepAvailableAfterInnerStepToEvaluate
			? StepStatus.Completed
			: StepStatus.Unavailable;
	};

	return (
		// <Box height={isMobile ? 'calc(100vh-8px)' : 'calc(100vh - 80px)'} overflow="scroll" css={thinScrollbar}>
		<Box height="100%" overflowY="scroll" css={thinScrollbar}>
			<VStack p={isMobile ? '40px 0 40px 24px' : '40px 0 120px 24px'} spacing="0px" align="start">
				{headerTitle && (
					<VStack mb="40px" align="start">
						<Text variant="Text-S-Medium" color="grey.700">
							{headerSubtitle}
						</Text>
						<Text variant="Title-M-Bold" color="grey.900">
							{headerTitle}
						</Text>
					</VStack>
				)}
				{stepper.map((step, idx) => {
					const isLastStep = idx === stepper.length - 1;
					const stepStatus = getStepStatus(step.id);
					const isCurrentStep = step.id === currentStep.id;
					/* if last step, show tick only if opened and has steps */
					const showLastTick = isLastStep && (stepStatus !== 'active' || !step.innerSteps?.length);

					return (
						<Box key={idx}>
							<Step
								onClick={() => {
									if (stepStatus !== StepStatus.Unavailable) {
										setCollapsed(true);
										navigate(step.url);
									}
								}}
								status={stepStatus}
								stepNumber={idx + 1}
								name={step.name}
							/>

							<TickStepBox hideTick={showLastTick} status={stepStatus}>
								{isCurrentStep &&
									step.innerSteps?.map((innerStep, innerIdx) => {
										const innerStepStatus = getInnerStepStatus(step.id, innerStep.id);

										return (
											<InnerStep
												key={innerIdx.toString()}
												onClick={() => {
													if (innerStepStatus !== StepStatus.Unavailable) {
														setCollapsed(true);
														navigate(innerStep.url);
													}
												}}
												status={innerStepStatus}
												name={innerStep.name}
											/>
										);
									})}
							</TickStepBox>
						</Box>
					);
				})}
			</VStack>
		</Box>
	);
};

export default Stepper;
