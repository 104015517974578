import React, { useEffect, useMemo } from 'react';
import { Notification as NotificationIcon } from '@carbon/icons-react';
import {
	Box,
	Button,
	Center,
	Circle,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import { useAppResponsive } from 'hooks/useAppResponsive';
import EventTracker from 'services/events/eventTracker';
import { useGetNotificationsQuery, useUpdateNotificationMutation } from 'services/requests/communication';
import { Notification } from 'store/types/notification.type';

import NotificationItem from './notificationItem';

const Index = () => {
	const isMobile = useAppResponsive({ base: true, md: false });

	const { data } = useGetNotificationsQuery();
	const [setNotificationUpdate] = useUpdateNotificationMutation();
	const { isOpen, onToggle, onClose, onOpen } = useDisclosure();

	const unReads = useMemo(() => {
		if (data) {
			const defaultDate = new Date(0).toISOString();
			return data.filter((el) => el.clickedAt === defaultDate && el.expiredAt === defaultDate);
		}
		return undefined;
	}, [data]);

	const reads = useMemo(() => {
		if (data) {
			const defaultDate = new Date(0).toISOString();
			return data.filter((el) => el.clickedAt !== defaultDate || el.expiredAt !== defaultDate);
		}
		return undefined;
	}, [data]);

	const markAsSeen = async (notification: Notification): Promise<void> => {
		const defaultDate = new Date(0).toISOString();
		if (notification.seenAt !== defaultDate) return;

		const payload = {
			id: notification.id,
			seenAt: new Date().toISOString(),
		};
		await EventTracker.pipedream.notificationSeen(notification);
		setNotificationUpdate(payload);
	};

	const markAsRead = async (notification: Notification): Promise<void> => {
		onToggle();

		const defaultDate = new Date(0).toISOString();
		if (notification.clickedAt !== defaultDate) return;

		const payload = {
			id: notification.id,
			clickedAt: new Date().toISOString(),
		};
		await EventTracker.pipedream.notificationClicked(notification);
		await setNotificationUpdate(payload);
	};

	// /**
	//  * On mount connect to the notification SSE
	//  */
	// useEffect(() => {
	// 	const event = new EventSource(backendConstants.backendUrl + '/communication/notifications/status');
	// 	event.onmessage = (sseData) => {
	// 		const taskInfo = JSON.parse(sseData.data) as { status: 'DONE' | 'ERROR'; error: string | null };
	// 		if (taskInfo.status === 'DONE') refetch();
	// 	};
	//
	// 	return () => event.close();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	/**
	 * Check if a notification is seen for the first time
	 */
	useEffect(() => {
		if (isOpen && unReads) {
			unReads.map((notification: Notification) => {
				markAsSeen(notification);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<>
			{isMobile ? (
				<>
					<Button onClick={onOpen} size="md" bg="transparent" _hover={{ bg: 'gray.200' }}>
						<NotificationIcon size="20" />
						{unReads && unReads.length > 0 && (
							<Circle ml="-1.5" mt="-3" size={2} bg="primary.yellow" className="pulse"></Circle>
						)}
					</Button>

					<Modal isOpen={isOpen} onClose={onClose}>
						<ModalOverlay />
						<ModalContent h="xs">
							<ModalHeader>
								<Text variant="Title-M-SemiBold">Annonces</Text>
							</ModalHeader>
							<ModalCloseButton size="lg" />
							<Tabs>
								<Box paddingX="16px" paddingTop="16px" position="sticky" top="0px" bg="white" rounded="xl">
									<HStack justify="space-between"></HStack>
									<TabList>
										<Tab>
											<Text variant="Text-S-Regular">Non-lues</Text>
											{unReads && unReads.length > 0 && (
												<Text variant="Text-S-Bold" bg="primary.yellow" rounded="sm" ml="12px" px="8px" py="1px">
													{unReads.length}
												</Text>
											)}
										</Tab>
										<Tab>
											<Text variant="Text-S-Regular">Lues</Text>
										</Tab>
									</TabList>
								</Box>
								<ModalBody>
									<TabPanels>
										<TabPanel>
											<VStack align="left" spacing="xs">
												{unReads && unReads.length > 0 ? (
													unReads.map((notification: Notification) => (
														<NotificationItem
															key={notification.id}
															notification={notification}
															markAsRead={markAsRead}
														/>
													))
												) : (
													<Center h="150" color="white">
														<Text color="grey.700" variant="Text-M-Regular">
															Vous n'avez pas de nouvelles notifications.
														</Text>
													</Center>
												)}
											</VStack>
										</TabPanel>
										<TabPanel>
											<VStack align="left" spacing="xs">
												{reads &&
													reads.map((notification: Notification) => (
														<NotificationItem
															key={notification.id}
															notification={notification}
															markAsRead={markAsRead}
														/>
													))}
											</VStack>
										</TabPanel>
									</TabPanels>
								</ModalBody>
							</Tabs>
						</ModalContent>
					</Modal>
				</>
			) : (
				<>
					<Popover placement="auto-start" isOpen={isOpen} onClose={onClose}>
						<PopoverTrigger>
							<Button onClick={onToggle} size="md" bg="transparent" _hover={{ bg: 'gray.200' }}>
								<NotificationIcon size="20" />
								{unReads && unReads.length > 0 && (
									<Circle ml="-1.5" mt="-3" size={2} bg="primary.yellow" className="pulse"></Circle>
								)}
							</Button>
						</PopoverTrigger>

						<PopoverContent position="relative" border="none" rounded="xl" shadow="lg" h="xs" minW="md">
							<Tabs>
								<Box paddingX="16px" paddingTop="16px" position="sticky" top="0px" bg="white" rounded="xl">
									<HStack justify="space-between">
										<PopoverHeader border="none">
											<Text variant="Title-M-SemiBold">Annonces</Text>
										</PopoverHeader>
										<PopoverCloseButton position="relative" size="lg" />
									</HStack>
									<TabList>
										<Tab>
											<Text variant="Text-S-Regular">Non-lues</Text>
											{unReads && unReads.length > 0 && (
												<Text variant="Text-S-Bold" bg="primary.yellow" rounded="sm" ml="12px" px="8px" py="1px">
													{unReads.length}
												</Text>
											)}
										</Tab>
										<Tab>
											<Text variant="Text-S-Regular">Lues</Text>
										</Tab>
									</TabList>
								</Box>

								<PopoverBody px="12px" paddingBottom="16px">
									<TabPanels>
										<TabPanel>
											<VStack align="left" spacing="xs">
												{unReads && unReads.length > 0 ? (
													unReads.map((notification: Notification) => (
														<NotificationItem
															key={notification.id}
															notification={notification}
															markAsRead={markAsRead}
														/>
													))
												) : (
													<Center h="150" color="white">
														<Text color="grey.700" variant="Text-M-Regular">
															Vous n'avez pas de nouvelles notifications.
														</Text>
													</Center>
												)}
											</VStack>
										</TabPanel>
										<TabPanel>
											<VStack align="left" spacing="xs">
												{reads &&
													reads.map((notification: Notification) => (
														<NotificationItem
															key={notification.id}
															notification={notification}
															markAsRead={markAsRead}
														/>
													))}
											</VStack>
										</TabPanel>
									</TabPanels>
								</PopoverBody>
							</Tabs>
						</PopoverContent>
					</Popover>
				</>
			)}
		</>
	);
};

export default Index;
