import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ColoredBspce from 'assets/icons/ProductsYellowColoredIcons/Bspce.svg';
import ColoredLivret from 'assets/icons/ProductsYellowColoredIcons/Cash.svg';
import ColoredScpi from 'assets/icons/ProductsYellowColoredIcons/Immo.svg';
import ColoredPe from 'assets/icons/ProductsYellowColoredIcons/Pe.svg';
import useGotoCashOnboarding from 'hooks/useGotoCashOnboarding';
import { isNotNone } from 'utils/functions';

import { IntestedProducts } from '../Modals/Calendly/useBuildIframeUrl';

import { HeaderProductPageProps, VariantHeaderProductPage } from './index';

const getVariant = (
	variant: VariantHeaderProductPage,
	onCalendly: () => void,
	navigate: ReturnType<typeof useNavigate>,
	openedSavingsAccount: boolean,
	onCashVersement: () => void,
	gotoCashOnboarding: () => void,
): HeaderProductPageProps | undefined => {
	if (variant === 'Private Equity')
		return {
			title: 'Private Equity',
			icon: ColoredPe,
			menu: {
				items: [
					{
						label: 'Faire une simulation',
						onClick: () => navigate('/private-equity/simulation/nouvelle'),
					},
					{
						label: 'Découvrir le catalogue des fonds',
						onClick: () => navigate('/private-equity'),
					},
					{
						label: 'Echanger avec un conseiller',
						onClick: onCalendly,
					},
				],
				label: 'Investir',
			},
			calendlyProduct: IntestedProducts.PE,
		};

	if (variant === 'SCPI')
		return {
			title: 'Immobilier',
			icon: ColoredScpi,
			menu: {
				items: [
					{
						label: 'Faire une simulation',
						onClick: () => navigate('/immobilier/simulation/nouvelle'),
					},
					{
						label: 'Découvrir le catalogue des fonds',
						onClick: () => navigate('/immobilier'),
					},
					{
						label: 'Echanger avec un conseiller',
						onClick: onCalendly,
					},
				],
				label: 'Investir',
			},
			calendlyProduct: IntestedProducts.SCPI,
		};

	if (variant === 'Cash')
		return {
			title: 'Cash',
			icon: ColoredLivret,
			menu: {
				items: [
					openedSavingsAccount
						? {
								label: 'Faire un versement sur le livret',
								onClick: onCashVersement,
						  }
						: {
								label: 'Ouvrir un livret épargne',
								onClick: gotoCashOnboarding,
						  },
					{
						label: 'Echanger avec un conseiller',
						onClick: onCalendly,
					},
				].filter(isNotNone),
				label: 'Investir',
			},
			calendlyProduct: IntestedProducts.CASH,
		};

	if (variant === 'BSPCE')
		return {
			title: 'BSPCE',
			icon: ColoredBspce,
			calendlyProduct: IntestedProducts.UNKNOWN,
		};
};

// todo rework this monstrosity
export const useHeaderProductVariant = (
	variant: VariantHeaderProductPage,
	onCalendly: () => void,
	openedSavingsAccount: boolean,
	onCashVersement: () => void,
) => {
	const navigate = useNavigate();
	const { goToOnboarding: gotoCashOnboarding } = useGotoCashOnboarding();

	return useMemo(
		() => getVariant(variant, onCalendly, navigate, openedSavingsAccount, onCashVersement, gotoCashOnboarding),
		[variant, onCalendly, navigate, openedSavingsAccount, onCashVersement, gotoCashOnboarding],
	);
};
