import { useMemo } from 'react';
import { Box, Card, Heading, HStack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import { displayMoneyNoDigits, displayPercentage } from 'utils/rendering';

type PeSimulationMetricsProps = {
	moic: number;
	taxReduction: number;
	internalReturn: number;
};

const PeSimulationMetrics = ({ moic, taxReduction, internalReturn }: PeSimulationMetricsProps) => {
	const metrics = useMemo(
		() => [
			{
				title: "Réductions d'impôt",
				metric: displayMoneyNoDigits(taxReduction),
				label: 'Estimation',
				tooltip:
					'Réduction d’impôt relative au produit FCPI, ne prend pas en compte les potentielles exonérations d’impôt sur les plus values.',
			},
			{
				title: (
					<HStack align="center">
						<Text>
							MOIC <b>(Multiple sur Capital Investi)</b>
						</Text>
					</HStack>
				),
				tooltip:
					"Le multiple sur capital investi correspond au ratio entre le capital généré par le fonds et le capital investi. Un multiple de x2 sur un investissement signifie que la valorisation de l'investissement est le double du capital investi.",
				metric: `X ${moic.toFixed(1)}`,
				label: 'Net de frais et impôt',
			},
			{
				title: (
					<HStack align="center">
						<Text>
							TRI <b>(Taux de Rentabilité Interne)</b>
						</Text>
					</HStack>
				),
				tooltip:
					"Le taux de rentabilité interne (TRI) est un indicateur important qui prend en compte tous les flux (achats, ventes, revenus, frais, fiscalité...) et ramène tout sur un rendement annuel. C'est un indicateur qui est donc avant tout financier, une aide à la décision avant tout investissement. ",
				metric: displayPercentage(internalReturn),
				label: 'Net de frais et impôt',
			},
		],
		[moic, taxReduction, internalReturn],
	);

	return (
		<Wrap w="100%" overflow="visible" spacing="16px">
			{metrics.map(({ title, metric, label, tooltip }) => (
				<WrapItem key={metric} flex="1" minW="280px">
					<Card w="100%" size="sm">
						<VStack spacing="16px" align="start" w="100%">
							<HStack align="start" w="100%" justify="space-between">
								<Heading variant="Title-S-SemiBold">{title}</Heading>
								<Box>{tooltip && <CustomTooltip text={tooltip} />}</Box>
							</HStack>
							<HStack>
								<Heading variant="Title-M-Bold" color="blue.900">
									{metric}
								</Heading>
								{label && <Text color="grey.900">{label}</Text>}
							</HStack>
						</VStack>
					</Card>
				</WrapItem>
			))}
		</Wrap>
	);
};

export default PeSimulationMetrics;
