import { useState } from 'react';
import { Document } from '@carbon/icons-react';
import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Button, Card, Divider, Heading, HStack, Skeleton, Stack, Text, VStack } from '@chakra-ui/react';
import { DotSpinner } from '@uiball/loaders';
import dayjs from 'dayjs';

import { useAppResponsive } from 'hooks/useAppResponsive';
import { useDownloadBulletin } from 'hooks/useDownload';
import { ListContractDocumentsResponse, useListContractDocumentsQuery } from 'services/requests/invest/contracts';
import { InvestContract } from 'store/types/contract.type';
import colors from 'theme/foundations/colors';
import api from 'utils/api';
import { isNone } from 'utils/functions';

const ContractDocuments = ({
	contractId,
	subscriptionId,
}: {
	contractId: InvestContract['id'];
	subscriptionId: InvestContract['subscriptionId'];
}) => {
	const { data: documentsData, isLoading } = useListContractDocumentsQuery({ contractId: contractId });
	const [documentDownloading, setDocumentDownloading] = useState<string>();
	const isDesktop = useAppResponsive({ base: false, md: true });
	const [isBulletinDownloading, downloadBulletin] = useDownloadBulletin();

	if (isLoading) {
		return (
			<Card w="100%">
				<VStack w="100%" spacing="24px" align="start">
					<Text variant="label">Documents</Text>
					<Skeleton w="100%" h="96px" />
				</VStack>
			</Card>
		);
	}

	const documentsGroupedByYear = documentsData?.content.reduce((acc, document) => {
		if (document.codeTypeDoc === '7041') {
			// Apicil messages, ignore them
			return acc;
		}
		const year = dayjs(document.dateNumerisation, 'DD/MM/YYYY').year();
		if (!acc[year]) {
			// eslint-disable-next-line no-param-reassign
			acc[year] = [];
		}
		acc[year].push(document);
		return acc;
	}, {} as { [year: string]: ListContractDocumentsResponse['content'] });
	const documentsGroupedSorted = Object.entries(documentsGroupedByYear ?? {})
		.map(([year, docs]) => ({ year: Number(year), documents: docs }))
		.sort((a, b) => b.year - a.year);

	const onDownload = (docId: string) => {
		if (documentDownloading === undefined) {
			setDocumentDownloading(docId);
			api.downloadContractDocument(contractId, docId).finally(() => setDocumentDownloading(undefined));
		}
	};

	return (
		<VStack align="start" spacing="24px" w="100%">
			<Heading variant="Title-L-SemiBold">Documents du contrat </Heading>
			{/* Static contract document */}
			<Stack direction={isDesktop ? 'row' : 'column'} justify="space-between" w="100%" py="8px">
				<HStack spacing="16px" w="100%">
					<Document size="32" />
					<VStack spacing="4px" align="start">
						<Text variant="Text-M-Medium" color={colors.primary.black}>
							Bulletin de souscription{' '}
						</Text>
						<Text variant="Text-S-Medium" color="grey.900">
							Document qui détaille l’ensemble de votre souscription
						</Text>
					</VStack>
				</HStack>

				<Button
					variant="secondary"
					p="16px 24px"
					isDisabled={isBulletinDownloading || isNone(subscriptionId)}
					rightIcon={isBulletinDownloading ? <DotSpinner color={colors.grey[700]} size={16} /> : <DownloadIcon />}
					onClick={() => {
						downloadBulletin(subscriptionId!);
					}}
				>
					Télecharger
				</Button>
			</Stack>

			{/* Documents resolved via API */}
			{documentsGroupedSorted.map(({ year, documents }, idxYear) => (
				<VStack key={idxYear} w="100%" spacing="24px" align="start">
					<Heading variant="Title-M-Bold">{year}</Heading>
					{documents.map((document: ListContractDocumentsResponse['content'][number], idxDocument) => (
						<VStack key={document.docId} w="100%" spacing="24px" align="start">
							{/* Responsive here */}
							<Stack direction={isDesktop ? 'row' : 'column'} justify="space-between" w="100%">
								<HStack spacing="16px" w="100%">
									<Document size="32" />
									<VStack spacing="8px" align="start">
										<Text variant="Text-M-Medium" color={colors.primary.black}>
											{document.dateNumerisation}
										</Text>
										<Text variant="Text-S-Medium" color="grey.900">
											{document.sousCategorieDocument}
										</Text>
									</VStack>
								</HStack>
								<Button
									variant="secondary"
									p="16px 24px"
									rightIcon={
										document.docId === documentDownloading ? (
											<DotSpinner color={colors.grey[700]} size={16} />
										) : (
											<DownloadIcon />
										)
									}
									isDisabled={document.docId === documentDownloading}
									onClick={() => onDownload(document.docId)}
								>
									Télecharger
								</Button>
							</Stack>
							{(idxDocument !== documents.length - 1 || idxYear !== documentsGroupedSorted.length - 1) && <Divider />}
						</VStack>
					))}
				</VStack>
			))}
		</VStack>
	);
};

export default ContractDocuments;
