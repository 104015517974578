import { Card, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { displayMoney } from 'utils/rendering';

type PerEconomyLastTwoYearsProps = {
	introText: string;
	amountCurrentYear: number;
	amountNextYear: number;
	initialAumPer: number;
	reccuringAumPer: number;
};

const PerEconomyLastTwoYears = ({
	introText,
	amountCurrentYear,
	amountNextYear,
	initialAumPer,
	reccuringAumPer,
}: PerEconomyLastTwoYearsProps) => {
	const data = [
		{
			year: new Date().getFullYear(),
			value: amountCurrentYear,
			initialAumPer,
			reccuringAumPer,
		},
		{
			year: new Date().getFullYear() + 1,
			value: amountNextYear,
			reccuringAumPer,
		},
	];
	return (
		<VStack spacing="24px">
			<Text variant="Text-M-Regular" color="grey.900">
				{introText}
			</Text>
			<Wrap direction={{ base: 'column', md: 'row' }} w="100%" spacing="40px">
				{data.map((item) => (
					<WrapItem key={item.year}>
						<Card h="100%" borderColor="grey.700">
							<VStack align="start" spacing="24px">
								<Text variant="Title-M-SemiBold">{item.year}</Text>
								<VStack align="start">
									<Text variant="Text-M-Regular" color="grey.900">
										Vous allez réduire votre impôt sur le revenu de
									</Text>
									<Text variant="Text-M-Bold" color="blue.900">
										{displayMoney(item.value)}{' '}
										<Text as="span" variant="label" color="inherit">
											sur {new Date().getFullYear() === item.year ? "l'année en cours." : 'l’année prochaine.'}
										</Text>
									</Text>
								</VStack>
								{item.initialAumPer && (
									<VStack align="start">
										<Text variant="Text-M-Regular" color="grey.900">
											Versement initial (PER)
										</Text>
										<Text variant="Text-M-Bold">{displayMoney(item.initialAumPer)}</Text>
									</VStack>
								)}
								<VStack align="start">
									<Text variant="Text-M-Regular" color="grey.900">
										Versements programmés (mensuels)
									</Text>
									<Text variant="Text-M-Bold">{displayMoney(item.reccuringAumPer)}</Text>
								</VStack>
							</VStack>
						</Card>
					</WrapItem>
				))}
			</Wrap>
		</VStack>
	);
};

export default PerEconomyLastTwoYears;
