import { createRef, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Card,
	chakra,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Select,
	Text,
	useBoolean,
	VStack,
	Wrap,
} from '@chakra-ui/react';

import NumberInput from 'components/inputs/NumberInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { SouscriptionStepProps } from 'onboarding/Stepper/stepper.slice';
import { UpdateInvestSubscriptionVersement } from 'services/requests/invest/subscription';
import { useGetUserQuery } from 'services/requests/user';
import { versementMinMax } from 'services/requests/versement';
import { FundOriginType, PeriodicityType } from 'store/types/subscription.type';
import api from 'utils/api';
import { typeFormConstants } from 'utils/constants';
import { displayMoneyNoDigits } from 'utils/rendering';

const fundOriginOptions = Object.entries({
	PROFESSIONAL_INCOME: 'Revenus professionnels',
	REAL_ESTATE: 'Vente immobilière',
	SUCCESSION_DONATION: 'Succession ou donation',
	ASSET_SELLOUT: "Cession d'actifs",
	GOODS_SELLOUT: 'Bien Mobiliers',
} as Record<FundOriginType, string>) as [FundOriginType, string][];

type VersementType = Omit<UpdateInvestSubscriptionVersement, 'recurrentDepositperiod'>;

const Versements = ({
	subscription,
	onNext,
	onPrev,
}: SouscriptionStepProps<UpdateInvestSubscriptionVersement>): JSX.Element => {
	const { data: user } = useGetUserQuery();

	const [noProgrammedVersement, setNoProgrammedVersement] = useState(
		+subscription.recurrentDepositAmount === 0 ? true : false,
	);

	const [minSavingError, setMinSavingError] = useState(false);
	// transfert
	const [transfert, { toggle: toggleTransfert }] = useBoolean();
	const [transfertUrl, setTransfertUrl] = useState<string>();
	useEffect(() => {
		if (transfert && !transfertUrl && user) {
			api.getOperationToken('transfert').then((token) => {
				const query = new URLSearchParams({ email: user.email, token });
				// eventTracker.askPERTransfer();
				setTransfertUrl(`${typeFormConstants.transfertUrl}#${query}`);
			});
		}
	}, [user, transfert, transfertUrl]);

	const formRef = createRef<HTMLFormElement>();
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<VersementType>({
		shouldUnregister: true,
		defaultValues: {
			fundOriginType: subscription.fundOriginType,
			initialDepositAmount: +subscription.initialDepositAmount,
			recurrentDepositAmount: +subscription.recurrentDepositAmount,
		},
	});

	const onSubmit = handleSubmit((data) => {
		if (data.recurrentDepositAmount > 0 && data.recurrentDepositAmount < 50) {
			setMinSavingError(true);
		} else {
			onNext({
				fundOriginType: data.fundOriginType,
				initialDepositAmount: data.initialDepositAmount,
				recurrentDepositAmount: noProgrammedVersement ? 0 : data.recurrentDepositAmount,
				recurrentDepositperiod: PeriodicityType.MONTHLY,
			});
		}
	});

	const minimum: number = versementMinMax[subscription.envelope.partnerCode].minVP.mensuel;

	return (
		<form onSubmit={onSubmit} ref={formRef}>
			<VStack align="start" w="100%" spacing="48px" padding="0 0 48px 0">
				<VStack align="start" w="100%" spacing="16px">
					<Heading variant="Title-XL-Bold">Versement initial</Heading>
					<Text variant="Text-M-Regular" color="grey.900">
						Le versement initial est le montant qui sera versé la première fois sur votre contrat lors de son ouverture.
					</Text>

					<Wrap spacing="24px" width="100%">
						<FormControl w={onboardingInputWidth} isInvalid={!!errors.initialDepositAmount}>
							<FormLabel>Montant du versement initial</FormLabel>
							<Controller
								control={control}
								name="initialDepositAmount"
								rules={{ required: true, min: 1000 }}
								render={({ field }) => <NumberInput {...field} placeholder="Min : 1000€" suffix=" €" />}
							/>

							{errors.initialDepositAmount?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
							{errors.initialDepositAmount?.type === 'min' && (
								<FormErrorMessage>
									L'ouverture d'un contrat nécéssite un versement initial d'un montant minimum de 1000€
								</FormErrorMessage>
							)}
						</FormControl>
						<FormControl w={onboardingInputWidth} isInvalid={!!errors.fundOriginType}>
							<FormLabel>Origine des fonds</FormLabel>
							<Select
								w="100%"
								placeholder="-"
								data-cy="fundOriginType"
								{...register('fundOriginType', { required: true })}
							>
								{fundOriginOptions.map(([name, label]) => (
									<option key={name} value={name}>
										{label}
									</option>
								))}
							</Select>
							{errors.fundOriginType?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
					</Wrap>
				</VStack>

				<VStack align="start" spacing="16px" w="100%">
					<Text variant="Title-M-Bold">Versements programmés mensuels</Text>
					<Text variant="Text-M-Regular" color="grey.900">
						Vous pouvez si vous le souhaitez programmer un versement qui aura lieu sur votre contrat tous les mois entre
						le 10 et le 12. Les versements programmés ne seront pas prélevés lors du premier mois suivant l’ouverture du
						contrat.
					</Text>
					<VStack align="start" w={{ base: '100%', lg: '320px' }}>
						{!noProgrammedVersement && (
							<VStack w="100%">
								<FormControl isInvalid={!!errors.recurrentDepositAmount}>
									<FormLabel>Montant du versement programmé mensuel</FormLabel>
									<Controller
										control={control}
										name="recurrentDepositAmount"
										rules={{ required: true, min: minimum }}
										render={({ field }) => (
											<NumberInput {...field} placeholder={`Min ${displayMoneyNoDigits(minimum)}`} suffix=" €" />
										)}
									/>
									{errors.recurrentDepositAmount?.type === 'required' && (
										<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
									)}
									{(errors.recurrentDepositAmount?.type === 'min' || minSavingError) && (
										<FormErrorMessage>La valeur minimum d'un versement programmé est de {minimum}€</FormErrorMessage>
									)}
								</FormControl>
							</VStack>
						)}
						<HStack align="center">
							<Checkbox
								size="lg"
								margin="5px"
								onChange={() => {
									setNoProgrammedVersement(!noProgrammedVersement);
									setMinSavingError(false);
								}}
								isChecked={noProgrammedVersement}
								data-cy="cy-is-saving"
							>
								<Text variant="Text-S-Regular">Je ne souhaite pas épargner mensuellement pour l'instant</Text>
							</Checkbox>
						</HStack>
					</VStack>
				</VStack>

				{subscription.envelope.type === 'PER' && (
					<VStack align="start" w="100%" spacing="16px">
						<Heading variant="Title-M-Bold">Transfert de contrat</Heading>
						<Text variant="Text-M-Regular" color="grey.900">
							Vous possédez un contrat (PER, PERP, Madelin, Art 83, PEROB, PERCO...) auprès d'un autre établissement
							financier ? Vous souhaitez transférer les fonds de ce contrat chez Ramify ? Vous pouvez le faire en
							remplissant une simple demande de transfert !
						</Text>

						<Card size="sm" variant="filled" bg="yellow.100">
							<Checkbox isChecked={transfert} onChange={toggleTransfert} size="lg">
								<Text variant="Text-S-Regular">Transférer un contrat</Text>
							</Checkbox>
						</Card>
						{transfert && transfertUrl && (
							<VStack spacing="16px">
								<Text variant="Text-M-Regular" color="grey.900">
									Merci de remplir le formulaire ci-dessous. Nous prendrons contact avec vous dans le cas d'informations
									manquantes pour finaliser votre demande.
								</Text>
								<chakra.iframe title="formulaire typeform transfert" src={transfertUrl} w="100%" h="800px" />
							</VStack>
						)}
					</VStack>
				)}

				<BottomStepBar nextSubmit onPrev={onPrev} />
			</VStack>
		</form>
	);
};

export default Versements;
