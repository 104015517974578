import { Controller, useFormContext } from 'react-hook-form';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import MultipleCheckboxSelect from 'components/inputs/MultipleCheckboxSelect';
import { ControlledNumberInput } from 'components/inputs/NumberInput';
import ControlledRadioOptions from 'components/inputs/RadioOptions';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import { RealEstateSimulationDto } from 'services/requests/realEstate/simulations';

const investmentModeOptions = [
	{
		value: 'nu',
		label: 'Nue propriété',
	},
	{
		value: 'plein',
		label: 'Pleine propriété',
	},
	{
		value: 'ramify',
		label: 'Je fais confiance à Ramify',
	},
] as {
	value: RealEstateSimulationDto['prefDemembrement'];
	label: string;
}[];

const GeographieOptions = [
	{
		value: 'Idf',
		label: 'Île-de-France',
	},
	{
		value: 'Etranger',
		label: 'Europe (hors France) ',
	},
	{
		value: 'Régions',
		label: 'France (hors Île-de-France)',
	},
] as {
	value: RealEstateSimulationDto['prefGeographie'][number];
	label: string;
}[];

const SecteurOptions = [
	{
		value: 'Bureaux',
		label: 'Bureaux',
	},
	{
		value: 'Commerces',
		label: 'Commerces',
	},
	{
		value: 'Résidentiel',
		label: 'Résidentiel',
	},
	{
		value: 'Santé',
		label: 'Santé',
	},
] as {
	value: RealEstateSimulationDto['prefSecteur'][number];
	label: string;
}[];

export const MontantInvestissement = (): JSX.Element => {
	const {
		control,
		formState: { errors },
	} = useFormContext<{ initialAUM: number }>();

	return (
		<FormControl w={onboardingInputWidth} isInvalid={!!errors.initialAUM}>
			<FormLabel>Quel est votre montant d'investissement ?</FormLabel>

			<ControlledNumberInput control={control} name="initialAUM" rules={{ required: true, min: 1000 }} />

			{errors.initialAUM?.type === 'required' && <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>}
			{errors.initialAUM?.type === 'min' && (
				<FormErrorMessage>Le montant initial doit être supérieur à 1 000 €</FormErrorMessage>
			)}
		</FormControl>
	);
};

export const TimeHorizon = (): JSX.Element => {
	const {
		register,
		formState: { errors },
	} = useFormContext<{ timeHorizon: number }>();

	return (
		<FormControl w={onboardingInputWidth} isInvalid={!!errors.timeHorizon}>
			<FormLabel>Durée maximum de l'épargne</FormLabel>
			<InputGroup>
				<Input
					{...register('timeHorizon', { required: true, min: 4, max: 20, valueAsNumber: true })}
					type="number"
					onWheel={(event) => event.currentTarget.blur()}
					placeholder="4 - 20"
					min={4}
					max={20}
				/>
				<InputRightElement w="128px">ans maximum</InputRightElement>
			</InputGroup>
			{errors.timeHorizon?.type === 'min' && (
				<FormErrorMessage>La durée minimale d'investissement est de 4 ans</FormErrorMessage>
			)}
			{errors.timeHorizon?.type === 'max' && (
				<FormErrorMessage>La durée maximale d'investissement est de 20 ans</FormErrorMessage>
			)}
			{errors.timeHorizon?.type === 'required' && <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>}
		</FormControl>
	);
};

export const ISRPreferences = (): JSX.Element => {
	const { control } = useFormContext();

	return (
		<FormControl>
			<FormLabel>
				<HStack>
					<Text variant="Text-S-Medium">
						Souhaitez-vous que votre portefeuille soit exclusivement constitué de fonds labellisés ISR ?
					</Text>
					<CustomTooltip text="Le label ISR est un label français créé en 2016 par le ministère de l'Économie et des Finances, dont l'objectif est d'offrir une meilleure visibilité aux fonds d'investissement respectant les principes de l'investissement socialement responsable, autorisés à la commercialisation en France." />
				</HStack>
			</FormLabel>
			<ControlledRadioOptions w={onboardingInputWidth} control={control} name="isISR" options="Yes-No" />
		</FormControl>
	);
};

export const NuePropertyPreferences = (): JSX.Element => {
	const { control } = useFormContext();

	return (
		<FormControl>
			<FormLabel>
				<HStack>
					<Text variant="Text-S-Medium">Avez-vous un mode d'investissement préférentiel ?</Text>
					<CustomTooltip text="L'option 'Je fais confiance à Ramify' optimise vos gains espérés selon votre profil." />
				</HStack>
			</FormLabel>
			<ControlledRadioOptions
				w={onboardingInputWidth}
				control={control}
				name="prefDemembrement"
				options={investmentModeOptions}
			/>
		</FormControl>
	);
};

export const Preferences = (): JSX.Element => {
	const {
		control,
		formState: { errors },
	} = useFormContext<{
		prefSecteur: RealEstateSimulationDto['prefSecteur'];
		prefGeographie: RealEstateSimulationDto['prefGeographie'];
	}>();

	return (
		<Wrap w="100%" spacing="24px">
			{/* <Text variant="label">Avez-vous des préférences d'investissement spécifiques ?</Text> */}
			<WrapItem>
				<FormControl isInvalid={!!errors.prefSecteur}>
					<FormLabel>Préfèrences géographiques (optionnel)</FormLabel>

					<Controller
						control={control}
						name="prefGeographie"
						render={({ field: { onChange, value } }) => (
							<MultipleCheckboxSelect onChange={onChange} value={value} options={GeographieOptions} />
						)}
					/>
				</FormControl>
			</WrapItem>
			<WrapItem>
				<FormControl isInvalid={!!errors.prefSecteur}>
					<FormLabel>Préfèrences sectorielles (optionnel)</FormLabel>

					<Controller
						control={control}
						name="prefSecteur"
						render={({ field: { onChange, value } }) => (
							<MultipleCheckboxSelect onChange={onChange} value={value} options={SecteurOptions} />
						)}
					/>
				</FormControl>
			</WrapItem>
		</Wrap>
	);
};
