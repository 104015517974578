import { backendApi } from 'services/apiService';
import { UserScpiKyc, UserScpiKycTest } from 'store/types/scpiKyc.type';

import { SubmitSCPISubscription } from './realEstate/subscriptions';

type SignLink = { signature_link: string; signature_request_id: string };

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		updatePhoenixScpiKycAndTest: builder.mutation<
			void,
			UserScpiKycTest & Pick<UserScpiKyc, 'riskPerception' | 'lengthPerception'>
		>({
			query: (body) => ({
				url: '/phoenix/update-kyc-test',
				method: 'POST',
				body: body,
			}),
		}),
		getSignedDocumentStatus: builder.query<{ fundOrigin: boolean; mandat: boolean; fatca: boolean }, string>({
			query: (subscriptionId) => ({
				url: `/phoenix/documents-status/${subscriptionId}`,
				method: 'GET',
			}),
		}),
		createPhoenixDocumentsSignLink: builder.query<SignLink, string>({
			query: (subscriptionId) => ({
				url: `/phoenix/generate-documents-signature/${subscriptionId}`,
				method: 'GET',
			}),
		}),
		submitPhoenixSubscription: builder.mutation<void, Omit<SubmitSCPISubscription, 'jof'>>({
			query: (body) => ({
				url: '/phoenix/submit-subscription',
				method: 'POST',
				body: body,
			}),
			invalidatesTags: ['SCPI_SUBSCRIPTIONS'],
		}),
	}),
});

export const {
	useCreatePhoenixDocumentsSignLinkQuery,
	useSubmitPhoenixSubscriptionMutation,
	useUpdatePhoenixScpiKycAndTestMutation,
	useGetSignedDocumentStatusQuery,
} = extendedApi;
