import { MainStepType, OnboardingStep } from 'onboarding/Stepper/stepper.slice';

import { CashOnboardingSteps, souscriptionStep } from './Souscription/steps';

export const projectStep: OnboardingStep<CashOnboardingSteps> = {
	id: CashOnboardingSteps.PROJECT,
	name: 'Mon projet',
	url: 'projet',
	type: MainStepType.START,
};

export const kycStep: OnboardingStep<CashOnboardingSteps> = {
	id: CashOnboardingSteps.KYC,
	name: 'Informations personnelles',
	url: 'connaissance-client',
	type: MainStepType.COURSE,
	innerSteps: [
		{
			id: 'etatCivil',
			name: 'État civil',
			url: 'connaissance-client/etatCivil',
		},
		{
			id: 'foyerFiscal',
			name: 'Foyer fiscal',
			url: 'connaissance-client/foyerFiscal',
		},
		{
			id: 'professionnelle',
			name: 'Situation professionnelle',
			url: 'connaissance-client/professionelle',
		},
		{
			id: 'coordonnees',
			name: 'Coordonnées',
			url: 'connaissance-client/coordonnees',
		},
		{
			id: 'patrimoine',
			name: 'Patrimoine',
			url: 'connaissance-client/patrimoine',
		},
		{
			id: 'justificatif',
			name: 'Justificatifs',
			url: 'connaissance-client/justificatif',
		},
		{
			id: 'recapitulatif',
			name: 'Validation',
			url: 'connaissance-client/recapitulatif',
		},
	],
};

export const confirmationStep: OnboardingStep<CashOnboardingSteps> = {
	id: CashOnboardingSteps.CONFIRMATION,
	type: MainStepType.END,
	name: 'Confirmation',
	url: 'confirmation',
};

const steps: OnboardingStep<CashOnboardingSteps>[] = [projectStep, kycStep, souscriptionStep, confirmationStep];

export default steps;
