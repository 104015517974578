import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import { Select } from '@chakra-ui/react';

interface ReferentielInputProps {
	register: UseFormRegister<any>;
	options?: RegisterOptions<any>;
	name: string;
	'data-cy'?: string;
	referentiel: Record<any, string>;
}

const ReferentielInput = ({ register, name, referentiel, options, ...rest }: ReferentielInputProps): JSX.Element => {
	const dataCy = rest['data-cy'];

	return (
		<Select {...register(name, options)} placeholder="-" data-cy={dataCy}>
			{Object.entries(referentiel).map(([key, libelle]) => (
				<option value={key} key={key}>
					{libelle}
				</option>
			))}
		</Select>
	);
};

export default ReferentielInput;
