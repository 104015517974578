import { DownloadIcon } from '@chakra-ui/icons';
import { Button, ButtonProps, Text } from '@chakra-ui/react';

export const DownloadButton = ({
	name,
	onClick,
	handleHover,
	leftIcon,
	...props
}: {
	name: string;
	handleHover?: () => void;
} & ButtonProps) => (
	<Button
		{...props}
		leftIcon={leftIcon ?? <DownloadIcon />}
		iconSpacing="16px"
		variant="tertiary"
		h="40px"
		size="lg"
		isDisabled={props.isDisabled || !onClick}
		onClick={onClick}
		onMouseEnter={handleHover}
		onMouseLeave={handleHover}
	>
		<Text variant="label">{name}</Text>
	</Button>
);
