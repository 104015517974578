import { AgricultureAnalytics } from '@carbon/icons-react';
import { Card, Heading, Icon, Text, VStack } from '@chakra-ui/react';

import { ImmobilierSubscriptionMetrics } from 'services/requests/realEstate';
import { displayMoney } from 'utils/rendering';

const CapitalValueCard = ({ subscription }: { subscription: ImmobilierSubscriptionMetrics }) => (
	<Card w="100%" h="100%">
		<VStack w="100%" align="start" h="100%" spacing="16px">
			<Icon boxSize="32px" as={AgricultureAnalytics} />

			<VStack w="100%" align="start">
				<Text variant="Text-M-Medium" color="grey.900">
					Valeur du capital
				</Text>
				<Heading variant="Title-L-Bold">{displayMoney(subscription.capital_valorisation)}</Heading>
			</VStack>
		</VStack>
	</Card>
);

export default CapitalValueCard;
