import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import eventTracker from 'services/events/eventTracker';

interface AuthState {
	isAuth: boolean;
	token: string | null;
}

const initialState: AuthState = {
	isAuth: !!localStorage.getItem('token'),
	token: localStorage.getItem('token'),
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setToken(state, action: PayloadAction<string>) {
			state.token = action.payload;
		},
		setIsAuth(state, action: PayloadAction<boolean>) {
			state.isAuth = action.payload;
		},
		logout(state) {
			state.isAuth = false;
			state.token = null;
			localStorage.removeItem('token');
			eventTracker.stopSession();
		},
	},
});

export const { setIsAuth, setToken, logout } = authSlice.actions;

export default authSlice.reducer;
