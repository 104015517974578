import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
	Button,
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	Text,
	VStack,
} from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import { isNone } from 'utils/functions';

import { FormContentBecomeBlack } from '.';

export const FormStep2: FC<{ onClose: () => void; onSuccess: (data: FormContentBecomeBlack) => void }> = ({
	onClose,
	onSuccess,
}) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useFormContext<FormContentBecomeBlack>();

	const onSubmit = handleSubmit((data) => onSuccess(data));

	return (
		<Modal size="6xl" isOpen onClose={onClose}>
			<ModalOverlay />
			<ModalContent bg="primary.black" h="540px">
				<chakra.form onSubmit={onSubmit}>
					<ModalHeader>
						<Text variant="title-M-Bold" color="white">
							Ramify Black
						</Text>
						<Text variant="Caption" color="primary.yellow">
							DEVENIR MEMBRE
						</Text>
					</ModalHeader>
					<ModalCloseButton color="white" />
					<VStack align="center">
						<ModalBody>
							<VStack w="100%" spacing="32px">
								<FormControl isInvalid={!!errors.amount}>
									<FormLabel color="white">Quel est votre objectif ?</FormLabel>
									<Controller
										name="amount"
										control={control}
										rules={{
											validate: {
												required: (value) => (isNone(value) ? 'Ce champ est requis' : true),
											},
										}}
										render={({ field: { onChange, value, ...rest } }) => (
											<VStack w="100%">
												<RadioGroup
													onChange={(nextValue) => {
														onChange(Number(nextValue));
													}}
													variant="dark"
													{...rest}
												>
													<VStack spacing="8px" w="400px">
														<Radio value="1000">Transmettre un patrimoine</Radio>
														<Radio value="20000">Préparer ma retraite</Radio>
														<Radio value="100000">Investissement professionnel</Radio>
														<Radio value="0">Construction d’un portfolio sur-mesure</Radio>
													</VStack>
												</RadioGroup>
											</VStack>
										)}
									/>
									{errors.amount && <FormErrorMessage>{errors.amount.message}</FormErrorMessage>}
								</FormControl>
								<HStack justify="center" spacing="24px">
									<ArrowButton size="lg" left variant="icon" color="white" onClick={onClose}>
										Annuler
									</ArrowButton>
									<Button size="lg" type="submit">
										Confirmer
									</Button>
								</HStack>
							</VStack>
						</ModalBody>
					</VStack>
				</chakra.form>
			</ModalContent>
		</Modal>
	);
};
