import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, HStack } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import MultipleCheckboxSelect from 'components/inputs/MultipleCheckboxSelect';

const strategies = [
	{
		label: 'Venture Capital',
		value: 'ventureCapital',
		tooltip:
			'Investissement dans des entreprises jeunes (start-ups) en phase de démarrage présentant un fort potentiel de croissance. Prise de participation minoritaire avec revente des parts à terme pour générer une plus value.',
	},
	{
		label: 'Growth',
		value: 'growthEquity',
		tooltip:
			'Fonds ciblant des entreprises jeunes ayant démontré la solidité de leur modèle commercial et en phase de croissance soutenue. Prise de participation minoritaire avec revente des parts à terme pour générer une plus value.',
	},
	{
		label: 'Buyout',
		value: 'buyout',
		tooltip:
			'Fonds ciblant des entreprises matures avec l’intention d’améliorer leurs performances financières et opérationnelles puis de revendre l’actif et générer une plus value intéressante. Le segment Buyout (ou capital transmission en français) constitue le segment du Private Equity le plus important en terme de volume de transactions.',
	},
	{
		label: 'Secondaries',
		value: 'secondaries',
		tooltip:
			'Véhicules d’investissement prenant des participations dans une gamme de fonds de Private Equity via un marché secondaire. Ces fonds peuvent être très intéressants car ils permettent aux particuliers d’investir dans des portefeuilles très diversifiés (jusqu’à plusieurs centaines d’entreprises).',
	},
	{
		label: 'Private Debt',
		value: 'privateDebt',
		tooltip:
			'Fonds finançant des entreprises grâce à de la dette privée. Les fonds de "private debt" ou dette privée peuvent servir de diversificateur dans un portefeuille d’investissement car ils sont moins corrélés aux marchés actions.',
	},
	{
		label: 'Real Estate',
		value: 'realEstate',
		tooltip:
			'Fonds investissant dans des parcs immobiliers dans le but de les améliorer, en faire l’exploitation puis les revendre afin de dégager des plus values. Contrairement aux SCPI, ces fonds ne versent pas de loyers mais se comportent comme un fonds de capital investissement classique avec une période de déploiement et une période de distribution.',
	},
].map(({ label, value, tooltip }) => ({ label, value, tooltip })) as {
	label: string;
	value: string;
	tooltip: React.ReactNode;
}[];

const SimulationPePreferencesInput = (): JSX.Element => {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	return (
		<FormControl isInvalid={!!errors.strategies}>
			<FormLabel>
				<HStack>
					<>Souhaitez-vous favoriser une ou plusieurs stratégies d'investissement ? (optionnel)</>
					<CustomTooltip text="Les fonds de Private Equity diffèrent par leurs stratégies d’investissement. Vous pouvez, si vous le désirez, privilégier un ou plusieurs types de stratégie dans votre portefeuille." />
				</HStack>
			</FormLabel>

			<Controller
				control={control}
				name="strategies"
				render={({ field: { onChange, value } }) => (
					<MultipleCheckboxSelect onChange={onChange} value={value} options={strategies} />
				)}
			/>
		</FormControl>
	);
};

export default SimulationPePreferencesInput;
