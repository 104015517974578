import { backendApi } from 'services/apiService';
import type { EnvelopeType } from 'store/types/global.type';
import type { VersementType } from 'store/types/transaction.type';

type RessourceDTO = {
	code: string;
	libelle: string;
};

export type BaseMouvementDTO = {
	compartiment?: RessourceDTO;
	dateOperation: string;
	dateReglement?: string;
	montantOperation: number;
	typeMouvement: string;
	typeOperation: string;
};

export type MouvementType = {
	contractId: string;
	envelopeType: EnvelopeType;

	dateOperation: string;
	montantOperation: number;
	typeVersement: VersementType;
	subOperation: BaseMouvementDTO[];
};

export type FetchVersementsQueryResponse = MouvementType[];

type FetchVersementsQueryInput = {
	onlyVersements?: boolean;
};

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllVersements: builder.query<FetchVersementsQueryResponse, FetchVersementsQueryInput | void>({
			query: () => '/mouvements/versements',
		}),
	}),
});

export const { useGetAllVersementsQuery } = extendedApi;
