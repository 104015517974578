import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { chakra, Text, useDisclosure, VStack } from '@chakra-ui/react';
import CommonReglementaryFormContext from 'formContexts/CommonReglementary';
import FoyerFiscalFormContext from 'formContexts/FoyerFiscal';

import HighIncomeModal from 'components/simulationResult/HighIncomeModal';
import ModalInformingAcceptancyCondition from 'components/simulationResult/ModalInformingAcceptancyCondition';
import eventTracker from 'services/events/eventTracker';
import { useGetKYCQuery, useUpdateKycFoyerFiscalMutation } from 'services/requests/kyc';
import { IncomePeriod } from 'store/referentiels/incomePeriod';
import { IncomeType } from 'store/referentiels/incomeType';
import { MatrimonialRegime } from 'store/referentiels/matrimonial';
import { CommonReglementaryQuestionsType, FoyerFiscalType } from 'store/types/KYC.type';
import { isNotNone } from 'utils/functions';

import { KYCFormProps } from './type';

type FiscalFormType = FoyerFiscalType & CommonReglementaryQuestionsType & { regimeMatrimonial: MatrimonialRegime };

const FoyerFiscalForm = ({ onSuccess, formConfirmation }: KYCFormProps): JSX.Element => {
	const { data: kycData, isSuccess: isKycSuccess } = useGetKYCQuery();
	const [updateFoyerFiscal] = useUpdateKycFoyerFiscalMutation();
	const { isOpen: isOpenModalUS, onOpen: onOpenModalUS, onClose: onCloseModalUS } = useDisclosure();
	const {
		isOpen: isOpenModalHighIncome,
		onOpen: onOpenModalHighIncome,
		onClose: onCloseModalHighIncome,
	} = useDisclosure();

	const methods = useForm<FiscalFormType>({
		mode: 'onChange',
		shouldUnregister: true,
		defaultValues: {
			childNumber: 0,
			revenuType: IncomeType.GROSS,
			revenuPeriod: IncomePeriod.ANNUALLY,
			revenuSpouseType: IncomeType.GROSS,
			revenuSpousePeriod: IncomePeriod.ANNUALLY,
		},
	});
	const { handleSubmit, setValue } = methods;

	useEffect(() => {
		if (isKycSuccess && kycData) {
			if (isNotNone(kycData.childNumber)) setValue('childNumber', kycData.childNumber);
			if (isNotNone(kycData.professionalSituation)) setValue('professionalSituation', kycData.professionalSituation);

			if (isNotNone(kycData.familySituation)) setValue('familySituation', kycData.familySituation);
			if (isNotNone(kycData.matrimonialRegime)) setValue('regimeMatrimonial', kycData.matrimonialRegime);

			if (isNotNone(kycData.revenu)) setValue('revenu', kycData.revenu);
			if (isNotNone(kycData.revenuType)) setValue('revenuType', kycData.revenuType);
			if (isNotNone(kycData.revenuPeriod)) setValue('revenuPeriod', kycData.revenuPeriod);

			if (isNotNone(kycData.revenuSpouse)) setValue('revenuSpouse', kycData.revenuSpouse);
			if (isNotNone(kycData.revenuSpouseType)) setValue('revenuSpouseType', kycData.revenuSpouseType);
			if (isNotNone(kycData.revenuSpousePeriod)) setValue('revenuSpousePeriod', kycData.revenuSpousePeriod);

			if (isNotNone(kycData.isPoliticallyExposed)) setValue('isPoliticallyExposed', kycData.isPoliticallyExposed);
			if (isNotNone(kycData.isUSPerson)) setValue('isUSPerson', kycData.isUSPerson);
		}
	}, [kycData, isKycSuccess, setValue]);

	const onSubmit = handleSubmit((data: FiscalFormType) => {
		const { isUSPerson } = data;
		if (isUSPerson) {
			onOpenModalUS();
			return;
		} else onCloseModalUS();
		eventTracker.mixpanel.track('Fiscalite - Situation', { ...data });

		if (data.revenu > 2_000_000 || (data.revenuPeriod === IncomePeriod.MONTHLY && data.revenu * 12 > 2_000_000)) {
			onOpenModalHighIncome();
			return;
		} else onCloseModalHighIncome();

		updateFoyerFiscal(data)
			.unwrap()
			.then(() => onSuccess());
	});

	return (
		<>
			<ModalInformingAcceptancyCondition isOpen={isOpenModalUS} onClose={onCloseModalUS} />
			<HighIncomeModal isOpen={isOpenModalHighIncome} onClose={onCloseModalHighIncome} />
			<FormProvider {...methods}>
				<chakra.form onSubmit={onSubmit} w="100%">
					<VStack align="start" w="100%" spacing={{ base: '32px', md: '40px', lg: '48px' }} mt="24px">
						<FoyerFiscalFormContext />
						<CommonReglementaryFormContext />

						<Text variant="Text-S-Regular">
							Dans le cas où cette condition s’applique à vous, veuillez nous contacter à l’adresse email suivante:
							<b> contact@ramify.fr </b>
						</Text>
						{formConfirmation}
					</VStack>
				</chakra.form>
			</FormProvider>
		</>
	);
};

export default FoyerFiscalForm;
