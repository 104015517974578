import { progressAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(progressAnatomy.keys);

const Progress = defineMultiStyleConfig({
	baseStyle: {
		track: {
			borderRadius: 'full',
			bg: 'grey.500',
		},
		filledTrack: {
			bg: 'informative.900',
		},
	},
	defaultProps: {
		size: 'xs',
	},
});

export default Progress;
