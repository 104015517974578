import React from 'react';
import { Button, Card, Heading, Image, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

type ProductDiscoverCardProps = {
	icon: string;
	title: string;
	description: string;
	customNode?: React.ReactNode;
	callToActions: {
		label: string;
		variant: string;
		isDisabled?: boolean;
		onClick: () => void;
	}[];
};

const ProductDiscoverCard = ({
	icon,
	title,
	description,
	callToActions,
	customNode,
}: ProductDiscoverCardProps): JSX.Element => {
	return (
		<Card w="100%" p="24px">
			<VStack w="100%" height="100%" justify="space-between" spacing="24px" align="start">
				<VStack w="100%" spacing="24px" align="start">
					<Image src={icon} w="40px" />
					<VStack spacing="16px" align="start">
						<Heading variant="Title-M-SemiBold">{title}</Heading>
						<Text minH="40px" variant="Text-M-Regular" color="grey.900">
							{description}
						</Text>
					</VStack>
					{customNode && customNode}
				</VStack>
				<Wrap spacing="16px" w="100%">
					{callToActions.map((cta) => (
						<WrapItem key={cta.label} flex="1">
							<Button
								variant={cta.variant}
								isDisabled={cta.isDisabled}
								onClick={cta.onClick}
								w="100%"
								flexShrink="0"
								size="lg"
							>
								{cta.label}
							</Button>
						</WrapItem>
					))}
				</Wrap>
			</VStack>
		</Card>
	);
};

export default ProductDiscoverCard;
