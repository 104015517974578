import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Button, Center, Drawer, DrawerContent, DrawerOverlay, HStack, Icon } from '@chakra-ui/react';

import { useAppResponsive } from 'hooks/useAppResponsive';

const ProgressTopBar = ({
	children,
	collapsed,
	setCollapsed,
	...props
}: BoxProps & { collapsed: boolean; setCollapsed: (c: boolean) => void }): JSX.Element => {
	const screenSize = useAppResponsive({ base: 'SM', md: 'MD' }) || 'MD';

	return (
		<HStack spacing="24px" w="100%" {...props}>
			<Drawer isOpen={!collapsed} placement="left" onClose={() => setCollapsed(true)}>
				<DrawerOverlay />
				<DrawerContent
					overflowY="scroll"
					bg="white"
					minH="100%"
					minW={{ base: '100%', md: '440px !important' }}
					pl="24px"
					pt="8px"
				>
					<Box h="100%" w="100%" position="relative">
						{screenSize === 'SM' && (
							<Button
								position="absolute"
								right="24px"
								top="16px"
								onClick={() => setCollapsed(true)}
								p="16px"
								bg="transparent"
								color="grey.700"
							>
								<Icon fontSize="16px" as={CloseIcon} />
							</Button>
						)}
						{children}
					</Box>
				</DrawerContent>
			</Drawer>
			<Center
				transition="all 0.5s"
				zIndex={2}
				w="40px"
				h="40px"
				bg="white"
				borderRadius="50%"
				border="1px solid rgba(48,48,48, .12)"
				cursor="pointer"
				filter="drop-shadow(0px 0px 12px rgba(48, 48, 48, 0.08))"
				onClick={() => setCollapsed(false)}
				transform={`rotate(${collapsed ? 180 : 0}deg)`}
			>
				<Icon as={HamburgerIcon} />
			</Center>
		</HStack>
	);
};

export default ProgressTopBar;
