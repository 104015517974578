import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Model } from '@carbon/icons-react';
import { Heading, Image, Stack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import CashIcon from 'assets/icons/ProductsColoredIcons/Cash.svg';
import ImmoIcon from 'assets/icons/ProductsColoredIcons/Immo.svg';
import InvestIcon from 'assets/icons/ProductsColoredIcons/Invest.svg';
import PeIcon from 'assets/icons/ProductsColoredIcons/Pe.svg';
import { ArrowButton } from 'components/Button/Button';
import ProductCard from 'components/cards/ProductCard';
import { ProductType } from 'store/types/global.type';

export const ExploreProducts: FC = () => {
	const navigate = useNavigate();
	return (
		<Stack spacing="32px">
			<Heading variant="Title-L-SemiBold">Explorez les produits Ramify</Heading>

			<Wrap spacing="24px" w="100%">
				<WrapItem>
					<ProductCard variant={ProductType.INVEST} onClick={() => navigate('/invest')} h="100%">
						<VStack w="100%" align="start" spacing="24px">
							<Image w="40px" h="40px" src={InvestIcon} alignSelf="start" />

							<VStack spacing="16px" w="100%" align="start">
								<Heading variant="Title-M-SemiBold">
									Ouvrez une{' '}
									<Heading variant="Title-M-SemiBold" fontWeight="bold" color="blue.900" as="span">
										Assurance vie
									</Heading>{' '}
									ou un{' '}
									<Heading variant="Title-M-SemiBold" fontWeight="bold" color="blue.900" as="span">
										PER
									</Heading>{' '}
									avec Ramify.
								</Heading>
								<Text color="grey.900">
									Investissez dans un portefeuille d’actifs diversifié via Assurance Vie ou PER.
								</Text>
							</VStack>
						</VStack>
						<ArrowButton variant="tertiary" alignSelf="end" color="primary.black">
							Découvrir
						</ArrowButton>
					</ProductCard>
				</WrapItem>

				<WrapItem>
					<ProductCard variant={ProductType.SCPI} onClick={() => navigate('/immobilier')} h="100%">
						<VStack w="100%" align="start" spacing="24px">
							<Image w="40px" h="40px" src={ImmoIcon} alignSelf="start" />

							<VStack spacing="16px" w="100%" align="start">
								<Heading variant="Title-M-SemiBold">
									Investissez dans les{' '}
									<Heading variant="Title-M-SemiBold" fontWeight="bold" color="green.900" as="span">
										SCPI{' '}
									</Heading>
									les plus performantes.
								</Heading>
								<Text color="grey.900">Investissez dans l’immobilier en direct, à credit ou via assurance vie.</Text>
							</VStack>
						</VStack>

						<ArrowButton variant="tertiary" alignSelf="end" color="primary.black">
							Découvrir
						</ArrowButton>
					</ProductCard>
				</WrapItem>

				<WrapItem>
					<ProductCard variant={ProductType.PE} onClick={() => navigate('/private-equity')} h="100%">
						<VStack w="100%" align="start" spacing="24px">
							<Image w="40px" h="40px" src={PeIcon} alignSelf="start" />

							<VStack spacing="16px" w="100%" align="start">
								<Heading variant="Title-M-SemiBold">
									Investissez dans les fonds de{' '}
									<Heading variant="Title-M-SemiBold" fontWeight="bold" color="red.900" as="span">
										Private Equity{' '}
									</Heading>
									les plus performants.
								</Heading>
								<Text color="grey.900">Investissez dès 1 000 € dans les fonds les plus prestigieux.</Text>
							</VStack>
						</VStack>

						<ArrowButton variant="tertiary" alignSelf="end" color="primary.black">
							Découvrir
						</ArrowButton>
					</ProductCard>
				</WrapItem>

				<WrapItem>
					<ProductCard variant={ProductType.CASH} onClick={() => navigate('/cash')}>
						<VStack w="100%" align="start" spacing="24px">
							<Image w="40px" h="40px" src={CashIcon} alignSelf="start" />

							<VStack spacing="16px" w="100%" align="start">
								<Heading variant="Title-M-SemiBold">
									Épargnez avec Ramify{' '}
									<Heading variant="Title-M-SemiBold" fontWeight="bold" color="orange.900" as="span">
										Cash
									</Heading>
								</Heading>
								<Text color="grey.900">Épargnez vos liquidité avec les meilleurs intérêts.</Text>
							</VStack>
						</VStack>

						<ArrowButton variant="tertiary" alignSelf="end" color="primary.black">
							Découvrir
						</ArrowButton>
					</ProductCard>
				</WrapItem>

				<WrapItem>
					<ProductCard variant="BLACK" onClick={() => navigate('/offre-black')}>
						<VStack w="100%" align="start" spacing="24px" >
							<Model width="40px" height="40px" color="white" />

							<VStack spacing="16px" w="100%" align="start">
								<Heading variant="Title-M-SemiBold" color="white">
									Accédez à{' '}
									<Heading variant="Title-M-SemiBold" fontWeight="bold" color="primary.yellow" as="span">
										Ramify Black
									</Heading>
								</Heading>
								<Text color="white">Expérimentez le meilleur du conseil financier.</Text>
							</VStack>
						</VStack>

						<ArrowButton bg="primary.black" alignSelf="end" color="white">
							Découvrir Ramify Black
						</ArrowButton>
					</ProductCard>
				</WrapItem>
			</Wrap>
		</Stack>
	);
};
