import { Button, Card, Image, Text, VStack } from '@chakra-ui/react';

type BannerVerticalCardProps = {
	title: string;
	subtitle: string;
	image: string;
	description: string;
	btnText: string;
	btnCallback: () => void;
};

const BannerVerticalCard = ({ title, subtitle, image, description, btnText, btnCallback }: BannerVerticalCardProps) => {
	return (
		<Card
			bg="#F8F8F9"
			size="sm"
			onClick={btnCallback}
			_hover={{ transition: 'all 0.3s ease-out', transform: 'scale(1.015)' }}
			cursor="pointer"
		>
			<Image src={image} alt="image" />

			<VStack w="100%" align="start" spacing="83px">
				<VStack w="100%" align="start" spacing="32px">
					<VStack w="100%" align="start" spacing="8px">
						<Text variant="subtitle" color="yellow.500" fontWeight="500">
							{title}
						</Text>
						<Text maxW="256px" fontWeight="500" fontSize="24px" fontFamily="Poppins">
							{subtitle}
						</Text>
					</VStack>

					<Text variant="Text-M-Regular">{description}</Text>
				</VStack>
				<Button variant="primary" w="100%">
					{btnText}
				</Button>
			</VStack>
		</Card>
	);
};
export default BannerVerticalCard;
