import { FC } from 'react';
import { DownloadIcon } from '@chakra-ui/icons';
import { Card, Heading, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { IconButton } from 'components/Button/Button';
import { EnvelopeType } from 'store/types/global.type';

type SouscriptionCardProps = {
	type: EnvelopeType;
	onClickBulletin?: () => void;
};

const SouscriptionCard: FC<SouscriptionCardProps> = ({ type, onClickBulletin }) => (
	<Card w="100%" gap="32px">
		<Wrap w="100%" justify="space-between">
			<WrapItem>
				<VStack w="100%" spacing="8px" align="start">
					<Heading variant="Title-M-Bold">{type === 'AV' ? 'Assurance Vie' : 'PER individuel'}</Heading>
					<Text variant="Text-M-Medium">Frais : 1% annuel</Text>
				</VStack>
			</WrapItem>
			<WrapItem>
				<Text variant="Text-M-Medium">En cours de traitement</Text>
			</WrapItem>
		</Wrap>

		<Text>
			Nos équipes vérifient votre dossier, nous reviendrons vers vous par email pour vous informer de l’ouverture du
			contrat.
		</Text>

		<IconButton onClick={onClickBulletin} variant="secondary" right icon={<DownloadIcon />}>
			Voir mon bulletin de soucription
		</IconButton>
	</Card>
);

export default SouscriptionCard;
