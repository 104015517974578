import { Heading, HStack, Image, Stack, Text, VStack } from '@chakra-ui/react';

import Leaf from 'assets/icons/Leaf.svg';
import { QAViewer } from 'components/faq/FAQ';

const ESGPart = (): JSX.Element => (
	<VStack w="100%" align="start" spacing="24px">
		<VStack w="100%" align="start">
			<Heading variant="Title-M-SemiBold">Investissements durables pour les autres actifs</Heading>
			<Text>
				Pour les autres classes actifs (obligations, immobilier..) nous nous basons sur les labels Greenfin & ISR pour
				réaliser notre sélection. La méthode de calcul de l’impact en température n’étant pas adapté pour l’instant.
			</Text>
		</VStack>
		<Stack w="100%" align="start" direction={{ base: 'column', md: 'row' }}>
			<QAViewer
				question={
					<HStack>
						<Heading variant="Title-S-SemiBold" color="green.700">
							Label Greenfin
						</Heading>
						<Image src={Leaf} alt="feuille" />
					</HStack>
				}
				answer={
					<Text variant="Text-S-Regular">
						Le label Greenfin a été créé en 2015 par le ministère de la Transition écologique. Les pouvoirs publics sont
						propriétaires du label, mais celui-ci est contrôlé par trois organismes : Novethic, EY France et Afnor
						certification. Il a vocation à garantir la « qualité verte » des fonds d'investissement.
					</Text>
				}
			/>
			<QAViewer
				question={
					<HStack>
						<Heading variant="Title-S-SemiBold" color="green.700">
							Label ISR
						</Heading>
						<Image src={Leaf} alt="feuille" />
					</HStack>
				}
				answer={
					<Text variant="Text-S-Regular">
						Le label ISR est un label français créé en 2016 par le ministère de l'Économie et des Finances, dont
						l'objectif est d'offrir une meilleure visibilité aux fonds d'investissement respectant les principes de
						l'investissement socialement responsable, autorisés à la commercialisation en France.
					</Text>
				}
			/>
		</Stack>
	</VStack>
);

export default ESGPart;
