import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CabinCare, ChartLine, ColorPalette, Enterprise, Finance, Home, Wallet } from '@carbon/icons-react';
import {
	Box,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	InputGroup,
	InputLeftElement,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

// Components
import CustomTooltip from 'components/CustomTooltip';
import ErrorFieldMessage from 'components/inputs/ErrorMessage';
import MultipleCheckboxSelect from 'components/inputs/MultipleCheckboxSelect';
import NumberInput from 'components/inputs/NumberInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import useThemedToast from 'hooks/useThemedToast';
import { useGetKYCQuery, useUpdateKYCPatrimoineMutation } from 'services/requests/kyc';
import { PatrimonySource } from 'store/referentiels/patrimonySource';
import { PatrimoineDTO, PatrimonySourceDTO, patrimonySourcesLabels } from 'store/types/KYC.type';

import { KYCFormProps } from './type';

const patrimonySourcesOptions = Object.entries(patrimonySourcesLabels).map(([name, label]) => ({
	label,
	value: name,
})) as {
	label: string;
	value: PatrimonySource;
}[];

// Must justify at least 1000€ patrimony
const validateFinancialPatrimony = (patrimonyEstimate: PatrimoineDTO['patrimonyEstimate']) =>
	Object.entries(patrimonyEstimate)
		.filter(([key]) =>
			['bankAccounts', 'capitalisation', 'portfolio', 'others', 'professionalAssets', 'retirement'].includes(key),
		)
		.map(([, value]) => value)
		.reduce((val, acc) => val + acc, 0) >= 1000;

const PatrimonyForm = ({ onSuccess, formConfirmation }: KYCFormProps): JSX.Element => {
	const toast = useThemedToast();
	const errorPatrimonySourceRef = useRef<null | HTMLDivElement>(null);
	const errorFinancialPatrimonyRef = useRef<null | HTMLDivElement>(null);
	const [triggerUpdatePatrimoine] = useUpdateKYCPatrimoineMutation();
	const { data: kycData, isSuccess } = useGetKYCQuery();

	const {
		control,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors },
		watch,
		setValue,
	} = useForm<{ patrimonyEstimate: PatrimoineDTO['patrimonyEstimate']; patrimonySources: PatrimonySourceDTO }>({
		mode: 'onChange',
		defaultValues: {
			patrimonySources: [],
			patrimonyEstimate: {
				primaryResidence: 0,
				secondaryResidence: 0,
				bankAccounts: 0,
				capitalisation: 0,
				portfolio: 0,
				retirement: 0,
				professionalAssets: 0,
				others: 0,
			},
		},
	});

	useEffect(() => {
		if (isSuccess && kycData) {
			if (kycData.patrimonyEstimatePrimaryRealEstate)
				setValue('patrimonyEstimate.primaryResidence', kycData.patrimonyEstimatePrimaryRealEstate);
			if (kycData.patrimonyEstimateRetirement)
				setValue('patrimonyEstimate.retirement', kycData.patrimonyEstimateRetirement);
			if (kycData.patrimonyEstimateSecondaryRealEstate)
				setValue('patrimonyEstimate.secondaryResidence', kycData.patrimonyEstimateSecondaryRealEstate);
			if (kycData.patrimonyEstimateBankAccount)
				setValue('patrimonyEstimate.bankAccounts', kycData.patrimonyEstimateBankAccount);
			if (kycData.patrimonyEstimatePortfolio)
				setValue('patrimonyEstimate.portfolio', kycData.patrimonyEstimatePortfolio);
			if (kycData.patrimonyEstimateProfessionalAssets)
				setValue('patrimonyEstimate.professionalAssets', kycData.patrimonyEstimateProfessionalAssets);
			if (kycData.patrimonyEstimateCcontractAVCapitalisation)
				setValue('patrimonyEstimate.capitalisation', kycData.patrimonyEstimateCcontractAVCapitalisation);
			if (kycData.patrimonyEstimateOther) setValue('patrimonyEstimate.others', kycData.patrimonyEstimateOther);

			setValue('patrimonySources', kycData.patrimonySources);
		}
	}, [isSuccess, kycData, setValue]);

	const [patrimonyEstimate] = watch(['patrimonyEstimate']);

	useEffect(() => {
		if (validateFinancialPatrimony(patrimonyEstimate)) clearErrors('patrimonyEstimate');
	}, [
		// eslint-disable-next-line react-hooks/exhaustive-deps
		...Object.keys(patrimonyEstimate).map((key) => patrimonyEstimate[key as keyof PatrimoineDTO['patrimonyEstimate']]),
		clearErrors,
		patrimonyEstimate,
	]);

	const onSubmit = handleSubmit(
		(data) => {
			if (!validateFinancialPatrimony(data.patrimonyEstimate)) {
				setError('patrimonyEstimate', {
					message: '*Vous devez justifier au minimum 1000€ de patrimoine financier',
				});
				// Scroll after 0.01 sec, the time for the component to be rendered
				setTimeout(() => {
					errorFinancialPatrimonyRef.current?.scrollIntoView({ behavior: 'smooth' });
				}, 10);
				return;
			}
			triggerUpdatePatrimoine(data)
				.unwrap()
				.then(() => onSuccess())
				.catch(() =>
					toast({
						title: 'Une erreur est survenue',
						status: 'error',
						duration: 9000,
						isClosable: true,
					}),
				);
		},
		(error) => {
			if (error.patrimonySources) errorPatrimonySourceRef.current?.scrollIntoView({ behavior: 'smooth' });
			else if (error.patrimonyEstimate) errorFinancialPatrimonyRef.current?.scrollIntoView({ behavior: 'smooth' });
		},
	);

	return (
		<form onSubmit={onSubmit}>
			<VStack spacing="20px" align="start">
				<VStack align="start" spacing="64px">
					{/* Patrimony real estate */}
					<VStack align="start" spacing="24px">
						<VStack align="start" spacing="16px">
							<Text variant="Title-S-SemiBold">Estimation du patrimoine immobilier</Text>
							<Text variant="Text-M-Regular" color="grey.900">
								Afin de vous proposer le meilleur accompagnement possible, nous avons besoin d'évaluer votre patrimoine
								immobilier. Cela nous permet de valider l'adéquation de votre investissement par rapport à votre
								patrimoine total et votre capacité d'épargne.
							</Text>
						</VStack>

						<Wrap spacing="24px">
							<WrapItem w={onboardingInputWidth}>
								<FormControl isInvalid={!!errors.patrimonyEstimate?.primaryResidence}>
									<FormLabel>Résidence principale</FormLabel>

									<Controller
										name="patrimonyEstimate.primaryResidence"
										control={control}
										rules={{ required: true, min: 0 }}
										render={({ field }) => (
											<InputGroup>
												<InputLeftElement children={<Home size="20" />} />
												<NumberInput {...field} paddingLeft="48px" placeholder="Laisser vide si 0€" suffix=" €" />
											</InputGroup>
										)}
									/>

									{errors.patrimonyEstimate?.primaryResidence?.type === 'required' && (
										<FormErrorMessage>*Ce champs est obligatoire</FormErrorMessage>
									)}
									{errors.patrimonyEstimate?.primaryResidence?.type === 'min' && (
										<FormErrorMessage>*Ce montant ne peut pas être négatif</FormErrorMessage>
									)}
								</FormControl>
							</WrapItem>

							<WrapItem w={onboardingInputWidth}>
								<FormControl isInvalid={!!errors.patrimonyEstimate?.secondaryResidence}>
									<FormLabel>Résidences secondaires</FormLabel>
									<Controller
										name="patrimonyEstimate.secondaryResidence"
										control={control}
										rules={{ required: true, min: 0 }}
										render={({ field }) => (
											<InputGroup>
												<InputLeftElement children={<Home size="20" />} />
												<NumberInput {...field} paddingLeft="48px" placeholder="Laisser vide si 0€" suffix=" €" />
											</InputGroup>
										)}
									/>
									{errors.patrimonyEstimate?.secondaryResidence?.type === 'required' && (
										<FormErrorMessage>*Ce champs est obligatoire</FormErrorMessage>
									)}
									{errors.patrimonyEstimate?.secondaryResidence?.type === 'min' && (
										<FormErrorMessage>*Ce montant ne peut pas être négatif</FormErrorMessage>
									)}
								</FormControl>
							</WrapItem>
						</Wrap>
					</VStack>

					{/* Patrimony Financial */}

					<VStack w="100%" spacing="24px" align="start">
						<VStack align="start">
							<Text variant="Title-S-SemiBold">Estimation du patrimoine financier</Text>
							<Text variant="Text-M-Regular" color="grey.900">
								Afin de vous proposer le meilleur accompagnement possible, nous avons besoin d'évaluer votre patrimoine
								financier. Cela nous permet de valider l'adéquation de votre investissement par rapport à votre
								patrimoine total et votre capacité d'épargne. Merci de détailler le plus précisément possible le montant
								de votre patrimoine pour chaque type d'actif détenu (par exemple : livret, épargne retraite ...).
							</Text>
						</VStack>

						<Wrap spacing="24px">
							<WrapItem w={onboardingInputWidth}>
								<FormControl isInvalid={!!errors.patrimonyEstimate?.bankAccounts}>
									<FormLabel>Comptes bancaires, liquidités</FormLabel>

									<Controller
										name="patrimonyEstimate.bankAccounts"
										control={control}
										rules={{ required: true, min: 0 }}
										render={({ field }) => (
											<InputGroup>
												<InputLeftElement children={<Finance size="20" />} />
												<NumberInput {...field} paddingLeft="48px" placeholder="Laisser vide si 0€" suffix=" €" />
											</InputGroup>
										)}
									/>

									{errors.patrimonyEstimate?.bankAccounts?.type === 'required' && (
										<FormErrorMessage>*Ce champs est obligatoire</FormErrorMessage>
									)}
									{errors.patrimonyEstimate?.bankAccounts?.type === 'min' && (
										<FormErrorMessage>*Ce montant ne peut pas être négatif</FormErrorMessage>
									)}
								</FormControl>
							</WrapItem>
							<WrapItem w={onboardingInputWidth}>
								<FormControl isInvalid={!!errors.patrimonyEstimate?.capitalisation}>
									<FormLabel>Contrats d’assurance-vie, capitalisation</FormLabel>
									<Controller
										name="patrimonyEstimate.capitalisation"
										control={control}
										rules={{ required: true, min: 0 }}
										render={({ field }) => (
											<InputGroup>
												<InputLeftElement children={<Wallet size="20" />} />
												<NumberInput {...field} paddingLeft="48px" placeholder="Laisser vide si 0€" suffix=" €" />
											</InputGroup>
										)}
									/>

									{errors.patrimonyEstimate?.capitalisation?.type === 'required' && (
										<FormErrorMessage>*Ce champs est obligatoire</FormErrorMessage>
									)}
									{errors.patrimonyEstimate?.capitalisation?.type === 'min' && (
										<FormErrorMessage>*Ce montant ne peut pas être négatif</FormErrorMessage>
									)}
								</FormControl>
							</WrapItem>

							<WrapItem w={onboardingInputWidth}>
								<FormControl isInvalid={!!errors.patrimonyEstimate?.portfolio}>
									<FormLabel>Portefeuille de valeurs mobilières (PEA, ...)</FormLabel>
									<Controller
										name="patrimonyEstimate.portfolio"
										control={control}
										rules={{ required: true, min: 0 }}
										render={({ field }) => (
											<InputGroup>
												<InputLeftElement children={<ChartLine size="20" />} />
												<NumberInput {...field} paddingLeft="48px" placeholder="Laisser vide si 0€" suffix=" €" />
											</InputGroup>
										)}
									/>

									{errors.patrimonyEstimate?.portfolio?.type === 'required' && (
										<FormErrorMessage>*Ce champs est obligatoire</FormErrorMessage>
									)}
									{errors.patrimonyEstimate?.portfolio?.type === 'min' && (
										<FormErrorMessage>*Ce montant ne peut pas être négatif</FormErrorMessage>
									)}
								</FormControl>
							</WrapItem>
							<WrapItem w={onboardingInputWidth}>
								<FormControl isInvalid={!!errors.patrimonyEstimate?.retirement}>
									<FormLabel>Épargne retraite (PER, Madelin...)</FormLabel>
									<Controller
										name="patrimonyEstimate.retirement"
										control={control}
										rules={{ required: true, min: 0 }}
										render={({ field }) => (
											<InputGroup>
												<InputLeftElement children={<CabinCare size="20" />} />
												<NumberInput {...field} paddingLeft="48px" placeholder="Laisser vide si 0€" suffix=" €" />
											</InputGroup>
										)}
									/>

									{errors.patrimonyEstimate?.retirement?.type === 'required' && (
										<FormErrorMessage>*Ce champs est obligatoire</FormErrorMessage>
									)}
									{errors.patrimonyEstimate?.retirement?.type === 'min' && (
										<FormErrorMessage>*Ce montant ne peut pas être négatif</FormErrorMessage>
									)}
								</FormControl>
							</WrapItem>
							<WrapItem w={onboardingInputWidth}>
								<FormControl isInvalid={!!errors.patrimonyEstimate?.others}>
									<FormLabel>Autres (ex: objets d’arts...)</FormLabel>
									<Controller
										name="patrimonyEstimate.others"
										control={control}
										rules={{ required: true, min: 0 }}
										render={({ field }) => (
											<InputGroup>
												<InputLeftElement children={<ColorPalette size="20" />} />
												<NumberInput {...field} paddingLeft="48px" placeholder="Laisser vide si 0€" suffix=" €" />
											</InputGroup>
										)}
									/>

									{errors.patrimonyEstimate?.others?.type === 'required' && (
										<FormErrorMessage>*Ce champs est obligatoire</FormErrorMessage>
									)}
									{errors.patrimonyEstimate?.others?.type === 'min' && (
										<FormErrorMessage>*Ce montant ne peut pas être négatif</FormErrorMessage>
									)}
								</FormControl>
							</WrapItem>
							<WrapItem w={onboardingInputWidth}>
								<FormControl isInvalid={!!errors.patrimonyEstimate?.professionalAssets}>
									<FormLabel>Actifs professionnels</FormLabel>
									<Controller
										name="patrimonyEstimate.professionalAssets"
										control={control}
										rules={{ required: true, min: 0 }}
										render={({ field }) => (
											<InputGroup>
												<InputLeftElement children={<Enterprise size="20" />} />
												<NumberInput {...field} paddingLeft="48px" placeholder="Laisser vide si 0€" suffix=" €" />
											</InputGroup>
										)}
									/>
									{errors.patrimonyEstimate?.professionalAssets?.type === 'required' && (
										<FormErrorMessage>*Ce champs est obligatoire</FormErrorMessage>
									)}
									{errors.patrimonyEstimate?.professionalAssets?.type === 'min' && (
										<FormErrorMessage>*Ce montant ne peut pas être négatif</FormErrorMessage>
									)}
								</FormControl>
							</WrapItem>
						</Wrap>

						{errors.patrimonyEstimate && (
							<Box ref={errorFinancialPatrimonyRef}>
								{/* @ts-ignore, virtual field on react-hook-form */}
								<ErrorFieldMessage>{errors.patrimonyEstimate.message}</ErrorFieldMessage>
							</Box>
						)}
					</VStack>

					{/* Patrimony Origin */}

					<VStack align="start" spacing="16px">
						<VStack align="start" spacing="16px">
							<Text variant="Title-S-SemiBold">Origine de votre patrimoine</Text>
							<VStack align="start" w="100%">
								<Text variant="Text-M-Regular" color="grey.900">
									En application de la loi relative à la lutte contre le blanchiment des capitaux et le financement du
									terrorisme, nous avons l'obligation de vous demander l'origine de votre patrimoine financier.
								</Text>
								<HStack align="center" spacing="8px">
									<Text variant="Text-M-Regular" color="grey.900">
										Vos informations sont protégées par{' '}
										<Text as="b" variant="Text-M-SemiBold">
											le secret professionnel
										</Text>
										.
									</Text>
									<CustomTooltip text="L'article 226-13 du code pénal punit « la révélation d'une information à caractère secret par une personne qui en est dépositaire soit par état ou par profession, soit en raison d'une fonction ou d'une mission temporaire »" />
								</HStack>
							</VStack>
						</VStack>

						<FormControl isInvalid={!!errors.patrimonySources}>
							<FormLabel>Sélectionnez les sources de revenus à l'origine de votre patrimoine</FormLabel>

							<Controller
								name="patrimonySources"
								control={control}
								rules={{
									validate: {
										// Must check at least one source
										required: (value) => value.length !== 0,
									},
								}}
								render={({ field: { onChange, value } }) => (
									<MultipleCheckboxSelect onChange={onChange} value={value} options={patrimonySourcesOptions} />
								)}
							/>

							{errors.patrimonySources?.type === 'required' && (
								<FormErrorMessage ref={errorPatrimonySourceRef}>
									Vous devez séletionner au minimum 1 source de patrimoine
								</FormErrorMessage>
							)}
						</FormControl>
					</VStack>
				</VStack>
				{formConfirmation}
			</VStack>
		</form>
	);
};

export default PatrimonyForm;
