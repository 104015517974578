import { FC, PropsWithChildren } from 'react';
import { Card, CardProps, VStack } from '@chakra-ui/react';

import offreBlack from 'assets/icons/OffreBlack/black.png';
import { ProductType } from 'store/types/global.type';
import colors from 'theme/foundations/colors';

type ProductCardType = {
	variant: ProductType | 'CRYPTO' | 'BLACK';
};

const bgColors: Record<ProductCardType['variant'], string> = {
	[ProductType.INVEST]: colors.products.invest,
	[ProductType.SCPI]: colors.products.scpi,
	[ProductType.PE]: colors.products.pe,
	[ProductType.CASH]: colors.products.cash,
	CRYPTO: colors.products.crypto,
	BLACK: colors.products.black,
};

const hoverColors: Record<ProductCardType['variant'], { bg: string; borderColor: string } | { image: string }> = {
	[ProductType.INVEST]: {
		bg: colors.blue[50],
		borderColor: colors.blue[500],
	},
	[ProductType.SCPI]: {
		bg: colors.green[50],
		borderColor: colors.green[500],
	},
	[ProductType.PE]: {
		bg: colors.red[50],
		borderColor: colors.red[500],
	},
	[ProductType.CASH]: {
		bg: colors.orange[50],
		borderColor: colors.orange[500],
	},
	CRYPTO: {
		bg: colors.turquoise[50],
		borderColor: colors.turquoise[500],
	},
	BLACK: {
		image: offreBlack,
	},
};

interface Props extends CardProps {
	variant?: ProductType | 'BLACK';
}

const ProductCard: FC<PropsWithChildren<Props>> = ({
	variant = ProductType.INVEST,
	children,
	w = '330px',
	...cardProps
}) => {
	const isBlack =
		variant !== ProductType.INVEST &&
		variant !== ProductType.SCPI &&
		variant !== ProductType.PE &&
		variant !== ProductType.CASH;

	return (
		<Card
			transition="all 50ms ease-in-out"
			w={w}
			borderRadius="base"
			borderWidth="1px"
			bg={bgColors[variant]}
			backgroundImage={isBlack ? offreBlack : ''}
			borderColor={colors.grey[300]}
			_hover={hoverColors[variant]}
			objectFit="cover"
			cursor="pointer"
			h="100%"
			{...cardProps}
		>
			<VStack w="100%" spacing="12px" justify="space-between" h="100%">
				{children}
			</VStack>
		</Card>
	);
};

export default ProductCard;
