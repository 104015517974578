import { useMemo } from 'react';
import {
	Card,
	Heading,
	HStack,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Skeleton,
	Stack,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import Banner from 'components/Banners/Banner';
import { ArrowButton } from 'components/Button/Button';
import RepartitionVP from 'components/strategy/RepartitionVP';
import { useAppResponsive } from 'hooks/useAppResponsive';
import ProjectGraphData from 'onboarding/Invest/Shared/Objective/ProjectGraphData';
import { CreateOVStepProps } from 'pages/Invest/Versements/CreateOptimizedVersement';
import IncomeFields from 'pages/Invest/Versements/CreateOptimizedVersement/FormFields/IncomeFields';
import SavingFields from 'pages/Invest/Versements/CreateOptimizedVersement/FormFields/SavingFields';
import { displayMoney, displayMoneyNoDigits } from 'utils/rendering';

const Simulation = ({
	trigger,
	register,
	errors,
	watch,
	setValue,
	step,
	setStep,
	optimization,
	isOptimizationFetching,
	repartition,
	isFetchingContracts,
	contracts,
}: CreateOVStepProps): JSX.Element => {
	const [saving] = watch(['saving']);
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;
	const initialAUM = useMemo(() => contracts.reduce<number>((acc, curr) => acc + +curr.amount, 0), [contracts]);

	const onCheck = () => {
		trigger().then((response) => {
			if (response) {
				if (step === 'informations') setStep('simulation');
				else setStep('contract');
			}
		});
	};

	return (
		<>
			<ModalHeader p="0px">
				<Heading variant="Title-M-SemiBold">Simuler des versements programmés</Heading>
			</ModalHeader>
			<ModalBody px="0px" py="32px">
				<VStack spacing="32px">
					<Wrap w="100%" justify="space-between" spacing="40px">
						<WrapItem minW="300px" flex={1}>
							<IncomeFields />
						</WrapItem>
						<WrapItem minW="300px" flex={1}>
							<SavingFields register={register} errors={errors} watch={watch} setValue={setValue} />
						</WrapItem>
					</Wrap>

					{repartition && optimization && step !== 'informations' && (
						<VStack spacing="32px" w="100%">
							<VStack spacing="24px" w="100%">
								<ProjectGraphData initialAUM={initialAUM} saving={saving} performance={0.05} />
								<Banner
									title="Les portefeuilles Ramify avec versements programmés ont délivré une performance de 35% supérieure aux portefeuilles sans versements programmés."
									description="Calcul basé sur les 12 mois de 2022"
									variant="information"
								/>
							</VStack>

							<VStack spacing="24px" w="100%">
								<VStack spacing="4px" align="start">
									<Text variant="Title-S-SemiBold">Optimisation Ramify</Text>
									<Text color="grey.900">
										Compte tenu de vos revenus et du montant initial d’investissement, voici l’allocation optimale de
										vos versements programmés :
									</Text>
								</VStack>
								<Skeleton isLoaded={!isFetchingContracts}>
									<Card w="100%" p="24px">
										<Stack
											direction={{ base: 'column', md: 'row' }}
											spacing="32px"
											align={{ base: 'start', md: 'center' }}
										>
											<RepartitionVP
												amountAV={repartition.avNewVersementProgramme}
												amountPER={repartition.perNewVersementProgramme}
												statusAV="visible"
												statusPER="visible"
											/>
											<Card variant="blue" borderColor="blue.500" p="16px" maxW="350px">
												<Text variant="detail">
													Ramify vous aide à déterminer les montants de versements programmés (Assurance Vie et PER) qui
													maximisent votre rendement net d’impôt.
												</Text>
											</Card>
										</Stack>
									</Card>
								</Skeleton>
							</VStack>

							<VStack spacing="16px" w="100%" align="start">
								<Skeleton isLoaded={!isOptimizationFetching}>
									<HStack spacing="24px" align="start">
										<Card maxW="400px" p="16px">
											<VStack spacing="24px" align="start" w="100%">
												<Text variant="label" fontWeight="normal">
													Grâce à vos versements programmés, vous réduirez votre impôt sur le revenu de{' '}
													<Text variant="label" as="span" color="#628DFB">
														{displayMoneyNoDigits(optimization.PVTaxEconomyThisYear)}* l'année en cours
													</Text>{' '}
													ainsi que de{' '}
													<Text variant="label" as="span" color="#628DFB">
														{displayMoneyNoDigits(optimization.PVTaxEconomyNextYear)} sur l'année 2024
													</Text>
													.
												</Text>
												<Text variant="detail">
													* sur une base de {optimization.nbRemainingVP} versements jusqu'à la fin de l'année fiscale
												</Text>
											</VStack>
										</Card>
										<VStack spacing="24px" align="start">
											<VStack align="start">
												<Text>Versements programmés (mensuel) :</Text>
												<Text variant="Text-M-Bold">{displayMoney(saving)}</Text>
											</VStack>
											<VStack align="start">
												<Text>Economie d'impôt grâce aux versements programmés (mensuel) sur cette année :</Text>
												<Text variant="Text-M-Bold" color="#628DFB">
													{displayMoney(optimization.PVTaxEconomy)}
												</Text>
											</VStack>
											<VStack align="start">
												<Text>"Vrai" effort d'épargne (mensuel) sur cette année :</Text>
												<Text variant="Text-M-Bold" color="#46D684">
													{displayMoney(saving - optimization.PVTaxEconomy)}
												</Text>
											</VStack>
										</VStack>
									</HStack>
								</Skeleton>
							</VStack>
						</VStack>
					)}
				</VStack>
			</ModalBody>

			<ModalFooter p="0">
				<HStack w="100%" spacing="24px" justifyContent="flex-end">
					{step !== 'informations' && (
						<ArrowButton
							variant="secondary"
							size="lg"
							onClick={() => setStep('informations')}
							type="button"
							w="50%"
							left
						>
							Précédent
						</ArrowButton>
					)}
					<ArrowButton variant="primary" size="lg" onClick={onCheck} type="button" w={isMobile ? '50%' : '48%'}>
						Suivant
					</ArrowButton>
				</HStack>
			</ModalFooter>
		</>
	);
};

export default Simulation;
