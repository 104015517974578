import { AffiliatesListCardContent } from 'pages/Affiliates/List/AffiliatesListCardTypes';
import { backendApi } from 'services/apiService';
import { User } from 'store/types/user.type';

type SponsoringStatus = {
	activated: boolean;
	by?: string;
};

type PromotionDetails = {
	user: User;
};

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		sendSponsorshipInvitation: builder.mutation<void, string>({
			query: (email: string) => ({
				url: '/sponsorship/invite',
				method: 'POST',
				body: { email },
			}),
			invalidatesTags: ['SponsorShip'],
		}),
		getSponsorshipGoal: builder.query<number, void>({
			query: () => '/sponsorship/goal',
		}),
		getSponsorshipCode: builder.query<{ code: string }, void>({
			query: () => '/sponsorship/mycode',
		}),
		getSponsoringStatus: builder.query<SponsoringStatus, void>({
			query: () => '/sponsorship/status',
			providesTags: ['SponsorShip'],
		}),
		getSponsorshipDetails: builder.query<PromotionDetails, string>({
			query: (code) => `/sponsorship/${code}/details`,
			providesTags: ['SponsorShip'],
		}),
		activateSponsorship: builder.mutation<boolean, { code: string }>({
			query: (body) => ({
				url: '/sponsorship/activate',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['SponsorShip'],
		}),
		getAffiliates: builder.query<AffiliatesListCardContent[], void>({
			query: () => '/sponsorship/affiliates',
			providesTags: ['SponsorShip'],
		}),
	}),
});

export const {
	useGetSponsorshipCodeQuery,
	useGetSponsorshipGoalQuery,
	useLazyGetSponsorshipDetailsQuery,
	useSendSponsorshipInvitationMutation,
	useActivateSponsorshipMutation,
	useGetSponsoringStatusQuery,
	useGetAffiliatesQuery,
} = extendedApi;
