// import colors from 'theme/foundations/colors';

// const NumberInput = {
// 	sizes: {
// 		inline: {},
// 		md: {
// 			field: {
// 				borderRadius: '5px',
// 				fontSize: '14px',
// 				height: '48px',
// 				px: '16px',
// 			},
// 		},
// 	},
// 	variants: {
// 		inline: {},
// 		base: {
// 			field: {
// 				border: `1px solid ${colors.grey[300]}`,

// 				_placeholder: { color: 'grey.400' },
// 				_hover: {
// 					border: `1px solid ${colors.grey[300]}`,
// 				},
// 				_focus: {
// 					border: `1px solid ${colors.blue[500]}`,
// 					boxShadow: `0px 0px 2px 1px ${colors.blue[100]}`,
// 				},
// 			},
// 			root: {
// 				background: 'white',
// 			},
// 		},
// 	},
// 	defaultProps: {
// 		size: 'md',
// 		variant: 'base',
// 	},
// };

// export default NumberInput;

import { numberInputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import colors from 'theme/foundations/colors';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const NumberInput = defineMultiStyleConfig({
	variants: {
		default: {
			field: {
				border: `1px solid ${colors.grey[300]}`,
				_placeholder: { color: colors.grey[700] },
				_hover: {
					borderColor: colors.grey[900],
				},
				_focus: {
					borderColor: colors.primary.yellow,
				},
				_invalid: {
					borderColor: colors.negative[900],
				},
				_disabled: {
					backgroundColor: 'grey.300',
					cursor: 'not-allowed',
					_placeholder: {
						color: colors.primary.black,
					},
				},
			},
		},
	},
	sizes: {
		md: {
			field: {
				fontSize: '14px',
				height: '52px',
				borderRadius: 'sm',
			},
		},
	},
	defaultProps: {
		size: 'md',
		variant: 'default',
	},
});

export default NumberInput;
