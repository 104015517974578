import { FieldErrors } from 'react-hook-form';

const scrollToErrorOnForm = <T>(errors: FieldErrors<Partial<T>>) => {
	// On error, scroll to top-most error encoutered
	const elements = Object.keys(errors)
		.map((name) => document.getElementsByName(name)?.[0])
		.filter((el) => !!el);
	elements.sort((a, b) => b.scrollHeight - a.scrollHeight);
	elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export default scrollToErrorOnForm;
