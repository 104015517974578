import { useNavigate } from 'react-router-dom';
import {
	Badge,
	Card,
	Grid,
	GridItem,
	Image,
	Show,
	Stack,
	Text,
	useDisclosure,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import CpmTeam from 'assets/icons/OffreBlack/cpmTeam.png';
import { CalendlyStep } from 'pages/OffreBlack/DiscoverBlack/Modal/Calendly';

import { MonthlyBlackCardType } from '..';

const MonthlyFundCard = ({ fundInfos }: { fundInfos: MonthlyBlackCardType }): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Card bg="primary.black" border="none" p="32px" w="100%" h="100%">
			<Stack direction={{ base: 'column', xl: 'row' }} spacing="40px" h="100%">
				<Image
					w={{ base: '100%', xl: '150px' }}
					h={{ base: '150px', xl: '100%' }}
					borderRadius="8px"
					src={fundInfos.image}
					objectFit="cover"
				/>
				<VStack w="100%" align="start" spacing="12px" h="100%" justify="space-between">
					<VStack w="100%" align="start" spacing="8px">
						<Text variant="Caption" color="grey.700">
							{fundInfos.partnerName}
						</Text>
						<Text variant="Title-L-Bold" color="white">
							{fundInfos.fundName}
						</Text>
					</VStack>
					<Badge variant="outlineYellow">
						<Text variant="Text-S-Regular" color="primary.yellow">
							Jusqu'au{' '}
							{new Date(fundInfos.valableUntil).toLocaleDateString(
								'fr-FR',

								{
									year: 'numeric',
									month: 'long',
									day: 'numeric',
								},
							)}
						</Text>
					</Badge>
					<Text variant="Text-M-Regular" color="white">
						{fundInfos.description}
					</Text>
					<Grid templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }} gap={6}>
						<GridItem w="100%">
							<Text variant="Text-M-Regular" color="grey.700">
								Géographie
							</Text>
							<Text variant="Text-M-Bold" color="white">
								{fundInfos.geo}
							</Text>
						</GridItem>
						<GridItem w="100%">
							<Text variant="Text-M-Regular" color="grey.700">
								Tri cible
							</Text>
							<Text variant="Text-M-Bold" color="white">
								{fundInfos.triCible}
							</Text>
						</GridItem>
						<GridItem w="100%">
							<Text variant="Text-M-Regular" color="grey.700">
								Secteur
							</Text>
							<Text variant="Text-M-Bold" color="white">
								{fundInfos.secteur}
							</Text>
						</GridItem>
						<GridItem w="100%">
							<Text variant="Text-M-Regular" color="grey.700">
								Stratégie
							</Text>
							<Text variant="Text-M-Bold" color="white">
								{fundInfos.strategy}
							</Text>
						</GridItem>
					</Grid>
					{fundInfos.link && (
						<Text cursor="pointer" color="primary.yellow" alignSelf="end" onClick={() => navigate(fundInfos.link)}>
							Découvrir le Fonds
						</Text>
					)}
				</VStack>
			</Stack>
		</Card>
	);
};

const MonthlyAdvisorCard = (): JSX.Element => {
	const {
		isOpen: isOpenAppointementModal,
		onOpen: onOpenAppointementModal,
		onClose: isCloseAppointementModal,
	} = useDisclosure();

	return (
		<Card w="100%" bg="primary.black" border="none" px="6px" h="100%">
			{isOpenAppointementModal && (
				<CalendlyStep
					url="https://calendly.com/d/y4s-6xr-tf4/echange-avec-un-conseiller-black-ramify"
					onClose={isCloseAppointementModal}
				/>
			)}
			<VStack spacing="28px">
				<VStack w="100%" spacing="16px" px="24px" align="start" justifyContent="center">
					<Text variant="Caption" color="grey.500">
						Vos conseillers dédiés
					</Text>

					<Text variant="Text-S-Regular" color="primary.white">
						Toujours disponibles pour vous accompagner dans vos projets d’investissement
					</Text>
				</VStack>

				<Image w="100%" src={CpmTeam} alt={CpmTeam} />
				<Text cursor="pointer" color="primary.yellow" alignSelf="center" onClick={onOpenAppointementModal}>
					Prendre un rendez-vous
				</Text>
			</VStack>
		</Card>
	);
};

const MonthlyOffreBlack = ({ fundInfos }: { fundInfos: MonthlyBlackCardType }): JSX.Element => (
	<Wrap w="100%" spacing="40px">
		<WrapItem flex={{ xl: '0.80' }}>
			<MonthlyFundCard fundInfos={fundInfos} />
		</WrapItem>
		<Show above="xl">
			<WrapItem flex={{ xl: '0.25' }}>
				<MonthlyAdvisorCard />
			</WrapItem>
		</Show>
	</Wrap>
);
export default MonthlyOffreBlack;
