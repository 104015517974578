import { useState } from 'react';
import { Button, Card, HStack, Text, VStack } from '@chakra-ui/react';

import { AttributeKycLabel } from 'components/badge/AttributeKycLabel';
import ProfessionalForm from 'onboarding/KYC/Forms/ProfessionalForm';
import ProfessionalCategoryReferentiel from 'store/referentiels/professionalCategory';
import ProfessionalSectorReferentiel from 'store/referentiels/professionalSector';
import ProfessionalSituationReferentiel, { ProfessionalSituation } from 'store/referentiels/professionalSituation';
import { KYC } from 'store/types/KYC.type';

const ProfessionalSituationEditable = ({ kyc }: { kyc?: KYC }): JSX.Element => {
	const [isEditing, setisEditing] = useState(false);

	return (
		<Card w="100%">
			<VStack w="100%" spacing="md" align="start">
				<HStack spacing="40px" w="100%" justify="space-between">
					<Text variant="Title-S-SemiBold">Situation professionnelle</Text>
					<Button variant="secondary" onClick={() => setisEditing(!isEditing)}>
						{isEditing ? 'Annuler' : 'Modifier'}
					</Button>
				</HStack>
				{isEditing ? (
					<ProfessionalForm
						onSuccess={() => setisEditing(false)}
						formConfirmation={
							<Button type="submit" w="100%" variant="primary">
								Mettre à jour
							</Button>
						}
					/>
				) : (
					<VStack align="start">
						<AttributeKycLabel
							title="Situation professionnelle"
							value={
								kyc?.professionalSituation &&
								ProfessionalSituationReferentiel[kyc?.professionalSituation as ProfessionalSituation]
							}
						/>

						{kyc?.professionalCategory && (
							<AttributeKycLabel
								title="Catégorie professionnelle"
								value={kyc?.professionalCategory && ProfessionalCategoryReferentiel[kyc?.professionalCategory]}
							/>
						)}
						{kyc?.professionalSector && (
							<AttributeKycLabel
								title="Secteur d’activité"
								value={kyc?.professionalSector && ProfessionalSectorReferentiel[kyc?.professionalSector]}
							/>
						)}
						<AttributeKycLabel title="Profession" value={kyc?.profession} />
						{kyc?.companyName && (
							<AttributeKycLabel title="Nom de l’entreprise" value={kyc?.companyName && kyc?.companyName} />
						)}
					</VStack>
				)}
			</VStack>
		</Card>
	);
};

export default ProfessionalSituationEditable;
