import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveLinear } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { AreaClosed, Bar, LinePath } from '@visx/shape';
import { ScaleLinear, ScaleTime } from 'd3-scale';

import { useAppResponsive } from 'hooks/useAppResponsive';
import colors from 'theme/foundations/colors';
import textStyles from 'theme/foundations/typography';
import { displayMoneyNoDigits } from 'utils/rendering';

import CursorBar from './CursorBar';

export type InfosGraph = {
	width: number;
	height: number;
	margin: { top: number; right: number; bottom: number; left: number };
	yMax: number;
	xMax: number;
	filteredData: any[];
	timeScale: ScaleTime<number, number, never>;
	valuationScale: ScaleLinear<number, number, never>;
	tooltipData?: any;
	tooltipLeft?: number;
	tooltipTop?: number;
	hideTooltip?: () => void;
	handleMouseMove?: any;
	curveType?: any;
};

export default function GraphLine({
	width,
	height,
	margin,
	yMax,
	xMax,
	filteredData,
	timeScale,
	valuationScale,
	tooltipData,
	tooltipLeft,
	tooltipTop,
	hideTooltip = () => {},
	handleMouseMove,
	curveType,
}: InfosGraph) {
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;

	return (
		<div>
			<svg width={width} height={height}>
				<Group top={margin.top}>
					{!isMobile && (
						<GridRows
							scale={valuationScale}
							width={xMax}
							strokeDasharray="2"
							stroke="#E7E7E7"
							pointerEvents="none"
							numTicks={4}
						/>
					)}
					<LinearGradient from="#FFBB2C" to="#FFBB2C" fromOpacity={0.2} toOpacity={0.0} id="bgColor" />

					<LinePath
						data={filteredData}
						x={(d) => timeScale(new Date(d.date).valueOf())}
						y={(d) => valuationScale(d.valuation)}
						stroke="#FDAC02"
						strokeWidth={2}
						curve={curveType ? curveType : curveLinear}
					/>

					<AreaClosed
						data={filteredData}
						curve={curveType ? curveType : curveLinear}
						width={width}
						height={height}
						x={(d) => timeScale(new Date(d.date).valueOf())}
						y={(d) => valuationScale(d.valuation)}
						yScale={valuationScale}
						fill="url(#bgColor)"
						id="bgColor"
					/>

					{!isMobile && (
						<>
							<AxisBottom
								top={yMax}
								scale={timeScale}
								labelOffset={100}
								tickFormat={(d) =>
									(d as Date).toLocaleDateString('fr-FR', {
										year: 'numeric',
										month: 'short',
										day: 'numeric',
									})
								}
								numTicks={width < 500 ? 3 : 5}
								tickStroke={colors.grey[300]}
								// @ts-ignore
								tickLabelProps={() => ({
									...textStyles['Text-XS-Bold'],
									fill: colors.grey[700],
									lineHeight: '20px',
								})}
								hideTicks
								hideAxisLine
							/>

							<AxisLeft
								top={-12}
								scale={valuationScale}
								numTicks={3}
								tickFormat={(d) => displayMoneyNoDigits(d.valueOf())}
								tickStroke={colors.grey[300]}
								stroke={colors.grey[300]}
								strokeWidth={0}
								// @ts-ignore
								tickLabelProps={() => ({
									fill: colors.grey[700],
									...textStyles['Text-S-Medium'],
									dx: '0.5em',
									dy: '0.35em',
									fontFamily: 'poppins',
								})}
								orientation="right"
							/>
						</>
					)}

					<Bar
						width={xMax}
						height={yMax}
						rx={0}
						fill="transparent"
						onTouchStart={handleMouseMove}
						onTouchMove={handleMouseMove}
						onMouseEnter={handleMouseMove}
						onMouseMove={handleMouseMove}
						onMouseLeave={() => hideTooltip()}
					/>
					<CursorBar
						tooltipLeft={tooltipLeft ?? 0}
						tooltipTop={tooltipTop ?? 0}
						tooltipData={tooltipData}
						yMax={yMax}
						margin={margin}
					/>
				</Group>
			</svg>
		</div>
	);
}
