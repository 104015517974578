import { Button, Heading, HStack, Image, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';

import ColoredInvest from 'assets/icons/ProductsYellowColoredIcons/Invest.svg';
import Book from 'assets/illu-top-page.png';
import { CalendlyModal } from 'components/Modals/Calendly';
import useGetActionsInvest from 'hooks/productActions/useGetActionsInvest';
import { useAppResponsive } from 'hooks/useAppResponsive';

const InvestHeaderProductPage = () => {
	const isDesktop = useAppResponsive({ base: false, xl: true });
	const { isOpen: isCalendlyOpen, onClose: onCalendlyClose, onOpen: onCalendlyOpen } = useDisclosure();

	const { actions: investActions } = useGetActionsInvest({ showAppointments: true, onCalendly: onCalendlyOpen });

	return (
		<>
			{isDesktop && <Image src={Book} right="35%" w="263px" position="absolute" top="0" />}
			<HStack w="100%" justify="space-between">
				<HStack spacing="8px">
					<Image src={ColoredInvest} />
					<Heading variant="Title-XL-Bold">Invest</Heading>
				</HStack>

				{investActions && (
					<Menu>
						<MenuButton as={Button} variant="super" size="lg">
							Investir
						</MenuButton>
						<MenuList>
							{investActions.map((action) => (
								<MenuItem key={action.label} onClick={action.onClick}>
									{action.label}
								</MenuItem>
							))}
						</MenuList>
					</Menu>
				)}
			</HStack>

			{/* calendly modal */}
			<CalendlyModal isOpen={isCalendlyOpen} onClose={onCalendlyClose} />
		</>
	);
};
export default InvestHeaderProductPage;
