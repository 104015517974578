import { CarbonIconType } from '@carbon/icons-react';
import { Card, Heading, Icon, Text, VStack } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';

const MetricCard = ({
	icon,
	title,
	value,
	label,
}: {
	icon: CarbonIconType;
	title: string;
	value?: string | React.ReactNode;
	label?: string;
}) => (
	<Card minW="248px" flex="1">
		<VStack spacing="16px" align="start">
			<Icon as={icon} w="32px" height="32px" color={colors.primary.black} />
			<VStack spacing="8px" align="start">
				<Heading variant="Title-S-SemiBold" color="grey.900">
					{title}
				</Heading>

				{value &&
					(typeof value === 'string' ? (
						<Text variant="Title-L-Bold" color="primary.black">
							{value}
						</Text>
					) : (
						value
					))}
				{label && (
					<Text variant="Text-M-Regular" color="grey.900">
						{label}
					</Text>
				)}
			</VStack>
		</VStack>
	</Card>
);

export default MetricCard;
