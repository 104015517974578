import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CheckIcon } from '@chakra-ui/icons';
import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
	Spinner,
	Text,
	VStack,
	Wrap,
} from '@chakra-ui/react';

import DropZone from 'components/inputs/Dropzone';
// Components
import LabelWrapperInput from 'components/inputs/LabelWrapperInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import { TypeIdentite, useGetKYCJustificatifQuery, useUpdateKYCJustificatifMutation } from 'services/requests/kyc';

import typeIdentites, { convertIdentityCodeToLabel } from './typeIdentite';

// Referentiels

type JustificatifForm = {
	typeIdentite: TypeIdentite;
	fileDataRecto: File;
	fileDataVerso?: File;
};

const FormUploadIdentite = ({ onSuccess }: { onSuccess: () => void }): JSX.Element => {
	const toast = useThemedToast();
	const [modalOpened, setModalOpened] = useState(false);

	const [triggerUpdateEtatCivil, { isLoading }] = useUpdateKYCJustificatifMutation();
	const {
		isLoading: isLoadingJustificatif,
		isSuccess: isSuccessJustificatif,
		data: justificatif,
	} = useGetKYCJustificatifQuery();

	const {
		register,
		watch,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<JustificatifForm>();
	const [typeIdentite, fileDataRecto, fileDataVerso] = watch(['typeIdentite', 'fileDataRecto', 'fileDataVerso']);

	const upload = (formData: FormData) => {
		triggerUpdateEtatCivil(formData)
			.unwrap()
			.then(() => {
				toast({
					title: 'Votre document a bien été mis à jour',
					status: 'success',
					duration: 4000,
					isClosable: true,
				});
				onSuccess();
			})
			.catch(() => {
				toast({
					title: 'Erreur',
					description: "Une erreur est survenue lors de l'envoi des documents",
					status: 'error',
					duration: 4000,
					isClosable: true,
				});
			});
	};

	const onSubmit = handleSubmit((data: JustificatifForm) => {
		if (!data.fileDataRecto) {
			toast({
				title: 'Veuillez fournir les documents manquants',
				status: 'error',
				duration: 4000,
				isClosable: true,
			});
			return;
		}
		const formData = new FormData();
		formData.append('files', fileDataRecto);
		formData.append('typeIdentite', typeIdentite);
		formData.append('libelle', 'Pièce didentité');
		formData.append('code', 'Piece_identite');
		if (data.fileDataRecto && !data.fileDataVerso && data.typeIdentite !== 'passeport') {
			setModalOpened(true);
			return;
		}
		if (data.fileDataRecto && data.fileDataVerso) formData.append('files', data.fileDataVerso);
		upload(formData);
	});

	const confirmUpload = () => {
		const formData = new FormData();
		formData.append('files', fileDataRecto);
		formData.append('typeIdentite', typeIdentite);
		formData.append('libelle', 'Pièce didentité');
		formData.append('code', 'Piece_identite');
		upload(formData);
	};

	useEffect(() => {
		if (!isLoadingJustificatif && isSuccessJustificatif && justificatif && justificatif?.Piece_identite?.typeIdentity)
			setValue('typeIdentite', justificatif?.Piece_identite?.typeIdentity);
	}, [isSuccessJustificatif, justificatif, isLoadingJustificatif, setValue]);

	return (
		<>
			{modalOpened && (
				<ModalConfirmationPJ
					isOpen={modalOpened}
					onClose={() => setModalOpened(false)}
					onConfirm={confirmUpload}
					isLoading={isLoading}
				/>
			)}
			<VStack w="100%" spacing="32px" align="start" position="relative" pb="96px">
				<Wrap spacing="32px" shouldWrapChildren>
					<FormControl w={onboardingInputWidth} isInvalid={!!errors.typeIdentite}>
						<FormLabel>Choisissez votre type de justificatif d’identitié: </FormLabel>
						<Select placeholder="-" {...register('typeIdentite', { required: true })}>
							{typeIdentites.map(({ code, libelle }) => (
								<option value={code} key={code}>
									{libelle}
								</option>
							))}
						</Select>

						{errors.typeIdentite?.type === 'required' && <FormErrorMessage children="Ce champ est nécessaire" />}
					</FormControl>
					{typeIdentite && (
						<VStack align="start">
							<HStack align="start" spacing="16px">
								<CheckIcon color="green.700" />
								<Text variant="body" lineHeight="21px" w="100%">
									Veuillez joindre une copie {typeIdentite !== 'passeport' ? 'recto-verso' : ''} lisible de votre{' '}
									<b>{convertIdentityCodeToLabel(typeIdentite)}</b> en cours de validité comportant une photographie et
									une signature.
								</Text>
							</HStack>
						</VStack>
					)}
				</Wrap>
				{typeIdentite && (
					<VStack w="100%" spacing="32px" align="start">
						<LabelWrapperInput
							label="Face recto du document"
							tooltip={`Veuillez joindre une copie ${
								typeIdentite !== 'passeport' ? 'du recto ' : ''
							} de votre justificatif d’identitié en cours de validité${
								typeIdentite === 'passeport' ? ' comportant une photographie et une signature. ' : '.'
							}`}
						>
							<DropZone
								cy="docInput"
								filesUploaded={fileDataRecto !== undefined}
								filename={fileDataRecto?.name}
								onDrop={(files) => {
									setValue('fileDataRecto', files[0]);
								}}
							/>
						</LabelWrapperInput>
						{typeIdentite !== 'passeport' && (
							<LabelWrapperInput
								label="Face verso du document"
								tooltip={`Veuillez joindre une copie du verso de votre justificatif d’identitié en cours de validité. Si le Recto et le Verso de la pièce d'identité sont sur la même page vous pouvez le télécharger 1 seule fois seulement dans l'encart Recto.`}
							>
								<DropZone
									cy="docInputVerso"
									filesUploaded={fileDataVerso !== undefined}
									filename={fileDataVerso?.name}
									onDrop={(files) => {
										setValue('fileDataVerso', files[0]);
									}}
								/>
							</LabelWrapperInput>
						)}
						{/* <ArrowButton onClick={onSubmit} size="lg">
						Valider {isLoading && <Spinner />}
					</ArrowButton> */}
					</VStack>
				)}
				<BottomStepBar onNext={onSubmit} showPrev={false} isNextLoading={isLoading} />
			</VStack>
		</>
	);
};

const ModalConfirmationPJ = ({
	isOpen,
	onClose,
	onConfirm,
	isLoading,
}: {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	isLoading: boolean;
}) => (
	<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl">
		<ModalOverlay />
		<ModalContent>
			<ModalHeader>Valider la pièce d'identité</ModalHeader>
			<ModalCloseButton data-cy="close-modal-recto-without-verso" />
			<ModalBody p="32px">
				<VStack w="100%" spacing="32px">
					<Text variant="label">
						Vous n'avez ajouté qu'un seul document pour votre carte d'identité. Ce document doit contenir le recto et le
						verso de votre carte, est-ce bien le cas ?
					</Text>
					<HStack w="100%" justifyContent="space-between">
						<Button onClick={onClose} variant="secondary">
							Annuler
						</Button>
						<Button variant="primary" onClick={onConfirm}>
							{isLoading ? <Spinner /> : 'Valider'}
						</Button>
					</HStack>
				</VStack>
			</ModalBody>
		</ModalContent>
	</Modal>
);

export default FormUploadIdentite;
