import { FC } from 'react';
import { Text, VStack } from '@chakra-ui/react';

import BankAccountCard from 'components/bankingInformations/BankAccountCard';
import ErrorFieldMessage from 'components/inputs/ErrorMessage';
import { BankInformations } from 'store/types/subscription.type';
import colors from 'theme/foundations/colors';
import { isNotNone } from 'utils/functions';

interface BankAccountSelectorProps {
	selectedAccountId: string | undefined;
	errors: unknown;
	setValue: (value: BankInformations) => void;
	bankAccounts: BankInformations[] | undefined;
	title?: string;
}

const BankAccountSelector: FC<BankAccountSelectorProps> = ({
	bankAccounts,
	errors,
	selectedAccountId,
	setValue,
	title,
}) => {
	if (!bankAccounts)
		return (
			<Text variant="body" fontWeight="medium" color="red.500">
				Vous n'avez aucun compte bancaire enregistré.
			</Text>
		);

	return (
		<VStack align="start" w="100%" spacing="16px">
			<Text variant="Text-S-Medium" color={colors.primary.black}>
				{title ?? <>Quel compte bancaire souhaitez-vous utiliser pour cette opération&nbsp;?</>}
			</Text>

			<VStack align="start" w="100%">
				{bankAccounts.map((acc) => (
					<BankAccountCard
						key={acc.IBAN}
						IBAN={acc.IBAN}
						name={acc.holder}
						onSelect={() => setValue(acc)}
						value={selectedAccountId === acc.id}
					/>
				))}
			</VStack>

			{isNotNone(errors) && <ErrorFieldMessage children="Veuillez sélectionner un compte" />}
		</VStack>
	);
};

export default BankAccountSelector;
