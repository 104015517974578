import React from 'react';
import { Box, HStack, SimpleGrid, Square, Text, Tooltip, VStack } from '@chakra-ui/react';

import AssetBadge from 'components/Tags';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { InvestFundType } from 'store/types/investment.type';
import colors from 'theme/foundations/colors';
import { displayPercentage } from 'utils/rendering';

export type RepartitionDataBarProps = {
	name: InvestFundType;
	value: number;
	color: string;
};

interface RepartitionBarProps {
	data: RepartitionDataBarProps[];
	directionLegend?: 'row' | 'column';
}

const RepartitionBar = ({ data, directionLegend: requestedDirection = 'row' }: RepartitionBarProps) => {
	const filteredData = data.filter((e) => e.value > 0);
	const directionLegend = useAppResponsive<'column' | 'row'>({ base: 'column', md: requestedDirection });

	return (
		<VStack w="100%" align="start" spacing="16px">
			<HStack w="100%" h="32px" spacing={0}>
				{filteredData.map((asset) => (
					<Tooltip key={asset.name} hasArrow label={asset.name}>
						<Box h="100%" w={`${asset.value * 100}%`} bg={asset.color} />
					</Tooltip>
				))}
			</HStack>
			{directionLegend === 'column' ? (
				<SimpleGrid columns={2} rowGap="24px" columnGap="20px">
					<Text variant="Caption" color={colors.grey[900]}>
						Allocation
					</Text>
					<Text variant="Caption" color={colors.grey[900]}>
						Types d'actif
					</Text>
					{filteredData.map((asset) => (
						<React.Fragment key={asset.name}>
							<HStack key={asset.value}>
								<Square size="8px" bg={asset.color} />
								<Text variant="Text-M-Medium">{displayPercentage(asset.value)}</Text>
							</HStack>
							<AssetBadge variant={asset.name} />
						</React.Fragment>
					))}
				</SimpleGrid>
			) : (
				<HStack w="100%" spacing="24px">
					{filteredData.map((asset) => (
						<HStack key={asset.value}>
							<HStack spacing="16px">
								<Text variant="Text-M-Medium">{displayPercentage(asset.value)}</Text>
								<AssetBadge variant={asset.name} />
							</HStack>
						</HStack>
					))}
				</HStack>
			)}
		</VStack>
	);
};

export default RepartitionBar;
