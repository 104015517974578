import { useMemo } from 'react';
import { Box, HStack, Tooltip } from '@chakra-ui/react';

type Data = {
	name: string;
	value: number;
	color: string;
};

export const GraphiqueLine = ({ data }: { data: Data[] }): JSX.Element => {
	const filteredData = data.filter((e) => e.value > 0);
	const sum = useMemo(() => filteredData.reduce((acc, curr) => acc + curr.value, 0), [filteredData]);

	return (
		<HStack w="100%" spacing={0} h="32px" bg="grey.300">
			{filteredData.map((asset) => (
				<Tooltip key={asset.name} hasArrow label={asset.name}>
					<Box h="100%" w={`${(asset.value / sum) * 100}%`} bg={asset.color} />
				</Tooltip>
			))}
		</HStack>
	);
};
