import { useCallback } from 'react';
import { useBoolean } from '@chakra-ui/react';
import fileDownload from 'js-file-download';

import { InvestSubscription } from 'store/types/subscription.type';
import api from 'utils/api';

import useThemedToast from './useThemedToast';

// TODO: could be used for all downloads
// including downloadDocuments
// including downloadContractDocument

export const useDownloadBulletin = () => {
	const [isDownloading, { on: downloadStarted, off: downloadEnded }] = useBoolean();
	const toast = useThemedToast();

	const download = useCallback(
		(subscriptionId: InvestSubscription['id']) => {
			if (isDownloading) return;
			downloadStarted();
			api
				.downloadBulletin(subscriptionId)
				.then((res) => fileDownload(res.data, 'bulletin.pdf', 'application/pdf'))
				.catch(() => {
					toast({
						title: 'Une erreur est survenue',
						description: "Le fichier n'a pas pu être téléchargé... \nVeuillez nous contacter pour plus d'informations.",
						status: 'error',
						duration: 4500,
						isClosable: true,
					});
				})
				.finally(() => {
					downloadEnded();
				});
		},
		[isDownloading, downloadStarted, downloadEnded, toast],
	);

	return [isDownloading, download] as const;
};
