import { FC, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import FullscreenLoader from 'components/FullscreenLoader';
import { useGetUserSCPISubscriptionsQuery } from 'services/requests/realEstate/subscriptions';
import { isNone } from 'utils/functions';

import DashboardSCPI from '.';

const RealEstateDashboardRouter: FC = () => {
	const { data: subscriptions, isLoading } = useGetUserSCPISubscriptionsQuery();

	const hasCompletedSubscription = useMemo(() => {
		if (isNone(subscriptions)) return false;
		return subscriptions.some((sub) => sub.status === 'COMPLETED');
	}, [subscriptions]);

	if (isLoading) return <FullscreenLoader />;
	if (!hasCompletedSubscription) return <Navigate to="/immobilier" replace />;

	return (
		<Routes>
			<Route path=":id?" element={<DashboardSCPI />} />
		</Routes>
	);
};

export default RealEstateDashboardRouter;
