import { backendApi } from 'services/apiService';
import { PEFund } from 'store/types/airtable.type';
import { FoyerFiscalType } from 'store/types/KYC.type';

export type InfoPro = {
	isTns: boolean;
	annualRevenueNet: number;
};

export type PrivateEquityStrategie =
	| 'secondaries'
	| 'ventureCapital'
	| 'growthEquity'
	| 'buyout'
	| 'privateDebt'
	| 'realEstate';

export type PrivateEquityObjective = 'defiscate' | 'improve_portfolio' | '150_0_b_ter';

export type PESimulationDto = {
	objective: PrivateEquityObjective;
	initialAUM: number;
	strategies: PrivateEquityStrategie[];
	timeHorizon: number;
} & FoyerFiscalType;

export type PrivateEquitySimulation = {
	id: string;
	createdAt: string;
	updatedAt: string;
	payload: PESimulationDto;
};

export type PESimulationResult = {
	projection: {
		year: number;
		cashflow: number;
		cumulativeCashflow: number;
	}[];
	allocation: {
		fund: PEFund;
		amount: number;
	}[];
	moic: number;
	internalReturn: number;
	taxReduction: number;
};

const peSimulationApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET /
		getPrivateEquitySimulations: builder.query<PrivateEquitySimulation[], void>({
			query: () => '/private-equity/simulations',
			providesTags: (result) =>
				result
					? [
							{ type: 'PrivateEquitySimulations', id: 'LIST' },
							...result.map(({ id }) => ({ type: 'PrivateEquitySimulations' as const, id })),
					  ]
					: [{ type: 'PrivateEquitySimulations', id: 'LIST' }],
		}),
		// GET /id
		getPrivateEquitySimulationById: builder.query<PrivateEquitySimulation, string>({
			query: (id) => `/private-equity/simulations/${id}`,
			providesTags: (result, error, id) => [{ type: 'PrivateEquitySimulations', id }],
		}),
		// POST /
		createPrivateEquitySimulation: builder.mutation<PrivateEquitySimulation, PESimulationDto>({
			query: (body) => ({
				url: '/private-equity/simulations',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'PrivateEquitySimulations', id: 'LIST' }],
		}),
		// Update /id
		updatePrivateEquitySimulation: builder.mutation<PrivateEquitySimulation, { id: string; data: PESimulationDto }>({
			query: ({ id, data }) => ({
				url: `/private-equity/simulations/${id}`,
				method: 'PUT',
				body: data,
			}),
			async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
				try {
					const { data: updatedSimulation } = await queryFulfilled;
					dispatch(
						peSimulationApi.util.updateQueryData('getPrivateEquitySimulationById', id, (draft) => {
							Object.assign(draft, updatedSimulation);
						}),
					);
				} catch {}
			},
		}),
		updatePrivateEquitySimulationObjective: builder.mutation<
			PrivateEquitySimulation,
			{ id: string; data: Partial<PESimulationDto> }
		>({
			query: ({ id, data }) => ({
				url: `/private-equity/simulations/${id}/objective`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'PrivateEquitySimulations', id }],
		}),
		// GET /id/result
		getPrivateEquitySimulationResult: builder.query<PESimulationResult, string>({
			query: (id) => `/private-equity/simulations/${id}/compute`,
			providesTags: (result, error, id) => ['Fiscalite/History', { type: 'PrivateEquitySimulations', id }],
		}),
		// DELETE /id
		deletePrivateEquitySimulation: builder.mutation<void, string>({
			query: (id) => ({
				url: `/private-equity/simulations/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, id) => [
				{ type: 'PrivateEquitySimulations', id: 'LIST' },
				{ type: 'PrivateEquitySimulations', id },
			],
		}),
	}),
});

export const {
	useCreatePrivateEquitySimulationMutation,
	useUpdatePrivateEquitySimulationMutation,
	useGetPrivateEquitySimulationByIdQuery,
	useLazyGetPrivateEquitySimulationByIdQuery,
	useGetPrivateEquitySimulationsQuery,
	useUpdatePrivateEquitySimulationObjectiveMutation,
	useGetPrivateEquitySimulationResultQuery,

	useDeletePrivateEquitySimulationMutation,
} = peSimulationApi;
