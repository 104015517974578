import { MainStepType, OnboardingStep } from 'onboarding/Stepper/stepper.slice';

import { InvestSubscriptionSteps } from '../steps';

const portfolioUrl = 'portefeuille';
export const portfolioStep: OnboardingStep<InvestSubscriptionSteps> = {
	id: InvestSubscriptionSteps.PORTFOLIO,
	name: 'Portefeuille sur-mesure',
	url: portfolioUrl,
	type: MainStepType.COURSE,
};
