import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import SubmenuNavigation, { ProjectBarMenuItem } from 'components/navigation/Submenu/SubmenuNavigation';

import Details from './Details';
import Discover from './Discover';

const CorporateLayout = (): JSX.Element => {
	const navigate = useNavigate();
	const location = useLocation();

	const menu: (ProjectBarMenuItem & { route: string })[] = [
		{
			label: 'BSPCE',
			route: '/bspce',
		},
	].map((item) => {
		if (item.route === '/bspce') return location.pathname === item.route ? { active: true, ...item } : item;
		return location.pathname.startsWith(item.route) ? { active: true, ...item } : item;
	});

	return (
		<>
			<SubmenuNavigation projectBarMenuItems={menu} onClick={(i) => navigate(menu[i].route)} />
			<Outlet />
		</>
	);
};

const Corporate = (): JSX.Element => (
	<VStack h="100%" align="start" spacing={{ base: '24px', md: '32px', lg: '40px' }} pb="80px">
		<Routes>
			<Route element={<CorporateLayout />}>
				<Route index element={<Discover />} />
			</Route>
			<Route path=":id" element={<Details />} />
		</Routes>
	</VStack>
);

export default Corporate;
