import React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { HStack, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';

import { PEFund, SCPIFund } from 'store/types/airtable.type';

export type SCPISortOptionType = {
	key: string;
	label: string;
	fnct: (a: SCPIFund, b: SCPIFund) => number;
};

export type PESortOptionType = {
	key: string;
	label: string;
	fnct: (a: PEFund, b: PEFund) => number;
};

const SortingSelect = ({
	sorting,
	sortOption,
	onSelection,
}: {
	sorting: string;
	sortOption: Record<string, SCPISortOptionType | PESortOptionType>;
	onSelection: (selection: string) => void;
}) => {
	return (
		<Menu>
			<MenuButton
				w={{ base: '100%', md: 'auto' }}
				px={6}
				py={3}
				borderRadius="sm"
				borderWidth="1px"
				bgColor="white"
				borderColor="grey.500"
			>
				<HStack w="100%" justify="space-between" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
					<Text variant="Text-S-Regular">{sorting !== '' ? sortOption[sorting].label : 'Trier par'}</Text>
					<ChevronDownIcon />
				</HStack>
			</MenuButton>
			<MenuList zIndex="dropdown">
				<MenuItem onClick={() => onSelection('')}>
					<Text variant="Text-S-Regular">Trier par défaut</Text>
				</MenuItem>
				{Object.entries(sortOption).map(([key, value]) => (
					<MenuItem key={key} onClick={() => onSelection(value.key)}>
						{value.label}
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
};

export default SortingSelect;
