import { FC, useEffect } from 'react';

import { useAppDispatch } from 'hooks/useStore';
import useThemedToast from 'hooks/useThemedToast';
import { useLazyAutologinAuthentificationQuery, useLazyLoginCgpQuery } from 'services/requests/auth';
import { setIsAuth, setToken } from 'store/auth.slice';
import api from 'utils/api';

const CgpAuth: FC = () => {
	const dispatch = useAppDispatch();
	const toast = useThemedToast();

	const queryParams = new URLSearchParams(window.location.search);
	const userEmail = queryParams.get('UseEmail');
	const cgpId = queryParams.get('cgpId');
	const cgpPassword = queryParams.get('cgpPassword');
	const autologinToken = queryParams.get('autologin');

	const [autologin, { data: autologinAuthData, isLoading, isError: isAutologinInvalid, isSuccess: isLoggedIn, error }] =
		useLazyAutologinAuthentificationQuery();
	const [loginCgp, { data: authData, isFetching, isError, isSuccess }] = useLazyLoginCgpQuery();

	useEffect(() => {
		// If url parameters are valids, throw a login request
		if (userEmail && cgpId && cgpPassword) {
			loginCgp({ userEmail, cgpId, password: cgpPassword });
		}
	}, [userEmail, cgpId, cgpPassword, loginCgp]);

	useEffect(() => {
		if (autologinToken) {
			autologin({ token: autologinToken });
		}
	}, [userEmail, autologinToken, autologin]);

	useEffect(() => {
		// If the auth request success, auth the page and redirect to '/'
		if (isSuccess && authData) {
			api.setHeaderToken(authData.access_token);
			localStorage.setItem('token', authData.access_token);
			dispatch(setIsAuth(true));
			dispatch(setToken(authData.access_token));
			window.location.href = '/'; // Todo: Dirty but we need to reload the page to clear en load the new user cache
		} else if (isError) {
			toast({
				title: "Une erreur s'est produite.",
				description: 'Impossible de se connecter.',
				status: 'error',
				duration: 4500,
				isClosable: true,
			});
		}
	}, [isFetching, isSuccess, authData, dispatch, isError, toast]);

	// Autologin
	useEffect(() => {
		if (isLoggedIn && autologinAuthData) {
			api.setHeaderToken(autologinAuthData.access_token);
			localStorage.setItem('token', autologinAuthData.access_token);
			dispatch(setIsAuth(true));
			dispatch(setToken(autologinAuthData.access_token));
			window.location.href = '/'; // Todo: Dirty but we need to reload the page to clear en load the new user cache
		} else if (isAutologinInvalid && error && 'data' in error) {
			toast({
				title: "Une erreur s'est produite.",
				description: (error.data as { message: string }).message,
				status: 'error',
				duration: 4500,
				isClosable: true,
			});
		}
	}, [isLoading, isLoggedIn, autologinAuthData, isAutologinInvalid, dispatch, error, toast]);

	return null;
};
export default CgpAuth;
