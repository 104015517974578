import { Badge, Box, HStack, StackProps } from '@chakra-ui/react';

type TextDividerProps = {
	label: string;
	dark?: boolean;
};

// remove ?
const TextDivider = ({ label, dark = false, ...rest }: TextDividerProps & StackProps): JSX.Element => {
	const bgColor = dark ? '#303030' : 'white';
	const color = dark ? 'white' : '#303030';
	const dividerColor = dark ? '#303030' : '#D2D2D2';

	return (
		<HStack as="header" w="100%" spacing={0} {...rest}>
			<Badge h="40px" bg={bgColor} color={color} fontWeight={500} border="base" p="8px 24px" borderRadius="base">
				{label}
			</Badge>
			<Box w="100%" borderBottom={`2px dashed ${dividerColor}`} />
		</HStack>
	);
};

export default TextDivider;
