import { Button, Card, Heading, HStack, Text, VStack } from '@chakra-ui/react';

import { ApicilOperationType } from 'services/requests/operations';
import { EnvelopeType } from 'store/types/global.type';
import { displayMoney, formatDateStandart, getEnvelopeNameFromType } from 'utils/rendering';

type ApicilOperationCardProps = {
	envelopeType: EnvelopeType;
	montant: string;
	typeOperation: ApicilOperationType;
	dateSaisie: string;
};

const operationTypeToName: Record<ApicilOperationType, string> = {
	VERSEMENT: 'Versement',
	VERSEMENT_PROGRAMME: 'Versement programmé',
	SUPPRESSION: 'Suppression de versement programmé',
	ARBITRAGE: 'Arbitrage du contrat',

	//
	// All the following operations are unused for now :
	//
	REMBOURSEMENT_AVANCE: "Remboursement d'avance",
	RACHAT_TOTAL: 'Rachat total',
	RACHAT_PARTIEL: 'Rachat partiel',
	RACHAT_PARTIEL_PROGRAMME: 'Rachat partiel programmé',

	ARBITRAGE_PROGRAMME: 'Arbitrage programmé',
	SECURISATION_PLUS_VALUES: 'Sécurisation des plus values',
	DYNAMISATION_INTERETS: 'Dynamisation des intérêts',
	CHANGEMENT_MODE_GESTION: 'Changement de mode de gestion',

	OPTION_ARBITRAGE_PROGRAMME: 'Option arbitrage programmé',
	DEMANDE_AVANCE: "Demande d'avance",
	CHANGEMENT_COORDONNEES: 'Changement de coordonnées',
	CHANGEMENT_IBAN: "Changement d'IBAN",
};

const ApicilOperationCard = ({
	envelopeType,
	typeOperation,
	dateSaisie,
	montant,
	...rest
}: ApicilOperationCardProps): JSX.Element => (
	<Card size="sm" variant="yellow" w="100%" {...rest}>
		<VStack w="100%" align="start">
			<HStack justify="space-between" w="100%">
				<Heading variant="Title-M-Bold">{getEnvelopeNameFromType(envelopeType)}</Heading>
				<Text>{operationTypeToName[typeOperation]}</Text>
			</HStack>

			<HStack justify="space-between" w="100%">
				<Text maxW="60%" variant="Text-S-Regular">
					Demande effectuée le {formatDateStandart(new Date(dateSaisie))}
				</Text>
				{montant && (
					<Text variant="Text-M-Bold" maxW="40%">
						{displayMoney(+montant)}
					</Text>
				)}
			</HStack>
			<Button
				mt="4px"
				variant="secondary"
				alignSelf="flex-end"
				onClick={() =>
					window.Intercom(
						'showNewMessage',
						`Bonjour, j'aimerais annuler l'opération suivante : ${
							operationTypeToName[typeOperation]
						} - ${getEnvelopeNameFromType(envelopeType)}`,
					)
				}
			>
				Annuler l'opération
			</Button>
		</VStack>
	</Card>
);

export default ApicilOperationCard;
