import { useMemo } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Tab, TabList, Tabs, VStack } from '@chakra-ui/react';

import AirtableBanner from 'components/Banners/AirtableBanner';
import HeaderProductPage from 'components/HeaderProductPage';
import DiscoverPrivateEquity from 'pages/PrivateEquity/Discover';
import RecommandationPE from 'pages/PrivateEquity/Simulations';
import RecommandationPEResultRouter from 'pages/PrivateEquity/Simulations/Results';
import RecommandationPrivateEquityFormPage from 'pages/PrivateEquity/Simulations/Survey';
import SubscriptionsPe from 'pages/PrivateEquity/Subscriptions';
import { useGetUserPESubscriptionsQuery } from 'services/requests/privateEquity/subscriptions';
import { isNone } from 'utils/functions';

import PrivateEquityDashboardRouter from './Dashboard/Routes';
import PrivateEquityDetails from './Details';

type MenuItem = {
	label: string;
	route: string;
	active: boolean;
};

const PrivateEquityLayout = ({
	hasCompletedSubscription,
	hasRequestedSubscription,
}: {
	hasCompletedSubscription: boolean;
	hasRequestedSubscription: boolean;
}): JSX.Element => {
	const navigate = useNavigate();
	const location = useLocation();

	const menu = useMemo<MenuItem[]>(
		() =>
			[
				{
					label: 'Découvrir',
					route: `/private-equity`,
				},
				...(hasCompletedSubscription
					? [
							{
								label: 'Dashboard',
								route: `/private-equity/dashboard`,
							},
					  ]
					: []),
				...(hasRequestedSubscription
					? [
							{
								label: 'Souscriptions',
								route: `/private-equity/souscriptions`,
							},
					  ]
					: []),
				{
					label: 'Simulations',
					route: `/private-equity/simulation`,
				},
			].map((item) => ({
				active:
					item.route === '/private-equity'
						? location.pathname === item.route
						: location.pathname.startsWith(item.route),
				...item,
			})),
		[hasCompletedSubscription, hasRequestedSubscription, location.pathname],
	);

	const tabIndex = useMemo(() => menu.findIndex((item) => item.active), [menu]);

	return (
		<>
			<VStack w="100%" spacing="24px" position="sticky" top="0px" zIndex={2} pt="46px" bg="white">
				<HeaderProductPage variant="Private Equity" />

				<Tabs w="100%" index={tabIndex}>
					<TabList>
						{menu.map((item) => (
							<Tab key={item.label} onClick={() => navigate(item.route)}>
								{item.label}
							</Tab>
						))}
					</TabList>
				</Tabs>
			</VStack>

			<AirtableBanner />

			<Outlet />
		</>
	);
};

const PrivateEquityRouter = (): JSX.Element => {
	const { data: subscriptions, isLoading: isSubscriptionLoading } = useGetUserPESubscriptionsQuery();

	const hasCompletedSubscription = useMemo(() => {
		if (isNone(subscriptions)) return false;
		return subscriptions.some((sub) => sub.status === 'COMPLETED');
	}, [subscriptions]);

	const hasRequestedSubscription = useMemo(() => {
		if (isNone(subscriptions)) return false;
		return subscriptions.some((sub) => sub.status !== 'COMPLETED');
	}, [subscriptions]);

	return (
		<VStack w="100%" spacing="32px" align="start" pb="56px">
			<Routes>
				<Route
					element={
						<PrivateEquityLayout
							hasCompletedSubscription={hasCompletedSubscription}
							hasRequestedSubscription={hasRequestedSubscription}
						/>
					}
				>
					{isSubscriptionLoading || hasCompletedSubscription ? (
						<Route path="dashboard/*" element={<PrivateEquityDashboardRouter />} />
					) : (
						<Route path="dashboard/*" element={<Navigate to=".." />} />
					)}

					{isSubscriptionLoading || hasRequestedSubscription ? (
						<Route path="souscriptions/*" element={<SubscriptionsPe />} />
					) : (
						<Route path="souscriptions/*" element={<Navigate to=".." />} />
					)}

					<Route path="simulation/*">
						<Route index element={<RecommandationPE />} />
						<Route path="nouvelle" element={<RecommandationPrivateEquityFormPage />} />
						<Route path=":id/modifier" element={<RecommandationPrivateEquityFormPage />} />
						<Route path=":id/resultats/*" element={<RecommandationPEResultRouter />} />
					</Route>

					{/* Discover + details page */}
					<Route
						path=":id?"
						element={
							<>
								<DiscoverPrivateEquity />
								<PrivateEquityDetails />
							</>
						}
					/>
				</Route>

				<Route path="*" element={<Navigate to="" />} />
			</Routes>
		</VStack>
	);
};

export default PrivateEquityRouter;
