import { useMemo } from 'react';
import { Heading, VStack } from '@chakra-ui/react';

import FullscreenLoader from 'components/FullscreenLoader';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { SouscriptionStepProps } from 'onboarding/Stepper/stepper.slice';
import { useGetPerSubscriptionTaxEconomyQuery } from 'services/requests/invest/subscription';

import PerEconomyLastTwoYears from './Sections/PerEconomyLastTwoYears';

const TaxEconomy = ({ onNext, onPrev, subscription }: SouscriptionStepProps): JSX.Element => {
	const { data, isFetching: isTaxEconomyFetching } = useGetPerSubscriptionTaxEconomyQuery({
		subscriptionId: subscription.id,
	});

	const taxEconomy = useMemo(() => {
		if (!data) return null;
		return {
			thisYear: { label: new Date().getFullYear(), value: data.thisYear },
			nextYear: { label: new Date().getFullYear() + 1, value: data.nextYear },
		};
	}, [data]);

	if (isTaxEconomyFetching || !taxEconomy) return <FullscreenLoader />;

	return (
		<VStack w="100%" spacing="32px" align="start">
			<Heading variant="bigTitle" fontSize={{ base: 16, md: 20 }}>
				Economies d’impôt
			</Heading>

			<PerEconomyLastTwoYears
				introText="Pour atteindre vos objectifs, vous avez choisi un PER. Cette enveloppe vous permet de réduire votre impôt sur le revenu, et ne pas payer d’impôt sur les plus-values tant que vous ne retirez pas vos fonds"
				amountCurrentYear={taxEconomy.thisYear.value}
				amountNextYear={taxEconomy.nextYear.value}
				initialAumPer={+subscription.initialDepositAmount}
				reccuringAumPer={+subscription.recurrentDepositAmount}
			/>

			<BottomStepBar onPrev={onPrev} onNext={onNext} />
		</VStack>
	);
};

export default TaxEconomy;
