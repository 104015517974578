import { Card, Heading, Image, Text, VStack } from '@chakra-ui/react';

import Custom from 'assets/images/PortofolioTypes/Custom.png';
import Elite from 'assets/images/PortofolioTypes/Elite.png';
import Essential from 'assets/images/PortofolioTypes/Essential.png';
import Flagship from 'assets/images/PortofolioTypes/Flagship.png';
import { PortfolioType, portfolioTypeLabels } from 'store/types/profilage.type';
import colors from 'theme/foundations/colors';

const TypePortfolioCard = ({ type }: { type: PortfolioType }) => {
	const portfolioIcons: Record<PortfolioType, string> = {
		FLAGSHIP: Flagship,
		ESSENTIAL: Essential,
		ELITE: Elite,
		CUSTOM: Custom,
	};

	const portfolioDescriptions: Record<PortfolioType, string> = {
		ESSENTIAL: 'Un portefeuille diversifié en fonds euros et unités de compte (actions et obligations).',
		FLAGSHIP:
			'Une stratégie diversifié multi-class actifs offrant une exposition en fonds euros et unités de compte (actions, obligations et immobilier).',
		ELITE: 'Performance, volatilité maitrisée et une corrélation faible avec les marchés publics.',
		CUSTOM: 'Un portefeuille sur-mesure pour répondre à vos besoins.',
	};

	return (
		<Card
			w="100%"
			position="relative"
			variant="portfolio"
			padding="0"
			// @ts-ignore
			type={type}
			overflow="hidden"
			border={`1px solid ${colors.grey[300]}`}
		>
			<Image position="absolute" top="0" right="0" src={portfolioIcons[type]} height="100%" />
			<VStack
				zIndex={1} // zIndex used or the image will be on top of the text
				w="100%"
				align="left"
				padding="24px"
				spacing="16px"
			>
				<VStack w="100%" align="left" spacing="2px">
					<Text variant="Caption" color={colors.yellow[700]}>
						portefeuille
					</Text>
					<Heading variant="Title-XL-Bold" color={colors.primary.black}>
						{portfolioTypeLabels[type]}
					</Heading>
				</VStack>

				<Text variant="Text-M-Regular" color="grey.900">
					{portfolioDescriptions[type]}
				</Text>
			</VStack>
		</Card>
	);
};
export default TypePortfolioCard;
