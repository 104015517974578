import { useController } from 'react-hook-form';
import AsyncSelect from 'react-select/async';

import colors from 'theme/foundations/colors';

type AsyncSelectInputProps = {
	name: string;
	loader: (iv: string, callback: (o: { value: string; label: string }[]) => void) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rules: any;
	onFieldChange?: (x: { postcode: string; city: string; citycode: string }) => void;
	placeholder?: string;
	label?: string;
	cy?: string;
};

const AsynchronousSelect = ({
	name,
	placeholder,
	control,
	rules,
	onFieldChange,
	loader,
	cy,
}: AsyncSelectInputProps): JSX.Element => {
	const {
		field: { ref, onChange, value, ...inputProps },
	} = useController({
		name,
		control,
		rules,
	});
	const formattedValue =
		(value && {
			label: value.libelle || value,
			value: value.code || value,
		}) ||
		null;

	return (
		<AsyncSelect
			loadingMessage={() => 'Recherche ...'}
			noOptionsMessage={({ inputValue }) =>
				inputValue.length > 0 ? 'Aucune option disponible' : 'Recherchez votre ville'
			}
			data-cy={cy}
			id="Asyncselect"
			placeholder={placeholder}
			styles={{
				menu: (base) => ({
					...base,
					zIndex: 9999,
				}),
				container: (base) => ({
					...base,
					width: '100%',
				}),
				control: (base, state) => ({
					...base,
					border: state.isFocused ? `1px solid ${colors.primary.yellow}` : `1px solid ${colors.grey[300]}`,
					boxShadow: state.isFocused ? `0px 0px 2px 1px ${colors.yellow[100]}` : 'inherit',
					'&:hover': {
						border: state.isFocused ? `1px solid ${colors.primary.yellow}` : `1px solid ${colors.grey[900]}`,
					},
					background: '#FFFFFF',
					borderRadius: '5px',
					height: '48px !important',
				}),
				valueContainer: (base) => ({
					...base,
					paddingLeft: 16,
				}),
				indicatorSeparator: () => ({
					opacity: 0,
				}),
				dropdownIndicator: () => ({
					color: '#535353',
					paddingRight: '24px',
					//change size of arrow
					'& svg': {
						width: '18px',
						height: '18px',
						marginTop: '6px', // magic centering
					},
				}),
			}}
			cacheOptions
			loadOptions={loader}
			{...inputProps}
			onChange={(option) => {
				if (onFieldChange) onFieldChange(option?.annexValue);
				onChange(option?.value);
			}}
			value={formattedValue}
		/>
	);
};

export default AsynchronousSelect;
