export enum InvestSubscriptionSignatureSteps {
	FRAIS = 'frais',
	BENEFICIAIRES = 'beneficiaires',
	VERSEMENTS = 'versements',
	DEDUCTIBILITE = 'deductibilite',
	BANCAIRE = 'bancaire',
	BULLETIN = 'bulletin',
}

export const feesStep = {
	id: InvestSubscriptionSignatureSteps.FRAIS,
	name: 'Informations sur les frais',
	url: 'frais',
};

export const beneficiaryStep = {
	id: InvestSubscriptionSignatureSteps.BENEFICIAIRES,
	name: 'Bénéficiaires',
	url: 'beneficiaires',
};

export const versementsStep = {
	id: InvestSubscriptionSignatureSteps.VERSEMENTS,
	name: 'Versements',
	url: 'versements',
};

export const deductibilityStep = {
	id: InvestSubscriptionSignatureSteps.DEDUCTIBILITE,
	name: 'Deductibilité',
	url: 'deductibilite',
};

export const bankStep = {
	id: InvestSubscriptionSignatureSteps.BANCAIRE,
	name: 'Coordonnées bancaires',
	url: 'bancaire',
};

export const bulletinStep = {
	id: InvestSubscriptionSignatureSteps.BULLETIN,
	name: 'Bulletin de souscription',
	url: 'bulletin',
};
