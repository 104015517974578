import { backendApi } from 'services/apiService';
import { Fund } from 'store/types/airtable.type';
import { FoyerFiscalType } from 'store/types/KYC.type';

export type PreferenceDemembrement = 'nu' | 'plein' | 'ramify';
export type PreferenceGeographie = 'Idf' | 'Etranger' | 'Régions';
export type PreferenceSecteur = 'Résidentiel' | 'Commerces' | 'Bureaux' | 'Santé';

export type RealEstateSimulationDto = {
	initialAUM: number;
	timeHorizon: number;

	isISR: boolean;
	prefDemembrement: PreferenceDemembrement;

	prefGeographie: PreferenceGeographie[];
	prefSecteur: PreferenceSecteur[];
} & FoyerFiscalType;

export type RealEstateSimulation = {
	id: string;
	createdAt: string;
	updatedAt: string;
	payload: RealEstateSimulationDto;
};

export type Allocation = {
	parts_plein: number;
	parts_nue: number;
	montant_plein: number;
	montant_nu: number;
	id_airtable: string;
	fundCover: Fund['fundCover'];
	partner: string;
};

export type Stats = {
	montant_investi: number;
	annual_dividends_net_of_fees_and_tax: number;
	capital_evolution: number;
	tri: number;
};

export type RealEstateSimulationResult = {
	allocation: Record<string, Allocation>;
	valo: number[];
	div: number[];
	index: number[];
	stats: Record<string, number>;
};

export type SubscriptionRepartition = {
	fundName: string;
	fundId: string;
	nbParts: number;
	amountVersement: number;
};

export interface SCPISubscriptionRepartition extends SubscriptionRepartition {
	type: 'nue' | 'plein';
}

const realEstateApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getRealEstateSimulations: builder.query<RealEstateSimulation[], void>({
			query: () => '/real-estate/simulation',
			providesTags: (result) =>
				result
					? [
							{ type: 'RealEstateSimulation', id: 'LIST' },
							...result.map(({ id }) => ({ type: 'RealEstateSimulation' as const, id })),
					  ]
					: [{ type: 'RealEstateSimulation', id: 'LIST' }],
		}),

		getRealEstateSimulationsById: builder.query<RealEstateSimulation, string>({
			query: (id) => `/real-estate/simulation/${id}`,
			providesTags: (result, error, id) => [{ type: 'RealEstateSimulation', id }],
		}),

		createRealEstateSimulations: builder.mutation<RealEstateSimulation, RealEstateSimulationDto>({
			query: (body) => ({
				url: '/real-estate/simulation',
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: 'RealEstateSimulation', id: 'LIST' }],
		}),

		updateRealEstateSimulations: builder.mutation<RealEstateSimulation, { id: string; data: RealEstateSimulationDto }>({
			query: ({ id, data }) => ({
				url: `/real-estate/simulation/${id}`,
				method: 'PUT',
				body: data,
			}),
			async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
				try {
					const { data: updatedSimulation } = await queryFulfilled;
					dispatch(
						realEstateApi.util.updateQueryData('getRealEstateSimulationsById', id, (draft) => {
							Object.assign(draft, updatedSimulation);
						}),
					);
				} catch {}
			},
		}),

		updateRealEstateSimulationsObjective: builder.mutation<
			RealEstateSimulation,
			{ id: string; data: Partial<RealEstateSimulationDto> }
		>({
			query: ({ id, data }) => ({
				url: `/real-estate/simulation/${id}/objective`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'RealEstateSimulation', id }],
		}),

		getRealEstateSimulationsResult: builder.query<RealEstateSimulationResult, string>({
			query: (id) => `/real-estate/simulation/${id}/compute`,
			providesTags: (result, error, id) => ['Fiscalite/History', { type: 'RealEstateSimulation', id }],
		}),

		deleteRealEstateSimulations: builder.mutation<void, string>({
			query: (id) => ({
				url: `/real-estate/simulation/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, id) => [
				{ type: 'RealEstateSimulation', id: 'LIST' },
				{ type: 'RealEstateSimulation', id },
			],
		}),
	}),
});

export const {
	useDeleteRealEstateSimulationsMutation,
	useGetRealEstateSimulationsResultQuery,
	useGetRealEstateSimulationsQuery,
	useCreateRealEstateSimulationsMutation,
	useUpdateRealEstateSimulationsMutation,
	useUpdateRealEstateSimulationsObjectiveMutation,
	useGetRealEstateSimulationsByIdQuery,
} = realEstateApi;
