import { FC, ReactNode } from 'react';
import { AgricultureAnalytics, ChartMedian, Wallet } from '@carbon/icons-react';
import { Heading, Wrap, WrapItem } from '@chakra-ui/react';

import { PerformanceBadge } from 'components/Metrics/PerformanceBadge';
import { ProductData } from 'services/requests/product';
import { isNotNone } from 'utils/functions';
import { displayMoney } from 'utils/rendering';

import InvestmentMetricCard from './Card';

const InvestmentMetrics: FC<{ data: ProductData; cta?: ReactNode }> = ({ data, cta }) => {
	return (
		<Wrap w="100%" spacingX="md">
			{isNotNone(data.valuation) && (
				<WrapItem flex={1} minW="260px">
					<InvestmentMetricCard icon={<AgricultureAnalytics size="32" />} title="Valorisation">
						<Heading variant="Title-L-Bold">{displayMoney(data.valuation)}</Heading>
					</InvestmentMetricCard>
				</WrapItem>
			)}
			{/* Hide if performance values are both 0 */}
			{!!(data.performance?.value || data.performance?.percentage) && (
				<WrapItem flex={1} minW="260px">
					<InvestmentMetricCard icon={<ChartMedian size="32" />} title="Gains / Pertes">
						<PerformanceBadge isTitle amount={data.performance?.value} percentage={data.performance?.percentage} />
					</InvestmentMetricCard>
				</WrapItem>
			)}
			{isNotNone(data.investedAmount) && (
				<WrapItem flex={1} minW="260px">
					<InvestmentMetricCard icon={<Wallet size="32" />} title="Montant versé" cta={cta}>
						<Heading variant="Title-L-Bold">{displayMoney(data.investedAmount)}</Heading>
					</InvestmentMetricCard>
				</WrapItem>
			)}
		</Wrap>
	);
};

export default InvestmentMetrics;
