import { FC, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import { Analytics, ArrowLeft, DocumentDownload, Edit, Reminder } from '@carbon/icons-react';
import {
	Badge,
	Box,
	Button,
	Card,
	chakra,
	Circle,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	HStack,
	IconButton,
	Image,
	Skeleton,
	Spinner,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import MoralPersonBlockerCard from 'features/MoralPersonBlockerCard';
import PrivateEquityIntention from 'formContexts/PrivateEquityIntention';

import Banner from 'components/Banners/Banner';
import { GraphiqueLine } from 'components/charts/GraphiqueLine';
import CustomTooltip from 'components/CustomTooltip';
import ListChecked, { ListCheckedChildrenProps } from 'components/ListChecked';
import InvestmentMetricCard from 'components/Metrics/InvestmentMetrics/Card';
import CustomModal from 'components/Modal';
import { CalendlyModal } from 'components/Modals/Calendly';
import { useDrawer } from 'hooks/useDrawer';
import useThemedToast from 'hooks/useThemedToast';
import { PEFundTaxAdvantageType, peTagsDisplay } from 'pages/PrivateEquity/Discover/options.constants';
import eventTracker from 'services/events/eventTracker';
import {
	useGetPEFundByIdQuery,
	useGetPEFundDetailsQuery,
	useGetPEFundTimelineQuery,
} from 'services/requests/privateEquity/funds';
import { useCreatePESubscriptionMutation } from 'services/requests/privateEquity/subscriptions';
import { PEFund, privateEquitySectorsOptions } from 'store/types/airtable.type';
import { PeIntention } from 'store/types/peKyc.type';
import { isNone, isNotNone } from 'utils/functions';
import { displayMoneyNoDigits, displayPercentage } from 'utils/rendering';

const buildFundCloseDate = (fund: PEFund): string | null => {
	if (!fund.subscriptionEndDate) return 'N/D';
	if (fund.subscriptionEndDate === 'evergreen') return null;
	return fund.subscriptionEndDate;
};

type SubscriptionModalProps = {
	isOpen: boolean;
	onClose: () => void;
	fund: PEFund;
};

export const SubscriptionModal = ({ isOpen, onClose, fund }: SubscriptionModalProps) => {
	const [completedData, setCompletedData] = useState<PeIntention>();
	const toast = useThemedToast();
	const [createPeSubscription, { isLoading: isCreatingSub }] = useCreatePESubscriptionMutation();
	const [subId, setSubId] = useState<string>();

	const methods = useForm<PeIntention>({
		mode: 'onChange',
	});
	const { handleSubmit } = methods;

	const onSubmit = handleSubmit((data: PeIntention) => {
		createPeSubscription({
			...data,
			fundId: fund.id,
			amountVersement: +data.amountVersement,
		})
			.unwrap()
			.then((newSub) => {
				eventTracker.pipedream.peIntention(+data.amountVersement, fund.name);
				setSubId(newSub.id);
				setCompletedData(data);
			})
			.catch((error) => {
				toast({
					title: 'Une erreur est survenue',
					description: error.data.message,
					status: 'error',
					duration: 4500,
					isClosable: true,
				});
			});
	});

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			headersProps={{
				children: !completedData
					? `Investir dans ${fund.name}`
					: 'Votre demande a été prise en compte, pour la suite...',
			}}
			contentProps={{ py: '16px' }}
			size={{ base: 'md', lg: '4xl' }}
		>
			{!completedData ? (
				isCreatingSub ? (
					<VStack align="center">
						<Spinner />
					</VStack>
				) : (
					<FormProvider {...methods}>
						<chakra.form onSubmit={onSubmit} w="100%">
							<VStack spacing="24px" align="start">
								<PrivateEquityIntention fund={fund} />

								<Button type="submit" onClick={onSubmit}>
									Confirmer
								</Button>
							</VStack>
						</chakra.form>
					</FormProvider>
				)
			) : (
				<VStack spacing="24px">
					{completedData.legalEntity === 'PHYSIQUE' ? (
						<Navigate to={`/private-equity/onboarding/${subId}`} />
					) : (
						<MoralPersonBlockerCard />
					)}
				</VStack>
			)}
		</CustomModal>
	);
};

type PrivateEquityStrategyType =
	| 'Growth Equity'
	| 'Buyout'
	| 'Private Debt'
	| 'Venture Capital'
	| 'Secondaries'
	| 'Impact'
	| 'Real Estate';

const peStrategyToType: {
	[key: string]: PrivateEquityStrategyType;
} = {
	GrowthEquity: 'Growth Equity',
	Buyout: 'Buyout',
	VC: 'Venture Capital',
	RealEstate: 'Real Estate',
	PrivateDebt: 'Private Debt',
	Secondaries: 'Secondaries',
	Impact: 'Impact',
};

const PrivateEquityDetails: FC = () => {
	const { id } = useParams();

	// Requests
	const { data: fund, isFetching: isFundFetching } = useGetPEFundByIdQuery(id!, { skip: !id });
	const { data: fundDetails, isFetching: isDetailsFetching } = useGetPEFundDetailsQuery(id!, { skip: !id });
	const { data: fundTimeline, isFetching: isTimelineFetching } = useGetPEFundTimelineQuery(id!, { skip: !id });

	const { isOpen, onOpen, onClose } = useDisclosure();

	// tag is not storeed in one field like for SCPIs, but in 3 different fields with boolean values
	const taxAdvantageTags = [
		fund?.reinvestTaxAdvantage ? 'reinvestTaxAdvantage' : undefined,
		fund?.gainsTaxAdvantage ? 'gainsTaxAdvantage' : undefined,
		fund?.revenueTaxAdvantage ? 'revenueTaxAdvantage' : undefined,
	].filter((e) => e !== undefined) as PEFundTaxAdvantageType[];

	const isLoading = useMemo<boolean>(
		() => isFundFetching || isDetailsFetching || isTimelineFetching,
		[isDetailsFetching, isFundFetching, isTimelineFetching],
	);

	const timelineDisplay = fundTimeline?.slice(0).sort((a, b) => dayjs(a.Index).unix() - dayjs(b.Index).unix());
	const DetailsHeader: FC = () => (
		<Stack direction={{ base: 'column', lg: 'row' }} spacing="lg">
			<Box w="300px">
				<Image objectFit="cover" w="100%" h="100%" borderRadius="base" src={fund?.fundCover?.[0]?.url} />
			</Box>

			<VStack p="16px" h="100%" align="start" justify="space-between" flex="1">
				<VStack align="start">
					<Text variant="Caption">{fund?.partner}</Text>
					<Heading variant="Text-XL-Bold">{fund?.name}</Heading>
					<Text variant="Text-M-Regular" color="grey.900">
						{fundDetails?.fundDescription}
					</Text>
				</VStack>
				{taxAdvantageTags && (
					<Wrap w="100%" align="start" opacity={0.6}>
						{taxAdvantageTags.map((tag) => (
							<WrapItem key={tag}>
								<Badge variant="outlineYellow">{peTagsDisplay[tag].display}</Badge>
							</WrapItem>
						))}
					</Wrap>
				)}
			</VStack>
		</Stack>
	);

	const Dates: FC = () => (
		<Card w="100%">
			<VStack align="start" minW={{ base: '100%', md: 'fit-content' }} spacing="md">
				<Heading variant="Title-M-SemiBold">Dates clés</Heading>
				<Wrap align="start" spacing="26px">
					{timelineDisplay?.map((e) => (
						<WrapItem key={`${e.Index}${e.Période}`}>
							<VStack align="start" spacing={0}>
								<Text variant="Text-S-Regular">{e.Période}</Text>
								<Heading variant="Title-S-Bold">{dayjs(e.Index).toDate().toLocaleDateString('fr-FR')}</Heading>
							</VStack>
						</WrapItem>
					))}
				</Wrap>
			</VStack>
		</Card>
	);

	const Team: FC = () => (
		<VStack align="start" w="100%">
			<Heading variant="Title-M-SemiBold">Equipe de gestion</Heading>
			<Text variant="Text-M-Regular">{fundDetails?.managementTeamDescription}</Text>
		</VStack>
	);

	const Composition: FC = () => {
		if (isNone(fundDetails?.portfolioComposition?.[0]?.url)) return null;

		return (
			<Card w="100%">
				<VStack align="start" w="100%">
					<HStack>
						<Heading variant="Title-M-SemiBold">Composition du portefeuille</Heading>

						{fundDetails?.imgPortfolioCompositionTooltip && (
							<CustomTooltip text={fundDetails?.imgPortfolioCompositionTooltip} />
						)}
					</HStack>
					<Image w="100%" src={fundDetails?.portfolioComposition?.[0]?.url} />
				</VStack>
			</Card>
		);
	};

	const colorGeo = ['yellow.600', 'yellow.500', 'yellow.400'];

	/*
		S'il y a de la donnée : ex : PrivateDebt (20%) -> checked
		Pas de données mais dans la liste -> checked
		0% ou pas dans la liste -> pas checked
	*/
	const strategyDataChecked: ListCheckedChildrenProps[] | undefined = Object.entries(peStrategyToType).map(
		([key, label]: [string, PrivateEquityStrategyType]) => {
			const valuePercentage =
				fundDetails &&
				(Object.entries(fundDetails).find(([x]) => x.replace('strategy', '') === key)?.[1] as number) * 100;

			return {
				label: `${label}${valuePercentage && valuePercentage > 0 ? ` (${valuePercentage}%)` : ''}`,
				checked: valuePercentage && valuePercentage > 0 ? true : fundDetails?.strategies?.includes(label),
			};
		},
	);

	const displayGeo =
		fundDetails &&
		Object.entries(fundDetails)
			// @ts-ignore eror only in vscode
			.filter(([key, value]) => key.startsWith('geo') && value > 0)
			.map(([key, value]) => ({
				name: key.replace('geo', ''),
				value,
			}))
			.sort(({ value: value1 }, { value: value2 }) => (value2 as number) - (value1 as number))
			.map(({ name, value }, idx) => ({
				name,
				color: colorGeo[idx],
				value: value as number,
			}));

	const displaySectors =
		fundDetails && privateEquitySectorsOptions.map((e) => ({ label: e, checked: fundDetails.sectors?.includes(e) }));

	const { isOpen: isDrawerOpen, onClose: onCloseDrawer } = useDrawer();

	const { isOpen: isCalendlyOpen, onOpen: onOpenCalendly, onClose: onCloseCalendly } = useDisclosure();

	return (
		<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isDrawerOpen} onClose={onCloseDrawer}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<HStack w="100%" justify="space-between">
						<IconButton
							aria-label="retour"
							variant="icon"
							alignSelf="start"
							onClick={onCloseDrawer}
							icon={
								<Box>
									<ArrowLeft size="32" />
								</Box>
							}
						/>
						<Button size="lg" onClick={onOpen}>
							Investir
						</Button>
					</HStack>
				</DrawerHeader>
				<DrawerBody>
					<CalendlyModal isOpen={isCalendlyOpen} onClose={onCloseCalendly} />
					<Skeleton isLoaded={isNotNone(fundDetails) && isNotNone(fund) && !isLoading} />
					<VStack w="100%" spacing="32px" py="6" align="start">
						<Skeleton isLoaded={!isLoading} w="100%" h={isLoading ? '300px' : 'auto'}>
							{!fund ? (
								<Text>Aucun fonds n'a été trouvé</Text>
							) : (
								<VStack align="start" spacing="24px" w="100%">
									<DetailsHeader />
									<Stack direction={{ base: 'column', md: 'row' }} w="100%" spacing="24px">
										<InvestmentMetricCard
											title={fundDetails?.performanceType ?? ''}
											tooltip={fundDetails?.performanceTooltip}
											icon={<Analytics size="32" />}
										>
											<HStack>
												<Heading variant="Title-L-Bold">{fundDetails?.performance}</Heading>
												<Text variant="Text-S-Regular">{fundDetails?.performanceDescription}</Text>
											</HStack>
										</InvestmentMetricCard>
										<InvestmentMetricCard title="Souscription minimum" icon={<Edit size="32" />}>
											<Heading variant="Title-L-Bold">{displayMoneyNoDigits(fund?.ticket ?? 0)}</Heading>
										</InvestmentMetricCard>
										{isNotNone(buildFundCloseDate(fund)) && (
											<InvestmentMetricCard title="Date de fermeture" icon={<Reminder size="32" />}>
												<Heading variant="Title-L-Bold">{buildFundCloseDate(fund)}</Heading>
											</InvestmentMetricCard>
										)}
									</Stack>

									{fundDetails?.ramifyOpinion && (
										<Banner
											variant="information"
											avatar="olivier"
											onClick={onOpenCalendly}
											description={fundDetails.ramifyOpinion}
											title={`Point de vue de Ramify sur ${fund.name}`}
											cta="En discuter avec un conseiller"
										/>
									)}
									<Team />
									<Composition />

									<Stack direction={{ base: 'column', md: 'row' }} w="100%" align="stretch" spacing="md">
										<Card flex={1} size="sm" w="100%">
											<VStack align="start" spacing="24px" w="100%">
												<Heading variant="Title-M-SemiBold">Répartition géographique</Heading>
												{displayGeo && (
													<VStack align="start" spacing="24px" w="100%">
														<GraphiqueLine data={displayGeo} />

														<TableContainer>
															<Table variant="unstyled">
																<Thead>
																	<Tr>
																		<Th pl="0px">
																			<Text variant="Caption" color="grey.900">
																				Répartition
																			</Text>
																		</Th>
																		<Th>
																			<Text variant="Caption" color="grey.900">
																				Secteur
																			</Text>
																		</Th>
																	</Tr>
																</Thead>
																<Tbody>
																	{displayGeo.map((asset) => (
																		<Tr key={asset.name}>
																			<Td p="8px 0px">
																				<HStack>
																					<Circle size="12px" bg={asset.color} />
																					<Text variant="Text-M-Medium">{displayPercentage(asset.value)}</Text>
																				</HStack>
																			</Td>
																			<Td p="8px 24px">
																				<Text variant="Text-M-Regular" color="grey.900">
																					{asset.name}
																				</Text>
																			</Td>
																		</Tr>
																	))}
																</Tbody>
															</Table>
														</TableContainer>
													</VStack>
												)}
											</VStack>
										</Card>

										<Card flex={1} size="sm">
											<VStack align="start" spacing="24px" w="100%">
												<Heading variant="Title-M-SemiBold">Répartition stratégique</Heading>
												<VStack spacing="8px" align="start" w="100%">
													<ListChecked children={strategyDataChecked} />
												</VStack>
											</VStack>
										</Card>

										<Card flex={1} size="sm">
											<VStack align="start" spacing="24px" w="100%">
												<Heading variant="Title-M-SemiBold">Répartition sectorielle</Heading>
												<VStack spacing="8px" align="start" w="100%">
													<ListChecked children={displaySectors} />
												</VStack>
											</VStack>
										</Card>
									</Stack>

									<Dates />

									<VStack align="start" spacing="sm" w="100%">
										<Heading variant="Title-M-SemiBold">Structure et termes</Heading>
										<Card p="0" w="100%">
											<TableContainer>
												<Table variant="striped" colorScheme="gray">
													<Tbody style={{ fontVariantNumeric: 'normal' }}>
														{/* empty row to flip the strip sequence */}
														<Tr />
														<Tr>
															<Td p="8px 24px">
																<Text variant="Text-S-Regular">Nom du fonds</Text>
															</Td>
															<Td p="8px 24px">
																<Text variant="Text-S-Medium" color="grey.900">
																	{fund.name}
																</Text>
															</Td>
														</Tr>
														<Tr>
															<Td p="8px 24px">
																<Text variant="Text-S-Regular">Structure légale</Text>
															</Td>
															<Td p="8px 24px">
																<Text variant="Text-S-Medium" color="grey.900">
																	{fundDetails?.structureLégale}
																</Text>
															</Td>
														</Tr>
														<Tr>
															<Td p="8px 24px">
																<Text variant="Text-S-Regular">Taille cible</Text>
															</Td>
															<Td p="8px 24px">
																<Text variant="Text-S-Medium" color="grey.900">
																	{fundDetails && fundDetails.tailleCible
																		? (fundDetails.tailleCible / 1_000_000).toLocaleString('fr-FR', {
																				minimumFractionDigits: 1,
																				maximumFractionDigits: 1,
																		  })
																		: '-'}{' '}
																	M€
																</Text>
															</Td>
														</Tr>
														<Tr>
															<Td p="8px 24px">
																<Text variant="Text-S-Regular">Statut</Text>
															</Td>
															<Td p="8px 24px">
																<Text variant="Text-S-Medium" color="grey.900">
																	{buildFundCloseDate(fund)}
																</Text>
															</Td>
														</Tr>
														<Tr>
															<Td p="8px 24px">
																<Text variant="Text-S-Regular">Frais de gestion</Text>
															</Td>
															<Td p="8px 24px">
																<Text variant="Text-S-Medium" color="grey.900">
																	{fundDetails && displayPercentage(fundDetails.fraisDeGestion)}
																</Text>
															</Td>
														</Tr>
														<Tr>
															<Td p="8px 24px">
																<HStack>
																	<Text variant="Text-S-Regular">Hurdle rate</Text>
																	{fundDetails?.hurdleTooltip && <CustomTooltip text={fundDetails?.hurdleTooltip} />}
																</HStack>
															</Td>
															<Td p="8px 24px">
																<Text variant="Text-S-Medium" color="grey.900">
																	{fundDetails?.hurdle}
																</Text>
															</Td>
														</Tr>
														<Tr>
															<Td p="8px 24px">
																<HStack>
																	<Text variant="Text-S-Regular">Carried interest</Text>
																	{fundDetails?.CarryTooltip && <CustomTooltip text={fundDetails?.CarryTooltip} />}
																</HStack>
															</Td>
															<Td p="8px 24px">
																<Text variant="Text-S-Medium" color="grey.900">
																	{fundDetails && displayPercentage(fundDetails.Carry)}
																</Text>
															</Td>
														</Tr>
													</Tbody>
												</Table>
											</TableContainer>
										</Card>
									</VStack>

									{(isNotNone(fund.fundFlyer?.[0]?.url) || isNotNone(fund.fundDICI?.[0]?.url)) && (
										<VStack w="100%" align="start" spacing="sm">
											<Heading variant="Title-M-SemiBold">Documents et ressources</Heading>
											<HStack spacing="16px" w="100%">
												{isNotNone(fund.fundDICI?.[0]?.url) && (
													<Button
														variant="secondary"
														rightIcon={
															<Box>
																<DocumentDownload size="16" />
															</Box>
														}
														onClick={() => {
															if (fund.fundDICI?.[0]?.url) window.open(fund.fundDICI?.[0]?.url);
														}}
													>
														DIC
													</Button>
												)}
												{isNotNone(fund.fundFlyer?.[0]?.url) && (
													<Button
														variant="secondary"
														rightIcon={
															<Box>
																<DocumentDownload size="16" />
															</Box>
														}
														onClick={() => {
															if (fund.fundFlyer?.[0]?.url) window.open(fund.fundFlyer?.[0]?.url);
														}}
													>
														Plaquette
													</Button>
												)}
											</HStack>
										</VStack>
									)}
								</VStack>
							)}
						</Skeleton>
						{fund && <SubscriptionModal isOpen={isOpen} onClose={onClose} fund={fund} />}
					</VStack>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default PrivateEquityDetails;
