import { FC } from 'react';
import { VStack } from '@chakra-ui/react';

import FinalizingSubscription from 'components/cards/subscriptions/FinalizingSubscription';
import ValidatingSubscription from 'components/cards/subscriptions/ValidatingSubscription';
import subscriptionSort from 'pages/Subscriptions/features/subscriptionSort';
import { ProductSubscriptionType } from 'store/types/global.type';

type SubscriptionsSectionProps = {
	availableSubscriptions: ProductSubscriptionType[];
	currentSubscriptions: ProductSubscriptionType[];
};

const SubscriptionsSection: FC<SubscriptionsSectionProps> = ({ availableSubscriptions, currentSubscriptions }) => (
	<VStack spacing="32px" w="100%" align="start">
		{availableSubscriptions.length !== 0 && (
			<VStack spacing="16px" w="100%" align="start">
				{subscriptionSort(availableSubscriptions)?.map((subscription) => (
					<FinalizingSubscription key={subscription.id} {...subscription} showAmount canBeDeleted={false} />
				))}
			</VStack>
		)}
		{currentSubscriptions.length !== 0 && (
			<VStack spacing="16px" w="100%" align="start">
				{currentSubscriptions.map((subscription) => (
					<ValidatingSubscription key={subscription.id} {...subscription} showAmount canBeDeleted={false} />
				))}
			</VStack>
		)}
	</VStack>
);

export default SubscriptionsSection;
