import { useMemo } from 'react';
import { Popover, PopoverContent, PopoverTrigger, Skeleton, Text, VStack } from '@chakra-ui/react';

import { PortfolioScenarios } from 'store/types/portfolio.type';
import { isNotNone } from 'utils/functions';
import { displayNumberRoundedThousands } from 'utils/rendering';

const getProfit = (scenario: number[]): string | undefined => {
	if (scenario.length === 0) return undefined;

	return displayNumberRoundedThousands(Math.round(scenario.at(-1)!)).toLocaleString('fr-FR', undefined);
};

const Profit = (scenarios: Pick<PortfolioScenarios, 'upper' | 'lower'>): JSX.Element => {
	const maxProfit = useMemo(() => getProfit(scenarios.upper), [scenarios.upper]);
	const minProfit = useMemo(() => getProfit(scenarios.lower), [scenarios.lower]);
	const isLoaded = useMemo(() => isNotNone(maxProfit) && isNotNone(minProfit), [maxProfit, minProfit]);

	return (
		<VStack spacing="8px" align="start" w="100%">
			<Skeleton isLoaded={isLoaded}>
				<Text variant="Title-XL-Bold">
					{minProfit}€ à {maxProfit}€{' '}
					<Text as="span" variant="Text-M-Medium">
						net d'impôt
					</Text>
				</Text>
			</Skeleton>

			<Popover trigger="hover">
				<PopoverTrigger>
					<Text>
						<Text as="span" variant="Text-M-Regular" color="grey.900">
							Selon la rentabilité de votre portefeuille.{' '}
						</Text>
						<Text as="span" cursor="pointer" textDecoration="underline" variant="Text-M-SemiBold">
							Comment avons-nous calculé cette estimation&nbsp;?
						</Text>
					</Text>
				</PopoverTrigger>
				<PopoverContent p={5} w={{ base: '320px', lg: '540px' }} bg="primary.black">
					<VStack w="100%" align="start" spacing="24px">
						<Text variant="Text-S-Regular" color="white">
							Nous calculons un <b>ensemble de scénarios</b> correspondant au niveau de risque que vous avez choisi.
							<br />
							<br />
							Selon nos calculs de risque,{' '}
							<b>80% des scénarios se situent entre le cas favorable et le cas défavorable</b> présentés dans le
							graphique suivant.
						</Text>
						<Text variant="Text-S-Regular" color="white">
							<b>Attention</b>: il ne s’agit pas d’une prédiction de la performance future de vos investissements.
							Gardez toujours à l’esprit que tout placement présente un risque de perte de capital, les gains et les
							pertes peuvent dépasser les montants affichés dans les cas les plus favorables et défavorables.
						</Text>
					</VStack>
				</PopoverContent>
			</Popover>
		</VStack>
	);
};

export default Profit;
