import { useEffect, useMemo, useState } from 'react';
import { Box, Card, HStack, Skeleton, StackProps, Text, VStack } from '@chakra-ui/react';
import { Layer, ResponsiveLine, SliceTooltipProps } from '@nivo/line';
import dayjs from 'dayjs';

import { MentionUC } from 'components/compliance/MentionUC';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { LineChartElement } from 'onboarding/Invest/Simulation/Result';
import { PortfolioScenarios, PortfolioScenariosReturns } from 'store/types/portfolio.type';
import colors from 'theme/foundations/colors';
import { displayMoneyNoDigits } from 'utils/rendering';

type LabelNamesType = {
	lower: string;
	average: string;
	upper: string;
};

const formatPortfolioScenarios = (
	portfolioScenarios: PortfolioScenarios,
	labelNames: LabelNamesType,
): LineChartElement[] => {
	const lineChartElements: LineChartElement[] = [];

	lineChartElements.push({
		id: 'Versements',
		color: colors.grey[300],
		data: portfolioScenarios.versements.map((amount: number, index) => ({
			x: new Date().getFullYear() + index,
			y: amount,
		})),
	});
	lineChartElements.push({
		id: labelNames.lower,
		color: colors.red[500],
		data: portfolioScenarios.lower.map((amount: number, index) => ({
			x: new Date().getFullYear() + index,
			y: amount,
		})),
	});
	lineChartElements.push({
		id: labelNames.average,
		color: colors.blue[500],
		data: portfolioScenarios.average.map((amount: number, index) => ({
			x: new Date().getFullYear() + index,
			y: amount,
		})),
	});
	lineChartElements.push({
		id: labelNames.upper,
		color: colors.green[500],
		data: portfolioScenarios.upper.map((amount: number, index) => ({
			x: new Date().getFullYear() + index,
			y: amount,
		})),
	});

	return lineChartElements;
};

const PortfolioScenariosGraph = ({
	portfolioScenarios: { scenarios, returns },
	...props
}: {
	portfolioScenarios: { scenarios: PortfolioScenarios; returns: PortfolioScenariosReturns };
} & StackProps): JSX.Element => {
	// const data = formatPortfolioScenarios(portfolioScenarios);
	const [localData, setLocalData] = useState<LineChartElement[]>([]);

	const labelNames = useMemo<LabelNamesType>(
		() => ({
			lower: `Scénario défavorable (${(returns.lower * 100).toFixed(2)}% par an)`,
			average: `Scénario moyen (${(returns.average * 100).toFixed(2)} % par an)`,
			upper: `Scénario favorable (${(returns.upper * 100).toFixed(2)} % par an)`,
		}),
		[returns],
	);

	const theme = {
		background: '#ffffff',
		fontSize: 12,
		fontFamily: 'Poppins, sans-serif',
		grid: {
			line: {
				stroke: colors.grey[300],
				strokeWidth: 1,
				strokeDasharray: '5 5',
			},
		},
	};

	// https://github.com/plouc/nivo/issues/1006#issuecomment-797091909
	useEffect(() => {
		if (scenarios !== undefined && labelNames !== undefined)
			setLocalData(formatPortfolioScenarios(scenarios, labelNames));
	}, [scenarios, labelNames, setLocalData]);

	const legendDirection: 'row' | 'column' = useAppResponsive({ base: 'column', md: 'column' }) || 'row';

	const toolTipElement = (data: SliceTooltipProps): JSX.Element => (
		<VStack bg="white" boxShadow="rgba(0, 0, 0, 0.25) 0px 1px 2px" padding="5px 9px">
			<Text variant="Text-M-Bold">
				{data.slice.points[0].data.x === new Date().getFullYear()
					? dayjs().format('Do MMMM')
					: dayjs('2021-12-31').format('Do MMMM')}{' '}
				{(data.slice.points[0].data.x as number) - 1}
			</Text>
			{data.slice.points.map((caseV, idx) => (
				<HStack align="center" w="100%" key={idx}>
					<Box w="16px" h="16px" bg={caseV.color} />
					<HStack justify="space-between !important" w="100%">
						<Text variant="Text-S-Regular">{caseV.serieId}</Text>
						<Text variant="Text-S-Bold">{displayMoneyNoDigits(Number(caseV.data.yFormatted))}</Text>
					</HStack>
				</HStack>
			))}
		</VStack>
	);

	return (
		<Card w="100%">
			<VStack w="100%" maxW="100% !important" align="start" {...props}>
				<VStack w="100%" align="start" spacing="16px" mb="24px">
					<Text variant="Title-M-SemiBold">Votre projet avec Ramify</Text>
					<Text variant="Text-M-Regular" color="grey.900">
						Scénarios possibles pour l'évolution de vos investissements <b>(Capital net de frais et d'impôt)</b>.
					</Text>
				</VStack>
				<Box w="100%" h="400px">
					{localData.length > 0 ? (
						<ResponsiveLine
							sliceTooltip={toolTipElement}
							data={localData}
							margin={{ top: legendDirection === 'row' ? 50 : 132, right: 16, bottom: 56, left: 56 }}
							xScale={{ type: 'point' }}
							yScale={{ type: 'linear', min: 0, max: 'auto', reverse: false }}
							yFormat=" >-.0f"
							curve="natural"
							theme={theme}
							colors={(element) => element.color}
							enableArea
							areaBaselineValue={0}
							areaOpacity={0.72}
							layers={['areas', 'grid', 'axes', 'lines', 'crosshair', 'slices', 'mesh', 'legends'] as Layer[]}
							defs={[
								{
									id: 'gradientWhite',
									type: 'linearGradient',
									colors: [
										{ offset: 0, color: 'white' },
										{ offset: 100, color: 'white' },
									],
								},
								{
									id: 'gradientTransparent',
									type: 'linearGradient',
									colors: [
										{ offset: 0, color: 'transparent' },
										{ offset: 100, color: 'transparent' },
									],
								},
								{
									id: 'gradientRed',
									type: 'linearGradient',
									colors: [
										{ offset: 0, color: colors.red[100] },
										{ offset: 100, color: colors.red[100] },
									],
								},
								{
									id: 'gradientGreen',
									type: 'linearGradient',
									colors: [
										{ offset: 0, color: colors.green[100] },
										{ offset: 100, color: colors.green[100] },
									],
								},
							]}
							fill={[
								{ match: (d) => d.id === labelNames.upper, id: 'gradientGreen' },
								{ match: (d) => d.id === labelNames.average, id: 'gradientRed' },
								{ match: (d) => d.id === labelNames.lower, id: 'gradientWhite' },
								{ match: (d) => d.id === 'Versements', id: 'gradientTransparent' },
							]}
							enablePoints={false}
							enableGridX={false}
							enableGridY
							axisBottom={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'Années',
								legendOffset: 40,
								legendPosition: 'middle',
								tickValues:
									legendDirection === 'column' || (localData[0] && localData[0].data.length >= 15)
										? [localData[0].data[0].x, localData[0].data[localData[0].data.length - 1].x]
										: undefined,
							}}
							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: '',
								legendOffset: -80,
								legendPosition: 'middle',
								format: '.2s',
							}}
							isInteractive
							useMesh
							enableSlices="x"
							legends={[
								{
									anchor: legendDirection === 'row' ? 'top-right' : 'top-left',
									direction: legendDirection,
									justify: false,
									translateX: legendDirection === 'row' ? -16 : -40,
									translateY: legendDirection === 'row' ? -40 : -132,
									itemsSpacing: legendDirection === 'row' ? 20 : 8,
									itemWidth: 240,
									itemHeight: 20,
									itemDirection: 'left-to-right',
									itemOpacity: 1,
									symbolSize: 16,
									symbolShape: 'circle',
									effects: [
										{
											on: 'hover',
											style: {
												itemBackground: 'rgba(0, 0, 0, .03)',
												itemOpacity: 1,
											},
										},
									],
								},
							]}
						/>
					) : (
						<Skeleton height={400} />
					)}
				</Box>
			</VStack>
			<MentionUC />
		</Card>
	);
};

export default PortfolioScenariosGraph;
