import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import StepperLayout from 'app/Layout/Onboarding/StepperLayout';
import { useAppDispatch } from 'hooks/useStore';
import KYC from 'onboarding/KYC';
import { setLastStepAvailable } from 'onboarding/Stepper/stepper.slice';

import { Signature } from './Signature';
import steps, { UpdateKycOnboardingSteps } from './steps';

const UpdateKYC = (): JSX.Element => {
	const [collapsed, setCollapsed] = useState(false);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setLastStepAvailable(UpdateKycOnboardingSteps.KYC));
	}, [dispatch]);

	return (
		<Routes>
			<Route
				element={
					<StepperLayout
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						steps={steps}
						basePath="/actualisation-situation"
					/>
				}
			>
				<Route
					path="connaissance-client/*"
					element={
						<KYC
							product="kyc-update"
							steps={[
								'introduction',
								'etatCivil',
								'foyerFiscal',
								'professionelle',
								'coordonnees',
								'patrimoine',
								'recapitulatif',
							]}
							redirectOnEnd="/actualisation-situation/signature"
						/>
					}
				/>
				<Route path="signature" element={<Signature />} />
			</Route>
			<Route path="*" element={<Navigate to="connaissance-client" replace />} />
		</Routes>
	);
};

export default UpdateKYC;
