import { useState } from 'react';
import {
	Button,
	Divider,
	Heading,
	HStack,
	ModalBody,
	ModalFooter,
	PinInput,
	PinInputField,
	Skeleton,
	Text,
	VStack,
} from '@chakra-ui/react';

import Banner from 'components/Banners/Banner';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useGetBankAccountsQuery } from 'services/requests/bank';
import { useSwitchEliteRequestQuery } from 'services/requests/invest/switch-elite';
import { useSignVersementMutation } from 'services/requests/versement';
import { PopulatedInvestContract } from 'store/types/contract.type';
import { displayMoneyNoDigits } from 'utils/rendering';

type UpgradeEliteConfirmationProps = {
	contract: PopulatedInvestContract;
	amountDeposit: number;
	bankAccountId: string;
	onPrev: () => void;
	onConfirm: () => void;
};

const UpgradeEliteConfirmation = ({
	contract,
	bankAccountId,
	amountDeposit,
	onPrev,
	onConfirm,
}: UpgradeEliteConfirmationProps) => {
	const [code, setCode] = useState('');
	const { data: bankAccountList } = useGetBankAccountsQuery();
	const bankAccountSelected = bankAccountList?.find((b) => b.id === bankAccountId);
	const {
		data: versementId,
		isLoading: isRequestLoading,
		isError: isRequestError,
	} = useSwitchEliteRequestQuery({
		id: contract.id,
		inflow: amountDeposit,
		bankAccountId,
	});

	const toast = useThemedToast();

	const [signVersement, { isLoading: isSignLoading, isError: isSignError }] = useSignVersementMutation();

	const onClickConfirm = () => {
		if (versementId)
			signVersement({
				otp: code,
				versementId,
			})
				.unwrap()
				.then(() => {
					// Ajouter un event mixpanel
					toast({
						status: 'success',
						title: 'Demande signée',
						description:
							'Votre demande de versement pour passer vers le portefeuille Elite a été prise en compte, nous la traitons dans les plus brefs délais',
					});
					onConfirm();
				})
				.catch((e) => {
					if ('status' in e && e.status === 400) {
						toast({
							status: 'error',
							title: 'Erreur de validation',
							description: "Le code saisi n'est pas correct",
						});
						return;
					}
					eventTracker.signVersementError({
						amount: amountDeposit,
						envelopeType: contract.envelope.type,
						operationType: 'ponctuel',
					});
				});
	};

	return (
		<>
			<ModalBody py="24px">
				<VStack align="start" spacing="24px" w="100%">
					<VStack align="start" spacing="24px" w="100%">
						<Heading variant="Title-M-SemiBold">Récapitulatif</Heading>
						<Text>
							Vous allez effectuer un versement de <b>{displayMoneyNoDigits(amountDeposit)}</b> sur votre contrat
							{bankAccountSelected ? (
								<>
									{' '}
									depuis l'IBAN suivant : <b>{bankAccountSelected.IBAN}</b>
								</>
							) : (
								'.'
							)}
						</Text>
					</VStack>
					<Divider />
					{isRequestLoading ? (
						<Skeleton h="200px" w="100%" />
					) : isRequestError || isSignError ? (
						<Banner
							title="Nous reprenons la main sur votre demande"
							description="Une erreur est survenue, nous ne pouvons pas valider automatiquement votre demande pour l’instant. Ne vous inquiétez pas, l’équipe support est informée et reviendra vers vous très vite !"
							avatar="amelie"
							variant="operationnel"
						/>
					) : (
						<VStack align="start" w="100%">
							<Text variant="Text-M-Regular">
								Afin de valider l'opération, veuillez confirmer le {'  '}
								<Text variant="Text-M-Bold" as="span">
									code à 6 chiffres {'  '}
								</Text>
								qui vous a été envoyé par SMS
							</Text>
							<HStack>
								<PinInput value={code} onChange={setCode} otp>
									<PinInputField />
									<PinInputField />
									<PinInputField />
									<PinInputField />
									<PinInputField />
									<PinInputField />
								</PinInput>
							</HStack>

							<VStack align="start" w="100%">
								<Text>Vous n'avez pas reçu de code au bout d'une minute ?</Text>
								<Text
									textDecoration="underline"
									cursor="pointer"
									onClick={() => {
										window.Intercom('showNewMessage', 'Bonjour, je ne reçois pas mon code de validation SMS');
									}}
								>
									Contactez nous via le chat
								</Text>
							</VStack>
						</VStack>
					)}
				</VStack>
			</ModalBody>
			<ModalFooter borderTop="1px solid" borderColor="grey.300">
				<HStack w="100%" spacing="24px" justifyContent="space-between">
					<Button variant="secondary" onClick={onPrev}>
						<Text variant="Text-S-Medium">Précédent</Text>
					</Button>
				</HStack>
				<HStack w="100%" spacing="24px" justifyContent="end">
					<Button isDisabled={code.length !== 6 || isSignLoading} onClick={onClickConfirm} isLoading={isSignLoading}>
						<Text variant="Text-S-Medium">Confirmer</Text>
					</Button>
				</HStack>
			</ModalFooter>
		</>
	);
};

export default UpgradeEliteConfirmation;
