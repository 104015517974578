import { useNavigate, useParams } from 'react-router-dom';
import { Heading, Text, VStack } from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import BottomStepBar from 'components/steppers/BottomStepBar';

const Introduction = (): JSX.Element => {
	const navigate = useNavigate();
	const { id } = useParams();

	const handleSubmit = () => navigate(`/cash/onboarding/${id}/souscription/reglementaire`);

	return (
		<VStack align="start" spacing="24px">
			<Heading variant="Title-XL-Bold">Souscription</Heading>
			<Text variant="Text-M-Regular" color="grey.900">
				Plus qu'une seule étape avant la signature de votre contrat
			</Text>
			<ArrowButton size="lg" onClick={handleSubmit}>
				Continuer la souscription
			</ArrowButton>
			<BottomStepBar
				onPrev={() => navigate(`/cash/onboarding/${id}/connaissance-client`)}
				onNext={handleSubmit}
				showNext
			/>
		</VStack>
	);
};

export default Introduction;
