import { FC } from 'react';
import { Card, Heading, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react';

import { CashflowGraph } from 'components/charts/CashFlowGraph';
import CustomTooltip from 'components/CustomTooltip';
import { ImmobilierSubscriptionMetrics } from 'services/requests/realEstate';
import { SCPIFund, SCPISubscription } from 'store/types/airtable.type';
import { displayMoney, displayPercentage, formatDateStandart } from 'utils/rendering';

import { CapitalGraphImmo } from '../Charts/CapitalGraph';

import CapitalValueCard from './CapitalValueCard';
import PlusValueCard from './PlusValueCard';

const InfosSubscriptionImmo: FC<{
	metrics: ImmobilierSubscriptionMetrics;
	subscription: SCPISubscription;
	fund: SCPIFund;
}> = ({ metrics, subscription, fund }) => {
	return (
		<VStack w="100%" align="start" spacing="24px">
			<Heading variant="Title-L-SemiBold">Mon investissement</Heading>

			{subscription.lastValuationDate && (
				<Text>
					Date de dernière valorisation :{' '}
					{new Date(subscription.lastValuationDate).toLocaleDateString('fr-FR', {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
					})}
				</Text>
			)}

			<SimpleGrid columns={{ base: 1, md: 2 }} w="100%" spacing="24px">
				<CapitalValueCard subscription={metrics} />
				<PlusValueCard metrics={metrics} />
				<Card w="100%" h="100%" minH="235px">
					<HStack w="100%">
						<Text fontWeight="semibold">Evolution du capital</Text>
						<CustomTooltip text="Les dividendes pris en compte sont brut d'impôt" />
					</HStack>
					<CapitalGraphImmo data={metrics} />
				</Card>
				<Card w="100%" h="100%" minH="235px">
					<Text fontWeight="semibold">Cashflow</Text>
					<CashflowGraph data={metrics} />
				</Card>

				{/* <Card w="100%" h="100%">
					<VStack align="start">
						<Text variant="Text-M-Medium">Nombre de parts</Text>
						<Text variant="Title-L-Bold">{subscription.nbShare}</Text>
					</VStack>
				</Card> */}

				<Card w="100%" h="100%">
					<VStack align="start">
						<Text variant="Text-M-Medium">Loyers estimés</Text>
						<HStack>
							<Text variant="Title-L-Bold">{displayMoney(metrics.rent)}</Text>
							<Text variant="Title-S-SemiBold">(mensuel)</Text>
						</HStack>
					</VStack>
				</Card>

				<Card w="100%" h="100%">
					<VStack align="start">
						<Text variant="Text-M-Medium">Date de jouissance</Text>
						<Text variant="Title-L-Bold">{formatDateStandart(new Date(subscription.effectiveDate || ''))}</Text>
					</VStack>
				</Card>

				<Card w="100%" h="100%">
					<VStack align="start">
						<Text variant="Text-M-Medium">Valeur de retrait</Text>
						<Text variant="Title-L-Bold">{displayMoney(metrics.capital_valorisation_after_fees)}</Text>
					</VStack>
				</Card>

				<Card w="100%" h="100%">
					<VStack align="start">
						<Text variant="Text-M-Medium">Frais de souscription</Text>
						<Text variant="Title-L-Bold">{displayPercentage(fund.entranceFees)}</Text>
					</VStack>
				</Card>
			</SimpleGrid>
		</VStack>
	);
};
export default InfosSubscriptionImmo;
