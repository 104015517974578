import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

const accordionVariant = defineMultiStyleConfig({
	baseStyle: {
		button: {},
		container: {},
		panel: {},
		icon: {},
	},
});

export default accordionVariant;
