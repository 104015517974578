import { backendApi } from 'services/apiService';
import { CustomAsset } from 'store/types/investment.type';
import { AssetType, FinancialLostType, LostReactionType, PortfolioType, RegionType } from 'store/types/profilage.type';
import { AssetsDataRepartition, InstrumentDataRepartition } from 'utils/api';

export type PreferencesEntityType = 'investSimulation' | 'subscription';

export type WherePreferences = {
	entityName: PreferencesEntityType;
	id: string;
};

export type InvestmentPreferences = {
	id: string;
	regions: RegionType[];
	types: AssetType[];
	esg: boolean | null;
	temperature: number | null;
	portfolioType: PortfolioType | null;
	risk: number | null;
	recommandedRisk: number | null;
	hasEverUsedSavingPlan: boolean;
	financialLost: FinancialLostType;
	lostReaction: LostReactionType;
};

export type UpdateRisk = {
	hasEverUsedSavingPlan: boolean;
	financialLost: FinancialLostType;
	lostReaction: LostReactionType;
	timeHorizon?: number | undefined;
	birthYear?: number | undefined;
	workedYears?: number | undefined;
};

export type UpdateDesiredRisk = {
	desiredClientRiskScore: number;
};

type UpdateType = {
	portfolioType: PortfolioType;
};

type GetOnboardingPortfolioResponse = {
	assets: CustomAsset[];
	performances: {
		yearly_performance: Record<string, number>;
		total_performance: number;
		annualized_return: number;
	};
	aggregatedData: {
		esg: AssetsDataRepartition;
		countries: Record<string, AssetsDataRepartition>;
		instruments: {
			listTopinstruments: InstrumentDataRepartition[];
			total_number: number;
		};
	};
};

type GetCustomPortfolioResponse = {
	assets: CustomAsset[];

	aggregatedData: {
		esg: AssetsDataRepartition;
		countries: Record<string, AssetsDataRepartition>;
		instruments: {
			listTopinstruments: InstrumentDataRepartition[];
			total_number: number;
		};
	};
};

type ParisAlignedFundsRangeDto = {
	temperature: number;
};

type ParisAlignedFundsRangeResponse = [number, number];

export type GreenPreferenceParams = {
	ESG: boolean;
	temperature: number;
};

type LabPotentialPerformances = {
	last_n_years_performance: {
		total_performances: number;
		yearly_performance: Record<number, number>;
	};
	annualized_return: number;
};

export interface LabOfferComparaisonResponse {
	client_risk_score: number;
	flagship: LabPotentialPerformances;
	essential: LabPotentialPerformances;
	elite: LabPotentialPerformances;
}

const tagFromEntityName = (entityName: PreferencesEntityType) => {
	switch (entityName) {
		case 'investSimulation':
			return 'InvestSimulations';
		case 'subscription':
			return 'InvestSubscriptions';
	}
};

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		updateRisk: builder.mutation<InvestmentPreferences, UpdateRisk & WherePreferences>({
			query: (body) => ({
				url: `/preferences/risk`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: (r, e, { entityName }) => [tagFromEntityName(entityName)],
		}),
		updateDesiredRisk: builder.mutation<InvestmentPreferences, UpdateDesiredRisk & WherePreferences>({
			query: (body) => ({
				url: `/preferences/desiredRisk`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: (r, e, { entityName }) => [tagFromEntityName(entityName)],
		}),
		getParisAlignedFundsRange: builder.query<
			ParisAlignedFundsRangeResponse,
			ParisAlignedFundsRangeDto & WherePreferences
		>({
			query: (body) => ({
				url: '/preferences/parisAlignedFundsRange',
				method: 'POST',
				body,
			}),
			providesTags: (r, e, { entityName }) => [tagFromEntityName(entityName)],
		}),
		updatePortfolioGreen: builder.mutation<InvestmentPreferences, GreenPreferenceParams & WherePreferences>({
			query: (body) => ({
				url: '/preferences/green',
				method: 'PUT',
				body,
			}),
			invalidatesTags: (r, e, { entityName }) => [tagFromEntityName(entityName)],
		}),
		updateType: builder.mutation<InvestmentPreferences, UpdateType & WherePreferences>({
			query: (params) => ({
				url: '/preferences/type',
				method: 'POST',
				body: params,
			}),
			invalidatesTags: (r, e, { entityName }) => [tagFromEntityName(entityName)],
		}),
		getPotentialPortfolioPerformances: builder.query<LabOfferComparaisonResponse, WherePreferences>({
			query: (body) => ({
				url: '/preferences/performance-comparaison',
				method: 'POST',
				body,
			}),
			providesTags: (r, e, { entityName }) => [tagFromEntityName(entityName)],
		}),
		getOnboardingPortfolio: builder.query<GetOnboardingPortfolioResponse, WherePreferences>({
			query: (body) => ({
				url: '/preferences/portfolio',
				method: 'POST',
				body,
			}),
			providesTags: (r, e, { entityName }) => [tagFromEntityName(entityName)],
		}),
		getCustomPortfolio: builder.query<GetCustomPortfolioResponse, WherePreferences>({
			query: (body) => ({
				url: '/preferences/custom-portfolio',
				method: 'POST',
				body,
			}),
			providesTags: (r, e, { entityName }) => [tagFromEntityName(entityName)],
		}),
	}),
	overrideExisting: true,
});

export const {
	useUpdateRiskMutation,
	useLazyGetParisAlignedFundsRangeQuery,
	useUpdatePortfolioGreenMutation,
	useGetPotentialPortfolioPerformancesQuery,
	useUpdateDesiredRiskMutation,
	useUpdateTypeMutation,
	useGetOnboardingPortfolioQuery,
	useGetCustomPortfolioQuery,
} = extendedApi;
