import { useCallback, useEffect, useMemo } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import { useAppDispatch } from 'hooks/useStore';
import { hasCompletedKycFoyerFiscal } from 'onboarding/KYC/utils';
import { OnboardingStepProps, setLastInnerStepAvailable } from 'onboarding/Stepper/stepper.slice';
import {
	UpdateInvestSimulationEnvelope,
	useUpdateInvestSimulationEnvelopeMutation,
} from 'services/requests/invest/simulation';
import { useGetKYCQuery } from 'services/requests/kyc';
import { PopulatedInvestSimulation } from 'store/types/investSimulation';
import { PopulatedInvestSubscription } from 'store/types/subscription.type';

import Envelope from './Steps/Envelope';
import Introduction from './Steps/Introduction';
import Situation from './Steps/Situation';
import TaxEconomy from './Steps/TaxEconomy';

type FiscaliteProps = (
	| { flow: 'subscription'; object: PopulatedInvestSubscription }
	| { flow: 'simulation'; object: PopulatedInvestSimulation }
) &
	OnboardingStepProps;

const Fiscalite = ({ onNext, object, flow }: FiscaliteProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [updateEnvelope] = useUpdateInvestSimulationEnvelopeMutation();

	const { data: kyc, isSuccess: isKycSuccess } = useGetKYCQuery();

	const isFoyerFiscalFilled = useMemo(() => hasCompletedKycFoyerFiscal(kyc), [kyc]);

	useEffect(() => {
		if (!isKycSuccess) return;
		if (isFoyerFiscalFilled) dispatch(setLastInnerStepAvailable('economies'));
		else dispatch(setLastInnerStepAvailable('introduction'));
	}, [dispatch, isFoyerFiscalFilled, isKycSuccess]);

	const situationNext = useCallback(() => {
		if (flow === 'subscription') navigate('economies');
		else navigate('envelope');
	}, [flow, navigate]);

	const taxNext = useCallback(() => {
		onNext();
	}, [onNext]);

	const envelopeNext = useCallback(
		(data: UpdateInvestSimulationEnvelope) => {
			updateEnvelope(data)
				.unwrap()
				.then(() => {
					onNext();
				});
		},
		[onNext, updateEnvelope],
	);

	return (
		<VStack spacing={{ base: '40px', md: '48px', lg: '56px' }} align="start" w="100%">
			<Routes>
				<Route index element={<Introduction />} />
				<Route
					path="situation"
					element={<Situation onPrev={() => navigate('introduction')} onNext={situationNext} />}
				/>
				{flow === 'subscription' && (
					<Route
						path="economies"
						element={<TaxEconomy subscription={object} onPrev={() => navigate('situation')} onNext={taxNext} />}
					/>
				)}
				{flow === 'simulation' && (
					<Route
						path="envelope"
						element={<Envelope simulation={object} onPrev={() => navigate('situation')} onNext={envelopeNext} />}
					/>
				)}
			</Routes>
		</VStack>
	);
};

export default Fiscalite;
