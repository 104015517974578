import { Heading, VStack } from '@chakra-ui/react';
import fileDownload from 'js-file-download';

import DocumentToDownload from 'components/cards/subscriptions/DocumentToDownload';
import { downloadDocument } from 'services/requests/document';
import { ProductSubscriptionType, ProductType } from 'store/types/global.type';
import api from 'utils/api';
import { isNone } from 'utils/functions';

const SubscriptionDocuments = ({ subscription }: { subscription: ProductSubscriptionType }) => {
	return (
		<VStack w="100%" spacing="16px" align="start">
			<>
				{[ProductType.INVEST, ProductType.CASH].includes(subscription.typeProject) ||
				('attestationDeParts' in subscription && subscription.attestationDeParts) ||
				('bulletinSigned' in subscription && subscription.bulletinSigned) ||
				('imprimeFiscal' in subscription && subscription.imprimeFiscal) ||
				('surveySigned' in subscription && subscription.surveySigned) ||
				('demembrementSigned' in subscription && subscription.demembrementSigned) ||
				('fundOriginDocument' in subscription && subscription.fundOriginDocument) ||
				('bulletin' in subscription && subscription.bulletin) ? (
					<Heading variant="Title-M-SemiBold">Documents relatifs à la souscription</Heading>
				) : null}

				{'attestationDeParts' in subscription && subscription.attestationDeParts && (
					<DocumentToDownload
						name="Attestation de parts"
						documentUrl={subscription.attestationDeParts[0].url}
						documentFilename={subscription.attestationDeParts[0].filename}
					/>
				)}

				{'bulletinSigned' in subscription && subscription.bulletinSigned && (
					<DocumentToDownload
						name="Bulletin de souscription"
						documentUrl={subscription.bulletinSigned[0].url}
						documentFilename={subscription.bulletinSigned[0].filename}
					/>
				)}

				{'imprimeFiscal' in subscription && subscription.imprimeFiscal && (
					<DocumentToDownload
						name="Imprimé fiscal"
						documentUrl={subscription.imprimeFiscal[0].url}
						documentFilename={subscription.imprimeFiscal[0].filename}
					/>
				)}

				{'surveySigned' in subscription && subscription.surveySigned && (
					<DocumentToDownload
						name="Questionnaire connaissance client"
						documentUrl={subscription.surveySigned[0].url}
						documentFilename={subscription.surveySigned[0].filename}
					/>
				)}

				{'demembrementSigned' in subscription && subscription.demembrementSigned && (
					<DocumentToDownload
						name="Document de nue-propriété"
						documentUrl={subscription.demembrementSigned[0].url}
						documentFilename={subscription.demembrementSigned[0].filename}
					/>
				)}

				{'fundOriginDocument' in subscription && subscription.fundOriginDocument && (
					<DocumentToDownload
						name="Déclaration d'origine des fonds"
						documentUrl={subscription.fundOriginDocument[0].url}
						documentFilename={subscription.fundOriginDocument[0].filename}
					/>
				)}

				{subscription.typeProject === ProductType.CASH && (
					<DocumentToDownload
						handleDownloadCustom={() => {
							downloadDocument({
								contentType: 'application/pdf',
								filename: 'bulletin.pdf',
								filepath: `livret-epargne/${subscription.id}/bulletin`,
							});
						}}
						name="Bulletin de souscription"
					/>
				)}

				{'trimestrialReport' in subscription &&
					subscription.trimestrialReport &&
					subscription.trimestrialReport.map((trimestrialReport) => {
						if (isNone(trimestrialReport.report) || trimestrialReport.report.length === 0) return null;

						return (
							<DocumentToDownload
								key={trimestrialReport.report[0].id}
								name={`Rapport trimestriel - ${trimestrialReport.period}`}
								documentUrl={trimestrialReport.report[0].url}
								documentFilename={trimestrialReport.report[0].filename}
							/>
						);
					})}

				{subscription.typeProject === ProductType.INVEST && (
					<DocumentToDownload
						name="Bulletin de souscription"
						handleDownloadCustom={() => {
							api
								.downloadBulletin(subscription.id)
								.then((res) => fileDownload(res.data, 'bulletin.pdf', 'application/pdf'));
						}}
					/>
				)}
			</>
		</VStack>
	);
};

export default SubscriptionDocuments;
