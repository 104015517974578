import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Divider,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
	Tab,
	Table,
	TableContainer,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	VStack,
} from '@chakra-ui/react';

import { AssetType, listBa } from 'components/badge/AssetClassBadges';
import AssetBadge from 'components/Tags';
import { UniverseFund } from 'services/requests/investment';
import { InvestGreenTagType } from 'store/types/investment.type';
import { PortfolioType } from 'store/types/profilage.type';

export type PortfolioCardType = {
	title: string;
	description: string;
	btnDiscover: string;
	portfolioType: PortfolioType;
};

export const ramifyPortfolios: PortfolioCardType[] = [
	{
		title: 'Ramify Elite',
		description: 'Le portefeuille multi-actifs ultime. Le plus performant, le plus diversifié et le plus innovant',
		btnDiscover: 'Détail du portefeuille',
		portfolioType: 'ELITE',
	},
	{
		title: 'Ramify Flagship',
		description: 'Un portefeuille multi-actifs, plus performant, plus résilient notamment au risque d’inflation',
		btnDiscover: 'Détail du portefeuille',
		portfolioType: 'FLAGSHIP',
	},
	{
		title: 'Ramify Essential',
		description: 'Un portefeuille composé d’actions et d’obligations. Simple et performant',
		btnDiscover: 'Détail du portefeuille',
		portfolioType: 'ESSENTIAL',
	},
];

interface FundsList {
	class: string;
}

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	portfolioType: PortfolioType;
	airtableData: ModalData[];
}

export interface ModalData {
	title: string;
	description: string;
	display: string;
	funds: UniverseFund[];
}

export const ModalDiscoverOurPortfoliosDesktop: FC<ModalProps> = ({ isOpen, onClose, portfolioType, airtableData }) => {
	if (!airtableData) return null;

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="6xl">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody p="24px">
					<Tabs variant="enclosed">
						<TabList>
							{Object.entries(listBa).map(([assetType, value]) => {
								return (portfolioType === 'ESSENTIAL' && assetType === 'IMMOBILIER') ||
									(portfolioType !== 'ELITE' && assetType === 'PRIVATE EQUITY') ? null : (
									<Tab
										key={assetType}
										_selected={{
											bg: 'grey.100',
										}}
									>
										{value}
									</Tab>
								);
							})}
						</TabList>

						<TabPanels>
							{Object.values(airtableData).map((value) => (
								<TabPanel key={value.title}>
									<VStack w="100%" align="start" spacing="16px">
										<VStack w="100%" align="start" spacing="8px">
											<Heading variant="Title-S-SemiBold">{value.title}</Heading>
											<Text variant="Text-M-Regular" color="grey.900">
												{value.description}
											</Text>
										</VStack>

										<TableContainer w="100%" h="500px" overflowY="scroll">
											<Table>
												<Thead w="100%">
													<Tr>
														<Th pl="0px">
															<Text variant="Caption" color="grey.900">
																Nom de fonds
															</Text>
														</Th>
														<Th>
															<Text variant="Caption" color="grey.900">
																Label
															</Text>
														</Th>
														<Th>
															<Text variant="Caption" color="grey.900">
																Type
															</Text>
														</Th>
														<Th>
															<Text variant="Caption" color="grey.900">
																Région/Secteur
															</Text>
														</Th>
													</Tr>
												</Thead>

												<Tbody>
													{value.funds.map((data) => (
														<Tr key={data.label}>
															<Td pl="0px">{data.label}</Td>
															<Td>{data.greenTag && <AssetBadge variant={data.greenTag as InvestGreenTagType} />}</Td>
															<Td>{data.typeDeFonds}</Td>
															<Td>{data['region/secteur']} </Td>
														</Tr>
													))}
												</Tbody>
											</Table>
										</TableContainer>
									</VStack>
								</TabPanel>
							))}
						</TabPanels>
					</Tabs>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export const ModalDiscoverOurPortfoliosMobile = ({
	isOpen,
	onClose,
	portfolioType,
	airtableData,
}: ModalProps): JSX.Element => {
	const assetsList: Record<AssetType, string> = {
		ACTIONS: 'Fonds en actions',
		OBLIGATIONS: 'Fonds en Obligation',
		'FONDS EURO': 'Fonds Euro',
		IMMOBILIER: 'Fonds en immobilier',
		'PRIVATE EQUITY': 'Fonds en Private Equity',
	};

	const { control, watch } = useForm<FundsList>({
		mode: 'onChange',
		defaultValues: { class: 'ACTIONS' },
	});

	const currentClass = watch('class');

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="xl">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalHeader>Classe d'actifs</ModalHeader>
				<ModalBody>
					<VStack w="100%" align="start" spacing="24px">
						<Controller
							control={control}
							rules={{ required: true }}
							name="class"
							render={({ field }) => (
								<Select {...field} size="md" _hover={{ bg: 'grey.100', borderColor: 'grey.300' }}>
									{Object.entries(assetsList).map(([key, value]) =>
										(portfolioType === 'ESSENTIAL' && value === assetsList.IMMOBILIER) ||
										(portfolioType !== 'ELITE' && value === assetsList['PRIVATE EQUITY']) ? null : (
											<option value={key} key={key}>
												{value}
											</option>
										),
									)}
								</Select>
							)}
						/>
						{Object.values(airtableData)
							.filter((value) => value.display === currentClass)

							.map((value) => (
								<VStack w="100%" align="start" spacing="24px" key={value.title}>
									{value.funds.map((data) => (
										<VStack w="100% " spacing="8px" align="start" key={data.dici}>
											<Text variant="Text-M-Medium">{data.label}</Text>

											{data.greenTag && <AssetBadge variant={data.greenTag as InvestGreenTagType} />}

											<Text variant="Text-M-Regular">
												{data.typeDeFonds}
												<Text as="span"> - {data['region/secteur']}</Text>
											</Text>
											<Divider />
										</VStack>
									))}
								</VStack>
							))}
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
