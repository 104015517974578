import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SCPIFilterKeyType, SCPIFilterType } from 'utils/fundFilters';

const initialState: SCPIFilterType = {
	name: '',
	sorting: '',
	maxTicket: 0,
	tags: [],
	sectors: [],
	geographies: [],
};

const SCPIFilterSlice = createSlice({
	name: 'SCPIFilter',
	initialState,
	reducers: {
		clearFilters() {
			return initialState;
		},
		setMaxTicket(state, action: PayloadAction<number>) {
			state.maxTicket = action.payload;
		},
		setSorting(state, action: PayloadAction<string>) {
			state.sorting = action.payload;
		},
		setName(state, action: PayloadAction<string>) {
			state.name = action.payload;
		},
		setFilter(
			state,
			{
				payload: { isChecked, key, value },
			}: PayloadAction<{ key: SCPIFilterKeyType; value: string; isChecked: boolean }>,
		) {
			if (isChecked) {
				if (!state[key].includes(value)) state[key].push(value);
			} else {
				state[key] = state[key].filter((elem) => elem !== value);
			}
		},
	},
});

export const { setName, clearFilters, setMaxTicket, setSorting, setFilter } = SCPIFilterSlice.actions;
export default SCPIFilterSlice.reducer;
