import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Information } from '@carbon/icons-react';
import {
	Box,
	Card,
	chakra,
	Checkbox,
	Divider,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Image,
	Text,
	VStack,
} from '@chakra-ui/react';

import Warning from 'assets/icons/Warning.svg';
import CustomTooltip from 'components/CustomTooltip';
import ErrorFieldMessage from 'components/inputs/ErrorMessage';
import { ControlledNumberInput } from 'components/inputs/NumberInput';
import ControlledRadioOptions from 'components/inputs/RadioOptions';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import {
	useGetSavingsAccountQuery,
	useUpdateSavingsAccountPreferencesMutation,
} from 'services/requests/savingsAccounts';
import {
	SavingsAccountInformation,
	SavingsAccountSupplyFrequency,
	SavingsAccountTimeHorizon,
} from 'store/types/savingsAccount.type';
import scrollToErrorOnForm from 'utils/errors/FormScrollToError';
import { isNotNone } from 'utils/functions';

type ProjectForm = {
	isFrenchResident: boolean;
	doesNotHaveMMBAccount: boolean;
} & Pick<SavingsAccountInformation, 'timeHorizon' | 'initialAUM' | 'moneySupplyFrequency'>;

const Project = (): JSX.Element => {
	const navigate = useNavigate();
	const { id } = useParams();
	const toast = useThemedToast();
	const [triggerUpdateSavingsAccount, { isLoading: isUpdateSavingsAccountLoading, isSuccess }] =
		useUpdateSavingsAccountPreferencesMutation();
	const { data: savingsAccount } = useGetSavingsAccountQuery(id!);
	const {
		control,
		formState: { errors },
		setValue,
		watch,
		handleSubmit,
	} = useForm<ProjectForm>({
		defaultValues: {
			doesNotHaveMMBAccount: false,
		},
	});

	const investAmount = watch('initialAUM');

	useEffect(() => {
		if (savingsAccount) {
			if (savingsAccount.initialAUM) setValue('initialAUM', savingsAccount.initialAUM);
			if (savingsAccount.moneySupplyFrequency) setValue('moneySupplyFrequency', savingsAccount.moneySupplyFrequency);
			if (savingsAccount.timeHorizon) setValue('timeHorizon', savingsAccount.timeHorizon);
		}
	}, [savingsAccount, isSuccess, setValue]);

	const onSubmit = handleSubmit(
		({ isFrenchResident, doesNotHaveMMBAccount, ...body }: ProjectForm) => {
			triggerUpdateSavingsAccount({
				initialAUM: body.initialAUM,
				moneySupplyFrequency: body.moneySupplyFrequency,
				timeHorizon: body.timeHorizon,
				id,
			})
				.unwrap()
				.then(() => {
					eventTracker.pipedream.savingsAccountIntention(body.initialAUM);
					navigate(`/cash/onboarding/${id}/connaissance-client`);
				})
				.catch(() => {
					toast({
						status: 'error',
						title: 'Une erreur est survenue',
					});
				});
		},
		() => scrollToErrorOnForm(errors),
	);

	const dateSixMonthAgo = useMemo(() => new Date().setMonth((new Date().getMonth() + 12 - 6) % 12), []);

	return (
		<chakra.form onSubmit={onSubmit}>
			<VStack align="start" w="100%" spacing="40px">
				<Heading variant="Title-XL-Bold">Mon projet</Heading>
				<VStack align="start" w="100%" spacing="32px">
					<VStack align="start" w="100%" spacing="16px">
						<FormControl isInvalid={!!errors.initialAUM} w={onboardingInputWidth}>
							<FormLabel>Quel montant souhaitez-vous épargner ?</FormLabel>

							<ControlledNumberInput
								name="initialAUM"
								control={control}
								rules={{ required: true, min: 500, max: 1_000_000 }}
								placeholder="Indiquez le montant en €"
							/>

							{errors.initialAUM?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
							{errors.initialAUM?.type === 'min' && (
								<FormErrorMessage>Le montant doit être supérieur à 500 €</FormErrorMessage>
							)}
							{errors.initialAUM?.type === 'max' ? (
								<FormErrorMessage>Le plafond de dépôt de ce produit est fixé à 1.000.000 €</FormErrorMessage>
							) : (
								investAmount &&
								investAmount > 150000 && (
									<ErrorFieldMessage warning>
										* Le taux boosté n'est valable que pour les premiers 150.000 € placés
									</ErrorFieldMessage>
								)
							)}
						</FormControl>
						<Card p="16px" minW="300px" borderColor="blue.500" bg="blue.100">
							<HStack align="center" spacing="16px">
								<Box color="blue.900">
									<Information size={24} />
								</Box>
								<Text>
									Votre premier versement devra être égal à la somme renseignée ci-dessus afin de valider l’ouverture de
									votre contrat.
								</Text>
							</HStack>
						</Card>
					</VStack>
					<FormControl isInvalid={!!errors.timeHorizon}>
						<FormLabel>
							<HStack>
								<>Quel est votre horizon de placement ?</>
								<CustomTooltip text="Ces informations sont demandées à titre déclaratif et n’ont aucun impact sur la liquidité des fonds déposés." />
							</HStack>
						</FormLabel>

						<ControlledRadioOptions
							name="timeHorizon"
							control={control}
							rules={{
								validate: {
									required: (b: SavingsAccountTimeHorizon | undefined | null) => isNotNone(b),
								},
							}}
							w={onboardingInputWidth}
							options={[
								{ label: '0 à 12 mois', value: SavingsAccountTimeHorizon.LESS_THAN_1_YEAR },
								{ label: 'Au-delà de 12 mois', value: SavingsAccountTimeHorizon.MORE_THAN_1_YEAR },
							]}
						/>

						{errors.timeHorizon?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					</FormControl>

					<FormControl isInvalid={!!errors.moneySupplyFrequency}>
						<FormLabel>
							<HStack>
								<>A quelle fréquence comptez-vous alimenter votre Livret Epargne Ramify ?</>
								<CustomTooltip text="Ces informations sont demandées à titre déclaratif et n’ont aucun impact sur la liquidité des fonds déposés." />
							</HStack>
						</FormLabel>

						<ControlledRadioOptions
							name="moneySupplyFrequency"
							control={control}
							rules={{
								validate: {
									required: (b: SavingsAccountSupplyFrequency | undefined | null) => isNotNone(b),
								},
							}}
							w={onboardingInputWidth}
							options={[
								{ label: 'Régulièrement', value: SavingsAccountSupplyFrequency.REGULARLY },
								{ label: 'Ponctuellement', value: SavingsAccountSupplyFrequency.PUNCTUALLY },
							]}
						/>

						{errors.moneySupplyFrequency?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
					</FormControl>

					<FormControl w={onboardingInputWidth} isInvalid={!!errors.isFrenchResident}>
						<FormLabel>Êtes-vous résident fiscal français ?</FormLabel>

						<ControlledRadioOptions
							name="isFrenchResident"
							control={control}
							w={onboardingInputWidth}
							rules={{
								validate: { required: (value) => isNotNone(value), goodChoice: (value) => value },
							}}
							options="Yes-No"
						/>

						{errors.isFrenchResident?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
						{errors.isFrenchResident?.type === 'goodChoice' && (
							<Card mt="16px" size="sm" borderColor="negative.900" borderWidth="1px">
								<HStack align="center" spacing="16px">
									<Image src={Warning} boxSize="32px" alt="incorrect" />
									<Text variant="Text-S-Regular">
										Le Livret Epargne Ramify est uniquement disponible aux <b>résidents fiscaux français.</b>
									</Text>
								</HStack>
							</Card>
						)}
					</FormControl>

					<Divider />

					<Card>
						<FormControl isInvalid={!!errors.doesNotHaveMMBAccount}>
							<Controller
								name="doesNotHaveMMBAccount"
								control={control}
								rules={{ validate: (value) => value }}
								render={({ field: { onChange, value, ref } }) => (
									<HStack>
										<Checkbox onChange={onChange} isChecked={value} ref={ref}>
											<Text variant="Text-S-Medium">
												Je confirme ne pas détenir/avoir détenu de livret épargne My Money Bank durant les 6 derniers
												mois (depuis le {new Date(dateSixMonthAgo).toLocaleDateString('fr-FR', { dateStyle: 'short' })})
											</Text>
										</Checkbox>
										<CustomTooltip text="Vous ne pouvez détenir qu'un seul livret d'épargne chez notre partenaire" />
									</HStack>
								)}
							/>

							{errors.doesNotHaveMMBAccount?.type === 'validate' && (
								<Card mt="16px" size="sm" borderColor="negative.900" borderWidth="1px">
									<HStack align="center" spacing="16px">
										<Image src={Warning} boxSize="32px" alt="incorrect" />
										<Text variant="Text-S-Regular">
											Vous ne pouvez détenir qu'un seul livret d'épargne chez notre partenaire
										</Text>
									</HStack>
								</Card>
							)}
						</FormControl>
					</Card>
				</VStack>
				<BottomStepBar nextSubmit showNext isNextLoading={isUpdateSavingsAccountLoading} showPrev={false} />
			</VStack>
		</chakra.form>
	);
};

export default Project;
