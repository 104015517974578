import { WarningIcon } from '@chakra-ui/icons';
import {
	Box,
	Button,
	Center,
	CloseButton,
	HStack,
	Portal,
	Text,
	useBoolean,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import ModalActualization from './Modal';

const BannerActualization = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isExpanded, setIsExpanded] = useBoolean(true);

	return (
		<Portal>
			{isOpen && <ModalActualization isOpen={isOpen} onClose={onClose} />}
			<Box
				cursor="pointer"
				position="fixed"
				zIndex={101}
				right="56px"
				top="0px"
				bg="primary.yellow"
				padding="12px"
				borderRadius="0px 0px 8px 8px"
				onClick={setIsExpanded.on}
			>
				<HStack align="center">
					<Text variant="Text-M-Regular">Mettre à jour mes informations</Text>
					<WarningIcon color="primary.black" />
				</HStack>
			</Box>

			<Center
				position="fixed"
				zIndex={102}
				w="100%"
				left="0px"
				top={isExpanded ? '0px' : '-400px'}
				transition="top 0.25s ease-out"
				bg="primary.yellow"
				padding="24px 16px"
			>
				<VStack align="start" maxW="800px" spacing="16px">
					<Text variant="Title-S-Bold">Mettez à jour vos informations</Text>
					<Text variant="Text-M-Regular">
						En application de la loi relative à la lutte contre le blanchiment des capitaux et le financement du
						terrorisme, nous avons l'obligation de vous demander de mettre à jour vos informations personnelles. Cette
						étape est nécessaire pour pouvoir continuer à faire des opérations sur vos contrats.
					</Text>
					<Button bg="primary.black" color="white" _hover={{ bg: 'grey.900' }} onClick={onOpen}>
						Mettre à jour
					</Button>
				</VStack>
				<CloseButton position="absolute" right="24px" bottom="16px" onClick={setIsExpanded.off} />
			</Center>
		</Portal>
	);
};

export default BannerActualization;
