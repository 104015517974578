import { FC } from 'react';
import { Heading, HStack, Img, Skeleton, Text, VStack } from '@chakra-ui/react';

import CorporateProductCard, { ProductName } from 'components/cards/CorporateInvestmentProductCard';
import InvestmentProductCard from 'components/cards/InvestmentProductCard';
import { PerformanceBadge } from 'components/Metrics/PerformanceBadge';
import { useProducts } from 'hooks/useProducts';
import { isNotNone } from 'utils/functions';
import { displayMoney } from 'utils/rendering';

export const OpenedProducts: FC = () => {
	const { anyProduct, allProducts, ...products } = useProducts();

	if (anyProduct.isLoading) return <Skeleton h="450px" w="100%" />;
	if (!anyProduct.isOpen) return null;

	const wholeValuation = Math.floor(allProducts.valuation);
	const decimalValuation = (allProducts.valuation - wholeValuation).toString().slice(2, 3);

	return (
		<VStack spacing="24px" w="100%" align="start">
			<HStack w="100%">
				<VStack align="start">
					<Text variant="Caption" color="grey.900">
						Votre patrimoine
					</Text>
					<Heading fontSize="64px">
						{wholeValuation.toLocaleString('fr-FR')}
						<Heading as="span" fontSize="40px">
							,{displayMoney(decimalValuation)}
						</Heading>
					</Heading>

					{allProducts.performance && (
						<PerformanceBadge
							isTitle
							amount={allProducts.performance.winLoss}
							percentage={allProducts.performance.tri}
						/>
					)}
				</VStack>

				{/* exemple for graph with gradient */}
				{/* <Box bg="linear-gradient(90deg, transparent 0%, #ededed 40%)" w="stretch" alignSelf="stretch" /> */}
			</HStack>

			{products.invest.isOpen && (
				<InvestmentProductCard data={products.invest.data!} linkCTA="/invest/dashboard" variant={ProductName.invest} />
			)}

			{products.scpi.isOpen && (
				<InvestmentProductCard data={products.scpi.data!} linkCTA="/immobilier/dashboard" variant={ProductName.immo} />
			)}

			{products.pe.isOpen && (
				<InvestmentProductCard data={products.pe.data!} linkCTA="/private-equity/dashboard" variant={ProductName.pe} />
			)}

			{products.savings.isOpen && (
				<InvestmentProductCard data={products.savings.data!} linkCTA="/cash/dashboard" variant={ProductName.savings} />
			)}

			{products.corporate.isOpen &&
				products.corporate.data?.map(
					(corporate) =>
						isNotNone(corporate.data) && (
							<CorporateProductCard
								key={corporate.data.attributions[0].company.id}
								name={corporate.data.attributions[0].company.name}
								icon={<Img h="40px" w="40px" src={corporate.data!.attributions[0].company.logo} />}
								data={{
									status: 'OPENED',
									valuation: corporate.data.simulationData.valorisation,
									performance: { value: corporate.data.simulationData.theoretical_gain },
								}}
								linkCTA={`/bspce/${corporate.data.attributions[0].company.id}`}
							/>
						),
				)}
		</VStack>
	);
};
