import { FC } from 'react';
import { Checkbox, HStack, MenuItem, Switch, Text } from '@chakra-ui/react';

const CheckList: FC<{
	options: string[];
	localFilter: string[];
	onChange: (newValue: string, isChecked: boolean) => void;
	display?: Record<string, Record<string, string>>;
}> = ({ options, localFilter, onChange, display }) => (
	<>
		{options.map((value) => {
			const isChecked = localFilter.includes(value);
			return (
				<MenuItem closeOnSelect={false} key={value} onClick={() => onChange(value, !isChecked)}>
					<Checkbox isChecked={isChecked} pointerEvents="none">
						{display ? display[value].display : value}
					</Checkbox>
				</MenuItem>
			);
		})}
	</>
);

type SwitchListProps = {
	options: string[];
	localFilter: string[];
	display: Record<string, Record<string, string>>;
	onChange: (label: string, value: boolean) => void;
};

export const SwitchList = ({ options, localFilter, display, onChange }: SwitchListProps) => (
	<>
		{options.map((option) => {
			const isChecked = localFilter.includes(option);
			return (
				<MenuItem closeOnSelect={false} key={option} onClick={() => onChange(option, !isChecked)}>
					<HStack>
						<Switch
							isChecked={isChecked}
							size="sm"
							pointerEvents="none"
							sx={{
								'span.chakra-switch__track': { backgroundColor: 'white' },
								'span.chakra-switch__thumb[data-checked]': { backgroundColor: display[option].color },
								'span.chakra-switch__thumb:not([data-checked])': { backgroundColor: 'grey.700' },
							}}
							borderWidth="2px"
							borderRadius="1000000000"
							borderColor={isChecked ? display[option].color : 'grey.700'}
						/>
						<Text color={isChecked ? display[option].color : 'grey.700'}>{display[option].display}</Text>
					</HStack>
				</MenuItem>
			);
		})}
	</>
);

export default CheckList;
