import { backendApi } from 'services/apiService';
import { CustomAsset } from 'store/types/investment.type';
import { InvestSimulation, PopulatedInvestSimulation, SimulationEnvelopeType } from 'store/types/investSimulation';
import { PortfolioProjections, TaxEconomy } from 'store/types/portfolio.type';
import { InvestSubscription } from 'store/types/subscription.type';
import { AssetsDataRepartition } from 'utils/api';

export type StrategyRepartition = {
	initialAmountPER: number;
	initialAmountAV: number;
	amountPER: number;
	amountAV: number;
};

type Strategy = StrategyRepartition & {
	taxEconomy: TaxEconomy;
};

type SimulationResult = {
	scenarios: PortfolioProjections;
	assets: CustomAsset[];
	strategy: Strategy;
	esgPart: AssetsDataRepartition;
};

export type CreateInvestSimulation = Pick<InvestSimulation, 'saving' | 'initialAUM' | 'type'>;
export type UpdateInvestSimulationEnvelope = { envelope: SimulationEnvelopeType };
type UpdateInvestSimulation = CreateInvestSimulation;

export type UpdateSimulationFromResult = {
	saving: number;
	initialAUM: number;
};

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		createInvestSimulation: builder.mutation<InvestSimulation, CreateInvestSimulation>({
			query: (body) => ({ url: '/simulation', method: 'POST', body }),
			invalidatesTags: ['InvestSimulations'],
		}),
		updateInvestSimulationObjective: builder.mutation<InvestSimulation, UpdateInvestSimulation>({
			query: (body) => ({ url: `/simulation`, method: 'PUT', body }),
			invalidatesTags: ['InvestSimulations'],
		}),
		updateInvestSimulationEnvelope: builder.mutation<InvestSimulation, UpdateInvestSimulationEnvelope>({
			query: (body) => ({ url: `/simulation/envelope`, method: 'PUT', body }),
			invalidatesTags: ['InvestSimulations'],
		}),
		getInvestSimulation: builder.query<PopulatedInvestSimulation | null, void>({
			query: () => ({ url: `/simulation`, method: 'GET' }),
			providesTags: ['InvestSimulations'],
		}),
		getSimulationResult: builder.query<SimulationResult, void>({
			query: () => ({
				url: '/simulation/result',
			}),
			providesTags: ['InvestSimulations'],
		}),
		updateSimulationFromResult: builder.mutation<InvestSimulation, UpdateSimulationFromResult>({
			query: (params) => ({
				url: '/simulation/result',
				method: 'POST',
				body: params,
			}),
			invalidatesTags: ['InvestSimulations'],
		}),
		finishSimulation: builder.mutation<InvestSubscription[], StrategyRepartition>({
			query: (params) => ({
				url: '/simulation/finish',
				method: 'POST',
				body: params,
			}),
			invalidatesTags: ['InvestSubscriptions'],
		}),
		deleteSimulation: builder.mutation<void, void>({
			query: () => ({
				url: '/simulation',
				method: 'DELETE',
			}),
			invalidatesTags: ['InvestSimulations'],
		}),
	}),
});

export const {
	useCreateInvestSimulationMutation,
	useUpdateInvestSimulationObjectiveMutation,
	useGetInvestSimulationQuery,
	useGetSimulationResultQuery,
	useUpdateSimulationFromResultMutation,
	useFinishSimulationMutation,
	useUpdateInvestSimulationEnvelopeMutation,
	useDeleteSimulationMutation,
} = extendedApi;
