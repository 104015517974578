import { FC } from 'react';
import { Heading, VStack } from '@chakra-ui/react';

import Contract from 'pages/Invest/Contrats/ContractPage';
import GetEnvelopeStateList from 'pages/Invest/Contrats/ContractsSections/Sections/GetEnvelopeStateList';

const ContractList: FC = () => (
	<>
		<VStack w="100%" spacing="46px" align="start">
			<Heading variant="Title-L-SemiBold">Mes contrats</Heading>
			{GetEnvelopeStateList()}
		</VStack>
		<Contract />
	</>
);

export default ContractList;
