import { Box, Button, ComponentWithAs, HStack, Icon, IconProps, Image, Show, Stack, Text } from '@chakra-ui/react';

import Samy from 'assets/team/smallSamy.png';

type SamyAdvisorProps = {
	text?: string;
	complexText?: JSX.Element;
	buttonText?: string;
	onButtonClick?: () => void;
	bgColor?: string;
	borderColor?: string;
	buttonVariant?: string;
	icon?: ComponentWithAs<'svg', IconProps>;
	advisor?: typeof Samy;
};

const SamyAdvisor = ({
	text,
	complexText,
	onButtonClick,
	buttonText,
	bgColor = 'yellow.100',
	borderColor = 'grey.300',
	buttonVariant = 'primary',
	icon,
	advisor = Samy,
}: SamyAdvisorProps) => {
	return (
		<Stack
			direction={{ base: 'column', md: 'row' }}
			bg={bgColor}
			p="16px"
			border="base"
			borderRadius="base"
			borderColor={borderColor}
			w="100%"
			align="center"
			justify="space-between"
		>
			<HStack spacing="24px">
				<Show above="md">
					<Image borderRadius="full" w="36px" src={advisor} />
				</Show>

				{complexText ?? <Text>{text}</Text>}
			</HStack>

			{buttonText && (
				<Box w={{ base: '100%', md: 'auto' }}>
					<Button variant={buttonVariant} onClick={onButtonClick} w={{ base: '100%', md: 'auto' }}>
						{buttonText}
						{icon && <Icon as={icon} ml="8px" />}
					</Button>
				</Box>
			)}
		</Stack>
	);
};

export default SamyAdvisor;
