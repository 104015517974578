import { Heading, VStack } from '@chakra-ui/react';

import DocumentToDownload from 'components/DocumentToDownload';
import { downloadDocument } from 'services/requests/document';
import { PrivateEquitySubscription, SCPISubscription } from 'store/types/airtable.type';
import { SavingsAccountInformation } from 'store/types/savingsAccount.type';
import { isNone } from 'utils/functions';

const DocumentsToDownloadDashboard = ({
	subscription,
}: {
	subscription: SCPISubscription | PrivateEquitySubscription | SavingsAccountInformation;
}) => (
	<VStack w="100%" spacing="16px" align="start">
		<>
			{(('attestationDeParts' in subscription && subscription.attestationDeParts) ||
				('bulletinSigned' in subscription && subscription.bulletinSigned) ||
				('imprimeFiscal' in subscription && subscription.imprimeFiscal) ||
				('surveySigned' in subscription && subscription.surveySigned) ||
				('demembrementSigned' in subscription && subscription.demembrementSigned) ||
				('fundOriginDocument' in subscription && subscription.fundOriginDocument) ||
				('bulletin' in subscription && subscription.bulletin)) && (
				<Heading variant="Title-L-SemiBold">Documents relatifs à cette souscription</Heading>
			)}

			{'attestationDeParts' in subscription && subscription.attestationDeParts && (
				<DocumentToDownload
					name="Attestation de parts"
					documentUrl={subscription.attestationDeParts[0].url}
					documentFilename={subscription.attestationDeParts[0].filename}
				/>
			)}

			{'bulletinSigned' in subscription && subscription.bulletinSigned && (
				<DocumentToDownload
					name="Bulletin de souscription"
					documentUrl={subscription.bulletinSigned[0].url}
					documentFilename={subscription.bulletinSigned[0].filename}
				/>
			)}

			{'imprimeFiscal' in subscription && subscription.imprimeFiscal && (
				<DocumentToDownload
					name="Imprimé fiscal"
					documentUrl={subscription.imprimeFiscal[0].url}
					documentFilename={subscription.imprimeFiscal[0].filename}
				/>
			)}

			{'surveySigned' in subscription && subscription.surveySigned && (
				<DocumentToDownload
					name="Questionnaire connaissance client"
					documentUrl={subscription.surveySigned[0].url}
					documentFilename={subscription.surveySigned[0].filename}
				/>
			)}

			{'demembrementSigned' in subscription && subscription.demembrementSigned && (
				<DocumentToDownload
					name="Document de nue-propriété"
					documentUrl={subscription.demembrementSigned[0].url}
					documentFilename={subscription.demembrementSigned[0].filename}
				/>
			)}

			{'fundOriginDocument' in subscription && subscription.fundOriginDocument && (
				<DocumentToDownload
					name="Déclaration d'origine des fonds"
					documentUrl={subscription.fundOriginDocument[0].url}
					documentFilename={subscription.fundOriginDocument[0].filename}
				/>
			)}

			{'bulletin' in subscription && subscription.bulletin && (
				<DocumentToDownload
					handleDownloadCustom={() => {
						downloadDocument({
							contentType: 'application/pdf',
							filename: 'bulletin.pdf',
							filepath: `livret-epargne/${subscription.id}/bulletin`,
						});
					}}
					name="Bulletin de souscription"
				/>
			)}

			{'trimestrialReport' in subscription &&
				subscription.trimestrialReport &&
				subscription.trimestrialReport.map((trimestrialReport) => {
					if (isNone(trimestrialReport.report) || trimestrialReport.report.length === 0) return null;

					return (
						<DocumentToDownload
							key={trimestrialReport.report[0].id}
							name={`Rapport trimestriel - ${trimestrialReport.period}`}
							documentUrl={trimestrialReport.report[0].url}
							documentFilename={trimestrialReport.report[0].filename}
						/>
					);
				})}
		</>
	</VStack>
);

export default DocumentsToDownloadDashboard;
