import { PatrimonySource } from 'store/referentiels/patrimonySource';

export enum SavingsAccountType {
	CER = 'CER',
	CAT = 'CAT',
}

export const savingsAccountTypeLabels: Record<SavingsAccountType, string> = {
	[SavingsAccountType.CAT]: 'Compte à terme',
	[SavingsAccountType.CER]: 'Livret épargne',
};

export enum SavingsAccountProvider {
	MY_MONEY_BANK = 'MY_MONEY_BANK',
}

export const savingsAccountProviderLabels: Record<SavingsAccountProvider, string> = {
	[SavingsAccountProvider.MY_MONEY_BANK]: 'MY MONEY BANK',
};

export enum SavingsAccountSupplyFrequency {
	REGULARLY = 'REGULARLY',
	PUNCTUALLY = 'PUNCTUALLY',
}

export enum SavingsAccountTimeHorizon {
	LESS_THAN_1_YEAR = 'LESS_THAN_1_YEAR',
	MORE_THAN_1_YEAR = 'MORE_THAN_1_YEAR',
}

export enum SavingsAccountStatus {
	REQUESTED = 'REQUESTED',
	SIGNED = 'SIGNED',
	SENT_TO_PARTNER = 'SENT_TO_PARTNER',
	WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
	COMPLETED = 'COMPLETED',
}

export type SavingsAccountInformation = {
	id: string;
	initialAUM?: number;
	timeHorizon?: SavingsAccountTimeHorizon;
	moneySupplyFrequency?: SavingsAccountSupplyFrequency;
	fundsOrigin?: PatrimonySource;
	bankInformationId?: string;
	status: SavingsAccountStatus;
	performance: SavingsAccountPerformanceResponse;
	createdAt: Date;
	endDate?: Date;
	openingDate?: Date;
	promotionalOfferRate: number;
	standardRate: number;
	promotionalOfferDuration: number;
	provider: SavingsAccountProvider;
	externalAccountId: string;
	type: SavingsAccountType;
};

export interface SavingsAccountWithHistory extends SavingsAccountInformation {
	history?: SavingsAccountHistoryResponse;
}

export type UpdateSavingsAccountDTO = Partial<SavingsAccountInformation>;

export interface UpdateSavingsAccountStatusDTO {
	status: SavingsAccountStatus;
}

export interface SavingsAccountHistory {
	id: string;
	date: string;
	valuation: string;
	estimatedValuation?: string;
}

export type SavingsAccountHistoryResponse = SavingsAccountHistory[];

export interface SavingsAccountPerformanceResponse {
	value: number;
	percentage: number;
}

export interface SavingsAccountUpdateTableResponse {
	date: string;
	valorisation: number;
	effective_rate: number;
	effective_inception_date: string;
}
