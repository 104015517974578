import { Tag, TagCloseButton, TagLabel, Wrap } from '@chakra-ui/react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { useAppDispatch } from 'hooks/useStore';
import { PEFilterKeyType } from 'utils/fundFilters';

interface FilterTagsProps {
	filters: { value: string; label: string; filterType: PEFilterKeyType }[];
	selectedFilters: string[];
	multiFilter: boolean;
	setFilter: ActionCreatorWithPayload<
		{
			key: PEFilterKeyType;
			value: string;
			isChecked: boolean;
		},
		'PEFilter/setFilter'
	>;
}

export const FilterTags = ({ filters, selectedFilters, multiFilter, setFilter }: FilterTagsProps) => {
	const dispatch = useAppDispatch();

	const handleSelect = (value: string, filterType: PEFilterKeyType) => {
		if (selectedFilters.includes(value)) {
			selectedFilters.map((selectedFilter: string) => {
				if (selectedFilter === value) dispatch(setFilter({ key: filterType, value: value, isChecked: false }));
			});
		} else {
			if (!multiFilter) {
				selectedFilters.map((selectedFilter: string) => {
					if (selectedFilter === value) dispatch(setFilter({ key: filterType, value: value, isChecked: true }));
					else dispatch(setFilter({ key: filterType, value: value, isChecked: false }));
				});
			} else {
				console.log('ici');
				dispatch(setFilter({ key: filterType, value: value, isChecked: true }));
			}
		}
	};

	return (
		<Wrap shouldWrapChildren>
			{filters.map(({ label, value, filterType }) => (
				<Tag
					key={value}
					onClick={() => handleSelect(value, filterType)}
					variant={selectedFilters.includes(value) ? 'filter-selected' : 'filter'}
				>
					<TagLabel>{label}</TagLabel>
					{selectedFilters.includes(value) && <TagCloseButton />}
				</Tag>
			))}
		</Wrap>
	);
};
