import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import { useAppDispatch } from 'hooks/useStore';
import { OnboardingUpdateStepProps, setLastInnerStepAvailable } from 'onboarding/Stepper/stepper.slice';
import { isNone, isNotNone } from 'utils/functions';

import PortfolioStyle from './Steps/Green/Style';
import Introduction from './Steps/Introduction';
import Recapitulatif from './Steps/Recapitulatif/Recapitulatif';
import RiskAversion from './Steps/RiskAversion';
import PortfolioTypeStep from './Steps/Type/Type';
import { introductionStep, PortfolioSteps, recapitulatifStep, riskAversionStep, styleStep, typeStep } from './steps';

const Portfolio = ({ object: onboardingObject, onNext }: OnboardingUpdateStepProps): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const mostAdvancedStep = useMemo(() => {
		if (isNone(onboardingObject.investmentPreferences)) return introductionStep;

		if (
			isNone(onboardingObject.investmentPreferences.esg) ||
			(onboardingObject.investmentPreferences.esg === true &&
				isNone(onboardingObject.investmentPreferences.temperature))
		)
			return styleStep;

		if (
			isNone(onboardingObject.investmentPreferences.portfolioType) ||
			(onboardingObject.initialAUM < 50000 && onboardingObject.investmentPreferences.portfolioType === 'ELITE')
		)
			return typeStep;

		return recapitulatifStep;
	}, [onboardingObject.initialAUM, onboardingObject.investmentPreferences]);

	useEffect(() => {
		if (isNotNone(mostAdvancedStep)) dispatch(setLastInnerStepAvailable(mostAdvancedStep.id));
	}, [mostAdvancedStep, dispatch]);

	// route guard that prevents the user from going further if subscription doesn't exist
	const preferencesExistsGuard = useCallback(
		() => (onboardingObject.investmentPreferences ? <Outlet /> : <Navigate to="portefeuille" replace />),
		[onboardingObject.investmentPreferences],
	);

	const introNext = useCallback(() => {
		navigate(mostAdvancedStep.id === PortfolioSteps.INTRODUCTION ? 'risque' : mostAdvancedStep.url);
	}, [navigate, mostAdvancedStep]);

	const riskNext = useCallback(() => {
		navigate('style');
	}, [navigate]);

	const styleNext = useCallback(() => {
		navigate('type');
	}, [navigate]);

	const typeNext = useCallback(() => {
		navigate('recapitulatif');
	}, [navigate]);

	return (
		<VStack spacing={{ base: '40px', md: '48px', lg: '56px' }} align="start" w="100%">
			<Routes>
				<Route index element={<Introduction onNext={introNext} />} />
				<Route
					path="risque"
					element={
						<RiskAversion onPrev={() => navigate(introductionStep.url)} object={onboardingObject} onNext={riskNext} />
					}
				/>
				<Route
					path="style"
					element={
						<PortfolioStyle
							onPrev={() => navigate(riskAversionStep.url)}
							object={onboardingObject}
							onNext={styleNext}
						/>
					}
				/>

				<Route element={preferencesExistsGuard()}>
					<Route
						path="type"
						element={
							<PortfolioTypeStep onPrev={() => navigate(styleStep.url)} object={onboardingObject} onNext={typeNext} />
						}
					/>
					<Route
						path="recapitulatif"
						element={<Recapitulatif onPrev={() => navigate(typeStep.url)} object={onboardingObject} onNext={onNext} />}
					/>

					<Route path="*" element={<Navigate to={introductionStep.url} replace />} />
				</Route>
			</Routes>
		</VStack>
	);
};

export default Portfolio;
