import { FC, PropsWithChildren } from 'react';
import { Idea } from '@carbon/icons-react';
import { Card, CardProps, Circle, HStack, VStack } from '@chakra-ui/react';

export const RamifyTipCard: FC<PropsWithChildren<CardProps>> = ({ children, ...cardProps }) => {
	return (
		<Card {...cardProps}>
			<HStack w="100%" align="start" spacing="sm">
				<Circle size="min-content" p="8px" bg="informative.100" color="informative.900">
					<Idea size="32" />
				</Circle>
				<VStack flex="1" align="start">
					{children}
				</VStack>
			</HStack>
		</Card>
	);
};
