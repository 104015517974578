import { VStack } from '@chakra-ui/react';

import Article from 'components/Articles/Article';
import Banner from 'components/Banners/Banner';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';

import Catalog from './Catalog';

const RealEstate = (): JSX.Element => {
	const toast = useThemedToast();

	const sendCrowdfundingOpportunityMail = () => {
		eventTracker.pipedream
			.crowdfundingOpportunityMail()
			.then(() => {
				toast({
					title: "Merci pour votre intérêt, vous allez recevoir un email avec toutes les informations sur l'opération",
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
			})
			.catch((error) => {
				if (error.response.status === 409) {
					toast({
						title: "Un email vous a déjà été envoyé, si vous ne l'avez pas reçu veuillez nous contacter",
						status: 'info',
						duration: 8000,
						isClosable: true,
					});
				} else {
					toast({
						title: 'Une erreur est survenue, veuillez ressayer plus tard',
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				}
			});
	};

	const sendEligibilityMail = () => {
		eventTracker.pipedream
			.scpiCreditMailOffer()
			.then(() => {
				toast({
					title: 'Merci pour votre intérêt, vous allez recevoir un email avec formulaire d’éligibilité',
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
			})
			.catch((error) => {
				if (error.response.status === 409) {
					toast({
						title: 'Un email vous a déjà été envoyé, si vous ne l’avez pas reçu veuillez nous contacter',
						status: 'info',
						duration: 8000,
						isClosable: true,
					});
				} else {
					toast({
						title: 'Une erreur est survenue, veuillez ressayer plus tard',
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				}
			});
	};

	return (
		<VStack spacing="xl" mb="80px" align="start">
			<VStack w="100%" spacing="lg">
				{/* We leave this banner because can't really handle it programatically from airtable */}
				<Banner
					avatar="samy"
					title="Lancement de notre offre de Crowdfunding Immobilier à 8 % annuel"
					description="Recevez dès maintenant cette opportunité par email"
					variant="commercial"
					onClick={sendCrowdfundingOpportunityMail}
					cta="Recevoir l'opportunité"
				/>

				<Banner
					avatar="samy"
					title="Ramify obtient la meilleure offre de SCPI à crédit du marché"
					description="Testez votre éligibilité pour en profiter dès à présent."
					variant="commercial"
					onClick={sendEligibilityMail}
					cta="Tester mon éligibilité"
				/>
			</VStack>

			<Article
				imageURL="https://assets.website-files.com/63c027c9ecb9a193a6b225ea/6492e712c67a2baf0e120a56_luke-van-zyl-koH7IVuwRLw-unsplash-p-800.webp"
				title="Qu'est-ce que les SCPI"
				description="Une Société Civile de Placement Immobilier (SCPI) est un organisme de placement collectif qui rassemble des fonds apportés par des investisseurs en vue d’acquérir et de gérer un patrimoine immobilier. En contrepartie, les épargnants reçoivent des parts sociales qui ne sont pas cotées en Bourse."
				tags={[{ label: 'Guide', variant: 'coloredBgYellow' }]}
				btnCallback={() => window.open('https://www.ramify.fr/scpi')}
			/>
			<Catalog />
		</VStack>
	);
};

export default RealEstate;
