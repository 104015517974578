import { FC } from 'react';
import { ArrowLeft, DataViewAlt } from '@carbon/icons-react';
import {
	Button,
	Card,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	HStack,
	IconButton as ChakraIconButton,
	Image,
	ListItem,
	Text,
	UnorderedList,
	useToken,
	VStack,
} from '@chakra-ui/react';

import CAT from 'assets/icons/ProductsColoredIcons/Cat.svg';
import { IconButton } from 'components/Button/Button';

import { Properties } from './Properties';
import { CashProductContentProps } from './type';

const catProperties = [
	{ label: 'Couverture du FGDR', value: 'Jusqu’à 100 000 €' },
	{ label: 'Disponibilité des fonds', value: 'Immédiate' },
	{ label: 'Frais d’entrée', value: 'Aucun' },
	{ label: 'Frais de gestion', value: 'Aucun' },
	{ label: 'Frais d’opérations', value: 'Aucun' },
	{ label: 'Frais de clôture', value: 'Aucun' },
	{ label: 'Fiscalité', value: 'Bénéfices soumis au PFU (30%)' },
];

export const CatDrawer: FC<CashProductContentProps> = ({ isOpen, onClose }) => {
	const bulletPointColor = useToken('colors', 'grey.900');

	return (
		<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isOpen} onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<HStack justify="space-between">
						<HStack>
							<ChakraIconButton onClick={onClose} variant="icon" aria-label="back" icon={<ArrowLeft size="32" />} />
							<Heading variant="Title-M-SemiBold">Retour</Heading>
						</HStack>
						<Button isDisabled variant="primary" size="lg" onClick={() => {}}>
							Ouvrir un compte à terme
						</Button>
					</HStack>
				</DrawerHeader>
				<DrawerBody>
					<VStack w="100%" spacing="32px" align="start">
						<Card
							w="100%"
							border="none"
							bg="linear-gradient(139deg, #FFEADE 0%, rgba(255, 247, 242, 0.10) 100%)"
							padding="64px 40px"
						>
							<VStack w="100%" align="start" spacing="24px">
								<Image src={CAT} w="40px" />
								<VStack w="100%" align="start" spacing="8px">
									<Heading variant="Title-XL-Bold" color="orange.900">
										Compte à terme
									</Heading>
									<Text variant="Text-S-Bold" color="orange.500">
										My Money Bank
									</Text>
								</VStack>
							</VStack>
						</Card>

						<VStack w="100%" align="start" spacing="16px">
							<Text variant="Title-L-SemiBold">Caractéristiques de votre compte à terme</Text>
							<VStack display="flex" w="100%" spacing="16px">
								<Card w="100%">
									<VStack w="100%" align="start">
										<Text variant="Title-S-SemiBold" color="primary.black">
											Une épargne disponible
										</Text>
										<UnorderedList css={{ 'li::marker': { color: bulletPointColor } }}>
											<ListItem>
												<Text variant="Text-M-Regular" color="grey.900">
													Une solution flexible : le capital est disponible à tout moment.
												</Text>
											</ListItem>
											<ListItem>
												<Text variant="Text-M-Regular" color="grey.900">
													Le retrait anticipé des fonds est possible. Il entraîne une diminution du taux de
													rémunération.
												</Text>
											</ListItem>
											<ListItem>
												<Text variant="Text-M-Regular" color="grey.900">
													Plus la durée de placement des fonds est longue, plus le taux de rémunération est important.
												</Text>
											</ListItem>
										</UnorderedList>
									</VStack>
								</Card>
								<Card w="100%">
									<HStack spacing="16px" justify="space-between">
										<Text variant="Title-S-SemiBold" color="primary.black">
											Taux applicables à date
										</Text>
										<IconButton
											right
											icon={<DataViewAlt />}
											variant="secondary"
											onClick={() => {
												window.open(
													'https://ramify-public-documents.s3.eu-west-3.amazonaws.com/Cash/CAT+_+Taux+PP+(1).png',
												);
											}}
										>
											Visualiser les taux
										</IconButton>
									</HStack>
								</Card>
							</VStack>
						</VStack>

						<Properties properties={catProperties} />
					</VStack>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
