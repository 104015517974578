import { Box, LayoutProps, PositionProps } from '@chakra-ui/react';

interface ActiveMenuIndicatorProps {
	left: PositionProps['left'];
	h: LayoutProps['h'];
	w: LayoutProps['w'];
}

const ActiveMenuIndicator = ({ left, w, h }: ActiveMenuIndicatorProps): JSX.Element => {
	return (
		<Box
			left={left}
			w={w}
			h={h}
			bottom="0"
			bg="yellow.500"
			position="absolute"
			margin="0px !important"
			transition="0.3s all ease-in"
			borderRadius="8px 8px 0px 0px"
		/>
	);
};

export default ActiveMenuIndicator;
