import { useState } from 'react';
import { Button, Card, Heading, HStack, VStack } from '@chakra-ui/react';

import { AttributeKycLabel } from 'components/badge/AttributeKycLabel';
import ContactInformationForm from 'onboarding/KYC/Forms/ContactInformationForm';
import ResidenceReferentiel, { Residence } from 'store/referentiels/pays/residence';
import { KYC } from 'store/types/KYC.type';

const ContactInformationsEditable = ({ kyc }: { kyc?: KYC }): JSX.Element => {
	const [isEditing, setisEditing] = useState(false);

	return (
		<Card w="100%">
			<VStack w="100%" spacing="md" align="start">
				<HStack spacing="40px" w="100%" justify="space-between">
					<Heading variant="Title-S-SemiBold">Mes coordonnées</Heading>
					<Button variant="secondary" size="md" onClick={() => setisEditing(!isEditing)}>
						{isEditing ? 'Annuler' : 'Modifier'}
					</Button>
				</HStack>
				{isEditing ? (
					<ContactInformationForm
						onSuccess={() => setisEditing(false)}
						formConfirmation={
							<Button type="submit" w="100%" borderRadius="5px" variant="primary">
								Mettre à jour
							</Button>
						}
					/>
				) : (
					<VStack align="start">
						<AttributeKycLabel title="Adresse principale" value={kyc?.address?.toString()} />
						<AttributeKycLabel
							title="Pays"
							value={kyc?.country && ResidenceReferentiel[kyc?.country as Residence].toString()}
						/>
						<AttributeKycLabel title="Code postale" value={kyc?.zipCode && kyc?.zipCode.toString()} />
						<AttributeKycLabel title="Ville" value={kyc?.city && kyc?.city.toString()} />
						<AttributeKycLabel
							title="Numéro de téléphone mobile"
							value={kyc?.phoneNumber && `(${kyc?.phoneNumberCode?.replace('00', '+')}) ${kyc?.phoneNumber}`}
						/>
					</VStack>
				)}
			</VStack>
		</Card>
	);
};

export default ContactInformationsEditable;
