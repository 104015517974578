import { ReactNode, SyntheticEvent } from 'react';
import { ChevronDown, ChevronUp } from '@carbon/icons-react';
import { DownloadIcon } from '@chakra-ui/icons';
import {
	Badge,
	Box,
	Button,
	Card,
	Heading,
	HStack,
	Text,
	useDisclosure,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import { DownloadButton } from 'components/inputs/DownloadButton';
import { isNotNone } from 'utils/functions';

export type InfosFundCardType = {
	name: string;
	value: string;
};

// function wrapper that calls stopPropagation on the event
const stopPropagation = (fn: () => void) => (e: SyntheticEvent) => {
	e.stopPropagation();
	fn();
};

export const InfosFundCard = ({ featureName, featureValue }: { featureName: string; featureValue: string }) => (
	<Text variant="Text-S-Regular">
		{featureName} :{' '}
		<Text variant="Text-S-Bold" as="span">
			{featureValue}
		</Text>
	</Text>
);

type AccessProps = { hasAccess: true } | { hasAccess: false; forbiddenText: string };

type DetailsProps =
	| { available: true; onDetailsClick: () => void }
	| { available: false; onDicClick: () => void; onPlaquetteClick: () => void };

type DescriptionProps = {
	content: ReactNode;
	notifyToggle: () => void;
};

type CardCatalogueType = {
	animate?: boolean;
	closed?: boolean;
	features: Array<InfosFundCardType>;
	tags?: string[];
	onInvest?: () => void;
	coverPicture: string;
	description: DescriptionProps;
	name: ReactNode;
	partner: ReactNode;
	access: AccessProps;
	details: DetailsProps;
};

const CardCatalogue = ({
	coverPicture,
	description,
	name,
	animate = true,
	tags,
	features,
	partner,
	onInvest,
	access,
	details,
	closed,
}: CardCatalogueType) => {
	const { isOpen: isDescriptionExpanded, onToggle: toggleDescriptionExpansion } = useDisclosure();

	return (
		<Card
			size="sm"
			onClick={details.available ? details.onDetailsClick : undefined}
			_hover={
				animate && !closed && details.available
					? { transition: 'all 0.3s ease-out', transform: 'scale(1.015)' }
					: undefined
			}
			cursor={details.available ? 'pointer' : undefined}
		>
			<VStack w="100%" h="100%" align="start" spacing="lg" justify="space-between">
				<VStack w="100%" align="start" spacing="16px">
					<Box w="100%" h="158px" position="relative">
						<Box
							w="100%"
							h="100%"
							background={`url(${coverPicture})`}
							backgroundPosition="center"
							backgroundSize="cover"
							borderRadius="base"
							filter="auto"
							blur={access.hasAccess ? undefined : '2px'}
						/>

						{!access.hasAccess && (
							<Text
								w="70%"
								color="white"
								variant="Text-M-Bold"
								position="absolute"
								top="49%"
								left="50%"
								transform="translate(-50%, -50%)"
								textAlign="center"
								alignSelf="center"
							>
								{access.forbiddenText}
							</Text>
						)}
					</Box>

					<Text variant="subtitle">{partner}</Text>

					<VStack w="100%" align="start" spacing="8px">
						<Heading minH="48px" variant="Title-M-SemiBold">
							{name}
						</Heading>

						<Text noOfLines={isDescriptionExpanded ? undefined : 3} variant="Text-S-Regular">
							{description.content}
						</Text>
					</VStack>

					<HStack
						w="100%"
						justify="space-between"
						onClick={stopPropagation(() => {
							description.notifyToggle();
							if (access.hasAccess) toggleDescriptionExpansion();
						})}
						borderRadius="4"
						px="16px"
						_hover={{
							cursor: 'pointer',
							textDecoration: 'underline',
							backgroundColor: 'rgba(30, 30, 30, 0.08)',
						}}
					>
						<Button variant="unstyled" h="40px">
							{isDescriptionExpanded ? 'Voir moins' : 'Voir plus'}
						</Button>

						{isDescriptionExpanded ? <ChevronUp /> : <ChevronDown />}
					</HStack>

					<VStack w="100%" align="start" spacing="16px">
						{isNotNone(tags) && tags.length && (
							<Wrap w="100%" align="start" opacity={0.6}>
								{tags.map((tag) => (
									<WrapItem key={tag}>
										<Badge variant="outlineYellow">{tag}</Badge>
									</WrapItem>
								))}
							</Wrap>
						)}

						<VStack w="100%" align="start" spacing="12px">
							{features.map((info) => (
								<InfosFundCard key={info.name} featureName={info.name} featureValue={info.value} />
							))}
						</VStack>
					</VStack>
				</VStack>

				<VStack w="100%" align="start" spacing="4px">
					{details.available ? (
						<ArrowButton
							w="100%"
							h="40px"
							p="0px"
							variant="tertiary"
							onClick={stopPropagation(details.onDetailsClick)}
							isDisabled={closed}
						>
							En savoir plus
						</ArrowButton>
					) : (
						<HStack spacing="8px" w="100%">
							<DownloadButton
								flex={1}
								name="DIC"
								leftIcon={<DownloadIcon />}
								onClick={stopPropagation(details.onDicClick)}
								isDisabled={closed}
							/>
							<DownloadButton
								flex={1}
								name="Plaquette"
								leftIcon={<DownloadIcon />}
								onClick={stopPropagation(details.onPlaquetteClick)}
								isDisabled={closed}
							/>
						</HStack>
					)}
					{isNotNone(onInvest) && (
						<Button w="100%" isDisabled={closed} onClick={stopPropagation(onInvest)}>
							Investir
						</Button>
					)}
				</VStack>
			</VStack>
		</Card>
	);
};

export default CardCatalogue;
