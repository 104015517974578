import { createStandaloneThemedToast } from 'hooks/useThemedToast';
import { IncomePeriod } from 'store/referentiels/incomePeriod';
import { IncomeType } from 'store/referentiels/incomeType';

import api from './api';

const toast = createStandaloneThemedToast();
const idToastUnvailableGeoAPI = 'Unavailable-geo-api';

type OptionLoaded = {
	label: string;
	value: string;
};

let timeoutCityOptionLoader: NodeJS.Timeout | undefined;
const cityOptionsLoader = (iv: string, callback: (options: OptionLoaded[]) => void): void => {
	if (iv.length < 3) return;
	clearTimeout(timeoutCityOptionLoader!);
	timeoutCityOptionLoader = setTimeout(() => {
		fetch(
			`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(iv)}&type=municipality&autocomplete=1&limit=5`,
		)
			.then((response) => response.json())
			.then((list_city) => {
				callback(
					list_city.features.map(({ properties }: { properties: GeoAPIAdresse }) => ({
						label: properties.label,
						value: { libelle: properties.city, code: properties.citycode },
					})),
				);
			})
			.catch((e) => {
				console.error(e, 'Service momentanément indisponible');
				if (!toast.isActive(idToastUnvailableGeoAPI))
					toast({
						id: idToastUnvailableGeoAPI,
						title: 'Service momentanément indisponible',
						description: 'Veuillez réessayer dans quelques secondes.',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
			});
	}, 500);
};

type GeoAPIAdresse = {
	label: string;
	housenumber: string;
	id: string;
	name: string;
	postcode: string;
	citycode: string;
	city: string;
	street: string;
	district: string;
};

export type Address = {
	label: string;
	value: string;
	annexValue: {
		postcode: string;
		city: string;
		citycode: string;
	};
};

const getAddresses = (iv: string): Promise<Address[]> =>
	fetch(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(iv)}&type=housenumber&autocomplete=1&limit=4`)
		.then((response) => response.json())
		.then((list_adresses) =>
			list_adresses.features.map(({ properties }: { properties: GeoAPIAdresse }) => ({
				label: properties.label,
				value: properties.name,
				annexValue: { postcode: properties.postcode, city: properties.city, citycode: properties.citycode },
			})),
		)
		.catch((e) => {
			console.error(e, 'Service momentanément indisponible');
			if (!toast.isActive(idToastUnvailableGeoAPI)) {
				toast({
					id: idToastUnvailableGeoAPI,
					title: 'Service momentanément indisponible',
					description: 'Veuillez réessayer dans quelques secondes.',
					status: 'error',
					duration: 9000,
					isClosable: true,
				});
			}
		});

type PromotionValidity = {
	valid: boolean;
	partnerName?: string;
	reason?: string;
};
let timeoutPromoLoader: NodeJS.Timeout | undefined;

const codePromoLoader = (promoCode: string): Promise<PromotionValidity> =>
	new Promise((resolve) => {
		clearTimeout(timeoutPromoLoader!);
		timeoutPromoLoader = setTimeout(() => {
			api
				.isCodeValid(promoCode)
				.then(({ data: validity }) => {
					resolve(validity);
				})
				.catch(() => {
					resolve({ valid: false });
				});
		}, 500);
	});

const calcLabNetAnnualIncome = (amount: number, periodicity: IncomePeriod, revenuType: IncomeType): number =>
	amount * (periodicity === 'ANNUALLY' ? 1 : 12) * (revenuType === 'GROSS' ? 0.78 : 1);

const calculatePercentage = (value: number, total: number) => Math.round((value / total) * 100);

const isNotNone = <T>(value: T | null | undefined): value is T => value !== undefined && value !== null;
const isNone = <T>(value: T | null | undefined): value is null | undefined => !isNotNone(value);

const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

// String.toLowerCase method forgets string literals
const toLowerCase = <T extends string>(str: T) => str.toLocaleLowerCase() as Lowercase<T>;

const toBase64 = (file: File) =>
	new Promise<string | ArrayBuffer | null>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

export {
	calcLabNetAnnualIncome,
	calculatePercentage,
	cityOptionsLoader,
	codePromoLoader,
	getAddresses,
	isNone,
	isNotNone,
	sleep,
	toBase64,
	toLowerCase,
};
