import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from '@nivo/core';
import { Chip, TableTooltip } from '@nivo/tooltip';

import colors from 'theme/foundations/colors';

export type VestingGraphData = {
	year: number;
	month: number;
	monthIndex: number;
	monthString: string;
	vested: number;
	notVested: number;
};

function getMarkerValue(data: VestingGraphData[]) {
	const today = new Date();
	const year = today.getFullYear();
	const monthIndex = today.getMonth();
	return data.findIndex((d) => d.year === year && d.monthIndex === monthIndex);
}

function SliceTooltip({ data }: { data: VestingGraphData }): JSX.Element {
	const theme = useTheme();

	return (
		<TableTooltip
			title={
				<b>
					{data.monthString} {data.year}
				</b>
			}
			rows={[
				[
					<Chip key="chip" color="#cfe2d9" style={theme.tooltip.chip} />,
					'Nombre de parts vestées',
					<span key="value" style={theme.tooltip.tableCellValue}>
						<b>{Math.ceil(data.vested) || 0}</b>
					</span>,
				],
				[
					<Chip key="chip" color="#d2c6eb" style={theme.tooltip.chip} />,
					'Nombre de parts non vestées',
					<span key="value" style={theme.tooltip.tableCellValue}>
						<b>{Math.ceil(data.notVested) || 0}</b>
					</span>,
				],
			]}
		/>
	);
}

export const VestingGraph = ({ data }: { data: VestingGraphData[] }) => (
	<ResponsiveBar
		data={data}
		keys={['vested', 'notVested']}
		indexBy="month"
		margin={{ right: 10, left: 10, top: 20, bottom: 20 }}
		borderRadius={2}
		innerPadding={1}
		colorBy="id"
		colors={['#cfe2d9', '#d2c6eb']}
		markers={[
			{
				axis: 'x',
				value: getMarkerValue(data),
				lineStyle: { stroke: colors.grey[700], strokeWidth: 1, borderRadius: 15 },
				legend: "aujourd'hui",
				legendOrientation: 'horizontal',
				legendPosition: 'top',
				textStyle: { fontSize: 12 },
			},
		]}
		axisBottom={{
			tickSize: 0,
			format: (v) => (data[v].year === data[v - 1]?.year || (data[v].monthIndex >= 7 && v <= 7) ? '' : data[v].year),
		}}
		axisLeft={null}
		enableGridY={false}
		enableLabel={false}
		isInteractive
		tooltip={SliceTooltip}
	/>
);
