import { FC } from 'react';
import { Heading, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import CAT from 'assets/icons/ProductsColoredIcons/Cat.svg';
import CER from 'assets/icons/ProductsColoredIcons/Cer.svg';
import ProductDiscoverCard from 'components/cards/ProductDiscoverCard';

import useCERCallToAction from './useCERCallToAction';

const CardBoost: FC<{ title: string; desc: string }> = ({ title, desc }) => (
	<VStack padding="16px" spacing="0px" align="start" w="100%" bg="orange.50" borderRadius="base">
		<Heading variant="Title-L-Bold" color="orange.900">
			{title}
		</Heading>
		<Text variant="Text-M-Regular">{desc}</Text>
	</VStack>
);

const DiscoverSection: FC<{ onCerDetails: () => void; onCatDetails: () => void }> = ({
	onCerDetails,
	onCatDetails,
}): JSX.Element => {
	const { cta } = useCERCallToAction();

	return (
		<VStack w="100%" align="start" spacing="32px">
			<Wrap w="100%" spacing="24px">
				<WrapItem alignItems="stretch" minW="320px" flex="1">
					<ProductDiscoverCard
						icon={CER}
						title="Livret épargne"
						description="Une solution simple et efficace pour faire fructifier son épargne."
						customNode={<CardBoost title="Taux boosté à 3,5 %" desc="les deux premiers mois, puis 2,8%*" />}
						callToActions={[
							{
								label: 'Voir les détails',
								variant: 'secondary',
								onClick: onCerDetails,
							},
							cta,
						]}
					/>
				</WrapItem>

				<WrapItem w="100%" alignItems="stretch" minW="320px" flex="1">
					<ProductDiscoverCard
						icon={CAT}
						title="Comptes à terme"
						description="Des intérêts proportionnels à la durée de placement."
						customNode={<CardBoost title="Jusqu’à 3,5 %" desc="selon la durée, de 24 à 36 mois" />}
						callToActions={[
							{
								label: 'Voir les détails',
								variant: 'secondary',
								onClick: onCatDetails,
							},
							{
								label: 'Bientôt disponible',
								variant: 'secondary',
								isDisabled: true,
								onClick: () => {},
							},
						]}
					/>
				</WrapItem>
			</Wrap>
			<Text variant="Text-S-Medium" color="grey.700">
				*Dans la limite des 150 000 premiers euros déposés, à condition que votre Livret épargne soit encore ouvert au
				31 décembre de l'année de l'ouverture de votre livret.
			</Text>
		</VStack>
	);
};

export default DiscoverSection;
