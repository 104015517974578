import axios, { AxiosInstance, AxiosResponse } from 'axios';
import FileDownload from 'js-file-download';

import { CustomAsset } from 'store/types/investment.type';

import { backendConstants } from './constants';
import toast from './standaloneToast';

export interface AssetsDataRepartition {
	id: string;
	montant: number;
	repartition: number;
	label: string;
}

export interface InstrumentDataRepartition {
	devise: string;
	name: string;
	sector: string;
	repartition: number;
}

export type HoldingInstruments = {
	listTopinstruments: InstrumentDataRepartition[];
	total_number: number;
};

export type ApicilVersementWithRepartition = {
	id: number;
	repartition: CustomAsset[];
};

type OperationType = 'transfert' | 'private-equity';

export type NonValidityType = 'ARCHIVED' | 'EXPIRED' | 'UNKNOWN' | 'SAME_USER';
export type CodeValidityResponse = {
	valid: boolean;
	reason?: NonValidityType;
};

class Api {
	instance: AxiosInstance;

	token: string | null = null;

	constructor() {
		this.instance = axios.create({
			baseURL: backendConstants.backendUrl,
			timeout: 3000,
			headers: { 'Content-Type': 'application/json' },
		});

		this.setHeaderToken(localStorage.getItem('token'));
		this.instance.interceptors.response.use(
			(response) => response,
			(error) => {
				if (error.isAxiosError && error.response?.status === 401) {
					localStorage.removeItem('token');
					if (!window.location.pathname.startsWith('/valider-email/')) window.location.replace('/connexion');
				} else console.dir(error);
				// if (error.response?.data?.message) throw new Error(error.response.data.message);
				throw error;
			},
		);
	}

	get axiosInstance() {
		return this.instance;
	}

	setHeaderToken(token: string | null) {
		this.token = token;
		this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
	}

	removeHeaderToken() {
		this.token = null;
		delete this.instance.defaults.headers.common.Authorization;
	}

	/* **************
		API Methods
	************** */

	//
	// User
	//
	getOperationToken = (type: OperationType) =>
		this.instance.get<string>(`/users/operation/${type}`).then((res) => res.data);

	//
	// Documents
	//
	downloadBulletin(subscriptionId: string, retry = false) {
		// Bulletin is usually 1.8Mb, so it can take up to 60s to download on a slow connection
		return this.instance.get(`/subscriptions/bulletin/${subscriptionId}`, {
			responseType: 'blob',
			timeout: 60 * 1000,
			// @ts-ignore ts(2345)
			'axios-retry': retry
				? {
						retries: 5,
						shouldResetTimeout: true,
						retryCondition: () => true,
						retryDelay: (retryCount: number) => (retryCount + 1) * 1000,
				  }
				: undefined,
		});
	}

	downloadDocuments = (filepath: string, contentType: string) =>
		this.instance.get('/documents/download', { params: { content_type: contentType, filepath }, responseType: 'blob' });

	downloadContractDocument = (contractId: string, documentId: string) =>
		this.instance
			.get(`/contracts/${contractId}/documents/${documentId}`, {
				responseType: 'blob',
				timeout: 20 * 1000,
			})
			.then((res) => {
				const filename = res.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
				FileDownload(res.data, filename);
			})
			.catch(() => {
				toast({
					title: 'Une erreur est survenue',
					description: "Le fichier n'a pas pu être téléchargé... \nVeuillez nous contacter pour plus d'informations.",
					status: 'error',
					duration: 4500,
					isClosable: true,
				});
			});

	//
	// Sponsorship
	//
	isCodeValid = (code: string): Promise<AxiosResponse<CodeValidityResponse>> =>
		this.instance.get('/sponsorship/validity', { params: { code } });

	//
	// Sponsorship for a user
	//
	isCodeValidForUser = (code: string): Promise<AxiosResponse<CodeValidityResponse>> =>
		this.instance.get('/sponsorship/validity-for-user', { params: { code } });
}

export default new Api();
