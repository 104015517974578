import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import colors from 'theme/foundations/colors';
import textStyles, { baseTextStyle } from 'theme/foundations/typography';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const variants = {
	full: definePartsStyle({
		container: {
			border: `1px solid ${colors.grey[300]}`,
			margin: '1px',
			background: 'white',
			_hover: {
				borderColor: colors.grey[900],
			},

			_checked: {
				margin: '0px',
				border: `2px solid ${colors.primary.yellow}`,
				_hover: {
					border: `2px solid ${colors.primary.yellow}`,
				},
			},

			boxShadow: '10px 10px 10px rgba(0, 0, 0, 0)',
			boxSizing: 'border-box',
			width: '100%',
			padding: '24px 16px',
			cursor: 'pointer',
			borderRadius: 'base',
		},
		control: {
			borderWidth: '1px',
			borderColor: `${colors.grey[500]} !important`,
			_checked: {
				borderWidth: '0px !important',
				background: colors.primary.yellow,
			},
		},

		label: {
			...textStyles['Text-S-Regular'],
			fontSize: '14px !important', // Bug with label
			color: colors.primary.black,
			_checked: {
				...textStyles['Text-S-Regular'],
			},
		},
	}),
	base: definePartsStyle({
		control: {
			bg: 'white',
			boxSize: '16px',
			borderRadius: '2px',
			borderWidth: '1px',
			borderColor: colors.grey[700],
			_invalid: {
				borderColor: 'negative.900',
			},
			_checked: {
				background: 'grey.900',
				borderColor: 'grey.900',
			},
			label: {
				...baseTextStyle,
				fontSize: '14px',
				color: 'primary.black',
			},
		},
	}),

	outline: {
		control: {
			bg: 'transparent',
			boxSize: '16px',
			borderRadius: '2px',
			borderWidth: '2px',
			borderColor: 'grey.700',
			_checked: {
				background: 'transparent',
				borderColor: 'yellow.900',
			},
		},
		icon: {
			color: 'yellow.900',
			background: 'transparent',
		},
	},
};

const checkBoxTheme = defineMultiStyleConfig({
	variants,
	defaultProps: { variant: 'base' },
});

export default checkBoxTheme;
