import { Document } from '@carbon/icons-react';
import { Button, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { useAppResponsive } from 'hooks/useAppResponsive';

const DocumentToDownload = ({
	documentUrl,
	documentFilename,
	name,
	handleDownloadCustom,
}: {
	documentUrl?: string;
	documentFilename?: string;
	name?: string;
	handleDownloadCustom?: () => void;
}) => {
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;

	const handleDownload = (url: string, filename: string) => {
		axios.get(url, { responseType: 'blob' }).then((res) => fileDownload(res.data, filename));
	};

	return (
		<Stack w="100%" justify="space-between" direction={{ base: 'column', sm: 'row' }}>
			{(name || !isMobile) && (
				<HStack spacing="8px" align="center">
					<Document size="32" />
					<VStack align="start" spacing="xs">
						{name && <Text variant="Text-M-Medium">{name}</Text>}
						{/* {name && <Text variant="Text-S-Medium">{name}</Text>} */}
					</VStack>
				</HStack>
			)}

			<Button
				variant="secondary"
				alignSelf={{ base: 'flex-end', md: 'center' }}
				onClick={() => {
					if (handleDownloadCustom) handleDownloadCustom();
					else handleDownload(documentUrl!, documentFilename!);
				}}
			>
				Télécharger
			</Button>
		</Stack>
	);
};
export default DocumentToDownload;
