export enum Residence {
	DE = 'DE',
	AD = 'AD',
	AT = 'AT',
	BE = 'BE',
	DK = 'DK',
	ES = 'ES',
	FR = 'FR',
	GP = 'GP',
	GG = 'GG',
	GF = 'GF',
	IE = 'IE',
	IT = 'IT',
	JE = 'JE',
	LU = 'LU',
	IM = 'IM',
	MQ = 'MQ',
	YT = 'YT',
	MC = 'MC',
	NC = 'NC',
	NL = 'NL',
	PF = 'PF',
	PT = 'PT',
	RE = 'RE',
	GB = 'GB',
}

const ResidenceReferentiel: Record<Residence, string> = {
	DE: 'ALLEMAGNE',
	AD: 'ANDORRE',
	AT: 'AUTRICHE',
	BE: 'BELGIQUE',
	DK: 'DANEMARK',
	ES: 'ESPAGNE',
	FR: 'FRANCE',
	GP: 'GUADELOUPE',
	GG: 'GUERNESEY',
	GF: 'GUYANE',
	IE: 'IRLANDE, ou EIRE',
	IT: 'ITALIE',
	JE: 'JERSEY',
	LU: 'LUXEMBOURG',
	IM: 'MAN (ILE)',
	MQ: 'MARTINIQUE',
	YT: 'MAYOTTE',
	MC: 'MONACO',
	NC: 'NOUVELLE-CALEDONIE',
	NL: 'PAYS-BAS',
	PF: 'POLYNESIE FRANCAISE',
	PT: 'PORTUGAL',
	RE: 'LA REUNION',
	GB: 'ROYAUME-UNI',
};

export default ResidenceReferentiel;
