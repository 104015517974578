import { from } from 'env-var';

const fakeUrl = 'https://eojkq3cn0tnqux6.m.pipedream.net';
const fakeToken = 'azertyuiop';

const publicEnv = {
	VITE_ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
	VITE_BACKEND_URL: import.meta.env.VITE_BACKEND_URL,
	VITE_INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID,
	VITE_HIGHLIGHT_PROJECT_ID: import.meta.env.VITE_HIGHLIGHT_PROJECT_ID,
	VITE_MIXPANEL_TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN,
	VITE_MIXPANEL_COOKIE_ID: import.meta.env.VITE_MIXPANEL_COOKIE_ID,
	VITE_GOOGLE_TAG_MANAGER_ID: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
	VITE_TYPEFORM_TRANSFERT_URL: import.meta.env.VITE_TYPEFORM_TRANSFERT_URL,
	VITE_TYPEFORM_PE_URL: import.meta.env.VITE_TYPEFORM_PE_URL,
	VITE_PIPEDREAM_REAL_ESTATE_SUB_URL: import.meta.env.VITE_PIPEDREAM_REAL_ESTATE_SUB_URL,
	VITE_PIPEDREAM_NOTIFY_TRANSFER_URL: import.meta.env.VITE_PIPEDREAM_NOTIFY_TRANSFER_URL,
	VITE_PIPEDREAM_NOTIFY_DEPOSIT_URL: import.meta.env.VITE_PIPEDREAM_NOTIFY_DEPOSIT_URL,
	VITE_PIPEDREAM_NOTIFY_PE_INTEREST_URL: import.meta.env.VITE_PIPEDREAM_NOTIFY_PE_INTEREST_URL,
	VITE_PIPEDREAM_NOTIFY_SCPI_INTEREST_URL: import.meta.env.VITE_PIPEDREAM_NOTIFY_SCPI_INTEREST_URL,
	VITE_PIPEDREAM_SCPI_INTENTION_URL: import.meta.env.VITE_PIPEDREAM_SCPI_INTENTION_URL,
	VITE_PIPEDREAM_PE_INTENTION_URL: import.meta.env.VITE_PIPEDREAM_PE_INTENTION_URL,
	VITE_PIPEDREAM_SCPI_SUBSCRIPTION_URL: import.meta.env.VITE_PIPEDREAM_SCPI_SUBSCRIPTION_URL,
	VITE_PIPEDREAM_PE_SUBSCRIPTION_URL: import.meta.env.VITE_PIPEDREAM_PE_SUBSCRIPTION_URL,
	VITE_MIN_SCPI_INITIAL_AUM: import.meta.env.VITE_MIN_SCPI_INITIAL_AUM,
	VITE_PIPEDREAM_PE_SUB_SIMULATION_URL: import.meta.env.VITE_PIPEDREAM_PE_SUB_SIMULATION_URL,
	VITE_PIPEDREAM_BULLETIN_GENERATION_URL: import.meta.env.VITE_PIPEDREAM_BULLETIN_GENERATION_URL,
	VITE_PIPEDREAM_SAVING_BOOKLET_INTENTION_URL: import.meta.env.VITE_PIPEDREAM_SAVING_BOOKLET_INTENTION_URL,
	VITE_PIPEDREAM_SAVING_BOOKLET_INTEREST_URL: import.meta.env.VITE_PIPEDREAM_SAVING_BOOKLET_INTEREST_URL,
	VITE_PIPEDREAM_SAVING_BOOKLET_SIGNED_URL: import.meta.env.VITE_PIPEDREAM_SAVING_BOOKLET_SIGNED_URL,
	VITE_PIPEDREAM_SAVING_BOOKLET_COMPLETED_URL: import.meta.env.VITE_PIPEDREAM_SAVING_BOOKLET_COMPLETED_URL,
	VITE_PIPEDREAM_NOTIFY_DEPOSIT_ERROR: import.meta.env.VITE_PIPEDREAM_NOTIFY_DEPOSIT_ERROR,
	VITE_PIPEDREAM_NEW_AFFILIATE_URL: import.meta.env.VITE_PIPEDREAM_NEW_AFFILIATE_URL,
	VITE_PIPEDREAM_BANNER_IMPRESSION_URL: import.meta.env.VITE_PIPEDREAM_BANNER_IMPRESSION_URL,
	VITE_PIPEDREAM_BANNER_ENGAGEMENT_URL: import.meta.env.VITE_PIPEDREAM_BANNER_ENGAGEMENT_URL,
	VITE_PIPEDREAM_SCPI_SIMULATION_URL: import.meta.env.VITE_PIPEDREAM_SCPI_SIMULATION_URL,
	VITE_PIPEDREAM_CROWDFUNDING_OPPORTUNITY_URL: import.meta.env.VITE_PIPEDREAM_CROWDFUNDING_OPPORTUNITY_URL,
	VITE_PIPEDREAM_SCPI_CREDIT_MAIL_OFFER_URL: import.meta.env.VITE_PIPEDREAM_SCPI_CREDIT_MAIL_OFFER_URL,
	VITE_PIPEDREAM_ADD_WISHLIST_AIRTABLE_SCPI_URL: import.meta.env.VITE_PIPEDREAM_ADD_WISHLIST_AIRTABLE_SCPI_URL,
	VITE_PIPEDREAM_PE_SIMULATION_URL: import.meta.env.VITE_PIPEDREAM_PE_SIMULATION_URL,
	VITE_PIPEDREAM_ADD_WISHLIST_AIRTABLE_PE_URL: import.meta.env.VITE_PIPEDREAM_ADD_WISHLIST_AIRTABLE_PE_URL,
	VITE_PIPEDREAM_SAVING_BOOKLET_WAITING_LIST_URL: import.meta.env.VITE_PIPEDREAM_SAVING_BOOKLET_WAITING_LIST_URL,
	VITE_PIPEDREAM_NOTIFICATION_SEEN: import.meta.env.VITE_PIPEDREAM_NOTIFICATION_SEEN,
	VITE_PIPEDREAM_NOTIFICATION_CLICKED: import.meta.env.VITE_PIPEDREAM_NOTIFICATION_CLICKED,
};

const env = from(publicEnv, {});

//
// APP /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//

export const environmentConstants = {
	environment: env.get('VITE_ENVIRONMENT').default('local').asString(),
};

export const scpiConstants = {
	minScpiInitialAum: env.get('VITE_MIN_SCPI_INITIAL_AUM').default(187).asInt(),
};

//
// STACKS RAMIFY ///////////////////////////////////////////////////////////////////////////////////////////////////////
//

export const backendConstants = {
	backendUrl: env.get('VITE_BACKEND_URL').required().asString(),
};

//
// INTEGRATIONS SERVICES EXTERNES //////////////////////////////////////////////////////////////////////////////////////
//

export const intercomConstants = {
	appId: env.get('VITE_INTERCOM_APP_ID').default(fakeToken).asString(),
};

export const typeFormConstants = {
	transfertUrl: env.get('VITE_TYPEFORM_TRANSFERT_URL').default(fakeUrl).asString(),
	peUrl: env.get('VITE_TYPEFORM_PE_URL').default(fakeUrl).asString(),
};

export const highlightConstants = {
	projectId: env.get('VITE_HIGHLIGHT_PROJECT_ID').default(fakeToken).asString(),
};

export const mixpanelConstants = {
	token: env.get('VITE_MIXPANEL_TOKEN').default(fakeToken).asString(),
	cookieId: env.get('VITE_MIXPANEL_COOKIE_ID').default(fakeToken).asString(),
};

export const googleTagManagerConstants = {
	id: env.get('VITE_GOOGLE_TAG_MANAGER_ID').default(fakeToken).asString(),
};

export const pipedreamConstants = {
	// app
	newAffiliateUrl: env.get('VITE_PIPEDREAM_NEW_AFFILIATE_URL').default(fakeUrl).asString(),
	bannerImpressionUrl: env.get('VITE_PIPEDREAM_BANNER_IMPRESSION_URL').default(fakeUrl).asString(),
	bannerEngagementUrl: env.get('VITE_PIPEDREAM_BANNER_ENGAGEMENT_URL').default(fakeUrl).asString(),

	// invest
	perTransferUrl: env.get('VITE_PIPEDREAM_NOTIFY_TRANSFER_URL').default(fakeUrl).asString(),
	interestVersementUrl: env.get('VITE_PIPEDREAM_NOTIFY_DEPOSIT_URL').default(fakeUrl).asString(),
	depositErrorUrl: env.get('VITE_PIPEDREAM_NOTIFY_DEPOSIT_ERROR').default(fakeUrl).asString(),

	// scpi
	scpiInterestUrl: env.get('VITE_PIPEDREAM_NOTIFY_SCPI_INTEREST_URL').default(fakeUrl).asString(),
	scpiIntentionUrl: env.get('VITE_PIPEDREAM_SCPI_INTENTION_URL').default(fakeUrl).asString(),
	scpiSubscriptionUrl: env.get('VITE_PIPEDREAM_SCPI_SUBSCRIPTION_URL').default(fakeUrl).asString(),
	scpiSubscriptionSimulationUrl: env.get('VITE_PIPEDREAM_REAL_ESTATE_SUB_URL').default(fakeUrl).asString(),
	scpiSimulationUrl: env.get('VITE_PIPEDREAM_SCPI_SIMULATION_URL').default(fakeUrl).asString(),
	scpiCreditMailOfferUrl: env.get('VITE_PIPEDREAM_SCPI_CREDIT_MAIL_OFFER_URL').default(fakeUrl).asString(),
	crowdfundingOpportunityUrl: env.get('VITE_PIPEDREAM_CROWDFUNDING_OPPORTUNITY_URL').default(fakeUrl).asString(),
	addWishListToAirTableSCPI: env.get('VITE_PIPEDREAM_ADD_WISHLIST_AIRTABLE_SCPI_URL').default(fakeUrl).asString(),

	// pe
	peInterestUrl: env.get('VITE_PIPEDREAM_NOTIFY_PE_INTEREST_URL').default(fakeUrl).asString(),
	peIntentionUrl: env.get('VITE_PIPEDREAM_PE_INTENTION_URL').default(fakeUrl).asString(),
	peSubscriptionUrl: env.get('VITE_PIPEDREAM_PE_SUBSCRIPTION_URL').default(fakeUrl).asString(),
	peSubscriptionSimulationUrl: env.get('VITE_PIPEDREAM_PE_SUB_SIMULATION_URL').default(fakeUrl).asString(),
	peSimulationUrl: env.get('VITE_PIPEDREAM_PE_SIMULATION_URL').default(fakeUrl).asString(),
	addWishListToAirTablePE: env.get('VITE_PIPEDREAM_ADD_WISHLIST_AIRTABLE_PE_URL').default(fakeUrl).asString(),

	// scpi & pe
	bulletinGenerationUrl: env.get('VITE_PIPEDREAM_BULLETIN_GENERATION_URL').default(fakeUrl).asString(),

	// savings account
	savingsAccountWaitingList: env.get('VITE_PIPEDREAM_SAVING_BOOKLET_WAITING_LIST_URL').default(fakeUrl).asString(),
	savingsAccountIntentionUrl: env.get('VITE_PIPEDREAM_SAVING_BOOKLET_INTENTION_URL').default(fakeUrl).asString(),
	savingsAccountSignedUrl: env.get('VITE_PIPEDREAM_SAVING_BOOKLET_SIGNED_URL').default(fakeUrl).asString(),

	// Notification
	notificationSeen: env.get('VITE_PIPEDREAM_NOTIFICATION_SEEN').default(fakeUrl).asString(),
	notificationClicked: env.get('VITE_PIPEDREAM_NOTIFICATION_CLICKED').default(fakeUrl).asString(),
};
