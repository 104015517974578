import { FC, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { inject as injectVercelAnalytics } from '@vercel/analytics';

import { getAppEnvironment } from 'hooks/getAppEnvironment';
import eventTracker from 'services/events/eventTracker';
import { useGetUserQuery } from 'services/requests/user';
import { googleTagManagerConstants, intercomConstants } from 'utils/constants';

const { isProductionOrStaging, isProduction } = getAppEnvironment();

const PluginSetup: FC = () => {
	const { data: user, isLoading } = useGetUserQuery();

	// Update the intercom widget everytime the user is updated
	useEffect(() => {
		//
		// Event tracker - Highlight & Mixpanel & Pipedream
		//
		if (isProductionOrStaging) {
			if (user) eventTracker.identify(user.id, user.email);
			else eventTracker.unidentify();
		}

		if (isProduction) {
			// Intercom
			const intercomUser = () =>
				user
					? { email: user.email, created_at: user.createdAt, user_id: user.id, user_hash: user.intercomIdentity }
					: {};
			window.Intercom('boot', {
				app_id: intercomConstants.appId,
				alignment: 'right',
				...intercomUser(),
			});
		}
	}, [user, isLoading]);

	useEffect(() => {
		if (isProduction) {
			TagManager.initialize({ gtmId: googleTagManagerConstants.id });

			// Vercel analytics
			injectVercelAnalytics();
		}
	}, []);

	return null;
};

export default PluginSetup;
