import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UseDisclosureReturn } from '@chakra-ui/react';

import { IntestedProducts } from 'components/Modals/Calendly/useBuildIframeUrl';

import { CalendlyStep } from './Calendly';
import { FormStep1 } from './FormStep1';
import { FormStep2 } from './FormStep2';
import { Recap } from './Recap';

enum CalendlyModalSteps {
	STEP1,
	STEP2,
	RECAP,
	CALENDLY,
}

export type FormContentBecomeBlack = {
	amount: number;
	objectif: string;
};
export const CalendlyModalBlack: FC<
	{ product?: IntestedProducts } & Pick<UseDisclosureReturn, 'isOpen' | 'onClose'>
> = ({ product, isOpen, onClose }) => {
	const [step, setStep] = useState(CalendlyModalSteps.STEP1);

	const formContext = useForm<FormContentBecomeBlack>();

	const closeAndReset = () => {
		setStep(CalendlyModalSteps.STEP1);
		formContext.reset();
		onClose();
	};

	if (!isOpen) return null;

	return (
		<FormProvider {...formContext}>
			{step === CalendlyModalSteps.STEP1 && (
				<FormStep1 onClose={closeAndReset} onSuccess={() => setStep(CalendlyModalSteps.STEP2)} />
			)}
			{step === CalendlyModalSteps.STEP2 && (
				<FormStep2 onClose={closeAndReset} onSuccess={() => setStep(CalendlyModalSteps.RECAP)} />
			)}
			{step === CalendlyModalSteps.RECAP && (
				<Recap onClose={closeAndReset} onSuccess={() => setStep(CalendlyModalSteps.CALENDLY)} />
			)}
			{step === CalendlyModalSteps.CALENDLY && (
				<CalendlyStep
					url="https://calendly.com/d/4tb-hkg-rdn/echange-pour-rejoindre-ramify-black"
					onClose={closeAndReset}
				/>
			)}
		</FormProvider>
	);
};
