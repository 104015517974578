import { useMemo } from 'react';
import { Text } from '@chakra-ui/react';

import { useRiskDisplay } from 'hooks/useRiskDisplay';
import { getRoundedTimeHorizon } from 'onboarding/Invest/Shared/Objective/projectData';
import { OnboardingObject } from 'onboarding/Invest/Shared/type';
import { displayMoneyNoDigits } from 'utils/rendering';

const Objective = ({ investSimulation, risk }: { investSimulation: OnboardingObject; risk: number }): JSX.Element => {
	const timeHorizonPlan = useMemo(
		() => getRoundedTimeHorizon(investSimulation.timeHorizon!),
		[investSimulation.timeHorizon],
	);
	const riskDisplay = useRiskDisplay(risk);

	return (
		<Text variant="Text-M-Regular" color="grey.900">
			En investissant chaque mois <b>{displayMoneyNoDigits(investSimulation.saving)}</b>, avec un portefeuille au profil
			de risque <b>{riskDisplay.name}</b>, et un capital initial de{' '}
			<b>{displayMoneyNoDigits(investSimulation.initialAUM)}</b>{' '}
			{investSimulation.type === 'RETIREMENT' ? (
				<>jusqu'à votre départ à la retraite</>
			) : (
				<>
					pendant une durée de
					<Text as="span" fontWeight={600} fontSize="inherit">
						&nbsp;
						{timeHorizonPlan.label}
					</Text>
				</>
			)}
			, vous pouvez constituer un capital de :
		</Text>
	);
};

export default Objective;
