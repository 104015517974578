import { useMemo } from 'react';
import { Skeleton, Stack, VStack } from '@chakra-ui/react';

import ListCompositionPortfolio from 'components/ListCompositionPortfolio';
import { useAppSelector } from 'hooks/useStore';
import { useGetContractsQuery } from 'services/requests/invest/contracts';
import { HoldingInfos, useGetAggregatedHoldingsQuery, useGetRateOfReturnQuery } from 'services/requests/investment';
import { CustomAsset } from 'store/types/investment.type';
import { isNone } from 'utils/functions';

const DashboardSkeleton = (): JSX.Element => (
	<VStack spacing="24px" w="100%">
		<Stack direction={{ base: 'column', md: 'row' }} spacing="24px" w="100%">
			<Skeleton w="100%" h="180px" />
			<Skeleton w="100%" h="180px" />
		</Stack>
		<Skeleton height="540px" w="100%" />
	</VStack>
);

const toCustomAssets = (assets: HoldingInfos[]): CustomAsset[] =>
	assets
		.map((a) => ({
			ISIN: a.isin,
			repartition: a.repartition,
			portfolioId: a.company,
			montant: a.montant,
			type: a.type,
			name: a.name,
			label: a.label,
			url: a.dici,
			greenTag: a.greenTag,
		}))
		.sort((a, b) => b.repartition - a.repartition) as CustomAsset[];

const CompositionPortfolio = () => {
	const { inactiveList } = useAppSelector((state) => state.investContractFilter);
	const { data: userContracts } = useGetContractsQuery();
	const { data: holdingDatas } = useGetAggregatedHoldingsQuery(inactiveList);
	const { data: rateOfReturnData } = useGetRateOfReturnQuery(inactiveList);

	const AssetWithNbShares = useMemo(() => {
		if (isNone(holdingDatas) || isNone(userContracts)) return null;

		const contractsToConsider = userContracts.filter((c) => !inactiveList.includes(c.id));
		return toCustomAssets(holdingDatas.portfolio).map((asset) => {
			const arrayofShares = contractsToConsider.map((c) => {
				const holdingFound = c.holdings.find((h) => {
					return h.ISIN === asset.ISIN;
				});
				if (holdingFound) return holdingFound.nbShares;
				return 0;
			});
			const totalShares = arrayofShares.reduce((agg, s) => agg + s, 0);
			return { ...asset, nbShares: totalShares };
		});
	}, [holdingDatas, inactiveList, userContracts]);

	if (isNone(AssetWithNbShares)) return DashboardSkeleton();

	return (
		<ListCompositionPortfolio
			rateOfreturns={rateOfReturnData}
			assets={AssetWithNbShares}
			showPerformance
			displayAmount
		/>
	);
};

export default CompositionPortfolio;
