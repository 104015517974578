import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Divider,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	Text,
	VStack,
} from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import { UpdateKycForm } from 'onboarding/UpdateKYC/Signature';

interface ModalActualizationProps {
	isOpen: boolean;
	onClose: () => void;
}

const ModalActualization = ({ isOpen, onClose }: ModalActualizationProps) => {
	const [answerSituationHasChanged, setAnswerSituationHasChanged] = useState<'yes' | 'no'>();
	const navigate = useNavigate();

	const navigateToUpdateKyc = () => {
		onClose();
		navigate('/actualisation-situation');
	};

	const sendRequestActualizationEmpty = () => {
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="lg">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Heading variant="Title-S-Bold">Mise à jour de mes informations</Heading>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<VStack align="start" spacing="16px" w="100%">
						<Text variant="Text-M-Regular">
							L'actualisation de vos données personnelles est <strong>obligatoire</strong> une fois par an. Voici la
							liste des éléments à actualiser :
						</Text>
						<VStack align="start">
							<Text variant="Text-M-Regular">- Adresse</Text>
							<Text variant="Text-M-Regular">- Coordonnées</Text>
							<Text variant="Text-M-Regular">- Situation professionnelle</Text>
							<Text variant="Text-M-Regular">- Revenus</Text>
						</VStack>
						<VStack align="start" spacing="16px" w="100%">
							<Text variant="label">Votre situation a-t-elle changé au cours de la dernière année ?</Text>
							<RadioGroup
								size="sm"
								variant="single"
								w={onboardingInputWidth}
								onChange={(nextValue: 'yes' | 'no') => setAnswerSituationHasChanged(nextValue)}
							>
								<VStack align="start">
									<Radio value="yes">Oui</Radio>
									<Radio value="no">Non</Radio>
								</VStack>
							</RadioGroup>
						</VStack>
						{answerSituationHasChanged === 'no' && (
							<>
								<Divider />
								<Text variant="body">
									Si votre situation n'a pas changé, veuillez simplement confirmer votre code reçu par SMS.
								</Text>

								<UpdateKycForm hasChanged={false} onCompleted={sendRequestActualizationEmpty} />
							</>
						)}
						{answerSituationHasChanged === 'yes' && (
							<>
								<Divider />
								<Text variant="label">Actualisez votre situation en 2 minutes chrono !</Text>
								<ArrowButton variant="primary" size="lg" onClick={navigateToUpdateKyc}>
									Mettre à jour mes informations
								</ArrowButton>
							</>
						)}
					</VStack>
					<ModalFooter />
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ModalActualization;
