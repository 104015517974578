import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrap, WrapItem } from '@chakra-ui/react';

import ActionCard from 'components/Metrics/ActionCard';
import { useAppSelector } from 'hooks/useStore';
import { useGetContractsQuery, useGetTaxEconomyQuery } from 'services/requests/invest/contracts';
import { isNotNone } from 'utils/functions';

const ActionCards = () => {
	const navigate = useNavigate();
	const { data: contracts } = useGetContractsQuery();
	const { inactiveList } = useAppSelector((state) => state.investContractFilter);
	const { data: taxEconomy } = useGetTaxEconomyQuery({ excludeContractIds: inactiveList });

	const totalVP = useMemo(
		() =>
			contracts
				?.filter((c) => !inactiveList.includes(c.id))
				.reduce((acc, contract) => acc + +(contract.programmedVersement?.amount ?? 0), 0),
		[contracts, inactiveList],
	);

	return (
		<Wrap w="100%" spacingX="md">
			{isNotNone(totalVP) && (
				<WrapItem flex={1}>
					<ActionCard
						title="Versements programmés"
						montant={totalVP}
						description="(mensuel)"
						textbtn="Versements programmés"
						btnCallback={() => navigate('/invest/versements')}
					/>
				</WrapItem>
			)}
			{isNotNone(taxEconomy) && isNotNone(taxEconomy.totalTaxEconomy) && (
				<WrapItem flex={1}>
					<ActionCard
						title="Vous avez défiscalisé"
						montant={taxEconomy.totalTaxEconomy}
						description={`sur l'année ${new Date().getFullYear()}`}
						textbtn="Page Fiscalité"
						btnCallback={() => navigate('/fiscalite')}
					/>
				</WrapItem>
			)}
		</Wrap>
	);
};
export default ActionCards;
