import { keyframes } from '@chakra-ui/react';
import type { StyleFunctionProps } from '@chakra-ui/styled-system';

import colors from 'theme/foundations/colors';
import textStyles from 'theme/foundations/typography';

const superButtonAnimation = keyframes({
	'0%': {
		backgroundPosition: '0% 50%',
	},
	'50%': {
		backgroundPosition: '100% 50%',
	},
	'100%': {
		backgroundPosition: '0% 50%',
	},
});

const Button = {
	baseStyle: {
		borderRadius: 'sm',
		textStyle: 'button',
		color: '#FFFFFF',
		_focus: {
			boxShadow: `inherit`,
		},
	},

	sizes: {
		xl: {
			...textStyles['Button-SemiBold'],
			padding: '20px 28px',
			height: '52px',
		},
		lg: {
			...textStyles['Button-SemiBold'],
			padding: '16px 24px',
			height: '48px',
		},
		md: {
			...textStyles['Button-SemiBold'],
			padding: '12px 16px',
			height: '40px',
		},
	},

	variants: {
		super: (props: StyleFunctionProps) => ({
			background: 'linear-gradient(90deg, #FFBB2C, #F8DB8F, #FFBB2C)',
			backgroundSize: '400% 400%',
			animation: `${superButtonAnimation} 3s linear infinite`,
			cursor: props.disabled ? 'not-allowed' : 'pointer',
			_hover: {
				transform: 'scale(1.015)',
			},
		}),
		primary: (props: StyleFunctionProps) => ({
			color: props.disabled ? 'grey.700' : colors.primary.black,
			background: props.disabled ? colors.grey[300] : colors.primary.yellow,
			_hover: {
				bg: props.disabled ? colors.grey[300] : '#FFCC61',
			},
			_active: {
				bg: '#FAA900',
			},
			cursor: props.disabled ? 'not-allowed' : 'pointer',
		}),
		secondary: (props: StyleFunctionProps) => ({
			color: props.disabled ? 'grey.700' : colors.primary.black,
			background: 'grey.100',
			_hover: {
				bg: colors.grey[300],
			},
			cursor: props.disabled ? 'not-allowed' : 'pointer',
		}),
		tertiary: () => ({
			color: colors.primary.black,
			_hover: {
				bg: 'rgba(30, 30, 30, 0.08)',
			},
		}),
		destroy: (props: StyleFunctionProps) => ({
			color: props.disabled ? colors.negative[500] : colors.negative[900],
			background: colors.negative[100],
			_hover: {
				bg: '#F4D7D7',
			},
			_active: {
				bg: '#F6DFDF',
			},
		}),
		icon: {
			background: 'transparent',
			_hover: {
				border: `1px solid ${colors.grey[900]}`,
			},
			_active: {
				background: colors.grey[300],
				border: 'none',
			},
		},
	},

	defaultProps: {
		size: 'md',
		variant: 'primary',
	},
};

export default Button;
