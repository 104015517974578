import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AgricultureAnalytics, ArrowLeft, Calendar, ChartMedian } from '@carbon/icons-react';
import {
	Button,
	Card,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	HStack,
	Icon,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Stack,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import FullscreenLoader from 'components/FullscreenLoader';
import { PerformanceBadge } from 'components/Metrics/PerformanceBadge';
import { useDrawer } from 'hooks/useDrawer';
import { useGetKYCQuery } from 'services/requests/kyc';
import {
	useGetSavingsAccountPerformanceQuery,
	useGetSavingsAccountQuery,
	useUpdateCERPerformancesTableQuery,
} from 'services/requests/savingsAccounts';
import { useGetUserQuery } from 'services/requests/user';
import { SavingsAccountPerformanceResponse, SavingsAccountType } from 'store/types/savingsAccount.type';
import { isNone } from 'utils/functions';
import { displayMoney, displayPercentage, formatDateStandart } from 'utils/rendering';

import CashVersementModal from './VersementModal';

export interface SavingsAccountStatsCardProps {
	type?: SavingsAccountType;
	initialDepositAmount: number;
	currentValuation: number;
	profit: number;
	onClickDeposit?: () => void;
}

const ContractInfo: FC<{ signedAt: Date }> = ({ signedAt }) => (
	<VStack spacing="16px" align="start">
		<Heading variant="Title-L-SemiBold">Contrat</Heading>

		<HStack spacing="16px">
			<Icon boxSize="32px" as={Calendar} />
			<VStack align="start" spacing="4px">
				<Text variant="Text-S-Medium" color="grey.900">
					Date de début du contrat
				</Text>
				<Text variant="Text-M-Medium">{formatDateStandart(signedAt)}</Text>
			</VStack>
		</HStack>
	</VStack>
);

const Remuneration: FC<{
	promotionalOfferRate: number;
	boostedInterestRateEndDate: string;
	accountType: SavingsAccountType;
	interestRate?: number;
}> = ({ boostedInterestRateEndDate, promotionalOfferRate, accountType, interestRate }) => (
	<VStack w="100%" align="start" spacing="24px">
		<Heading variant="Title-L-SemiBold">Remuneration</Heading>

		<SimpleGrid columns={{ base: 1, md: 2 }} w="100%" spacing="24px">
			{accountType === SavingsAccountType.CER && (
				<Card border="none" bg="orange.50">
					<VStack align="start">
						<Text color="orange.900" variant="Title-L-Bold">
							Taux boosté à {displayPercentage(promotionalOfferRate)}
						</Text>
						<Text>Actuellement en promotion, jusqu’au {boostedInterestRateEndDate}</Text>
					</VStack>
				</Card>
			)}
			<Card border="none" bg="orange.50">
				<VStack align="start">
					<Text color="orange.900" variant="Title-L-Bold">
						{accountType === SavingsAccountType.CER
							? `Après ${displayPercentage(0.028)}`
							: displayPercentage(interestRate!)}
					</Text>
					<Text>Taux standard nominal brut annuel</Text>
				</VStack>
			</Card>
		</SimpleGrid>
	</VStack>
);

const PlusValueCard: FC<SavingsAccountPerformanceResponse & { tip: string }> = ({ value, percentage, tip }) => (
	<Card w="100%">
		<VStack w="100%" align="start" spacing="16px">
			<Icon boxSize="32px" as={ChartMedian} />

			<HStack w="100%">
				<Text variant="Text-M-Medium" color="grey.900">
					Plus-values
				</Text>
				<CustomTooltip text={tip} />
			</HStack>

			<PerformanceBadge isTitle amount={value} percentage={percentage} />
		</VStack>
	</Card>
);

const CapitalValueCard = ({ currentValuation }: { currentValuation: number }) => (
	<Card w="100%" h="100%">
		<VStack w="100%" align="start" h="100%" spacing="16px">
			<Icon boxSize="32px" as={AgricultureAnalytics} />

			<VStack w="100%" align="start">
				<Text variant="Text-M-Medium" color="grey.900">
					Valeur du capital
				</Text>
				<Heading variant="Title-L-Bold">{displayMoney(currentValuation)}</Heading>
			</VStack>
		</VStack>
	</Card>
);

const InfosSubscription: FC<{
	currentValuation: number;
	added_value: SavingsAccountPerformanceResponse;
	accountType: SavingsAccountType;
}> = ({ currentValuation, added_value, accountType }) => (
	<VStack w="100%" align="start" spacing="24px">
		<Heading variant="Title-L-SemiBold">Mon investissement</Heading>

		<SimpleGrid columns={{ base: 1, md: 2 }} w="100%" spacing="24px">
			<CapitalValueCard currentValuation={currentValuation} />
			<PlusValueCard
				tip={
					accountType === SavingsAccountType.CAT
						? 'Les intérêts sont estimés avec comme hypothèse la non fermeture du compte à terme avant son échéance.'
						: 'Le taux de rendement personnalisé est défini comme le rapport entre le gain et la valeur moyenne du capital investi dans le support.'
				}
				value={added_value.value}
				percentage={added_value.percentage}
			/>
		</SimpleGrid>
	</VStack>
);

const SavingsAccountDetails = (): JSX.Element => {
	const { id } = useParams();

	const { data: savingsAccount, isLoading } = useGetSavingsAccountQuery(id!, { skip: isNone(id) });
	const { data: performanceData } = useUpdateCERPerformancesTableQuery(id!, { skip: isNone(id) });
	const { data: savingsAccountPerformance } = useGetSavingsAccountPerformanceQuery(id!, { skip: isNone(id) });

	const { data: kyc, isLoading: isKycLoading } = useGetKYCQuery();
	const { data: user, isLoading: isUserLoading } = useGetUserQuery();

	const {
		isOpen: isOpenFullRedemptionModal,
		onOpen: onOpenFullRedemptionModal,
		onClose: onCloseFullRedemptionModal,
	} = useDisclosure();

	const {
		isOpen: isOpenVersementModal,
		onOpen: onOpenVersementModal,
		onClose: onCloseVersementModal,
	} = useDisclosure();

	const { isOpen, onClose } = useDrawer();

	const savingsAccountInformation = useMemo<Omit<SavingsAccountStatsCardProps, 'onClickDeposit'> | null>(() => {
		if (isNone(savingsAccount) || isNone(savingsAccount.history) || savingsAccount.history.length === 0) return null;

		const initialDepositAmount = +(savingsAccount.history.at(-1)?.valuation ?? 0);
		const currentValuation = +(savingsAccount.history.at(-1)?.estimatedValuation ?? 0);

		return {
			type: savingsAccount.type,
			initialDepositAmount,
			currentValuation,
			profit: currentValuation - initialDepositAmount,
		};
	}, [savingsAccount]);

	const boostedInterestRateEndDate = useMemo(() => {
		if (!performanceData?.effective_inception_date || !savingsAccount) return null;

		const date = new Date(performanceData.effective_inception_date);
		return new Date(date.setMonth(date.getMonth() + savingsAccount.promotionalOfferDuration)).toLocaleDateString(
			'fr-FR',
			{ dateStyle: 'long' },
		);
	}, [performanceData, savingsAccount]);

	const isLoadingAny =
		isLoading ||
		isKycLoading ||
		isUserLoading ||
		!savingsAccount ||
		!kyc ||
		!user ||
		!savingsAccountInformation ||
		!performanceData ||
		!savingsAccountPerformance;

	return (
		<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isOpen} onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<HStack justify="space-between">
						<HStack>
							<IconButton onClick={onClose} variant="icon" aria-label="back" icon={<ArrowLeft size="32" />} />
							<Heading variant="Title-M-SemiBold">Retour</Heading>
						</HStack>
						{savingsAccount?.bankInformationId && savingsAccount?.type === SavingsAccountType.CER && (
							<Button size="lg" variant="super" onClick={onOpenVersementModal}>
								Faire un versement
							</Button>
						)}
					</HStack>
				</DrawerHeader>

				<DrawerBody>
					{isLoadingAny ? (
						<FullscreenLoader />
					) : (
						<VStack w="100%" spacing="32px" align="start">
							<InfosSubscription
								accountType={savingsAccount.type}
								currentValuation={savingsAccountInformation.currentValuation}
								added_value={savingsAccountPerformance}
							/>

							{boostedInterestRateEndDate && (
								<Remuneration
									boostedInterestRateEndDate={boostedInterestRateEndDate}
									promotionalOfferRate={savingsAccount.promotionalOfferRate}
									accountType={savingsAccount.type}
									interestRate={savingsAccount.standardRate}
								/>
							)}

							<ContractInfo signedAt={savingsAccount.openingDate!} />

							<VStack spacing="16px" align="start">
								<Heading variant="Title-L-SemiBold">Gérer mon contrat</Heading>

								<Stack spacing="24px" direction={{ base: 'column', md: 'row' }}>
									<Card w="100%" h="100%">
										<VStack w="100%" align="start" h="100%" spacing="16px">
											<VStack w="100%" align="start" spacing="28px">
												<VStack w="100%" align="start">
													<Text variant="Title-M-SemiBold">Prélèvement</Text>
													<Text>Vous souhaitez prélever une partie de l’argent présent sur votre livret ?</Text>
												</VStack>
												<Button
													variant="secondary"
													onClick={() => {
														window.Intercom('showNewMessage');
													}}
												>
													Faire une demande de prélèvement
												</Button>
											</VStack>
										</VStack>
									</Card>
									<Card w="100%" h="100%">
										<VStack w="100%" align="start" h="100%" spacing="16px">
											<VStack w="100%" align="start" spacing="28px">
												<VStack w="100%" align="start">
													<Text variant="Title-M-SemiBold">Résiliation</Text>
													<Text>Ramify vous facilite la vie pour résilier votre contrat en quelques étapes</Text>
												</VStack>
												<Button variant="destroy" alignSelf="end" onClick={onOpenFullRedemptionModal}>
													Résilier
												</Button>
											</VStack>
										</VStack>
									</Card>
								</Stack>
							</VStack>

							<Modal isOpen={isOpenFullRedemptionModal} onClose={onCloseFullRedemptionModal}>
								<ModalOverlay />
								<ModalContent>
									<ModalHeader>Resiliation</ModalHeader>
									<ModalCloseButton />
									<ModalBody>
										<Text variant="body">
											Afin de procéder à cette opération, vous allez être rédirigé vers le site de My Money Bank
										</Text>
									</ModalBody>

									<ModalFooter>
										<Button
											variant="secondary"
											onClick={() =>
												window.open(
													'https://www.monespace.mymoneybank.fr/mon-espace/mon-epargne/gestion-comptes/cloturer-un-compte',
												)
											}
										>
											Faire un rachat total
										</Button>
									</ModalFooter>
								</ModalContent>
							</Modal>

							{savingsAccount?.bankInformationId && (
								<CashVersementModal
									isOpen={isOpenVersementModal}
									onClose={onCloseVersementModal}
									bankAccountId={savingsAccount.bankInformationId}
								/>
							)}
						</VStack>
					)}
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default SavingsAccountDetails;
