import { useCallback, useState } from 'react';
import {
	Button,
	Checkbox,
	Heading,
	HStack,
	Icon,
	Image,
	PinInput,
	PinInputField,
	Radio,
	RadioGroup,
	Spacer,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';

import Leaf from 'assets/icons/Leaf.svg';
import { EssentialLogo, FlagshipLogo } from 'assets/logoRamify/LogoOffers';
import Aymane from 'assets/team/aymane.png';
import Banner from 'components/Banners/Banner';
import { ArrowButton } from 'components/Button/Button';
import SamyAdvisor from 'components/cards/SamyAdvisor';
import RiskSlider from 'components/inputs/Slider/RiskSlider';
import { Stepper } from 'components/navigation/Stepper';
import useThemedToast from 'hooks/useThemedToast';
import {
	useLazyPreviewArbitrageQuery,
	useSignArbitrageMutation,
	useTriggerArbitrageMutation,
} from 'services/requests/invest/arbitrage';
import { InvestmentPreferences } from 'services/requests/invest/preferences';
import { PopulatedInvestContract } from 'store/types/contract.type';
import { PortfolioType, portfolioTypeLabels } from 'store/types/profilage.type';

import PreviewArbitrage from './PreviewArbitrage';

type ChangeStrategyProps = {
	onClose: () => void;
	contract: PopulatedInvestContract;
	preferences: InvestmentPreferences;
};

type OfferCardProps = {
	currentPortfolioType: PortfolioType;
	title: string;
	portfolioChecked: PortfolioType;
	setPortfolioChecked: (portfolio: PortfolioType) => void;
};

const OfferCard = ({
	currentPortfolioType,
	title,
	portfolioChecked,
	setPortfolioChecked,
}: OfferCardProps): JSX.Element =>
	portfolioTypeLabels[currentPortfolioType] === title ? (
		<HStack w="100%" p="12px 16px" align="center" bg="grey.100" maxH="48px" border="base" borderRadius="base">
			<HStack>
				<Icon
					as={
						title === portfolioTypeLabels.FLAGSHIP
							? FlagshipLogo
							: title === portfolioTypeLabels.ESSENTIAL
							? EssentialLogo
							: EssentialLogo
					}
				/>
				<Text>{title}</Text>
			</HStack>
			<Spacer />
			<Text variant="body">Stratégie actuelle</Text>
		</HStack>
	) : (
		<HStack
			w="100%"
			p="12px 16px"
			bg="white"
			align="center"
			maxH="48px"
			border="base"
			borderColor={portfolioTypeLabels[portfolioChecked] === title ? 'blue.500' : 'grey.200'}
			borderRadius="base"
			onClick={() =>
				setPortfolioChecked(
					title.toUpperCase() === portfolioChecked ? currentPortfolioType : (title.toUpperCase() as PortfolioType),
				)
			}
			cursor="pointer"
		>
			<HStack>
				<Icon
					as={
						title === portfolioTypeLabels.FLAGSHIP
							? FlagshipLogo
							: title === portfolioTypeLabels.ESSENTIAL
							? EssentialLogo
							: EssentialLogo
					}
				/>
				<Text>{title}</Text>
			</HStack>
			<Spacer />
			{/* https://github.com/chakra-ui/chakra-ui/issues/2854 */}
			<Checkbox size="lg" isChecked={portfolioTypeLabels[portfolioChecked] === title} pointerEvents="none" />
		</HStack>
	);

const ChangeStrategy = ({
	onClose,
	contract,
	preferences: { risk, recommandedRisk, portfolioType, temperature, esg },
}: ChangeStrategyProps): JSX.Element => {
	const toast = useThemedToast();

	const [newRisk, updateRisk] = useState(risk!);
	const [newEsg, updateEsg] = useState(esg!);
	const [newTemperature, updateTemperature] = useState(temperature ?? 2.5);
	const [newPortfolioType, setStrategyChecked] = useState<PortfolioType>(portfolioType!);
	const [code, setCode] = useState('');

	const [step, setStep] = useState<'form' | 'preview' | 'sign'>('form');

	const closeWithSuccess = useCallback(
		(rebalanced: boolean) => {
			toast({
				title: 'Préférences enregistrées',
				description: rebalanced
					? "Vos préférences d'investissement ont été modifiées, un arbitrage est en cours sur votre contrat."
					: 'Le changement sera pris en compte lors de vos prochains versements.',
				status: 'success',
				duration: 6000,
				isClosable: true,
			});
			onClose();
		},
		[onClose, toast],
	);

	const generateTriggerPayload = useCallback(
		() => ({
			contractId: contract.id,
			portfolioType: portfolioType !== newPortfolioType ? newPortfolioType : undefined,
			risk: risk !== newRisk ? newRisk : undefined,
			esg: esg !== newEsg ? newEsg : undefined,
			temperature: newEsg && temperature !== newTemperature ? newTemperature! : undefined,
		}),
		[contract.id, esg, newEsg, newPortfolioType, newRisk, newTemperature, portfolioType, risk, temperature],
	);

	const [previewArbitrage, { data: arbitragePreview, isFetching: isArbitragePreviewFetching }] =
		useLazyPreviewArbitrageQuery();
	const handlePreview = useCallback((): void => {
		previewArbitrage(generateTriggerPayload())
			.unwrap()
			.then(() => setStep('preview'))
			.catch(() => {
				toast({
					title: "Une erreur s'est produite.",
					description: 'Nous ne pouvons pas mettre à jour vos préférences.',
					status: 'error',
					duration: 4500,
					isClosable: true,
				});
			});
	}, [generateTriggerPayload, previewArbitrage, toast]);

	const [triggerArbitrage, { isLoading: isTriggerLoading, data: triggerArbitrageResponse }] =
		useTriggerArbitrageMutation();
	const handleTrigger = useCallback(
		(rebalanced: boolean): void => {
			triggerArbitrage(generateTriggerPayload())
				.unwrap()
				.then(({ requiredSignature }) => {
					if (requiredSignature) setStep('sign');
					else closeWithSuccess(rebalanced);
				})
				.catch(() => {
					toast({
						title: "Une erreur s'est produite.",
						description: 'Nous ne pouvons pas mettre à jour vos préférences.',
						status: 'error',
						duration: 4500,
						isClosable: true,
					});
				});
		},
		[closeWithSuccess, generateTriggerPayload, toast, triggerArbitrage],
	);

	const [signArbitrage, { isLoading: isSignLoading }] = useSignArbitrageMutation();
	const handleSign = useCallback(() => {
		signArbitrage({
			otp: code,
			arbitrageId: triggerArbitrageResponse!.arbitrageId!,
			...generateTriggerPayload(),
		})
			.unwrap()
			.then(() => closeWithSuccess(true))
			.catch(() => {
				toast({
					title: "Une erreur s'est produite.",
					description: 'Le code entré est incorrect',
					status: 'error',
					duration: 4500,
					isClosable: true,
				});
			});
	}, [closeWithSuccess, code, generateTriggerPayload, signArbitrage, toast, triggerArbitrageResponse]);

	const steps: { title: string; internal: typeof step }[] = [
		{ internal: 'form', title: 'Informations' },
		{ internal: 'preview', title: 'Vérification' },
		{ internal: 'sign', title: 'Signature' },
	];

	return (
		<VStack spacing="36px">
			<Stepper w="80%" steps={steps} index={steps.findIndex((s) => s.internal === step)} />

			{step === 'form' && (
				<VStack spacing="40px" w="100%">
					<VStack spacing="32px" align="start" w="100%">
						<Banner
							title="Une question sur nos stratégies ?"
							description="Si vous avez une question ou que vous souhaitez être conseillé, vous pouvez vous rapprocher de votre conseiller Ramify"
							variant="information"
							avatar="samy"
							cta="Poser la question à mon conseiller"
							onClick={() => {
								window.Intercom('showMessages');
							}}
						/>

						<VStack spacing="32px" align="start" w="100%">
							<Stack align="start" spacing="32px" w="100%" direction={{ base: 'column', md: 'row' }}>
								<VStack flex={1} spacing="16px" align="start">
									<Text variant="Text-S-Medium">
										Quel risque souhaitez-vous adopter pour votre contrat{' '}
										<Text variant="Text-S-Medium" as="span" color="turquoise.900">
											{contract.envelope.type === 'AV' ? 'Assurance vie' : 'PER individuel'}
										</Text>
										&nbsp;?
									</Text>
									<RiskSlider value={newRisk!} onChange={updateRisk} recommandedRisk={recommandedRisk!} />
								</VStack>
								<VStack flex={1} spacing="16px" align="start">
									<VStack spacing="16px" align="start">
										<Text variant="Text-S-Medium">Êtes-vous interessés par l’investissement durable&nbsp;?</Text>

										<RadioGroup
											size="sm"
											w="100%"
											onChange={(value) => {
												updateEsg(value === 'yes');
											}}
											value={newEsg === true ? 'yes' : 'no'}
										>
											<VStack>
												<Radio value="yes">Oui</Radio>
												<Radio value="no">Non</Radio>
											</VStack>
										</RadioGroup>
									</VStack>

									{newEsg && (
										<VStack spacing="16px" align="start">
											<Text variant="Text-S-Medium">
												Quelle température souhaitez-vous adopter pour votre contrat{' '}
												<Text variant="Text-S-Medium" as="span" color="turquoise.900">
													{contract.envelope.type === 'AV' ? 'Assurance vie' : 'PER individuel'}
												</Text>
												&nbsp;?
											</Text>
											<RadioGroup
												w="100%"
												onChange={(value) => {
													updateTemperature(+value);
												}}
												value={newTemperature.toString()}
											>
												<VStack spacing="16px">
													<Radio value="2">
														<HStack>
															<Text variant="Text-S-Regular">+2,0°C</Text>
															<Image src={Leaf} boxSize="20px" />
														</HStack>
													</Radio>
													<Radio value="2.5">
														<Text variant="Text-S-Regular">+2,5°C</Text>
													</Radio>
												</VStack>
											</RadioGroup>
										</VStack>
									)}
								</VStack>
							</Stack>
							<VStack spacing="16px" align="start" w="100%">
								<Text variant="Text-S-Medium">
									Quelle stratégie souhaitez-vous adopter pour votre contrat{' '}
									<Text variant="Text-S-Medium" as="span" color="turquoise.900">
										{contract.envelope.type === 'AV' ? "d'Assurance vie" : 'de PER individuel'}
									</Text>
									&nbsp;?
								</Text>
								<VStack spacing="12px" align="start" w="100%">
									<OfferCard
										currentPortfolioType={portfolioType!}
										title={portfolioTypeLabels.FLAGSHIP}
										portfolioChecked={newPortfolioType}
										setPortfolioChecked={setStrategyChecked}
									/>
									<OfferCard
										currentPortfolioType={portfolioType!}
										title={portfolioTypeLabels.ESSENTIAL}
										portfolioChecked={newPortfolioType}
										setPortfolioChecked={setStrategyChecked}
									/>
								</VStack>
							</VStack>
						</VStack>
					</VStack>
					<ArrowButton
						isLoading={isArbitragePreviewFetching}
						isDisabled={
							!(
								risk !== newRisk ||
								portfolioType !== newPortfolioType ||
								esg !== newEsg ||
								(esg && temperature !== newTemperature)
							) || isArbitragePreviewFetching
						}
						onClick={() => handlePreview()}
						alignSelf="end"
					>
						<Text variant="label">Voir l'impact sur mon portefeuille</Text>
					</ArrowButton>
				</VStack>
			)}

			{step === 'preview' && (
				<VStack align="start" spacing="40px" w="100%">
					{arbitragePreview ? (
						<PreviewArbitrage
							assets={arbitragePreview}
							portfolioTypeChange={{ old: portfolioType!, new: newPortfolioType }}
							risk={{ old: risk!, new: newRisk! }}
						/>
					) : (
						<SamyAdvisor
							bgColor="blue.100"
							borderColor="blue.300"
							advisor={Aymane}
							complexText={
								<VStack align="start" spacing="4px">
									<Heading variant="Title-S-SemiBold">
										Votre demande de modification est sur le point d'être prise en compte !
									</Heading>
									<Text variant="Text-S-Medium">
										Le changement de préférences d'investissement demandé n'induit aucune modification de votre
										portefeuille à ce stade.
									</Text>
								</VStack>
							}
						/>
					)}
					<Button
						isLoading={isTriggerLoading}
						isDisabled={isTriggerLoading}
						onClick={() => handleTrigger(!!arbitragePreview)}
						alignSelf="end"
					>
						Valider
					</Button>
				</VStack>
			)}

			{step === 'sign' && (
				<VStack spacing="40px" w="100%">
					<VStack align="center">
						<Text variant="Text-S-Medium">
							Veuillez confirmer le code à 6 chiffres qui vous a été envoyé par SMS&nbsp;:
						</Text>
						<HStack>
							<PinInput value={code} onChange={setCode} otp>
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
								<PinInputField />
							</PinInput>
						</HStack>
					</VStack>

					<Button isLoading={isSignLoading} isDisabled={isSignLoading} onClick={handleSign} w="100%">
						Confirmer
					</Button>
				</VStack>
			)}
		</VStack>
	);
};

export default ChangeStrategy;
