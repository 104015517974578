import { FC, useMemo } from 'react';
import { Card, Hide, HStack, Image, Show, Stack, Text, VStack } from '@chakra-ui/react';

import Amelie from 'assets/team/amelie.png';
import Arnaud from 'assets/team/arnaud.png';
import Aymane from 'assets/team/aymane.png';
import Dario from 'assets/team/Dario.png';
import Hugo from 'assets/team/hugo.png';
import Mehdi from 'assets/team/mehdi.png';
import Nicolas from 'assets/team/Nicolas.png';
import Nils from 'assets/team/nils.png';
import Olivier from 'assets/team/olivier.png';
import Samy from 'assets/team/samy.png';
import SmallAmelie from 'assets/team/smallAmelie.png';
import SmallAymane from 'assets/team/smallAymane.png';
import SmallOlivier from 'assets/team/smallOlivier.png';
import SmallSamy from 'assets/team/smallSamy.png';
import { ArrowButton } from 'components/Button/Button';
import { BannerFace, BannerType } from 'store/types/airtable.type';

const bannerPictures: Record<BannerFace, Array<string>> = {
	olivier: [Olivier, SmallOlivier],
	amelie: [Amelie, SmallAmelie],
	samy: [Samy, SmallSamy],
	aymane: [Aymane, SmallAymane],
	arnaud: [Arnaud, Arnaud],
	hugo: [Hugo, Hugo],
	mehdi: [Mehdi, Mehdi],
	nicolas: [Nicolas, Nicolas],
	dario: [Dario, Dario],
	nils: [Nils, Nils],
};

const bannerTextColors: Record<BannerType, string> = {
	information: 'grey.900',
	operationnel: 'grey.900',
	commercial: 'grey.900',
	investissement: 'grey.900',
};

type BannerProps = {
	title: string[] | string;
	description?: React.ReactNode | string;
	variant: BannerType;
	avatar?: BannerFace;
	onClick?: () => void;
	cta?: string;
};

const Banner: FC<BannerProps> = ({ title, description, avatar, variant, cta, onClick }) => {
	const [largeAvatar, smallAvatar] = useMemo(() => {
		let a: BannerFace;
		if (!avatar) {
			if (variant === 'information') a = 'olivier';
			if (variant === 'operationnel') a = 'amelie';
			if (variant === 'commercial') a = 'samy';
			if (variant === 'investissement') a = 'aymane';
		} else a = avatar;

		return bannerPictures[a!];
	}, [avatar, variant]);

	return (
		<Card variant={variant} size="sm" onClick={onClick} _hover={onClick ? undefined : {}}>
			<Stack
				w="100%"
				justify="space-between"
				spacing="16px"
				align={{ base: 'start', md: 'center' }}
				direction={{ base: 'column', md: 'row' }}
			>
				<HStack gap="16px" flex="1">
					<Show above="md">
						<Image w="32px" borderRadius="4px" src={largeAvatar} />
					</Show>
					<VStack spacing="8px" w="100%" align="start">
						{typeof title === 'string' ? (
							<Text variant="Text-M-Medium" color={bannerTextColors[variant]}>
								{title}
							</Text>
						) : (
							<VStack align="start" spacing="2px">
								{title.map((t) => (
									<Text key={t} variant="Text-M-Medium" color={bannerTextColors[variant]}>
										{t}
									</Text>
								))}
							</VStack>
						)}

						<Stack spacing="8px" w="100%" align="center" direction="row">
							<Hide above="md">
								<Image w="24px" borderRadius="4px" src={smallAvatar} />
							</Hide>

							{description && typeof description === 'string' ? (
								<Text variant="Text-S-Regular" color="grey.900">
									{description}
								</Text>
							) : (
								description
							)}
						</Stack>
					</VStack>
				</HStack>

				{cta && <ArrowButton variant="tertiary">{cta}</ArrowButton>}
			</Stack>
		</Card>
	);
};
export default Banner;
