import { Heading, VStack } from '@chakra-ui/react';

// Components
import BottomStepBar from 'components/steppers/BottomStepBar';

import ProfessionalForm from '../../Forms/ProfessionalForm';
import { KycStepProps } from '../../utils';

const Professionnel = ({ onNext, onPrev }: KycStepProps): JSX.Element => (
	<VStack w="100%" spacing="32px" align="left">
		<Heading variant="Title-XL-Bold">Ma situation professionnelle</Heading>

		<ProfessionalForm onSuccess={onNext} formConfirmation={<BottomStepBar onPrev={onPrev} showNext nextSubmit />} />
	</VStack>
);

export default Professionnel;
