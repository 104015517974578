import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { PortfolioType } from 'store/types/profilage.type';
import colors from 'theme/foundations/colors';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const Card = defineMultiStyleConfig({
	baseStyle: {
		container: {
			boxShadow: 'none',
			borderRadius: 'base',
			borderWidth: '1px',
			borderStyle: 'solid',
			position: 'relative',
			borderColor: 'grey.500',
			backgroundColor: 'white',
		},
	},
	sizes: {
		none: {
			container: {
				padding: '0',
			},
		},
		sm: {
			container: {
				padding: '16px',
			},
		},
		md: {
			container: {
				padding: '24px',
			},
		},
		lg: {
			container: {
				padding: '32px',
			},
		},
	},
	variants: {
		// @ts-ignore ts(2322)
		portfolio: ({ type }: { type: PortfolioType }) => ({
			container: {
				bgGradient: colors.brand[type],
				border: 'none',
			},
		}),
		information: {
			container: {
				bg: colors.informative[100],
				borderColor: 'transparent',
				w: '100%',
				_hover: {
					cursor: 'pointer',
					borderColor: colors.informative[900],
				},
			},
		},
		commercial: {
			container: {
				bg: colors.grey[100],
				borderColor: 'transparent',
				w: '100%',
				_hover: {
					cursor: 'pointer',
					borderColor: colors.grey[700],
				},
			},
		},
		investissement: {
			container: {
				bg: colors.yellow[50],
				borderColor: 'transparent',
				w: '100%',
				_hover: {
					cursor: 'pointer',
					borderColor: colors.yellow[700],
				},
			},
		},
		operationnel: {
			container: {
				bg: colors.green[50],
				borderColor: 'transparent',
				w: '100%',
				_hover: {
					cursor: 'pointer',
					borderColor: colors.green[900],
				},
			},
		},
	},
	defaultProps: {
		size: 'md',
	},
});

export default Card;
