import { useEffect, useState } from 'react';
import { Button, Heading, Text, VStack } from '@chakra-ui/react';

import RiskSlider from 'components/inputs/Slider/RiskSlider';
import { useAppResponsive } from 'hooks/useAppResponsive';

export type ChangeRiskProps = {
	validateRisk: (newRisk: number) => void;
	risk: number;
	recommandedRisk: number;
};

const ChangeRisk = ({ risk, recommandedRisk, validateRisk }: ChangeRiskProps): JSX.Element => {
	const isMobile = useAppResponsive({ base: true, md: false });
	const [newRisk, updateRisk] = useState(risk);

	// This is important. The modal must be updated when the user change the risk
	// The internal `newRisk` state doesn't refresh if the `risk` prop changes, it must be done manually
	useEffect(() => {
		updateRisk(risk);
	}, [risk]);

	return (
		<VStack align="start" spacing="24px">
			<Heading variant="Title-M-Bold">Quel est votre style d’investissement</Heading>
			<Text variant="Text-M-Regular" color="grey.900">
				Définissez le niveau de risque adapté à votre projet d'investissement.
				<br />A tout moment vous pouvez revenir au risque conseillé par Ramify.
			</Text>
			<VStack w="100%" spacing="32px">
				<RiskSlider value={newRisk} onChange={updateRisk} recommandedRisk={recommandedRisk} />
				<Button w="100%" onClick={() => validateRisk(newRisk)} variant="primary" size="lg">
					Mettre à jour{!isMobile && ' mon niveau de risque'}
				</Button>
			</VStack>
		</VStack>
	);
};

export default ChangeRisk;
