import { useForm } from 'react-hook-form';
import { Link as ReachLink, useLocation, useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	chakra,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Link,
	Stack,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import { ShowHidePasswordInput } from 'components/inputs/ShowHidePasswordInput';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { useAppDispatch } from 'hooks/useStore';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useLazyLoginQuery } from 'services/requests/auth';
import { useLazyGetUserQuery } from 'services/requests/user';
import { setIsAuth, setToken } from 'store/auth.slice';
import api from 'utils/api';

import { ContainerAuthStyle } from './style';
import { emailPattern } from './utils';

type LoginFormType = {
	email: string;
	password: string;
	stayConnected: boolean;
};

const Login = (): JSX.Element => {
	const navigate = useNavigate();
	const location = useLocation();
	const isDesktop = useAppResponsive({ base: false, lg: true });
	const toast = useThemedToast();
	const dispatch = useAppDispatch();
	const [retriggerUser] = useLazyGetUserQuery();
	const [login, { isLoading }] = useLazyLoginQuery();

	const {
		formState: { errors },
		register,
		handleSubmit,
	} = useForm<LoginFormType>();

	const onSubmit = handleSubmit((data: LoginFormType) => {
		login({ email: data.email, password: data.password, extendConnection: data.stayConnected })
			.unwrap()
			.then((res) => {
				api.setHeaderToken(res.access_token);
				localStorage.setItem('token', res.access_token);
				dispatch(setIsAuth(true));
				dispatch(setToken(res.access_token));
				retriggerUser();
				navigate((location.state as { from: { pathname: string } } | undefined)?.from.pathname ?? '/');
				eventTracker.mixpanel.trackLogin(res.user.id);
			})
			.catch((error) => {
				if ('status' in error) {
					toast({
						title: "Une erreur s'est produite.",
						description: (error.data as { message: string })?.message || 'Impossible de se connecter.',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				}
			});
	});

	return (
		<Stack align={{ base: 'stretch', lg: 'center' }} direction="row" w="100%">
			{isDesktop && (
				<VStack flex="1">
					<Box w="100%" minH="calc(100vh - 80px)" h="100%" position="relative">
						<Box
							minH="calc(100vh - 80px)"
							as="video"
							autoPlay
							loop
							muted
							playsInline
							w="100%"
							h="100%"
							objectFit="cover"
							bgImg="https://uploads-ssl.webflow.com/63c027c9ecb9a193a6b225ea/640b565985f0c026358edc00_Product_02-poster-00001.jpg"
							bgPos="50% 50%"
							bgSize="cover"
							src="https://uploads-ssl.webflow.com/63c027c9ecb9a193a6b225ea/640b565985f0c026358edc00_Product_02-transcode.mp4"
						/>
						<VStack position="absolute" top="0" left="0" zIndex="10" w="100%">
							<VStack w="100%" p="52px 64px" align="start">
								<Heading variant="Title-L-Bold">Accédez à votre espace investisseur</Heading>
								<Text variant="Text-M-Regular">
									Accédez à votre dashboard, visualisez les performances de votre portefeuille et explorez notre large
									gamme de produits
								</Text>
							</VStack>
						</VStack>
					</Box>
				</VStack>
			)}
			<VStack flex="1" spacing="0px" justify="center">
				<VStack {...ContainerAuthStyle}>
					{!isDesktop && (
						<VStack align="start">
							<Heading variant="Title-L-Bold">Accédez à votre espace investisseur</Heading>
							<Text variant="Text-M-Regular">
								Accédez à votre dashboard, visualisez les performances de votre portefeuille et explorez notre large
								gamme de produits
							</Text>
						</VStack>
					)}
					<VStack spacing={{ base: 'sm', lg: 'lg' }} w="100%">
						<VStack align="start" w="100%">
							<Heading variant="Title-L-Bold">Connexion</Heading>
						</VStack>

						<chakra.form w="100%" onSubmit={onSubmit}>
							<VStack align="center" spacing="md" w="100%">
								<VStack align="start" spacing="md" w="100%">
									<FormControl isInvalid={!!errors.email}>
										<FormLabel>Adresse email</FormLabel>
										<Input
											{...register('email', {
												required: { value: true, message: 'Ce champ est nécessaire' },
												pattern: { value: emailPattern, message: "L'email est incorrect" },
											})}
											placeholder="warren.buffett@gmail.com"
											data-cy="cy-email"
											autoComplete="current-email"
										/>
										{!!errors.email && <FormErrorMessage>{errors.email?.message}</FormErrorMessage>}
									</FormControl>

									<FormControl isInvalid={!!errors.password}>
										<FormLabel>Mot de passe</FormLabel>
										<ShowHidePasswordInput
											{...register('password', {
												required: { value: true, message: 'Ce champ est nécessaire' },
												minLength: { value: 8, message: 'Le mot de passe doit contenir au moins 8 caractères' },
												maxLength: {
													value: 100,
													message: 'Le mot de passe ne doit pas contenir plus de 100 caractères',
												},
											})}
											placeholder="Minimum 8 caractères"
											data-cy="cy-pass"
											type="password"
											autoComplete="current-password"
										/>
										{!!errors.password && <FormErrorMessage>{errors.password?.message}</FormErrorMessage>}
									</FormControl>
								</VStack>

								<VStack spacing="md" w="100%">
									<Wrap spacing="sm" justify="space-between" w="100%">
										<WrapItem>
											<Checkbox {...register('stayConnected')}>
												<Text variant="Text-S-SemiBold">Rester connecté</Text>
											</Checkbox>
										</WrapItem>
										<WrapItem>
											<Link as={ReachLink} to={'/mot-de-passe-oublie' + location.search}>
												<Text variant="Text-M-Regular">Mot de passe oublié ?</Text>
											</Link>
										</WrapItem>
									</Wrap>
								</VStack>
								<VStack spacing="sm" w="100%">
									<Button
										variant="primary"
										w="100%"
										size="lg"
										data-cy="cy-connect-btn"
										isLoading={isLoading}
										type="submit"
									>
										Se connecter
									</Button>

									<Wrap justify="space-between" w="100%">
										<WrapItem>
											<Text variant="Text-M-SemiBold">Vous n'êtes pas encore inscrit ?</Text>
										</WrapItem>
										<WrapItem>
											<Link as={ReachLink} to={'/creer-un-compte' + location.search}>
												<Text variant="Text-M-Regular">Créez un compte</Text>
											</Link>
										</WrapItem>
									</Wrap>
								</VStack>
							</VStack>
						</chakra.form>
					</VStack>
				</VStack>
			</VStack>
		</Stack>
	);
};

export default Login;
