import { FC } from 'react';
import {
	HStack,
	Input,
	InputGroup,
	InputRightElement,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Text,
	VStack,
} from '@chakra-ui/react';

import colors from 'theme/foundations/colors';

type RangeType = {
	min: number;
	max: number;
};

const SliderWithText: FC<{
	range: RangeType;
	step: number;
	value: number;
	onChange: (newValue: number) => void;
	onChangeEnd: (newValue: number) => void;
}> = ({ range, step, value, onChange, onChangeEnd }) => {
	return (
		<VStack w="100%" align="left" spacing="24px">
			<Slider
				step={step}
				min={range.min}
				max={range.max}
				focusThumbOnChange={false}
				defaultValue={range.max}
				value={value}
				onChange={(newValue) => onChange(newValue)}
				onChangeEnd={(newValue) => onChangeEnd(newValue)}
			>
				<SliderTrack>
					<SliderFilledTrack backgroundColor={colors.primary.yellow} />
				</SliderTrack>
				<SliderThumb fontSize="sm" boxSize="12px" border={`2px solid ${colors.primary.black} !important`} />
			</Slider>
			<HStack w="100%">
				<Text variant="body" w="max-content">
					Moins de
				</Text>
				<InputGroup>
					<Input
						value={value}
						onChange={(event) => {
							onChange(+event.target.value);
							onChangeEnd(+event.target.value);
						}}
						type="number"
						step={step}
						min={range.min}
						max={range.max}
					/>
					<InputRightElement pointerEvents="none" color="grey.500" fontSize="1.2em" children="€" />
				</InputGroup>
			</HStack>
		</VStack>
	);
};

export default SliderWithText;
