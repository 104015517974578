import { useMemo } from 'react';
import { Card, Heading, Skeleton, Text, VStack } from '@chakra-ui/react';

import RepartitionBar, { RepartitionDataBarProps } from 'components/Graphs/RepartitionBar';
import { useAppSelector } from 'hooks/useStore';
import { useGetAggregatedHoldingsQuery } from 'services/requests/investment';
import colors from 'theme/foundations/colors';
import { isNone } from 'utils/functions';

const RepartitionBarCard = () => {
	const { inactiveList } = useAppSelector((state) => state.investContractFilter);
	const { data: holdingDatas } = useGetAggregatedHoldingsQuery(inactiveList);

	const data = useMemo<RepartitionDataBarProps[] | undefined>(() => {
		if (isNone(holdingDatas)) return undefined;
		return [
			{ name: 'Actions', value: holdingDatas.aggregatedData.equity.repartition, color: '#C9D5E8' },
			{ name: 'Obligations', value: holdingDatas.aggregatedData.bonds.repartition, color: '#D2C6EC' },
			{ name: 'SCPI', value: holdingDatas.aggregatedData.scpi.repartition, color: '#CFE2D9' },
			{ name: 'Fonds Euros', value: holdingDatas.aggregatedData.euros.repartition, color: '#EFE1C3' },
		];
	}, [holdingDatas]);

	if (!data) return <Skeleton w="100%" height="240px" />;

	return (
		<Card w="100%">
			<VStack w="100%" spacing="md" align="flex-start">
				<VStack align="flex-start">
					<Heading variant="Title-M-SemiBold">Composition de votre portefeuille - par classe d’actif</Heading>
					<Text variant="Text-M-Regular" color={colors.grey['900']}>
						En % de vos investissements
					</Text>
				</VStack>
				<RepartitionBar data={data} />
			</VStack>
		</Card>
	);
};

export default RepartitionBarCard;
