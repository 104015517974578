import { useEffect, useRef, useState } from 'react';
import { ArrowRightIcon, UpDownIcon } from '@chakra-ui/icons';
import {
	Box,
	Card,
	Divider,
	Heading,
	HStack,
	Input,
	Skeleton,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import { PerformanceBadge } from 'components/Metrics/PerformanceBadge';
import { useAppResponsive } from 'hooks/useAppResponsive';
import {
	BSPCESimulationResponse,
	CorporateAttribution,
	useLazyRevalorisationSimulationQuery,
} from 'services/requests/corporate';
import colors from 'theme/foundations/colors';
import { displayMoney } from 'utils/rendering';

type SimulationSectionProps = {
	attributionData: CorporateAttribution[];
	simulationData: BSPCESimulationResponse;
};

const SimulationSection = ({ attributionData, simulationData }: SimulationSectionProps): JSX.Element => {
	const [simulation] = useLazyRevalorisationSimulationQuery();
	const isMobile = useAppResponsive({ base: true, md: false }) || false;
	const afterLoad = useRef(1);
	const [multiplicator, setMultiplicator] = useState(2);
	const [computedData, setComputedData] = useState<number[]>([
		simulationData.next_fund_raising_valorisation,
		simulationData.next_fund_raising_theoretical_gain,
	]);

	useEffect(() => {
		if (afterLoad.current > 0) {
			afterLoad.current -= 1;
			return;
		}

		function getSimulationsResults() {
			setComputedData([]);
			simulation({
				actual_part_value: attributionData[0].company.lastShareValuation,
				next_fund_raising_multiple: multiplicator,
				list_attributions: attributionData.map((d) => ({
					id: d.id,
					attribution_date: d.vestingStartDate,
					strike_price: d.exerciceCost,
					parts_number: d.nbShares,
					fund_raising_stage: d.round,
				})),
			})
				.unwrap()
				.then((res) => setComputedData([res.next_fund_raising_valorisation, res.next_fund_raising_theoretical_gain]));
		}

		const timer = setTimeout(() => getSimulationsResults(), 700);
		// eslint-disable-next-line consistent-return
		return () => clearTimeout(timer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [multiplicator]);

	return (
		<VStack w="100%" align="start" spacing="16px">
			<Text variant="title">🦄 Simuler une revalorisation</Text>
			<Stack direction={{ base: 'column', md: 'row' }} w="100%" spacing="24px" align="stretch">
				<Skeleton w="100%" isLoaded={!isNaN(computedData[0]) || !isNaN(computedData[1])}>
					<Card p="16px 24px" w="100%" h="100%" justify="center">
						<HStack spacing={{ base: '16px', md: '40px' }} w="100%" justify="space-evenly" align="center">
							<VStack align="left">
								<HStack>
									<Text color="grey.900">Valorisation des BSPCE</Text>
									<CustomTooltip
										placement={isMobile ? 'bottom' : 'right'}
										text={
											<>
												La valorisation de vos BSPCE est estimée en fonction du nombre de parts auxquelles vous avez
												droits et de la valeur de la part estimée par l'entreprise à date. Cette valorisation est ainsi
												non garantie et sujette à l'évolution de l'entreprise.
											</>
										}
									/>
								</HStack>
								<Heading variant="Title-L-Bold">{displayMoney(computedData[0], 2)}</Heading>
							</VStack>
							<Divider
								orientation={isMobile ? 'horizontal' : 'vertical'}
								h={{ base: '1px', md: '72px' }}
								w={{ base: '200px', md: '1px' }}
							/>
							<VStack align="left">
								<HStack>
									<Text color="grey.900">Gain théorique</Text>
									<CustomTooltip
										placement={isMobile ? 'bottom' : 'right'}
										text={
											<>
												Le gain théorique est estimé faisant la soustraction entre valorisation de vos BSCPE estimée à
												date et le prix d'exercice de ces derniers.
											</>
										}
									/>
								</HStack>
								<PerformanceBadge isTitle amount={computedData[1]} />
							</VStack>
						</HStack>
					</Card>
				</Skeleton>

				<VStack w="100%" spacing="16px" justify="space-between">
					<VStack w="100%" align="start">
						<Text variant="label">Multiplicateur de valorisation</Text>
						<VStack w="100%">
							<HStack w="100%" spacing="24px">
								<Input
									w="100px"
									type="number"
									min={0}
									max={20}
									step=".01"
									value={multiplicator}
									onChange={(newValue) => setMultiplicator(+newValue.target.value)}
								/>
								<Slider
									min={0}
									max={20}
									value={multiplicator}
									focusThumbOnChange={false}
									onChange={(newValue) => setMultiplicator(newValue)}
									onChangeEnd={(newValue) => setMultiplicator(newValue)}
									w="100%"
								>
									<SliderTrack>
										<SliderFilledTrack />
									</SliderTrack>
									<SliderThumb fontSize="sm" boxSize="24px" border={`1px solid ${colors.grey[300]} !important`}>
										<Box as={UpDownIcon} transform="rotate(90deg)" />
									</SliderThumb>
								</Slider>
							</HStack>
						</VStack>
					</VStack>

					<HStack w="100%" justify="space-between">
						<VStack align="start">
							<Text variant="label">Valeur actuelle de la part</Text>
							<Input isReadOnly value={attributionData[0].company.lastShareValuation} />
						</VStack>
						<ArrowRightIcon color="grey.500" />
						<VStack align="start">
							<Text variant="label">Valeur après simulation</Text>
							<Input
								isReadOnly
								value={Math.floor(attributionData[0].company.lastShareValuation * multiplicator * 100) / 100}
							/>
						</VStack>
					</HStack>
				</VStack>
			</Stack>
		</VStack>
	);
};

export default SimulationSection;
