import { ReactNode, useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { Card, Collapse, HStack, IconButton, VStack } from '@chakra-ui/react';

export type QAType = { question: ReactNode; answer: ReactNode; variant?: 'blue' };

export const QAViewer = ({ question, answer, variant }: QAType): JSX.Element => {
	const [show, setShow] = useState(false);

	return (
		<Card
			size="sm"
			w="100%"
			transition="width 1s ease"
			borderColor={variant ? 'blue.300' : 'grey.100'}
			bg={variant ? 'blue.100' : 'white'}
		>
			<VStack w="100%" spacing="12px" onClick={() => setShow(!show)} _hover={{ cursor: 'pointer' }}>
				<HStack w="100%" justify="space-between">
					{question}
					<IconButton
						aria-label="Open/Close"
						variant="outline"
						borderColor={variant ? 'grey.700' : ''}
						icon={!show ? <ArrowDownIcon /> : <ArrowUpIcon />}
					/>
				</HStack>

				<Collapse in={show}>{answer}</Collapse>
			</VStack>
		</Card>
	);
};

export const FAQ = ({ questions }: { questions: QAType[] }) => (
	<VStack spacing="16px" w="100%" align="start">
		{questions.map((question, idx) => (
			<QAViewer key={idx} {...question} />
		))}
	</VStack>
);
