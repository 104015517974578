import { useState } from 'react';
import { Link as ReachLink, useLocation, useNavigate } from 'react-router-dom';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, Center, Heading, HStack, Image, Link, Text, VStack } from '@chakra-ui/react';

import Email from 'assets/auth/email.svg';
import Nicolas from 'assets/team/Nicolas.png';
import WelcomeCard from 'components/auth/WelcomeCard';
import { useQuery } from 'hooks/useQuery';
import { useAppDispatch } from 'hooks/useStore';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useGetUserQuery } from 'services/requests/user';
import { useLazyRequestValidateQuery } from 'services/requests/validateEmail';
import { logout } from 'store/auth.slice';

import { ContainerAuthStyle } from './style';

const Validate = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const toast = useThemedToast();
	const query = useQuery();
	const navigate = useNavigate();
	const [timeoutDone, setTimeoutDone] = useState(true);
	const [requestValidateEmail, { isLoading, error: errorValidation }] = useLazyRequestValidateQuery();
	const { data: userData } = useGetUserQuery();
	const { search } = useLocation();
	const product = new URLSearchParams(search).get('redirect') ?? '/';

	const handleTitleProduct = (productRedirect: string) => {
		if (productRedirect.includes('immobilier')) return 'Découvrez notre sélection de SCPI';
		if (productRedirect.includes('private-equity')) return 'Découvrez notre sélection de fonds de Private Equity';
		if (productRedirect.includes('invest')) return 'Faire une simulation';
		return 'Validation de votre compte';
	};

	const onResend = () => {
		eventTracker.mixpanel.track('Confirmation email not received');
		setTimeoutDone(false);
		setTimeout(() => setTimeoutDone(true), 10000);
		requestValidateEmail(query.get('redirect') ?? '')
			.unwrap()
			.then(() => {
				toast({
					title: 'Envoyé',
					description: 'Vous pouvez regarder dans votre boîte mail !',
					status: 'success',
					duration: 9000,
					isClosable: true,
				});
			})
			.catch((error) => {
				if ('status' in error) {
					toast({
						title: "Une erreur s'est produite.",
						description: (error.data as { message: string })?.message || "Nous n'avons pas pu envoyer l'email",
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				}
			});
	};

	const getMailBoxUrl = (email: string): string => {
		if (email.endsWith('@gmail.com'))
			return 'https://mail.google.com/mail/u/0/#search/from%3A(noreply%40ramify.fr)+in%3Aanywhere+newer_than%3A1h';
		if (email.includes('@outlook') || email.includes('@hotmail') || email.includes('@live'))
			return 'https://outlook.live.com/mail/0/';
		if (email.endsWith('@yahoo.com') || email.endsWith('@yahoo.fr'))
			return 'https://mail.yahoo.com/d/search/keyword=from%25%noreply%40ramify.fr';
		if (email.endsWith('@protonmail.com') || email.endsWith('@proton.me'))
			return 'https://mail.proton.me/u/0/all-mail#from=noreply%40ramify.fr';
		if (email.endsWith('@icloud.com')) return 'https://www.icloud.com/mail/';
		return '';
	};

	return (
		<VStack w="100%">
			<VStack spacing="lg" mt="40px" w="100%" paddingX={{ base: '16px', sm: '40px' }}>
				<Heading variant="Title-L-SemiBold">{handleTitleProduct(product)}</Heading>
				<WelcomeCard
					welcomeTitleActive={false}
					description="Vous y êtes presque, afin d’accéder à notre sélection, veuillez simplement vérifier votre adresse via l’email que vous avez reçu."
					imagePath={Nicolas}
				/>
			</VStack>
			<VStack {...ContainerAuthStyle}>
				<VStack align="center" spacing="lg" w="100%" maxW="668px">
					<Heading variant="Title-S-SemiBold">Vérifiez votre adresse email</Heading>
					<Center bg="#DFECFE" w="72px" h="72px" borderRadius="50%">
						<Image src={Email} alt="Email" />
					</Center>
					<VStack align="center" spacing="16px" w="100%">
						{isLoading ? (
							<Text variant="Text-M-Regular" align="center">
								Nous vous envoyons un email...
							</Text>
						) : errorValidation ? (
							<Text variant="Text-M-Regular" align="center">
								Nous n'avons pas pû vous envoyer d'email de vérification...
								<br />
								Veuillez nous contacter via la bulle de chat présente sur le site, un membre de l'équipe Ramify pourra
								vous aider à vérifier votre compte directement.
							</Text>
						) : (
							<Text variant="Text-M-Regular" align="center">
								Un email de vérification vous a été envoyé à &nbsp;
								<b>{userData?.email}</b>
								<br />
								Votre email doit être vérifié afin d’accéder à notre sélection de fonds.
							</Text>
						)}

						{userData && getMailBoxUrl(userData.email) && (
							<HStack>
								<Link as={ReachLink} to={getMailBoxUrl(userData.email)} target="_blank" w="100%">
									Ouvrir ma boîte mail
								</Link>
								<ExternalLinkIcon />
							</HStack>
						)}
						<Button size="lg" w="100%" isDisabled={!timeoutDone || isLoading} onClick={onResend} isLoading={isLoading}>
							Renvoyer un email
						</Button>

						<Link
							as={ReachLink}
							onClick={() => {
								dispatch(logout());
								// This is important: logout clear api cache and navigating prevents hooks in pages from refetching one more time
								navigate('/connexion');
							}}
						>
							Me déconnecter
						</Link>
					</VStack>
				</VStack>
			</VStack>
		</VStack>
	);
};

export default Validate;
