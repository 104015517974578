import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import { useKycOnboardingNavigate } from 'hooks/useKycNavigate';
import { useAppSelector } from 'hooks/useStore';

import Coordonnees from './Steps/Coordonnees';
import EtatCivil from './Steps/EtatCivil';
import FoyerFiscal from './Steps/FoyerFiscal';
import Introduction from './Steps/Introduction';
import Justificatif from './Steps/Justificatif';
import Patrimoine from './Steps/Patrimoine';
import Professionnel from './Steps/Profesionel';
import Recapitulatif from './Steps/Recapitulatif/Recapitulatif';
import { KYCStepType } from './utils';

export type KycProduct = 'scpi-pe' | 'invest' | 'kyc-update' | 'scpi-phoenix' | 'epargne';

interface KycProps {
	steps: KYCStepType[];
	redirectOnEnd: string;
	product: KycProduct;
}

const KYC = ({ steps, redirectOnEnd, product }: KycProps): JSX.Element => {
	const { goToNextStep, goToPreviousStep, StateWrapper } = useKycOnboardingNavigate(steps, redirectOnEnd);
	const { lastInnerStepAvailable } = useAppSelector((state) => state.onboarding.stepper);
	const navigate = useNavigate();

	const stepParams = { onNext: goToNextStep, onPrev: goToPreviousStep };
	const stepComponents: Record<KYCStepType, React.ReactNode> = {
		introduction: <Introduction {...stepParams} product={product} onNext={() => navigate(lastInnerStepAvailable)} />,
		etatCivil: <EtatCivil {...stepParams} product={product} />,
		foyerFiscal: <FoyerFiscal {...stepParams} />,
		professionelle: <Professionnel {...stepParams} />,
		coordonnees: <Coordonnees {...stepParams} />,
		patrimoine: <Patrimoine {...stepParams} />,
		justificatif: <Justificatif {...stepParams} />,
		recapitulatif: <Recapitulatif {...stepParams} />,
	};
	const stepsRoutes = steps.map((step) => ({ name: step, component: stepComponents[step] }));

	return (
		<VStack spacing={{ base: '40px', md: '48px', lg: '56px' }} align="start" w="100%">
			<Routes>
				{stepsRoutes.map(({ name, component }) => (
					<Route key={name} element={<StateWrapper step={name as KYCStepType} />}>
						{name === 'introduction' && <Route index element={component} />}
						{name === 'justificatif' && <Route path={`${name}/*`} element={component} />}
						{name !== 'introduction' && name !== 'justificatif' && <Route path={name} element={component} />}
					</Route>
				))}
			</Routes>
		</VStack>
	);
};

export default KYC;
