import { Card, Heading, HStack, Stack, StackDivider, Text, useBreakpointValue, VStack } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import { PerformanceBadge } from 'components/Metrics/PerformanceBadge';
import { displayMoney } from 'utils/rendering';

type StatCardProps = {
	valuation: number;
	theoricalGain: number;
	nbShares: number;
	shareValue: number;
};

const StatCard = ({ valuation, theoricalGain, nbShares, shareValue }: StatCardProps): JSX.Element => {
	const isMobile = useBreakpointValue({ base: true, md: false });

	return (
		<Card p="24px" w="100%">
			<Stack
				direction={{ base: 'column', md: 'row' }}
				spacing="16px"
				w="100%"
				divider={isMobile ? undefined : <StackDivider />}
				justify="space-evenly"
			>
				<VStack align="start" spacing={{ base: '0px', md: '16px' }} justify="space-evenly">
					<HStack>
						<Text color="grey.900">Valorisation de vos BSPCE</Text>
						<CustomTooltip
							placement={isMobile ? 'bottom' : 'right'}
							text={
								<>
									La valorisation de vos BSPCE est estimée en fonction du nombre de parts auxquelles vous avez droits et
									de la valeur de la part estimée par l'entreprise à date. Cette valorisation est ainsi non garantie et
									sujette à l'évolution de l'entreprise.
								</>
							}
						/>
					</HStack>

					<Heading variant="Title-M-Bold">{displayMoney(valuation, 2)}</Heading>
				</VStack>

				<VStack align="start" spacing={{ base: '0px', md: '16px' }} justify="space-evenly">
					<HStack>
						<Text variant="label" color="grey.900">
							Gain théorique
						</Text>
						<CustomTooltip
							placement={isMobile ? 'bottom' : 'right'}
							text={
								<>
									Le gain théorique est estimé faisant la soustraction entre valorisation de vos BSCPE estimée à date et
									le prix d'exercice de ces derniers.
								</>
							}
						/>
					</HStack>
					<PerformanceBadge isTitle amount={theoricalGain} />
				</VStack>

				<VStack align="start" spacing={{ base: '0px', md: '16px' }} justify="space-evenly">
					<Text variant="label" color="grey.900">
						Nombre de parts
					</Text>
					<Heading variant="Title-M-Bold">{nbShares}</Heading>
				</VStack>

				<VStack align="start" spacing={{ base: '0px', md: '16px' }} justify="space-evenly">
					<Text variant="label" color="grey.900">
						Valeur de la part
					</Text>
					<Heading variant="Title-M-Bold">{displayMoney(shareValue, 2)}</Heading>
				</VStack>
			</Stack>
		</Card>
	);
};

export default StatCard;
