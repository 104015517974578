import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import StepperLayout from 'app/Layout/Onboarding/StepperLayout';
import { useAppDispatch } from 'hooks/useStore';
import KYC from 'onboarding/KYC';
import { hasCompletedKyc } from 'onboarding/KYC/utils';
import { setLastStepAvailable } from 'onboarding/Stepper/stepper.slice';
import { useGetKYCJustificatifQuery, useGetKYCQuery, useGetPESCPIKYCQuery } from 'services/requests/kyc';
import { useGetSCPIKYCQuery, useGetSCPIKYCTestQuery } from 'services/requests/realEstate/kyc';
import { useGetUserSCPISubscriptionByIdQuery } from 'services/requests/realEstate/subscriptions';
import { SCPISubscription } from 'store/types/airtable.type';
import { listSCPI } from 'utils/automatedFundTypes';

import DefaultConfirmation from './Confirmation/default';
// import NCapContinentConfirmation from './Confirmation/ncap-continent';
// import KyaneosPierreConfirmation from './Confirmation/kyaneos-pierre';
import NovaxiaNeoConfirmation from './Confirmation/novaxia-neo';
import RemakeLiveConfirmation from './Confirmation/remake-live';
import { isReReglementaryStepCompleted } from './Reglementaire/utils';
import Projet from './Projet';
import Reglementaire from './Reglementaire';
import Souscription from './Souscription';
import steps, { ReOnboardingSteps } from './steps';

// TODO TMP disable to disable automated funds
const ConfirmationChooser = ({ subscription }: { subscription?: SCPISubscription }): JSX.Element => {
	// if (subscription?.fundName === listSCPI.KYANEOS_PIERRE) return <KyaneosPierreConfirmation />;
	if (subscription?.fundName === listSCPI.NOVAXIA_NEO) return <NovaxiaNeoConfirmation />;
	if (subscription?.fundName === listSCPI.REMAKE_LIVE) return <RemakeLiveConfirmation />;
	// if (subscription?.fundName === listSCPI.NCAP_CONTINENT) return <NCapContinentConfirmation />;
	return <DefaultConfirmation />;
};

const SCPIOnboardingRoutes = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const [collapsed, setCollapsed] = useState(false);
	const { id } = useParams<{ id: string }>();

	const { data: kyc } = useGetKYCQuery();
	const { data: justificatif } = useGetKYCJustificatifQuery();
	const { data: subscription } = useGetUserSCPISubscriptionByIdQuery({ id: id! });

	// Informations
	const { data: peScpiKyc } = useGetPESCPIKYCQuery();

	// Informations produit
	const { data: scpiKyc } = useGetSCPIKYCQuery();

	// Test d'adéquation
	const { data: scpiKycTest } = useGetSCPIKYCTestQuery();

	// Set la dernière étape complétée
	// if (isAtStep(condition)) dispatch(setLastCompletedStep('correctStep'));

	// Redirect on lastStep if we go out of bounds
	// if (userData && userData.onboardingStatus && isAtleastAtStep(userData.onboardingStatus, step)) return <Outlet />;

	useEffect(() => {
		if (isReReglementaryStepCompleted(kyc, peScpiKyc, scpiKyc, scpiKycTest)) {
			dispatch(setLastStepAvailable(ReOnboardingSteps.SUBSCRIPTION));
		} else if (hasCompletedKyc(kyc, justificatif)) {
			dispatch(setLastStepAvailable(ReOnboardingSteps.REGLEMENTARY));
		} else {
			dispatch(setLastStepAvailable(ReOnboardingSteps.KYC));
		}
	}, [kyc, justificatif, dispatch, peScpiKyc, scpiKyc, scpiKycTest]);

	if (!id) return <Navigate to="/immobilier" />;

	return (
		<Routes>
			<Route
				element={
					<StepperLayout
						headerTitle={subscription?.fundName}
						headerSubtitle="Finalisation"
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						steps={steps}
						basePath={`/immobilier/onboarding/${id}`}
					/>
				}
			>
				{/* Guard redirect last step */}
				<Route path="projet" element={<Projet />} />
				<Route
					path="connaissance-client/*"
					element={
						<KYC
							product="scpi-pe"
							steps={[
								'introduction',
								'etatCivil',
								'foyerFiscal',
								'professionelle',
								'coordonnees',
								'patrimoine',
								'justificatif',
								'recapitulatif',
							]}
							redirectOnEnd={`/immobilier/onboarding/${id}/reglementaire`}
						/>
					}
				/>
				<Route path="reglementaire/*" element={<Reglementaire />} />
				{/* The /* is important to allow opening drawer for bank account */}
				<Route path="souscription/*" element={<Souscription />} />
				<Route path="confirmation" element={<ConfirmationChooser subscription={subscription} />} />
			</Route>
			<Route path="*" element={<Navigate to="projet" replace />} />
		</Routes>
	);
};

export default SCPIOnboardingRoutes;
