import { Button, HStack, Show, Text, useDisclosure, VStack } from '@chakra-ui/react';

import { CalendlyModalBlack } from '../Modal';

const HeaderOffreBlack = (): JSX.Element => {
	const { isOpen: isCalendlyOpen, onClose: onCalendlyClose, onOpen: onCalendlyOpen } = useDisclosure();

	return (
		<Show above="md">
			<CalendlyModalBlack isOpen={isCalendlyOpen} onClose={onCalendlyClose} />
			<HStack w="100%" justify="space-between">
				<VStack w="100%" align="start" spacing="8px">
					<Text variant="Title-XL-SemiBold" color="white">
						Ramify Black
					</Text>
					<Text variant="Caption" color="primary.yellow">
						réservé aux membres
					</Text>
				</VStack>
				<Button variant="super" size="lg" onClick={onCalendlyOpen}>
					Devenir membre
				</Button>
			</HStack>
		</Show>
	);
};
export default HeaderOffreBlack;
