import { Link } from 'react-router-dom';
import { Close } from '@carbon/icons-react';
import { Box, Hide, HStack, IconButton, Image, VStack } from '@chakra-ui/react';

import RamifyLogo from 'assets/logoRamify/black.svg';
import Notification from 'components/notification';
import { SideBarType } from 'components/SideBar';

import BuildSideBar from './BuildSideBar';

type SideBarUpperProps = {
	sideBarParts: SideBarType[];
	onClose?: () => void;
};

const SideBarUpper = ({ sideBarParts, onClose }: SideBarUpperProps) => (
	<VStack w="100%" align="start" spacing="32px">
		<HStack w="100%" justify="space-between">
			<Link to="/">
				<Image paddingLeft="8px" src={RamifyLogo} w="96px" />
			</Link>
			<HStack>
				<Notification />
				<Hide above="sm">
					<IconButton
						aria-label="close"
						onClick={onClose}
						variant="tertiary"
						icon={
							<Box>
								<Close size="32" />
							</Box>
						}
					/>
				</Hide>
			</HStack>
		</HStack>
		<VStack align="start" w="100%" spacing="4px">
			{sideBarParts.map((part) => (
				<Box onClick={part.onClick} key={part.title} w="100%">
					<BuildSideBar icon={part.icon} title={part.title} isActive={part.isActive} badge={part.badge} />
				</Box>
			))}
		</VStack>
	</VStack>
);

export default SideBarUpper;
