import { useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormLabel, HStack, Text, VStack } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import ControlledRadioOptions from 'components/inputs/RadioOptions';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';

const SimulationPeObjectiveInput = (): JSX.Element => {
	const {
		formState: { errors },
		control,
	} = useFormContext<{ objective: string }>();

	return (
		<FormControl isInvalid={!!errors.objective}>
			<FormLabel>
				<HStack>
					<>Quel est votre objectif d’investissement ?</>
					<CustomTooltip
						content={
							<VStack spacing="12px" align="start">
								<Text variant="Text-S-Regular" color="white">
									Il existe différents types de fonds de Private Equity proposant des spécificités adaptées à certains
									objectifs d’investissement, parmi eux:
								</Text>
								<Text variant="Text-S-Regular" color="white">
									- <b>Dynamiser votre portefeuille :</b> vous recherchez une performance financière supérieure sans
									attention spécifique à la réduction de votre impôt sur le revenu (IR) actuel.
								</Text>
								<Text variant="Text-S-Regular" color="white">
									- <b>Réduire votre impôt (IR) :</b> vous recherchez une performance financière intéressante couplée à
									une réduction de votre impôt sur le revenu (jusqu’à 30% des montants investis).
								</Text>
								<Text variant="Text-S-Regular" color="white">
									- <b>Investir dans le cadre d'une cession / 150-0-B-TER :</b> vous souhaitez bénéficier d'un report
									d'imposition dans le cadre de la cession des parts de votre entreprise.
								</Text>
							</VStack>
						}
					/>
				</HStack>
			</FormLabel>

			<ControlledRadioOptions
				w={onboardingInputWidth}
				name="objective"
				control={control}
				options={[
					{
						label: 'Réduire mon impôt (IR)',
						value: 'defiscate',
					},
					{
						label: 'Dynamiser mon portefeuille',
						value: 'improve_portfolio',
					},
					{
						label: "Investir dans le cadre d'une cession / 150-0-B-TER",
						value: '150_0_b_ter',
					},
				]}
			/>
			{errors.objective && <FormErrorMessage>{errors.objective.message}</FormErrorMessage>}
		</FormControl>
	);
};
export default SimulationPeObjectiveInput;
