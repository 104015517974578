type IdentityCode = 'passeport' | 'carteIdentite' | 'carteSejourResidence';

const referentiel: { code: IdentityCode; libelle: string }[] = [
	{
		code: 'passeport',
		libelle: 'Passeport',
	},
	{
		code: 'carteIdentite',
		libelle: "Carte d'identité",
	},
	{
		code: 'carteSejourResidence',
		libelle: 'Carte de séjour ou de résidence',
	},
];

export const convertIdentityCodeToLabel = (code: IdentityCode): string => {
	if (code === 'passeport') return 'Passeport';
	if (code === 'carteIdentite') return 'Carte d’identité';
	if (code === 'carteSejourResidence') return 'Carte de séjour ou de résidence';
	return '';
};

export default referentiel;
