import { useState } from 'react';
import { Box, HStack, Stack } from '@chakra-ui/react';

export interface Option {
	value: string;
	label: string;
}

interface CustomRadioButtonProps {
	options: Option[];
	onChange: (option: Option) => void;
	strokeColor?: string;
	fillColor?: string;
	defaultValue?: Option;
}

const CustomRadioButton = ({ options, onChange, strokeColor, fillColor, defaultValue }: CustomRadioButtonProps) => {
	const [selectedOption, setSelectedOption] = useState<Option | null>(defaultValue ?? null);

	const handleOptionChange = (optionValue: Option) => {
		setSelectedOption(optionValue);
		onChange(optionValue);
	};

	return (
		<Stack direction="row" w="100%" mt="16px" _hover={{ cursor: 'pointer' }}>
			{options.map((option) => (
				<HStack key={option.label} onClick={() => handleOptionChange(option)}>
					<Box
						width="16px"
						height="16px"
						borderRadius="50%"
						backgroundColor={selectedOption?.value === option.value ? fillColor : 'white'}
						border="2px solid"
						borderColor={selectedOption?.value === option.value ? strokeColor : 'gray.300'}
					/>
					<Box marginLeft="8px" fontSize="14px" _hover={{ color: 'grey.500' }}>
						{option.label}
					</Box>
				</HStack>
			))}
		</Stack>
	);
};
export default CustomRadioButton;
