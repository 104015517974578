import { FC } from 'react';
import { ArrowLeft } from '@carbon/icons-react';
import {
	Button,
	Card,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	HStack,
	IconButton,
	Image,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import CER from 'assets/icons/ProductsColoredIcons/Cer.svg';

import useCERCallToAction from '../useCERCallToAction';

import { Properties } from './Properties';
import { CashProductContentProps } from './type';

const cerProperties = [
	{
		label: 'Taux d’intérêt en vigueur',
		value: '2,8% brut',
	},
	{
		label: 'Couverture du FGDR',
		value: "Jusqu'à 100 000€",
	},
	{
		label: 'Disponibilité des fonds',
		value: 'Immédiate',
	},
	{
		label: 'Frais d’entrée',
		value: 'Aucun',
	},
	{
		label: 'Frais de gestion',
		value: 'Aucun',
	},
	{
		label: 'Frais d’opérations',
		value: 'Aucun',
	},
	{
		label: 'Frais de clôture',
		value: 'Aucun',
	},
	{
		label: 'Fiscalité',
		value: 'Bénéfices soumis au PFU (30%)',
	},
];

export const CerDrawer: FC<CashProductContentProps> = ({ isOpen, onClose }) => {
	const { cta } = useCERCallToAction();

	return (
		<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isOpen} onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<HStack justify="space-between">
						<HStack>
							<IconButton onClick={onClose} variant="icon" aria-label="back" icon={<ArrowLeft size="32" />} />
							<Heading variant="Title-M-SemiBold">Retour</Heading>
						</HStack>
						<Button variant={cta.variant} size="lg" onClick={cta.onClick}>
							{cta.label}
						</Button>
					</HStack>
				</DrawerHeader>
				<DrawerBody>
					<VStack w="100%" spacing="32px" align="start">
						<Card
							w="100%"
							border="none"
							bg="linear-gradient(139deg, #FFEADE 0%, rgba(255, 247, 242, 0.10) 100%)"
							padding="64px 40px"
						>
							<VStack w="100%" align="start" spacing="24px">
								<Image src={CER} w="40px" />
								<VStack w="100%" align="start" spacing="8px">
									<Heading variant="Title-XL-Bold" color="orange.900">
										Livret épargne
									</Heading>
									<Text variant="Text-S-Bold" color="orange.500">
										My Money Bank
									</Text>
								</VStack>
							</VStack>
						</Card>

						<VStack w="100%" align="start" spacing="16px">
							<Text variant="Title-L-SemiBold">Détail de votre rémunération</Text>
							<Wrap display="flex" w="100%" spacing="16px">
								<WrapItem minW="296px" flex="1" alignItems="stretch">
									<Card>
										<VStack w="100%" align="start">
											<Text variant="Title-S-SemiBold" color="primary.black">
												3,5&nbsp;%, pendant les deux premiers mois
											</Text>
											<Text variant="Text-M-Regular" color="grey.900">
												Un taux nominal annuel brut promotionnel*&nbsp;: pendant une 1ère période définie. Le bénéfice
												du taux promotionnel est limité à l’ouverture du 1er Compte Épargne Rémunéré et dans la limite
												d’un compte par client. Toute ouverture d’un nouveau compte consécutive à la clôture d’un
												précédent compte ne pourra bénéficier du taux promotionnel.
											</Text>
										</VStack>
									</Card>
								</WrapItem>
								<WrapItem minW="296px" flex="1" alignItems="stretch">
									<Card>
										<VStack w="100%" align="start">
											<Text variant="Title-S-SemiBold" color="primary.black">
												2,8&nbsp;%, taux de rémunération nominal
											</Text>
											<Text variant="Text-M-Regular" color="grey.900">
												Suivi d’un taux nominal annuel brut standard 2,8&nbsp;%&nbsp;: à l’issue de la 1ère période
												définie, ou pour les sommes déposées supérieures au montant bénéficiant du taux nominal annuel
												brut promotionnel, la totalité des dépôts est rémunérée au taux nominal annuel brut en vigueur.
											</Text>
										</VStack>
									</Card>
								</WrapItem>
							</Wrap>
						</VStack>

						<VStack w="100%" align="start" spacing="16px">
							<Text variant="Title-L-SemiBold">Caractéristiques de votre livret</Text>
							<Properties properties={cerProperties} />
						</VStack>
					</VStack>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};
