import { Controller, useFormContext } from 'react-hook-form';
import {
	Card,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Radio,
	RadioGroup,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import NumberInput from 'components/inputs/NumberInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import { LoanKyc } from 'store/types/KYC.type';
import { isNotNone } from 'utils/functions';

const RegulatoryLoansFormContext = () => {
	const {
		control,
		formState: { errors },
		watch,
	} = useFormContext<LoanKyc>();

	const loanFrequencyOptions = Object.entries({
		MONTHLY: 'Mensuelle',
		ANNUALLY: 'Annuelle',
		IN_FINE: 'In fine',
	} as Record<LoanKyc['realEstateLoanFrequency'], string>) as [LoanKyc['realEstateLoanFrequency'], string][];

	const form = [
		{
			title: 'Emprunt immobilier',
			hasField: 'hasRealEstateLoan',
			frequencyField: 'realEstateLoanFrequency',
			restField: 'realEstateLoanRest',
		},
		{
			title: 'Emprunt consommation',
			hasField: 'hasConsomationLoan',
			frequencyField: 'consomationLoanFrequency',
			restField: 'consomationLoanRest',
		},
		{
			title: 'Autre emprunt',
			hasField: 'hasOtherLoan',
			frequencyField: 'otherLoanFrequency',
			restField: 'otherLoanRest',
		},
	] as const;

	return (
		<VStack w="100%" align="start" spacing="24px">
			<Heading variant="Title-L-Bold">Avez-vous des crédits en cours ?</Heading>

			<Wrap spacing="24px">
				{form.map(({ title, hasField, frequencyField, restField }) => {
					const hasLoan = watch(hasField);

					return (
						<WrapItem key={hasField}>
							<Card>
								<VStack w="100%" align="start" spacing="24px">
									<Heading variant="Title-M-Bold">{title}</Heading>
									<FormControl w={onboardingInputWidth} isInvalid={!!errors[hasField]}>
										<FormLabel>Avez-vous un crédit de ce type en cours ?</FormLabel>

										<Controller
											name={hasField}
											control={control}
											rules={{ validate: { required: (value) => isNotNone(value) } }}
											render={({ field: { onChange, value, ...rest } }) => (
												<RadioGroup
													variant="single"
													size="sm"
													onChange={(newValue) => onChange(newValue === 'true')}
													value={isNotNone(value) ? (value ? 'true' : 'false') : undefined}
													{...rest}
												>
													<VStack spacing="8px">
														<Radio value="true">Oui</Radio>
														<Radio value="false">Non</Radio>
													</VStack>
												</RadioGroup>
											)}
										/>

										{errors[hasField]?.type === 'required' && (
											<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
										)}
									</FormControl>

									{hasLoan && (
										<>
											<FormControl isInvalid={!!errors[frequencyField]}>
												<FormLabel>Quelle est la fréquence de paiement de ce crédit ?</FormLabel>
												<Controller
													name={frequencyField}
													control={control}
													rules={{ validate: { required: (value) => isNotNone(value) } }}
													render={({ field: { onChange, ...rest } }) => (
														<RadioGroup variant="single" size="sm" onChange={onChange} {...rest}>
															<VStack spacing="8px">
																{loanFrequencyOptions.map(([key, display]) => (
																	<Radio key={key} value={key}>
																		{display}
																	</Radio>
																))}
															</VStack>
														</RadioGroup>
													)}
												/>

												{errors[frequencyField]?.type === 'required' && (
													<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
												)}
											</FormControl>
											<FormControl isInvalid={!!errors[restField]}>
												<FormLabel>Capital restant dû</FormLabel>

												<Controller
													name={restField}
													control={control}
													rules={{ required: true, min: 0 }}
													render={({ field }) => <NumberInput {...field} placeholder="Montant" suffix=" €" />}
												/>

												{errors[restField]?.type === 'min' && (
													<FormErrorMessage>Le restant ne peut pas être négatif</FormErrorMessage>
												)}
												{errors[restField]?.type === 'required' && (
													<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
												)}
											</FormControl>
										</>
									)}
								</VStack>
							</Card>
						</WrapItem>
					);
				})}
			</Wrap>
		</VStack>
	);
};

export default RegulatoryLoansFormContext;
