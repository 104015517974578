import { MainStepType, OnboardingStep } from 'onboarding/Stepper/stepper.slice';

export enum CashOnboardingSteps {
	PROJECT = 'project',
	KYC = 'connaissance-client',
	SOUSCRIPTION = 'souscription',
	CONFIRMATION = 'confirmation',
}

// export const signatureStep = (
// 	displayDeduction: boolean,
// 	displayVersements: boolean,
// ): OnboardingStep<InvestSubscriptionSteps> => ({
// 	id: InvestSubscriptionSteps.SIGNATURE,
// 	name: 'Signature',
// 	url: 'signature',
// 	type: MainStepType.END,
// 	innerSteps: [
// 		feesStep,
// 		beneficiaryStep,
// 		displayVersements ? versementsStep : undefined,
// 		displayDeduction ? deductibilityStep : undefined,
// 		bankStep,
// 		bulletinStep,
// 	]
// 		.filter(isNotNone)
// 		.map((innerStep) => ({
// 			...innerStep,
// 			url: `${signatureUrl}/${innerStep.url}`,
// 		})),
// });

export const reglementaireStep = {
	id: 'reglementaire',
	name: 'Informations réglementaires',
	url: 'reglementaire',
};

export const justificatifsStep = {
	id: 'justificatif',
	name: 'Justificatifs',
	url: 'justificatifs',
};

export const signatureStep = {
	id: 'signature',
	name: 'Signature',
	url: 'signature',
};

const souscriptionUrl = 'souscription';
export const souscriptionStep = {
	id: CashOnboardingSteps.SOUSCRIPTION,
	name: 'Souscription',
	url: 'souscription',
	type: MainStepType.COURSE,
	innerSteps: [reglementaireStep, justificatifsStep, signatureStep].map((innerStep) => ({
		...innerStep,
		url: `${souscriptionUrl}/${innerStep.url}`,
	})),
} satisfies OnboardingStep<CashOnboardingSteps>;
