import { Line } from 'react-chartjs-2';
import { Box, Card, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';

import colors from 'theme/foundations/colors';
import { displayMoney, displayPercentage } from 'utils/rendering';

export const calculateSaving = (initialAUM: number, monthLength: number, annualYield: number, saving: number) => {
	const list: { x: Date; y: number }[] = [
		{ x: dayjs().add(1, 'month').toDate(), y: initialAUM * Math.pow(1 + annualYield, 1 / 12) },
	];

	// Start at the second month, because the first month doesn't have savings
	// Go to nbr + 1 because there is no month 0
	for (let i = 2; i < monthLength + 1; i++) {
		const number =
			initialAUM * Math.pow(1 + annualYield, i / 12) +
			(saving * (Math.pow(1 + annualYield, i / 12) - 1)) / (Math.pow(1 + annualYield, 1 / 12) - 1);
		list.push({ x: dayjs().add(i, 'month').toDate(), y: number });
	}
	return list;
};

const ProjectGraph = ({
	initialAUM,
	saving,
	monthLength,
	performance,
}: {
	initialAUM: number;
	saving: number;
	monthLength: number;
	performance: number;
}): JSX.Element => (
	<Card w="100%">
		<VStack w="100%" align="start" spacing="16px">
			<VStack align="start" spacing="0px" w="100%">
				<Text variant="Title-S-SemiBold">Evolution de votre capital</Text>
				<Text variant="Text-S-Regular" color="grey.700">
					(performance moyenne de {displayPercentage(performance)} par an)
				</Text>
			</VStack>
			<Box position="relative" height="320px" w="100%" borderRadius="base">
				<Line
					data={{
						datasets: [
							...(saving !== 0
								? [
										{
											label: 'Avec versements programmés',
											data: calculateSaving(initialAUM, monthLength, performance, saving),
											fill: false,
											backgroundColor: '#FDAC02',
											borderColor: '#FDAC02',
											pointRadius: 0,
										},
								  ]
								: []),
							{
								label: 'Sans versements programmés',
								data: calculateSaving(initialAUM, monthLength, performance, 0),
								fill: false,
								backgroundColor: colors.grey[500],
								// backgroundColor: colors.grey[300],
								borderColor: colors.grey[500],
								// borderColor: colors.grey[300],
								pointRadius: 0,
							},
						],
					}}
					plugins={[
						{
							id: 'indexBar',
							afterDraw: (chart) => {
								if (chart.tooltip && chart.tooltip.getActiveElements().length > 0) {
									const x = chart.tooltip.getActiveElements()[0].element.x;
									const yAxis = chart.scales.y;
									const ctx = chart.ctx;
									ctx.save();
									ctx.beginPath();
									ctx.moveTo(x, yAxis.top);
									ctx.lineTo(x, yAxis.bottom);
									ctx.lineWidth = 1;

									ctx.strokeStyle = colors.grey[300];
									ctx.stroke();
									ctx.restore();
								}
							},
						},
					]}
					options={{
						hover: {
							mode: 'index',
							intersect: false,
						},
						plugins: {
							legend: {
								align: 'start',
								position: 'bottom',
							},
							tooltip: {
								mode: 'index',
								axis: 'x',
								padding: 12,
								intersect: false,
								backgroundColor: 'white',
								bodyColor: colors.grey[900],
								titleColor: colors.primary.black,
								borderColor: colors.grey[300],
								borderWidth: 1,
								titleFont: {
									size: 16,
								},
								bodyFont: {
									size: 14,
								},
								callbacks: {
									labelColor: (t) => {
										if (t.datasetIndex === 0) return { backgroundColor: '#FDAC02', borderColor: 'rgba(0, 0,0,0)' };
										return { backgroundColor: colors.grey[500], borderColor: 'rgba(0, 0,0,0)' };
									},
									title: (datapoints) => dayjs((datapoints[0].raw as { x: Date }).x).format('DD MMMM YYYY'),
									label: (dataPoint) =>
										`${dataPoint.dataset.label} - ${displayMoney((dataPoint.raw as { y: number }).y)}`,
									// beforeBody: () => 'Performances',
									// label: (dataPoint) => `${Number(((dataPoint.raw as number) * 100).toFixed(2))}%`,
								},
							},
						},
						elements: {
							line: {
								borderWidth: 1,
							},
						},
						scales: {
							x: {
								grid: {
									display: false,
								},
								type: 'time',
								time: {
									displayFormats: {
										quarter: 'YYYY',
									},
								},
								ticks: {
									color: colors.grey[900],
									maxTicksLimit: 5,
									minRotation: 0,
									maxRotation: 0,
									callback: (value) => {
										return dayjs(value).format('YYYY');
									},
								},
							},
							y: {
								grid: {
									borderDash: [5, 4],
								},
								beginAtZero: true,
								ticks: {
									color: colors.grey[900],
									font: {
										size: 13,
									},
									callback: (value) => {
										return `${Number(value)} €`;
									},
								},
							},
						},
						responsive: true,
						maintainAspectRatio: false,
					}}
				/>
			</Box>
		</VStack>
	</Card>
);

export default ProjectGraph;
