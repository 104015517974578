import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetContractsQuery } from 'services/requests/invest/contracts';
import { useGetInvestSimulationQuery } from 'services/requests/invest/simulation';
import { useGetAllSubscriptionsQuery } from 'services/requests/invest/subscription';
import { useGetKYCQuery } from 'services/requests/kyc';
import { useLazyIsVersementEligibleQuery } from 'services/requests/versement';
import { ProfessionalSituation } from 'store/referentiels/professionalSituation';
import { isNone, isNotNone } from 'utils/functions';

import { ProductActions } from './type';

type UseGetActionsInvestProps = {
	showAppointments?: boolean;
	onCalendly?: () => void;
};

const useGetActionsInvest = ({
	showAppointments = false,
	onCalendly,
}: UseGetActionsInvestProps): { actions: ProductActions[] } => {
	const navigate = useNavigate();
	const { data: contracts } = useGetContractsQuery();
	const { data: subs } = useGetAllSubscriptionsQuery();
	const { data: kyc } = useGetKYCQuery();
	const { data: simulation } = useGetInvestSimulationQuery();

	const [getVersementEligibility] = useLazyIsVersementEligibleQuery();

	// if user has a simulation but already has other investements, propose to reset simulation
	const eraseSimulation = useMemo(
		() => isNotNone(simulation) && !!subs?.length && !!contracts?.length,
		[contracts?.length, simulation, subs?.length],
	);

	const canUserOpenPer = useCallback(
		() =>
			kyc?.professionalSituation !== ProfessionalSituation.RETIRED &&
			!contracts?.find((c) => c.envelope.type === 'PER'),
		[contracts, kyc?.professionalSituation],
	);

	const [isVersementAvailable, setIsVersementAvailable] = useState<boolean>(false);

	// is any versement available on any contract ?
	useEffect(() => {
		if (isNone(contracts)) {
			setIsVersementAvailable(false);
			return;
		}

		const promises = contracts
			// is any versement available on this contract
			.map((c) => [
				getVersementEligibility({
					contractId: c.id,
					type: 'COMPLEMENTAIRE',
				}),
				getVersementEligibility({
					contractId: c.id,
					type: 'PROGRAMME',
				}),
			])
			.flat()
			.map((RTKPromises) => RTKPromises.unwrap());

		// do all calls asynchronously
		Promise.all(promises).then((operationsAvailability) => {
			setIsVersementAvailable(operationsAvailability.some((op) => op.eligibility === 'ELIGIBLE'));
		});
	}, [contracts, getVersementEligibility]);

	const state = useMemo(
		() =>
			[
				{
					label: 'Faire une simulation',
					onClick: () => navigate('/onboarding', { state: { eraseSimulation } }),
					isAvailable: true,
				},
				{
					label: 'Ouvrir une Assurance vie',
					onClick: () => navigate('/invest/ouverture-assurance-vie'),
					isAvailable: true,
				},
				{
					label: 'Ouvrir un PER',
					onClick: () => navigate('/invest/ouverture-per-individuel'),
					isAvailable: canUserOpenPer(),
				},
				{
					label: 'Faire un versement',
					onClick: () => navigate('/invest/versements?open=nouveau-versement'),
					isAvailable: isVersementAvailable,
				},
				{
					label: 'Prendre rendez-vous avec un conseiller',
					onClick: onCalendly ?? (() => {}),
					isAvailable: showAppointments,
				},
			]
				.filter((o) => o.isAvailable)
				.map((o) => ({ label: o.label, onClick: o.onClick })),
		[canUserOpenPer, eraseSimulation, isVersementAvailable, navigate, onCalendly, showAppointments],
	);

	return { actions: state };
};

export default useGetActionsInvest;
