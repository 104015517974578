import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Button,
	Card,
	Center,
	Heading,
	HStack,
	Icon,
	Image,
	ListItem,
	Text,
	UnorderedList,
	useBoolean,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { DotWave } from '@uiball/loaders';

import { DocumentIcon } from 'assets/icons/contract/ContractIcons';
import SignIcon from 'assets/phoenix/sign.svg';
import CustomModal from 'components/Modal';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import {
	useGenerateBulletinSignatureMutation,
	useGetSavingsAccountQuery,
	useSignSavingsAccountMutation,
} from 'services/requests/savingsAccounts';
import { useGetUserQuery } from 'services/requests/user';
import { SavingsAccountType, savingsAccountTypeLabels } from 'store/types/savingsAccount.type';
import textStyles from 'theme/foundations/typography';

const Signature = (): JSX.Element => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [isSigned, { on: setIsSigned }] = useBoolean();
	const toast = useThemedToast();

	const { isOpen: isSignModalOpen, onClose: onSignModalClose, onOpen: onSignModalOpen } = useDisclosure();
	const [triggerGenerateBulletinSignature, { data: bulletin, isLoading: isSignatureGenerationLoading }] =
		useGenerateBulletinSignatureMutation();
	const { data: savingsAccountInformation } = useGetSavingsAccountQuery(id!);
	const { data: kyc } = useGetUserQuery();
	const [triggerSignSavingsAccount] = useSignSavingsAccountMutation();

	const handleGenerateBulletinSignature = () => {
		if (savingsAccountInformation) {
			onSignModalOpen();
			triggerGenerateBulletinSignature(id!)
				.unwrap()
				.catch(() => {
					toast({
						title:
							"Nous n'avons pas pu générer votre bulletin de souscription. Veuillez vous assurer de bien avoir rempli toutes vos informations personnelles.",
						description: (
							<Text cursor="pointer" onClick={() => navigate(`/cash/onboarding/${id}/connaissance-client/etatCivil`)}>
								Vérifier mes informations
							</Text>
						),
						status: 'error',
						duration: 5000,
					});
					onSignModalClose();
				});
		}
	};

	// rediriger automatiquement sur la page de confirmation une fois le bulletin signé
	useEffect(() => {
		if (isSigned) {
			eventTracker.pipedream.savingsAccountSigned(savingsAccountInformation?.initialAUM);
			setTimeout(() => navigate(`/cash/onboarding/${id}/confirmation`), 500);
		}
	}, [id, isSigned, kyc?.email, navigate, savingsAccountInformation?.initialAUM, triggerSignSavingsAccount]);

	useEffect(() => {
		eventTracker.mixpanel.track('Livret Epargne - Signature');
		const handler = (e: MessageEvent<{ signature_request_id: string; type: string; event: string }>) => {
			if (e.origin === 'https://yousign.app' && e.data.type === 'yousign') {
				if (e.data.event === 'success' && e.data.signature_request_id === bulletin?.signature_request_id) {
					onSignModalClose();
					setIsSigned();
				}
				if (e.data.event === 'error') {
					toast({
						title: 'Erreur lors de la signature de votre document',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
					onSignModalClose();
				}
			}
		};
		window.addEventListener('message', handler);
		return () => window.removeEventListener('message', handler);
	}, [bulletin?.signature_request_id, onSignModalClose, setIsSigned, toast]);

	return (
		<VStack align="start" spacing="24px">
			<Heading variant="Title-XL-Bold">Signature des contrats</Heading>

			<Card size="sm">
				<VStack w="100%" spacing="16px">
					<HStack w="100%">
						<Center borderRadius="base" bg="blue.100" padding="12px">
							<Icon as={DocumentIcon} color="blue.900" />
						</Center>
						<Text variant="Title-S-Bold">{savingsAccountTypeLabels[SavingsAccountType.CER]}</Text>
					</HStack>

					<UnorderedList pl="20px" pb="8px" {...textStyles['Text-S-Regular']}>
						<ListItem>Questionnaire de souscription</ListItem>
						<ListItem>Demande d’ouverture</ListItem>
						<ListItem>Formulaire d’auto-certification FATCA/CRS</ListItem>
					</UnorderedList>
					<Button
						w="100%"
						size="lg"
						onClick={handleGenerateBulletinSignature}
						isLoading={isSignatureGenerationLoading}
						rightIcon={<Image src={SignIcon} h="20px" ml="16px" />}
					>
						Signer les documents
					</Button>
				</VStack>
			</Card>

			{/* Yousign modal */}
			<CustomModal
				size={{ md: '6xl', base: '6xl' }}
				contentProps={{ w: '100%', h: '80%' }}
				isOpen={isSignModalOpen}
				onClose={onSignModalClose}
			>
				{isSignatureGenerationLoading ? (
					<Center w="100%" h="100%" flexDirection="column">
						<Text mb="24px" fontSize="16px">
							Document(s) en cours de génération
						</Text>
						<DotWave size={70} />
					</Center>
				) : (
					<iframe
						style={{ border: 'none', margin: 0, padding: '20px 20px 0px 0px', width: '100%', height: '100%' }}
						// disable_domain_validation=true is ignored in production, only prupose is dev/staging
						src={`${bulletin?.signature_link}&disable_domain_validation=true`}
					/>
				)}
			</CustomModal>

			<BottomStepBar onPrev={() => navigate(`/cash/onboarding/${id}/souscription/justificatifs`)} showNext={false} />
		</VStack>
	);
};
export default Signature;
