import { CheckmarkFilled } from '@carbon/icons-react';
import { HStack, Icon, Text, VStack } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';

const CheckList = ({ elements }: { elements: string[] }): JSX.Element => {
	return (
		<VStack spacing="32px" align="start" py="30px">
			{elements.map((element) => (
				<HStack key={element}>
					<Icon as={CheckmarkFilled} color={colors.grey[900]} />
					<Text>{element}</Text>
				</HStack>
			))}
		</VStack>
	);
};

export default CheckList;
