import { Grid, GridItem, Heading, VStack } from '@chakra-ui/react';

import AffiliatesOffersCard from 'pages/Affiliates/Offers/AffiliatesOffersCard';

import { AffiliatesOffersCardContent } from './AffiliatesOffersCardTypes';
import offersData from './OffersData';

const AffiliatesOffers = (): JSX.Element => {
	return (
		<VStack align="start" w="100%" spacing="lg">
			<Heading variant="Title-L-SemiBold">Mes offres</Heading>
			<Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap="lg">
				{offersData.map((offer: AffiliatesOffersCardContent) => (
					<GridItem h="100%" key={offer.offerSubLine}>
						<AffiliatesOffersCard {...offer} />
					</GridItem>
				))}
			</Grid>
		</VStack>
	);
};

export default AffiliatesOffers;
