import { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { Box, Card, Heading, Text, VStack } from '@chakra-ui/react';

import { PESimulationResult } from 'services/requests/privateEquity/simulations';
import colors from 'theme/foundations/colors';
import { displayMoney, displayMoneyNoDigits } from 'utils/rendering';

const PrivateEquityGraphProjection = ({ data }: { data: PESimulationResult }) => {
	const labels = useMemo(() => data.projection.map((d) => d.year), [data]);
	const line = useMemo(() => data.projection.map((d) => ({ y: d.cumulativeCashflow, x: d.year })), [data]);
	const bars = useMemo(
		() =>
			data.projection.map((d) => ({
				y: d.cashflow,
				x: d.year,
				color: d.cashflow > 0 ? colors.blue[500] : colors.blue[100],
				hoverColor: d.cashflow > 0 ? colors.blue[500] : colors.blue[100],
			})),
		[data],
	);

	const isTaxReduction =
		data.projection &&
		data.projection[1] &&
		data.projection[1].cashflow > 0 &&
		data.projection[1].cashflow === data.taxReduction;

	return (
		<Card w="100%">
			<VStack align="start" w="100%" spacing="16px">
				<Heading variant="Title-M-SemiBold">Projection de votre projet</Heading>
				<Text color="grey.900">
					Ce graphique présente l’évolution de votre trésorerie pendant la durée de vie de votre investissement.
					<br />
					{isTaxReduction && (
						<>
							Certains de vos fonds étant des FCPI, ils sont éligibles à une réduction d’impôt de 25% du montant
							investi, et vous permettent donc <b>d’économiser {displayMoneyNoDigits(data.taxReduction)} d’impôt</b>{' '}
							lors de la deuxième année.
						</>
					)}
				</Text>

				<Box w="100%" h="400px">
					<Chart
						type="line"
						data={{
							labels,
							datasets: [
								{
									type: 'line',
									label: 'Cashflow cumulé',
									data: line,
									// borderColor: 'rgb(255, 99, 132)',
									// backgroundColor: 'rgba(255, 99, 132, 0.5)',
									backgroundColor: colors.yellow[500],
									borderColor: colors.yellow[500],
									cubicInterpolationMode: 'monotone',
									pointRadius: 0,
									tension: 0.1,
								},
								{
									type: 'bar',
									label: 'Cashflow annuel',
									data: bars,
									borderColor: bars.map((b) => b.color),
									backgroundColor: bars.map((b) => b.color),
									hoverBackgroundColor: bars.map((b) => b.hoverColor),
								},
							],
						}}
						plugins={[
							{
								id: 'indexBar',
								afterDraw: (chart) => {
									if (chart.tooltip && chart.tooltip.getActiveElements().length > 0) {
										const x = chart.tooltip.getActiveElements()[0].element.x;
										const yAxis = chart.scales.y;
										const ctx = chart.ctx;
										ctx.save();
										ctx.beginPath();
										ctx.moveTo(x, yAxis.top);
										ctx.lineTo(x, yAxis.bottom);
										ctx.lineWidth = 1;

										ctx.strokeStyle = colors.grey[300];
										ctx.stroke();
										ctx.restore();
									}
								},
							},
							{
								id: 'legendColor',
								beforeDraw: (chart) => {
									const annualCashflowLegend = chart.legend?.legendItems?.find((l) => l.text === 'Cashflow annuel');
									if (annualCashflowLegend) annualCashflowLegend.fillStyle = colors.blue[500];
								},
							},
						]}
						options={{
							hover: {
								mode: 'index',
								intersect: false,
							},
							plugins: {
								legend: {
									position: 'top',
									align: 'end',
								},
								tooltip: {
									mode: 'nearest',
									axis: 'x',
									intersect: false,
									backgroundColor: 'white',
									bodyColor: colors.grey[700],
									titleColor: colors.grey[700],
									borderColor: colors.grey[300],
									borderWidth: 1,

									titleFont: {
										size: 16,
									},
									bodyFont: {
										size: 14,
									},
									callbacks: {
										labelColor: (t) => {
											if (t.datasetIndex === 0)
												return { backgroundColor: colors.yellow[500], borderColor: 'rgba(0, 0,0,0)' };
											return { backgroundColor: colors.blue[500], borderColor: 'rgba(0, 0,0,0)' };
										},
										labelTextColor: (t) => {
											if (t.datasetIndex === 0) return colors.grey[700];
											return t.parsed.y < 0 ? colors.red[500] : t.parsed.y === 0 ? colors.grey[700] : colors.green[500];
										},
										label: (dataPoint) =>
											`${dataPoint.dataset.label}:  ${(dataPoint.raw as { y: number }).y > 0 ? '+' : ''} ${displayMoney(
												(dataPoint.raw as { y: number }).y,
											)}`,
										// beforeBody: () => 'Performances',
										// label: (dataPoint) => `${Number(((dataPoint.raw as number) * 100).toFixed(2))}%`,
									},
								},
							},

							scales: {
								x: {
									grid: {
										display: false,
									},
								},
								y: {
									grid: {
										borderDash: [5, 4],
									},
									ticks: {
										color: colors.grey[700],
										font: {
											family: 'Poppins',
											size: 13,
										},
										callback: (value) => {
											return `${+value} €`;
										},
									},
								},
							},
							responsive: true,
							maintainAspectRatio: false,
						}}
					/>
				</Box>
				<Text color="grey.900">
					Les projections présentées sont basées sur les performances historiques des fonds. Ces investissements peuvent
					présenter des risques de perte en capital et les rendements ne sont pas garantis.
					<br />
					La fréquence et le montant des appels de fonds / distributions peuvent varier dans le temps.
				</Text>
			</VStack>
		</Card>
	);
};

export default PrivateEquityGraphProjection;
