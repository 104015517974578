import { FC } from 'react';
import { Document, Gamification, Product } from '@carbon/icons-react';
import { Heading, Skeleton, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import InvestmentMetricCard from 'components/Metrics/InvestmentMetrics/Card';
import { useGetImpositionFiscaliteQuery } from 'services/requests/fiscalite';
import { displayMoneyNoDigits, displayPercentage } from 'utils/rendering';

const GeneraKPISlInfo: FC = () => {
	const { data, isFetching, isError } = useGetImpositionFiscaliteQuery();

	if (isFetching) return <Skeleton minH="150px" w="100%" />;
	if (isError) return null;

	return (
		<VStack w="100%" spacing="lg" align="start">
			<Heading variant="Title-L-SemiBold">Ma fiscalité sur l'année en cours</Heading>
			<Wrap w="100%">
				<WrapItem flex="1">
					<InvestmentMetricCard icon={<Gamification size="32" />} title="Défiscalisation totale">
						<Heading variant="Title-L-Bold" color="informative.900">
							{displayMoneyNoDigits(
								(data?.taxDataPerFiscalProduct.FCPI.taxBenefit ?? 0) +
									(data?.taxDataPerFiscalProduct.PER.taxBenefit ?? 0),
							)}
						</Heading>
					</InvestmentMetricCard>
				</WrapItem>
				{data?.detailsImpositionIr && (
					<WrapItem flex="1">
						<InvestmentMetricCard icon={<Document size="32" />} title="Estimation de l'impôt sur le revenu">
							<Heading variant="Title-L-Bold" color="negative.900">
								{displayMoneyNoDigits(data.detailsImpositionIr.ir)}
							</Heading>
						</InvestmentMetricCard>
					</WrapItem>
				)}
				{data?.tauxPrelevementSource && (
					<WrapItem flex="1">
						<InvestmentMetricCard icon={<Product size="32" />} title="Taux de prélèvement à la source">
							<Heading variant="Title-L-Bold" color="primary.black">
								{displayPercentage(data.tauxPrelevementSource)}
							</Heading>
						</InvestmentMetricCard>
					</WrapItem>
				)}
			</Wrap>
		</VStack>
	);
};
export default GeneraKPISlInfo;
