import { backendApi } from 'services/apiService';
import { CustomAsset } from 'store/types/investment.type';

type ContractIdDto = {
	id: string;
};

export type SwitchEliteInformationsResponse = {
	MINIMAL_AMOUNT: number;
	RECOMMENDED_AMOUNT: number;
};

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		switchEliteInformations: builder.query<SwitchEliteInformationsResponse, ContractIdDto>({
			query: ({ id }) => ({
				url: `/switch-elite/${id}/informations`,
			}),
		}),
		switchEliteRepartition: builder.query<CustomAsset[], ContractIdDto & { inflow: number }>({
			query: ({ id, inflow }) => ({
				url: `/switch-elite/${id}/repartition`,
				params: { inflowAmount: inflow },
			}),
		}),
		switchEliteRequest: builder.query<number, ContractIdDto & { inflow: number; bankAccountId: string }>({
			query: ({ id, inflow, bankAccountId }) => ({
				url: `/switch-elite/${id}/request`,
				params: { inflowAmount: inflow, bankAccountId },
			}),
		}),
	}),
	overrideExisting: true,
});

export const { useSwitchEliteInformationsQuery, useSwitchEliteRepartitionQuery, useSwitchEliteRequestQuery } =
	extendedApi;
