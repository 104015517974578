import { SerializedError } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { RootState } from 'store/store';
import { backendConstants } from 'utils/constants';

const apiBase = fetchBaseQuery({
	baseUrl: backendConstants.backendUrl,
	prepareHeaders: (headers, { getState }) => {
		const { token } = (getState() as RootState).auth;
		if (token) headers.set('Authorization', `Bearer ${token}`);
		return headers;
	},
});

export type FetchBaseQueryErrorType = { status: number; data: { message: string } };
export const isFetchBaseQueryErrorType = (err: FetchBaseQueryError | SerializedError): err is FetchBaseQueryErrorType =>
	'status' in err;

export const backendApi = createApi({
	tagTypes: [
		'User',
		'BankAccount',
		'Product',
		'KYC',

		'SCPI',
		'SCPI-KYC',
		'SCPI-KYC-TEST',
		'SCPI_SUBSCRIPTIONS',
		'RealEstateSimulation',
		'PE',
		'PE-KYC',
		'PE-KYC-TEST',
		'PE_SUBSCRIPTIONS',
		'PE-SCPI-KYC',
		'PrivateEquitySimulations',

		// Invest
		'InvestSimulations',
		'InvestContracts',
		'InvestSubscriptions',

		// Communication
		'Notification',

		'KYC/Justificatif',
		'Onboarding/AvailableEnvelopes',
		'Iroko',
		'Holding',
		'Versement',
		'SponsorShip',
		'Operations',
		'Eligiblity/arbitrage',
		'Fiscalite',
		'Fiscalite/Imposition',
		'Fiscalite/UpdatedRepartition',
		'Fiscalite/History',
		'Fiscalite/Optimization',
		'Proposals',
		'SavingsAccounts',
	],
	reducerPath: 'backendApi',
	baseQuery: apiBase,
	endpoints: () => ({}),
});
