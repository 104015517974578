import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { Heading, VStack } from '@chakra-ui/react';

import { CashFund, OpenedFund } from 'components/cards/funds/cash/OpenedFund';
import FullscreenLoader from 'components/FullscreenLoader';
import InvestmentMetrics from 'components/Metrics/InvestmentMetrics';
import { useProducts } from 'hooks/useProducts';
import { useGetAllSavingsAccountsQuery } from 'services/requests/savingsAccounts';
import { SavingsAccountWithHistory } from 'store/types/savingsAccount.type';
import { isNone, isNotNone } from 'utils/functions';

type RequireKeys<T extends object, K extends keyof T> = Required<Pick<T, K>> & Omit<T, K>;

const Dashboard = (): JSX.Element => {
	const { savings } = useProducts();
	const { data: savingsAccounts, isFetching: isSavingsAccountsQueryFetching } = useGetAllSavingsAccountsQuery();

	const dataWithHistory = useMemo(() => {
		if (isNone(savingsAccounts)) return [];
		return savingsAccounts.filter(
			(savingsAccount) => isNotNone(savingsAccount.history) && savingsAccount.history.length > 0,
		);
	}, [savingsAccounts]);

	const tableData = useMemo<CashFund[]>(() => {
		return (
			dataWithHistory.filter((sa) => isNotNone(sa.history) && sa.history.length > 0) as RequireKeys<
				SavingsAccountWithHistory,
				'history'
			>[]
		).map((account) => {
			return {
				id: account.id,
				type: account.type,
				provider: account.provider,
				lastValuationDate: account.history.at(-1)?.date ?? new Date().toISOString(),
				performance: account.performance,
				valuation: +(account.history.at(-1)?.estimatedValuation ?? 0),
			};
		});
	}, [dataWithHistory]);

	// used later for graph
	// Dernière valorisation le {maxDate.toLocaleDateString('fr-FR', { dateStyle: 'long' })}
	// const maxDate = useMemo(
	// 	() =>
	// 		new Date(
	// 			savingsAccounts
	// 				? Math.max(
	// 						...savingsAccounts.map((savingsAccount) =>
	// 							new Date(savingsAccount.history.at(-1)?.date ?? new Date()).getTime(),
	// 						),
	// 				  )
	// 				: 0,
	// 		),
	// 	[savingsAccounts],
	// );

	if (savings.isLoading || isSavingsAccountsQueryFetching) return <FullscreenLoader />;
	if (!savings.isOpen) return <Navigate replace to="/cash" />;

	return (
		<VStack w="100%" spacing="32px" align="start">
			<Heading variant="Title-L-SemiBold">Votre tableau de bord</Heading>
			{savings.data && <InvestmentMetrics data={savings.data} />}

			<VStack w="100%" spacing="16px" align="start">
				{tableData.map((s) => (
					<OpenedFund key={s.id} subscription={s} />
				))}
			</VStack>
		</VStack>
	);
};

export default Dashboard;
