import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'hooks/useStore';
import {
	hasCompletedKycCoordonnees,
	hasCompletedKycEtatCivil,
	hasCompletedKycFoyerFiscal,
	hasCompletedKycJustificatif,
	hasCompletedKycPatrimoine,
	hasCompletedKycProfessionnelle,
	KYCStepType,
} from 'onboarding/KYC/utils';
import { setLastInnerStepAvailable } from 'onboarding/Stepper/stepper.slice';
import { useGetKYCJustificatifQuery, useGetKYCQuery } from 'services/requests/kyc';

export const useKycOnboardingNavigate = (steps: KYCStepType[], redirectOnEnd: string) => {
	const dispatch = useAppDispatch();
	const { data: kyc } = useGetKYCQuery();
	const { data: statusPj } = useGetKYCJustificatifQuery();
	const [currentStep, setCurrentStep] = useState<string>();
	const navigate = useNavigate();

	const mostAdvancedStep = useMemo((): KYCStepType | undefined => {
		if (!kyc) return undefined;

		if (!hasCompletedKycEtatCivil(kyc)) return 'etatCivil';
		if (!hasCompletedKycProfessionnelle(kyc)) return 'professionelle';
		if (!hasCompletedKycFoyerFiscal(kyc)) return 'foyerFiscal';
		if (!hasCompletedKycCoordonnees(kyc)) return 'coordonnees';
		if (!hasCompletedKycPatrimoine(kyc)) return 'patrimoine';
		if (!hasCompletedKycJustificatif(kyc, statusPj)) return 'justificatif';

		return 'recapitulatif';
	}, [kyc, statusPj]);

	useEffect(() => {
		const indexLastStepCompleted = steps.findIndex((step) => step === mostAdvancedStep) - 1;

		if (indexLastStepCompleted >= 0 && indexLastStepCompleted < steps.length - 1) {
			dispatch(setLastInnerStepAvailable(steps[indexLastStepCompleted + 1]));
		} else if (indexLastStepCompleted === steps.length - 1) {
			dispatch(setLastInnerStepAvailable(steps[indexLastStepCompleted]));
		} else if (indexLastStepCompleted < 0) {
			// Dirty fix, introduction should not be in the innerstep list
			dispatch(steps[0] === 'introduction' ? setLastInnerStepAvailable(steps[1]) : setLastInnerStepAvailable(steps[0]));
		}
	}, [dispatch, mostAdvancedStep, steps]);

	const goToNextStep = useCallback(() => {
		const indexCurrentStep = steps.findIndex((step) => step === currentStep);
		if (indexCurrentStep >= 0 && indexCurrentStep < steps.length - 1) {
			navigate(steps[indexCurrentStep + 1]);
		} else if (indexCurrentStep === steps.length - 1) {
			navigate(redirectOnEnd);
		}
	}, [steps, navigate, redirectOnEnd, currentStep]);

	const goToPreviousStep = useCallback(() => {
		const indexCurrentStep = steps.findIndex((step) => step === currentStep);
		if (indexCurrentStep > 1 && indexCurrentStep <= steps.length - 1) {
			navigate(steps[indexCurrentStep - 1]);
		} else if (indexCurrentStep === 1) {
			// introduction
			navigate('');
		}
	}, [steps, navigate, currentStep]);

	const StateWrapper = ({ step }: { step: KYCStepType }): JSX.Element => {
		useEffect(() => {
			setCurrentStep(step);
		}, [step]);

		return <Outlet />;
	};

	return {
		currentStep,
		goToNextStep,
		goToPreviousStep,
		StateWrapper,
		setCurrentStep,
	};
};
