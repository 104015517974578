export type AirtableAttachment = {
	id: string;
	size: number;
	type: string;
	filename: string;
	url: string;
	width?: number;
	height?: number;
	thumbnails?: {
		small: {
			url: string;
			width?: number;
			height?: number;
		};
		large: {
			url: string;
			width?: number;
			height?: number;
		};
	};
};

export type Fund = {
	id: string;
	name: string;
	description: string;
	partner: string;
	sectors?: string[];
	regions: string[];
	typeformUrl: string;

	// coverPicture: string;
	// dici?: string;
	// flyer?: string;
	fundCover: AirtableAttachment[];
	fundDICI?: AirtableAttachment[];
	fundFlyer?: AirtableAttachment[];

	FundType?: string;
	InvestorCategory?: 'INDIVIDUAL' | 'PROFESSIONAL' | 'ASSIMILTED_PROFESSIONAL';
};

export type PEFund = Fund & {
	strategy?: string[];
	reinvestTaxAdvantage: boolean;
	taxReduction?: number;
	gainsTaxAdvantage: boolean;
	revenueTaxAdvantage: boolean;
	subscriptions?: (PrivateEquitySubscription | string)[];
	DetailsAvailable: boolean;
	fundOpen: string;
	FundType?: string;
	subscriptionEndDate?: string;
	lastShareValuation?: number;
	ticket: number;
};

export type PEFundDetails = {
	Carry: number;
	portfolioComposition: AirtableAttachment[];
	fraisDeGestion: number;
	fundDescription: string;
	geoAutres: number;
	geoEurope: number;
	geoFrance: number;
	geoNorthAmerica: number;
	hurdle: string;
	id: string;
	managementTeamDescription: string;
	name: string;
	performance: string;
	performanceDescription: string;
	performanceTooltip: string;
	performanceType: string;
	ramifyOpinion: string;
	sectors?: string[];
	strategies?: string[];
	strategyBuyout: number;
	strategyGrowthEquity: number;
	strategyImpact: number;
	strategyPrivateDebt: number;
	strategyRealEstate: number;
	strategySecondaries: number;
	strategyVC: number;
	structureLégale: string;
	tailleCible: number;
	hurdleTooltip?: string;
	CarryTooltip?: string;
	imgPortfolioCompositionTooltip?: string;
	ticketTooltip?: string;
};

export type PEFundTimeline = {
	Index: string;
	Période: string;
	id: string;
	name: string;
}[];

export type SCPIFund = Fund & {
	isCapitalVariable: string;
	tenurePeriod: string;
	distributionRate: number;
	entranceFees: number;
	label: string;
	lastShareValuation: number;
	subscriptions?: SCPISubscription | string;
	tags?: string[];
	expectedDistributionRate?: number;
	distributionRateYear?: number;
	meanRevalorisation?: number;
	ramifyOpinion?: string;
	aum?: number;
	numberOfAssets?: number;
	financialOccupancyRate?: number;
	reconstitutionPricePerShare?: number;
	lastValuationDate?: Date;
	exitPrice?: number;
	minPPShares: number;
	minNPShares: number;
};

export type SCPIFundHistory = {
	prix?: number;
	Index: string;
	date?: string;
	dividende?: number;
};

export type SCPIFundPatrimoine = {
	secteurSanté: number;
	secteurRésidentiel: number;
	secteurCommerces: number;
	secteurBureaux: number;
	secteurLogistique: number;
	"secteurLocaux d'activité": number;
	secteurHôtels: number;
	secteurAutre: number;

	geoIdf: number;
	geoEtranger: number;
	geoRégions: number;
};

export type SCPIFundDemembrement = Record<string, number>;

type Document = {
	id: string;
	url: string;
	filename: string;
	type: string;
}[];

export enum FundSubscriptionStatus {
	REQUESTED = 'REQUESTED',
	PENDING = 'PENDING',
	CONTRACT_SENT = 'CONTRACT SENT',
	SIGNED = 'SIGNED',
	RECEIVED_FUNDS = 'RECEIVED FUNDS',
	REGISTERED = 'REGISTERED',
	COMPLETED = 'COMPLETED',
}

export type FundSubscription = {
	id: string;
	ownerEmail: string;
	status: FundSubscriptionStatus;
	amountVersement: number;
	nbShare: number;
	valuation: number;
	legalEntity: 'PHYSIQUE' | 'MORALE';
	objectives: (
		| 'ADDITIONNAL_REVENUE'
		| 'FISCAL_OPTIMISATION'
		| 'PATRIMONY_CONSTITUTION'
		| 'PATRIMONY_DIVERSIFICATION'
		| 'PATRIMONY_TRANSMITION'
		| 'OTHER'
	)[];
	bankAccountId?: string;
	fundsOrigin?: 'ofRevenusPro' | 'ofSaving' | 'ofVente' | 'ofCessionActifs' | 'ofSuccessionDonation';
	createdAt: Date;
	signedAt?: Date;
	effectiveDate?: Date;
	registeredAt?: Date;
	fundName: string;
	fundPartnerName?: string;
	fundCoverPicture?: string;
	lastValuationDate?: string;
	attestationDeParts?: Document;
	fundOriginDocument?: Document;
	bulletinSigned?: Document;
	surveySigned?: Document;
	imprimeFiscal?: Document;
	trimestrialReport?: { period: string; report?: Document }[];
	WinLoss?: number;
	Performance?: number;
};

export type PrivateEquitySubscription = FundSubscription & {
	fund?: PEFund | string;
	fundType?: string[];
	fundsOriginGeography?: 'France' | 'Other';
};

export type SCPISubscription = FundSubscription & {
	projectType: 'NUE_PROPRIETE' | 'PLEINE_PROPRIETE';
	initialPriceShare?: number;
	fund?: SCPIFund | string;
	fundShareValuation?: string;
	fundShareLastValuationDate?: string;
	lastShareValuation?: string;
	demembrementDuration?: number;
	demembrementSigned?: Document;
	horizon?: Date;
};

export const privateEquitySectorsOptions = [
	'Santé',
	'Consommation',
	'Services',
	'Technologie et Digital',
	'Industrie',
	'Multi-Secteurs',
];

export type CPM = 'Olivier' | 'Samy' | 'Amélie' | 'Virgile' |  'Arnaud' | 'Mehdi' | 'Hugo';

export type Asset = 'Actions' | 'Immobilier' | 'Obligations' | 'Fonds euro' | 'Private Equity';

export type ProposalDTO = {
	id: string;
	createdAt: Date;
	ownerEmail: string;
	CPM: CPM;
	estimatedDistributionRate: number;
	assets: Asset[];
	message: string;
	notionURL: string;
	SCPISubscriptionsIds: string[];
	PESubscriptionsIds: string[];
	investSubscriptionsIds: string[];
	cashSubscriptionsIds: string[];
};

export type BannerFace =
	| 'samy'
	| 'aymane'
	| 'amelie'
	| 'olivier'
	| 'nils'

	| 'arnaud'
	| 'mehdi'
	| 'hugo'
	| 'nicolas'
	| 'dario';
export type BannerType = 'operationnel' | 'information' | 'commercial' | 'investissement';
export type BannerCTAType = 'navigate' | 'href' | 'pipedream' | 'calendly' | 'none';

export type Banner = {
	url: string;
	name: string;
	show: boolean;
	face: BannerFace;
	type: BannerType;
	title: string;
	description: string;
	CTAText: string;
	CTALink: string;
	CTAType: BannerCTAType;
};

export type MonthlyOffreBlackType = {
	image: string;
	partnerName: string;
	fundName: string;
	description: string;
	valableUntil: Date;
	geo: string;
	strategy: string;
	triCible: string;
	secteur: string;
	link: string;
	show: boolean;
};
