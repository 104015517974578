import { Card, Heading, Text, VStack } from '@chakra-ui/react';

import { AffiliatesOffersCardContent } from './AffiliatesOffersCardTypes';

const AffiliatesOffersCardDesktop = (offer: AffiliatesOffersCardContent): JSX.Element => {
	return (
		<Card h="100%">
			<VStack w="100%" align="start" spacing="md">
				<VStack align="start" spacing="0">
					<Heading variant="Title-XL-SemiBold" color="informative.900">
						{offer.offer}
					</Heading>
					<Heading variant="Title-S-SemiBold">{offer.offerSubLine}</Heading>
				</VStack>
				<Card w="100%" size="sm">
					<Text variant="title">{offer.title}</Text>
				</Card>
				<Text variant="Text-M-Regular" color="grey.900">
					{offer.description}
				</Text>
			</VStack>
		</Card>
	);
};

export default AffiliatesOffersCardDesktop;
