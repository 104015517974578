import { SCPISortOptionType } from 'components/filter/SortingSelect';
import { SCPIFund } from 'store/types/airtable.type';

export const scpiSortOption: Record<string, SCPISortOptionType> = {
	distrib: {
		key: 'distrib',
		label: 'Distribution (croissant)',
		fnct: (a: SCPIFund, b: SCPIFund) => (a.distributionRate < b.distributionRate ? -1 : 1),
	},
	revDistrib: {
		key: 'revDistrib',
		label: 'Distribution (décroissant)',
		fnct: (a: SCPIFund, b: SCPIFund) => (a.distributionRate > b.distributionRate ? -1 : 1),
	},
	price: {
		key: 'price',
		label: `Minimum d'achat (croissant)`,
		fnct: (a: SCPIFund, b: SCPIFund) => {
			const aTicket = a.lastShareValuation * a.minPPShares;
			const bTicket = b.lastShareValuation * b.minPPShares;

			return aTicket - bTicket;
		},
	},
	revPrice: {
		key: 'revPrice',
		label: `Minimum d'achat (décroissant)`,
		fnct: (a: SCPIFund, b: SCPIFund) => {
			const aTicket = a.lastShareValuation * a.minPPShares;
			const bTicket = b.lastShareValuation * b.minPPShares;

			return bTicket - aTicket;
		},
	},
};

// color is used for the tag border & font color
// name is used for the tag text
// display is used for the tag filter bar component
export const scpiTagsDisplay = {
	crédit: { color: 'yellow.700', display: 'Crédit disponible', name: 'Crédit' },
	ISR: { color: 'green.700', display: 'Investissement socialement responsable (ISR)', name: 'ISR' },
	cashback: { color: 'red.500', display: 'Remise', name: 'Remise' },
	fiscal: { color: 'blue.500', display: 'Avantage fiscal', name: 'Avantage fiscal' },
	'nue-proprieté': { color: 'grey.500', display: 'Disponible en nue-proprieté', name: 'Nue-propriété' },
	'versements programmés': {
		color: 'grey.500',
		display: 'Versements programmés disponibles',
		name: 'Versements programmés',
	},
} as Record<string, Record<'color' | 'display' | 'name', string>>;

export type CatalogNavigationStateType =
	| {
			fromDiscover: boolean | undefined;
	  }
	| undefined;

export const tagOptions = Object.keys(scpiTagsDisplay);
