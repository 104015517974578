import { useCallback, useState } from 'react';
import {
	Box,
	Button,
	Heading,
	HStack,
	ModalBody,
	ModalFooter,
	ModalHeader,
	PinInput,
	PinInputField,
	Skeleton,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Ring } from '@uiball/loaders';

import { ArrowButton } from 'components/Button/Button';
import ListCompositionPortfolio from 'components/ListCompositionPortfolio';
import { Stepper } from 'components/navigation/Stepper';
import { VersementEligibilityError } from 'components/versements/VersementEligibilityError';
import useThemedToast from 'hooks/useThemedToast';
import { CreateOVStepProps } from 'pages/Invest/Versements/CreateOptimizedVersement';
import eventTracker from 'services/events/eventTracker';
import {
	useCreateVersementMutation,
	useIsVersementEligibleQuery,
	useSignVersementMutation,
	VersementEligibility,
} from 'services/requests/versement';
import colors from 'theme/foundations/colors';
import { isNone, isNotNone } from 'utils/functions';
import { displayMoney, maskSensibleIban } from 'utils/rendering';

const Signature = ({
	watch,
	setValue,
	setStep,
	onClose,
	availableVersementContracts,
	contracts,
	onError,
}: CreateOVStepProps & { onError: () => void }): JSX.Element => {
	const toast = useThemedToast();
	const [savingType, amountAV, amountPER, bankInfo] = watch([
		'savingType',
		'amountAV',
		'amountPER',
		'bankInfo',
		'saving',
	]);

	const [code, setCode] = useState('');
	const selectedContract = contracts.find((contract) => contract.envelope.type === savingType);

	const [
		triggerCreateVP,
		{
			data: createdVersement,
			isLoading: isSmsRequestLoading,
			isSuccess: isSmsRequestSuccess,
			isError: isCreateVPError,
			isUninitialized: isCreateVPUninitialized,
		},
	] = useCreateVersementMutation();

	const [
		triggerSignVP,
		{ isLoading: isSignVPLoading, isError: isSignVPError, isUninitialized: isSignVPUninitialized },
	] = useSignVersementMutation();

	const handleSign = useCallback(() => {
		triggerSignVP({
			otp: code,
			versementId: createdVersement!.id,
		})
			.unwrap()
			.then(() => {
				eventTracker.mixpanel.updateVersementProgramme(savingType === 'AV' ? amountAV : amountPER);
				toast({
					status: 'success',
					title: 'Demande signée',
					description:
						"Votre demande d'ajout de versement programmé a été prise en compte, nous la traitons dans les plus brefs délais",
				});
				if (availableVersementContracts!.length - 1 === 0) onClose();
				else setStep('contract');
			})
			.catch((e) => {
				if ('status' in e && e.status === 400) {
					// incorrect code. Don't do anything and let JSX display the error so the user can enter new code
					return;
				}
				eventTracker.signVersementError({
					amount: savingType === 'AV' ? amountAV : amountPER,
					envelopeType: savingType,
					operationType: 'programme',
				});
				onError();
			});
	}, [
		amountAV,
		amountPER,
		availableVersementContracts,
		code,
		createdVersement,
		onClose,
		onError,
		savingType,
		setStep,
		toast,
		triggerSignVP,
	]);

	const recap: { label: string; value: string }[] = [
		{
			label: 'Contrat :',
			value: savingType === 'AV' ? 'Assurance vie' : 'PER individuel',
		},
		{
			label: 'Précédent montant (mensuel) :',
			value: displayMoney(0),
		},
		{
			label: 'Nouveau montant (mensuel) :',
			value: displayMoney(savingType === 'AV' ? amountAV : amountPER),
		},
		{
			label: 'Depuis le compte :',
			value: maskSensibleIban(bankInfo.IBAN),
		},
	];

	const { data: versementEligibility } = useIsVersementEligibleQuery(
		{
			contractId: selectedContract?.id ?? '',
			type: 'PROGRAMME',
		},
		{ skip: isNone(selectedContract) },
	);

	return (
		<>
			<ModalHeader p="0px">
				<Heading variant="Title-M-SemiBold">Mettre en place vos versements programmés</Heading>
			</ModalHeader>

			<ModalBody px="0px" py="32px">
				<VStack w="100%" align="start" spacing="32px">
					<Text color="grey.900">Vous allez procéder aux signatures afin de valider les versements programmés.</Text>
					<Stepper
						alignSelf="center"
						w="80%"
						steps={[{ title: 'Simulation' }, { title: 'Contrats' }, { title: 'Signature' }]}
						index={2}
					/>
					{!availableVersementContracts || !selectedContract ? (
						<Skeleton />
					) : (
						<>
							<VStack align="start">
								<HStack spacing="16px">
									<Button
										variant={savingType === 'AV' ? 'primary' : 'secondary'}
										isDisabled={!availableVersementContracts.includes('AV')}
										onClick={() => setValue('savingType', 'AV')}
									>
										Assurance Vie
									</Button>
									<Button
										variant={savingType === 'PER' ? 'primary' : 'secondary'}
										isDisabled={!availableVersementContracts.includes('PER')}
										onClick={() => setValue('savingType', 'PER')}
									>
										PER
									</Button>
								</HStack>
							</VStack>

							<VStack
								align="start"
								spacing="24px"
								bg="grey.100"
								p="16px 20px"
								border="base"
								borderRadius="base"
								w="100%"
							>
								<Text variant="label">Récapitulatif</Text>
								<VStack w="100%" spacing="16px">
									{recap.map(({ label, value }) => (
										<HStack key={label} w="100%" justify="space-between">
											<Text variant="detail">{label}</Text>
											<Text variant="label">{value}</Text>
										</HStack>
									))}
								</VStack>
							</VStack>

							<VersementEligibilityError versementEligibility={versementEligibility?.eligibility} />

							{(isCreateVPUninitialized || isCreateVPError) && (
								<>
									{isCreateVPError && (
										<Text fontWeight="medium" variant="body" color="red.500">
											Une erreur est survenue, nous ne pouvons pas modifier le versement programmé de votre contrat pour
											l'instant. Veuillez nous contacter pour plus d'informations.
										</Text>
									)}
									<Text variant="body">Une validation SMS est requise pour cette opération.</Text>
								</>
							)}

							{isSmsRequestSuccess && (
								<>
									<Box overflowX="hidden" w="100%">
										<VStack align="start" spacing="16px">
											<Text variant="label">Répartition de votre versement programmé :</Text>

											<ListCompositionPortfolio assets={createdVersement!.repartition} showPerformance={false} />
										</VStack>
									</Box>

									{(isSignVPUninitialized || isSignVPError) && (
										<>
											{isSignVPError && (
												<Text fontWeight="medium" variant="body" color="red.500">
													Une erreur est survenue, nous ne pouvons pas modifier le versement programmé de votre contrat
													pour l'instant. Veuillez nous contacter pour plus d'informations.
												</Text>
											)}
											<VStack align="start" spacing="16px">
												<Text variant="body">
													Veuillez confirmer le code à 6 chiffres qui vous a été envoyé par SMS&nbsp;:
												</Text>
												<HStack>
													<PinInput value={code} onChange={setCode} otp>
														<PinInputField />
														<PinInputField />
														<PinInputField />
														<PinInputField />
														<PinInputField />
														<PinInputField />
													</PinInput>
												</HStack>
												<VStack align="start">
													<Text>Vous n'avez pas reçu de code au bout d'une minute ?</Text>
													<Text
														textDecoration="underline"
														cursor="pointer"
														onClick={() =>
															window.Intercom('showNewMessage', 'Bonjour, je ne reçois pas mon code de validation SMS')
														}
													>
														Contactez nous via le chat
													</Text>
												</VStack>
											</VStack>
										</>
									)}
								</>
							)}
						</>
					)}
				</VStack>
			</ModalBody>

			<ModalFooter p="0px">
				<VStack w="100%" align="start" spacing="16px">
					<HStack w="100%" spacing="24px">
						<ArrowButton
							variant="secondary"
							size="lg"
							onClick={() => setStep('contract')}
							data-cy="cy-next-btn"
							type="button"
							w="50%"
							left
						>
							Retour
						</ArrowButton>

						{selectedContract && (isCreateVPUninitialized || isCreateVPError) && (
							<Button
								w="50%"
								isDisabled={
									isNotNone(versementEligibility) && versementEligibility.eligibility !== VersementEligibility.ELIGIBLE
								}
								type="button"
								variant="primary"
								size="lg"
								onClick={() =>
									triggerCreateVP({
										bankInfoId: bankInfo.id,
										contratId: selectedContract.id,
										montant: savingType === 'AV' ? amountAV : amountPER,
										programmed: true,
									})
								}
							>
								{isCreateVPError ? 'Réessayer' : 'Recevoir un code'}
							</Button>
						)}

						{selectedContract && isSmsRequestSuccess && (
							<>
								{(isSignVPUninitialized || isSignVPError) && (
									<ArrowButton
										variant="primary"
										isDisabled={
											code.length !== 6 ||
											(isNotNone(versementEligibility) &&
												versementEligibility.eligibility !== VersementEligibility.ELIGIBLE)
										}
										onClick={handleSign}
									>
										{isSignVPError ? 'Réessayer' : 'Valider le nouveau montant'}
									</ArrowButton>
								)}
							</>
						)}
					</HStack>

					{selectedContract && isSignVPLoading && (
						<HStack spacing="16px">
							<Ring size={24} color={colors.grey[700]} />
							<Text variant="label">
								Validation en cours
								<br /> Cette opération peut prendre plusieurs dizaines de secondes
							</Text>
						</HStack>
					)}

					{selectedContract && isSmsRequestLoading && (
						<HStack spacing="16px">
							<Ring size={24} color={colors.grey[700]} />
							<Text variant="label">
								Envoi du code sms
								<br /> Cette opération peut prendre plusieurs dizaines de secondes
							</Text>
						</HStack>
					)}
				</VStack>
			</ModalFooter>
		</>
	);
};

export default Signature;
