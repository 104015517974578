import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import textStyles from 'theme/foundations/typography';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const defaultTabsStyle = definePartsStyle({
	tablist: {
		borderBottom: `1px solid`,
		borderColor: 'grey.500',
	},
	tab: {
		...textStyles['Text-M-Regular'],
		_selected: {
			...textStyles['Text-M-Bold'],
			position: 'relative',
			_before: {
				content: '""',
				position: 'absolute',
				bottom: '0',
				width: '100%',
				height: '4px',
				background: 'primary.yellow',
			},
			color: 'grey.900',
		},
		whiteSpace: 'nowrap',
		color: 'grey.700',
	},
	root: {
		overflowX: 'auto',
	},
});

const tabsTheme = defineMultiStyleConfig({
	baseStyle: defaultTabsStyle,
	defaultProps: {
		variant: 'unstyled',
	},
});

export default tabsTheme;
