import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Card,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Image,
	Text,
	VStack,
} from '@chakra-ui/react';

import Valide from 'assets/icons/Valide.svg';
import Warning from 'assets/icons/Warning.svg';
import ControlledRadioOptions from 'components/inputs/RadioOptions';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useGetSCPIKYCTestQuery, useUpdateSCPIKYCTestMutation } from 'services/requests/realEstate/kyc';
import { UserScpiKycTest } from 'store/types/scpiKyc.type';
import scrollToErrorOnForm from 'utils/errors/FormScrollToError';
import { isNotNone } from 'utils/functions';

type FormData = UserScpiKycTest;

const investKnowledgeOptions = Object.entries({
	NO_KNOWLEDGE: 'Faible',
	SOME_KNOWLEDGE: 'Moyen',
	GOOD_KNOWLEDGE: 'Bon',
} as const).map(([value, label]) => ({ value, label }));

const investReturnPerceptionOptions = Object.entries({
	GARANTIED: 'Garanti',
	NOT_GARANTIED: 'Non garanti',
} as const).map(([value, label]) => ({ value, label }));

const investRevenusPerceptionOptions = Object.entries({
	FIXES: 'Fixes et invariables',
	VARIABLES: 'Variables notamment en fonction des loyers perçus',
} as const).map(([value, label]) => ({ value, label }));

const investRevenusLengthPerceptionOptions = Object.entries({
	DAYS: 'Quelques jours',
	WEEKS: 'Quelques semaines',
} as const).map(([value, label]) => ({ value, label }));

export const Test = (): JSX.Element => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const { data: scpiKycTest } = useGetSCPIKYCTestQuery();
	const [updateKycTest] = useUpdateSCPIKYCTestMutation();

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm<FormData>();

	const [
		investReturnPerception,
		investRevenusPerception,
		investRevenusLengthPerception,
		hasLongTermInvestmentConscience,
	] = watch([
		'investReturnPerception',
		'investRevenusPerception',
		'investRevenusLengthPerception',
		'hasLongTermInvestmentConscience',
	]);

	const isValidInvestReturnPerception = investReturnPerception === 'NOT_GARANTIED';
	const isValidInvestRevenusPerception = investRevenusPerception === 'VARIABLES';
	const isValidInvestRevenusLengthPerception = investRevenusLengthPerception === 'WEEKS';
	const isValidHasLongTermInvestmentConscience = hasLongTermInvestmentConscience === true;

	useEffect(() => {
		if (scpiKycTest) {
			if (isNotNone(scpiKycTest.agreeWithInvestment)) setValue('agreeWithInvestment', scpiKycTest.agreeWithInvestment);
			if (isNotNone(scpiKycTest.hasInvestedMoreThan100k))
				setValue('hasInvestedMoreThan100k', scpiKycTest.hasInvestedMoreThan100k);
			if (isNotNone(scpiKycTest.hasLongTermInvestmentConscience))
				setValue('hasLongTermInvestmentConscience', scpiKycTest.hasLongTermInvestmentConscience);
			if (isNotNone(scpiKycTest.hasRealEstateInvest)) setValue('hasRealEstateInvest', scpiKycTest.hasRealEstateInvest);
			if (isNotNone(scpiKycTest.hasSCPIShares)) setValue('hasSCPIShares', scpiKycTest.hasSCPIShares);
			if (isNotNone(scpiKycTest.investKnowledge)) setValue('investKnowledge', scpiKycTest.investKnowledge);
			if (isNotNone(scpiKycTest.investReturnPerception))
				setValue('investReturnPerception', scpiKycTest.investReturnPerception);
			if (isNotNone(scpiKycTest.investRevenusLengthPerception))
				setValue('investRevenusLengthPerception', scpiKycTest.investRevenusLengthPerception);
			if (isNotNone(scpiKycTest.investRevenusPerception))
				setValue('investRevenusPerception', scpiKycTest.investRevenusPerception);
		}
	}, [setValue, scpiKycTest]);

	const onSubmit = handleSubmit(
		(data: FormData) => {
			updateKycTest(data)
				.unwrap()
				.then(() => {
					navigate(`/immobilier/onboarding/${id}/souscription`);
				})
				.catch(() =>
					toast({
						title: 'Une erreur est survenue',
						status: 'error',
						duration: 9000,
						isClosable: true,
					}),
				);
		},
		() => scrollToErrorOnForm(errors),
	);

	useEffect(() => {
		eventTracker.mixpanel.track("SCPI Onboarding - Test d'adéquation");
	}, []);

	return (
		<form onSubmit={onSubmit}>
			<VStack align="start" spacing="24px">
				<Heading variant="Title-XL-Bold">Test d’adéquation</Heading>

				<Text variant="Text-M-Regular" color="grey.900">
					Ces données sont confidentielles et nécessaires vis-à-vis de nos obligations réglementaires et en particulier
					concernant la lutte contre le blanchiment. Elles nous permettent, par ailleurs, de valider la bonne adéquation
					de votre souscription avec votre patrimoine et votre bonne information.
				</Text>

				<FormControl isInvalid={!!errors.investKnowledge}>
					<FormLabel>Votre niveau de connaissances en matière de placements financiers est plûtot</FormLabel>

					<ControlledRadioOptions
						options={investKnowledgeOptions}
						control={control}
						name="investKnowledge"
						w={onboardingInputWidth}
					/>

					{errors.investKnowledge?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
				</FormControl>

				<FormControl isInvalid={!!errors.hasRealEstateInvest}>
					<FormLabel>Avez-vous déjà réalisé des opérations de placements immobilier ?</FormLabel>

					<ControlledRadioOptions
						options="Yes-No"
						control={control}
						name="hasRealEstateInvest"
						w={onboardingInputWidth}
					/>

					{errors.hasRealEstateInvest?.type === 'required' && (
						<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
					)}
				</FormControl>

				<FormControl isInvalid={!!errors.hasSCPIShares}>
					<FormLabel>Possédez-vous déjà des parts de SCPI ?</FormLabel>

					<ControlledRadioOptions options="Yes-No" control={control} name="hasSCPIShares" w={onboardingInputWidth} />

					{errors.hasSCPIShares?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
				</FormControl>

				<FormControl isInvalid={!!errors.hasInvestedMoreThan100k}>
					<FormLabel>
						Sur un an glissant, vous avez investi une somme supérieure ou égale à 100 000 € en parts de SCPI (en une
						seule fois, ou en cumulé)*
					</FormLabel>

					<ControlledRadioOptions
						options="Yes-No"
						control={control}
						name="hasInvestedMoreThan100k"
						w={onboardingInputWidth}
					/>

					{errors.hasInvestedMoreThan100k?.type === 'required' && (
						<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
					)}
				</FormControl>

				<VStack align="start" spacing="16px">
					<FormControl isInvalid={!!errors.hasLongTermInvestmentConscience}>
						<FormLabel>
							L’investissement en SCPI étant un placement immobilier, avez-vous conscience que ce placement doit être
							envisagé sur le long terme ?
						</FormLabel>

						<ControlledRadioOptions
							name="hasLongTermInvestmentConscience"
							control={control}
							options="Yes-No"
							rules={{ validate: { required: (b) => isNotNone(b), goodChoice: (value) => value === true } }}
							w={onboardingInputWidth}
						/>

						{errors.hasLongTermInvestmentConscience?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
					</FormControl>
					{hasLongTermInvestmentConscience !== undefined && (
						<Card borderColor={isValidHasLongTermInvestmentConscience ? 'grey.300' : 'red.500'} borderWidth="1px">
							<HStack align="center" spacing="16px">
								<Image
									src={isValidHasLongTermInvestmentConscience ? Valide : Warning}
									alt={isValidHasLongTermInvestmentConscience ? 'correct' : 'incorrect'}
								/>

								<Text variant="label" fontWeight="400">
									Comme tout placement immobilier, il s’agit d’un&nbsp;
									<Text fontWeight="600" color="primary.black" as="span">
										investissement long terme.&nbsp;
									</Text>
									Nous vous recommandons une durée de placement de plus de 7 ans.
								</Text>
							</HStack>
						</Card>
					)}
				</VStack>

				{isValidHasLongTermInvestmentConscience && (
					<VStack align="start" spacing="16px">
						<FormControl isInvalid={!!errors.investReturnPerception}>
							<FormLabel>L’investissement en SCPI est un investissement dont le capital est</FormLabel>

							<ControlledRadioOptions
								options={investReturnPerceptionOptions}
								control={control}
								rules={{ required: true, validate: { goodChoice: (value) => !(value === 'GARANTIED') } }}
								name="investReturnPerception"
								w={onboardingInputWidth}
							/>

							{errors.investReturnPerception?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
						{investReturnPerception && (
							<Card borderColor={isValidInvestReturnPerception ? 'grey.300' : 'red.500'} borderWidth="1px">
								<HStack align="center" spacing="16px">
									<Image
										src={isValidInvestReturnPerception ? Valide : Warning}
										alt={isValidInvestReturnPerception ? 'correct' : 'incorrect'}
									/>

									<Text variant="label" fontWeight="400">
										Le capital investi&nbsp;
										<Text color="primary.black" as="span" fontWeight="600">
											n’est pas garanti :&nbsp;
										</Text>
										il est lié aux évolutions du marché de l'immobilier
									</Text>
								</HStack>
							</Card>
						)}
					</VStack>
				)}

				{isValidInvestReturnPerception && (
					<VStack align="start" spacing="16px">
						<FormControl isInvalid={!!errors.investRevenusPerception}>
							<FormLabel>Les revenus des SCPI sont des revenus</FormLabel>

							<ControlledRadioOptions
								name="investRevenusPerception"
								control={control}
								options={investRevenusPerceptionOptions}
								rules={{ required: true, validate: { goodChoice: (value) => !(value === 'FIXES') } }}
								w={onboardingInputWidth}
							/>

							{errors.investRevenusPerception?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
						{investRevenusPerception && (
							<Card borderColor={isValidInvestRevenusPerception ? 'grey.300' : 'red.500'} borderWidth="1px">
								<HStack align="center" spacing="16px">
									<Image
										src={isValidInvestRevenusPerception ? Valide : Warning}
										alt={isValidInvestRevenusPerception ? 'correct' : 'incorrect'}
									/>

									<Text variant="label" fontWeight="400">
										Les revenus&nbsp;
										<Text color="primary.black" as="span" fontWeight="600">
											ne sont pas garantis :&nbsp;
										</Text>
										ils sont liés au bon paiement des loyers par les locataires
									</Text>
								</HStack>
							</Card>
						)}
					</VStack>
				)}
				{isValidInvestRevenusPerception && (
					<VStack align="start" spacing="16px">
						<FormControl isInvalid={!!errors.investRevenusLengthPerception}>
							<FormLabel>
								Les SCPI investissent dans des biens immobiliers. Selon vous, combien de temps minimum faut-il pour
								revendre un placement immobilier ?
							</FormLabel>

							<ControlledRadioOptions
								name="investRevenusLengthPerception"
								control={control}
								options={investRevenusLengthPerceptionOptions}
								rules={{ required: true, validate: { goodChoice: (value) => !(value === 'DAYS') } }}
								w={onboardingInputWidth}
							/>

							{errors.investRevenusLengthPerception?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
						{investRevenusLengthPerception && (
							<Card borderColor={isValidInvestRevenusLengthPerception ? 'grey.300' : 'red.500'} borderWidth="1px">
								<HStack align="center" spacing="16px">
									<Image
										src={isValidInvestRevenusLengthPerception ? Valide : Warning}
										alt={isValidInvestRevenusLengthPerception ? 'correct' : 'incorrect'}
									/>

									<Text variant="label" fontWeight="400">
										Le placement en SCPI est plutôt &nbsp;
										<Text fontWeight="600" color="primary.black" as="span">
											illiquide &nbsp;
										</Text>
										et la revente dépendra du bon rachat de vos parts en SCPI
									</Text>
								</HStack>
							</Card>
						)}
					</VStack>
				)}

				{isValidInvestRevenusLengthPerception && isValidInvestRevenusPerception && (
					<VStack align="start" spacing="16px">
						<Text variant="Title-M-Bold">
							Estimez-vous qu’un placement SCPI est adapté à votre profil et à votre situation ?
						</Text>
						<Text variant="Text-S-Regular" color="grey.900">
							<br />
							Acheter des parts de SCPI est un investissement immobilier.
							<br />
							<br />
							Comme tout placement immobilier, il s’agit d’un investissement long terme.
							<br />
							Nous vous recommandons une durée de placement de plus de 7 ans.
							<br />
							Contrairement au Livret A par exemple, ce placement comporte des risques.
							<br />
							<br />
							Le capital investi et les revenus ne sont pas garantis : ils sont liés aux évolutions du marché de
							l'immobilier et au bon paiement des loyers par les locataires.
							<br />
							Enfin, comme tout placement rappelez-vous que les performances passées ne préjugent pas des performances
							futures.
							<br />
							<br />
						</Text>
						<Card>
							<FormControl isInvalid={!!errors.agreeWithInvestment}>
								<Controller
									name="agreeWithInvestment"
									control={control}
									rules={{ required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Checkbox onChange={onChange} isChecked={value} ref={ref}>
											<Text variant="Text-M-Bold">J’ai bien pris connaissance des informations ci-dessus</Text>
										</Checkbox>
									)}
								/>

								{errors.agreeWithInvestment?.type === 'required' && (
									<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
								)}
							</FormControl>
						</Card>
					</VStack>
				)}
			</VStack>
			<BottomStepBar
				onPrev={() => navigate(`/immobilier/onboarding/${id}/reglementaire/informations-produit`)}
				nextSubmit
			/>
		</form>
	);
};
