import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import colors from 'theme/foundations/colors';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const Input = defineMultiStyleConfig({
	variants: {
		default: {
			field: {
				border: `1px solid ${colors.grey[300]}`,
				_placeholder: { color: colors.grey[700] },
				_hover: {
					borderColor: colors.grey[900],
				},
				_focus: {
					borderColor: colors.primary.yellow,
				},
				_invalid: {
					borderColor: colors.negative[900],
				},
				_disabled: {
					backgroundColor: 'grey.300',
					cursor: 'not-allowed',
					_placeholder: {
						color: colors.primary.black,
					},
				},
			},
		},
	},
	sizes: {
		md: {
			field: {
				fontSize: '14px',
				height: '52px',
				borderRadius: 'sm',
			},
		},
	},
	defaultProps: {
		size: 'md',
		variant: 'default',
	},
});

export default Input;
