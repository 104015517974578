import { Box, Flex, HStack, Image, Skeleton, Text, VStack } from '@chakra-ui/react';

import LoadingObjective from 'assets/shapes/loadingObjective.svg';

type LoadingModalProps = {
	title: string;
	content: string;
	condition?: string;
};

const LoadingModal = ({ title, content, condition }: LoadingModalProps): JSX.Element => (
	<Flex
		borderRadius="base"
		zIndex="10"
		h="100%"
		w="100%"
		position="absolute"
		align="center"
		justify="center"
		bg="rgba(43, 61, 128, 0.2)"
	>
		<Flex
			direction={{ base: 'column-reverse', md: 'row', lg: 'row' }}
			bg="white"
			borderRadius="base"
			align="center"
			p="32px"
		>
			<Image
				src={LoadingObjective}
				w="48px"
				alt=""
				mr={{ base: '0px', md: '48px', lg: '48px' }}
				mt={{ base: '16px', md: '0px', lg: '0px' }}
			/>
			<Box>
				<Text fontWeight="semibold" mb="8px" fontSize="14px">
					{title}
				</Text>
				<Text opacity="0.56" fontSize="14px">
					{content}
				</Text>
				{condition && (
					<Text opacity="0.56" fontSize="14px" fontWeight="600">
						({condition})
					</Text>
				)}
			</Box>
		</Flex>
	</Flex>
);

const GraphLine = (): JSX.Element => (
	<Flex align="center">
		<Skeleton w="64px" h="12px" borderRadius="base" startColor="grey.300" endColor="grey.500" />
		<Box ml="16px" h="1px" w="100%" borderTop="dashed 1px" borderColor="grey.300" />
	</Flex>
);

const GraphColumn = ({ height, color }: { height: string; color: string }): JSX.Element => (
	<Flex direction="column-reverse">
		<Skeleton w="16px" h="16px" borderRadius="base" mt="16px" startColor="grey.300" endColor="grey.500" />
		<Box bg={color} w="16px" h={height} borderRadius="base" />
	</Flex>
);

const PlaceholderPerformances = ({ message }: { message: string }): JSX.Element => (
	<Box position="relative" w="100%">
		<LoadingModal
			title="L'historique de vos performances"
			content="Suivez l’évolution détaillé de votre épargne."
			condition={message}
		/>
		<VStack borderRadius="base" align="left" bg="white" p="32px" spacing="32px">
			<Flex justify="space-between" align="center">
				<Text fontWeight="500" fontSize="16px">
					Performances
				</Text>
				<HStack bg="grey.100" w="128px" p="16px" spacing="8px" borderRadius="base">
					<Skeleton w="64px" h="12px" borderRadius="base" startColor="grey.300" endColor="grey.500" />
					<Skeleton w="22px" h="12px" borderRadius="base" startColor="grey.300" endColor="grey.500" />
				</HStack>
			</Flex>
			<VStack position="relative" spacing="32px" pb="32px" align="left" overflow="hidden">
				<GraphLine />
				<GraphLine />
				<GraphLine />
				<GraphLine />
				<GraphLine />
				<HStack spacing="40px" align="bottom" position="absolute" bottom="0px" left="104px">
					<GraphColumn height="64px" color="red.500" />
					<GraphColumn height="104px" color="yellow.500" />
					<GraphColumn height="88px" color="red.500" />
					<GraphColumn height="112px" color="red.500" />
					<GraphColumn height="136px" color="yellow.500" />
					<GraphColumn height="120px" color="yellow.500" />
					<GraphColumn height="144px" color="yellow.500" />
					<GraphColumn height="120px" color="red.500" />
					<GraphColumn height="112px" color="yellow.500" />
					<GraphColumn height="144px" color="red.500" />
					<GraphColumn height="156px" color="red.500" />
					<GraphColumn height="132px" color="red.500" />
					<GraphColumn height="152px" color="yellow.500" />
					<GraphColumn height="168px" color="red.500" />
					<GraphColumn height="184px" color="red.500" />
				</HStack>
			</VStack>
		</VStack>
	</Box>
);

const LoadingPerformance = (): JSX.Element => <Skeleton w="100%" h="440px" color="grey.300" />;

export { LoadingPerformance, PlaceholderPerformances };
