import { TemperatureMax } from '@carbon/icons-react';
import { Badge, HStack, Image, Text } from '@chakra-ui/react';

import Leaf from 'assets/icons/Leaf.svg';
import { InvestFundType, InvestGreenTagType } from 'store/types/investment.type';
import colors from 'theme/foundations/colors';

type AssetBadgeProps = {
	bgGraph?: string;
	bg: string;
	borderColor: string;
	textColor: string;
	icon?: React.ReactNode;
};

type TagsFondsActifsType = InvestFundType | InvestGreenTagType | 'Cash' | 'Crypto' | 'Autres';
export const activeFundTags: Record<TagsFondsActifsType, AssetBadgeProps> = {
	Actions: {
		bgGraph: '#C9D5E8',
		bg: colors.blue[100],
		borderColor: colors.blue[500],
		textColor: colors.blue[900],
	},
	SCPI: {
		bgGraph: '#CFE2D9',
		bg: colors.positive[100],
		borderColor: colors.positive[500],
		textColor: colors.positive[900],
	},
	SCI: {
		bg: colors.positive[100],
		borderColor: colors.positive[500],
		textColor: colors.positive[900],
	},
	Obligations: {
		bgGraph: '#D2C6EC',
		bg: colors.purple[100],
		borderColor: colors.purple[500],
		textColor: colors.purple[900],
	},
	'Fonds Euros': {
		bgGraph: '#EFE1C3',
		bg: colors.yellow[100],
		borderColor: colors.yellow[500],
		textColor: colors.yellow[700],
	},
	'Private Equity': {
		bgGraph: '#FFD7C9',
		bg: colors.negative[100],
		borderColor: colors.negative[500],
		textColor: colors.negative[900],
	},
	Cash: {
		bg: colors.orange[100],
		borderColor: colors.orange[500],
		textColor: colors.orange[900],
	},
	Crypto: {
		bg: colors.turquoise[100],
		borderColor: colors.turquoise[500],
		textColor: colors.turquoise[900],
	},
	'Fonds de Trésorerie': {
		bg: colors.brown[100],
		borderColor: colors.brown[500],
		textColor: colors.brown[900],
	},
	'Produit Structuré': {
		bg: colors.brown[100],
		borderColor: colors.brown[500],
		textColor: colors.brown[900],
	},
	Autres: {
		bg: colors.grey[100],
		borderColor: colors.grey[500],
		textColor: colors.grey[900],
	},
	ISR: {
		bg: colors.primary.white,
		borderColor: colors.brand.RAMIFYGREEN,
		textColor: colors.brand.RAMIFYGREEN,
		icon: <Image src={Leaf} w="16px" />,
	},
	ESG: {
		bg: colors.primary.white,
		borderColor: colors.brand.RAMIFYGREEN,
		textColor: colors.brand.RAMIFYGREEN,
		icon: <Image src={Leaf} w="16px" />,
	},
	GreenFin: {
		bg: colors.primary.white,
		borderColor: colors.brand.RAMIFYGREEN,
		textColor: colors.brand.RAMIFYGREEN,
		icon: <Image src={Leaf} w="16px" />,
	},
	'Accord de Paris': {
		bg: colors.primary.white,
		borderColor: colors.brand.RAMIFYGREEN,
		textColor: colors.brand.RAMIFYGREEN,
		icon: <TemperatureMax size="16" color={colors.brand.RAMIFYGREEN} />,
	},
};

const AssetBadge = ({ variant }: { variant: TagsFondsActifsType }) => {
	return (
		<Badge
			borderRadius="2px"
			p="4px 8px"
			bg={activeFundTags[variant]?.bg}
			borderWidth="1px"
			borderColor={activeFundTags[variant]?.borderColor}
			w="fit-content"
		>
			<HStack spacing="4px" justify="center">
				{activeFundTags[variant]?.icon && activeFundTags[variant].icon}
				<Text color={activeFundTags[variant]?.textColor} variant="Text-S-Medium">
					{variant}
				</Text>
			</HStack>
		</Badge>
	);
};

export default AssetBadge;
