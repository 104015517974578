import { useMemo } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Tab, TabList, Tabs, VStack } from '@chakra-ui/react';

import AirtableBanner from 'components/Banners/AirtableBanner';
import HeaderProductPage from 'components/HeaderProductPage';
import Discover from 'pages/Cash/Discover';
import Subscriptions from 'pages/Cash/Subscriptions';
import {
	useGetAllSavingsAccountsQuery,
	useGetSavingsAccountsSubscriptionsQuery,
} from 'services/requests/savingsAccounts';
import { isNotNone } from 'utils/functions';

import SavingsAccountDetails from './Dashboard/Details';
import Dashboard from './Dashboard';

const DashboardRoutes = (): JSX.Element => (
	<Routes>
		<Route
			path=":id?"
			element={
				<>
					<Dashboard />
					<SavingsAccountDetails />
				</>
			}
		/>
	</Routes>
);

type MenuItem = {
	label: string;
	route: string;
	active: boolean;
};

function CashLayout(): JSX.Element {
	const { data: savingsAccounts } = useGetAllSavingsAccountsQuery();
	const { data: subscriptions, isFetching: isFetchingSubscriptions } = useGetSavingsAccountsSubscriptionsQuery();

	const navigate = useNavigate();
	const location = useLocation();

	const isDashboardVisible = useMemo(() => {
		if (!savingsAccounts) return false;
		return savingsAccounts.some(
			(savingAccount) => isNotNone(savingAccount.history) && savingAccount.history.length > 0,
		);
	}, [savingsAccounts]);

	const menu = useMemo<MenuItem[]>(
		() =>
			[
				{
					label: 'Découvrir',
					route: '/cash',
				},
				isNotNone(subscriptions) && !isFetchingSubscriptions && subscriptions.length > 0
					? {
							label: 'Souscriptions',
							route: '/cash/souscriptions',
					  }
					: undefined,
				isDashboardVisible
					? {
							label: 'Dashboard',
							route: '/cash/dashboard',
					  }
					: undefined,
			]
				.filter(isNotNone)
				.map((item) => {
					return {
						...item,
						active:
							item.route === '/cash' ? location.pathname === item.route : location.pathname.startsWith(item.route),
					};
				}),
		[isDashboardVisible, isFetchingSubscriptions, location.pathname, subscriptions],
	);

	const tabIndex = useMemo(() => menu.findIndex((item) => item.active), [menu]);

	return (
		<>
			<VStack w="100%" spacing="24px" position="sticky" top="0px" zIndex={2} pt="46px" bg="white">
				<HeaderProductPage variant="Cash" />

				<Tabs w="100%" index={tabIndex}>
					<TabList>
						{menu.map((item) => (
							<Tab key={item.label} onClick={() => navigate(item.route)}>
								{item.label}
							</Tab>
						))}
					</TabList>
				</Tabs>
			</VStack>

			<AirtableBanner />

			<Outlet />
		</>
	);
}

const CashRoutes = (): JSX.Element => (
	<VStack w="100%" spacing="32px" align="start" pb="56px">
		<Routes>
			<Route element={<CashLayout />}>
				<Route index element={<Discover />} />
				<Route path="dashboard/*" element={<DashboardRoutes />} />
				<Route path="souscriptions/*" element={<Subscriptions />} />
			</Route>
			<Route path="*" element={<Navigate to="/cash" replace />} />
		</Routes>
	</VStack>
);

export default CashRoutes;
