import textStyles, { baseHeadingStyle } from 'theme/foundations/typography';

const Heading = {
	baseStyle: {
		...baseHeadingStyle,
	},
	variants: {
		'Title-XL-Bold': { ...textStyles['Title-XL-Bold'] },
		'Title-XL-SemiBold': { ...textStyles['Title-XL-SemiBold'] },
		'Title-L-Bold': { ...textStyles['Title-L-Bold'] },
		'Title-L-SemiBold': { ...textStyles['Title-L-SemiBold'] },
		'Title-M-Bold': { ...textStyles['Title-M-Bold'] },
		'Title-M-SemiBold': { ...textStyles['Title-M-SemiBold'] },
		'Title-S-Bold': { ...textStyles['Title-S-Bold'] },
		'Title-S-SemiBold': { ...textStyles['Title-S-SemiBold'] },
		'Button-SemiBold': { ...textStyles['Button-SemiBold'] },
		'Button-Medium': { ...textStyles['Button-Medium'] },
	},
};

export default Heading;
