import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
	Control,
	FieldErrors,
	useForm,
	UseFormHandleSubmit,
	UseFormRegister,
	UseFormSetValue,
	UseFormTrigger,
	UseFormWatch,
} from 'react-hook-form';
import {
	chakra,
	Heading,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import Amelie from 'assets/team/smallAmelie.png';
import SamyAdvisor from 'components/cards/SamyAdvisor';
import CustomModal from 'components/Modal';
import { VersementEligibilityError } from 'components/versements/VersementEligibilityError';
import { useFoyerAnnualNetIncome } from 'hooks/useFoyerIncome';
import Contracts from 'pages/Invest/Versements/CreateOptimizedVersement/Steps/Contracts';
import Signature from 'pages/Invest/Versements/CreateOptimizedVersement/Steps/Signature';
import { BankInformation } from 'services/requests/bank';
import {
	OptimizationTaxEconomy,
	OptimizationUpdateResponse,
	useLazyGetOptimizationTaxEconomyQuery,
	useLazyGetUpdatedRepartitionQuery,
} from 'services/requests/fiscalite';
import { useGetContractsQuery } from 'services/requests/invest/contracts';
import { VersementEligibility, VersementEligibilityResponse } from 'services/requests/versement';
import { PopulatedInvestContract } from 'store/types/contract.type';
import { EnvelopeType } from 'store/types/global.type';
import { isNotNone } from 'utils/functions';

import Simulation from './Steps/Simulation';

export type CreateOVStepType = 'informations' | 'simulation' | 'contract' | 'signature';

export type CreateOVType = {
	saving: number; // montant a verser mensuellement, reparti entre AV et PER
	amountAV: number; // montant a verser sur AV
	amountPER: number; // montant a verser sur PER
	savingType: EnvelopeType; // Enveloppe actuellement selectionnée
	bankInfo: BankInformation; // Informations bancaires
};

export type CreateOVStepProps = {
	handleSubmit: UseFormHandleSubmit<CreateOVType>;
	trigger: UseFormTrigger<CreateOVType>;
	register: UseFormRegister<CreateOVType>;
	watch: UseFormWatch<CreateOVType>;
	setValue: UseFormSetValue<CreateOVType>;
	setStep: Dispatch<SetStateAction<CreateOVStepType>>;
	step: CreateOVStepType;
	errors: FieldErrors<CreateOVType>;
	control: Control<CreateOVType>;

	availableVersementContracts?: EnvelopeType[];
	selectedEnvelope: EnvelopeType;
	optimization?: OptimizationTaxEconomy;
	isOptimizationFetching: boolean;
	repartition?: OptimizationUpdateResponse;
	isRepartitionFetching: boolean;
	contracts: PopulatedInvestContract[];
	isFetchingContracts: boolean;
	onClose: () => void;
};

type CreateOptimizedVersementProps = {
	isOpen: boolean;
	onClose: () => void;
	versementEligibility?: VersementEligibilityResponse;
};

const CreateOptimizedVersement = ({
	isOpen,
	onClose,
	versementEligibility,
}: CreateOptimizedVersementProps): JSX.Element => {
	const { data: contracts, isFetching: isFetchingContracts } = useGetContractsQuery();
	const { isOpen: isVersementError, onOpen: onVersementError } = useDisclosure();

	const annualNetIncome = useFoyerAnnualNetIncome();
	const [getUpdatedRepartition, { data: repartition, isFetching: isRepartitionFetching }] =
		useLazyGetUpdatedRepartitionQuery();
	const [getOptimizationTaxEconomy, { data: optimization, isFetching: isOptimizationFetching }] =
		useLazyGetOptimizationTaxEconomyQuery();

	const [step, setStep] = useState<CreateOVStepType>('informations');
	const availableVersementContracts = useMemo(
		() =>
			contracts
				?.filter((contract) => !contract.programmedVersement)
				.map((contract) => contract.envelope.type)
				.sort(),
		[contracts],
	);

	const {
		handleSubmit,
		control,
		trigger,
		formState: { errors },
		register,
		setValue,
		watch,
	} = useForm<CreateOVType>({
		mode: 'onChange',
		defaultValues: {
			saving: 250,
		},
	});

	const [saving] = watch(['saving']);

	useEffect(() => {
		getOptimizationTaxEconomy({ saving: saving });
		getUpdatedRepartition({ monthlySavingCapacity: saving })
			.unwrap()
			.then((res) => {
				setValue('amountAV', res.avNewVersementProgramme);
				setValue('amountPER', res.perNewVersementProgramme);
			});
	}, [getOptimizationTaxEconomy, getUpdatedRepartition, saving, setValue]);

	useEffect(() => {
		if (availableVersementContracts) setValue('savingType', availableVersementContracts[0]);
		const monthSaving = annualNetIncome / 12 / 10;
		if (monthSaving > 0) {
			setValue('saving', monthSaving);
			getOptimizationTaxEconomy({ saving: monthSaving });
			getUpdatedRepartition({ monthlySavingCapacity: monthSaving })
				.unwrap()
				.then((res) => {
					setValue('amountAV', res.avNewVersementProgramme);
					setValue('amountPER', res.perNewVersementProgramme);
				});
		}
	}, [setValue, getUpdatedRepartition, getOptimizationTaxEconomy, availableVersementContracts, annualNetIncome]);

	const stepProps: CreateOVStepProps = {
		handleSubmit: handleSubmit,
		setValue: setValue,
		control: control,
		trigger: trigger,
		register: register,
		errors: errors,
		watch: watch,
		step: step,
		setStep: setStep,
		availableVersementContracts: availableVersementContracts,
		optimization: optimization,
		selectedEnvelope: 'AV', // default value set on AV
		isOptimizationFetching: isOptimizationFetching,
		repartition: repartition,
		isRepartitionFetching: isRepartitionFetching,
		contracts: contracts!,
		isFetchingContracts,
		onClose: onClose,
	};

	if (isVersementError) {
		return (
			<CustomModal isOpen onClose={onClose} size="3xl">
				<SamyAdvisor
					complexText={
						<VStack align="start">
							<Heading variant="Title-S-SemiBold">Nous reprenons la main sur votre demande</Heading>

							<Text>
								Une erreur est survenue, nous ne pouvons pas valider automatiquement votre demande pour l’instant. Ne
								vous inquiétez pas, l’équipe support est informée et reviendra vers vous très vite !
							</Text>
						</VStack>
					}
					advisor={Amelie}
				/>
			</CustomModal>
		);
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="3xl">
			<ModalOverlay />
			<ModalContent p="24px" maxW="880px" bg="#FBFBFE">
				<ModalCloseButton />
				{isNotNone(versementEligibility) && versementEligibility.eligibility !== VersementEligibility.ELIGIBLE ? (
					<VersementEligibilityError versementEligibility={versementEligibility.eligibility} />
				) : (
					<chakra.form w="100%">
						{(step === 'informations' || step === 'simulation') && <Simulation {...stepProps} />}
						{step === 'contract' && <Contracts {...stepProps} />}
						{step === 'signature' && <Signature {...stepProps} onError={onVersementError} />}
					</chakra.form>
				)}
			</ModalContent>
		</Modal>
	);
};

export default CreateOptimizedVersement;
