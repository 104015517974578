import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Alert,
	AlertIcon,
	Button,
	Card,
	Heading,
	HStack,
	Link,
	Stack,
	Text,
	useBoolean,
	useDisclosure,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import { DotSpinner } from '@uiball/loaders';

import { ArrowButton } from 'components/Button/Button';
import { MentionUC } from 'components/compliance/MentionUC';
import CustomTooltip from 'components/CustomTooltip';
import FullscreenLoader from 'components/FullscreenLoader';
import PerformancesBarCard from 'components/Graphs/visx/PerformancesBarCard';
import ListCompositionPortfolio from 'components/ListCompositionPortfolio';
import Modal from 'components/Modal';
import ChangeRisk from 'components/portfolio/ChangeRisk';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { useRiskDisplay } from 'hooks/useRiskDisplay';
import { useAppSelector } from 'hooks/useStore';
import { useTemperatureDisplay } from 'hooks/useTemperatureDisplay';
import ChangeTemperature from 'onboarding/Invest/Shared/Portfolio/Steps/Recapitulatif/Sections/ChangeTemperature';
import { OnboardingUpdateStepProps } from 'onboarding/Stepper/stepper.slice';
import TypePortfolioCard from 'pages/Invest/Contrats/ContractPage/Sections/InvestissementPreferences/Sections/TypePortfolioCard';
import {
	GreenPreferenceParams,
	useGetOnboardingPortfolioQuery,
	useUpdateDesiredRiskMutation,
	useUpdatePortfolioGreenMutation,
} from 'services/requests/invest/preferences';

import { styleStep } from '../../steps';

import PortfolioAssetClassRepartition from './Sections/PortfolioAssetClassRepartition';

const Recapitulatif = ({ object: onboardingObject, onNext, onPrev }: OnboardingUpdateStepProps): JSX.Element => {
	const navigate = useNavigate();
	const { goBackToPreviousStep, collapsed } = useAppSelector((state) => state.onboarding.stepper);
	const isMobile = useAppResponsive({ base: true, md: false }) ?? false;
	const [triggerUpdateRisk, { isLoading: isUpdateLoading }] = useUpdateDesiredRiskMutation();

	const [updatePortfolioGreenWithoutWhere] = useUpdatePortfolioGreenMutation();
	const {
		data: portfolio,
		isFetching,
		isLoading,
		refetch: triggerRefetchPortfolio,
	} = useGetOnboardingPortfolioQuery({ id: onboardingObject.id, entityName: onboardingObject.table });

	const { isOpen: isChangeRiskOpen, onClose: onChangeRiskClose, onOpen: onChangeRiskOpen } = useDisclosure();
	const {
		isOpen: isChangeTemperatureOpen,
		onClose: onChangeTemperatureClose,
		// onOpen: onChangeTemperatureOpen, // unused for now
	} = useDisclosure();
	const [recapShown, { off: hideRecap, on: showRecap }] = useBoolean();
	const risk = useRiskDisplay(onboardingObject.investmentPreferences?.risk ?? 0);
	const temperature = useTemperatureDisplay(onboardingObject.investmentPreferences?.temperature ?? 0);

	// delay first render
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			showRecap();
		}, 2000);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [showRecap]);

	const updatePortfolioGreen = useCallback(
		(args: GreenPreferenceParams) => {
			if (onboardingObject) {
				updatePortfolioGreenWithoutWhere({ ...args, id: onboardingObject.id, entityName: onboardingObject.table });
			}
		},
		[onboardingObject, updatePortfolioGreenWithoutWhere],
	);

	if (!recapShown || isLoading || isUpdateLoading || isFetching)
		return (
			<FullscreenLoader>
				<VStack w="70%" spacing="32px">
					<DotSpinner />
					<Text fontWeight={600} align="center">
						À partir d'une liste de plus de 3000 actifs financiers de toutes natures, nous construisons le portefeuille
						qui maximise votre rendement net de frais.
					</Text>
				</VStack>
			</FullscreenLoader>
		);

	if (!portfolio)
		return (
			<Alert variant="left-accent" status="error">
				<AlertIcon />
				<Text>
					Une erreur est survenue.{' '}
					<Button variant="link" onClick={triggerRefetchPortfolio}>
						Réessayer
					</Button>
				</Text>
			</Alert>
		);

	return (
		<VStack w="100%" align="start" spacing="24px">
			<VStack align="start" spacing="16px" w="100%">
				<Heading variant="Title-XL-Bold">Mon portefeuille sur mesure</Heading>
				<Text variant="Text-M-Regular">
					Ce portefeuille a été construit en prenant en compte votre appétence au risque, votre objectif
					d’investissement ainsi que vos préférences d’investissement.
				</Text>
			</VStack>
			{/* <VStack align="start" w="100%"> */}
			{/* <Text variant="Title-L-SemiBold">Type de portefeuille :</Text> */}
			{onboardingObject.investmentPreferences && (
				<TypePortfolioCard type={onboardingObject.investmentPreferences.portfolioType || 'ESSENTIAL'} />
			)}
			{/* </VStack> */}
			<Stack w="100%" direction={{ base: 'column', lg: 'row' }} spacing="24px">
				<Card flex={1} bg="products.pe">
					<VStack align="start" spacing="8px">
						<HStack w="100%" justify="space-between">
							<Text variant="Caption" color="grey.900">
								Profil de risque
							</Text>
							<HStack align="baseline">
								<Text variant="Title-L-Bold">
									{onboardingObject.investmentPreferences!.risk!.toLocaleString('fr-FR')}
								</Text>
								<Text variant="Title-S-SemiBold">/10</Text>
							</HStack>
						</HStack>
						<HStack spacing="12px">
							<Text variant="Title-M-SemiBold">{risk.name}</Text>
							<CustomTooltip text={<RecommandedRiskTooltip />} />
						</HStack>
						<VStack align="start" w="100%" spacing="16px">
							<Text variant="Text-M-Regular" color="grey.900">
								{risk.description}
							</Text>
							<ArrowButton variant="tertiary" onClick={onChangeRiskOpen} alignSelf="end">
								Modifier
							</ArrowButton>
						</VStack>
					</VStack>
				</Card>
				{onboardingObject.investmentPreferences?.esg && (
					<Card flex={1} bg="products.scpi">
						<VStack align="start" spacing="8px">
							<HStack w="100%" justify="space-between">
								<Text variant="Caption" color="grey.900">
									Impact en température
								</Text>
								<HStack align="baseline">
									<Text variant="Title-L-Bold" color="positive.900">
										{temperature.name}
									</Text>
									<Text variant="Title-S-SemiBold" color="positive.900">
										°C
									</Text>
								</HStack>
							</HStack>
							<HStack spacing="12px">
								<Text variant="Title-M-SemiBold">{temperature.description}</Text>
								<CustomTooltip text="L'objectif à long terme de l'accord de Paris en matière de température est de maintenir l'augmentation de la température moyenne de la planète bien en dessous de 2 °C par rapport aux niveaux préindustriels." />
							</HStack>
							<VStack align="start" w="100%" spacing="16px">
								<Text variant="Text-M-Regular" color="grey.900">
									Calcul réalisé sur l’allocation en actions de votre portefeuille.
								</Text>
								<ArrowButton variant="tertiary" onClick={() => navigate(`../${styleStep.url}`)} alignSelf="end">
									Modifier
								</ArrowButton>
							</VStack>
						</VStack>
					</Card>
				)}
			</Stack>

			<Text variant="Title-L-SemiBold">Détails du portefeuille</Text>
			<Wrap w="100%" spacing="24px">
				<WrapItem minW="340px" w="100%" flex={collapsed ? { base: undefined, md: '1' } : { base: undefined, xl: '1' }}>
					<PortfolioAssetClassRepartition
						assets={portfolio.assets.map((asset) => ({
							type: asset.type,
							repartition: asset.repartition,
						}))}
					/>
				</WrapItem>
				<WrapItem minW="340px" w="100%" flex={collapsed ? { base: undefined, md: '2' } : { base: undefined, xl: '2' }}>
					<PerformancesBarCard performances={portfolio.performances} />
				</WrapItem>
			</Wrap>

			<Card w="100%">
				<VStack w="100%" align="start" spacing="24px">
					<VStack w="100%" align="start" spacing="8px">
						<Text variant="Title-M-SemiBold">Composition de votre portefeuille</Text>
						<Text variant="Text-M-Regular" color="grey.900">
							En % de vos investissements
						</Text>
					</VStack>
					<ListCompositionPortfolio assets={portfolio.assets} isLoading={isFetching} />
					<MentionUC />
				</VStack>
			</Card>

			{/* Change risk popup */}
			<Modal isOpen={isChangeRiskOpen} onClose={onChangeRiskClose}>
				<ChangeRisk
					validateRisk={(newRisk) => {
						hideRecap();
						setTimeout(() => showRecap(), 2000);
						triggerUpdateRisk({
							id: onboardingObject.id,
							entityName: onboardingObject.table,
							desiredClientRiskScore: newRisk,
						});
						onChangeRiskClose();
					}}
					risk={onboardingObject.investmentPreferences!.risk!}
					recommandedRisk={onboardingObject.investmentPreferences!.recommandedRisk!}
				/>
			</Modal>

			{/* Change temperature popup */}
			<Modal isOpen={isChangeTemperatureOpen} onClose={onChangeTemperatureClose} size="md">
				{/* todo: refaire la modal */}
				<ChangeTemperature
					validateTemperature={(newTemperature) => {
						hideRecap();
						setTimeout(() => showRecap(), 2000);
						updatePortfolioGreen({ ESG: true, temperature: newTemperature });
						onChangeTemperatureClose();
					}}
					temperature={onboardingObject.investmentPreferences!.temperature!}
				/>
			</Modal>

			<BottomStepBar
				onPrev={onPrev}
				onNext={onNext}
				showNext={!isFetching}
				textNext={goBackToPreviousStep && !isMobile ? 'Reprendre mon parcours' : undefined}
			/>
		</VStack>
	);
};

export const RecommandedRiskTooltip = () => (
	<Text variant="Text-S-Regular" color="white" align="start">
		Le score de risque qui vous est présenté est une <b>estimation de votre appétence au risque</b>. Plus le score est
		élevé, plus votre aversion au risque est faible. Celle-ci exprime le rapport de force entre la volonté de gagner de
		l'argent, et celle de ne pas prendre de risques (et donc d’en perdre).
		<br />
		<br />
		<b>Chaque personne a une aversion au risque qui lui est propre</b>, qui dépend de facteurs objectifs et subjectifs.
		Nous pouvons citer notamment la crainte de l’avenir, les revenus, le projet d’investissement, l’horizon
		d’investissement...
		<br />
		<br />
		Pour plus de détails sur notre méthodologie de scoring de risque, consultez notre{' '}
		<Link href="https://ramify.fr/livre-blanc/" isExternal>
			<b>
				<u>livre blanc</u>
			</b>
		</Link>
		.
	</Text>
);

export default Recapitulatif;
