import { backendApi } from 'services/apiService';
import { UserScpiKyc, UserScpiKycTest } from 'store/types/scpiKyc.type';

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		// KYC
		getSCPIKYC: builder.query<UserScpiKyc, void>({
			query: () => '/real-estate/kyc',
			providesTags: ['SCPI-KYC'],
		}),
		updateSCPIKYC: builder.mutation<void, UserScpiKyc>({
			query: (params) => ({
				url: `/real-estate/kyc`,
				method: 'POST',
				body: params,
			}),
			invalidatesTags: ['SCPI-KYC', 'KYC'],
		}),
		getSCPIKYCTest: builder.query<UserScpiKycTest, void>({
			query: () => '/real-estate/kyc/test',
			providesTags: ['SCPI-KYC-TEST'],
		}),
		updateSCPIKYCTest: builder.mutation<void, UserScpiKycTest>({
			query: (params) => ({
				url: `/real-estate/kyc/test`,
				method: 'POST',
				body: params,
			}),
			invalidatesTags: ['SCPI-KYC-TEST'],
		}),
	}),
});

export const {
	// KYC
	useGetSCPIKYCQuery,
	useUpdateSCPIKYCMutation,
	// Test
	useGetSCPIKYCTestQuery,
	useUpdateSCPIKYCTestMutation,
} = extendedApi;
