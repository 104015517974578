import { useMemo } from 'react';
import { Button, HStack, ModalBody, ModalFooter, Text, VStack } from '@chakra-ui/react';
import { DotSpinner } from '@uiball/loaders';

import ListCompositionPortfolio from 'components/ListCompositionPortfolio';
import { useSwitchEliteRepartitionQuery } from 'services/requests/invest/switch-elite';
import { PopulatedInvestContract } from 'store/types/contract.type';
import { displayMoneyNoDigits } from 'utils/rendering';

type UpgradeElitePreviewProps = {
	amountDeposit: number;
	onPrev: () => void;
	onNext: () => void;
	contract: PopulatedInvestContract;
};

const UpgradeElitePreview = ({ amountDeposit, onPrev, onNext, contract }: UpgradeElitePreviewProps) => {
	const { data: repartition, isLoading } = useSwitchEliteRepartitionQuery({ id: contract.id, inflow: amountDeposit });

	// Todo : Elite deposit is specific for now since Lab return repartition as amount instead of percentage for precision reasons
	const repartitionSpecificElite = useMemo(() => {
		const total = repartition?.reduce((acc, asset) => acc + asset.repartition, 0);
		if (!total) return undefined;
		return repartition?.map((asset) => {
			const percentageRepartition = asset.repartition / total;
			return {
				...asset,
				repartition: percentageRepartition,
				montant: asset.repartition,
			};
		});
	}, [repartition]);

	return (
		<>
			<ModalBody py="24px">
				<VStack w="100%" spacing="24px" align="start">
					{!isLoading && repartition ? (
						<>
							<Text>
								Votre versement de{' '}
								<Text as="span" variant="Text-M-Bold">
									{displayMoneyNoDigits(amountDeposit!)}
								</Text>{' '}
								sera alloué de la façon suivante :
							</Text>
							{repartitionSpecificElite && (
								<ListCompositionPortfolio assets={repartitionSpecificElite} showPerformance={false} displayAmount />
							)}
						</>
					) : (
						<HStack>
							<DotSpinner size={20} />
							<Text>Nous calculons la répartition du versement qui sera fait sur votre Assurance-Vie</Text>
						</HStack>
					)}
				</VStack>
			</ModalBody>
			<ModalFooter borderTop="1px solid" borderColor="grey.300">
				<HStack w="100%" spacing="24px" justifyContent="space-between">
					<Button variant="secondary" onClick={onPrev}>
						<Text variant="Text-S-Medium">Précédent</Text>
					</Button>
				</HStack>
				<HStack w="100%" spacing="24px" justifyContent="end">
					<Button onClick={onNext} isDisabled={isLoading || !repartition}>
						<Text variant="Text-S-Medium">Signer l'opération</Text>
					</Button>
				</HStack>
			</ModalFooter>
		</>
	);
};

export default UpgradeElitePreview;
