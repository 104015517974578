import React, { LegacyRef, useState } from 'react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Input, InputGroup, InputLeftElement, InputProps, InputRightElement } from '@chakra-ui/react';
import { Ring } from '@uiball/loaders';

import colors from 'theme/foundations/colors';

import LabelWrapperInput from './LabelWrapperInput';

type TextInputProps = {
	placeholder?: string;
	label?: string;
	tooltip?: string;
	autocomplete?: string;
};

const TextInput = React.forwardRef(
	(
		{ placeholder, label, tooltip, autocomplete, ...rest }: TextInputProps & InputProps,
		ref?: LegacyRef<HTMLInputElement>,
	): JSX.Element => (
		<LabelWrapperInput label={label} tooltip={tooltip}>
			<Input
				ref={ref}
				placeholder={placeholder}
				{...rest}
				onWheel={(event) => event.currentTarget.blur()}
				autoComplete={autocomplete}
			/>
		</LabelWrapperInput>
	),
);

const ValidationTextInput = React.forwardRef(
	(
		{
			placeholder,
			iconLeft,
			label,
			type,
			validate,
			onChange,
			tooltip,
			formatOnChange,
			onValidated,
			hideLabel = false,
			...rest
		}: TextInputProps &
			InputProps & {
				iconLeft?: JSX.Element;
				type?: 'email' | 'tel';
				validate?: ((s: string) => boolean) | ((s: string) => Promise<boolean>);
				onChange: React.ChangeEventHandler<HTMLInputElement>;
				formatOnChange?: (s: string) => string | null;
				hideLabel?: boolean;
				onValidated?: (valid: boolean | undefined) => void;
			},
		ref?: LegacyRef<HTMLInputElement>,
	): JSX.Element => {
		const [isValid, setIsValid] = useState<boolean>();
		const [timerValidation, setTimerValidation] = useState<NodeJS.Timeout>();
		const [loading, setLoading] = useState(false);
		const [input, setInput] = useState('');
		const setValidity = (v: string) => {
			if (!v) {
				setLoading(false);
				setIsValid(undefined);
				if (onValidated) onValidated(undefined);
				return;
			}

			if (validate) {
				setTimerValidation(
					setTimeout(async () => {
						const validity = await validate(v);
						setIsValid(validity);
						setLoading(false);
						if (onValidated) {
							onValidated(validity);
						}
					}, 700),
				);
			}
		};
		const slowValidation = (v: string) => {
			if (timerValidation) {
				clearTimeout(timerValidation);
			}
			setIsValid(undefined);
			setLoading(true);
			setValidity(v);
		};

		const inputJsx = (
			<InputGroup>
				{iconLeft && <InputLeftElement pointerEvents="none" children={iconLeft} />}
				<Input
					ref={ref}
					{...(isValid === false && {
						boxShadow: `${colors.negative[900]} 0px 0px 0px 1px !important`,
					})}
					type={type}
					placeholder={placeholder}
					value={input}
					onChange={(e) => {
						const cursorPlace = e.target.selectionStart;
						if (formatOnChange) setInput(formatOnChange(e.target.value) ?? e.target.value);
						else setInput(e.target.value);
						onChange(e);
						slowValidation(e.target.value);
						if (cursorPlace !== e.target.value.length)
							setTimeout(() => e.target.setSelectionRange(cursorPlace, cursorPlace), 25);
					}}
					autoComplete={type ? 'on' : 'off'}
					{...rest}
				/>
				{loading && (
					<InputRightElement zIndex="0">
						<Ring size={20} color={colors.blue[500]} />
					</InputRightElement>
				)}
				{isValid === true && (
					<InputRightElement zIndex="0">
						<CheckIcon color="positive.900" />
					</InputRightElement>
				)}
				{isValid === false && (
					<InputRightElement zIndex="0">
						<CloseIcon color="negative.900" boxSize={3} />
					</InputRightElement>
				)}
			</InputGroup>
		);

		if (hideLabel) return inputJsx;
		return (
			<LabelWrapperInput label={label} tooltip={tooltip}>
				{inputJsx}
			</LabelWrapperInput>
		);
	},
);

export { ValidationTextInput };

export default TextInput;
