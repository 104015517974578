import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { chakra } from '@chakra-ui/react';
import RegulatoryInformationFormContext from 'formContexts/RegulatoryInformations';

import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import {
	useGetKYCQuery,
	useGetPESCPIKYCQuery,
	useUpdatekycFromScpiPeMutation,
	useUpdatePESCPIKYCMutation,
} from 'services/requests/kyc';
import { PeScpiKyc, UpdateKycFromScpiPeDTO } from 'store/types/KYC.type';
import { isNotNone } from 'utils/functions';

type FormData = PeScpiKyc & UpdateKycFromScpiPeDTO;

export const Informations = (): JSX.Element => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const [updatePeScpiKyc] = useUpdatePESCPIKYCMutation();
	const { data: peScpiKyc } = useGetPESCPIKYCQuery();
	const { data: kyc } = useGetKYCQuery();
	const [updateKycFromPeScpi] = useUpdatekycFromScpiPeMutation();

	const methods = useForm<FormData>({
		shouldUnregister: true,
	});

	const { handleSubmit, setValue } = methods;

	useEffect(() => {
		if (peScpiKyc && kyc) {
			// the values from db can be null and form doesn't like it
			if (isNotNone(kyc.nif)) setValue('nif', kyc.nif);
			if (isNotNone(kyc.patrimonyNonCote)) setValue('patrimonyNonCote', +kyc.patrimonyNonCote);
			if (isNotNone(kyc.patrimonyRealEstate)) setValue('patrimonyRealEstate', +kyc.patrimonyRealEstate);

			if (isNotNone(peScpiKyc.savingCapacity)) setValue('savingCapacity', +peScpiKyc.savingCapacity);
			if (isNotNone(peScpiKyc.primaryResidenceStatus))
				setValue('primaryResidenceStatus', peScpiKyc.primaryResidenceStatus);

			if (isNotNone(peScpiKyc.hasRealEstateLoan)) setValue('hasRealEstateLoan', peScpiKyc.hasRealEstateLoan);
			if (isNotNone(peScpiKyc.realEstateLoanRest)) setValue('realEstateLoanRest', +peScpiKyc.realEstateLoanRest);
			if (isNotNone(peScpiKyc.realEstateLoanFrequency))
				setValue('realEstateLoanFrequency', peScpiKyc.realEstateLoanFrequency);

			if (isNotNone(peScpiKyc.hasConsomationLoan)) setValue('hasConsomationLoan', peScpiKyc.hasConsomationLoan);
			if (isNotNone(peScpiKyc.consomationLoanRest)) setValue('consomationLoanRest', +peScpiKyc.consomationLoanRest);
			if (isNotNone(peScpiKyc.consomationLoanFrequency))
				setValue('consomationLoanFrequency', peScpiKyc.consomationLoanFrequency);

			if (isNotNone(peScpiKyc.hasOtherLoan)) setValue('hasOtherLoan', peScpiKyc.hasOtherLoan);
			if (isNotNone(peScpiKyc.otherLoanRest)) setValue('otherLoanRest', +peScpiKyc.otherLoanRest);
			if (isNotNone(peScpiKyc.otherLoanFrequency)) setValue('otherLoanFrequency', peScpiKyc.otherLoanFrequency);
		}
	}, [setValue, peScpiKyc, kyc]);

	const onSubmit = handleSubmit(({ nif, patrimonyNonCote, patrimonyRealEstate, ...data }: FormData) => {
		updateKycFromPeScpi({ nif, patrimonyNonCote, patrimonyRealEstate })
			.unwrap()
			.then(() =>
				updatePeScpiKyc(data)
					.unwrap()
					.then(() => {
						navigate('../informations-produit');
					})
					.catch(() =>
						toast({
							title: 'Une erreur est survenue',
							status: 'error',
							duration: 9000,
							isClosable: true,
						}),
					),
			)
			.catch(() =>
				toast({
					title: 'Une erreur est survenue',
					status: 'error',
					duration: 9000,
					isClosable: true,
				}),
			);
	});

	useEffect(() => {
		eventTracker.mixpanel.track('PE Onboarding - Informations');
	}, []);

	return (
		<FormProvider {...methods}>
			<chakra.form onSubmit={onSubmit}>
				<RegulatoryInformationFormContext />
				<BottomStepBar nextSubmit onPrev={() => navigate(`..`)} />
			</chakra.form>
		</FormProvider>
	);
};
