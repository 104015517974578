import { KYC, PeScpiKyc, UserPeScpiKyc } from 'store/types/KYC.type';
import { UserPEKyc, UserPeKycTest } from 'store/types/peKyc.type';
import { isNotNone } from 'utils/functions';

// ------ Private Equity Reglementary Step ------

// TODO: This function is common to PE and SCPI, it should be in a shared folder
export const isReglementaryInformationCompleted = (kyc: KYC | undefined, peScpiKyc: UserPeScpiKyc | undefined) =>
	!!kyc &&
	!!peScpiKyc &&
	isNotNone(peScpiKyc.savingCapacity) &&
	isNotNone(kyc.nif) &&
	isNotNone(peScpiKyc.primaryResidenceStatus) &&
	isNotNone(peScpiKyc.hasRealEstateLoan) &&
	// has a loan with frequency and rest or has no loan
	(!peScpiKyc.hasRealEstateLoan ||
		(isNotNone(peScpiKyc.realEstateLoanRest) && isNotNone(peScpiKyc.realEstateLoanFrequency))) &&
	isNotNone(peScpiKyc.hasConsomationLoan) &&
	// has a loan with frequency and rest or has no loan
	(!peScpiKyc.hasConsomationLoan ||
		(isNotNone(peScpiKyc.consomationLoanRest) && isNotNone(peScpiKyc.consomationLoanFrequency))) &&
	isNotNone(peScpiKyc.hasOtherLoan) &&
	// has a loan with frequency and rest or has no loan
	(!peScpiKyc.hasOtherLoan || (isNotNone(peScpiKyc.otherLoanRest) && isNotNone(peScpiKyc.otherLoanFrequency)));

export const isPeProductInformationCompleted = (peKyc: UserPEKyc | undefined) =>
	!!peKyc &&
	isNotNone(peKyc.hasExercisedFinancialProfession) &&
	isNotNone(peKyc.hasPELinks) &&
	(peKyc.hasPELinks ? isNotNone(peKyc.PELinksNames) : true) &&
	isNotNone(peKyc.hasPEShares) &&
	(peKyc.hasPEShares ? isNotNone(peKyc.PESharesNames) : true) &&
	isNotNone(peKyc.lengthPerception) &&
	isNotNone(peKyc.riskPerception);

export const isPeKycTestCompleted = (peKycTest: UserPeKycTest | undefined) =>
	!!peKycTest && isNotNone(peKycTest.nonCoteKnowledge) && isNotNone(peKycTest.agreeWithInvestment);

export const isPeReglementaryStepCompleted = (
	kyc: KYC | undefined,
	peScpiKyc: PeScpiKyc | undefined,
	peKyc: UserPEKyc | undefined,
	peKycTest: UserPeKycTest | undefined,
) =>
	isReglementaryInformationCompleted(kyc, peScpiKyc) &&
	isPeProductInformationCompleted(peKyc) &&
	isPeKycTestCompleted(peKycTest);

// ----------------------------------------------
