import { Line } from '@visx/shape';

import colors from 'theme/foundations/colors';

export type InfosCursorBar = {
	tooltipLeft: number;
	tooltipTop: number;
	yMax: number;
	margin: { top: number; right: number; bottom: number; left: number };
	tooltipData: any;
};

export default function CursorBar({ tooltipData, tooltipLeft, tooltipTop, yMax, margin }: InfosCursorBar) {
	return (
		<g>
			{tooltipData && (
				<g>
					<Line
						from={{ x: tooltipLeft ?? 0 - margin.left, y: 0 }}
						to={{ x: tooltipLeft, y: yMax }}
						stroke={colors.primary.black}
						strokeWidth={2}
						pointerEvents="none"
					/>

					<circle
						cx={tooltipLeft}
						cy={tooltipTop}
						r={4}
						fill="#FFFFFF"
						fillOpacity={0.1}
						strokeOpacity={0.1}
						strokeWidth={2}
						pointerEvents="none"
					/>
					<circle
						cx={tooltipLeft}
						cy={tooltipTop}
						r={4}
						fill="#FFFFFF"
						stroke={colors.primary.black}
						strokeWidth={2}
						pointerEvents="none"
					/>
				</g>
			)}
		</g>
	);
}
