import { Image, VStack } from '@chakra-ui/react';

import partnersBlack from 'assets/icons/OffreBlack/partnersBlack.png';

const PartenersOffreBlack = (): JSX.Element => (
	<VStack w="100%">
		<Image w="100%" src={partnersBlack} alt={partnersBlack} />
	</VStack>
);
export default PartenersOffreBlack;
