export const listSCPI = {
	// KYANEOS_PIERRE: 'Kyaneos Pierre',
	NOVAXIA_NEO: 'Novaxia Neo',
	// NCAP_CONTINENT: 'NCap Continent',
	REMAKE_LIVE: 'Remake Live',
	RIVOLI_AVENIR_PATRIMOINE: 'Rivoli Avenir Patrimoine',
};

export const listPE = {
	VATEL_DIVIDENDE_PLUS_10: 'Dividendes Plus n°10',
	PRINCIPAL_INVESTMENTS: 'Eurazeo Principal Investments',
	PRIVATE_VALUE_3: 'Eurazeo Private Value Europe 3',
	EUROPEAN_REAL_ESTATE_II: 'Eurazeo European Real Estate II',
	STRATEGIC_OPPORTUNITIES_3: 'Eurazeo Strategic Opportunities 3',
	ENTREPRENEURS_CLUB_II: 'Eurazeo Entrepreneurs Club 2',
};

// map for the fund name on airtable with the associated url path for the pdf generation
export const automatedFundsSCPIRoute: Record<string, string> = {
	// [listSCPI.KYANEOS_PIERRE]: 'kyaneos-pierre',
	[listSCPI.NOVAXIA_NEO]: 'novaxia-neo',
	// [listSCPI.NCAP_CONTINENT]: 'ncap-continent',
	[listSCPI.REMAKE_LIVE]: 'remake-live',
	[listSCPI.RIVOLI_AVENIR_PATRIMOINE]: 'rivoli-avenir-patrimoine',
};

// map for the fund name on airtable with the associated url path for the pdf generation
export const automatedFundsPERoute: Record<string, string> = {
	[listPE.VATEL_DIVIDENDE_PLUS_10]: 'vatel-dividende-plus-10',
};

export const partnersFundsRoute: Record<string, string> = {
	[listPE.PRINCIPAL_INVESTMENTS]: 'eurazeo',
	[listPE.EUROPEAN_REAL_ESTATE_II]: 'eurazeo',
	[listPE.STRATEGIC_OPPORTUNITIES_3]: 'eurazeo',
	[listPE.PRIVATE_VALUE_3]: 'eurazeo',
	[listPE.ENTREPRENEURS_CLUB_II]: 'eurazeo',
};
