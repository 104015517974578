import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { Heading, VStack } from '@chakra-ui/react';

import { OpenedFund } from 'components/cards/funds/pe-immo/OpenedFund';
import FullscreenLoader from 'components/FullscreenLoader';
import InvestmentMetrics from 'components/Metrics/InvestmentMetrics';
import { useProducts } from 'hooks/useProducts';
import DashboardFund from 'pages/PrivateEquity/Dashboard/InvestmentDetail';
import KpisSubscriptions from 'pages/PrivateEquity/Dashboard/KpisSubscriptions';
import { useGetPrivateEquityDashboardSubscriptionsQuery } from 'services/requests/privateEquity';
import { useGetUserPESubscriptionsQuery } from 'services/requests/privateEquity/subscriptions';

const DashboardPe: FC = () => {
	const { pe } = useProducts();
	const { data: subscriptions } = useGetUserPESubscriptionsQuery();
	const { data: subscriptionsMetrics } = useGetPrivateEquityDashboardSubscriptionsQuery();

	const openedSubscriptions = subscriptions?.filter((sub) => sub.status === 'COMPLETED');

	if (pe.isLoading) return <FullscreenLoader />;
	if (!pe.isOpen) return <Navigate replace to="/private-equity" />;

	return (
		<>
			<VStack w="100%" spacing="32px" align="start">
				<Heading variant="Title-L-SemiBold">Votre tableau de bord</Heading>
				{pe.data && <InvestmentMetrics data={pe.data} />}

				<KpisSubscriptions subscriptions={openedSubscriptions} />

				{openedSubscriptions && (
					<VStack w="100%">
						{openedSubscriptions.map((s) => (
							<OpenedFund key={s.id} subscription={s} metrics={subscriptionsMetrics?.find((sub) => sub.id === s.id)} />
						))}
					</VStack>
				)}
			</VStack>
			<DashboardFund />
		</>
	);
};

export default DashboardPe;
