import { StatusPieceJustificatives } from 'services/requests/kyc';
import { ProfessionalSituation } from 'store/referentiels/professionalSituation';
import { KYC } from 'store/types/KYC.type';
import { isNotNone } from 'utils/functions';

export type KYCStepType =
	| 'introduction'
	| 'etatCivil'
	| 'foyerFiscal'
	| 'professionelle'
	| 'coordonnees'
	| 'patrimoine'
	| 'justificatif'
	| 'recapitulatif';

export interface KycStepProps {
	onNext: () => void;
	onPrev: () => void;
}

type MandatoryDocumentType = {
	Piece_identite: boolean;
	Justificatif_domicile: boolean;
	Justificatif_Kbis: boolean;
};

export const getMandatoryDocument = (kycData: KYC | undefined): MandatoryDocumentType => ({
	Piece_identite: true,
	Justificatif_domicile: true,
	Justificatif_Kbis: kycData?.professionalSituation === ProfessionalSituation.INDEPENDANT,
});

export const hasUploadEveryRequiredDocument = (
	mandatoryDocument: MandatoryDocumentType,
	justificatif: StatusPieceJustificatives | undefined,
): boolean => {
	for (const [file, required] of Object.entries(mandatoryDocument))
		if (required && required !== !!justificatif?.[file]?.uploaded) return false;
	return true;
};

export const hasCompletedKycEtatCivil = (kycData?: KYC): boolean =>
	isNotNone(kycData?.civilStatus) && isNotNone(kycData?.citizenship) && isNotNone(kycData?.birthDate);

export const hasCompletedKycFoyerFiscal = (kycData?: KYC): boolean =>
	isNotNone(kycData?.familySituation) && isNotNone(kycData?.professionalSituation) && isNotNone(kycData?.revenu);

export const hasCompletedKycProfessionnelle = (kycData?: KYC): boolean =>
	isNotNone(kycData?.profession) && isNotNone(kycData?.professionalSituation);

export const hasCompletedKycCoordonnees = (kycData?: KYC): boolean =>
	isNotNone(kycData?.address) && isNotNone(kycData?.zipCode) && isNotNone(kycData?.city);

export const hasCompletedKycPatrimoine = (kycData?: KYC): boolean => isNotNone(kycData?.patrimonyEstimatePortfolio);

export const hasCompletedKycJustificatif = (kycData?: KYC, statusPj?: StatusPieceJustificatives): boolean => {
	if (!kycData || !statusPj) return false;
	const mandatoryDocument = getMandatoryDocument(kycData);
	return hasUploadEveryRequiredDocument(mandatoryDocument, statusPj);
};

export const hasCompletedKyc = (kycData?: KYC, statusPj?: StatusPieceJustificatives): boolean =>
	hasCompletedKycEtatCivil(kycData) &&
	hasCompletedKycFoyerFiscal(kycData) &&
	hasCompletedKycProfessionnelle(kycData) &&
	hasCompletedKycCoordonnees(kycData) &&
	hasCompletedKycPatrimoine(kycData) &&
	hasCompletedKycJustificatif(kycData, statusPj);
