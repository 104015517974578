import { createIcon } from '@chakra-ui/react';

export const EpargneIcon = createIcon({
	displayName: 'EpargneIcon',
	path: (
		<g fill="currentColor">
			<path d="M19.5675 9.30119L16.3557 7.69535L19.5675 6.08935C20.142 5.80218 20.1427 4.97942 19.5675 4.69179L10.3488 0.0823979C10.1287 -0.027466 9.86993 -0.027466 9.64989 0.0823979L0.431101 4.69179C-0.143395 4.97912 -0.144005 5.80188 0.431101 6.08935L3.64294 7.69535L0.431101 9.30119C-0.143395 9.58852 -0.144005 10.4111 0.431101 10.6988L3.64294 12.3047L0.431101 13.9106C-0.143395 14.1979 -0.144005 15.0205 0.431101 15.3081L9.64989 19.9175C9.86947 20.0273 10.1283 20.0277 10.3488 19.9175L19.5675 15.3081C20.142 15.0208 20.1427 14.1982 19.5675 13.9106L16.3557 12.3047L19.5675 10.6988C20.142 10.4116 20.1427 9.58882 19.5675 9.30119ZM9.99932 1.65467L17.4711 5.39065L9.99932 9.12648L2.52752 5.39065L9.99932 1.65467ZM17.4711 14.6094L9.99932 18.3453L2.52752 14.6094L5.38993 13.1782L9.64989 15.3081C9.86962 15.4179 10.1283 15.4183 10.3488 15.3081L14.6087 13.1782L17.4711 14.6094ZM9.99932 13.7359L2.52752 10L5.38993 8.56877L9.64989 10.6988C9.86962 10.8085 10.1283 10.8089 10.3488 10.6988L14.6087 8.56877L17.4711 10L9.99932 13.7359Z" />
		</g>
	),
});

export const DashboardIcon = createIcon({
	displayName: 'DashboardIcon',
	path: (
		<g fill="currentColor">
			<path d="M7.70822 6.66667H1.45827C0.654139 6.66667 0 6.01253 0 5.20824V1.45827C0 0.654139 0.654139 0 1.45827 0H7.70822C8.51251 0 9.16665 0.654139 9.16665 1.45827V5.20824C9.16665 6.01253 8.51251 6.66667 7.70822 6.66667ZM1.45827 1.24999C1.34337 1.24999 1.24999 1.34337 1.24999 1.45827V5.20824C1.24999 5.32329 1.34337 5.41668 1.45827 5.41668H7.70822C7.82327 5.41668 7.91666 5.32329 7.91666 5.20824V1.45827C7.91666 1.34337 7.82327 1.24999 7.70822 1.24999H1.45827Z" />
			<path d="M7.70822 19.9987H1.45827C0.654139 19.9987 0 19.3445 0 18.5404V9.79045C0 8.98617 0.654139 8.33203 1.45827 8.33203H7.70822C8.51251 8.33203 9.16665 8.98617 9.16665 9.79045V18.5404C9.16665 19.3445 8.51251 19.9987 7.70822 19.9987ZM1.45827 9.58202C1.34337 9.58202 1.24999 9.6754 1.24999 9.79045V18.5404C1.24999 18.6553 1.34337 18.7487 1.45827 18.7487H7.70822C7.82327 18.7487 7.91666 18.6553 7.91666 18.5404V9.79045C7.91666 9.6754 7.82327 9.58202 7.70822 9.58202H1.45827Z" />
			<path d="M18.5409 19.9987H12.2909C11.4867 19.9987 10.8325 19.3446 10.8325 18.5404V14.7905C10.8325 13.9862 11.4867 13.332 12.2909 13.332H18.5409C19.345 13.332 19.9992 13.9862 19.9992 14.7905V18.5404C19.9992 19.3446 19.345 19.9987 18.5409 19.9987ZM12.2909 14.582C12.1759 14.582 12.0825 14.6754 12.0825 14.7905V18.5404C12.0825 18.6553 12.1759 18.7487 12.2909 18.7487H18.5409C18.6558 18.7487 18.7492 18.6553 18.7492 18.5404V14.7905C18.7492 14.6754 18.6558 14.582 18.5409 14.582H12.2909Z" />
			<path d="M18.5409 11.6666H12.2909C11.4867 11.6666 10.8325 11.0125 10.8325 10.2082V1.45827C10.8325 0.654139 11.4867 0 12.2909 0H18.5409C19.345 0 19.9992 0.654139 19.9992 1.45827V10.2082C19.9992 11.0125 19.345 11.6666 18.5409 11.6666ZM12.2909 1.24999C12.1759 1.24999 12.0825 1.34337 12.0825 1.45827V10.2082C12.0825 10.3233 12.1759 10.4166 12.2909 10.4166H18.5409C18.6558 10.4166 18.7492 10.3233 18.7492 10.2082V1.45827C18.7492 1.34337 18.6558 1.24999 18.5409 1.24999H12.2909Z" />
		</g>
	),
});

export const InvestmentIcon = createIcon({
	displayName: 'InvestmentIcon',
	path: (
		<g fill="currentColor">
			<path d="M18.0469 4.375H16.1719C15.0949 4.375 14.2188 5.25117 14.2188 6.32812V18.0469C14.2188 19.1238 15.0949 20 16.1719 20H18.0469C19.1238 20 20 19.1238 20 18.0469V6.32812C20 5.25117 19.1238 4.375 18.0469 4.375ZM18.4375 18.0469C18.4375 18.2623 18.2623 18.4375 18.0469 18.4375H16.1719C15.9565 18.4375 15.7812 18.2623 15.7812 18.0469V6.32812C15.7812 6.11273 15.9565 5.9375 16.1719 5.9375H18.0469C18.2623 5.9375 18.4375 6.11273 18.4375 6.32812V18.0469Z" />
			<path d="M3.82812 10.3125H1.95312C0.876172 10.3125 0 11.1887 0 12.2656V18.0469C0 19.1238 0.876172 20 1.95312 20H3.82812C4.90508 20 5.78125 19.1238 5.78125 18.0469V12.2656C5.78125 11.1887 4.90508 10.3125 3.82812 10.3125ZM4.21875 18.0469C4.21875 18.2623 4.04352 18.4375 3.82812 18.4375H1.95312C1.73773 18.4375 1.5625 18.2623 1.5625 18.0469V12.2656C1.5625 12.0502 1.73773 11.875 1.95312 11.875H3.82812C4.04352 11.875 4.21875 12.0502 4.21875 12.2656V18.0469Z" />
			<path d="M10.9375 0H9.0625C7.98555 0 7.10938 0.876172 7.10938 1.95312V18.0469C7.10938 19.1238 7.98555 20 9.0625 20H10.9375C12.0145 20 12.8906 19.1238 12.8906 18.0469V1.95312C12.8906 0.876172 12.0145 0 10.9375 0ZM11.3281 18.0469C11.3281 18.2623 11.1529 18.4375 10.9375 18.4375H9.0625C8.84711 18.4375 8.67188 18.2623 8.67188 18.0469V1.95312C8.67188 1.73773 8.84711 1.5625 9.0625 1.5625H10.9375C11.1529 1.5625 11.3281 1.73773 11.3281 1.95312V18.0469Z" />
		</g>
	),
});

export const EuroIcon = createIcon({
	displayName: 'EuroIcon',
	viewBox: '0 0 384 384',
	path: (
		<g fill="currentColor">
			<path
				d="M285.306,296.302c-73.7,28.4-156.5-8.4-184.9-82.2c-0.5-1.4-1-2.7-1.5-4.1h147.8c5.5,0,10-4.5,10-10s-4.5-10-10-10h-153.1
			c-3-15.8-3.3-32.1-1-48h170.1c5.5,0,10-4.5,10-10s-4.5-10-10-10h-165.6c22.7-75.6,102.5-118.5,178.2-95.8c3.3,1,6.7,2.1,10.1,3.4
			c5.1,2,10.9-0.5,13-5.7c2-5.1-0.5-10.9-5.7-13h-0.1c-84-32.3-178.3,9.6-210.7,93.6c-2.2,5.7-4.1,11.5-5.6,17.4h-39.6
			c-5.5,0-10,4.5-10,10s4.5,10,10,10h35.7c-2,16-1.7,32.1,0.9,48h-36.6c-5.5,0-10,4.5-10,10s4.5,10,10,10h41.3
			c26.1,86.2,117.1,134.9,203.2,108.9c3.8-1.2,7.6-2.5,11.3-3.9c5.1-2,7.7-7.8,5.7-12.9
			C296.206,296.902,290.406,294.302,285.306,296.302z"
			/>
		</g>
	),
});

export const AccountIcon = createIcon({
	displayName: 'AccountIcon',
	viewBox: '0 0 22 22',
	path: (
		<g fill="currentColor" stroke="currentColor">
			<path
				d="M8.5 13.3438L3.08333 15.3437C1.83333 15.8021 1 17.0104 1 18.3437V20.0104H21V18.3437C21 17.0104 20.125 15.8021 18.875 15.3437L13.5 13.3438"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
			<path
				d="M16.0837 7.55168C16.0837 11.0517 13.7504 13.8433 10.8337 13.8433C7.91707 13.8433 5.58374 11.01 5.58374 7.55168C5.58374 4.09334 7.95874 1.26001 10.8337 1.26001C13.7504 1.26001 16.0837 4.09334 16.0837 7.55168Z"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
		</g>
	),
});

export const DocumentsIcon = createIcon({
	displayName: 'DocumentsIcon',
	viewBox: '0 0 24 21',
	path: (
		<path
			d="M23.9167 14.6662C24.1281 15.1305 23.9232 15.6779 23.4589 15.8883L13.3054 20.5035C13.1845 20.5589 13.0543 20.5866 12.9233 20.5866C12.8319 20.5866 12.7405 20.5728 12.6519 20.546L0.652302 16.8538C0.164934 16.7034 -0.108287 16.1865 0.0412464 15.7C0.19078 15.2127 0.708608 14.9367 1.19505 15.089L12.8605 18.6787L22.6946 14.2084C23.1635 13.9933 23.7072 14.2038 23.9167 14.6662ZM12.8605 14.9865L1.19505 11.3968C0.708608 11.2445 0.191703 11.5205 0.0412464 12.0078C-0.10921 12.4943 0.164934 13.0112 0.652302 13.1616L12.6519 16.8538C12.7405 16.8806 12.8319 16.8944 12.9233 16.8944C13.0543 16.8944 13.1845 16.8667 13.3054 16.8114L23.4589 12.1961C23.9232 11.9848 24.1281 11.4383 23.9167 10.974C23.7063 10.5116 23.1635 10.3039 22.6946 10.5162L12.8605 14.9865ZM0.00155549 8.63319C-0.0178285 8.24551 0.207395 7.88737 0.564613 7.73599L7.30377 4.89578C8.63018 4.33641 9.26154 3.63859 9.26154 1.18329C9.26154 0.899914 9.39169 0.631308 9.61507 0.456852C9.83845 0.281474 10.1292 0.21963 10.4052 0.287012L22.3485 3.22968C22.7602 3.33121 23.0509 3.70136 23.0509 4.12596V4.26626C23.0518 6.99663 23.0528 8.79933 19.7086 10.2088C17.4702 11.1522 13.358 13.0933 13.3174 13.1136C13.1937 13.1727 13.058 13.2023 12.9233 13.2023C12.8319 13.2023 12.7405 13.1884 12.6519 13.1616L0.652302 9.46947C0.281238 9.35501 0.0209394 9.02087 0.00155549 8.63319ZM3.62174 8.45135L12.8522 11.2925C13.8786 10.8097 17.0631 9.32085 18.9904 8.50857C21.0313 7.6483 21.1919 6.98647 21.203 4.84962L11.0587 2.34909C10.8806 4.24318 10.1624 5.69421 8.02189 6.59695L3.62174 8.45135Z"
			fill="currentColor"
		/>
	),
});

export const FacturationIcon = createIcon({
	displayName: 'FacturationIcon',
	viewBox: '0 0 24 25',
	path: (
		<g fill="currentColor">
			<path d="M21.375 4.01038H2.625C1.17759 4.01038 0 5.18797 0 6.63538V17.8854C0 19.3328 1.17759 20.5104 2.625 20.5104H21.375C22.8224 20.5104 24 19.3328 24 17.8854V6.63538C24 5.18797 22.8224 4.01038 21.375 4.01038ZM2.625 5.51038H21.375C21.9953 5.51038 22.5 6.01508 22.5 6.63538V8.13538H1.5V6.63538C1.5 6.01508 2.0047 5.51038 2.625 5.51038ZM21.375 19.0104H2.625C2.0047 19.0104 1.5 18.5057 1.5 17.8854V9.63538H22.5V17.8854C22.5 18.5057 21.9953 19.0104 21.375 19.0104Z" />
			<path d="M5.25 16.7604H4.5C4.08581 16.7604 3.75 16.4246 3.75 16.0104V15.2604C3.75 14.8462 4.08581 14.5104 4.5 14.5104H5.25C5.66419 14.5104 6 14.8462 6 15.2604V16.0104C6 16.4246 5.66419 16.7604 5.25 16.7604Z" />
		</g>
	),
});

export const PencilIcon = createIcon({
	displayName: 'PencilIcon',
	viewBox: '0 0 24 24',
	path: (
		<g fill="currentColor">
			<path d="M4 19C3.44772 19 3 19.4477 3 20C3 20.5523 3.44772 21 4 21V19ZM20 21C20.5523 21 21 20.5523 21 20C21 19.4477 20.5523 19 20 19V21ZM4 21H20V19H4V21Z" />
			<path d="M8 19.9998V20.9998C8.26522 20.9998 8.51957 20.8945 8.70711 20.707L8 19.9998ZM4 19.9998H3C3 20.5521 3.44772 20.9998 4 20.9998V19.9998ZM4 15.9998L3.29289 15.2927C3.10536 15.4803 3 15.7346 3 15.9998H4ZM15.2929 4.70696L16 5.41406L16 5.41406L15.2929 4.70696ZM16.7071 4.70696L16 5.41406L16 5.41406L16.7071 4.70696ZM19.2929 7.29274L20 6.58564V6.58564L19.2929 7.29274ZM19.2929 8.70696L18.5858 7.99985L19.2929 8.70696ZM8 18.9998H4V20.9998H8V18.9998ZM5 19.9998V15.9998H3V19.9998H5ZM4.70711 16.707L16 5.41406L14.5858 3.99985L3.29289 15.2927L4.70711 16.707ZM16 5.41406L18.5858 7.99985L20 6.58564L17.4142 3.99985L16 5.41406ZM18.5858 7.99985L7.29289 19.2927L8.70711 20.707L20 9.41406L18.5858 7.99985ZM18.5858 7.99985V7.99985L20 9.41406C20.781 8.63302 20.781 7.36669 20 6.58564L18.5858 7.99985ZM16 5.41406H16L17.4142 3.99985C16.6332 3.2188 15.3668 3.2188 14.5858 3.99985L16 5.41406Z" />
		</g>
	),
});

export const SecurityIcon = createIcon({
	displayName: 'SecurityIcon',
	viewBox: '0 0 512 512',
	path: (
		<g fill="currentColor">
			<path d="m459.669 82.906-196-81.377c-4.91-2.038-10.429-2.039-15.338 0l-196 81.377c-7.465 3.1-12.331 10.388-12.331 18.471v98.925c0 136.213 82.329 258.74 208.442 310.215 4.844 1.977 10.271 1.977 15.116 0 126.111-51.474 208.442-174.001 208.442-310.215v-98.925c0-8.083-4.865-15.371-12.331-18.471zm-27.669 117.396c0 115.795-68 222.392-176 269.974-105.114-46.311-176-151.041-176-269.974v-85.573l176-73.074 176 73.074zm-198.106 67.414 85.964-85.963c7.81-7.81 20.473-7.811 28.284 0s7.81 20.474-.001 28.284l-100.105 100.105c-7.812 7.812-20.475 7.809-28.284 0l-55.894-55.894c-7.811-7.811-7.811-20.474 0-28.284s20.474-7.811 28.284 0z" />
		</g>
	),
});

export const LogoutIcon = createIcon({
	displayName: 'DashboardIcon',
	viewBox: '0 0 16 16',
	path: (
		<g fill="currentColor">
			<path
				d="M11.3542 15.25C11.3542 15.4489 11.2752 15.6397 11.1346 15.7803C10.9939 15.921 10.8031 16 10.6042 16H3.53381C2.871 15.9993 2.23555 15.7356 1.76687 15.2669C1.29819 14.7983 1.03456 14.1628 1.03381 13.5V2.5C1.03456 1.83719 1.29819 1.20173 1.76687 0.733055C2.23555 0.264376 2.871 0.000744409 3.53381 0L10.6042 0C10.8031 0 10.9939 0.0790176 11.1346 0.21967C11.2752 0.360322 11.3542 0.551088 11.3542 0.75C11.3542 0.948912 11.2752 1.13968 11.1346 1.28033C10.9939 1.42098 10.8031 1.5 10.6042 1.5H3.53381C3.26869 1.50029 3.0145 1.60574 2.82703 1.79321C2.63955 1.98069 2.5341 2.23487 2.53381 2.5V13.5C2.5341 13.7651 2.63954 14.0193 2.82702 14.2068C3.01449 14.3943 3.26868 14.4997 3.53381 14.5H10.6042C10.8031 14.5 10.9939 14.579 11.1346 14.7197C11.2752 14.8603 11.3542 15.0511 11.3542 15.25ZM14.7465 7.46969L11.1824 3.90556C11.1131 3.83464 11.0303 3.77819 10.939 3.73946C10.8477 3.70074 10.7496 3.68052 10.6504 3.67997C10.5512 3.67942 10.4528 3.69855 10.3611 3.73626C10.2693 3.77397 10.186 3.82951 10.1158 3.89966C10.0457 3.9698 9.99018 4.05317 9.95248 4.14492C9.91478 4.23668 9.89566 4.335 9.89622 4.4342C9.89678 4.53339 9.91702 4.63149 9.95575 4.72282C9.99448 4.81414 10.051 4.89687 10.1219 4.96622L12.4055 7.25H6.50128C6.30237 7.25 6.11161 7.32902 5.97095 7.46967C5.8303 7.61032 5.75128 7.80109 5.75128 8C5.75128 8.19891 5.8303 8.38968 5.97095 8.53033C6.11161 8.67098 6.30237 8.75 6.50128 8.75H12.4055L10.1218 11.0337C10.0518 11.1033 9.99626 11.186 9.95832 11.2771C9.92037 11.3682 9.90077 11.4658 9.90064 11.5645C9.90051 11.6632 9.91984 11.7609 9.95753 11.8521C9.99523 11.9432 10.0505 12.0261 10.1203 12.0958C10.1901 12.1656 10.2729 12.2209 10.3641 12.2586C10.4553 12.2963 10.553 12.3157 10.6517 12.3155C10.7503 12.3154 10.848 12.2958 10.9391 12.2578C11.0301 12.2199 11.1128 12.1644 11.1824 12.0944L14.7465 8.53034C14.8872 8.38969 14.9662 8.19893 14.9662 8.00002C14.9662 7.8011 14.8872 7.61034 14.7465 7.46969Z"
				fill="currentColor"
			/>
		</g>
	),
});

export const HouseIcon = createIcon({
	displayName: 'HouseIcon',
	viewBox: '0 0 30 30',
	path: (
		<path
			d="M24.5731 12.1265L15.8231 4.47029C15.3518 4.05792 14.6482 4.05792 14.1769 4.47029L5.42687 12.1265C5.1556 12.3639 5 12.7068 5 13.0673V23.7501C5 24.4404 5.55964 25.0001 6.25 25.0001H11.25C11.9404 25.0001 12.5 24.4404 12.5 23.7501V18.7501C12.5 18.0597 13.0596 17.5001 13.75 17.5001H16.25C16.9404 17.5001 17.5 18.0597 17.5 18.7501V23.7501C17.5 24.4404 18.0596 25.0001 18.75 25.0001H23.75C24.4404 25.0001 25 24.4404 25 23.7501V13.0673C25 12.7068 24.8444 12.3639 24.5731 12.1265Z"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	),
});
