import textStyles from 'theme/foundations/typography';

const style = {
	baseStyle: {
		indicator: {
			background: 'transparent',
			'&[data-status=active]': {
				borderColor: 'transparent',
				background: 'primary.yellow',
			},
			'&[data-status=complete]': {
				borderWidth: '2px',
				borderColor: 'primary.black',
				background: 'transparent',
			},
		},
		icon: {
			color: 'primary.black',
		},
		number: {
			...textStyles['Text-M-Regular'],
			color: 'grey.700',
			'&[data-status=complete]': {
				color: 'primary.black',
			},
			'&[data-status=active]': {
				color: 'primary.black',
			},
		},
		title: {
			...textStyles['Text-M-Regular'],
			color: 'grey.700',
			'&[data-status=complete]': {
				color: 'primary.black',
			},
			'&[data-status=active]': {
				color: 'primary.black',
			},
		},
		separator: {
			marginTop: '12px',
			alignSelf: 'start',
			'&[data-status=complete]': {
				background: 'primary.black',
			},
		},
	},
	variants: {
		mobile: {
			stepper: {
				gap: 0,
			},
			step: {
				gap: 0,
			},
			separator: {
				'&[data-orientation=horizontal]': {
					ml: 0,
				},
			},
		},
		subscription: {
			stepper: {
				gap: 0,
			},
			icon: {
				width: '10px',
				height: '10px',
			},
			step: {
				gap: 0,
			},
			separator: {
				background: 'transparent !important',
				borderColor: 'grey.500',
				height: '1px !important',
				borderWidth: '0.5px',
				borderStyle: 'dashed',

				minW: '16px',
				marginTop: '7px',
				marginStart: '0 !important',
			},
			indicator: {
				width: '16px',
				height: '16px',
				position: 'relative',
				borderWidth: '1px !important',
				'&[data-status=active]': {
					borderColor: 'primary.yellow',
					background: 'transparent',
					'::after': {
						content: '""',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '10px',
						height: '10px',
						borderRadius: 'full',
						background: 'primary.yellow',
					},
				},
			},
		},
	},
	defaultProps: {
		size: 'sm',
	},
};

export default style;
