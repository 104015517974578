import { Card, CardProps, Heading, Image, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import Custom from 'assets/images/PortofolioTypes/Custom.png';
import Elite from 'assets/images/PortofolioTypes/Elite.png';
import Essential from 'assets/images/PortofolioTypes/Essential.png';
import Flagship from 'assets/images/PortofolioTypes/Flagship.png';
import { PortfolioType, portfolioTypeLabels } from 'store/types/profilage.type';
import colors from 'theme/foundations/colors';

interface ContractTypeCardProps extends CardProps {
	risque: number;
	temperature?: number;
	portfolioType: PortfolioType;
}

const ContractTypeCard = ({ risque, temperature, portfolioType, ...rest }: ContractTypeCardProps) => {
	const portfolioIcons: Record<PortfolioType, string> = {
		FLAGSHIP: Flagship,
		ESSENTIAL: Essential,
		ELITE: Elite,
		CUSTOM: Custom,
	};

	return (
		<Card w="100%" position="relative" variant="portfolio" type={portfolioType} h="100%" {...rest}>
			<Image w="100px" position="absolute" top="10px" right="0" src={portfolioIcons[portfolioType]} />
			<VStack w="100%" align="left" h="100%" spacing="16px">
				<Heading variant="Title-L-SemiBold" color={colors.primary.black}>
					{portfolioTypeLabels[portfolioType]}
				</Heading>
				<Wrap spacing="16px">
					<WrapItem minW="100px">
						<VStack w="100%" align="left" h="100%" spacing="0px">
							<Text variant="Caption" color={colors.primary.black}>
								Risque
							</Text>
							<Heading variant="Title-XL-SemiBold">
								{risque}
								<Text as="span" variant="Text-M-Bold">
									/10
								</Text>
							</Heading>
						</VStack>
					</WrapItem>
					{temperature && (
						<WrapItem minW="100px">
							<VStack w="100%" align="left" h="100%" spacing="0px">
								<Text variant="Caption" color={colors.primary.black}>
									Temperature
								</Text>
								<Heading variant="Title-XL-SemiBold">
									{temperature}
									<Text as="span" variant="Text-L-Bold">
										°C
									</Text>
								</Heading>
							</VStack>
						</WrapItem>
					)}
				</Wrap>
			</VStack>
		</Card>
	);
};
export default ContractTypeCard;
