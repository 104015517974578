import { Heading, VStack } from '@chakra-ui/react';

import BottomStepBar from 'components/steppers/BottomStepBar';
import FoyerFiscalForm from 'onboarding/KYC/Forms/FoyerFiscal';
import { OnboardingStepProps } from 'onboarding/Stepper/stepper.slice';

const FiscalSituationForm = ({ onNext, onPrev }: OnboardingStepProps): JSX.Element => {
	return (
		<VStack w="100%" spacing="32px" align="start">
			<Heading variant="bigTitle" fontSize={{ base: 16, md: 20 }}>
				Ma situation fiscale
			</Heading>

			<FoyerFiscalForm onSuccess={onNext} formConfirmation={<BottomStepBar onPrev={onPrev} nextSubmit />} />
		</VStack>
	);
};

export default FiscalSituationForm;
