import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading, HStack, Progress, Text, useDisclosure, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import PeIcon from 'assets/icons/ProductsColoredIcons/Pe.svg';
import PerIcon from 'assets/icons/ProductsColoredIcons/Per.svg';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { useFoyerAnnualNetIncome } from 'hooks/useFoyerIncome';
import useThemedToast from 'hooks/useThemedToast';
import ImproveTaxationCard from 'pages/Fiscalite/OptimiseFiscalite/ActionCards/ImproveTaxationCard';
import CreateVersement from 'pages/Invest/Versements/CreateVersement';
import eventTracker from 'services/events/eventTracker';
import {
	TaxDataPerFiscalProduct,
	useGetImpositionFiscaliteQuery,
	useLazyGetUpdatedRepartitionQuery,
} from 'services/requests/fiscalite';
import { useGetContractsQuery } from 'services/requests/invest/contracts';
import { useGetKYCQuery } from 'services/requests/kyc';
import { useGetUserQuery } from 'services/requests/user';
import { ProfessionalSituation } from 'store/referentiels/professionalSituation';
import { ProductType } from 'store/types/global.type';
import { displayMoneyNoDigits } from 'utils/rendering';

import { ActionCardType } from './ActionCards/ActionCard.type';
import { PopUpCardProps } from './ActionCards/PopUpCard';

enum OptimisationProductType {
	OPEN_PER = 'OPEN_PER',
	MAXIMIZE_PER = 'MAXIMIZE_PER',
	FCPI = 'FCPI',
}

export type ActionCardProps = {
	product: Exclude<ProductType, ProductType.CASH>;
	title: string;
	potentialTaxEconomy: number;
	popUpCard?: Record<ActionCardType, PopUpCardProps>;
	icon: string;
	cta: string;
	buttonText: string;
	// progress: number;
	progressData: TaxDataPerFiscalProduct;
	ramifyTip: ReactNode;
	onClickButton: () => void;
};

// table avec le wording en fonction du type de produit
const PRODUCTS_WORDING: Record<Exclude<ProductType, ProductType.CASH>, { aum: string; tax: string }> = {
	[ProductType.INVEST]: {
		aum: 'Somme des versements',
		tax: "Économie d'impôt",
	},
	[ProductType.SCPI]: {
		aum: 'Capital investi',
		tax: "Réduction d'impôt",
	},
	[ProductType.PE]: {
		aum: 'Capital investi',
		tax: "Réduction d'impôt",
	},
};

const OptimiseFiscalite: FC = () => {
	const { data: fiscalData, isLoading } = useGetImpositionFiscaliteQuery();
	const { data: contracts, isLoading: isLoadingContracts } = useGetContractsQuery();
	const { data: user } = useGetUserQuery();
	const { data: kyc } = useGetKYCQuery();
	const [getUpdatedRepartition] = useLazyGetUpdatedRepartitionQuery();
	const annualNetIncome = useFoyerAnnualNetIncome();
	const smallText = useAppResponsive({ base: true, md: false });

	const navigate = useNavigate();
	const toast = useThemedToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const sumVP = useMemo(
		() => contracts?.reduce((acc, c) => acc + +(c.programmedVersement?.amount ?? 0), 0),
		[contracts],
	);

	const contratPer = useMemo(() => contracts?.find((contract) => contract.envelope.type === 'PER'), [contracts]);

	useEffect(() => {
		const monthlySavingBasedOnIcome = Math.round(annualNetIncome / 12 / 10);
		if (annualNetIncome / 12 / 10 > 0 || (sumVP && sumVP > 0)) {
			getUpdatedRepartition({ monthlySavingCapacity: sumVP || monthlySavingBasedOnIcome });
		}
	}, [getUpdatedRepartition, annualNetIncome, sumVP]);

	const newFormData = {
		amount: fiscalData?.taxDataPerFiscalProduct.PER.residualAmountToInvest || 0,
		contract: contratPer,
	};

	const onCreateVcSuccess = () => {
		toast({
			status: 'success',
			title: 'Demande signée',
			description: 'Votre demande de versement a été prise en compte, nous la traitons dans les plus brefs délais',
		});
		onClose();
	};

	const handleClickFiscalityStep = (invest: string): void => {
		if (invest === 'per') navigate('/invest/ouverture-per-individuel');
		else if (invest === 'enveloppes') navigate('/invest/versements', { state: { isRecommandationVpActive: true } });
		else if (invest === 'maximizePer') onOpen();
		else if (invest === 'fcpi') navigate('/private-equity?fundType=revenueTaxAdvantage');
	};

	if ((isLoading && !fiscalData) || !user || (isLoadingContracts && !contracts)) return null;
	if (!fiscalData || !kyc) return null;

	const actionCards: ActionCardProps[] = [];
	const allOptions: Record<OptimisationProductType, ActionCardProps> = {
		[OptimisationProductType.OPEN_PER]: {
			title: 'PER Individuel',
			cta: 'Ouvrir un PER',
			product: ProductType.INVEST,
			buttonText: 'Ouvrir un PER',
			ramifyTip: `En investissant via un PER, vous pouvez déduire ${displayMoneyNoDigits(
				fiscalData.taxDataPerFiscalProduct.PER.maximalAmountToInvest,
			)} de votre revenu imposable. L’économie d’impôt qui en découle s’élève à ${displayMoneyNoDigits(
				fiscalData.taxDataPerFiscalProduct.PER.potentialTaxBenefit,
			)}.`,
			potentialTaxEconomy: Math.round(fiscalData?.taxDataPerFiscalProduct.PER.potentialTaxBenefit || 0),
			icon: PerIcon,
			onClickButton: () => {
				eventTracker.mixpanel.track('Fiscalite-CarteOuvrirPER');
				handleClickFiscalityStep('per');
			},
			progressData: fiscalData.taxDataPerFiscalProduct.PER,
		},
		[OptimisationProductType.MAXIMIZE_PER]: {
			title: 'PER Individuel',
			product: ProductType.INVEST,
			cta: 'Maximiser mon PER',
			buttonText: 'Maximiser mon PER',
			ramifyTip: `Nous vous conseillons de verser au maximum ${displayMoneyNoDigits(
				fiscalData.taxDataPerFiscalProduct.PER.maximalAmountToInvest,
			)} pour économiser ${displayMoneyNoDigits(
				fiscalData.taxDataPerFiscalProduct.PER.residualTaxBenefit,
			)} sur vos impôts.`,
			potentialTaxEconomy: Math.round(fiscalData?.taxDataPerFiscalProduct.PER.residualTaxBenefit || 0),
			icon: PerIcon,
			onClickButton: () => {
				eventTracker.mixpanel.track('Fiscalite-CarteMaximiserPER');
				handleClickFiscalityStep('maximizePer');
			},
			progressData: fiscalData.taxDataPerFiscalProduct.PER,
		},
		[OptimisationProductType.FCPI]: {
			cta: 'Investir en FCPI',
			product: ProductType.PE,
			buttonText: smallText ? 'Investir en Private Equity' : 'Investir en Private Equity pour défiscaliser',
			title: 'Private Equity',
			ramifyTip: (
				<>
					Vous pouvez réduire votre impôt de{' '}
					{displayMoneyNoDigits(fiscalData.taxDataPerFiscalProduct.FCPI.residualTaxBenefit)} en investissant un montant
					de <b>{displayMoneyNoDigits(fiscalData.taxDataPerFiscalProduct.FCPI.residualAmountToInvest)}</b> dans des
					fonds de Private Equity avec avantages fiscaux (FCPI, FIP, FIP-Corse, etc). Cet investissement vous débloquera
					une réduction d'impôt d'environ 25% du montant investi.
				</>
			),
			potentialTaxEconomy: Math.round(fiscalData?.taxDataPerFiscalProduct.FCPI.residualTaxBenefit || 0),
			icon: PeIcon,
			onClickButton: () => {
				eventTracker.mixpanel.track('Fiscalite-CarteFCPI');
				handleClickFiscalityStep('fcpi');
			},
			progressData: fiscalData.taxDataPerFiscalProduct.FCPI,
		},
	};

	// USER IS NOT RETIRED && PER IS NOT OPEN
	if (
		fiscalData?.taxDataPerFiscalProduct.PER.potentialTaxBenefit !== 0 &&
		kyc?.professionalSituation !== ProfessionalSituation.RETIRED &&
		!contracts?.find((c) => c.envelope.type === 'PER')
	) {
		actionCards.push(allOptions[OptimisationProductType.OPEN_PER]);
	}

	// USER HAS PER
	if (!!contracts?.find((c) => c.envelope.type === 'PER'))
		actionCards.push(allOptions[OptimisationProductType.MAXIMIZE_PER]);

	// USER HAS FCPI
	if (fiscalData?.taxDataPerFiscalProduct.FCPI.maximalAmountToInvest > 1000)
		actionCards.push(allOptions[OptimisationProductType.FCPI]);

	return (
		<VStack w="100%" align="start" spacing="lg">
			{isOpen && <CreateVersement onSuccess={onCreateVcSuccess} onClose={onClose} prefilledData={newFormData} />}
			<Heading variant="Title-L-SemiBold">Opportunités pour optimiser ma fiscalité</Heading>
			<Wrap w="100%" align="start" spacing="md">
				{actionCards.map(({ progressData, ...card }) => (
					<WrapItem key={card.title} flex="1" alignSelf="stretch">
						<ImproveTaxationCard {...card}>
							<HStack w="100%" align="start">
								<VStack flex="1" align="start">
									<Text variant="Text-M-Medium" color="grey.900">
										{PRODUCTS_WORDING[card.product].aum}
									</Text>
									<Heading variant="Title-L-SemiBold">{displayMoneyNoDigits(progressData.investedAmount)}</Heading>
								</VStack>
								<VStack flex="1" align="start">
									<Text variant="Text-M-Medium" color="grey.900">
										{PRODUCTS_WORDING[card.product].tax}
									</Text>
									<Heading variant="Title-L-SemiBold" color="informative.900">
										{displayMoneyNoDigits(progressData.taxBenefit)}
									</Heading>
									<Progress
										max={1}
										value={Math.max(0.01, progressData.taxBenefit / progressData.potentialTaxBenefit)}
										w="100%"
									/>
								</VStack>
							</HStack>
						</ImproveTaxationCard>
					</WrapItem>
				))}
			</Wrap>
		</VStack>
	);
};

export default OptimiseFiscalite;
