import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Center, Heading, HStack, Image, Spinner, Stack, Text, VStack } from '@chakra-ui/react';

import Article from 'components/Articles/Article';
import useThemedToast from 'hooks/useThemedToast';
import {
	useGetCorporateAttributionsByCompanyIdQuery,
	useLazyRevalorisationSimulationQuery,
} from 'services/requests/corporate';

import AttributionCards from './AttributionSection';
import SimulationSection from './SimulationSection';
import StatCard from './StatisticsSection';
import VestingCard from './VestingSection';

const BSPCE: FC = () => {
	const toast = useThemedToast();
	const { id } = useParams<{ id: string }>();
	const { data, isLoading, isError } = useGetCorporateAttributionsByCompanyIdQuery(id!);
	const [simulation, { data: simulationData, isLoading: isSimuLoading, isError: isSimuError }] =
		useLazyRevalorisationSimulationQuery();

	useEffect(() => {
		if (data?.length)
			simulation({
				actual_part_value: data[0].company.lastShareValuation,
				next_fund_raising_multiple: 2,
				list_attributions: data.map((d) => ({
					id: d.id,
					attribution_date: d.vestingStartDate,
					strike_price: d.exerciceCost,
					parts_number: d.nbShares,
					fund_raising_stage: d.round,
				})),
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (isError || isSimuError)
			toast({ title: 'Une erreur est survenue lors du chargement de vos informations', status: 'error' });
	}, [isError, isSimuError, toast]);

	if (isLoading || isSimuLoading)
		return (
			<Center w="100%" mt="40px">
				<Spinner />
			</Center>
		);
	if (isError || isSimuError || !data || !simulationData) return null;

	return (
		<VStack spacing="40px" w="100%" mt="40px">
			<Stack direction={{ base: 'column', md: 'row' }} justify="space-between" w="100%">
				<HStack w="100%" justify="left" align="center">
					<Heading variant="Title-S-SemiBold">Vos BSPCE avec {data[0].companyName}</Heading>
					<Image h="40px" w="40px" src={data[0].company.logo} />
				</HStack>
				<Text whiteSpace="nowrap">
					Valorisé le{' '}
					{new Date(data[0].company.lastShareValuationDate).toLocaleDateString('fr-FR', {
						year: 'numeric',
						month: 'short',
						day: 'numeric',
					})}
				</Text>
			</Stack>

			<StatCard
				valuation={simulationData.valorisation}
				theoricalGain={simulationData.theoretical_gain}
				nbShares={simulationData.total_parts_number}
				shareValue={data[0].company.lastShareValuation}
			/>

			<VestingCard statData={simulationData.vested_parts_repartition} graphData={simulationData.graph_repartition} />

			<AttributionCards
				attributionData={data}
				vestedRepartition={simulationData.vested_parts_repartition_by_attribution}
			/>

			<SimulationSection attributionData={data} simulationData={simulationData} />

			<Article
				imageURL="https://assets.website-files.com/63c1a162348341b917d77638/6489ae20cc09ab63068aa27e_image-p-500.png"
				title="Le Guide des BSPCE: Fonctionnement et Avantages"
				description="Les BSPCE sont, pour l’employé, l’équivalent de bons d’achat permettant d’obtenir des parts de son entreprise à un prix déterminé à l’avance, c’est-à-dire le prix d’exercice."
				tags={[{ label: 'Guide', variant: 'coloredBgYellow' }]}
				btnCallback={() => {
					window.open('https://www.ramify.fr/gestion-de-patrimoine/bspce');
				}}
			/>
		</VStack>
	);
};
export default BSPCE;
