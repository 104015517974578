import { backendApi } from 'services/apiService';

export interface BankInformation {
	id: string;
	IBAN: string;
	BIC: string;
	urlIBAN: string;
	holder: string;
}

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getBankAccounts: builder.query<BankInformation[], void>({
			query: () => '/bank-informations',
			providesTags: (result) =>
				result
					? [...result.map(({ id }) => ({ type: 'BankAccount' as const, id })), { type: 'BankAccount', id: 'LIST' }]
					: [{ type: 'BankAccount', id: 'LIST' }],
		}),
		addBankAccount: builder.mutation<BankInformation, FormData>({
			query: (params) => ({
				url: '/bank-informations',
				method: 'POST',
				body: params,
			}),
			invalidatesTags: [{ type: 'BankAccount', id: 'LIST' }],
		}),
		deleteBankAccount: builder.mutation<boolean, string>({
			query: (params) => ({
				url: `/bank-informations/${params}`,
				method: 'DELETE',
			}),
			invalidatesTags: [{ type: 'BankAccount', id: 'LIST' }],
		}),
	}),
});

export const { useGetBankAccountsQuery, useAddBankAccountMutation, useDeleteBankAccountMutation } = extendedApi;
