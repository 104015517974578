import { Controller, useFormContext } from 'react-hook-form';
import {
	Box,
	FormControl,
	FormErrorMessage,
	FormLabel,
	ListItem,
	Select,
	Stack,
	Text,
	UnorderedList,
	VStack,
} from '@chakra-ui/react';

import { ControlledDropZone } from 'components/inputs/Dropzone';
import MultipleCheckboxSelect from 'components/inputs/MultipleCheckboxSelect';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';

type FundOriginSource = 'ofRevenusPro' | 'ofSaving' | 'ofVente' | 'ofCessionActifs' | 'ofSuccessionDonation';
const fundsOriginOptions = Object.entries({
	ofRevenusPro: 'Salaires et Revenus',
	ofSaving: 'Epargne déjà constituée',
	ofCessionActifs: 'Cession',
	ofSuccessionDonation: 'Donation / Héritage',
	ofVente: 'Vente de bien immobilier / Mobilier',
}).map(([value, label]) => ({ label, value })) as {
	label: string;
	value: FundOriginSource;
}[];

type FundsOriginGeography = 'France' | 'Other';
const fundsOriginGeographyOptions = Object.entries({
	France: 'France',
	Other: 'Autre',
}).map(([value, label]) => ({ label, value })) as {
	label: string;
	value: FundsOriginGeography;
}[];

type ObjectiveSubscriptionType =
	| 'ADDITIONNAL_REVENUE'
	| 'PATRIMONY_CONSTITUTION'
	| 'FISCAL_OPTIMISATION'
	| 'PATRIMONY_DIVERSIFICATION'
	| 'PATRIMONY_TRANSMITION'
	| 'OTHER';
const objectivesOptions = Object.entries({
	ADDITIONNAL_REVENUE: 'Revenu additionnel',
	PATRIMONY_CONSTITUTION: 'Constitution d’un patrimoine',
	FISCAL_OPTIMISATION: 'Optimisation fiscale',
	PATRIMONY_DIVERSIFICATION: 'Diversification de patrimoine',
	PATRIMONY_TRANSMITION: 'Transmission de patrimoine aux proches',
	OTHER: 'Autre',
}).map(([value, label]) => ({ label, value })) as {
	label: string;
	value: ObjectiveSubscriptionType;
}[];

type FundJustificatifFormData = {
	fundsOrigin: FundOriginSource;
	jof: File;
	fundsOriginGeography: FundsOriginGeography;
	objectives: string[];
};

type FundJustificatifFormContextProps = {
	includeFundsOriginGeography?: boolean;
	includeObjectives?: boolean;
};

const FundJustificatifFormContext = ({
	includeFundsOriginGeography = true,
	includeObjectives = true,
}: FundJustificatifFormContextProps): JSX.Element => {
	const methods = useFormContext<FundJustificatifFormData>();
	const {
		control,
		formState: { errors },
		register,
	} = methods;

	return (
		<VStack align="start" w="100%" spacing="32px">
			<Stack direction={{ base: 'column', md: 'row' }} align="start" spacing="32px" w="100%">
				<VStack align="start" spacing="28px" w="100%">
					<FormControl isInvalid={!!errors.fundsOrigin}>
						<FormLabel>Origine des fonds ?</FormLabel>
						<Select placeholder="-" {...register('fundsOrigin', { required: true })}>
							{fundsOriginOptions.map(({ value, label }) => (
								<option value={value} key={value}>
									{label}
								</option>
							))}
						</Select>

						{errors.fundsOrigin?.type === 'required' && <FormErrorMessage children="Ce champ est nécessaire" />}
					</FormControl>
					<VStack align="start" spacing="6px" w="100%">
						<Text variant="Text-M-regular" color="grey.900">
							Merci de nous fournir le <b>relevé de votre compte bancaire </b>
							utilisé pour la souscription et remplissant les conditions suivantes :
						</Text>
						<UnorderedList paddingLeft="24px">
							<ListItem>
								<Text variant="Text-M-regular" color="grey.900">
									Daté de <b>moins de 3 mois</b>
								</Text>
							</ListItem>
							<ListItem>
								<Text variant="Text-M-regular" color="grey.900">
									Faisant apparaître les fonds de la souscription à minima, ainsi que votre nom et prénom
								</Text>
							</ListItem>
						</UnorderedList>
					</VStack>
				</VStack>

				<FormControl isInvalid={!!errors.jof}>
					<FormLabel>Justificatif d'origine des fonds</FormLabel>

					<ControlledDropZone control={control} name="jof" />

					{errors.jof?.type === 'required' && (
						<FormErrorMessage children="Veuillez renseigner un justificatif d'origine des fonds" />
					)}
				</FormControl>
			</Stack>

			{includeFundsOriginGeography && (
				<FormControl w={onboardingInputWidth} isInvalid={!!errors.fundsOriginGeography}>
					<FormLabel>Origine géographique des fonds ?</FormLabel>
					<Select placeholder="-" {...register('fundsOriginGeography', { required: true })}>
						{fundsOriginGeographyOptions.map(({ value, label }) => (
							<option value={value} key={value}>
								{label}
							</option>
						))}
					</Select>

					{errors.fundsOriginGeography?.type === 'required' && <FormErrorMessage children="Ce champ est nécessaire" />}
				</FormControl>
			)}

			{includeObjectives && (
				<FormControl isInvalid={!!errors.objectives}>
					<FormLabel>Objectifs poursuivis par le souscripteur sur cet investissement (2 choix possibles)</FormLabel>

					<Controller
						control={control}
						rules={{ required: true, validate: (value: string[]) => value.length <= 2 }}
						name="objectives"
						render={({ field: { onChange, value, ref, name } }) => (
							<Box as="fieldset" ref={ref} name={name}>
								<MultipleCheckboxSelect onChange={onChange} value={value} options={objectivesOptions} />
							</Box>
						)}
					/>

					{errors.objectives?.type === 'required' && (
						<FormErrorMessage>Merci de séléctionner au minimum 1 option</FormErrorMessage>
					)}
					{errors.objectives?.type === 'validate' && (
						<FormErrorMessage>Merci de séléctionner au maximum 2 options</FormErrorMessage>
					)}
				</FormControl>
			)}
		</VStack>
	);
};

export default FundJustificatifFormContext;
