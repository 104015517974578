import { Heading, Wrap, WrapItem } from '@chakra-ui/react';

import InvestmentMetricCard from 'components/Metrics/InvestmentMetrics/Card';

type InfosCardsProps = {
	icon: React.ReactNode;
	title: string;
	value: React.ReactNode;
};

const InfosCards = ({ elements }: { elements: InfosCardsProps[] }): JSX.Element => {
	return (
		<Wrap w="100%" spacingX="md">
			{elements.map((element) => (
				<WrapItem flex={1} key={element.title}>
					<InvestmentMetricCard icon={element.icon} title={element.title}>
						<Heading variant="Title-S-SemiBold">{element.value}</Heading>
					</InvestmentMetricCard>
				</WrapItem>
			))}
		</Wrap>
	);
};

export default InfosCards;
