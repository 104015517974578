import axios from 'axios';

import { SCPISubscriptionRepartition, SubscriptionRepartition } from 'services/requests/realEstate/simulations';
import { RequestVersementType } from 'services/requests/versement';
import { Banner, PrivateEquitySubscription, SCPISubscription } from 'store/types/airtable.type';
import { EnvelopeType } from 'store/types/global.type';
import { Notification } from 'store/types/notification.type';
import { environmentConstants, pipedreamConstants } from 'utils/constants';

export class PipedreamService {
	private email?: string;

	identify(email: string) {
		this.email = email;
	}

	unidentify() {
		this.email = undefined;
	}

	//
	// APP ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//

	triggerWithLink(link: string) {
		if (environmentConstants.environment === 'production') {
			axios.post(link, { email: this.email });
		}
	}

	newAffiliate(inviteeEmail: string) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.newAffiliateUrl, { email: this.email, inviteeEmail });
		}
	}

	bannerImpression(bannerData: Banner) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.bannerImpressionUrl, { email: this.email, data: bannerData });
		}
	}

	bannerEngagement(bannerData: Banner) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.bannerEngagementUrl, { email: this.email, data: bannerData });
		}
	}

	//
	// INVEST ////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//

	perTransferInterest() {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.perTransferUrl, { email: this.email });
		}
	}

	versementInterest() {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.interestVersementUrl, { email: this.email });
		}
	}

	signVersementError(data: {
		errorMessage: string;
		message: string;
		envelopeType: EnvelopeType;
		amount: number;
		operationType: RequestVersementType;
	}) {
		if (environmentConstants.environment === 'production') {
			// TODO use a proper pipedream
			axios.post(pipedreamConstants.depositErrorUrl, { ...data, email: this.email });
		}
	}

	//
	// SCPI //////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//

	scpiInterest() {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.scpiInterestUrl, { email: this.email });
		}
	}

	scpiIntention(
		projectType: SCPISubscription['projectType'],
		amountVersement: SCPISubscription['amountVersement'],
		fund: SCPISubscription['fundName'],
		horizon?: Date,
	) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.scpiIntentionUrl, {
				email: this.email,
				projectType,
				amountVersement,
				fund,
				horizon,
			});
		}
	}

	scpiSimulation() {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.scpiSimulationUrl, { email: this.email });
		}
	}

	scpiSubscription(id: SCPISubscription['id']) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.scpiSubscriptionUrl, {
				email: this.email,
				id,
			});
		}
	}

	async scpiSubscriptionSimulation(timeHorizon: string, repartition: SCPISubscriptionRepartition[]) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.scpiSubscriptionSimulationUrl, {
				email: this.email,
				timeHorizon,
				repartition,
			});
		}
	}

	async scpiCreditMailOffer() {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.scpiCreditMailOfferUrl, { email: this.email });
		}
	}

	async crowdfundingOpportunityMail() {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.crowdfundingOpportunityUrl, { email: this.email });
		}
	}

	//
	// PE ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//

	peInterest() {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.peInterestUrl, { email: this.email });
		}
	}

	peIntention(
		amountVersement: PrivateEquitySubscription['amountVersement'],
		fund: PrivateEquitySubscription['fundName'],
	) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.peIntentionUrl, {
				email: this.email,
				amountVersement,
				fund,
			});
		}
	}

	peSimulation() {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.peSimulationUrl, { email: this.email });
		}
	}

	peSubscription(id: PrivateEquitySubscription['id']) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.peSubscriptionUrl, {
				email: this.email,
				id,
			});
		}
	}

	async peSubscriptionSimulation(repartition: SubscriptionRepartition[]) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.peSubscriptionSimulationUrl, {
				email: this.email,
				repartition,
			});
		}
	}

	//
	// PE / SPCI
	//

	bulletinGeneration(success: boolean, product: string, name: string, message?: string) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.bulletinGenerationUrl, { email: this.email, success, product, name, message });
		}
	}

	async addWishListToAirTable(type: 'PE' | 'SCPI', date: string, demande: string) {
		if (environmentConstants.environment === 'production') {
			const prodUrl = {
				SCPI: pipedreamConstants.addWishListToAirTableSCPI,
				PE: pipedreamConstants.addWishListToAirTablePE,
			};
			axios.post(prodUrl[type], { Email: this.email, Date: date, Demande: demande });
		}
	}

	//
	// SAVINGS ACCOUNTS ////////////////////////////////////////////////////////////////////////////////////////////////////
	//

	async joinWaitingListSavingsAccount(amount: number) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.savingsAccountWaitingList, { email: this.email, amount });
		}
	}

	savingsAccountIntention(initialAUM: number | undefined) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.savingsAccountIntentionUrl, { email: this.email, initialAUM });
		}
	}

	savingsAccountSigned(initialAUM: number | undefined) {
		if (environmentConstants.environment === 'production') {
			axios.post(pipedreamConstants.savingsAccountSignedUrl, { email: this.email, initialAUM });
		}
	}

	//
	// NOTIFICATION ////////////////////////////////////////////////////////////////////////////////////////////////////
	//

	async notificationSeen(data: Notification) {
		if (environmentConstants.environment === 'production') {
			await axios.post(pipedreamConstants.notificationSeen, { email: this.email, data });
		}
	}

	async notificationClicked(data: Notification) {
		if (environmentConstants.environment === 'production') {
			await axios.post(pipedreamConstants.notificationClicked, { email: this.email, data });
		}
	}
}
