import { backendApi } from 'services/apiService';

type Partner = {
	id: string;
	name: string;
	promotionText: string;
};

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getUserPartner: builder.query<Partner, void>({
			query: () => '/partners/self',
		}),
		getPartnerByName: builder.query<Partner, string>({
			query: (name: string) => `/partners/${name}`,
		}),
	}),
});

export const { useGetUserPartnerQuery, useLazyGetPartnerByNameQuery } = extendedApi;
