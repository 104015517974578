import { useEffect, useState } from 'react';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Radio,
	RadioGroup,
	Select,
	Skeleton,
	Text,
	VStack,
} from '@chakra-ui/react';

import BankAccountSelector from 'components/bankingInformations/BankAccountSelector';
import { ArrowButton } from 'components/Button/Button';
import ErrorFieldMessage from 'components/inputs/ErrorMessage';
import NumberInput from 'components/inputs/NumberInput';
import { VersementEligibilityError } from 'components/versements/VersementEligibilityError';
import { useGetBankAccountsQuery } from 'services/requests/bank';
import { useGetContractsQuery } from 'services/requests/invest/contracts';
import {
	ApicilFundOrigins,
	useGetPerLimitQuery,
	useIsVersementEligibleQuery,
	VersementEligibility,
	versementMinMax,
} from 'services/requests/versement';
import { PopulatedInvestContract } from 'store/types/contract.type';
import { EnvelopeType } from 'store/types/global.type';
import { BankInformations } from 'store/types/subscription.type';
import colors from 'theme/foundations/colors';
import { isNone, isNotNone } from 'utils/functions';
import { displayMoney, displayMoneyNoDigits } from 'utils/rendering';

const CreateVcSkeleton = (): JSX.Element => (
	<VStack w="100%" align="start" p="16px 8px" spacing="32px">
		<Skeleton h="64px" w="100%" />
		<Skeleton h="32px" w="100%" />
		<Skeleton h="48px" w="100%" />
		<Skeleton h="40px" w="40%" />
	</VStack>
);

export type CreateVCData = {
	amount: number;
	bankInfo: BankInformations;
	contract?: PopulatedInvestContract;
	origineDesFonds: ApicilFundOrigins;
};

const apicilFundOriginsOptions = Object.entries({
	ofRevenusPro: 'Salaires et Revenus',
	ofAutresPlacements: 'Autres placements',
	ofBiensMobiliers: 'Vente de biens mobiliers',
	ofCessionActifs: 'Cession',
	ofSuccessionDonation: 'Donation / Héritage',
	ofVenteImmobiliere: 'Vente de biens immobiliers',
} as Record<CreateVCData['origineDesFonds'], string>) as [NonNullable<CreateVCData['origineDesFonds']>, string][];

type InformationsProps = {
	isVp: boolean;
	onSubmit: (data: CreateVCData) => void;
	data: CreateVCData | undefined;
	vpSelectedContract?: PopulatedInvestContract; // only present if programmed versement
};

const Informations = ({ onSubmit, data, vpSelectedContract, isVp }: InformationsProps): JSX.Element => {
	const { data: contracts } = useGetContractsQuery();
	const { data: accounts } = useGetBankAccountsQuery();
	const { data: perLimit } = useGetPerLimitQuery();

	const [contract, setContract] = useState<PopulatedInvestContract | undefined>(vpSelectedContract ?? data?.contract);
	const [amount, setAmount] = useState<number | undefined>(data?.amount);
	const [bankInfo, setBankInfo] = useState<BankInformations | undefined>(data?.bankInfo);
	// why save enveloppe type if we can get it on contract ?
	const [type, setType] = useState<EnvelopeType | undefined>(contract?.envelope.type);
	const [origineDesFonds, setOrigineDesFonds] = useState(ApicilFundOrigins.revenusPro);

	// prefill bank account with the one on contract
	useEffect(() => {
		if (accounts && contract && !bankInfo) {
			const defaultBankInfo: BankInformations | undefined = accounts.find((i) => i.id === contract.bankInformationId);
			if (defaultBankInfo) setBankInfo(defaultBankInfo);
		}
	}, [accounts, bankInfo, contract]);

	const { data: versementEligibility } = useIsVersementEligibleQuery(
		{
			contractId: contract?.id ?? '',
			type: isVp ? 'PROGRAMME' : 'COMPLEMENTAIRE',
		},
		{ skip: isNone(contract) },
	);

	if (!contracts || !accounts) return <CreateVcSkeleton />;

	const minimum: number = isVp
		? versementMinMax[contract?.envelope.partnerCode ?? 'P2'].minVP.mensuel
		: versementMinMax[contract?.envelope.partnerCode ?? 'P2'].minVC;
	const maximum: number = versementMinMax[contract?.envelope.partnerCode ?? 'P2'].max;
	const fulfilled = !!contract && amount && amount >= minimum && amount <= maximum && !!bankInfo;
	const abovePerLimit = contract?.envelope.type === 'PER' && !!amount && !!perLimit && amount > perLimit;

	console.log(
		JSON.stringify(
			{
				body: {
					contractId: contract?.id ?? '',
					type: isVp ? 'PROGRAMME' : 'COMPLEMENTAIRE',
				},
				versementEligibility,
			},
			undefined,
			'...',
		),
	);

	return (
		<VStack spacing="48px" align="start" w="100%">
			<VStack spacing="16px" align="start" w="100%">
				<Text variant="Text-S-Medium" color={colors.primary.black}>
					Sur quel contrat souhaitez-vous effectuer un versement {isVp ? 'programmé' : 'ponctuel'}&nbsp;?
				</Text>

				<RadioGroup
					onChange={(v) => {
						return setType(v as EnvelopeType);
					}}
					value={type}
					w="100%"
				>
					<HStack align="start" w="100%">
						{(!!vpSelectedContract ? [vpSelectedContract] : contracts).map((c) => (
							<Radio value={c.envelope.type} key={c.envelope.id} onChange={() => setContract(c)}>
								{c.envelope.type === 'AV' ? 'Assurance vie' : 'PER individuel'} ({displayMoney(c.amount)})
							</Radio>
						))}
					</HStack>
				</RadioGroup>
			</VStack>

			{isNotNone(versementEligibility) && versementEligibility.eligibility !== VersementEligibility.ELIGIBLE ? (
				<VersementEligibilityError versementEligibility={versementEligibility.eligibility} />
			) : (
				<>
					<BankAccountSelector
						bankAccounts={accounts}
						errors={null}
						selectedAccountId={bankInfo?.id}
						setValue={(value) => setBankInfo(value)}
					/>

					<FormControl>
						<FormLabel>Quel montant souhaitez-vous verser&nbsp;?</FormLabel>
						<NumberInput
							suffix=" €"
							value={amount ?? ''}
							placeholder={`Minimum ${minimum}€`}
							onChange={(value) => setAmount(value)}
							min={minimum}
							max={maximum}
						/>
						{!!amount && amount < minimum && (
							<FormErrorMessage>
								Le montant minimum d'un versement ${isVp ? 'programmé' : 'ponctuel'} est de ${minimum}€.
							</FormErrorMessage>
						)}
						{!!amount && amount > maximum && (
							<FormErrorMessage>
								Le montant maximum d'un versement ${isVp ? 'programmé' : 'ponctuel'} est de ${maximum}€.
							</FormErrorMessage>
						)}
						{abovePerLimit && (
							<ErrorFieldMessage warning>
								Le montant choisit dépasse votre plafond de défiscalisation annuel du PER, s'élevant à{' '}
								{displayMoneyNoDigits(perLimit)}. Si ce montant provient de nos conseillers, il est basé sur des
								informations supplémentaires, notamment de vos plafonds des trois années précédentes. Pour plus
								d'informations, nous restons disponibles via le chat.
							</ErrorFieldMessage>
						)}
					</FormControl>

					<FormControl>
						<FormLabel>Origine des fonds</FormLabel>

						<Select
							value={origineDesFonds}
							onChange={(event) => setOrigineDesFonds(event.target.value as ApicilFundOrigins)}
						>
							{apicilFundOriginsOptions.map(([key, label]) => (
								<option value={key} key={key}>
									{label}
								</option>
							))}
						</Select>
					</FormControl>
				</>
			)}

			<ArrowButton
				variant="primary"
				alignSelf="end"
				isDisabled={
					!fulfilled ||
					(isNotNone(versementEligibility) && versementEligibility.eligibility !== VersementEligibility.ELIGIBLE)
				}
				onClick={() => fulfilled && onSubmit({ amount, bankInfo, contract, origineDesFonds })}
			>
				Suivant
			</ArrowButton>
		</VStack>
	);
};

export default Informations;
