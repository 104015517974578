import { useEffect, useState } from 'react';
import { Box, Card, Heading, HStack, Text, VStack } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';
import { displayPercentage } from 'utils/rendering';

import PerformancesGraph from './PerformancesGraph';

export type BarDataIntervalType = {
	id: string;
	color: string;
	year: string;
	performance: number;
};

export interface PerformancesBarCardProps {
	performances: {
		yearly_performance: Record<string, number>;
		total_performance: number;
		annualized_return: number;
	};
}

const color = {
	positive: colors.primary.yellow,
	negative: colors.grey[500],
} as const;

const PerformancesBarCard = ({ performances }: PerformancesBarCardProps): JSX.Element => {
	const [barData, setBarData] = useState<BarDataIntervalType[]>([]);

	useEffect(() => {
		setBarData(
			Object.entries(performances.yearly_performance).map(([year, performance]) => ({
				id: year,
				year,
				performance,
				color: performance < 0 ? color.negative : color.positive,
			})),
		);
	}, [performances, setBarData]);

	return (
		<Card w="100%">
			<VStack align="start" spacing="lg">
				<VStack align="start" spacing="sm" w="100%">
					<Heading variant="Title-M-SemiBold">Performance du portefeuille</Heading>
					<Text variant="Text-M-Regular">Sur les 10 dernières années</Text>
				</VStack>

				<VStack align="start" w="100%" spacing="sm">
					<Box w="100%">
						<PerformancesGraph barData={barData} />
					</Box>

					<HStack spacing="sm" marginLeft="70px">
						<HStack>
							<Box h="8px" w="8px" bg={color.positive} />
							<Text variant="body">Gains</Text>
						</HStack>
						<HStack>
							<Box h="8px" w="8px" bg={color.negative} />
							<Text variant="body">Pertes</Text>
						</HStack>
					</HStack>

					<HStack spacing="xl">
						<VStack align="flex-start">
							<Text variant="Text-M-Medium">Rendement moyen par an</Text>
							<Heading variant="Title-L-SemiBold">{displayPercentage(performances.annualized_return)}</Heading>
						</VStack>
						<VStack align="flex-start">
							<Text variant="Text-M-Medium">Total (sur 10 ans)</Text>
							<Heading variant="Title-L-SemiBold">{displayPercentage(performances.total_performance)}</Heading>
						</VStack>
					</HStack>
				</VStack>
			</VStack>
		</Card>
	);
};

export default PerformancesBarCard;
