import { MainStepType, OnboardingStep } from 'onboarding/Stepper/stepper.slice';

export enum InvestOnboardingSteps {
	PROJECT = 'project',
	PORTFOLIO = 'portfolio',
	FISCALITE = 'fiscalite',
	RESULTS = 'resultats',
}

export const projectStep: OnboardingStep<InvestOnboardingSteps> = {
	id: InvestOnboardingSteps.PROJECT,
	name: 'Mon projet',
	url: 'projet',
	type: MainStepType.START,
};

export const portfolioStep: OnboardingStep<InvestOnboardingSteps> = {
	id: InvestOnboardingSteps.PORTFOLIO,
	name: 'Portefeuille d’investissement',
	url: 'portefeuille',
	type: MainStepType.COURSE,
	innerSteps: [
		{
			id: 'riskAversion',
			name: 'Profil de risque',
			url: 'portefeuille/risque',
		},
		{
			id: 'style',
			name: 'Ramify Green',
			url: 'portefeuille/style',
		},
		{
			id: 'type',
			name: 'Type de portefeuille',
			url: 'portefeuille/type',
		},
		{
			id: 'recapitulatif',
			name: 'Portefeuille proposé',
			url: 'portefeuille/recapitulatif',
		},
	],
};

export const fiscaliteStep: OnboardingStep<InvestOnboardingSteps> = {
	id: InvestOnboardingSteps.FISCALITE,
	name: 'Optimisation fiscale',
	url: 'fiscalite',
	type: MainStepType.COURSE,
	innerSteps: [
		{
			id: 'situation',
			name: 'Foyer fiscal',
			url: 'fiscalite/situation',
		},
		{
			id: 'enveloppe',
			name: 'Choix des enveloppes',
			url: 'fiscalite/envelope',
		},
	],
};

export const resultatsStep: OnboardingStep<InvestOnboardingSteps> = {
	id: InvestOnboardingSteps.RESULTS,
	name: 'Résultats',
	url: 'resultats',
	type: MainStepType.END,
};

const steps = [projectStep, portfolioStep, fiscaliteStep, resultatsStep];

export default steps;
