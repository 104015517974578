import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { VStack } from '@chakra-ui/react';
import { curveStepAfter } from '@visx/curve';
import { localPoint } from '@visx/event';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { useTooltip } from '@visx/tooltip';
import { bisector, extent } from 'd3-array';

import GraphLine from 'components/Graphs/visx/GraphLine';
import TooltipDesktop from 'components/Graphs/visx/ToolTipGraphDesktop';
import TooltipGraphMobile from 'components/Graphs/visx/ToolTipGraphMobile';
import CustomRadioButton, { Option } from 'components/RadioButton';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { ImmobilierSubscriptionMetrics, ImmobilierSubscriptionsMetrics } from 'services/requests/realEstate';
import { displayMoney } from 'utils/rendering';

type TextLegend = 'Avec loyers' | 'Hors loyers';

type LineProps = {
	firstDate?: Date;
	setCurrentValue: Dispatch<SetStateAction<{ date: Date; valuation: number } | undefined>>;
	width: number;
	height: number;
	margin: { top: number; right: number; bottom: number; left: number };
	data: ImmobilierSubscriptionMetrics | ImmobilierSubscriptionsMetrics;
	selectedLegend: TextLegend;
};

const CapitalValueGraph = ({ width, height, selectedLegend, data, firstDate, setCurrentValue, margin }: LineProps) => {
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;
	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;
	const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<{
		date: Date;
		valuation: number;
	}>({
		tooltipOpen: true,
	});
	const today = new Date();

	const timeScale = scaleTime<number>({
		domain: extent(data.capital_evolution[0].date) as [Date, Date],
		range: [0, xMax],
	});

	if (firstDate !== undefined) {
		timeScale.domain(scaleTime<number>({ domain: [firstDate, today] }).domain());
	}

	const filteredData = data.capital_evolution[0].date.map((d, i) => ({
		date: d,
		valuation:
			selectedLegend === 'Avec loyers' ? data.capital_evolution[1].div[i] : data.capital_evolution[0].no_div[i],
	}));

	const minValue = useCallback(() => {
		return Math.min(...filteredData.map((d) => d.valuation * 0.95));
	}, [filteredData]);

	const maxValue = useCallback(() => {
		return Math.max(...filteredData.map((d) => d.valuation * 1.05));
	}, [filteredData]);

	const valuationScale = scaleLinear<number>({
		domain: [minValue(), maxValue()],
		range: [yMax, 0],
	});

	const handleMouseMove = useCallback<
		React.MouseEventHandler<SVGPathElement> & React.TouchEventHandler<SVGRectElement>
	>(
		(event) => {
			const { x } = localPoint(event) || { x: xMax };
			const realX = x - margin.left;
			const x0 = timeScale.invert(realX);
			const index = bisector((d: { date: Date; valuation: number }) => new Date(d.date)).left(filteredData, x0, 1);
			const d0 = filteredData[index - 1];
			const d1 = filteredData[index];
			let d = d0 && d0.date ? d0 : d1;
			if (d0 && d0.date && d1 && d1.date) {
				d = x0.valueOf() - new Date(d0.date).valueOf() > new Date(d1.date).valueOf() - x0.valueOf() ? d1 : d0;
			}

			setCurrentValue(d);
			if (d) {
				showTooltip({
					tooltipData: d,
					tooltipLeft: timeScale(new Date(d.date)),
					tooltipTop: valuationScale(d.valuation),
				});
			}
		},
		[filteredData, timeScale, valuationScale, showTooltip, setCurrentValue, margin.left, xMax],
	);

	return (
		<>
			<GraphLine
				yMax={yMax}
				xMax={xMax}
				width={width}
				height={height}
				margin={margin}
				timeScale={timeScale}
				valuationScale={valuationScale}
				filteredData={filteredData}
				tooltipData={tooltipData}
				tooltipLeft={tooltipLeft}
				tooltipTop={tooltipTop}
				hideTooltip={hideTooltip}
				handleMouseMove={handleMouseMove}
				curveType={curveStepAfter}
			/>

			{tooltipOpen && tooltipData && !isMobile && (
				<TooltipDesktop
					tooltipData={[
						{ title: 'Capital :', value: displayMoney(tooltipData.valuation) },
						{
							title: 'Date :',
							value: new Date(tooltipData?.date).toLocaleDateString('fr-FR', {
								year: 'numeric',
								month: 'long',
								day: 'numeric',
							}),
						},
					]}
					tooltipLeft={tooltipLeft ?? 0}
					tooltipTop={tooltipTop ?? 0}
				/>
			)}
		</>
	);
};

export const CapitalGraphImmo = ({
	data,
}: {
	data: ImmobilierSubscriptionMetrics | ImmobilierSubscriptionsMetrics;
}) => {
	const [currentValue, setCurrentValue] = React.useState<{
		date: Date;
		valuation: number;
	}>();

	const isMobile = useAppResponsive({ base: true, xl: false }) || false;
	const margin = { top: 10, right: 0, bottom: 20, left: 0 };
	const [legendSelected, setLegendSelected] = useState('Avec loyers');

	const options: Option[] = [
		{ value: 'Avec loyers', label: 'Avec loyers' },
		{ value: 'Hors loyers', label: 'Hors loyers' },
	];
	const handleOptionChange = (option: Option) => {
		setLegendSelected(option.value);
	};
	return (
		<VStack
			w="100%"
			h="100%"
			align="start"
			position="relative" // relative position for the tooltip
		>
			<CustomRadioButton
				options={options}
				defaultValue={options[0]}
				onChange={handleOptionChange}
				strokeColor="#FDAC02"
				fillColor="#FEE3AB"
			/>
			{isMobile && currentValue && (
				<TooltipGraphMobile
					tooltipProps={[
						{ title: 'Capital :', value: displayMoney(currentValue.valuation) },
						{
							title: 'Date :',
							value: new Date(currentValue.date).toLocaleDateString('fr-FR', {
								year: 'numeric',
								month: 'long',
								day: 'numeric',
							}),
						},
					]}
				/>
			)}

			<ParentSize>
				{({ width, height }) => (
					<CapitalValueGraph
						width={width}
						// first render force height from 0 to margin.top + margin.bottom
						height={height || margin.top + margin.bottom}
						data={data}
						margin={margin}
						selectedLegend={legendSelected as TextLegend}
						setCurrentValue={setCurrentValue}
						firstDate={new Date(data.capital_evolution[0].date[0])}
					/>
				)}
			</ParentSize>
		</VStack>
	);
};
