import { Cube, Lifesaver, Money, Security } from '@carbon/icons-react';
import { Heading, HStack, VStack } from '@chakra-ui/react';

import MetricCard from 'components/cards/MetricCard';

const AdvantageSection = (): JSX.Element => {
	const advantageList = [
		{
			icon: Security,
			title: 'Aucun risque',
			label: 'Aucun risque de perte en capital. Couvert par le fonds de garantie à hauteur de 100 000 €',
		},

		{
			icon: Cube,
			title: 'Pas de frais',
			label: '0% frais d’entrée, 0% frais de gestion courante, 0% frais de sortie',
		},

		{
			icon: Money,
			title: 'Liquidité totale',
			label: 'Vous pouvez retirer tout ou une partie de votre capital à tout moment, sans condition',
		},

		{
			icon: Lifesaver,
			title: 'Poche de sécurité',
			label: 'Le placement parfait pour l’épargne de précaution, en cas de coup dur ',
		},
	];

	return (
		<VStack w="100%" align="start" spacing="32px" pb="32px">
			<Heading variant="Title-L-SemiBold">Les avantages</Heading>
			{/* Brut height */}
			<HStack overflowX="auto" width="100%" spacing="24px" alignItems="stretch">
				{advantageList.map((data) => (
					<MetricCard key={data.title} {...data} />
				))}
			</HStack>
		</VStack>
	);
};

export default AdvantageSection;
