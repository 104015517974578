import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import FullscreenLoader from 'components/FullscreenLoader';
import { useAppDispatch } from 'hooks/useStore';
import useThemedToast from 'hooks/useThemedToast';
import { OnboardingUpdateStepProps, setLastInnerStepAvailable } from 'onboarding/Stepper/stepper.slice';
import {
	UpdateInvestSubscriptionBankAccount,
	UpdateInvestSubscriptionBeneficiary,
	UpdateInvestSubscriptionDeduction,
	UpdateInvestSubscriptionVersement,
	useGetSubscriptionQuery,
	useUpdateBankAccountMutation,
	useUpdateBeneficiaryMutation,
	useUpdateDeductionMutation,
	useUpdateVersementMutation,
} from 'services/requests/invest/subscription';
import { BeneficiaryType } from 'store/types/subscription.type';
import { isNone, isNotNone } from 'utils/functions';

import Bancaire from './Steps/BankAccount';
import Beneficiaire from './Steps/Beneficiaire';
import Bulletin from './Steps/Bulletin';
import Deductibilite from './Steps/Deductibilite';
import Explanation from './Steps/Explanations';
import Versements from './Steps/Versements';
import { bankStep, beneficiaryStep, bulletinStep, deductibilityStep, feesStep, versementsStep } from './steps';

export const SignatureWrapper = ({
	object: onboardingObject,
	onNext,
	displayDeduction,
	displayVersements,
}: OnboardingUpdateStepProps & { displayDeduction: boolean; displayVersements: boolean }): JSX.Element => {
	const navigate = useNavigate();
	const toast = useThemedToast();
	const dispatch = useAppDispatch();

	const { data: subscription, isLoading } = useGetSubscriptionQuery({ subscriptionId: onboardingObject.id });
	const [updateBeneficiary] = useUpdateBeneficiaryMutation();
	const [updateVersement] = useUpdateVersementMutation();
	const [updateBankAccount] = useUpdateBankAccountMutation();
	const [updateDeduction] = useUpdateDeductionMutation();

	const mostAdvancedStep = useMemo(() => {
		if (isNone(subscription)) return undefined;

		if (isNone(subscription.beneficiary)) return feesStep;

		if (subscription.beneficiary === BeneficiaryType.WRITTEN && isNone(subscription.freeBeneficiaryClause))
			return beneficiaryStep;

		if (isNone(subscription.bankInformationId)) return bankStep;

		return bulletinStep;
	}, [subscription]);

	useEffect(() => {
		if (isNotNone(mostAdvancedStep)) dispatch(setLastInnerStepAvailable(mostAdvancedStep.id));
	}, [mostAdvancedStep, dispatch]);

	const explanationOnNext = useCallback(() => {
		navigate('beneficiaires');
	}, [navigate]);

	const beneficiaryOnNext = useCallback(
		(data: UpdateInvestSubscriptionBeneficiary) => {
			updateBeneficiary({ ...data, subscriptionId: onboardingObject.id })
				.unwrap()
				.then(() => {
					navigate(displayVersements ? 'versements' : displayDeduction ? 'deductibilite' : 'bancaire');
				})
				.catch(() => {
					toast({
						title: 'Une erreur est survenue.',
						description: "Nous n'avons pas pu valider vos informations.",
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				});
		},
		[displayDeduction, displayVersements, navigate, onboardingObject.id, toast, updateBeneficiary],
	);

	const versementOnNext = useCallback(
		(data: UpdateInvestSubscriptionVersement) => {
			updateVersement({ ...data, subscriptionId: onboardingObject.id })
				.unwrap()
				.then(() => {
					navigate(displayDeduction ? 'deductibilite' : 'bancaire');
				})
				.catch(() => {
					toast({
						title: 'Une erreur est survenue.',
						description: "Nous n'avons pas pu valider vos informations.",
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				});
		},
		[displayDeduction, navigate, onboardingObject.id, toast, updateVersement],
	);

	const deductionOnNext = useCallback(
		(data: UpdateInvestSubscriptionDeduction) => {
			updateDeduction({ ...data, subscriptionId: onboardingObject.id })
				.unwrap()
				.then(() => {
					navigate('bancaire');
				})
				.catch(() => {
					toast({
						title: 'Une erreur est survenue.',
						description: "Nous n'avons pas pu valider vos informations.",
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				});
		},
		[navigate, onboardingObject.id, toast, updateDeduction],
	);

	const bankOnNext = useCallback(
		(data: UpdateInvestSubscriptionBankAccount) => {
			updateBankAccount({ ...data, subscriptionId: onboardingObject.id })
				.unwrap()
				.then(() => {
					navigate('bulletin');
				})
				.catch(() => {
					toast({
						title: 'Une erreur est survenue',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				});
		},
		[navigate, onboardingObject.id, toast, updateBankAccount],
	);

	if (isLoading || !subscription) {
		return <FullscreenLoader />;
	}

	return (
		<Routes>
			<Route path={feesStep.url} element={<Explanation subscription={subscription} onNext={explanationOnNext} />} />
			<Route
				path={beneficiaryStep.url}
				element={
					<Beneficiaire subscription={subscription} onNext={beneficiaryOnNext} onPrev={() => navigate(feesStep.url)} />
				}
			/>
			{displayVersements && (
				<Route
					path={versementsStep.url}
					element={
						<Versements
							subscription={subscription}
							onNext={versementOnNext}
							onPrev={() => navigate(beneficiaryStep.url)}
						/>
					}
				/>
			)}
			{displayDeduction && (
				<Route
					path={deductibilityStep.url}
					element={
						<Deductibilite
							subscription={subscription}
							onNext={deductionOnNext}
							onPrev={() => navigate(displayVersements ? versementsStep.url : feesStep.url)}
						/>
					}
				/>
			)}
			<Route
				path={`${bankStep.url}/*`}
				element={
					<Bancaire
						subscription={subscription}
						onNext={bankOnNext}
						onPrev={() =>
							navigate(displayDeduction ? deductibilityStep.url : displayVersements ? versementsStep.url : feesStep.url)
						}
					/>
				}
			/>
			<Route
				path={bulletinStep.url}
				element={<Bulletin subscription={subscription} onNext={onNext} onPrev={() => navigate(bankStep.url)} />}
			/>

			{mostAdvancedStep && <Route path="*" element={<Navigate to={mostAdvancedStep.url} replace />} />}
		</Routes>
	);
};
