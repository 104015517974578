import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Heading, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';

import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useSubmitPhoenixSubscriptionMutation } from 'services/requests/phoenix';

const Confirmation = (): JSX.Element => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const { subId } = useParams<{ subId: string }>();
	const [submitSCPISubscription, { isLoading: isSubscriptionLoading }] = useSubmitPhoenixSubscriptionMutation();

	useEffect(() => {
		eventTracker.mixpanel.track('SCPI Onboarding - Confirmation');
	}, []);

	const onSubmit = async () => {
		if (!subId) return;
		try {
			await submitSCPISubscription({
				subscriptionId: subId,
				objectives: ['ADDITIONNAL_REVENUE', 'PATRIMONY_CONSTITUTION'],
				fundsOrigin: 'ofRevenusPro',
				isAutoFund: true,
			}).unwrap();
			navigate('/');
		} catch (error) {
			toast({ title: 'Une erreur est survenue', status: 'error', duration: 9000, isClosable: true });
		}
	};

	return (
		<>
			<VStack align="start" spacing="24px">
				<Heading fontSize={{ base: 16, md: 20 }} fontWeight="700">
					Confirmation d’investissement sur Ramify
				</Heading>
				<VStack align="start" spacing="16px">
					<Text color="grey.900">
						Nous allons compléter les démarches pour finaliser votre souscription. L'ouverture de votre contrat se
						déroulera en plusieurs étapes :
					</Text>
					<Box>
						<UnorderedList spacing="24px">
							<ListItem>
								<Text color="grey.900">
									<Text as="span">Étape 1 : </Text>
									<b>Validation</b> de votre dossier par notre partenaire - en moyenne sous 2-3 jours
								</Text>
							</ListItem>

							<ListItem>
								<Text color="grey.900">
									<Text as="span">Étape 2 : </Text>
									<b>Réception des fonds</b> - prélèvement sur votre compte
								</Text>
							</ListItem>

							<ListItem>
								<Text color="grey.900">
									<Text as="span">Étape 3 : </Text>
									<b>Enregistrement de votre opération</b> - en moyenne sous 1 à 2 semaines
								</Text>
							</ListItem>

							<ListItem>
								<Text color="grey.900">
									<Text as="span">Étape 4 : </Text>
									<b>Réception des l’attestation</b> de votre opération - en moyenne sous 2 semaines
								</Text>
							</ListItem>
						</UnorderedList>
					</Box>
					<Text color="grey.900">
						Vous pourrez ensuite <b>suivre votre placement</b> directement depuis votre espace client Ramify
					</Text>
				</VStack>
			</VStack>
			<BottomStepBar
				showPrev={false}
				textNext="Valider mon dossier"
				isNextLoading={isSubscriptionLoading}
				onNext={onSubmit}
			/>
		</>
	);
};

export default Confirmation;
