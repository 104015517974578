import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UseDisclosureReturn } from '@chakra-ui/react';

import { CalendlyStep, FormContent } from './CalendlyStep';
import { FormStep } from './FormStep';
import { IntestedProducts } from './useBuildIframeUrl';

enum CalendlyModalSteps {
	FORM,
	CALENDLY,
}

export const CalendlyModal: FC<{ product?: IntestedProducts } & Pick<UseDisclosureReturn, 'isOpen' | 'onClose'>> = ({
	product,
	isOpen,
	onClose,
}) => {
	const [step, setStep] = useState(CalendlyModalSteps.FORM);

	const formContext = useForm<FormContent>();

	const closeAndReset = () => {
		setStep(CalendlyModalSteps.FORM);
		formContext.reset();
		onClose();
	};

	if (!isOpen) return null;

	return (
		<FormProvider {...formContext}>
			{step === CalendlyModalSteps.FORM && (
				<FormStep onClose={closeAndReset} onSuccess={() => setStep(CalendlyModalSteps.CALENDLY)} />
			)}
			{step === CalendlyModalSteps.CALENDLY && <CalendlyStep product={product} onClose={closeAndReset} />}
		</FormProvider>
	);
};
