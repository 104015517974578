import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Button,
	chakra,
	Divider,
	FormControl,
	FormErrorMessage,
	HStack,
	Text,
	VStack,
} from '@chakra-ui/react';

import TextInput from 'components/inputs/TextInput';
import { useAppDispatch } from 'hooks/useStore';
import useThemedToast from 'hooks/useThemedToast';
import { useUpdatePasswordMutation } from 'services/requests/auth';
import { useGetUserQuery } from 'services/requests/user';
import { logout } from 'store/auth.slice';

type PasswordFields = {
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
};

const Account = (): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		setValue,
	} = useForm<PasswordFields>();
	const toast = useThemedToast();
	const [updatePassword] = useUpdatePasswordMutation();

	const { data: user } = useGetUserQuery();

	const onSubmit = handleSubmit((data: PasswordFields) => {
		if (data.newPassword !== data.confirmPassword) setError('confirmPassword', { type: 'match' });
		else if (data.newPassword === data.oldPassword) setError('newPassword', { type: 'distinct' });
		else {
			const { confirmPassword, ...passwordUpdate } = data;
			updatePassword({ ...passwordUpdate })
				.unwrap()
				.then(() => {
					setValue('confirmPassword', '');
					setValue('newPassword', '');
					setValue('oldPassword', '');
					toast({
						title: 'Succès',
						description: 'Votre mot de passe a bien été modifié',
						status: 'success',
						duration: 9000,
						isClosable: true,
					});
				})
				.catch((e) => {
					toast({
						title: "Une erreur s'est produite.",
						description: e.message || 'Impossible de modifier le mot de passe.',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				});
		}
	});

	return (
		<VStack spacing="32px" align="start" w="100%">
			<HStack align="start" spacing="16px" w="100%">
				<Text variant="Text-M-Regular" color="grey.900">
					Mon email:
				</Text>
				<Text variant="Text-M-Medium">{user?.email}</Text>
			</HStack>

			<Divider />

			<VStack align="start" w="100%" spacing="16px">
				<Accordion allowToggle borderColor="transparent" w="100%">
					<AccordionItem>
						<AccordionButton w="auto">
							<Text variant="Text-M-Regular" color="grey.900">
								Changer de mot de passe
							</Text>
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel>
							<chakra.form id="form" onSubmit={onSubmit} w="100%">
								<VStack align="start" spacing="16px" w="100%">
									<FormControl isInvalid={!!errors.oldPassword}>
										<TextInput
											type="password"
											autoComplete="current-password"
											fontSize="14px"
											placeholder="Ancien mot de passe"
											{...register('oldPassword', { required: true })}
										/>
										{errors.oldPassword?.type === 'required' && (
											<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
										)}
									</FormControl>

									<FormControl isInvalid={!!errors.newPassword}>
										<TextInput
											type="password"
											autoComplete="new-password"
											fontSize="14px"
											placeholder="Nouveau mot de passe"
											{...register('newPassword', { required: true, minLength: 8 })}
										/>
										{errors.newPassword?.type === 'required' && (
											<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
										)}
										{errors.newPassword?.type === 'minLength' && (
											<FormErrorMessage>Le mot de passe doit faire 8 charactères minimum</FormErrorMessage>
										)}
										{errors.newPassword?.type === 'distinct' && (
											<FormErrorMessage>Le nouveau mot de passe doit être différent de l'ancien</FormErrorMessage>
										)}
									</FormControl>

									<FormControl isInvalid={!!errors.confirmPassword}>
										<TextInput
											type="password"
											autoComplete="new-password"
											fontSize="14px"
											placeholder="Confirmation nouveau mot de passe"
											{...register('confirmPassword', { required: true })}
										/>
										{errors.confirmPassword?.type === 'required' && (
											<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
										)}
										{errors.confirmPassword?.type === 'match' && (
											<FormErrorMessage>La vérification est différente du mot de passe</FormErrorMessage>
										)}
									</FormControl>
									<Button form="form" type="submit" mt="32px !important" variant="primary">
										Changer de mot de passe
									</Button>
								</VStack>
							</chakra.form>
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</VStack>

			<Divider />

			<VStack align="start" spacing="24px">
				<Text variant="Text-M-Regular">Déconnexion</Text>
				<Button
					variant="secondary"
					onClick={() => {
						dispatch(logout());
						// This is important: logout clear api cache and navigating prevents hooks in pages from refetching one more time
						navigate('/connexion', { state: { from: { pathname: '/' } } });
					}}
				>
					Me déconnecter
				</Button>
			</VStack>
		</VStack>
	);
};

export default Account;
