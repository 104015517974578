import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Button,
	Card,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Select,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';
import SelectOrAddBankAccountFormContext from 'formContexts/BankAccount/BankAccountSelector';

import { ControlledDropZone } from 'components/inputs/Dropzone';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import {
	useGetSavingsAccountQuery,
	useUpdateSavingsAccountPreferencesMutation,
	useUploadFundsOriginDocumentMutation,
} from 'services/requests/savingsAccounts';
import patrimonyOriginReferentiel from 'store/referentiels/patrimonyOrigin';
import { SavingsAccountInformation } from 'store/types/savingsAccount.type';
import { displayMoney } from 'utils/rendering';

type CashJustificatifs = { jof: File; bankAccountId: string } & Required<
	Omit<SavingsAccountInformation, 'timeHorizon' | 'moneySupplyFrequency' | 'bankInformationId'>
>;

const Justificatifs = (): JSX.Element => {
	const navigate = useNavigate();
	const { id } = useParams();

	const [triggerUpdateSavingsAccountPreferences, { isLoading: isUpdateSavingsAccountLoading, isSuccess }] =
		useUpdateSavingsAccountPreferencesMutation();
	const { data: savingsAccountPreferences } = useGetSavingsAccountQuery(id!);
	const [triggerUploadFundsOriginDocument, { isLoading: isUploadLoading }] = useUploadFundsOriginDocumentMutation();

	const methods = useForm<CashJustificatifs>();
	const {
		formState: { errors },
		setValue,
		register,
		handleSubmit,
		control,
	} = methods;

	const toast = useThemedToast();

	useEffect(() => {
		if (savingsAccountPreferences) {
			if (savingsAccountPreferences.initialAUM) setValue('initialAUM', savingsAccountPreferences.initialAUM);
			if (savingsAccountPreferences.fundsOrigin) setValue('fundsOrigin', savingsAccountPreferences.fundsOrigin);
			if (savingsAccountPreferences.bankInformationId)
				setValue('bankAccountId', savingsAccountPreferences.bankInformationId);
		}
	}, [savingsAccountPreferences, isSuccess, setValue]);

	const onSubmit = handleSubmit(
		(body: CashJustificatifs) => {
			triggerUpdateSavingsAccountPreferences({
				id: id!,
				initialAUM: body.initialAUM,
				fundsOrigin: body.fundsOrigin,
				bankInformationId: body.bankAccountId,
			})
				.unwrap()
				.then(() => {
					if (body.initialAUM >= 75000) {
						const formData = new FormData();
						formData.append('file', body.jof);
						triggerUploadFundsOriginDocument(formData)
							.unwrap()
							.then(() => navigate(`/cash/onboarding/${id}/souscription/signature`))
							.catch(() => {
								toast({
									title: 'Une erreur est survenue lors de la mise à jour de votre document',
									status: 'error',
									duration: 4000,
									isClosable: true,
								});
								return;
							});
					} else {
						navigate(`/cash/onboarding/${id}/souscription/signature`);
					}
				})
				.catch(() => {
					toast({
						title: 'Une erreur est survenue',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				});
		},
		(body) => console.error(body),
	);

	return (
		<VStack align="start" spacing="24px">
			<Heading variant="Title-XL-Bold">Justificatifs</Heading>

			<Card w="100%">
				<VStack w="100%" align="start" spacing="24px">
					<Heading variant="Title-S-Bold">Récapitulatif de mon projet</Heading>

					<Text variant="Text-M-Regular">
						Vous allez investir <b>{displayMoney(savingsAccountPreferences?.initialAUM ?? 0)}</b> au sein de votre
						Livret Épargne Ramify.
					</Text>
					<Button size="lg" variant="secondary" onClick={() => navigate(`/cash/onboarding/${id}/projet`)}>
						Modifier mon projet
					</Button>
				</VStack>
			</Card>

			{savingsAccountPreferences && savingsAccountPreferences.initialAUM! >= 30000 && (
				<Stack direction={{ base: 'column', md: 'row' }} align="start" spacing="32px" w="100%">
					<FormControl isInvalid={!!errors.fundsOrigin}>
						<FormLabel>Origine des fonds ?</FormLabel>
						<Select placeholder="-" {...register('fundsOrigin', { required: true })}>
							{Object.entries(patrimonyOriginReferentiel).map(([value, label]) => (
								<option key={value} value={value}>
									{label}
								</option>
							))}
						</Select>
						{errors.fundsOrigin?.type === 'required' && <FormErrorMessage children="Ce champ est nécessaire" />}
					</FormControl>

					{savingsAccountPreferences.initialAUM! >= 75000 && (
						<FormControl isInvalid={!!errors.jof}>
							<FormLabel>Justificatif d'origine des fonds</FormLabel>
							<ControlledDropZone control={control} name="jof" />
							{errors.jof?.type === 'required' && (
								<FormErrorMessage children="Veuillez renseigner un justificatif d'origine des fonds" />
							)}
						</FormControl>
					)}
				</Stack>
			)}

			<FormProvider {...methods}>
				<SelectOrAddBankAccountFormContext />
			</FormProvider>

			<BottomStepBar
				onPrev={() => navigate(`/cash/onboarding/${id}/souscription/reglementaire`)}
				onNext={onSubmit}
				showNext
				isNextLoading={isUpdateSavingsAccountLoading || isUploadLoading}
			/>
		</VStack>
	);
};
export default Justificatifs;
