import React from 'react';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { Input, InputProps } from '@chakra-ui/react';

interface NumberInputProps
	extends Omit<
		NumericFormatProps,
		'decimalScale' | 'allowNegativeScale' | 'customInput' | 'thousandSeparator' | 'size'
	> {
	onChange: (...event: any[]) => void; // type of onChange callback in react hook form
}

const NumberInput = React.forwardRef(({ onChange, ...rest }: NumberInputProps & InputProps, ref) => {
	return (
		<NumericFormat
			getInputRef={ref}
			decimalScale={0}
			allowNegative={false}
			customInput={Input}
			thousandSeparator={' '}
			onValueChange={onChange ? (v) => onChange(v.floatValue ?? 0) : undefined}
			{...rest}
		/>
	);
});

const ControlledNumberInput = ({
	control,
	name,
	suffix = ' €',
	rules = { required: true, min: 0 },
	...numberInputProps
}: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any>;
	name: string;
	suffix?: string;
	rules?: Omit<RegisterOptions, 'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'> | undefined;
} & Omit<NumberInputProps, 'onChange'> &
	InputProps) => {
	const {
		field: { ref, onChange, ...input },
	} = useController({
		name,
		control,
		rules,
	});

	return (
		<NumericFormat
			suffix={suffix}
			getInputRef={ref}
			decimalScale={0}
			allowNegative={false}
			customInput={Input}
			thousandSeparator={' '}
			onValueChange={(v) => onChange(v.floatValue)}
			{...input}
			{...numberInputProps}
		/>
	);
};

export { ControlledNumberInput };
export default NumberInput;
