import { useParams } from 'react-router-dom';
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Heading, VStack } from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import FormUploadDomicile from 'onboarding/KYC/Forms/Justificatifs/Domicile/Domicile';
import FormUploadIdentite from 'onboarding/KYC/Forms/Justificatifs/Identite/Identite';

type DocumentUploadDrawerProps = {
	onClose: () => void;
};

const JustificatifUploadDrawer = ({ onClose }: DocumentUploadDrawerProps): JSX.Element => {
	const { id: idPath } = useParams<{ id: string }>();
	let currentPage;

	switch (idPath) {
		case 'identite':
			currentPage = {
				form: <FormUploadIdentite onSuccess={onClose} />,
				title: 'Pièce d’identité',
			};
			break;
		case 'domicile':
			currentPage = {
				form: <FormUploadDomicile onSuccess={onClose} />,
				title: 'Justificatif de domicile',
			};
			break;
	}

	return (
		<Drawer size={{ base: 'full', md: 'xl' }} isOpen={!!currentPage} onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<VStack align="start" w="100%" spacing="24px">
						<ArrowButton variant="secondary" alignSelf="start" onClick={onClose} left w={onboardingInputWidth}>
							Retour aux pièces justificatives
						</ArrowButton>
						<Heading variant="Title-L-Bold">{currentPage?.title}</Heading>
					</VStack>
				</DrawerHeader>
				<DrawerBody>{currentPage?.form}</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default JustificatifUploadDrawer;
