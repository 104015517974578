import React from 'react';
import { InformationFilled } from '@carbon/icons-react';
import {
	Box,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverProps,
	PopoverTrigger,
	Text,
} from '@chakra-ui/react';

const CustomTooltip = ({
	text,
	content,
	...props
}: {
	text?: React.ReactNode;
	content?: React.ReactNode;
} & PopoverProps): JSX.Element => (
	<Popover placement="auto" trigger="hover" {...props}>
		<PopoverTrigger>
			<InformationFilled style={{ minWidth: '16px', minHeight: '16px' }} cursor="pointer" color="grey.900" />
		</PopoverTrigger>
		<Box zIndex={999}>
			<PopoverContent p="16px" maxW="88vw" bg="primary.black" border="0">
				<PopoverArrow bg="primary.black" shadow="none" />
				<PopoverBody p="0">
					{text && (
						<Text as="span" variant="Text-S-Regular" whiteSpace="normal" color="white">
							{text}
						</Text>
					)}
					{content}
				</PopoverBody>
			</PopoverContent>
		</Box>
	</Popover>
);

export default CustomTooltip;
