import { PropsWithChildren } from 'react';
import { Center } from '@chakra-ui/react';
import { DotWave } from '@uiball/loaders';

const FullscreenLoader = ({ children = <DotWave /> }: PropsWithChildren): JSX.Element => (
	<Center bg="grey.100" w="100%" h="100%" minH="80vh">
		{children}
	</Center>
);

export default FullscreenLoader;
