import { useNavigate, useParams } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

import { isNotNone } from 'utils/functions';

export const useDrawer = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const { isOpen, onClose } = useDisclosure({
		isOpen: isNotNone(id), // listen for param on url
		onClose: () => {
			navigate(
				'..', // remove the id from the url
				{ preventScrollReset: true }, // prevent page from scrolling to top after redirection
			);
		},
	});

	return { isOpen, onClose };
};
