import { Card, Heading, Image, Stack, Text, VStack } from '@chakra-ui/react';

type WelcomeCardProps = {
	description: string | React.ReactNode;
	welcomeTitleActive: boolean;
	imagePath: string;
};

const WelcomeCard = ({ description, welcomeTitleActive, imagePath }: WelcomeCardProps) => {
	return (
		<Card>
			<Stack
				direction={{ base: 'column', sm: 'row' }}
				align={{ base: 'center', sm: 'flex-start' }}
				spacing="24px"
				maxW="668px"
			>
				<Image w="32px" borderRadius="4px" src={imagePath} />
				<VStack align="start" spacing="6px">
					{welcomeTitleActive && <Heading variant="Title-S-SemiBold">Bienvenue chez Ramify</Heading>}
					<Text variant="Text-M-Regular">{description}</Text>
				</VStack>
			</Stack>
		</Card>
	);
};
export default WelcomeCard;
