import { useEffect, useState } from 'react';
import { Button, Heading, Text, VStack } from '@chakra-ui/react';

import TemperatureInput from 'components/inputs/Slider/TemperatureSlider';
import { useAppResponsive } from 'hooks/useAppResponsive';

export type ChangeTemperatureProps = {
	validateTemperature: (newTemperature: number) => void;
	temperature: number;
};

const ChangeTemperature = ({ temperature, validateTemperature }: ChangeTemperatureProps): JSX.Element => {
	const isMobile = useAppResponsive({ base: true, md: false });
	const [newTemperature, updateTemperature] = useState(temperature);

	// This is important. The modal must be updated when the user change the risk
	// The internal `newRisk` state doesn't refresh if the `risk` prop changes, it must be done manually
	useEffect(() => {
		updateTemperature(temperature);
	}, [temperature]);

	return (
		<VStack align="start" spacing="24px" mt="24px">
			<Heading variant="bigTitle" fontWeight={700}>
				Quel est votre style d’investissement
			</Heading>
			<Text>Définissez la température adaptée à votre projet d'investissement.</Text>
			<VStack w="100%" spacing="32px">
				<TemperatureInput value={newTemperature} onChange={updateTemperature} />
				<Button w="100%" onClick={() => validateTemperature(newTemperature)} variant="primary">
					Mettre à jour{!isMobile && ' ma température'}
				</Button>
			</VStack>
		</VStack>
	);
};

export default ChangeTemperature;
