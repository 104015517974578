import textStyles, { baseTextStyle } from 'theme/foundations/typography';

const Text = {
	baseStyle: {
		...baseTextStyle,
	},
	variants: {
		'Text-M-Bold': { ...textStyles['Text-M-Bold'] },
		'Text-M-Medium': { ...textStyles['Text-M-Medium'] },
		'Text-M-Regular': { ...textStyles['Text-M-Regular'] },
		'Text-S-Bold': { ...textStyles['Text-S-Bold'] },
		'Text-S-Medium': { ...textStyles['Text-S-Medium'] },
		'Text-S-Regular': { ...textStyles['Text-S-Regular'] },
		'Text-XS-Bold': { ...textStyles['Text-XS-Bold'] },
		'Text-XS-Medium': { ...textStyles['Text-XS-Medium'] },
		'Text-XS-Regular': { ...textStyles['Text-XS-Regular'] },

		// Titles
		'Title-XL-Bold': { ...textStyles['Title-XL-Bold'] },
		'Title-XL-SemiBold': { ...textStyles['Title-XL-SemiBold'] },
		'Title-L-Bold': { ...textStyles['Title-L-Bold'] },
		'Title-L-SemiBold': { ...textStyles['Title-L-SemiBold'] },
		'Title-M-Bold': { ...textStyles['Title-M-Bold'] },
		'Title-M-SemiBold': { ...textStyles['Title-M-SemiBold'] },
		'Title-S-Bold': { ...textStyles['Title-S-Bold'] },
		'Title-S-SemiBold': { ...textStyles['Title-S-SemiBold'] },

		// Buttons
		Caption: { ...textStyles.Caption },
		'Button-SemiBold': { ...textStyles['Button-SemiBold'] },
		'Button-Medium': { ...textStyles['Button-Medium'] },
	},
	defaultProps: {
		variant: 'Text-M-Regular',
	},
};

export default Text;
