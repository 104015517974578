import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Close } from '@carbon/icons-react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Heading,
	HStack,
	Menu,
	MenuButton,
	MenuList,
	Show,
	Text,
	VStack,
} from '@chakra-ui/react';

import CheckList from 'components/filter/CheckList';
import SearchBar from 'components/filter/SearchBar';
import SliderWithText from 'components/filter/SliderWithText';
import SortingSelect from 'components/filter/SortingSelect';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { clearFilters, setFilter, setMaxTicket, setName, setSorting } from 'pages/RealEstate/SCPIFilters.slice';
import { SCPIFund } from 'store/types/airtable.type';
import { SCPIFilterKeyType } from 'utils/fundFilters';

import { scpiSortOption, scpiTagsDisplay, tagOptions } from './options.constants';

type FilterBarProps = { funds: SCPIFund[] | undefined; filteredCounts: number };

const FilterBar = ({ funds, filteredCounts }: FilterBarProps) => {
	const isMobile = useAppResponsive({ base: true, md: false });
	const filter = useAppSelector((state) => state.scpiFilters);
	const [sliderValue, setSliderValue] = useState(filter.maxTicket);
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const subscriptionRange = useMemo(
		() => ({
			min:
				funds?.reduce((min, fund) => {
					const ticket = fund.lastShareValuation * fund.minPPShares;
					return ticket ? Math.min(min, ticket) : min;
				}, Infinity) ?? 0,
			max:
				funds?.reduce((max, fund) => {
					const ticket: number = fund.lastShareValuation * fund.minPPShares;
					return ticket ? Math.max(max, ticket) : max;
				}, -Infinity) ?? 0,
		}),
		[funds],
	);

	useEffect(() => {
		if (!searchParams.has('maxTicket')) {
			const maxTicket = subscriptionRange.max;
			if (!sliderValue) setSliderValue(maxTicket);
			if (!filter.maxTicket) dispatch(setMaxTicket(maxTicket));
		}
	}, [funds, dispatch, filter.maxTicket, sliderValue, subscriptionRange, searchParams]);

	// Update query params according to filters
	useEffect(() => {
		const queryParams: Record<string, string> = {};
		if (filter.name) queryParams.name = filter.name;
		if (filter.sorting) queryParams.sorting = filter.sorting;
		if (filter.maxTicket && filter.maxTicket !== subscriptionRange.max)
			queryParams.maxTicket = filter.maxTicket.toString();

		const arrayFilters: SCPIFilterKeyType[] = ['sectors', 'geographies', 'tags'];
		arrayFilters.map((arrayFilter) => {
			if (filter[arrayFilter].length > 0) queryParams[arrayFilter] = filter[arrayFilter].join('*');
		});
		setSearchParams(queryParams, { preventScrollReset: true });
	}, [filter, setSearchParams, subscriptionRange.max]);

	// Fetch query params in the URl and apply them
	useEffect(() => {
		const arrayFilters: SCPIFilterKeyType[] = ['sectors', 'geographies', 'tags'];
		arrayFilters.map((arrayFilter: SCPIFilterKeyType) => {
			if (searchParams.has(arrayFilter)) {
				const sectors = (searchParams.get(arrayFilter) as string).split('*');
				sectors.map((el: string) => {
					dispatch(setFilter({ key: arrayFilter, value: el, isChecked: true }));
				});
			}
		});

		if (searchParams.has('name')) dispatch(setName(searchParams.get('name') as string));
		if (searchParams.has('maxTicket')) {
			const newSliderValue = parseInt(searchParams.get('maxTicket') as string);
			dispatch(setMaxTicket(newSliderValue));
			setSliderValue(newSliderValue);
		}
		if (searchParams.has('sorting')) dispatch(setSorting(searchParams.get('sorting') as string));
	}, [dispatch, searchParams]);

	const sectorOptions = useMemo<string[]>(
		() =>
			funds
				?.reduce<string[]>((acc, fund) => {
					fund.sectors?.forEach((sector) => {
						if (!acc.includes(sector)) acc.push(sector);
					});
					return acc;
				}, [])
				?.sort() ?? [],
		[funds],
	);

	const regionOptions = useMemo<string[]>(
		() =>
			funds
				?.reduce<string[]>((acc, fund) => {
					fund.regions?.forEach((region) => {
						if (!acc.includes(region)) acc.push(region);
					});
					return acc;
				}, [])
				?.sort() ?? [],
		[funds],
	);

	const filterList = [
		{
			key: 'ticket',
			name: 'Minimum de souscription',
			isActive: sliderValue !== subscriptionRange.max,
			component: (
				<Box px="12px">
					<SliderWithText
						range={subscriptionRange}
						step={100}
						value={sliderValue}
						onChange={(newValue) => setSliderValue(newValue)}
						onChangeEnd={(newValue) => dispatch(setMaxTicket(newValue))}
					/>
				</Box>
			),
		},
		{
			key: 'sectors',
			name: `Secteur${filter.sectors.length > 0 ? ` (${filter.sectors.length})` : ''}`,
			isActive: filter.sectors.length > 0,
			component: (
				<CheckList
					options={sectorOptions}
					localFilter={filter.sectors}
					onChange={(value, isChecked) => dispatch(setFilter({ key: 'sectors', value, isChecked }))}
				/>
			),
		},
		{
			key: 'regions',
			name: `Géographie${filter.geographies.length > 0 ? ` (${filter.geographies.length})` : ''}`,
			isActive: filter.geographies.length > 0,
			component: (
				<CheckList
					options={regionOptions}
					localFilter={filter.geographies}
					onChange={(value, isChecked) => dispatch(setFilter({ key: 'geographies', value, isChecked }))}
				/>
			),
		},
		{
			key: 'others',
			name: `Autres filtres${filter.tags.length > 0 ? ` (${filter.tags.length})` : ''}`,
			isActive: filter.tags.length > 0,
			component: (
				<CheckList
					display={scpiTagsDisplay}
					localFilter={filter.tags}
					options={tagOptions}
					onChange={(value, isChecked) => dispatch(setFilter({ key: 'tags', value, isChecked }))}
				/>
			),
		},
	];

	const resetFilters = () => {
		setSliderValue(subscriptionRange.max);
		dispatch(clearFilters());
	};

	return (
		<VStack w="100%" align="start" spacing="16px">
			<Heading variant="Title-L-SemiBold">Investir en SCPI</Heading>
			<SearchBar
				placeholder="Rechercher une SCPI ou un gérant"
				value={filter.name}
				onChange={(name) => dispatch(setName(name))}
			/>

			{isMobile ? (
				<VStack spacing="sm" pb="24px" align="start" w="100%">
					<Box w="100%">
						<SortingSelect
							sorting={filter.sorting}
							sortOption={scpiSortOption}
							onSelection={(selection: string) => dispatch(setSorting(selection))}
						/>
					</Box>
					<Box w="100%">
						<Menu closeOnSelect={false} matchWidth>
							<MenuButton w="100%" px={6} py={3} borderRadius="sm" borderWidth="1px" bgColor="white" flex={1}>
								<HStack w="100%" justify="space-between">
									<Text variant="Text-S-Regular">Filtres</Text>
									<ChevronDownIcon />
								</HStack>
							</MenuButton>
							<MenuList zIndex="dropdown" px="12px">
								<Accordion defaultIndex={[0]} allowToggle minW="100%">
									{filterList.map((filterElement) => (
										<AccordionItem key={filterElement.key}>
											<AccordionButton>
												<Box flex="1">
													<Text variant="Text-S-Regular">{filterElement.name}</Text>
												</Box>
												<AccordionIcon />
											</AccordionButton>
											<AccordionPanel>{filterElement.component}</AccordionPanel>
										</AccordionItem>
									))}
								</Accordion>
							</MenuList>
						</Menu>
					</Box>
				</VStack>
			) : (
				<HStack spacing="16px" w="100%" pb="24px" justify="space-between">
					{filterList.map((filterElement) => (
						<Box w="100%" key={filterElement.key}>
							<Menu closeOnSelect={false}>
								<MenuButton
									minW={filterElement.name === 'Minimum de souscription' ? '280px' : undefined}
									w="100%"
									px={6}
									py={3}
									borderRadius="sm"
									borderWidth="1px"
									bgColor="white"
									borderColor="grey.500"
									flex={1}
								>
									<HStack w="100%" justify="space-between">
										<Text variant="Text-S-Regular">{filterElement.name}</Text>
										<ChevronDownIcon />
									</HStack>
								</MenuButton>
								<MenuList zIndex="dropdown">{filterElement.component}</MenuList>
							</Menu>
						</Box>
					))}
				</HStack>
			)}

			<HStack w="100%" justify="space-between">
				<HStack spacing="24px">
					<Text variant="title">
						{filteredCounts} SCPI disponible{filteredCounts > 1 ? 's' : ''}
					</Text>
					<Show above="md">
						<Box>
							<SortingSelect
								sorting={filter.sorting}
								sortOption={scpiSortOption}
								onSelection={(selection: string) => dispatch(setSorting(selection))}
							/>
						</Box>
					</Show>
				</HStack>
				<Button
					onClick={resetFilters}
					variant="tertiary"
					rightIcon={
						<Box>
							<Close aria-hidden="true" size="20" />
						</Box>
					}
				>
					Réinitialiser les filtres
				</Button>
			</HStack>
		</VStack>
	);
};

export default FilterBar;
