import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { CheckmarkFilled, DocumentAdd } from '@carbon/icons-react';
import { Box, Button, HStack, Text, VStack, Wrap } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';

type DropZoneProps = {
	filesUploaded: boolean;
	filename?: string;
	cy?: string;
};

const DropZone = ({ filesUploaded, cy, filename, ...options }: DropZoneProps & DropzoneOptions): JSX.Element => {
	const { getRootProps, getInputProps } = useDropzone({
		maxFiles: 1, // one file only
		maxSize: 10000000, // 10 Mo
		accept: '.png,.jpg,.pdf,.jpeg', // https://developer.mozilla.org/fr/docs/Web/HTML/Attributes/accept
		multiple: false, // prevent drag and drop of multiple files
		...options,
	});

	const truncateLongFilename = filename && (filename?.length > 30 ? `${filename.substring(0, 30)}...` : filename);

	return (
		<Box {...getRootProps()} cursor="pointer" position="relative">
			<input type="file" {...getInputProps()} data-cy={cy} />
			{filesUploaded && (
				<Box position="absolute" top="-12px" right="-12px" borderRadius="full" bg="white">
					<CheckmarkFilled color={colors.positive[500]} size="32" style={{ margin: '-3px' }} />
				</Box>
			)}
			<Box
				borderRadius="10px"
				bgColor={!!filesUploaded ? 'rgb(218, 255, 228, 0.5)' : 'rgb(255, 243, 206, 0.5)'}
				border={`1px dashed ${filesUploaded ? 'green' : colors.primary.yellow}`}
				w="100%"
				h="100%"
				p="30px"
			>
				<Wrap shouldWrapChildren spacing="24px">
					<HStack align="center" spacing="16px">
						<DocumentAdd size="32" />
						<VStack align="flex-start" spacing="4px">
							<Text variant="Text-M-Medium">
								{!filesUploaded
									? 'Faites glisser votre pièce ici'
									: `Fichier ajouté ${truncateLongFilename && `: ${truncateLongFilename}`}`}
							</Text>
							<Text variant="Text-S-Regular" color="grey.900">
								Formats acceptés : PDF, JPG et PNG. max 10Mo
							</Text>
						</VStack>
					</HStack>
					{!filesUploaded && (
						<Button flexShrink="0" justifySelf="end" variant="primary" size="lg">
							Ajouter une pièce
						</Button>
					)}
				</Wrap>
			</Box>
		</Box>
	);
};

const ControlledDropZone = ({
	control,
	name,
	rules = { required: true },
}: {
	name: string;
	control: Control<any>;
	rules?: Omit<RegisterOptions, 'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'> | undefined;
} & DropzoneOptions): JSX.Element => {
	const {
		field: { ref, value, onChange, ...rest },
	} = useController({
		control,
		name,
		rules,
	});

	const { getRootProps, getInputProps } = useDropzone({
		maxFiles: 1, // one file only
		maxSize: 10000000, // 10 Mo
		accept: '.png,.jpg,.pdf,.jpeg', // https://developer.mozilla.org/fr/docs/Web/HTML/Attributes/accept
		multiple: false, // prevent drag and drop of multiple files
		onDrop: (acceptedFiles) => {
			onChange(acceptedFiles[0]);
		},
	});

	const file = value as File | undefined;
	const filesUploaded = !!file;

	const truncateLongFilename = file?.name && (file.name.length > 30 ? `${file.name.substring(0, 30)}...` : file.name);

	return (
		<Box {...getRootProps()} cursor="pointer" position="relative">
			<input ref={ref} type="file" {...getInputProps()} {...rest} />
			{filesUploaded && (
				<Box position="absolute" top="-12px" right="-12px" borderRadius="full" bg="white">
					<CheckmarkFilled color={colors.positive[500]} size="32" style={{ margin: '-3px' }} />
				</Box>
			)}
			<Box
				borderRadius="10px"
				bgColor={!!value ? 'rgb(218, 255, 228, 0.5)' : 'rgb(255, 243, 206, 0.5)'}
				border={`1px dashed ${value ? 'green' : colors.primary.yellow}`}
				w="100%"
				h="100%"
				p="30px"
			>
				<Wrap shouldWrapChildren spacing="24px">
					<HStack align="center" spacing="16px">
						<DocumentAdd size="32" />
						<VStack align="flex-start" spacing="4px">
							<Text variant="Text-M-Medium">
								{!filesUploaded
									? 'Faites glisser votre pièce ici'
									: `Fichier ajouté ${truncateLongFilename && `: ${truncateLongFilename}`}`}
							</Text>
							<Text variant="Text-S-Regular" color="grey.900">
								Formats acceptés : PDF, JPG et PNG. max 10Mo
							</Text>
						</VStack>
					</HStack>
					{!filesUploaded && (
						<Button flexShrink="0" justifySelf="end" variant="primary" size="lg">
							Ajouter une pièce
						</Button>
					)}
				</Wrap>
			</Box>
		</Box>
	);
};

export { ControlledDropZone };
export default DropZone;
