type AssetType = 'BOND' | 'EQUITY';
type RegionType = 'EUROPE' | 'USA' | 'EMERGING' | 'DEVELOPED' | 'FRANCE' | 'JAPAN' | 'CHINA';
type FinancialLostType = 'NO' | 'MAX_10' | 'MAX_20' | 'MORE_20';
type LostReactionType = 'WAIT' | 'REINVEST' | 'SELL' | 'UNKNOWN';

interface RiskQuestions {
	hasEverUsedSavingPlan: boolean | null;
	financialLost: FinancialLostType | null;
	lostReaction: LostReactionType | null;
	[key: string]: boolean | FinancialLostType | LostReactionType | null;
}

interface Preferences {
	types: AssetType[]; // as a default value for now, so never null
	regions: RegionType[]; // as a default value for now, so never null;
	isEsg: boolean | null;
	[key: string]: AssetType[] | RegionType[] | boolean | null;
}

type PortfolioType = 'ESSENTIAL' | 'FLAGSHIP' | 'ELITE' | 'CUSTOM';

export const portfolioTypeLabels: Record<PortfolioType, string> = {
	ESSENTIAL: 'Essential',
	FLAGSHIP: 'Flagship',
	ELITE: 'Elite',
	CUSTOM: 'Personnalisé', // should never be displayed
};

export type { AssetType, FinancialLostType, LostReactionType, PortfolioType, Preferences, RegionType, RiskQuestions };
