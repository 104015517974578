import { useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	NumberDecrementStepper as ChakraNumberDecrementStepper,
	NumberIncrementStepper as ChakraNumberIncrementStepper,
	NumberInput as ChakraNumberInput,
	NumberInputField as ChakraNumberInputField,
	NumberInputStepper as ChakraNumberInputStepper,
	Radio,
	RadioGroup,
	Select,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import ErrorFieldMessage from 'components/inputs/ErrorMessage';
import NumberInput from 'components/inputs/NumberInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import { SCPIFund, SCPIFundDemembrement } from 'store/types/airtable.type';
import { ScpiIntention } from 'store/types/scpiKyc.type';
import { displayMoney, displayPercentage } from 'utils/rendering';

type RealEstateIntentionFormProps = {
	fundDetails: SCPIFund;
	fundDemembrement: SCPIFundDemembrement | undefined;
};

const RealEstateIntentionForm = ({ fundDemembrement, fundDetails }: RealEstateIntentionFormProps): JSX.Element => {
	const demembrement = fundDemembrement && Object.entries(fundDemembrement);

	const {
		formState: { errors },
		control,
		watch,
		setValue,
	} = useFormContext<ScpiIntention>();

	// crado ?
	const [projectType, demembrementDuration, nbShares] = watch(['projectType', 'demembrementDuration', 'nbShare']);
	const decote = demembrementDuration ? fundDemembrement?.[demembrementDuration] || 1 : 1;

	const computeAmountVersement = useCallback(
		(sharesNumberToBuy: number, decotePercentage: number) =>
			sharesNumberToBuy * (fundDetails.lastShareValuation * decotePercentage),
		[fundDetails.lastShareValuation],
	);

	const computeNumberShares = (versement: number, decotePercentage: number) =>
		Math.ceil(versement / (fundDetails.lastShareValuation * decotePercentage));

	const refreshValuationFromDecote = useCallback(
		(shares: number, decotePercentage: number) => {
			const amount = computeAmountVersement(shares, decotePercentage);
			setValue('amountVersement', Math.ceil(amount));
		},
		[computeAmountVersement, setValue],
	);
	const minimumShares = projectType === 'PLEINE_PROPRIETE' ? fundDetails.minPPShares : fundDetails.minNPShares;
	const ticket = fundDetails.lastShareValuation * minimumShares;

	useEffect(() => {
		refreshValuationFromDecote(nbShares, projectType === 'PLEINE_PROPRIETE' ? 1 : decote);
	}, [decote, nbShares, projectType, refreshValuationFromDecote]);

	return (
		<VStack align="start" w="100%" spacing="24px">
			<FormControl w={onboardingInputWidth} isInvalid={!!errors.legalEntity}>
				<FormLabel>Vous souhaitez investir en tant que</FormLabel>

				<Controller
					name="legalEntity"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<RadioGroup variant="single" size="sm" {...field}>
							<VStack spacing="8px">
								<Radio value="PHYSIQUE">Personne physique</Radio>
								<Radio value="MORALE">Personne morale</Radio>
							</VStack>
						</RadioGroup>
					)}
				/>
				{errors.legalEntity?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
			</FormControl>

			<Wrap w="100%" spacing="24px">
				<WrapItem w={onboardingInputWidth}>
					<FormControl isInvalid={!!errors.projectType}>
						<FormLabel>Vous souhaitez investir</FormLabel>
						<Controller
							name="projectType"
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<RadioGroup variant="single" size="sm" {...field}>
									<VStack spacing="8px">
										<Radio value="PLEINE_PROPRIETE">En pleine propriété (standard)</Radio>
										<Radio value="NUE_PROPRIETE">En nue-propriété (avancé)</Radio>
									</VStack>
								</RadioGroup>
							)}
						/>
						{errors.projectType?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					</FormControl>
				</WrapItem>

				{projectType === 'NUE_PROPRIETE' && (
					<WrapItem w={onboardingInputWidth}>
						<VStack align="start" w="100%">
							<FormControl isInvalid={!!errors.demembrementDuration}>
								<FormLabel>Durée de démembrement</FormLabel>
								<Controller
									control={control}
									name="demembrementDuration"
									rules={{ required: true }}
									render={({ field: { value, onChange, ref } }) => (
										<Select
											ref={ref}
											placeholder="Choisissez une durée"
											onChange={(e) => {
												if (e.target.value === '') {
													onChange(undefined);
													refreshValuationFromDecote(nbShares, 1);
												} else {
													onChange(+e.target.value);
													refreshValuationFromDecote(nbShares, fundDemembrement?.[+e.target.value] || 1);
												}
											}}
											value={value}
										>
											{demembrement?.map(([year, decotePercentage]) => (
												<option value={year} key={year}>
													{year} ans ({displayPercentage(1 - decotePercentage)})
												</option>
											))}
										</Select>
									)}
								/>
								{errors.demembrementDuration?.type === 'required' && (
									<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
								)}
							</FormControl>
							<Text>
								Prix de la part avec décote :{' '}
								<Text as="span" variant="Text-M-Bold" color="blue.900">
									{displayMoney(fundDetails.lastShareValuation * decote)}
								</Text>
							</Text>
						</VStack>
					</WrapItem>
				)}
			</Wrap>

			{projectType && (
				<Wrap w="100%" spacing="24px">
					<WrapItem w={onboardingInputWidth}>
						<FormControl isInvalid={!!errors.amountVersement}>
							<FormLabel>Montant à investir</FormLabel>
							{/* <Controller
								control={control}
								name="amountVersement"
								rules={{ required: true }}
								render={({ field: { value, onChange, ref } }) => (
									<InputGroup>
										<Input
											type="number"
											onWheel={(event) => event.currentTarget.blur()}
											ref={ref}
											onKeyPress={(e) => {
												if (e.key === 'Enter') {
													const shares = computeNumberShares(value, decote);
													setValue('nbShare', shares);
													refreshValuationFromDecote(shares, decote);
													e.preventDefault(); // prevent submit
												}
											}}
											value={value}
											onChange={(newValue) => onChange(newValue)}
											// when user stops typing, we compute the number of shares and round the amount
											onBlur={() => {
												const shares = computeNumberShares(value, decote);
												setValue('nbShare', shares);
												refreshValuationFromDecote(shares, decote);
											}}
											placeholder={`Minimum ${displayMoney(ticket)}`}
										/>
										<InputRightElement pointerEvents="none" color="grey.500" fontSize="1.2em" children="€" />
									</InputGroup>
								)}
							/> */}
							<Controller
								name="amountVersement"
								control={control}
								rules={{ required: true, min: 0 }}
								render={({ field: { value, ...rest } }) => (
									<NumberInput
										{...rest}
										placeholder={`Minimum ${displayMoney(ticket)}`}
										suffix=" €"
										value={value}
										onKeyPress={(e) => {
											if (e.key === 'Enter') {
												const shares = computeNumberShares(value, decote);
												setValue('nbShare', shares);
												refreshValuationFromDecote(shares, decote);
												e.preventDefault(); // prevent submit
											}
										}}
										onBlur={() => {
											const shares = computeNumberShares(value, decote);
											setValue('nbShare', shares);
											refreshValuationFromDecote(shares, decote);
										}}
									/>
								)}
							/>

							{errors.amountVersement?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
					</WrapItem>

					<WrapItem w={onboardingInputWidth}>
						<FormControl isInvalid={!!errors.nbShare}>
							<FormLabel>Nombre de parts</FormLabel>
							<Controller
								control={control}
								name="nbShare"
								rules={{ required: true, min: minimumShares }}
								render={({ field: { value, onChange, ref } }) => (
									<ChakraNumberInput
										ref={ref}
										value={value}
										// when user stops typing, we compute the number of shares and round the amount
										onChange={(_, newValue) => {
											const shares = Number.isNaN(newValue) ? minimumShares : Math.floor(newValue);
											onChange(shares);
											refreshValuationFromDecote(shares, decote);
										}}
										// prevent onChange from overriding the custom value
										clampValueOnBlur={false}
										step={1}
										min={minimumShares}
										placeholder={`Minimum ${minimumShares}`}
									>
										<ChakraNumberInputField h="48px" />
										<ChakraNumberInputStepper>
											<ChakraNumberIncrementStepper />
											<ChakraNumberDecrementStepper />
										</ChakraNumberInputStepper>
									</ChakraNumberInput>
								)}
							/>
							<FormHelperText>Nombre minimum de parts: {minimumShares}</FormHelperText>
							{errors.nbShare?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
						</FormControl>
					</WrapItem>
				</Wrap>
			)}
			{nbShares < fundDetails.minPPShares && (
				<ErrorFieldMessage>
					{`Le nombre minimum de parts à souscrire pour ce fond est de ${fundDetails.minPPShares}`}
				</ErrorFieldMessage>
			)}
		</VStack>
	);
};

export default RealEstateIntentionForm;
