import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	Text,
	VStack,
} from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import NumberInput from 'components/inputs/NumberInput';
import { isNone } from 'utils/functions';

import { FormContentBecomeBlack } from '.';

export const Recap: FC<{ onClose: () => void; onSuccess: (data: FormContentBecomeBlack) => void }> = ({
	onClose,
	onSuccess,
}) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useFormContext<FormContentBecomeBlack>();

	const onSubmit = handleSubmit((data) => onSuccess(data));

	return (
		<Modal size="6xl" isOpen onClose={onClose}>
			<ModalOverlay />
			<ModalContent bg="primary.black" h="540px">
				<chakra.form onSubmit={onSubmit}>
					<ModalHeader>
						<Text variant="title-M-Bold" color="white">
							Ramify Black
						</Text>
						<Text variant="Caption" color="primary.yellow">
							DEVENIR MEMBRE
						</Text>
					</ModalHeader>
					<ModalCloseButton color="white" />
					<VStack align="center">
						<ModalBody p="64px">
							<VStack spacing="64px" w="100%">
								<Text variant="Title-M-Bold" color="white" px="72px" textAlign="center">
									Afin de finaliser vote adhésion à Ramify Black nous vous invitons à prendre un rendez-vous avec un
									conseiller dedié
								</Text>

								<VStack spacing="32px" align="flex-start">
									<ArrowButton size="lg" type="submit">
										Prendre un rendez-vous
									</ArrowButton>
									<ArrowButton size="lg" left variant="icon" color="white" onClick={onClose}>
										Modifier mes réponses
									</ArrowButton>
								</VStack>
							</VStack>
						</ModalBody>
					</VStack>
				</chakra.form>
			</ModalContent>
		</Modal>
	);
};
