import { MainStepType, OnboardingStep } from 'onboarding/Stepper/stepper.slice';
import { EnvelopeType } from 'store/types/global.type';
import { isNotNone } from 'utils/functions';

import { recapitulatifStep, riskAversionStep, styleStep, typeStep } from '../Shared/Portfolio/steps';

import {
	bankStep,
	beneficiaryStep,
	bulletinStep,
	deductibilityStep,
	feesStep,
	versementsStep,
} from './Signature/steps';

export enum InvestSubscriptionSteps {
	PROJECT = 'project',
	PORTFOLIO = 'portfolio',
	FISCALITE = 'fiscalite',
	KYC = 'kyc',
	RECAP = 'recap',
	SIGNATURE = 'signature',
}

export const projectStep: OnboardingStep<InvestSubscriptionSteps> = {
	id: InvestSubscriptionSteps.PROJECT,
	name: 'Mon projet',
	url: 'projet',
	type: MainStepType.START,
};

const portfolioUrl = 'portefeuille';
export const portfolioStep: OnboardingStep<InvestSubscriptionSteps> = {
	id: InvestSubscriptionSteps.PORTFOLIO,
	name: 'Portefeuille d’investissement',
	url: portfolioUrl,
	type: MainStepType.COURSE,
	innerSteps: [riskAversionStep, styleStep, typeStep, recapitulatifStep].map((innerStep) => ({
		...innerStep,
		url: `${portfolioUrl}/${innerStep.url}`,
	})),
};

export const fiscaliteStep: OnboardingStep<InvestSubscriptionSteps> = {
	id: InvestSubscriptionSteps.FISCALITE,
	name: 'Optimisation fiscale',
	url: 'fiscalite',
	type: MainStepType.COURSE,
	innerSteps: [
		{
			id: 'foyer',
			name: 'Foyer Fiscal',
			url: 'fiscalite/situation',
		},
		{
			id: 'economies',
			name: 'Economies d’impôt',
			url: 'fiscalite/economies',
		},
	],
};

export const kycStep = (contract: EnvelopeType): OnboardingStep<InvestSubscriptionSteps> => ({
	id: InvestSubscriptionSteps.KYC,
	name: 'Informations personnelles',
	url: 'connaissance-client',
	type: MainStepType.COURSE,
	innerSteps: [
		{
			id: 'etatCivil',
			name: 'État civil',
			url: 'connaissance-client/etatCivil',
		},
		{
			id: 'professionnelle',
			name: 'Situation professionnelle',
			url: 'connaissance-client/professionelle',
		},
		contract === 'AV'
			? {
					id: 'foyer',
					name: 'Foyer Fiscal',
					url: 'connaissance-client/foyerFiscal',
			  }
			: undefined,
		{
			id: 'coordonnees',
			name: 'Coordonnées',
			url: 'connaissance-client/coordonnees',
		},
		{
			id: 'patrimoine',
			name: 'Patrimoine',
			url: 'connaissance-client/patrimoine',
		},
		{
			id: 'justificatif',
			name: 'Justificatifs',
			url: 'connaissance-client/justificatif',
		},
		{
			id: 'recapitulatif',
			name: 'Validation',
			url: 'connaissance-client/recapitulatif',
		},
	].filter(isNotNone),
});

export const recapStep: OnboardingStep<InvestSubscriptionSteps> = {
	id: InvestSubscriptionSteps.RECAP,
	name: 'Récapitulatif',
	url: 'recapitulatif',
	type: MainStepType.COURSE,
};

const signatureUrl = 'signature';
export const signatureStep = (
	displayDeduction: boolean,
	displayVersements: boolean,
): OnboardingStep<InvestSubscriptionSteps> => ({
	id: InvestSubscriptionSteps.SIGNATURE,
	name: 'Signature',
	url: 'signature',
	type: MainStepType.END,
	innerSteps: [
		feesStep,
		beneficiaryStep,
		displayVersements ? versementsStep : undefined,
		displayDeduction ? deductibilityStep : undefined,
		bankStep,
		bulletinStep,
	]
		.filter(isNotNone)
		.map((innerStep) => ({
			...innerStep,
			url: `${signatureUrl}/${innerStep.url}`,
		})),
});
