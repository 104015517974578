import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Badge,
	Box,
	Flex,
	Heading,
	HStack,
	Stack,
	Text,
	useBreakpointValue,
	VStack,
} from '@chakra-ui/react';

import { CorporateAttribution, Periodicity, Round } from 'services/requests/corporate';
import { displayMoney } from 'utils/rendering';

type AttributionCardProps = {
	attributionData: CorporateAttribution[];
	vestedRepartition: Record<string, { vested_parts: number; non_vested_parts: number }>;
};

const translatePeriodicity = (periodicity: Periodicity): string => {
	if (periodicity === 'month') return 'Mensuelle';
	if (periodicity === 'trimester') return 'Trimestrielle';
	if (periodicity === 'quarter') return 'Quadrimestrielle';
	if (periodicity === 'semester') return 'Semestrielle';
	if (periodicity === 'year') return 'Annuelle';
	return '';
};

const translateRound = (round: Round): string => {
	if (round === 'preSeed') return 'Pre Seed';
	if (round === 'seed') return 'Seed';
	if (round === 'seriesA') return 'Series A';
	if (round === 'seriesB') return 'Series B';
	if (round === 'seriesC') return 'Series C';
	if (round === 'seriesD') return 'Series D';
	if (round === 'seriesE+') return 'Series E et plus';
	if (round === 'FMV') return 'Fair Value';
	return '';
};

const AttributionCards = ({ attributionData, vestedRepartition }: AttributionCardProps): JSX.Element => {
	const isMobile = useBreakpointValue({ base: true, md: false });

	return (
		<VStack align="start" spacing="16px" w="100%">
			<Text variant="title">Mes attributions</Text>
			<VStack spacing="16px" align="stretch" w="100%">
				{attributionData.map((attrib) => (
					<Accordion
						key={attrib.id}
						p="24px"
						bg="white"
						borderRadius="10px"
						borderWidth="1px"
						w="100%"
						defaultIndex={[0]}
						allowMultiple
					>
						<AccordionItem borderColor="transparent">
							<AccordionButton justifyContent="space-between" p="0px">
								{isMobile ? (
									<VStack w="350px" align="left">
										<Flex>
											<Badge variant="outlineYellow">{translateRound(attrib.round)}</Badge>
										</Flex>

										<Text variant="label" my="8px" size="md" textAlign="left">
											Signé le{' '}
											{new Date(attrib.attributionDate).toLocaleDateString('fr-FR', {
												year: 'numeric',
												month: 'short',
												day: 'numeric',
											})}
										</Text>
									</VStack>
								) : (
									<>
										<Flex w="150px">
											<Badge variant="outlineYellow">{translateRound(attrib.round)}</Badge>
										</Flex>

										<Text variant="label" my="8px" size="md" w="300px" textAlign="left">
											Signé le{' '}
											{new Date(attrib.attributionDate).toLocaleDateString('fr-FR', {
												year: 'numeric',
												month: 'short',
												day: 'numeric',
											})}
										</Text>
									</>
								)}

								<Heading my="8px" size="md" w="300px" textAlign="left">
									{attrib.nbShares} parts
								</Heading>

								<HStack justify="right" w="120px">
									{!isMobile && <Text variant="Text-M-Medium">Voir le détail</Text>}
									<AccordionIcon />
								</HStack>
							</AccordionButton>

							<AccordionPanel p="24px 0px 0px 0px">
								<Stack direction={{ base: 'column', md: 'row' }} w="100%" justify="space-between">
									<Text w="150px" variant="Text-M-Medium">
										Prix d'exercice{' '}
										<Text variant="Text-M-Medium" as="span" color="grey.900" fontWeight="500">
											{displayMoney(attrib.exerciceCost, 2)}
										</Text>
									</Text>

									<VStack w="300px" align="start">
										<Text variant="Text-M-Medium">
											Date de début de vesting{' '}
											<Text variant="Text-M-Medium" as="span" color="grey.900" fontWeight="500">
												{new Date(attrib.vestingStartDate).toLocaleDateString()}
											</Text>
										</Text>
										<Text variant="Text-M-Medium">
											Fréquence de vesting{' '}
											<Text variant="Text-M-Medium" as="span" color="grey.900" fontWeight="500">
												{translatePeriodicity(attrib.vestingPeriodicity)}
											</Text>
										</Text>
									</VStack>

									<VStack w="300px" align="start">
										<Text variant="Text-M-Medium">
											Parts vestées{' '}
											<Text variant="Text-M-Medium" as="span" color="grey.900" fontWeight="500">
												{vestedRepartition[attrib.id].vested_parts}
											</Text>
										</Text>
										<Text variant="Text-M-Medium">
											Parts non vestées{' '}
											<Text variant="Text-M-Medium" as="span" color="grey.900" fontWeight="500">
												{vestedRepartition[attrib.id].non_vested_parts}
											</Text>
										</Text>
									</VStack>
									<Box w="120px" />
								</Stack>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				))}
			</VStack>
		</VStack>
	);
};

export default AttributionCards;
