import { Card, HStack, Icon, IconProps, Text } from '@chakra-ui/react';

import affiliatesListCardStatusValue from './affiliatesListCardStatusValue';
import { AffiliatesListCardStatus, AffiliatesListCardStatusEnum } from './AffiliatesListCardTypes';

const AffiliatesListBadge = ({ status }: { status: AffiliatesListCardStatusEnum }): JSX.Element => {
	const CircleIcon = (props: IconProps): JSX.Element => {
		return (
			<Icon viewBox="0 0 200 200" {...props}>
				<path fill="currentColor" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" />
			</Icon>
		);
	};

	const statusData: AffiliatesListCardStatus = affiliatesListCardStatusValue[status];

	return (
		<Card maxW="180px" h="30px" p="8px" borderRadius="2px" borderColor={statusData.colorIcon}>
			<HStack h="100%">
				<CircleIcon boxSize="12px" color={statusData.colorIcon} />
				<Text variant="Text-XS-Medium" color={statusData.colorIcon}>
					{statusData.label}
				</Text>
			</HStack>
		</Card>
	);
};

export default AffiliatesListBadge;
