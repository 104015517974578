import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';
import textStyles from 'theme/foundations/typography';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
	container: {
		border: `1px solid ${colors.grey[300]}`,
		background: 'white',
		_hover: {
			borderColor: colors.grey[900],
		},
		_checked: {
			border: `2px solid ${colors.primary.yellow}`,
			_hover: {
				border: `2px solid ${colors.primary.yellow}`,
			},
		},
		boxSizing: 'border-box',
		width: '100%',
		padding: '24px 16px',
		cursor: 'pointer',
		borderRadius: 'base',
	},
	control: {
		minW: '20px',
		minH: '20px',
		borderRadius: '4px',
		border: `1px solid ${colors.grey[300]} !important`,
		background: 'white !important',
		_checked: {
			_before: {
				background: `${colors.primary.yellow} !important`,
				w: '10px !important',
				h: '10px !important',
				borderRadius: '2px',
			},
			borderColor: `${colors.grey[700]} !important`,
		},
	},
	label: {
		...textStyles['Text-S-Regular'],
		fontSize: '14px !important', // Bug with label
		_checked: {
			...textStyles['Text-S-Medium'],
		},
	},
});

const sizes = {
	sm: {
		container: {
			padding: '16px 16px',
		},
	},
	md: {
		container: {
			padding: '24px 16px',
		},
	},
};

const variants = {
	dark: definePartsStyle({
		container: {
			background: colors.primary.black,
			_hover: {
				background: colors.grey[900],
			},
			_checked: {
				_hover: {
					background: colors.grey[900],
				},
			},
		},
		label: {
			...textStyles['Text-S-Regular'],
			color: 'white',
			_checked: {
				color: 'white',
			},
		},
		control: {
			border: 'none',
			background: 'white',
			borderRadius: 'full',
			_checked: {
				background: 'white',
				_before: {
					borderRadius: 'full',
				},
			},
		},
	}),

	single: definePartsStyle({
		control: {
			borderRadius: 'full',
			_checked: {
				_before: {
					borderRadius: 'full',
				},
			},
		},
	}),
	filter: definePartsStyle({
		container: {
			border: 'none',
			padding: '8px 16px',
			background: colors.grey[100],
			borderRadius: '16px',
			_checked: {
				border: 'none',
				boxShadow: 'none',
				background: colors.grey[900],
				_hover: {
					background: colors.grey[900],
				},
			},
			_hover: {
				background: colors.grey[500],
			},
			_active: {
				background: colors.grey[700],
			},
		},
		label: {
			...textStyles['Text-S-Regular'],
			margin: 0,
			_checked: {
				color: 'white',
			},
		},
		control: {
			display: 'none',
		},
	}),
};

const radioTheme = defineMultiStyleConfig({
	baseStyle,
	variants,
	sizes,
	defaultProps: { size: 'md', variant: 'single' },
});

export default radioTheme;
