import { useNavigate } from 'react-router-dom';
import { Button, Heading, Text, Tooltip, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import AV from 'assets/icons/SideBar/Av.svg';
import PER from 'assets/icons/SideBar/Per.svg';
import ContractCard from 'components/cards/ContractCard';
import { useGetProductPerAVOpenQuery } from 'services/requests/product';

const ContractsCards = (): JSX.Element => {
	const { data: investProductStatus } = useGetProductPerAVOpenQuery();
	const hasPer = investProductStatus?.isPerOpen;
	const navigate = useNavigate();

	return (
		<VStack spacing="32px" align="start" w="100%">
			<Heading variant="Title-L-SemiBold">Vos options pour investir</Heading>
			<Wrap w="100%" spacing="24px">
				<WrapItem minW="250px" flex="1">
					<ContractCard
						icon={AV}
						title="Assurance vie"
						description="Préparez votre avenir en gardant une liquidité totale de vos investissements."
						footer={
							<Button
								alignSelf="end"
								onClick={() => {
									navigate('/invest/ouverture-assurance-vie');
								}}
							>
								Investir
							</Button>
						}
					/>
				</WrapItem>
				<WrapItem minW="250px" flex="1">
					<ContractCard
						icon={PER}
						title="Plan d’épargne retraite"
						description="Préparez votre retraite en allégeant votre pression fiscale."
						footer={
							<Button
								alignSelf="end"
								onClick={() => {
									if (!hasPer) navigate('/invest/ouverture-per-individuel');
								}}
								disabled={hasPer}
								variant={hasPer ? 'secondary' : 'primary'}
							>
								{hasPer && (
									<Tooltip
										label={
											<>
												<Text fontSize="14px" color="white">
													Vous ne pouvez détenir qu’un seul PER individuel à titre personnel.
												</Text>
											</>
										}
										borderRadius="base"
										p={4}
										color="white"
										bg="primary.black"
									>
										Investir
									</Tooltip>
								)}
								{!hasPer && 'Investir'}
							</Button>
						}
					/>
				</WrapItem>
			</Wrap>
		</VStack>
	);
};

export default ContractsCards;
