import { backendApi } from 'services/apiService';
import { EnvelopeType } from 'store/types/global.type';

export type ApicilOperationType =
	| 'VERSEMENT'
	| 'REMBOURSEMENT_AVANCE'
	| 'VERSEMENT_PROGRAMME'
	| 'RACHAT_TOTAL'
	| 'RACHAT_PARTIEL'
	| 'RACHAT_PARTIEL_PROGRAMME'
	| 'ARBITRAGE'
	| 'ARBITRAGE_PROGRAMME'
	| 'SECURISATION_PLUS_VALUES'
	| 'DYNAMISATION_INTERETS'
	| 'CHANGEMENT_MODE_GESTION'
	| 'OPTION_ARBITRAGE_PROGRAMME'
	| 'SUPPRESSION'
	| 'DEMANDE_AVANCE'
	| 'CHANGEMENT_COORDONNEES'
	| 'CHANGEMENT_IBAN';

export type StatusOperations =
	| 'A_SIGNER'
	| 'A_COMPLETER'
	| 'TRAITEMENT_DOSSIER_EN_COURS'
	| 'DENOUE'
	| 'ABANDONNE'
	| 'REFUSEE'
	| 'INELIGIBLE';

export type OperationDTO = {
	id: string;
	typeOperation: ApicilOperationType;
	montant: string;
	dateSaisie: string;
	envelopeType: EnvelopeType;
	codeStatut: StatusOperations;

	// nom: string;
	// prenom: string;
	// email: string;
	// numeroContrat: string;
	// typeSignature: string;
	// produit: string;
	// modeReglement: string;
	// nombreInstances: number;
	// dateValidation?: string;
	// statut: string;
	// sourceOperation: string;
	// signeElectroniquement: boolean;
	// signe: boolean;
	// afficherSupprimer: boolean;
	// afficherRelance: boolean;
	// afficherVoir: boolean;
	// afficherAcquittement: boolean;
	// estProduitLux: boolean;
	// estOperationBanque: boolean;
	// idCourtier: string;
	// periodicite: string;
	// selfcare: string;
	// dateEffet?: string;
	// idCreateur: number;
	// idPersonne: number;
	// referenceClient: string;
	// idValidateur?: string;
	// dateLimiteSignature?: string;
};

export type OperationEncoursDTO = {
	totalOperations: number;
	operations: OperationDTO[];
};

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllOperations: builder.query<OperationEncoursDTO, void>({
			query: () => '/operations',
			providesTags: ['Operations'],
		}),
	}),
});

export const { useGetAllOperationsQuery } = extendedApi;
