import { FC } from 'react';
import { Heading, VStack } from '@chakra-ui/react';

import ValidatingSubscription from 'components/cards/subscriptions/ValidatingSubscription';
import { ProductSubscriptionType } from 'store/types/global.type';
import { isNone } from 'utils/functions';

const ValidatingSubscriptionList: FC<{
	subscriptions: ProductSubscriptionType[] | undefined;
}> = ({ subscriptions }) => {
	if (isNone(subscriptions) || subscriptions.length === 0) return null;

	return (
		<VStack w="100%" align="start" spacing="24px">
			<Heading variant="Title-L-SemiBold">Mes souscriptions en cours de validation</Heading>
			<VStack w="100%" align="start" spacing="md">
				{subscriptions?.map((d) => (
					<ValidatingSubscription key={d.id} {...d} showAmount />
				))}
			</VStack>
		</VStack>
	);
};

export default ValidatingSubscriptionList;
