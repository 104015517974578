import { Document } from '@carbon/icons-react';
import { Button, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import axios from 'axios';
import fileDownload from 'js-file-download';

const DocumentToDownload = ({
	documentUrl,
	documentFilename,
	name,
	handleDownloadCustom,
}: {
	documentUrl?: string;
	documentFilename?: string;
	name: string;
	handleDownloadCustom?: () => void;
}) => {
	const handleDownload = (url: string, filename: string) => {
		axios.get(url, { responseType: 'blob' }).then((res) => {
			fileDownload(res.data, filename);
		});
	};

	return (
		<Stack w="100%" justify="space-between" direction={{ base: 'column', xl: 'row' }}>
			<HStack spacing="16px">
				<Icon boxSize="32px" as={Document} />
				<Text variant="Text-M-Medium">{name}</Text>
			</HStack>

			<Button
				alignSelf={{ base: 'end', md: undefined }}
				variant="secondary"
				onClick={() => {
					if (handleDownloadCustom) {
						handleDownloadCustom();
					} else {
						handleDownload(documentUrl!, documentFilename!);
					}
				}}
			>
				Télécharger
			</Button>
		</Stack>
	);
};
export default DocumentToDownload;
