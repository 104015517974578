import { Box, Card, Divider, HStack, Stack, Text, useBreakpointValue, VStack } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import { BSPCESimulationResponse } from 'services/requests/corporate';
import { displayMoney, displayPercentage } from 'utils/rendering';

import { VestingGraph, VestingGraphData } from './GraphSection';

type VestingCardProps = {
	statData: BSPCESimulationResponse['vested_parts_repartition'];
	graphData: BSPCESimulationResponse['graph_repartition'];
};

const formatGraphData = (graphData: VestingCardProps['graphData']): VestingGraphData[] =>
	graphData.map((data, idx) => {
		const date = new Date(data.date);
		const monthString = date.toLocaleString('default', { month: 'long' });

		return {
			year: date.getFullYear(),
			month: idx,
			monthIndex: date.getMonth(),
			monthString: monthString.charAt(0).toUpperCase() + monthString.slice(1),
			vested: data.vested_parts,
			notVested: data.non_vested_parts,
		};
	});

const VestingCard = ({ statData, graphData }: VestingCardProps): JSX.Element => {
	const isMobile = useBreakpointValue({ base: true, md: false });

	return (
		<VStack w="100%" align="start" spacing="16px">
			<HStack>
				<Text>Vesting des parts</Text>
				<CustomTooltip
					placement={isMobile ? 'bottom' : 'right'}
					text={
						<>
							Le vesting est un calendrier utilisé dans le cadre de <b>l’émission de BSPCE</b>. Il permet de déterminer
							le rythme auquel les BSPCE sont définitivement acquis par les bénéficiaires et deviennent ainsi
							exerçables.
						</>
					}
				/>
			</HStack>
			<Stack direction={{ base: 'column', md: 'row' }} spacing="16px" w="100%">
				<Card w="100%" px="24px" py="30px" display="flex" justify="space-between">
					<Stack
						direction={{ base: 'column', md: 'row' }}
						w="100%"
						justify="space-between"
						align={{ base: 'left', md: 'center' }}
						mb={{ base: '16px', md: '0px' }}
					>
						<HStack spacing="8px">
							<Box w="12px" h="12px" bg="#CFE2D9" borderRadius="2px" />
							<Text>Parts vestées</Text>
							<Text variant="Text-S-Bold" color="grey.900">
								{displayPercentage(statData.vested_parts_percentage)}
							</Text>
						</HStack>
						<Text>
							{displayMoney(statData.vested_parts_amount)}{' '}
							<Text as="span" variant="detail">
								({statData.vested_parts_number} parts)
							</Text>
						</Text>
					</Stack>

					<Stack
						direction={{ base: 'column', md: 'row' }}
						w="100%"
						justify="space-between"
						align={{ base: 'left', md: 'center' }}
						mb={{ base: '16px', md: '0px' }}
					>
						<HStack spacing="8px">
							<Box w="12px" h="12px" bg="#D2C6EB" borderRadius="2px" />
							<Text>Parts non vestées</Text>
							<Text variant="Text-S-Bold" color="grey.900">
								{displayPercentage(statData.non_vested_parts_percentage)}
							</Text>
						</HStack>
						<Text>
							{displayMoney(statData.non_vested_parts_amount, 2)}{' '}
							<Text as="span" variant="detail">
								({statData.non_vested_parts_number} parts)
							</Text>
						</Text>
					</Stack>

					<Divider mb={{ base: '16px', md: '0px' }} />

					<Stack
						direction={{ base: 'column', md: 'row' }}
						w="100%"
						justify="space-between"
						align={{ base: 'left', md: 'center' }}
					>
						<HStack>
							<Text>Coût d'achat des parts vestées</Text>
							<CustomTooltip
								placement={isMobile ? 'bottom' : 'right'}
								text={
									<>
										Ce chiffre est une estimation du coût d'exercice de vos droits d'achat de parts. Il est calculé en
										multipliant le nombre de parts vestées à date et leur prix d'exercice respectifs
									</>
								}
							/>
						</HStack>
						<Text variant="Text-S-Bold" color="grey.900">
							{displayMoney(statData.vested_parts_exercise_price, 2)}
						</Text>
					</Stack>

					<Stack
						direction={{ base: 'column', md: 'row' }}
						w="100%"
						justify="space-between"
						align={{ base: 'left', md: 'center' }}
					>
						<HStack>
							<Text>Gain théorique des parts vestées</Text>
							<CustomTooltip
								placement={isMobile ? 'bottom' : 'right'}
								text={
									<>
										Le gain théorique des parts vestées est estimé faisant la soustraction entre valorisation de vos
										parts vestées et le prix d’exercice de ces derniers.
									</>
								}
							/>
						</HStack>
						<Text variant="Text-S-Bold" color="grey.900">
							{displayMoney(statData.vested_parts_theoretical_gain, 2)}
						</Text>
					</Stack>
				</Card>

				<Card w="100%" h="200px">
					<VestingGraph data={formatGraphData(graphData)} />
				</Card>
			</Stack>
		</VStack>
	);
};

export default VestingCard;
