import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Close } from '@carbon/icons-react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Heading,
	HStack,
	Menu,
	MenuButton,
	MenuList,
	Show,
	Text,
	VStack,
	Wrap,
} from '@chakra-ui/react';

import CheckList from 'components/filter/CheckList';
import SearchBar from 'components/filter/SearchBar';
import SliderWithText from 'components/filter/SliderWithText';
import SortingSelect from 'components/filter/SortingSelect';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { FilterTags } from 'pages/PrivateEquity/Discover/Filter';
import { PEFund } from 'store/types/airtable.type';
import { PEFilterKeyType } from 'utils/fundFilters';

import { clearFilters, setFilter, setMaxTicket, setName, setSorting } from '../PEFilters.slice';

import { openStatusOptions, peSortOption } from './options.constants';

type FilterBarProps = {
	funds: PEFund[] | undefined;
	filteredCounts: number;
};

// if you modify this value, don't forgot to modify the variable with the same name in the backend too
const MASKED_FUND_VALUE = '●●●●●';

const FilterBar = ({ funds, filteredCounts }: FilterBarProps) => {
	const dispatch = useAppDispatch();
	const isMobile = useAppResponsive({ base: true, md: false });
	const filter = useAppSelector((state) => state.peFilters);

	const [searchParams, setSearchParams] = useSearchParams();
	const [sliderValue, setSliderValue] = useState(filter.maxTicket);

	const subscriptionRange = useMemo(
		() => ({
			min: funds?.reduce((min, fund) => (fund.ticket ? Math.min(min, fund.ticket) : min), Infinity) ?? 0,
			max: funds?.reduce((max, fund) => (fund.ticket ? Math.max(max, fund.ticket) : max), -Infinity) ?? 0,
		}),
		[funds],
	);

	useEffect(() => {
		if (!searchParams.has('maxTicket')) {
			const maxTicket = funds?.reduce((max, fund) => (fund.ticket ? Math.max(max, fund.ticket) : max), -Infinity) ?? 0;
			if (!sliderValue) setSliderValue(maxTicket);
			if (!filter.maxTicket) dispatch(setMaxTicket(maxTicket));
		}
	}, [funds, dispatch, filter.maxTicket, sliderValue, searchParams]);

	// Update query params according to filters
	useEffect(() => {
		const queryParams: Record<string, string> = {};
		if (filter.name) queryParams.name = filter.name;
		if (filter.sorting) queryParams.sorting = filter.sorting;
		if (
			(filter.maxTicket &&
				filter.maxTicket !==
					funds?.reduce((max, fund) => (fund.ticket ? Math.max(max, fund.ticket) : max), -Infinity)) ??
			0
		)
			queryParams.maxTicket = filter.maxTicket.toString();

		const arrayFilters: PEFilterKeyType[] = ['taxAdvantages', 'sectors', 'geographies', 'status', 'strategies', 'type'];
		arrayFilters.map((arrayFilter) => {
			if (filter[arrayFilter].length > 0) queryParams[arrayFilter] = filter[arrayFilter].join('*');
		});

		setSearchParams(queryParams, { preventScrollReset: true });
	}, [filter, funds, setSearchParams, subscriptionRange.max]);

	// Fetch query params in the URl and apply them
	useEffect(() => {
		const arrayFilters: PEFilterKeyType[] = ['taxAdvantages', 'sectors', 'geographies', 'status', 'strategies', 'type'];
		arrayFilters.map((arrayFilter: PEFilterKeyType) => {
			if (searchParams.has(arrayFilter)) {
				const sectors = (searchParams.get(arrayFilter) as string).split('*');
				sectors.map((el: string) => {
					dispatch(setFilter({ key: arrayFilter, value: el, isChecked: true }));
				});
			}
		});

		if (searchParams.has('name')) dispatch(setName(searchParams.get('name') as string));
		if (searchParams.has('maxTicket')) {
			const newSliderValue = parseInt(searchParams.get('maxTicket') as string);
			dispatch(setMaxTicket(newSliderValue));
			setSliderValue(newSliderValue);
		}
		if (searchParams.has('sorting')) dispatch(setSorting(searchParams.get('sorting') as string));
	}, [dispatch, searchParams]);

	const strategyOptions = useMemo<string[]>(
		() =>
			funds
				?.reduce<string[]>((acc, fund) => {
					fund.strategy?.forEach((strat) => {
						if (!acc.includes(strat) && strat !== MASKED_FUND_VALUE) acc.push(strat);
					});
					return acc;
				}, [])
				?.sort() ?? [],
		[funds],
	);

	const sectorOptions = useMemo<string[]>(
		() =>
			funds
				?.reduce<string[]>((acc, fund) => {
					fund.sectors?.forEach((sector) => {
						if (!acc.includes(sector) && sector !== MASKED_FUND_VALUE) acc.push(sector);
					});
					return acc;
				}, [])
				?.sort() ?? [],
		[funds],
	);

	const regionOptions = useMemo<string[]>(
		() =>
			funds
				?.reduce<string[]>((acc, fund) => {
					fund.regions?.forEach((region) => {
						if (!acc.includes(region) && region !== MASKED_FUND_VALUE) acc.push(region);
					});
					return acc;
				}, [])
				?.sort() ?? [],
		[funds],
	);

	const typeOptions = useMemo<string[]>(
		() =>
			funds
				?.reduce<string[]>((acc, fund) => {
					if (fund.FundType && !acc.includes(fund.FundType) && fund.FundType !== MASKED_FUND_VALUE)
						acc.push(fund.FundType);
					return acc;
				}, [])
				?.sort() ?? [],
		[funds],
	);

	const diversificationFilterList = [
		{
			key: 'regions',
			name: `Géographie${filter.geographies.length > 0 ? ` (${filter.geographies.length})` : ''}`,
			isActive: filter.geographies.length > 0,
			component: (
				<CheckList
					options={regionOptions}
					localFilter={filter.geographies}
					onChange={(value, isChecked) => dispatch(setFilter({ key: 'geographies', value, isChecked }))}
				/>
			),
		},
		{
			key: 'sectors',
			name: `Secteur${filter.sectors.length > 0 ? ` (${filter.sectors.length})` : ''}`,
			isActive: filter.sectors.length > 0,
			component: (
				<CheckList
					options={sectorOptions}
					localFilter={filter.sectors}
					onChange={(value, isChecked) => dispatch(setFilter({ key: 'sectors', value, isChecked }))}
				/>
			),
		},
		{
			key: 'strategies',
			name: `Stratégie${filter.strategies.length > 0 ? ` (${filter.strategies.length})` : ''}`,
			isActive: filter.strategies.length > 0,
			component: (
				<CheckList
					options={strategyOptions}
					localFilter={filter.strategies}
					onChange={(value, isChecked) => dispatch(setFilter({ key: 'strategies', value, isChecked }))}
				/>
			),
		},
	];

	const diversificationCount = filter.geographies.length + filter.sectors.length + filter.strategies.length;

	const filterList = [
		{
			key: 'ticket',
			name: 'Minimum de souscription',
			isActive: sliderValue !== subscriptionRange.max,
			component: (
				<Box px="12px">
					<SliderWithText
						range={subscriptionRange}
						onChange={(newValue) => setSliderValue(newValue)}
						onChangeEnd={(newValue) => dispatch(setMaxTicket(newValue))}
						value={sliderValue}
						step={1000}
					/>
				</Box>
			),
		},
		{
			key: 'status',
			name: `Statut${filter.status.length > 0 ? ` (${filter.status.length})` : ''}`,
			isActive: filter.status.length > 0,
			component: (
				<CheckList
					options={openStatusOptions}
					localFilter={filter.status}
					onChange={(value, isChecked) => dispatch(setFilter({ key: 'status', value, isChecked }))}
				/>
			),
		},
		{
			key: 'type',
			name: `Type${filter.type.length > 0 ? ` (${filter.type.length})` : ''}`,
			isActive: filter.type.length > 0,
			component: (
				<CheckList
					options={typeOptions}
					localFilter={filter.type}
					onChange={(value, isChecked) => dispatch(setFilter({ key: 'type', value, isChecked }))}
				/>
			),
		},
		...(isMobile
			? diversificationFilterList
			: [
					{
						key: 'diversifications',
						name: `Diversifications${diversificationCount > 0 ? ` (${diversificationCount})` : ''}`,
						isActive: diversificationCount > 0,
						component: (
							<Accordion defaultIndex={[0]} allowToggle minW="100%">
								{diversificationFilterList.map((filterElement) => (
									<AccordionItem key={filterElement.key}>
										<AccordionButton>
											<Box flex="1" textAlign="left">
												{filterElement.name}
											</Box>
											<AccordionIcon />
										</AccordionButton>
										<AccordionPanel>{filterElement.component}</AccordionPanel>
									</AccordionItem>
								))}
							</Accordion>
						),
					},
			  ]),
	];

	const resetFilters = () => {
		setSliderValue(funds?.reduce((max, fund) => Math.max(max, fund.ticket), -Infinity) ?? 0);
		dispatch(clearFilters());
	};

	return (
		<VStack w="100%" align="start" spacing="16px">
			<Heading variant="Title-L-SemiBold">Investir en Private Equity</Heading>
			<Text variant="Text-M-Regular">Mon objectif d'investissement :</Text>

			<HStack w="100%">
				<FilterTags
					multiFilter
					setFilter={setFilter}
					selectedFilters={filter.taxAdvantages}
					filters={[
						{
							value: 'revenueTaxAdvantage',
							label: 'Réduire mon impôt',
							filterType: 'taxAdvantages',
						},
						{
							value: 'noRevenueTaxAdvantage',
							label: 'Dynamiser mon portefeuille',
							filterType: 'taxAdvantages',
						},
						{
							value: 'reinvestTaxAdvantage',
							label: 'Investir dans le cadre d’une cession',
							filterType: 'taxAdvantages',
						},
						{
							value: 'gainsTaxAdvantage',
							label: "Plus-value exonérée de l'IR",
							filterType: 'taxAdvantages',
						},
					]}
				/>
			</HStack>

			<SearchBar
				placeholder="Rechercher un fonds ou un gérant"
				value={filter.name}
				onChange={(name) => dispatch(setName(name))}
			/>

			{isMobile ? (
				<VStack spacing="sm" pb="24px" align="start" w="100%">
					<Box w="100%">
						<SortingSelect
							sorting={filter.sorting}
							sortOption={peSortOption}
							onSelection={(selection: string) => dispatch(setSorting(selection))}
						/>
					</Box>
					<Box w="100%">
						<Menu closeOnSelect={false} matchWidth>
							<MenuButton w="100%" px={6} py={3} borderRadius="sm" borderWidth="1px" bgColor="white" flex={1}>
								<HStack w="100%" justify="space-between">
									<Text variant="Text-S-Regular">Filtres</Text>
									<ChevronDownIcon />
								</HStack>
							</MenuButton>
							<MenuList zIndex="dropdown" px="12px">
								<Accordion defaultIndex={[0]} allowToggle minW="100%">
									{filterList.map((filterElement) => (
										<AccordionItem key={filterElement.key}>
											<AccordionButton>
												<Box flex="1">
													<Text variant="Text-S-Regular">{filterElement.name}</Text>
												</Box>
												<AccordionIcon />
											</AccordionButton>
											<AccordionPanel>{filterElement.component}</AccordionPanel>
										</AccordionItem>
									))}
								</Accordion>
							</MenuList>
						</Menu>
					</Box>
				</VStack>
			) : (
				<Wrap shouldWrapChildren spacing="16px" w="100%">
					{filterList.map((filterElement) => (
						<Box w="100%" key={filterElement.key}>
							<Menu closeOnSelect={false}>
								<MenuButton
									minW={filterElement.name === 'Minimum de souscription' ? '280px' : undefined}
									w="100%"
									px={6}
									py={3}
									borderRadius="sm"
									borderWidth="1px"
									bgColor="white"
									borderColor="grey.500"
									flex={1}
								>
									<HStack w="100%" justify="space-between">
										<Text variant="Text-S-Regular">{filterElement.name}</Text>
										<ChevronDownIcon />
									</HStack>
								</MenuButton>
								<MenuList zIndex="dropdown">{filterElement.component}</MenuList>
							</Menu>
						</Box>
					))}
				</Wrap>
			)}

			<HStack w="100%" justify="space-between">
				<HStack spacing="24px">
					<Text variant="title">
						{filteredCounts} Fonds disponible{filteredCounts > 1 ? 's' : ''}
					</Text>
					<Show above="md">
						<Box>
							<SortingSelect
								sorting={filter.sorting}
								sortOption={peSortOption}
								onSelection={(selection: string) => dispatch(setSorting(selection))}
							/>
						</Box>
					</Show>
				</HStack>
				<Button
					onClick={resetFilters}
					variant="tertiary"
					rightIcon={
						<Box>
							<Close aria-hidden="true" size="20" />
						</Box>
					}
				>
					Réinitialiser les filtres
				</Button>
			</HStack>
		</VStack>
	);
};
export default FilterBar;
