import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Card, Heading, HStack, Spacer, Stack, Text } from '@chakra-ui/react';

import { useAppResponsive } from 'hooks/useAppResponsive';

import { InvestmentCardDataType } from './welcomePageCardsData';

const InvestmentCard = ({ investmentData }: { investmentData: InvestmentCardDataType }): JSX.Element => {
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;

	return (
		<Card w="100%" role="group" transition="0.5s">
			<Stack spacing="16px">
				<HStack align="top">
					{investmentData.icon}
					<Stack spacing="0px">
						<Heading variant="Title-S-SemiBold" w={isMobile ? '191px' : '100%'}>
							{investmentData.title}
						</Heading>
						{!isMobile && (
							<Text variant="Text-S-Regular" color="grey.900">
								{investmentData.subtitle}
							</Text>
						)}
					</Stack>
					<Spacer />
					<Stack pr={!isMobile ? '16px' : ''} _groupHover={{ pr: '0px' }} transition="0.5s">
						<ArrowForwardIcon w="24px" h="24px" />
					</Stack>
				</HStack>
				{((isMobile && investmentData.subtitle) || (investmentData.description && investmentData.condition)) && (
					<Stack spacing="0px">
						{isMobile && (
							<Text variant="Text-S-Regular" pb={investmentData.description || investmentData.condition ? '16px' : ''}>
								{investmentData.subtitle}
							</Text>
						)}
						<Text variant="Text-M-Regular">{investmentData.description}</Text>
						<Text variant="Text-M-Regular">
							<b>{investmentData.condition}</b>
						</Text>
					</Stack>
				)}
			</Stack>
		</Card>
	);
};

export default InvestmentCard;
