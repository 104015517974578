import { useEffect, useState } from 'react';

export type ParentalLink = 'sister' | 'brother' | 'husband' | 'wife' | 'other';

type Beneficiary = {
	firstname: string;
	lastname: string;
	birthDate: string;
	birthCity: string;
	parentalLink: ParentalLink;
	percentage: number;
};

type Props = {
	firstname: string;
	lastname: string;
	birthDate: string;
	birthCity: string;
};

const parentalLinkMap: Record<ParentalLink, string> = {
	sister: 'Ma soeur',
	brother: 'Mon frère',
	husband: 'Mon conjoint',
	wife: 'Ma conjointe',
	other: 'Autres',
};

const emptyBeneficiary: Beneficiary = {
	firstname: '',
	lastname: '',
	birthDate: '',
	birthCity: '',
	parentalLink: 'sister',
	percentage: 100,
};

const addPaddingZero = (nb: number) => nb.toString().padStart(2, '0');

const formatDate = (date: Date) => {
	return `${addPaddingZero(date.getDate())}/${addPaddingZero(date.getMonth() + 1)}/${date.getFullYear()}`;
};

export const useBeneficiary = ({ firstname, lastname, birthDate, birthCity }: Props) => {
	const [outputClause, setOutputClause] = useState('');
	const [totalPercentage, setTotalPercentage] = useState(100);
	const [beneficiaries, setBeneficiaries] = useState<Beneficiary[]>([]);

	useEffect(() => {
		const newDescription = `${beneficiaries.reduce(
			(acc, beneficiary) =>
				acc +
				`\n- ${beneficiary.percentage}% : ${beneficiary.firstname} ${beneficiary.lastname}, ${
					parentalLinkMap[beneficiary.parentalLink]
				} né(e) à ${beneficiary.birthCity} le ${
					beneficiary.birthDate
				}, vivant(e) ou représenté(e), à défaut mes héritiers.\n`,
			`Je soussigné ${firstname} ${lastname}, né à ${birthCity} le ${formatDate(
				new Date(birthDate),
			)}, désigne comme bénéficiaires de mon contrat :`,
		)}`;
		setOutputClause(newDescription);
		setTotalPercentage(beneficiaries.reduce((acc, val) => acc + val.percentage, 0));
	}, [beneficiaries, birthCity, birthDate, firstname, lastname]);

	const addBeneficiary = () =>
		setBeneficiaries((old) => [
			...old.map((e) => ({
				...e,
				percentage: Number((emptyBeneficiary.percentage / (beneficiaries.length + 1)).toFixed(0)),
			})),
			{
				...emptyBeneficiary,
				percentage: Number((emptyBeneficiary.percentage / (beneficiaries.length + 1)).toFixed(0)),
			},
		]);

	const editBeneficiaryField = (newValue: Beneficiary, index: number) => {
		setBeneficiaries((old) => old.map((e, i) => (index === i ? newValue : e)));
	};

	const deleteBeneficiary = (index: number) => {
		setBeneficiaries((old) =>
			old
				.filter((_e, i) => i !== index)
				.map((e) => ({
					...e,
					percentage: Number((emptyBeneficiary.percentage / (beneficiaries.length - 1)).toFixed(0)),
				})),
		);
	};

	const isBeneficiaryCorrect = (beneficiary: Beneficiary) => {
		return (
			!!beneficiary.birthCity &&
			!!beneficiary.firstname &&
			!!beneficiary.lastname &&
			beneficiary.birthDate.match(/^\d{2}\/\d{2}\/\d{4}$/)
		);
	};

	return {
		beneficiaries,
		parentalLinkMap,
		totalPercentage,
		addBeneficiary,
		deleteBeneficiary,
		editBeneficiaryField,
		isBeneficiaryCorrect,
		outputClause,
	};
};
