import { ArrowLeft, ArrowRight } from '@carbon/icons-react';
import { Box, Button, ButtonProps, Center } from '@chakra-ui/react';

import './Button.css';

// either left or right, not both, not none
type Direction = { left: true; right?: undefined } | { left?: undefined; right: true };

type RawProps = {
	icon?: JSX.Element;
	animated?: true;
} & Direction;

type IconButtonProps = Omit<ButtonProps, keyof RawProps | 'leftIcon' | 'rightIcon'> & RawProps;

const IconButton = ({ icon, animated, left, right, ...props }: IconButtonProps) => (
	<Button
		{...props}
		leftIcon={
			left &&
			icon && (
				// Boxed to prevent a bug with carbon icon https://github.com/carbon-design-system/carbon/issues/3658
				<Box className={animated && 'animate-left'}>{icon}</Box>
			)
		}
		rightIcon={
			right &&
			icon && (
				// Boxed to prevent a bug with carbon icon https://github.com/carbon-design-system/carbon/issues/3658
				<Center className={animated && 'animate-right'}>{icon}</Center>
			)
		}
	/>
);

type RawArrowButtonProps = Partial<Direction>;
type ArrowButtonProps = Omit<IconButtonProps, 'icon' | 'animated'> & RawArrowButtonProps;

const ArrowButton: React.FC<ArrowButtonProps> = ({ left, right, ...props }: ArrowButtonProps) =>
	left ? (
		<IconButton animated left icon={<ArrowLeft size="16" />} {...props} />
	) : (
		<IconButton animated right icon={<ArrowRight size="16" />} {...props} />
	);

export { ArrowButton, IconButton };
