import { useNavigate } from 'react-router-dom';

import useThemedToast from 'hooks/useThemedToast';
import {
	useCreateSavingsAccountMutation,
	useGetSavingsAccountsSubscriptionsQuery,
} from 'services/requests/savingsAccounts';

const useGotoCashOnboarding = () => {
	const navigate = useNavigate();
	const [createSavingsAccount, { isLoading }] = useCreateSavingsAccountMutation();
	const { data: savingsAccountSubscriptions } = useGetSavingsAccountsSubscriptionsQuery();
	const toast = useThemedToast();

	const goToOnboarding = async () => {
		if (savingsAccountSubscriptions?.length && savingsAccountSubscriptions?.length > 0) {
			navigate(`/cash/onboarding/${savingsAccountSubscriptions[0].id}`);
		} else {
			await createSavingsAccount()
				.unwrap()
				.then(({ id }) => navigate(`/cash/onboarding/${id}`))
				.catch((error) => {
					if (error.data.statusCode === 409) {
						toast({
							title: error.data.message,
							status: 'error',
						});
					} else {
						toast({
							title: 'Une erreur est survenue',
							status: 'error',
						});
					}
				});
		}
	};

	return { goToOnboarding, isLoading };
};

export default useGotoCashOnboarding;
