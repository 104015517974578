import { FormProvider, useForm } from 'react-hook-form';
import { WarningAltFilled } from '@carbon/icons-react';
import { Box, Card, chakra, Heading, Text, VStack } from '@chakra-ui/react';
import SelectOrAddBankAccountFormContext from 'formContexts/BankAccount/BankAccountSelector';

import BottomStepBar from 'components/steppers/BottomStepBar';
import { SouscriptionStepProps } from 'onboarding/Stepper/stepper.slice';
import { UpdateInvestSubscriptionBankAccount } from 'services/requests/invest/subscription';
import { RepresentativeType } from 'store/types/subscription.type';
import colors from 'theme/foundations/colors';

const Bancaire = ({
	subscription,
	onNext,
	onPrev,
}: SouscriptionStepProps<UpdateInvestSubscriptionBankAccount>): JSX.Element => {
	const methods = useForm<{ bankAccountId: string }>();

	const onSubmit = (data: { bankAccountId: string }) => {
		if (data.bankAccountId) {
			onNext({ bankInformationId: data.bankAccountId });
		}
	};

	return (
		<VStack align="start" spacing="48px" pb="40px">
			<chakra.form w="100%" onSubmit={methods.handleSubmit(onSubmit)}>
				<VStack align="start" spacing="24px">
					<Heading variant="Title-XL-Bold">Mes coordonnées bancaires</Heading>
					<Text variant="Text-M-Regular" color="grey.900">
						Le versement initial sera prélevé sous une semaine environ depuis le compte renseigné. Vous pourrez, à tout
						moment, modifier le compte enregistré directement à partir de votre espace client.
					</Text>
					{subscription.envelope.type === 'PER' &&
						subscription.representativeType === RepresentativeType.PROFESSIONAL && (
							<Card align="center" gap="24px" direction="row" borderColor="yellow.500">
								<Box w="32px" h="32px">
									<WarningAltFilled size="32" color={colors.yellow[500]} />
								</Box>
								<Text variant="Text-M-Regular" color="yellow.700">
									Attention : vous avez indiqué vouloir souscrire à titre professionnel. Assurez-vous que le compte que
									vous allez utiliser pour souscrire est bien le même que celui associé à votre entreprise.
								</Text>
							</Card>
						)}
					<FormProvider {...methods}>
						<SelectOrAddBankAccountFormContext />
					</FormProvider>
				</VStack>

				<BottomStepBar onPrev={onPrev} nextSubmit />
			</chakra.form>
		</VStack>
	);
};

export default Bancaire;
