import { Card, Heading, Image, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { ProductType } from 'store/types/global.type';

import { bgColors, HeaderProps } from './index';

export const HeaderCard = ({ type, heading, ...data }: HeaderProps): JSX.Element => (
	<Card
		p={type === ProductType.SCPI || type === ProductType.PE ? '0' : '40px'}
		w="100%"
		border="none"
		bg={type === ProductType.INVEST || type === ProductType.CASH ? bgColors[type] : undefined}
	>
		<Wrap w="100%" spacing="lg" align="center">
			{(type === ProductType.PE || type === ProductType.SCPI) && 'picture' in data && (
				<WrapItem flex="1" minW="300px">
					<Card flex="1" border="none" p="0">
						<Image objectFit="cover" minH="200px" borderRadius="base" src={data.picture} />
					</Card>
				</WrapItem>
			)}
			<WrapItem flex="1" minW="300px">
				<VStack w="100%" h="100%" align="start" justify="center" spacing="sm">
					<Text variant="Caption">{heading}</Text>
					{typeof data.title === 'string' ? <Heading variant="Title-L-Bold">{data.title}</Heading> : data.title}
					{data.info}
				</VStack>
			</WrapItem>
		</Wrap>
	</Card>
);
