import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { ErrorBoundary } from '@highlight-run/react';
import {
	ArcElement,
	BarController,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Filler,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	TimeScale,
	Tooltip,
} from 'chart.js';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PersistGate } from 'redux-persist/integration/react';
import ResizeObserver from 'resize-observer-polyfill';

import { getAppEnvironment } from 'hooks/getAppEnvironment';
import eventTracker from 'services/events/eventTracker';
import { ToastContainer } from 'utils/standaloneToast';

import 'dayjs/locale/fr';
import 'chartjs-adapter-dayjs';

import App from './app/App';
import { persistor, store } from './store/store';
import * as serviceWorker from './serviceWorker';
import theme from './theme';

import './theme/index.css';
import './styles/notificationPing.css';

const { isProductionOrStaging } = getAppEnvironment();

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.locale('fr');
eventTracker.init();

ChartJS.register(
	CategoryScale,
	LinearScale,
	TimeScale,
	BarElement,
	BarController,
	Tooltip,
	LineElement,
	PointElement,
	Filler,
	Legend,
	ArcElement,
);
ChartJS.defaults.font.family = 'Poppins';

window.ResizeObserver = ResizeObserver; // Set the Observer globally: https://github.com/plouc/nivo/issues/2018

// disable ts error for Intercom since it's imported in index.html script tag
declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Intercom: any;
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HighlightWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) =>
	isProductionOrStaging ? <ErrorBoundary showDialog>{children}</ErrorBoundary> : <>{children}</>;

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ChakraProvider theme={theme} resetCSS>
					<HighlightWrapper>
						<App />
						<ToastContainer />
					</HighlightWrapper>
				</ChakraProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
