import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	PinInput,
	PinInputField,
	Text,
	VStack,
} from '@chakra-ui/react';

import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import {
	useGetKYCQuery,
	useRequestUpdateApicilKYCMutation,
	useSignUpdateApicilKYCMutation,
} from 'services/requests/kyc';
import { SignUpdateKyc } from 'store/types/KYC.type';

type UpdateKycFormContextProps = {
	hasChanged: boolean;
	onCompleted: () => void;
};

export const UpdateKycForm = ({ hasChanged, onCompleted }: UpdateKycFormContextProps) => {
	const { data: kyc } = useGetKYCQuery();
	const toast = useThemedToast();
	const [requestUpdateKyc, { isLoading: isRequestLoading, isUninitialized: isRequestUninitialized }] =
		useRequestUpdateApicilKYCMutation();
	const [signUpdateKyc, { isLoading: isSignLoading }] = useSignUpdateApicilKYCMutation();

	const {
		handleSubmit,
		watch,
		formState: { errors },
		control,
	} = useForm<SignUpdateKyc>({ defaultValues: { otp: '' } });

	const otp = watch('otp');

	const onSubmit = handleSubmit((formData: SignUpdateKyc) => {
		signUpdateKyc({ otp: formData.otp })
			.unwrap()
			.then(() => {
				toast({
					title: 'Demande signée avec succès',
					status: 'success',
					isClosable: true,
				});
				onCompleted();
			})
			.catch(() => {
				toast({
					title: 'Une erreur est survenue',
					status: 'error',
					isClosable: true,
				});
			});
	});

	if (isRequestUninitialized)
		return (
			<Button
				size="lg"
				onClick={() => {
					requestUpdateKyc({ hasChanged })
						.unwrap()
						.catch(() => {
							toast({
								title: 'Une erreur est survenue',
								status: 'error',
								isClosable: true,
							});
						});
				}}
				isLoading={isRequestLoading}
				isDisabled={isRequestLoading}
			>
				Recevoir mon code par SMS
			</Button>
		);

	return (
		<form onSubmit={onSubmit}>
			<VStack align="start" spacing="16px">
				<FormControl isInvalid={!!errors.otp}>
					<FormLabel>
						Code reçu{' '}
						{kyc?.phoneNumberCode &&
							kyc?.phoneNumber &&
							`sur le numéro: ${kyc?.phoneNumberCode.replace('00', '+')}${'*'.repeat(
								kyc?.phoneNumber.length - 2,
							)}${kyc?.phoneNumber.slice(-2)}`}
					</FormLabel>
					<Controller
						control={control}
						name="otp"
						rules={{ required: true, minLength: 6, maxLength: 6 }}
						render={({ field: { ref, ...field } }) => (
							<HStack>
								<PinInput {...field}>
									<PinInputField />
									<PinInputField />
									<PinInputField />
									<PinInputField />
									<PinInputField />
									<PinInputField />
								</PinInput>
							</HStack>
						)}
					/>
					{errors.otp?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					{(errors.otp?.type === 'maxLength' || errors.otp?.type === 'minLength') && (
						<FormErrorMessage>Le code est composé de 6 chiffres</FormErrorMessage>
					)}
				</FormControl>

				<HStack align="start" spacing="16px">
					<Button
						size="lg"
						variant="secondary"
						onClick={() => {
							requestUpdateKyc({ hasChanged })
								.unwrap()
								.catch(() => {
									toast({
										title: 'Une erreur est survenue',
										status: 'error',
										isClosable: true,
									});
								});
						}}
						isDisabled={isRequestLoading}
					>
						Renvoyer un code
					</Button>
					<Button
						size="lg"
						type="submit"
						isLoading={isSignLoading}
						isDisabled={isSignLoading || !otp || otp.length < 6}
					>
						Signer ma demande
					</Button>
				</HStack>
			</VStack>
		</form>
	);
};

export const Signature = () => {
	const navigate = useNavigate();

	return (
		<VStack w="100%" align="start" spacing="24px">
			<Heading variant="Title-XL-Bold">Finalisation</Heading>

			<Text variant="body">
				C'est fini ! Merci de confirmer cette mise à jour depuis le téléphone associé à votre compte Ramify.
			</Text>

			<UpdateKycForm hasChanged onCompleted={() => navigate('/invest')} />

			<BottomStepBar showNext={false} />
		</VStack>
	);
};
