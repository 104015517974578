import { Card, Heading, HStack, Text, VStack } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import colors from 'theme/foundations/colors';
import { RiskDisplay } from 'utils/rendering/riskDisplay';

const ContractRiskCard = ({ risk, toolTipText }: { risk: RiskDisplay; toolTipText: JSX.Element }) => {
	const RisqueCard = [
		{
			profil: 'Prudent',
			description: 'Je suis prêt(e) à accepter des rendements plus faibles pour plus de stabilité.',
			risquecolor: colors.primary.yellow,
			bgColor: 'rgba(255, 187, 44, 0.15)',
			rangeRisque: [1, 4],
		},
		{
			profil: 'Moderé',
			description: 'Je recherche un portefeuille avec des gains ou pertes modérés sur le long terme.',
			risquecolor: colors.yellow[500],
			bgColor: 'rgba(249, 235, 208, 1)',
			rangeRisque: [5, 7],
		},
		{
			profil: 'Agressif',
			description: 'Je suis prêt(e) à accepter un risque plus élevé pour un rendement plus élevé sur le long terme.',
			risquecolor: colors.yellow[700],
			bgColor: 'rgba(238, 228, 210, 1)',
			rangeRisque: [8, 10],
		},
	];
	return (
		<>
			{RisqueCard.filter((item) => item.rangeRisque[0] <= risk.value && item.rangeRisque[1] >= risk.value).map(
				(item) => (
					<Card w="100%" px="24px" bg={item.bgColor}  borderColor={item.bgColor} h="100%" key={item.profil}>
						<VStack spacing="16px" align="start" w="100%" >
							<HStack spacing="8px" align="center" w="100%">
								<Text variant="Caption" color={colors.grey[900]}>
									Profil de risque
								</Text>
								<CustomTooltip text={toolTipText} />
							</HStack>
							<HStack spacing="16px" align="center">
								<Heading variant="Title-L-Bold" color={item.risquecolor}>
									{risk.value.toLocaleString('fr-FR')}
									<Text as="span" variant="Text-M-Bold" color={item.risquecolor}>
										/10
									</Text>
								</Heading>

								<Text variant="Text-M-Bold">{item.profil}</Text>
							</HStack>
						</VStack>
					</Card>
				),
			)}
		</>
	);
};
export default ContractRiskCard;
