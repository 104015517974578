import { Heading, VStack } from '@chakra-ui/react';

// Components
import BottomStepBar from 'components/steppers/BottomStepBar';

import ContactInformationForm from '../../Forms/ContactInformationForm';
import { KycStepProps } from '../../utils';

const Coordonnees = ({ onNext, onPrev }: KycStepProps): JSX.Element => (
	<VStack w="100%" spacing="24px" align="left">
		<Heading variant="Title-XL-Bold">Mes coordonnées</Heading>

		<ContactInformationForm
			onSuccess={onNext}
			formConfirmation={<BottomStepBar onPrev={onPrev} showNext nextSubmit />}
		/>
	</VStack>
);

export default Coordonnees;
