import { HStack, Text } from '@chakra-ui/react';

import { UninformedBadge } from './UninformedBadge';

export const AttributeKycLabel = ({ title, value }: { title: string; value?: string | null }): JSX.Element => (
	<HStack spacing="12px" align="center">
		<Text fontSize="14px" color="grey.600">
			{title} :{' '}
		</Text>
		<Text fontSize="14px" color="grey.600">
			{value ? value : <UninformedBadge />}
		</Text>
	</HStack>
);
