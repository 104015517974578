import { useState } from 'react';
import { Heading, Spinner, useDisclosure, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import PortfolioTypeCard from 'components/cards/PortfolioTypeCard';
import { useAppResponsive } from 'hooks/useAppResponsive';
import {
	ModalData,
	ModalDiscoverOurPortfoliosDesktop,
	ModalDiscoverOurPortfoliosMobile,
} from 'pages/Invest/Discover/sections/OurPortfolios';
import { useGetUniverseFundsQuery } from 'services/requests/investment';
import { PortfolioType } from 'store/types/profilage.type';

const PortfoliosCards = (): JSX.Element => {
	const {
		isOpen: isAddBankAccountModalOpen,
		onOpen: onAddAccountModalOpen,
		onClose: onAddAccountModalClose,
	} = useDisclosure();
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;
	const { data: airtableData, isLoading } = useGetUniverseFundsQuery();
	const [PortofolioType, setPortofolioType] = useState<PortfolioType>('ESSENTIAL');

	if (isLoading || !airtableData) {
		return <Spinner />;
	}
	const modalData: ModalData[] = [
		{
			title: 'Actions - ETFs',
			description:
				"Fonds indiciel dont le but est de suivre le plus fidèlement possible l'évolution d'un indice boursier, à la hausse comme à la baisse. Les ETF sont des fonds d'investissement émis par des sociétés de gestion agréés.",
			display: 'ACTIONS',
			funds: airtableData
				.filter((item) => item.newType === 'Actions')
				// While we don't have black clients integrated
				.filter((item) => item.typeDeFonds !== 'Fonds actif')
				.map((item) => {
					return item;
				}),
		},
		{
			title: 'Obligations - ETFs',
			description:
				"Fonds indiciel dont le but est de suivre le plus fidèlement possible l'évolution d'un indice obligataires, à la hausse comme à la baisse. Les ETF sont des fonds d'investissement émis par des sociétés de gestion agréés.",
			display: 'OBLIGATIONS',
			funds: airtableData
				.filter((item) => item.newType === 'Obligations')
				// While we don't have black clients integrated
				.filter((item) => item.typeDeFonds !== 'Fonds actif')
				.map((item) => {
					return item;
				}),
		},
		{
			title: 'Fonds Euro',
			description:
				'Le fonds en euros est un fonds obligataire dont le capital est garanti par l’assureur: vous n’assumez aucun risque de perte et vous percevez des intérêts annuels sur les sommes placées.',
			display: 'FONDS EURO',
			funds: airtableData
				.filter((item) => item.newType === 'Fonds Euros')
				.map((item) => {
					return item;
				}),
		},
		{
			title: 'Immobilier - SCPI',
			description:
				"La Société Civile de Placement Immobilier (SCPI) un support non coté qui collecte des fonds auprès d'épargnants pour constituer un portefeuille composé exclusivement d'immobilier physique.",
			display: 'IMMOBILIER',
			funds: airtableData
				// While we don't have black clients integrated
				// .filter((item) => item.type === 'SCPI' || item.type === 'SCI')
				.filter((item) => item.newType === 'SCPI')
				.map((item) => {
					return item;
				}),
		},
		{
			title: 'Private Equity',
			description:
				'Placement d’actualité pour promouvoir la relance économique, le Private Equity, à l’origine réservé aux business angels et investisseurs institutionnels, devient plus accessible. Il séduit l’investisseur à la recherche de sens pour son épargne et d’un rendement élevé dans un univers de taux bas.',
			display: 'PRIVATE EQUITY',
			funds: airtableData
				.filter((item) => item.newType === 'Private Equity')
				.map((item) => {
					return item;
				}),
		},
	];

	return (
		<VStack spacing="32px" align="start" w="100%">
			{isMobile ? (
				<ModalDiscoverOurPortfoliosMobile
					airtableData={modalData}
					isOpen={isAddBankAccountModalOpen}
					onClose={onAddAccountModalClose}
					portfolioType={PortofolioType}
				/>
			) : (
				<ModalDiscoverOurPortfoliosDesktop
					airtableData={modalData}
					isOpen={isAddBankAccountModalOpen}
					onClose={onAddAccountModalClose}
					portfolioType={PortofolioType}
				/>
			)}

			<Heading variant="Title-L-SemiBold">Les portefeuilles Ramify</Heading>
			<Wrap w="100%" spacing="32px">
				<WrapItem flex="1">
					<PortfolioTypeCard
						type="ESSENTIAL"
						description="Un portefeuille composé d’actions et d’obligations. Simple et performant"
						onClick={() => {
							setPortofolioType('ESSENTIAL');
							onAddAccountModalOpen();
						}}
					/>
				</WrapItem>
				<WrapItem flex="1">
					<PortfolioTypeCard
						type="FLAGSHIP"
						description="Un portefeuille multi-actifs, plus performant, plus résilient notamment au risque d’inflation"
						onClick={() => {
							setPortofolioType('FLAGSHIP');
							onAddAccountModalOpen();
						}}
					/>
				</WrapItem>
				<WrapItem flex="1">
					<PortfolioTypeCard
						type="ELITE"
						description="Le portefeuille ultime, diversification maximum avec l'addition du Private Equity"
						onClick={() => {
							setPortofolioType('ELITE');
							onAddAccountModalOpen();
						}}
					/>
				</WrapItem>
			</Wrap>
		</VStack>
	);
};

export default PortfoliosCards;
