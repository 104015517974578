import { useMemo } from 'react';
import { ViewIcon } from '@chakra-ui/icons';
import { Box, Button, Card, HStack, Icon, Image, Stack, Text, useDisclosure, VStack } from '@chakra-ui/react';

import { InvestProjectIcon, NewProjectIcon } from 'assets/icons/ProjectTypeIcons';
import productSubscriptionSteps from 'components/products/SCPI-PE/subscriptionSteps';
import { getRoundedTimeHorizon } from 'onboarding/Invest/Shared/Objective/projectData';
import { FundSubscription, PrivateEquitySubscription, SCPISubscription } from 'store/types/airtable.type';
import { ProductType } from 'store/types/global.type';
import { SavingsAccountInformation } from 'store/types/savingsAccount.type';
import { listPE, listSCPI } from 'utils/automatedFundTypes';
import { isNotNone } from 'utils/functions';
import { displayCapitalized, displayMoney, displayPercentage } from 'utils/rendering';

import InvestSubscriptionDetail from '../Invest/SubscriptionDetail';
import { SubscriptionPropsType } from '../SubscriptionCard';

import SubscriptionAdvancement from './SubscriptionAdvancement';
import SubscriptionDetail from './SubscriptionDetail';

export const isFundFoundAutomated = (fundName: string, projectType: ProductType): boolean =>
	Object.values(projectType === ProductType.PE ? listPE : listSCPI).includes(fundName);

const CurrentSubscription = ({ data, showAmount }: SubscriptionPropsType): JSX.Element => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const timeHorizonPlan = useMemo(
		() => ('timeHorizon' in data && isNotNone(data.timeHorizon) ? getRoundedTimeHorizon(data.timeHorizon) : undefined),
		[data],
	);

	const newDetails = [
		{
			label: data.typeProject === ProductType.CASH ? 'Montant épargné' : 'Montant investi',
			value:
				data.typeProject === ProductType.CASH
					? displayMoney(data.initialAUM!)
					: displayMoney((data as FundSubscription).amountVersement),
		},
		{
			label: 'Nombre de parts',
			value: (data as FundSubscription).nbShare,
		},
		{
			label: 'Type',
			value: (data as SCPISubscription).projectType
				? (data as SCPISubscription).projectType === 'NUE_PROPRIETE'
					? 'Nue propriété'
					: 'Pleine propriété'
				: '',
		},
	];

	return (
		<Card w="100%" p="16px 24px">
			<Stack align={{ base: 'start', md: 'center' }} direction={{ base: 'column', md: 'row' }} justify="space-between">
				<VStack align="start" w="100%">
					{data.typeProject === ProductType.INVEST && (
						<HStack spacing="16px">
							<Box p="4px" bg="yellow.500" w="40px" h="40px" borderRadius="6px">
								<Icon as={InvestProjectIcon} height="32px" width="32px" color="grey.700" bg="yellow.500" />
							</Box>
							<VStack align="start">
								<Text variant="title">
									{data.envelope.type === 'AV' ? 'Assurance Vie' : 'PER'} - {displayMoney(data.initialDepositAmount)}
									{+data.recurrentDepositAmount > 0 && <> - {displayMoney(data.recurrentDepositAmount)}/mois</>}
								</Text>
								<Text color={{ base: 'gray.500', md: 'gray.700' }}>
									{data.investmentPreferences?.portfolioType && (
										<>{displayCapitalized(data.investmentPreferences.portfolioType)} - </>
									)}
									{data.investmentPreferences?.risk && <>Risque {data.investmentPreferences.risk} - </>}
									{data.investmentPreferences?.temperature && (
										<>Ramify Green {data.investmentPreferences.temperature} °C - </>
									)}
									{timeHorizonPlan && <>Horizon {timeHorizonPlan.label} </>}
								</Text>
							</VStack>
						</HStack>
					)}

					{data.typeProject !== ProductType.INVEST && (
						<HStack spacing="16px">
							{data.typeProject === ProductType.CASH ? (
								<Icon
									w="34px"
									h="34px"
									bg="grey.700"
									p="6px"
									borderRadius="base"
									color="yellow.500"
									as={NewProjectIcon}
								/>
							) : (
								<Image src={data.fundCoverPicture} alt="cover" boxSize="40px" objectFit="cover" borderRadius="50%" />
							)}
							<VStack align="start">
								{data.typeProject === ProductType.CASH ? (
									<Text variant="title">
										Épargner avec Livret Épargne Ramify - Taux boosté à {displayPercentage(data.promotionalOfferRate)}
										{showAmount &&
											(data as SavingsAccountInformation).initialAUM &&
											` - ${displayMoney(data.initialAUM!)}`}
									</Text>
								) : (
									<Text variant="title">
										{data.fundPartnerName} - {data.fundName}
										{' - '}
										{showAmount && data.amountVersement && displayMoney(data.amountVersement)}
									</Text>
								)}
								<SubscriptionAdvancement
									currentStep={data.status}
									subscriptionSteps={
										productSubscriptionSteps(
											(data as SCPISubscription | PrivateEquitySubscription)?.fundName ?? '',
											data.status,
										)[data.typeProject as Exclude<ProductType, ProductType.INVEST>]
									}
								/>
							</VStack>
						</HStack>
					)}
				</VStack>
				{data.typeProject === ProductType.INVEST ? (
					<InvestSubscriptionDetail subscription={data} onClose={onClose} show={isOpen} />
				) : (
					<SubscriptionDetail
						type={data.typeProject}
						show={isOpen}
						subscription={data as SCPISubscription | PrivateEquitySubscription | SavingsAccountInformation}
						image={data.fundCoverPicture}
						details={newDetails}
						onClose={onClose}
					/>
				)}
				<Button
					variant="secondary"
					p="24px 48px"
					w={{ base: '100%', md: 'auto' }}
					rightIcon={<ViewIcon />}
					onClick={onOpen}
				>
					Suivre ma souscription
				</Button>
			</Stack>
		</Card>
	);
};

export default CurrentSubscription;
