import { useState } from 'react';

import { backendApi } from 'services/apiService';
import eventTracker from 'services/events/eventTracker';
import { SubscriptionRepartition } from 'services/requests/realEstate/simulations';

type PrivateEquitySubscriptionDto = {
	repartition: SubscriptionRepartition[];
};

export const usePrivateEquitySubscribe = () => {
	const [isSubscribing, setIsSubscribing] = useState(false);
	const [isSubscribed, setIsSubscribed] = useState(false);

	const subscribe = (dto: PrivateEquitySubscriptionDto) => {
		setIsSubscribing(true);
		eventTracker.pipedream
			.peSubscriptionSimulation(dto.repartition)
			.then(() => {
				setIsSubscribed(true);
				backendApi.util.invalidateTags(['PE_SUBSCRIPTIONS']);
			})
			.finally(() => {
				setIsSubscribing(false);
			});
	};

	return {
		subscribe,
		isSubscribing,
		isSubscribed,
	};
};
