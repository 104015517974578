import { Controller, useForm } from 'react-hook-form';
import {
	Button,
	Checkbox,
	FormControl,
	FormErrorMessage,
	ListItem,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Stack,
	Text,
	UnorderedList,
	VStack,
} from '@chakra-ui/react';

import { QAViewer } from 'components/faq/FAQ';

type SubscriptionModalProps = {
	isOpenModalToBecomeProfessional: boolean;
	OnCloseModalToBecomeProfessional: () => void;
};

const ModalToBecomeProfessional = ({
	isOpenModalToBecomeProfessional,
	OnCloseModalToBecomeProfessional,
}: SubscriptionModalProps) => {
	type FormDataType = {
		isProfessional: boolean;
	};
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormDataType>();

	const onSubmit = handleSubmit(() => {
		window.open('https://calendly.com/d/ysr-4nh-r6r/ramify-private-equity-pro-echange-avec-olivier');
		OnCloseModalToBecomeProfessional();
	});

	return (
		<Modal isOpen={isOpenModalToBecomeProfessional} onClose={OnCloseModalToBecomeProfessional} size="3xl">
			<ModalOverlay />
			<ModalContent p="16px">
				<form onSubmit={onSubmit}>
					<VStack spacing="24px" w="100%">
						<Text variant="title" pr="24px">
							Fonds réservé aux investisseurs professionnels
						</Text>
						<ModalCloseButton />
						<VStack spacing="24px" w="100%">
							<Text variant="body">
								Certains fonds de Private Equity sont réservés aux investisseurs professionnels. Pour des raisons
								légales, vous devez remplir certaines conditions pour avoir accès aux détails du fonds.
							</Text>

							<QAViewer
								variant="blue"
								question={
									<Text variant="label">
										Liste de conditions à remplir
										<Text variant="detail" as="i">
											<br />
											Minimum 2 conditions sur les 3
										</Text>
									</Text>
								}
								answer={
									<UnorderedList spacing="12px">
										<ListItem fontSize="12">Expérience d'au moins 1 an dans le secteur de la finance</ListItem>
										<ListItem fontSize="12">
											Détention d’un portefeuille d’instruments financiers d’une valeur supérieure à 500 000 €
										</ListItem>
										<ListItem fontSize="12">
											Nombre de transactions (minimum 10 opérations par trimestre d'au moins 600 €)
										</ListItem>
									</UnorderedList>
								}
							/>

							<FormControl isInvalid={!!errors.isProfessional}>
								<Controller
									name="isProfessional"
									control={control}
									rules={{ required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Checkbox onChange={onChange} isChecked={value} ref={ref}>
											<Text variant="label">
												Je confirme être un client professionnel avec une expérience dans l'investissement et dans
												l'évaluation des actifs non cotés
											</Text>
										</Checkbox>
									)}
								/>
								{errors.isProfessional?.type === 'required' && (
									<FormErrorMessage>Ce champs est obligatoire</FormErrorMessage>
								)}
							</FormControl>
						</VStack>

						<Stack w="100%">
							<Button variant="tertiary" type="submit">
								Prendre rendez-vous avec un conseiller
							</Button>
						</Stack>
					</VStack>
				</form>
			</ModalContent>
		</Modal>
	);
};
export default ModalToBecomeProfessional;
