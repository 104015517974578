import { useMemo } from 'react';

import { useGetUserQuery } from 'services/requests/user';

export type ClientType = 'classic' | 'corporate' | 'black';
export type FeesType = 'ramifyGestion' | 'envelope' | 'entryExit' | 'fondEuro' | 'fondEuroEntryExit';

export const Fees: Record<ClientType, Record<FeesType, number>> = {
	classic: {
		ramifyGestion: 0.65,
		envelope: 0.65,
		entryExit: 0,
		fondEuro: 1,
		fondEuroEntryExit: 0,
	},
	corporate: {
		ramifyGestion: 0.35,
		envelope: 0.65,
		entryExit: 0,
		fondEuro: 1,
		fondEuroEntryExit: 0,
	},
	black: {
		ramifyGestion: 0.35,
		envelope: 0.65,
		entryExit: 0,
		fondEuro: 1,
		fondEuroEntryExit: 0,
	},
};

export const useFees = () => {
	const { data: user } = useGetUserQuery();

	return useMemo(() => {
		if (user?.isBlack) return Fees.black;
		if (user?.isCorporate) return Fees.corporate;
		return Fees.classic;
	}, [user]);
};
