import { Text, VStack } from '@chakra-ui/react';

import ListCompositionPortfolio from 'components/ListCompositionPortfolio';
import { CustomAsset } from 'store/types/investment.type';
import { PortfolioType, portfolioTypeLabels } from 'store/types/profilage.type';

type PreviewArbitrageProps = {
	portfolioTypeChange: {
		old: PortfolioType;
		new: PortfolioType;
	};
	risk: {
		old: number;
		new: number;
	};
	assets: CustomAsset[];
};

const PreviewArbitrage = ({ portfolioTypeChange, risk, assets }: PreviewArbitrageProps) => {
	return (
		<VStack align="start" w="100%" spacing="24px">
			<Text variant="Text-M-Regular">
				En changeant{' '}
				{portfolioTypeChange.old !== portfolioTypeChange.new && (
					<>
						votre stratégie de
						<Text as="span" color="turquoise.900">
							{' '}
							{portfolioTypeLabels[portfolioTypeChange.old]} à {portfolioTypeLabels[portfolioTypeChange.new]}
						</Text>
						,{' '}
					</>
				)}
				{risk.old !== risk.new && (
					<>
						votre niveau de
						<Text as="span" color="turquoise.900">
							{' '}
							risque de {risk.old} à {risk.new}
						</Text>
						,{' '}
					</>
				)}
				un
				<Text as="span" color="turquoise.900">
					{' '}
					arbitrage{' '}
				</Text>
				sera effectué sur votre contrat pour obtenir l’allocation suivante :
			</Text>
			<ListCompositionPortfolio assets={assets} />
		</VStack>
	);
};

export default PreviewArbitrage;
