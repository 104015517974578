import { Badge, HStack, Image, Text } from '@chakra-ui/react';

export type AssetType = 'ACTIONS' | 'IMMOBILIER' | 'OBLIGATIONS' | 'FONDS EURO' | 'PRIVATE EQUITY';

export const AssetClassBadge = ({
	bg,
	borderColor,
	txtColor,
	title,
	icon = undefined,
}: {
	bg: string;
	borderColor: string;
	txtColor: string;
	title: string;
	icon?: string;
}) => (
	<Badge borderRadius="sm" p="2px 4px" bg={bg} borderWidth={icon ? '1px !important' : '1px'} borderColor={borderColor}>
		<HStack w="100%" spacing="3px" justify="center">
			{icon && <Image src={icon} alt="icon" />}
			<Text color={txtColor} variant="label" fontSize="10px">
				{title}
			</Text>
		</HStack>
	</Badge>
);

export const assetClassBadges: Record<AssetType, JSX.Element> = {
	ACTIONS: <Badge variant="outlineYellow">ACTIONS</Badge>,
	IMMOBILIER: <Badge variant="outlineYellow">IMMOBILIER</Badge>,
	OBLIGATIONS: <Badge variant="outlineYellow">OBLIGATIONS</Badge>,
	'FONDS EURO': <Badge variant="outlineYellow">FONDS EURO</Badge>,
	'PRIVATE EQUITY': <Badge variant="outlineYellow">PRIVATE EQUITY</Badge>,
};

export const badgesList: JSX.Element[] = [
	assetClassBadges.ACTIONS,
	assetClassBadges.OBLIGATIONS,
	assetClassBadges['FONDS EURO'],
	assetClassBadges.IMMOBILIER,
	assetClassBadges['PRIVATE EQUITY'],
];

export const listBa: Record<AssetType, JSX.Element> = {
	ACTIONS: assetClassBadges.ACTIONS,
	OBLIGATIONS: assetClassBadges.OBLIGATIONS,
	'FONDS EURO': assetClassBadges['FONDS EURO'],
	IMMOBILIER: assetClassBadges.IMMOBILIER,
	'PRIVATE EQUITY': assetClassBadges['PRIVATE EQUITY'],
};
