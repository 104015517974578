import { useMemo } from 'react';
import { ArrowLeft } from '@carbon/icons-react';
import {
	Box,
	Button,
	Card,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	HStack,
	IconButton,
	ListItem,
	Text,
	UnorderedList,
	useDisclosure,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

type GestionTypeCardType = {
	title?: string;
	description: React.ReactNode;
	btnCallback: () => void;
	btnText: string;
};

const GestionTypeCard = ({ title, description, btnCallback, btnText }: GestionTypeCardType): JSX.Element => {
	return (
		<Card w="100%" h="100%">
			<VStack w="100%" h="100%" spacing="8px" align="start" justify="space-between">
				{title && <Text variant="Title-M-SemiBold">{title}</Text>}
				{description}
				<Button variant="secondary" onClick={btnCallback} w="100%">
					{btnText}
				</Button>
			</VStack>
		</Card>
	);
};

const OptionContractDrawer = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }): JSX.Element => {
	const gestionTypes = useMemo(
		() => [
			{
				title: 'Gestion sous mandat',
				description: (
					<VStack w="100%" spacing="8px" align="start">
						<Text variant="Text-M-Regular" color="grey.900">
							La gestion sous mandat proposée dans le cadre du multi-poches permet de combiner au sein d’un même contrat
							un mode de gestion libre et/ou déléguée et une gestion sous mandat. La gestion sous mandat donne accès à
							une gamme innovante de cinq orientations, cumulables entre elles, qui se distinguent par leur profil de
							gestion et leur niveau de risque (SRI).
						</Text>
						<Text variant="Text-M-Medium" color="grey.900">
							Frais : 1,5 %
						</Text>
					</VStack>
				),
				btnCallback: () => {
					window.Intercom('showMessages');
				},
				btnText: 'Plus d’informations',
			},
			{
				title: 'Gestion pilotée',
				description: (
					<VStack w="100%" spacing="8px" align="start">
						<Text variant="Text-M-Regular" color="grey.900">
							Dans le cadre du mode de gestion “Gestion Pilotée”, vous demandez à l’assureur de gérer vos
							investissements selon une orientation de gestion que vous aurez préalablement choisie. Afin de la mettre
							en œuvre, l’assureur prendra conseil auprès d’une société de gestion qu’elle aura rigoureusement
							sélectionnée. L’assureur mettra en œuvre l’orientation choisie, conformément aux recommandations de la
							société de gestion.
						</Text>
						<Text variant="Text-M-Medium" color="grey.900">
							Frais : 1,5 %
						</Text>
					</VStack>
				),
				btnCallback: () => {
					window.Intercom('showMessages');
				},
				btnText: 'Plus d’informations',
			},
			{
				title: 'Gestion libre',
				description: (
					<VStack w="100%" spacing="8px" align="start">
						<Text variant="Text-M-Regular" color="grey.900">
							Dans le cadre du mode de gestion “Gestion Libre”, vous choisissez de répartir librement vos versements sur
							les différents supports accessibles à ce mode gestion, selon votre profil investisseur et votre projet
							d’investissement.
						</Text>
						<Text variant="Text-M-Medium" color="grey.900">
							Frais : 0,7 %
						</Text>
					</VStack>
				),
				btnCallback: () => {
					window.Intercom('showMessages');
				},
				btnText: 'Plus d’informations',
			},
		],
		[],
	);

	return (
		<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isOpen} onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<HStack w="100%" justify="space-between">
						<HStack>
							<IconButton
								onClick={onClose}
								variant="icon"
								aria-label="back"
								icon={
									<Box>
										<ArrowLeft size="32" />
									</Box>
								}
							/>
						</HStack>
						<Button
							variant="primary"
							onClick={() => {
								window.Intercom('showMessages');
							}}
						>
							Prendre rendez-vous
						</Button>
					</HStack>
				</DrawerHeader>
				<DrawerBody>
					<VStack w="100%" align="start" spacing="48px">
						<VStack w="100%" align="start" spacing="16px">
							<Text variant="Title-L-SemiBold">Modes de gestion</Text>
							<Wrap spacing="16px" flex="1">
								{gestionTypes.map((gestionType) => (
									<WrapItem key={gestionType.btnText} flex="1" minW="400px">
										<GestionTypeCard {...gestionType} />
									</WrapItem>
								))}
							</Wrap>
						</VStack>

						<VStack w="100%" align="start" spacing="24px">
							<Text variant="Title-L-SemiBold">Garantie plancher</Text>
							<GestionTypeCard
								description={
									<VStack w="100%" spacing="12px" align="start">
										<Text variant="Text-M-Regular" color="grey.900">
											En cas de décès de l’assuré pendant la durée de vie du contrat, les bénéficiaires auront droit au
											capital plancher, soit la somme des versements nets réalisés
										</Text>
										<Text variant="Text-M-Medium" color="grey.900">
											Frais : coût annuel en fonction de l’âge de l’assuré
										</Text>
									</VStack>
								}
								btnText="Plus d’informations"
								btnCallback={() => {
									window.Intercom('showMessages');
								}}
							/>
						</VStack>

						<VStack w="100%" align="start" spacing="24px">
							<Text variant="Title-L-SemiBold">Options programmées</Text>
							<GestionTypeCard
								description={
									<VStack w="100%" spacing="12px" align="start">
										<Text variant="Text-M-Regular" color="grey.900">
											Mettez en place des stratégies d’investissement personnalisées : arbitrages programmés, sécurisation
											des plus values, etc.
										</Text>
										<Text variant="Text-M-Medium" color="grey.900">
											Frais : 0,2 %
										</Text>
									</VStack>
								}
								btnText="Plus d’informations"
								btnCallback={() => {
									window.Intercom('showMessages');
								}}
							/>
						</VStack>
					</VStack>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

const OptionContracts = (): JSX.Element => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<VStack align="start" w="100%" spacing="16px">
			<OptionContractDrawer isOpen={isOpen} onClose={onClose} />
			<Text variant="Title-L-SemiBold">Autres options de contrat</Text>
			<Text variant="Text-M-Regular">
				Nos conseillers peuvent vous accompagner dans vos projets. Á partir de 100 000 €
			</Text>
			<Card w="100%">
				<UnorderedList>
					<ListItem>
						<Text variant="Text-M-Regular">Modes de gestion libre, déléguée, pilotée, multipoches</Text>
					</ListItem>
					<ListItem>
						<Text variant="Text-M-Regular">Garantie plancher</Text>
					</ListItem>
					<ListItem>
						<Text variant="Text-M-Regular">Options programmées</Text>
					</ListItem>
				</UnorderedList>
				<Button variant="secondary" alignSelf="end" onClick={onOpen}>
					Découvrir les options
				</Button>
			</Card>
		</VStack>
	);
};

export default OptionContracts;
