import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	Card,
	Heading,
	HStack,
	Image,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	VStack,
} from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import AnimatedBar from 'components/Graphs/AnimatedBar';
import { useAppResponsive } from 'hooks/useAppResponsive';
import useScpiFundRepartition from 'hooks/useScpiFundRepartition';
import eventTracker from 'services/events/eventTracker';
import colors from 'theme/foundations/colors';
import { displayMoneyNoDigits, displayPercentage } from 'utils/rendering';

type AllocationSimulationSCPIProps = {
	allocation: ReturnType<typeof useScpiFundRepartition>;
	onSubscribe: () => void;
};

const AllocationSimulationSCPI = ({ allocation, onSubscribe }: AllocationSimulationSCPIProps) => {
	const isDesktop = useAppResponsive({ base: false, md: true });
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;

	const navigate = useNavigate();

	const totalAmount = useMemo(() => allocation.reduce((acc, curr) => acc + curr.value, 0), [allocation]);

	return (
		<Card w="100%">
			<VStack w="100%" align="start" spacing="32px">
				<HStack justify="space-between" w="100%">
					<Heading variant="Title-M-SemiBold">Portefeuille recommandé</Heading>
					{!isMobile && (
						<Box w={{ base: '100%', md: 'auto' }}>
							<Button
								variant="primary"
								onClick={() => {
									eventTracker.mixpanel.souscriptionPotfolio('SCPI');
									onSubscribe();
								}}
							>
								Souscrire
							</Button>
						</Box>
					)}
				</HStack>

				<TableContainer w="100%">
					<Table w="100%" variant="unstyled" size={isDesktop ? 'md' : 'sm'}>
						<Thead>
							<Tr>
								<Th>
									<Text variant="Caption" color="grey.900">
										Fonds
									</Text>
								</Th>
								<Th>
									<Text variant="Caption" color="grey.900">
										Type
									</Text>
								</Th>
								<Th>
									<Text variant="Caption" color="grey.900">
										Parts
									</Text>
								</Th>
								<Th>
									<Text variant="Caption" color="grey.900">
										Montant
									</Text>
								</Th>
								<Th>
									<Text variant="Caption" color="grey.900">
										Répartition
									</Text>
								</Th>
							</Tr>
						</Thead>
						<Tbody style={{ fontVariantNumeric: 'normal' }}>
							{allocation.map((fund, idx) => (
								<Tr
									key={fund.id}
									border={idx + 1 === allocation.length ? 'none !important' : ''}
									_hover={{ bg: 'grey.100' }}
									cursor="pointer"
									onClick={() => {
										eventTracker.mixpanel.resultSimulationPageClickedOnFondTable('SCPI');
										navigate(fund.id, { preventScrollReset: true });
									}}
								>
									<Td>
										<HStack spacing="16px">
											{isDesktop && (
												<Image
													src={fund.fundCover?.[0]?.url}
													alt={fund.name}
													borderRadius="sm"
													boxSize="48px"
													fit="cover"
												/>
											)}
											<VStack align="left" spacing="0px">
												<Text variant="Text-M-Medium">{fund.name}</Text>
												<Text variant="Caption" color="grey.700">
													{fund.partner}
												</Text>
											</VStack>
										</HStack>
									</Td>

									<Td>
										<Text>{fund.type}</Text>
									</Td>

									<Td>
										<Text>{fund.nbParts}</Text>
									</Td>

									<Td>
										<Text>{displayMoneyNoDigits(fund.montant)} </Text>
									</Td>

									<Td>
										<VStack align="start">
											<Text>{displayPercentage(Math.round(fund.value) / 100)}</Text>
											<AnimatedBar color={colors.primary.yellow} width={`${(fund.value / totalAmount) * 100}%`} />
										</VStack>
									</Td>
									<Td>
										<ArrowButton right variant="tertiary">
											Détails
										</ArrowButton>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
				{isMobile && (
					<Button
						variant="primary"
						onClick={() => {
							eventTracker.mixpanel.souscriptionPotfolio('PE');
							onSubscribe();
						}}
						w="100%"
					>
						Souscrire
					</Button>
				)}
			</VStack>
		</Card>
	);
};

export default AllocationSimulationSCPI;
