export const tooltipObjective: Record<string, React.ReactNode> = {
	projectType: (
		<>
			Préparez votre retraite, anticipez l’achat d’un appartement, ou maximisez vos revenus en profitant de notre
			optimisation fiscale…{' '}
			<b>
				Pour chaque projet, nous effectuons les investissements adéquats en fonction de votre horizon de temps et de
				votre situation fiscale.
			</b>
			<br />
			<br /> Si vous n’avez pas d’objectif particulier, vous pouvez simplement faire le choix d'investir pour vous
			constituer un capital en cliquant sur <b>"autre"</b>. Dans ce cas nous ouvrirons une assurance vie et votre
			capital sera déblocable à tout moment, sous 72h.
		</>
	),
	initialAUM: (
		<>
			Il s’agit des <b>économies</b> que vous avez déjà réalisées, et qui sont destinées à être investies.
			<br />
			Le montant minimal à investir pour pouvoir bénéficier de l'optimisation Ramify est de 2000 euros.
			<br />
			L'optimisation Ramify est la proposition de répartition entre l'assurance vie et le PER.
		</>
	),
	type: (
		<>
			Si vous ne savez pas quel objectif choisir, nous vous conseillons de sélectionner <b>Constituer un capital</b>.
		</>
	),
	saving: (
		<>
			La somme d’argent que vous souhaitez investir tous les mois. <b>10%-15%</b> de votre revenu mensuel net est la
			somme que nous recommandons pour mener à bien ses projets de vie futurs.
			<br />
			<br />
			N'hésitez pas à modifier cette valeur. Le but est que votre niveau d’épargne vous permette de profiter du présent,
			tout en préparant <b>votre avenir</b>. Nous ne sommes pas là pour vous forcer à vous serrer la ceinture, mais à
			investir de manière plus intelligente.
		</>
	),
	timeHorizon: (
		<>
			Il s’agit de votre horizon d’investissement, donnée nécessaire à l’élaboration de votre stratégie d'épargne ainsi
			que votre portefeuille d’actifs sur-mesure.
		</>
	),

	house: (
		<>
			Ces informations nous permettent de collecter un paramètre essentiel à l’optimisation de votre épargne:
			<b> l’objectif d’investissement</b>.
		</>
	),
	retirement: (
		<>
			Si vous êtes né(e) après 1973, vous bénéficiez d'une retraite à taux plein dès lors que vous justifiez d'une durée
			d'assurance retraite d'au moins 43 ans. Sinon, vous bénéficiez automatiquement d'une retraite à taux plein à
			partir de 67 ans.
			<br />
			<br />
			Pour ceux né(e) avant 1973, les règles sont un peu différentes. Nous les avons aussi prises en compte.
		</>
	),
};
