import colors from './colors';

const borderRadius = {
	sm: '4px',
	base: '8px',
};

const borders = {
	base: `1px solid ${colors.grey[700]}`,
};

export { borderRadius, borders };
