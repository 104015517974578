import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import StepperLayout from 'app/Layout/Onboarding/StepperLayout';
import { useAppDispatch } from 'hooks/useStore';
import KYC from 'onboarding/KYC';
import { hasCompletedKyc } from 'onboarding/KYC/utils';
import EurazeoConfirmation from 'onboarding/PrivateEquity/Confirmation/eurazeo-confirmation';
import { setLastStepAvailable } from 'onboarding/Stepper/stepper.slice';
import { useGetKYCJustificatifQuery, useGetKYCQuery, useGetPESCPIKYCQuery } from 'services/requests/kyc';
import { useGetPEKYCQuery, useGetPEKYCTestQuery } from 'services/requests/privateEquity/kyc';
import { useGetUserPESubscriptionByIdQuery } from 'services/requests/privateEquity/subscriptions';
import { PrivateEquitySubscription } from 'store/types/airtable.type';
import { listPE } from 'utils/automatedFundTypes';

import DefaultConfirmation from './Confirmation/default';
import VatelDividendePlus10Confirmation from './Confirmation/vatel-dividende-plus-10';
import { isPeReglementaryStepCompleted } from './Reglementaire/utils';
import Projet from './Projet';
import Reglementaire from './Reglementaire';
import Souscription from './Souscription';
import steps, { PeOnboardingSteps } from './steps';

const ConfirmationChooser = ({ subscription }: { subscription?: PrivateEquitySubscription }): JSX.Element => {
	if (subscription?.fundName === listPE.VATEL_DIVIDENDE_PLUS_10) return <VatelDividendePlus10Confirmation />;
	if (
		subscription?.fundName &&
		[
			listPE.PRIVATE_VALUE_3,
			listPE.EUROPEAN_REAL_ESTATE_II,
			listPE.PRINCIPAL_INVESTMENTS,
			listPE.STRATEGIC_OPPORTUNITIES_3,
			listPE.ENTREPRENEURS_CLUB_II,
		].includes(subscription?.fundName)
	)
		return <EurazeoConfirmation />;
	return <DefaultConfirmation />;
};

const PrivateEquityOnboardingRoutes = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const [collapsed, setCollapsed] = useState(false);
	const { id } = useParams<{ id: string }>();

	const { data: kyc } = useGetKYCQuery();
	const { data: justificatif } = useGetKYCJustificatifQuery();
	const { data: subscription } = useGetUserPESubscriptionByIdQuery({ id: id! });

	// Informations
	const { data: peScpiKyc } = useGetPESCPIKYCQuery();

	// Informations produit
	const { data: peKyc } = useGetPEKYCQuery();

	// Test d'adéquation
	const { data: peKycTest } = useGetPEKYCTestQuery();

	// Set la dernière étape complétée
	// if (isAtStep(condition)) dispatch(setLastCompletedStep('correctStep'));

	// Redirect on lastStep if we go out of bounds
	// if (userData && userData.onboardingStatus && isAtleastAtStep(userData.onboardingStatus, step)) return <Outlet />;

	useEffect(() => {
		if (isPeReglementaryStepCompleted(kyc, peScpiKyc, peKyc, peKycTest)) {
			dispatch(setLastStepAvailable(PeOnboardingSteps.SUBSCRIPTION));
		} else if (hasCompletedKyc(kyc, justificatif)) {
			dispatch(setLastStepAvailable(PeOnboardingSteps.REGLEMENTARY));
		} else {
			dispatch(setLastStepAvailable(PeOnboardingSteps.KYC));
		}
	}, [kyc, justificatif, dispatch, peScpiKyc, peKyc, peKycTest]);

	if (!id) return <Navigate to="/private-equity" />;

	return (
		<Routes>
			<Route
				element={
					<StepperLayout
						headerTitle={subscription?.fundName}
						headerSubtitle="Finalisation"
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						steps={steps}
						basePath={`/private-equity/onboarding/${id}`}
					/>
				}
			>
				{/* Guard redirect last step */}
				<Route path="projet" element={<Projet />} />
				<Route
					path="connaissance-client/*"
					element={
						<KYC
							product="scpi-pe"
							steps={[
								'introduction',
								'etatCivil',
								'foyerFiscal',
								'professionelle',
								'coordonnees',
								'patrimoine',
								'justificatif',
								'recapitulatif',
							]}
							redirectOnEnd={`/private-equity/onboarding/${id}/reglementaire`}
						/>
					}
				/>

				<Route path="reglementaire/*" element={<Reglementaire />} />
				{/* The /* is important to allow opening drawer for bank account */}
				<Route path="souscription/*" element={<Souscription />} />
				<Route path="confirmation" element={<ConfirmationChooser subscription={subscription} />} />
			</Route>
			<Route path="*" element={<Navigate to="projet" replace />} />
		</Routes>
	);
};

export default PrivateEquityOnboardingRoutes;
