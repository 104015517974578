import { Idea } from '@carbon/icons-react';
import { As, Card, Center, HStack, Icon, Text, VStack } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';

type InformationBannerProps = {
	title?: string;
	description?: string;
	colorScheme?: 'white' | 'grey';
	icon?: As;
};

const InformationBanner = ({ title, description, icon = Idea }: InformationBannerProps) => (
	<Card size="sm" w="100%">
		<HStack spacing="16px">
			<Center borderRadius="full" bg="blue.100" p="8px">
				<Icon as={icon} boxSize="24px" color={colors.blue[900]} />
				{/* <Idea size="24" color={colors.blue[900]} /> */}
			</Center>
			<VStack align="start" w="100%">
				{title && <Text variant="Title-S-Bold">{title}</Text>}
				{description && <Text variant="Text-S-Regular">{description}</Text>}
			</VStack>
		</HStack>
	</Card>
);

export default InformationBanner;
