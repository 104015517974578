import { backendApi } from 'services/apiService';
import { CustomAsset } from 'store/types/investment.type';
import { PortfolioType } from 'store/types/profilage.type';

type ArbitrageRequest = {
	contractId: string;
	risk?: number;
	portfolioType?: PortfolioType;
	esg?: boolean;
	temperature?: number;
};

type ArbitrageSignRequest = ArbitrageRequest & {
	arbitrageId: number;
	otp: string;
};

export type ArbitrageEligible = {
	code: number;
	eligible: boolean;
	message: string;
	messageConnaissanceClient: string;
};

type ArbitrageResponse = {
	arbitrageId?: number | undefined;
	requiredSignature: boolean;
};

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		previewArbitrage: builder.query<CustomAsset[] | undefined, ArbitrageRequest>({
			query: (body) => ({
				url: '/arbitrage/preview',
				method: 'POST',
				body,
			}),
		}),
		triggerArbitrage: builder.mutation<ArbitrageResponse, ArbitrageRequest>({
			query: (body) => ({
				url: `/arbitrage/trigger`,
				method: 'POST',
				body,
			}),
			invalidatesTags: (response) =>
				response && !response.requiredSignature ? ['InvestContracts', 'Eligiblity/arbitrage'] : [],
		}),
		signArbitrage: builder.mutation<void, ArbitrageSignRequest>({
			query: (body) => ({
				url: `/arbitrage/sign`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['InvestContracts', 'Eligiblity/arbitrage'],
		}),
		getArbitrageEligible: builder.query<ArbitrageEligible, string>({
			query: (contractId) => `/arbitrage/arbitrage-eligible/${contractId}`,
			providesTags: ['Eligiblity/arbitrage'],
		}),
	}),
	overrideExisting: true,
});

export const {
	useLazyPreviewArbitrageQuery,
	useTriggerArbitrageMutation,
	useSignArbitrageMutation,
	useGetArbitrageEligibleQuery,
} = extendedApi;
