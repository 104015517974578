import { FetchBaseQueryErrorType } from 'services/apiService';

export type ErrorMetadata = {
	status: number;
	message: string;
};

export const getErrorMetadataFromRequest = (error: FetchBaseQueryErrorType): ErrorMetadata => {
	const errorCode = error.status || 500;
	const errorMessage = error?.data?.message || 'Une erreur inattendu est survenue';
	return {
		status: errorCode,
		message: errorMessage,
	};
};
