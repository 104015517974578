const colors = {
	primary: {
		white: '#FFFFFF',
		yellow: '#FFBB2C',
		black: '#1E1E1E',
	},

	//Secondary
	grey: {
		100: '#F5F7FA',
		300: '#E6ECF1',
		500: '#D2D9DB',
		700: '#A3A7AD',
		900: '#454B4F',
	},
	yellow: {
		50: '#FFF9E7',
		100: '#FFF3CE',
		300: '#F8C56D',
		500: '#DF9D12',
		700: '#AA781F',
		900: '#5F4311',
	},

	//Classes d’actifs
	blue: {
		50: '#F0F7FB',
		100: '#E0EFFA',
		500: '#96CBEE',
		900: '#0081CE',
	},
	turquoise: {
		50: '#F3FAFB',
		100: '#E7F5F7',
		500: '#2F99A7',
		900: '#065964',
	},
	purple: {
		100: '#ECE2F5',
		500: '#BE96EE',
		900: '#703CAC',
	},
	red: {
		50: '#FEF2F3',
		100: '#FEE6E9',
		500: '#F98190',
		900: '#DC3B4F',
	},
	green: {
		50: '#F2FBF3',
		100: '#E7F7E9',
		500: '#AEE4B4',
		900: '#3B5521',
	},
	orange: {
		50: '#FFF7F2',
		100: '#FFEADE',
		500: '#FFB992',
		900: '#C84D09',
	},
	brown: {
		100: '#F8F6EF',
		500: '#B5A15C',
		900: '#7A6826',
	},

	//Functional
	warning: {
		100: '#FFF3DE',
		900: '#9F4D00',
	},
	negative: {
		100: '#F9EAEA',
		500: '#E87979',
		900: '#C22929',
	},
	positive: {
		100: '#E1FAEC',
		500: '#5FC5A0',
		900: '#297358',
	},
	informative: {
		100: '#ECEEFD',
		900: '#3540A5',
	},

	//Brand
	brand: {
		RAMIFYGREEN: '#0A4035',
		BLACK: '#1E1E1E',
		ELITE: 'linear-gradient(to-br, #E6C790, #FEEAC5,#E4CA9D)',
		FLAGSHIP: 'linear-gradient(to-br, #E6E4E4, #F9F8FD)',
		ESSENTIAL: 'linear-gradient(315deg, #E6E4E4 0%, #F9F8FD 83%)',
		CUSTOM:
			'linear-gradient(143deg, rgba(251, 212, 98, 0.50) 0%, rgba(244.80, 233.28, 197.76, 0.80) 49%, rgba(224.40, 189.53, 87.61, 0.50) 100%)',
	},

	products: {
		invest: 'linear-gradient(135deg, #F0F7FB 0%, #FFF 100%)',
		scpi: 'linear-gradient(135deg, #F2FBF3 0%, #FFF 100%)',
		pe: 'linear-gradient(135deg, #FEF2F3 0%, #FFF 100%)',
		cash: 'linear-gradient(135deg, #FFF7F2 0%, #FFF 100%)',
		crypto: 'linear-gradient(135deg, #F3FAFB 0%, #FFF 100%)',
		black: '#1E1E1E',
	},

	//OLD
	primaryExpHeatmap: {
		0: 'rgba(255, 123, 121, 0.05)',
		1: '#303030',
		100: 'rgba(255, 123, 121, 0.08)',
		200: 'rgba(255, 123, 121, 0.16)',
		300: 'rgba(255, 123, 121, 0.28)',
		400: 'rgba(255, 123, 121, 0.44)',
		500: 'rgba(255, 123, 121, 0.56)',
		600: 'rgba(255, 123, 121, 0.7)',
		700: 'rgba(255, 123, 121, 0.8)',
		800: 'rgba(255, 123, 121, 0.9)',
		900: 'rgba(255, 123, 121, 1)',
	},
	primaryQuantHeatmap: {
		0: 'rgba(255, 123, 121, 0.1)',
		1: '#303030',
		100: 'rgba(255, 123, 121, 0.2)',
		200: 'rgba(255, 123, 121, 0.3)',
		300: 'rgba(255, 123, 121, 0.4)',
		400: 'rgba(255, 123, 121, 0.5)',
		500: 'rgba(255, 123, 121, 0.6)',
		600: 'rgba(255, 123, 121, 0.7)',
		700: 'rgba(255, 123, 121, 0.8)',
		800: 'rgba(255, 123, 121, 0.9)',
		900: 'rgba(255, 123, 121, 1)',
	},
};

export default colors;
