import { Card, chakra, Text } from '@chakra-ui/react';

const CardInfo = () => (
	<Card variant="blue" borderColor="blue.500" p="16px">
		<Text variant="detail">
			Les clients Ramify épargnent en moyenne <chakra.span fontWeight="600">10 % de leur revenus.</chakra.span>
		</Text>
	</Card>
);

export default CardInfo;
