import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, chakra, Heading, Spinner, Text, useBoolean, VStack } from '@chakra-ui/react';
import MoralPersonBlockerCard from 'features/MoralPersonBlockerCard';
import RealEstateIntentionForm from 'formContexts/RealEstateIntention';

import { SponsorActivation } from 'components/contracts/SponsorActivation';
import CustomModal from 'components/Modal';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useGetSCPIFundByIdQuery, useGetSCPIFundDemembrementQuery } from 'services/requests/realEstate/funds';
import {
	useGetUserSCPISubscriptionByIdQuery,
	useUpdateSCPIIntentionMutation,
} from 'services/requests/realEstate/subscriptions';
import { ScpiIntention } from 'store/types/scpiKyc.type';
import { isNotNone } from 'utils/functions';
import { displayMoney, displayPercentage } from 'utils/rendering';

const ScpiProject = (): JSX.Element => {
	const navigate = useNavigate();
	const toast = useThemedToast();
	const { id } = useParams<{ id: string }>();
	const { data: subscription, isError } = useGetUserSCPISubscriptionByIdQuery({ id: id! });
	const [update, { isLoading: isUpdating }] = useUpdateSCPIIntentionMutation();
	const [isModifying, modify] = useBoolean();
	const { data: fundDetails } = useGetSCPIFundByIdQuery(subscription?.fund as string, { skip: !subscription });
	const [isMoralPersonOpen, setMoralPersonOpen] = useBoolean();
	const { data: fundDemembrement } = useGetSCPIFundDemembrementQuery(subscription?.fund as string, {
		skip: !subscription,
	});

	const methods = useForm<ScpiIntention>();
	const { handleSubmit, setValue } = methods;

	useEffect(() => {
		if (subscription) {
			if (isNotNone(subscription.amountVersement)) setValue('amountVersement', subscription.amountVersement);
			if (isNotNone(subscription.legalEntity)) setValue('legalEntity', subscription.legalEntity);
			if (isNotNone(subscription.projectType)) setValue('projectType', subscription.projectType);
			if (isNotNone(subscription.demembrementDuration))
				setValue('demembrementDuration', +subscription.demembrementDuration);
			if (isNotNone(subscription.nbShare)) setValue('nbShare', subscription.nbShare);
		}
	}, [subscription, setValue]);

	useEffect(() => {
		if (isError) {
			toast({
				title: 'Une erreur est survenue',
				status: 'error',
				duration: 4500,
				isClosable: true,
			});
		}
	}, [isError, toast]);

	useEffect(() => {
		eventTracker.mixpanel.track('SCPI Onboarding - Projet');
	}, []);

	if (!subscription || !fundDetails) return <Spinner />;

	const onSubmit = handleSubmit((data) => {
		const navNext = () => {
			if (data.legalEntity === 'MORALE') {
				modify.off();
				setMoralPersonOpen.on();
			} else {
				navigate(`/immobilier/onboarding/${id}/connaissance-client`);
			}
		};

		if (isModifying) {
			update({
				...data,
				id: id!,
				demembrementDuration:
					data.projectType === 'NUE_PROPRIETE' && data.demembrementDuration ? +data.demembrementDuration : undefined,
				amountVersement: +data.amountVersement,
			})
				.unwrap()
				.then(navNext)
				.catch((error) => {
					toast({
						title: 'Une erreur est survenue',
						description: error.data.message,
						status: 'error',
						duration: 4500,
						isClosable: true,
					});
				});
		} else {
			navNext();
		}
	});

	return (
		<VStack w="100%" align="start" spacing="40px">
			<VStack w="100%" align="start" spacing="24px">
				<Heading variant="Title-L-Bold">Mon investissement - {subscription.fundName}</Heading>
				<Text variant="Text-M-Regular" color="grey.900">
					Afin de finaliser votre souscription à {subscription.fundName}, nous avons besoin de collecter certaines
					informations.
				</Text>
			</VStack>

			<Card w="100%">
				<VStack spacing="24px" align="start" w="100%">
					<VStack align="start" w="100%" spacing="12px">
						<Heading variant="Title-S-SemiBold">Récapitulatif de mon projet</Heading>
						<Text variant="Text-M-Regular" color="grey.900">
							Vous allez acheter{' '}
							<b>
								{subscription.nbShare} part{subscription.nbShare > 1 && 's'}
							</b>{' '}
							de la SCPI <b>{subscription.fundName}</b> pour un montant total de{' '}
							<b>{displayMoney(subscription.amountVersement)}</b>. Cet investissement sera fait en{' '}
							<b>{subscription.projectType === 'NUE_PROPRIETE' ? 'nue-propriété' : 'pleine propriété'}</b>
							{subscription.projectType === 'NUE_PROPRIETE' && subscription.demembrementDuration && (
								<>
									, avec un <b>démembrement sur {subscription.demembrementDuration} ans</b>
									{fundDemembrement && (
										<>
											{' '}
											et une{' '}
											<b>
												décôte de{' '}
												{displayPercentage(1 - fundDemembrement[subscription.demembrementDuration.toString()])}
											</b>
										</>
									)}
								</>
							)}
							.
						</Text>
					</VStack>

					<FormProvider {...methods}>
						<chakra.form w="100%" onSubmit={onSubmit}>
							{isModifying ? (
								<RealEstateIntentionForm fundDemembrement={fundDemembrement} fundDetails={fundDetails} />
							) : (
								<Button size="lg" variant="secondary" onClick={modify.on}>
									Modifier mon projet
								</Button>
							)}
							<BottomStepBar showPrev={false} textNext="Commencer" nextSubmit isNextLoading={isUpdating} />
						</chakra.form>
					</FormProvider>
				</VStack>
			</Card>

			<CustomModal
				headersProps={{
					pt: '24px',
					children: `Investir dans ${fundDetails.name}`,
				}}
				isOpen={isMoralPersonOpen}
				onClose={setMoralPersonOpen.off}
			>
				<MoralPersonBlockerCard />
			</CustomModal>

			<Card>
				<SponsorActivation />
			</Card>
		</VStack>
	);
};

export default ScpiProject;
