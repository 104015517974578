import { useForm } from 'react-hook-form';
import { Link as ReachLink, useNavigate, useParams } from 'react-router-dom';
import { Button, chakra, FormControl, FormErrorMessage, FormLabel, HStack, Link, Text, VStack } from '@chakra-ui/react';

import { ShowHidePasswordInput } from 'components/inputs/ShowHidePasswordInput';
import useThemedToast from 'hooks/useThemedToast';
import { useApplyResetPasswordMutation } from 'services/requests/auth';
import { useLazyGetUserQuery } from 'services/requests/user';
import api from 'utils/api';

import { ButtonAuthStyle, ContainerAuthStyle } from './style';

type ApplyResetFormType = {
	password: string;
	confirmPassword: string;
};

const ApplyReset = (): JSX.Element => {
	const { token } = useParams<{ token: string }>();
	const toast = useThemedToast();
	const navigate = useNavigate();
	const [retriggerUser] = useLazyGetUserQuery();
	const [resetPassword, { isLoading }] = useApplyResetPasswordMutation();

	const {
		formState: { errors },
		register,
		handleSubmit,
	} = useForm<ApplyResetFormType>({
		reValidateMode: 'onSubmit',
	});

	const onSubmit = handleSubmit(({ password }: ApplyResetFormType) => {
		resetPassword({ password, token: token || '' })
			.unwrap()
			.then((res) => {
				toast({
					title: 'Mot de passe modifié',
					duration: 4000,
					isClosable: true,
				});
				const jwtToken = res.access_token;

				api.setHeaderToken(jwtToken);
				localStorage.setItem('token', jwtToken);
				retriggerUser();
				navigate('/');
			})
			.catch((error) => {
				if ('status' in error) {
					toast({
						title: "Une erreur s'est produite.",
						description:
							(error.status < 500 && (error.data as { message: string })?.message) ||
							"Nous n'avons pas pu réinitialiser votre mot de passe.",
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				}
			});
	});

	return (
		<VStack {...ContainerAuthStyle}>
			<VStack align="center" spacing="12px" w="100%">
				<Text color="grey.700" fontSize="20px" fontWeight="bold">
					Récupération de votre mot de passe
				</Text>
				<Text color="grey.700" fontSize="14px">
					Renseignez le nouveau mot de passe que vous souhaitez utiliser
				</Text>
			</VStack>

			<chakra.form w="100%" onSubmit={onSubmit}>
				<VStack align="start" spacing="16px" w="100%">
					<VStack align="left" spacing="8px" w="100%">
						<FormControl isInvalid={!!errors.password}>
							<FormLabel fontSize="14px">Mot de passe</FormLabel>
							<ShowHidePasswordInput
								{...register('password', { required: true, minLength: 8, maxLength: 100 })}
								placeholder="Minimum 8 caractères"
								autoComplete="new-password"
								type="password"
							/>
							{errors.password?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
							{errors.password?.type === 'minLength' && (
								<FormErrorMessage>Le mot de passe doit contenir au moins 8 caractères</FormErrorMessage>
							)}
							{errors.password?.type === 'maxLength' && (
								<FormErrorMessage>Le mot de passe ne peut pas faire plus de 100 caractères</FormErrorMessage>
							)}
						</FormControl>
					</VStack>

					<VStack align="left" spacing="8px" w="100%">
						<FormControl isInvalid={!!errors.confirmPassword}>
							<FormLabel fontSize="14px">Confirmation du mot de passe</FormLabel>
							<ShowHidePasswordInput
								{...register('confirmPassword', { required: true, minLength: 8, maxLength: 100 })}
								placeholder="Minimum 8 caractères"
								type="password"
								autoComplete="new-password"
							/>
							{errors.confirmPassword?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
							{errors.confirmPassword?.type === 'minLength' && (
								<FormErrorMessage>Le mot de passe doit contenir au moins 8 caractères</FormErrorMessage>
							)}
							{errors.confirmPassword?.type === 'maxLength' && (
								<FormErrorMessage>Le mot de passe ne peut pas faire plus de 100 caractères</FormErrorMessage>
							)}
						</FormControl>
					</VStack>

					<Button {...ButtonAuthStyle} isLoading={isLoading} mt="32px !important" type="submit">
						Réinitialiser mon mot de passe
					</Button>

					<HStack mt="24px !important" wrap="wrap" align="start">
						<Text fontSize={14}>Vous connaissez votre mot de passe ?</Text>
						<Link fontWeight="500" fontSize={14} color="yellow.500" as={ReachLink} to="/connexion">
							<Text variant="Text-M-Regular">Connectez-vous !</Text>
						</Link>
					</HStack>
				</VStack>
			</chakra.form>
		</VStack>
	);
};

export default ApplyReset;
