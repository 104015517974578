import { Badge } from '@carbon/icons-react';
import { Button, HStack, Stack, Text, useDisclosure, VStack } from '@chakra-ui/react';

import { CalendlyStep } from 'pages/OffreBlack/DiscoverBlack/Modal/Calendly';

const HeaderOffreBlack = (): JSX.Element => {
	const {
		isOpen: isOpenAppointementModal,
		onOpen: onOpenAppointementModal,
		onClose: isCloseAppointementModal,
	} = useDisclosure();
	return (
		<Stack w="100%" justify="space-between" direction={{ base: 'column', xl: 'row' }}>
			{isOpenAppointementModal && (
				<CalendlyStep
					url="https://calendly.com/d/y4s-6xr-tf4/echange-avec-un-conseiller-black-ramify"
					onClose={isCloseAppointementModal}
				/>
			)}
			<VStack w="100%" align="start" spacing="24px">
				<Text variant="Title-XL-SemiBold" color="white">
					Ramify Black
				</Text>
				<HStack w="100%">
					<Badge color="#FFBB2C" size="32" />
					<Text variant="Caption" color="primary.yellow">
						Membre vérifié
					</Text>
				</HStack>
			</VStack>
			<Button
				variant="super"
				size="xl"
				onClick={() => {
					onOpenAppointementModal();
				}}
			>
				Prendre un rendez-vous
			</Button>
		</Stack>
	);
};
export default HeaderOffreBlack;
