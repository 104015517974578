import { useNavigate } from 'react-router-dom';
import { Skeleton } from '@chakra-ui/react';
import fileDownload from 'js-file-download';

import SouscriptionCard from 'components/savings/EnvelopeStates/SubscriptionCard';
import ContractCard from 'pages/Invest/Contrats/ContractsSections/Sections/ContractCard';
import EnvelopeCard from 'pages/Invest/Contrats/ContractsSections/Sections/EnvelopeCard';
import { useGetContractsQuery } from 'services/requests/invest/contracts';
import { useGetAllSubscriptionsQuery } from 'services/requests/invest/subscription';
import { useGetKYCQuery } from 'services/requests/kyc';
import { useGetEnvelopesQuery } from 'services/requests/product';
import { ProfessionalSituation } from 'store/referentiels/professionalSituation';
import { PopulatedInvestSubscription, SubscriptionStatus } from 'store/types/subscription.type';
import api from 'utils/api';

const SkeletonCardList = (): JSX.Element[] =>
	[1, 2].map((i) => <Skeleton height="300px" width="100%" borderRadius="base" key={i} />);

const GetEnvelopeStateList = (): JSX.Element[] => {
	const navigate = useNavigate();
	const { data: contracts } = useGetContractsQuery();
	const { data: subscriptions } = useGetAllSubscriptionsQuery();
	const { data: envelopes } = useGetEnvelopesQuery();

	const { data: kyc } = useGetKYCQuery();

	const isRetired = kyc?.professionalSituation === ProfessionalSituation.RETIRED;

	if (!contracts || !subscriptions || !envelopes) return SkeletonCardList();

	const envelopeList: JSX.Element[] = [];
	// ----------------
	// Contracted envelopes
	// ----------------
	const contractCards = contracts.map((contract) => <ContractCard key={contract.id} contract={contract} />);

	// ----------------
	// Subscription awaiting envelopes
	// ----------------
	const isCompleted = (s: PopulatedInvestSubscription): boolean => s.status === SubscriptionStatus.COMPLETED;
	const isAwaiting = (s: PopulatedInvestSubscription): boolean =>
		s.status === SubscriptionStatus.SIGNED || s.status === SubscriptionStatus.SENT_TO_PARTNER;

	const subscriptionCards = subscriptions
		.filter((s) => isAwaiting(s))
		.filter((s) => !isCompleted(s))
		.map((s) => (
			// TODO: improve
			<SouscriptionCard
				type={envelopes.find((e) => e.id === s.envelopeId)?.type || 'AV'}
				key={s.id}
				onClickBulletin={() =>
					api.downloadBulletin(s.id).then((res) => fileDownload(res.data, 'bulletin.pdf', 'application/pdf'))
				}
			/>
		));

	// ----------------
	// Inactive envelopes
	// ----------------
	const hasSignedOrOpened = (s: PopulatedInvestSubscription): boolean =>
		s.status === SubscriptionStatus.SIGNED ||
		s.status === SubscriptionStatus.SENT_TO_PARTNER ||
		s.status === SubscriptionStatus.COMPLETED;
	envelopeList.push(
		<EnvelopeCard
			key="envelope-av"
			objectif="Pour construire un patrimoine"
			type="AV"
			onClickCTA={() => navigate(`/invest/ouverture-assurance-vie`)}
		/>,
	);
	// Aucun PER n'a été ouvert et aucune souscription n'est en attente
	if (
		!contracts.find((c) => c.envelope.type === 'PER') &&
		!subscriptions.filter((s) => hasSignedOrOpened(s)).find((s) => s.envelope.type === 'PER')
	) {
		const envelopePER = envelopes.find((e) => e.type === 'PER');
		if (envelopePER)
			envelopeList.push(
				<EnvelopeCard
					key="envelope-per"
					objectif="Pour défiscaliser et préparer la rétraite"
					isRetired={isRetired}
					type="PER"
					onClickCTA={() => navigate(`/invest/ouverture-per-individuel`)}
				/>,
			);
	}

	// Show contracts first / then subscriptions / then available envelopes
	return contractCards.concat(subscriptionCards).concat(envelopeList);
};

export default GetEnvelopeStateList;
