import { backendApi } from 'services/apiService';

export const RoundArray = ['preSeed', 'seed', 'seriesA', 'seriesB', 'seriesC', 'seriesD', 'seriesE+', 'FMV'] as const;
export type Round = (typeof RoundArray)[number];
export type Periodicity = 'month' | 'trimester' | 'quarter' | 'semester' | 'year';

type CorporateCompany = {
	id: string;
	name: string;
	domain: string;
	round: Round;
	lastShareValuation: number;
	lastShareValuationDate: string;
	logo: string;
};

export type CorporateAttribution = {
	id: string;
	emailPro: string;
	emailPerso: string;
	companyId: string;
	companyName: string;
	nbShares: number;
	exerciceCost: number;
	totalExerciceCost: number;
	round: Round;
	attributionDate: string;
	vestingStartDate: string;
	vestingDuration: number;
	vestingPeriodicity: Periodicity;
	initialVestingLock: number;
	company: CorporateCompany;
};

///////////////////////////////////////////////////////////////////////////////////////////

export type BSPCEAttributionSchema = {
	id: string;
	attribution_date: string;
	strike_price: number;
	parts_number: number;
	fund_raising_stage: Round;
};

export type BSPCESimulationBody = {
	list_attributions: BSPCEAttributionSchema[];
	next_fund_raising_multiple: number;
	actual_part_value: number;
};

export type BSPCESimulationResponse = {
	total_parts_number: number;
	theoretical_gain: number;
	valorisation: number;
	graph_repartition: { date: string; vested_parts: number; non_vested_parts: number }[];
	vested_parts_repartition: {
		vested_parts_percentage: number;
		non_vested_parts_percentage: number;
		vested_parts_number: number;
		non_vested_parts_number: number;
		vested_parts_amount: number;
		non_vested_parts_amount: number;
		vested_parts_exercise_price: number;
		vested_parts_theoretical_gain: number;
	};
	vested_parts_repartition_by_attribution: Record<string, { vested_parts: number; non_vested_parts: number }>;
	next_fund_raising_valorisation: number;
	next_fund_raising_theoretical_gain: number;
};

export type DashboardResponse = {
	email: string;
	isEmailValidated: boolean;
	data: null | {
		attributions: CorporateAttribution[];
		simulationData: BSPCESimulationResponse;
	};
}[];

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		// no auth route, sends restricted amount of data
		getCorporateCompanyById: builder.query<{ logo: string; name: string; domain: string }, string>({
			query: (id) => `/corporates/companies/${id}`,
		}),
		getCorporateAttributions: builder.query<Record<string, CorporateAttribution[] | null>, void>({
			query: () => '/corporates/self',
		}),
		getCorporateDashboard: builder.query<DashboardResponse, void>({
			query: () => '/corporates/dashboard',
		}),
		getCorporateAttributionsByCompanyId: builder.query<CorporateAttribution[], string>({
			query: (companyId) => `/corporates/self/companies/${companyId}`,
		}),
		revalorisationSimulation: builder.query<BSPCESimulationResponse, BSPCESimulationBody>({
			query: (body) => ({
				method: 'POST',
				url: '/corporates/simulation',
				body,
			}),
		}),
	}),
});

export const {
	useGetCorporateCompanyByIdQuery,
	useGetCorporateAttributionsQuery,
	useGetCorporateDashboardQuery,
	useGetCorporateAttributionsByCompanyIdQuery,
	useRevalorisationSimulationQuery,
	useLazyRevalorisationSimulationQuery,
} = extendedApi;
