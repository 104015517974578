import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	Box,
	Center,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	SimpleGrid,
	Text,
	useToast,
	VStack,
} from '@chakra-ui/react';
import { DotWave } from '@uiball/loaders';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/mobile';

// Components
import PhoneInput from 'components/inputs/PhoneInput';
import ReferentielInput from 'components/inputs/ReferentielInput';
import useOnClickOutside from 'hooks/useClickOutside';
import { useGetKYCQuery, useUpdateKYCCoordonneesMutation } from 'services/requests/kyc';
// Referentiels
import Indicatifs from 'store/referentiels/indicatif.json';
import ResidenceFicaleReferentiel from 'store/referentiels/pays/residence';
import { CoordonneesDTO } from 'store/types/KYC.type';
import colors from 'theme/foundations/colors';
// Async options loaders
import { Address, getAddresses } from 'utils/functions';

import { KYCFormProps } from './type';

const ContactInformationForm = ({ onSuccess, formConfirmation }: KYCFormProps): JSX.Element => {
	const toast = useToast();
	const [triggerUpdateCoordonnees] = useUpdateKYCCoordonneesMutation();
	const { data: kycData, isSuccess } = useGetKYCQuery();
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		setValue,
		watch,
	} = useForm<CoordonneesDTO>({
		defaultValues: {
			adresse: { pays: 'FR', adressePrincipale: '' },
			telephoneMobile: { indicatif: '0033', numeroTelephone: '' },
		},
	});
	const [adressePrincipale, telephoneMobile] = watch(['adresse.adressePrincipale', 'telephoneMobile']);
	const [recommendedAddresses, setRecommendedAddress] = useState<Address[]>();
	const [isComponentVisible, setIsComponentVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const getCountryCode = (indicatifValue: string): string => {
		const indicatif = Indicatifs.find((x) => x.code.toString() === indicatifValue);
		if (indicatif) return indicatif.codePays;
		return '';
	};

	const [phoneErrorMsg, setPhoneErrorMsg] = useState('');
	const validatePhone = (value: { indicatif: string; numeroTelephone: string }) => {
		if (value.numeroTelephone.length === 0) {
			setPhoneErrorMsg('Ce champ est incomplet.');
			return false;
		}
		if (
			!isValidPhoneNumber(
				value.indicatif.replace('00', '+') + value.numeroTelephone,
				getCountryCode(value.indicatif.toString()) as CountryCode,
			)
		) {
			setPhoneErrorMsg("Votre numéro de téléphone n'est pas reconnu.");
			return false;
		}
		return true;
	};

	const ref = useRef(null);
	useOnClickOutside(ref, () => setIsComponentVisible(false));

	const addAddressManually = () => {
		setIsComponentVisible(false);
	};

	const suggestionSelected = (address: Address) => {
		setIsComponentVisible(false);
		setValue('adresse.adressePrincipale', address.value);
		setValue('adresse.codePostal', address.annexValue.postcode);
		setValue('adresse.ville', address.annexValue.city);
		setRecommendedAddress([]);
	};

	useEffect(() => {
		if (adressePrincipale) {
			setRecommendedAddress([]);
			setIsLoading(true);
			const timer = setTimeout(async () => {
				const addresses = await getAddresses(adressePrincipale);
				setRecommendedAddress(addresses);
				setIsLoading(false);
			}, 600);
			return () => clearTimeout(timer);
		}
	}, [adressePrincipale]);

	const onSubmit = handleSubmit((data: CoordonneesDTO) => {
		triggerUpdateCoordonnees(data)
			.unwrap()
			.then(() => onSuccess())
			.catch(() =>
				toast({
					title: 'Une erreur est survenue',
					status: 'error',
					duration: 9000,
					isClosable: true,
				}),
			);
	});

	useEffect(() => {
		if (isSuccess && kycData) {
			if (kycData.address) setValue('adresse.adressePrincipale', kycData.address);
			if (kycData.zipCode) setValue('adresse.codePostal', kycData.zipCode);
			if (kycData.country) setValue('adresse.pays', kycData.country);
			if (kycData.city) setValue('adresse.ville', kycData.city);
			if (kycData.phoneNumber) setValue('telephoneMobile.numeroTelephone', kycData.phoneNumber);
			if (kycData.phoneNumberCode) setValue('telephoneMobile.indicatif', kycData.phoneNumberCode);
		}
	}, [isSuccess, kycData, setValue]);

	return (
		<form onSubmit={onSubmit}>
			<VStack spacing="20px" align="start" w="100%">
				<VStack align="start" w="100%" position="relative">
					<FormControl isInvalid={!!errors.adresse?.adressePrincipale}>
						<FormLabel>Adresse principale</FormLabel>
						<Input
							placeholder="..."
							{...register('adresse.adressePrincipale', { required: true })}
							onFocus={() => setIsComponentVisible(true)}
							autoComplete="off"
						/>
						{errors.adresse?.adressePrincipale?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
						{recommendedAddresses !== undefined && adressePrincipale.length > 0 && isComponentVisible && (
							<VStack
								position="absolute"
								top="85px"
								w="100%"
								overflow="hidden"
								bg="white"
								zIndex="2"
								ref={ref}
								data-cy="address-results"
								border="base"
								borderRadius="base"
								minH="40px"
								spacing="0px"
							>
								{isLoading && (
									<Center w="100%" h="40px">
										<DotWave size={28} color={colors.grey[700]} />
									</Center>
								)}
								{recommendedAddresses.map((item: Address) => (
									<Box
										key={item.value}
										onClick={() => suggestionSelected(item)}
										padding="0 24px"
										w="100%"
										as="button"
										box-sizing="border-box"
										_hover={{ bg: 'blue.100' }}
										_focus={{ boxShadow: 'outline' }}
										lineHeight="40px"
									>
										<Text variant="label" textAlign="left">
											{item.label}
										</Text>
									</Box>
								))}
								{!isLoading && (
									<Box
										key="AddNewAddress"
										onClick={addAddressManually}
										padding="0 24px"
										w="100%"
										as="button"
										box-sizing="border-box"
										_hover={{ bg: 'blue.100' }}
										_focus={{ boxShadow: 'outline' }}
										lineHeight="40px"
									>
										<Text textAlign="left" as="u">
											Ajouter mon adresse
										</Text>
									</Box>
								)}
							</VStack>
						)}
					</FormControl>
				</VStack>

				<SimpleGrid columns={{ base: 1, md: 2 }} spacingX="64px" spacingY="20px" mt="20px !important" w="100%">
					<VStack align="start">
						<FormControl isInvalid={!!errors.adresse?.pays}>
							<FormLabel>Pays de résidence</FormLabel>
							<ReferentielInput
								name="adresse.pays"
								referentiel={ResidenceFicaleReferentiel}
								register={register}
								data-cy="kyc-pays"
							/>
							{errors.adresse?.pays && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
						</FormControl>
					</VStack>

					<VStack align="start">
						<FormControl isInvalid={!!errors.adresse?.codePostal}>
							<FormLabel>Code postal</FormLabel>
							<Input
								placeholder="..."
								{...register('adresse.codePostal', { required: true, pattern: /^(?:[0-8]\d|9[0-8])\d{3}$/ })}
							/>
							{errors.adresse?.codePostal?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
							{errors.adresse?.codePostal?.type === 'pattern' && (
								<FormErrorMessage>Votre code postal n'est pas reconnu.</FormErrorMessage>
							)}
						</FormControl>
					</VStack>

					<VStack align="start">
						<FormControl>
							<FormLabel>Ville</FormLabel>
							<Input placeholder="..." {...register('adresse.ville', { required: true })} />
							{errors.adresse?.ville?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
					</VStack>

					<VStack align="start">
						<FormControl isInvalid={!!errors.telephoneMobile}>
							<FormLabel>Numéro de téléphone</FormLabel>
							<PhoneInput
								name="telephoneMobile"
								placeholder="-"
								control={control}
								rules={{
									validate: validatePhone,
								}}
								cyInd="kyc-indicatif"
								cyTel="kyc-numeroTelephone"
								defaultTelephoneMobile={telephoneMobile}
							/>
							{/* {errors.telephoneMobile && <ErrorFieldMessage children={phoneErrorMsg} />} */}
							{errors.telephoneMobile && <FormErrorMessage>{phoneErrorMsg}</FormErrorMessage>}
						</FormControl>
					</VStack>
				</SimpleGrid>
				{formConfirmation}
			</VStack>
		</form>
	);
};

export default ContactInformationForm;
