import { FC } from 'react';
import { Link as ReachLink } from 'react-router-dom';
import { ArrowRight, Finance } from '@carbon/icons-react';
import { Button, Card, Heading, HStack, Link, ListItem, Stack, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { friendlyFormatIBAN } from 'ibantools';

import { AccountUsage } from 'pages/Account/BankInformations';
import { BankInformation } from 'services/requests/bank';

interface BankAccountDetailsCardProps {
	accountUsage: AccountUsage[] | null;
	accountDetails: BankInformation;
	onDelete?: (accountDetails: AccountUsage[] | null) => void;
}

export const BankAccountDetailsCard: FC<BankAccountDetailsCardProps> = ({ accountUsage, accountDetails, onDelete }) => {
	const operations = accountUsage?.filter((usage) => !!usage.programmedVersement);
	const contracts = accountUsage?.filter((usage) => !usage.programmedVersement);

	return (
		<Card w="100%">
			<VStack spacing="24px" w="100%" align="start">
				<HStack align="start" spacing="12px" alignItems="center">
					<Finance size="32" />
					<Heading variant="Title-S-SemiBold">{accountDetails.holder}</Heading>
				</HStack>
				<Stack direction={{ base: 'column', md: 'row' }} align="start" spacing={{ base: 'sm', md: 'xl' }}>
					<VStack align="start">
						<Text variant="Text-S-Medium" color="grey.900">
							IBAN
						</Text>
						<Text variant="Text-S-Regular" color="grey.900">
							{friendlyFormatIBAN(accountDetails.IBAN)}
						</Text>
					</VStack>
					<VStack align="start">
						<Text variant="Text-S-Medium" color="grey.900">
							BIC
						</Text>
						<Text variant="Text-S-Regular" color="grey.900">
							{accountDetails.BIC}
						</Text>
					</VStack>
				</Stack>

				{accountUsage && operations && contracts && (
					<VStack align="start">
						{operations.length > 0 && (
							<>
								<Text variant="Text-M-Regular">
									Utilisé par {operations.length} opération{operations.length > 1 && 's'} :
								</Text>
								<UnorderedList>
									{operations.map((operation) => (
										<ListItem ml="30px" key={`${operation.type}-${operation.usage}`}>
											<Link as={ReachLink} to="/invest/versements">
												<HStack>
													<Text variant="Text-M-Medium">
														Versements automatique - {operation.type === 'PER' ? 'PER individuel' : 'Assurance Vie'}
													</Text>
													<ArrowRight size="16" />
												</HStack>
											</Link>
										</ListItem>
									))}
								</UnorderedList>
							</>
						)}

						{contracts.length > 0 && (
							<>
								<Text variant="Text-M-Regular">
									Associé à {contracts.length} contrat{contracts.length > 1 && 's'} :
								</Text>
								<UnorderedList>
									{contracts.map((contract) => (
										<ListItem ml="30px" key={`${contract.type}-${contract.usage}`}>
											<Text variant="Text-M-Medium">
												{contract.type === 'PER' ? 'PER individuel' : 'Assurance Vie'}
											</Text>
										</ListItem>
									))}
								</UnorderedList>
							</>
						)}
					</VStack>
				)}

				{onDelete && (
					<Button variant="destroy" onClick={() => onDelete(accountUsage)}>
						Supprimer
					</Button>
				)}
			</VStack>
		</Card>
	);
};
