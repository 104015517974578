import { useCallback, useEffect, useState } from 'react';
import { CheckmarkOutline } from '@carbon/icons-react';
import {
	Button,
	Heading,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import Amelie from 'assets/team/smallAmelie.png';
import SamyAdvisor from 'components/cards/SamyAdvisor';
import CustomModal from 'components/Modal';
import { Stepper } from 'components/navigation/Stepper';
import { useAppResponsive } from 'hooks/useAppResponsive';
import Informations, { CreateVCData } from 'pages/Invest/Versements/CreateVersement/Steps/Informations';
import Signature from 'pages/Invest/Versements/CreateVersement/Steps/Signature';
import { PopulatedInvestContract } from 'store/types/contract.type';
import colors from 'theme/foundations/colors';

export interface PrefillDataInterface {
	amount?: number;
	contract?: PopulatedInvestContract;
}

interface ModalProps {
	onClose: () => void;
	onSuccess: () => void;
	prefilledData?: PrefillDataInterface;
	vpSelectedContract?: PopulatedInvestContract; // only present if programmed versement
}

const CreateVersement = ({ onClose, onSuccess, vpSelectedContract, prefilledData }: ModalProps): JSX.Element => {
	const [step, setStep] = useState(0);
	const [formData, setFormData] = useState<CreateVCData>();
	const [hasDataPrefilled, setHasDataPrefilled] = useState(false);
	const { isOpen: isVersementError, onOpen: onVersementError } = useDisclosure();
	const modalSize = useAppResponsive({ base: 'sm', md: '4xl' });

	useEffect(() => {
		if (!hasDataPrefilled) setFormData(prefilledData as CreateVCData);
		setHasDataPrefilled(true);
	}, [prefilledData, hasDataPrefilled]);

	const onSubmit = useCallback((data: CreateVCData) => {
		setFormData(data);
		setStep(1);
	}, []);

	const lastStep = useCallback(() => {
		onSuccess();
		setStep(2);
	}, [onSuccess]);

	if (isVersementError) {
		return (
			<CustomModal isOpen onClose={onClose} size={modalSize}>
				<SamyAdvisor
					complexText={
						<VStack align="start">
							<Heading variant="Title-S-SemiBold">Nous reprenons la main sur votre demande</Heading>

							<Text>
								Une erreur est survenue, nous ne pouvons pas valider automatiquement votre demande pour l’instant. Ne
								vous inquiétez pas, l’équipe support est informée et reviendra vers vous très vite !
							</Text>
						</VStack>
					}
					advisor={Amelie}
				/>
			</CustomModal>
		);
	}

	return (
		<Modal closeOnOverlayClick={false} isOpen onClose={onClose} size="3xl" autoFocus={false}>
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack p="16px 8px" spacing="32px" align="start">
						<Heading variant="Title-M-SemiBold">
							Nouveau versement {vpSelectedContract ? 'programmé' : 'ponctuel'}
						</Heading>

						{step !== 2 && (
							<Stepper
								alignSelf="center"
								w="80%"
								steps={[{ title: 'Informations' }, { title: 'Signature' }, { title: 'Succès' }]}
								index={step}
							/>
						)}

						{hasDataPrefilled && (
							<>
								{step === 0 && (
									<Informations
										vpSelectedContract={vpSelectedContract}
										isVp={!!vpSelectedContract ? true : false}
										data={formData}
										onSubmit={onSubmit}
									/>
								)}
								{step === 1 && (
									<Signature
										versementType={!!vpSelectedContract ? 'programme' : 'ponctuel'}
										data={formData!}
										edit={() => setStep(0)}
										onSuccess={lastStep}
										onError={onVersementError}
									/>
								)}
								{step === 2 && (
									<VStack spacing="0px" w="100%">
										<VStack spacing="32px" m="130px 169px 168px 169px">
											<Icon boxSize="142px" as={CheckmarkOutline} color={colors.positive[900]} />
											<VStack spacing="16px" textAlign="center">
												<Text variant="Title-M-Bold">Votre investissement a été versé avec succès</Text>
												<Text variant="Text-M-Regular" color={colors.grey[900]}>
													Il sera visible dans votre patrimoine dans 48 heures
												</Text>
											</VStack>
										</VStack>
										<Button onClick={onClose} alignSelf="end">
											Terminer
										</Button>
									</VStack>
								)}
							</>
						)}
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default CreateVersement;
