import { Navigate } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import FinalizingSubscriptionList from 'components/cards/subscriptions/FinalizingSubscriptionList';
import ValidatingSubscriptionList from 'components/cards/subscriptions/ValidatingSubscriptionList';
import FullscreenLoader from 'components/FullscreenLoader';
import useSubscriptionList, { getInvestSubscriptionStatus } from 'hooks/useSubscriptionList';
import isProposalSubscription from 'pages/Subscriptions/features/isPropalSubscription';
import { useGetLatestProposalQuery } from 'services/requests/proposal';
import { ProductType } from 'store/types/global.type';

const Subscriptions = (): JSX.Element => {
	const { data: proposal, isLoading: isProposalLoading } = useGetLatestProposalQuery();
	const { subscriptionList, isLoading } = useSubscriptionList();

	if (isLoading || isProposalLoading) return <FullscreenLoader />;
	if (subscriptionList.length === 0) return <Navigate to="/" replace />;

	return (
		<VStack w="100%" spacing="40px" align="start">
			<FinalizingSubscriptionList
				subscriptions={subscriptionList.filter((sub) => {
					if (isProposalSubscription(proposal, sub.id)) return false;
					return sub.typeProject === ProductType.INVEST
						? getInvestSubscriptionStatus(sub) === 'TO_COMPLETE'
						: sub.status === 'REQUESTED';
				})}
			/>
			<ValidatingSubscriptionList
				subscriptions={subscriptionList.filter((sub) => {
					if (isProposalSubscription(proposal, sub.id)) return false;
					if (sub.typeProject === ProductType.INVEST) return getInvestSubscriptionStatus(sub) === 'SUBSCRIBING';
					return sub.status !== 'REQUESTED';
				})}
			/>
		</VStack>
	);
};

export default Subscriptions;
