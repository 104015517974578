import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Grid, GridItem, HStack, Image, Stack, Text, useDisclosure, VStack } from '@chakra-ui/react';

import avLuxDesktop from 'assets/icons/OffreBlack/avLuxDesktop.png';
import avLuxMobile from 'assets/icons/OffreBlack/avLuxMobile.png';
import creditLombardDesktop from 'assets/icons/OffreBlack/creditLombardDesktop.png';
import creditLombardMobile from 'assets/icons/OffreBlack/creditLombardMobile.png';
import exonerationDesktop from 'assets/icons/OffreBlack/exonerationDesktop.png';
import exonerationMobile from 'assets/icons/OffreBlack/exonerationMobile.png';
import peDesktop from 'assets/icons/OffreBlack/peDesktop.png';
import peMobile from 'assets/icons/OffreBlack/peMobile.png';
import { useAppResponsive } from 'hooks/useAppResponsive';

import AvLuxDrawer from './Services/AvLux';
import CreditLomardDrawer from './Services/CreditLomard';

type ServicesProductsOffreBlackType = {
	image: string;
	productName: string;
	description: string;
	btnText: string;
	onClick: () => void;
	badge?: string;
};

const ServicesProductsOffreBlack = (): JSX.Element => {
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;
	const navigate = useNavigate();

	const { isOpen: isOpenAvLuxDrawer, onOpen: onOpenAvLuxDrawer, onClose: isCloseAvLuxDrawer } = useDisclosure();

	const {
		isOpen: isOpenCreditLomardDrawer,
		onOpen: onOpenCreditLomardDrawer,
		onClose: isCloseCreditLomardDrawer,
	} = useDisclosure();

	const servicesProducts: ServicesProductsOffreBlackType[] = useMemo(
		() => [
			{
				image: isMobile ? peMobile : peDesktop,
				productName: 'Private Equity',
				description: "Accédez aux fonds de Private Equity les plus prestigieux d'Europe",
				btnText: 'Explorer le catalogue',
				onClick: () => {
					navigate('/private-equity?taxAdvantages=noRevenueTaxAdvantage');
				},
			},
			{
				image: isMobile ? avLuxMobile : avLuxDesktop,
				productName: 'Assurance-vie Luxembourgeoise',
				description:
					"Neutralité fiscale, choix des devises (€, $, £, CHF). protection du capital et large diversité des supports d'investissement",
				btnText: 'En savoir plus',
				onClick: () => {
					onOpenAvLuxDrawer();
				},
			},
			{
				image: isMobile ? creditLombardMobile : creditLombardDesktop,
				productName: 'Crédit Lombard',
				badge: 'Bientot',
				description:
					"Empruntez à des conditions intéressantes en faisant levier sur votre assurance vie Ramify. Jusqu'à 200 000 €",
				btnText: 'En savoir plus',
				onClick: () => {
					onOpenCreditLomardDrawer();
				},
			},
			{
				image: isMobile ? exonerationMobile : exonerationDesktop,
				productName: 'Exonération 150-0 B Ter',
				description:
					"Bénéficiez du report d'impôt post cession grâce à notre selection de fonds immobilier et Private Equity",
				btnText: 'Découvrir les fonds éligibles',
				onClick: () => {
					navigate('/private-equity?taxAdvantages=reinvestTaxAdvantage');
				},
			},
		],
		[isMobile, navigate, onOpenAvLuxDrawer, onOpenCreditLomardDrawer],
	);

	return (
		<VStack w="100%" align="start" spacing="40px">
			<AvLuxDrawer isOpen={isOpenAvLuxDrawer} onClose={isCloseAvLuxDrawer} />
			<CreditLomardDrawer isOpen={isOpenCreditLomardDrawer} onClose={isCloseCreditLomardDrawer} />

			<Text variant="Title-L-Bold" color="primary.white">
				Services et produits exclusifs
			</Text>
			<Grid templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }} gap="40px">
				{servicesProducts.map((serviceProduct) => (
					<GridItem key={serviceProduct.productName}>
						<Card h="100%" border="none" bg="primary.black" key={serviceProduct.productName} p="0px">
							<Stack h="100%" direction={{ base: 'column', xl: 'row' }}>
								<Image
									w={{ base: '100%', xl: '180px' }}
									h={{ base: '120px', xl: '450px' }}
									borderRadius="8px 0px 0px 8px"
									src={serviceProduct.image}
									alt={serviceProduct.productName}
								/>
								<VStack w="100%" h="100%" align="start" justify="space-between" px="40px" py="24px" spacing="24px">
									<HStack w="100%" align="start" justify="start">
										<Text variant="Title-L-SemiBold" color="primary.white" minH="72px">
											{serviceProduct.productName}
										</Text>
										{serviceProduct.badge && (
											<Text
												variant="Caption"
												color="primary.black"
												p="2px 4px"
												ml="8px"
												bg="primary.yellow"
												as="span"
												borderRadius="4px"
												fontSize="8px"
											>
												{serviceProduct.badge}
											</Text>
										)}
									</HStack>

									<Text variant="Text-M-Regular" color="primary.white">
										{serviceProduct.description}
									</Text>
									<Text
										variant="Text-S-Regular"
										color="primary.yellow"
										alignSelf="end"
										justifySelf="end"
										cursor="pointer"
										onClick={serviceProduct.onClick}
									>
										{serviceProduct.btnText}
									</Text>
								</VStack>
							</Stack>
						</Card>
					</GridItem>
				))}
			</Grid>
		</VStack>
	);
};
export default ServicesProductsOffreBlack;
