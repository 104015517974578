import { Controller, useForm } from 'react-hook-form';
import {
	Button,
	Checkbox,
	FormControl,
	FormErrorMessage,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import { useUpdateUserEntityTypeMutation } from 'services/requests/user';

type SubscriptionModalProps = {
	isOpenModalToBecomeAssimilatedProfessional: boolean;
	OnCloseModalToBecomeAssimilatedProfessional: () => void;
};

const ModalToBecomeAssimilatedProfessional = ({
	isOpenModalToBecomeAssimilatedProfessional,
	OnCloseModalToBecomeAssimilatedProfessional,
}: SubscriptionModalProps) => {
	const [setEntityType] = useUpdateUserEntityTypeMutation();
	const toast = useThemedToast();

	type FormDataType = {
		hasKnowledge: boolean;
		hasFunds: boolean;
	};
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormDataType>();

	const onSubmit = handleSubmit((data) => {
		if (data.hasKnowledge === true && data.hasFunds === true) {
			setEntityType({ legalEntityType: 'ASSIMILTED_PROFESSIONAL' })
				.then(() => {
					toast({
						title: 'Merci pour votre intérêt, vous êtes désormais considéré comme un averti professionnel',
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
				})
				.catch(() => {
					toast({
						title: 'Une erreur est survenue, veuillez ressayer plus tard',
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				});
		}
		OnCloseModalToBecomeAssimilatedProfessional();
	});

	return (
		<Modal
			isOpen={isOpenModalToBecomeAssimilatedProfessional}
			onClose={OnCloseModalToBecomeAssimilatedProfessional}
			size="2xl"
		>
			<ModalOverlay />
			<ModalContent p="16px">
				<form onSubmit={onSubmit}>
					<VStack spacing="24px" w="100%">
						<Text variant="title" pr="24px">
							Fonds réservé aux investisseurs avertis
						</Text>
						<ModalCloseButton />
						<VStack spacing="24px" w="100%" align="start">
							<Text variant="body">
								Certains fonds de Private Equity sont réservés aux investisseurs avertis. Pour des raisons légales, nous
								devons confirmer votre éligibilité.
							</Text>

							<FormControl isInvalid={!!errors.hasKnowledge}>
								<Controller
									name="hasKnowledge"
									control={control}
									rules={{ required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Checkbox onChange={onChange} isChecked={value} ref={ref}>
											<Text variant="label">
												Je confirme disposer d'une connaissance adéquate du non coté, notamment de ses risques
												(illiquidité, perte de capital)
											</Text>
										</Checkbox>
									)}
								/>
								{errors.hasKnowledge?.type === 'required' && (
									<FormErrorMessage>Ce champs est obligatoire</FormErrorMessage>
								)}
							</FormControl>

							<FormControl isInvalid={!!errors.hasFunds}>
								<Controller
									name="hasFunds"
									control={control}
									rules={{ required: true }}
									render={({ field: { onChange, value, ref } }) => (
										<Checkbox onChange={onChange} isChecked={value} ref={ref}>
											<Text variant="label">
												Je confirme pouvoir investir le montant minimum des fonds présentés sans impact sur mon niveau
												de vie dans le cas d'une perte totale du capital{' '}
											</Text>
										</Checkbox>
									)}
								/>
								{errors.hasFunds?.type === 'required' && <FormErrorMessage>Ce champs est obligatoire</FormErrorMessage>}
							</FormControl>
						</VStack>

						<Stack w="100%" flex="1" direction={{ base: 'column', md: 'row' }}>
							<Button
								variant="secondary"
								onClick={OnCloseModalToBecomeAssimilatedProfessional}
								w={{ base: '100%', md: '50%' }}
							>
								Annuler
							</Button>
							<Button variant="primary" type="submit" w={{ base: '100%', md: '50%' }}>
								Mettre à jour mon profil d’investisseur
							</Button>
						</Stack>
					</VStack>
				</form>
			</ModalContent>
		</Modal>
	);
};
export default ModalToBecomeAssimilatedProfessional;
