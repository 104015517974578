import { CloseIcon } from '@chakra-ui/icons';
import {
	AspectRatio,
	chakra,
	HStack,
	IconButton,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react';

type TypeformPropsType = {
	url: string;
	typeformTitle: string;
	show: boolean;
	onClose: () => void;
};

const TypeformContainer = ({ url, typeformTitle, show, onClose }: TypeformPropsType): JSX.Element => {
	return (
		<Modal isOpen={show} isCentered onClose={() => onClose()}>
			<ModalOverlay />
			<ModalContent maxW="80vw">
				<ModalCloseButton />
				<HStack align="start" w="100%">
					<AspectRatio ratio={{ base: 1 / 2, md: 16 / 9 }} minW="100%">
						<chakra.iframe src={url} id={typeformTitle} title={typeformTitle} />
					</AspectRatio>
					<IconButton
						aria-label="close form"
						icon={<CloseIcon color="grey.500" />}
						backgroundColor="transparent"
						onClick={() => onClose()}
					/>
				</HStack>
			</ModalContent>
		</Modal>
	);
};

export default TypeformContainer;
