import { CenterCircle, ChartStepper } from '@carbon/icons-react';
import { Heading, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import Article from 'components/Articles/Article';

import CheckList from './CheckList';
import InfosCards from './InfosCards';

const Articles = (): JSX.Element => {
	return (
		<VStack spacing="56px" align="start" w="100%">
			<Heading variant="Title-L-SemiBold">L'assurance Vie</Heading>

			<VStack spacing="48px" align="start" w="100%">
				<Wrap w="100%">
					<WrapItem flex="1" minW="250px">
						<CheckList
							elements={[
								'Argent déblocable à tout moment, sans condition',
								'Possibilité de mettre en place des versements programmés',
								'Fiscalité allégée après 8 ans de détention',
							]}
						/>
					</WrapItem>
					<WrapItem flex="1" minW="250px">
						<InfosCards
							elements={[
								{
									icon: <CenterCircle size="32" />,
									title: 'Objectif',
									value: 'Se construire un capital et baisser l’impôt sur les plus values',
								},
								{
									icon: <ChartStepper size="32" />,
									title: 'Liquidité',
									value: 'Elevée, argent déblocable à tout moment',
								},
							]}
						/>
					</WrapItem>
				</Wrap>
				<Article
					imageURL="https://assets.website-files.com/63c027c9ecb9a193a6b225ea/6425c1fa47eb3a5e007dfe51_pexels-photo-3760067-p-500.webp"
					title="Assurance vie : définition et fonctionnement"
					description="L'assurance vie française est un produit financier permettant de constituer un capital à long terme et de transmettre un patrimoine. Elle offre des avantages fiscaux et successoraux, ainsi que des options d'investissement variées, tels que les fonds en euros ou les unités de compte."
					tags={[{ label: 'Guide', variant: 'coloredBgYellow' }]}
					btnCallback={() => {
						window.open('https://www.ramify.fr/assurance-vie');
					}}
				/>
			</VStack>

			<Heading variant="Title-L-SemiBold">Plan Epargne Retraite</Heading>
			<VStack spacing="48px" align="start" w="100%">
				<Wrap w="100%">
					<WrapItem flex="1" minW="250px">
						<CheckList
							elements={[
								'Argent disponible au moment de la retraite',
								'Versements déduits de votre revenu imposable',
								'Possibilité de mettre en place des versements programmés',
							]}
						/>
					</WrapItem>
					<WrapItem flex="1" minW="250px">
						<InfosCards
							elements={[
								{
									icon: <CenterCircle size="32" />,
									title: 'Objectif',
									value: 'Préparer sa retraite et baisser son impôt sur le revenu',
								},
								{
									icon: <ChartStepper size="32" />,
									title: 'Liquidité',
									value: 'Faible, argent déblocable à la retraite',
								},
							]}
						/>
					</WrapItem>
				</Wrap>
				<Article
					imageURL="https://assets.website-files.com/63c1a162348341b917d77638/64abe1aac73c27689c8a2394_towfiqu-barbhuiya-jpqyfK7GB4w-unsplash-p-500.jpg"
					title="PER – L’enveloppe fiscale dont vous n’entendez pas (assez) parler"
					description="Le PER c’est une enveloppe fiscale géniale, un peu contraignante et surtout méconnue. Ce dispositif vous permet d’investir et de profiter d’avantages fiscaux complètement différents des enveloppes les plus connues."
					tags={[{ label: 'Guide', variant: 'coloredBgYellow' }]}
					btnCallback={() => {
						window.open('https://www.ramify.fr/per/per-defiscaliser-retraite');
					}}
				/>
			</VStack>
		</VStack>
	);
};

export default Articles;
