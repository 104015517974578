import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowLeft, Calendar, Portfolio, Report } from '@carbon/icons-react';
import {
	Card,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	HStack,
	Icon,
	IconButton,
	SimpleGrid,
	Skeleton,
	Text,
	VStack,
} from '@chakra-ui/react';

import { CashflowGraph } from 'components/charts/CashFlowGraph';
import DocumentsToDownloadDashboard from 'components/products/DocumentsToDownload';
import CardFundDashboard from 'components/products/FundCard';
import { useDrawer } from 'hooks/useDrawer';
import { CapitalGraphPe } from 'pages/PrivateEquity/Dashboard/Charts/CapitalGraph';
import {
	PrivateEquitySubscriptionMetrics,
	useGetPrivateEquityDashboardSubscriptionsQuery,
} from 'services/requests/privateEquity';
import { useGetPEFundByIdQuery } from 'services/requests/privateEquity/funds';
import { useGetUserPESubscriptionByIdQuery } from 'services/requests/privateEquity/subscriptions';
import { PEFund, PrivateEquitySubscription } from 'store/types/airtable.type';
import { isNone } from 'utils/functions';
import { displayMoney, formatDateStandart } from 'utils/rendering';

import CapitalValueCard from './CapitalValueCard';
import PlusValueCard from './PlusValueCard';

const ContractInfo: FC<{ fund: PEFund; subscription: PrivateEquitySubscription }> = ({ fund, subscription }) => (
	<VStack spacing="16px" align="start">
		<Heading variant="Title-L-SemiBold">Contrat</Heading>

		<HStack spacing="16px">
			<Icon boxSize="32px" as={Report} />
			<VStack align="start" spacing="4px">
				<Text variant="Text-S-Medium" color="grey.900">
					Type de fonds
				</Text>
				<Text variant="Text-M-Medium">{fund.FundType}</Text>
			</VStack>
		</HStack>

		<HStack spacing="16px">
			<Icon boxSize="32px" as={Portfolio} />
			<VStack align="start" spacing="4px">
				<Text variant="Text-S-Medium" color="grey.900">
					Montant Investi
				</Text>
				<Text variant="Text-M-Medium">{displayMoney(subscription.amountVersement)}</Text>
			</VStack>
		</HStack>

		<HStack spacing="16px">
			<Icon boxSize="32px" as={Calendar} />
			<VStack align="start" spacing="4px">
				<Text variant="Text-S-Medium" color="grey.900">
					Date de début du contrat
				</Text>
				<Text variant="Text-M-Medium">{formatDateStandart(subscription.signedAt)}</Text>
			</VStack>
		</HStack>
	</VStack>
);

const InfosSubscription: FC<{
	metrics: PrivateEquitySubscriptionMetrics;
	subscription: PrivateEquitySubscription;
}> = ({ metrics, subscription }) => {
	const { data: PEFundDetails } = useGetPEFundByIdQuery(subscription.fund as string);

	if (!(PEFundDetails && PEFundDetails?.FundType !== 'Startup')) return null;

	return (
		<VStack w="100%" align="start" spacing="24px">
			<Heading variant="Title-L-SemiBold">Mon investissement</Heading>

			<Text>
				Date de dernière valorisation :{' '}
				{subscription.lastValuationDate &&
					new Date(subscription.lastValuationDate).toLocaleDateString('fr-FR', {
						dateStyle: 'long',
					})}
			</Text>

			<SimpleGrid columns={{ base: 1, md: 2 }} w="100%" spacing="24px">
				<CapitalValueCard subscription={metrics} />
				<PlusValueCard subscription={metrics} />
				<Card w="100%" h="100%" minH="235px">
					<Text fontWeight="semibold">Evolution du capital</Text>

					<CapitalGraphPe data={metrics} />
				</Card>
				<Card w="100%" h="100%" minH="235px">
					<Text fontWeight="semibold">Cashflow</Text>

					<CashflowGraph data={metrics} />
				</Card>
			</SimpleGrid>
		</VStack>
	);
};

const DashboardFund: FC = () => {
	const { id } = useParams<{ id: string }>();

	const { data: subscriptions, isFetching: areSubscriptionsFetching } =
		useGetPrivateEquityDashboardSubscriptionsQuery();
	const { data: subscription, isFetching: isSubscriptionFetching } = useGetUserPESubscriptionByIdQuery(
		{ id: id! },
		{ skip: isNone(id) || areSubscriptionsFetching },
	);
	const { data: fund } = useGetPEFundByIdQuery(subscription?.fund as string, {
		skip: isNone(subscription?.fund) || isSubscriptionFetching,
	});
	const subscriptionMetrics = subscriptions?.find((sub) => sub.id === id);

	const { isOpen, onClose } = useDrawer();

	return (
		<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isOpen} onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<HStack>
						<IconButton onClick={onClose} variant="icon" aria-label="back" icon={<ArrowLeft size="32" />} />
						<Heading variant="Title-M-SemiBold">Private Equity</Heading>
					</HStack>
				</DrawerHeader>

				<DrawerBody>
					{subscriptionMetrics && subscription && fund && !areSubscriptionsFetching && !isSubscriptionFetching ? (
						<VStack w="100%" spacing="32px" align="start">
							<CardFundDashboard subscription={subscription} fund={fund} />

							<InfosSubscription
								metrics={subscriptionMetrics}
								subscription={subscription as PrivateEquitySubscription}
							/>

							<ContractInfo fund={fund} subscription={subscription} />

							<DocumentsToDownloadDashboard subscription={subscription} />
						</VStack>
					) : (
						<Skeleton height="500px" w="100%" />
					)}
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default DashboardFund;
