import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OnboardingObject } from 'onboarding/Invest/Shared/type';
import { PopulatedInvestSimulation } from 'store/types/investSimulation';
import { PopulatedInvestSubscription } from 'store/types/subscription.type';

export enum MainStepType {
	START = 'start',
	COURSE = 'course',
	END = 'end',
}

export type OnboardingStep<T extends string = string> = {
	id: T;
	name: string;
	url: string;
	type: MainStepType;
	innerSteps?: {
		id: string;
		name: string;
		url: string;
	}[];
};

type WithOnboardingObject = { object: OnboardingObject };
type WithSubscription = { subscription: PopulatedInvestSubscription };
type WithSimulation = { simulation: PopulatedInvestSimulation };
type WithNavigation<D> = { onNext: (data: D) => void; onPrev?: () => void };
type WithOptionalOnboardingObject = Partial<WithOnboardingObject>;

// props for steps may create an onboarding object or will update it
export type OnboardingStepProps<D extends Record<string, unknown> | void = void> = WithNavigation<D>;

// props for steps may create an onboarding object or will update it
export type OnboardingCreateStepProps<D extends Record<string, unknown> | void = void> = WithOptionalOnboardingObject &
	WithNavigation<D>;

// props for steps that need an onboarding object and will update it
export type OnboardingUpdateStepProps<D extends Record<string, unknown> | void = void> = WithOnboardingObject &
	WithNavigation<D>;

// props for subscription steps that need a subscription object
export type SouscriptionStepProps<D extends Record<string, unknown> | void = void> = WithSubscription &
	WithNavigation<D>;

// props for simulation steps that need a simulation object
export type SimulationStepProps<D extends Record<string, unknown> | void = void> = WithSimulation & WithNavigation<D>;

export type StepType = {
	id: string;
	innerStepId: string;
};

type InitialStateType = {
	currentStep: StepType;
	goBackToPreviousStep: boolean;
	lastStepAvailable: string;
	lastInnerStepAvailable: string;
	collapsed: boolean;
};

const initialState: InitialStateType = {
	currentStep: {
		id: 'project',
		innerStepId: 'introduction',
	},
	goBackToPreviousStep: false,
	lastStepAvailable: 'project',
	lastInnerStepAvailable: 'introduction',
	collapsed: false,
};

const stepperSlice = createSlice({
	name: 'stepper',
	initialState,
	reducers: {
		setCurrentStep: (
			state,
			action: PayloadAction<{
				id: string;
				innerStepId: string;
			}>,
		) => {
			state.currentStep = action.payload;
		},
		setGoBackToPreviousStep: (state, action: PayloadAction<boolean>) => {
			state.goBackToPreviousStep = action.payload;
		},
		setLastStepAvailable: (state, action: PayloadAction<string>) => {
			state.lastStepAvailable = action.payload;
		},
		setLastInnerStepAvailable: (state, action: PayloadAction<string>) => {
			state.lastInnerStepAvailable = action.payload;
		},
		// ----- new ------
		// goToNextInnerStep: (state) => {
		// 	const currentInnerStepIndex = state.currentStep.innerStepId
		// 		? state.currentStep.innerStepId
		// 		: 0;
		// 	const nextInnerStepIndex = currentInnerStepIndex + 1;
		// 	const nextInnerStepId = state.currentStep.innerSteps[nextInnerStepIndex].id;
		// 	state.currentStep.innerStepId = nextInnerStepId;
		// }
	},
});

export const { setCurrentStep, setLastStepAvailable, setLastInnerStepAvailable, setGoBackToPreviousStep } =
	stepperSlice.actions;

export default stepperSlice.reducer;
