export const tooltipRiskAversion: Record<string, React.ReactNode> = {
	timeHorizon: (
		<>
			Il s’agit de votre horizon d’investissement, donnée nécessaire à l’élaboration de votre stratégie d'épargne ainsi
			que votre portefeuille d’actifs sur-mesure.
			<br />
			Plus votre horizon d’investissement sera <b>long</b>, plus le portefeuille recommandé sera risqué. En effet, la
			probabilité de perdre de l’argent en bourse sur une longue période est très faible
		</>
	),

	house: (
		<>
			Ces informations nous permettent de collecter un paramètre essentiel à l’optimisation de votre épargne:
			<b> l’objectif d’investissement</b>.
		</>
	),
	retirement: (
		<>
			Si vous êtes né(e) après 1973, vous bénéficiez d'une retraite à taux plein dès lors que vous justifiez d'une durée
			d'assurance retraite d'au moins 43 ans. Sinon, vous bénéficiez automatiquement d'une retraite à taux plein à
			partir de 67 ans.
			<br />
			<br />
			Pour ceux né(e) avant 1973, les règles sont un peu différentes. Nous les avons aussi prises en compte.
		</>
	),
};
