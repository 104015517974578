import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Alert,
	AlertIcon,
	Button,
	Checkbox,
	Heading,
	HStack,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	PinInput,
	PinInputField,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Ring } from '@uiball/loaders';

import { ArrowButton } from 'components/Button/Button';
import LabelWrapperInput from 'components/inputs/LabelWrapperInput';
import useThemedToast from 'hooks/useThemedToast';
import { isFetchBaseQueryErrorType } from 'services/apiService';
import eventTracker from 'services/events/eventTracker';
import { useGetContractsQuery } from 'services/requests/invest/contracts';
import {
	useGetAllSubscriptionsQuery,
	useLazySendSignatureCodeQuery,
	useSignSouscriptionMutation,
} from 'services/requests/invest/subscription';
import { useGetUserQuery } from 'services/requests/user';
import { PopulatedInvestSubscription, SubscriptionStatus } from 'store/types/subscription.type';
import { environmentConstants } from 'utils/constants';
import { getErrorMetadataFromRequest } from 'utils/errors/getErrorMetadataFromRequest';
import { isNotNone } from 'utils/functions';

type ModalBulletinProps = {
	isOpen: boolean;
	onClose: () => void;
	onSuccess: () => void;
	subscription: PopulatedInvestSubscription;
};

const ModalBulletin = ({ isOpen, onClose, onSuccess, subscription }: ModalBulletinProps): JSX.Element => {
	const toast = useThemedToast();
	const { data: userData } = useGetUserQuery();
	const [codeSent, setCodeSent] = useState(false);
	const { data: subscriptions } = useGetAllSubscriptionsQuery();
	const { data: contracts } = useGetContractsQuery();

	const [sendCode] = useLazySendSignatureCodeQuery();
	const [
		sign,
		{ isLoading: isSignatureLoading, isSuccess: isSignatureSuccess, isError: isSignatureError, error: errorSign },
	] = useSignSouscriptionMutation();

	const duplicatePER = useMemo<boolean>(() => {
		if (subscription.envelope.type !== 'PER') return false;
		const hasSignedAnotherPER =
			isNotNone(subscriptions) &&
			subscriptions.some(
				// find if a PER subscription is signed
				(s) =>
					s.envelope.type === 'PER' && ![SubscriptionStatus.CREATED, SubscriptionStatus.GENERATED].includes(s.status),
			);
		const hasOpenedAnotherPER =
			isNotNone(contracts) &&
			contracts.some(
				// find if a PER contract is opened
				(c) => c.envelope.type === 'PER',
			);
		return hasSignedAnotherPER || hasOpenedAnotherPER;
	}, [contracts, subscriptions, subscription]);

	useEffect(() => {
		if (isSignatureLoading) return;
		if (isSignatureSuccess) {
			if (environmentConstants.environment === 'production') {
				eventTracker.contractSigned({
					subscriptionId: subscription.id,
					montant: +subscription.initialDepositAmount,
					emailCustomer: userData?.email || '',
				});
			}
			toast({
				title: 'Votre signature a bien été prise en compte',
				description: "Votre contrat sera ouvert d'ici quelques jours",
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
			onSuccess();
		} else if (isSignatureError && errorSign) {
			if (isFetchBaseQueryErrorType(errorSign)) {
				const { message } = getErrorMetadataFromRequest(errorSign);
				toast({
					title: message,
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
			}
		}
	}, [isSignatureSuccess, isSignatureLoading]); // eslint-disable-line react-hooks/exhaustive-deps

	const onSendCodeRequest = () => {
		sendCode({ subscriptionId: subscription.id });
		setCodeSent(true);
	};

	const [codeValue, setCodeValue] = useState('');
	const onCodeSubmit = () => {
		if (codeValue.length !== 6) {
			toast({
				title: 'Le code est incorrect',
				description: 'Le code de signature doit faire 6 caractères.',
				status: 'error',
				duration: 4000,
				isClosable: true,
			});
		} else if (isSignatureLoading === false) {
			sign({
				subscriptionId: subscription.id,
				code: codeValue,
			});
		}
	};

	const duplicatePERCallback = useCallback(() => {
		onClose();
		window.Intercom('showNewMessage');
	}, [onClose]);

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Heading variant="Title-M-Bold">Signature du contrat</Heading>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody pt="32px" pb="32px">
					<VStack spacing="32px" align="start">
						{!codeSent ? (
							duplicatePER ? (
								<Text variant="label" color="negative.500">
									Vous avez déjà souscrit à un PER individuel, veuillez contacter le support pour plus d'informations.
								</Text>
							) : (
								<>
									<Text variant="body">Merci de confirmer que vous avez pris connaissance du contrat proposé :</Text>
									<Checkbox
										isChecked={false}
										isDisabled={duplicatePER}
										onChange={() => onSendCodeRequest()}
										data-cy="confirm-contract"
										// {...textStyles.body}
									>
										J'ai pris connaissance du contrat
									</Checkbox>
								</>
							)
						) : (
							<VStack spacing="32px">
								<Alert status="success">
									<AlertIcon />
									Un code vous a été envoyé par SMS.
								</Alert>
								<LabelWrapperInput label="Code reçu">
									<HStack>
										<PinInput value={codeValue} onChange={setCodeValue}>
											<PinInputField />
											<PinInputField />
											<PinInputField />
											<PinInputField />
											<PinInputField />
											<PinInputField />
										</PinInput>
									</HStack>
								</LabelWrapperInput>
								<Text variant="Text-S-Regular" alignSelf="flex-start">
									La réception du code peut parfois prendre jusqu'à 3 minutes.
								</Text>
								<Text variant="Text-S-Regular">
									Vous n'avez rien reçu ?{' '}
									<Link onClick={onSendCodeRequest} fontWeight="semibold">
										Renvoyer un code sms
									</Link>
									.
								</Text>
								{isSignatureLoading ? (
									<ArrowButton type="submit" size="lg" variant="primary" ml="auto !important">
										<HStack align="center" spacing="12px">
											<Ring size={20} />
											<Text variant="label">Validation ...</Text>
										</HStack>
									</ArrowButton>
								) : (
									<ArrowButton type="submit" size="lg" variant="primary" ml="auto !important" onClick={onCodeSubmit}>
										Signer le bulletin
									</ArrowButton>
								)}
							</VStack>
						)}
					</VStack>
				</ModalBody>
				{duplicatePER && (
					<ModalFooter>
						<Button onClick={duplicatePERCallback}>Contacter un conseiller</Button>
					</ModalFooter>
				)}
			</ModalContent>
		</Modal>
	);
};

export default ModalBulletin;
