// export const temperatureMin = 1.5;
// export const temperatureMid = 2;
export const temperatureMin = 2;
export const temperatureMax = 2.5;

type TemperatureDisplay = {
	name: string;
	description: string;
	value: number;
};

export const temperatureDisplay = (temperature: number | null | undefined): TemperatureDisplay => {
	// if (temperature === temperatureMin)
	// 	return {
	// 		name: '+ 1,5',
	// 		description: 'Scenario idéal Accord de Paris',
	// 		value: temperatureMin,
	// 	};
	// if (temperature === temperatureMid)
	// 	return {
	// 		name: '+ 2,0',
	// 		description: 'Aligné avec l’Accord de Paris',
	// 		value: temperatureMid,
	// 	};
	if (temperature === temperatureMin)
		return {
			name: '2,0 °C',
			description: 'Aligné avec l’Accord de Paris',
			value: temperatureMin,
		};
	return {
		name: '2,5 °C',
		description: 'Incompatible avec l’Accord de Paris',
		value: temperatureMax,
	};
};
