import { Box, Heading, VStack } from '@chakra-ui/react';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';

import { BarDataIntervalType } from 'components/Graphs/visx/PerformancesBarCard';
import { TooltipData } from 'components/Graphs/visx/ToolTipGraphMobile';
import { useAppResponsive } from 'hooks/useAppResponsive';
import colors from 'theme/foundations/colors';
import { displayPercentage } from 'utils/rendering';

const BarChart = ({
	barData,
	width,
	height,
}: {
	barData: BarDataIntervalType[];
	width: number;
	height: number;
}): JSX.Element => {
	const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
	const scaleFontSize = useAppResponsive({ base: '10px', md: '12px' });
	const scaleFontAngle = useAppResponsive({ base: 45, md: 0 });

	const { TooltipInPortal } = useTooltipInPortal({
		scroll: true,
	});

	let tooltipTimeout: number;

	const margin = { top: 20, right: 20, bottom: 40, left: 40 };
	const yMax = height - margin.top - margin.bottom;

	const xScale = scaleBand<string>({
		domain: barData.map((d) => d.year),
		range: [margin.left, width - margin.right],
		padding: 0.2,
	});

	const yScale = scaleLinear({
		// From the lowest performance to the highest performance (+/- 10 to display the max/min values in the y axis)
		domain: [
			Math.min(...barData.map((d) => d.performance * 100)),
			Math.max(...barData.map((d) => d.performance * 100)),
		],
		range: [yMax, 0],
	});

	return (
		<Box as="svg" w="100%" h="320px" maxH="100%" position="relative">
			<Group>
				{/* Render the x-grid */}
				<GridRows
					scale={yScale}
					width={width - margin.left - margin.right}
					numTicks={8}
					left={margin.left}
					stroke="#e0e0e0"
					strokeOpacity={0.5}
				/>

				{/* Render the bars */}
				{barData.map((d) => (
					<Bar
						key={d.year}
						x={(xScale(d.year) ?? 0) - 10}
						y={d.performance >= 0 ? yScale(d.performance * 100) : yScale(0)}
						height={Math.abs(yScale(d.performance * 100) - yScale(0))}
						width={xScale.bandwidth() - 4}
						fill={d.color}
						onMouseLeave={() => {
							tooltipTimeout = window.setTimeout(() => {
								hideTooltip();
							}, 300);
						}}
						onMouseMove={(event) => {
							if (tooltipTimeout) clearTimeout(tooltipTimeout);
							showTooltip({
								tooltipData: {
									title: d.year,
									value: d.performance,
								},
								tooltipTop: event.clientY,
								tooltipLeft: event.clientX,
							});
						}}
					/>
				))}

				{/* Render the x-axis */}
				<AxisBottom
					strokeWidth={0}
					scale={xScale}
					top={height - margin.bottom}
					tickLabelProps={{
						fontFamily: 'Whyte',
						color: colors.primary.black,
						lineHeight: '120%',
						fontSize: scaleFontSize,
						fontWeight: 'bold',
						textAnchor: 'end',
						angle: scaleFontAngle,
					}}
				/>

				{/* Render the y-axis */}
				<AxisLeft
					scale={yScale}
					left={margin.left}
					strokeWidth={0}
					numTicks={8}
					tickLabelProps={{
						fontFamily: 'Whyte',
						color: colors.primary.black,
						lineHeight: '120%',
						fontSize: '12px',
						fontWeight: 'bold',
						textAnchor: 'end',
					}}
					tickFormat={(value) => `${value}%`}
				/>
				{tooltipOpen && tooltipData && (
					<TooltipInPortal top={tooltipTop} left={tooltipLeft}>
						<Heading variant="Title-S-Bold">{tooltipData.title}</Heading>
						<Heading variant="Title-S-Bold" color={+tooltipData.value > 0 ? colors.primary.yellow : colors.grey[500]}>
							{displayPercentage(+tooltipData.value)}
						</Heading>
					</TooltipInPortal>
				)}
			</Group>
		</Box>
	);
};

const PerformancesGraph = ({ barData }: { barData: BarDataIntervalType[] }): JSX.Element => {
	return (
		<VStack w="100%">
			<ParentSize>{(parent) => <BarChart barData={barData} width={parent.width} height={parent.height} />}</ParentSize>
		</VStack>
	);
};

export default PerformancesGraph;
