import { VStack } from '@chakra-ui/react';

import Articles from './sections/Articles';
import ComparePortfoliosCards from './sections/ComparePorfolios';
import ContractsCards from './sections/ContractCards';
import OptionContracts from './sections/OptionContracts';
import PortfoliosCards from './sections/PorfoliosCards';
import OurFees from './OurFees';

const DiscoverInvest = (): JSX.Element => {
	return (
		<VStack align="start" w="100%" spacing="56px" pb="132px">
			<ContractsCards />
			<PortfoliosCards />
			<ComparePortfoliosCards />
			<Articles />
			<OurFees />
			<OptionContracts />
		</VStack>
	);
};

export default DiscoverInvest;
