import { Control, RegisterOptions, useController } from 'react-hook-form';
import { Radio, RadioGroup, RadioGroupProps, VStack } from '@chakra-ui/react';

import { isNotNone } from 'utils/functions';

const ControlledRadioOptions = ({
	control,
	name,
	options,
	type = 'string',
	// Must use this rule instand of {required: true}, otherwise 'false' will be considered as empty
	rules = { validate: { required: (value) => isNotNone(value) } },
	...radioGroupProps
}: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<any>;
	name: string;
	options: readonly { label: string; value: string | number | boolean }[] | 'Yes-No';
	type?: 'string' | 'boolean' | 'number';
	rules?: Omit<RegisterOptions, 'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'> | undefined;
} & Omit<RadioGroupProps, 'children'>) => {
	const { field } = useController({
		name,
		control,
		rules,
	});

	const onValueChange = (newValue: string) => {
		if (type === 'boolean' || options === 'Yes-No') {
			field.onChange(newValue === 'true');
		} else if (type === 'number') {
			field.onChange(Number(newValue));
		} else {
			field.onChange(newValue);
		}
	};

	return (
		<RadioGroup
			variant="single"
			size="sm"
			{...field}
			onChange={onValueChange}
			value={field.value?.toString()}
			{...radioGroupProps}
		>
			<VStack spacing="8px">
				{options === 'Yes-No' ? (
					<>
						<Radio value="true">Oui</Radio>
						<Radio value="false">Non</Radio>
					</>
				) : (
					options.map(({ label, value }) => (
						<Radio key={value.toString()} value={value.toString()}>
							{label}
						</Radio>
					))
				)}
			</VStack>
		</RadioGroup>
	);
};

export default ControlledRadioOptions;
