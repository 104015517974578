import { Heading, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { OnboardingStepProps } from 'onboarding/Stepper/stepper.slice';
import textStyles from 'theme/foundations/typography';

const Introduction = ({ onNext }: OnboardingStepProps): JSX.Element => {
	const isMobile = useAppResponsive({ base: true, lg: false });

	return (
		<VStack w="100%" align="start" spacing="48px">
			<VStack w="100%" spacing="24px" align="start">
				<Heading variant="Title-XL-Bold">Mon portefeuille d'investissement</Heading>
				<Text variant="Text-M-Regular" color="grey.900" maxW="900px">
					Dans cette étape nous allons construire ensemble un portefeuille d’investissement <b>unique pour vous</b>,
					basé sur votre situation personnelle et une évaluation de votre appétence au risque. Un portefeuille doit non
					seulement s’adapter à <b>votre appétence au risque</b>, mais aussi évoluer en fonction de vos projets et de
					vos objectifs.
				</Text>
			</VStack>
			{/* <Skeleton w="100%" h="120px" borderRadius="sm" /> */}
			<VStack spacing="16px" align="start">
				<Text variant="Text-M-Regular" color="grey.900">
					Ce qui vous attend :
				</Text>
				<UnorderedList
					ml={{ base: '24px !important', md: '32px !important' }}
					spacing="8px"
					{...textStyles['Text-M-Regular']}
					color="grey.900"
				>
					<ListItem>
						Un court questionnaire pour estimer votre expérience de l’investissement et <b>votre appétence au risque</b>
						.
					</ListItem>
					<ListItem>
						La présentation de <b>votre portefeuille sur mesure</b>.
					</ListItem>
				</UnorderedList>
			</VStack>
			<ArrowButton size="lg" w={{ base: '100%', md: 'auto' }} data-cy="create-portfolio-btn" onClick={() => onNext()}>
				{isMobile ? 'Continuer' : "Créer mon portefeuille d'investissement"}
			</ArrowButton>
		</VStack>
	);
};

export default Introduction;
