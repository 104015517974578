import { Card, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';

import DocumentToDownload from 'components/DocumentToDownload';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { DocumentDetails } from 'services/requests/invest/contracts';
import { PopulatedInvestContract } from 'store/types/contract.type';
import api from 'utils/api';
import { displayMoney } from 'utils/rendering';

type HelpDeclarationSourcePerProps = {
	title: string;
	description: JSX.Element;
	contract: {
		contract: PopulatedInvestContract;
		totalYearlyDeposit: number;
		DocumentFiscale?: DocumentDetails[];
	};
};

const HelpDeclarationSourcePer = ({ title, description, contract }: HelpDeclarationSourcePerProps) => {
	const isMobile = useAppResponsive({ base: true, md: false });

	return (
		<VStack align="start" w="100%" spacing="16px">
			<Text variant="title">{title}</Text>
			<Card variant="blue">{description}</Card>

			{isMobile ? (
				<VStack align="start" w="100%" spacing="16px">
					{contract && (
						<VStack w="100%" align="start">
							<Text variant="title">PER Ramify</Text>
							<Text variant="title">
								{displayMoney(contract.totalYearlyDeposit)} {'  '}
								<Text as="span" variant="label">
									Somme des versements
								</Text>
							</Text>
							{contract.DocumentFiscale && contract.DocumentFiscale?.length >= 1 && (
								<DocumentToDownload
									handleDownloadCustom={() => {
										api.downloadContractDocument(contract.contract.id, contract.DocumentFiscale![0].docId || '');
									}}
									name="Document fiscale PER"
								/>
							)}
						</VStack>
					)}
				</VStack>
			) : (
				<TableContainer w="100%">
					<Table variant="unstyled" w="100%">
						<Thead>
							<Tr>
								<Th>
									<Text variant="title" fontSize="12px">
										SOMME DES VERSEMENTS
									</Text>
								</Th>

								<Th>
									<Text variant="title" fontSize="12px">
										CONTRAT
									</Text>
								</Th>

								<Th>
									<Text variant="title" fontSize="12px">
										DOCUMENT / IFU
									</Text>
								</Th>
							</Tr>
						</Thead>
						<Tbody w="100%">
							<Tr justifyContent="space-between" w="100%">
								<Td>
									<Text variant="title">{displayMoney(contract.totalYearlyDeposit)}</Text>
								</Td>

								<Td>
									<Text variant="title">PER Ramify</Text>
								</Td>

								<Td justifyContent="space-between">
									{contract.DocumentFiscale && contract.DocumentFiscale?.length >= 1 && (
										<DocumentToDownload
											name="Document fiscale PER"
											handleDownloadCustom={() => {
												api.downloadContractDocument(contract.contract.id, contract.DocumentFiscale![0].docId || '');
											}}
										/>
									)}
								</Td>
							</Tr>
						</Tbody>
					</Table>
				</TableContainer>
			)}
		</VStack>
	);
};
export default HelpDeclarationSourcePer;
