import { ResponsivePie } from '@nivo/pie';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MyResponsivePie = ({
	data,
	isInteractive,
	...rest
}: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[x: string]: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any[];
	isInteractive?: boolean;
}): JSX.Element => (
	<ResponsivePie
		isInteractive={isInteractive}
		data={data}
		enableArcLabels={false}
		enableArcLinkLabels={false}
		innerRadius={0.85}
		colors={data.map((d) => d.color)}
		{...rest}
	/>
);

export default MyResponsivePie;
