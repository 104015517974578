const affiliatesListCardStatusValue = {
	// AffiliatesListCardStatusEnum.SEND
	'Invitation envoyée': {
		label: 'Invitation envoyée',
		colorIcon: 'blue.900',
	},
	// AffiliatesListCardStatusEnum.PROGRESS
	'Souscription en cours': {
		label: 'Souscription en cours',
		colorIcon: 'warning.900',
	},
	// AffiliatesListCardStatusEnum.VALIDATED
	'Parrainé avec succès': {
		label: 'Parrainé avec succès',
		colorIcon: 'positive.900',
	},
};

export default affiliatesListCardStatusValue;
