import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { FormControl, FormErrorMessage, FormLabel, Heading, Input, Text, VStack } from '@chakra-ui/react';

import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import { useGetKYCQuery, useUpdateKYCNifMutation } from 'services/requests/kyc';

const Reglementaire = (): JSX.Element => {
	const navigate = useNavigate();
	const toast = useThemedToast();
	const { id } = useParams();

	const { data: kyc } = useGetKYCQuery();
	const [triggerUpdateKycNif, { isLoading }] = useUpdateKYCNifMutation();

	const {
		formState: { errors },
		register,
		setValue,
		handleSubmit,
	} = useForm<{ nif: string }>({
		defaultValues: {
			nif: kyc?.nif,
		},
	});

	useEffect(() => {
		if (kyc?.nif) setValue('nif', kyc.nif);
	}, [kyc?.nif, setValue]);

	const onNext = handleSubmit((body) => {
		triggerUpdateKycNif({ nif: body.nif })
			.unwrap()
			.then(() => {
				navigate(`/cash/onboarding/${id}/souscription/justificatifs`);
			})
			.catch(() => {
				toast({
					title: 'Une erreur est survenue',
					status: 'error',
					isClosable: true,
					duration: 3000,
				});
			});
	});

	return (
		<VStack align="start" spacing="24px">
			<Heading variant="Title-XL-Bold">Informations réglementaires</Heading>
			<Text variant="Text-M-Regular" color="grey.900">
				Ces données sont confidentielles et nécessaires vis-à-vis de nos obligations réglementaires et en particulier
				concernant la lutte contre le blanchiment. Elles nous permettent, par ailleurs, de valider la bonne adéquation
				de votre souscription avec votre patrimoine et votre bonne information.
			</Text>

			<FormControl isInvalid={!!errors.nif}>
				<FormLabel>Quel est votre numéro d’identification fiscale (NIF) ?</FormLabel>

				<Input
					w={onboardingInputWidth}
					{...register('nif', {
						required: true,
						minLength: 13,
						maxLength: 13,
						pattern: {
							value: /^(\d*)$/,
							message: "Le NIF ne peut contenir que des chiffres, et ne doit pas contenir d'espaces",
						},
					})}
					placeholder="Numéro fiscal de référence à 13 chiffres"
				/>

				{errors.nif?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
				{errors.nif?.type === 'min' && <FormErrorMessage>Le NIF ne peut pas être négatif</FormErrorMessage>}
				{(errors.nif?.type === 'minLength' || errors.nif?.type === 'maxLength') && (
					<FormErrorMessage>Le NIF est composé de 13 chiffres</FormErrorMessage>
				)}
				{errors.nif?.type === 'pattern' && <FormErrorMessage>{errors.nif.message}</FormErrorMessage>}
			</FormControl>

			<BottomStepBar
				onPrev={() => navigate(`/cash/onboarding/${id}/souscription`)}
				onNext={onNext}
				isNextLoading={isLoading}
				showNext
			/>
		</VStack>
	);
};
export default Reglementaire;
