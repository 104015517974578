import React, { ChangeEvent, LegacyRef, useState } from 'react';
import { Input, InputProps } from '@chakra-ui/react';

import LabelWrapperInput from './LabelWrapperInput';

type DatePickerProps = {
	placeholder?: string;
	label?: string;
	onChangeText?: (text?: string) => void;
};

const splitdate = (date: string): string[] => {
	const concateDate = date.replace(/[/]/gm, '');
	return [concateDate.slice(0, 2), concateDate.slice(2, 4), concateDate.slice(4, 8)].filter((x) => x !== '');
};

const onInputChange = (date: string, previousDate: string, a: ChangeEvent<HTMLInputElement>): string | undefined => {
	if (date.match(/^[0-9/]+$/gm) === null && date.length !== 0) return undefined;
	const cursorPlace = a.target.selectionStart;
	const splitDate = splitdate(date);
	let newDate = '';

	splitDate.forEach((value, index) => {
		newDate = newDate.concat(value);
		if (index < 2 && value.length === 2) newDate = newDate.concat('/');
	});
	if (newDate[newDate.length - 1] === '/' || date.length < previousDate.length || date.length - 1 !== cursorPlace)
		setTimeout(() => a.target.setSelectionRange(cursorPlace, cursorPlace), 25);
	return newDate;
};

const CustomDateImput = React.forwardRef(
	(
		{ placeholder, label, onChange, onChangeText, ...rest }: DatePickerProps & InputProps,
		ref?: LegacyRef<HTMLInputElement>,
	): JSX.Element => {
		const [dateInput, setDateInput] = useState<string>();
		return (
			<LabelWrapperInput label={label}>
				<Input
					ref={ref}
					placeholder="JJ / MM / AAAA"
					value={dateInput}
					{...rest}
					onChange={(e) => {
						const newdate = onInputChange(e.target.value, dateInput ?? '', e);
						if (newdate !== undefined) setDateInput(newdate);
						if (onChange) onChange(e);
						if (onChangeText) onChangeText(newdate);
					}}
				/>
			</LabelWrapperInput>
		);
	},
);

export default CustomDateImput;
