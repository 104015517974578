import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Idea } from '@carbon/icons-react';
import {
	Card,
	Center,
	chakra,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Select,
	Text,
	useDisclosure,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import { MentionUC } from 'components/compliance/MentionUC';
import CustomTooltip from 'components/CustomTooltip';
import NumberInput from 'components/inputs/NumberInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import ConfirmationModal from 'components/project/ConfirmationModal';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { OnboardingCreateStepProps } from 'onboarding/Stepper/stepper.slice';
import { CreateInvestSimulation } from 'services/requests/invest/simulation';
import colors from 'theme/foundations/colors';

import { objectifLabels } from './projectData';
import ProjectGraphData from './ProjectGraphData';
import { tooltipObjective } from './TooltipObjective';

const ProjectObjective = ({
	object: onboardingObject,
	onNext,
}: OnboardingCreateStepProps<CreateInvestSimulation>): JSX.Element => {
	const {
		isOpen: isOverwriteModalOpen,
		onClose: onOverwriteModalClose,
		onOpen: onOverwriteModalOpen,
	} = useDisclosure();
	const [dataSaved, setDataToConfirm] = useState<CreateInvestSimulation>();

	const {
		handleSubmit,
		control,
		formState: { errors },
		register,
		setValue,
		watch,
	} = useForm<CreateInvestSimulation>({
		mode: 'onChange',
		defaultValues: {
			saving: 250,
		},
	});
	const [saving, initialAUM, type] = watch(['saving', 'initialAUM', 'type']);

	const onSubmit = handleSubmit((data: CreateInvestSimulation) => {
		const dto = {
			saving: +data.saving,
			initialAUM: +data.initialAUM,
			type: data.type,
		};
		// if project type changed, display overwrite modal
		if (!!onboardingObject && onboardingObject.type !== dto.type) {
			setDataToConfirm(dto);
			onOverwriteModalOpen();
		} else {
			onNext(dto);
		}
	});

	// if object exists, fill the form with the simulation data
	useEffect(() => {
		if (onboardingObject) {
			setValue('saving', onboardingObject.saving);
			setValue('initialAUM', onboardingObject.initialAUM);
			setValue('type', onboardingObject.type);
		}
	}, [onboardingObject, setValue]);

	return (
		<VStack spacing={{ base: '32px', md: '40px', lg: '48px' }} align="start" w="100%">
			{dataSaved && (
				<ConfirmationModal
					isOpen={isOverwriteModalOpen}
					onConfirm={() => onNext(dataSaved)}
					onClose={onOverwriteModalClose}
				/>
			)}
			<VStack w="100%" align="start" spacing={{ base: '8px', md: '16px', lg: '24px' }}>
				<Heading variant="Title-XL-Bold">Mon Projet</Heading>
				<Text variant="Text-M-Regular" color="grey.900">
					Quelques questions pour nous aider à construire votre stratégie d’investissement. Plus vos réponses reflètent
					votre situation réelle, plus nos conseils seront pertinents.
				</Text>
			</VStack>

			<chakra.form onSubmit={onSubmit} w="100%">
				<VStack spacing="24px" align="start">
					<Wrap w="100%" spacing="24px">
						<WrapItem w={onboardingInputWidth}>
							<FormControl isInvalid={!!errors.type}>
								<FormLabel>
									<HStack>
										<>Quel est votre objectif ?</>
										<CustomTooltip text={tooltipObjective.type} />
									</HStack>
								</FormLabel>

								<Select {...register('type', { required: true })} placeholder="Choisissez un objectif">
									{objectifLabels.map((o) => (
										<option value={o.value} key={o.key}>
											{o.label}
										</option>
									))}
								</Select>
								{errors.type?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
							</FormControl>
						</WrapItem>

						{/* Ask for initialAUM */}
						<WrapItem w={onboardingInputWidth}>
							<FormControl isInvalid={!!errors.initialAUM}>
								{/* <FormLabel>Quel est votre apport initial ?</FormLabel> */}
								<FormLabel>
									<HStack>
										<>Quel est votre apport initial ?</>
										<CustomTooltip text={tooltipObjective.initialAUM} />
									</HStack>
								</FormLabel>

								<Controller
									name="initialAUM"
									control={control}
									rules={{ required: true, min: 1000 }}
									render={({ field }) => <NumberInput {...field} placeholder="10 000 €" suffix=" €" />}
								/>
								{errors.initialAUM?.type === 'required' && <FormErrorMessage children="Ce champ est nécessaire" />}
								{errors.initialAUM?.type === 'min' && (
									<FormErrorMessage children="L'ouverture d’un compte Ramify nécessite un apport initial minimum de 1000 euros" />
								)}
								{errors.initialAUM?.type === 'validate' && <FormErrorMessage children="Error validate" />}
							</FormControl>
						</WrapItem>
					</Wrap>

					{/* Recurring deposits */}
					{initialAUM && type && !errors.initialAUM && !errors.type && (
						<VStack w="100%" align="start" spacing="16px">
							<FormControl isInvalid={!!errors.saving} w={onboardingInputWidth}>
								{/* <FormLabel>Combien souhaitez-vous investir chaque mois ?</FormLabel> */}
								<FormLabel>
									<HStack>
										<>Combien souhaitez-vous investir chaque mois ?</>
										<CustomTooltip text={tooltipObjective.saving} />
									</HStack>
								</FormLabel>

								<Controller
									name="saving"
									control={control}
									rules={{ required: true, validate: (value) => value === 0 || value >= 100 }}
									render={({ field }) => (
										<VStack w="100%" align="start">
											<NumberInput {...field} placeholder="10 000 €" suffix=" €" />
											<Checkbox
												size="lg"
												margin="5px"
												{...field}
												onChange={() => {
													const oldValue = field.value;
													if (oldValue === 0) field.onChange(100);
													else field.onChange(0);
												}}
												isChecked={field.value === 0}
												data-cy="cy-is-saving"
											>
												<Text variant="Text-S-Regular" color="grey.900">
													Je ne souhaite pas épargner mensuellement
												</Text>
											</Checkbox>
										</VStack>
									)}
								/>
								{errors.saving?.type === 'required' && <FormErrorMessage children="Ce champ est nécessaire" />}
								{errors.saving?.type === 'validate' && (
									<FormErrorMessage children="Le montant minimum pour investir mensuellement est de 100 euros" />
								)}
							</FormControl>
							<ProjectGraphData initialAUM={initialAUM} saving={saving} performance={0.07} />
							<Card size="sm">
								<HStack spacing="16px">
									<Center borderRadius="full" bg="blue.100" p="8px">
										<Idea size="24" color={colors.blue[900]} />
									</Center>
									<Text variant="Text-S-Regular">
										Les portfeuilles Ramify avec versements programmés ont délivré une performance 35 % supérieure aux
										portefeuilles sans versements programmés. Calcul basé sur les 12 mois de 2022.
									</Text>
								</HStack>
							</Card>
							<MentionUC />
						</VStack>
					)}
				</VStack>
			</chakra.form>
			<BottomStepBar showPrev={false} onNext={onSubmit} />
		</VStack>
	);
};

export default ProjectObjective;
