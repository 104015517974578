import { UpDownIcon } from '@chakra-ui/icons';
import {
	Box,
	HStack,
	Input,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Text,
	VStack,
} from '@chakra-ui/react';

import ErrorFieldMessage from 'components/inputs/ErrorMessage';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { CreateOVStepProps } from 'pages/Invest/Versements/CreateOptimizedVersement';
import colors from 'theme/foundations/colors';

import CardInfo from './CardInfo';

const SavingFields = ({
	register,
	errors,
	watch,
	setValue,
}: Pick<CreateOVStepProps, 'register' | 'errors' | 'watch' | 'setValue'>): JSX.Element => {
	const isMobile = useAppResponsive({ base: true, md: false });
	const [saving] = watch(['saving']);

	return (
		<VStack w="100%" spacing="16px">
			<VStack w="100%" align="start">
				<Text variant="Text-S-Medium">Quel montant souhaitez-vous investir chaque mois ?</Text>
				<VStack align="start" w="100%">
					<HStack w="100%" spacing="24px">
						<HStack>
							<Input
								{...register('saving', { valueAsNumber: true, required: true, min: 100 })}
								type="number"
								placeholder="250"
								w="80px"
							/>
							<Text fontWeight="600">€</Text>
						</HStack>
						<Slider
							min={100}
							max={1000}
							value={saving}
							focusThumbOnChange={false}
							onChange={(newValue) => setValue('saving', newValue)}
							onChangeEnd={(newValue) => setValue('saving', newValue)}
							maxW="250px"
						>
							<SliderTrack>
								<SliderFilledTrack />
							</SliderTrack>
							<SliderThumb fontSize="sm" boxSize="24px" border={`1px solid ${colors.grey[300]} !important`}>
								<Box as={UpDownIcon} transform="rotate(90deg)" />
							</SliderThumb>
						</Slider>
					</HStack>
					{errors.saving?.type === 'required' && <ErrorFieldMessage children="Le montant minimum est de 100€" />}
					{errors.saving?.type === 'min' && <ErrorFieldMessage children="Le montant minimum est de 100€" />}
				</VStack>
			</VStack>
			{!isMobile && <CardInfo />}
		</VStack>
	);
};

export default SavingFields;
