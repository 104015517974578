import { motion } from 'framer-motion';

import colors from 'theme/foundations/colors';

const AnimatedBar = ({ width, color }: { width: string; color: string }): JSX.Element => (
	<motion.div
		style={{ background: colors.grey[300], height: '4px' }}
		animate={{ x: 0, width: ['0%', '100%'] }}
		transition={{ duration: 0.5 }}
	>
		<motion.div
			style={{ background: color, height: '4px' }}
			animate={{ x: 0, width: ['0%', width] }}
			transition={{ duration: 0.5 }}
		/>
	</motion.div>
);
export default AnimatedBar;
