import { Heading, VStack } from '@chakra-ui/react';

// Components
import BottomStepBar from 'components/steppers/BottomStepBar';
import { KycProduct } from 'onboarding/KYC';
import EtatCivilForm from 'onboarding/KYC/Forms/EtatCivilForm';
import { KycStepProps } from 'onboarding/KYC/utils';

const EtatCivil = ({ onNext, onPrev, product }: KycStepProps & { product: KycProduct }): JSX.Element => (
	<VStack w="100%" spacing="24px" align="left">
		<Heading variant="Title-XL-Bold">Mon état civil</Heading>

		<EtatCivilForm
			onSuccess={onNext}
			formConfirmation={<BottomStepBar onPrev={onPrev} showNext nextSubmit />}
			product={product}
		/>
	</VStack>
);

export default EtatCivil;
