import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

import StepperLayout from 'app/Layout/Onboarding/StepperLayout';
import FullscreenLoader from 'components/FullscreenLoader';
import { useAppDispatch } from 'hooks/useStore';
import useThemedToast from 'hooks/useThemedToast';
import { setLastStepAvailable } from 'onboarding/Stepper/stepper.slice';
import {
	CreateInvestSimulation,
	StrategyRepartition,
	useCreateInvestSimulationMutation,
	useDeleteSimulationMutation,
	useFinishSimulationMutation,
	useGetInvestSimulationQuery,
	useUpdateInvestSimulationObjectiveMutation,
} from 'services/requests/invest/simulation';
import { useGetKYCQuery } from 'services/requests/kyc';
import { isNone, isNotNone } from 'utils/functions';

import Fiscalite from '../Shared/Fiscalite';
import Project from '../Shared/Objective';
import Portfolio from '../Shared/Portfolio/Routes';
import { OnboardingObject } from '../Shared/type';

import ModalReset from './ModalReset';
import Result from './Result';
import steps, { fiscaliteStep, InvestOnboardingSteps, portfolioStep, projectStep, resultatsStep } from './steps';

const InvestSimulationRoutes = (): JSX.Element => {
	const [collapsed, setCollapsed] = useState(false);
	const dispatch = useAppDispatch();
	const toast = useThemedToast();
	const navigate = useNavigate();

	const { data: kyc, isLoading: isKYCLoading } = useGetKYCQuery();
	const {
		data: simulation,
		isLoading: isSimulationLoading,
		isFetching: isSimulationFetching,
	} = useGetInvestSimulationQuery();
	const [createSimulation] = useCreateInvestSimulationMutation();
	const [updateSimulation] = useUpdateInvestSimulationObjectiveMutation();
	const [finishResult] = useFinishSimulationMutation();
	const [deleteSimulation] = useDeleteSimulationMutation();

	// reset simulation modal
	const { state } = useLocation();
	const { isOpen: isModalResetOpen, onClose: onModalResetClose } = useDisclosure({
		defaultIsOpen: state?.eraseSimulation ?? false,
	});

	const onModalResetConfirm = useCallback(() => {
		deleteSimulation()
			.unwrap()
			.then(() => {
				onModalResetClose();
			});
	}, [deleteSimulation, onModalResetClose]);

	const mostAdvancedStep = useMemo(() => {
		if (isSimulationLoading || isKYCLoading) return undefined;

		if (isNone(simulation) || isNone(simulation.initialAUM) || isNone(simulation.type)) {
			return projectStep;
		}

		if (
			isNone(simulation.investmentPreferences) ||
			isNone(simulation.investmentPreferences.esg) ||
			isNone(simulation.investmentPreferences.risk) ||
			isNone(simulation.investmentPreferences.recommandedRisk) ||
			isNone(simulation.investmentPreferences.portfolioType)
		) {
			return portfolioStep;
		}

		if (isNone(kyc) || isNone(kyc.isPoliticallyExposed) || isNone(kyc.isUSPerson) || isNone(simulation.envelope)) {
			return fiscaliteStep;
		}

		return resultatsStep;
	}, [isKYCLoading, isSimulationLoading, kyc, simulation]);

	const isAtLeastAtStep = useCallback(
		(step: InvestOnboardingSteps) => {
			if (isNone(mostAdvancedStep)) return false;

			const indexActualStep = steps.findIndex((s) => s.id === mostAdvancedStep.id);
			const indexDesiredStep = steps.findIndex((s) => s.id === step);
			return indexActualStep >= indexDesiredStep;
		},
		[mostAdvancedStep],
	);

	useEffect(() => {
		if (isNotNone(mostAdvancedStep)) dispatch(setLastStepAvailable(mostAdvancedStep.id));
	}, [mostAdvancedStep, dispatch]);

	// route guard that prevents the user from going further than he completed
	const completedStepGuard = useCallback(
		(step: InvestOnboardingSteps) => {
			if (isSimulationLoading || isKYCLoading || isNone(mostAdvancedStep)) return <FullscreenLoader />;

			// if user is authorized to access the step, we let him go
			if (isAtLeastAtStep(step)) return <Outlet />;

			// else we redirect him to the most advanced step he can access
			return <Navigate to={mostAdvancedStep.url} replace />;
		},
		[isAtLeastAtStep, isSimulationLoading, isKYCLoading, mostAdvancedStep],
	);

	// route guard that prevents the user from going further if simulation doesn't exist
	const simulationExistsGuard = useCallback(() => {
		if (isNotNone(simulation)) return <Outlet />;
		if (isSimulationFetching) return <FullscreenLoader />;
		return <Navigate to={steps[0].url} replace />;
	}, [isSimulationFetching, simulation]);

	const projectNext = useCallback(
		(data: CreateInvestSimulation) => {
			(simulation ? updateSimulation(data) : createSimulation(data))
				.unwrap()
				.then(() => {
					navigate(portfolioStep.url);
				})
				.catch(() => {
					toast({
						title: 'Une erreur est survenue.',
						description: "Nous n'avons pas pu valider vos informations.",
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				});
		},
		[createSimulation, navigate, simulation, toast, updateSimulation],
	);

	const portfolioNext = useCallback(() => {
		navigate(fiscaliteStep.url);
	}, [navigate]);

	const fiscaliteNext = useCallback(() => {
		navigate(resultatsStep.url);
	}, [navigate]);

	const resultNext = useCallback(
		(data: StrategyRepartition) => {
			finishResult({
				amountAV: data.amountAV,
				amountPER: data.amountPER,
				initialAmountAV: data.initialAmountAV,
				initialAmountPER: data.initialAmountPER,
			})
				.unwrap()
				.then((createdSubscriptions) => {
					navigate(
						createdSubscriptions.length === 1 ? `/invest/souscription/${createdSubscriptions[0].id}` : '/souscriptions',
					);
				})
				.catch(() => {
					toast({
						title: 'Une erreur est survenue',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				});
		},
		[finishResult, navigate, toast],
	);

	const onboardingObject = useMemo<OnboardingObject | undefined>(() => {
		if (!simulation) return undefined;
		return {
			id: simulation.id,
			table: 'investSimulation',
			initialAUM: simulation.initialAUM,
			type: simulation.type,
			investmentPreferences: simulation.investmentPreferences,
			saving: simulation.saving,
			extraData: simulation.extraData,
			timeHorizon: simulation.timeHorizon,
		};
	}, [simulation]);

	if (isSimulationLoading || isKYCLoading) return <FullscreenLoader />;

	return (
		<>
			<Routes>
				<Route
					element={
						<StepperLayout
							headerTitle="Simulation Invest"
							steps={steps}
							basePath="/onboarding"
							collapsed={collapsed}
							setCollapsed={setCollapsed}
						/>
					}
				>
					<Route path={`${projectStep.url}/*`} element={<Project object={onboardingObject} onNext={projectNext} />} />

					<Route element={simulationExistsGuard()}>
						<Route element={completedStepGuard(projectStep.id)}>
							<Route
								path={`${portfolioStep.url}/*`}
								element={<Portfolio object={onboardingObject!} onNext={portfolioNext} />}
							/>
						</Route>

						<Route element={completedStepGuard(portfolioStep.id)}>
							<Route
								path={`${fiscaliteStep.url}/*`}
								element={<Fiscalite object={simulation!} flow="simulation" onNext={fiscaliteNext} />}
							/>
						</Route>

						<Route element={completedStepGuard(fiscaliteStep.id)}>
							<Route
								path={`${resultatsStep.url}/*`}
								element={<Result simulation={simulation!} onNext={resultNext} />}
							/>
						</Route>
					</Route>
				</Route>
				{mostAdvancedStep && <Route path="*" element={<Navigate to={mostAdvancedStep.url} replace />} />}
			</Routes>

			<ModalReset isOpen={isModalResetOpen} onClose={onModalResetClose} onReset={onModalResetConfirm} />
		</>
	);
};

export default InvestSimulationRoutes;
