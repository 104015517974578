import { backendApi } from 'services/apiService';
import { VehiculeCodeApicil } from 'store/types/global.type';
import { ApicilVersementWithRepartition } from 'utils/api';

export type VersementId = number;
export type RequestVersementType = 'SUPPRESSION_PROGRAMME' | 'COMPLEMENTAIRE' | 'PROGRAMME';

export enum ApicilFundOrigins {
	'autresPlacements' = 'ofAutresPlacements',
	'biensMobiliers' = 'ofBiensMobiliers',
	'cessionActifs' = 'ofCessionActifs',
	'revenusPro' = 'ofRevenusPro',
	'successionDonation' = 'ofSuccessionDonation',
	'venteImmobiliere' = 'ofVenteImmobiliere',
}

type SignVersementRequest = {
	versementId: number;
	otp: string;
};

type CreateVPRequest = {
	contratId: string;
	montant: number;
	bankInfoId: string;
	programmed: boolean;
	origineDesFonds?: ApicilFundOrigins;
};

type VersementEligibilityRequest = {
	contractId: string;
	type: RequestVersementType;
};

export enum VersementEligibility {
	ELIGIBLE = 'ELIGIBLE',
	KYC_OUTDATED = 'KYC_OUTDATED',
	RESILIATION_PERIOD = 'RESILIATION_PERIOD',
	CUSTOM_PORTFOLIO = 'CUSTOM_PORTFOLIO',
	OPERATION_PENDING = 'OPERATION_PENDING',
	APICIL_INELIGIBLE = 'APICIL_INELIGIBLE',
}

export type VersementEligibilityResponse = {
	eligibility: VersementEligibility;
};

// todo: create endpoint to call {apicil}/versement/contrat/{id}/montantminmax
export const versementMinMax = {
	P2: {
		max: 9999999999.99,
		minInitial: 1000,
		minVC: 300,
		minVP: { mensuel: 100, trimestriel: 300, semestriel: 600, annuel: 600 },
	},
	IL: {
		max: 9999999999.99,
		minInitial: 1000,
		minVC: 150,
		minVP: { mensuel: 100, trimestriel: 100, semestriel: 200, annuel: 400 },
	},
	SV09: {
		max: 9999999999.99,
		minInitial: 20000,
		minVC: 5000,
		minVP: { mensuel: 300, trimestriel: 1000, semestriel: 2000, annuel: 3000 },
	},
} satisfies Record<
	VehiculeCodeApicil,
	{
		max: number;
		minInitial: number;
		minVC: number;
		minVP: { mensuel: number; trimestriel: number; semestriel: number; annuel: number };
	}
>;

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		signVersement: builder.mutation<void, SignVersementRequest>({
			query: (body) => ({
				url: '/versement/sign',
				method: 'POST',
				body,
				timeout: 5 * 60 * 1000,
			}),
			invalidatesTags: ['Versement', 'Operations'],
		}),
		createVersement: builder.mutation<ApicilVersementWithRepartition, CreateVPRequest>({
			query: (body) => ({
				url: '/versement',
				method: 'POST',
				body: body,
				timeout: 10 * 60 * 1000,
			}),
			invalidatesTags: ['Versement'],
		}),
		deleteVP: builder.mutation<VersementId, string>({
			query: (contractId) => ({
				url: `/versement/VP/${contractId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Versement'],
		}),
		getPerLimit: builder.query<number, void>({
			query: () => '/versement/VC/perLimit',
			providesTags: ['Versement'],
		}),
		isVersementEligible: builder.query<VersementEligibilityResponse, VersementEligibilityRequest>({
			query: (body) => ({
				url: '/versement/eligible',
				method: 'POST',
				body,
			}),
			providesTags: [
				'Versement',
				'KYC', // we want to invalidate this cache when user updates KYC from <UpdateKycForm hasChanged={false} />
			],
		}),
	}),
});

export const {
	useSignVersementMutation,
	useCreateVersementMutation,
	useDeleteVPMutation,
	useGetPerLimitQuery,
	useIsVersementEligibleQuery,
	useLazyIsVersementEligibleQuery,
} = extendedApi;
