import { useNavigate } from 'react-router-dom';
import { EventSchedule } from '@carbon/icons-react';
import { Card, Heading, HStack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import InvestmentMetricCard from 'components/Metrics/InvestmentMetrics/Card';
import { PopulatedInvestContract } from 'store/types/contract.type';
import colors from 'theme/foundations/colors';
import { displayMoney, formatDateStandart } from 'utils/rendering';

import ContractTypeCard from './ContractTypeCard';

const ContractValue = ({ contractValue, contractPerf }: { contractValue: number; contractPerf: number }) => (
	<VStack w="100%" align="start">
		<Text variant="Text-M-Medium">Valeur du contrat</Text>
		<HStack w="100%">
			<Heading variant="Title-L-Bold">{displayMoney(contractValue)}</Heading>
			<Heading variant="Title-S-Bold" color={contractPerf >= 0 ? colors.positive[900] : colors.negative[900]}>
				({displayMoney(contractPerf)})
			</Heading>
		</HStack>
	</VStack>
);

const ContractCard = ({ contract }: { contract: PopulatedInvestContract }) => {
	const navigate = useNavigate();
	// Don't compute performances if there is no money in the contract (important !)
	const contractPerf = +contract.amount && contract.totalVersement ? +contract.amount - +contract.totalVersement : 0;
	const vp = contract.programmedVersement ? +contract.programmedVersement.amount : 0;

	return (
		<Card
			key={contract.id}
			w="100%"
			_hover={{ cursor: 'pointer', borderColor: colors.grey[700] }}
			onClick={() => {
				navigate(contract.id, { preventScrollReset: true });
			}}
		>
			<VStack w="100%" align="start" spacing="40px">
				<Wrap w="100%" justify="space-between">
					<WrapItem>
						<VStack w="100%" spacing="8px" align="start">
							<Heading variant="Title-M-Bold">
								{contract.envelope.type === 'AV' ? 'Assurance Vie' : 'PER individuel'}
							</Heading>
							<Text variant="Text-M-Medium">Ouvert le {formatDateStandart(contract.createdAt)}</Text>
						</VStack>
					</WrapItem>
					<WrapItem>
						<ContractValue contractValue={+contract.amount} contractPerf={contractPerf} />
					</WrapItem>
				</Wrap>

				<Wrap w="100%">
					<WrapItem minW="250px" flex="1">
						<ContractTypeCard
							risque={contract.investmentPreferences!.risk!}
							portfolioType={contract.investmentPreferences!.portfolioType!}
							temperature={contract.investmentPreferences!.temperature!}
						/>
					</WrapItem>
					<WrapItem minW="250px" flex="1">
						<InvestmentMetricCard
							icon={<EventSchedule size="32" />}
							title={vp ? 'Versement programmé' : 'Aucun versement programmé'}
						>
							{vp && <Heading variant="Title-L-Bold">{displayMoney(vp)}/mois</Heading>}
						</InvestmentMetricCard>
					</WrapItem>
					<WrapItem minW="200px">
						<ArrowButton variant="tertiary" alignSelf="end">
							Voir les détails
						</ArrowButton>
					</WrapItem>
				</Wrap>
			</VStack>
		</Card>
	);
};
export default ContractCard;
