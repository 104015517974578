import { Skeleton, VStack } from '@chakra-ui/react';

import AirtableBanner from 'components/Banners/AirtableBanner';
import { useGetOfferBlackQuery } from 'services/requests/communication';

import HeaderOffreBlack from './sections/HeaderOffreBlack';
import MonthlyOffreBlack from './sections/MonthlyOfferBlack';
import ServicesProductsOffreBlack from './sections/ServicesProductsOffreBlack';

export type MonthlyBlackCardType = {
	image: string;
	partnerName: string;
	fundName: string;
	description: string;
	valableUntil: Date;
	geo: string;
	strategy: string;
	triCible: string;
	secteur: string;
	link: string;
	show: boolean;
};

const BlackMember = (): JSX.Element => {
	const { data, isLoading } = useGetOfferBlackQuery();

	return (
		<VStack w="100%" py="56px" spacing="72px">
			<HeaderOffreBlack />
			<AirtableBanner />
			{isLoading && <Skeleton height="545px" width="100%" />}
			{data && <MonthlyOffreBlack fundInfos={data} />}
			<ServicesProductsOffreBlack />
		</VStack>
	);
};
export default BlackMember;
