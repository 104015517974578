import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	Box,
	Card,
	Center,
	HStack,
	Slider,
	SliderFilledTrack,
	SliderMark,
	SliderThumb,
	SliderTrack,
	Text,
	VStack,
} from '@chakra-ui/react';

import { RiskSliderMark } from 'components/Graphs/SliderMark';
import { useRiskDisplay } from 'hooks/useRiskDisplay';
import colors from 'theme/foundations/colors';
import { riskMax, riskMin } from 'utils/rendering/riskDisplay';

import { RiskSliderButton } from './SliderButton';

const RiskSlider = ({
	value,
	onChange,
	recommandedRisk,
	step = 0.1,
}: {
	value: number;
	onChange: (value: number) => void;
	recommandedRisk?: number | undefined;
	step?: number;
}) => {
	const showRecommandedRisk = recommandedRisk !== undefined;

	const dRiskLow = useRiskDisplay(2.5);
	const dRiskMedium = useRiskDisplay(5.5);
	const dRiskHigh = useRiskDisplay(8.5);
	const dRiskActual = useRiskDisplay(value);
	const [posTriangle, setPosTriangle] = useState(0);

	const riskLow = useRef() as React.MutableRefObject<HTMLButtonElement>;
	const riskMedium = useRef() as React.MutableRefObject<HTMLButtonElement>;
	const riskHigh = useRef() as React.MutableRefObject<HTMLButtonElement>;

	const sliderThumbRectRef = useRef() as React.MutableRefObject<HTMLDivElement>;

	const handleTextArrowPos = useCallback(() => {
		if (riskLow.current == null || riskMedium.current == null || riskHigh.current == null) return;

		const parentElement = sliderThumbRectRef.current.offsetParent as HTMLDivElement;

		if (value >= dRiskLow.min && value <= dRiskLow.max) {
			setPosTriangle(riskLow.current.offsetLeft + riskLow.current.offsetWidth / 2 - parentElement.offsetLeft);
		}
		if (value > dRiskMedium.min && value <= dRiskMedium.max) {
			setPosTriangle(riskMedium.current.offsetLeft + riskMedium.current.offsetWidth / 2 - parentElement.offsetLeft);
		}
		if (value > dRiskHigh.min && value <= dRiskHigh.max) {
			setPosTriangle(riskHigh.current.offsetLeft + riskHigh.current.offsetWidth / 2 - parentElement.offsetLeft);
		}
	}, [value, riskLow, riskMedium, riskHigh, dRiskLow, dRiskMedium, dRiskHigh, sliderThumbRectRef]);

	useEffect(() => {
		handleTextArrowPos();
		window.addEventListener('resize', handleTextArrowPos, false);
		return () => window.removeEventListener('resize', handleTextArrowPos, false);
	}, [dRiskActual, handleTextArrowPos]);

	return (
		<VStack align="center" spacing="24px" w="100%">
			{showRecommandedRisk && (
				<Text
					cursor="pointer"
					onClick={() => onChange(recommandedRisk)}
					pb={5}
					alignSelf="start"
					as="u"
					color="blue.900"
				>
					Conseil Ramify : risque{' '}
					{recommandedRisk.toLocaleString('fr-FR', { minimumFractionDigits: recommandedRisk === 10 ? 0 : 1 })}
				</Text>
			)}
			<VStack w="100%" align="flex-start" spacing="32px" position="relative">
				<VStack pt={6} w="100%" spacing="24px">
					<Slider
						w="100%"
						min={riskMin}
						max={riskMax}
						step={step}
						aria-label="advise-slider"
						value={value}
						data-cy="risk-slider"
						onChange={onChange}
					>
						<SliderTrack bg="grey.100" h={{ base: '12px', md: '6px' }} />
						{showRecommandedRisk && (
							<SliderMark h="48px" w="3px" mt="-24px" borderRadius="base" bgColor="blue.900" value={recommandedRisk} />
						)}
						<SliderMark
							mt="-72px"
							ml="-20px"
							value={value}
							h="42px"
							w="40px"
							border="1px"
							borderColor="grey.500"
							borderRadius="base"
							textAlign="center"
						>
							<Center w="100%" h="100%">
								<Text variant="Text-M-SemiBold">
									{value.toLocaleString('fr-FR', { minimumFractionDigits: value === 10 || step === 1 ? 0 : 1 })}
								</Text>
							</Center>
						</SliderMark>

						<SliderThumb
							ref={sliderThumbRectRef}
							w={{ base: 8, md: 6 }}
							h={{ base: 8, md: 6 }}
							bg={dRiskActual.value === recommandedRisk ? 'blue.900' : 'primary.black'}
							border="2px"
							borderColor="#fff"
							_focus={{ boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.25)` }}
						/>
						<SliderFilledTrack borderRadius="6px" h={{ base: '12px', md: '6px' }} bg="primary.black" />

						{/* La limite entre le risque "prudent" et "modéré" => 4,0 */}
						<RiskSliderMark value={dRiskMedium.min} />
						{/* La limite entre le risque "modéré" et "agressif" => 7,0 */}
						<RiskSliderMark value={dRiskMedium.max} />
					</Slider>
					<HStack w="100%" spacing={0}>
						{/* Boutons "Prudent", "Modéré", "Agressif" */}
						<RiskSliderButton
							ref={riskLow}
							selected={value >= dRiskLow.min && value <= dRiskLow.max}
							onClick={() => onChange(dRiskLow.value)}
							name={dRiskLow.name}
						/>
						<RiskSliderButton
							ref={riskMedium}
							selected={value > dRiskMedium.min && value <= dRiskMedium.max}
							onClick={() => onChange(dRiskMedium.value)}
							name={dRiskMedium.name}
						/>
						<RiskSliderButton
							ref={riskHigh}
							selected={value > dRiskHigh.min && value <= dRiskHigh.max}
							onClick={() => onChange(dRiskHigh.value)}
							name={dRiskHigh.name}
						/>
					</HStack>
				</VStack>
			</VStack>
			<Card position="relative" alignSelf="flex-start" w="100%" bg="primary.black" size="sm">
				<Box
					h={0}
					w={0}
					top="-13px"
					position="absolute"
					left={`${posTriangle - 12}px`}
					transition="0.3s all ease-in"
					borderLeft="18px solid transparent"
					borderRight="18px solid transparent"
					borderBottom={`14px solid ${colors.primary.black}`}
				/>
				<Text variant="Text-S-Regular" color="white">
					{dRiskActual.description}
				</Text>
			</Card>
		</VStack>
	);
};

export default RiskSlider;
