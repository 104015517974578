import { Skeleton } from '@chakra-ui/react';

import { useGetUserQuery } from 'services/requests/user';

import BlackMember from './BlackMember';
import DiscoverPageOffreBlack from './DiscoverBlack';

const OffreBlack = (): JSX.Element => {
	const { data: user, isLoading } = useGetUserQuery();

	const isBlack = user?.isBlack ?? false;
	if (isLoading) return <Skeleton height="100vh" width="100vw" />;

	return isBlack ? <BlackMember /> : <DiscoverPageOffreBlack />;
};
export default OffreBlack;
