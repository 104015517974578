import { useCallback, useState } from 'react';
import { Heading, HStack, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react';

import InformationBanner from 'components/Banners/InformationBanner';
import DropZone from 'components/inputs/Dropzone';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import { SouscriptionStepProps } from 'onboarding/Stepper/stepper.slice';
import { UpdateInvestSubscriptionDeduction } from 'services/requests/invest/subscription';
import { useUpdateKYCJustificatifMutation } from 'services/requests/kyc';
import { RepresentativeType } from 'store/types/subscription.type';

const Deductibilite = ({
	subscription,
	onNext,
	onPrev,
}: SouscriptionStepProps<UpdateInvestSubscriptionDeduction>): JSX.Element => {
	const [representing, setRepresenting] = useState<RepresentativeType>(subscription.representativeType);
	const [formDataKbis, setFormDataKbis] = useState<FormData>(); // todo add default or check if pj was already uploaded
	const [kbisUpload, setKbisUpload] = useState<Blob | null>(null);
	const [triggerUpdateEtatCivil] = useUpdateKYCJustificatifMutation();
	const toast = useThemedToast();

	const onSubmit = useCallback(() => {
		if (representing === RepresentativeType.PROFESSIONAL) {
			if (!formDataKbis) {
				toast({
					title: 'Veuillez téléverser un justificatif',
					status: 'error',
					duration: 4000,
					isClosable: true,
				});
				return;
			} else {
				triggerUpdateEtatCivil(formDataKbis);
			}
		}
		onNext({ representativeType: representing });
	}, [formDataKbis, onNext, representing, toast, triggerUpdateEtatCivil]);

	return (
		<VStack align="start" spacing="48px" padding={{ base: '0', sm: '0' }} w="100%">
			<VStack align="start" spacing="24px" w="100%">
				<Heading variant="bigTitle">Déductibilité</Heading>
				<InformationBanner description="En tant que travailleur non salarié, vous pouvez si vous le souhaitez souscrire à titre professionnel et bénéficier de la fiscalité entreprise (article 154 bis)." />
				<VStack spacing="16px" w="100%" align="start">
					<HStack>
						<Text variant="Text-S-Regular">À quel titre souhaitez-vous souscrire à un PER ?</Text>
					</HStack>
					<HStack align="start" w="100%">
						<RadioGroup
							variant="single"
							size="sm"
							onChange={(value) => {
								setRepresenting(value as RepresentativeType);
							}}
							value={representing}
						>
							<VStack spacing="8px" w={{ base: '100%', md: '380px' }}>
								<Radio value="INDIVIDUAL" key="INDIVIDUAL">
									À titre personnel
								</Radio>

								<Radio value="PROFESSIONAL" key="PROFESSIONAL">
									À titre professionel
								</Radio>
							</VStack>
						</RadioGroup>
					</HStack>
				</VStack>

				{representing === RepresentativeType.PROFESSIONAL && (
					<>
						<Text variant="title">Justificatif de KBIS</Text>
						<Text variant="body">
							Afin de souscrire à titre professionnel, nous avons besoin de votre justificatif de KBIS.
						</Text>
						<DropZone
							filesUploaded={!!kbisUpload}
							onDrop={(files) => {
								const formData = new FormData();
								formData.append('files', files[0]);
								formData.append('libelle', 'KBIS');
								formData.append('code', 'Justificatif_Kbis');
								setFormDataKbis(formData);
								setKbisUpload(files[0]);
							}}
						/>
					</>
				)}
			</VStack>

			<BottomStepBar onNext={onSubmit} onPrev={onPrev} />
		</VStack>
	);
};

export default Deductibilite;
