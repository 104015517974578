import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '@carbon/icons-react';
import { Box, Center, HStack, Menu, MenuButton, MenuList, Text, VStack } from '@chakra-ui/react';

import { LogoutIcon } from 'assets/icons/customIcons';
import { useAppDispatch } from 'hooks/useStore';
import { useGetUserQuery } from 'services/requests/user';
import { logout } from 'store/auth.slice';
import colors from 'theme/foundations/colors';

export type TopBarMenuProps = {
	show?: boolean;
};

const TopBarMenu: FC<TopBarMenuProps> = ({ show = true }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { data: userData } = useGetUserQuery();

	const onDisconnect = () => {
		dispatch(logout());
		// This is important: logout clear api cache and navigating prevents hooks in pages from refetching one more time
		navigate('/connexion');
	};

	if (!show) return null;

	return (
		// Wrapping in Box to fix a small chakra UI issue
		// https://github.com/chakra-ui/chakra-ui/issues/3173
		<Box display="flex">
			<Menu gutter={40}>
				<MenuButton border="0">
					<Center w="40px" h="40px" bg="grey.300" border="0" borderRadius="50%">
						<User size={24} color={colors.grey[700]} />
					</Center>
				</MenuButton>
				<MenuList
					p="0px !important"
					borderRadius="base"
					border="1px solid #e6e6e6"
					boxShadow="base"
					maxW="340px"
					overflow="hidden"
				>
					{userData && (
						<VStack align="start" w="100%" py="16px" px="24px">
							<Text variant="Text-S-Regular" color="grey.900">
								Connecté avec :
							</Text>
							<Text variant="Text-M-Bold" color="primary.black">
								{userData?.email}
							</Text>
						</VStack>
					)}
					<HStack
						cursor="pointer"
						align="center"
						justify="space-between"
						w="100%"
						py="16px"
						px="24px"
						bg="grey.300"
						color="grey.900"
						_hover={{ bg: 'grey.900', color: 'white' }}
						onClick={onDisconnect}
					>
						<Text color="inherit" variant="Text-S-Medium" onClick={onDisconnect}>
							Me déconnecter
						</Text>
						<LogoutIcon />
					</HStack>
				</MenuList>
			</Menu>
		</Box>
	);
};

export default TopBarMenu;
