import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Box,
	Card,
	Center,
	Heading,
	HStack,
	Image,
	ListItem,
	Spinner,
	Text,
	UnorderedList,
	VStack,
} from '@chakra-ui/react';

import LeParticulierLogo from 'assets/logoPartners/le-particulier-logo.png';
import NovaxiaLogo from 'assets/logoPartners/novaxia-logo.png';
import RealEstateCard from 'components/cards/funds/RealEstateCard';
import { QAViewer } from 'components/faq/FAQ';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useGetSCPIFundByNameQuery } from 'services/requests/realEstate/funds';

const ScpiProject = (): JSX.Element => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const { subId } = useParams<{ subId: string }>();
	const { data: fund, isError } = useGetSCPIFundByNameQuery('Novaxia Neo');

	useEffect(() => {
		if (isError) toast({ title: 'Une erreur est survenue', status: 'error', duration: 4500, isClosable: true });
	}, [isError, toast]);

	useEffect(() => {
		eventTracker.mixpanel.track('Phoenix Onboarding - Introduction');
	}, []);

	if (!fund)
		return (
			<Center>
				<Spinner />
			</Center>
		);

	return (
		<VStack w="100%" align="start" spacing="40px">
			<Heading variant="Title-L-Bold">Investissement en immobilier - SCPI</Heading>
			<QAViewer
				question={<Text variant="Text-M-Bold">Qu’est-ce qu'une SCPI ?</Text>}
				answer={
					<Text>
						Une Société Civile de Placement Immobilier (SCPI) est un organisme de placement collectif qui rassemble des
						fonds apportés par des investisseurs en vue d’acquérir et de gérer un patrimoine immobilier. En
						contrepartie, les épargnants reçoivent des parts sociales qui ne sont pas cotées en Bourse.
					</Text>
				}
			/>

			<VStack spacing="24px" align="start">
				<VStack align="start" spacing="8px">
					<Heading variant="Title-M-SemiBold">Fonds Immobilier (SCPI) séléctionné - Novaxia Neo</Heading>
					<Text>
						Chaque mois, votre trésorerie d’entreprise sera investie en SCPI, un actif{' '}
						<b>physique résililent et peu volatile.</b>
					</Text>
				</VStack>
				<HStack spacing="24px" align="start">
					<VStack flex="1" spacing="24px" align="start">
						<Card w="100%">
							<HStack pb="16px">
								<Image src={NovaxiaLogo} h="60px" />
								<Image src={LeParticulierLogo} h="60px" />
							</HStack>
							<Text>
								Société de gestion des SCPI innovante. En savoir plus sur{' '}
								<Text as="a" href="https://www.novaxia.fr/" textDecoration="underline" fontWeight="bold">
									novaxia.fr
								</Text>
							</Text>
						</Card>
						<Card w="100%">
							<UnorderedList spacing="16px">
								<ListItem>Loyers versé chaque mois</ListItem>
								<ListItem>Sans frais d'entrée</ListItem>
								<ListItem>Certification ISR</ListItem>
							</UnorderedList>
						</Card>
					</VStack>
					<Box flex="1">
						<RealEstateCard fund={fund} disableAnimation hideInvestButton />
					</Box>
				</HStack>
				<Text>
					Le capital investi et les revenus ne sont pas garantis : ils sont liés aux évolutions du marché de
					l’immobilier et au bon paiement des loyers par les locataires.
				</Text>
			</VStack>

			<BottomStepBar
				showPrev={false}
				textNext="Suivant"
				nextSubmit
				onNext={() => navigate(`/phoenix/onboarding/${subId}/documents`)}
			/>
		</VStack>
	);
};

export default ScpiProject;
