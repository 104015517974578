import { Link } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { Button, Heading, Skeleton, Text, VStack } from '@chakra-ui/react';

import { SimulationTable } from 'pages/PrivateEquity/Simulations/SimulationTable';
import eventTracker from 'services/events/eventTracker';
import { useGetRealEstateSimulationsQuery } from 'services/requests/realEstate/simulations';

const SCPIRecommandationHome = (): JSX.Element => {
	const { data: simulations, isLoading } = useGetRealEstateSimulationsQuery();

	return (
		<VStack spacing="lg" align="start" w="100%">
			<Heading variant="Title-L-SemiBold">Mes Simulations</Heading>

			<Button
				as={Link}
				to="nouvelle"
				variant="primary"
				size="lg"
				rightIcon={<AddIcon />}
				onClick={() => {
					window.sessionStorage.removeItem('realEstateSimulation');
					eventTracker.mixpanel.newSimulation('SCPI');
				}}
			>
				Commencer une nouvelle simulation
			</Button>

			<Skeleton isLoaded={!isLoading} w="100%">
				{simulations && simulations.length > 0 ? (
					<SimulationTable simulations={simulations} type="SCPI" />
				) : (
					<Text variant="Text-M-SemiBold">Aucune simulation n'a été trouvée</Text>
				)}
			</Skeleton>
		</VStack>
	);
};

export default SCPIRecommandationHome;
