import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { Link as ReachLink, useLocation, useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Image,
	Input,
	Link,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';

import ErrorFieldMessage from 'components/inputs/ErrorMessage';
import { ShowHidePasswordInput } from 'components/inputs/ShowHidePasswordInput';
import SuccessMessage from 'components/inputs/SuccessMessage';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { useAppDispatch } from 'hooks/useStore';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useRegisterMutation } from 'services/requests/auth';
import { useGetCorporateCompanyByIdQuery } from 'services/requests/corporate';
import { useLazyGetPartnerByNameQuery } from 'services/requests/partner';
import { useLazyGetUserQuery } from 'services/requests/user';
import { setIsAuth, setToken } from 'store/auth.slice';
import api from 'utils/api';
import { codePromoLoader } from 'utils/functions';

import { ContainerAuthStyle } from './style';
import { confirmCodePromoRules, emailPattern, getCodePromo } from './utils';

type RegisterFormType = {
	email: string;
	corporateEmail?: string;
	password: string;
	confirmPassword: string;
	codePromo?: string;
};

const Register = (): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const toast = useThemedToast();
	const isDesktop = useAppResponsive({ base: false, lg: true });
	const [cookies] = useCookies(['utm_source']);
	const [retriggerUser] = useLazyGetUserQuery();
	const [fetchPartner, { data: partnerData, isFetching: isPartnerFetching }] = useLazyGetPartnerByNameQuery();
	const [registerUser, { isLoading }] = useRegisterMutation();
	const [partnerName, setPartnerName] = useState('');
	const [invalidSyntax, setInvalidSyntax] = useState<string>();
	const [promoMessage, setPromoMessage] = useState<string>();
	const { search } = useLocation();
	const emailUrl = new URLSearchParams(search).get('email') ?? undefined;
	const corporateEmailUrl = new URLSearchParams(search).get('corporateEmail') ?? undefined;
	const codePromoUrl = new URLSearchParams(search).get('code') ?? undefined;
	const partner = new URLSearchParams(search).get('partner') ?? undefined;
	const product = new URLSearchParams(search).get('redirect') ?? '/';
	const corporate = new URLSearchParams(search).get('corporate') ?? undefined;

	const { data: corporateData, isFetching: isCorporateFetching } = useGetCorporateCompanyByIdQuery(corporate ?? '', {
		skip: !corporate,
	});

	const {
		formState: { errors },
		register,
		handleSubmit,
	} = useForm<RegisterFormType>({
		reValidateMode: 'onSubmit',
		defaultValues: {
			email: emailUrl ?? undefined,
			corporateEmail: corporateEmailUrl ?? undefined,
			codePromo: codePromoUrl?.toUpperCase() ?? getCodePromo(),
		},
	});

	const handlePromoCodeValidation = (valid?: boolean) => {
		if (valid === undefined) {
			setInvalidSyntax(undefined);
			setPromoMessage(undefined);
		} else if (!valid) {
			setInvalidSyntax('Ce code est invalide');
			setPromoMessage(undefined);
		} else {
			setInvalidSyntax(undefined);
			setPromoMessage('Votre offre de parrainage est valide !');
		}
	};

	// Partner:
	useEffect(() => {
		// Si le client s'inscrit depuis une URL '/creer-un-compte?partner=Ramify', le partner utilisé sera celui de l'url
		// Si non specifié dans l'URL mais présent dans le cookie "utm_source", celui ci sera utilisé
		if (partner) fetchPartner(partner);
		else if (cookies.utm_source && cookies.utm_source !== 'null') fetchPartner(cookies.utm_source);
	}, [partner, cookies, fetchPartner]);

	useEffect(() => {
		if (codePromoUrl) window.sessionStorage.setItem('codepromo', codePromoUrl);
	}, [codePromoUrl]);

	const onSubmit = handleSubmit(({ email, password, codePromo, corporateEmail }: RegisterFormType) => {
		eventTracker.createHubspotForm(email, partnerName);
		registerUser({
			email,
			password,
			codePromo: codePromo?.toUpperCase(),
			partner: partnerData?.name || undefined,
			redirect: product,
			corporateEmail,
		})
			.unwrap()
			.then((res) => {
				api.setHeaderToken(res.access_token);
				localStorage.setItem('token', res.access_token);
				dispatch(setToken(res.access_token));
				dispatch(setIsAuth(true));
				retriggerUser();
				navigate(`/valider-email?redirect=${product}`);
				eventTracker.mixpanel.trackRegister(res.user.id, { email: res.user.email });
			})
			.catch((error) => {
				if ('status' in error) {
					toast({
						title: "Une erreur s'est produite.",
						description: (error.data as { message: string })?.message || "Impossible de valider l'inscription.",
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				}
			});
	});

	const handleTitleProduct = (productRedirect: string) => {
		if (productRedirect.includes('immobilier')) return 'Découvrez notre sélection de SCPI';
		if (productRedirect.includes('private-equity')) return 'Découvrez notre sélection de fonds Private Equity';
		if (productRedirect.includes('invest') || productRedirect.includes('onboarding'))
			return 'Découvrez nos offres Invest';
		if (!isCorporateFetching && corporateData) return `Ramify x ${corporateData.name}`;
		return 'Bienvenue chez Ramify';
	};

	const handleDescriptionProduct = (productRedirect: string) => {
		if (productRedirect.includes('immobilier'))
			return "Nos équipes de recherche ont sélectionné les meilleures SCPIs dans lesquelles investir. Rendement, prix d'entrée, secteur…";
		if (productRedirect.includes('private-equity'))
			return "Investir dans le Private Equity n'est plus réservé aux institutionnels. Nous avons analysé les fonds français et sélectionné les meilleurs.";
		if (productRedirect.includes('invest') || productRedirect.includes('onboarding'))
			return "Nous allons vous poser des questions afin de vous proposer un portefeuille d'investissement personnalisé et répondant à vos besoins.";
		if (!isCorporateFetching && corporateData)
			return 'Créez votre compte Ramify pour visualiser la valorisation de vos BSPCE.';
		return "Un compte Ramify vous permettra de visualiser notre sélection d'ETFs, SCPIs, fonds de Private Equity et être tenu au courant des nouvelles que nous ajoutons régulièrement.";
	};

	const handleVideoProduct = (productRedirect: string) => {
		if (productRedirect.includes('immobilier'))
			return 'https://uploads-ssl.webflow.com/63c027c9ecb9a193a6b225ea/640af73c85f0c03b26896882_Product_01-transcode.mp4';
		if (productRedirect.includes('private-equity'))
			return 'https://uploads-ssl.webflow.com/63c027c9ecb9a193a6b225ea/640af73c85f0c03b26896882_Product_01-transcode.mp4';
		if (productRedirect.includes('invest') || productRedirect.includes('onboarding'))
			return 'https://uploads-ssl.webflow.com/63c027c9ecb9a193a6b225ea/640af73c85f0c03b26896882_Product_01-transcode.mp4';
		return 'https://uploads-ssl.webflow.com/63c027c9ecb9a193a6b225ea/640b565985f0c026358edc00_Product_02-transcode.mp4';
	};

	// Used to render a preview of the video before the video is loaded (it takes a little time for the video to load)
	const handleVideoBackgroundProduct = (productRedirect: string) => {
		if (productRedirect.includes('immobilier'))
			return 'https://uploads-ssl.webflow.com/63c027c9ecb9a193a6b225ea/640af73c85f0c03b26896882_Product_01-poster-00001.jpg';
		if (productRedirect.includes('private-equity'))
			return 'https://uploads-ssl.webflow.com/63c027c9ecb9a193a6b225ea/640af73c85f0c03b26896882_Product_01-poster-00001.jpg';
		if (productRedirect.includes('invest') || productRedirect.includes('onboarding'))
			return 'https://uploads-ssl.webflow.com/63c027c9ecb9a193a6b225ea/640af73c85f0c03b26896882_Product_01-poster-00001.jpg';
		return 'https://uploads-ssl.webflow.com/63c027c9ecb9a193a6b225ea/640b565985f0c026358edc00_Product_02-poster-00001.jpg';
	};

	return (
		<Stack align={isDesktop ? ' stretch' : 'center'} direction="row" w="100%">
			{isDesktop && (
				<VStack w="50%">
					<Box w="100%" minH="calc(100vh - 80px)" h="100%" position="relative">
						<Box
							as="video"
							autoPlay
							loop
							muted
							playsInline
							w="100%"
							h="100%"
							objectFit="cover"
							bgImg={handleVideoBackgroundProduct(product)}
							bgPos="50% 50%"
							bgSize="cover"
							src={handleVideoProduct(product)}
						/>
						<VStack position="absolute" top="0" left="0" zIndex="10">
							<VStack w="100%" p="52px 64px" align="start">
								<Text variant="bigTitle" fontSize="21px">
									{handleTitleProduct(product)}
								</Text>
								<Text variant="label" fontSize="15px">
									{handleDescriptionProduct(product)}
								</Text>
							</VStack>
						</VStack>
					</Box>
				</VStack>
			)}
			<VStack w={isDesktop ? '50%' : '100%'} spacing="0px" justify="center">
				<VStack {...ContainerAuthStyle}>
					{!isDesktop && (
						<VStack align="start">
							<Text variant="bigTitle" fontSize="21px">
								{handleTitleProduct(product)}
							</Text>
							<Text variant="label" fontSize="15px">
								{handleDescriptionProduct(product)}
							</Text>
						</VStack>
					)}
					<VStack spacing={isDesktop ? '32px' : '16px'} w="100%">
						<HStack align={isDesktop ? 'center' : 'start'} w="100%" justify="space-between" h="48px">
							<Heading variant="Title-L-Bold">Créer un compte Ramify</Heading>
							{!isCorporateFetching && corporateData && <Image h="48px" w="48px" src={corporateData.logo} />}
						</HStack>

						<chakra.form w="100%" onSubmit={onSubmit}>
							<VStack align="center" spacing="24px" w="100%">
								{/* Only display this field if corporate is present in url, like GitGuardian */}
								{!isCorporateFetching && corporateData && (
									<VStack align="left" spacing="8px" w="100%">
										<FormControl isInvalid={!!errors.corporateEmail}>
											<FormLabel>Adresse email {corporateData.name}</FormLabel>
											<Input
												{...register('corporateEmail', {
													required: true,
													pattern: emailPattern,
													validate: (value) => value && value.split('@')[1] === corporateData.domain,
												})}
												placeholder={'warren.buffett@' + corporateData.domain}
											/>
											{errors.corporateEmail?.type === 'required' && (
												<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
											)}
											{errors.corporateEmail?.type === 'pattern' && (
												<FormErrorMessage>L'email est incorrect</FormErrorMessage>
											)}
											{errors.corporateEmail?.type === 'validate' && (
												<FormErrorMessage>Domaine de l'email incorrect</FormErrorMessage>
											)}
										</FormControl>
									</VStack>
								)}

								<VStack align="left" spacing="8px" w="100%">
									<FormControl isInvalid={!!errors.email}>
										<FormLabel>Adresse email {!isCorporateFetching && corporateData ? 'personnelle' : null}</FormLabel>
										<Input
											{...register('email', { required: true, pattern: emailPattern })}
											placeholder="warren.buffett@gmail.com"
											autoComplete="email"
										/>
										{errors.email?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
										{errors.email?.type === 'pattern' && <FormErrorMessage>L'email est incorrect</FormErrorMessage>}
									</FormControl>
								</VStack>

								<VStack align="left" spacing="8px" w="100%">
									<FormControl isInvalid={!!errors.password}>
										<FormLabel>Mot de passe</FormLabel>
										<ShowHidePasswordInput
											{...register('password', { required: true, minLength: 8, maxLength: 100 })}
											placeholder="Minimum 8 caractères"
											autoComplete="new-password"
											type="password"
										/>
										{errors.password?.type === 'required' && (
											<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
										)}
										{errors.password?.type === 'minLength' && (
											<FormErrorMessage>Le mot de passe doit contenir au moins 8 caractères</FormErrorMessage>
										)}
										{errors.password?.type === 'maxLength' && (
											<FormErrorMessage>Le mot de passe ne peut pas faire plus de 100 caractères</FormErrorMessage>
										)}
									</FormControl>
								</VStack>

								<VStack align="left" spacing="8px" w="100%">
									<FormControl isInvalid={!!errors.confirmPassword}>
										<FormLabel>Confirmation du mot de passe</FormLabel>
										<ShowHidePasswordInput
											{...register('confirmPassword', { required: true, minLength: 8, maxLength: 100 })}
											placeholder="Minimum 8 caractères"
											type="password"
											autoComplete="new-password"
										/>
										{errors.confirmPassword?.type === 'required' && (
											<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
										)}
										{errors.confirmPassword?.type === 'minLength' && (
											<FormErrorMessage>Le mot de passe doit contenir au moins 8 caractères</FormErrorMessage>
										)}
										{errors.confirmPassword?.type === 'maxLength' && (
											<FormErrorMessage>Le mot de passe ne peut pas faire plus de 100 caractères</FormErrorMessage>
										)}
									</FormControl>
								</VStack>

								{/* hide code input if partner is found from url or cookies. Hide too if request is loading */}
								{!isPartnerFetching && !partnerData && !(!isCorporateFetching && corporate) && (
									<FormControl>
										<FormLabel>Code de parrainage (optionnel)</FormLabel>
										<Input
											isInvalid={!!invalidSyntax}
											placeholder="Ex: RAMIFY1234"
											{...register('codePromo', {
												onChange: async (e) => {
													const uppercaseCode = e.target.value.toUpperCase();
													const syntaxValid = confirmCodePromoRules(uppercaseCode);
													if (!syntaxValid || uppercaseCode.length < 2) {
														handlePromoCodeValidation(undefined);
														return false;
													}
													const res = await codePromoLoader(uppercaseCode);
													if (res.partnerName) setPartnerName(res.partnerName);
													handlePromoCodeValidation(res.valid);
													return res.valid;
												},
											})}
										/>
										{promoMessage && <SuccessMessage>{promoMessage}</SuccessMessage>}
										{invalidSyntax && <ErrorFieldMessage>Ce code est invalide</ErrorFieldMessage>}
									</FormControl>
								)}

								<VStack w="100%">
									<Button variant="primary" size="lg" w="100%" isLoading={isLoading} type="submit">
										S'inscrire
									</Button>
									<HStack wrap="wrap" align="start">
										<Text variant="Text-M-SemiBold">Vous êtes déjà inscrit ?</Text>
										<Link as={ReachLink} to={'/connexion' + search}>
											<Text variant="Text-M-Regular">Connectez-vous !</Text>
										</Link>
									</HStack>
								</VStack>
							</VStack>
						</chakra.form>
					</VStack>
				</VStack>
			</VStack>
		</Stack>
	);
};

export default Register;
