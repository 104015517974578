import { Box, createIcon, HStack, Icon, Text } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';

import { StepStatus } from './type';

// Carbon icon adapted because of a weird outline
export const CheckMarkIconOutline = createIcon({
	displayName: 'DashboardIcon',
	path: (
		<g fill="currentColor" stroke="currentColor">
			<path
				d="M10.1225 16.6082L10.2919 16.7612L10.4612 16.6082L17.8542 9.92736L18.0616 9.73993L17.8542 9.55249L16.8603 8.65436L16.691 8.50129L16.5216 8.65436L10.2919 14.284L7.47965 11.7427L7.31027 11.5896L7.14089 11.7427L6.14702 12.6408L5.93961 12.8282L6.14702 13.0157L10.1225 16.6082Z"
				fill="currentColor"
				strokeWidth="0.505263"
			/>
			<circle cx="12" cy="12" r="11.2421" fill="transparent" strokeWidth="1.51579" />
		</g>
	),
});

const StepIcon = ({ status, number }: { status: StepStatus; number: number }): JSX.Element => {
	const isCurrentStep = status === StepStatus.Active;
	const isAvailable = status === StepStatus.Available;

	if (status === StepStatus.Completed) return <Icon color="grey.900" boxSize="24px" as={CheckMarkIconOutline} />;

	return (
		<Box
			borderRadius="full"
			w="24px"
			h="24px"
			bg={isCurrentStep ? 'primary.yellow' : 'transparent'}
			border={
				isCurrentStep
					? '1.5px solid transparent'
					: isAvailable
					? `1.5px solid ${colors.grey[900]}`
					: `1.5px solid ${colors.grey[700]}`
			}
		>
			<Text
				// color={isCurrentStep ? 'primary.black' : 'grey.700'}
				color={isCurrentStep ? 'primary.black' : isAvailable ? 'grey.900' : 'grey.700'}
				lineHeight="22px"
				variant="Text-S-Medium"
				align="center"
			>
				{number}
			</Text>
		</Box>
	);
};

const Step = ({
	stepNumber,
	status,
	name,
	onClick,
}: {
	stepNumber: number;
	status: StepStatus;
	name: string;
	onClick: () => void;
}) => {
	const isSelected = status === 'active';
	const isAvailable = status === StepStatus.Available;

	return (
		<HStack
			spacing="12px"
			cursor="pointer"
			borderRadius="full"
			p="6px 16px 6px 6px"
			onClick={onClick}
			bg={isSelected ? 'yellow.100' : 'transparent'}
		>
			<StepIcon status={status} number={stepNumber} />
			<Text
				variant="Text-M-Medium"
				color={
					status === StepStatus.Active
						? 'primary.black'
						: status === StepStatus.Completed || isAvailable
						? 'grey.900'
						: 'grey.700'
				}
			>
				{name}
			</Text>
		</HStack>
	);
};
export default Step;
