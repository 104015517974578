import type { SystemStyleObject } from '@chakra-ui/theme-tools';

import textStyles from 'theme/foundations/typography';

const baseStyle: SystemStyleObject = {
	...textStyles['Text-S-Medium'],
	marginEnd: 0,
	// _focus: {
	// 	color: 'primary.yellow',
	// },
	_invalid: {
		color: 'negative.900',
	},
};

export default {
	baseStyle,
};
