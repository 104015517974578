import { useEffect, useMemo, useState } from 'react';
import { Close, EventSchedule } from '@carbon/icons-react';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Card,
	Divider,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	HStack,
	IconButton,
	Image,
	Show,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import CPMPaysage from 'assets/backgrounds/cpm-paysage.png';
import CashIconColored from 'assets/icons/ProductsColoredIcons/Cash.svg';
import ImmoIconColored from 'assets/icons/ProductsColoredIcons/Immo.svg';
import InvestIconColored from 'assets/icons/ProductsColoredIcons/Invest.svg';
import PeIconColored from 'assets/icons/ProductsColoredIcons/Pe.svg';
import ProductCard from 'components/cards/ProductCard';
import { CalendlyModal } from 'components/Modals/Calendly';
import { ProductActions } from 'hooks/productActions/type';
import useGetActionsCash from 'hooks/productActions/useGetActionsCash';
import useGetActionsInvest from 'hooks/productActions/useGetActionsInvest';
import useGetActionsPrivateEquity from 'hooks/productActions/useGetActionsPE';
import useGetActionsSCPI from 'hooks/productActions/useGetActionsSCPI';
import CashVersementModal from 'pages/Cash/Dashboard/VersementModal';
import { useGetAllSavingsAccountsQuery } from 'services/requests/savingsAccounts';
import { ProductType } from 'store/types/global.type';
import { SavingsAccountProvider, SavingsAccountType } from 'store/types/savingsAccount.type';
import colors from 'theme/foundations/colors';

const HomeDrawer = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }): JSX.Element => {
	const { data: savingsAccounts } = useGetAllSavingsAccountsQuery();
	const { onClose: onCashVersementClose, onOpen: onCashVersementOpen, isOpen: isCashVersementOpen } = useDisclosure();
	const { onClose: onCalendlyClose, onOpen: onCalendlyOpen, isOpen: isCalendlyOpen } = useDisclosure();

	const openedSavingsAccount = useMemo(
		() =>
			savingsAccounts?.find(
				(savingAccount) =>
					savingAccount.provider === SavingsAccountProvider.MY_MONEY_BANK &&
					savingAccount.type === SavingsAccountType.CER,
			),
		[savingsAccounts],
	);
	const [options, setOptions] = useState<
		{
			variant: ProductType;
			title: string;
			description: string;
			icon: string;
			options: ProductActions[];
		}[]
	>([]);

	const { actions: investActions } = useGetActionsInvest({});
	const { actions: peActions } = useGetActionsPrivateEquity({});
	const { actions: scpiActions } = useGetActionsSCPI({});
	const { actions: cashActions } = useGetActionsCash({ onOpenVersement: () => onCashVersementOpen() });

	useEffect(() => {
		setOptions([
			{
				variant: ProductType.INVEST,
				title: 'Investir avec Ramify Invest',
				description: 'Investissez dans un portefeuille d’actifs diversifié via Assurance Vie ou PER.',
				icon: InvestIconColored,
				options: investActions,
			},
			{
				variant: ProductType.PE,
				title: 'Investir en Private Equity',
				description: 'Investissez dès 1 000 € dans les fonds les plus prestigieux.',
				icon: PeIconColored,
				options: peActions,
			},
			{
				variant: ProductType.SCPI,
				title: 'Investir en Immobilier',
				description: 'Investissez dans l’immobilier dès 187 €, sans frais d’entrée !',
				icon: ImmoIconColored,
				options: scpiActions,
			},
			{
				variant: ProductType.CASH,
				title: 'Épargnez avec Ramify Cash',
				description: 'Épargnez votre liquidité avec les meilleurs intérêts.',
				icon: CashIconColored,
				options: cashActions,
			},
		]);
	}, [investActions, scpiActions, peActions, cashActions]);

	return (
		<>
			{openedSavingsAccount?.bankInformationId && (
				<CashVersementModal
					isOpen={isCashVersementOpen}
					onClose={onCashVersementClose}
					bankAccountId={openedSavingsAccount.bankInformationId}
				/>
			)}
			<CalendlyModal isOpen={isCalendlyOpen} onClose={onCalendlyClose} />
			<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isOpen} onClose={onClose}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader>
						<HStack w="100%" justify="space-between">
							<Heading variant="Title-L-Bold">Investir</Heading>
							<IconButton
								aria-label="close"
								onClick={onClose}
								variant="tertiary"
								icon={
									<Box>
										<Close size="32" />
									</Box>
								}
							/>
						</HStack>
					</DrawerHeader>
					<DrawerBody>
						<VStack align="start" w="100%" spacing="32px">
							<Card
								w="100%"
								p="0"
								bgImg={CPMPaysage}
								bgRepeat="no-repeat"
								bgPosition="center"
								cursor="pointer"
								_hover={{ border: `1px solid ${colors.primary.yellow}`, opacity: '0.95' }}
								onClick={() => onCalendlyOpen()}
							>
								<Box p="16px" bg="rgba(0,0,0,0.80)" borderRadius="base">
									<HStack w="100%" justify="space-between">
										<HStack w="100%" spacing="16px">
											<EventSchedule size="32" color="white" />
											<VStack align="start" spacing="4px">
												<Text variant="Title-S-Bold" color="grey.100">
													Echanger avec un conseiller
												</Text>
											</VStack>
										</HStack>
										<Show above="md">
											<Button variant="primary" flexShrink="0">
												Prendre rendez-vous
											</Button>
										</Show>
									</HStack>
								</Box>
							</Card>
							<Divider />
							<Accordion allowToggle w="100%">
								<VStack spacing="16px" align="start" w="100%">
									{options.map((product) => (
										<AccordionItem key={product.variant} border="0" w="100%">
											<AccordionButton as="div" padding="0">
												<ProductCard variant={product.variant} w="100%">
													<VStack w="100%" align="start" spacing="0px">
														<HStack w="100%" justify="space-between">
															<HStack w="100%" spacing="16px">
																<Image boxSize="32px" src={product.icon} />
																<VStack align="start" spacing="4px">
																	<Text variant="Title-S-Bold">{product.title}</Text>
																	<Text variant="Text-M-Regular" color="grey.900" textAlign="start">
																		{product.description}
																	</Text>
																</VStack>
															</HStack>
															<AccordionIcon />
														</HStack>

														<AccordionPanel pb="0px">
															<Divider my="16px" />
															<VStack w="100%" align="start" spacing="16px">
																{product.options.map((option) => (
																	<Button
																		key={option.label}
																		variant="tertiary"
																		onClick={(e) => {
																			e.stopPropagation();
																			option.onClick();
																		}}
																	>
																		{option.label}
																	</Button>
																))}
															</VStack>
														</AccordionPanel>
													</VStack>
												</ProductCard>
											</AccordionButton>
										</AccordionItem>
									))}
								</VStack>
							</Accordion>
						</VStack>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
};

export default HomeDrawer;
