import { useNavigate } from 'react-router-dom';
import { Heading, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import { useAppResponsive } from 'hooks/useAppResponsive';
import textStyles from 'theme/foundations/typography';

const Introduction = (): JSX.Element => {
	const navigate = useNavigate();
	const isMobile = useAppResponsive({ base: true, lg: false });

	return (
		<VStack w="100%" spacing="24px" align="start">
			<Heading variant="Title-XL-Bold">Optimiser ma fiscalité</Heading>
			{!isMobile && (
				<Text variant="Text-M-Regular" color="grey.900" maxW="900px">
					Sans optimisation, vous pouvez perdre une grande partie des gains de votre épargne en impôts, tous les ans.
					Les dispositifs proposés par l’État français permettent de bénéficier d'avantages fiscaux :{'	'}
					<Text as="span" variant="Text-M-Bold">
						exonération des plus-values ou encore diminution d'impôt sur les revenus{'	'}
					</Text>
					(via déduction des versements du PER). En réinvestissant ces économies, vous augmentez significativement votre
					patrimoine à la fin de l’horizon d’investissement.
				</Text>
			)}
			<VStack spacing="16px" align="start">
				<Text variant="Text-M-Medium" color="grey.900">
					Ce qui vous attend :
				</Text>
				<UnorderedList
					ml={{ base: '24px !important', md: '32px !important' }}
					spacing={{ base: '4px', md: '6px', lg: '8px' }}
					{...textStyles['Text-M-Regular']}
					color="grey.900"
				>
					<ListItem color="grey.600">
						Une étape pour renseigner votre situation fiscale, qui nous permet de déterminer les optimisations
						possibles.
					</ListItem>
					<ListItem color="grey.600">
						Notre recommandation d’enveloppes fiscales adaptée à votre situation personnelle.
					</ListItem>
					<ListItem color="grey.600">Les projections nettes de votre projet d'épargne.</ListItem>
				</UnorderedList>
			</VStack>
			<ArrowButton
				size="lg"
				w={{ base: '100%', md: 'auto' }}
				variant="primary"
				data-cy="cy-skip-intro"
				onClick={() => navigate('situation')}
			>
				{isMobile ? 'Continuer' : 'Optimiser fiscalement mes investissements'}
			</ArrowButton>
		</VStack>
	);
};

export default Introduction;
