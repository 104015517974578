import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';
import textStyles from 'theme/foundations/typography';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const filterTagVariant = definePartsStyle({
	closeButton: {
		color: 'white',
		boxSize: '14px',
		opacity: 1,
	},
	container: {
		userSelect: 'none',
		border: 'none',
		padding: '8px 16px',
		background: colors.grey[100],
		borderRadius: 'full',
		cursor: 'pointer',
		_hover: {
			background: colors.grey[500],
		},
		_active: {
			background: colors.grey[700],
		},
	},
	label: {
		...textStyles['Text-S-Regular'],
	},
});

const filterSelectedTagVariant = definePartsStyle({
	container: {
		...filterTagVariant.container,
		background: colors.grey[900],
		_hover: undefined,
	},
	label: {
		...textStyles['Text-S-Medium'],
		color: 'white',
	},
	closeButton: filterTagVariant.closeButton,
});

const tagTheme = defineMultiStyleConfig({
	variants: { filter: filterTagVariant, 'filter-selected': filterSelectedTagVariant },
});

export default tagTheme;
