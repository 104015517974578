import { CheckmarkFilled } from '@carbon/icons-react';
import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Button, Card, HStack, Icon, Image, Stack, Text, VStack } from '@chakra-ui/react';

// Components
import { ArrowButton } from 'components/Button/Button';
import { downloadDocument } from 'services/requests/document';
import { UploadedDocumentStatus } from 'services/requests/kyc';
import colors from 'theme/foundations/colors';

const JustificatifCard = ({
	logo,
	title,
	onClickUpload,
	existingDocument,
}: {
	logo: string;
	title: string;
	onClickUpload: () => void;
	existingDocument?: UploadedDocumentStatus;
}): JSX.Element => {
	const isEmpty = !existingDocument?.uploaded;
	const dateLastUpload = existingDocument?.modifiedDate ? existingDocument?.modifiedDate : null;

	return (
		<Card w="100%" position="relative" {...(!isEmpty ? { border: `1px solid ${colors.positive[500]}` } : {})}>
			{!isEmpty && (
				<Box position="absolute" top="-12px" right="-12px" borderRadius="full" bg="white">
					<CheckmarkFilled color={colors.positive[500]} size="32" style={{ margin: '-3px' }} />
				</Box>
			)}
			<Stack justify="space-between" direction={{ base: 'column', md: 'row' }}>
				<HStack spacing="16px" align="center">
					<Image src={logo} />
					<VStack align="start" spacing="4px">
						<Text variant="Text-M-Medium">{title}</Text>
						{dateLastUpload && (
							<Text fontSize="12px" color="grey.700">
								Dernière modification le {dateLastUpload.slice(0, 10)} à {dateLastUpload.slice(11, 16)}
							</Text>
						)}
					</VStack>
				</HStack>
				<HStack spacing="16px">
					{!isEmpty && (
						<Button
							variant="secondary"
							p="4px"
							onClick={() => {
								downloadDocument({
									filepath: existingDocument?.key || '',
									contentType: existingDocument?.contentType || '',
									filename: existingDocument?.originalName || '',
								});
							}}
						>
							<Icon as={DownloadIcon} w="20px" h="20px" />
						</Button>
					)}
					{isEmpty ? (
						<ArrowButton flex="1" onClick={onClickUpload} variant="primary">
							Ajouter
						</ArrowButton>
					) : (
						<Button flex="1" onClick={onClickUpload} variant="secondary">
							Modifier
						</Button>
					)}
				</HStack>
			</Stack>
		</Card>
	);
};

export default JustificatifCard;
