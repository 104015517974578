import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	Button,
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	VStack,
} from '@chakra-ui/react';
import { electronicFormatIBAN, friendlyFormatIBAN, validateBIC, validateIBAN } from 'ibantools';

import DropZone from 'components/inputs/Dropzone';
import { ValidationTextInput } from 'components/inputs/TextInput';
import { useAddBankAccountMutation } from 'services/requests/bank';
import { DonneesBancairesForm } from 'store/types/subscription.type';

interface AddBankAccountModalProps {
	isOpen: boolean;
	onClose: () => void;
}

const checkIbanFrench = (iban: string | undefined): boolean =>
	iban === undefined || iban?.length < 2 || iban.toLowerCase().startsWith('fr');

const checkIbanValidation = (iban: string): boolean =>
	validateIBAN(electronicFormatIBAN(iban) || undefined).valid && checkIbanFrench(iban);

export const AddBankAccountModal: FC<AddBankAccountModalProps> = ({ isOpen, onClose }) => {
	const [ibanUpload, setIbanUpload] = useState<File | null>(null);
	const [ibanUploadError, setIbanUploadError] = useState(false);

	const [runAddBankAccount, { isSuccess, isLoading }] = useAddBankAccountMutation();
	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		watch,
	} = useForm<DonneesBancairesForm>({ mode: 'onChange' });

	useEffect(() => {
		if (isSuccess) onClose();
	}, [isSuccess, onClose]);

	const ibanFilled = watch('IBAN');

	const onSubmit = handleSubmit((formData: DonneesBancairesForm) => {
		const iban = electronicFormatIBAN(formData.IBAN) as string;
		if (!ibanUpload) {
			setIbanUploadError(true);
		} else {
			const requestFormData = new FormData();
			requestFormData.append('file', ibanUpload);
			requestFormData.append('IBAN', iban);
			requestFormData.append('BIC', formData.BIC.replace(/\s/g, ''));
			requestFormData.append('holder', formData.holder);

			runAddBankAccount(requestFormData);
		}
	});

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="2xl">
			<ModalOverlay />
			<ModalContent>
				<chakra.form
					onSubmit={(e) => {
						e.stopPropagation();
						onSubmit(e);
					}}
				>
					<ModalHeader>
						<Heading variant="Title-M-SemiBold">Ajouter un compte bancaire</Heading>
					</ModalHeader>
					<ModalCloseButton />

					<ModalBody padding="24px">
						<VStack w="100%" spacing="24px" align="start">
							<FormControl isInvalid={!!errors.holder}>
								<FormLabel>Titulaire du compte</FormLabel>
								<Input placeholder="Nom complet du titulaire" {...register('holder', { required: true })} />
								{errors.holder?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
							</FormControl>

							<Stack
								direction={{ base: 'column', md: 'row' }}
								align="start"
								alignItems="flex-start"
								w="100%"
								spacing={{ base: undefined, md: 'lg' }}
							>
								<FormControl isInvalid={!!errors.IBAN}>
									<FormLabel>IBAN</FormLabel>
									<ValidationTextInput
										validate={(str) => checkIbanValidation(str)}
										placeholder="ex : FRXX XXXX XXXX XXXX"
										formatOnChange={friendlyFormatIBAN}
										{...register('IBAN', { required: true, validate: (str) => checkIbanValidation(str) })}
									/>
									{errors.IBAN?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
									{errors.IBAN?.type === 'validate' &&
										(checkIbanFrench(ibanFilled) ? (
											<FormErrorMessage>Votre Iban n'est pas reconnu</FormErrorMessage>
										) : (
											<FormErrorMessage>Seuls les IBAN français sont acceptés</FormErrorMessage>
										))}
								</FormControl>

								<FormControl isInvalid={!!errors.BIC}>
									<FormLabel>BIC</FormLabel>
									<ValidationTextInput
										validate={(str) => validateBIC(str).valid}
										placeholder="Code BIC de votre banque"
										{...register('BIC', {
											required: {
												value: true,
												message: 'Ce champ est nécessaire',
											},
											pattern: {
												value: /^[a-z]{6}[0-9a-z]{2}([0-9a-z]{3})?$/i,
												message: "Le BIC est constitué de 8 ou 11 caractères et ne doit pas contenir d'espaces",
											},
										})}
									/>
									{errors.BIC && <FormErrorMessage>{errors.BIC.message}</FormErrorMessage>}
								</FormControl>
							</Stack>

							<FormControl isInvalid={!!ibanUploadError}>
								<FormLabel>Justificatif (RIB ou IBAN)</FormLabel>
								<DropZone
									filename={ibanUpload?.name}
									filesUploaded={!!ibanUpload}
									onDrop={(files) => {
										setIbanUploadError(false);
										setIbanUpload(files[0]);
									}}
								/>
								{ibanUploadError && <FormErrorMessage>Une photo ou scan de votre IBAN est nécessaire</FormErrorMessage>}
							</FormControl>
						</VStack>
					</ModalBody>

					<ModalFooter>
						<HStack w="100%" justify="space-between">
							<Button variant="secondary" onClick={onClose}>
								Annuler
							</Button>
							<Button type="submit" isDisabled={!isValid || !ibanUpload} isLoading={isLoading}>
								Ajouter
							</Button>
						</HStack>
					</ModalFooter>
				</chakra.form>
			</ModalContent>
		</Modal>
	);
};
