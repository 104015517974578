import { useController } from 'react-hook-form';
import { Select, Stack } from '@chakra-ui/react';
import { AsYouType, CountryCode, isValidPhoneNumber } from 'libphonenumber-js/mobile';

import Indicatifs from 'store/referentiels/indicatif.json';
import { TelephoneMobileDTO } from 'store/types/KYC.type';

import LabelWrapperInput from './LabelWrapperInput';
import { ValidationTextInput } from './TextInput';

type PhoneInputProps = {
	name: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rules: any;
	placeholder?: string;
	label?: string;
	cyInd?: string;
	cyTel?: string;
	defaultTelephoneMobile?: TelephoneMobileDTO;
};

const PhoneInput = ({
	name,
	placeholder,
	label,
	control,
	rules,
	cyInd,
	cyTel,
	defaultTelephoneMobile,
}: PhoneInputProps): JSX.Element => {
	const {
		field: { ref, onChange, value },
	} = useController({
		name,
		control,
		rules,
		defaultValue: {
			indicatif: `${defaultTelephoneMobile ? defaultTelephoneMobile.indicatif : '0033'}`,
			numeroTelephone: `${defaultTelephoneMobile ? defaultTelephoneMobile.numeroTelephone : ''}`,
		},
	});

	const getCountryCode = (): string => {
		const indicatif = Indicatifs.find((x) => x.code.toString() === value.indicatif.toString());
		if (indicatif) return indicatif.codePays;
		return '';
	};

	const onTextChange = (text: string) => {
		const formattedNumber = new AsYouType(getCountryCode() as CountryCode).input(text);
		onChange({ ...value, numeroTelephone: formattedNumber });
	};
	const onIndicatifChange = (newV: string) => onChange({ ...value, indicatif: newV });

	return (
		<LabelWrapperInput label={label}>
			<Stack ref={ref} direction={{ base: 'column', xl: 'row' }} w="100%">
				<Select
					w={{ base: '100%', xl: '200px' }}
					value={value.indicatif}
					onChange={(e) => onIndicatifChange(e.target.value)}
					data-cy={cyInd}
				>
					{Indicatifs.sort((a, b) => (a.code > b.code ? 1 : -1)).map((i) => (
						<option value={i.code} key={i.libelle}>
							{i.code.replace('00', '+')}
						</option>
					))}
				</Select>

				<ValidationTextInput
					data-cy={cyTel}
					// iconLeft={<PhoneIcon color="#535353" />}
					validate={(str) => isValidPhoneNumber(value.indicatif.replace('00', '+') + str)}
					placeholder={placeholder}
					value={value.numeroTelephone}
					maxLength={18}
					type="tel"
					// @ts-ignore -- ON CHANGE REF BAD TYPE
					onChange={(s) => onTextChange(s.target.value)}
				/>
			</Stack>
		</LabelWrapperInput>
	);
};

export default PhoneInput;
