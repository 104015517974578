import {
	Button,
	HStack,
	ListItem,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
	UnorderedList,
	VStack,
} from '@chakra-ui/react';

const ConfirmationModal = ({
	isOpen,
	onConfirm,
	onClose,
}: {
	isOpen: boolean;
	onConfirm: () => void;
	onClose: () => void;
}): JSX.Element => (
	<Modal isOpen={isOpen} onClose={onClose}>
		<ModalOverlay />
		<ModalContent>
			<ModalBody py="24px">
				<VStack spacing="24px" align="start">
					<Text variant="Title-M-SemiBold">Changement de projet d'épargne</Text>
					<Text variant="Text-M-Regular" color="grey.900">
						En modifiant votre projet, votre simulation sera réinitialisée. Par conséquent, en poursuivant, vous serez
						amené à refaire les étapes suivantes :
					</Text>
					<UnorderedList
						ml={{ base: '24px !important', md: '32px !important' }}
						fontSize={{ base: '12px', md: '14px' }}
						spacing={{ base: '12px', md: '14px', lg: '16px' }}
					>
						<ListItem>
							<Text as="span" variant="Text-M-Medium">
								Portefeuille d'investissement
							</Text>
						</ListItem>
						{/* <ListItem>
							<Text as="span" variant="label">
								Optimisation fiscale
							</Text>
						</ListItem> */}
					</UnorderedList>

					<HStack w="100%" justifyContent="space-between">
						<Button variant="secondary" onClick={onClose}>
							Annuler
						</Button>

						<Button variant="primary" onClick={onConfirm}>
							Continuer
						</Button>
					</HStack>
				</VStack>
			</ModalBody>
		</ModalContent>
	</Modal>
);

export default ConfirmationModal;
