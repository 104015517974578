import { Chart } from 'react-chartjs-2';
import { Box } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { SCPIFundHistory } from 'store/types/airtable.type';
import colors from 'theme/foundations/colors';
import { displayMoney, displayMoneyNoDigits, displayPercentage } from 'utils/rendering';

export const HistoryChart = ({ fundHistory }: { fundHistory: SCPIFundHistory[] }) => {
	// PRICES
	const prices = fundHistory.filter((h): h is SCPIFundHistory & { prix: number } => h.prix !== undefined);
	const sortedPrices = prices.sort((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());

	const barMin = prices.reduce((acc, curr) => Math.min(acc, curr.prix!), Infinity) * 0.9;
	const maxPrice = prices.reduce((acc, curr) => Math.max(acc, curr.prix!), -Infinity);
	const barMax = maxPrice * 2 - barMin;

	const pricesCleaned = sortedPrices.reduce((acc, curr) => {
		const year = dayjs(curr.date).year().toString();
		if (year in acc) return acc;
		return { ...acc, [year]: curr.prix };
	}, {} as Record<string, number>);

	const displayPrices = Object.entries(pricesCleaned).map(([year, price]) => ({ x: +year, y: price }));

	// DIVIDENDES
	const dividendes = fundHistory.filter((h): h is SCPIFundHistory & { dividende: number } => h.dividende !== undefined);

	const dividendesAggregatedByYear = Object.entries(
		dividendes.reduce((acc, curr) => {
			const year = dayjs(curr.date).year();
			return { [year]: [...(acc[year] ?? []), curr], ...acc };
		}, {} as Record<string, SCPIFundHistory[]>),
	);

	const dividendesData = dividendesAggregatedByYear
		.map(([year, divi], index) => {
			// TO DO : take the sum of the dividendes of the year and divide by the last price of the year - 1
			const selectedYear = sortedPrices.filter((p) => dayjs(p.date).year() === +year && p.dividende !== undefined);

			if (!selectedYear) return undefined;

			if (index === dividendesAggregatedByYear.length - 1) {
				const dividendeReachesDecember = divi.find((d) => dayjs(d.date).month() === 11);
				if (!dividendeReachesDecember) {
					return undefined;
				}
			}
			return {
				x: +year,
				y: (selectedYear.reduce((acc, curr) => acc + curr.dividende!, 0) / selectedYear[0].prix) * 100,
			};
		})
		.filter((d): d is { x: number; y: number } => d !== undefined);

	// backfill dividendes years (from prices) with null
	const displayDividendes = displayPrices.map((p) => dividendesData.find((d) => d.x === p.x) ?? { x: p.x, y: null });

	return (
		<Box w="100%" height="400px">
			<Chart
				type="line"
				data={{
					datasets: [
						{
							type: 'line' as const,
							label: 'Taux de distribution',
							data: displayDividendes,
							pointHitRadius: 160,
							backgroundColor: colors.yellow[500],
							borderColor: colors.yellow[500],
							yAxisID: 'y1',
							tension: 0.5,
						},
						{
							type: 'bar' as const,
							label: 'Prix de souscription',
							data: displayPrices,
							backgroundColor: colors.grey[700],
							borderColor: colors.grey[700],
							yAxisID: 'y',
						},
					],
				}}
				plugins={[
					{
						id: 'indexBar',
						afterDraw: (chart) => {
							if (chart.tooltip && chart.tooltip.getActiveElements().length > 0) {
								const x = chart.tooltip.getActiveElements()[0].element.x;
								const yAxis = chart.scales.y;
								const ctx = chart.ctx;
								ctx.save();
								ctx.beginPath();
								ctx.moveTo(x, yAxis.top);
								ctx.lineTo(x, yAxis.bottom);
								ctx.lineWidth = 1;

								ctx.strokeStyle = colors.grey[300];
								ctx.stroke();
								ctx.restore();
							}
						},
					},
				]}
				options={{
					interaction: {
						mode: 'index',
						intersect: false,
					},
					plugins: {
						legend: {
							display: true,
							position: 'top',
						},
						tooltip: {
							backgroundColor: 'white',
							bodyColor: 'grey',
							titleColor: colors.grey[500],
							borderColor: colors.grey[300],
							borderWidth: 1,
							titleFont: {
								size: 14,
							},
							bodyFont: {
								size: 13,
							},
							callbacks: {
								labelColor: (t) =>
									t.datasetIndex === 0
										? { backgroundColor: colors.yellow[500], borderColor: colors.yellow[500] }
										: { backgroundColor: colors.grey[700], borderColor: colors.grey[700] },
								title: (datapoints) => (datapoints[0].raw as { x: string }).x,
								label: (dataPoint) => {
									const label = dataPoint.dataset.label;
									const value = (dataPoint.raw as { y: number }).y;
									if (label?.startsWith('Prix')) return `${label} - ${displayMoney(value)}`;
									else if (label?.startsWith('Taux')) return `${label} - ${displayPercentage(value / 100)}`;
									return `${label} - ${value}`;
								},
							},
						},
					},
					elements: {
						line: {
							borderWidth: 2,
						},
						point: {
							pointStyle: 'point',
							radius: 4,
							backgroundColor: colors.yellow[500],
						},
					},
					scales: {
						x: {
							grid: {
								display: false,
							},
							type: 'linear',
							ticks: {
								stepSize: 1,
								callback: (value) => value,
								color: colors.grey[500],
							},
						},
						y: {
							grid: {
								borderDash: [5, 4],
							},
							ticks: {
								color: colors.grey[500],
								font: {
									size: 13,
									weight: 'bold',
								},
								// Hide the max tick
								callback: (value, index, values) =>
									index == values.length - 1 ? undefined : displayMoneyNoDigits(value as number),
							},
							min: barMin,
							max: barMax,
						},
						y1: {
							position: 'right',
							min: -0.5,
							suggestedMax: 8,
							beginAtZero: true,
							ticks: {
								color: colors.grey[500],
								font: {
									size: 13,
									weight: 'bold',
								},
								callback: (value) => (value >= 0 ? `${Number(value)} %` : ''),
							},
							grid: {
								display: false, // only want the grid lines for one axis to show up
							},
						},
					},
					responsive: true,
					maintainAspectRatio: false,
				}}
			/>
		</Box>
	);
};
