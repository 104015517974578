import { useParams } from 'react-router-dom';
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Heading, VStack } from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';

import AddBankAccountForm from './AddBankAccountForm';

const AddBankAccountDrawer = ({ urlPath, onClose }: { urlPath: string; onClose: () => void }): JSX.Element => {
	const { id } = useParams<{ id: string }>();

	return (
		<Drawer size={{ base: 'full', md: 'xl' }} isOpen={id === urlPath} onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<VStack align="start" w="100%" spacing="24px">
						<ArrowButton variant="secondary" alignSelf="start" onClick={onClose} left w={onboardingInputWidth}>
							Retour aux comptes enregistrés
						</ArrowButton>
						<Heading variant="Title-L-Bold">Ajouter un compte bancaire</Heading>
					</VStack>
				</DrawerHeader>
				<DrawerBody>
					<AddBankAccountForm onSuccess={onClose} />
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default AddBankAccountDrawer;
