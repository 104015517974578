import { Card, Text, VStack } from '@chakra-ui/react';

import RepartitionBar from 'components/Graphs/RepartitionBar';
import { activeFundTags } from 'components/Tags';
import { InvestFundType } from 'store/types/investment.type';

type PortfolioAssetClassRepartitionProps = {
	assets: {
		type: InvestFundType;
		repartition: number;
	}[];
};

type CustomData = {
	name: InvestFundType;
	value: number;
	color: string;
};

const PortfolioAssetClassRepartition = ({ assets }: PortfolioAssetClassRepartitionProps) => {
	const data: CustomData[] = (
		Object.entries(
			assets.reduce(
				(agg, curr) => ({ ...agg, [curr.type]: (agg[curr.type] ?? 0) + curr.repartition }),
				{} as Record<InvestFundType, number>,
			),
		) as [InvestFundType, number][]
	)
		.map(([type, repartition]: [InvestFundType, number]) => ({
			value: repartition,
			name: type,
			color: activeFundTags[type]?.bgGraph ?? 'primary.black',
		}))
		.sort((a, b) => b.value - a.value);

	return (
		<Card w="100%" h="100%">
			<VStack align="start" w="100%" spacing="24px">
				<VStack align="start" w="100%" h="100%" spacing="24px">
					<VStack align="start" spacing="12px" w="100%">
						<Text variant="Title-M-SemiBold">Actifs du portefeuille</Text>
						<Text variant="Text-M-Regular" color="grey.900">
							Description brève de la composition de votre portefeuille.
						</Text>
					</VStack>
					<RepartitionBar data={data} directionLegend="column" />
				</VStack>
			</VStack>
		</Card>
	);
};

export default PortfolioAssetClassRepartition;
