import { useEffect, useMemo } from 'react';
import { Heading, Skeleton, VStack } from '@chakra-ui/react';

import FullscreenLoader from 'components/FullscreenLoader';
import Advisor from 'components/simulationResult/Advisor';
import Objective from 'components/simulationResult/Objective';
import PortfolioScenariosGraph from 'components/simulationResult/PortfolioScenariosGraph';
import Profit from 'components/simulationResult/Profit';
import SuggestedRepartition from 'components/simulationResult/SuggestedRepartition';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import { SimulationStepProps } from 'onboarding/Stepper/stepper.slice';
import { StrategyRepartition, useGetSimulationResultQuery } from 'services/requests/invest/simulation';
import { SimulationEnvelopeType } from 'store/types/investSimulation';

import InfosCards from './Cards';

export type LineChartElement = {
	id: string;
	color: string;
	data: {
		x: number;
		y: number;
	}[];
};

export type ResultForm = {
	saving: number;
	initialAUM: number;
};

const Result = ({ simulation, onNext }: SimulationStepProps<StrategyRepartition>): JSX.Element => {
	const toast = useThemedToast();

	const {
		data: result,
		isError: isResultError,
		isLoading: isResultLoading,
		isFetching: isResultFetching,
	} = useGetSimulationResultQuery();

	const isLoading = useMemo(() => isResultLoading || isResultFetching, [isResultLoading, isResultFetching]);

	const onSubmit = () => {
		if (!result) return;
		onNext(result.strategy);
	};

	useEffect(() => {
		if (isResultError && !toast.isActive('RESULT_ERROR')) {
			toast({
				id: 'RESULT_ERROR',
				title: 'Une erreur est survenue',
				description: "Nous n'avons pas pu réaliser la simulation de votre projet avec les paramètres fournis",
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
		}
		if (!isResultError && toast.isActive('RESULT_ERROR')) {
			toast.close('RESULT_ERROR');
		}
	}, [isResultError, toast]);

	if (!result) return <FullscreenLoader />;

	return (
		<VStack w="100%" align="start" spacing="32px">
			<VStack w="100%" align="start" spacing="16px">
				<Heading variant="Title-XL-Bold">Résultats de la simulation</Heading>

				<Objective
					investSimulation={{ ...simulation, table: 'investSimulation' }}
					risk={simulation.investmentPreferences!.risk!}
				/>
			</VStack>

			{!isLoading ? (
				<Profit upper={result.scenarios.scenarios.upper} lower={result.scenarios.scenarios.lower} />
			) : (
				<Skeleton h="80px" w="100%" />
			)}

			<InfosCards simulation={simulation} taxEconomy={result.strategy.taxEconomy} strategy={result.strategy} />

			{simulation.envelope === SimulationEnvelopeType.OPTIM && (
				<SuggestedRepartition recommandedRepartition={result.strategy} />
			)}

			{!isLoading ? <PortfolioScenariosGraph portfolioScenarios={result.scenarios} /> : <Skeleton h="150px" w="100%" />}

			{simulation.initialAUM >= 50_000 && <Advisor />}

			<BottomStepBar showPrev={false} textNext="Finaliser" onNext={onSubmit} />
		</VStack>
	);
};

export default Result;
