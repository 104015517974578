import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Box, Card, Heading, Skeleton, Stack, Text, useDisclosure, VStack } from '@chakra-ui/react';

import Banner from 'components/Banners/Banner';
import { ArrowButton } from 'components/Button/Button';
import { FAQ } from 'components/faq/FAQ';
import { CalendlyModal } from 'components/Modals/Calendly';
import { IntestedProducts } from 'components/Modals/Calendly/useBuildIframeUrl';
import { SubscriptionConfirmModal } from 'components/products/SCPI-PE/SubscriptionConfirmModal';
import { SubscriptionDoneModal } from 'components/products/SCPI-PE/SubscriptionDoneModal';
import { usePrivateEquitySubscribe } from 'hooks/subscriptions/usePrivateEquitySubscribe';
import PrivateEquityDetails from 'pages/PrivateEquity/Details';
import { peSimulationObjectives } from 'pages/PrivateEquity/Simulations/SimulationTable';
import eventTracker from 'services/events/eventTracker';
import {
	PESimulationDto,
	useGetPrivateEquitySimulationByIdQuery,
	useGetPrivateEquitySimulationResultQuery,
	useUpdatePrivateEquitySimulationObjectiveMutation,
} from 'services/requests/privateEquity/simulations';
import { SubscriptionRepartition } from 'services/requests/realEstate/simulations';
import { isNone } from 'utils/functions';
import { displayMoneyNoDigits } from 'utils/rendering';

import AllocationSimulationPE from './Sections/Allocation';
import ModalUpdateInfoFiscal from './Sections/CardFiscalInformations';
import PrivateEquityGraphProjection from './Sections/GraphProjection';
import PeSimulationMetrics from './Sections/Metrics';

const SimulationFailCard = () => (
	<Card w="100%">
		<VStack align="start" w="100%" spacing="16px">
			<Heading variant="Title-L-Bold">Aucun fonds de notre catalogue ne respecte vos critères</Heading>

			<Text variant="Text-M-Regular" color="grey.900">
				Veuillez réessayer en modifiant les paramètres de votre simulation.
				<br /> Gardez en tête que :
			</Text>
			<Text>
				• Dans un projet de Private Equity, même si certains fonds sont ouverts à partir de <b>1.000€</b>, il est
				généralement recommandé d’investir au moins <b>5.000€</b> afin de se constituer un portefeuille diversifié
			</Text>
			<Text>
				• La durée moyenne d’un fonds de Private Equity est de <b>8 ans</b>. Certains fonds permettent d’investir sur
				une période plus courte mais leur nombre est très limité.
			</Text>
			<Text>
				• L’investissement dans le cadre d’une cession / 150-0-B-TER est un cadre d’investissement très spécifique, où
				les montants minimums de souscription sont généralement de l’ordre de <b>100.000€</b>
			</Text>
		</VStack>
	</Card>
);

const RecommandationPEResult = (): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();

	const { data: simulation } = useGetPrivateEquitySimulationByIdQuery(id!, { skip: isNone(id) });
	const [updateObjective, { isLoading: isUpdateObjectiveLoading }] =
		useUpdatePrivateEquitySimulationObjectiveMutation();
	const {
		data,
		isFetching: isResultFetching,
		isError,
	} = useGetPrivateEquitySimulationResultQuery(id!, { skip: isNone(id) });
	const [timeHorizon, setTimeHorizon] = useState(0);
	const [initialAUM, setinitialAUM] = useState(0);
	const [objective, setObjective] = useState<PESimulationDto['objective']>('improve_portfolio');
	const [simulateOnInputChange, setSimulateOnInputChange] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isCalendlyModalOpen, onOpen: onOpenCalendlyModal, onClose: onCloseCalendlyModal } = useDisclosure();
	const { subscribe, isSubscribing, isSubscribed } = usePrivateEquitySubscribe();
	const { isOpen: isSubConfirmOpen, onOpen: onSubConfirmOpen, onClose: onSubConfirmClose } = useDisclosure();

	useEffect(() => {
		eventTracker.mixpanel.resultSimulationPage('PE');
	}, []);

	useEffect(() => {
		if (simulateOnInputChange === false && simulation?.payload) {
			const {
				objective: simulationObjective,
				timeHorizon: simulationTimeHorizon,
				initialAUM: simulationInitialAUM,
			} = simulation.payload;
			setTimeHorizon(simulationTimeHorizon);
			setinitialAUM(simulationInitialAUM);
			setObjective(simulationObjective);
			simulate({
				objective: simulationObjective,
				timeHorizon: simulationTimeHorizon,
				initialAUM: simulationInitialAUM,
			})
				.unwrap()
				.finally(() => setSimulateOnInputChange(true));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [simulation, simulateOnInputChange]);

	useEffect(() => {
		if (simulateOnInputChange && simulation && (initialAUM || 0) >= 1000) {
			const timer = setTimeout(() => simulate({ objective, timeHorizon, initialAUM }), 600);
			return () => clearTimeout(timer);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objective, initialAUM, timeHorizon]);

	const simulate = (params: Partial<PESimulationDto>) =>
		updateObjective({
			id: id || '',
			data: {
				objective: params.objective,
				timeHorizon: params.timeHorizon as number,
				initialAUM: params.initialAUM as number,
			},
		});

	const computeSimulationAumResult = () => {
		if (data) {
			const totalInvested = data.allocation.reduce((acc, { amount }) => acc + amount, 0);
			return totalInvested + data.projection[data.projection.length - 1].cumulativeCashflow;
		}
		return 0;
	};

	const onConfirmSubscription = () => {
		if (data && data?.allocation) {
			const repartition: SubscriptionRepartition[] = [];
			data.allocation.forEach((peFund) => {
				if (peFund.fund.lastShareValuation)
					repartition.push({
						amountVersement: peFund.amount,
						fundId: peFund.fund.id,
						fundName: peFund.fund.name,
						nbParts: peFund.amount / peFund.fund.lastShareValuation,
					});
			});
			subscribe({ repartition });
		}
	};

	return (
		<VStack spacing="sm" mt="24px" mb="80px" align="start" w="100%">
			<CalendlyModal isOpen={isCalendlyModalOpen} onClose={onCloseCalendlyModal} product={IntestedProducts.PE} />
			<SubscriptionConfirmModal
				isOpen={isOpen}
				onCancel={onClose}
				onSuccess={() => {
					onClose();
					onSubConfirmOpen();
				}}
				onConfirm={onConfirmSubscription}
				isLoading={isSubscribing}
				isSuccess={isSubscribed}
				type="Private Equity"
			/>
			<SubscriptionDoneModal
				isOpen={isSubConfirmOpen}
				onClose={onSubConfirmClose}
				productName="Private Equity"
				redirectToWhenDone="/private-equity/souscriptions"
			/>
			<Box w="100%" mb="32px">
				<ArrowButton
					left
					variant="secondary"
					size="lg"
					onClick={() => navigate(`/private-equity/simulation/${id}/modifier`)}
				>
					Modifier mes informations
				</ArrowButton>
			</Box>

			<VStack align="start" w="100%" spacing="16px">
				<Heading variant="Title-XL-SemiBold">Résultat de votre simulation</Heading>

				{simulation && (
					<Text>
						En investissant <b>{displayMoneyNoDigits(initialAUM)}</b> sur une durée maximale de <b>{timeHorizon}</b> ans
						et avec l’objectif de <b>{peSimulationObjectives[objective]}</b>, vous pouvez constituer un capital de :
					</Text>
				)}
			</VStack>

			{/* Loading */}
			{isUpdateObjectiveLoading || isResultFetching ? (
				<Skeleton w="100%" h="200px" />
			) : isError || !data ? (
				<SimulationFailCard />
			) : (
				<VStack align="start" spacing="lg" w="100%">
					<Stack direction={{ base: 'column', md: 'row' }} align="baseline" spacing="8px">
						<Heading variant="Title-XL-SemiBold">{displayMoneyNoDigits(computeSimulationAumResult())} </Heading>
						<Text variant="Text-M-Regular" color="grey.900">
							(calcul basé sur les performances historiques et projetées des fonds)
						</Text>
					</Stack>
					<ModalUpdateInfoFiscal />
					<PeSimulationMetrics moic={data.moic} internalReturn={data.internalReturn} taxReduction={data.taxReduction} />
					<AllocationSimulationPE allocation={data.allocation} onSubscribe={onOpen} />
					<Banner
						title="Envie d'investir dans ce portefeuille ?"
						description="Un de nos conseillers vous accompagnera dans la réalisation de votre projet."
						variant="information"
						avatar="olivier"
						cta="En discuter avec un conseiller"
						onClick={() => {
							eventTracker.mixpanel.resultSimulationPageClickedOnRDV('PE');
							onOpenCalendlyModal();
						}}
					/>
					<PrivateEquityGraphProjection data={data} />
					<FAQ
						questions={[
							{
								question: (
									<Heading variant="Title-M-SemiBold">Quelle est la fiscalité appliquée à mes plus-values ?</Heading>
								),
								answer: (
									<Text color="grey.900">
										Comme tout investissement, les plus-values sont imposées à hauteur de 30% (flat tax incluant 12,8 %
										au titre de l’impôt sur le revenu et 17,2 % au titre des prélèvements sociaux). Cependant la
										majorité des fonds de Private Equity sont des fonds fiscaux permettant de bénéficier d'une
										exonération de l'impôt sur les plus-values réduisant l'impôt à 17.2% après 5 ans de détention, au
										même titre que le PEA. Le simulateur Ramify prend en compte toutes les exonérations disponibles dans
										les portefeuilles qui vous sont proposés.
									</Text>
								),
							},
							{
								question: <Heading variant="Title-M-SemiBold">Comprendre la fiscalité du Private Equity</Heading>,
								answer: (
									<VStack align="start" w="100%" spacing="12px">
										<Text>
											En fonction du type de véhicule d’investissement la fiscalité de la classe d’actif Private Equity
											:
										</Text>
										<Text>
											<b>FCPR (Fonds Communs de Placement à Risque) </b>
											composés à 50&nbsp;% au moins de sociétés non cotées en Bourse. Les FCPR permettent de bénéficier
											d’une exonération des plus-values réalisées mais les prélèvements sociaux à 17,2&nbsp;% restent
											dus.
										</Text>
										<Text>
											Les FIP et FCPI sont deux catégories de FCPR qui présentent des caractéristiques particulières
											dans leur composition et affichent des avantages fiscaux supplémentaires.
										</Text>
										<Text>
											<b>Les FIP (Fonds d’Investissement de Proximité) </b>
											sont composés de PME de moins de 250 salariés et de moins de 50 millions d’€ de chiffre
											d’affaires. Ces fonds doivent inclure des investissements concentrés sur une région précise avec
											la possibilité d’étendre ses sélections à quatre régions limitrophes. Ils doivent être investis à
											hauteur de 70&nbsp;% minimum au sein de PME.
										</Text>
										<Text>
											<b>Les FCPI (Fonds Communs de Placement dans l’Innovation) </b>
											sont composés de sociétés dites innovantes de moins de 2 000 salariés. Ces types de sociétés sont
											caractérisés par un seuil nécessaire d’investissement en Recherche & Développement (R&D) d’au
											minimum 15&nbsp;% de leurs dépenses globales.
										</Text>
										<Text>
											Les FIP et FCPI permettent de bénéficier d’une réduction d’impôt pouvant aller jusqu’à 25&nbsp;%
											des montants investis.
										</Text>
										<Text>
											Ces réductions d’impôt entrent dans le plafonnement global des niches fiscales et il existe un
											plafond de 12 000 euros pour une personne seule, soit une réduction d’impôt de 3 000 euros si le
											taux est porté à 25&nbsp;% et de 24 000 euros pour un couple partageant le même foyer fiscal, soit
											une réduction d’impôt de de 6 000 euros si le taux est porté à 25&nbsp;%.
										</Text>
									</VStack>
								),
								// "Quelques fonds notamment les FCPI et les FIP offrent des réductions d'impôt. Les FPCI par exemple permettent de bénéficier d'une réduction d'impôt d'environ 25% du montant investi, ce montant est plafonné à 12 000€ ou 24 000€ en fonction du foyer fiscal. Le moteur de recommendation prend en compte tous ces plafonds fiscaux et d'autres comme le plafonnement global des niches fiscales.",
							},
							{
								question: <Heading variant="Title-M-SemiBold">Comment fonctionne le simulateur Ramify ?</Heading>,
								answer: (
									<Text color="grey.900">
										Ce simulateur a pour but de vous proposer le portefeuille Private Equity maximisant votre rendement
										net de frais et net d’impôt. Il prend en compte plusieurs variables spécifiques à votre situation
										personnelle telles que votre objectif d’investissement, votre horizon de placement ainsi que vos
										préférences sectorielles, stratégiques et géographiques.
									</Text>
								),
							},
						]}
					/>
				</VStack>
			)}
		</VStack>
	);
};

export const RecommandationPEResultRouter = () => (
	<>
		<RecommandationPEResult />
		<Routes>
			<Route path=":id" element={<PrivateEquityDetails />} />
		</Routes>
	</>
);

export default RecommandationPEResultRouter;
