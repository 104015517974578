import { backendApi } from 'services/apiService';
import { PEFund, PEFundDetails, PEFundTimeline } from 'store/types/airtable.type';

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getPEFunds: builder.query<PEFund[], void>({
			query: () => '/private-equity/funds',
			providesTags: ['PE'],
		}),
		getPEFundById: builder.query<PEFund, string>({
			query: (id) => `/private-equity/funds/${id}`,
			providesTags: ['PE'],
		}),
		getPEFundDetails: builder.query<PEFundDetails, string>({
			query: (id) => `/private-equity/funds/${id}/details`,
			providesTags: ['PE'],
		}),
		getPEFundTimeline: builder.query<PEFundTimeline, string>({
			query: (id) => `/private-equity/funds/${id}/timeline`,
			providesTags: ['PE'],
		}),
	}),
});

export const { useGetPEFundsQuery, useGetPEFundByIdQuery, useGetPEFundDetailsQuery, useGetPEFundTimelineQuery } =
	extendedApi;
