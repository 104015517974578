import { useMemo } from 'react';

import { useGetKYCQuery } from 'services/requests/kyc';
import { calcLabNetAnnualIncome, isNotNone } from 'utils/functions';

export const useFoyerAnnualNetIncome = () => {
	const { data: kyc } = useGetKYCQuery();

	return useMemo(() => {
		let ret = 0;
		if (kyc && isNotNone(kyc.revenu) && isNotNone(kyc.revenuPeriod) && isNotNone(kyc.revenuType))
			ret = calcLabNetAnnualIncome(kyc.revenu, kyc.revenuPeriod, kyc.revenuType);
		if (
			kyc &&
			(kyc.familySituation === 'MARRIED' || kyc.familySituation === 'CIVIL_UNION') &&
			isNotNone(kyc.revenuSpouse) &&
			isNotNone(kyc.revenuSpousePeriod) &&
			isNotNone(kyc.revenuSpouseType)
		)
			ret += calcLabNetAnnualIncome(kyc.revenuSpouse, kyc.revenuSpousePeriod, kyc.revenuSpouseType);
		return ret;
	}, [kyc]);
};
