import React, { LegacyRef } from 'react';
import { Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react';

import LabelWrapperInput from './LabelWrapperInput';

type InputWithRightElementProps = {
	label?: string;
	rightElement: React.ReactNode;
	tooltip?: string;
};

const InputWithRightElement = React.forwardRef(
	(
		{ label, rightElement, tooltip, ...props }: InputWithRightElementProps & InputProps,
		ref?: LegacyRef<HTMLInputElement>,
	): JSX.Element => (
		<LabelWrapperInput label={label} tooltip={tooltip}>
			<InputGroup>
				<Input min={0} type="number" ref={ref} {...props} onWheel={(event) => event.currentTarget.blur()} />
				<InputRightElement pointerEvents="none" color="grey.900" fontSize="1.2em" children={rightElement} />
			</InputGroup>
		</LabelWrapperInput>
	),
);

export default InputWithRightElement;
