import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProductActions } from './type';

type UseGetActionsPrivateEquityProps = {
	showAppointments?: boolean;
	onCalendly?: () => void;
};

const useGetActionsPrivateEquity = ({
	showAppointments = false,
	onCalendly,
}: UseGetActionsPrivateEquityProps): { actions: ProductActions[] } => {
	const navigate = useNavigate();
	const [state, setState] = useState<ProductActions[]>([]);

	useEffect(() => {
		const options = [
			{
				label: 'Découvrir le catalogue',
				onClick: () => navigate('/private-equity'),
				isAvailable: true,
			},
			{
				label: 'Faire une simulation',
				onClick: () => navigate('/private-equity/simulation/nouvelle'),
				isAvailable: true,
			},
			{
				label: 'Prendre rendez-vous avec un conseiller',
				onClick: onCalendly || (() => {}),
				isAvailable: showAppointments,
			},
		];

		setState(options.filter((o) => o.isAvailable).map((o) => ({ label: o.label, onClick: o.onClick })));
	}, [showAppointments, onCalendly, navigate]);

	return { actions: state };
};

export default useGetActionsPrivateEquity;
