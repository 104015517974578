import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, chakra, Divider, Heading, SlideFade, VStack } from '@chakra-ui/react';
import FoyerFiscalFormContext from 'formContexts/FoyerFiscal';

import { useAppResponsive } from 'hooks/useAppResponsive';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import {
	PESimulationDto,
	PrivateEquitySimulation,
	useCreatePrivateEquitySimulationMutation,
	useUpdatePrivateEquitySimulationMutation,
} from 'services/requests/privateEquity/simulations';
import { IncomePeriod } from 'store/referentiels/incomePeriod';
import { IncomeType } from 'store/referentiels/incomeType';
import { KYC } from 'store/types/KYC.type';

import SimulationPePreferencesInput from './Form/Preferences';
import SimulationPeProjectInput from './Form/Project';
import SimulationPeObjectiveInput from './Form/ProjectInputObjective';

type RecommandationPrivateEquityFormProps = {
	currentSimulation?: PrivateEquitySimulation;
	userKyc?: KYC;
};

const RecommandationPrivateEquityForm = ({
	currentSimulation,
	userKyc,
}: RecommandationPrivateEquityFormProps): JSX.Element => {
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const [createSimulation] = useCreatePrivateEquitySimulationMutation();
	const [updateSimulation] = useUpdatePrivateEquitySimulationMutation();

	const toast = useThemedToast();

	const methods = useForm<PESimulationDto>({
		mode: 'onChange',
		defaultValues: {
			// On autofill avec le KYC si il existe puis on override avec la souscription si elle existe
			childNumber: userKyc?.childNumber || 0,
			familySituation: userKyc?.familySituation,
			professionalSituation: userKyc?.professionalSituation,
			revenuType: userKyc?.revenuType || IncomeType.GROSS,
			revenuPeriod: userKyc?.revenuPeriod || IncomePeriod.ANNUALLY,
			revenuSpouseType: userKyc?.revenuSpouseType || IncomeType.GROSS,
			revenuSpousePeriod: userKyc?.revenuSpousePeriod || IncomePeriod.ANNUALLY,
			revenu: userKyc?.revenu,
			revenuSpouse: userKyc?.revenuSpouse,
			...currentSimulation?.payload,
		},
	});

	const inputObjective = methods.watch('objective');
	const hasCompletedObjective = !(inputObjective === undefined || inputObjective === null);
	const isTimeHorizonValid = methods.getFieldState('timeHorizon').error === undefined;
	const isInitialAumValid = methods.getFieldState('initialAUM').error === undefined;

	const projectInputs = methods.watch(['timeHorizon', 'initialAUM']);
	const hasCompletedProjectStep =
		!(projectInputs.findIndex((v) => !v) !== -1) && isTimeHorizonValid && isInitialAumValid;

	const foyerFiscal = methods.watch([
		'childNumber',
		'professionalSituation',
		'familySituation',
		'revenu',
		'revenuType',
		'revenuPeriod',
		// 'revenuSpouse',
		// 'revenuSpouseType',
		// 'revenuSpousePeriod',
	]);
	const hasCompletedFoyerFiscalStep = !(foyerFiscal.findIndex((v) => !v && v !== 0) !== -1);

	const onSubmit = (data: PESimulationDto) => {
		const saveSimulation = id ? updateSimulation({ id, data }) : createSimulation(data);
		saveSimulation
			.unwrap()
			.then((simulation) => {
				eventTracker.mixpanel.track('SimulationPE-Resultat');
				eventTracker.pipedream.peSimulation();
				navigate(`/private-equity/simulation/${simulation.id}/resultats`);
			})
			.catch(() => {
				toast({
					title: 'Erreur',
					description: 'Les paramètres de votre simulation sont incorrects',
					status: 'error',
					duration: 5000,
				});
			});
	};
	const isMobile = useAppResponsive({ base: true, md: false }) || false;

	return (
		<FormProvider {...methods}>
			<chakra.form onSubmit={methods.handleSubmit(onSubmit)} w="100%">
				<VStack spacing="40px" w="100%">
					<VStack spacing="md" align="start" w="100%">
						<Heading variant="Title-M-SemiBold">Votre projet</Heading>
						<SimulationPeObjectiveInput />

						{hasCompletedObjective && <SimulationPeProjectInput objectiveType={inputObjective} />}
					</VStack>

					{hasCompletedProjectStep && (
						<SlideFade in offsetY="20px" style={{ width: '100%' }}>
							<VStack align="start" w="100%" spacing="32px">
								<Divider />
								<VStack spacing="24px" align="start" w="100%">
									<Heading variant="Title-M-SemiBold">Votre foyer fiscal</Heading>
									<FoyerFiscalFormContext />
								</VStack>
							</VStack>
						</SlideFade>
					)}

					{hasCompletedProjectStep && hasCompletedFoyerFiscalStep && (
						<SlideFade in offsetY="20px" style={{ width: '100%' }}>
							<VStack align="start" w="100%" spacing="32px">
								<Divider />
								<VStack spacing="24px" align="start" w="100%">
									<Heading variant="Title-M-SemiBold">Vos préférences</Heading>
									<SimulationPePreferencesInput />
									<VStack w="100%" align="start" mt="48px !important">
										<Button w={isMobile ? '100%' : '256px'} size="lg" variant="primary" type="submit">
											Valider la simulation
										</Button>
									</VStack>
								</VStack>
							</VStack>
						</SlideFade>
					)}
				</VStack>
			</chakra.form>
		</FormProvider>
	);
};

export default RecommandationPrivateEquityForm;
