import { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from '@carbon/icons-react';
import { Box, Drawer, DrawerContent, DrawerOverlay, Heading, HStack, IconButton, Img } from '@chakra-ui/react';

import LeafBlack from 'assets/logo/leaf-black.svg';

const TopBar = ({
	children,
	pageTitle,
	showPageIcon = false,
	isOpen,
	onOpen,
	onClose,
}: PropsWithChildren<{
	pageTitle?: { label: string; icon?: ReactNode };
	showPageIcon: boolean;
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
}>): JSX.Element => {
	return (
		<Box p="8px" w="100vw">
			<Drawer isOpen={isOpen} placement="left" onClose={onClose}>
				<DrawerOverlay />
				<DrawerContent bg="transparent" shadow="none" w="100%">
					{children}
				</DrawerContent>
			</Drawer>
			<Box
				as="nav"
				w="100%"
				p="12px 16px"
				borderRadius="base"
				bg="grey.100"
				borderColor="grey.300"
				borderWidth="1px"
				justifyContent="space-between"
			>
				<HStack justify="space-between" align="center" w="100%" h="100%" px="24px">
					<Link to="/">
						<Img src={LeafBlack} w="24px" />
					</Link>
					<HStack spacing="8px" ml="0px !important">
						{pageTitle && (
							<>
								{showPageIcon && pageTitle.icon}
								<Heading variant="Title-S-SemiBold">{pageTitle.label}</Heading>
							</>
						)}
					</HStack>
					<IconButton
						aria-label="menu"
						variant="tertiary"
						onClick={onOpen}
						icon={
							<Box>
								<Menu size="24" />
							</Box>
						}
					/>
				</HStack>
			</Box>
		</Box>
	);
};

export default TopBar;
