import { FC } from 'react';
import { Box, Button, Checkbox, HStack, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from '@chakra-ui/react';

import FullscreenLoader from 'components/FullscreenLoader';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { useGetContractsQuery } from 'services/requests/invest/contracts';
import { PopulatedInvestContract } from 'store/types/contract.type';
import colors from 'theme/foundations/colors';
import { isNone } from 'utils/functions';
import { displayMoney } from 'utils/rendering';

import { clearSelector, toogleContract } from './ContractFilter.slice';

const ContractFilter: FC = () => {
	const { data: userContracts, isLoading } = useGetContractsQuery();

	const dispatch = useAppDispatch();
	const { inactiveList } = useAppSelector((state) => state.investContractFilter);
	const isMobile = useAppResponsive({ base: true, md: false });
	const isContractActive = (contract: PopulatedInvestContract) => isNone(inactiveList.find((e) => e === contract.id));

	if (isLoading) return <FullscreenLoader />;
	if (!userContracts || userContracts.length <= 1) return null;

	const activeContracts = userContracts.filter(isContractActive) ?? [];
	const isDisabled = (contract: PopulatedInvestContract) => isContractActive(contract) && activeContracts.length <= 1;
	const shouldPreventToogle = (contract: PopulatedInvestContract) =>
		!(isContractActive(contract) && activeContracts.length <= 1);

	return (
		<Menu closeOnSelect={false} autoSelect={false}>
			{({ onClose }) => (
				<>
					<MenuButton as={Button} variant="secondary" size={isMobile ? 'md' : 'lg'}>
						{userContracts.length === activeContracts.length
							? `Tous les contrats (${activeContracts.length})`
							: `(${activeContracts.length}) Contrat${activeContracts.length > 1 ? 's' : ''} sélectionné${
									activeContracts.length > 1 ? 's' : ''
							  }`}
					</MenuButton>
					<MenuList minW="260px">
						{userContracts.map((contract) => (
							<MenuItem
								key={contract.id}
								isDisabled={isDisabled(contract)}
								w="100%"
								_hover={{ bg: 'grey.100' }}
								onClick={() => dispatch(toogleContract(contract.id))}
							>
								<Tooltip
									key={contract.id}
									bg="grey.100"
									padding="12px 16px"
									boxShadow="none"
									border="base"
									color={colors.grey[700]}
									label="Un contrat au minimum doit être sélectionné"
									shouldWrapChildren
									isDisabled={shouldPreventToogle(contract)}
								>
									<HStack justify="space-between" align="baseline" w="100%">
										<HStack spacing="8px" align="baseline">
											<Checkbox alignSelf="center" pointerEvents="none" isChecked={isContractActive(contract)} />
											<Text>{contract.envelope.name} :</Text>
										</HStack>
										<Text variant="label" fontWeight="bold">
											{displayMoney(contract.amount)}
										</Text>
									</HStack>
								</Tooltip>
							</MenuItem>
						))}
						<MenuItem _hover={{ bg: 'inherit' }} as={Box}>
							<Button
								variant="secondary"
								w="100%"
								size="sm"
								onClick={() => {
									dispatch(clearSelector());
									onClose();
								}}
							>
								Réinitialiser
							</Button>
						</MenuItem>
					</MenuList>
				</>
			)}
		</Menu>
	);
};

export default ContractFilter;
