import { useMemo } from 'react';
import {
	Stack,
	StackProps,
	Step as ChakraStep,
	StepIcon,
	StepIndicator,
	StepNumber,
	Stepper as ChakraStepper,
	StepSeparator,
	StepStatus,
	StepTitle,
	Text,
	VStack,
} from '@chakra-ui/react';

import { useAppResponsive } from 'hooks/useAppResponsive';

type Step = {
	title: string;
};

export interface CustomStepperProps extends StackProps {
	index: number;
	steps: Step[];
	showLabels?: boolean;
	showIndexes?: boolean;
	variant?: string;
}

export const Stepper = ({
	steps,
	index,
	showLabels = true,
	showIndexes = true,
	variant,
	...rest
}: CustomStepperProps): JSX.Element => {
	const stepperVariant = useAppResponsive({ base: 'mobile', sm: undefined });
	const clampedIndex = useMemo(() => Math.max(0, Math.min(index, steps.length - 1)), [index, steps.length]); // clamp index to be between 0 and length of steps

	return (
		<Stack spacing="12px" {...rest}>
			<ChakraStepper w="100%" index={clampedIndex} variant={variant ?? stepperVariant}>
				{steps.map((step) => (
					<ChakraStep key={step.title}>
						<VStack spacing="12px">
							<StepIndicator>
								<StepStatus
									complete={<StepIcon />}
									active={showIndexes && <StepNumber />}
									incomplete={showIndexes && <StepNumber />}
								/>
							</StepIndicator>

							{variant !== 'mobile' && showLabels && <StepTitle>{step.title}</StepTitle>}
						</VStack>

						<StepSeparator />
					</ChakraStep>
				))}
			</ChakraStepper>

			{variant && ['mobile', 'subscription'].includes(variant) && (
				<Text>
					Etape {clampedIndex + 1} : {steps[clampedIndex]?.title}
				</Text>
			)}
		</Stack>
	);
};
