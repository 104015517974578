import { CivilStatus } from 'store/referentiels/civilStatus';
import { FamilySituation } from 'store/referentiels/familySituation';
import { IncomePeriod } from 'store/referentiels/incomePeriod';
import { IncomeType } from 'store/referentiels/incomeType';
import { MatrimonialRegime } from 'store/referentiels/matrimonial';
import { PatrimonySource } from 'store/referentiels/patrimonySource';
import { ProfessionalCategory } from 'store/referentiels/professionalCategory';
import { ProfessionalSector } from 'store/referentiels/professionalSector';
import { ProfessionalSituation } from 'store/referentiels/professionalSituation';

export type KYC = {
	id: string;
	userId: string;
	createdAt: Date;
	updatedAt: Date;
	lastName?: string;
	firstName?: string;
	civilStatus?: CivilStatus;
	familySituation?: FamilySituation;
	matrimonialRegime?: MatrimonialRegime;
	citizenship?: string;
	birthDate?: Date;
	birthCountry?: string;
	birthCity?: string;
	birthZipCode?: string;
	birthCityCode?: string;
	birthName?: string;
	address?: string;
	phoneNumber?: string;
	phoneNumberCode?: string;
	country?: string;
	city?: string;
	zipCode?: string;
	professionalSector?: ProfessionalSector;
	professionalCategory?: ProfessionalCategory;
	professionalSituation?: ProfessionalSituation;
	companyName?: string;
	profession?: string;
	selfEmployed?: boolean;
	siret?: string;

	patrimonyEstimateRealEstate?: number;
	patrimonyEstimateBankAccount?: number;
	patrimonyEstimatePortfolio?: number;
	patrimonyEstimateProfessionalAssets?: number;
	patrimonyEstimateRetirement?: number;
	patrimonyEstimateCcontractAVCapitalisation?: number;
	patrimonyPrimaryEstimateOther?: number;
	patrimonyEstimatePrimaryRealEstate?: number;
	patrimonyEstimateSecondaryRealEstate?: number;
	patrimonyEstimateOther?: number;
	patrimonySources: PatrimonySource[];

	patrimonyRealEstate: number;
	patrimonyNonCote: number;
	nif?: string;

	childNumber: number;
	revenu: number;
	revenuType: IncomeType;
	revenuPeriod: IncomePeriod;
	revenuSpouse: number;
	revenuSpouseType: IncomeType;
	revenuSpousePeriod: IncomePeriod;

	isPoliticallyExposed?: boolean;
	isUSPerson?: boolean;
};

export type ReferentielDTO = {
	code: string;
	libelle?: string;
	modifiedDate?: string;
};

export type EtatCivilType = {
	prenom: string;
	nom: string;
	dateNaissance: string;
	situationFamiliale: FamilySituation;
	civilite: CivilStatus;
	nationalite: string;
	pays: string;
	villeNaissance: ReferentielDTO;
	nomNaissance?: string;
	regimeMatrimonial?: MatrimonialRegime;
};

export type SituationProfessionnelleType = {
	categorieSocioProfessionnelle: ProfessionalCategory;
	secteurActivite: ProfessionalSector;
	situationActuelle: ProfessionalSituation;
	travailleurNonSalarie: boolean;
	professionActuelle: string;
	nomEntreprise?: string;
	siret?: string;
};

export type UpdateNifDTO = Pick<KYC, 'nif'>;

export type UpdateKycFromScpiPeDTO = Pick<KYC, 'nif' | 'patrimonyNonCote' | 'patrimonyRealEstate'>;

export interface FoyerFiscalType {
	professionalSituation: ProfessionalSituation;
	familySituation: FamilySituation;
	regimeMatrimonial?: MatrimonialRegime;
	childNumber: number;
	revenu: number;
	revenuType: IncomeType;
	revenuPeriod: IncomePeriod;
	revenuSpouse: number;
	revenuSpouseType: IncomeType;
	revenuSpousePeriod: IncomePeriod;
	// fiscalAddress: string; disabled for now
}

export interface CommonReglementaryQuestionsType {
	isPoliticallyExposed: boolean;
	isUSPerson: boolean;
}

export type AdresseDTO = {
	adressePrincipale: string;
	codePostal: string;
	pays: string;
	ville: string;
	adresseComplementaire?: string;
};

export type TelephoneMobileDTO = {
	indicatif: string;
	numeroTelephone: string;
};

export type CoordonneesDTO = {
	adresse: AdresseDTO;
	telephoneMobile: TelephoneMobileDTO;
};

export type PatrimonyEstimateDTO = {
	primaryResidence: number;
	secondaryResidence: number;
	bankAccounts: number;
	capitalisation: number;
	portfolio: number;
	professionalAssets: number;
	retirement: number;
	others: number;
};
export type PatrimonySourceDTO = PatrimonySource[];
export type PatrimoineDTO = {
	patrimonyEstimate: PatrimonyEstimateDTO;
	patrimonySources: PatrimonySourceDTO;
};

export const patrimonySourcesLabels: Record<PatrimonySource, string> = {
	SAVINGS: 'Epargne, revenus',
	INHERITANCE: 'Succession / Donation',
	GAMBLE_GAINS: 'Gain aux jeu',
	PRO_ASSETS: 'Cession d’actif(s) professionnel(s)',
	REAL_ESTATE_ASSETS: 'Cession d’actif(s) immobilier(s)',
	ASSETS_SELLOUT: 'Cession d’actif(s) mobilier(s)',
	OTHER: 'Autre',
};

export type LoanKyc = {
	hasRealEstateLoan: boolean;
	realEstateLoanFrequency: 'MONTHLY' | 'ANNUALLY' | 'IN_FINE';
	realEstateLoanRest: number;
	hasConsomationLoan: boolean;
	consomationLoanFrequency: 'MONTHLY' | 'ANNUALLY' | 'IN_FINE';
	consomationLoanRest: number;
	hasOtherLoan: boolean;
	otherLoanFrequency: 'MONTHLY' | 'ANNUALLY' | 'IN_FINE';
	otherLoanRest: number;
};

export type PeScpiKyc = LoanKyc & {
	primaryResidenceStatus: 'OWNER_WITH_LOAN' | 'OWNER_WITHOUT_LOAN' | 'FREELY_HOSTED' | 'TENANT';
	savingCapacity: number;
};

export type UserPeScpiKyc = Partial<PeScpiKyc>;

export type RequestUpdateKyc = {
	hasChanged: boolean;
};

export type SignUpdateKyc = {
	otp: string;
};
