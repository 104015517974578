import { FC, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Skeleton, VStack } from '@chakra-ui/react';

import FinalizingSubscriptionList from 'components/cards/subscriptions/FinalizingSubscriptionList';
import ValidatingSubscriptionList from 'components/cards/subscriptions/ValidatingSubscriptionList';
import { useGetUserSCPISubscriptionsQuery } from 'services/requests/realEstate/subscriptions';
import { FundSubscriptionStatus } from 'store/types/airtable.type';
import { ProductSubscriptionType, ProductType } from 'store/types/global.type';
import { isNotNone } from 'utils/functions';

const RealEstateSubscriptionRoutes = () => (
	<Routes>
		<Route path=":id?" element={<RealEstateSubscriptions />} />
	</Routes>
);

const RealEstateSubscriptions: FC = () => {
	const { data: subscriptions, isFetching: isFetchingSubscriptions } = useGetUserSCPISubscriptionsQuery();

	const formattedSubscriptions = useMemo<ProductSubscriptionType[] | undefined>(() => {
		if (!subscriptions) return undefined;

		return subscriptions.map((sub) => ({
			...sub,
			typeProject: ProductType.SCPI,
		}));
	}, [subscriptions]);

	if (isFetchingSubscriptions || !subscriptions) return null;

	return (
		<Skeleton isLoaded={isNotNone(formattedSubscriptions)} w="100%" minH={isFetchingSubscriptions ? '140px' : '0px'}>
			<VStack w="100%" align="start" spacing="lg">
				<FinalizingSubscriptionList
					subscriptions={formattedSubscriptions?.filter((sub) => sub.status === FundSubscriptionStatus.REQUESTED)}
				/>
				<ValidatingSubscriptionList
					subscriptions={formattedSubscriptions?.filter(
						(sub) => sub.status !== FundSubscriptionStatus.REQUESTED && sub.status !== FundSubscriptionStatus.COMPLETED,
					)}
				/>
			</VStack>
		</Skeleton>
	);
};

export default RealEstateSubscriptionRoutes;
