import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, chakra, Divider, SlideFade, VStack } from '@chakra-ui/react';
import FoyerFiscalFormContext from 'formContexts/FoyerFiscal';

import { RecommendationQuestion, RecommendationTooltipQuestion } from 'components/inputs/RecommendationTooltipQuestion';
import { useAppResponsive } from 'hooks/useAppResponsive';
import {
	// FoyerFiscal,
	ISRPreferences,
	MontantInvestissement,
	NuePropertyPreferences,
	Preferences,
	TimeHorizon,
} from 'pages/RealEstate/Simulations/Survey/Form/AnswerComponents';
import eventTracker from 'services/events/eventTracker';
import {
	RealEstateSimulation,
	RealEstateSimulationDto,
	useCreateRealEstateSimulationsMutation,
	useUpdateRealEstateSimulationsMutation,
} from 'services/requests/realEstate/simulations';
import { IncomePeriod } from 'store/referentiels/incomePeriod';
import { IncomeType } from 'store/referentiels/incomeType';
import { KYC } from 'store/types/KYC.type';
import { environmentConstants } from 'utils/constants';
import toast from 'utils/standaloneToast';

interface RecommandationSCPIFormProps {
	currentSimulation?: RealEstateSimulation;
	userKyc?: KYC;
}

const RecommandationSCPIForm = ({ currentSimulation, userKyc }: RecommandationSCPIFormProps): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const [createSimulation] = useCreateRealEstateSimulationsMutation();
	const [updateSimulation] = useUpdateRealEstateSimulationsMutation();
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;

	const navigate = useNavigate();

	const methods = useForm<RealEstateSimulationDto>({
		mode: 'onChange',
		defaultValues: {
			// On autofill avec le KYC si il existe puis on override avec la souscription si elle existe
			childNumber: userKyc?.childNumber || 0,
			familySituation: userKyc?.familySituation,
			professionalSituation: userKyc?.professionalSituation,
			revenuType: userKyc?.revenuType || IncomeType.GROSS,
			revenuPeriod: userKyc?.revenuPeriod || IncomePeriod.ANNUALLY,
			revenuSpouseType: userKyc?.revenuSpouseType || IncomeType.GROSS,
			revenuSpousePeriod: userKyc?.revenuSpousePeriod || IncomePeriod.ANNUALLY,
			revenu: userKyc?.revenu,
			revenuSpouse: userKyc?.revenuSpouse,
			...currentSimulation?.payload,
			isISR: currentSimulation?.payload.isISR ?? false,
			prefDemembrement: currentSimulation?.payload.prefDemembrement ?? 'ramify',
		},
	});

	const projectInputs = methods.watch(['timeHorizon', 'initialAUM']);

	const isTimeHorizonValid = methods.getFieldState('timeHorizon').error === undefined;
	const isInitialAumValid = methods.getFieldState('initialAUM').error === undefined;

	const hasCompletedProjectStepOne =
		!(projectInputs.findIndex((v) => !v) !== -1) && isTimeHorizonValid && isInitialAumValid;

	const foyerFiscal = methods.watch([
		'childNumber',
		'familySituation',
		'revenu',
		'revenuType',
		'revenuPeriod',
		// 'revenuSpouse',
		// 'revenuSpouseType',
		// 'revenuSpousePeriod',
	]);
	const hasCompletedFoyerFiscalStep = !(foyerFiscal.findIndex((v) => !v && v !== 0) !== -1);

	const questions: RecommendationQuestion[] = [
		{
			question: 'Votre Projet',
			component: (
				<>
					<MontantInvestissement />
					<TimeHorizon />
				</>
			),
		},
		{
			question: 'Votre foyer fiscal',
			component: <FoyerFiscalFormContext />,
		},
		{
			question: 'Vos préférences',
			component: (
				<>
					<ISRPreferences />
					<NuePropertyPreferences />
					<Preferences />
				</>
			),
		},
	];

	const onSubmit = (data: RealEstateSimulationDto) => {
		const saveSimulation = id ? updateSimulation({ id, data }) : createSimulation(data);
		saveSimulation
			.unwrap()
			.then((simulation) => {
				if (environmentConstants.environment === 'production') {
					eventTracker.pipedream.scpiSimulation();
					eventTracker.pipedream.scpiInterest();
					eventTracker.mixpanel.track('SimulationSCPI-Resultat');
				}
				navigate(`/immobilier/simulation/${simulation.id}/resultats`);
			})
			.catch((e) => {
				console.error(e.data.message);
				toast({
					title: 'Une erreur est survenue',
					description: 'Les paramètres de votre simulation sont incorrects',
					status: 'error',
					duration: 5000,
				});
			});
	};

	return (
		<FormProvider {...methods}>
			<chakra.form onSubmit={methods.handleSubmit(onSubmit)} w="100%">
				<VStack spacing="40px" align={isMobile ? 'center' : 'start'} w="100%">
					{questions.slice(0, 1).map((q) => (
						<VStack align="start" w="100%" spacing="48px" key={q.question}>
							<RecommendationTooltipQuestion question={q.question} component={q.component} />
						</VStack>
					))}

					{hasCompletedProjectStepOne && (
						<SlideFade in offsetY="20px" style={{ width: '100%' }}>
							<VStack align="start" w="100%" spacing="32px">
								<Divider />
								{questions.slice(1, 2).map((q) => (
									<VStack align="start" w="100%" spacing="48px" key={q.question}>
										<RecommendationTooltipQuestion question={q.question} component={q.component} />
									</VStack>
								))}
							</VStack>
						</SlideFade>
					)}

					{hasCompletedProjectStepOne && hasCompletedFoyerFiscalStep && (
						<SlideFade in offsetY="20px" style={{ width: '100%' }}>
							<VStack w="100%" align="start" spacing="24px">
								<VStack align="start" w="100%" spacing="32px">
									<Divider />
									{questions.slice(2, 3).map((q) => (
										<VStack align="start" w="100%" spacing="48px" key={q.question}>
											<RecommendationTooltipQuestion question={q.question} component={q.component} />
										</VStack>
									))}
									<Button w={isMobile ? '100%' : '256px'} size="lg" variant="primary" type="submit">
										Valider la simulation
									</Button>
								</VStack>
							</VStack>
						</SlideFade>
					)}
				</VStack>
			</chakra.form>
		</FormProvider>
	);
};

export default RecommandationSCPIForm;
