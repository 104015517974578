import useThemedToast from 'hooks/useThemedToast';
import { checkEmail } from 'pages/Auth/utils';
import eventTracker from 'services/events/eventTracker';
import { useSendSponsorshipInvitationMutation } from 'services/requests/sponsorship';

interface UseAffiliateSendReturnType {
	sendEmail: (email: string) => Promise<boolean>;
	isLoading: boolean;
}

const useInviteAffiliate = (): UseAffiliateSendReturnType => {
	const [sendInvitation, { isLoading: sendingInvitation }] = useSendSponsorshipInvitationMutation();
	const toast = useThemedToast();

	const sendEmail: UseAffiliateSendReturnType['sendEmail'] = async (email) => {
		if (!checkEmail(email)) {
			toast({ title: 'Email invalide', status: 'error', isClosable: true, duration: 3000 });
			return false;
		}
		sendInvitation(email)
			.unwrap()
			.then(() => {
				toast({
					title: 'Une invitation a été envoyée',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				eventTracker.mixpanel.sponsorInvitationSent(email);
				eventTracker.pipedream.newAffiliate(email);
				return true;
			})
			.catch((error) => {
				toast({
					title: "Une erreur s'est produite.",
					description: (error.data as { message: string }).message,
					status: 'error',
					duration: 4500,
					isClosable: true,
				});
				return false;
			});
		return false;
	};

	return { sendEmail, isLoading: sendingInvitation };
};

export default useInviteAffiliate;
