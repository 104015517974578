import { Download } from '@carbon/icons-react';
import { Button, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';

import FullscreenLoader from 'components/FullscreenLoader';
import { useTrackPageVisit } from 'hooks/useTrackPageVisit';
import { downloadDocument, useGetDocumentsQuery } from 'services/requests/document';

const Documents = (): JSX.Element => {
	const { data: documents, isLoading: documentLoading, isError } = useGetDocumentsQuery();

	useTrackPageVisit('Compte - Mes documents');

	if (documentLoading) return <FullscreenLoader />;

	return (
		<VStack spacing="lg" align="start" w="100%">
			<Text variant="Text-M-Regular">L’ensemble des pièces justificatives relatives à vos contrats.</Text>

			{isError ? (
				<Text>
					Une erreur est survenue lors du chargement de vos documents. Veuillez réessayer plus tard ou contacter le
					support.
				</Text>
			) : (
				<TableContainer w="100%">
					<Table>
						<Thead>
							<Tr>
								<Th>
									<Text variant="Caption" color="grey.900">
										Nom
									</Text>
								</Th>
								<Th>
									<Text variant="Caption" color="grey.900">
										Date de modification
									</Text>
								</Th>
								<Th />
							</Tr>
						</Thead>
						<Tbody>
							{documents!
								.filter(({ key }) => !key.includes('bulletin'))
								.map((file) => (
									<Tr key={file.key}>
										<Td>
											<Text variant="Text-M-Regular">{file.key.split('/').at(-1)}</Text>
										</Td>
										<Td>
											<Text variant="Text-M-Regular">
												{dayjs(new Date(Date.parse(file.documentInfos.LastModified))).format('DD/MM/YYYY')}
											</Text>
										</Td>
										<Td>
											<Button
												variant="secondary"
												onClick={() =>
													downloadDocument({
														filepath: file.key,
														contentType: file.documentInfos.Metadata.mimetype,
														filename: file.documentInfos.Metadata.originalname,
													})
												}
												w="100%"
												rightIcon={<Download size="16" />}
											>
												Télécharger
											</Button>
										</Td>
									</Tr>
								))}
						</Tbody>
					</Table>
				</TableContainer>
			)}
		</VStack>
	);
};

export default Documents;
