import { MainStepType, OnboardingStep } from 'onboarding/Stepper/stepper.slice';

export enum UpdateKycOnboardingSteps {
	INTRO = 'intro',
	KYC = 'kyc',
	SIGNATURE = 'signature',
}

const steps: OnboardingStep<UpdateKycOnboardingSteps>[] = [
	{
		id: UpdateKycOnboardingSteps.KYC,
		name: 'Informations personnelles',
		url: 'connaissance-client',
		type: MainStepType.COURSE,
		innerSteps: [
			{
				id: 'etatCivil',
				name: 'État civil',
				url: 'connaissance-client/etatCivil',
			},
			{
				id: 'foyerFiscal',
				name: 'Foyer fiscal',
				url: 'connaissance-client/foyerFiscal',
			},
			{
				id: 'professionnelle',
				name: 'Situation professionnelle',
				url: 'connaissance-client/professionelle',
			},
			{
				id: 'coordonnees',
				name: 'Coordonnées',
				url: 'connaissance-client/coordonnees',
			},
			{
				id: 'patrimoine',
				name: 'Patrimoine',
				url: 'connaissance-client/patrimoine',
			},
			{
				id: 'recapitulatif',
				name: 'Validation',
				url: 'connaissance-client/recapitulatif',
			},
		],
	},
	{
		id: UpdateKycOnboardingSteps.SIGNATURE,
		name: 'Signature',
		url: 'signature',
		type: MainStepType.END,
	},
];

export default steps;
