import { Navigate } from 'react-router-dom';
import { Button, Text, VStack, Wrap } from '@chakra-ui/react';

import FullscreenLoader from 'components/FullscreenLoader';
import useSubscriptionList from 'hooks/useSubscriptionList';
import isProposalSubscription from 'pages/Subscriptions/features/isPropalSubscription';
import CPMProfileCard from 'pages/Subscriptions/Proposals/CPMProfileCard';
import { useGetLatestProposalQuery } from 'services/requests/proposal';
import { FundSubscriptionStatus } from 'store/types/airtable.type';
import { SavingsAccountStatus } from 'store/types/savingsAccount.type';
import { SubscriptionStatus } from 'store/types/subscription.type';

import SubscriptionsSection from './SubscriptionsSection';

const Proposals = (): JSX.Element => {
	// use only one proposal: Decided by Samy (Aug 25 2023)
	// todo: when we create a new proposal, we need to delete any other one
	const { data: proposal, isLoading: isProposalLoading } = useGetLatestProposalQuery();
	const { subscriptionList, isLoading } = useSubscriptionList();

	if (isLoading || isProposalLoading) return <FullscreenLoader />;
	if (subscriptionList.length === 0) return <Navigate to="/" replace />;
	if (!proposal) return <Navigate to="/souscriptions" />;

	const availableSubscriptions = subscriptionList.filter(
		(sub) =>
			(sub.typeProject === 'INVEST'
				? sub.status === SubscriptionStatus.CREATED || sub.status === SubscriptionStatus.GENERATED
				: sub.status === SavingsAccountStatus.REQUESTED || sub.status === FundSubscriptionStatus.REQUESTED) &&
			isProposalSubscription(proposal, sub.id),
	);
	const currentSubscriptions = subscriptionList.filter(
		(sub) =>
			(sub.typeProject === 'INVEST'
				? sub.status !== SubscriptionStatus.CREATED && sub.status !== SubscriptionStatus.GENERATED
				: sub.status !== SavingsAccountStatus.REQUESTED && sub.status !== FundSubscriptionStatus.REQUESTED) &&
			isProposalSubscription(proposal, sub.id),
	);

	const isEmpty = availableSubscriptions.length === 0 && currentSubscriptions.length === 0;

	return (
		<VStack w="100%" align="start" spacing="32px">
			<VStack w="100%" spacing="32px" align="start">
				{!isEmpty && (
					<Text variant="Text-M-Regular">
						Vous trouverez ci-dessous les souscriptions proposées par votre conseiller dédié :
					</Text>
				)}
				<Wrap shouldWrapChildren justify="space-between" w="100%">
					<CPMProfileCard cpm={proposal.CPM} />
					{proposal.notionURL && (
						<Button variant="super" onClick={() => window.open(proposal.notionURL)}>
							Accéder à ma proposition
						</Button>
					)}
				</Wrap>
				{isEmpty ? (
					<Text>Vous avez déjà souscrit à toutes les souscriptions proposées par votre conseiller.</Text>
				) : (
					<>
						{proposal.message && <Text variant="Text-S-Medium">{proposal.message}</Text>}

						<SubscriptionsSection
							availableSubscriptions={availableSubscriptions}
							currentSubscriptions={currentSubscriptions}
						/>
					</>
				)}
			</VStack>
		</VStack>
	);
};

export default Proposals;
