import { AlignBoxMiddleLeft, Calendar, ChartRose, PiggyBankSlot, Receipt, ToolBox, User } from '@carbon/icons-react';
import { Heading, HStack, Skeleton, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import { useFees } from 'hooks/useFees';
import { useGetContractApicilInfosQuery } from 'services/requests/invest/contracts';
import { PopulatedInvestContract } from 'store/types/contract.type';
import colors from 'theme/foundations/colors';
import { displayMoney, formatDateStandart } from 'utils/rendering';

const Info = ({
	icon,
	label,
	value,
	tooltipText,
}: {
	label: string;
	value: string;
	icon: JSX.Element;
	tooltipText?: string;
}) => (
	<HStack spacing="16px" w="100%">
		{icon}
		<VStack spacing="4px" align="start">
			<HStack spacing="8px" align="center">
				<Text variant="Text-S-Medium" color="grey.900">
					{label}
				</Text>
				{tooltipText && <CustomTooltip text={tooltipText} />}
			</HStack>
			<Text variant="Text-M-Medium" color={colors.primary.black}>
				{value}
			</Text>
		</VStack>
	</HStack>
);

const ContractInfos = ({ contract }: { contract: PopulatedInvestContract }) => {
	const { data: contractApicil, isFetching: isContractApicilFetching } = useGetContractApicilInfosQuery({
		contractId: contract.id,
	});
	const fees = useFees();

	if (isContractApicilFetching) return <Skeleton h="200px" w="100%" />;

	return (
		<VStack align="start" spacing="16px" w="100%">
			<Heading variant="Title-L-SemiBold">Contrat</Heading>
			<VStack align="start">
				<Wrap w="100%" spacing="18px">
					<WrapItem flex="1" minW="250px">
						<Info
							icon={<User size="32" />}
							label="Souscripteur"
							value={`${contractApicil?.souscripteur?.prenom} ${contractApicil?.souscripteur?.nom}`}
						/>
					</WrapItem>

					<WrapItem flex="1" minW="250px">
						<Info
							icon={<Calendar size="32" />}
							label="Date de début du contrat"
							value={formatDateStandart(new Date(contract.createdAt))}
						/>
					</WrapItem>

					<WrapItem flex="1" minW="250px">
						<Info
							icon={<AlignBoxMiddleLeft size="32" />}
							label="Numéro de contrat"
							value={contractApicil?.contratId || ''}
						/>
					</WrapItem>

					<WrapItem flex="1" minW="250px">
						<Info
							icon={<Receipt size="32" />}
							label="Frais du contrat"
							tooltipText={` Ces frais comprennent les frais d'enveloppes ( ${fees.envelope}% ) + les frais de gestion de Ramify `}
							value={`${contractApicil?.tauxFraisEncoursUniteCompte.toLocaleString('fr-FR')} %`}
						/>
					</WrapItem>

					<WrapItem flex="1" minW="250px">
						<Info icon={<ToolBox size="32" />} label="Valeur du contrat" value={displayMoney(+contract.amount)} />
					</WrapItem>
					<WrapItem flex="1" minW="250px">
						<Info icon={<ChartRose size="32" />} label="Frais sur les versements" value="0 %" />
					</WrapItem>

					<WrapItem flex="1" minW="250px">
						<Info
							icon={<PiggyBankSlot size="32" />}
							label="Somme des versements"
							value={displayMoney(+contract.totalVersement!)}
						/>
					</WrapItem>
				</Wrap>
			</VStack>
		</VStack>
	);
};

export default ContractInfos;
