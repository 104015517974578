import { Route, Routes, useNavigate } from 'react-router-dom';

import { KycStepProps } from 'onboarding/KYC/utils';

import JustificatifList from './Sections/JustificatifList';
import JustificatifUploadDrawer from './Sections/JustificatifUploadDrawer';
import { JustificatifType } from './type';

const Justificatifs = ({ onNext, onPrev }: KycStepProps): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Routes>
			<Route
				path=":id?"
				element={
					<>
						<JustificatifList
							onNext={onNext}
							onPrev={onPrev}
							onClickAdd={(justificatif: JustificatifType) => navigate(justificatif, { preventScrollReset: true })}
						/>
						<JustificatifUploadDrawer onClose={() => navigate('', { preventScrollReset: true })} />
					</>
				}
			/>
		</Routes>
	);
};

export default Justificatifs;
