import React, { LegacyRef, useCallback, useState } from 'react';
import { View, ViewOff } from '@carbon/icons-react';
import { Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react';

export const ShowHidePasswordInput = React.forwardRef(
	(props: InputProps, ref: LegacyRef<HTMLInputElement>): JSX.Element => {
		const [showPassword, setShowPassword] = useState(false);

		const toggleShowPassword = useCallback(() => {
			setShowPassword(!showPassword);
		}, [showPassword, setShowPassword]);

		return (
			<InputGroup>
				<Input {...props} ref={ref} type={showPassword ? 'text' : 'password'} />
				<InputRightElement padding={props.padding}>
					{showPassword ? (
						<View size="20" onClick={toggleShowPassword} cursor="pointer" />
					) : (
						<ViewOff size="20" onClick={toggleShowPassword} cursor="pointer" />
					)}
				</InputRightElement>
			</InputGroup>
		);
	},
);
