import { HStack, Text, VStack } from '@chakra-ui/react';
import { defaultStyles, TooltipWithBounds } from '@visx/tooltip';

import { TooltipData } from 'components/Graphs/visx/ToolTipGraphMobile';
import colors from 'theme/foundations/colors';

export type TooltipDesktopProps = {
	tooltipLeft: number;
	tooltipTop: number;
	tooltipData: TooltipData[];
};

export default function TooltipDesktop({ tooltipLeft, tooltipTop, tooltipData }: TooltipDesktopProps) {
	return (
		<TooltipWithBounds
			top={tooltipTop + 16}
			left={tooltipLeft + 12}
			style={{
				...defaultStyles,
				padding: 16,
				borderRadius: 8,
				backgroundColor: colors.primary.black,
				color: '#718096',
			}}
		>
			<VStack align="start" bg={colors.primary.black}>
				{tooltipData.map((data) => (
					<HStack key={data.title}>
						<Text variant="Text-S-Medium" color={colors.grey[500]}>
							{data.title}
						</Text>
						<Text variant="Text-S-Bold" color={colors.primary.white}>
							{data.value}
						</Text>
					</HStack>
				))}
			</VStack>
		</TooltipWithBounds>
	);
}
