import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowLeft, Calendar, Portfolio, Report } from '@carbon/icons-react';
import {
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	HStack,
	Icon,
	IconButton,
	Skeleton,
	Text,
	VStack,
} from '@chakra-ui/react';

import DocumentsToDownloadDashboard from 'components/products/DocumentsToDownload';
import CardFundDashboard from 'components/products/FundCard';
import { useDrawer } from 'hooks/useDrawer';
import { useGetImmobilierDashboardSubscriptionsQuery } from 'services/requests/realEstate';
import { useGetSCPIFundByIdQuery } from 'services/requests/realEstate/funds';
import { useGetUserSCPISubscriptionByIdQuery } from 'services/requests/realEstate/subscriptions';
import { SCPISubscription } from 'store/types/airtable.type';
import { isNone } from 'utils/functions';
import { displayMoney, formatDateStandart } from 'utils/rendering';

import InfosSubscriptionImmo from './InfosSubscription';

const ContractInfo: FC<{ subscription: SCPISubscription }> = ({ subscription }) => (
	<VStack spacing="16px" align="start">
		<Heading variant="Title-L-SemiBold">Contrat</Heading>

		<HStack spacing="16px">
			<Icon boxSize="32px" as={Report} />
			<VStack align="start" spacing="4px">
				<Text variant="Text-S-Medium" color="grey.900">
					Type de projet
				</Text>
				<Text variant="Text-M-Medium">
					{subscription.projectType === 'NUE_PROPRIETE' ? 'Nue propriété' : 'Pleine propriété'}
				</Text>
			</VStack>
		</HStack>

		<HStack spacing="16px">
			<Icon boxSize="32px" as={Report} />
			<VStack align="start" spacing="4px">
				<Text variant="Text-S-Medium" color="grey.900">
					Nombre de parts
				</Text>
				<Text variant="Text-M-Medium">{subscription.nbShare}</Text>
			</VStack>
		</HStack>

		<HStack spacing="16px">
			<Icon boxSize="32px" as={Portfolio} />
			<VStack align="start" spacing="4px">
				<Text variant="Text-S-Medium" color="grey.900">
					Montant Investi
				</Text>
				<Text variant="Text-M-Medium">{displayMoney(subscription.amountVersement)}</Text>
			</VStack>
		</HStack>

		<HStack spacing="16px">
			<Icon boxSize="32px" as={Calendar} />
			<VStack align="start" spacing="4px">
				<Text variant="Text-S-Medium" color="grey.900">
					Date de début du contrat
				</Text>
				<Text variant="Text-M-Medium">{formatDateStandart(subscription.signedAt)}</Text>
			</VStack>
		</HStack>
	</VStack>
);

const DashboardFundImmo = (): JSX.Element => {
	const { id } = useParams<{ id: string }>();

	const { data: subscriptions, isFetching: areSubscriptionsFetching } = useGetImmobilierDashboardSubscriptionsQuery();
	const { data: subscription, isFetching: isSubscriptionFetching } = useGetUserSCPISubscriptionByIdQuery(
		{ id: id! },
		{ skip: isNone(id) || areSubscriptionsFetching },
	);
	const { data: fund } = useGetSCPIFundByIdQuery(subscription?.fund as string, {
		skip: isNone(subscription?.fund) || isSubscriptionFetching,
	});
	const subscriptionMetrics = subscriptions?.filter((sub) => sub.id === id)[0];

	const { isOpen, onClose } = useDrawer();

	return (
		<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isOpen} onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<HStack>
						<IconButton onClick={onClose} variant="icon" aria-label="back" icon={<ArrowLeft size="32" />} />
						<Heading variant="Title-M-SemiBold">Immobilier</Heading>
					</HStack>
				</DrawerHeader>

				<DrawerBody>
					{subscriptionMetrics && subscription && fund && !areSubscriptionsFetching && !isSubscriptionFetching ? (
						<VStack w="100%" spacing="32px" align="start">
							<CardFundDashboard fund={fund} subscription={subscription} />

							<InfosSubscriptionImmo metrics={subscriptionMetrics} subscription={subscription} fund={fund} />

							<ContractInfo subscription={subscription} />

							<DocumentsToDownloadDashboard subscription={subscription} />
						</VStack>
					) : (
						<Skeleton height="500px" w="100%" />
					)}
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default DashboardFundImmo;
