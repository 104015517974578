import { extendTheme } from '@chakra-ui/react';

import Accordion from 'theme/components/accordion';
import Drawer from 'theme/components/drawer';
import Menu from 'theme/components/menu';
import Progress from 'theme/components/progress';
import Tabs from 'theme/components/tabs';
import Tag from 'theme/components/tag';
import spacing from 'theme/foundations/spacing';

// Components overrides
import Badge from './components/badge';
import Button from './components/button';
import Card from './components/card';
import Checkbox from './components/checkbox';
import FormError from './components/form-error-message';
import FormLabel from './components/form-label';
import Heading from './components/heading';
import Input from './components/input';
import Link from './components/link';
import NumberInput from './components/number-input';
import PinInput from './components/pin-input';
import Radio from './components/radio';
import Select from './components/select';
import Slider from './components/slider';
import Stepper from './components/stepper';
import Text from './components/text';
import { borderRadius, borders } from './foundations/borders';
import colors from './foundations/colors';
// Foundations overrides
import fonts from './foundations/fonts';
import shadows from './foundations/shadows';

const breakpoints = {
	sm: '576px',
	md: '768px',
	lg: '1024px',
	xl: '1280px',
	'2xl': '1440px',
	'3xl': '1921px',
};

const overrides = {
	// Foundations
	shadows,
	fonts,
	radii: borderRadius,
	borders,
	colors,
	space: spacing,

	// //
	breakpoints,
	components: {
		Radio,
		Card,
		Checkbox,
		Badge,
		Select,
		PinInput,
		Button,
		Heading,
		Text,
		FormLabel,
		FormError,
		Input,
		Link,
		Slider,
		NumberInput,
		Tag,
		Accordion,
		Menu,
		Tabs,
		Stepper,
		Progress,
		Drawer,
	},
};

export default extendTheme(overrides);
