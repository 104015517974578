import { FC } from 'react';
import { Box, Button, Card, Heading, Hide, HStack, Image, Show, Stack, Text, VStack } from '@chakra-ui/react';

import AVIconGrey from 'assets/icons/SideBar/Av.svg';
import PERIconGrey from 'assets/icons/SideBar/Per.svg';
import { EnvelopeType } from 'store/types/global.type';
import { isNotNone } from 'utils/functions';
import { displayMoney, formatDateStandart } from 'utils/rendering';

export type ProgrammedVersementCardProps = {
	type: EnvelopeType;
	montant?: number | string;
	startDate?: string;
	onClickCreate?: () => void;
	onClickModify?: () => void;
	onClickDelete?: () => void;
	contractAmount: number | string;
};

const ProgrammedVersementCard: FC<ProgrammedVersementCardProps> = ({
	type,
	montant,
	onClickModify,
	onClickDelete,
	onClickCreate,
	startDate,
	contractAmount,
}) => (
	<Card size="md" w="100%">
		<Stack direction={{ base: 'column', md: 'row' }} spacing="24px" justify="space-between">
			<Stack direction={{ base: 'column', md: 'row' }} spacing="24px">
				<VStack align="start">
					<Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '24px', md: '8px' }}>
						<Image boxSize="24px" src={type === 'AV' ? AVIconGrey : PERIconGrey} />

						<Heading variant="Title-M-Bold">{type === 'AV' ? 'Assurance vie' : 'PER individuel'}</Heading>
					</Stack>

					<Text variant="Text-M-Medium" color="grey.900">
						Valorisation: {displayMoney(contractAmount)}
					</Text>
				</VStack>

				<Show above="md">
					<Box w="1px" bg="grey.500" />
				</Show>

				<VStack align="start">
					<VStack align="start" flexDirection={{ base: 'column-reverse', md: 'column' }}>
						<Heading variant="Title-M-Bold">{displayMoney(montant ?? 0)} / mois</Heading>

						<Text variant="Text-M-Medium" color="grey.900">
							Versement programmé {isNotNone(startDate) && <>ajouté le {formatDateStandart(startDate)}</>}
						</Text>
					</VStack>
					<Hide above="md">
						<Text variant="Text-S-Regular" color="grey.900">
							Les versements programmés sont prélevés sur votre compte bancaire entre le 10 et le 12 de chaque mois.
						</Text>
					</Hide>
				</VStack>
			</Stack>
			{isNotNone(montant) ? (
				<HStack spacing="16px" alignSelf="end" flexDirection={{ base: 'row-reverse', md: 'row' }}>
					<Button variant="secondary" onClick={onClickModify}>
						Modifier
					</Button>
					<Button variant="destroy" onClick={onClickDelete}>
						Supprimer
					</Button>
				</HStack>
			) : (
				<Button alignSelf="end" variant="secondary" onClick={onClickCreate}>
					Ajouter versement programmé
				</Button>
			)}
		</Stack>
	</Card>
);

export default ProgrammedVersementCard;
