import { Box, Text, VStack } from '@chakra-ui/react';

import { SideBarType } from 'components/SideBar';

import BuildSideBar from './BuildSideBar';

type SideBarLowerProps = {
	sideBarParts: SideBarType[];
};
const SideBarLower = ({ sideBarParts }: SideBarLowerProps) => (
	<VStack w="100%" align="start" spacing="16px">
		<Text variant="Text-S-Medium">MES PRODUITS</Text>

		<VStack align="start" w="100%" spacing="4px">
			{sideBarParts.map((part) => (
				<Box onClick={part.onClick} key={part.title} w="100%">
					<BuildSideBar icon={part.icon} title={part.title} isActive={part.isActive} />
				</Box>
			))}
		</VStack>
	</VStack>
);

export default SideBarLower;
