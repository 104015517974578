import { ReactNode } from 'react';
import { Explore } from '@carbon/icons-react';
import { Image } from '@chakra-ui/react';

import ImmoIcon from 'assets/icons/ProductsYellowColoredIcons/Immo.svg';
import InvestIcon from 'assets/icons/ProductsYellowColoredIcons/Invest.svg';
import PeIcon from 'assets/icons/ProductsYellowColoredIcons/Pe.svg';

export type InvestmentCardDataType = {
	// TODO: change the type of logo
	icon: ReactNode;
	title: string;
	subtitle: string;
	description: string;
	condition: string;
	showBackgroundBadge: boolean;
	redirectParam: string;
};

const welcomePageCardsData: InvestmentCardDataType[] = [
	{
		icon: <Image src={InvestIcon} />,
		title: 'Investir en Bourse : Assurance Vie et PER',
		subtitle: 'Portefeuilles ETF actions, ETF obligations, SCPI, fonds euros',
		description: "Construisez votre portefeuille sur mesure maximisant votre rendement net de frais et net d'impôt.",
		condition: 'À partir de 1 000 €',
		showBackgroundBadge: true,
		redirectParam: '/onboarding',
	},
	{
		icon: <Image src={ImmoIcon} />,
		title: "Investir dans l'immobilier : SCPI",
		subtitle: 'SCPI en direct, SCPI à crédit, SCPI via assurance vie et PER',
		description:
			"Explorez l'offre d'investissement immobilier la plus complète du marché, un conseiller Ramify sera toujours disponible pour vous accompagner dans votre projet.",
		condition: 'À partir de 187 €',
		showBackgroundBadge: true,
		redirectParam: '/immobilier',
	},
	{
		icon: <Image src={PeIcon} />,
		title: 'Investir dans le Private Equity : FCPI, FCPR, FIP',
		subtitle: "Investissements en direct éligibles aux réductions d'IR et exonérations d'impôt sur les plus values",
		description: 'Ramify vous ouvre les portes du Private Equity. Plus de 40 fonds disponibles !',
		condition: 'À partir de 1 000 €',
		showBackgroundBadge: true,
		redirectParam: '/private-equity',
	},
	{
		icon: <Explore size="24" />,
		title: 'Pas de projet spécifique ?',
		subtitle: "Créez un compte gratuitement et explorez l'univers d'investissement le plus complet du marché",
		description: '',
		condition: '',
		showBackgroundBadge: false,
		redirectParam: '/',
	},
];

export default welcomePageCardsData;
