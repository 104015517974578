import { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';

import { useAppDispatch } from 'hooks/useStore';
import { isReglementaryInformationCompleted } from 'onboarding/PrivateEquity/Reglementaire/utils';
import { setLastInnerStepAvailable } from 'onboarding/Stepper/stepper.slice';
import { useGetKYCQuery, useGetPESCPIKYCQuery } from 'services/requests/kyc';
import { useGetSCPIKYCQuery, useGetSCPIKYCTestQuery } from 'services/requests/realEstate/kyc';
import { isNotNone } from 'utils/functions';

import { Informations } from './informations';
import { InformationsProduit } from './Informations-produit';
import { Test } from './Test';
import { isReProductInformationCompleted } from './utils';

const Reglementaire = (): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const { data: kyc, isLoading: isLoadingKYC } = useGetKYCQuery();
	const { data: scpiKyc, isLoading: isLoadingSCPIKYC } = useGetSCPIKYCQuery();
	const { data: peScpiKyc, isLoading: isLoadingPESCPIKYC } = useGetPESCPIKYCQuery();
	const { data: scpiKycTest, isLoading: isLoadingSCPIKYCTest } = useGetSCPIKYCTestQuery();
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const dispatch = useAppDispatch();

	const informationDone = useMemo(() => isReglementaryInformationCompleted(kyc, peScpiKyc), [kyc, peScpiKyc]);
	const produitDone = useMemo(() => isReProductInformationCompleted(scpiKyc), [scpiKyc]);
	const testDone = useMemo(
		() => !!scpiKycTest && Object.values(scpiKycTest).every((value) => isNotNone(value)),
		[scpiKycTest],
	);

	// Redirect to last step available (only the first time)
	useEffect(() => {
		if (scpiKycTest && scpiKyc && peScpiKyc && isInitialLoad) {
			if (testDone) {
				// navigate(`/immobilier/onboarding/${id}/souscription`);
				navigate(`/immobilier/onboarding/${id}/reglementaire/test`);
			} else if (produitDone) {
				navigate(`/immobilier/onboarding/${id}/reglementaire/test`);
			} else if (informationDone) {
				navigate(`/immobilier/onboarding/${id}/reglementaire/informations-produit`);
			}
			setIsInitialLoad(false);
		}
	}, [id, informationDone, isInitialLoad, navigate, peScpiKyc, produitDone, scpiKyc, scpiKycTest, testDone]);

	useEffect(() => {
		if (testDone || produitDone) {
			dispatch(setLastInnerStepAvailable('test'));
		} else if (informationDone) {
			dispatch(setLastInnerStepAvailable('informations-produit'));
		} else {
			dispatch(setLastInnerStepAvailable('informations'));
		}
	}, [dispatch, informationDone, produitDone, testDone]);

	if (isLoadingSCPIKYC || isLoadingKYC || isLoadingSCPIKYCTest || isLoadingPESCPIKYC) return <Spinner />;

	return (
		<Routes>
			<Route path="informations" element={<Informations />} />
			<Route path="informations-produit" element={<InformationsProduit />} />
			<Route path="test" element={<Test />} />
			<Route path="*" element={<Navigate to="informations" replace />} />
		</Routes>
	);
};

export default Reglementaire;
