import React, { Dispatch, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Analytics, ArrowUpRight, ChartSunburst } from '@carbon/icons-react';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Skeleton,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Stack,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import MetricCard from 'components/cards/MetricCard';
import FilterSelect from 'components/FilterSelect';
import NumberInput from 'components/inputs/NumberInput';
import { SavingsAccountType } from 'store/types/savingsAccount.type';
import colors from 'theme/foundations/colors';
import { displayMoney } from 'utils/rendering';

type SimulationSectionType = {
	initialAUM: number;
	savingLength: number;
	type: SavingsAccountType;
};

const SimulationSection = (): JSX.Element => {
	const {
		control,
		formState: { errors },
		register,
		setValue,
		watch,
	} = useForm<SimulationSectionType>({
		mode: 'onChange',
		defaultValues: {
			initialAUM: 10000,
			savingLength: 10,
			type: SavingsAccountType.CER,
		},
	});

	const [initialAUM, savingLength, type] = watch(['initialAUM', 'savingLength', 'type']);

	const minimumAum = useMemo(() => (type === SavingsAccountType.CER ? 500 : 5000), [type]);
	const [minimumSavingLength, maximumSavingLength] = useMemo(
		() => (type === SavingsAccountType.CER ? [1, 30] : [1, 3]),
		[type],
	);

	const finalAUM = useMemo<number>(() => {
		if (errors.savingLength || errors.initialAUM) return NaN;
		if (type === SavingsAccountType.CER) {
			const boostedBonus = 1.035;
			const boostCap = Math.min(150000, initialAUM);
			const normalBonus = 1.028;
			const amount =
				boostCap * ((boostedBonus / 12) * 2 + (normalBonus / 12) * 10) + (initialAUM - boostCap) * normalBonus;
			return amount * Math.pow(normalBonus, savingLength - 1);
		} else {
			const mapping: Record<number, number> = { 1: 0.031, 2: 0.0325, 3: 0.035 };
			return initialAUM * Math.pow(1 + mapping[savingLength], savingLength);
		}
	}, [errors.savingLength, errors.initialAUM, initialAUM, savingLength, type]);

	// reset initialAUM if it is below minimum
	useEffect(() => {
		if (initialAUM < minimumAum) setValue('initialAUM', minimumAum, { shouldValidate: true });
	}, [initialAUM, minimumAum, setValue]);

	// reset savingLength if it is out of range
	useEffect(() => {
		if (savingLength < minimumSavingLength) setValue('savingLength', minimumSavingLength, { shouldValidate: true });
		if (savingLength > maximumSavingLength) setValue('savingLength', maximumSavingLength, { shouldValidate: true });
	}, [maximumSavingLength, minimumSavingLength, savingLength, setValue]);

	const filters = [
		{ label: 'Livret épargne', labelValue: SavingsAccountType.CER },
		{ label: 'Compte à terme', labelValue: SavingsAccountType.CAT },
	];

	const setTypeValue = useCallback(
		(value: SavingsAccountType) => {
			setValue('type', value, { shouldValidate: true });
		},
		[setValue],
	);

	return (
		<VStack w="100%" align="start" spacing="32px">
			<Text variant="Title-L-SemiBold">Simulez la rémunération de votre épargne</Text>
			<VStack align="start" spacing="16px" w="100%">
				<FilterSelect data={filters} label={{ filterValue: type, setFilterValue: setTypeValue as Dispatch<unknown> }} />
			</VStack>

			<Stack w="100%" direction={{ base: 'column', md: 'row' }} spacing="24px" align="stretch">
				{/* Inputs */}
				<VStack flex="1" justify="center" spacing="32px">
					<FormControl isInvalid={!!errors.initialAUM}>
						<FormLabel>Montant initial investi</FormLabel>
						<HStack w="100%" spacing="24px">
							<HStack>
								<Controller
									control={control}
									name="initialAUM"
									rules={{
										required: true,
										min: minimumAum,
										max: 1000000,
									}}
									render={({ field: { onChange } }) => <NumberInput onChange={onChange} value={initialAUM} />}
								/>

								<Text variant="label">€</Text>
							</HStack>
							<Slider
								min={minimumAum}
								max={1000000}
								step={1000}
								value={initialAUM}
								focusThumbOnChange={false}
								onChange={(newValue) => setValue('initialAUM', newValue, { shouldValidate: true })}
								onChangeEnd={(newValue) => setValue('initialAUM', newValue, { shouldValidate: true })}
								w="100%"
							>
								<SliderTrack>
									<SliderFilledTrack backgroundColor={colors.primary.yellow} />
								</SliderTrack>
								<SliderThumb fontSize="sm" boxSize="12px" border={`2px solid ${colors.primary.black} !important`} />
							</Slider>
						</HStack>
						{errors.initialAUM?.type === 'required' && <FormErrorMessage children="Ce champ est nécessaire" />}
						{errors.initialAUM?.type === 'min' && (
							<FormErrorMessage children={`Le montant minimum est de ${minimumAum} euros`} />
						)}
						{errors.initialAUM?.type === 'max' && (
							<FormErrorMessage children="Le montant maximum est de 1 000 000 euros" />
						)}
					</FormControl>

					<FormControl isInvalid={!!errors.savingLength}>
						<FormLabel>Durée de votre placement</FormLabel>
						<HStack w="100%" spacing="24px">
							<HStack>
								<Input
									{...register('savingLength', {
										valueAsNumber: true,
										required: true,
										min: minimumSavingLength,
										max: maximumSavingLength,
									})}
									type="number"
									w="55px"
								/>
								<Text variant="label">ans</Text>
							</HStack>

							<Slider
								min={minimumSavingLength}
								max={maximumSavingLength}
								value={savingLength}
								focusThumbOnChange={false}
								onChange={(newValue) => setValue('savingLength', newValue, { shouldValidate: true })}
								onChangeEnd={(newValue) => setValue('savingLength', newValue, { shouldValidate: true })}
								w="100%"
							>
								<SliderTrack>
									<SliderFilledTrack backgroundColor={colors.primary.yellow} />
								</SliderTrack>
								<SliderThumb fontSize="sm" boxSize="12px" border={`2px solid ${colors.primary.black} !important`} />
							</Slider>
						</HStack>
						{errors.savingLength?.type === 'required' && <FormErrorMessage children="Ce champ est nécessaire" />}
						{errors.savingLength?.type === 'min' && <FormErrorMessage children="Le durée minimale est de 1 an" />}
						{errors.savingLength?.type === 'max' && <FormErrorMessage children="Le durée maximale est de 30 ans" />}
					</FormControl>
				</VStack>

				<VStack alignItems="stretch">
					<Wrap flex="1" display="flex" spacingX="24px">
						<WrapItem alignItems="stretch" flex="1">
							<MetricCard
								icon={Analytics}
								title="Capital total"
								value={isNaN(finalAUM) ? <Skeleton h="40px" w="100%" /> : displayMoney(finalAUM)}
							/>
						</WrapItem>
						<WrapItem alignItems="stretch" flex="1">
							<MetricCard
								icon={ChartSunburst}
								title="Intérêts génères"
								value={
									isNaN(finalAUM) ? (
										<Skeleton h="40px" w="100%" />
									) : (
										<HStack>
											<ArrowUpRight size={24} color={colors.positive[900]} />
											<Text variant="Title-L-Bold" color="positive.900">
												{displayMoney(finalAUM - initialAUM)}
											</Text>
										</HStack>
									)
								}
							/>
						</WrapItem>
					</Wrap>
				</VStack>
			</Stack>
		</VStack>
	);
};

export default SimulationSection;
