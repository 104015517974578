import { FC, useMemo } from 'react';
import { WarningAlt } from '@carbon/icons-react';
import {
	Button,
	Card,
	HStack,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ModalProps,
	Text,
	VStack,
} from '@chakra-ui/react';

import BankAccountCard from 'components/bankingInformations/BankAccountCard';
import { useGetBankAccountsQuery } from 'services/requests/bank';

const CashVersementModal: FC<
	Pick<ModalProps, 'onClose' | 'isOpen'> & {
		bankAccountId: string;
	}
> = ({ isOpen, onClose, bankAccountId }) => {
	const { data: bankAccountList } = useGetBankAccountsQuery();

	const bankAccount = useMemo(() => {
		if (!bankAccountList) return null;

		return bankAccountList.find((b) => b.id === bankAccountId);
	}, [bankAccountList, bankAccountId]);

	return (
		<Modal size="xl" isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Faire un versement sur mon livret</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<VStack spacing="24px" align="start">
						<Text variant="body">
							Afin de réaliser le versement, il vous suffit d'effectuer un virement depuis votre compte bancaire
							renseigné ci dessous vers le RIB de votre livret envoyé par My Money Bank{' '}
						</Text>
						<Card>
							<HStack spacing="16px">
								<Icon bg="warning.100" borderRadius="full" boxSize="48px" p="8px" as={WarningAlt} color="warning.900" />
								<Text variant="Text-M-Medium" color="grey.900">
									Seul un virement en provenance du compte ci dessous permettra d'alimenter votre livret
								</Text>
							</HStack>
						</Card>
						{bankAccount && (
							<BankAccountCard IBAN={bankAccount.IBAN} name={bankAccount.holder} onSelect={() => {}} value={true} />
						)}
					</VStack>
				</ModalBody>

				<ModalFooter>
					<Button variant="secondary" onClick={onClose}>
						J’ai compris
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default CashVersementModal;
