import { Checkbox, Text } from '@chakra-ui/react';

export interface ListCheckedChildrenProps {
	checked?: boolean;
	label: string;
}

const ListChecked = ({ children }: { children?: ListCheckedChildrenProps[] }) => {
	return (
		<>
			{children &&
				children.map(({ checked, label }) => (
					<Checkbox variant="outline" key={label} readOnly isChecked={checked} pointerEvents="none">
						<Text variant="Text-M-Regular" color="grey.900">
							{label}
						</Text>
					</Checkbox>
				))}
		</>
	);
};

export default ListChecked;
