import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Button,
	Card,
	Checkbox,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Image,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import Valide from 'assets/icons/Valide.svg';
import Warning from 'assets/icons/Warning.svg';
import BadgeSelectInput from 'components/inputs/BadgeSelectInput';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useUpdatePhoenixScpiKycAndTestMutation } from 'services/requests/phoenix';
import { useGetSCPIKYCQuery, useGetSCPIKYCTestQuery } from 'services/requests/realEstate/kyc';
import { LengthPerceptionType, RiskPerceptionType, ScpiKycTest, UserScpiKycTest } from 'store/types/scpiKyc.type';
import { isNotNone } from 'utils/functions';

type FormData = UserScpiKycTest & {
	riskPerception?: RiskPerceptionType | undefined;
	lengthPerception?: LengthPerceptionType | undefined;
};

const riskPerceptionReferentiel: {
	label: string;
	value: RiskPerceptionType;
}[] = [
	{
		label: 'Aucun risque',
		value: 'NORISK',
	},
	{
		label: 'Risque moyen',
		value: 'LOWRISK',
	},
	{
		label: 'Risque élevé',
		value: 'HIGHRISK',
	},
];

const lengthPerceptionReferentiel: {
	label: string;
	value: LengthPerceptionType;
}[] = [
	{
		label: 'Court-terme',
		value: 'SHORTERM',
	},
	{
		label: 'Moyen-terme',
		value: 'MIDTERM',
	},
	{
		label: 'Long-terme',
		value: 'LONGTERM',
	},
];

const investKnowledgeOptions = Object.entries({
	NO_KNOWLEDGE: 'Faible',
	SOME_KNOWLEDGE: 'Moyen',
	GOOD_KNOWLEDGE: 'Bon',
} as Record<ScpiKycTest['investKnowledge'], string>) as [ScpiKycTest['investKnowledge'], string][];

const investReturnPerceptionOptions = Object.entries({
	GARANTIED: 'Garanti',
	NOT_GARANTIED: 'Non garanti',
} as Record<ScpiKycTest['investReturnPerception'], string>) as [ScpiKycTest['investReturnPerception'], string][];

const investRevenusPerceptionOptions = Object.entries({
	FIXES: 'Fixes et invariables',
	VARIABLES: 'Variables notamment en fonction des loyers perçus',
} as Record<ScpiKycTest['investRevenusPerception'], string>) as [ScpiKycTest['investRevenusPerception'], string][];

const investRevenusLengthPerceptionOptions = Object.entries({
	DAYS: 'Quelques jours',
	WEEKS: 'Quelques semaines',
} as Record<ScpiKycTest['investRevenusLengthPerception'], string>) as [
	ScpiKycTest['investRevenusLengthPerception'],
	string,
][];

const Test = (): JSX.Element => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const { subId } = useParams<{ subId: string }>();
	const { data: scpiKycTest } = useGetSCPIKYCTestQuery();
	const { data: scpiKyc } = useGetSCPIKYCQuery();
	const [updateKycTest] = useUpdatePhoenixScpiKycAndTestMutation();

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm<FormData>();

	const [
		investReturnPerception,
		investRevenusPerception,
		investRevenusLengthPerception,
		riskPerception,
		lengthPerception,
	] = watch([
		'investReturnPerception',
		'investRevenusPerception',
		'investRevenusLengthPerception',
		'riskPerception',
		'lengthPerception',
	]);

	const isValidInvestReturnPerception = investReturnPerception === 'NOT_GARANTIED';
	const isValidInvestRevenusPerception = investRevenusPerception === 'VARIABLES';
	const isValidInvestRevenusLengthPerception = investRevenusLengthPerception === 'WEEKS';
	const isValidRisk = riskPerception === 'LOWRISK' || riskPerception === 'HIGHRISK';
	const isValidTerm = lengthPerception === 'MIDTERM' || lengthPerception === 'LONGTERM';

	useEffect(() => {
		if (scpiKycTest && scpiKyc) {
			if (isNotNone(scpiKyc.riskPerception)) setValue('riskPerception', scpiKyc.riskPerception);
			if (isNotNone(scpiKyc.lengthPerception)) setValue('lengthPerception', scpiKyc.lengthPerception);
			if (isNotNone(scpiKycTest.agreeWithInvestment)) setValue('agreeWithInvestment', scpiKycTest.agreeWithInvestment);
			if (isNotNone(scpiKycTest.hasInvestedMoreThan100k))
				setValue('hasInvestedMoreThan100k', scpiKycTest.hasInvestedMoreThan100k);
			if (isNotNone(scpiKycTest.hasLongTermInvestmentConscience))
				setValue('hasLongTermInvestmentConscience', scpiKycTest.hasLongTermInvestmentConscience);
			if (isNotNone(scpiKycTest.hasRealEstateInvest)) setValue('hasRealEstateInvest', scpiKycTest.hasRealEstateInvest);
			if (isNotNone(scpiKycTest.hasSCPIShares)) setValue('hasSCPIShares', scpiKycTest.hasSCPIShares);
			if (isNotNone(scpiKycTest.investKnowledge)) setValue('investKnowledge', scpiKycTest.investKnowledge);
			if (isNotNone(scpiKycTest.investReturnPerception))
				setValue('investReturnPerception', scpiKycTest.investReturnPerception);
			if (isNotNone(scpiKycTest.investRevenusLengthPerception))
				setValue('investRevenusLengthPerception', scpiKycTest.investRevenusLengthPerception);
			if (isNotNone(scpiKycTest.investRevenusPerception))
				setValue('investRevenusPerception', scpiKycTest.investRevenusPerception);
		}
	}, [setValue, scpiKycTest, scpiKyc]);

	const onSubmit = handleSubmit((data: FormData) => {
		updateKycTest(data)
			.unwrap()
			.then(() => {
				navigate(`/phoenix/onboarding/${subId}/confirmation`);
			})
			.catch(() => toast({ title: 'Une erreur est survenue', status: 'error', duration: 9000, isClosable: true }));
	});

	useEffect(() => {
		eventTracker.mixpanel.track("SCPI Onboarding - Test d'adéquation");
	}, []);

	return (
		<form onSubmit={onSubmit}>
			<VStack align="start" spacing="24px">
				<Heading variant="Title-XL-Bold">Test d’adéquation</Heading>

				<Text>
					Ces données sont confidentielles et nécessaires vis-à-vis de nos obligations réglementaires et en particulier
					concernant la lutte contre le blanchiment. Elles nous permettent, par ailleurs, de valider la bonne adéquation
					de votre souscription avec votre patrimoine et votre bonne information.
				</Text>

				<VStack align="start" spacing="16px">
					<FormControl isRequired isInvalid={!!errors.investKnowledge}>
						<FormLabel>Votre niveau de connaissances en matière de placements financiers est plûtot</FormLabel>
						<Controller
							name="investKnowledge"
							control={control}
							rules={{ validate: { required: (b) => isNotNone(b) } }}
							render={({ field: { onChange, value, ref } }) => (
								<Wrap ref={ref}>
									{investKnowledgeOptions.map(([key, display]) => (
										<WrapItem key={key}>
											<Button variant={value === key ? 'primary' : 'secondary'} onClick={() => onChange(key)}>
												{display}
											</Button>
										</WrapItem>
									))}
								</Wrap>
							)}
						/>

						{errors.investKnowledge?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
					</FormControl>
				</VStack>

				<VStack align="start" spacing="16px">
					<FormControl isRequired isInvalid={!!errors.hasRealEstateInvest}>
						<FormLabel>Avez-vous déjà réalisé des opérations de placements immobilier ?</FormLabel>
						<Controller
							name="hasRealEstateInvest"
							control={control}
							rules={{ validate: { required: (b) => isNotNone(b) } }}
							render={({ field: { onChange, value } }) => (
								<HStack>
									<Button variant={value === true ? 'primary' : 'secondary'} onClick={() => onChange(true)}>
										Oui
									</Button>
									<Button variant={value === false ? 'primary' : 'secondary'} onClick={() => onChange(false)}>
										Non
									</Button>
								</HStack>
							)}
						/>

						{errors.hasRealEstateInvest?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
					</FormControl>
				</VStack>

				<VStack align="start" spacing="16px">
					<FormControl isRequired isInvalid={!!errors.hasSCPIShares}>
						<FormLabel>Possédez-vous déjà des parts de SCPI ?</FormLabel>
						<Controller
							name="hasSCPIShares"
							control={control}
							rules={{ validate: { required: (b) => isNotNone(b) } }}
							render={({ field: { onChange, value } }) => (
								<HStack>
									<Button variant={value === true ? 'primary' : 'secondary'} onClick={() => onChange(true)}>
										Oui
									</Button>
									<Button variant={value === false ? 'primary' : 'secondary'} onClick={() => onChange(false)}>
										Non
									</Button>
								</HStack>
							)}
						/>

						{errors.hasSCPIShares?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					</FormControl>
				</VStack>

				<VStack align="start" spacing="16px">
					<FormControl isRequired isInvalid={!!errors.hasInvestedMoreThan100k}>
						<FormLabel>
							Sur un an glissant, vous avez investi une somme supérieure ou égale à 100 000 € en parts de SCPI (en une
							seule fois, ou en cumulé)
						</FormLabel>
						<Controller
							name="hasInvestedMoreThan100k"
							control={control}
							rules={{ validate: { required: (b) => isNotNone(b) } }}
							render={({ field: { onChange, value } }) => (
								<HStack>
									<Button variant={value === true ? 'primary' : 'secondary'} onClick={() => onChange(true)}>
										Oui
									</Button>
									<Button variant={value === false ? 'primary' : 'secondary'} onClick={() => onChange(false)}>
										Non
									</Button>
								</HStack>
							)}
						/>

						{errors.hasInvestedMoreThan100k?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
					</FormControl>
				</VStack>

				<VStack align="start" spacing="16px">
					<FormControl isRequired isInvalid={!!errors.hasLongTermInvestmentConscience}>
						<FormLabel>
							L’investissement en SCPI étant un placement immobilier, avez-vous conscience que ce placement doit être
							envisagé sur le long terme ?
						</FormLabel>
						<Controller
							name="hasLongTermInvestmentConscience"
							control={control}
							rules={{ validate: { required: (b) => isNotNone(b) } }}
							render={({ field: { onChange, value } }) => (
								<HStack>
									<Button variant={value === true ? 'primary' : 'secondary'} onClick={() => onChange(true)}>
										Oui
									</Button>
									<Button variant={value === false ? 'primary' : 'secondary'} onClick={() => onChange(false)}>
										Non
									</Button>
								</HStack>
							)}
						/>

						{errors.hasLongTermInvestmentConscience?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
					</FormControl>
				</VStack>

				<VStack align="start" spacing="16px">
					<FormControl isRequired isInvalid={!!errors.riskPerception}>
						<FormLabel htmlFor="cc">
							Quelle est votre perception du risque de perte en capital pour ce produit d’investissement ?
						</FormLabel>

						<Controller
							control={control}
							name="riskPerception"
							rules={{ required: true, validate: { goodChoice: (value) => !(value === 'NORISK') } }}
							render={({ field }) => <BadgeSelectInput {...field} options={riskPerceptionReferentiel} />}
						/>

						{errors.riskPerception?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					</FormControl>
					{riskPerception && (
						<Card borderColor={isValidRisk ? 'grey.300' : 'red.500'} borderWidth="1px">
							<HStack align="center" spacing="16px">
								<Image src={isValidRisk ? Valide : Warning} alt={isValidRisk ? 'correct' : 'incorrect'} />

								<Text variant="label" fontWeight="400">
									Contrairement au Livret A par exemple, l’investissement en immobilier
									<Text color="black" as="span" fontWeight="600">
										{' '}
										comporte des risques{' '}
									</Text>
									de perte en capital et les revenus dépendront de l’évolution du marché immobilier.
								</Text>
							</HStack>
						</Card>
					)}
				</VStack>

				{isValidRisk && (
					<VStack align="start" spacing="16px">
						<FormControl isRequired isInvalid={!!errors.lengthPerception}>
							<FormLabel>Selon vous, investir en immobilier est un investissement à horizon ?</FormLabel>

							<Controller
								control={control}
								name="lengthPerception"
								rules={{ required: true, validate: { goodChoice: (value) => !(value === 'SHORTERM') } }}
								render={({ field }) => <BadgeSelectInput {...field} options={lengthPerceptionReferentiel} />}
							/>

							{errors.lengthPerception?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
						{lengthPerception && (
							<Card borderColor={isValidTerm ? 'grey.300' : 'red.500'} borderWidth="1px">
								<HStack align="center" spacing="16px">
									<Image src={isValidTerm ? Valide : Warning} alt={isValidTerm ? 'correct' : 'incorrect'} />

									<Text variant="label" fontWeight="400">
										Comme tout placement immobilier, il s’agit d’un
										<Text color="black" as="span" fontWeight="600">
											{' '}
											investissement long terme.{' '}
										</Text>
										Nous vous recommandons une durée de placement de plus de 7 ans.
									</Text>
								</HStack>
							</Card>
						)}
					</VStack>
				)}

				{isValidTerm && (
					<VStack align="start" spacing="16px">
						<FormControl isRequired isInvalid={!!errors.investReturnPerception}>
							<FormLabel>L’investissement en SCPI est un investissement dont le capital est</FormLabel>
							<Controller
								name="investReturnPerception"
								control={control}
								rules={{ required: true, validate: { goodChoice: (value) => !(value === 'GARANTIED') } }}
								render={({ field: { onChange, value, ref } }) => (
									<Wrap ref={ref}>
										{investReturnPerceptionOptions.map(([key, display]) => (
											<WrapItem key={key}>
												<Button variant={value === key ? 'primary' : 'secondary'} onClick={() => onChange(key)}>
													{display}
												</Button>
											</WrapItem>
										))}
									</Wrap>
								)}
							/>

							{errors.investReturnPerception?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
						{investReturnPerception && (
							<Card borderColor={isValidInvestReturnPerception ? 'grey.300' : 'red.500'} borderWidth="1px">
								<HStack align="center" spacing="16px">
									<Image
										src={isValidInvestReturnPerception ? Valide : Warning}
										alt={isValidInvestReturnPerception ? 'correct' : 'incorrect'}
									/>

									<Text variant="label" fontWeight="400">
										Le capital investi{' '}
										<Text color="black" as="span" fontWeight="600">
											n’est pas garanti :{' '}
										</Text>
										il est lié aux évolutions du marché de l'immobilier
									</Text>
								</HStack>
							</Card>
						)}
					</VStack>
				)}

				{isValidInvestReturnPerception && (
					<VStack align="start" spacing="16px">
						<FormControl isRequired isInvalid={!!errors.investRevenusPerception}>
							<FormLabel>Les revenus des SCPI sont des revenus</FormLabel>
							<Controller
								name="investRevenusPerception"
								control={control}
								rules={{ required: true, validate: { goodChoice: (value) => !(value === 'FIXES') } }}
								render={({ field: { onChange, value, ref } }) => (
									<Wrap ref={ref}>
										{investRevenusPerceptionOptions.map(([key, display]) => (
											<WrapItem key={key}>
												<Button
													variant={value === key ? 'primary' : 'secondary'}
													onClick={() => onChange(key)}
													whiteSpace="normal" // "VARIABLES" option is too long
												>
													{display}
												</Button>
											</WrapItem>
										))}
									</Wrap>
								)}
							/>

							{errors.investRevenusPerception?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
						{investRevenusPerception && (
							<Card borderColor={isValidInvestRevenusPerception ? 'grey.300' : 'red.500'} borderWidth="1px">
								<HStack align="center" spacing="16px">
									<Image
										src={isValidInvestRevenusPerception ? Valide : Warning}
										alt={isValidInvestRevenusPerception ? 'correct' : 'incorrect'}
									/>

									<Text variant="label" fontWeight="400">
										Les revenus{' '}
										<Text color="black" as="span" fontWeight="600">
											ne sont pas garantis :{' '}
										</Text>
										ils sont liés au bon paiement des loyers par les locataires
									</Text>
								</HStack>
							</Card>
						)}
					</VStack>
				)}
				{isValidInvestRevenusPerception && (
					<VStack align="start" spacing="16px">
						<FormControl isRequired isInvalid={!!errors.investRevenusLengthPerception}>
							<FormLabel>
								Les SCPI investissent dans des biens immobiliers. Selon vous, combien de temps minimum faut-il pour
								revendre un placement immobilier ?
							</FormLabel>
							<Controller
								name="investRevenusLengthPerception"
								control={control}
								rules={{ required: true, validate: { goodChoice: (value) => !(value === 'DAYS') } }}
								render={({ field: { onChange, value, ref } }) => (
									<Wrap ref={ref}>
										{investRevenusLengthPerceptionOptions.map(([key, display]) => (
											<WrapItem key={key}>
												<Button variant={value === key ? 'primary' : 'secondary'} onClick={() => onChange(key)}>
													{display}
												</Button>
											</WrapItem>
										))}
									</Wrap>
								)}
							/>

							{errors.investRevenusLengthPerception?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
						{investRevenusLengthPerception && (
							<Card borderColor={isValidInvestRevenusLengthPerception ? 'grey.300' : 'red.500'} borderWidth="1px">
								<HStack align="center" spacing="16px">
									<Image
										src={isValidInvestRevenusLengthPerception ? Valide : Warning}
										alt={isValidInvestRevenusLengthPerception ? 'correct' : 'incorrect'}
									/>

									<Text variant="label" fontWeight="400">
										Le placement en SCPI est plutôt{' '}
										<Text fontWeight="600" color="black" as="span">
											illiquide{' '}
										</Text>
										et la revente dépendra du bon rachat de vos parts en SCPI
									</Text>
								</HStack>
							</Card>
						)}
					</VStack>
				)}

				{isValidInvestRevenusLengthPerception && isValidInvestRevenusPerception && (
					<VStack align="start" spacing="16px">
						<FormControl isRequired isInvalid={!!errors.agreeWithInvestment}>
							<FormLabel>Estimez-vous qu’un placement SCPI est adapté à votre profil et à votre situation ?</FormLabel>
							<Text>
								<br />
								Acheter des parts de SCPI est un investissement immobilier.
								<br />
								<br />
								Comme tout placement immobilier, il s’agit d’un investissement long terme.
								<br />
								Nous vous recommandons une durée de placement de plus de 7 ans.
								<br />
								Contrairement au Livret A par exemple, ce placement comporte des risques.
								<br />
								<br />
								Le capital investi et les revenus ne sont pas garantis : ils sont liés aux évolutions du marché de
								l'immobilier et au bon paiement des loyers par les locataires.
								<br />
								Enfin, comme tout placement rappelez-vous que les performances passées ne préjugent pas des performances
								futures.
								<br />
								<br />
							</Text>
							<Controller
								name="agreeWithInvestment"
								control={control}
								rules={{ required: true }}
								render={({ field: { onChange, value, ref } }) => (
									<Checkbox onChange={onChange} isChecked={value} ref={ref}>
										J’ai bien pris connaissance des éléments ci-dessus
									</Checkbox>
								)}
							/>

							{errors.agreeWithInvestment?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
					</VStack>
				)}
			</VStack>
			<BottomStepBar onPrev={() => navigate(`/phoenix/onboarding/${subId}/documents`)} nextSubmit />
		</form>
	);
};

export default Test;
