import { Button, HStack, ModalProps, Text } from '@chakra-ui/react';

import CustomModal from 'components/Modal';

type ModalResetProps = {
	isOpen: ModalProps['isOpen'];
	onClose: ModalProps['onClose'];
	onReset: ModalProps['onClose'];
};

const ModalReset = ({ isOpen, onClose, onReset }: ModalResetProps): JSX.Element => (
	<CustomModal
		isCentered
		headersProps={{ children: "Votre simulation d'investissement" }}
		footerProps={{
			children: (
				<HStack spacing="16px">
					<Button variant="secondary" onClick={onClose}>
						Reprendre la simulation
					</Button>
					<Button variant="primary" onClick={onReset}>
						Réinitialiser
					</Button>
				</HStack>
			),
		}}
		isOpen={isOpen}
		onClose={onClose}
	>
		<Text variant="label">
			Vous avez une simulation en cours. Vous pouvez reprendre votre simulation ou la réinitialiser.
		</Text>
	</CustomModal>
);

export default ModalReset;
