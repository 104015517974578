import { Heading, VStack } from '@chakra-ui/react';

export type RecommendationQuestion = {
	question?: string;
	component: JSX.Element;
};

export const RecommendationTooltipQuestion = ({ question, component }: RecommendationQuestion): JSX.Element => {
	return (
		<VStack spacing="24px" w="100%" align="start">
			{question && <Heading variant="Title-M-Bold">{question}</Heading>}
			{component}
		</VStack>
	);
};
