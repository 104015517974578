import { useCallback, useState } from 'react';
import {
	Button,
	Heading,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	PinInput,
	PinInputField,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { Ring } from '@uiball/loaders';

import Amelie from 'assets/team/smallAmelie.png';
import { ArrowButton } from 'components/Button/Button';
import SamyAdvisor from 'components/cards/SamyAdvisor';
import CustomModal from 'components/Modal';
import { VersementEligibilityError } from 'components/versements/VersementEligibilityError';
import { useAppResponsive } from 'hooks/useAppResponsive';
import eventTracker from 'services/events/eventTracker';
import {
	useDeleteVPMutation,
	useIsVersementEligibleQuery,
	useSignVersementMutation,
	VersementEligibility,
} from 'services/requests/versement';
import { PopulatedInvestContract } from 'store/types/contract.type';
import colors from 'theme/foundations/colors';
import { isNone, isNotNone } from 'utils/functions';

interface DeleteVPProps {
	onClose: () => void;
	onSuccess: () => void;
	contract: PopulatedInvestContract;
}

const DeleteVP = ({ onClose, onSuccess, contract }: DeleteVPProps): JSX.Element => {
	const modalSize = useAppResponsive({ base: 'sm', md: '4xl' });
	const { isOpen: isVersementError, onOpen: onVersementError } = useDisclosure();
	const [code, setCode] = useState('');

	const [
		triggerDeleteVP,
		{
			data: deletingVPId,
			isLoading: isDeleteVPLoading,
			isSuccess: isDeleteVPSuccess,
			isError: isDeleteVPError,
			isUninitialized: isDeleteVPUninitialized,
		},
	] = useDeleteVPMutation();

	const [
		triggerSignVP,
		{ isLoading: isSignDeleteLoading, isError: isSignDeleteError, isUninitialized: isSignDeleteUninitialized },
	] = useSignVersementMutation();

	const handleSign = useCallback(() => {
		triggerSignVP({ otp: code, versementId: deletingVPId! })
			.unwrap()
			.then(() => {
				eventTracker.mixpanel.deleteVersementProgramme(+contract.programmedVersement!.amount);
				onSuccess();
			})
			.catch((e) => {
				if ('status' in e && e.status === 400) {
					// incorrect code. Don't do anything and let JSX display the error so the user can enter new code
					return;
				}
				eventTracker.signVersementError({
					amount: +contract.programmedVersement!.amount,
					envelopeType: contract.envelope.type,
					operationType: 'programme',
				});
				onVersementError();
			});
	}, [
		code,
		contract.envelope.type,
		contract.programmedVersement,
		deletingVPId,
		onSuccess,
		onVersementError,
		triggerSignVP,
	]);

	const { data: versementEligibility } = useIsVersementEligibleQuery(
		{
			contractId: contract?.id ?? '',
			type: 'SUPPRESSION_PROGRAMME',
		},
		{ skip: isNone(contract) },
	);

	if (isVersementError) {
		return (
			<CustomModal isOpen onClose={onClose} size="3xl">
				<SamyAdvisor
					complexText={
						<VStack align="start">
							<Heading variant="Title-S-SemiBold">Nous reprenons la main sur votre demande</Heading>

							<Text>
								Une erreur est survenue, nous ne pouvons pas valider automatiquement votre demande pour l’instant. Ne
								vous inquiétez pas, l’équipe support est informée et reviendra vers vous très vite !
							</Text>
						</VStack>
					}
					advisor={Amelie}
				/>
			</CustomModal>
		);
	}

	return (
		<Modal isOpen onClose={onClose} closeOnOverlayClick={false} size={modalSize} autoFocus={false}>
			<ModalOverlay />
			<ModalContent maxW="580px">
				<ModalCloseButton />
				<ModalBody>
					<VStack p="16px 8px" spacing="24px" align="start">
						<Text variant="Title-M-SemiBold">Arrêt du versement programmé</Text>

						{(isDeleteVPUninitialized || isDeleteVPError) && (
							<>
								{isNotNone(versementEligibility) &&
								versementEligibility.eligibility !== VersementEligibility.ELIGIBLE ? (
									<VersementEligibilityError versementEligibility={versementEligibility.eligibility} />
								) : (
									(isDeleteVPUninitialized || isDeleteVPError) && (
										<>
											<Text
												color="red.500"
												bg="#FF7B790D"
												border="base"
												borderColor="red.500"
												borderRadius="base"
												p="12px 20px"
											>
												Vous êtes sur le point de supprimer le versement programmé de{' '}
												{contract.programmedVersement!.amount}€ par mois sur votre{' '}
												<b>{contract.envelope.type === 'AV' ? 'Assurance vie' : 'PER individuel'}</b>.
											</Text>

											{isDeleteVPError && (
												<Text fontWeight="medium" variant="body" color="red.500">
													Nous ne pouvons pas supprimer le versement programmé sur votre contrat pour l'instant.
													Veuillez nous contacter pour plus d'informations.
												</Text>
											)}
										</>
									)
								)}

								<VStack align="start" spacing="16px">
									<Text variant="body">Une validation SMS est requise pour cette opération</Text>
									<Button
										isDisabled={
											isNotNone(versementEligibility) &&
											versementEligibility.eligibility !== VersementEligibility.ELIGIBLE
										}
										variant="destroy"
										onClick={() => triggerDeleteVP(contract.id)}
									>
										{isDeleteVPError ? 'Réessayer' : 'Arrêter le versement programmé'}
									</Button>
								</VStack>
							</>
						)}

						{isDeleteVPLoading && (
							<HStack spacing="16px">
								<Ring size={24} color={colors.grey[700]} />
								<Text fontWeight="medium" fontSize="14px" color="grey.500">
									Nous vous envoyons un code de vérification
									<br /> Cette opération peut prendre plusieurs dizaines de secondes
								</Text>
							</HStack>
						)}

						{isDeleteVPSuccess && (
							<>
								{(isSignDeleteUninitialized || isSignDeleteError) && (
									<>
										{isSignDeleteError && (
											<Text fontWeight="medium" variant="body" color="red.500">
												Le code est incorrect
											</Text>
										)}
										<VStack align="start" spacing="16px">
											<Text variant="body">
												Veuillez confirmer le code à 6 chiffres qui vous a été envoyé par SMS&nbsp;:
											</Text>
											<HStack>
												<PinInput value={code} onChange={setCode} otp>
													<PinInputField />
													<PinInputField />
													<PinInputField />
													<PinInputField />
													<PinInputField />
													<PinInputField />
												</PinInput>
											</HStack>
											<VStack align="start">
												<Text>Vous n'avez pas reçu de code au bout d'une minute ?</Text>
												<Text
													textDecoration="underline"
													cursor="pointer"
													onClick={() => {
														window.Intercom('showNewMessage', 'Bonjour, je ne reçois pas mon code de validation SMS');
													}}
												>
													Contactez nous via le chat
												</Text>
											</VStack>
										</VStack>

										<ArrowButton variant="primary" isDisabled={code.length !== 6} onClick={handleSign}>
											{isSignDeleteError ? 'Réessayer' : 'Arrêter le versement programmé'}
										</ArrowButton>
									</>
								)}
								{isSignDeleteLoading && (
									<HStack spacing="16px">
										<Ring size={24} color={colors.grey[700]} />
										<Text fontWeight="medium" variant="body">
											Validation en cours
											<br /> Cette opération peut prendre plusieurs dizaines de secondes
										</Text>
									</HStack>
								)}
							</>
						)}
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default DeleteVP;
