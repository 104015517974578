import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useAppDispatch } from 'hooks/useStore';
import useThemedToast from 'hooks/useThemedToast';
import { useLazyAutologinAuthentificationQuery } from 'services/requests/auth';
import { useLazyGetUserQuery } from 'services/requests/user';
import { setIsAuth, setToken } from 'store/auth.slice';
import api from 'utils/api';

const Autologin: FC = () => {
	const dispatch = useAppDispatch();
	const toast = useThemedToast();
	const [retriggerUser, { data: userData }] = useLazyGetUserQuery();

	const queryParams = new URLSearchParams(window.location.search);
	const autologinToken = queryParams.get('autologinToken');
	const redirect = queryParams.get('redirect');

	const [autologin] = useLazyAutologinAuthentificationQuery();

	useEffect(() => {
		if (autologinToken) {
			autologin({ token: autologinToken })
				.unwrap()
				.then((data) => {
					api.setHeaderToken(data.access_token);
					localStorage.setItem('token', data.access_token);
					dispatch(setIsAuth(true));
					dispatch(setToken(data.access_token));
					//  redirect
					retriggerUser();
				})
				.catch(() => {
					toast({
						title: "Une erreur s'est produite.",
						description: "Le lien auquel vous tentez d'accéder a expiré.",
						status: 'error',
						duration: 4500,
						isClosable: true,
					});
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [autologinToken, autologin]);

	if (userData) return <Navigate to={redirect ?? '/'} replace />;

	return null;
};

export default Autologin;
