export enum BirthCountry {
	AF = 'AF',
	ZA = 'ZA',
	AL = 'AL',
	DZ = 'DZ',
	DE = 'DE',
	AD = 'AD',
	AO = 'AO',
	AG = 'AG',
	AN = 'AN',
	SA = 'SA',
	AR = 'AR',
	AM = 'AM',
	AU = 'AU',
	AT = 'AT',
	AZ = 'AZ',
	BS = 'BS',
	BH = 'BH',
	BD = 'BD',
	BB = 'BB',
	BE = 'BE',
	BZ = 'BZ',
	BJ = 'BJ',
	BT = 'BT',
	BY = 'BY',
	MM = 'MM',
	BO = 'BO',
	BQ = 'BQ',
	BA = 'BA',
	BW = 'BW',
	BR = 'BR',
	BN = 'BN',
	BG = 'BG',
	BF = 'BF',
	BI = 'BI',
	KY = 'KY',
	KH = 'KH',
	CM = 'CM',
	CA = 'CA',
	CV = 'CV',
	CF = 'CF',
	CL = 'CL',
	CN = 'CN',
	CY = 'CY',
	CO = 'CO',
	KM = 'KM',
	CG = 'CG',
	CD = 'CD',
	CK = 'CK',
	KR = 'KR',
	KP = 'KP',
	CR = 'CR',
	CI = 'CI',
	HR = 'HR',
	CU = 'CU',
	CW = 'CW',
	DK = 'DK',
	DJ = 'DJ',
	DM = 'DM',
	EG = 'EG',
	SV = 'SV',
	AE = 'AE',
	EC = 'EC',
	ER = 'ER',
	ES = 'ES',
	EE = 'EE',
	US = 'US',
	ET = 'ET',
	MK = 'MK',
	FO = 'FO',
	FJ = 'FJ',
	FI = 'FI',
	FR = 'FR',
	GA = 'GA',
	GM = 'GM',
	GE = 'GE',
	GH = 'GH',
	GI = 'GI',
	GR = 'GR',
	GD = 'GD',
	GL = 'GL',
	GT = 'GT',
	GG = 'GG',
	GN = 'GN',
	GQ = 'GQ',
	GW = 'GW',
	GY = 'GY',
	HT = 'HT',
	HN = 'HN',
	HK = 'HK',
	HU = 'HU',
	IN = 'IN',
	ID = 'ID',
	IR = 'IR',
	IQ = 'IQ',
	IE = 'IE',
	IS = 'IS',
	IL = 'IL',
	IT = 'IT',
	JM = 'JM',
	JP = 'JP',
	JE = 'JE',
	JO = 'JO',
	KZ = 'KZ',
	KE = 'KE',
	KG = 'KG',
	KI = 'KI',
	XK = 'XK',
	KW = 'KW',
	LA = 'LA',
	LS = 'LS',
	LV = 'LV',
	LB = 'LB',
	LR = 'LR',
	LY = 'LY',
	LI = 'LI',
	LT = 'LT',
	LU = 'LU',
	MO = 'MO',
	MG = 'MG',
	MY = 'MY',
	MW = 'MW',
	MV = 'MV',
	ML = 'ML',
	MT = 'MT',
	IM = 'IM',
	MA = 'MA',
	MH = 'MH',
	MU = 'MU',
	MR = 'MR',
	MX = 'MX',
	FM = 'FM',
	MD = 'MD',
	MC = 'MC',
	MN = 'MN',
	ME = 'ME',
	MZ = 'MZ',
	NA = 'NA',
	NR = 'NR',
	NP = 'NP',
	NI = 'NI',
	NE = 'NE',
	NG = 'NG',
	NO = 'NO',
	NZ = 'NZ',
	IO = 'IO',
	OM = 'OM',
	UG = 'UG',
	UZ = 'UZ',
	PK = 'PK',
	PW = 'PW',
	PS = 'PS',
	PA = 'PA',
	PG = 'PG',
	PY = 'PY',
	NL = 'NL',
	PE = 'PE',
	PH = 'PH',
	PN = 'PN',
	PL = 'PL',
	PT = 'PT',
	QA = 'QA',
	DO = 'DO',
	RO = 'RO',
	GB = 'GB',
	RU = 'RU',
	RW = 'RW',
	EH = 'EH',
	KN = 'KN',
	SM = 'SM',
	SX = 'SX',
	VC = 'VC',
	SH = 'SH',
	LC = 'LC',
	SB = 'SB',
	WS = 'WS',
	ST = 'ST',
	SN = 'SN',
	RS = 'RS',
	SC = 'SC',
	SL = 'SL',
	SG = 'SG',
	SK = 'SK',
	SI = 'SI',
	SO = 'SO',
	SD = 'SD',
	SS = 'SS',
	LK = 'LK',
	SE = 'SE',
	CH = 'CH',
	SR = 'SR',
	SZ = 'SZ',
	SY = 'SY',
	TJ = 'TJ',
	TW = 'TW',
	TZ = 'TZ',
	TD = 'TD',
	CZ = 'CZ',
	TH = 'TH',
	TL = 'TL',
	TG = 'TG',
	TO = 'TO',
	TT = 'TT',
	TN = 'TN',
	TM = 'TM',
	TR = 'TR',
	TV = 'TV',
	UA = 'UA',
	UY = 'UY',
	VU = 'VU',
	VA = 'VA',
	VE = 'VE',
	VN = 'VN',
	YE = 'YE',
	ZM = 'ZM',
	ZW = 'ZW',
}

const BirthCountryReferentiel: Record<BirthCountry, string> = {
	AF: 'Afghanistan',
	ZA: 'Afrique du Sud',
	AL: 'Albanie',
	DZ: 'Algérie',
	DE: 'Allemagne',
	AD: 'Andorre',
	AO: 'Angola',
	AG: 'Antigua-et-Barbuda',
	AN: 'Antilles néerlandaises',
	SA: 'Arabie saoudite',
	AR: 'Argentine',
	AM: 'Arménie',
	AU: 'Australie',
	AT: 'Autriche',
	AZ: 'Azerbaïdjan',
	BS: 'Bahamas',
	BH: 'Bahreïn',
	BD: 'Bangladesh',
	BB: 'Barbade',
	BE: 'Belgique',
	BZ: 'Belize',
	BJ: 'Bénin',
	BT: 'Bhoutan',
	BY: 'Biélorussie',
	MM: 'Birmanie',
	BO: 'Bolivie',
	BQ: 'Bonaire, Saint-Eustache et Saba',
	BA: 'Bosnie-Herzégovine',
	BW: 'Botswana',
	BR: 'Brésil',
	BN: 'Brunei',
	BG: 'Bulgarie',
	BF: 'Burkina Faso',
	BI: 'Burundi',
	KY: 'Caïmans (Îles)',
	KH: 'Cambodge',
	CM: 'Cameroun',
	CA: 'Canada',
	CV: 'Cap-Vert',
	CF: 'Centrafrique',
	CL: 'Chili',
	CN: 'Chine',
	CY: 'Chypre',
	CO: 'Colombie',
	KM: 'Comores',
	CG: 'Congo',
	CD: 'Congo (République démocratique)',
	CK: 'Cook (Îles)',
	KR: 'Corée (République de)',
	KP: 'Corée du Nord',
	CR: 'Costa Rica',
	CI: "Côte d'Ivoire",
	HR: 'Croatie',
	CU: 'Cuba',
	CW: 'Curaçao',
	DK: 'Danemark',
	DJ: 'Djibouti',
	DM: 'Dominique',
	EG: 'Egypte',
	SV: 'El Salvador',
	AE: 'Emirats arabes unis',
	EC: 'Equateur',
	ER: 'Erythrée',
	ES: 'Espagne',
	EE: 'Estonie',
	US: 'Etats-Unis',
	ET: 'Ethiopie',
	MK: 'Ex-République yougoslave de Macédoine',
	FO: 'Féroé (Îles)',
	FJ: 'Fidji',
	FI: 'Finlande',
	FR: 'France',
	GA: 'Gabon',
	GM: 'Gambie',
	GE: 'Géorgie',
	GH: 'Ghana',
	GI: 'Gibraltar',
	GR: 'Grèce',
	GD: 'Grenade',
	GL: 'Groenland',
	GT: 'Guatemala',
	GG: 'Guernesey',
	GN: 'Guinée',
	GQ: 'Guinée équatoriale',
	GW: 'Guinée-Bissau',
	GY: 'Guyana',
	HT: 'Haïti',
	HN: 'Honduras',
	HK: 'Hong Kong',
	HU: 'Hongrie',
	IN: 'Inde',
	ID: 'Indonésie',
	IR: 'Iran',
	IQ: 'Iraq',
	IE: 'Irlande',
	IS: 'Islande',
	IL: 'Israël',
	IT: 'Italie',
	JM: 'Jamaïque',
	JP: 'Japon',
	JE: 'Jersey',
	JO: 'Jordanie',
	KZ: 'Kazakhstan',
	KE: 'Kenya',
	KG: 'Kirghizistan',
	KI: 'Kiribati',
	XK: 'Kosovo',
	KW: 'Koweït',
	LA: 'Laos',
	LS: 'Lesotho',
	LV: 'Lettonie',
	LB: 'Liban',
	LR: 'Liberia',
	LY: 'Libye',
	LI: 'Liechtenstein',
	LT: 'Lituanie',
	LU: 'Luxembourg',
	MO: 'Macao',
	MG: 'Madagascar',
	MY: 'Malaisie',
	MW: 'Malawi',
	MV: 'Maldives',
	ML: 'Mali',
	MT: 'Malte',
	IM: 'Man (Île de)',
	MA: 'Maroc',
	MH: 'Marshall (Îles)',
	MU: 'Maurice',
	MR: 'Mauritanie',
	MX: 'Mexique',
	FM: 'Micronésie',
	MD: 'Moldavie',
	MC: 'Monaco',
	MN: 'Mongolie',
	ME: 'Monténégro',
	MZ: 'Mozambique',
	NA: 'Namibie',
	NR: 'Nauru',
	NP: 'Népal',
	NI: 'Nicaragua',
	NE: 'Niger',
	NG: 'Nigéria',
	NO: 'Norvège',
	NZ: 'Nouvelle-Zélande',
	IO: "Océan Indien (Territoire britannique de l')",
	OM: 'Oman',
	UG: 'Ouganda',
	UZ: 'Ouzbékistan',
	PK: 'Pakistan',
	PW: 'Palaos',
	PS: 'Palestine',
	PA: 'Panama',
	PG: 'Papouasie-Nouvelle-Guinée',
	PY: 'Paraguay',
	NL: 'Pays-Bas',
	PE: 'Pérou',
	PH: 'Philippines',
	PN: 'Pitcairn',
	PL: 'Pologne',
	PT: 'Portugal',
	QA: 'Qatar',
	DO: 'Dominicaine (République)',
	RO: 'Roumanie',
	GB: 'Royaume-Uni',
	RU: 'Russie',
	RW: 'Rwanda',
	EH: 'Sahara occidental',
	KN: 'Saint-Christophe-et-Niévès',
	SM: 'Saint-Marin',
	SX: 'Saint-Martin (partie néerlandaise)',
	VC: 'Saint-Vincent-et-les-Grenadines',
	SH: 'Sainte-Hélène et le dépendance de Tristan da Cunha',
	LC: 'Sainte-Lucie',
	SB: 'Salomon (Îles)',
	WS: 'Samoa',
	ST: 'Sao Tomé-et-Principe',
	SN: 'Sénégal',
	RS: 'Serbie',
	SC: 'Seychelles',
	SL: 'Sierra Leone',
	SG: 'Singapour',
	SK: 'Slovaquie',
	SI: 'Slovénie',
	SO: 'Somalie',
	SD: 'Soudan',
	SS: 'Soudan du Sud',
	LK: 'Sri Lanka',
	SE: 'Suède',
	CH: 'Suisse',
	SR: 'Suriname',
	SZ: 'Eswatini',
	SY: 'Syrie',
	TJ: 'Tadjikistan',
	TW: 'Taïwan',
	TZ: 'Tanzanie',
	TD: 'Tchad',
	CZ: 'Tchéquie',
	TH: 'Thaïlande',
	TL: 'Timor oriental',
	TG: 'Togo',
	TO: 'Tonga',
	TT: 'Trinité-et-Tobago',
	TN: 'Tunisie',
	TM: 'Turkménistan',
	TR: 'Turquie',
	TV: 'Tuvalu',
	UA: 'Ukraine',
	UY: 'Uruguay',
	VU: 'Vanuatu',
	VA: 'Vatican ou le Saint-Siège',
	VE: 'Venezuela',
	VN: 'Vietnam',
	YE: 'Yemen',
	ZM: 'Zambie',
	ZW: 'Zimbabwe',
};
export default BirthCountryReferentiel;
