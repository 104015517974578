import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Center, Flex, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';

import TopBar from 'app/Layout/Onboarding/elements/TopBar';
import OriasLogo from 'assets/logoPartners/orias-logo.png';
import PhoenixLogo from 'assets/logoPartners/phoenix-logo.png';
import ReutersLogo from 'assets/logoPartners/reuters-logo.png';
import RamifyLogo from 'assets/logoRamify/black.svg';
import ContractIcon from 'assets/phoenix/contract.svg';
import InvestIcon from 'assets/phoenix/invest.svg';
import TargetIcon from 'assets/phoenix/target.svg';
import Banner from 'components/Banners/Banner';
import useThemedToast from 'hooks/useThemedToast';
import { useGetUserSCPISubscriptionsQuery } from 'services/requests/realEstate/subscriptions';

const Phoenix = (): JSX.Element => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const { data: subscriptions, isError } = useGetUserSCPISubscriptionsQuery();

	useEffect(() => {
		if (isError)
			toast({
				title: 'Une erreur est survenue lors du chargement de votre souscription',
				status: 'error',
				duration: 4500,
				isClosable: true,
			});
	}, [isError, toast]);

	const redirect = useCallback(() => {
		if (!subscriptions) return;
		navigate('/phoenix/onboarding/' + subscriptions[0].id);
	}, [subscriptions, navigate]);

	return (
		<VStack minH="100vh" bg="grey.100" spacing={0}>
			<TopBar showMenu={false} />
			<HStack py="40px" spacing="40px">
				<Image src={PhoenixLogo} h="110px" />
				<Text>✕</Text>
				<Image src={RamifyLogo} h="48px" />
			</HStack>
			<VStack justifyContent="center" w={{ base: '90%', md: '80%', xl: '60%' }} pb="40px" pt="24px" spacing="64px">
				{/* Section 1 */}
				<Box w="100%">
					<Heading variant="Title-M-SemiBold" pb="8px">
						Optimisez votre trésorerie d’entreprise pour vous constituer un capital
					</Heading>
					<Text fontSize="14px" fontWeight="light" pb="32px">
						En partenariat avec <b>Ramify, Phoenix Business Bank</b> vous propose de faire fructifier la trésorerie de
						votre entreprise
					</Text>
					<Banner
						title="Completez votre profil Ramify"
						variant="information"
						description="Plus que 5 minutes avant de faire fructifier votre trésorerie"
						cta="Finaliser"
						onClick={redirect}
						avatar="samy"
					/>
				</Box>

				{/* Section 2 */}
				<Box w="100%">
					<Heading variant="Title-M-SemiBold" pb="40px">
						Ramify, une plateforme de gestion de patrimoine sécurisée et performante
					</Heading>
					<HStack spacing="56px" align="start">
						<Flex align="start" direction="column" flex="1">
							<Image src={OriasLogo} filter="grayscale(100%)" h="32px" />
							<Text pt="16px" pb="8px" variant="title">
								Enregistré à l'ORIAS
							</Text>
							<Text variant="detail">
								Le Registre unique des intermédiaires en assurance, banque et finance recense les personnes physiques ou
								morales, autorisées à exercer l’activité de conseiller en investissements financiers en France
							</Text>
						</Flex>
						<Flex align="start" direction="column" flex="1">
							<Image src={ReutersLogo} filter="grayscale(100%)" h="32px" />
							<Text pt="16px" pb="8px" variant="title">
								Partenariat data provider
							</Text>
							<Text variant="detail">
								Nous accordons énormément d’importance à la qualité de la donnée sur laquelle nous basons nos
								recherches. En ce sens, nous avons choisi Reuters comme fournisseur de données, l’un des leader mondiaux
								en la matière
							</Text>
						</Flex>
					</HStack>
				</Box>

				{/* Section 3 */}
				<Box w="100%">
					<Heading variant="Title-M-SemiBold" pb="16px">
						Ok. Et comment ça marche ?
					</Heading>
					<Card direction="row">
						<VStack spacing="20px" align="start" px="24px" py="40px" flex="1">
							<Center w="46px" h="46px" borderRadius="100px" borderWidth="1px" borderColor="primary.black">
								<Text fontSize="20px">1</Text>
							</Center>
							<Text variant="title" h="72px">
								Signez les documents
							</Text>
							<Text h="108px" color="grey.900">
								Deux documents à signer pour valider votre profil sur Ramify
							</Text>
							<Image src={ContractIcon} h="100px" />
						</VStack>
						<VStack spacing="20px" align="start" px="24px" py="40px" flex="1">
							<Center w="46px" h="46px" borderRadius="100px" borderWidth="1px" borderColor="primary.black">
								<Text fontSize="20px">2</Text>
							</Center>
							<Text variant="title" h="72px">
								Remplissez le questionnaire d’adéquation
							</Text>
							<Text h="108px" color="grey.900">
								Ces données sont nécessaires vis-à-vis de nos obligations réglementaires et en particulier concernant la
								lutte contre le blanchiment.
							</Text>
							<Image src={TargetIcon} h="100px" />
						</VStack>
						<VStack spacing="20px" align="start" px="24px" py="40px" flex="1">
							<Center w="46px" h="46px" borderRadius="100px" borderWidth="1px" borderColor="primary.black">
								<Text fontSize="20px">3</Text>
							</Center>
							<Text variant="title" h="72px">
								Suivez vos investissements sur Ramify
							</Text>
							<Text h="108px" color="grey.900">
								L’espace client Ramify vous permettra de suivre en détail vos investissements.
							</Text>
							<Image src={InvestIcon} h="100px" />
						</VStack>
					</Card>
				</Box>
			</VStack>
		</VStack>
	);
};

export default Phoenix;
