import React from 'react';
import { Checkbox, CheckboxGroup, HStack, Wrap, WrapItem } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';

const MultipleCheckboxSelect = <T extends string | number>({
	options,
	value: currentValue,
	onChange,
}: {
	options: readonly {
		label: string;
		value: T;
		tooltip?: React.ReactNode;
	}[];
	value: T[];
	onChange: (value: T[]) => void;
}): JSX.Element => (
	<Wrap overflow="visible">
		<CheckboxGroup onChange={onChange} value={currentValue} variant="full">
			{options.map(({ label, value: newValue, tooltip }) => (
				<WrapItem w={{ base: '100%', md: '320px' }} key={newValue}>
					<Checkbox value={newValue} isRequired={false}>
						<HStack>
							<>{label}</>
							{tooltip && <CustomTooltip text={tooltip} />}
						</HStack>
					</Checkbox>
				</WrapItem>
			))}
		</CheckboxGroup>
	</Wrap>
);

export default MultipleCheckboxSelect;
