import { FinancialLostType, LostReactionType } from 'store/types/profilage.type';

export interface RiskAversionAnswerType {
	text: string;
	value: 'true' | 'false' | LostReactionType | FinancialLostType;
	cy: string;
}

export interface RiskAversionQuestionType {
	key: 'hasEverUsedSavingPlan' | 'financialLost' | 'lostReaction';
	question: string;
	whyThisQuestion: string;
	answers: RiskAversionAnswerType[];
}

export const RiskAversionQuestions: RiskAversionQuestionType[] = [
	{
		key: 'hasEverUsedSavingPlan',
		question:
			'Avez vous déjà placé de l’argent sur un contrat d’assurance vie, un compte-titres ou un plan d’épargne en actions (PEA) ?',
		whyThisQuestion:
			"Cette question nous permet d'en savoir plus sur vos expériences passées en terme d'investissement. Cela nous permettra d'adapter le niveau de risque et d'accompagnement à votre profil.",
		answers: [
			{ text: 'Oui', value: 'true', cy: 'cy-risk-yes-btn' },
			{ text: 'Non', value: 'false', cy: 'cy-risk-no-btn' },
		],
	},
	{
		key: 'financialLost',
		question: 'Avez-vous déjà subi des pertes sur vos placements financiers ?',
		whyThisQuestion:
			"Chaque investisseur réagit différemment face aux pertes financières. Cette question nous permet de mieux déterminer votre profil de risque afin de vous constituer un portefeuille d'actifs financiers sur-mesure. Votre tolérance aux pertes nous permet de mieux identifier le risque que vous êtes prêt à prendre ainsi que l'allocation d'actifs de votre portefeuille.",
		answers: [
			{ text: 'Non, jamais', value: 'NO', cy: 'cy-lost-no' },
			{ text: 'Oui, de 10% maximum', value: 'MAX_10', cy: 'cy-lost-10' },
			{ text: 'Oui, de 20% maximum', value: 'MAX_20', cy: 'cy-lost-20' },
			{ text: 'Oui, de plus de 20%', value: 'MORE_20', cy: 'cy-lost-more-20' },
		],
	},
	{
		key: 'lostReaction',
		question: 'Votre investissement perd 10% de sa valeur en 3 mois. Que faites-vous ?',
		whyThisQuestion:
			"Chaque investisseur réagit différemment face aux pertes financières. Cette question nous permet de mieux déterminer votre profil de risque afin de vous constituer un portefeuille d'actifs financiers sur-mesure. Votre tolérance aux pertes nous permet de mieux identifier le risque que vous êtes prêt à prendre ainsi que l'allocation d'actifs de votre portefeuille.",
		answers: [
			{ text: 'Je patiente sans paniquer', value: 'WAIT', cy: 'cy-reaction-wait' },
			{ text: 'Je réinvestis pour en profiter', value: 'REINVEST', cy: 'cy-reaction-reinvest' },
			{ text: 'Je vends tout', value: 'SELL', cy: 'cy-reaction-sell' },
			{ text: 'Je ne sais pas', value: 'UNKNOWN', cy: 'cy-reaction-unknown' },
		],
	},
];
