import { Dispatch, SetStateAction } from 'react';
import { Tag, TagLabel, Wrap, WrapItem } from '@chakra-ui/react';

export type YearDataProp<Type> = {
	label: string;
	labelValue: Type;
};

export type YearFilterDataProp<Type> = {
	filterValue: Type;
	setFilterValue: Dispatch<SetStateAction<Type>>;
};

const Filter = ({
	label,
	labelValue,
	filterValue,
	setFilterValue,
}: YearDataProp<unknown> & YearFilterDataProp<unknown>): JSX.Element => {
	return (
		<Tag
			onClick={() => {
				setFilterValue(labelValue);
			}}
			variant={filterValue === labelValue ? 'filter-selected' : 'filter'}
		>
			<TagLabel>{label}</TagLabel>
		</Tag>
	);
};

const FilterSelect = ({ label, data }: { data: YearDataProp<unknown>[]; label: YearFilterDataProp<unknown> }) => {
	return (
		<Wrap spacing="16px" align="center">
			{data.map((year) => (
				<WrapItem key={year.label}>
					<Filter
						key={year.label}
						label={year.label}
						labelValue={year.labelValue}
						filterValue={label.filterValue}
						setFilterValue={label.setFilterValue}
					/>
				</WrapItem>
			))}
		</Wrap>
	);
};

export default FilterSelect;
