import { backendApi } from 'services/apiService';
import { SCPISubscription } from 'store/types/airtable.type';
import { ScpiIntention } from 'store/types/scpiKyc.type';

type Stringified<T> = {
	[P in keyof T]: string;
};
export type SubmitSCPISubscription = Pick<
	SCPISubscription,
	'fundsOrigin' | 'bankAccountId' | Stringified<'objectives'>
> & { subscriptionId: string; isAutoFund: boolean; jof: File };

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getUserSCPISubscriptions: builder.query<SCPISubscription[], void>({
			query: () => '/real-estate/subscriptions',
			providesTags: ['SCPI_SUBSCRIPTIONS'],
		}),
		getUserSCPISubscriptionById: builder.query<SCPISubscription, { id: string }>({
			query: (params) => `/real-estate/subscriptions/${params.id}`,
			providesTags: ['SCPI_SUBSCRIPTIONS'],
		}),
		createSCPIIntention: builder.mutation<SCPISubscription, ScpiIntention>({
			query: (params) => ({
				url: '/real-estate/subscriptions',
				method: 'POST',
				body: params,
			}),
			invalidatesTags: ['SCPI_SUBSCRIPTIONS'],
		}),
		updateSCPIIntention: builder.mutation<void, ScpiIntention & { id: string }>({
			query: ({ id, ...params }) => ({
				url: `/real-estate/subscriptions/${id}`,
				method: 'PUT',
				body: params,
			}),
			invalidatesTags: ['SCPI_SUBSCRIPTIONS'],
		}),
		submitSCPISubscription: builder.mutation<void, SubmitSCPISubscription>({
			query: (formData) => ({
				url: `/real-estate/subscriptions/submit`,
				method: 'POST',
				body: formData,
			}),
			invalidatesTags: ['SCPI_SUBSCRIPTIONS'],
		}),
		deleteSCPISubscription: builder.mutation<void, { id: string }>({
			query: (params) => ({
				url: `/real-estate/subscriptions/${params.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['SCPI_SUBSCRIPTIONS'],
		}),
	}),
});

export const {
	useGetUserSCPISubscriptionsQuery,
	useLazyGetUserSCPISubscriptionsQuery,
	useGetUserSCPISubscriptionByIdQuery,
	useCreateSCPIIntentionMutation,
	useSubmitSCPISubscriptionMutation,
	useUpdateSCPIIntentionMutation,
	useDeleteSCPISubscriptionMutation,
} = extendedApi;
