import { useState } from 'react';
import { ChevronDown, ChevronUp } from '@carbon/icons-react';
import {
	Button,
	Collapse,
	Divider,
	HStack,
	Image,
	Link,
	Skeleton,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import FileDownload from 'assets/icons/File_Document.svg';
import CustomTooltip from 'components/CustomTooltip';
import AnimatedBar from 'components/Graphs/AnimatedBar';
import AssetBadge from 'components/Tags';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { PerformanceObject, RateOfReturnsResponse } from 'services/requests/investment';
import { CustomAsset } from 'store/types/investment.type';
import colors from 'theme/foundations/colors';
import { isNotNone } from 'utils/functions';
import { displayMoney, displayPercentage } from 'utils/rendering';

export type InvestmentTableProps = {
	assets: CustomAsset[];
	isLoading?: boolean;
	displayAmount?: boolean;
	showPerformance?: boolean;
	showRepartitionBars?: boolean;
	rateOfreturns?: RateOfReturnsResponse | undefined;
};

const ListCompositionPortfolio = ({
	assets,
	displayAmount = false,
	isLoading = false,
	showPerformance = true,
	showRepartitionBars = true,
	rateOfreturns = undefined,
}: InvestmentTableProps) => {
	const isDesktop = useAppResponsive({ base: false, xl: true });
	const [indexToShow, setIndexToShow] = useState<number>();
	const getReturnOfAssetByIsin = (isin: string): PerformanceObject =>
		rateOfreturns?.isin_return_dict[isin] ||
		({
			valuePerformance: 0,
			percentagePerformance: 0,
		} as PerformanceObject);

	const rateOfReturnExist: boolean =
		!!rateOfreturns &&
		rateOfreturns?.portfolio_return.valuePerformance !== 0 &&
		rateOfreturns?.portfolio_return.percentagePerformance !== 0;

	return (
		<VStack w="100%" spacing="32px">
			{isDesktop && (
				<HStack w="100%" align="start" spacing="32px" justify="space-between">
					<Text minW="250px" variant="Caption" color="grey.900">
						ACTIFS
					</Text>
					<Text minW="200px" variant="Caption" color="grey.900">
						REPARTITION
					</Text>
					{showPerformance && rateOfReturnExist ? (
						<Text minW="150px" variant="Caption" color="grey.900">
							PERFORMANCE
						</Text>
					) : (
						<></>
					)}
					<Text minW="120px" /> {/* empty cell for the alignment, do not delete */}
				</HStack>
			)}

			<VStack w="100%" spacing="32px">
				{assets.map((asset, index) => {
					const { valuePerformance } = getReturnOfAssetByIsin(asset.ISIN);

					return (
						<VStack w="100%" spacing="16px" key={asset.ISIN}>
							<Skeleton w="100%" borderRadius="base" isLoaded={!isLoading}>
								<Wrap spacing="32px" w="100%" justify="space-between">
									<WrapItem minW="250px">
										<VStack align="start" spacing="8px">
											<Text variant="Text-M-Medium">{asset.label}</Text>
											<Wrap>
												<WrapItem>
													<AssetBadge variant={asset.type} />
												</WrapItem>

												{asset.greenTag && (
													<WrapItem>
														<AssetBadge variant={asset.greenTag} />
													</WrapItem>
												)}
											</Wrap>
										</VStack>
									</WrapItem>
									<WrapItem minW="200px">
										<VStack align="start" spacing="8px" w="100%">
											{displayAmount && asset.montant ? (
												<Text variant="Text-M-Medium">
													{displayMoney(asset.montant)}&nbsp;({displayPercentage(asset.repartition)})
												</Text>
											) : (
												<Text variant="Text-M-Medium">{displayPercentage(asset.repartition)}</Text>
											)}
											{showRepartitionBars && (
												<VStack w="100%" align="start">
													<AnimatedBar
														width={`${(asset.repartition * 100) / assets[0].repartition}%`}
														color={
															asset.type === 'Actions'
																? colors.blue[500]
																: asset.type === 'Obligations'
																? colors.purple[500]
																: asset.type === 'Fonds Euros'
																? colors.yellow[300]
																: asset.type === 'SCPI'
																? colors.green[500]
																: asset.type === 'Private Equity'
																? colors.red[500]
																: colors.blue[500]
														}
													/>
												</VStack>
											)}
										</VStack>
									</WrapItem>
									{showPerformance && rateOfReturnExist && isNotNone(valuePerformance) && (
										<WrapItem w="150px">
											<Text variant="Text-M-Medium" color={valuePerformance >= 0 ? 'positive.900' : 'negative.900'}>
												{valuePerformance >= 0 && '+'}
												{displayMoney(valuePerformance)}
											</Text>
										</WrapItem>
									)}
									<WrapItem>
										{isDesktop ? (
											<HStack
												w="120px"
												_hover={{ cursor: 'pointer' }}
												onClick={() => {
													if (index === indexToShow) {
														setIndexToShow(undefined);
													} else {
														setIndexToShow(index);
													}
												}}
											>
												<Text variant="Text-S-Regular" _hover={{ textDecoration: 'underline' }}>
													Voir les détails
												</Text>
												{index === indexToShow ? <ChevronUp /> : <ChevronDown />}
											</HStack>
										) : (
											<Button
												w="100%"
												variant="secondary"
												_hover={{ bg: 'transparent' }}
												onClick={() => {
													if (index === indexToShow) {
														setIndexToShow(undefined);
													} else {
														setIndexToShow(index);
													}
												}}
											>
												Voir les détails
												{index === indexToShow ? <ChevronUp /> : <ChevronDown />}
											</Button>
										)}
									</WrapItem>
								</Wrap>
							</Skeleton>
							<Collapse in={index === indexToShow} animateOpacity>
								<Wrap flex="1" justify="space-between" pl="8px">
									<WrapItem minW="60px">
										<Link href={asset.url} isExternal>
											<HStack w="100%" spacing="2px">
												{isDesktop && (
													<Text color="grey.900" decoration="underline" variant="Text-S-Medium">
														DIC
													</Text>
												)}
												{!isDesktop && <Text as="u">Télécharger le document </Text>}
												<Image src={FileDownload} alt="fileDownload" />
											</HStack>
										</Link>
									</WrapItem>

									<WrapItem minW="160px">
										<Text color="grey.700" variant="Text-S-Medium">
											Secteur
										</Text>
										<Text as="span" color="grey.900" variant="Text-S-Medium">
											&nbsp;
											{asset.name.split('- ')[1]}
										</Text>
									</WrapItem>

									<WrapItem minW="160px">
										<HStack spacing="2px">
											<Text color="grey.700" variant="Text-S-Medium">
												ISIN
												<Text as="span" color="grey.900" variant="Text-S-Medium">
													{' '}
													{asset.ISIN}
												</Text>
											</Text>
											{/* afficher un tool tip uniquement que le isin se termine par un D */}
											{asset.ISIN.substring(asset.ISIN.length - 1) === 'D' && (
												<CustomTooltip text="Les loyers générés par cette SCPI seront réinvestis en fonds euros. Au vu de votre horizon d’investissement nous estimons que cette solution est meilleur qu’un réinvestissement dans la SCPI." />
											)}
										</HStack>
									</WrapItem>

									{!!(asset?.nbShares && asset?.nbShares > 0) && (
										<WrapItem minW="160px">
											<Text color="grey.700" variant="Text-S-Medium">
												Nombre de parts
												<Text as="span" color="grey.900" variant="Text-S-Medium">
													{' '}
													{asset.nbShares.toFixed(4)}
												</Text>
											</Text>
										</WrapItem>
									)}

									{!!(asset.montant && asset.nbShares && asset.nbShares > 0) && (
										<WrapItem minW="160px">
											<Text color="grey.700" variant="Text-S-Medium">
												Valeur actuelle de la part{' '}
												<Text as="span" color="grey.900" variant="Text-S-Medium">
													{displayMoney(asset.montant / asset.nbShares)}
												</Text>
											</Text>
										</WrapItem>
									)}
								</Wrap>
							</Collapse>
							{index !== assets.length - 1 && <Divider />}
						</VStack>
					);
				})}
			</VStack>
		</VStack>
	);
};

export default ListCompositionPortfolio;
