export enum Citizenship {
	AF = 'AF',
	AL = 'AL',
	DZ = 'DZ',
	DE = 'DE',
	US = 'US',
	AD = 'AD',
	AO = 'AO',
	AG = 'AG',
	AR = 'AR',
	AM = 'AM',
	AU = 'AU',
	AT = 'AT',
	AZ = 'AZ',
	BS = 'BS',
	BH = 'BH',
	BD = 'BD',
	BB = 'BB',
	BZ = 'BZ',
	BE = 'BE',
	BJ = 'BJ',
	BM = 'BM',
	BT = 'BT',
	BY = 'BY',
	MM = 'MM',
	GW = 'GW',
	BO = 'BO',
	BA = 'BA',
	BW = 'BW',
	BR = 'BR',
	GB = 'GB',
	BN = 'BN',
	BG = 'BG',
	BF = 'BF',
	BI = 'BI',
	KH = 'KH',
	CM = 'CM',
	CA = 'CA',
	CV = 'CV',
	CF = 'CF',
	CL = 'CL',
	CN = 'CN',
	KN = 'KN',
	CY = 'CY',
	CO = 'CO',
	KM = 'KM',
	CG = 'CG',
	CD = 'CD',
	CK = 'CK',
	CR = 'CR',
	HR = 'HR',
	CU = 'CU',
	DK = 'DK',
	DJ = 'DJ',
	DO = 'DO',
	DM = 'DM',
	EG = 'EG',
	AE = 'AE',
	GQ = 'GQ',
	EC = 'EC',
	ER = 'ER',
	ES = 'ES',
	EE = 'EE',
	SZ = 'SZ',
	ET = 'ET',
	FJ = 'FJ',
	FI = 'FI',
	FR = 'FR',
	GA = 'GA',
	GM = 'GM',
	GE = 'GE',
	GH = 'GH',
	GI = 'GI',
	GD = 'GD',
	GP = 'GP',
	GT = 'GT',
	GG = 'GG',
	GN = 'GN',
	GY = 'GY',
	HT = 'HT',
	GR = 'GR',
	HN = 'HN',
	HK = 'HK',
	HU = 'HU',
	IN = 'IN',
	ID = 'ID',
	IQ = 'IQ',
	IR = 'IR',
	IE = 'IE',
	IS = 'IS',
	IL = 'IL',
	IT = 'IT',
	CI = 'CI',
	JM = 'JM',
	JP = 'JP',
	JO = 'JO',
	KZ = 'KZ',
	KE = 'KE',
	KG = 'KG',
	KI = 'KI',
	XK = 'XK',
	KW = 'KW',
	LA = 'LA',
	LS = 'LS',
	LV = 'LV',
	LB = 'LB',
	LR = 'LR',
	LY = 'LY',
	LI = 'LI',
	LT = 'LT',
	LU = 'LU',
	MO = 'MO',
	MK = 'MK',
	MY = 'MY',
	MW = 'MW',
	MV = 'MV',
	MG = 'MG',
	ML = 'ML',
	MT = 'MT',
	MA = 'MA',
	MH = 'MH',
	MQ = 'MQ',
	MU = 'MU',
	MR = 'MR',
	MX = 'MX',
	FM = 'FM',
	MD = 'MD',
	MC = 'MC',
	MN = 'MN',
	ME = 'ME',
	MS = 'MS',
	MZ = 'MZ',
	NA = 'NA',
	NR = 'NR',
	NL = 'NL',
	NZ = 'NZ',
	NP = 'NP',
	NI = 'NI',
	NE = 'NE',
	NG = 'NG',
	KP = 'KP',
	NO = 'NO',
	NC = 'NC',
	OM = 'OM',
	UG = 'UG',
	UZ = 'UZ',
	PK = 'PK',
	PW = 'PW',
	PS = 'PS',
	PA = 'PA',
	PG = 'PG',
	PY = 'PY',
	PE = 'PE',
	PH = 'PH',
	PL = 'PL',
	PF = 'PF',
	PT = 'PT',
	QA = 'QA',
	RO = 'RO',
	RU = 'RU',
	RW = 'RW',
	LC = 'LC',
	SM = 'SM',
	SB = 'SB',
	SV = 'SV',
	WS = 'WS',
	ST = 'ST',
	SA = 'SA',
	SN = 'SN',
	RS = 'RS',
	SC = 'SC',
	SL = 'SL',
	SG = 'SG',
	SK = 'SK',
	SI = 'SI',
	SO = 'SO',
	SD = 'SD',
	LK = 'LK',
	ZA = 'ZA',
	KR = 'KR',
	SS = 'SS',
	SE = 'SE',
	CH = 'CH',
	SR = 'SR',
	SY = 'SY',
	TJ = 'TJ',
	TW = 'TW',
	TZ = 'TZ',
	TD = 'TD',
	CZ = 'CZ',
	TH = 'TH',
	TL = 'TL',
	TO = 'TO',
	TG = 'TG',
	TT = 'TT',
	TN = 'TN',
	TM = 'TM',
	TR = 'TR',
	TV = 'TV',
	UA = 'UA',
	UY = 'UY',
	VU = 'VU',
	VA = 'VA',
	VE = 'VE',
	VN = 'VN',
	VC = 'VC',
	YE = 'YE',
	ZM = 'ZM',
	ZW = 'ZW',
}

const CitizenshipReferentiel: Record<Citizenship, string> = {
	AF: 'Afghane',
	AL: 'Albanaise',
	DZ: 'Algérienne',
	DE: 'Allemande',
	US: 'Américaine',
	AD: 'Andorrane',
	AO: 'Angolaise',
	AG: 'Antiguayenne',
	AR: 'Argentine',
	AM: 'Arménienne',
	AU: 'Australienne',
	AT: 'Autrichienne',
	AZ: 'Azerbaïdjanaise',
	BS: 'Bahamienne',
	BH: 'Bahreïnienne',
	BD: 'Bangladaise',
	BB: 'Barbadienne',
	BZ: 'Bélizienne',
	BE: 'Belge',
	BJ: 'Béninoise',
	BM: 'Bermudes',
	BT: 'Bhoutanaise',
	BY: 'Biélorusse',
	MM: 'Birmane',
	GW: 'Bissau-Guinéenne',
	BO: 'Bolivienne',
	BA: 'Bosnienne',
	BW: 'Botswanaise',
	BR: 'Brésilienne',
	GB: 'Britannique',
	BN: 'Brunéienne',
	BG: 'Bulgare',
	BF: 'Burkinabé',
	BI: 'Burundaise',
	KH: 'Cambodgienne',
	CM: 'Camerounaise',
	CA: 'Canadienne',
	CV: 'Cap-verdienne',
	CF: 'Centrafricaine',
	CL: 'Chilienne',
	CN: 'Chinoise',
	KN: 'Christophienne',
	CY: 'Chypriote',
	CO: 'Colombienne',
	KM: 'Comorienne',
	CG: 'Congolaise',
	CD: 'Congolaise (RDC)',
	CK: 'Cookienne',
	CR: 'Costaricaine',
	HR: 'Croate',
	CU: 'Cubaine',
	DK: 'Danoise',
	DJ: 'Djiboutienne',
	DO: 'Dominicaine',
	DM: 'Dominiquaise',
	EG: 'Egyptienne',
	AE: 'Emirienne',
	GQ: 'Equato-Guinéenne',
	EC: 'Equatorienne',
	ER: 'Erythréenne',
	ES: 'Espagnole',
	EE: 'Estonienne',
	SZ: 'Eswatinienne',
	ET: 'Ethiopienne',
	FJ: 'Fidjienne',
	FI: 'Finlandaise',
	FR: 'Française',
	GA: 'Gabonaise',
	GM: 'Gambienne',
	GE: 'Georgienne',
	GH: 'Ghanéenne',
	GI: 'Gibraltar',
	GD: 'Grenadienne',
	GP: 'Guadeloupe',
	GT: 'Guatémaltèque',
	GG: 'Guernesiaise',
	GN: 'Guinéenne',
	GY: 'Guyana',
	HT: 'Haïtienne',
	GR: 'Hellénique',
	HN: 'Hondurienne',
	HK: 'Hong Kong',
	HU: 'Hongroise',
	IN: 'Indienne',
	ID: 'Indonésienne',
	IQ: 'Irakienne',
	IR: 'Iranien',
	IE: 'Irlandaise',
	IS: 'Islandaise',
	IL: 'Israélienne',
	IT: 'Italienne',
	CI: 'Ivoirienne',
	JM: 'Jamaïcaine',
	JP: 'Japonaise',
	JO: 'Jordanienne',
	KZ: 'Kazakhstanaise',
	KE: 'Kenyane',
	KG: 'Kirghize',
	KI: 'Kiribatienne',
	XK: 'Kosovare',
	KW: 'Koweitienne',
	LA: 'Laotienne',
	LS: 'Lesothane',
	LV: 'Lettone',
	LB: 'Libanaise',
	LR: 'Libérienne',
	LY: 'Libyenne',
	LI: 'Liechtensteinoise',
	LT: 'Lituanienne',
	LU: 'Luxembourgeoise',
	MO: 'Macao',
	MK: 'Macédonienne',
	MY: 'Malaisienne',
	MW: 'Malawienne',
	MV: 'Maldivienne',
	MG: 'Malgache',
	ML: 'Malienne',
	MT: 'Maltaise',
	MA: 'Marocaine',
	MH: 'Marshallaise',
	MQ: 'Martinique',
	MU: 'Mauricienne',
	MR: 'Mauritanienne',
	MX: 'Mexicaine',
	FM: 'Micronésienne',
	MD: 'Moldave',
	MC: 'Monégasque',
	MN: 'Mongole',
	ME: 'Monténégrine',
	MS: 'Montserrat',
	MZ: 'Mozambicaine',
	NA: 'Namibienne',
	NR: 'Nauruane',
	NL: 'Néerlandaise',
	NZ: 'Néo-zélandaise',
	NP: 'Népalaise',
	NI: 'Nicaraguayenne',
	NE: 'Niger',
	NG: 'Nigériane',
	KP: 'Nord-coréenne',
	NO: 'Norvégienne',
	NC: 'Nouvelle Calédonie',
	OM: 'Omanaise',
	UG: 'Ougandaise',
	UZ: 'Ouzbèke',
	PK: 'Pakistanaise',
	PW: 'Palaoise',
	PS: 'Palestinien',
	PA: 'Panaméenne',
	PG: 'Papouasienne',
	PY: 'Paraguayenne',
	PE: 'Péruvienne',
	PH: 'Philippine',
	PL: 'Polonaise',
	PF: 'Polynésie Française',
	PT: 'Portuguaise',
	QA: 'Qatarienne',
	RO: 'Roumaine',
	RU: 'Russe',
	RW: 'Rwandaise',
	LC: 'Saint-Lucienne',
	SM: 'Saint-Marinaise',
	SB: 'Salomonaise',
	SV: 'Salvadorienne',
	WS: 'Samoane',
	ST: 'Santoméenne',
	SA: 'Saoudienne',
	SN: 'Sénégalaise',
	RS: 'Serbe',
	SC: 'Seychelloise',
	SL: 'Sierraléonaise',
	SG: 'Singapourienne',
	SK: 'Slovaque',
	SI: 'Slovène',
	SO: 'Somalienne',
	SD: 'Soudanaise',
	LK: 'Sri-lankaise',
	ZA: 'Sud-africaine',
	KR: 'Sud-coréenne',
	SS: 'Sud-soudanaise',
	SE: 'Suédoise',
	CH: 'Suisse',
	SR: 'Surinamaise',
	SY: 'Syrienne',
	TJ: 'Tadjike',
	TW: 'Taiwanaise',
	TZ: 'Tanzanienne',
	TD: 'Tchadienne',
	CZ: 'Tchèque',
	TH: 'Thaïlandaise',
	TL: 'Timoraise',
	TO: 'Tonga',
	TG: 'Tonguienne',
	TT: 'Trinidadienne',
	TN: 'Tunisienne',
	TM: 'Turkmène',
	TR: 'Turque',
	TV: 'Tuvalaise',
	UA: 'Ukrainienne',
	UY: 'Uruguayenne',
	VU: 'Vanuatuane',
	VA: 'Vaticanaise',
	VE: 'Vénézuélienne',
	VN: 'Vietnamienne',
	VC: 'Vincentaise',
	YE: 'Yéménite',
	ZM: 'Zambienne',
	ZW: 'Zimbabwéenne',
};

export default CitizenshipReferentiel;
