import { HStack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { FeesType } from 'hooks/useFees';
import { FeeCard, FeeEnterExit } from 'pages/Invest/Discover/OurFees';

type OurFeesProps = {
	fees: Record<FeesType, number>;
};

export const OurFees = ({ fees }: OurFeesProps) => (
	<VStack w="100%" align="start" spacing="16px">
		<Text variant="Title-M-SemiBold">Détail de nos frais</Text>
		<VStack w="100%" align="start" spacing="16px">
			<Text variant="Text-M-Regular">
				Frais du contrat en unité de compte : <b>{fees.ramifyGestion + fees.envelope} % (à vie)</b>
			</Text>
			<Wrap w="100%" spacing="16px" align="center">
				<WrapItem minWidth="280px" flex="1">
					<FeeCard
						title="Frais d'enveloppe"
						txtTooltip="Ce sont les frais prélevés par notre partenaire assureur pour la sécurisation de vos fonds"
						fee={`${fees.envelope} %`}
					/>
				</WrapItem>
				<WrapItem minWidth="280px" flex="1">
					<FeeCard
						title="Frais de gestion Ramify"
						txtTooltip="C’est la rémunération de Ramify, en tant que conseiller de vos investissements financiers"
						fee={`${fees.ramifyGestion} %`}
					/>
				</WrapItem>
				<WrapItem minWidth="280px" flex="1">
					<FeeEnterExit enterFee={fees.entryExit} exitFee={fees.entryExit} />
				</WrapItem>
			</Wrap>
		</VStack>
		<VStack w="100%" align="start" flexDirection="column">
			<HStack>
				<Text variant="Text-M-Regular">
					Frais du contrat en fonds euros : <b>{fees.fondEuro} % (à vie)</b>
				</Text>
			</HStack>
			<Wrap w="100%" spacing="24px" align="center">
				<WrapItem minWidth="280px" flex="1">
					<FeeCard title="Frais tout inclus" fee={`${fees.fondEuro} %`} />
				</WrapItem>
				<WrapItem minWidth="280px" flex="1">
					<FeeEnterExit enterFee={fees.fondEuroEntryExit} exitFee={fees.fondEuroEntryExit} />
				</WrapItem>
			</Wrap>
		</VStack>
	</VStack>
);
