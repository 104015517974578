import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from '@carbon/icons-react';
import { Box, HStack, Text } from '@chakra-ui/react';

import { Notification } from 'store/types/notification.type';

export type NotificationProps = {
	notification: Notification;
	markAsRead: (notification: Notification) => Promise<void>;
};

const NotificationItem = ({ notification, markAsRead }: NotificationProps) => {
	const navigate = useNavigate();

	return (
		<Box
			padding="sm"
			rounded="xl"
			_hover={{
				bg: '#FFF3DE',
				transitionDuration: '0.2s',
				transitionTimingFunction: 'ease-in-out',
				cursor: 'pointer',
			}}
			onClick={async () => {
				await markAsRead(notification);

				if (notification.redirect) {
					if (notification.redirect.startsWith('http') || notification.redirect.startsWith('https'))
						window.open(notification.redirect, '_blank');
					else navigate(notification.redirect);
				}
			}}
		>
			<HStack justify="space-between">
				<Box>
					<Text variant="Text-M-Bold">{notification.title}</Text>
					<Text variant="Text-S-Regular">{notification.description}</Text>
				</Box>
				<ArrowRight size="20" />
			</HStack>
		</Box>
	);
};

export default NotificationItem;
