import { SubscriptionStep } from 'components/products/SCPI-PE/subscriptionSteps';
import { FundSubscriptionStatus } from 'store/types/airtable.type';
import eurazeoFundsSteps from 'utils/automatedFunds/steps/eurazeo';
import { listPE } from 'utils/automatedFundTypes';

export interface SubscriptionDetails {
	label: string;
	description: string;
	tooltip?: React.ReactNode;
}

const automatedFundsSteps: Record<string, SubscriptionStep<FundSubscriptionStatus>[]> = {
	[listPE.EUROPEAN_REAL_ESTATE_II]: eurazeoFundsSteps,
	[listPE.PRINCIPAL_INVESTMENTS]: eurazeoFundsSteps,
	[listPE.PRIVATE_VALUE_3]: eurazeoFundsSteps,
	[listPE.STRATEGIC_OPPORTUNITIES_3]: eurazeoFundsSteps,
};

export default automatedFundsSteps;
