import { Route, Routes } from 'react-router-dom';

import ContractList from './ContractsSections';

const ContractRoutes = (): JSX.Element => (
	<Routes>
		<Route path=":id?" element={<ContractList />} />
	</Routes>
);

export default ContractRoutes;
