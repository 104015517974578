export const riskMin = 1;
export const riskMax = 10;

const rgbMin = { r: 253, g: 235, b: 160 };

const rgbMax = { r: 235, g: 87, b: 87 };

const rgbDiff = {
	r: Math.abs(rgbMax.r - rgbMin.r),
	g: Math.abs(rgbMax.g - rgbMin.g),
	b: Math.abs(rgbMax.b - rgbMin.b),
};

export type RiskDisplay = { name: string; description: string; value: number; color: string; min: number; max: number };

const riskColor = (risk: number): string => {
	const rgb = {
		r: rgbMin.r - (rgbDiff.r * (risk - riskMin)) / (riskMax - riskMin),
		g: rgbMin.g - (rgbDiff.g * (risk - riskMin)) / (riskMax - riskMin),
		b: rgbMin.b - (rgbDiff.b * (risk - riskMin)) / (riskMax - riskMin),
	};
	return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
};

export const riskDisplay = (risk: number): RiskDisplay => {
	if (risk <= 4)
		return {
			name: 'Prudent',
			description: 'Je suis prêt(e) à accepter des rendements plus faibles pour plus de stabilité.',
			value: risk,
			min: riskMin,
			max: 4,
			color: riskColor(risk),
		};
	if (risk <= 7)
		return {
			name: 'Modéré',
			description: 'Je recherche un portefeuille avec des gains ou pertes modérés sur le long terme.',
			value: risk,
			min: 4,
			max: 7,
			color: riskColor(risk),
		};
	return {
		name: 'Agressif',
		description: 'Je suis prêt(e) à accepter un risque plus élevé pour un rendement plus élevé sur le long terme.',
		value: risk,
		min: 7,
		max: riskMax,
		color: riskColor(risk),
	};
};
