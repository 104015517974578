import { FC, useEffect } from 'react';
import { Link as ReachLink } from 'react-router-dom';
import {
	Button,
	Heading,
	HStack,
	Link,
	ListItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	UnorderedList,
	VStack,
} from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import { AccountUsage } from 'pages/Account/BankInformations';
import { isFetchBaseQueryErrorType } from 'services/apiService';
import { BankInformation, useDeleteBankAccountMutation } from 'services/requests/bank';

import { BankAccountDetailsCard } from './BankAccountDetailsCard';

interface DeleteBankAccountModalProps {
	isOpen: boolean;
	onClose: () => void;
	accountDetails: { bankAccount: BankInformation; accountUsage: AccountUsage[] | null };
}

export const DeleteBankAccountModal: FC<DeleteBankAccountModalProps> = ({ accountDetails, isOpen, onClose }) => {
	const toast = useThemedToast();
	const [deleteAccount, { data, isLoading, isSuccess, isError, error }] = useDeleteBankAccountMutation();

	const operations = accountDetails.accountUsage?.filter((usage) => !!usage.programmedVersement);
	const contracts = accountDetails.accountUsage?.filter((usage) => !usage.programmedVersement);

	useEffect(() => {
		if (isError && isFetchBaseQueryErrorType(error!)) {
			toast({
				title: 'Une erreur est survenue',
				description:
					(error.data as { message: string })?.message || "Nous n'avons pas pu supprimer ce compte bancaire.",
				status: 'error',
				duration: 4500,
				isClosable: true,
			});
		}
	}, [isError, toast, error]);

	useEffect(() => {
		if (isSuccess && data && data === true) onClose();
	}, [isSuccess, data, onClose]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="lg">
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Heading variant="Title-M-SemiBold">Supprimer le compte bancaire</Heading>
				</ModalHeader>
				<ModalCloseButton />

				<ModalBody padding="24px">
					<VStack w="100%" spacing="32px" align="start">
						<Text variant="Text-M-Regular">
							Vous êtes sur le point de dissocier votre compte Ramify du compte bancaire suivant :
						</Text>

						<BankAccountDetailsCard accountDetails={accountDetails.bankAccount} accountUsage={null} />

						{accountDetails.accountUsage && operations && contracts && (
							<VStack align="start" spacing="16px">
								<Text color="red.500" variant="body">
									L’opération n’est pas possible pour l’instant.
								</Text>

								{operations.length > 0 && (
									<VStack align="start">
										<Text fontWeight="500" color="red.500" variant="body">
											Ce compte est utilisé dans {operations.length} opération
											{operations.length > 1 && 's'} :
										</Text>
										<UnorderedList>
											{operations.map((operation) => (
												<ListItem
													ml="30px"
													color="red.500"
													fontSize="14px"
													key={`${operation.type}-${operation.usage}`}
												>
													Versements automatique - {operation.type === 'PER' ? 'PER individuel' : 'Assurance Vie'}
													&nbsp;
													<Link as={ReachLink} to="/invest/versements" color="red.500" textDecoration="underline">
														(Voir)
													</Link>
												</ListItem>
											))}
										</UnorderedList>
									</VStack>
								)}

								{contracts.length > 0 && (
									<VStack align="start">
										<Text fontWeight="500" color="red.500" variant="body">
											Ce compte est associé à {contracts.length} contrat{contracts.length > 1 && 's'} :
										</Text>
										<UnorderedList color="red.500" fontSize="14px">
											{contracts.map((contract) => (
												<ListItem ml="30px" key={`${contract.type}-${contract.usage}`}>
													{contract.type === 'PER' ? 'PER individuel' : 'Assurance Vie'}
												</ListItem>
											))}
										</UnorderedList>
									</VStack>
								)}
							</VStack>
						)}
					</VStack>
				</ModalBody>

				<ModalFooter>
					<HStack w="100%" justify="space-between">
						<Button variant="secondary" onClick={onClose}>
							Annuler
						</Button>
						<Button
							variant="destroy"
							type="submit"
							isDisabled={!!accountDetails.accountUsage}
							isLoading={isLoading}
							onClick={() => deleteAccount(accountDetails.bankAccount.id)}
						>
							Supprimer le compte
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
