import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import {
	Analytics,
	ArrowLeft,
	ChartLineData,
	ChartSunburst,
	DocumentDownload,
	Money,
	Ordinal,
	Result,
} from '@carbon/icons-react';
import {
	Badge,
	Box,
	Button,
	Card,
	Circle,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	Hide,
	HStack,
	IconButton,
	Image,
	Show,
	SimpleGrid,
	Skeleton,
	Spinner,
	Stack,
	StackDivider,
	StackProps,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useBoolean,
	useDisclosure,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import MoralPersonBlockerCard from 'features/MoralPersonBlockerCard';
import RealEstateIntentionForm from 'formContexts/RealEstateIntention';

import Banner from 'components/Banners/Banner';
import { GraphiqueLine } from 'components/charts/GraphiqueLine';
import { HistoryChart } from 'components/Graphs/visx/HistoryChart';
import InvestmentMetricCard from 'components/Metrics/InvestmentMetrics/Card';
import CustomModal from 'components/Modal';
import TypeformContainer from 'components/typeformContainer';
import { useDrawer } from 'hooks/useDrawer';
import useThemedToast from 'hooks/useThemedToast';
import { scpiTagsDisplay } from 'pages/RealEstate/Discover/options.constants';
import eventTracker from 'services/events/eventTracker';
import {
	useGetSCPIFundByIdQuery,
	useGetSCPIFundDemembrementQuery,
	useGetSCPIFundHistoryQuery,
	useGetSCPIFundPatrimoineQuery,
} from 'services/requests/realEstate/funds';
import { useCreateSCPIIntentionMutation } from 'services/requests/realEstate/subscriptions';
import { SCPIFund, SCPIFundDemembrement } from 'store/types/airtable.type';
import { ScpiIntention } from 'store/types/scpiKyc.type';
import { thinScrollbar } from 'theme/scrollbar';
import { isNone, isNotNone } from 'utils/functions';
import { displayMoney, displayMoneyNoDigits, displayPercentage, formatNumber } from 'utils/rendering';

export type IntentionModalProps = {
	isOpen: boolean;
	onClose: () => void;
	fundDetails: SCPIFund;
	fundDemembrement?: SCPIFundDemembrement;
};

type FormData = Omit<ScpiIntention, 'fundId'>;

const createHorizon = (duree: number | undefined): Date | undefined => {
	if (!duree) return undefined;
	const date = new Date();
	date.setFullYear(date.getFullYear() + duree);
	return date;
};

export const IntentionModal = ({ isOpen, onClose, fundDemembrement, fundDetails }: IntentionModalProps) => {
	const [step, setStep] = useState<'form' | 'done'>('form');
	const toast = useThemedToast();
	const [completedData, setCompletedData] = useState<FormData>();
	const [createScpiIntention, { isLoading: isCreatingSub }] = useCreateSCPIIntentionMutation();
	const [subId, setSubId] = useState<string>();

	const minimumShares = fundDetails.minPPShares;
	const ticket = minimumShares * fundDetails.lastShareValuation;

	const methods = useForm<FormData>({
		shouldUnregister: true,
		defaultValues: {
			nbShare: minimumShares,
			amountVersement: ticket,
		},
	});
	const { handleSubmit } = methods;

	const onSubmit = handleSubmit((data) => {
		createScpiIntention({
			fundId: fundDetails.id,
			...data,
			demembrementDuration: data.demembrementDuration,
			amountVersement: +data.amountVersement,
		})
			.unwrap()
			.then((newSub) => {
				eventTracker.pipedream.scpiIntention(
					data.projectType,
					+data.amountVersement,
					fundDetails.name,
					createHorizon(data.demembrementDuration),
				);
				setSubId(newSub.id);
				setStep('done');
				setCompletedData(data);
			})
			.catch((error) => {
				toast({
					title: 'Une erreur est survenue',
					description: error.data.message,
					status: 'error',
					duration: 4500,
					isClosable: true,
				});
			});
	});

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			headersProps={{ children: `Investir dans ${fundDetails.name}` }}
			contentProps={{ py: '16px' }}
		>
			{step === 'form' ? (
				isCreatingSub ? (
					<VStack align="center">
						<Spinner />
					</VStack>
				) : (
					<FormProvider {...methods}>
						<form onSubmit={onSubmit}>
							<VStack spacing="24px" align="start">
								<RealEstateIntentionForm fundDemembrement={fundDemembrement} fundDetails={fundDetails} />
								<Button type="submit">Confirmer</Button>
							</VStack>
						</form>
					</FormProvider>
				)
			) : (
				<VStack spacing="24px">
					{completedData?.legalEntity === 'PHYSIQUE' ? (
						<Navigate to={`/immobilier/onboarding/${subId}`} />
					) : (
						<MoralPersonBlockerCard />
					)}
				</VStack>
			)}
		</CustomModal>
	);
};

interface ScpiFeesProps {
	value: string;
	label: string;
}

const ScpiFees: FC<ScpiFeesProps> = ({ value, label }) => (
	<HStack spacing="xs" align="center">
		<Heading variant="Title-M-SemiBold">{value}</Heading>
		<Text variant="Text-M-Regular" color="grey.900">
			{label}
		</Text>
	</HStack>
);

interface ScpiMetricsProps extends StackProps {
	value: string;
	label: string;
}

const ScpiMetrics: FC<ScpiMetricsProps> = ({ label, value, ...props }) => (
	<VStack align="center" {...props}>
		<Heading variant="Title-XL-Bold" textAlign="center">
			{value}
		</Heading>
		<Text variant="Text-M-Medium" textAlign="center" color="grey.900">
			{label}
		</Text>
	</VStack>
);

const RealEstateDetails: FC<{
	hideAdvisor?: boolean;
	hideNPDetails?: boolean;
	hideInvestButton?: boolean;
}> = ({ hideAdvisor, hideNPDetails, hideInvestButton }) => {
	const { id } = useParams();

	// Requests
	const { data: fundDetails, isLoading } = useGetSCPIFundByIdQuery(id!, { skip: !id });
	const {
		data: fundHistory,
		isLoading: isHistoryLoading,
		isError: isHistoryError,
	} = useGetSCPIFundHistoryQuery(id!, { skip: !id });
	const {
		data: fundPatrimoine,
		isLoading: isPatrimoineLoading,
		isError: isPatrimoineError,
	} = useGetSCPIFundPatrimoineQuery(id!, { skip: !id });
	const { data: fundDemembrement, isLoading: IsDemembrementLoading } = useGetSCPIFundDemembrementQuery(id!, {
		skip: !id,
	});

	const { isOpen: isOpenDetailsDrawer, onClose: onCloseDetailsDrawer } = useDrawer();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [showCalendlyTypeform, setShowCalendlyTypeform] = useBoolean();

	const minimumShares = fundDetails?.minPPShares ?? 0;
	const ticket = (fundDetails?.lastShareValuation ?? 0) * minimumShares;

	const colorSectors = ['grey.900', 'grey.700', 'grey.500', 'grey.300', 'grey.100'];
	const colorGeo = ['yellow.600', 'yellow.500', 'yellow.400'];

	const sectorsRepartition =
		fundPatrimoine &&
		Object.entries(fundPatrimoine[0])
			.filter(([key, value]) => key.startsWith('secteur') && value > 0)
			.map(([key, value]) => ({
				name: key.replace('secteur', ''),
				value,
			}))
			.sort(({ value: value1 }, { value: value2 }) => value2 - value1)
			.map(({ name, value }, idx) => ({
				name,
				color: colorSectors[idx],
				value,
			}));

	const geographieRepartition =
		fundPatrimoine &&
		Object.entries(fundPatrimoine[0])
			.filter(([key, value]) => key.startsWith('geo') && value > 0)
			.map(([key, value]) => ({
				name: key.replace('geo', ''),
				value,
			}))
			.sort(({ value: value1 }, { value: value2 }) => value2 - value1)
			.map(({ name, value }, idx) => ({
				name,
				color: colorGeo[idx],
				value,
			}));

	const displayDemembrement = fundDemembrement && Object.entries(fundDemembrement);

	if (isNone(fundDetails) || !id) return null;

	return (
		<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isOpenDetailsDrawer} onClose={onCloseDetailsDrawer}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<HStack w="100%" justify="space-between">
						<IconButton
							onClick={onCloseDetailsDrawer}
							variant="icon"
							aria-label="back"
							icon={<ArrowLeft size="32" />}
						/>
						{!hideInvestButton && <Button onClick={onOpen}>Investir</Button>}
					</HStack>
				</DrawerHeader>
				<DrawerBody>
					<VStack w="100%" spacing="32px" py="6" align="start">
						<TypeformContainer
							url="https://j3ksz7q85rt.typeform.com/to/YInLTumy?typeform-source=app.ramify.fr"
							typeformTitle="Prendre rendez-vous"
							show={showCalendlyTypeform}
							onClose={setShowCalendlyTypeform.off}
						/>

						<Skeleton isLoaded={!isLoading} w="100%" h={isLoading ? '300px' : 'auto'}>
							<VStack align="start" spacing="24px" w="100%">
								<Stack direction={{ base: 'column', lg: 'row' }} spacing="lg">
									<Box w="300px">
										<Image
											objectFit="cover"
											w="100%"
											h="100%"
											borderRadius="base"
											src={fundDetails?.fundCover?.[0]?.url}
										/>
									</Box>

									<VStack p="16px" h="100%" align="start" justify="space-between" flex="1">
										<VStack align="start">
											<Text variant="Caption">{fundDetails?.partner}</Text>
											<Heading variant="Text-XL-Bold">{fundDetails?.name}</Heading>
											<Text variant="Text-M-Regular" color="grey.900">
												{fundDetails?.description}
											</Text>
										</VStack>
										{fundDetails.tags && (
											<Wrap w="100%" align="start" opacity={0.6}>
												{fundDetails.tags.map((tag) => (
													<WrapItem key={tag}>
														<Badge variant="outlineYellow">{scpiTagsDisplay[tag].display}</Badge>
													</WrapItem>
												))}
											</Wrap>
										)}
									</VStack>
								</Stack>

								<Stack direction={{ base: 'column', md: 'row' }} w="100%" spacing="24px">
									{isNotNone(fundDetails.meanRevalorisation) && (
										<InvestmentMetricCard title="Revalorisation moyenne des parts" icon={<Analytics size="32" />}>
											<Heading variant="Title-L-Bold">{displayPercentage(fundDetails.meanRevalorisation)}</Heading>
										</InvestmentMetricCard>
									)}
									{(isNotNone(fundDetails.expectedDistributionRate) || isNotNone(fundDetails.distributionRate)) && (
										<InvestmentMetricCard
											title={`Taux de distribution ${
												fundDetails.expectedDistributionRate ? 'espéré' : `brut ${fundDetails.distributionRateYear}`
											}`}
											icon={<ChartSunburst size="32" />}
										>
											<Heading variant="Title-L-Bold">
												{displayPercentage(fundDetails.expectedDistributionRate ?? fundDetails.distributionRate)}
											</Heading>
										</InvestmentMetricCard>
									)}
								</Stack>

								{fundDetails.ramifyOpinion && !hideAdvisor && (
									<Banner
										title={`Point de vue Ramify sur ${fundDetails.name}`}
										variant="information"
										avatar="olivier"
										cta="Prendre rendez-vous"
										description={fundDetails.ramifyOpinion}
										onClick={setShowCalendlyTypeform.on}
									/>
								)}

								<Stack
									direction={{ base: 'column', md: 'row' }}
									justify="space-evenly"
									w="100%"
									align={{ base: 'center', md: 'start' }}
									spacing="md"
								>
									<ScpiMetrics
										w="175px"
										label="Prix de part actuel"
										value={displayMoneyNoDigits(fundDetails.lastShareValuation)}
									/>
									<ScpiMetrics w="175px" label="Souscription minimum" value={displayMoneyNoDigits(ticket)} />
									<ScpiMetrics w="175px" label="Durée de placement recommandée" value="10 ans" />
									<ScpiMetrics w="175px" label="Délai de jouissance" value={fundDetails.tenurePeriod} />
								</Stack>

								{!isHistoryError && (
									<Skeleton w="100%" isLoaded={!isHistoryLoading && !isLoading}>
										<Card w="100%">
											<VStack spacing="16px" w="100%" align="start">
												<Heading variant="Title-M-SemiBold">Historique</Heading>
												{fundHistory && <HistoryChart fundHistory={fundHistory} />}
											</VStack>
										</Card>
									</Skeleton>
								)}
								<Stack w="100%" spacing="24px" direction={{ base: 'column', md: 'row' }}>
									{!isPatrimoineError && (
										<Skeleton w={{ base: '100%', md: '50%' }} isLoaded={!isPatrimoineLoading && !isLoading}>
											<Card w="100%" h="100%">
												<VStack align="start" spacing="24px" w="100%">
													<Heading variant="Title-M-SemiBold">Répartition sectorielle</Heading>

													{sectorsRepartition && (
														<VStack align="start" spacing="24px" w="100%">
															<GraphiqueLine data={sectorsRepartition} />
															<Table>
																<Thead>
																	<Tr>
																		<Th>
																			<Text variant="Caption" color="grey.900">
																				Répartition
																			</Text>
																		</Th>
																		<Th>
																			<Text variant="Caption" color="grey.900">
																				Secteur
																			</Text>
																		</Th>
																	</Tr>
																</Thead>
																<Tbody>
																	{sectorsRepartition.map((asset) => (
																		<Tr key={asset.name}>
																			<Td>
																				<HStack>
																					<Circle size="12px" bg={asset.color} />
																					<Text variant="Text-M-Medium">{displayPercentage(asset.value)}</Text>
																				</HStack>
																			</Td>
																			<Td>
																				<Text variant="Text-M-Regular">{asset.name}</Text>
																			</Td>
																		</Tr>
																	))}
																</Tbody>
															</Table>
														</VStack>
													)}
												</VStack>
											</Card>
										</Skeleton>
									)}

									{!isPatrimoineError && (
										<Skeleton w={{ base: '100%', md: '50%' }} isLoaded={!isPatrimoineLoading && !isLoading}>
											<Card w="100%" h="100%">
												<VStack align="start" spacing="24px" w="100%">
													<Heading variant="Title-M-SemiBold">Répartition géographique</Heading>
													{geographieRepartition && (
														<VStack align="start" spacing="24px" w="100%">
															<GraphiqueLine data={geographieRepartition} />

															<Table>
																<Thead>
																	<Tr>
																		<Th>
																			<Text variant="Caption" color="grey.900">
																				Répartition
																			</Text>
																		</Th>
																		<Th>
																			<Text variant="Caption" color="grey.900">
																				Secteur
																			</Text>
																		</Th>
																	</Tr>
																</Thead>
																<Tbody>
																	{geographieRepartition.map((asset) => (
																		<Tr key={asset.name}>
																			<Td>
																				<HStack>
																					<Circle size="12px" bg={asset.color} />
																					<Text variant="Text-M-Medium">{displayPercentage(asset.value)}</Text>
																				</HStack>
																			</Td>
																			<Td>
																				<HStack>
																					<Text variant="Text-M-Regular">{asset.name}</Text>
																				</HStack>
																			</Td>
																		</Tr>
																	))}
																</Tbody>
															</Table>
														</VStack>
													)}
												</VStack>
											</Card>
										</Skeleton>
									)}
								</Stack>

								<Stack w="100%" direction={{ base: 'column', md: 'row' }} spacing="24px">
									{(fundDetails.aum ||
										fundDetails.numberOfAssets ||
										fundDetails.financialOccupancyRate ||
										fundDetails.reconstitutionPricePerShare) && (
										<VStack w={{ base: '100%', md: '65%' }} align="start" spacing="md">
											<Heading variant="Title-M-SemiBold">Chiffres clés</Heading>
											<SimpleGrid columns={2} spacing="lg" w="100%">
												{fundDetails.aum && (
													<InvestmentMetricCard
														title="Capitalisation totale"
														icon={<Money size="32" />}
														border="none"
														spacing="xs"
														size="none"
													>
														<Heading variant="Title-L-SemiBold">
															{fundDetails.aum.toLocaleString('fr-FR', {
																minimumFractionDigits: 1,
																maximumFractionDigits: 1,
															})}{' '}
															M€
														</Heading>
													</InvestmentMetricCard>
												)}
												{fundDetails.numberOfAssets && (
													<InvestmentMetricCard
														title="Nombre d'actifs"
														icon={<Result size="32" />}
														spacing="xs"
														border="none"
														size="none"
													>
														<Heading variant="Title-L-SemiBold">{fundDetails.numberOfAssets}</Heading>
													</InvestmentMetricCard>
												)}
												{fundDetails.financialOccupancyRate && (
													<InvestmentMetricCard
														title="Taux d'Occupation Financier"
														icon={<ChartLineData size="32" />}
														border="none"
														spacing="xs"
														size="none"
													>
														<Heading variant="Title-L-SemiBold">
															{displayPercentage(fundDetails.financialOccupancyRate)}
														</Heading>
													</InvestmentMetricCard>
												)}
												{fundDetails.reconstitutionPricePerShare && (
													<InvestmentMetricCard
														title="Valeur de reconsitution"
														icon={<Ordinal size="32" />}
														border="none"
														spacing="xs"
														size="none"
													>
														<Heading variant="Title-L-SemiBold">
															{displayMoney(fundDetails.reconstitutionPricePerShare)}
														</Heading>
													</InvestmentMetricCard>
												)}
											</SimpleGrid>
										</VStack>
									)}
									<VStack flex="1" align="start" spacing="md">
										<Heading variant="Title-M-SemiBold">Frais</Heading>
										<VStack align="start" spacing="md" w="100%" divider={<StackDivider borderColor="grey.700" />}>
											<ScpiFees label="Frais de souscription" value={displayPercentage(fundDetails.entranceFees)} />
											<ScpiFees label="Prix d'achat" value={displayMoney(fundDetails.lastShareValuation)} />
											{fundDetails.exitPrice && (
												<ScpiFees label="Prix de cession" value={displayMoney(fundDetails.exitPrice)} />
											)}
										</VStack>
									</VStack>
								</Stack>

								{!hideNPDetails && (IsDemembrementLoading || displayDemembrement?.length) && (
									<VStack w="100%" align="start" spacing="md">
										<Heading variant="Title-M-SemiBold">Tableau de démembrement et décote en nue-proprieté</Heading>
										<Skeleton w="100%" h={IsDemembrementLoading ? '200px' : 'auto'} isLoaded={!IsDemembrementLoading}>
											<>
												<Show above="md">
													<TableContainer border="base" borderRadius="sm" w="100%" css={thinScrollbar}>
														<Table bg="white">
															<Thead style={{ fontVariantNumeric: 'normal' }}>
																<Tr>
																	<Th textAlign="center">
																		<Text variant="Text-S-Medium" color="grey.900" textTransform="none">
																			Durée (années)
																		</Text>
																	</Th>
																	{displayDemembrement?.map(([key]) => (
																		<Td textAlign="center" key={key}>
																			<Text variant="Text-S-Medium">{key}</Text>
																		</Td>
																	))}
																</Tr>
															</Thead>
															<Tbody style={{ fontVariantNumeric: 'normal' }}>
																<Tr>
																	<Th textAlign="center">
																		<Text variant="Text-S-Medium" color="grey.900" textTransform="none">
																			Décote (%)
																		</Text>
																	</Th>
																	{displayDemembrement?.map(([key, value]) => (
																		<Td textAlign="center" key={key}>
																			<Text variant="Text-S-Medium">{formatNumber(+value * 100, 1)}</Text>
																		</Td>
																	))}
																</Tr>
															</Tbody>
														</Table>
													</TableContainer>
												</Show>
												<Hide above="md">
													<TableContainer border="base" borderRadius="sm" w="100%">
														<Table variant="striped" colorScheme="grey">
															<Thead>
																<Tr>
																	<Th textAlign="center">
																		<Text variant="label" color="grey.500" textTransform="none">
																			Durée (années)
																		</Text>
																	</Th>
																	<Th textAlign="center">
																		<Text variant="label" color="grey.500" textTransform="none">
																			Décote (%)
																		</Text>
																	</Th>
																</Tr>
															</Thead>
															<Tbody>
																{displayDemembrement?.map(([key, value]) => (
																	<Tr key={key}>
																		<Td textAlign="center">
																			<Text variant="label">{key}</Text>
																		</Td>
																		<Td textAlign="center">
																			<Text variant="label">{formatNumber(+value * 100, 1)}</Text>
																		</Td>
																	</Tr>
																))}
															</Tbody>
														</Table>
													</TableContainer>
												</Hide>
											</>
										</Skeleton>
									</VStack>
								)}
								<VStack w="100%" align="start">
									<Heading variant="Title-M-SemiBold">Documents et ressources</Heading>

									<HStack spacing="16px">
										<Button
											variant="secondary"
											rightIcon={<DocumentDownload size="16" />}
											onClick={() => {
												if (fundDetails.fundDICI?.[0]?.url) window.open(fundDetails.fundDICI?.[0]?.url);
											}}
											isDisabled={!fundDetails.fundDICI?.[0]?.url}
										>
											DIC
										</Button>
										<Button
											variant="secondary"
											rightIcon={<DocumentDownload size="16" />}
											onClick={() => {
												if (fundDetails.fundFlyer?.[0]?.url) window.open(fundDetails.fundFlyer?.[0]?.url);
											}}
											isDisabled={!fundDetails.fundFlyer?.[0]?.url}
										>
											Plaquette
										</Button>
									</HStack>
								</VStack>
								{!hideInvestButton && (
									<HStack spacing="24px">
										<Button onClick={onOpen}>Investir dans cette SCPI</Button>
										{/* inactif pour l'instant d'après Dario. à cacher */}
										{/* <Button variant="tertiary">Faire une simulation avec cette SCPI</Button> */}
									</HStack>
								)}
							</VStack>
						</Skeleton>
						{fundDetails && (
							<IntentionModal
								isOpen={isOpen}
								onClose={onClose}
								fundDemembrement={fundDemembrement}
								fundDetails={fundDetails}
							/>
						)}
					</VStack>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default RealEstateDetails;
