import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Heading, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';

import Article from 'components/Articles/Article';
import AirtableBanner from 'components/Banners/AirtableBanner';
import { ArrowButton } from 'components/Button/Button';
import { HelperAdvisor } from 'components/cards/HelperAdvisor';
import { SponsorshipInviteCard } from 'components/cards/SponsorshipInviteCard';
import { useProducts } from 'hooks/useProducts';
import useSubscriptionList from 'hooks/useSubscriptionList';
import { useGetKYCQuery } from 'services/requests/kyc';

import HomeDrawer from './features/HomeDrawer';
import { ExploreProducts } from './ExploreProducts';
import { OpenedProducts } from './OpenedProducts';

// function that checks local time and returns a greeting accordingly
const getGreetings = (name: string | undefined) => {
	if (!name) return 'Bienvenue';

	const time = new Date().getHours();
	if (time < 19) return `Bonjour ${name},`;
	return `Bonsoir ${name},`;
};

export const Home: FC = () => {
	const { data: kyc } = useGetKYCQuery();
	const { subscriptionList, isLoading: areSubscriptionsLoading } = useSubscriptionList();
	const { anyProduct } = useProducts();

	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const greetings = useMemo(() => getGreetings(kyc?.firstName), [kyc?.firstName]);

	return (
		<VStack spacing="56px" mt={{ base: '24px', md: '40px', lg: '56px' }} mb="80px" align="start">
			<VStack spacing="32px" align="start" w="100%">
				<HStack justify="space-between" w="100%">
					<Heading variant="Title-XL-SemiBold">{greetings}</Heading>

					<Button size="lg" onClick={onOpen} variant="super">
						Investir
					</Button>
					{/* <MenuButton as={Button} variant="super" size="lg">
						Investir
					</MenuButton> */}
				</HStack>

				<AirtableBanner />
			</VStack>

			<OpenedProducts />

			{!areSubscriptionsLoading && subscriptionList.length > 0 && (
				<Card
					w="100%"
					bg="yellow.50"
					borderColor="transparent"
					_hover={{ borderColor: 'primary.yellow' }}
					onClick={() => navigate('/souscriptions')}
					cursor="pointer"
				>
					<HStack justify="space-between">
						<HStack spacing="16px">
							<Card borderRadius="4px" padding="2px 6px" bg="primary.yellow" border="none" align="start">
								<Text variant="Text-S-Bold">{subscriptionList.length}</Text>
							</Card>
							<Text variant="Text-M-Medium">Souscription{subscriptionList.length > 1 && 's'} en attente</Text>
						</HStack>
						<ArrowButton>Finaliser</ArrowButton>
					</HStack>
				</Card>
			)}

			<ExploreProducts />

			{!anyProduct.isOpen && (
				<Article
					imageURL="https://assets.website-files.com/63c1a162348341b917d77638/64abe1aac73c27689c8a2394_towfiqu-barbhuiya-jpqyfK7GB4w-unsplash-p-500.jpg"
					title="PER – L’enveloppe fiscale dont vous n’entendez pas (assez) parler"
					description="Le PER c’est une enveloppe fiscale géniale, un peu contraignante et surtout méconnue. Ce dispositif vous permet d’investir et de profiter d’avantages fiscaux complètement différents des enveloppes les plus connues."
					tags={[{ label: 'Guide', variant: 'coloredBgYellow' }]}
					btnCallback={() => {
						window.open('https://www.ramify.fr/per/per-defiscaliser-retraite');
					}}
				/>
			)}

			{anyProduct.isOpen && <SponsorshipInviteCard />}

			<HelperAdvisor />

			<HomeDrawer isOpen={isOpen} onClose={onClose} />
		</VStack>
	);
};
