import { Badge, Card, CardProps, Heading, Image, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import Elite from 'assets/images/PortofolioTypes/Elite.png';
import Essential from 'assets/images/PortofolioTypes/Essential.png';
import Flagship from 'assets/images/PortofolioTypes/Flagship.png';
import { ArrowButton } from 'components/Button/Button';
import { InvestFundType } from 'store/types/investment.type';
import { PortfolioType, portfolioTypeLabels } from 'store/types/profilage.type';

interface PortfolioTypeCardProps extends CardProps {
	type: Exclude<PortfolioType, 'CUSTOM'>;
	description: string;
	title?: string;
	buttonText?: string;
	onClick: () => void;
}

const PortfolioTypeCard = ({
	type,
	description,
	title = 'Portefeuille',
	buttonText = 'Détails du portefeuille',
	onClick,
	...rest
}: PortfolioTypeCardProps) => {
	const portfolioIcons: Record<Exclude<PortfolioType, 'CUSTOM'>, string> = {
		FLAGSHIP: Flagship,
		ESSENTIAL: Essential,
		ELITE: Elite,
	};

	const portfolioTags: Record<Exclude<PortfolioType, 'CUSTOM'>, InvestFundType[]> = {
		ESSENTIAL: ['Actions', 'Obligations', 'Fonds Euros'],
		FLAGSHIP: ['Actions', 'Obligations', 'Fonds Euros', 'SCPI'],
		ELITE: ['Actions', 'Obligations', 'Fonds Euros', 'SCPI', 'Private Equity'],
	};

	return (
		<Card w="100%" position="relative" variant="portfolio" type={type} {...rest}>
			<Image w="100px" position="absolute" top="10px" right="0" src={portfolioIcons[type]} />
			<VStack flex={1} w="100%" spacing="24px" pt="24px">
				<VStack flex={1} w="100%" spacing="24px" align="left">
					<VStack flex={1} align="left" h="100%" w="100%" justify="space-between">
						<Text variant="Caption" color="yellow.700">
							{title}
						</Text>
						<Heading variant="Title-L-SemiBold">{portfolioTypeLabels[type]}</Heading>
						<Text variant="Text-M-Regular" color="grey.900" minH="80px">
							{description}
						</Text>
					</VStack>
					<Wrap minH="60px">
						{portfolioTags[type].map((tag) => (
							<WrapItem key={tag}>
								<Badge variant="outlineYellow">{tag}</Badge>
							</WrapItem>
						))}
					</Wrap>
				</VStack>
				<ArrowButton variant="tertiary" onClick={onClick} alignSelf="end">
					{buttonText}
				</ArrowButton>
			</VStack>
		</Card>
	);
};

export default PortfolioTypeCard;
