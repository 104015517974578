import { useNavigate } from 'react-router-dom';
import { Card, HStack, Skeleton, Text, useDisclosure, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import CustomModal from 'components/Modal';
import { useGetOnboardingPortfolioQuery } from 'services/requests/invest/preferences';
import { StrategyRepartition } from 'services/requests/invest/simulation';
import { InvestSimulation, SimulationEnvelopeType } from 'store/types/investSimulation';
import { TaxEconomy } from 'store/types/portfolio.type';
import { displayMoney, displayPercentage } from 'utils/rendering';

import PerEconomyLastTwoYears from '../Shared/Fiscalite/Steps/TaxEconomy/Sections/PerEconomyLastTwoYears';

const InfosCards = ({
	taxEconomy,
	simulation,
	strategy,
}: {
	taxEconomy: TaxEconomy;
	simulation: InvestSimulation;
	strategy: StrategyRepartition;
}) => {
	const navigate = useNavigate();
	const { isOpen: isTaxEconomyOpen, onOpen: onTaxEconomyOpen, onClose: onTaxEconomyClose } = useDisclosure();

	// this will be replaced by tri
	const { data: portfolioData, isLoading: isPortfolioLoading } = useGetOnboardingPortfolioQuery({
		id: simulation.id,
		entityName: 'investSimulation',
	});

	if (isPortfolioLoading || !portfolioData)
		return (
			<HStack>
				<Skeleton h="80px" flex={1} />
				<Skeleton h="80px" flex={1} />
				<Skeleton h="80px" flex={1} />
			</HStack>
		);

	return (
		<>
			<Wrap w="100%" spacing="24px">
				<WrapItem flex={1}>
					<Card minWidth="250px" w="100%" h="100%">
						<VStack w="100%" align="start" spacing="16px">
							<Text variant="Title-S-SemiBold">Enveloppe sélectionnée</Text>
							<HStack spacing="16px" w="100%" justify="space-between">
								<Text variant="Title-S-Bold" color="blue.900">
									{simulation.envelope === SimulationEnvelopeType.OPTIM
										? 'Optimisation Ramify'
										: simulation.envelope === SimulationEnvelopeType.AV
										? 'Assurance vie'
										: 'PER invididuel'}
								</Text>
								<Text
									as="a"
									onClick={() => {
										navigate('/onboarding/fiscalite/envelope');
									}}
									textDecoration="underline"
									cursor="pointer"
									variant="Text-S-Regular"
								>
									modifier
								</Text>
							</HStack>
						</VStack>
					</Card>
				</WrapItem>
				<WrapItem flex={1}>
					<Card minWidth="250px" w="100%" h="100%">
						<VStack w="100%" align="start" spacing="16px">
							<Text variant="Title-S-SemiBold">Economies d’impôt</Text>
							<HStack spacing="16px" w="100%" justify="space-between">
								<HStack spacing="8px">
									<Text variant="Title-S-Bold" color="blue.900">
										{displayMoney(taxEconomy.thisYear)}
									</Text>
									<Text variant="Text-S-Regular" color="grey.900">
										(Estimation)
									</Text>
								</HStack>
								{simulation.envelope !== SimulationEnvelopeType.AV && (
									<Text
										as="a"
										onClick={onTaxEconomyOpen}
										textDecoration="underline"
										cursor="pointer"
										variant="Text-S-Regular"
									>
										détails
									</Text>
								)}
							</HStack>
						</VStack>
					</Card>
				</WrapItem>
				<WrapItem flex={1}>
					<Card minWidth="250px" w="100%" h="100%">
						<VStack w="100%" align="start" spacing="16px">
							<Text variant="Title-S-SemiBold">Rendement du portefeuille</Text>
							<HStack spacing="16px" w="100%" justify="space-between">
								<HStack spacing="16px">
									<Text variant="Title-S-Bold" color="blue.900">
										{displayPercentage(portfolioData.performances.annualized_return)}
									</Text>
									<Text variant="Text-S-Regular" color="grey.900">
										(Par an, net de frais)
									</Text>
								</HStack>
								<Text
									as="a"
									onClick={() => {
										navigate('/onboarding/portefeuille/recapitulatif');
									}}
									textDecoration="underline"
									cursor="pointer"
									variant="Text-S-Regular"
								>
									modifier
								</Text>
							</HStack>
						</VStack>
					</Card>
				</WrapItem>
			</Wrap>
			<CustomModal
				headersProps={{ children: <Text variant="Title-M-SemiBold">Economies d’impôt</Text> }}
				isOpen={isTaxEconomyOpen}
				onClose={onTaxEconomyClose}
				size={{ base: 'md', md: '4xl' }}
			>
				<PerEconomyLastTwoYears
					introText="Les sommes versées sur votre PER Individuel sont déductibles de vos revenus imposables sur l’année en cours. Cette enveloppe fiscale permet donc d’effectuer des économies d’impôt."
					amountCurrentYear={taxEconomy.thisYear}
					amountNextYear={taxEconomy.nextYear}
					initialAumPer={strategy.initialAmountPER}
					reccuringAumPer={strategy.amountPER}
				/>
			</CustomModal>
		</>
	);
};

export default InfosCards;
