import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from '@carbon/icons-react';
import { Card, Hide, HStack, IconButton, Image, Show, Text, VStack } from '@chakra-ui/react';

import CatIcon from 'assets/icons/ProductsColoredIcons/Cat.svg';
import CerIcon from 'assets/icons/ProductsColoredIcons/Cer.svg';
import { ArrowButton } from 'components/Button/Button';
import { ProductMetrics } from 'components/cards/InvestmentProductCard';
import {
	SavingsAccountPerformanceResponse,
	SavingsAccountProvider,
	savingsAccountProviderLabels,
	SavingsAccountType,
	savingsAccountTypeLabels,
} from 'store/types/savingsAccount.type';

export const savingsAccountIcon: Record<SavingsAccountType, string> = {
	[SavingsAccountType.CAT]: CatIcon,
	[SavingsAccountType.CER]: CerIcon,
};

export type CashFund = {
	id: string;
	provider: SavingsAccountProvider;
	type: SavingsAccountType;
	valuation: number;
	lastValuationDate: string;
	performance: SavingsAccountPerformanceResponse;
};

export const OpenedFund: FC<{
	subscription: CashFund;
}> = ({ subscription }) => {
	const navigate = useNavigate();

	return (
		<Card
			size="sm"
			w="100%"
			cursor="pointer"
			_hover={{ borderColor: 'grey.900' }}
			onClick={() => {
				navigate(subscription.id, { preventScrollReset: true });
			}}
		>
			<HStack transition="all 0.3s ease-out" justify="space-between" spacing="16px" bg="white">
				<HStack
					spacing={{ base: '8px', lg: '16px' }}
					minW={{ base: undefined, lg: '200px' }}
					flex={{ base: 1, lg: undefined }}
				>
					<Image src={savingsAccountIcon[subscription.type]} alt={subscription.type} boxSize="32px" />

					<VStack align="start">
						<Text variant="Text-M-Medium">{savingsAccountTypeLabels[subscription.type]}</Text>
						<Text variant="Caption" color="grey.700">
							{savingsAccountProviderLabels[subscription.provider]}
						</Text>
					</VStack>
				</HStack>

				<ProductMetrics
					data={{
						status: 'OPENED',
						performance: subscription.performance,
						valuation: subscription.valuation,
					}}
					date={subscription.lastValuationDate}
				/>

				<Show above="lg">
					<ArrowButton variant="tertiary">Voir</ArrowButton>
				</Show>

				<Hide above="lg">
					<IconButton variant="tertiary" boxSize="24px" aria-label="details" as={ChevronRight} />
				</Hide>
			</HStack>
		</Card>
	);
};
