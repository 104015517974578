import StepperComponent from 'components/steppers/StepperV2';
import { useAppSelector } from 'hooks/useStore';
import { OnboardingStep } from 'onboarding/Stepper/stepper.slice';

export interface StepperProps {
	setCollapsed: (c: boolean) => void;
	steps: OnboardingStep[];
	headerTitle?: string;
	headerSubtitle?: string;
}

const Stepper = ({ setCollapsed, steps, headerTitle, headerSubtitle }: StepperProps): JSX.Element => {
	const { currentStep, lastStepAvailable, lastInnerStepAvailable } = useAppSelector(
		(state) => state.onboarding.stepper,
	);

	return (
		<StepperComponent
			currentStep={currentStep}
			lastAvailableStepId={lastStepAvailable}
			lastAvailableInnerStepId={lastInnerStepAvailable}
			stepper={steps}
			headerTitle={headerTitle}
			headerSubtitle={headerSubtitle}
			setCollapsed={setCollapsed}
		/>
	);
};

export default Stepper;
