import PluginSetup from './Plugins/PluginSetup';
import AppRouter from './Router';

const App = () => (
	<>
		<PluginSetup />
		<AppRouter />
	</>
);

export default App;
