import { useCallback } from 'react';
import { Button, Card, Heading, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { useAppResponsive } from 'hooks/useAppResponsive';
import eventTracker from 'services/events/eventTracker';
import { PopulatedInvestContract } from 'store/types/contract.type';
import { User } from 'store/types/user.type';
import colors from 'theme/foundations/colors';
import api from 'utils/api';
import { typeFormConstants } from 'utils/constants';

const ManageContract = ({ contract, user }: { contract: PopulatedInvestContract; user: User }) => {
	const isDesktop = useAppResponsive({ base: false, sm: true });

	const requestTransfertContract = useCallback(() => {
		api.getOperationToken('transfert').then((token) => {
			const query = new URLSearchParams({ email: user!.email, token });
			eventTracker.askPERTransfer();
			window.open(`${typeFormConstants.transfertUrl}#${query}`, '_blank');
		});
	}, [user]);

	return (
		<VStack align="start" w="100%" spacing="16px">
			<Heading variant="Title-L-SemiBold">Gérer mon contrat</Heading>
			<Wrap w="100%">
				{contract.envelope.type === 'PER' && (
					<WrapItem minW="250px" flex="1">
						<Card w="100%" h="100%">
							<VStack align="start" spacing="24px">
								<VStack align="start" spacing="8px">
									<Heading variant="Title-L-SemiBold" color={colors.primary.black}>
										Transfert
									</Heading>
									<Text variant="Text-M-Regular" color={colors.grey[900]}>
										Vous souhaitez transférer un contrat existant chez Ramify ? On s’en occupe pour vous
									</Text>
								</VStack>
								<Button variant="secondary" onClick={requestTransfertContract}>
									{isDesktop ? 'Faire une demande de transfert' : 'Transférer'}
								</Button>
							</VStack>
						</Card>
					</WrapItem>
				)}
				<WrapItem minW="250px" flex="1">
					<Card w="100%" h="100%">
						<VStack align="start" spacing="24px">
							<VStack align="start" spacing="8px">
								<Heading variant="Title-L-SemiBold" color={colors.primary.black}>
									Résiliation
								</Heading>
								<Text variant="Text-M-Regular" color={colors.grey[900]}>
									Ramify vous facilite la vie pour résilier votre contrat en quelques étapes
								</Text>
							</VStack>
							<Button
								alignSelf="end"
								variant="destroy"
								onClick={() => {
									window.Intercom('showNewMessage', `Bonjour, j'aimerais résilier mon contrat, `);
								}}
							>
								Résilier
							</Button>
						</VStack>
					</Card>
				</WrapItem>
			</Wrap>
		</VStack>
	);
};

export default ManageContract;
