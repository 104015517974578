import { backendApi } from 'services/apiService';
import { InvestFundType } from 'store/types/investment.type';

export type PerformanceObject = {
	percentagePerformance: number;
	valuePerformance: number;
};
export type RateOfReturnsResponse = {
	portfolio_return: PerformanceObject;
	isin_return_dict: { [key: string]: PerformanceObject };
};
export interface HoldingInfos {
	isin: string;
	company: string;
	isISR: boolean;
	type: string;
	montant: number;
	repartition: number;
	name: string;
	dici: string;
	label: string;
	greenTag: string;
	nbShares: number;
}
interface AssetsDataRepartition {
	id: string;
	montant: number;
	repartition: number;
	label: string;
}
interface InstrumentDataRepartition {
	devise: string;
	name: string;
	sector: string;
	repartition: number;
}

// Todo: Delete and rename after design system refacto - (nils 03/08/2023)
// export type TypeFund = 'BOND' | 'EQUITY' | 'SCPI' | 'SCI' | 'Fonds Euro' | 'PE';

export interface UniverseFund {
	isin: string;
	label: string;
	'key Penelop': string;
	// type: TypeFund;
	newType: InvestFundType;
	'region/secteur': string;
	dici: string;
	typeDeFonds: string;
	greenTag: string;
}

type AssetsHoldingsInfo = Record<string, AssetsDataRepartition>;
export interface HoldingAggregatedResponse {
	portfolio: HoldingInfos[];
	aggregatedData: {
		bonds: AssetsDataRepartition;
		equity: AssetsDataRepartition;
		scpi: AssetsDataRepartition;
		esg: AssetsDataRepartition;
		euros: AssetsDataRepartition;
		countries: AssetsHoldingsInfo;
		sectors: AssetsHoldingsInfo;
		instruments: {
			listTopinstruments: InstrumentDataRepartition[];
			total_number: number;
		};
	};
}

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		// Holdings
		getAggregatedHoldings: builder.query<HoldingAggregatedResponse, string[] | void>({
			query: (excludeContractIds) => ({
				url: '/investments',
				params: { excludeContractIds },
			}),
			providesTags: ['Holding'],
		}),

		// Rate of returns
		getRateOfReturn: builder.query<RateOfReturnsResponse, string[] | void>({
			query: (excludeContractIds) => ({
				url: `/investments/rate-of-return/`,
				method: 'GET',
				params: { excludeContractIds },
			}),
		}),
		getUniverseFunds: builder.query<UniverseFund[], void>({
			query: () => ({
				url: `/investments/list-of-funds/`,
				method: 'GET',
			}),
		}),

		getCompositionPortfolio: builder.query<
			{
				flagship: Record<number, Array<{ name: string; value: number }>>;
				essential: Record<number, Array<{ name: string; value: number }>>;
				elite: Record<number, Array<{ name: string; value: number }>>;
			},
			void
		>({
			query: () => ({
				url: '/investments/asset-class-repartition/',
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useGetRateOfReturnQuery,
	useGetAggregatedHoldingsQuery,
	useGetUniverseFundsQuery,
	useGetCompositionPortfolioQuery,
} = extendedApi;
