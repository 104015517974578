import { useMemo } from 'react';
import { Analytics, CarbonIconType, Flash, Recommend, Star } from '@carbon/icons-react';
import { Box, Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';

type BonusBlackProps = {
	title: string;
	icon: CarbonIconType;
};

const BonusOffreBlack = (): JSX.Element => {
	const BonusBlack: BonusBlackProps[] = useMemo(
		() => [
			{
				title: 'Produits exclusifs',
				icon: Star,
			},
			{
				title: 'Fonds prestigieux',
				icon: Recommend,
			},
			{
				title: 'Fiscalité optimisée',
				icon: Flash,
			},
			{
				title: 'Frais dégressifs',
				icon: Analytics,
			},
		],
		[],
	);

	return (
		<HStack w="100%">
			<Grid templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }} alignContent="center" w="100%">
				{BonusBlack.map((bonus) => (
					<GridItem w="100%" key={bonus.title}>
						<VStack w="100%" align="center" spacing="12px">
							<Box bg="primary.black" w="48px" h="48px" p="12px" borderRadius="4px">
								<bonus.icon color="white" size="24" />
							</Box>
							<Text variant="Text-S-Regular" color="white">
								{bonus.title}
							</Text>
						</VStack>
					</GridItem>
				))}
			</Grid>
		</HStack>
	);
};
export default BonusOffreBlack;
