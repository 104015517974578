import { Text, TextProps } from '@chakra-ui/react';

type ErrorFieldMessageType = { warning?: boolean };

const ErrorFieldMessage = ({ warning, ...rest }: ErrorFieldMessageType & TextProps): JSX.Element => (
	<Text
		data-cy="cy-error"
		mt="8px !important"
		color={warning ? 'yellow.500' : 'negative.900'}
		variant="Text-S-Medium"
		{...rest}
	/>
);

export default ErrorFieldMessage;
