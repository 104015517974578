import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TrashCan } from '@carbon/icons-react';
import {
	Box,
	Button,
	ButtonGroup,
	Card,
	Heading,
	HStack,
	IconButton,
	Image,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverFooter,
	PopoverHeader,
	PopoverTrigger,
	Stack,
	Text,
	useDisclosure,
	useToast,
	VStack,
} from '@chakra-ui/react';
import MoralPersonBlockerCard from 'features/MoralPersonBlockerCard';

import CashIcon from 'assets/icons/ProductsColoredIcons/Cash.svg';
import ImmoIcon from 'assets/icons/ProductsColoredIcons/Immo.svg';
import InvestIcon from 'assets/icons/ProductsColoredIcons/Invest.svg';
import PeIcon from 'assets/icons/ProductsColoredIcons/Pe.svg';
import { ArrowButton } from 'components/Button/Button';
import { SubscriptionCardProps } from 'components/cards/SubscriptionCard';
import CustomModal from 'components/Modal';
import { getRoundedTimeHorizon } from 'onboarding/Invest/Shared/Objective/projectData';
import { useDeleteSouscriptionMutation } from 'services/requests/invest/subscription';
import { useDeletePESubscriptionMutation } from 'services/requests/privateEquity/subscriptions';
import { useDeleteSCPISubscriptionMutation } from 'services/requests/realEstate/subscriptions';
import { useDeleteSavingsAccountMutation } from 'services/requests/savingsAccounts';
import { FundSubscription } from 'store/types/airtable.type';
import { ProductType } from 'store/types/global.type';
import { PortfolioType } from 'store/types/profilage.type';
import { SavingsAccountInformation } from 'store/types/savingsAccount.type';
import { isNotNone } from 'utils/functions';
import { displayMoney } from 'utils/rendering';

export const iconVariants: Record<ProductType, string> = {
	INVEST: InvestIcon,
	SCPI: ImmoIcon,
	PE: PeIcon,
	CASH: CashIcon,
};

const displayPortfolioType = {
	CUSTOM: 'Personnalisé',
	ELITE: 'Elite',
	ESSENTIAL: 'Essential',
	FLAGSHIP: 'Flagship',
} satisfies Record<PortfolioType, string>;

const bgColors: Record<ProductType | 'CRYPTO', string> = {
	[ProductType.INVEST]: 'products.invest',
	[ProductType.SCPI]: 'products.scpi',
	[ProductType.PE]: 'products.pe',
	[ProductType.CASH]: 'products.cash',
	CRYPTO: 'products.crypto',
};

const hoverColors: Record<ProductType | 'CRYPTO', { bg: string; borderColor: string }> = {
	[ProductType.INVEST]: {
		bg: 'blue.50',
		borderColor: 'blue.500',
	},
	[ProductType.SCPI]: {
		bg: 'green.50',
		borderColor: 'green.500',
	},
	[ProductType.PE]: {
		bg: 'red.50',
		borderColor: 'red.500',
	},
	[ProductType.CASH]: {
		bg: 'orange.50',
		borderColor: 'orange.500',
	},
	CRYPTO: {
		bg: 'turquoise.50',
		borderColor: 'turquoise.500',
	},
};

const FinalizingSubscription = ({ canBeDeleted = true, showAmount, ...data }: SubscriptionCardProps) => {
	const navigate = useNavigate();

	const [SCPIDelete, { isLoading: isSCPIDeleting }] = useDeleteSCPISubscriptionMutation();
	const [PEDelete, { isLoading: isPEDeleting }] = useDeletePESubscriptionMutation();
	const [deleteInvestSubscription, { isLoading: isInvestDeleting }] = useDeleteSouscriptionMutation();
	const [deleteSavingsAccount, { isLoading: isSavingsAccountDeleting }] = useDeleteSavingsAccountMutation();

	const onDelete = (id: string) => ({
		CASH: () => deleteSavingsAccount(id),
		PE: () => PEDelete({ id }),
		INVEST: () => deleteInvestSubscription({ subscriptionId: id }),
		SCPI: () => SCPIDelete({ id }),
	});

	const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
	const { isOpen: isOpenAppointment, onOpen: onOpenAppointment, onClose: onCloseAppointment } = useDisclosure();
	const timeHorizonPlan = useMemo(
		() => ('timeHorizon' in data && isNotNone(data.timeHorizon) ? getRoundedTimeHorizon(data.timeHorizon!) : undefined),
		[data],
	);

	const toast = useToast({ status: 'error', duration: 4500, isClosable: true });

	const pathFromProjectType = (type: Exclude<ProductType, 'INVEST'>): string => {
		switch (type) {
			case ProductType.SCPI:
				return 'immobilier';
			case ProductType.PE:
				return 'private-equity';
			case ProductType.CASH:
				return 'cash';
		}
	};

	const handleDeleting = async () => {
		try {
			onDelete(data.id)[data.typeProject]();
		} catch (error) {
			toast({
				title: 'Une erreur est survenue',
				description:
					(error as { data: { message: string } }).data.message || "Nous n'avons pas pu supprimer cette souscription.",
			});
		}
	};

	const onClick = () =>
		data.typeProject === ProductType.INVEST
			? navigate(`/invest/souscription/${data.id}`)
			: navigate(`/${pathFromProjectType(data.typeProject)}/onboarding/${data.id}`);

	return (
		<>
			<Card
				cursor="pointer"
				bg={bgColors[data.typeProject]}
				_hover={hoverColors[data.typeProject]}
				w="100%"
				p="24px"
				borderRadius="base"
				onClick={onClick}
				userSelect="none"
			>
				<Stack
					align={{ base: 'start', md: 'center' }}
					direction={{ base: 'column', md: 'row' }}
					justify="space-between"
					spacing="32px"
				>
					<VStack align="start" spacing="24px">
						<HStack spacing="16px" align="start">
							<Image boxSize="32px" src={iconVariants[data.typeProject]} />
							<VStack align="start" w="100%" spacing="4px">
								<Heading variant="Title-M-Bold">
									{data.typeProject === ProductType.INVEST ? (
										<>
											{data.envelope.type === 'AV' ? 'Assurance Vie' : 'PER'} -{' '}
											{displayMoney(data.initialDepositAmount)}
											{+data.recurrentDepositAmount > 0 && <> - {displayMoney(data.recurrentDepositAmount)}/mois</>}
										</>
									) : data.typeProject === ProductType.CASH ? (
										<>
											Livret Épargne
											{showAmount &&
												(data as SavingsAccountInformation).initialAUM &&
												` - ${displayMoney((data as SavingsAccountInformation).initialAUM!)}`}
										</>
									) : (
										<>
											{(data as FundSubscription).fundName}
											{showAmount &&
												(data as FundSubscription).amountVersement &&
												` - ${displayMoney((data as FundSubscription).amountVersement)}`}
										</>
									)}
								</Heading>
							</VStack>
						</HStack>
						<Text variant="Text-M-Medium" color="gray.700">
							{data.typeProject === ProductType.INVEST ? (
								<>
									{data.investmentPreferences?.portfolioType && (
										<>{displayPortfolioType[data.investmentPreferences.portfolioType]} - </>
									)}
									{data.investmentPreferences?.risk && <>Risque {data.investmentPreferences.risk} - </>}
									{data.investmentPreferences?.temperature && (
										<>Ramify Green {data.investmentPreferences.temperature} °C - </>
									)}
									{timeHorizonPlan && <>Horizon {timeHorizonPlan.label} </>}
								</>
							) : data.typeProject === ProductType.CASH ? (
								<>Cash Ramify</>
							) : (
								<>
									{data.typeProject === ProductType.SCPI ? (
										<>
											{data.fundPartnerName} -{' '}
											{data.projectType === 'NUE_PROPRIETE' ? 'Nue propriété' : 'Pleine propriété'} -{' '}
											{`${data.nbShare} ${data.nbShare > 1 ? 'parts' : 'part'}`}
										</>
									) : (
										<>
											{data.fundPartnerName}
											{` - ${data.fundType?.join(', ')}`}
										</>
									)}
								</>
							)}
						</Text>
					</VStack>
					<HStack
						align={{ base: 'start', md: 'center' }}
						spacing="16px"
						w={{ base: '100%', md: 'auto' }}
						justify="space-between"
					>
						{/* Box fix css issue with chakra popover */}
						{canBeDeleted && (
							<Box>
								<Popover isOpen={isOpenDelete} onClose={onCloseDelete}>
									<PopoverTrigger>
										<IconButton
											variant="icon"
											aria-label="trash"
											onClick={(e) => {
												e.stopPropagation();
												onOpenDelete();
											}}
											icon={
												<Box>
													<TrashCan size="24" />
												</Box>
											}
										/>
									</PopoverTrigger>
									<PopoverContent>
										<PopoverHeader>
											<Heading variant="Title-S-SemiBold">Confirmation</Heading>
										</PopoverHeader>
										<PopoverBody>
											<Text variant="Text-S-Regular">Êtes-vous sûr de vouloir supprimer cette souscription ?</Text>
										</PopoverBody>
										<PopoverFooter display="flex" justifyContent="flex-end">
											<ButtonGroup>
												<Button
													size="md"
													variant="secondary"
													onClick={(e) => {
														e.stopPropagation();
														onCloseDelete();
													}}
												>
													Annuler
												</Button>
												<Button
													size="md"
													variant="destroy"
													isLoading={isInvestDeleting || isPEDeleting || isSCPIDeleting || isSavingsAccountDeleting}
													onClick={(e) => {
														e.stopPropagation();
														handleDeleting();
													}}
												>
													Supprimer
												</Button>
											</ButtonGroup>
										</PopoverFooter>
									</PopoverContent>
								</Popover>
							</Box>
						)}

						{(data.typeProject === ProductType.INVEST || (data as FundSubscription).legalEntity !== 'MORALE') && (
							<ArrowButton
								onClick={(e) => {
									e.stopPropagation();
									onClick();
								}}
							>
								Finaliser
							</ArrowButton>
						)}

						{(data as FundSubscription).legalEntity === 'MORALE' && (
							<ArrowButton variant="primary" onClick={onOpenAppointment}>
								Prendre rendez-vous
							</ArrowButton>
						)}
					</HStack>
				</Stack>
			</Card>
			<CustomModal
				isOpen={isOpenAppointment}
				onClose={onCloseAppointment}
				isCentered
				headersProps={<Text variant="bigTitle">Investir dans {(data as FundSubscription).fundName}</Text>}
			>
				<MoralPersonBlockerCard />
			</CustomModal>
		</>
	);
};

export default FinalizingSubscription;
