import { backendApi } from 'services/apiService';
import { SCPISubscription } from 'store/types/airtable.type';

type Stringified<T> = {
	[P in keyof T]: string;
};
export type SubmitSCPISubscription = Pick<
	SCPISubscription,
	'fundsOrigin' | 'bankAccountId' | Stringified<'objectives'>
> & { subscriptionId: string; isAutoFund: boolean };

interface GeneratePdfProps {
	routeFundName: string;
	id: string;
}

export interface CashFlowEvolutionImmo {
	cashflow: number[];
	cumulative_cashflow: number[];
	dates: Date[];
}

export interface ImmobilierSubscriptionMetrics {
	tri: number;
	tri_annualised: number | undefined;
	added_value: number;
	tax_reduction: number;
	capital_evolution: [{ date: Date[]; no_div: number[] }, { date: Date[]; div: number[] }];
	cash_flow_evolution_dictionary: CashFlowEvolutionImmo;
	rent: number;
	revalo: number;
	dividend: number;
	capital_valorisation: number;
	capital_valorisation_after_fees: number;
	decote: number;
}

export interface ImmobilierSubscriptionsMetrics {
	tri: number;
	tri_annualised: number | undefined;
	added_value: number;
	tax_reduction: number;
	capital_evolution: [{ date: Date[]; no_div: number[] }, { date: Date[]; div: number[] }];
	cash_flow_evolution_dictionary: CashFlowEvolutionImmo;
	rent: number;
	revalo: number;
	dividend: number;
	total_capital_valorisation: number;
	total_capital_valorisation_after_fees: number;
	decote: number;
}

const realEstateApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getImmobilierDashboard: builder.query<ImmobilierSubscriptionsMetrics, void>({
			query: () => `/real-estate/dashboard/`,
			providesTags: ['SCPI_SUBSCRIPTIONS'],
		}),
		getImmobilierDashboardSubscriptions: builder.query<(ImmobilierSubscriptionMetrics & { id: string })[], void>({
			query: () => '/real-estate/dashboard/souscriptiondetails',
			providesTags: ['SCPI_SUBSCRIPTIONS'],
		}),
		generateSCPISubscriptionPDF: builder.query<{ signLink: string }, GeneratePdfProps>({
			query: ({ id, routeFundName }) => ({
				url: `/pdf-fill/${routeFundName}/${id}`,
			}),
		}),
	}),
});

export const {
	// PDF generation
	useLazyGenerateSCPISubscriptionPDFQuery,

	// Dashboard
	useGetImmobilierDashboardQuery,
	useGetImmobilierDashboardSubscriptionsQuery,
} = realEstateApi;
