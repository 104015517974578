import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowLeft } from '@carbon/icons-react';
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	HStack,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Skeleton,
	Text,
	Tooltip,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import Banner from 'components/Banners/Banner';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { useDrawer } from 'hooks/useDrawer';
import UpgradeEliteModal from 'pages/Invest/features/UpgradeToElite/UpgradeModalElite';
import { useGetArbitrageEligibleQuery } from 'services/requests/invest/arbitrage';
import { useGetContractQuery } from 'services/requests/invest/contracts';
import { useGetUserQuery } from 'services/requests/user';
import { useIsVersementEligibleQuery, VersementEligibility } from 'services/requests/versement';
import { isNone, isNotNone } from 'utils/functions';

import ContractDocuments from './Sections/Documents';
import ContractInfos from './Sections/Infos';
import ContractInvestmentPreferences from './Sections/InvestissementPreferences';
import ChangeStrategy from './Sections/InvestissementPreferences/Modal/UpdateInvestmentPreferencesModal';
import ManageContract from './Sections/ManageContract';

const APICIL_RENONCIATION_PERIOD_CODE = 117;

const Contract: FC = () => {
	const { id } = useParams<{ id: string }>();
	const { data: user } = useGetUserQuery();
	const { data: contract } = useGetContractQuery({ contractId: id! }, { skip: isNone(id) });
	const { data: eligibleData } = useGetArbitrageEligibleQuery(id!, { skip: isNone(id) });
	const { data: eligibleVersementElite } = useIsVersementEligibleQuery(
		{ contractId: id!, type: 'COMPLEMENTAIRE' },
		{ skip: isNone(id) },
	);

	const { isOpen, onClose } = useDrawer();

	const isDesktop = useAppResponsive({ base: false, xl: true });
	const {
		isOpen: isChangeStrategyOpen,
		onClose: onChangeStrategyClose,
		onOpen: onChangeStrategyOpen,
	} = useDisclosure();
	const { isOpen: isSwitchEliteOpen, onClose: onSwitchEliteClose, onOpen: onSwitchEliteOpen } = useDisclosure();

	const showEliteSwitchOption =
		eligibleVersementElite?.eligibility === VersementEligibility.ELIGIBLE &&
		contract &&
		contract.investmentPreferences?.portfolioType !== 'ELITE';

	return (
		<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isOpen} onClose={onClose}>
			<DrawerOverlay />
			{isNotNone(contract) && isNotNone(user) ? (
				<DrawerContent>
					<DrawerHeader>
						<HStack justify="space-between">
							<HStack>
								<IconButton
									onClick={onClose}
									variant="icon"
									aria-label="back"
									icon={
										<Box>
											<ArrowLeft size="32" />
										</Box>
									}
								/>
								<Heading variant="Title-M-SemiBold">
									{contract.envelope.type === 'AV' ? 'Assurance vie' : 'PER individuel'}
								</Heading>
							</HStack>

							<Tooltip
								hasArrow
								label={
									!eligibleData?.eligible && eligibleData?.code === APICIL_RENONCIATION_PERIOD_CODE
										? 'Ce contrat est en délai de rétraction'
										: "Vous ne pouvez pas faire d'arbitrage sur ce contrat pour le moment"
								}
								shouldWrapChildren
								isDisabled={eligibleData && eligibleData.eligible}
							>
								<Button
									w="100%"
									variant="primary"
									onClick={!eligibleData || !eligibleData.eligible ? undefined : onChangeStrategyOpen}
									isDisabled={!eligibleData || !eligibleData.eligible}
								>
									{isDesktop ? 'Modifier mes préférences' : 'Modifier'}
								</Button>
							</Tooltip>
						</HStack>
					</DrawerHeader>

					<DrawerBody>
						<Modal isOpen={isChangeStrategyOpen} onClose={onChangeStrategyClose} size={{ base: 'md', md: '3xl' }}>
							<ModalOverlay />
							<ModalContent>
								<ModalHeader>
									<Text variant="Title-M-SemiBold">Changer mes préférences d’investissement</Text>
								</ModalHeader>
								<ModalCloseButton />
								<ModalBody>
									<ChangeStrategy
										preferences={contract.investmentPreferences!}
										contract={contract}
										onClose={onChangeStrategyClose}
									/>
								</ModalBody>
								<ModalFooter />
							</ModalContent>
						</Modal>

						{showEliteSwitchOption && (
							<UpgradeEliteModal isOpen={isSwitchEliteOpen} onClose={onSwitchEliteClose} contract={contract} />
						)}

						<VStack w="100%" spacing="48px" align="start">
							<ContractInvestmentPreferences contract={contract} eligibleData={eligibleData} />

							{showEliteSwitchOption && (
								<Banner
									title="Optez pour Elite, notre portefeuille le plus performant"
									description="Disponible à partir de 50 000€"
									variant="investissement"
									avatar="arnaud"
									cta="Passer à Elite"
									onClick={onSwitchEliteOpen}
								/>
							)}
							<ContractInfos contract={contract} />
							<ContractDocuments contractId={contract.id} subscriptionId={contract.subscriptionId} />

							<ManageContract contract={contract} user={user} />
						</VStack>
					</DrawerBody>
				</DrawerContent>
			) : (
				<VStack w="100%" spacing="16px" align="left">
					<Skeleton h="56px" w="40%" />
					<Skeleton h="456px" w="100%" />
					<Skeleton h="320px" w="100%" />
					<Skeleton h="320px" w="100%" />
					<Skeleton h="320px" w="100%" />
				</VStack>
			)}
		</Drawer>
	);
};

export default Contract;
