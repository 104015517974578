import { backendApi } from 'services/apiService';
import {
	SavingsAccountHistoryResponse,
	SavingsAccountInformation,
	SavingsAccountPerformanceResponse,
	SavingsAccountUpdateTableResponse,
	SavingsAccountWithHistory,
	UpdateSavingsAccountDTO,
	UpdateSavingsAccountStatusDTO,
} from 'store/types/savingsAccount.type';

type SignLink = { signature_link: string; signature_request_id: string };

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllSavingsAccounts: builder.query<SavingsAccountWithHistory[], void>({
			query: () => '/savings-account',
			providesTags: ['SavingsAccounts'],
		}),
		getSavingsAccount: builder.query<SavingsAccountWithHistory, string>({
			query: (id) => `/savings-account/${id}`,
			providesTags: ['SavingsAccounts'],
		}),
		getSavingsAccountsSubscriptions: builder.query<SavingsAccountInformation[], void>({
			query: () => '/savings-account/subscriptions',
			providesTags: ['SavingsAccounts'],
		}),
		createSavingsAccount: builder.mutation<SavingsAccountInformation, void>({
			query: () => ({
				url: '/savings-account',
				method: 'POST',
			}),
			invalidatesTags: ['SavingsAccounts'],
		}),
		updateSavingsAccountPreferences: builder.mutation<SavingsAccountInformation, UpdateSavingsAccountDTO>({
			query: ({ id, ...body }) => ({
				url: `/savings-account/${id}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['SavingsAccounts'],
		}),
		updateSavingsAccountStatus: builder.mutation<SavingsAccountInformation, UpdateSavingsAccountStatusDTO>({
			query: (body) => ({
				url: '/savings-account/status',
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['SavingsAccounts'],
		}),
		signSavingsAccount: builder.mutation<void, void>({
			query: (body) => ({
				url: '/savings-account/sign',
				method: 'PUT',
				body,
			}),
			invalidatesTags: ['SavingsAccounts'],
		}),
		uploadFundsOriginDocument: builder.mutation<void, FormData>({
			query: (body) => ({
				url: '/savings-account/funds-origin-document',
				method: 'POST',
				body,
			}),
		}),
		deleteSavingsAccount: builder.mutation<void, string>({
			query: (id) => ({
				url: `/savings-account/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['SavingsAccounts'],
		}),
		generateBulletinSignature: builder.mutation<SignLink, string>({
			query: (savingsAccountId) => ({
				url: `/savings-account/signature/bulletin/${savingsAccountId}`,
				method: 'POST',
			}),
		}),
		// unused ?
		getSavingsAccountDocuments: builder.query<void, void>({
			query: () => '/savings-account/documents',
			providesTags: ['SavingsAccounts'],
		}),
		getSavingsAccountHistory: builder.query<SavingsAccountHistoryResponse, string>({
			query: (id) => `/savings-account/${id}/history`,
			providesTags: ['SavingsAccounts'],
		}),
		getSavingsAccountPerformance: builder.query<SavingsAccountPerformanceResponse, string>({
			query: (id) => `/savings-account/${id}/performance`,
			providesTags: ['SavingsAccounts'],
		}),
		updateCERPerformancesTable: builder.query<SavingsAccountUpdateTableResponse, string>({
			query: (id) => `/savings-account/${id}/update-table`,
			providesTags: ['SavingsAccounts'],
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetAllSavingsAccountsQuery,
	useGetSavingsAccountsSubscriptionsQuery,
	useGetSavingsAccountQuery,
	useCreateSavingsAccountMutation,
	useUpdateSavingsAccountPreferencesMutation,
	useUpdateSavingsAccountStatusMutation,
	useUploadFundsOriginDocumentMutation,
	useSignSavingsAccountMutation,
	useDeleteSavingsAccountMutation,
	useGenerateBulletinSignatureMutation,
	useGetSavingsAccountDocumentsQuery, // unused ?
	useGetSavingsAccountHistoryQuery,
	useGetSavingsAccountPerformanceQuery,
	useUpdateCERPerformancesTableQuery,
} = extendedApi;
