import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Download, Email, ViewFilled } from '@carbon/icons-react';
import { Box, Card, Center, Heading, HStack, Link, Skeleton, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { DotSpinner } from '@uiball/loaders';

import InformationBanner from 'components/Banners/InformationBanner';
import { IconButton } from 'components/Button/Button';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useOnScreen from 'hooks/useOnScreen';
import { SouscriptionStepProps } from 'onboarding/Stepper/stepper.slice';
import { useGenerateBulletinMutation } from 'services/requests/invest/subscription';
import { isNotNone } from 'utils/functions';

import ModalBulletin from './ModalBulletin';

const Bulletin = ({ subscription, onNext, onPrev }: SouscriptionStepProps): JSX.Element => {
	const { isOpen: IsModalBulletinOpen, onClose: onModalBulletinClose, onOpen: onModalBulletinOpen } = useDisclosure();
	const [pdfBulletin, setPdfBulletin] = useState<null | string>(null);
	const [fallbackRef, setFallbackRef] = useState<HTMLElement | null>(null);
	const isFallbackVisible = useOnScreen(fallbackRef);
	const [generateBulletinInternal, { isError: isGenerateError }] = useGenerateBulletinMutation();
	const isFirstRender = useRef(true);

	const generateBulletin = useCallback(
		() => generateBulletinInternal({ subscriptionId: subscription.id }).unwrap().then(setPdfBulletin),
		[generateBulletinInternal, subscription.id],
	);

	useEffect(() => {
		if (isFirstRender.current) {
			generateBulletin();
			isFirstRender.current = false;
		}
	}, [generateBulletin]);

	const isLoaded = useMemo(() => isNotNone(pdfBulletin) || isGenerateError, [isGenerateError, pdfBulletin]);

	return (
		<VStack w="100%" align="start" spacing="40px" padding="0 0 48px 0">
			<Heading variant="Title-XL-Bold">Signature du contrat</Heading>

			<InformationBanner
				title="Vos fonds seront hébergés par APICIL Épargne"
				description={`La signature du contrat ci-dessous permettra l'ouverture de votre ${
					subscription.envelope.type === 'AV' ? 'assurance vie' : 'PER individuel'
				}. La gestion de votre contrat se fera intégralement via votre espace client Ramify.`}
			/>

			{!isLoaded && (
				<HStack w="100%" spacing="16px">
					<Box flexShrink="0">
						<DotSpinner />
					</Box>
					<Text variant="Text-M-Regular">
						Nous générons votre bulletin de souscription, cela peut prendre quelques secondes...
					</Text>
				</HStack>
			)}

			<Skeleton isLoaded={isLoaded} w="100%" minH="280px">
				{pdfBulletin ? (
					<object
						data={pdfBulletin}
						type="application/pdf"
						width="100%"
						height={isFallbackVisible ? '280px' : '800px'}
						title="Bulletin d'adhésion"
					>
						<Card ref={(ref) => setFallbackRef(ref)} w="100%" h="100%">
							<VStack w="100%" h="100%" align="start" justify="space-between">
								<VStack w="100%" align="start">
									<Text variant="Title-S-Bold">Votre contrat est prêt !</Text>
									<Text variant="Text-M-Regular" color="grey.900">
										Veuillez en prendre connaissance avant de procéder à sa signature.
									</Text>
								</VStack>
								<VStack w="100%" align="start">
									<Link w="100%" href={pdfBulletin} target="_blank">
										<IconButton variant="secondary" size="lg" w="100%" icon={<ViewFilled />} right>
											Consulter
										</IconButton>
									</Link>
									<Link w="100%" href={pdfBulletin} download="bulletin-souscription.pdf">
										<IconButton variant="secondary" size="lg" w="100%" icon={<Download />} right>
											Télécharger
										</IconButton>
									</Link>
								</VStack>
							</VStack>
						</Card>
					</object>
				) : (
					<Center border="base" bg="white" h="100%" w="100%" borderRadius="base" height="200px">
						<Text px="32px">
							Une erreur est survenue lors du chargement de votre bulletin de souscription :{' '}
							<Link
								color="blue.500"
								fontWeight={500}
								href="#"
								onClick={() => generateBulletin()}
								textDecoration="underline"
							>
								Réessayer
							</Link>
						</Text>
					</Center>
				)}
			</Skeleton>

			<InformationBanner
				description="Vous allez recevoir par email les documents d'information réglementaire associés à votre contrat"
				icon={Email}
			/>

			<BottomStepBar
				tooltip="Veuillez patientez quelques secondes pendant que nous générons votre contrat."
				onPrev={onPrev}
				onNext={onModalBulletinOpen}
				textNext="Signer mon contrat"
				showTooltip={!pdfBulletin}
				isNextDisabled={!pdfBulletin}
			/>

			<ModalBulletin
				isOpen={IsModalBulletinOpen}
				onClose={onModalBulletinClose}
				onSuccess={onNext}
				subscription={subscription}
			/>
		</VStack>
	);
};

export default Bulletin;
