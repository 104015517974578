import { Skeleton, VStack } from '@chakra-ui/react';

import TextDivider from './TextDivider';

const VersementHistoryLoading = () => (
	<VStack spacing="24px" w="100%">
		<VStack w="100%" align="start" spacing="24px">
			<Skeleton>
				<TextDivider label="2022" />
			</Skeleton>
			<VStack w="100%" spacing="16px">
				<Skeleton w="100%" h="128px" />
				<Skeleton w="100%" h="128px" />
				<Skeleton w="100%" h="128px" />
			</VStack>
		</VStack>
	</VStack>
);

export default VersementHistoryLoading;
