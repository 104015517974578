import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Add } from '@carbon/icons-react';
import { Box, Button, FormControl, FormErrorMessage, FormLabel, Skeleton, Text, VStack } from '@chakra-ui/react';

import BankAccountCard from 'components/bankingInformations/BankAccountCard';
import { useGetBankAccountsQuery } from 'services/requests/bank';
import { BankInformations } from 'store/types/subscription.type';

const SelectBankAccount = ({ onClickAdd }: { onClickAdd?: () => void }): JSX.Element => {
	const { data: bankAccountList, isLoading: bankAccountListLoading } = useGetBankAccountsQuery();

	const {
		control,
		formState: { errors },
		watch,
		setValue,
		clearErrors,
	} = useFormContext();

	const selectedBankAccountId = watch('bankAccountId');

	// By default, select the first bank account in the list
	useEffect(() => {
		if (!selectedBankAccountId && bankAccountList?.length) {
			setValue('bankAccountId', bankAccountList[0].id);
			clearErrors('bankAccountId');
		}
	}, [bankAccountList, selectedBankAccountId, setValue, clearErrors]);

	if (bankAccountListLoading) return <Skeleton h="80px" />;

	return (
		<VStack align="start" w="100%" spacing="16px">
			<FormControl isInvalid={!!errors.bankAccountId}>
				<FormLabel> Mes coordonnées bancaires</FormLabel>
				<Controller
					name="bankAccountId"
					control={control}
					rules={{ required: true }}
					render={({ field: { onChange, value, ref, name } }) => (
						<VStack as="fieldset" spacing="16px" align="start" ref={ref} name={name}>
							{bankAccountList && bankAccountList.length > 0 ? (
								bankAccountList.map((bankAccount: BankInformations) => (
									<Box key={bankAccount.id} w="100%">
										<BankAccountCard
											IBAN={bankAccount.IBAN}
											name={bankAccount.holder}
											onSelect={() => onChange(bankAccount.id)}
											value={value === bankAccount.id}
										/>
									</Box>
								))
							) : (
								<Text variant="Text-M-Regular" color="grey.900">
									Vous n'avez pas de compte bancaire enregistré...
								</Text>
							)}
						</VStack>
					)}
				/>
				{errors.bankAccountId?.type === 'required' && bankAccountList?.length === 0 && (
					<FormErrorMessage>
						{bankAccountList && bankAccountList.length > 0
							? 'Merci de sélectionner le compte bancaire que vous souhaitez utiliser pour votre souscription'
							: 'Veuillez ajouter un compte bancaire pour continuer'}
					</FormErrorMessage>
				)}
			</FormControl>

			{onClickAdd && (
				<Button
					data-cy="addAccount"
					variant="primary"
					size="lg"
					rightIcon={
						// Trick to fix aria-hidden error
						<Box>
							<Add size="20" />
						</Box>
					}
					onClick={onClickAdd}
				>
					Ajouter un nouveau compte
				</Button>
			)}
		</VStack>
	);
};

export default SelectBankAccount;
