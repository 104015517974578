import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ContractFilterState {
	inactiveList: string[];
}

const initialState: ContractFilterState = {
	inactiveList: [],
};

const contractFilterSlice = createSlice({
	name: 'contractFilter',
	initialState,
	reducers: {
		toogleContract(state, action: PayloadAction<string>) {
			if (state.inactiveList.find((e) => e == action.payload)) {
				// in, remove
				state.inactiveList = state.inactiveList.filter((e) => e !== action.payload);
			} else {
				// not in, add
				state.inactiveList = [...state.inactiveList, action.payload];
			}
		},
		clearSelector(state) {
			state.inactiveList = [];
		},
	},
});

export const { toogleContract, clearSelector } = contractFilterSlice.actions;
export default contractFilterSlice.reducer;
