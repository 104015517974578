import { CheckmarkOutline } from '@carbon/icons-react';
import { Box, Card, HStack, Icon, Text, VStack } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';

import { StepProps } from './index';

export const Step = ({ current, index, title, description, tooltip, last, show = false }: StepProps) => {
	const isSelected = current === index;
	return (
		<VStack align="start">
			<HStack>
				<HStack borderRadius="full" p="4px 8px 4px 4px" bg={isSelected ? 'yellow.100' : 'transparent'}>
					{index >= current ? (
						<Box
							borderRadius="full"
							w="24px"
							h="24px"
							pt="1px"
							bg={isSelected ? 'primary.yellow' : 'transparent'}
							border={isSelected ? '1px solid transparent' : '1px solid black'}
						>
							<Text variant="Text-S-Medium" align="center">
								{index + 1}
							</Text>
						</Box>
					) : (
						<Icon boxSize={6} as={CheckmarkOutline} />
					)}
					<Text variant="Text-M-Medium">{title}</Text>
				</HStack>
				{tooltip && (
					<CustomTooltip
						content={
							<Text variant="Text-S-Regular" color="white">
								{tooltip}
							</Text>
						}
					/>
				)}
			</HStack>
			<Box ml="15px" borderLeft={!last || isSelected ? '1px solid black' : 'none'} minH="16px">
				{(isSelected || show) && (
					<Card border="none">
						<Text>{description}</Text>
					</Card>
				)}
			</Box>
		</VStack>
	);
};
