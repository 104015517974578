import { CheckmarkOutline, Information, Warning } from '@carbon/icons-react';
import { CloseIcon } from '@chakra-ui/icons';
import { HStack, Text, useToast, UseToastOptions, VStack } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';
import standaloneToast from 'utils/standaloneToast';

const getIconFromStatus = (status: string | undefined) => {
	if (status === 'success') return <CheckmarkOutline color={getTextColorFromStatus(status)} size="24" />;
	if (status === 'error') return <Warning color={getTextColorFromStatus(status)} size="24" />;
	if (status === 'warning') return <Warning color={getTextColorFromStatus(status)} size="24" />;
	if (status === 'info') return <Information color={getTextColorFromStatus(status)} size="24" />;
	return <Information />;
};

const getBgColorFromStatus = (status: string | undefined): string => {
	if (status === 'success') return colors.positive[100];
	if (status === 'error') return colors.negative[100];
	if (status === 'warning') return colors.warning[100];
	if (status === 'info') return colors.blue[100];
	return colors.blue[100];
};

const getTextColorFromStatus = (status: string | undefined): string => {
	if (status === 'success') return colors.positive[900];
	if (status === 'error') return colors.negative[900];
	if (status === 'warning') return colors.warning[900];
	if (status === 'info') return colors.blue[900];
	return colors.blue[900];
};

const themedToast = (options: UseToastOptions, onClose: () => void) => (
	<HStack bg={getBgColorFromStatus(options.status)} p="16px" align="start" spacing="8px" justify="space-between">
		{getIconFromStatus(options.status)}
		<VStack align="start" w="100%">
			<Text color={getTextColorFromStatus(options.status)} variant="Text-M-Bold">
				{options.title}
			</Text>
			<Text color={getTextColorFromStatus(options.status)} variant="Text-S-Medium">
				{options.description}
			</Text>
		</VStack>
		{options.isClosable && (
			<CloseIcon color={getTextColorFromStatus(options.status)} onClick={onClose} fontSize="14px" cursor="pointer" />
		)}
	</HStack>
);

const useThemedToast = () => {
	const toast = useToast();

	const returnFunction = (options: UseToastOptions) =>
		toast({
			id: options.id,
			duration: options.duration,
			render: ({ onClose }) => themedToast(options, onClose),
		});
	return Object.assign(returnFunction, toast);
};

export const createStandaloneThemedToast = () => {
	const returnFunction = (options: UseToastOptions) =>
		standaloneToast({
			id: options.id,
			render: ({ onClose }) => themedToast(options, onClose),
		});
	return Object.assign(returnFunction, standaloneToast);
};

export default useThemedToast;
