import { PESortOptionType } from 'components/filter/SortingSelect';
import { PEFund } from 'store/types/airtable.type';

export const peSortOption: Record<string, PESortOptionType> = {
	price: {
		key: 'price',
		label: 'Minimum de souscription (croissant)',
		fnct: (a: PEFund, b: PEFund) => (a.ticket < b.ticket ? -1 : 1),
	},
	revPrice: {
		key: 'revPrice',
		label: 'Minimum de souscription (décroissant)',
		fnct: (a: PEFund, b: PEFund) => (a.ticket > b.ticket ? -1 : 1),
	},
};

export const openStatusOptions = ['Fonds ouverts', 'Fonds fermés'];

export type PEFundTaxAdvantageType = keyof Pick<
	PEFund,
	'reinvestTaxAdvantage' | 'gainsTaxAdvantage' | 'revenueTaxAdvantage'
>;

// name is used for the tag text
// display is used for the tag filter bar component
export const peTagsDisplay = {
	reinvestTaxAdvantage: { display: 'Eligible au 150-0 B ter', name: '150-0 B ter' },
	gainsTaxAdvantage: {
		display: "Plus-value exonérée de l'IR (Impôt sur le revenu)",
		name: 'Plus-value exonérée de l’IR',
	},
	revenueTaxAdvantage: {
		display: 'Reduction IR (Impôt sur le Revenu)',
		name: 'Réduction IR',
	},
} satisfies Record<PEFundTaxAdvantageType, Record<'display' | 'name', string>>;

export type CatalogNavigationStateType =
	| {
			fromDiscover: boolean | undefined;
	  }
	| undefined;

export const taxAdvantageOptions = Object.keys(peTagsDisplay);
