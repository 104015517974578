import { useState } from 'react';
import { CheckIcon } from '@chakra-ui/icons';
import { HStack, Stack, Text, VStack } from '@chakra-ui/react';

import DropZone from 'components/inputs/Dropzone';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import { useGetKYCQuery, useUpdateKYCJustificatifMutation } from 'services/requests/kyc';
import { isNotNone } from 'utils/functions';

type DomicileProps = {
	onSuccess: () => void;
};

const Domicile = ({ onSuccess }: DomicileProps): JSX.Element => {
	const toast = useThemedToast();
	const { data: kyc } = useGetKYCQuery();
	const [file, setFile] = useState<File>();

	const [triggerUpdateEtatCivil, { isLoading }] = useUpdateKYCJustificatifMutation();

	const onSubmit = () => {
		if (isNotNone(file)) {
			const formData = new FormData();
			formData.append('files', file);
			formData.append('libelle', 'Justificatif de domicile');
			formData.append('code', 'Justificatif_domicile');
			triggerUpdateEtatCivil(formData)
				.unwrap()
				.then(() => {
					toast({
						title: 'Votre document a bien été mis à jour',
						status: 'success',
						duration: 9000,
						isClosable: true,
					});
					onSuccess();
				})
				.catch(() => {
					toast({
						title: 'Une erreur est survenue',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				});
		} else
			toast({
				title: 'Veuillez téléverser un justificatif',
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
	};

	return (
		<Stack w="100%" spacing="32px" position="relative" pb="96px">
			<VStack align="start" spacing="16px">
				<Text variant="Title-S-Bold">1 - Vérifiez votre adresse personnelle</Text>
				<Text variant="Text-S-Regular">L’adresse spécifiée dans votre document doit être identique à celle-ci :</Text>
				<Text variant="Text-S-Bold">{kyc?.address}</Text>
				<Text variant="Text-S-Bold">
					{kyc?.zipCode} {kyc?.city}
				</Text>
			</VStack>

			<VStack align="start" spacing="24px">
				<Text variant="Title-S-Bold">2 - Ajoutez un justificatif de domicile</Text>

				<DropZone
					cy="docInput"
					filesUploaded={isNotNone(file)}
					filename={file?.name}
					onDrop={(files) => {
						setFile(files[0]);
					}}
				/>
				<Text variant="Text-M-Regular">Les documents suivants sont acceptés :</Text>
				<VStack align="start">
					<HStack>
						<CheckIcon color="green.700" />
						<Text variant="Text-S-Regular">
							Facture d'énergie ou de téléphone fixe, facture mobile non acceptée (de moins de 3 mois)
						</Text>
					</HStack>
					<HStack>
						<CheckIcon color="green.700" />
						<Text variant="Text-S-Regular">Relevé d'imposition (de moins de 12 mois)</Text>
					</HStack>
					<HStack>
						<CheckIcon color="green.700" />
						<Text variant="Text-S-Regular">
							Dans le cas d’un <b>hébergement chez un tiers </b>, veuillez joindre la pièce d’identité, le justificatif
							de domicile et la lettre de l’hébergeur
						</Text>
					</HStack>
				</VStack>
			</VStack>
			<BottomStepBar onNext={onSubmit} showPrev={false} isNextLoading={isLoading} />
		</Stack>
	);
};

export default Domicile;
