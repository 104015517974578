import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AddIcon } from '@chakra-ui/icons';
import {
	Button,
	Card,
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Image,
	Input,
	Select,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';

import { TrashIcon } from 'assets/icons/contract/ContractIcons';
import CheckedIllustration from 'assets/souscription/check.svg';
import DatePicker from 'components/inputs/DatePicker';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { ParentalLink, useBeneficiary } from 'hooks/useBeneficiary';
import useThemedToast from 'hooks/useThemedToast';
import { SouscriptionStepProps } from 'onboarding/Stepper/stepper.slice';
import { UpdateInvestSubscriptionBeneficiary } from 'services/requests/invest/subscription';
import { useGetKYCQuery } from 'services/requests/kyc';
import { BeneficiaryType } from 'store/types/subscription.type';

const toolTipChoixBeneficiaire = (value: BeneficiaryType): { title: string; description: string } | null => {
	if (value === 'SPOUSE')
		return {
			title: 'Conjoint',
			description:
				'En cas de décès, je souhaite que le capital constitué soit versé à mon conjoint à la date de décès non divorcé non séparé judiciairement, à défaut à mes enfants nés ou à naître, vivants ou représentés par parts égales entre eux, à défaut à mes héritiers.',
		};
	if (value === 'CHILDREN')
		return {
			title: 'Enfants',
			description:
				'En cas de décès, je souhaite que le capital constitué soit versé à mes enfants nés ou à naître, vivants ou représentés par parts égales entre eux, à défaut à mes héritiers.',
		};
	if (value === 'HEIRS')
		return {
			title: 'Héritiers',
			description: 'En cas de décès, je souhaite que le capital constitué soit versé à mes héritiers.',
		};
	return null;
};

const beneficiaryOptions = Object.entries({
	SPOUSE: 'mon conjoint',
	CHILDREN: 'mes enfants',
	HEIRS: 'mes héritiers',
	WRITTEN: 'Personnalisé',
} as Record<BeneficiaryType, string>) as [BeneficiaryType, string][];

const Beneficiaire = ({
	subscription,
	onNext,
	onPrev,
}: SouscriptionStepProps<UpdateInvestSubscriptionBeneficiary>): JSX.Element => {
	const { data: kyc } = useGetKYCQuery();
	const toast = useThemedToast();
	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm<UpdateInvestSubscriptionBeneficiary>({});

	useEffect(() => {
		setValue('beneficiary', subscription.beneficiary);
		setValue('freeBeneficiaryClause', subscription.freeBeneficiaryClause);
	}, [setValue, subscription]);

	const {
		beneficiaries,
		parentalLinkMap,
		totalPercentage,
		addBeneficiary,
		deleteBeneficiary,
		outputClause,
		editBeneficiaryField,
		isBeneficiaryCorrect,
	} = useBeneficiary({
		firstname: kyc?.firstName ?? '',
		lastname: kyc?.lastName ?? '',
		birthDate: kyc?.birthDate?.toString() ?? '',
		birthCity: kyc?.birthCity ?? '',
	});
	const beneficiaire = watch('beneficiary');

	const onSubmit = handleSubmit((data: UpdateInvestSubscriptionBeneficiary) => {
		if (data.beneficiary !== 'WRITTEN') return onNext({ beneficiary: data.beneficiary, freeBeneficiaryClause: null });

		if (beneficiaries.length === 0) {
			toast({
				title: 'Champs manquants',
				description: 'Veuillez ajouter au moins un bénéficiaire',
				status: 'error',
				duration: 4500,
				isClosable: true,
			});
			return;
		}
		if (beneficiaries.find((e) => !isBeneficiaryCorrect(e))) {
			toast({
				title: 'Champs manquants',
				description: 'Veuillez remplir tous les champs',
				status: 'error',
				duration: 4500,
				isClosable: true,
			});
			return;
		}
		onNext({
			beneficiary: data.beneficiary,
			freeBeneficiaryClause: data.freeBeneficiaryClause,
		});
	});

	useEffect(() => {
		setValue('freeBeneficiaryClause', outputClause);
	}, [setValue, outputClause]);

	return (
		<VStack align="start" spacing="48px" width="100%">
			<Heading variant="bigTitle">Bénéficiaire</Heading>
			<chakra.form onSubmit={onSubmit} w="100%">
				<VStack spacing="32px" align="start">
					<FormControl isInvalid={!!errors.beneficiary}>
						<FormLabel>En cas de décès, je souhaite que mon capital constitué soit versé à : </FormLabel>
						<Select {...register('beneficiary', { required: true })}>
							{beneficiaryOptions.map(([name, label]) => (
								<option key={name} value={name}>
									{label}
								</option>
							))}
						</Select>
						{errors.beneficiary?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					</FormControl>

					{beneficiaire && beneficiaire !== 'WRITTEN' && (
						<Card w="100%">
							<VStack align="start" w="100%" spacing="8px">
								<Heading variant="Title-S-Bold">{toolTipChoixBeneficiaire(beneficiaire)?.title}</Heading>
								<Text variant="Text-M-Regular">{toolTipChoixBeneficiaire(beneficiaire)?.description}</Text>
							</VStack>
						</Card>
					)}

					{beneficiaire === 'WRITTEN' && (
						<>
							<Heading alignSelf="flex-start" variant="Title-M-Bold">
								Informations bénéficiaire(s)
							</Heading>

							{beneficiaries.length > 0 && totalPercentage !== 100 && (
								<Card size="sm" width="full" borderColor="negative.900" bg="negative.100">
									<HStack>
										<Text variant="Title-S-Bold">Attention</Text>
									</HStack>
									<Text variant="body" as="i">
										La somme du pourcentage de parts attribuées à chacun de vos bénéficiaires doit être égale à{' '}
										<strong>100%</strong>
									</Text>
								</Card>
							)}

							{beneficiaries.map((b, index) => (
								<Card key={`${b.lastname}-${b.birthDate}`} width="full">
									<HStack alignItems="center" justifyContent="space-between" w="100%">
										<Text variant="label">
											Bénéficiaire {isBeneficiaryCorrect(b) ? `/ ${b.firstname} ${b.lastname}` : `n° ${index + 1}`}
										</Text>
										{isBeneficiaryCorrect(b) && <Image src={CheckedIllustration} alt="check" />}
										<TrashIcon
											display={beneficiaries.length > 1 ? 'inherit' : 'none'}
											onClick={() => deleteBeneficiary(index)}
											_hover={{ cursor: 'pointer' }}
											boxSize={22}
										/>
									</HStack>

									<Stack
										direction={{ base: 'column', xl: 'row' }}
										mt="24px"
										width="full"
										gap="16px"
										justify="space-between"
									>
										<FormControl>
											<FormLabel>Prénom</FormLabel>
											<Input
												onChange={(e) => editBeneficiaryField({ ...b, firstname: e.target.value }, index)}
												w="100%"
												type="text"
												placeholder="..."
											/>
										</FormControl>

										<FormControl>
											<FormLabel>Nom</FormLabel>
											<Input
												onChange={(e) => editBeneficiaryField({ ...b, lastname: e.target.value }, index)}
												w="100%"
												type="text"
												placeholder="..."
											/>
										</FormControl>

										<FormControl>
											<FormLabel>Lien de parenté</FormLabel>
											<Select
												onChange={(e) =>
													editBeneficiaryField({ ...b, parentalLink: e.target.value as ParentalLink }, index)
												}
											>
												{Object.entries(parentalLinkMap).map(([value, label]) => (
													<option key={`option-parental-link-${value}`} value={value}>
														{label}
													</option>
												))}
											</Select>
										</FormControl>
									</Stack>

									<Stack
										direction={{ base: 'column', xl: 'row' }}
										mt="24px"
										width="full"
										gap="16px"
										justify="space-between"
									>
										<FormControl>
											{/* <FormLabel>Date de naissance</FormLabel> */}
											<DatePicker
												label="Date de naissance"
												value={b.birthDate}
												onChangeText={(text) => {
													if (text) editBeneficiaryField({ ...b, birthDate: text }, index);
												}}
												autoComplete="bday"
											/>
										</FormControl>
										<FormControl>
											<FormLabel>Lieu de naissance</FormLabel>
											<Input
												onChange={(e) => editBeneficiaryField({ ...b, birthCity: e.target.value }, index)}
												w="100%"
												type="text"
												placeholder="..."
											/>
										</FormControl>
										<FormControl>
											<FormLabel>Pourcentage de parts</FormLabel>
											<Input
												onChange={(e) => editBeneficiaryField({ ...b, percentage: Number(e.target.value) }, index)}
												value={b.percentage}
												w="100%"
												type="number"
												placeholder="..."
												min={0}
												max={100}
											/>
										</FormControl>
									</Stack>
								</Card>
							))}
							<Button
								leftIcon={<AddIcon ml="8px" w="10px" color="grey.700" />}
								alignSelf="flex-start"
								onClick={() => addBeneficiary()}
								variant="secondary"
							>
								Ajouter un bénéficiaire
							</Button>
						</>
					)}
				</VStack>
				<BottomStepBar onNext={onSubmit} onPrev={onPrev} />
			</chakra.form>
		</VStack>
	);
};

export default Beneficiaire;
