import { Button, Heading, HStack, Image, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';

import Book from 'assets/illu-top-page.png';
import { CalendlyModal } from 'components/Modals/Calendly';
import { IntestedProducts } from 'components/Modals/Calendly/useBuildIframeUrl';
import { useAppResponsive } from 'hooks/useAppResponsive';
import CashVersementModal from 'pages/Cash/Dashboard/VersementModal';
import useCERCallToAction from 'pages/Cash/Discover/Sections/useCERCallToAction';
import { isNone, isNotNone } from 'utils/functions';

import { useHeaderProductVariant } from './useHeaderProductVariant';

export type HeaderProductPageProps = {
	icon: string;
	title: string;
	menu?: { label: string; items: { label: string; onClick: () => void }[] };
	calendlyProduct: IntestedProducts;
};

export type VariantHeaderProductPage = 'Private Equity' | 'SCPI' | 'Cash' | 'BSPCE';

const HeaderProductPage = ({ variant }: { variant: VariantHeaderProductPage }) => {
	const isDesktop = useAppResponsive({ base: false, xl: true });
	const { isOpen: isCalendlyOpen, onClose: onCalendlyClose, onOpen: onCalendlyOpen } = useDisclosure();
	const { isOpen: isCashVersementOpen, onClose: onCashVersementClose, onOpen: onCashVersementOpen } = useDisclosure();

	const { openedCER } = useCERCallToAction();

	const headerContent = useHeaderProductVariant(variant, onCalendlyOpen, isNotNone(openedCER), onCashVersementOpen);

	if (isNone(headerContent)) return null;

	return (
		<>
			{isDesktop && <Image src={Book} right="25%" w="263px" position="absolute" top="0" />}
			<HStack w="100%" justify="space-between">
				<HStack spacing="8px">
					<Image src={headerContent.icon} />
					<Heading variant="Title-XL-Bold">{headerContent.title}</Heading>
				</HStack>
				{headerContent.menu && (
					<Menu>
						<MenuButton as={Button} variant="super" size="lg">
							{headerContent.menu.label}
						</MenuButton>
						<MenuList>
							{headerContent.menu.items.map((item) => (
								<MenuItem key={item.label} onClick={item.onClick}>
									{item.label}
								</MenuItem>
							))}
						</MenuList>
					</Menu>
				)}
			</HStack>

			{/* calendly modal */}
			<CalendlyModal product={headerContent.calendlyProduct} isOpen={isCalendlyOpen} onClose={onCalendlyClose} />

			{/* cash modal */}
			{openedCER?.bankInformationId && (
				<CashVersementModal
					isOpen={isCashVersementOpen}
					onClose={onCashVersementClose}
					bankAccountId={openedCER.bankInformationId}
				/>
			)}
		</>
	);
};
export default HeaderProductPage;
