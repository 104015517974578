import FileDownload from 'js-file-download';

import { createStandaloneThemedToast } from 'hooks/useThemedToast';
import { backendApi } from 'services/apiService';
import api from 'utils/api';

const toast = createStandaloneThemedToast();

export interface S3Document {
	key: string;
	documentInfos: {
		AcceptRanges: string;
		LastModified: string;
		ContentLength: number;
		ETag: string;
		ContentType: string;
		Metadata: {
			owner: string;
			mimetype: string;
			originalname: string;
			typeIdentity?: string;
			modifieddate?: string;
		};
	};
}

interface DownloadDocumentRequest {
	filepath: string;
	filename: string;
	contentType: string;
}

export const downloadDocument = ({ filepath, filename, contentType }: DownloadDocumentRequest): Promise<void> =>
	api
		.downloadDocuments(filepath, contentType)
		.then((res) => FileDownload(res.data, filename, contentType))
		.catch(() => {
			toast({
				title: 'Une erreur est survenue',
				description: "Le fichier n'a pas pu être téléchargé... \nVeuillez nous contacter pour plus d'informations.",
				status: 'error',
				duration: 4500,
				isClosable: true,
			});
		});

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getDocuments: builder.query<S3Document[], void>({
			query: () => '/documents',
		}),
	}),
});

export const { useGetDocumentsQuery } = extendedApi;
