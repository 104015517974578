import { MainStepType, OnboardingStep } from 'onboarding/Stepper/stepper.slice';

export enum ReOnboardingSteps {
	INTRODUCTION = 'introduction',
	DOCUMENTS = 'documents',
	TEST = 'test',
	CONFIRMATION = 'confirmation',
}

const steps: OnboardingStep<ReOnboardingSteps>[] = [
	{
		id: ReOnboardingSteps.INTRODUCTION,
		name: 'Introduction',
		url: 'introduction',
		type: MainStepType.START,
	},
	{
		id: ReOnboardingSteps.DOCUMENTS,
		name: 'Documents à signer',
		url: 'documents',
		type: MainStepType.COURSE,
	},
	{
		id: ReOnboardingSteps.TEST,
		name: "Test d'adéquation",
		url: 'test',
		type: MainStepType.COURSE,
	},
	{
		id: ReOnboardingSteps.CONFIRMATION,
		name: 'Confirmation',
		url: 'confirmation',
		type: MainStepType.END,
	},
];

export default steps;
