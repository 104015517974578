import { backendApi } from 'services/apiService';
import { PrivateEquitySubscription } from 'store/types/airtable.type';
import { PeIntention } from 'store/types/peKyc.type';

type Stringified<T> = {
	[P in keyof T]: string;
};
export type SubmitPESubscription = Pick<
	PrivateEquitySubscription,
	'fundsOrigin' | 'bankAccountId' | Stringified<'objectives'> | 'fundsOriginGeography'
> & { subscriptionId: string; isAutoFund: boolean; jof: File };

interface GeneratePdfProps {
	routeFundName: string;
	id: string;
}

export interface CapitalEvolution {
	date: Date[];
	value_no_div_no_tax: number[];
	value_no_div_tax?: number[];
}

export interface CashFlowEvolution {
	cashflow: number[];
	cumulative_cashflow: number[];
	dates: Date[];
}

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		// Airtable subscriptions
		getUserPESubscriptions: builder.query<PrivateEquitySubscription[], void>({
			query: () => '/private-equity/subscriptions',
			providesTags: ['PE_SUBSCRIPTIONS'],
		}),
		getUserPESubscriptionById: builder.query<PrivateEquitySubscription, { id: string }>({
			query: (params) => `/private-equity/subscriptions/${params.id}`,
			providesTags: ['PE_SUBSCRIPTIONS'],
		}),
		createPESubscription: builder.mutation<PrivateEquitySubscription, PeIntention>({
			query: (params) => ({
				url: '/private-equity/subscriptions',
				method: 'POST',
				body: params,
			}),
			invalidatesTags: ['PE_SUBSCRIPTIONS'],
		}),
		updatePEIntention: builder.mutation<void, PeIntention & { id: string }>({
			query: ({ id, ...params }) => ({
				url: `/private-equity/subscriptions/${id}`,
				method: 'PUT',
				body: params,
			}),
			invalidatesTags: ['PE_SUBSCRIPTIONS'],
		}),
		deletePESubscription: builder.mutation<void, { id: string }>({
			query: (params) => ({
				url: `/private-equity/subscriptions/${params.id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['PE_SUBSCRIPTIONS'],
		}),
		submitPESubscription: builder.mutation<void, SubmitPESubscription>({
			query: (formData) => ({
				url: `/private-equity/subscriptions/submit`,
				method: 'POST',
				body: formData,
			}),
			invalidatesTags: ['PE_SUBSCRIPTIONS'],
		}),
		generatePESubscriptionPDF: builder.query<{ signLink: string }, GeneratePdfProps>({
			query: ({ id, routeFundName }) => ({
				url: `/pdf-fill/${routeFundName}/${id}`,
			}),
		}),
	}),
});

export const {
	// Airtable subscriptions
	useGetUserPESubscriptionsQuery,
	useGetUserPESubscriptionByIdQuery,
	useCreatePESubscriptionMutation,
	useUpdatePEIntentionMutation,
	useSubmitPESubscriptionMutation,
	useDeletePESubscriptionMutation,

	// PDF generation
	useLazyGeneratePESubscriptionPDFQuery,
} = extendedApi;
