import { Divider, Heading, Text, VStack } from '@chakra-ui/react';

import RegulatoryLoansFormContext from './Sections/Loans';
import PatrimonyPESCPIFormContext from './Sections/Patrimony';
import PersonalPrecisionsKycFormContext from './Sections/PersonalPrecisions';

const RegulatoryInformationFormContext = () => (
	<VStack w="100%" align="start" spacing="32px">
		<Heading variant="Title-XL-Bold">Informations réglementaires</Heading>

		<Text variant="Text-M-Regular" color="grey.900">
			Ces données sont confidentielles et nécessaires vis-à-vis de nos obligations réglementaires et en particulier
			concernant la lutte contre le blanchiment. Elles nous permettent, par ailleurs, de valider la bonne adéquation de
			votre souscription avec votre patrimoine et votre bonne information.
		</Text>

		<PersonalPrecisionsKycFormContext />

		<Divider />

		<PatrimonyPESCPIFormContext />

		<Divider />

		<RegulatoryLoansFormContext />

		<Divider />
	</VStack>
);

export default RegulatoryInformationFormContext;
