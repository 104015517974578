import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { OnboardingStep, setCurrentStep } from 'onboarding/Stepper/stepper.slice';

type UseOnboardingProgressProps = {
	basePath: string;
	steps: OnboardingStep[];
};

const useOnboardingProgress = ({ basePath, steps }: UseOnboardingProgressProps) => {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const [progress, setProgress] = useState(0);
	const { currentStep } = useAppSelector((state) => state.onboarding.stepper);

	useEffect(() => {
		const stepFound = steps.findIndex((step) => step.id === currentStep.id);
		if (stepFound === -1) return setProgress(0);

		const stepShare = 1 / steps.length;

		const progression = stepShare * stepFound * 100;
		const innerSteps = steps[stepFound].innerSteps;
		if (innerSteps) {
			const innerStepFound = innerSteps.findIndex((step) => step.id === currentStep.innerStepId);
			if (innerStepFound === -1) {
				return setProgress(progression);
			}

			const innerStepLength = innerSteps.length - 1;
			const innerStepShare = stepShare / innerStepLength;
			return setProgress(progression + innerStepFound * innerStepShare * 100);
		}
		return setProgress(progression + stepShare * 100);
	}, [currentStep, steps, setProgress]);

	useEffect(() => {
		const step = steps.find((s) => location.pathname.includes(s.url));
		if (step === undefined) return;

		if (location.pathname === `${basePath}/${step.url}` || (step.url === '' && location.pathname === basePath)) {
			dispatch(setCurrentStep({ id: step.id, innerStepId: 'introduction' }));
		} else if (step.innerSteps) {
			step.innerSteps.forEach((innerStep) => {
				// Startwith because some inner steps have internal /:id routes (justificatif for example)
				if (location.pathname.startsWith(`${basePath}/${innerStep.url}`)) {
					dispatch(setCurrentStep({ id: step.id, innerStepId: innerStep.id }));
				}
			});
		}
	}, [basePath, dispatch, location, steps]);

	return { progress, currentStep };
};

export default useOnboardingProgress;
