import { InvestmentPreferences } from 'services/requests/invest/preferences';

import { Envelope, ProjectType } from './global.type';

export enum PeriodicityType {
	MONTHLY = 'MONTHLY',
	QUARTERLY = 'QUARTERLY',
	SEMI_ANNUALLY = 'SEMI_ANNUALLY',
	ANNUALLY = 'ANNUALLY',
}

export enum BeneficiaryType {
	SPOUSE = 'SPOUSE',
	CHILDREN = 'CHILDREN',
	HEIRS = 'HEIRS',
	WRITTEN = 'WRITTEN',
}

export enum FundOriginType {
	PROFESSIONAL_INCOME = 'PROFESSIONAL_INCOME',
	REAL_ESTATE = 'REAL_ESTATE',
	SUCCESSION_DONATION = 'SUCCESSION_DONATION',
	ASSET_SELLOUT = 'ASSET_SELLOUT',
	GOODS_SELLOUT = 'GOODS_SELLOUT',
}

export enum RepresentativeType {
	INDIVIDUAL = 'INDIVIDUAL',
	PROFESSIONAL = 'PROFESSIONAL',
}

export enum SubscriptionStatus {
	CREATED = 'CREATED',
	GENERATED = 'GENERATED',
	SIGNED = 'SIGNED',
	SENT_TO_PARTNER = 'SENT_TO_PARTNER',
	COMPLETED = 'COMPLETED',
	ABANDONED = 'ABANDONED',
}

export enum InsuranceProvider {
	APICIL = 'APICIL',
	GENERALI = 'GENERALI',
}

export type BankInformations = {
	id: string;
	IBAN: string;
	BIC: string;
	urlIBAN: string;
	holder: string;
};

export interface InvestSubscription {
	id: string;
	userId: string;
	envelopeId: string;
	apicilSubscriptionId: string | null;
	createdAt: string;
	updatedAt: string;
	timeHorizon: string | null;
	projectType: ProjectType;
	bankInformationId: string | null;
	extraData: Partial<{ workedYears: number; birthYear: number }> | null;
	status: SubscriptionStatus;
	initialDepositAmount: string;
	recurrentDepositAmount: string;
	recurrentDepositperiod: PeriodicityType;
	beneficiary: BeneficiaryType | null;
	freeBeneficiaryClause: string | null;
	fundOriginType: FundOriginType | null;
	representativeType: RepresentativeType;
	provider: InsuranceProvider;
	hubspotId: string | null;
}

export interface PopulatedInvestSubscription extends InvestSubscription {
	envelope: Envelope;
	investmentPreferences: InvestmentPreferences | null;
}

export type DonneesBancairesForm = {
	BIC: string;
	IBAN: string;
	holder: string;
	ibanUpload: File;
};
