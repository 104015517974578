import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Center, chakra, Heading, Spinner, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { DotWave } from '@uiball/loaders';
import SelectOrAddBankAccountFormContext from 'formContexts/BankAccount/BankAccountSelector';
import FundJustificatifFormContext from 'formContexts/FundJustificatifs';

import CustomModal from 'components/Modal';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { useAppDispatch } from 'hooks/useStore';
import useThemedToast from 'hooks/useThemedToast';
import { backendApi } from 'services/apiService';
import eventTracker from 'services/events/eventTracker';
import { useLazyGenerateSCPISubscriptionPDFQuery } from 'services/requests/realEstate';
import { useGetSCPIFundDemembrementQuery } from 'services/requests/realEstate/funds';
import {
	SubmitSCPISubscription,
	useGetUserSCPISubscriptionByIdQuery,
	useSubmitSCPISubscriptionMutation,
} from 'services/requests/realEstate/subscriptions';
import { automatedFundsSCPIRoute } from 'utils/automatedFundTypes';
import { isNone, isNotNone } from 'utils/functions';
import { displayMoney, displayPercentage } from 'utils/rendering';

type SubmitFormData = Omit<SubmitSCPISubscription, 'id'>;

const Souscription = (): JSX.Element => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { id } = useParams<{ id: string }>();
	const [signLink, setSignLink] = useState<string | null>(null);

	const { data: subscription } = useGetUserSCPISubscriptionByIdQuery({ id: id! });
	const { data: fundDemembrement } = useGetSCPIFundDemembrementQuery(subscription?.fund as string, {
		skip: !subscription,
	});
	const [submitSCPISubscription, { isLoading: isUpdateLoading }] = useSubmitSCPISubscriptionMutation();
	const [generateSubscriptionPdf, { isLoading: isGenerateLoading }] = useLazyGenerateSCPISubscriptionPDFQuery();
	const { isOpen: isSignModalOpen, onOpen: onSignModalOpen, onClose: onSignModalClose } = useDisclosure();

	const methods = useForm<SubmitFormData>();
	const { handleSubmit, setValue } = methods;

	useEffect(() => {
		if (subscription) {
			if (isNotNone(subscription.fundsOrigin)) setValue('fundsOrigin', subscription.fundsOrigin);
			if (isNotNone(subscription.bankAccountId)) setValue('bankAccountId', subscription.bankAccountId);
			if (isNotNone(subscription.objectives)) setValue('objectives', subscription.objectives);
		}
	}, [subscription, setValue]);

	const isFundFoundAutomated = (autoFundList: Record<string, string>, fundName: string): boolean =>
		Object.keys(autoFundList).includes(fundName);

	// add event listener on iframe to know when the user has signed the document
	useEffect(() => {
		const handler = (e: MessageEvent<{ type: string; event: string }>) => {
			if (e.origin === 'https://yousign.app' && e.data.type === 'yousign') {
				if (e.data.event === 'started') {
					console.log('Signer has opened the signature');
				}
				if (e.data.event === 'success') {
					console.log('Signer has successfully signed');
					onSignModalClose();
					setTimeout(() => {
						dispatch(backendApi.util.invalidateTags(['SCPI_SUBSCRIPTIONS']));
					}, 3000);
					setTimeout(() => navigate(`/immobilier/onboarding/${id}/confirmation`), 500);
				}
				if (e.data.event === 'error') {
					console.log('Signer encountered an error when signing');
					toast({
						title: 'Erreur lors de la signature de votre bulletin de souscription',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				}
			}
		};
		window.addEventListener('message', handler);
		return () => window.removeEventListener('message', handler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = handleSubmit(async (data: SubmitFormData) => {
		if (isNone(subscription)) return;

		try {
			const requestFormData = new FormData();

			const isAutoFund = isFundFoundAutomated(automatedFundsSCPIRoute, subscription.fundName);

			requestFormData.append('fundsOrigin', data.fundsOrigin!);
			requestFormData.append('objectives', JSON.stringify(data.objectives));
			requestFormData.append('bankAccountId', data.bankAccountId!);
			requestFormData.append('subscriptionId', id!);
			requestFormData.append('isAutoFund', isAutoFund.toString());
			requestFormData.append('jof', data.jof);
			await submitSCPISubscription(requestFormData as unknown as SubmitSCPISubscription).unwrap();
			eventTracker.pipedream.scpiSubscription(id!);
			if (isAutoFund) {
				onSignModalOpen();
				const sub = await generateSubscriptionPdf({
					routeFundName: automatedFundsSCPIRoute[subscription.fundName],
					id: id!,
				}).unwrap();
				new URL(sub.signLink); // check if received URL is valid
				setSignLink(sub.signLink);
				eventTracker.pipedream.bulletinGeneration(true, 'scpi', subscription.fundName);
			} else navigate(`/immobilier/onboarding/${id}/confirmation`);
		} catch (e) {
			onSignModalClose();
			console.log(e);
			eventTracker.pipedream.bulletinGeneration(false, 'scpi', subscription.fundName, JSON.stringify(e));
			toast({
				title: 'Une erreur est survenue',
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
		}
	});

	useEffect(() => {
		eventTracker.mixpanel.track('SCPI Onboarding - Souscription');
	}, []);

	if (!subscription) return <Spinner />;

	return (
		<chakra.form onSubmit={onSubmit}>
			<VStack align="start" spacing="40px">
				<Heading variant="Title-XL-Bold">Souscription</Heading>

				<Card w="100%">
					<VStack w="100%" align="start" spacing="24px">
						<Heading variant="Title-S-Bold">Récapitulatif de mon projet</Heading>
						<Text variant="Text-M-Regular">
							Vous allez acheter{' '}
							<b>
								{subscription.nbShare} part{subscription.nbShare > 1 && 's'}
							</b>{' '}
							de la SCPI <b>{subscription.fundName}</b> pour un montant total de{' '}
							<b>{displayMoney(subscription.amountVersement)}</b>
							.
							<br />
							Cet investissement sera fait en{' '}
							<b>{subscription.projectType === 'NUE_PROPRIETE' ? 'nue-propriété' : 'pleine propriété'}</b>
							{subscription.projectType === 'NUE_PROPRIETE' && subscription.demembrementDuration && (
								<>
									, avec un <b>démembrement sur {subscription.demembrementDuration} ans</b>
									{fundDemembrement && (
										<>
											{' '}
											et une{' '}
											<b>
												décôte de{' '}
												{displayPercentage(1 - fundDemembrement[subscription.demembrementDuration.toString()])}
											</b>
										</>
									)}
								</>
							)}
							.
						</Text>
						<Button size="lg" variant="secondary" onClick={() => navigate('../projet')}>
							Modifier mon projet
						</Button>
					</VStack>
				</Card>

				<FormProvider {...methods}>
					<FundJustificatifFormContext includeFundsOriginGeography={false} />
					<SelectOrAddBankAccountFormContext />
				</FormProvider>
			</VStack>

			<CustomModal
				isOpen={isSignModalOpen}
				onClose={onSignModalClose}
				size={{ base: 'full', md: '6xl' }} // TODO handle size on mobile
				contentProps={{ style: { display: 'flex', height: 900 } }}
			>
				{(isUpdateLoading || isGenerateLoading) && (
					<Center w="100%" h="100%" flexDirection="column">
						<Text mb="24px" fontSize="16px">
							Document(s) en cours de génération
						</Text>
						<DotWave size={70} />
					</Center>
				)}
				{signLink && (
					<chakra.iframe
						flexGrow={1}
						border="none"
						margin={0}
						padding="20px 20px 0px 0px"
						// disable_domain_validation=true is ignored in production, only prupose is dev/staging
						src={`${signLink}&disable_domain_validation=true`}
					/>
				)}
			</CustomModal>

			<BottomStepBar
				showPrev={false}
				textNext={
					isFundFoundAutomated(automatedFundsSCPIRoute, subscription.fundName)
						? 'Signer mes documents'
						: 'Confirmer la souscription'
				}
				nextSubmit
				isNextLoading={isUpdateLoading || isGenerateLoading}
			/>
		</chakra.form>
	);
};

export default Souscription;
