import { Heading, StackDivider, VStack } from '@chakra-ui/react';

import VersementCard from 'components/versements/VersementCard';
import VersementHistoryEmpty from 'components/versements/VersementHistoryEmpty';
import VersementHistoryLoading from 'components/versements/VersementHistoryLoading';
import { FetchVersementsQueryResponse, MouvementType, useGetAllVersementsQuery } from 'services/requests/mouvements';
import { RachatType, VersementType } from 'store/types/transaction.type';

const transactionsTypeToLabel: Record<VersementType | RachatType, string> = {
	VEP: 'Versement programmé',
	VLC: 'Versement',
	OUV: 'Versement initial',
	ENT: 'Transfert',
	RAP: 'Rachat partiel',
	RAT: 'Rachat total',
	RPP: 'Rachat partiel programmé',
};

const groupVersementsByYear = (versements: FetchVersementsQueryResponse) =>
	versements.reduce((acc, curr) => {
		const dateOperationComputed = new Date(curr.dateOperation);
		const key = dateOperationComputed.toLocaleDateString('fr-FR', { year: 'numeric' });
		return { ...acc, [key]: (acc[key] ?? []).concat({ ...curr, dateOperationComputed }) };
	}, {} as Record<string, (MouvementType & { dateOperationComputed: Date })[]>);

const VersementHistory = (): JSX.Element => {
	const { data: listVersements, isLoading } = useGetAllVersementsQuery();

	if (isLoading) return <VersementHistoryLoading />;

	// Empty state
	if (!listVersements || listVersements.length === 0) return <VersementHistoryEmpty />;

	const groupedVersementsByYear = groupVersementsByYear(listVersements);

	return (
		<VStack spacing="24px" w="100%">
			{Object.entries(groupedVersementsByYear)
				.sort(([year1], [year2]) => +year2 - +year1)
				.map(([date, versementList]) => (
					<VStack w="100%" align="start" key={date} spacing="24px">
						<Heading variant="Title-M-Bold">{date}</Heading>
						<VStack spacing="16px" w="100%" divider={<StackDivider borderColor="grey.500" />}>
							{versementList
								.sort((a, b) => -1 /* reverse order */ * a.dateOperation.localeCompare(b.dateOperation))
								.map((versement, idx) => (
									<VersementCard
										key={idx}
										dateExecution={versement.dateOperationComputed}
										label={transactionsTypeToLabel[versement.typeVersement]}
										amount={versement.montantOperation}
										typeEnvelope={versement.envelopeType}
										status="EXECUTED"
										subOperation={versement.subOperation}
									/>
								))}
						</VStack>
					</VStack>
				))}
		</VStack>
	);
};

export default VersementHistory;
