import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon, useDisclosure } from '@chakra-ui/react';

import { AccountIcon, EditContract, Fiscalite, OverviewIcon, ParrainageIcon } from 'assets/icons/NavBarLinksIcons';
import TopBar from 'components/navigation/Navbar/TopBar';
import SideBar from 'components/SideBar';
import { useAppResponsive } from 'hooks/useAppResponsive';
import useSubscriptionList from 'hooks/useSubscriptionList';
import isProposalSubscription from 'pages/Subscriptions/features/isPropalSubscription';
import { useGetCorporateAttributionsQuery } from 'services/requests/corporate';
import { useGetProductPerAVOpenQuery } from 'services/requests/product';
import { useGetLatestProposalQuery } from 'services/requests/proposal';
import PeriodeDeclaration from 'utils/periodeDeclaration';
import { RenderFromProjectType } from 'utils/rendering';

import { NavBarMenuLinksItemProps, NavBarMenuProductsItemProps } from './NavBar.type';

import 'theme/animation.css';

const NavBar = (): JSX.Element => {
	const { data: investProductStatus } = useGetProductPerAVOpenQuery();
	const { subscriptionList, isLoading } = useSubscriptionList();
	const navigate = useNavigate();
	const location = useLocation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data: proposal } = useGetLatestProposalQuery();
	const { data: corpoUser } = useGetCorporateAttributionsQuery();
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;
	const isInvestOpen = investProductStatus?.isAvOpen || investProductStatus?.isPerOpen;

	const proposalSubscriptions = useMemo(
		() => subscriptionList.filter((sub) => isProposalSubscription(proposal, sub.id)),
		[proposal, subscriptionList],
	);

	const isLinkActive = (link: string): boolean => {
		if (link === '/') return location.pathname === link;
		if (link === '/souscriptions/propositions') return location.pathname.startsWith('/souscriptions');
		return location.pathname.startsWith(link);
	};

	const DisplayPeriodeDeclaration = useMemo(() => PeriodeDeclaration(), []);

	const menuNav = [
		{
			label: 'Accueil',
			icon: <Icon boxSize="24px" as={OverviewIcon} />,
			link: '/',
		},
		{
			label: 'Ramify Black',
			icon: <Icon boxSize="24px" as={OverviewIcon} />,
			link: '/offre-black',
		},
		// Souscription
		...(!isLoading && subscriptionList.length > 0
			? [
					{
						label: 'Souscriptions',
						icon: <Icon boxSize="24px" as={EditContract} />,
						link: proposalSubscriptions.length > 0 ? '/souscriptions/propositions' : '/souscriptions',
						badgeNumber: subscriptionList.length,
					},
			  ]
			: []),
		{
			label: 'Fiscalité',
			icon: <Icon boxSize="24px" as={Fiscalite} />,
			link: '/fiscalite',
			...(DisplayPeriodeDeclaration && {
				badgeLabel: 'DECLARATION',
			}),
		},
		{
			label: 'Parrainage',
			icon: <Icon boxSize="24px" as={ParrainageIcon} />,
			link: '/parrainage',
		},
		{
			label: 'Compte',
			icon: <Icon boxSize="24px" as={AccountIcon} />,
			link: '/compte',
		},
	].map<NavBarMenuLinksItemProps>(({ link, ...rest }) => ({
		active: isLinkActive(link),
		onClick: () => {
			if (link) navigate(link);
		},
		...rest,
	}));

	const menuProducts: NavBarMenuProductsItemProps[] = [
		{
			key: 'INVEST',
			active: location.pathname.startsWith('/invest'),
			onClick: () => (isInvestOpen ? navigate('/invest/dashboard') : navigate('/invest')),
		},
		{
			key: 'IMMO',
			active: location.pathname.startsWith('/immobilier'),
			onClick: () => navigate('/immobilier'),
		},
		{
			key: 'PE',
			active: location.pathname.startsWith('/private-equity'),
			onClick: () => navigate('/private-equity'),
		},
		...(corpoUser && Object.keys(corpoUser).length > 0
			? [
					{
						key: 'BSPCE' as const,
						active: location.pathname.startsWith('/bspce'),
						onClick: () => navigate('/bspce'),
					},
			  ]
			: []),
		{
			key: 'CASH',
			active: location.pathname.startsWith('/cash'),
			onClick: () => navigate('/cash/dashboard'),
			badgeLabel: 'NOUVEAUTÉ',
		},
	];
	if (isMobile) {
		const extraTitles = [
			{
				label: 'Assurance vie',
				active: location.pathname.match(new RegExp('ouverture-assurance-vie', 'g')),
			},
			{
				label: 'PER individuel',
				active: location.pathname.match(new RegExp('ouverture-per-individuel', 'g')),
			},
		];
		const active = [...extraTitles, ...menuProducts, ...menuNav].find((e) => e.active);
		const isProject = active && 'key' in active;
		const render = isProject ? RenderFromProjectType(active.key) : active;
		return (
			<TopBar pageTitle={render} showPageIcon={!!isProject} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
				<SideBar onClose={onClose} />
			</TopBar>
		);
	}
	return <SideBar onClose={onClose} />;
};

export default NavBar;
