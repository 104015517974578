import { useEffect, useState } from 'react';
import { Button, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import Immo from 'assets/products/Immo.svg';
import Pe from 'assets/products/Pe.svg';
import Modal from 'components/Modal';
import eventTracker from 'services/events/eventTracker';

type ConfirmModalProps = {
	onConfirm: () => void;
	onCancel: () => void;
	onSuccess: () => void;
	isOpen: boolean;
	isSuccess: boolean;
	isLoading: boolean;
	type: string;
};

export function SubscriptionConfirmModal({
	onConfirm,
	onCancel,
	onSuccess,
	isOpen,
	isSuccess,
	isLoading,
	type,
}: ConfirmModalProps) {
	const [index, setIndex] = useState(0);
	const [texts, setTexts] = useState<Array<string>>([]);

	useEffect(() => {
		const waitingTexts = [
			'Nous préparons votre souscription...',
			'Cela peut prendre quelques secondes...',
			'Ramify simplifie vos démarches...',
		];

		const interval = setInterval(() => {
			setIndex((idx) => idx + 1);
			setTexts([...texts, waitingTexts[index]]);
		}, 3500);
		return () => clearInterval(interval);
	}, [index, texts]);

	useEffect(() => {
		if (isSuccess) {
			onSuccess();
		}
	}, [isSuccess, onSuccess]);

	return (
		<Modal isOpen={isOpen} onClose={onCancel}>
			<VStack w="100%" align="center" p="32px 32px" spacing="32px">
				<VStack align="center" w="100%" spacing="16px">
					<Image src={type === 'SCPI' ? Immo : Pe} alt="maison" w="40px" h="40px" />
					<Heading variant="Title-M-SemiBold">Souscriptions {type}</Heading>
				</VStack>
				<Text textAlign="center">
					Nous aurons besoin de quelques informations pour finaliser vos souscriptions, les montants de vos
					souscriptions seront modifiables à tout moment du parcours de souscription.
				</Text>

				<HStack w="100%" justifyContent="center">
					<Button variant="secondary" onClick={onCancel} isDisabled={isLoading}>
						Annnuler
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							eventTracker.mixpanel.confirmModalInvestisement(type as 'SCPI' | 'PE');
							onConfirm();
						}}
						isLoading={isLoading}
					>
						Confirmer
					</Button>
				</HStack>
				{isLoading && (
					<VStack>
						{texts.map((text) => (
							<motion.div
								key={text}
								initial={{ opacity: 0, scale: 0.8 }}
								animate={{ opacity: 1, scale: 1 }}
								transition={{
									duration: 0.8,
									delay: 0.5,
									ease: [0, 0.71, 0.2, 1.01],
								}}
							>
								<Text key={text}>{text}</Text>
							</motion.div>
						))}
					</VStack>
				)}
			</VStack>
		</Modal>
	);
}
