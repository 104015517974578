import { Box, HStack, Text, VStack } from '@chakra-ui/react';

import PieRepartition from 'components/charts/Pie';
import { EnvelopeType } from 'store/types/global.type';
import { displayMoney } from 'utils/rendering';

// An envelope visible will be visible in both the graph and the list
// An envelope blurred will not be shown in the graph but will be visible blurred in the list
// An envelope hidden isn't shown anywhere
type EnvelopeVisibility = 'hidden' | 'blurred' | 'visible';
type RepartitionVPProps = {
	amountAV?: number;
	amountPER?: number;

	statusAV?: EnvelopeVisibility;
	statusPER?: EnvelopeVisibility;
};

type Repartition = {
	id: EnvelopeType;
	color: string;
	label: string;
	value: number;
	blurred?: boolean;
};

const formatBasicRepartition = (amountAV: number, amountPER: number): Repartition[] => [
	{ id: 'AV', color: '#FF7B79', label: 'Assurance Vie', value: amountAV },
	{ id: 'PER', color: '#FBD462', label: 'PER individuel', value: amountPER },
];

const formatPieChart = ({ amountAV, amountPER, statusAV, statusPER }: RepartitionVPProps): Repartition[] => {
	let basicRepartition = formatBasicRepartition(amountAV!, amountPER!);

	// If envelope isn't fully visible remote it from pieChart
	if (statusAV !== 'visible') basicRepartition = basicRepartition.filter((r) => r.id !== 'AV');
	if (statusPER !== 'visible') basicRepartition = basicRepartition.filter((r) => r.id !== 'PER');

	return basicRepartition;
};

const formatBoxes = ({ amountAV, amountPER, statusAV, statusPER }: RepartitionVPProps): Repartition[] => {
	let basicRepartition = formatBasicRepartition(amountAV!, amountPER!);

	// If envelope is unavailable for the project, remove it from the list
	if (statusAV === 'hidden') basicRepartition = basicRepartition.filter((r) => r.id !== 'AV');
	if (statusPER === 'hidden') basicRepartition = basicRepartition.filter((r) => r.id !== 'PER');

	// If an envelope is not openned yet, it appears blurred
	if (statusAV === 'blurred')
		basicRepartition = basicRepartition.map((r) => (r.id === 'AV' ? { ...r, blurred: true } : r));
	if (statusPER === 'blurred')
		basicRepartition = basicRepartition.map((r) => (r.id === 'PER' ? { ...r, blurred: true } : r));
	return basicRepartition;
};

const emptyPieChart = () => [
	{ id: 'EMPTY_GRAPH', color: '#F7F7F7', label: 'Aucun versement programmé actif.', value: 0.001 },
];

const RepartitionVP = ({
	amountAV = 0,
	amountPER = 0,
	statusAV = 'hidden',
	statusPER = 'hidden',
}: RepartitionVPProps): JSX.Element => {
	const repartitionVpParams = { amountAV, amountPER, statusAV, statusPER };
	const repartitionGraph = formatPieChart(repartitionVpParams);
	const repartitionBoxes = formatBoxes(repartitionVpParams);

	const total = repartitionGraph.map(({ value: v }) => v).reduce((l, r) => l + r, 0);
	const percentage = (v: number) => (total === 0 ? '0' : ((100 * v) / total).toFixed(1));

	return (
		<HStack wrap="wrap" align="start" maxW="100%" gap="32px" w="100%">
			<VStack spacing="24px">
				<Box h="120px" w="124px" position="relative">
					<PieRepartition
						data={total > 0 ? repartitionGraph : emptyPieChart()}
						isInteractive={false}
						innerRadius={0.83}
					/>
					<VStack position="absolute" w="100%" top="50%" transform="translateY(-50%)" spacing={0} align="center">
						<Text variant="Title-S-SemiBold">{displayMoney(total)}</Text>
						<Text>par mois</Text>
					</VStack>
				</Box>
			</VStack>

			<VStack align="start" spacing="0px" flex="1" maxW="100%">
				<HStack wrap="wrap" spacing="0" gap="16px" w="100%">
					{repartitionBoxes.map((account) => (
						<VStack key={account.id} spacing="8px" align="start" w="100%" maxW="196px">
							<HStack>
								<Box w="16px" h="16px" bg={account.blurred ? 'grey.300' : account.color} borderRadius="base" />
								<Text variant="label">{account.label}</Text>
							</HStack>
							<HStack spacing="8px" align="baseline" opacity={account.blurred ? 0.56 : 1}>
								<Text variant="label">{displayMoney(account.value)}</Text>
								<Text variant="detail" color="grey.900">
									{account.blurred ? 'Recommandé' : `(${percentage(account.value)}%)`}
								</Text>
								<Text variant="detail">par mois</Text>
							</HStack>
						</VStack>
					))}
				</HStack>
			</VStack>
		</HStack>
	);
};

export type { RepartitionVPProps };
export default RepartitionVP;
