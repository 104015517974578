import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, SimpleGrid, Skeleton, VStack } from '@chakra-ui/react';

import RamifyCircle from 'assets/icons/ramify-circle.svg';
import EmptyState from 'assets/scpiEmptyState.png';
import BannerVerticalCard from 'components/cards/BannerVerticalCard';
import PrivateEquityCard from 'components/cards/PrivateEquityCard';
import FundRequest from 'components/fundRequest';
import { useAppSelector } from 'hooks/useStore';
import FilterBar from 'pages/PrivateEquity/Discover/FilterBar';
import { peSortOption } from 'pages/PrivateEquity/Discover/options.constants';
import eventTracker from 'services/events/eventTracker';
import { useGetPEFundsQuery } from 'services/requests/privateEquity/funds';
import { PEFund } from 'store/types/airtable.type';
import {
	filterByCategory,
	filterByName,
	filterByOpenStatus,
	filterByTaxAdvantages,
	filterByTicketMinimum,
} from 'utils/fundFilters';

const Catalog = () => {
	const { data: funds, isLoading: isLoadingFunds } = useGetPEFundsQuery();
	const navigate = useNavigate();
	const [filteredFunds, setFilteredFunds] = useState<PEFund[] | undefined>(funds);
	const filter = useAppSelector((state) => state.peFilters);

	useEffect(() => {
		setFilteredFunds(funds);
	}, [funds]);

	const sortFunds = (newFilteredFunds: PEFund[] | undefined, sortingchoice: string) => {
		if (sortingchoice === '') return newFilteredFunds;
		return newFilteredFunds?.sort(peSortOption[sortingchoice].fnct);
	};

	useEffect(() => {
		if (!funds) return;
		let newFilteredFunds = filterByName(funds, filter.name);
		newFilteredFunds = filterByTicketMinimum(newFilteredFunds, filter);
		newFilteredFunds = filterByTaxAdvantages(newFilteredFunds, filter);
		newFilteredFunds = filterByCategory('sectors', 'sectors', newFilteredFunds, filter);
		newFilteredFunds = filterByCategory('strategies', 'strategy', newFilteredFunds, filter);
		newFilteredFunds = filterByCategory('geographies', 'regions', newFilteredFunds, filter);
		newFilteredFunds = filterByOpenStatus('status', 'fundOpen', newFilteredFunds, filter);
		newFilteredFunds = filterByCategory('type', 'FundType', newFilteredFunds, filter);
		setFilteredFunds(sortFunds(newFilteredFunds, filter.sorting));
	}, [filter, funds]);

	return (
		<VStack id="nos-offres" w="100%" align="start" spacing="16px">
			<FilterBar funds={funds} filteredCounts={filteredFunds?.length ?? 0} />

			<Skeleton isLoaded={!isLoadingFunds} w="100%" minH={isLoadingFunds ? '140px' : '0px'}>
				{filteredFunds &&
					(filteredFunds.length === 0 ? (
						<Image src={EmptyState} w="580px" align="center" m="auto" />
					) : (
						<SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="16px">
							{filteredFunds.map((fund, i) => (
								<Fragment key={fund.id}>
									<PrivateEquityCard fund={fund} />

									{i === Math.min(3, filteredFunds.length - 1) && (
										<BannerVerticalCard
											image={RamifyCircle}
											title="ACCOMPAGNEMENT"
											subtitle="Envie d'être accompagné dans votre stratégie d'investissement ?"
											description="Essayez le moteur de recommandation Ramify"
											btnText="Faire une simulation"
											btnCallback={() => {
												eventTracker.mixpanel.track('SimulationPE-CTA');
												navigate('/private-equity/simulation/nouvelle');
											}}
										/>
									)}
								</Fragment>
							))}
						</SimpleGrid>
					))}
			</Skeleton>

			<FundRequest type="PE" />
		</VStack>
	);
};

export default Catalog;
