import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	SimpleGrid,
	useToast,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import AsynchronousSelect from 'components/inputs/AsynchronousSelect';
// Components
import DatePicker from 'components/inputs/DatePicker';
import ReferentielInput from 'components/inputs/ReferentielInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import { KycProduct } from 'onboarding/KYC';
import { useGetSubscriptionQuery } from 'services/requests/invest/subscription';
import { useGetKYCQuery, useUpdateKYCEtatCivilMutation } from 'services/requests/kyc';
// Referentiels V2
import CivilStatusReferentiel from 'store/referentiels/civilStatus';
import FamilySituationReferentiel, { FamilySituation } from 'store/referentiels/familySituation';
import MatrimonialRegimeReferentiel from 'store/referentiels/matrimonial';
import CitizenshipReferentiel from 'store/referentiels/pays/ciziten';
import BirthCountryReferentiel from 'store/referentiels/pays/naissance';
import { EtatCivilType } from 'store/types/KYC.type';
import { cityOptionsLoader } from 'utils/functions';

import { KYCFormProps } from './type';

const ageFromDateOfBirthday = (dateOfBirth: string): number => {
	const today = new Date();
	const birthDate = new Date(dateOfBirth);
	let age = today.getFullYear() - birthDate.getFullYear();
	const m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
};

type EtatCivilFormType = EtatCivilType & {
	villeNaissanceHorsFrance: string;
};

const EtatCivilForm = ({
	onSuccess,
	formConfirmation,
	product,
}: KYCFormProps & { product: KycProduct }): JSX.Element => {
	const toast = useToast();
	const [triggerUpdateEtatCivil] = useUpdateKYCEtatCivilMutation();
	const { data: kycData, isSuccess } = useGetKYCQuery();
	const [limitAgeError, setLimitAgeError] = useState(false);

	const { id } = useParams<{ id: string }>();
	const { data: subscription } = useGetSubscriptionQuery({ subscriptionId: id! }, { skip: product !== 'invest' });

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		watch,
		setError,
		setValue,
	} = useForm<EtatCivilFormType>({
		shouldUnregister: true,
		defaultValues: {
			nationalite: 'FR',
			pays: 'FR',
		},
	});

	const [paysNaissance, dateNaissance, situationFamiliale] = watch(['pays', 'dateNaissance', 'situationFamiliale']);
	const isMarried = useMemo(
		() => situationFamiliale === FamilySituation.MARRIED || situationFamiliale === FamilySituation.CIVIL_UNION,
		[situationFamiliale],
	);

	useEffect(() => {
		if (isSuccess && kycData) {
			if (kycData.lastName) setValue('nom', kycData.lastName);
			if (kycData.firstName) setValue('prenom', kycData.firstName);
			if (kycData.birthDate)
				setValue('dateNaissance', kycData.birthDate.toString().split('T')[0].split('-').reverse().join('/'));
			if (kycData.familySituation) setValue('situationFamiliale', kycData.familySituation);
			if (kycData.matrimonialRegime) setValue('regimeMatrimonial', kycData.matrimonialRegime);
			if (kycData.civilStatus) setValue('civilite', kycData.civilStatus);
			if (kycData.citizenship) setValue('nationalite', kycData.citizenship);
			if (kycData.birthCountry) setValue('pays', kycData.birthCountry.toString());
			if (kycData.birthCity && kycData.birthCityCode) {
				setValue('villeNaissance', { libelle: kycData.birthCity.toString(), code: kycData.birthCityCode });
				setValue('villeNaissanceHorsFrance', kycData.birthCity.toString());
			}
			if (kycData.birthName) setValue('nomNaissance', kycData.birthName);
		}
	}, [kycData, isSuccess, setValue]);

	useEffect(() => {
		if (subscription?.investmentPreferences?.portfolioType === 'ESSENTIAL' || !dateNaissance) return;
		setLimitAgeError(ageFromDateOfBirthday(dateNaissance) >= 80);
	}, [dateNaissance, subscription]);

	const onSubmit = handleSubmit((data: EtatCivilFormType) => {
		const birthday = data.dateNaissance.split('/').reverse().join('-');
		const dateValue = Date.parse(birthday);
		if (limitAgeError) {
			toast({
				title:
					"Il est impossible de souscrire à la stratégie ”Flagship” pour les personnes de plus de 80 ans. Pour finaliser votre souscription, veuillez choisir l’offre ”Essential” à l'étape ”Portefeuille d’investissement”.",
				status: 'error',
				duration: 6000,
				isClosable: true,
			});
		} else {
			if (!dateValue || dateValue > Date.parse(Date())) setError('dateNaissance', { type: 'pattern' });
			else {
				const { villeNaissanceHorsFrance, ...etatCivilData } = data;
				const finalData: EtatCivilType = {
					...etatCivilData,
					dateNaissance: birthday,
					villeNaissance:
						data.pays === 'FR' ? data.villeNaissance : { code: '99999', libelle: villeNaissanceHorsFrance },
				};
				triggerUpdateEtatCivil(finalData)
					.unwrap()
					.then(() => onSuccess())
					.catch((err: Error) => {
						toast({
							title: 'Une erreur est survenue',
							status: 'error',
							duration: 9000,
							isClosable: true,
						});
						console.error(err);
					});
			}
		}
	});

	return (
		<form onSubmit={onSubmit}>
			<VStack spacing="20px" align="start" w="100%">
				<FormControl w={onboardingInputWidth} isInvalid={!!errors.civilite}>
					<FormLabel>Civilité</FormLabel>
					<ReferentielInput
						name="civilite"
						referentiel={CivilStatusReferentiel}
						register={register}
						data-cy="kyc-civilite"
						options={{ required: true }}
					/>
					{errors.civilite && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
				</FormControl>

				<FormControl w={onboardingInputWidth} isInvalid={!!errors.prenom}>
					<FormLabel>Prénom</FormLabel>
					<Input
						data-cy="kyc-firstname"
						placeholder="ex : Jean"
						autoComplete="given-name"
						{...register('prenom', { required: true, maxLength: 50 })}
					/>
					{errors.prenom?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					{errors.prenom?.type === 'maxLength' && (
						<FormErrorMessage>Votre prénom doit faire moins de 50 caractères</FormErrorMessage>
					)}
				</FormControl>

				{/* <SimpleGrid columns={{ base: 1, md: 2 }} spacingX="24px" spacingY="20px"> */}
				<Wrap spacingX="24px" spacingY="20px">
					<WrapItem w={onboardingInputWidth}>
						<FormControl isInvalid={!!errors.nom}>
							<FormLabel>Nom de famille</FormLabel>
							<Input
								data-cy="kyc-lastname"
								placeholder="ex : Dupont"
								autoComplete="family-name"
								{...register('nom', { required: true })}
							/>
							{errors.nom?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
						</FormControl>
					</WrapItem>
					<WrapItem w={onboardingInputWidth}>
						<FormControl isInvalid={!!errors.nomNaissance}>
							<FormLabel>Nom de Naissance (optionnel)</FormLabel>
							<Input placeholder="ex : Alain" {...register('nomNaissance', { required: false })} />
							{errors.nomNaissance?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
						</FormControl>
					</WrapItem>
				</Wrap>
				{/* </SimpleGrid> */}

				<SimpleGrid columns={1} spacingX="80px" spacingY="20px">
					<FormControl w={onboardingInputWidth} isInvalid={!!errors.dateNaissance}>
						<FormLabel>Date de naissance</FormLabel>
						<DatePicker
							data-cy="kyc-birthday"
							autoComplete="bday"
							{...register('dateNaissance', {
								required: true,
								pattern: /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]((19|20))\d\d$/,
							})}
						/>
						{errors.dateNaissance?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
						{errors.dateNaissance?.type === 'pattern' && (
							<FormErrorMessage>Cette date de naissance n'est pas valide</FormErrorMessage>
						)}
					</FormControl>

					<SimpleGrid columns={1} spacingX="80px" spacingY="20px">
						<FormControl w={onboardingInputWidth} isInvalid={!!errors.situationFamiliale}>
							<FormLabel>Situation familiale</FormLabel>
							<ReferentielInput
								name="situationFamiliale"
								referentiel={FamilySituationReferentiel}
								register={register}
								data-cy="kyc-situationFamiliale"
								options={{ required: true }}
							/>
							{errors.situationFamiliale && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
						</FormControl>

						{situationFamiliale && isMarried && (
							<FormControl w={onboardingInputWidth} isInvalid={!!errors.regimeMatrimonial}>
								<FormLabel>Régime matrimonial</FormLabel>
								<ReferentielInput
									name="regimeMatrimonial"
									referentiel={MatrimonialRegimeReferentiel}
									register={register}
									data-cy="kyc-regimeMatrimonial"
									options={{ required: true }}
								/>
								{errors.regimeMatrimonial && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
							</FormControl>
						)}
					</SimpleGrid>

					<FormControl w={onboardingInputWidth} isInvalid={!!errors.nationalite}>
						<FormLabel>Nationalité</FormLabel>
						<ReferentielInput
							name="nationalite"
							referentiel={CitizenshipReferentiel}
							register={register}
							data-cy="kyc-nationalite"
							options={{ required: true }}
						/>
						{errors.nationalite && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					</FormControl>

					<FormControl w={onboardingInputWidth} isInvalid={!!errors.pays}>
						<FormLabel>Pays de naissance</FormLabel>
						<ReferentielInput
							name="pays"
							referentiel={BirthCountryReferentiel}
							register={register}
							data-cy="kyc-birthCountry"
							options={{ required: true }}
						/>
						{errors.pays && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					</FormControl>

					{paysNaissance === 'FR' ? (
						<FormControl w={onboardingInputWidth} isInvalid={!!errors.villeNaissance}>
							<FormLabel>Ville de naissance </FormLabel>
							<AsynchronousSelect
								cy="kyc-villeNaissance"
								name="villeNaissance"
								placeholder="-"
								loader={cityOptionsLoader}
								control={control}
								rules={{ required: true }}
							/>
							{errors.villeNaissance && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
						</FormControl>
					) : (
						<FormControl w={onboardingInputWidth} isInvalid={!!errors.villeNaissanceHorsFrance}>
							<FormLabel>Ville de naissance</FormLabel>
							<Input
								data-cy="kyc-villeNaissance"
								placeholder="..."
								{...register('villeNaissanceHorsFrance', { required: true })}
							/>
							{errors.villeNaissanceHorsFrance && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
						</FormControl>
					)}
				</SimpleGrid>
				{formConfirmation}
			</VStack>
		</form>
	);
};

export default EtatCivilForm;
