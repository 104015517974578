import { HStack, Tooltip } from '@chakra-ui/react';
import { Ring } from '@uiball/loaders';

import { ArrowButton } from 'components/Button/Button';
import colors from 'theme/foundations/colors';

const BottomStepBar = ({
	onPrev,
	onNext,
	show = true,
	showNext = true,
	showPrev = true,
	showTooltip = false,
	isNextLoading,
	isNextDisabled,
	textNext = 'Suivant',
	textPrev = 'Précédent',
	nextSubmit = false,
	tooltip = 'Vos contrats sont en cours de traitement.',
}: {
	onNext?: () => void;
	onPrev?: () => void;
	show?: boolean;
	isNextLoading?: boolean;
	isNextDisabled?: boolean;
	showNext?: boolean;
	showPrev?: boolean;
	showTooltip?: boolean;
	textNext?: string;
	textPrev?: string;
	nextSubmit?: boolean;
	tooltip?: string;
}): JSX.Element => (
	<HStack
		position="fixed"
		left="0px"
		bottom={show ? '0px' : '-96px'}
		w="100%"
		h="80px"
		justify={showPrev ? 'space-between' : 'flex-end'}
		bg="white"
		transition="bottom 0.3s"
		transition-timing-function="ease-in"
		borderTop="1px solid rgba(48, 48, 48, 0.12)"
		px={{ base: '16px', md: '24px' }}
		zIndex="3"
	>
		{showPrev && (
			<ArrowButton size="lg" variant="secondary" onClick={onPrev} isDisabled={!showPrev} left>
				{textPrev}
			</ArrowButton>
		)}
		{showNext && !showTooltip && (
			<ArrowButton
				variant="primary"
				size="lg"
				onClick={onNext}
				data-cy="cy-next-btn"
				isDisabled={!showNext || isNextLoading || isNextDisabled}
				type={nextSubmit ? 'submit' : 'button'}
			>
				{isNextLoading ? <Ring size={20} color={colors.grey[700]} /> : textNext}
			</ArrowButton>
		)}

		{showTooltip && showNext && (
			<Tooltip bg="primary.black" padding="12px 16px" hasArrow shouldWrapChildren label={tooltip}>
				<ArrowButton
					variant="primary"
					size="lg"
					onClick={onNext}
					data-cy="cy-next-btn"
					isDisabled={!showNext || isNextLoading || isNextDisabled}
					type={nextSubmit ? 'submit' : 'button'}
				>
					{isNextLoading ? <Ring size={20} color={colors.grey[700]} /> : textNext}
				</ArrowButton>
			</Tooltip>
		)}
	</HStack>
);

export default BottomStepBar;
