import { Button, Text, useDisclosure, VStack } from '@chakra-ui/react';

import { CalendlyModalBlack } from '../Modal';

const BannerOffreBlack = (): JSX.Element => {
	const { isOpen: isCalendlyOpen, onClose: onCalendlyClose, onOpen: onCalendlyOpen } = useDisclosure();

	return (
		<VStack w="100%" align="center" spacing={{ base: '34px', xl: '250px' }}>
			<CalendlyModalBlack isOpen={isCalendlyOpen} onClose={onCalendlyClose} />

			<VStack w="100%" align="center" spacing="8px">
				<Text variant="Title-XL-SemiBold" color="white" textAlign="center">
					Expérimentez le meilleur du conseil financier
				</Text>
				<Text variant="Title-S-Regular" color="white">
					Á partir de 100 000 €
				</Text>
			</VStack>
			<Button variant="super" size="xl" onClick={onCalendlyOpen}>
				Devenir membre
			</Button>
		</VStack>
	);
};
export default BannerOffreBlack;
