import { isReglementaryInformationCompleted } from 'onboarding/PrivateEquity/Reglementaire/utils';
import { KYC, PeScpiKyc } from 'store/types/KYC.type';
import { UserScpiKyc, UserScpiKycTest } from 'store/types/scpiKyc.type';
import { isNotNone } from 'utils/functions';

export const isReProductInformationCompleted = (scpiKyc: UserScpiKyc | undefined) =>
	!!scpiKyc &&
	isNotNone(scpiKyc.hasIFI) &&
	(scpiKyc.hasIFI ? isNotNone(scpiKyc.taxIFIAmount) : true) &&
	isNotNone(scpiKyc.immoRevenusBrut) &&
	isNotNone(scpiKyc.taxAmount) &&
	isNotNone(scpiKyc.taxType) &&
	isNotNone(scpiKyc.lengthPerception) &&
	isNotNone(scpiKyc.riskPerception);

export const isScpiKycTestCompleted = (scpiKycTest: UserScpiKycTest | undefined) =>
	!!scpiKycTest &&
	isNotNone(scpiKycTest.investKnowledge) && // : InvestKnowledgeType;
	isNotNone(scpiKycTest.hasRealEstateInvest) && // : boolean;
	isNotNone(scpiKycTest.hasSCPIShares) && // : boolean;
	isNotNone(scpiKycTest.hasInvestedMoreThan100k) && // : boolean;
	isNotNone(scpiKycTest.hasLongTermInvestmentConscience) && // : boolean;
	isNotNone(scpiKycTest.investReturnPerception) && // : InvestReturnPerceptionType;
	isNotNone(scpiKycTest.investRevenusPerception) && // : InvestRevenusPerceptionType;
	isNotNone(scpiKycTest.investRevenusLengthPerception) && // : InvestRevenusLengthPerceptionType;
	isNotNone(scpiKycTest.agreeWithInvestment); // : boolean;

export const isReReglementaryStepCompleted = (
	kyc: KYC | undefined,
	peScpiKyc: PeScpiKyc | undefined,
	scpiKyc: UserScpiKyc | undefined,
	scpiKycTest: UserScpiKycTest | undefined,
) =>
	isReglementaryInformationCompleted(kyc, peScpiKyc) &&
	isReProductInformationCompleted(scpiKyc) &&
	isScpiKycTestCompleted(scpiKycTest);
