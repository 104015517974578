import { backendApi } from 'services/apiService';
import { User } from 'store/types/user.type';

type RegisterRequestParams = {
	email: string;
	password: string;
	codePromo?: string;
	partner?: string;
	corporateEmail?: string;
	redirect: string;
};

type LoginRequestParams = { email: string; password: string; extendConnection?: boolean };
type UpdatePasswordParams = { newPassword: string; oldPassword: string };
type ResetPasswordParams = { email: string };
type ApplyResetPasswordParams = { password: string; token: string };
type LoginCgpParams = { userEmail: string; password: string; cgpId: string };
type AutologinCgpParams = { token: string };

export type AuthResponse = {
	user: User;
	access_token: string;
};

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		register: builder.mutation<AuthResponse, RegisterRequestParams>({
			query: ({ redirect, ...body }) => ({
				url: `/auth/register`,
				method: 'POST',
				body,
				params: { redirect },
			}),
		}),
		login: builder.query<AuthResponse, LoginRequestParams>({
			query: (body) => ({
				url: `/auth/login`,
				method: 'POST',
				body,
			}),
		}),
		updatePassword: builder.mutation<void, UpdatePasswordParams>({
			query: (body) => ({
				url: `/auth/password/update`,
				method: 'PUT',
				body,
			}),
		}),
		requestResetPassword: builder.query<void, ResetPasswordParams>({
			query: (body) => ({
				url: `/auth/password/request-reset`,
				method: 'POST',
				body,
			}),
		}),
		applyResetPassword: builder.mutation<AuthResponse, ApplyResetPasswordParams>({
			query: (body) => ({
				url: `/auth/password/apply-reset`,
				method: 'POST',
				body,
			}),
		}),
		//
		// CGP
		//
		loginCgp: builder.query<AuthResponse, LoginCgpParams>({
			query: ({ userEmail, password, cgpId }) => ({
				url: `/auth/login-cgp`,
				method: 'POST',
				body: { userEmail, password, cgpId },
			}),
		}),
		autologinAuthentification: builder.query<AuthResponse, AutologinCgpParams>({
			query: ({ token }) => ({
				url: '/auth/autologin',
				method: 'POST',
				body: { token },
			}),
		}),
	}),
});

export const {
	useRegisterMutation,
	useLazyLoginQuery,
	useUpdatePasswordMutation,
	useLazyRequestResetPasswordQuery,
	useApplyResetPasswordMutation,
	useLazyLoginCgpQuery,
	useLazyAutologinAuthentificationQuery,
} = extendedApi;
