import { backendApi } from 'services/apiService';
import { Notification } from 'store/types/notification.type';
import {Banner, MonthlyOffreBlackType} from "store/types/airtable.type";

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		//
		// Notification
		//
		getNotifications: builder.query<Notification[], void>({
			query: () => '/communication/notifications',
			providesTags: ['Notification'],
		}),

		updateNotification: builder.mutation<void, Partial<Notification>>({
			query: (notificationUpdate) => ({
				url: '/communication/notifications',
				method: 'PUT',
				body: notificationUpdate,
			}),
			invalidatesTags: ['Notification'],
		}),

		//
		// Banner
		//
		getBanner: builder.query<Banner[], string>({
			query: (endpoint) => ({
				url: '/communication/banner',
				method: 'GET',
				params: { endpoint },
			}),
		}),
		getOfferBlack: builder.query<MonthlyOffreBlackType, void>({
			query: () => ({
				url: '/communication/monthly-offer-black',
				method: 'GET',
			}),
		}),
	}),
});

export const { useGetNotificationsQuery, useUpdateNotificationMutation, useGetBannerQuery, useGetOfferBlackQuery } = extendedApi;
