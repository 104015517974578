import React, { useRef } from 'react';
import { Center, Slider, SliderMark, SliderThumb, SliderTrack, VStack } from '@chakra-ui/react';

import { RiskSliderMark } from 'components/Graphs/SliderMark';
import colors from 'theme/foundations/colors';
import { riskMax, riskMin } from 'utils/rendering/riskDisplay';

const RiskSlider = ({
	value,
	onChange,
	recommandedRisk,
	step = 0.1,
}: {
	value: number;
	onChange: (value: number) => void;
	recommandedRisk?: number | undefined;
	step?: number;
}) => {
	const showRecommandedRisk = recommandedRisk !== undefined;
	const sliderThumbRectRef = useRef() as React.MutableRefObject<HTMLDivElement>;

	return (
		<VStack align="center" spacing="24px" w="100%" p="16px">
			<Slider
				w="100%"
				min={riskMin}
				max={riskMax}
				step={step}
				aria-label="advise-slider"
				value={value}
				data-cy="risk-slider"
				onChange={onChange}
			>
				<SliderTrack
					h="16px"
					borderRadius="8px"
					bgGradient="linear(to-r, rgba(255, 187, 44, 1) , rgba(223, 157, 18, 1), rgba(170, 120, 31, 1) )"
				/>
				{showRecommandedRisk && (
					<SliderMark h="48px" w="3px" mt="-24px" borderRadius="base" bgColor="blue.500" value={recommandedRisk} />
				)}
				<SliderMark
					mt="-42px"
					ml="-10px"
					value={value}
					h="24px"
					w="24px"
					border="1px"
					borderColor="grey.300"
					borderRadius="base"
					textAlign="center"
					color={colors.grey[900]}
				>
					<Center w="100%" h="100%">
						{value.toLocaleString('fr-FR', { minimumFractionDigits: value === 10 || step === 1 ? 0 : 1 })}
					</Center>
				</SliderMark>

				{/* on ajoute les marquers pour chaque risque  à part le premier le*/}

				{Array(9).map((_, i) => {
					const risk = i + 1;
					return <RiskSliderMark key={risk} value={risk} />;
				})}

				<SliderThumb
					ref={sliderThumbRectRef}
					w={{ base: 8, md: 6 }}
					h={{ base: 8, md: 6 }}
					bg={colors.yellow[300]}
					border="2px"
					borderColor="white"
					_focus={{ boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.25)` }}
				/>
			</Slider>
		</VStack>
	);
};

export default RiskSlider;
