import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Card,
	Checkbox,
	Divider,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Radio,
	RadioGroup,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useGetPEKYCTestQuery, useUpdatePEKYCTestMutation } from 'services/requests/privateEquity/kyc';
import { UserPeKycTest } from 'store/types/peKyc.type';
import scrollToErrorOnForm from 'utils/errors/FormScrollToError';

const knowledgeLevel = Object.entries({
	KNOWLEDGE: "J'ai connaissance de ce produit",
	EXPERIENCE: "J'ai déjà eu une expérience avec ce produit",
	MORE_THAN_5_OPERATIONS: "J'ai déjà réalisé plus de 5 opérations sur ce produit",
});

type KnowledgeKeyType = keyof Omit<UserPeKycTest, 'agreeWithInvestment'>;
const productKnowledgeCategories: Record<KnowledgeKeyType, string> = {
	avKnowledge: 'Assurance Vie',
	opcvmMonetaireKnowledge: 'OPCVM monétaires',
	coteKnowledge: 'Actions cotés',
	nonCoteKnowledge: 'Titres non cotés (FCPI, FIP, FCPR, holdings, ...)',
	peaKnowledge: 'Comptes-titres, PEA',
	opcvmActionKnowledge: 'OPCVM actions',
	produitStrucutureKnowledge: 'Produits structurés',
};

type FormData = UserPeKycTest;

export const Test = (): JSX.Element => {
	const toast = useThemedToast();
	// const isMobile = useAppResponsive({ base: true, md: false }) ?? false;
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const { data: peKycTest } = useGetPEKYCTestQuery();
	const [updateKycTest] = useUpdatePEKYCTestMutation();

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<FormData>();

	useEffect(() => {
		if (peKycTest) {
			if (peKycTest.agreeWithInvestment) setValue('agreeWithInvestment', peKycTest.agreeWithInvestment);
			if (peKycTest.avKnowledge) setValue('avKnowledge', peKycTest.avKnowledge);
			if (peKycTest.opcvmActionKnowledge) setValue('opcvmActionKnowledge', peKycTest.opcvmActionKnowledge);
			if (peKycTest.coteKnowledge) setValue('coteKnowledge', peKycTest.coteKnowledge);
			if (peKycTest.opcvmMonetaireKnowledge) setValue('opcvmMonetaireKnowledge', peKycTest.opcvmMonetaireKnowledge);
			if (peKycTest.nonCoteKnowledge) setValue('nonCoteKnowledge', peKycTest.nonCoteKnowledge);
			if (peKycTest.peaKnowledge) setValue('peaKnowledge', peKycTest.peaKnowledge);
			if (peKycTest.produitStrucutureKnowledge)
				setValue('produitStrucutureKnowledge', peKycTest.produitStrucutureKnowledge);
		}
	}, [setValue, peKycTest]);

	const onSubmit = handleSubmit(
		(data: FormData) => {
			updateKycTest(data)
				.unwrap()
				.then(() => {
					navigate(`/private-equity/onboarding/${id}/souscription`);
				})
				.catch(() =>
					toast({
						title: 'Une erreur est survenue',
						status: 'error',
						duration: 9000,
						isClosable: true,
					}),
				);
		},
		() => scrollToErrorOnForm(errors),
	);

	useEffect(() => {
		eventTracker.mixpanel.track("PE Onboarding - Test d'adéquation");
	}, []);

	return (
		<form onSubmit={onSubmit}>
			<VStack align="start" spacing="24px">
				<Heading variant="Title-XL-Bold">Test d’adéquation</Heading>

				<Text variant="Text-M-Regular" color="grey.900">
					Connaître un produit équivaut à connaître ses risques, avantages et inconvénients. A minima, avoir des
					connaissances dans les titres non cotés est un prérequis pour que votre souscription soit validée. Dans le cas
					contraire, n’hésitez pas à vous rapprocher d’un conseiller pour obtenir ces informations.
				</Text>

				<Divider />

				<VStack w="100%" align="start" spacing="40px">
					<Text variant="Title-M-Bold">Merci d'indiquer votre niveau de connaissance vis-à-vis de ces produits</Text>

					<Wrap spacing="32px">
						{(Object.entries(productKnowledgeCategories) as [KnowledgeKeyType, string][]).map(([key, display]) => (
							<WrapItem key={key} w={onboardingInputWidth}>
								<FormControl key={key} isInvalid={!!errors[key]}>
									<FormLabel>{display}</FormLabel>
									<Controller
										name={key}
										control={control}
										rules={{ required: true }}
										render={({ field: { onChange, ...rest } }) => (
											<RadioGroup size="sm" onChange={onChange} {...rest}>
												<VStack spacing="8px">
													{knowledgeLevel.map(([knowledgeKey, knowledgeDescription]) => (
														<Radio key={knowledgeKey} value={knowledgeKey}>
															{knowledgeDescription}
														</Radio>
													))}
												</VStack>
											</RadioGroup>
										)}
									/>
									{errors[key]?.type === 'required' && <FormErrorMessage>Ce champs est nécessaire</FormErrorMessage>}
								</FormControl>
							</WrapItem>
						))}
					</Wrap>
				</VStack>

				<Divider />

				<VStack align="start" spacing="16px">
					<Text variant="Title-M-Bold">
						Estimez-vous qu’un placement en Private Equity est adapté à votre profil et à votre situation ?
					</Text>
					<Text variant="Text-S-Regular" color="grey.900">
						Acheter des titres non cotés est un investissement illiquide et long terme.
						<br />
						<br />
						Nous vous recommandons une durée de placement de plus de 10 ans.
						<br />
						Contrairement au Livret A par exemple, ce placement comporte des risques.
						<br />
						<br />
						Le capital investi et les distributions ne sont pas garantis : ils sont liés aux évolutions du marché et à
						la performance des actifs du portefeuille.
						<br />
						Enfin, comme tout placement rappelez-vous que les performances passées ne préjugent pas des performances
						futures.
						<br />
						<br />
					</Text>
					<Card>
						<FormControl isInvalid={!!errors.agreeWithInvestment}>
							<Controller
								name="agreeWithInvestment"
								control={control}
								rules={{ required: true }}
								render={({ field: { value, onChange, ref } }) => (
									<Checkbox isChecked={value} onChange={onChange} ref={ref}>
										<Text variant="Text-M-Bold">J’ai bien pris connaissance des informations ci-dessus</Text>
									</Checkbox>
								)}
							/>

							{errors.agreeWithInvestment?.type === 'required' && (
								<FormErrorMessage>Merci de prendre connaissance des informations ci-dessus</FormErrorMessage>
							)}
						</FormControl>
					</Card>
				</VStack>
			</VStack>
			<BottomStepBar
				onPrev={() => navigate(`/private-equity/onboarding/${id}/reglementaire/informations-produit`)}
				nextSubmit
			/>
		</form>
	);
};
