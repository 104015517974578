import { Search } from '@carbon/icons-react';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

const SearchBar = ({
	placeholder,
	value,
	onChange,
}: {
	placeholder: string;
	value: string;
	onChange: (newName: string) => void;
}) => {
	return (
		<InputGroup w="100%">
			<InputLeftElement>
				<Search size="20" />
			</InputLeftElement>
			<Input placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} />
		</InputGroup>
	);
};

export default SearchBar;
