import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormLabel, Heading, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import NumberInput from 'components/inputs/NumberInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import { UpdateKycFromScpiPeDTO } from 'store/types/KYC.type';

type TempToRefacto = Pick<UpdateKycFromScpiPeDTO, 'patrimonyNonCote' | 'patrimonyRealEstate'>;

const PatrimonyPESCPIFormContext = () => {
	const {
		control,
		formState: { errors },
	} = useFormContext<TempToRefacto>();

	return (
		<VStack w="100%" align="start" spacing="24px">
			<Heading variant="Title-L-Bold">Mon patrimoine</Heading>
			<Text variant="Text-M-Regular" color="grey.900">
				Indiquez le montant de votre patrimoine en SCPI et immobilier locatif, ainsi que le montant de votre patrimoine
				en titres non cotés.
			</Text>

			<Wrap w="100%" spacingX="56px" spacingY="24px">
				<WrapItem w={onboardingInputWidth}>
					<FormControl isInvalid={!!errors.patrimonyRealEstate}>
						<FormLabel>SCPI et immobilier locatif</FormLabel>
						<Controller
							name="patrimonyRealEstate"
							control={control}
							rules={{ required: true, min: 0 }}
							render={({ field }) => <NumberInput {...field} placeholder="Montant" suffix=" €" />}
						/>

						{errors.patrimonyRealEstate?.type === 'min' && (
							<FormErrorMessage>Le montant ne peut pas être négatif</FormErrorMessage>
						)}
						{errors.patrimonyRealEstate?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
					</FormControl>
				</WrapItem>

				<WrapItem w={onboardingInputWidth}>
					<FormControl isInvalid={!!errors.patrimonyNonCote}>
						<FormLabel>Titres non cotés</FormLabel>

						<Controller
							name="patrimonyNonCote"
							control={control}
							rules={{ required: true, min: 0 }}
							render={({ field }) => <NumberInput {...field} placeholder="Montant" suffix=" €" />}
						/>

						{errors.patrimonyNonCote?.type === 'min' && (
							<FormErrorMessage>Le montant ne peut pas être négatif</FormErrorMessage>
						)}
						{errors.patrimonyNonCote?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
					</FormControl>
				</WrapItem>
			</Wrap>
		</VStack>
	);
};

export default PatrimonyPESCPIFormContext;
