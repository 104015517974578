import { Card, Heading, HStack, Image, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import Av from 'assets/icons/ProductsColoredIcons/Av.svg';
import Per from 'assets/icons/ProductsColoredIcons/Per.svg';
import { ArrowButton } from 'components/Button/Button';
import { EnvelopeType } from 'store/types/global.type';
import colors from 'theme/foundations/colors';

type EnvelopeCardProps = {
	type: EnvelopeType;
	objectif: string;
	onClickCTA: () => void;
	isRetired?: boolean;
};

const EnvelopeCard = ({ type, onClickCTA, objectif, isRetired = false }: EnvelopeCardProps): JSX.Element => (
	<Card bg="blue.50" w="100%" cursor={isRetired ? 'not-allowed' : 'initial'} borderColor="blue.50">
		<VStack align="start" spacing="24px" w="100%">
			<Wrap w="100%" justify="space-between" spacing="24px">
				<WrapItem>
					<HStack spacing="24px" w="100%">
						<Image src={type === 'AV' ? Av : Per} alt="Envelope" />
						<Heading variant="Title-M-SemiBold">{type === 'AV' ? 'Assurance vie' : 'PER individuel'}</Heading>
					</HStack>
				</WrapItem>
				<WrapItem>
					<Heading variant="Title-S-SemiBold" color={colors.blue['900']}>
						{objectif}
					</Heading>
				</WrapItem>
			</Wrap>

			<Text variant="Text-M-Regular" color="grey.900">
				{type === 'AV'
					? "L’assurance vie vous permet d'obtenir un avantage fiscal si celle-ci est ouverte depuis plus de 8 ans. Lors du retrait de votre investissement, vous ne serez taxé que sur les gains issus de cet investissement."
					: isRetired
					? 'Votre situation de Rétraité ne vous permet pas d’ouvrir un contrat PER.'
					: 'Un PER individuel vous permet de défiscaliser directement vos revenus investis avant qu’ils ne soient prélevés de l’impôt. L’investissement n’est imposé qu’au moment du retrait.'}
			</Text>
			<ArrowButton alignSelf="end" onClick={onClickCTA} variant="tertiary" isDisabled={isRetired}>
				{type === 'AV' ? 'Ouvrir une assurance vie' : 'Ouvrir un PER individuel'}
			</ArrowButton>
		</VStack>
	</Card>
);

export default EnvelopeCard;
