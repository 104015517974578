import colors from '../foundations/colors';

const Slider = {
	baseStyle: {
		thumb: {
			_focus: {
				border: '0px solid rgba(0, 0, 0, 0)',
				boxShadow: `0px 0px 0px 2px ${colors.yellow[300]}`,
			},
		},
	},
	defaultProps: {},
};

export default Slider;
