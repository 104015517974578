import { createIcon } from '@chakra-ui/react';

export const InvestProjectIcon = createIcon({
	displayName: 'InvestProjectIcon',
	viewBox: '0 0 24 26',
	path: (
		<g fill="none">
			<path
				d="M18.5 9.653V18.8963H5.5V9.653V9.00004V8.83435V6.5H18.5V8.00004L18.5 9.653Z"
				stroke="currentColor"
				strokeWidth="3"
				strokeLinejoin="round"
			/>
		</g>
	),
});

export const RealEstateIcon = createIcon({
	displayName: 'ResidenceIcon',
	viewBox: '0 0 24 27',
	path: (
		<g fill="none">
			<path
				d="M5.5 11.0281L12 5.74687L18.5 11.0281V19.8963H5.5V11.0281Z"
				stroke="currentColor"
				strokeWidth="3"
				strokeLinejoin="round"
			/>
		</g>
	),
});

export const PrivateEquityIcon = createIcon({
	displayName: 'PrivateEquityIcon',
	viewBox: '-3 -4 24 27',
	path: (
		<g fill="none">
			<path
				d="M9 -3.93402e-07C7.21997 -3.15595e-07 5.47991 0.527841 3.99987 1.51677C2.51982 2.50571 1.36627 3.91131 0.685084 5.55585C0.00389503 7.20038 -0.174335 9.00998 0.172932 10.7558C0.520199 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9L14.6604 9C14.6604 10.1195 14.3284 11.2139 13.7064 12.1447C13.0845 13.0756 12.2004 13.8011 11.1661 14.2295C10.1318 14.6579 8.99372 14.77 7.89571 14.5516C6.7977 14.3332 5.78912 13.7941 4.9975 13.0025C4.20588 12.2109 3.66678 11.2023 3.44837 10.1043C3.22996 9.00628 3.34206 7.86816 3.77048 6.83386C4.1989 5.79956 4.92441 4.91553 5.85525 4.29356C6.7861 3.67158 7.88048 3.33961 9 3.33961L9 -3.93402e-07Z"
				fill="currentColor"
			/>
			<path
				d="M18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -5.16623e-08 9 0L9 9H18Z"
				fill="currentColor"
			/>
		</g>
	),
});

export const BSPCEIcon = createIcon({
	displayName: 'BSPCEIcon',
	viewBox: '-3 -4 24 27',
	path: (
		<g fill="none">
			<path
				d="M9.43301 1.75L16.3612 13.75C16.5537 14.0833 16.3131 14.5 15.9282 14.5H2.0718C1.6869 14.5 1.44634 14.0833 1.63879 13.75L8.56699 1.75C8.75944 1.41667 9.24056 1.41667 9.43301 1.75Z"
				stroke="currentColor"
				strokeWidth="3"
				strokeLinejoin="round"
			/>
		</g>
	),
});

export const NewProjectIcon = createIcon({
	displayName: 'NewProjectIcon',
	viewBox: '0 0 24 27',
	path: (
		<g fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.6 6.2C13.6 5.53726 13.0627 5 12.4 5C11.7373 5 11.2 5.53726 11.2 6.2V12.2H5.2C4.53726 12.2 4 12.7373 4 13.4C4 14.0627 4.53726 14.6 5.2 14.6H11.2V19.8C11.2 20.4627 11.7373 21 12.4 21C13.0627 21 13.6 20.4627 13.6 19.8V14.6H18.8C19.4627 14.6 20 14.0627 20 13.4C20 12.7373 19.4627 12.2 18.8 12.2H13.6V6.2Z"
				fill="currentColor"
			/>
		</g>
	),
});

export const ExploreIcon = createIcon({
	displayName: 'ExploreIcon',
	viewBox: '0 0 40 40',
	path: (
		<g fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM20 17.8C18.78 17.8 17.8 18.78 17.8 20C17.8 21.22 18.78 22.2 20 22.2C21.22 22.2 22.2 21.22 22.2 20C22.2 18.78 21.22 17.8 20 17.8ZM8 32L24.38 24.38L32 8L15.62 15.62L8 32Z"
				fill="currentColor"
			/>
		</g>
	),
});
