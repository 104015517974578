import { useMemo } from 'react';
import { ChevronDown, ChevronUp } from '@carbon/icons-react';
import { Grid, GridItem, Heading, HStack, Image, Stack, Text, useDisclosure, VStack } from '@chakra-ui/react';

import AvIcon from 'assets/icons/ProductsColoredIcons/Av.svg';
import PerIcon from 'assets/icons/ProductsColoredIcons/Per.svg';
import { IconButton } from 'components/Button/Button';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { BaseMouvementDTO } from 'services/requests/mouvements';
import { EnvelopeType } from 'store/types/global.type';
import { isNotNone } from 'utils/functions';
import { displayMoney } from 'utils/rendering';

export type VersementCardProps = {
	typeEnvelope: EnvelopeType;
	label: string;
	status: 'EXECUTED' | 'INCOMING';
	dateExecution: Date;
	amount: number;
	subOperation: BaseMouvementDTO[];
};

const VersementCard = ({
	typeEnvelope,
	amount,
	label,
	status,
	dateExecution,
	subOperation,
}: VersementCardProps): JSX.Element => {
	const { isOpen: isDropdownOpen, onToggle: onDropdownToggle } = useDisclosure();
	const isMobile = useAppResponsive({
		base: true,
		md: false,
	});

	const shouldDisplayButton = subOperation.length > 0;
	const shouldDisplayButtonDesktop = !isMobile && shouldDisplayButton;
	const shouldDisplayButtonMobile = isMobile && shouldDisplayButton;
	const shouldDisplayIcon = !isMobile;

	// 4 columns: the icon, the label, the amount, the desktop button
	// remove column if it will be empty
	// keep each row with the same number of columns (or it will break the grid)
	// 3 rows: the data, the details, the mobile button
	// remove row if it will be empty
	const templateAreas = useMemo(
		() =>
			[
				[
					shouldDisplayIcon ? 'icon' : undefined,
					'label',
					'amount',
					shouldDisplayButtonDesktop ? 'button-desktop' : undefined,
				],
				isDropdownOpen
					? [
							shouldDisplayIcon ? 'icon-fake' : undefined,
							'details',
							'details',
							shouldDisplayButtonDesktop ? 'button-fake' : undefined,
					  ]
					: undefined,
				shouldDisplayButtonMobile
					? [
							shouldDisplayIcon ? 'icon-fake' : undefined,
							'button-mobile',
							'button-mobile',
							shouldDisplayButtonDesktop ? 'button-fake' : undefined,
					  ]
					: undefined,
			]
				.filter(isNotNone)
				.map((row) => `"${row.filter(isNotNone).join(' ')}"`) // use css grid format
				.join(' '),
		[isDropdownOpen, shouldDisplayButtonDesktop, shouldDisplayButtonMobile, shouldDisplayIcon],
	);

	return (
		<Grid
			gridTemplateColumns={shouldDisplayIcon ? `auto 1fr` : '1fr auto'}
			templateAreas={templateAreas}
			columnGap="10px"
			rowGap="24px"
			w="100%"
			alignItems="center"
			opacity={status === 'EXECUTED' ? 1 : 0.56}
		>
			{shouldDisplayIcon && (
				<GridItem area="icon">
					<Image boxSize="24px" src={typeEnvelope === 'AV' ? AvIcon : PerIcon} />
				</GridItem>
			)}
			<GridItem area="label">
				<VStack align="start">
					<Text variant="Caption" color="grey.900">
						{typeEnvelope === 'AV' ? 'Assurance Vie' : 'PER individuel'}
					</Text>
					<Stack align={{ base: 'start', md: 'center' }} direction={{ base: 'column', md: 'row' }}>
						<Text variant="Text-M-Medium">{label}</Text>
						<Text variant="Text-S-Regular" color="grey.900">
							{dateExecution.toLocaleDateString('fr-FR', { month: 'long', day: 'numeric', year: 'numeric' })}
						</Text>
					</Stack>
				</VStack>
			</GridItem>
			<GridItem area="amount" justifySelf="flex-end">
				<Heading variant="Title-M-Bold">{displayMoney(amount)}</Heading>
			</GridItem>
			{shouldDisplayButtonDesktop && (
				<GridItem area="button-desktop">
					<IconButton
						onClick={onDropdownToggle}
						variant="tertiary"
						right
						icon={isDropdownOpen ? <ChevronUp /> : <ChevronDown />}
					>
						Détails
					</IconButton>
				</GridItem>
			)}
			{shouldDisplayButtonMobile && (
				<GridItem area="button-mobile">
					<IconButton
						onClick={onDropdownToggle}
						variant="tertiary"
						right
						w="100%"
						icon={isDropdownOpen ? <ChevronUp /> : <ChevronDown />}
					>
						Détails
					</IconButton>
				</GridItem>
			)}
			{isDropdownOpen && (
				<GridItem area="details">
					<VStack align="start">
						{subOperation.map((subOp) => (
							<HStack align="start" w="100%" justify="space-between" key={subOp.typeMouvement}>
								<Text variant="Text-M-Regular" color="grey.900">
									{subOp.typeMouvement}
								</Text>
								<Text variant="Text-M-Medium">{displayMoney(subOp.montantOperation)}</Text>
							</HStack>
						))}
					</VStack>
				</GridItem>
			)}
		</Grid>
	);
};

export default VersementCard;
