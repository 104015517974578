import { Card, HStack, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';

import DocumentToDownload from 'components/DocumentToDownload';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { FundSubscription, PEFund } from 'store/types/airtable.type';

type DetailCardProps = {
	title: string;
	description: JSX.Element;
	souscriptions:
		| (FundSubscription & {
				fund?: string | PEFund | undefined;
				fundsOriginGeography?: 'France' | 'Other' | undefined;
		  } & {
				fund: PEFund;
		  })[];
};

const HelpDeclarationSourcePrivateEquity = ({ title, description, souscriptions }: DetailCardProps) => {
	const isMobile = useAppResponsive({ base: true, md: false });

	return (
		<VStack align="start" w="100%" spacing="16px">
			<Text variant="title">{title}</Text>
			<Card variant="blue" w="100%">
				{description}
			</Card>

			{isMobile ? (
				<VStack w="100%" align="start" spacing="16px">
					{souscriptions.map((subscription) => (
						<VStack w="100%" align="start" spacing="8px" key={subscription.id}>
							<HStack spacing="8px">
								<Image src={subscription.fund.fundCover?.[0]?.url} borderRadius="full" boxSize="48px" fit="cover" />

								<VStack align="start">
									<Text variant="label">{subscription.fund.name}</Text>
									<Text variant="body" fontWeight="medium">
										{subscription.fund.partner?.toUpperCase()}
									</Text>
								</VStack>
							</HStack>

							<Text pl="64px" variant="title">
								{(subscription.fund.taxReduction || 1) * subscription.amountVersement} € {'  '}
								<Text as="span" variant="label">
									Réductions d'impôt
								</Text>
							</Text>

							{subscription.imprimeFiscal && (
								<DocumentToDownload
									documentUrl={subscription.imprimeFiscal[0].url}
									documentFilename={subscription.imprimeFiscal[0].filename}
									name="Imprimé Fiscal unique"
								/>
							)}
						</VStack>
					))}
				</VStack>
			) : (
				<TableContainer w="100%">
					<Table variant="unstyled" w="100%">
						<Thead>
							<Tr>
								<Th>
									<Text variant="title" fontSize="12px">
										REDUCTIONS D'IMPÔT
									</Text>
								</Th>

								<Th>
									<Text variant="title" fontSize="12px">
										FONDS
									</Text>
								</Th>

								<Th>
									<Text variant="title" fontSize="12px">
										DOCUMENT / IFU
									</Text>
								</Th>
							</Tr>
						</Thead>
						<Tbody w="100%">
							{souscriptions.map((subscription) => (
								<Tr w="100%" key={subscription.id} justifyContent="space-between">
									<Td>
										<Text variant="title" alignSelf="center">
											{(subscription.fund.taxReduction || 1) * subscription.amountVersement} €
										</Text>
									</Td>

									<Td>
										<HStack spacing="16px">
											<Image
												src={subscription.fund.fundCover?.[0]?.url}
												alt={subscription.fund.name}
												borderRadius="full"
												boxSize="48px"
												fit="cover"
											/>
											<VStack pl="0px" align="start">
												<Text variant="label">{subscription.fund.name}</Text>
												<Text variant="body" fontWeight="medium">
													{subscription.fund.partner?.toUpperCase()}
												</Text>
											</VStack>
										</HStack>
									</Td>

									<Td justifyContent="space-between">
										{subscription.imprimeFiscal && (
											<DocumentToDownload
												name="Imprimé Fiscal unique"
												documentUrl={subscription.imprimeFiscal[0].url}
												documentFilename={subscription.imprimeFiscal[0].filename}
											/>
										)}
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			)}
		</VStack>
	);
};
export default HelpDeclarationSourcePrivateEquity;
