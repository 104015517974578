import { InvestmentPreferences } from 'services/requests/invest/preferences';

import { ProjectType } from './global.type';

export enum SimulationEnvelopeType {
	PER = 'PER',
	AV = 'AV',
	OPTIM = 'OPTIM',
}

export type InvestSimulation = {
	id: string;
	createdAt: string;
	updatedAt: string;
	userId: string;
	timeHorizon: string | null;
	saving: number;
	initialAUM: number;
	type: ProjectType;
	envelope: SimulationEnvelopeType | null;
	extraData: Partial<{
		workedYears: number;
		birthYear: number;
	}> | null;
};

export type PopulatedInvestSimulation = InvestSimulation & { investmentPreferences: InvestmentPreferences | null };
