import { useEffect, useState } from 'react';
import { Card, FormControl, FormErrorMessage, FormLabel, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';

import BankAccountSelector from 'components/bankingInformations/BankAccountSelector';
import { ArrowButton } from 'components/Button/Button';
import NumberInput from 'components/inputs/NumberInput';
import { VersementEligibilityError } from 'components/versements/VersementEligibilityError';
import { UpdateVPData } from 'pages/Invest/Versements/UpdateVP';
import { useGetBankAccountsQuery } from 'services/requests/bank';
import { useIsVersementEligibleQuery, VersementEligibility, versementMinMax } from 'services/requests/versement';
import { PopulatedInvestContract } from 'store/types/contract.type';
import { BankInformations } from 'store/types/subscription.type';
import { isNone, isNotNone } from 'utils/functions';
import { displayMoney, displayMoneyNoDigits, maskSensibleIban } from 'utils/rendering';

const UpdateVpSkeleton = (): JSX.Element => (
	<VStack w="100%" align="start" p="16px 8px" spacing="32px">
		<Skeleton h="64px" w="100%" />
		<Skeleton h="32px" w="100%" />
		<Skeleton h="48px" w="100%" />

		<Skeleton h="40px" w="40%" />
	</VStack>
);

interface UpdateVPInformationsProps {
	onSubmit: (data: UpdateVPData) => void;
	data: UpdateVPData | undefined;
	contract: PopulatedInvestContract;
}

const CurrentVpInformations = ({ contract }: { contract: PopulatedInvestContract }) => (
	<VStack align="start" w="100%" spacing="16px">
		<Text>Versement programmé en cours :</Text>
		<Card variant={contract.envelope.type === 'AV' ? 'redBorder' : 'yellowBorder'} w="100%">
			<VStack w="100%" align="start" spacing="12px">
				<HStack w="100%" justify="space-between">
					<Text variant="title">{contract.envelope.type === 'PER' ? 'PER individuel' : 'Assurance vie'}</Text>
					<VStack align="start" spacing="4px">
						<HStack align="baseline">
							<Text variant="title">{displayMoneyNoDigits(Number(contract.programmedVersement?.amount))}</Text>
							<Text variant="detail">par mois</Text>
						</HStack>
					</VStack>
				</HStack>
				<Text variant="detail" color="grey.900">
					Prélevés sur <b>{maskSensibleIban(contract.bankInformation.IBAN)}</b>
				</Text>
			</VStack>
		</Card>
	</VStack>
);

const Informations = ({ onSubmit, data, contract }: UpdateVPInformationsProps): JSX.Element => {
	const { data: accounts } = useGetBankAccountsQuery();

	const [amount, setAmount] = useState<number | undefined>(data?.amount);
	const [bankInfo, setBankInfo] = useState<BankInformations | undefined>(data?.bankInfo);

	// prefill bank account with the one on contract
	useEffect(() => {
		if (accounts && !bankInfo) {
			const defaultBankInfo: BankInformations | undefined = accounts.find((i) => i.id === contract.bankInformationId);
			if (defaultBankInfo) setBankInfo(defaultBankInfo);
		}
	}, [accounts, bankInfo, contract]);

	const { data: versementEligibility } = useIsVersementEligibleQuery(
		{
			contractId: contract?.id ?? '',
			type: 'PROGRAMME',
		},
		{ skip: isNone(contract) },
	);

	if (!accounts) return <UpdateVpSkeleton />;

	const minimum: number = versementMinMax[contract.envelope.partnerCode].minVP.mensuel;
	const maximum: number = versementMinMax[contract.envelope.partnerCode].max;
	const fulfilled = !!amount && amount >= minimum && amount <= maximum && !!bankInfo;

	return (
		<VStack w="100%" spacing="32px" align="start">
			<CurrentVpInformations contract={contract} />

			{isNotNone(versementEligibility) && versementEligibility.eligibility !== VersementEligibility.ELIGIBLE ? (
				<VersementEligibilityError versementEligibility={versementEligibility.eligibility} />
			) : (
				<>
					<FormControl>
						<FormLabel>
							Quel est le nouveau montant que vous souhaitez verser <b>(mensuellement)</b> ?
						</FormLabel>
						<NumberInput
							suffix=" €"
							value={amount ?? ''}
							placeholder={`Minimum ${minimum}€`}
							onChange={(value) => {
								setAmount(value);
							}}
							min={minimum}
							max={maximum}
						/>
						{!!amount && amount < minimum && (
							<FormErrorMessage>
								Le montant minimum d'un versement programmé est de {displayMoney(minimum)}.
							</FormErrorMessage>
						)}
						{!!amount && amount > maximum && (
							<FormErrorMessage>
								Le montant maximum d'un versement programmé est de {displayMoney(maximum)}.
							</FormErrorMessage>
						)}
					</FormControl>

					<FormControl>
						<BankAccountSelector
							bankAccounts={accounts}
							errors={null}
							selectedAccountId={bankInfo?.id}
							setValue={(value) => setBankInfo(value)}
						/>
					</FormControl>
				</>
			)}

			<ArrowButton
				isDisabled={
					!fulfilled ||
					(isNotNone(versementEligibility) && versementEligibility.eligibility !== VersementEligibility.ELIGIBLE)
				}
				onClick={() => fulfilled && onSubmit({ amount, bankInfo })}
				variant="primary"
				alignSelf="end"
			>
				Suivant
			</ArrowButton>
		</VStack>
	);
};

export default Informations;
