import { useNavigate } from 'react-router-dom';
import { Card, Heading, HStack, Image, List, ListItem, Text, VStack } from '@chakra-ui/react';

import Warning from 'assets/icons/Warning-yellow.svg';
import { ArrowButton } from 'components/Button/Button';
import BottomStepBar from 'components/steppers/BottomStepBar';

const Confirmation = (): JSX.Element => {
	const navigate = useNavigate();
	const onNext = () => navigate('/cash');

	return (
		<VStack align="start" spacing="24px">
			<Heading variant="bigTitle">Confirmation de souscription</Heading>
			<Text variant="label">L'ouverture de votre contrat se déroulera en trois étapes :</Text>

			<List spacing="8px">
				<ListItem>
					<Text variant="Text-M-Regular" color="grey.900">
						• <b>Étape 1 :</b> Traitement de votre dossier par Ramify et partage à notre partenaire - en moyenne sous 1
						à 2 jours ouvrés.
					</Text>
				</ListItem>
				<ListItem>
					<Text>
						• <b>Étape 2 :</b> Validation de votre dossier par notre partenaire - en moyenne sous 2 à 4 jours ouvrés
						(délai dépendant des volumes traités par le partenaire).
					</Text>
				</ListItem>
				<ListItem>
					<Text>
						• <b>Étape 3 :</b> Alimentation de votre livret - Une fois validé, vous pourrez récupérer l’IBAN du livret
						et approvisionner ce dernier.
					</Text>
				</ListItem>
			</List>
			<Text>
				Vous pourrez ensuite <b>suivre votre placement</b> directement dans votre espace client Ramify.
			</Text>

			<Card w="100%" size="sm">
				<HStack w="100%" spacing="24px">
					<Image src={Warning} w="40px" h="40px" />
					<Text variant="Text-S-Regular">
						Nous vous <b>déconseillons de débloquer vos fonds</b> avant d’avoir reçu la validation de notre équipe de
						support que vous pouvez alimenter votre livret.
					</Text>
				</HStack>
			</Card>

			<ArrowButton onClick={onNext} variant="primary" size="lg">
				Revenir à l'accueil
			</ArrowButton>

			<BottomStepBar
				textNext="Revenir à l'accueil"
				onPrev={() => navigate('introduction')}
				onNext={onNext}
				showPrev={false}
				showNext
			/>
		</VStack>
	);
};

export default Confirmation;
