import { useMemo } from 'react';
import { Heading, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';

import AirtableBanner from 'components/Banners/AirtableBanner';
import SamyAdvisor from 'components/cards/SamyAdvisor';
import FiscalSituation from 'pages/Fiscalite/FiscalSituation/FiscalSituation';
import GeneraKPISlInfo from 'pages/Fiscalite/GeneralInfos/GeneralInfo';
import Imposition from 'pages/Fiscalite/Imposition/Imposition';
import OptimiseFiscalite from 'pages/Fiscalite/OptimiseFiscalite/OptimiseFiscalite';
import { useGetProductsDataQuery } from 'services/requests/product';

import ModalDeclarationGuide from './HelpDeclaration/ModalDeclarationGuide';

const Fiscalite = (): JSX.Element => {
	const { data: products } = useGetProductsDataQuery();

	const hasAtleastOneProduct = useMemo(() => {
		if (!products) return false;
		return products?.invest.status === 'OPENED' || products?.pe.status === 'OPENED';
	}, [products]);

	const {
		isOpen: isOpenModalModalDeclarationGuide,
		onOpen: onOpenModalModalDeclarationGuide,
		onClose: onCloseModalModalDeclarationGuide,
	} = useDisclosure();

	// display samy banner declaration guide only from 13 april to 8 june every year
	const displayBannerDecalarationGuide = useMemo(() => {
		const today = new Date();
		const startDate = new Date(today.getFullYear(), 3, 13);
		const endDate = new Date(today.getFullYear(), 5, 8);
		return today > startDate && today < endDate;
	}, []);

	return (
		<VStack w="100%" spacing="xl" mt="56px" mb="56px" align="start">
			{displayBannerDecalarationGuide && hasAtleastOneProduct && (
				<ModalDeclarationGuide
					isOpenModalDeclarationGuide={isOpenModalModalDeclarationGuide}
					onCloseModalDeclarationGuide={onCloseModalModalDeclarationGuide}
				/>
			)}

			<VStack spacing="24px" align="start" w="100%">
				<HStack w="100%" justify="space-between">
					<Heading variant="Title-XL-SemiBold">Fiscalité</Heading>

					{/* <Button variant="super" size="lg" onClick={onOpenModalModalDeclarationGuide}>
						Optimiser ma fiscalité
					</Button> */}
				</HStack>

				{/* TODO centralise in banner airtable? */}
				{displayBannerDecalarationGuide && (
					<SamyAdvisor
						complexText={
							<Text color="grey.700">
								Ramify vous accompagne dans votre{' '}
								<Text as="span" fontWeight="600" color="grey.700" variant="body">
									déclaration des revenus {new Date().getFullYear() - 1}
								</Text>
							</Text>
						}
						buttonText="Guide à la déclaration"
						onButtonClick={onOpenModalModalDeclarationGuide}
					/>
				)}

				<AirtableBanner />

				<Text variant="Text-M-Regular" color="grey.900">
					Plusieurs produits d’investissement permettent d’alléger votre fiscalité. Réductions et économies d’impôt
					viennent améliorer la performance globale de vos placements. Cette page vous permet d’avoir une vue globale
					sur votre situation fiscale et vous suggère des pistes pour l’optimiser.
				</Text>
			</VStack>

			<GeneraKPISlInfo />
			<OptimiseFiscalite />
			<FiscalSituation />
			<Imposition />
		</VStack>
	);
};

export default Fiscalite;
