import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
	Button,
	chakra,
	Divider,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	VStack,
} from '@chakra-ui/react';

import { isNone } from 'utils/functions';
import { displayMoney } from 'utils/rendering';

import { FormContent } from './CalendlyStep';

export const FormStep: FC<{ onClose: () => void; onSuccess: (data: FormContent) => void }> = ({
	onClose,
	onSuccess,
}) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useFormContext<FormContent>();

	const onSubmit = handleSubmit((data) => onSuccess(data));

	return (
		<Modal size="lg" isOpen onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<chakra.form onSubmit={onSubmit}>
					<ModalHeader>Prendre rendez-vous</ModalHeader>

					<ModalCloseButton />
					<Divider />

					<ModalBody p="32px">
						<FormControl isInvalid={!!errors.amount}>
							<FormLabel>Combien voulez-vous investir ?</FormLabel>
							<Controller
								name="amount"
								control={control}
								rules={{
									validate: {
										required: (value) => (isNone(value) ? 'Ce champ est requis' : true),
									},
								}}
								render={({ field: { onChange, value, ...rest } }) => (
									<RadioGroup
										onChange={(nextValue) => {
											onChange(Number(nextValue));
										}}
										{...rest}
									>
										<VStack spacing="8px">
											<Radio value="1000">
												Entre {displayMoney(1_000)} et {displayMoney(20_000)}
											</Radio>
											<Radio value="20000">
												Entre {displayMoney(20_000)} et {displayMoney(100_000)}
											</Radio>
											<Radio value="100000">Plus de {displayMoney(100_000)}</Radio>
											<Radio value="0">Je ne sais pas</Radio>
										</VStack>
									</RadioGroup>
								)}
							/>
							{errors.amount && <FormErrorMessage>{errors.amount.message}</FormErrorMessage>}
						</FormControl>
					</ModalBody>

					<Divider />

					<ModalFooter justifyContent="space-between">
						<Button size="lg" variant="secondary" onClick={onClose}>
							Annuler
						</Button>
						<Button size="lg" type="submit">
							Confirmer
						</Button>
					</ModalFooter>
				</chakra.form>
			</ModalContent>
		</Modal>
	);
};
