import { useCallback, useState } from 'react';
import { Box, Button, HStack, PinInput, PinInputField, Text, VStack } from '@chakra-ui/react';
import { Ring } from '@uiball/loaders';

import { ArrowButton } from 'components/Button/Button';
import ListCompositionPortfolio from 'components/ListCompositionPortfolio';
import { UpdateVPData } from 'pages/Invest/Versements/UpdateVP';
import eventTracker from 'services/events/eventTracker';
import { useCreateVersementMutation, useSignVersementMutation } from 'services/requests/versement';
import { PopulatedInvestContract } from 'store/types/contract.type';
import colors from 'theme/foundations/colors';
import { displayMoney, maskSensibleIban } from 'utils/rendering';

type SignatureProps = {
	data: UpdateVPData;
	contract: PopulatedInvestContract;
	edit: () => void;
	onSuccess: () => void;
	onError: () => void;
};

const Signature = ({ data, edit, contract, onSuccess, onError }: SignatureProps): JSX.Element => {
	const [code, setCode] = useState('');

	const [
		triggerUpdateVP,
		{
			data: createdVersement,
			isLoading: isSmsRequestLoading,
			isSuccess: isSmsRequestSuccess,
			isError: isUpdateVPError,
			isUninitialized: isUpdateVPUninitialized,
		},
	] = useCreateVersementMutation();

	const [
		triggerSignVP,
		{ isLoading: isSignVPLoading, isError: isSignVPError, isUninitialized: isSignVPUninitialized },
	] = useSignVersementMutation();

	const recap: { label: string; value: React.ReactNode }[] = [
		{
			label: 'Contrat :',
			value: contract.envelope.type === 'AV' ? 'Assurance vie' : 'PER individuel',
		},
		{
			label: 'Précédent montant (mensuel) :',
			value: displayMoney(+contract.programmedVersement!.amount),
		},
		{
			label: 'Nouveau montant (mensuel) :',
			value: displayMoney(data.amount),
		},
		{
			label: 'Depuis le compte :',
			value: (
				<VStack align="end">
					<Text variant="label">{data.bankInfo.BIC}</Text>
					<Text variant="body">{maskSensibleIban(data.bankInfo.IBAN)}</Text>
				</VStack>
			),
		},
	];

	const handleSign = useCallback(() => {
		triggerSignVP({
			otp: code,
			versementId: createdVersement!.id,
		})
			.unwrap()
			.then(() => {
				eventTracker.mixpanel.updateVersementProgramme(data.amount);
				onSuccess();
			})
			.catch((e) => {
				if ('status' in e && e.status === 400) {
					// incorrect code. Don't do anything and let JSX display the error so the user can enter new code
					return;
				}
				eventTracker.signVersementError({
					amount: data.amount,
					envelopeType: contract.envelope.type,
					operationType: 'programme',
				});
				onError();
			});
	}, [code, contract.envelope.type, createdVersement, data.amount, onError, onSuccess, triggerSignVP]);

	return (
		<VStack align="start" spacing="32px" w="100%">
			<ArrowButton left onClick={edit} variant="secondary">
				Modifier
			</ArrowButton>

			<VStack align="start" spacing="24px" bg="grey.100" p="16px 20px" border="base" borderRadius="base" w="100%">
				<Text variant="label">Récapitulatif</Text>
				<VStack w="100%" spacing="16px">
					{recap.map(({ label, value }) => (
						<HStack key={label} w="100%" justify="space-between">
							<Text variant="detail">{label}</Text>
							<Text variant="label">{value}</Text>
						</HStack>
					))}
				</VStack>
			</VStack>

			{(isUpdateVPUninitialized || isUpdateVPError) && (
				<>
					{isUpdateVPError && (
						<Text fontWeight="medium" variant="body" color="red.500">
							Une erreur est survenue, nous ne pouvons pas modifier le versement programmé de votre contrat pour
							l'instant. Veuillez nous contacter pour plus d'informations.
						</Text>
					)}
					<VStack align="start">
						<Text variant="body">Une validation SMS est requise pour cette opération.</Text>
						<Button
							variant="primary"
							onClick={() =>
								triggerUpdateVP({
									bankInfoId: data.bankInfo.id,
									contratId: contract.id,
									montant: data.amount,
									programmed: true,
								})
							}
						>
							{isUpdateVPError ? 'Réessayer' : 'Recevoir un code'}
						</Button>
					</VStack>
				</>
			)}
			{isSmsRequestLoading && (
				<HStack spacing="16px">
					<Ring size={24} color={colors.grey[700]} />
					<Text variant="label">
						Envoi du code sms
						<br /> Cette opération peut prendre plusieurs dizaines de secondes
					</Text>
				</HStack>
			)}
			{isSmsRequestSuccess && (
				<>
					<Box overflowX="hidden" w="100%">
						<VStack align="start" spacing="16px">
							<Text variant="label">Répartition de votre versement programmé :</Text>
							<ListCompositionPortfolio assets={createdVersement!.repartition} showPerformance={false} />
						</VStack>
					</Box>
					{(isSignVPUninitialized || isSignVPError) && (
						<>
							{isSignVPError && (
								<Text fontWeight="medium" variant="body" color="red.500">
									Le code est incorrect
								</Text>
							)}
							<VStack align="start" spacing="16px">
								<Text variant="body">Veuillez confirmer le code à 6 chiffres qui vous a été envoyé par SMS&nbsp;:</Text>
								<HStack>
									<PinInput value={code} onChange={setCode} otp>
										<PinInputField />
										<PinInputField />
										<PinInputField />
										<PinInputField />
										<PinInputField />
										<PinInputField />
									</PinInput>
								</HStack>
								<VStack align="start">
									<Text>Vous n'avez pas reçu de code au bout d'une minute ?</Text>
									<Text
										textDecoration="underline"
										cursor="pointer"
										onClick={() => {
											window.Intercom('showNewMessage', 'Bonjour, je ne reçois pas mon code de validation SMS');
										}}
									>
										Contactez nous via le chat
									</Text>
								</VStack>
							</VStack>
							<ArrowButton variant="primary" isDisabled={code.length !== 6} onClick={handleSign}>
								{isSignVPError ? 'Réessayer' : 'Valider le nouveau montant'}
							</ArrowButton>
						</>
					)}
					{isSignVPLoading && (
						<HStack spacing="16px">
							<Ring size={24} color={colors.grey[700]} />
							<Text fontWeight="medium" variant="primary">
								Validation en cours
								<br /> Cette opération peut prendre plusieurs dizaines de secondes
							</Text>
						</HStack>
					)}
				</>
			)}
		</VStack>
	);
};

export default Signature;
