import { useForm } from 'react-hook-form';
import { Link as ReachLink, useLocation } from 'react-router-dom';
import {
	Button,
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	Input,
	Link,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import { useLazyRequestResetPasswordQuery } from 'services/requests/auth';

import { ContainerAuthStyle } from './style';
import { emailPattern } from './utils';

type ResetPasswordFormType = {
	email: string;
};

const ResetPassword = (): JSX.Element => {
	const toast = useThemedToast();
	const { search } = useLocation();
	const [requestResetPassword, { isLoading }] = useLazyRequestResetPasswordQuery();

	const {
		formState: { errors },
		register,
		handleSubmit,
	} = useForm<ResetPasswordFormType>({
		reValidateMode: 'onSubmit',
	});

	const onSubmit = handleSubmit(({ email }: ResetPasswordFormType) => {
		requestResetPassword({ email })
			.unwrap()
			.then(() => {
				toast({
					status: 'success',
					title: 'Un email vous a été envoyé',
					duration: 4000,
					isClosable: true,
				});
			})
			.catch((error) => {
				if ('status' in error) {
					toast({
						title: "Une erreur s'est produite.",
						description: (error.data as { message: string })?.message || 'Impossible de réinitialiser le mot de passe.',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				}
			});
	});

	return (
		<VStack {...ContainerAuthStyle}>
			<VStack align="center" spacing="sm" w="100%">
				<Heading variant="Title-L-SemiBold">Récupération du mot de passe</Heading>
				<Text variant="Text-M-Regular" color="grey.900">
					Faites fructifier votre épargne !
				</Text>
			</VStack>
			<chakra.form w="100%" onSubmit={onSubmit}>
				<VStack align="center" spacing="md" w="100%">
					<VStack align="left" spacing="8px" w="100%">
						<FormControl isInvalid={!!errors.email}>
							<FormLabel>Adresse email du compte perdu</FormLabel>
							<Input
								{...register('email', { required: true, pattern: emailPattern })}
								placeholder="warren.buffett@gmail.com"
								autoComplete="email"
							/>
							{errors.email?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
							{errors.email?.type === 'pattern' && <FormErrorMessage>L'email est incorrect</FormErrorMessage>}
						</FormControl>
					</VStack>

					<Button variant="primary" size="lg" w="100%" isLoading={isLoading} type="submit">
						Réinitialiser mon mot de passe
					</Button>

					<Wrap spacing="16px" justify="space-between">
						<WrapItem>
							<Text variant="body">Vous connaissez votre mot de passe ?</Text>
						</WrapItem>
						<WrapItem>
							<Link as={ReachLink} to={'/connexion' + search}>
								<Text variant="Text-M-Regular">Connectez-vous !</Text>
							</Link>
						</WrapItem>
					</Wrap>
				</VStack>
			</chakra.form>
		</VStack>
	);
};

export default ResetPassword;
