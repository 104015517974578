import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import StepperLayout from 'app/Layout/Onboarding/StepperLayout';
import FullscreenLoader from 'components/FullscreenLoader';
import { useAppDispatch } from 'hooks/useStore';
import useThemedToast from 'hooks/useThemedToast';
import { hasCompletedKyc, hasCompletedKycFoyerFiscal } from 'onboarding/KYC/utils';
import { setLastStepAvailable } from 'onboarding/Stepper/stepper.slice';
import { CreateInvestSimulation } from 'services/requests/invest/simulation';
import { useGetSubscriptionQuery, useUpdateObjectiveMutation } from 'services/requests/invest/subscription';
import { useGetKYCJustificatifQuery, useGetKYCQuery } from 'services/requests/kyc';
import { ProfessionalSituation } from 'store/referentiels/professionalSituation';
import { SubscriptionStatus } from 'store/types/subscription.type';
import { isNone, isNotNone } from 'utils/functions';

import Fiscalite from '../Shared/Fiscalite';
import Project from '../Shared/Objective';
import Portfolio from '../Shared/Portfolio/Routes';
import { OnboardingObject } from '../Shared/type';

import CustomProject from './Custom/Objective';
import CustomPortfolio from './Custom/Portfolio/Recapitulatif';
import { portfolioStep as customPortfolioStep } from './Custom/steps';
import { SignatureWrapper } from './Signature/Routes';
import { KYCWrapper } from './KYC.wrapper';
import Recapitulatif from './Recapitulatif';
import {
	fiscaliteStep,
	InvestSubscriptionSteps,
	kycStep,
	portfolioStep,
	projectStep,
	recapStep,
	signatureStep,
} from './steps';

const InvestSubscriptionRoutes = (): JSX.Element => {
	const { id } = useParams();
	const [collapsed, setCollapsed] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const toast = useThemedToast();

	const { data: kyc, isFetching: isKYCFetching } = useGetKYCQuery();
	const { data: subscription, isLoading: isSubscriptionLoading } = useGetSubscriptionQuery({ subscriptionId: id! });
	const { isFetching: isFetchingJustificatif, data: statusPj } = useGetKYCJustificatifQuery();
	const [updateSubscription] = useUpdateObjectiveMutation();
	const isCustom = useMemo<boolean>(
		() => subscription?.investmentPreferences?.portfolioType === 'CUSTOM',
		[subscription],
	);

	const displayDeduction = useMemo<boolean>(
		() => subscription?.envelope.type === 'PER' && kyc?.professionalSituation === ProfessionalSituation.INDEPENDANT,
		[kyc?.professionalSituation, subscription?.envelope.type],
	);

	const generatedProjectStep = useMemo(() => projectStep, []);
	const generatedPortfolioStep = useMemo(() => (isCustom ? customPortfolioStep : portfolioStep), [isCustom]);
	const generatedFiscaliteStep = useMemo(() => fiscaliteStep, []);
	const generatedRecapStep = useMemo(() => recapStep, []);
	const generatedKYCStep = useMemo(() => kycStep(subscription?.envelope.type ?? 'AV'), [subscription]);
	const generatedSignatureStep = useMemo(
		() => signatureStep(displayDeduction, !isCustom),
		[displayDeduction, isCustom],
	);

	const steps = useMemo(() => {
		if (!subscription) return [];
		return [
			generatedProjectStep,
			generatedPortfolioStep,
			subscription?.envelope.type === 'PER' ? generatedFiscaliteStep : undefined,
			generatedKYCStep,
			!isCustom ? generatedRecapStep : undefined,
			generatedSignatureStep,
		].filter(isNotNone);
	}, [
		generatedProjectStep,
		generatedPortfolioStep,
		generatedFiscaliteStep,
		generatedKYCStep,
		generatedRecapStep,
		generatedSignatureStep,
		subscription,
		isCustom,
	]);

	const mostAdvancedStep = useMemo(() => {
		if (isSubscriptionLoading || isKYCFetching || isFetchingJustificatif) return undefined;

		if (isNone(subscription) || isNone(subscription.initialDepositAmount) || isNone(subscription.projectType)) {
			return generatedProjectStep;
		}

		if (
			isNone(subscription.investmentPreferences) ||
			isNone(subscription.investmentPreferences.esg) ||
			isNone(subscription.investmentPreferences.risk) ||
			isNone(subscription.investmentPreferences.recommandedRisk) ||
			isNone(subscription.investmentPreferences.portfolioType)
		) {
			return generatedPortfolioStep;
		}

		if (subscription.envelope.type === 'PER' && !hasCompletedKycFoyerFiscal(kyc)) {
			return generatedFiscaliteStep;
		}

		if (!hasCompletedKyc(kyc, statusPj)) {
			return generatedKYCStep;
		}

		return isCustom ? generatedSignatureStep : generatedRecapStep;
	}, [
		generatedFiscaliteStep,
		generatedKYCStep,
		generatedPortfolioStep,
		generatedProjectStep,
		generatedRecapStep,
		generatedSignatureStep,
		isFetchingJustificatif,
		isKYCFetching,
		isSubscriptionLoading,
		kyc,
		statusPj,
		subscription,
		isCustom,
	]);

	const isAtLeastAtStep = useCallback(
		(step: InvestSubscriptionSteps) => {
			if (isNone(mostAdvancedStep)) return false;

			const indexActualStep = steps.findIndex((s) => s.id === mostAdvancedStep.id);
			const indexDesiredStep = steps.findIndex((s) => s.id === step);
			return indexActualStep >= indexDesiredStep;
		},
		[mostAdvancedStep, steps],
	);

	useEffect(() => {
		if (isNotNone(mostAdvancedStep)) dispatch(setLastStepAvailable(mostAdvancedStep.id));
	}, [mostAdvancedStep, dispatch]);

	useEffect(() => {
		// if subscription is signed (or later), we redirect the user out of the parcours
		if (
			subscription &&
			subscription.status !== SubscriptionStatus.CREATED &&
			subscription.status !== SubscriptionStatus.GENERATED
		)
			navigate('/souscriptions');
	}, [subscription?.status, navigate, subscription]);

	const completedStepGuard = useCallback(
		(step: InvestSubscriptionSteps) => {
			if (isSubscriptionLoading || isKYCFetching || isNone(mostAdvancedStep)) return undefined;

			// if user is authorized to access the step, we let him go
			if (isAtLeastAtStep(step)) return <Outlet />;

			// else we redirect him to the most advanced step he can access
			return <Navigate to={mostAdvancedStep.url} replace />;
		},
		[isSubscriptionLoading, isKYCFetching, mostAdvancedStep, isAtLeastAtStep],
	);

	// route guard that prevents the user from going further if subscription doesn't exist
	const subscriptionExistsGuard = useCallback(
		() => (subscription ? <Outlet /> : <Navigate to={steps[0].url} replace />),
		[subscription, steps],
	);

	const projectNext = useCallback(
		(data: CreateInvestSimulation) => {
			updateSubscription({
				initialDepositAmount: data.initialAUM,
				projectType: data.type,
				recurrentDepositAmount: data.saving,
				subscriptionId: subscription!.id,
			})
				.unwrap()
				.then(() => {
					navigate(generatedPortfolioStep.url);
				})
				.catch(() => {
					toast({
						title: 'Une erreur est survenue.',
						description: "Nous n'avons pas pu valider vos informations.",
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				});
		},
		[navigate, subscription, toast, updateSubscription, generatedPortfolioStep.url],
	);

	const customProjectNext = useCallback(() => {
		navigate(generatedPortfolioStep.url);
	}, [navigate, generatedPortfolioStep.url]);

	const portfolioNext = useCallback(() => {
		navigate(subscription!.envelope.type === 'PER' ? generatedFiscaliteStep.url : generatedKYCStep.url);
	}, [navigate, subscription, generatedKYCStep.url, generatedFiscaliteStep.url]);

	const fiscaliteNext = useCallback(() => {
		navigate(generatedKYCStep.url);
	}, [generatedKYCStep.url, navigate]);

	const recapNext = useCallback(() => {
		navigate(generatedSignatureStep.url);
	}, [generatedSignatureStep.url, navigate]);

	const signatureNext = useCallback(() => {
		navigate('/souscriptions');
	}, [navigate]);

	const onboardingObject = useMemo<OnboardingObject | undefined>(() => {
		if (!subscription) return undefined;
		return {
			id: subscription.id,
			table: 'subscription',
			initialAUM: +subscription.initialDepositAmount,
			type: subscription.projectType,
			investmentPreferences: subscription.investmentPreferences,
			saving: +subscription.recurrentDepositAmount,
			extraData: subscription.extraData,
			timeHorizon: subscription.timeHorizon,
		};
	}, [subscription]);

	if (isSubscriptionLoading || isKYCFetching || isFetchingJustificatif || !onboardingObject || !subscription)
		return <FullscreenLoader />;

	return (
		<Routes>
			<Route
				element={
					<StepperLayout
						headerTitle={subscription.envelope.type === 'AV' ? 'Assurance vie' : 'PER'}
						headerSubtitle={isCustom ? 'Ouverture contrat sur-mesure' : 'Ouverture Contrat'}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						steps={steps}
						basePath={`/invest/souscription/${subscription.id}`}
					/>
				}
			>
				<Route
					path={`${generatedProjectStep.url}/*`}
					element={
						isCustom ? (
							<CustomProject object={onboardingObject} onNext={customProjectNext} />
						) : (
							<Project object={onboardingObject} onNext={projectNext} />
						)
					}
				/>

				<Route element={subscriptionExistsGuard()}>
					<Route element={completedStepGuard(generatedProjectStep.id)}>
						<Route
							path={`${generatedPortfolioStep.url}/*`}
							element={
								isCustom ? (
									<CustomPortfolio object={onboardingObject} onNext={portfolioNext} />
								) : (
									<Portfolio object={onboardingObject} onNext={portfolioNext} />
								)
							}
						/>
					</Route>

					<Route element={completedStepGuard(generatedPortfolioStep.id)}>
						<Route
							path={`${generatedFiscaliteStep.url}/*`}
							element={<Fiscalite flow="subscription" object={subscription} onNext={fiscaliteNext} />}
						/>
					</Route>

					<Route element={completedStepGuard(generatedFiscaliteStep.id)}>
						<Route
							path={`${generatedKYCStep.url}/*`}
							element={
								<KYCWrapper
									envelope={subscription.envelope.type}
									redirectOnEnd={`../${isCustom ? generatedSignatureStep.url : generatedRecapStep.url}`}
								/>
							}
						/>
					</Route>

					<Route element={completedStepGuard(generatedKYCStep.id)}>
						{!isCustom && (
							<Route
								path={`${generatedRecapStep.url}/*`}
								element={<Recapitulatif object={onboardingObject} onNext={recapNext} />}
							/>
						)}
						<Route
							path={`${generatedSignatureStep.url}/*`}
							element={
								<SignatureWrapper
									displayDeduction={displayDeduction}
									displayVersements={!isCustom}
									object={onboardingObject}
									onNext={signatureNext}
								/>
							}
						/>
					</Route>
				</Route>
			</Route>
			{isCustom ? (
				<Route path="*" element={<Navigate to={generatedProjectStep.url} replace />} />
			) : (
				mostAdvancedStep && <Route path="*" element={<Navigate to={mostAdvancedStep.url} replace />} />
			)}
		</Routes>
	);
};

export default InvestSubscriptionRoutes;
