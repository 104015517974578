import { Box, Heading, Image, Stack, Text, VStack, Wrap } from '@chakra-ui/react';

import { PEFund, PrivateEquitySubscription, SCPIFund, SCPISubscription } from 'store/types/airtable.type';

type CardFundDashboardProps = {
	subscription: SCPISubscription | PrivateEquitySubscription;
	fund: SCPIFund | PEFund;
};
const CardFundDashboard = ({ subscription, fund }: CardFundDashboardProps) => (
	<Stack direction={{ base: 'column', md: 'row' }} spacing="24px">
		<Box borderRadius="base" maxW={{ base: '100%', md: '400px' }} h="218px">
			<Image h="100%" w="100%" src={subscription.fundCoverPicture} alt={subscription.fundName} objectFit="cover" />
		</Box>
		<VStack align="start">
			<Text>{subscription.fundPartnerName}</Text>
			<Heading>{subscription.fundName}</Heading>
			<Wrap shouldWrapChildren>
				{fund.sectors?.map((sector) => (
					<Text
						key={sector}
						color="yellow.900"
						fontSize="12px"
						fontWeight={500}
						borderStyle="solid"
						borderColor="yellow.900"
						borderRadius="2px"
						borderWidth="1px"
						p="4px 8px"
					>
						{sector}
					</Text>
				))}
			</Wrap>
		</VStack>
	</Stack>
);

export default CardFundDashboard;
