import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
	Box,
	Button,
	Card,
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Image,
	ModalBody,
	ModalFooter,
	Skeleton,
	Text,
	VStack,
} from '@chakra-ui/react';
import { DotSpinner } from '@uiball/loaders';

import Elite from 'assets/images/PortofolioTypes/Elite.png';
import BankAccountCard from 'components/bankingInformations/BankAccountCard';
import Banner from 'components/Banners/Banner';
import { ControlledNumberInput } from 'components/inputs/NumberInput';
import { VersementEligibilityError } from 'components/versements/VersementEligibilityError';
import { useGetBankAccountsQuery } from 'services/requests/bank';
import { useSwitchEliteInformationsQuery } from 'services/requests/invest/switch-elite';
import { useIsVersementEligibleQuery, VersementEligibility } from 'services/requests/versement';
import { PopulatedInvestContract } from 'store/types/contract.type';
import { PortfolioType } from 'store/types/profilage.type';
import { BankInformations } from 'store/types/subscription.type';
import { isNone, isNotNone } from 'utils/functions';
import { displayMoneyNoDigits } from 'utils/rendering';

const CardElite = () => {
	return (
		<Card
			w="100%"
			position="relative"
			variant="portfolio"
			padding="0"
			// @ts-ignore
			type={'ELITE' as PortfolioType}
			overflow="hidden"
		>
			<Image position="absolute" top="0" right="0" src={Elite} height="100%" />
			<VStack
				zIndex={1} // zIndex used or the image will be on top of the text
				w="100%"
				align="left"
				padding="16px 24px"
				spacing="6px"
			>
				<VStack w="100%" align="left" spacing="2px">
					<Heading variant="Title-M-Bold" color="primary.black">
						Ramify Elite
					</Heading>
					<Text variant="Text-S-Regular" color="grey.900">
						Diversification maximum avec fonds de private equity inclus dans le portefeuille
					</Text>
				</VStack>

				<Link to="https://www.ramify.fr/ramify-elite" target="_blank">
					<Text variant="Text-S-Medium" textDecoration="underline">
						Voir plus de détails
					</Text>
				</Link>
			</VStack>
		</Card>
	);
};

type UpgradeEliteInformationProps = {
	contract: PopulatedInvestContract;
	onNext: ({ bankAccountId, amountDeposit }: { bankAccountId: string; amountDeposit: number }) => void;
};

const UpgradeEliteInformation = ({ contract, onNext }: UpgradeEliteInformationProps) => {
	const { data: information, isLoading: isInformationSwitchEliteLoading } = useSwitchEliteInformationsQuery({
		id: contract.id,
	});
	const { data: bankAccountList, isLoading: bankAccountListLoading } = useGetBankAccountsQuery();
	const { data: eligibility, isLoading: eligibilityLoading } = useIsVersementEligibleQuery({
		contractId: contract.id,
		type: 'COMPLEMENTAIRE',
	});
	const minimumDepositAmount = useMemo(() => information?.MINIMAL_AMOUNT, [information?.MINIMAL_AMOUNT]);

	const methods = useForm<{ amountDeposit: number; bankAccountId: string }>();
	const {
		control,
		watch,
		formState: { errors, isValid },
		setValue,
		clearErrors,
		handleSubmit,
	} = methods;
	const selectedBankAccountId = watch('bankAccountId');

	const onSubmit = handleSubmit(({ amountDeposit, bankAccountId }) => {
		onNext({ amountDeposit, bankAccountId });
	});

	useEffect(() => {
		if (information?.MINIMAL_AMOUNT) setValue('amountDeposit', information.MINIMAL_AMOUNT);
	}, [information?.MINIMAL_AMOUNT, setValue]);

	useEffect(() => {
		if (!selectedBankAccountId && bankAccountList?.length) {
			setValue('bankAccountId', bankAccountList[0].id);
			clearErrors('bankAccountId');
		}
	}, [bankAccountList, selectedBankAccountId, setValue, clearErrors]);

	return (
		<>
			<ModalBody py="24px">
				{eligibilityLoading || isInformationSwitchEliteLoading || bankAccountListLoading ? (
					<HStack>
						<DotSpinner size={20} />
						<Text>Nous vérifions votre égibilité pour cette opération</Text>
					</HStack>
				) : isNotNone(eligibility) && eligibility.eligibility !== VersementEligibility.ELIGIBLE ? (
					<VersementEligibilityError versementEligibility={eligibility.eligibility} />
				) : (
					<VStack w="100%" spacing="24px" align="start">
						<CardElite />
						<Banner
							title="Montant minimum d'investissement"
							description="Le portefeuille elite est disponible à partir de 50 000€ d'investissement sur un seul contrat."
							variant="information"
							avatar="arnaud"
						/>
						{minimumDepositAmount ? (
							<chakra.form>
								<VStack w="100%" spacing="24px" align="start">
									<Text>
										Afin de passer à Ramify Elite, vous aurez besoin d'effectuer un versement complémentaire d'un
										montant minimum de <b>{displayMoneyNoDigits(minimumDepositAmount)}</b> sur votre{' '}
										{contract.envelope.type === 'AV' ? 'assurance vie' : 'PER individuel'}
									</Text>
									<FormControl isInvalid={!!errors.amountDeposit}>
										<FormLabel>Montant du versement</FormLabel>

										<ControlledNumberInput
											rules={{ required: true, min: minimumDepositAmount }}
											name="amountDeposit"
											control={control}
											placeholder={`Minimum ${displayMoneyNoDigits(minimumDepositAmount)}`}
										/>

										{errors.amountDeposit && (
											<FormErrorMessage>
												Le montant minimum pour passer à Ramify Elite est de{' '}
												{displayMoneyNoDigits(minimumDepositAmount)}
											</FormErrorMessage>
										)}
									</FormControl>

									<FormControl isInvalid={!!errors.bankAccountId}>
										<FormLabel>Quel compte souhaitez-vous utiliser pour cette opération ?</FormLabel>
										<Controller
											name="bankAccountId"
											control={control}
											rules={{ required: true }}
											render={({ field: { onChange, value, ref, name } }) => (
												<VStack as="fieldset" spacing="16px" align="start" ref={ref} name={name}>
													{bankAccountList && bankAccountList.length > 0 ? (
														bankAccountList.map((bankAccount: BankInformations) => (
															<Box key={bankAccount.id} w="100%">
																<BankAccountCard
																	IBAN={bankAccount.IBAN}
																	name={bankAccount.holder}
																	onSelect={() => onChange(bankAccount.id)}
																	value={value === bankAccount.id}
																/>
															</Box>
														))
													) : (
														<Text variant="Text-M-Regular" color="grey.900">
															Vous n'avez pas de compte bancaire enregistré...
														</Text>
													)}
												</VStack>
											)}
										/>
										{errors.bankAccountId?.type === 'required' && bankAccountList?.length === 0 && (
											<FormErrorMessage>
												{bankAccountList && bankAccountList.length > 0
													? 'Merci de sélectionner le compte bancaire que vous souhaitez utiliser pour votre souscription'
													: 'Veuillez ajouter un compte bancaire pour continuer'}
											</FormErrorMessage>
										)}
									</FormControl>
								</VStack>
							</chakra.form>
						) : (
							<Skeleton w="100%" h="200px" />
						)}
					</VStack>
				)}
			</ModalBody>
			<ModalFooter borderTop="1px solid" borderColor="grey.300">
				<HStack w="100%" spacing="24px" justifyContent="end">
					<Button
						isDisabled={!isValid || isNone(eligibility) || eligibility.eligibility !== VersementEligibility.ELIGIBLE}
						onClick={onSubmit}
					>
						<Text variant="Text-S-Medium">Suivant</Text>
					</Button>
				</HStack>
			</ModalFooter>
		</>
	);
};

export default UpgradeEliteInformation;
