import { useEffect, useState } from 'react';

import { useGetContractsQuery } from 'services/requests/invest/contracts';

interface UseInvestHasContractsReturnType {
	hasContractOpened: boolean;
	isLoading: boolean;
}

const useInvestHasContracts = (): UseInvestHasContractsReturnType => {
	const [hasContractOpened, setHasContractOpened] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const { data: contracts, isFetching: isContractsLoading } = useGetContractsQuery();

	useEffect(() => {
		if (isContractsLoading) {
			setIsLoading(true);
		} else if (!!contracts) {
			setHasContractOpened(contracts.length > 0);
			setIsLoading(false);
		} else {
			setHasContractOpened(false);
			setIsLoading(false);
		}
	}, [isContractsLoading, setIsLoading, contracts]);

	return { hasContractOpened, isLoading };
};

export default useInvestHasContracts;
