import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useGotoCashOnboarding from 'hooks/useGotoCashOnboarding';
import {
	useGetAllSavingsAccountsQuery,
	useGetSavingsAccountsSubscriptionsQuery,
} from 'services/requests/savingsAccounts';
import {
	SavingsAccountInformation,
	SavingsAccountProvider,
	SavingsAccountStatus,
	SavingsAccountType,
	SavingsAccountWithHistory,
} from 'store/types/savingsAccount.type';
import { isNotNone } from 'utils/functions';

export type CERCallToActionType = {
	label: string;
	variant: 'primary' | 'secondary';
	onClick: () => void;
};

const useCERCallToAction = (): {
	cta: CERCallToActionType;
	isLoading: boolean;
	openedCER?: SavingsAccountWithHistory;
	availableForSubscriptionCER?: SavingsAccountInformation[];
	pendingSignedCER?: SavingsAccountInformation[];
} => {
	const navigate = useNavigate();
	const { goToOnboarding: goToCerOnboarding } = useGotoCashOnboarding();

	const { data: savingsAccounts, isLoading: isGetSavingAccountLoading } = useGetAllSavingsAccountsQuery();
	const { data: subscriptions, isLoading: isGetSubscriptionsLoading } = useGetSavingsAccountsSubscriptionsQuery();

	const openedCER = useMemo(
		() =>
			savingsAccounts?.find(
				(savingAccount) =>
					savingAccount.provider === SavingsAccountProvider.MY_MONEY_BANK &&
					savingAccount.type === SavingsAccountType.CER &&
					savingAccount.status === SavingsAccountStatus.COMPLETED,
			),
		[savingsAccounts],
	);

	const pendingSignedCER = useMemo(
		() =>
			subscriptions?.filter(
				(savingAccount) =>
					savingAccount.provider === SavingsAccountProvider.MY_MONEY_BANK &&
					savingAccount.type === SavingsAccountType.CER &&
					(savingAccount.status === SavingsAccountStatus.SIGNED ||
						savingAccount.status === SavingsAccountStatus.SENT_TO_PARTNER ||
						savingAccount.status === SavingsAccountStatus.WAITING_FOR_PAYMENT),
			),
		[subscriptions],
	);

	const availableForSubscriptionCER = useMemo(
		() =>
			subscriptions?.filter(
				(savingAccount) =>
					savingAccount.provider === SavingsAccountProvider.MY_MONEY_BANK &&
					savingAccount.type === SavingsAccountType.CER &&
					savingAccount.status === SavingsAccountStatus.REQUESTED,
			),
		[subscriptions],
	);

	const cta = useMemo(() => {
		if (isNotNone(openedCER)) {
			return {
				label: 'Visualiser',
				variant: 'secondary' as const,
				onClick: () => navigate(`/cash/dashboard/${openedCER.id}`),
			};
		}
		if (isNotNone(pendingSignedCER) && pendingSignedCER.length > 0) {
			return {
				label: "En cours d'ouverture",
				variant: 'primary' as const,
				onClick: () => navigate(`/cash/souscriptions/${pendingSignedCER[0].id}`),
			};
		}
		if (isNotNone(availableForSubscriptionCER) && availableForSubscriptionCER.length > 0) {
			return {
				label: 'Reprendre la souscription',
				variant: 'primary' as const,
				onClick: goToCerOnboarding,
			};
		}
		return {
			label: 'Ouvrir un livret',
			variant: 'primary' as const,
			onClick: goToCerOnboarding,
		};
	}, [openedCER, pendingSignedCER, availableForSubscriptionCER, goToCerOnboarding, navigate]);

	return {
		cta,
		isLoading: isGetSavingAccountLoading || isGetSubscriptionsLoading,
		openedCER: openedCER,
		pendingSignedCER,
		availableForSubscriptionCER,
	};
};

export default useCERCallToAction;
