import { FC } from 'react';
import {
	chakra,
	Divider,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	UseDisclosureReturn,
	useMediaQuery,
} from '@chakra-ui/react';

import { useAppResponsive } from 'hooks/useAppResponsive';

export const CalendlyStep: FC<{ url: string } & Pick<UseDisclosureReturn, 'onClose'>> = ({ onClose, url }) => {
	const [isHigh] = useMediaQuery('(min-height: 720px)');
	const isWide = useAppResponsive({ base: false, xl: true });
	const isBlackOffer = location.pathname.includes('offre-black');

	return (
		<Modal size={isHigh && isWide ? '6xl' : 'full'} isOpen onClose={onClose}>
			<ModalOverlay />
			<ModalContent bg={isBlackOffer ? 'primary.black' : 'white'}>
				<ModalHeader>Prendre rendez-vous</ModalHeader>

				<ModalCloseButton color={isBlackOffer ? 'white' : 'primary.black'} />
				<Divider />

				<ModalBody>
					<chakra.iframe minH="720px" w="100%" src={url} />
				</ModalBody>

				<Divider />
			</ModalContent>
		</Modal>
	);
};
