import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from '@carbon/icons-react';
import { Card, Hide, HStack, IconButton, Image, Show, Text, VStack } from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import { PrivateEquitySubscriptionMetrics } from 'services/requests/privateEquity';
import { ImmobilierSubscriptionMetrics } from 'services/requests/realEstate';
import { PrivateEquitySubscription, SCPISubscription } from 'store/types/airtable.type';

import { ProductMetrics } from '../../InvestmentProductCard';

export const OpenedFund: FC<{
	subscription: SCPISubscription | PrivateEquitySubscription;
	metrics?: ImmobilierSubscriptionMetrics | PrivateEquitySubscriptionMetrics;
}> = ({ subscription, metrics }) => {
	const navigate = useNavigate();

	return (
		<Card
			size="sm"
			w="100%"
			cursor="pointer"
			_hover={{ borderColor: 'grey.900' }}
			onClick={() => {
				navigate(subscription.id, { preventScrollReset: true });
			}}
		>
			<HStack transition="all 0.3s ease-out" justify="space-between" spacing="16px" bg="white">
				<HStack
					spacing={{ base: '8px', lg: '16px' }}
					minW={{ base: undefined, lg: '350px' }}
					flex={{ base: 1, lg: undefined }}
				>
					<Image
						src={subscription.fundCoverPicture}
						alt={subscription.fundName}
						borderRadius="full"
						boxSize="48px"
						fit="cover"
					/>

					<VStack align="start">
						<Text variant="Text-M-Medium">{subscription.fundName}</Text>
						<Text variant="Caption" color="grey.700">
							{subscription.fundPartnerName}
						</Text>
					</VStack>
				</HStack>

				{metrics && (
					<ProductMetrics
						data={{
							status: 'OPENED',
							performance: {
								value: metrics.added_value ?? 0,
								percentage: metrics.tri ?? 0,
							},
							valuation: 'capital_value' in metrics ? metrics.capital_value : metrics.capital_valorisation,
						}}
						date={subscription.lastValuationDate}
					/>
				)}

				<Show above="lg">
					<ArrowButton variant="tertiary">Voir</ArrowButton>
				</Show>

				<Hide above="lg">
					<IconButton variant="tertiary" boxSize="24px" aria-label="details" as={ChevronRight} />
				</Hide>
			</HStack>
		</Card>
	);
};
