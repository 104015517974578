import { useMemo } from 'react';
import { Box, Card, chakra, Text, VStack } from '@chakra-ui/react';
import { Layer, ResponsiveLine } from '@nivo/line';

import { useAppResponsive } from 'hooks/useAppResponsive';

const accumulate = (arr: number[]): number[] => {
	let sum = 0;
	return arr.reduce<number[]>((result, value) => {
		sum += value;
		result.push(sum);
		return result;
	}, []);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }: any) =>
	series.map(({ id, data }: { id: string; data: any }) => (
		<chakra.path
			key={id}
			d={lineGenerator(
				data.map((d: any) => ({
					x: xScale(d.data.x),
					y: yScale(d.data.y),
				})),
			)}
			fill="none"
			stroke={id === 'versement' ? '#D2D2D2' : 'rgba(0,0,0,0)'}
			strokeWidth={id === 'versement' ? 2 : 1}
		/>
	));
/* eslint-enable @typescript-eslint/no-explicit-any */

type CapitalProjectionProps = {
	projectionData: { index: number[]; valo: number[]; div: number[] };
};

const CapitalProjection = ({ projectionData }: CapitalProjectionProps): JSX.Element => {
	const isMobile = useAppResponsive({ base: true, lg: false });

	const cumulatedDividends = useMemo(() => accumulate(projectionData.div), [projectionData.div]);

	const minData = useMemo(() => Math.min(...projectionData.valo), [projectionData.valo]);
	const maxData = useMemo(
		() => Math.max(...projectionData.valo, ...cumulatedDividends.map((v, i) => v + projectionData.valo[i])),
		[cumulatedDividends, projectionData.valo],
	);

	const graphData = useMemo(
		() => [
			{
				id: 'Capital',
				data: projectionData.index.map((v, i) => ({
					x: v,
					y: Math.round(projectionData.valo[i]).toFixed(0),
				})),
			},
			...(projectionData.div.reduce<number>((acc, v) => acc + v, 0) > 0
				? [
						{
							id: 'Avec dividendes cumulés',
							data: projectionData.index.map((v, i) => ({
								x: v,
								y: Math.round(projectionData.valo[i] + cumulatedDividends[i]).toFixed(0),
							})),
						},
				  ]
				: []),
		],
		[projectionData.index, projectionData.div, projectionData.valo, cumulatedDividends],
	);

	return (
		<Card w="100%">
			<VStack align="start" spacing="32px">
				<Text variant="title">Capital constitué</Text>
				<Text variant="body">Valeur de cession et dividendes cumulés projetés (net de frais et net d'impôt).</Text>

				<Box height="400px" w="100%">
					<ResponsiveLine
						margin={{ bottom: 60, left: 110, top: 40, right: 40 }}
						axisBottom={{
							tickValues: isMobile ? 2 : 5,
							tickSize: 0,
							tickPadding: 12,
							tickRotation: 0,
							legend: 'Années',
							legendPosition: 'middle',
							legendOffset: 50,
						}}
						axisLeft={{
							tickValues: 5,
							tickSize: 0,
							tickPadding: 24,
							tickRotation: 0,
							legend: 'Capital (€)',
							legendPosition: 'middle',
							legendOffset: -100,
							format: (v) => `${v.toLocaleString('fr-FR', undefined)} €`,
						}}
						data={graphData}
						colors={['#FBD462', 'rgba(0, 0, 0, 0.5)']}
						enableSlices="x"
						xScale={{ type: 'linear' }}
						yScale={{ type: 'linear', min: minData * 0.8, max: maxData * 1.25 }}
						layers={['areas', 'grid', DashedSolidLine, 'lines', 'crosshair', 'slices', 'axes'] as Layer[]}
						enableArea
						areaOpacity={1}
						axisRight={null}
						axisTop={null}
						enableGridX={false}
						gridYValues={5}
						areaBaselineValue={minData * 0.8}
						defs={[
							{
								id: 'capital-area',
								type: 'linearGradient',
								colors: [
									{ offset: 0, color: 'rgba(251, 212, 98, 0.2)' },
									{ offset: 100, color: 'rgba(251, 212, 98, 0.2)' },
								],
							},
							{
								id: 'empty-area',
								type: 'linearGradient',
								colors: [
									{ offset: 0, color: 'rgba(0,0,0,0)' },
									{ offset: 100, color: 'rgba(255,255,255,0.3)' },
								],
							},
						]}
						fill={[
							{ match: { id: 'Capital' }, id: 'capital-area' },
							{ match: { id: 'Avec dividendes cumulés' }, id: 'empty-area' },
						]}
						theme={{
							fontFamily: 'Poppins',
							fontSize: 14,
							grid: { line: { strokeDasharray: '6px' } },
						}}
					/>
				</Box>
			</VStack>
		</Card>
	);
};
export default CapitalProjection;
