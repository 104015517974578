import { Card, Image, Link, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import Dario from 'assets/team/Dario.png';
import Nils from 'assets/team/nils.png';
import Amelie from 'assets/team/smallAmelie.png';
import Arnaud from 'assets/team/smallArnaud.png';
import Aymane from 'assets/team/smallAymane.png';
import Hugo from 'assets/team/smallHugo.png';
import Nicolas from 'assets/team/smallNicolas.png';
import Olivier from 'assets/team/smallOlivier.png';
import Samy from 'assets/team/smallSamy.png';
import { ArrowButton } from 'components/Button/Button';

const Team = (): JSX.Element => (
	<VStack>
		<Wrap maxW="480px" spacing={{ base: '16px', md: '24px' }}>
			<WrapItem>
				<Image borderRadius="full" w="48px" src={Samy} />
			</WrapItem>
			<WrapItem>
				<Image borderRadius="full" w="48px" src={Olivier} />
			</WrapItem>
			<WrapItem>
				<Image borderRadius="full" w="48px" src={Aymane} />
			</WrapItem>
			<WrapItem>
				<Image borderRadius="full" w="48px" src={Nils} />
			</WrapItem>
			<WrapItem>
				<Image borderRadius="full" w="48px" src={Hugo} />
			</WrapItem>
		</Wrap>
		<Wrap maxW="480px" spacing={{ base: '16px', md: '24px' }}>
			<WrapItem>
				<Image borderRadius="full" w="48px" src={Amelie} />
			</WrapItem>
			<WrapItem>
				<Image borderRadius="full" w="48px" src={Nicolas} />
			</WrapItem>
			<WrapItem>
				<Image borderRadius="full" w="48px" src={Dario} />
			</WrapItem>
			<WrapItem>
				<Image borderRadius="full" w="48px" src={Arnaud} />
			</WrapItem>
		</Wrap>
	</VStack>
);

const Advisor = () => {
	return (
		<Card w="100%">
			<VStack w="100%" spacing="40px" align="start">
				<VStack w="100%" spacing="16px" align="start">
					<Text variant="Title-M-SemiBold">Des questions au sujet de votre simulation ?</Text>
					<Text variant="Text-M-Regular" color="grey.900">
						Affinez votre projet avec un expert du portfolio management dédié et bénéficiez de conseils personnalisés
					</Text>
				</VStack>

				<VStack align="center" w="100%" spacing="24px">
					<Team />
					<Link href="https://j3ksz7q85rt.typeform.com/to/YInLTumy" isExternal textDecoration="none !important">
						<ArrowButton size="lg">Prendre rendez-vous avec un conseiller</ArrowButton>
					</Link>
				</VStack>

				<Text variant="Text-S-Regular" color="grey.900">
					*Ramify est une société de gestion de patrimoine enregistrée à l’ORIAS au numéro 20009289 et agréée par l’AMF
					(autorité des marchés financiers). Vos fonds sont hébergés par Apicil, 3ème groupe de protection sociale en
					France. Passer par un acteur de confiance nous permet de vous offrir une expérience d’investissement
					sécurisée.
				</Text>
			</VStack>
		</Card>
	);
};

export default Advisor;
