import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';

const ModalInformingAcceptancyCondition = ({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}): JSX.Element => (
	<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
		<ModalOverlay />
		<ModalContent>
			<ModalHeader>Condition non acceptée</ModalHeader>
			<ModalCloseButton data-cy="close-acceptancy-modal" />
			<ModalBody>
				<VStack w="100%" padding="16px">
					<Text fontSize="14px" opacity="0.80" variant="label">
						La souscription aux produits Ramify n'est pas possible pour les personnes ayant des obligations fiscales aux
						États-Unis.
					</Text>
				</VStack>
			</ModalBody>
		</ModalContent>
	</Modal>
);

export default ModalInformingAcceptancyCondition;
