import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import {
	chakra,
	Divider,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	UseDisclosureReturn,
	useMediaQuery,
} from '@chakra-ui/react';

import { useAppResponsive } from 'hooks/useAppResponsive';
import { useGetKYCQuery } from 'services/requests/kyc';
import { useGetUserQuery } from 'services/requests/user';

import { IntestedProducts, useBuildCalendlyUrl } from './useBuildIframeUrl';

export type FormContent = {
	amount: number;
};

// usefull for mixpanel
// useCalendlyEventListener({
// 	onProfilePageViewed: () => console.log('onProfilePageViewed'),
// 	onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
// 	onEventTypeViewed: () => console.log('onEventTypeViewed'),
// 	onEventScheduled: (e: any) => console.log(e.data.payload),
// });
// function isCalendlyEvent(e: any) {
// 	return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
// }
// window.addEventListener('message', function (e) {
// 	if (isCalendlyEvent(e)) {
// 		/* Example to get the name of the event */
// 		console.log('Event name:', e.data.event);
// 		/* Example to get the payload of the event */
// 		console.log('Event details:', e.data.payload);
// 	}
// });

export const CalendlyStep: FC<{ product: IntestedProducts | undefined } & Pick<UseDisclosureReturn, 'onClose'>> = ({
	onClose,
	product,
}) => {
	const { watch } = useFormContext<FormContent>();
	const { data: kyc } = useGetKYCQuery();
	const { data: user } = useGetUserQuery();

	const amount = watch('amount');

	const urlWithSearchParams = useBuildCalendlyUrl(amount, product, user, kyc);

	const [isHigh] = useMediaQuery('(min-height: 720px)');
	const isWide = useAppResponsive({ base: false, xl: true });

	return (
		<Modal size={isHigh && isWide ? '6xl' : 'full'} isOpen onClose={onClose}>
			<ModalOverlay />
			<ModalContent bg="white">
				<ModalHeader>Prendre rendez-vous</ModalHeader>

				<ModalCloseButton color="primary.black" />
				<Divider />

				<ModalBody>
					<chakra.iframe minH="720px" w="100%" src={urlWithSearchParams} />
				</ModalBody>

				<Divider />
			</ModalContent>
		</Modal>
	);
};
