import colors from '../foundations/colors';

const Link = {
	baseStyle: {
		_focus: {
			border: '0px solid rgba(0, 0, 0, 0)',
			boxShadow: `0px 0px 0px 2px ${colors.yellow[300]}`,
		},
	},
	defaultProps: {},
};

export default Link;
