import React, { LegacyRef } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

type SliderButtonProps = {
	name: string;
	selected: boolean;
};

export const RiskSliderButton = React.forwardRef(
	({ name, selected, bg, ...rest }: SliderButtonProps & ButtonProps, ref?: LegacyRef<HTMLButtonElement>) => (
		<Button
			flex={1}
			ref={ref}
			bg="transparent"
			maxH="33px"
			color={selected ? 'primary.black' : 'grey.900'}
			fontWeight={selected ? 600 : 400}
			_hover={{}}
			_focus={{}}
			_active={{}}
			{...rest}
		>
			{name}
		</Button>
	),
);

export const TemperatureSliderButton = React.forwardRef(
	({ name, selected, ...rest }: SliderButtonProps & ButtonProps, ref?: LegacyRef<HTMLButtonElement>) => (
		<Button
			flex={1}
			ref={ref}
			maxH="33px"
			fontWeight={selected ? 600 : 400}
			{...rest}
			variant={selected ? 'primary' : 'secondary'}
		>
			{name} °C
		</Button>
	),
);
