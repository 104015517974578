import { backendApi } from 'services/apiService';
import { PopulatedInvestContract } from 'store/types/contract.type';

interface TaxEconomySynthesis {
	totalTaxEconomy: number;
	availableYears: number[];
}

export type TransfertStatus = {
	'allocation transfert': 'OK' | 'To be done' | 'Blocked';
	client: string;
	'date demande': string;
	email: string;
	montant: number;
	statut: 'Pris en charge par Apicil' | 'Formulaire envoyé au client';
};

export type InvestSavingHistory = {
	success: boolean;
	message?: string;
	data: {
		montantVersements: number;
		valuation: number;
		date: Date;
	}[];
};

type RessourceDTO = {
	code: string;
	libelle: string;
};

type BaseMouvementDTO = {
	compartiment?: RessourceDTO;
	dateOperation: string;
	dateReglement?: string;
	montantOperation: number;
	typeMouvement: string;
	typeOperation: string;
};

export type DocumentDetails = {
	docId: string;
	codeTypeDoc: string;
	dateNumerisation: string;
	direction: 'SORTANT' | 'ENTRANT';
	catDoc: string;
	sousCategorieDocument: string;
};

export type ListContractDocumentsResponse = {
	currentPage: number;
	totalElements: number;
	maxPerPage: number;
	content: DocumentDetails[];
};

export type MouvementDTO = BaseMouvementDTO & {
	sousOperations?: BaseMouvementDTO[];
};

export type ApicilContractDTO = {
	arbitrageProgramme: number;
	arbitrageProgrammeActive: string;

	// statutCode: ApicilContractStatutType;

	cadreFiscal: string;
	clientId?: number;

	codeProduit: string;
	contratId: string;

	cumulRachatDerniereAnnee: number;
	cumulRachatPartiel: number;

	dateEffet?: Date;

	dateEffetFiscal?: Date;
	dateEncaissement: Date;

	dateValorisationEncours: Date;
	dateVersementInitial: Date;

	dureeContrat: string;

	typeProduit: string;
	nomContrat: string;

	mandatDeGestion?: string;

	montantEncours: number;
	montantEncoursAffiche?: number;
	montantEncoursNonValorise: number;

	soldeNonValorise?: number;

	valeurRachat?: number;
	versementProgramme: number;
	versementProgrammeActive: string;

	tauxFraisEncoursUniteCompte: number;

	// portefeuille: SupportContratDto[];

	souscripteur: {
		nomNaissance: string;
		nom: string;
		prenom: string;
	};
};

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getContracts: builder.query<PopulatedInvestContract[], void>({
			query: () => '/contracts',
			providesTags: ['InvestContracts'],
		}),
		getContract: builder.query<PopulatedInvestContract, { contractId: string }>({
			query: ({ contractId }) => `/contracts/${contractId}`,
			providesTags: ['InvestContracts'],
		}),
		getContractMouvements: builder.query<MouvementDTO[], { contractId: string }>({
			query: ({ contractId }) => `/contracts/${contractId}/mouvements`,
			providesTags: ['InvestContracts'],
		}),
		getInvestmentHistory: builder.query<InvestSavingHistory, string[] | void>({
			query: (contractsIdsToExclude) => ({
				url: `/contracts/all/history`,
				params: { excludeContractIds: contractsIdsToExclude },
			}),
			providesTags: ['InvestContracts'],
		}),
		getContractApicilInfos: builder.query<ApicilContractDTO, { contractId: string }>({
			query: ({ contractId }) => `/contracts/${contractId}/apicilInfos`,
			providesTags: ['InvestContracts'],
		}),
		listContractDocuments: builder.query<ListContractDocumentsResponse, { contractId: string }>({
			query: ({ contractId }) => `/contracts/${contractId}/documents`,
			providesTags: ['InvestContracts'],
		}),
		getTaxEconomy: builder.query<TaxEconomySynthesis, { year?: number; excludeContractIds?: string[] }>({
			query: ({ year, excludeContractIds }) => ({
				url: '/contracts/all/tax-economy',
				params: { year, excludeContractIds },
			}),
			providesTags: ['InvestContracts', 'Fiscalite'],
		}),
		getPERTransfertStatus: builder.query<TransfertStatus[], void>({
			query: () => '/contracts/all/transfert',
			providesTags: ['InvestContracts'],
		}),
	}),
});

export const {
	useListContractDocumentsQuery,
	useGetContractsQuery,
	useGetContractQuery,
	useGetContractMouvementsQuery,
	useGetInvestmentHistoryQuery,
	useGetContractApicilInfosQuery,
	useGetTaxEconomyQuery,
	useGetPERTransfertStatusQuery,
} = extendedApi;
