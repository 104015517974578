import { HStack, Text, VStack } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';

export type TooltipData = {
	title: string;
	value: string | number;
};

export default function TooltipGraphMobile({ tooltipProps }: { tooltipProps: TooltipData[] }) {
	return (
		<VStack align="start">
			{tooltipProps.map((tooltipProp) => (
				<HStack key={tooltipProp.title}>
					<Text variant="Text-S-Medium" color={colors.grey[700]}>
						{tooltipProp.title}
					</Text>
					<Text variant="Text-S-Medium" color={colors.grey[900]}>
						{tooltipProp.value}
					</Text>
				</HStack>
			))}
		</VStack>
	);
}
