import { Link } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { Button, Heading, Skeleton, Text, VStack } from '@chakra-ui/react';

import { SimulationTable } from 'pages/PrivateEquity/Simulations/SimulationTable';
import eventTracker from 'services/events/eventTracker';
import { useGetPrivateEquitySimulationsQuery } from 'services/requests/privateEquity/simulations';

const RecommandationPE = (): JSX.Element => {
	const { data: simulations, isLoading } = useGetPrivateEquitySimulationsQuery();

	return (
		<VStack spacing="lg" align="start" w="100%">
			<Heading variant="Title-L-SemiBold">Mes Simulations</Heading>

			<Button
				as={Link}
				to="nouvelle"
				variant="primary"
				size="lg"
				rightIcon={<AddIcon />}
				onClick={() => eventTracker.mixpanel.newSimulation('PE')}
			>
				Commencer une nouvelle simulation
			</Button>

			<Skeleton isLoaded={!isLoading} w="100%">
				{simulations && simulations.length > 0 ? (
					<SimulationTable simulations={simulations} type="PE" />
				) : (
					<Text variant="Text-M-SemiBold">Aucune simulation n'a été trouvée</Text>
				)}
			</Skeleton>
		</VStack>
	);
};

export default RecommandationPE;
