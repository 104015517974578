import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Input,
	Radio,
	RadioGroup,
	Select,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import { getRoundedTimeHorizon, TimeHorizon, timeHorizonLabels } from 'onboarding/Invest/Shared/Objective/projectData';
import { OnboardingUpdateStepProps } from 'onboarding/Stepper/stepper.slice';
import { useUpdateRiskMutation } from 'services/requests/invest/preferences';
import { FinancialLostType, LostReactionType } from 'store/types/profilage.type';
import { isNone, isNotNone } from 'utils/functions';

import { RiskAversionQuestions } from './riskQuestions.data';
import { tooltipRiskAversion } from './TooltipRiskAversion';

export type RiskAversionForm = {
	timeHorizon?: TimeHorizon;
	birthYear?: number;
	workedYears?: number;
	lostReaction: LostReactionType;
	financialLost: FinancialLostType;
	hasEverUsedSavingPlan: 'true' | 'false';
};

const RiskAversion = ({ object: onboardingObject, onNext, onPrev }: OnboardingUpdateStepProps): JSX.Element => {
	const toast = useThemedToast();
	const [updateRisk] = useUpdateRiskMutation();

	const {
		formState: { errors },
		register,
		control,
		setValue,
		handleSubmit,
	} = useForm<RiskAversionForm>();

	useEffect(() => {
		if (onboardingObject.timeHorizon)
			setValue('timeHorizon', getRoundedTimeHorizon(onboardingObject.timeHorizon!).value);
		if (onboardingObject.extraData) {
			setValue('workedYears', onboardingObject.extraData?.workedYears);
			setValue('birthYear', onboardingObject.extraData?.birthYear);
		}
		if (onboardingObject.investmentPreferences) {
			setValue(
				'hasEverUsedSavingPlan',
				onboardingObject.investmentPreferences.hasEverUsedSavingPlan ? 'true' : 'false',
			);
			setValue('financialLost', onboardingObject.investmentPreferences.financialLost);
			setValue('lostReaction', onboardingObject.investmentPreferences.lostReaction);
		}
	}, [onboardingObject, setValue]);

	const onSubmit = handleSubmit((formData: RiskAversionForm) => {
		const { timeHorizon, birthYear, workedYears, hasEverUsedSavingPlan, financialLost, lostReaction } = formData;
		if (
			isNotNone(onboardingObject) &&
			(onboardingObject.type === 'RETIREMENT'
				? birthYear &&
				  birthYear?.toString().length > 0 &&
				  workedYears !== undefined &&
				  workedYears?.toString().length > 0
				: timeHorizon !== undefined && timeHorizon?.toString().length > 0)
		) {
			updateRisk({
				timeHorizon: onboardingObject.type !== 'RETIREMENT' ? Number(timeHorizon) : undefined,
				birthYear: onboardingObject.type === 'RETIREMENT' ? Number(birthYear) : undefined,
				workedYears: onboardingObject.type === 'RETIREMENT' ? Number(workedYears) : undefined,
				hasEverUsedSavingPlan: hasEverUsedSavingPlan === 'true',
				financialLost,
				lostReaction,
				id: onboardingObject.id,
				entityName: onboardingObject.table,
			})
				.unwrap()
				.then(() => {
					onNext();
				})
				.catch(() => {
					toast({
						title: 'Erreur',
						description: 'Une erreur est survenue lors de la sauvegarde',
						status: 'error',
						duration: 4500,
						isClosable: true,
					});
				});
		} else {
			toast({
				title: 'Champs manquants',
				description: 'Veuillez remplir tous les champs',
				status: 'error',
				duration: 4500,
				isClosable: true,
			});
		}
	});

	return (
		<VStack w="100%" align="start" spacing="24px">
			<Heading variant="Title-XL-Bold">Mon profil de risque</Heading>
			<chakra.form onSubmit={onSubmit} w="100%">
				<VStack align="start" w="100%" spacing="24px">
					<Wrap w="100%" spacingX="24px" spacingY="16px">
						{onboardingObject.type === 'RETIREMENT' ? (
							<>
								{/* Ask for birthYear */}
								<WrapItem w={onboardingInputWidth} maxW="100%">
									<FormControl isInvalid={!!errors.birthYear}>
										<FormLabel>
											<HStack>
												<>Année de naissance</>
												<CustomTooltip text={tooltipRiskAversion.retirement} />
											</HStack>
										</FormLabel>
										<Input
											{...register('birthYear', {
												required: { value: true, message: 'Ce champ est requis' },
												min: {
													// Should never happen
													value: new Date().getFullYear() - 122 + 1,
													message: "La date renseignée n'est pas valide",
												},
												max: {
													value: new Date().getFullYear() + 1,
													message: "La date renseignée n'est pas valide",
												},
											})}
											placeholder="Ex: 1982"
											type="number"
										/>
										{errors.birthYear && <FormErrorMessage>{errors.birthYear.message}</FormErrorMessage>}
									</FormControl>
								</WrapItem>
								{/* Ask for workedYears */}
								<WrapItem w={onboardingInputWidth} maxW="100%">
									<FormControl isInvalid={!!errors.workedYears}>
										<FormLabel>
											<HStack>
												<>Nombre d'années travaillées</>
												<CustomTooltip text={tooltipRiskAversion.retirement} />
											</HStack>
										</FormLabel>
										<Input
											{...register('workedYears', {
												required: { value: true, message: 'Ce champs est requis' },
												min: { value: 0, message: "La valeur renseignée n'est pas valide" },
												max: { value: 90, message: "La valeur renseignée n'est pas valide" },
											})}
											type="number"
											placeholder="Ex: 15"
										/>
										{errors.workedYears && <FormErrorMessage>{errors.workedYears.message}</FormErrorMessage>}
									</FormControl>
								</WrapItem>
							</>
						) : (
							<WrapItem w={onboardingInputWidth} maxW="100%">
								<FormControl isInvalid={!!errors.timeHorizon}>
									<FormLabel>
										<HStack>
											<>Durée de l'épargne</>
											<CustomTooltip text={tooltipRiskAversion.timeHorizon} />
										</HStack>
									</FormLabel>
									<Select
										placeholder="-"
										{...register('timeHorizon', { required: { value: true, message: 'Ce champs est requis' } })}
									>
										{timeHorizonLabels.map((o) => (
											<option value={o.value} key={o.value}>
												{o.label}
											</option>
										))}
									</Select>

									{errors.timeHorizon && <FormErrorMessage>{errors.timeHorizon.message}</FormErrorMessage>}
								</FormControl>
							</WrapItem>
						)}
					</Wrap>

					{RiskAversionQuestions.map((question) => (
						<FormControl isInvalid={!!errors[question.key]} key={question.key}>
							<FormLabel>
								<HStack>
									<>{question.question}</>
									<CustomTooltip text={question.whyThisQuestion} />
								</HStack>
							</FormLabel>
							<Controller
								name={question.key}
								control={control}
								rules={{
									validate: {
										required: (value) => {
											if (isNone(value)) return 'Ce champ est requis';
											return true;
										},
									},
								}}
								render={({ field: { onChange, value, ref, ...rest } }) => (
									<RadioGroup variant="single" size="sm" onChange={onChange} value={value} {...rest}>
										<VStack spacing="8px" w={{ base: '100%', md: '380px' }}>
											{question.answers.map((choice) => (
												<Radio value={choice.value} key={choice.value}>
													{choice.text}
												</Radio>
											))}
										</VStack>
									</RadioGroup>
								)}
							/>
							{errors[question.key] && <FormErrorMessage>{errors[question.key]?.message}</FormErrorMessage>}
						</FormControl>
					))}
				</VStack>
			</chakra.form>
			<BottomStepBar onPrev={onPrev} onNext={onSubmit} showNext />
		</VStack>
	);
};

export default RiskAversion;
