import { environmentConstants } from 'utils/constants';

type EnvironmentState = {
	isProduction: boolean;
	isStaging: boolean;
	isLocal: boolean;
	isProductionOrStaging: boolean;
};

export const getAppEnvironment = (): EnvironmentState => ({
	isProduction: environmentConstants.environment === 'production',
	isStaging: environmentConstants.environment === 'staging',
	isLocal: environmentConstants.environment === 'local',
	isProductionOrStaging:
		environmentConstants.environment === 'production' || environmentConstants.environment === 'staging',
});
