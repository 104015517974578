import { backendApi } from 'services/apiService';

import { AuthResponse } from './auth';

type ValidateEmailRequestParams = {
	token: string;
	from: string;
};

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		validateEmail: builder.mutation<AuthResponse, ValidateEmailRequestParams>({
			query: (params) => ({
				url: '/users/validate',
				method: 'POST',
				body: params,
			}),
		}),
		validateEmailCorporate: builder.mutation<AuthResponse, string>({
			query: (token) => ({
				url: '/users/validate-corporate',
				method: 'POST',
				body: { token },
			}),
		}),
		requestValidate: builder.query<void, string>({
			query: (redirect) => ({
				url: '/users/request-validate',
				method: 'POST',
				params: {
					redirect,
				},
			}),
		}),
		requestValidateCorporate: builder.query<void, string>({
			query: (email) => ({
				url: '/users/request-validate-corporate',
				method: 'POST',
				params: {
					email,
				},
			}),
		}),
	}),
});

export const {
	useValidateEmailMutation,
	useValidateEmailCorporateMutation,
	useLazyRequestValidateQuery,
	useLazyRequestValidateCorporateQuery,
} = extendedApi;
