import { useMemo } from 'react';
import { Box, Card, Heading, HStack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import { displayMoney, displayPercentage } from 'utils/rendering';

type ImmoSimulationMetricsProps = {
	annual_dividends_net_of_fees_and_tax: number;
	capital_evolution: number;
	tri: number;
};

const ImmoSimulationMetrics = ({
	annual_dividends_net_of_fees_and_tax,
	capital_evolution,
	tri,
}: ImmoSimulationMetricsProps) => {
	const metrics = useMemo(
		() => [
			{
				title: 'Rendement total',
				metric: displayPercentage(capital_evolution),
				label: "Net de frais et d'impôt",
				tooltip:
					'Retour sur investissement net de frais et d’impôt hors dividendes distribués. Ce retour est calculé en comparant le prix estimé à la cession des parts et le montant initial investi. L’évolution du capital est plus importante dans le cas d’un investissement en nue-propriété ou dans des SCPI dites de “valorisation”.',
			},
			{
				title: 'Dividendes annuels',
				metric: displayMoney(annual_dividends_net_of_fees_and_tax),
				label: "Net de frais et d'impôt",
				tooltip:
					'Dividendes annuels totaux nets de frais et d’impôt. Le montant des dividendes annuels est plus important dans le cas d’un investissement en pleine propriété dans les SCPI dîtes de “rendement”.',
			},
			{
				title: 'TRI',
				metric: displayPercentage(tri),
				label: "Net de frais et d'impôt",
				tooltip:
					"Le taux de rentabilité interne (TRI) est un indicateur important qui prend en compte tous les flux (achats, ventes, revenus, frais, fiscalité...) et ramène tout sur un rendement annuel. C'est un indicateur qui est donc avant tout financier, une aide à la décision avant tout investissement. ",
			},
		],
		[annual_dividends_net_of_fees_and_tax, capital_evolution, tri],
	);

	return (
		<Wrap w="100%" overflow="visible" spacing="16px">
			{metrics.map(({ title, metric, label, tooltip }) => (
				<WrapItem key={title} flex="1" minW="280px">
					<Card w="100%" size="sm">
						<VStack spacing="16px" align="start" w="100%">
							<HStack align="start" w="100%" justify="space-between">
								<Heading variant="Title-S-SemiBold">{title}</Heading>
								<Box>{tooltip && <CustomTooltip text={tooltip} />}</Box>
							</HStack>

							<HStack>
								<Heading variant="Title-M-Bold" color="blue.900">
									{metric}
								</Heading>
								{label && <Text color="grey.900">{label}</Text>}
							</HStack>
						</VStack>
					</Card>
				</WrapItem>
			))}
		</Wrap>
	);
};

export default ImmoSimulationMetrics;
