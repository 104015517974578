import { backendApi } from 'services/apiService';
import { ProposalDTO } from 'store/types/airtable.type';

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getProposals: builder.query<ProposalDTO[], void>({
			query: () => '/proposals',
		}),
		getLatestProposal: builder.query<ProposalDTO | undefined, void>({
			query: () => '/proposals/latest',
			providesTags: ['Proposals'],
		}),
	}),
});

export const { useGetProposalsQuery, useGetLatestProposalQuery } = extendedApi;
