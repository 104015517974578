import React, { Dispatch, SetStateAction } from 'react';
import { localPoint } from '@visx/event';
import { scaleLinear, scaleTime } from '@visx/scale';
import { useTooltip } from '@visx/tooltip';
import { bisector, extent } from 'd3-array';

import GraphLine from 'components/Graphs/visx/GraphLine';
import TooltipDesktop from 'components/Graphs/visx/ToolTipGraphDesktop';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { InvestSavingHistory } from 'services/requests/invest/contracts';
import { displayMoney } from 'utils/rendering';

type LineProps = {
	firstDate?: Date;
	setCurrentValue: Dispatch<SetStateAction<{ date: Date; valuation: number; montantVersements: number } | undefined>>;
	width: number;
	height: number;
	margin?: { top: number; right: number; bottom: number; left: number };
	data: InvestSavingHistory;
};

const Line = ({
	firstDate,
	setCurrentValue,
	width,
	height,
	data,
	margin = { top: 30, right: 0, bottom: 30, left: 0 },
}: LineProps) => {
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;
	const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<{
		date: Date;
		valuation: number;
		montantVersements: number;
	}>({
		tooltipOpen: true,
	});

	// bounds
	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;

	const today = new Date();
	const timeScale = scaleTime<number>({
		domain: extent(data.data, (d) => new Date(d.date)) as [Date, Date],
	});
	timeScale.range([0, xMax]);
	if (firstDate !== undefined) {
		timeScale.domain(scaleTime<number>({ domain: [firstDate, today] }).domain());
	}

	const filteredData = data.data.filter(
		(d) => new Date(d.date) <= timeScale.domain()[1] && new Date(d.date) >= timeScale.domain()[0],
	);

	const valuationScale = scaleLinear<number>({
		domain: [
			Math.min(...filteredData.map((d) => d.valuation * 0.97)),
			Math.max(...filteredData.map((d) => d.valuation * 1.03)),
		],
		range: [yMax, 0],
	});

	const bisectDate = bisector<InvestSavingHistory['data'][0], Date>((d) => new Date(d.date)).left;
	const handleMouseMove: React.MouseEventHandler<SVGPathElement> & React.TouchEventHandler<SVGRectElement> = (
		event,
	) => {
		const { x } = localPoint(event) || { x: xMax };
		const realX = x - margin.left;
		const x0 = timeScale.invert(realX);
		const index = bisectDate(filteredData, x0, 1);
		const d0 = filteredData[index - 1];
		const d1 = filteredData[index];
		let d = d0 && d0.date ? d0 : d1;
		if (d0 && d0.date && d1 && d1.date) {
			d = x0.valueOf() - new Date(d0.date).valueOf() > new Date(d1.date).valueOf() - x0.valueOf() ? d1 : d0;
		}

		setCurrentValue(d);
		if (d) {
			showTooltip({
				tooltipData: d,
				tooltipLeft: timeScale(new Date(d.date)),
				tooltipTop: valuationScale(d.valuation),
			});
		}
	};

	return (
		<>
			<GraphLine
				yMax={yMax}
				xMax={xMax}
				width={width}
				height={isMobile ? 222 : height}
				margin={margin}
				timeScale={timeScale}
				valuationScale={valuationScale}
				filteredData={filteredData}
				tooltipData={tooltipData}
				tooltipLeft={tooltipLeft}
				tooltipTop={tooltipTop}
				hideTooltip={hideTooltip}
				handleMouseMove={handleMouseMove}
			/>

			{tooltipOpen && tooltipData && !isMobile && (
				<TooltipDesktop
					tooltipData={[
						{ title: 'Capital :', value: displayMoney(tooltipData.valuation) },
						{
							title: 'Versements :',
							value: displayMoney(tooltipData?.montantVersements),
						},
					]}
					tooltipLeft={tooltipLeft ?? 0}
					tooltipTop={tooltipTop ?? 0}
				/>
			)}
		</>
	);
};

export default Line;
