import { FC } from 'react';
import { ArrowDownLeft, ArrowUpRight } from '@carbon/icons-react';
import { HStack, Text, Wrap } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';
import { isNone, isNotNone } from 'utils/functions';
import { displayMoney, displayPercentage } from 'utils/rendering';

type PerformanceBadgeProps = {
	amount?: number;
	percentage?: number;
	isTitle?: boolean;
};

const PerformanceIcon: FC<{ isPositive: boolean }> = ({ isPositive }) =>
	isPositive ? <ArrowUpRight color={colors.positive[900]} /> : <ArrowDownLeft color={colors.negative[900]} />;

// we must use isNone and isNotNone; 0 is falsy but we still want to display it
export const PerformanceBadge: FC<PerformanceBadgeProps> = ({ amount, percentage, isTitle = false }) => {
	if (isNone(amount) && isNone(percentage)) return null;

	return (
		<Wrap align="center" shouldWrapChildren>
			{isNotNone(amount) && (
				<Text
					variant={isTitle ? 'Title-L-Bold' : 'Text-M-Medium'}
					color={amount >= 0 ? 'positive.900' : 'negative.900'}
				>
					{amount >= 0 && '+'}
					{displayMoney(amount)}
				</Text>
			)}
			{isNotNone(percentage) && (
				<HStack
					p="4px 8px"
					spacing="4px"
					borderRadius="2px"
					bg={percentage >= 0 ? colors.positive[100] : colors.negative[100]}
				>
					<PerformanceIcon isPositive={percentage >= 0} />
					<Text variant="Text-S-Medium" color={percentage >= 0 ? 'positive.900' : 'negative.900'} lineHeight="100%">
						{percentage >= 0 ? '+' : '-'}&nbsp;
						{displayPercentage(Math.abs(percentage))}
					</Text>
				</HStack>
			)}
		</Wrap>
	);
};
