import { useState } from 'react';
import {
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import Amelie from 'assets/team/smallAmelie.png';
import SamyAdvisor from 'components/cards/SamyAdvisor';
import CustomModal from 'components/Modal';
import { Stepper } from 'components/navigation/Stepper';
import { useAppResponsive } from 'hooks/useAppResponsive';
import Informations from 'pages/Invest/Versements/UpdateVP/Steps/Informations';
import Signature from 'pages/Invest/Versements/UpdateVP/Steps/Signature';
import { PopulatedInvestContract } from 'store/types/contract.type';
import { BankInformations } from 'store/types/subscription.type';

export type UpdateVPData = {
	amount: number;
	bankInfo: BankInformations;
};

interface UpdateVPProps {
	onClose: () => void;
	onSuccess: () => void;
	contract: PopulatedInvestContract;
}

const UpdateVP = ({ onClose, onSuccess, contract }: UpdateVPProps): JSX.Element => {
	const modalSize = useAppResponsive({ base: 'sm', md: '4xl' });
	const [step, setStep] = useState(0);
	const [formData, setFormData] = useState<UpdateVPData>();
	const { isOpen: isVersementError, onOpen: onVersementError } = useDisclosure();

	const onSubmit = (data: UpdateVPData) => {
		setFormData(data);
		setStep(1);
	};

	if (isVersementError) {
		return (
			<CustomModal isOpen onClose={onClose} size="3xl">
				<SamyAdvisor
					complexText={
						<VStack align="start">
							<Heading variant="Title-S-SemiBold">Nous reprenons la main sur votre demande</Heading>

							<Text>
								Une erreur est survenue, nous ne pouvons pas valider automatiquement votre demande pour l’instant. Ne
								vous inquiétez pas, l’équipe support est informée et reviendra vers vous très vite !
							</Text>
						</VStack>
					}
					advisor={Amelie}
				/>
			</CustomModal>
		);
	}

	return (
		<Modal isOpen closeOnOverlayClick={false} onClose={onClose} size={modalSize} autoFocus={false}>
			<ModalOverlay />
			<ModalContent maxW="580px">
				<ModalCloseButton />
				<ModalBody>
					<VStack p="16px 8px" spacing="32px" align="start">
						<Text variant="Title-M-SemiBold">Modifier le versement programmé</Text>
						<Stepper
							alignSelf="center"
							w="80%"
							steps={[{ title: 'Montant' }, { title: 'Vérification' }]}
							index={step}
						/>
						{step === 0 && <Informations contract={contract} data={formData} onSubmit={onSubmit} />}
						{step === 1 && (
							<Signature
								contract={contract}
								data={formData!}
								edit={() => setStep(0)}
								onSuccess={onSuccess}
								onError={onVersementError}
							/>
						)}
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default UpdateVP;
