import { backendApi } from 'services/apiService';
import { User } from 'store/types/user.type';

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getUser: builder.query<User, void>({
			query: () => '/users/self',
			providesTags: ['User'],
		}),

		updateUserEntityType: builder.mutation<User, { legalEntityType: string }>({
			query: ({ legalEntityType }) => ({
				url: '/users/updateUserEntityType',
				method: 'POST',
				body: { legalEntityType },
			}),
			invalidatesTags: ['User', 'PE'],
		}),
	}),
});

export const { useGetUserQuery, useLazyGetUserQuery, useUpdateUserEntityTypeMutation } = extendedApi;
