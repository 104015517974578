import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import textStyles from 'theme/foundations/typography';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const menuVariant = defineMultiStyleConfig({
	baseStyle: {},
	variants: {
		legacy: {
			item: {
				_hover: {
					backgroundColor: 'grey.500',
				},
				_active: {
					backgroundColor: 'grey.500',
				},
				_focus: {
					backgroundColor: 'grey.500',
				},
			},
			list: {
				borderStyle: 'solid',
				borderWidth: '1px',
				borderColor: 'grey.700',
			},
		},
		new: {
			item: {
				...textStyles['Button-Medium'],
				_hover: {
					backgroundColor: 'yellow.100',
				},
				_focus: {
					backgroundColor: 'yellow.100',
				},
			},
			list: {
				borderRadius: 'base',
				borderStyle: 'solid',
				borderWidth: '1px',
				borderColor: 'grey.300',
			},
		},
	},
	defaultProps: {
		variant: 'new',
	},
});

export default menuVariant;
