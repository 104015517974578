import { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Heading, VStack } from '@chakra-ui/react';
import { Ring } from '@uiball/loaders';

import { useQuery } from 'hooks/useQuery';
import { useAppDispatch } from 'hooks/useStore';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useGetUserQuery, useLazyGetUserQuery } from 'services/requests/user';
import { useValidateEmailMutation } from 'services/requests/validateEmail';
import { setIsAuth, setToken } from 'store/auth.slice';
import api from 'utils/api';

import { ContainerAuthStyle } from './style';

const ApplyValidate = (): JSX.Element => {
	const query = useQuery();
	const { token } = useParams<{ token: string }>();
	const redirectUrlOnValidate = query.get('redirect') ?? '/';

	const toast = useThemedToast();
	const { data: user, isLoading: isUserLoading } = useGetUserQuery();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [retriggerUser] = useLazyGetUserQuery();
	const [validateEmail] = useValidateEmailMutation();

	const sendEmailValidationRequest = () => {
		validateEmail({ token: token || '', from: redirectUrlOnValidate })
			.unwrap()
			.then((response) => {
				api.setHeaderToken(response.access_token);
				localStorage.setItem('token', response.access_token);
				dispatch(setIsAuth(true));
				dispatch(setToken(response.access_token));
				eventTracker.emailValidated(user?.email || '');
				retriggerUser();
			})
			.catch((err) => {
				if ('status' in err) {
					// Email already validated
					if (err.status === 409) {
						toast({
							title: 'Email déjà validé',
							description: 'Votre email a déjà été validé, vous allez être redirigé vers la page de connexion.',
							status: 'info',
							duration: 9000,
							isClosable: true,
						});
					} else if (err.status === 406) {
						// Jwt expired
						toast({
							title: 'Lien expiré',
							description: 'Votre lien a expiré, vous allez être redirigé vers la page de connexion.',
							status: 'error',
							duration: 9000,
							isClosable: true,
						});
					} else {
						// Unknown error
						toast({
							title: "Une erreur s'est produite.",
							description:
								"Nous n'avons pas pu vérifier votre adresse email, vous allez être redirigé vers la page de connexion.",
							status: 'error',
							duration: 9000,
							isClosable: true,
						});
					}
					setTimeout(() => {
						navigate('/');
					}, 1000);
				}
			});
	};

	// Hook is called twitce otherwise
	let isFirstRequest = true;

	useEffect(() => {
		if (isUserLoading) return;
		if (!user?.emailValidated && isFirstRequest) {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			isFirstRequest = false;
			sendEmailValidationRequest();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, validateEmail]);

	if (user?.emailValidated) return <Navigate to={query.get('redirect') ?? '/'} replace />;

	return (
		<VStack {...ContainerAuthStyle}>
			<VStack align="center" spacing="12px" w="100%">
				<Heading variant="Text-L-SemiBold">Validation de votre adresse email</Heading>
			</VStack>

			<VStack align="center" spacing="16px" w="100%">
				<Ring />
			</VStack>
		</VStack>
	);
};

export default ApplyValidate;
