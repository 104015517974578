import { AffiliatesOffersCardContent } from './AffiliatesOffersCardTypes';

const offersData: AffiliatesOffersCardContent[] = [
	{
		offer: <>Gagnez 75&nbsp;€</>,
		offerSubLine: 'par personne parrainée',
		title: (
			<>
				Pour une première souscription en <b>SCPI</b> ou <b>Private Equity</b> (minimum&nbsp;1000€).
			</>
		),
		description: (
			<>
				Cette prime de 75 € est versée directement sur vos comptes bancaires, dans les 90 jours suivant la première
				souscription.
			</>
		),
	},
	{
		offer: <>3 mois</>,
		offerSubLine: 'de frais de gestion offerts',
		title: (
			<>
				Pour une première souscription à une <b>Assurance Vie</b> ou un <b>PER</b>.
			</>
		),
		description: <>Recevez 3 mois de frais de gestion offerts (jusqu'à 500 €) chacun.</>,
	},
];

export default offersData;
