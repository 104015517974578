import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';

import StepperLayout from 'app/Layout/Onboarding/StepperLayout';
import FullscreenLoader from 'components/FullscreenLoader';
import { useAppDispatch } from 'hooks/useStore';
import KYC from 'onboarding/KYC';
import { hasCompletedKyc } from 'onboarding/KYC/utils';
import { setLastStepAvailable } from 'onboarding/Stepper/stepper.slice';
import { useGetKYCJustificatifQuery, useGetKYCQuery } from 'services/requests/kyc';
import { useGetSavingsAccountQuery } from 'services/requests/savingsAccounts';
import { SavingsAccountStatus } from 'store/types/savingsAccount.type';
import { isNone, isNotNone } from 'utils/functions';

import { CashOnboardingSteps, souscriptionStep } from './Souscription/steps';
import Confirmation from './Confirmation';
import Project from './Project';
import Souscription from './Souscription';
import steps, { confirmationStep, kycStep, projectStep } from './steps';

const CashOnboardingRoutes: FC = () => {
	const { id } = useParams();
	const [collapsed, setCollapsed] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const { data: savingsAccount, isLoading: isAccountLoading } = useGetSavingsAccountQuery(id!);
	const { data: kyc, isFetching: isKYCFetching } = useGetKYCQuery();
	const { isFetching: isJustificatifFetching, data: statusPj } = useGetKYCJustificatifQuery();

	const mostAdvancedStep = useMemo(() => {
		if (isAccountLoading || isKYCFetching || isJustificatifFetching) return undefined;

		if (
			isNone(savingsAccount) ||
			isNone(savingsAccount.initialAUM) ||
			isNone(savingsAccount.moneySupplyFrequency) ||
			isNone(savingsAccount.timeHorizon)
		) {
			return projectStep;
		}

		if (isNone(kyc) || !hasCompletedKyc(kyc, statusPj)) {
			return kycStep;
		}

		if (
			isNone(kyc.nif) ||
			isNone(savingsAccount.bankInformationId) ||
			savingsAccount.status === SavingsAccountStatus.REQUESTED
		) {
			return souscriptionStep;
		}

		return confirmationStep;
	}, [isAccountLoading, isKYCFetching, isJustificatifFetching, savingsAccount, kyc, statusPj]);

	const isAtLeastAtStep = useCallback(
		(step: CashOnboardingSteps) => {
			if (isNone(mostAdvancedStep)) return false;

			const indexActualStep = steps.findIndex((s) => s.id === mostAdvancedStep.id);
			const indexDesiredStep = steps.findIndex((s) => s.id === step);
			return indexActualStep >= indexDesiredStep;
		},
		[mostAdvancedStep],
	);

	useEffect(() => {
		if (isNotNone(mostAdvancedStep)) dispatch(setLastStepAvailable(mostAdvancedStep.id));
	}, [mostAdvancedStep, dispatch]);

	const completedStepGuard = useCallback(
		(step: CashOnboardingSteps) => {
			if (isAccountLoading || isKYCFetching || isJustificatifFetching || isNone(mostAdvancedStep)) return undefined;

			// if user is authorized to access the step, we let him go
			if (isAtLeastAtStep(step)) return <Outlet />;

			// else we redirect him to the most advanced step he can access
			return <Navigate to={mostAdvancedStep.url} replace />;
		},
		[isAccountLoading, isKYCFetching, isJustificatifFetching, mostAdvancedStep, isAtLeastAtStep],
	);

	if (isAccountLoading || isKYCFetching) return <FullscreenLoader />;
	if (!savingsAccount) return <Navigate to="/cash" replace />;

	return (
		<Routes>
			<Route
				element={
					<StepperLayout
						headerTitle="Livret Épargne"
						headerSubtitle="Finalisation"
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						steps={steps}
						basePath={`/cash/onboarding/${id}`}
					/>
				}
			>
				<Route path={projectStep.url} element={<Project />} />

				<Route element={completedStepGuard(projectStep.id)}>
					<Route
						path={`${kycStep.url}/*`}
						element={
							<KYC
								product="epargne"
								steps={[
									'introduction',
									'etatCivil',
									'foyerFiscal',
									'professionelle',
									'coordonnees',
									'patrimoine',
									'justificatif',
									'recapitulatif',
								]}
								redirectOnEnd={`/cash/onboarding/${id}/${souscriptionStep.url}`}
							/>
						}
					/>
				</Route>

				<Route element={completedStepGuard(kycStep.id)}>
					<Route path={`${souscriptionStep.url}/*`} element={<Souscription />} />
				</Route>

				<Route element={completedStepGuard(souscriptionStep.id)}>
					<Route path={confirmationStep.url} element={<Confirmation />} />
				</Route>
			</Route>
			{mostAdvancedStep && <Route path="*" element={<Navigate to={mostAdvancedStep.url} replace />} />}
		</Routes>
	);
};

export default CashOnboardingRoutes;
