import { FC, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Card, Heading, Text, VStack, Wrap } from '@chakra-ui/react';

import FullscreenLoader from 'components/FullscreenLoader';
import InvestmentMetrics from 'components/Metrics/InvestmentMetrics';
import useInvestHasContracts from 'hooks/useInvestHasContracts';
import { useAppSelector } from 'hooks/useStore';
import ContractFilter from 'pages/Invest/features/ContractFilter/ContractFilter';
import { useGetContractsQuery } from 'services/requests/invest/contracts';
import { useGetRateOfReturnQuery } from 'services/requests/investment';
import { ProductData } from 'services/requests/product';
import { isNotNone } from 'utils/functions';

import ActionCards from './sections/ActionCards';
import CompositionPortfolio from './sections/CompositionPortfolio';
import PerformanceGraph from './sections/PerformanceGraph';
import RepartitionBarCard from './sections/RepartitionBar';

const DashboardInvest: FC = () => {
	const { inactiveList } = useAppSelector((state) => state.investContractFilter);
	const { hasContractOpened, isLoading } = useInvestHasContracts();
	const navigate = useNavigate();
	const { data: rateOfReturnData } = useGetRateOfReturnQuery(inactiveList);
	const { data: contracts } = useGetContractsQuery();

	const data = useMemo<ProductData>(() => {
		const contractsToConsider = contracts?.filter((c) => !inactiveList.includes(c.id));
		const performance = isNotNone(rateOfReturnData) ? rateOfReturnData.portfolio_return.valuePerformance : 0;
		const winLossValue = isNotNone(rateOfReturnData) ? rateOfReturnData.portfolio_return.percentagePerformance : 0;
		const totalVersements = contractsToConsider?.reduce((acc, c) => acc + +(c.totalVersement ?? 0), 0);
		const valuation = contractsToConsider?.reduce((acc, c) => acc + +(c.amount ?? 0), 0);

		return {
			status: 'AVAILABLE', // does not matter
			performance: {
				value: performance,
				percentage: winLossValue,
			},
			awaitingAUM: 0, // does not matter
			investedAmount: totalVersements,
			valuation: valuation,
		};
	}, [contracts, rateOfReturnData, inactiveList]);

	if (isLoading) return <FullscreenLoader />;
	if (!hasContractOpened) return <Navigate replace to="/invest" />;

	return (
		<VStack w="100%" spacing="32px" align="start">
			<Wrap shouldWrapChildren w="100%" justify="space-between">
				<Heading variant="Title-L-SemiBold">Votre tableau de bord</Heading>
				<ContractFilter />
			</Wrap>
			<VStack w="100%" spacing="24px">
				<InvestmentMetrics
					data={data}
					cta={
						<Button
							onClick={() => {
								navigate('/invest/versements?open=nouveau-versement');
							}}
						>
							Faire un versement
						</Button>
					}
				/>
				<PerformanceGraph />
				<ActionCards />
				<RepartitionBarCard />
				<Card w="100%">
					<VStack w="100%" spacing="24px" align="start">
						<VStack align="start">
							<Heading variant="Title-M-SemiBold">Composition de votre portefeuille - par fonds</Heading>
							<Text variant="Text-M-Regular" color="grey.900">
								En % de vos investissements
							</Text>
						</VStack>
						<CompositionPortfolio />
					</VStack>
				</Card>
			</VStack>
		</VStack>
	);
};

export default DashboardInvest;
