import colors from 'theme/foundations/colors';

const Badge = {
	baseStyle: {},
	sizes: {
		md: {
			borderRadius: '2px',
			borderWidth: '1px',
			padding: '4px 8px',
			fontWeight: '500',
			fontSize: '12px',
			textTransform: 'none',
		},
	},
	variants: {
		outlineWhite: {
			borderColor: colors.primary.white,
			color: colors.primary.white,
		},
		outlineYellow: {
			borderColor: colors.yellow[900],
			color: colors.yellow[900],
		},
		coloredBgBlack: {
			color: colors.primary.yellow,
			borderColor: colors.primary.black,
			bg: colors.primary.black,
		},
		coloredBgYellow: {
			color: colors.primary.black,
			borderColor: colors.primary.yellow,
			bg: colors.primary.yellow,
		},
	},
	defaultProps: {
		size: 'md',
		variant: 'coloredBgYellow',
	},
};

export default Badge;
