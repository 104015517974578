import { FC, useMemo } from 'react';
import { Box, Skeleton, Text, VStack } from '@chakra-ui/react';

import { CapitalGraphImmo } from 'pages/RealEstate/Dashboard/Charts/CapitalGraph';
import { useGetImmobilierDashboardQuery } from 'services/requests/realEstate';
import { SCPISubscription } from 'store/types/airtable.type';
import { isNone } from 'utils/functions';

const KpisSubscriptions: FC<{ subscriptions: SCPISubscription[] | undefined }> = ({ subscriptions }) => {
	const { data: allSub, isLoading } = useGetImmobilierDashboardQuery();

	const lastValuationDate = useMemo(
		() =>
			subscriptions
				?.filter((sub) => sub.lastValuationDate)
				.map((sub) => new Date(sub.lastValuationDate || ''))
				.sort((a, b) => (a > b ? -1 : 1))[0]
				?.toLocaleDateString('fr-FR', {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				}),
		[subscriptions],
	);

	if (isLoading) return <Skeleton height="300px" w="100%" />;
	if (isNone(allSub) || Object.keys(allSub).length === 0) return null;

	return (
		<VStack w="100%" align="start">
			{lastValuationDate && (
				<Text variant="Text-S-Medium" color="grey.700">
					Date de dernière valorisation : {lastValuationDate}
				</Text>
			)}

			{/* height to allow visx.ParentSize.height to be effective */}
			<Box height="222px" w="100%">
				<CapitalGraphImmo data={allSub} />
			</Box>
		</VStack>
	);
};

export default KpisSubscriptions;
