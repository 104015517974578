import KYC from 'onboarding/KYC';
import { KYCStepType } from 'onboarding/KYC/utils';
import { EnvelopeType } from 'store/types/global.type';
import { isNotNone } from 'utils/functions';

export const KYCWrapper = ({
	envelope,
	redirectOnEnd,
}: {
	envelope: EnvelopeType;
	redirectOnEnd: string;
}): JSX.Element => {
	const steps: (KYCStepType | undefined)[] = [
		'introduction',
		'etatCivil',
		'professionelle',
		envelope === 'AV' ? 'foyerFiscal' : undefined,
		'coordonnees',
		'patrimoine',
		'justificatif',
		'recapitulatif',
	];

	return <KYC product="invest" steps={steps.filter(isNotNone)} redirectOnEnd={redirectOnEnd} />;
};
