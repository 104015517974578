import { VStack } from '@chakra-ui/react';

import AirtableBanner from 'components/Banners/AirtableBanner';
import { SponsorshipInviteCard } from 'components/cards/SponsorshipInviteCard';

import AffiliatesList from './List/AffiliatesList';
import AffiliatesOffers from './Offers/AffiliatesOffers';
import AffiliatesCodes from './AffiliatesCodes';

const AffiliatePage = (): JSX.Element => (
	<VStack w="100%" spacing="xl" py="40px">
		<SponsorshipInviteCard />

		<AirtableBanner />

		<AffiliatesOffers />
		<AffiliatesCodes />
		<AffiliatesList />
	</VStack>
);

export default AffiliatePage;
