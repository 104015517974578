import { FormLabel, HStack } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';

type LabelWrapperInputProps = {
	label?: string;
	tooltip?: string;
	children: JSX.Element | JSX.Element[];
};

const LabelWrapperInput = ({ children, label, tooltip }: LabelWrapperInputProps): JSX.Element => (
	<>
		<FormLabel>
			<HStack>
				<>{label}</>
				{tooltip && <CustomTooltip text={tooltip} />}
			</HStack>
		</FormLabel>
		{children}
	</>
);

export default LabelWrapperInput;
