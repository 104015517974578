import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

import { IntentionModal, IntentionModalProps } from 'pages/RealEstate/Details';
import { scpiTagsDisplay } from 'pages/RealEstate/Discover/options.constants';
import eventTracker from 'services/events/eventTracker';
import { useGetSCPIFundDemembrementQuery } from 'services/requests/realEstate/funds';
import { SCPIFund } from 'store/types/airtable.type';
import { isNotNone } from 'utils/functions';
import { displayMoneyNoDigits, displayPercentage } from 'utils/rendering';

import CardCatalogue from '../CardCatalogue';

type CardPropsType = {
	fund: SCPIFund;
	disableAnimation?: boolean;
	mixpanel?: boolean;
	hideInvestButton?: boolean;
};

// TODO: refactor this, dirty hack to avoid fetching demembrement for every fund in the catalog - 24/03/2023
const IntentionModalWrapper = ({ isOpen, onClose, fundDetails: fund }: IntentionModalProps) => {
	const { data: fundDemembrement } = useGetSCPIFundDemembrementQuery(fund.id);

	return <IntentionModal isOpen={isOpen} onClose={onClose} fundDetails={fund} fundDemembrement={fundDemembrement} />;
};

const RealEstateCard = ({ fund, disableAnimation, mixpanel, hideInvestButton }: CardPropsType): JSX.Element => {
	const tdDefined = fund.expectedDistributionRate || (fund.distributionRateYear && fund.distributionRate);
	const ticket = fund.lastShareValuation * fund.minPPShares;
	const navigate = useNavigate();

	const { isOpen, onOpen, onClose } = useDisclosure();

	const tags = useMemo(() => fund.tags?.map((tag) => scpiTagsDisplay[tag]?.name).filter(isNotNone), [fund]);
	const features = useMemo(
		() =>
			[
				tdDefined
					? {
							name: `Taux de distribution ${
								fund.expectedDistributionRate ? 'espéré' : `brut ${fund.distributionRateYear}`
							}`,
							value: displayPercentage(fund.expectedDistributionRate ?? fund.distributionRate),
					  }
					: undefined,
				fund.meanRevalorisation
					? {
							name: 'Revalorisation moyenne',
							value: displayPercentage(fund.meanRevalorisation),
					  }
					: undefined,
				ticket
					? {
							name: 'A partir de',
							value: displayMoneyNoDigits(ticket),
					  }
					: undefined,
				fund.sectors
					? {
							name: 'Secteur',
							value: fund.sectors.join(', '),
					  }
					: undefined,
				fund.regions
					? {
							name: 'Géographie',
							value: fund.regions.join(', '),
					  }
					: undefined,
			].filter(isNotNone),
		[
			fund.distributionRate,
			fund.distributionRateYear,
			fund.expectedDistributionRate,
			fund.meanRevalorisation,
			fund.regions,
			fund.sectors,
			tdDefined,
			ticket,
		],
	);

	return (
		<>
			{fund && isOpen && <IntentionModalWrapper isOpen={isOpen} onClose={onClose} fundDetails={fund} />}
			<CardCatalogue
				name={fund.name}
				partner={fund.partner}
				description={{
					content: fund.description,
					notifyToggle: () => {
						eventTracker.pipedream.scpiInterest();
					},
				}}
				coverPicture={fund.fundCover?.[0]?.url}
				access={{ hasAccess: true }}
				tags={tags}
				details={{
					available: true,
					onDetailsClick: () => {
						eventTracker.pipedream.scpiInterest();
						navigate(fund.id, { preventScrollReset: true });
					},
				}}
				features={features}
				onInvest={
					hideInvestButton
						? undefined
						: () => {
								onOpen();
								eventTracker.pipedream.scpiInterest();
								if (mixpanel) {
									// eventTracker.resultSimulationPageClickedOnExtraInfo('SCPI'); // paul t'as changé qlch ici ?
								}
						  }
				}
				animate={!disableAnimation}
			/>
		</>
	);
};

export default RealEstateCard;
