import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, List, ListItem, Text, VStack } from '@chakra-ui/react';

import BottomStepBar from 'components/steppers/BottomStepBar';
import eventTracker from 'services/events/eventTracker';

const Confirmation = (): JSX.Element => {
	const navigate = useNavigate();

	useEffect(() => {
		eventTracker.mixpanel.track('SCPI Onboarding - Confirmation');
	}, []);

	return (
		<>
			<VStack align="start" spacing="24px">
				<Heading variant="Title-L-Bold">Votre demande de souscription a été prise en compte</Heading>
				<VStack align="start" spacing="16px">
					<Text variant="Text-M-Regular" color="grey.900">
						Nous reviendrons vers vous d’ici 2 jours ouvrés maximum pour vous faire signer votre bulletin ou bien pour
						vous demander des informations complémentaires le cas échéant (selon les partenaires).
					</Text>

					<Text variant="Text-M-Regular" color="grey.900">
						L'ouverture de votre contrat se déroulera en plusieurs étapes :
					</Text>

					<Box>
						<List spacing="24px">
							<ListItem>
								<Text variant="Text-M-Regular" color="grey.900">
									• <b>Etape 1 : </b>
									Validation de votre dossier par notre partenaire - en moyenne sous <b>2-3 jours ouvrés.</b>
								</Text>
							</ListItem>

							<ListItem>
								<Text variant="Text-M-Regular" color="grey.900">
									• <b>Etape 2 : </b>
									Réception des fonds - selon <b>votre mode de paiement </b>
									(virement ou prélèvement).
								</Text>
							</ListItem>

							<ListItem>
								<Text variant="Text-M-Regular" color="grey.900">
									• <b>Etape 3 : </b>
									Enregistrement de votre opération - en moyenne sous <b>1 à 2 semaines </b>
									selon les produits et partenaires.
								</Text>
							</ListItem>

							<ListItem>
								<Text variant="Text-M-Regular" color="grey.900">
									• <b>Etape 4 : </b>
									Réception de l’attestation de votre opération - en moyenne sous <b>2 semaines, </b>
									jusqu’à trimestriellement pour certains produits et partenaires.
								</Text>
							</ListItem>
						</List>
					</Box>

					<Text variant="Text-M-Bold">
						Vous pourrez ensuite suivre votre placement directement depuis votre espace client Ramify
					</Text>
				</VStack>
			</VStack>
			<BottomStepBar
				showPrev={false}
				textNext="Revenir à l'accueil"
				onNext={() => navigate(`/immobilier/souscriptions`)}
			/>
		</>
	);
};

export default Confirmation;
