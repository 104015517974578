import { TemperatureCelsius } from '@carbon/icons-react';
import { Card, Heading, HStack, Text, VStack } from '@chakra-ui/react';

import colors from 'theme/foundations/colors';

const ContractTemperatureCard = ({ temperature }: { temperature: number }) => (
	<Card w="100%" h="100%" pt="24px" pb="36px" pl="24px">
		<VStack align="start" spacing="10px" w="100%">
			<Text variant="caption" color={colors.grey[900]}>
				Score température
			</Text>
			<HStack>
				<Heading variant="Title-L-SemiBold" color={colors.positive[900]}>
					+ {temperature} °C
				</Heading>
				<TemperatureCelsius size="32" color={colors.positive[900]} />
			</HStack>
		</VStack>
	</Card>
);
export default ContractTemperatureCard;
