import { RefCallBack } from 'react-hook-form';
import { Button, Wrap, WrapItem } from '@chakra-ui/react';

const BadgeSelectInput = <T,>({
	options,
	ref,
	value: currentValue,
	onChange,
}: {
	options: readonly { label: string; value: T }[];
	ref: RefCallBack;
	value: T;
	onChange: (value: T) => void;
}): JSX.Element => (
	<Wrap>
		{options.map(({ label, value: newValue }) => (
			<WrapItem key={label}>
				<Button
					ref={ref}
					variant={currentValue === newValue ? 'primary' : 'secondary'}
					onClick={() => {
						onChange(newValue);
					}}
				>
					{label}
				</Button>
			</WrapItem>
		))}
	</Wrap>
);

export default BadgeSelectInput;
