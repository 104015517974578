import { drawerAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(drawerAnatomy.keys);

const Drawer = defineMultiStyleConfig({
	baseStyle: {
		body: {
			// Lorenzo & Dario: Add padding bottom on all drawer bodies
			// to avoid the content overflowing out of screen on IOS mobiles
			'@media screen and (max-width: 576px)': {
				pb: '300px',
			},
		},
	},
	sizes: {
		lg: {
			header: {
				px: '24px',
				pt: '24px',
			},
			body: {
				px: '24px',
			},
			footer: {
				px: '24px',
				pb: '24px',
			},
		},
		xl: {
			header: {
				px: '32px',
				pt: '32px',
			},
			body: {
				px: '32px',
			},
			footer: {
				px: '32px',
				pb: '32px',
			},
		},
		full: {
			header: {
				px: '16px',
				pt: '16px',
			},
			body: {
				px: '16px',
			},
			footer: {
				px: '16px',
				pb: '16px',
			},
		},
	},
});

export default Drawer;
