import { Heading, VStack } from '@chakra-ui/react';

// Components
import BottomStepBar from 'components/steppers/BottomStepBar';

import PatrimonyForm from '../../Forms/PatrimonyForm';
import { KycStepProps } from '../../utils';

const Patrimoine = ({ onNext, onPrev }: KycStepProps): JSX.Element => (
	<VStack w="100%" spacing="24px" align="left">
		<Heading variant="Title-XL-Bold">Mon patrimoine</Heading>

		<PatrimonyForm onSuccess={onNext} formConfirmation={<BottomStepBar onPrev={onPrev} showNext nextSubmit />} />
	</VStack>
);

export default Patrimoine;
