import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, FormControl, FormErrorMessage, FormLabel, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';

import Valide from 'assets/icons/Valide.svg';
import Warning from 'assets/icons/Warning.svg';
import { ControlledNumberInput } from 'components/inputs/NumberInput';
import ControlledRadioOptions from 'components/inputs/RadioOptions';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useGetSCPIKYCQuery, useUpdateSCPIKYCMutation } from 'services/requests/realEstate/kyc';
import { LengthPerceptionType, RiskPerceptionType, TaxType, UserScpiKyc } from 'store/types/scpiKyc.type';
import scrollToErrorOnForm from 'utils/errors/FormScrollToError';
import { isNotNone } from 'utils/functions';

// Referentiels
// ------------------------------

const taxTypeReferentiel: {
	label: string;
	value: TaxType;
}[] = [
	{
		label: 'IR',
		value: 'IR',
	},
	{
		label: 'IS',
		value: 'IS',
	},
];

const riskPerceptionReferentiel: {
	label: string;
	value: RiskPerceptionType;
}[] = [
	{
		label: 'Aucun risque',
		value: 'NORISK',
	},
	{
		label: 'Risque moyen',
		value: 'LOWRISK',
	},
	{
		label: 'Risque élevé',
		value: 'HIGHRISK',
	},
];

const lengthPerceptionReferentiel: {
	label: string;
	value: LengthPerceptionType;
}[] = [
	{
		label: 'Court-terme',
		value: 'SHORTERM',
	},
	{
		label: 'Moyen-terme',
		value: 'MIDTERM',
	},
	{
		label: 'Long-terme',
		value: 'LONGTERM',
	},
];

export const InformationsProduit = (): JSX.Element => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const [updateKyc] = useUpdateSCPIKYCMutation();
	const { data: scpiKyc } = useGetSCPIKYCQuery();

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors },
		watch,
	} = useForm<UserScpiKyc>();

	const [hasIFI, riskPerception, lengthPerception] = watch(['hasIFI', 'riskPerception', 'lengthPerception']);

	const isValidRisk = riskPerception === 'LOWRISK' || riskPerception === 'HIGHRISK';
	const isValidTerm = lengthPerception === 'MIDTERM' || lengthPerception === 'LONGTERM';

	useEffect(() => {
		if (scpiKyc) {
			if (isNotNone(scpiKyc.taxType)) setValue('taxType', scpiKyc.taxType);
			if (isNotNone(scpiKyc.riskPerception)) setValue('riskPerception', scpiKyc.riskPerception);
			if (isNotNone(scpiKyc.lengthPerception)) setValue('lengthPerception', scpiKyc.lengthPerception);
			if (isNotNone(scpiKyc.taxAmount)) setValue('taxAmount', +scpiKyc.taxAmount);
			if (isNotNone(scpiKyc.hasIFI)) setValue('hasIFI', scpiKyc.hasIFI);
			if (isNotNone(scpiKyc.taxIFIAmount)) setValue('taxIFIAmount', +scpiKyc.taxIFIAmount);
			if (isNotNone(scpiKyc.immoRevenusBrut)) setValue('immoRevenusBrut', +scpiKyc.immoRevenusBrut);
		}
	}, [setValue, scpiKyc]);

	const onSubmit = handleSubmit(
		(data: UserScpiKyc) => {
			updateKyc(data)
				.unwrap()
				.then(() => {
					navigate(`/immobilier/onboarding/${id}/reglementaire/test`);
				})
				.catch(() =>
					toast({
						title: 'Une erreur est survenue',
						status: 'error',
						duration: 9000,
						isClosable: true,
					}),
				);
		},
		() => scrollToErrorOnForm(errors),
	);

	useEffect(() => {
		eventTracker.mixpanel.track('SCPI Onboarding - Informations Produit');
	}, []);

	return (
		<form onSubmit={onSubmit}>
			<VStack align="start" spacing="24px">
				<Heading variant="Title-XL-Bold">Informations relatives au produit</Heading>
				<Text>
					Ces données sont confidentielles et nécessaires vis-à-vis de nos obligations réglementaires et en particulier
					concernant la lutte contre le blanchiment. Elles nous permettent, par ailleurs, de valider la bonne adéquation
					de votre souscription avec votre patrimoine et votre bonne information.
				</Text>

				<VStack align="start" spacing="16px">
					<FormControl isInvalid={!!errors.taxType}>
						<FormLabel>Êtes-vous asujetti à l’IR (impôt sur le revenus) ou IS (impôt sur les sociétés) ?</FormLabel>

						<ControlledRadioOptions
							name="taxType"
							control={control}
							options={taxTypeReferentiel}
							w={onboardingInputWidth}
						/>

						{errors.taxType?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					</FormControl>
				</VStack>

				<VStack align="start" spacing="16px">
					<FormControl isInvalid={!!errors.taxAmount}>
						<FormLabel>Quel montant l’année précédente ?</FormLabel>

						<ControlledNumberInput
							name="taxAmount"
							control={control}
							placeholder="Indiquez la somme en €"
							w={onboardingInputWidth}
						/>

						{errors.taxAmount?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
						{errors.taxAmount?.type === 'min' && (
							<FormErrorMessage>Le montant ne peut pas être négatif</FormErrorMessage>
						)}
					</FormControl>
				</VStack>

				<VStack align="start" spacing="16px">
					<FormControl isInvalid={!!errors.hasIFI}>
						<FormLabel>Êtes-vous asujetti à l’IFI (impôt sur la fortune immobilière) ?</FormLabel>

						<ControlledRadioOptions name="hasIFI" control={control} options="Yes-No" w={onboardingInputWidth} />

						{errors.hasIFI?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					</FormControl>
				</VStack>

				{hasIFI && (
					<VStack align="start" spacing="16px">
						<FormControl isInvalid={!!errors.taxIFIAmount}>
							<FormLabel>Quel est le montant IFI de l’année précédente ?</FormLabel>

							<ControlledNumberInput
								name="taxIFIAmount"
								control={control}
								placeholder="Indiquez la somme en €"
								w={onboardingInputWidth}
							/>

							{errors.taxIFIAmount?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
							{errors.taxIFIAmount?.type === 'min' && (
								<FormErrorMessage>Le montant ne peut pas être négatif</FormErrorMessage>
							)}
						</FormControl>
					</VStack>
				)}

				<VStack align="start" spacing="16px">
					<FormControl isInvalid={!!errors.immoRevenusBrut}>
						<FormLabel>Quel est le montant brut de vos revenus immobiliers ?</FormLabel>

						<ControlledNumberInput
							name="immoRevenusBrut"
							control={control}
							placeholder="Indiquez la somme en €"
							w={onboardingInputWidth}
						/>

						{errors.immoRevenusBrut?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
						{errors.immoRevenusBrut?.type === 'min' && (
							<FormErrorMessage>Le montant ne peut pas être négatif</FormErrorMessage>
						)}
					</FormControl>
				</VStack>

				<VStack align="start" spacing="16px">
					<FormControl isInvalid={!!errors.riskPerception}>
						<FormLabel htmlFor="cc">
							Quelle est votre perception du risque de perte en capital pour ce produit d’investissement ?
						</FormLabel>

						<ControlledRadioOptions
							name="riskPerception"
							control={control}
							options={riskPerceptionReferentiel}
							w={onboardingInputWidth}
							rules={{ required: true, validate: { goodChoice: (value) => !(value === 'NORISK') } }}
						/>

						{errors.riskPerception?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					</FormControl>
					{riskPerception && (
						<Card borderColor={isValidRisk ? 'grey.300' : 'red.500'} borderWidth="1px">
							<HStack align="center" spacing="16px">
								<Image src={isValidRisk ? Valide : Warning} alt={isValidRisk ? 'correct' : 'incorrect'} />

								<Text variant="label" fontWeight="400">
									Contrairement au Livret A par exemple, l’investissement en immobilier
									<Text color="primary.black" as="span" fontWeight="600">
										&nbsp; comporte des risques &nbsp;
									</Text>
									de perte en capital et les revenus dépendront de l’évolution du marché immobilier.
								</Text>
							</HStack>
						</Card>
					)}
				</VStack>
				{isValidRisk && (
					<VStack align="start" spacing="16px">
						<FormControl isInvalid={!!errors.lengthPerception}>
							<FormLabel>Selon vous, investir en immobilier est un investissement à horizon ?</FormLabel>

							<ControlledRadioOptions
								name="lengthPerception"
								control={control}
								options={lengthPerceptionReferentiel}
								w={onboardingInputWidth}
								rules={{ required: true, validate: { goodChoice: (value) => !(value === 'SHORTERM') } }}
							/>

							{errors.lengthPerception?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
						{lengthPerception && (
							<Card borderColor={isValidTerm ? 'grey.300' : 'red.500'} borderWidth="1px">
								<HStack align="center" spacing="16px">
									<Image src={isValidTerm ? Valide : Warning} alt={isValidTerm ? 'correct' : 'incorrect'} />

									<Text variant="label" fontWeight="400">
										Comme tout placement immobilier, il s’agit d’un
										<Text color="primary.black" as="span" fontWeight="600">
											&nbsp;investissement long terme.&nbsp;
										</Text>
										Nous vous recommandons une durée de placement de plus de 7 ans.
									</Text>
								</HStack>
							</Card>
						)}
					</VStack>
				)}
			</VStack>
			<BottomStepBar nextSubmit onPrev={() => navigate(`/immobilier/onboarding/${id}/reglementaire/informations`)} />
		</form>
	);
};
