import { FC } from 'react';
import { Box, Button, Card, Heading, HStack, Image, Show, Text, VStack } from '@chakra-ui/react';

import Nils from 'assets/team/nils.png';
import Amelie from 'assets/team/smallAmelie.png';
import Aymane from 'assets/team/smallAymane.png';
import Olivier from 'assets/team/smallOlivier.png';
import Samy from 'assets/team/smallSamy.png';

export const HelperAdvisor: FC = () => (
	<Card border="none" bg="informative.100" w="100%">
		<HStack>
			<VStack flex={1} align="start" spacing="24px">
				<VStack align="start" spacing="16px">
					<Heading variant="Title-L-SemiBold">Une question&nbsp;? Un conseil&nbsp;?</Heading>
					<Text variant="Text-M-Regular" color="grey.900">
						Prenez rendez-vous gratuitement avec votre conseiller dédié, il sera ravi de vous aider dans vos projets
						d'investissement.
					</Text>
				</VStack>
				<Button
					onClick={() => {
						window.Intercom('showNewMessage');
					}}
					size="lg"
				>
					Poser une question
				</Button>
			</VStack>
			<Show above="sm">
				<Box w="250px" h="191px" alignSelf="stretch" position="relative">
					<Image position="absolute" borderRadius="full" w="66px" src={Aymane} right="0px" top="0px" />
					<Image position="absolute" borderRadius="full" w="91px" src={Amelie} right="60px" top="50px" />
					<Image position="absolute" borderRadius="full" w="66px" src={Samy} left="40px" top="0px" />
					<Image position="absolute" borderRadius="full" w="78px" src={Olivier} bottom="10px" left="0px" />
					<Image position="absolute" borderRadius="full" w="48px" src={Nils} right="20px" bottom="0px" />
				</Box>
			</Show>
		</HStack>
	</Card>
);
