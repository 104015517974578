import { useEffect, useState } from 'react';
import {
	Box,
	Card,
	Heading,
	HStack,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Radio,
	RadioGroup,
	Skeleton,
	Text,
	VStack,
} from '@chakra-ui/react';

import BankAccountSelector from 'components/bankingInformations/BankAccountSelector';
import { ArrowButton } from 'components/Button/Button';
import ErrorFieldMessage from 'components/inputs/ErrorMessage';
import InputWithRightElement from 'components/inputs/InputWithRightElement';
import { Stepper } from 'components/navigation/Stepper';
import { CreateOVStepProps } from 'pages/Invest/Versements/CreateOptimizedVersement';
import { useGetBankAccountsQuery } from 'services/requests/bank';
import { useGetPerLimitQuery, versementMinMax } from 'services/requests/versement';
import { EnvelopeType } from 'store/types/global.type';
import { BankInformations } from 'store/types/subscription.type';
import { displayMoneyNoDigits } from 'utils/rendering';

const Contracts = ({
	trigger,
	register,
	errors,
	watch,
	setValue,
	setStep,
	availableVersementContracts,
	repartition,
}: CreateOVStepProps): JSX.Element => {
	const { data: accounts } = useGetBankAccountsQuery();
	const { data: perLimit } = useGetPerLimitQuery();

	const [bankInfo, savingType, amountAV, amountPER] = watch(['bankInfo', 'savingType', 'amountAV', 'amountPER']);
	const [savingTypeError, setSavingTypeError] = useState(false);
	const [bankInfoError, setBankInfoError] = useState(false);

	const repartitionAV = repartition?.avNewVersementProgramme ?? 0;
	const repartitionPER = repartition?.perNewVersementProgramme ?? 0;
	const amountTotal = repartitionPER + repartitionAV;
	const minimumPER = versementMinMax.IL.minVP.mensuel;
	const minimumAV = versementMinMax.P2.minVP.mensuel;

	// prefill bank account with the one on contract
	useEffect(() => {
		if (accounts && !bankInfo) {
			const defaultBankInfo: BankInformations | undefined = accounts.find((info) => info.id === accounts[0].id);
			if (defaultBankInfo) setValue('bankInfo', defaultBankInfo);
		}
	}, [accounts, bankInfo, setValue]);

	if (!accounts || !perLimit) return <Skeleton h="840px" w="100%" />;

	const onCheck = () => {
		trigger().then((response) => {
			if (!savingType) setSavingTypeError(true);
			if (!bankInfo) setBankInfoError(true);
			if (response && savingType && bankInfo) setStep('signature');
		});
	};

	return (
		<>
			<ModalHeader p="0px">
				<Heading variant="Title-M-SemiBold">Mettre en place vos versements programmés</Heading>
			</ModalHeader>

			<ModalBody px="0px" py="32px">
				<VStack w="100%" align="start" spacing="32px">
					<Text color="grey.900">Vous allez procéder aux signatures afin de valider les versements programmés.</Text>
					<Stepper
						alignSelf="center"
						w="80%"
						steps={[{ title: 'Simulation' }, { title: 'Contrats' }, { title: 'Signature' }]}
						index={1}
					/>
					{!availableVersementContracts ? (
						<Skeleton />
					) : (
						<>
							<VStack align="start" w="100%">
								<Text color="grey.900">Contract selectionné : </Text>

								<RadioGroup
									onChange={(v) => {
										setValue('savingType', v as EnvelopeType);
										setSavingTypeError(false);
									}}
									value={savingType}
									w="100%"
								>
									<VStack align="start" w="100%">
										<Radio value="AV" isDisabled={!availableVersementContracts.includes('PER')}>
											Assurance Vie
										</Radio>
										<Radio value="PER" isDisabled={!availableVersementContracts.includes('PER')}>
											PER
										</Radio>
									</VStack>
								</RadioGroup>
								{savingTypeError && <ErrorFieldMessage children="Veuillez sélectionner un type de contrat" />}
							</VStack>

							<Card borderColor="blue.500" w="100%">
								<VStack w="100%" align="start" spacing="16px">
									<Text variant="detail">
										En prenant en compte votre foyer fiscal et une épargne mensuelle de{' '}
										<Box as="span" fontWeight="600">
											{displayMoneyNoDigits(amountTotal)} par mois
										</Box>
										, votre allocation optimale est la suivante:
									</Text>
									<Text variant="detail" fontWeight="600">
										{displayMoneyNoDigits(repartitionAV)} sur l'Assurance Vie
									</Text>
									<Text variant="detail" fontWeight="600">
										{displayMoneyNoDigits(repartitionPER)} sur le PER
									</Text>
								</VStack>
							</Card>

							<VStack align="start">
								{savingType === 'AV' && (
									<InputWithRightElement
										{...register('amountAV', {
											required: true,
											min: minimumAV,
											valueAsNumber: true,
										})}
										rightElement="€"
										type="number"
										label="Quel montant souhaitez-vous verser chaque mois ?"
									/>
								)}
								{savingType === 'PER' && (
									<InputWithRightElement
										{...register('amountPER', {
											required: true,
											min: minimumPER,
											valueAsNumber: true,
										})}
										rightElement="€"
										type="number"
										label="Quel montant souhaitez-vous verser chaque mois ?"
									/>
								)}
								{(errors.amountAV?.type === 'required' || errors.amountPER?.type === 'required') && (
									<ErrorFieldMessage children="Ce champ est nécessaire" />
								)}
								{(errors.amountAV?.type === 'min' || errors.amountPER?.type === 'min') && (
									<ErrorFieldMessage>
										Votre montant doit être supérieur à{' '}
										{displayMoneyNoDigits(savingType === 'AV' ? minimumAV : minimumPER)}
									</ErrorFieldMessage>
								)}
							</VStack>
							<BankAccountSelector
								bankAccounts={accounts}
								errors={bankInfoError}
								selectedAccountId={bankInfo?.id}
								setValue={(value) => setValue('bankInfo', value)}
							/>
						</>
					)}
				</VStack>
			</ModalBody>
			<ModalFooter p="0px" flexDirection="column">
				<HStack w="100%" spacing="24px">
					<ArrowButton
						variant="secondary"
						size="lg"
						onClick={() => setStep('simulation')}
						data-cy="cy-next-btn"
						type="button"
						w="50%"
						left
					>
						Retour
					</ArrowButton>

					<ArrowButton
						variant="primary"
						size="lg"
						onClick={onCheck}
						isDisabled={amountAV + amountPER <= 0 || !bankInfo || !savingType}
						type="button"
						w="50%"
					>
						Suivant
					</ArrowButton>
				</HStack>
			</ModalFooter>
		</>
	);
};

export default Contracts;
