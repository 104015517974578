import { backendApi } from 'services/apiService';

export type TaxDataPerFiscalProduct = {
	investedAmount: number;
	maximalAmountToInvest: number;
	residualAmountToInvest: number;
	maximalAmountToInvestInTheory?: number;
	potentialTaxBenefit: number;
	taxBenefit: number;
	residualTaxBenefit: number;
	type: string;
};

type DetailImpositionIR = {
	irAvantPerEtReductions: number;
	economiesPER: number;
	reductions: number;
	ir: number;
};

export type FiscaliteImposition = {
	impositionIr: number;
	impositionIfi: number;
	tauxPrelevementSource: number;
	partsFiscales: number;
	detailsImpositionIr: DetailImpositionIR;
	taxDataPerFiscalProduct: { PER: TaxDataPerFiscalProduct; FCPI: TaxDataPerFiscalProduct };
};

export type OptimizationUpdateResponse = {
	avNewVersementProgramme: number;
	perNewVersementProgramme: number;
};

export type UpdateYearFiscalHistoryDto = {
	fcpi: number;
	fip: number;
	fipCorse: number;
	fipDomTom: number;
	reduction: number;
	credit: number;
	per: number;
};

export type YearFiscalHistory = {
	fcpi: number;
	fip: number;
	fipCorse: number;
	fipDomTom: number;
	reduction: number;
	credit: number;
	per: number;
};

export type OptimizationTaxEconomy = {
	nbRemainingVP: number;
	PVTaxEconomy: number;
	PVTaxEconomyThisYear: number;
	PVTaxEconomyNextYear: number;
};

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getImpositionFiscalite: builder.query<FiscaliteImposition, void>({
			query: () => '/fiscalite/imposition',
			providesTags: ['Fiscalite/Imposition'],
		}),
		getUpdatedRepartition: builder.query<OptimizationUpdateResponse, { monthlySavingCapacity: number }>({
			query: ({ monthlySavingCapacity }) => ({
				url: `/fiscalite/updated-repartition/${monthlySavingCapacity}`,
				method: 'GET',
			}),
			providesTags: ['Fiscalite/UpdatedRepartition'],
		}),

		// Historique fiscal
		upsertHistory: builder.mutation<YearFiscalHistory, UpdateYearFiscalHistoryDto>({
			query: (body) => ({
				url: `/fiscalite/history`,
				method: 'Post',
				body,
			}),
			invalidatesTags: ['Fiscalite/History'],
		}),
		geFiscalHistoryForYear: builder.query<YearFiscalHistory, number>({
			query: (year) => `/fiscalite/history/${year}`,
			providesTags: ['Fiscalite/History'],
		}),

		getOptimizationTaxEconomy: builder.query<OptimizationTaxEconomy, { saving: number }>({
			query: ({ saving }) => ({
				url: `/fiscalite/optimization?saving=${saving}`,
				method: 'GET',
			}),
			providesTags: ['Fiscalite/Optimization'],
		}),
	}),
});

export const {
	useGetImpositionFiscaliteQuery,
	useGetUpdatedRepartitionQuery,
	useLazyGetUpdatedRepartitionQuery,
	useUpsertHistoryMutation,
	useGeFiscalHistoryForYearQuery,
	useGetOptimizationTaxEconomyQuery,
	useLazyGetOptimizationTaxEconomyQuery,
} = extendedApi;
