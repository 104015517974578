import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Heading, List, ListIcon, ListItem, Text, VStack } from '@chakra-ui/react';

import BottomStepBar from 'components/steppers/BottomStepBar';
import eventTracker from 'services/events/eventTracker';

const Confirmation = (): JSX.Element => {
	const navigate = useNavigate();

	useEffect(() => {
		eventTracker.mixpanel.track('PE Onboarding - Confirmation');
	}, []);

	return (
		<>
			<VStack align="start" spacing="24px">
				<Heading fontSize={{ base: 16, md: 20 }} fontWeight="700">
					Votre document a été signé avec succès !
				</Heading>
				<VStack align="start" spacing="16px">
					<Text>
						Votre souscription a été transmise à <b>Vatel</b>
					</Text>
					<Text>La finalisation de votre souscription se déroulera en plusieurs étapes :</Text>
					<Box>
						<List spacing="24px">
							<ListItem>
								<Text>
									<ListIcon as={CheckCircleIcon} color="red.500" />
									<Text as="span" fontWeight="bold">
										Etape 1 :{' '}
									</Text>
									Validation de votre dossier par notre partenaire - en moyenne sous{' '}
									<Text as="span" fontWeight="bold">
										48 heures.
									</Text>
								</Text>
							</ListItem>

							<ListItem>
								<Text>
									<ListIcon as={CheckCircleIcon} color="red.500" />
									<Text as="span" fontWeight="bold">
										Etape 2 :{' '}
									</Text>
									Réception des fonds - selon{' '}
									<Text as="span" fontWeight="bold">
										votre mode de paiement{' '}
									</Text>
									(virement ou prélèvement).
								</Text>
							</ListItem>

							<ListItem>
								<Text>
									<ListIcon as={CheckCircleIcon} color="red.500" />
									<Text as="span" fontWeight="bold">
										Etape 3 :{' '}
									</Text>
									Enregistrement de votre opération - en moyenne sous{' '}
									<Text as="span" fontWeight="bold">
										1 à 2 semaines{' '}
									</Text>
									selon les produits et partenaires.
								</Text>
							</ListItem>

							<ListItem>
								<Text>
									<ListIcon as={CheckCircleIcon} color="red.500" />
									<Text as="span" fontWeight="bold">
										Etape 4 :{' '}
									</Text>
									Réception de l’attestation de votre opération - en moyenne sous{' '}
									<Text as="span" fontWeight="bold">
										2 semaines,{' '}
									</Text>
									jusqu’à trimestriellement pour certains produits et partenaires.
								</Text>
							</ListItem>
						</List>
					</Box>
					<Text>Vous pourrez ensuite suivre votre placement directement depuis votre espace client Ramify</Text>
				</VStack>
			</VStack>
			<BottomStepBar
				showPrev={false}
				textNext="Revenir à l'accueil"
				onNext={() => navigate(`/private-equity/souscriptions`)}
			/>
		</>
	);
};

export default Confirmation;
