import { Outlet } from 'react-router-dom';
import { HStack, Image, VStack } from '@chakra-ui/react';

import RamifyLogo from 'assets/logoRamify/white.svg';
import { usePositionIntercomBubble } from 'hooks/usePositionIntercomBubble';

const AuthenticationLayout = (): JSX.Element => {
	usePositionIntercomBubble('app');

	return (
		<VStack minH="100vh" align="flex-start" spacing={0} overflowX="hidden" bg="grey.100">
			<HStack align="left" w="100%" h="80px" bg="primary.black" padding="24px">
				<Image src={RamifyLogo} alt="Ramify" w={{ base: '128px', sm: '128px', md: '128px', lg: '128px' }} />
			</HStack>
			<VStack w="100%" spacing="8px" align="center">
				<Outlet />
			</VStack>
		</VStack>
	);
};

export default AuthenticationLayout;
