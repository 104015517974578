import { FC } from 'react';
import { CheckmarkFilled, Finance } from '@carbon/icons-react';
import { Card, Heading, HStack, Text, VStack, Wrap } from '@chakra-ui/react';
import { friendlyFormatIBAN } from 'ibantools';

import colors from 'theme/foundations/colors';

export type BankAccountCardProps = {
	IBAN: string;
	name: string;
	value: boolean;
	onSelect: () => void;
};

const BankAccountCard: FC<BankAccountCardProps> = ({ IBAN, name, onSelect, value }) => (
	<Card
		_hover={{ borderColor: value ? '' : 'grey.400' }}
		onClick={onSelect}
		borderColor={value ? colors.primary.yellow : 'grey.300'}
		boxShadow={value ? '0px 0px 4px rgba(98, 141, 251, 0.32)' : ''}
		w="100%"
		cursor="pointer"
		borderWidth={value ? '2px' : '1px'}
		key={IBAN}
	>
		<VStack spacing="16px" align="start" w="100%">
			<HStack spacing="24px" w="100%">
				<HStack spacing="24px" w="100%">
					<Finance size="32" />
					<Heading variant="Title-M-Bold">{name}</Heading>
				</HStack>
				{value && <CheckmarkFilled size="16" color={colors.primary.yellow} />}
			</HStack>

			<VStack align="start" spacing="4px" w="100%">
				<Wrap w="100%">
					<Text variant="Text-S-Medium" color={colors.grey[900]}>
						IBAN :{' '}
					</Text>
					<Text variant="Text-S-Regular" color={colors.grey[900]}>
						{friendlyFormatIBAN(IBAN, '-')}
					</Text>
				</Wrap>
			</VStack>
		</VStack>
	</Card>
);

export default BankAccountCard;
