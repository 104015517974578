import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Binoculars } from '@carbon/icons-react';
import {
	Badge,
	Box,
	Button,
	Card,
	Center,
	chakra,
	FormControl,
	FormLabel,
	Heading,
	HStack,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import NumberInput from 'components/inputs/NumberInput';
import useThemedToast from 'hooks/useThemedToast';
import {
	UpdateYearFiscalHistoryDto,
	useGeFiscalHistoryForYearQuery,
	useUpsertHistoryMutation,
} from 'services/requests/fiscalite';
import colors from 'theme/foundations/colors';

const ModalUpdateInfoFiscal = (): JSX.Element => {
	const { data: fiscalHistory } = useGeFiscalHistoryForYearQuery(new Date().getFullYear());
	const [updateUserFoyerFiscal] = useUpsertHistoryMutation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const initialRef = useRef(null);
	const finalRef = useRef(null);
	const toast = useThemedToast();

	const { setValue, handleSubmit, control } = useForm<UpdateYearFiscalHistoryDto>({
		mode: 'onChange',
	});

	useEffect(() => {
		if (fiscalHistory) {
			setValue('fcpi', fiscalHistory.fcpi);
			setValue('fip', fiscalHistory.fip);
			setValue('fipCorse', fiscalHistory.fipCorse);
			setValue('fipDomTom', fiscalHistory.fipDomTom);
			setValue('reduction', fiscalHistory.reduction);
			setValue('credit', fiscalHistory.credit);
			setValue('per', fiscalHistory.per);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fiscalHistory]);

	const onSubmit = handleSubmit((data: UpdateYearFiscalHistoryDto) => {
		updateUserFoyerFiscal({
			...data,
			fcpi: data.fcpi || 0,
			fip: data.fip || 0,
			fipCorse: data.fipCorse || 0,
			fipDomTom: data.fipDomTom || 0,
			reduction: data.reduction || 0,
			credit: data.credit || 0,
			per: data.per || 0,
		})
			.unwrap()
			.then(() => {
				onClose();
				toast({
					title: 'Modification enregistrée',
					description: 'Vos registres sont mis à jour.',
					status: 'success',
					duration: 4500,
					isClosable: true,
				});
			})
			.catch(() => {
				toast({
					title: "Une erreur s'est produite",
					description: 'Nous ne pouvons pas mettre à jour vos préférences.',
					status: 'error',
					duration: 4500,
					isClosable: true,
				});
			});
	});

	return (
		<>
			<Card w="100%" variant={fiscalHistory ? 'base' : 'blue'}>
				<VStack spacing="16px" align="start" w="100%">
					<Stack direction={{ base: 'column', md: 'row' }} align={{ base: 'start', md: 'center' }} spacing="sm">
						<Box bg="informative.100" borderRadius="full" p="12px">
							<Binoculars size="24" color={colors.informative[900]} />
						</Box>
						<VStack align="start">
							<Heading variant="Title-S-SemiBold">Affiner ma simulation</Heading>
							{fiscalHistory && (
								<Badge size="xs" bg="green.100" color="green.700">
									Vos informations sont à jour
								</Badge>
							)}
						</VStack>
						<Text variant="Text-S-Regular" color="grey.900" flex="1">
							Renseignez les dispositifs fiscaux que vous avez déjà mis en place cette année, nous les prendrons en
							compte dans nos calculs
						</Text>
						<Button alignSelf={{ base: 'end', md: undefined }} variant="secondary" onClick={onOpen}>
							Affiner ma simulation
						</Button>
					</Stack>
				</VStack>
			</Card>

			<Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<chakra.form onSubmit={onSubmit}>
						<ModalHeader my={2}>
							<HStack align="start" spacing="12px">
								<Heading variant="Title-M-SemiBold">Affiner ma recommandation</Heading>
								<Center h="19px">
									<CustomTooltip text="Les réductions d'impôts débloquées par l'investissement dans des fonds comme les FCPI seront retenues à condition de respecter plusieurs règles notamment le plafonnement des niches fiscales et les plafonds d'investissement dans les FCPI/ FIP.." />
								</Center>
							</HStack>
						</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<VStack spacing="40px" align="start" w="100%">
								<VStack spacing="16px" align="start" w="100%">
									<HStack align="start" spacing="12px">
										<Text variant="label" fontWeight="bold">
											Mes investissement hors Ramify 2023
										</Text>
										<Center h="21px">
											<CustomTooltip text="Les véhicules d'investissements comme les FCPI et les FIP sont soumis à un plafonnement annuel par foyer fiscal, le plafond est de 12.000€ pour un investisseur célibataire et 24.000€ pour un investisseur marié. L'accès à ces informations permet de calculer les plafonds résiduels." />
										</Center>
									</HStack>
									<VStack align="start" spacing="16px" w="100%">
										<FormControl>
											<FormLabel>FCPI</FormLabel>
											<Controller
												control={control}
												name="fcpi"
												rules={{
													min: { value: 0, message: 'Le montant doit être positif' },
												}}
												render={({ field }) => <NumberInput placeholder="0" suffix=" €" {...field} />}
											/>
										</FormControl>

										<FormControl>
											<FormLabel>FIP</FormLabel>
											<Controller
												control={control}
												name="fip"
												rules={{
													min: { value: 0, message: 'Le montant doit être positif' },
												}}
												render={({ field }) => <NumberInput placeholder="0" suffix=" €" {...field} />}
											/>
										</FormControl>
										<FormControl>
											<FormLabel>FIP CORSE </FormLabel>
											<Controller
												control={control}
												name="fipCorse"
												rules={{
													min: { value: 0, message: 'Le montant doit être positif' },
												}}
												render={({ field }) => <NumberInput placeholder="0" suffix=" €" {...field} />}
											/>
										</FormControl>
										<FormControl>
											<FormLabel>FIP DOM TOM</FormLabel>
											<Controller
												control={control}
												name="fipDomTom"
												rules={{
													min: { value: 0, message: 'Le montant doit être positif' },
												}}
												render={({ field }) => <NumberInput placeholder="0" suffix=" €" {...field} />}
											/>
										</FormControl>
									</VStack>
								</VStack>

								<VStack spacing="16px" align="start" w="100%">
									<HStack align="start" spacing="12px">
										<Text variant="label" fontWeight="bold">
											Autres
										</Text>
										<Center h="21px">
											<CustomTooltip text="Les montants des autres déductions / réductions / crédits d'impôts sont pris en compte dans le plafonnement global des niches fiscales." />
										</Center>
									</HStack>
									<VStack align="start" spacing="20px" w="100%">
										<FormControl>
											<FormLabel>Mes autres réduction en 2023</FormLabel>
											<Controller
												control={control}
												name="reduction"
												rules={{
													min: { value: 0, message: 'Le montant doit être positif' },
												}}
												render={({ field }) => <NumberInput placeholder="0" suffix=" €" {...field} />}
											/>
										</FormControl>
										<FormControl>
											<FormLabel>Mes crédits d'impôts en 2023</FormLabel>
											<Controller
												control={control}
												name="credit"
												rules={{
													min: { value: 0, message: 'Le montant doit être positif' },
												}}
												render={({ field }) => <NumberInput placeholder="0" suffix=" €" {...field} />}
											/>
										</FormControl>
										<FormControl>
											<HStack align="start" spacing="12px">
												<FormLabel>Mes investissements attendus en PER en 2023</FormLabel>
												<Center h="21px">
													<CustomTooltip text="Cette information permet d'inclure les déductions fiscales via PER dans le calcul de l'impôt sur le revenu." />
												</Center>
											</HStack>
											<Controller
												control={control}
												name="per"
												rules={{
													min: { value: 0, message: 'Le montant doit être positif' },
												}}
												render={({ field }) => <NumberInput placeholder="0" suffix=" €" {...field} />}
											/>
										</FormControl>
									</VStack>
								</VStack>
							</VStack>
						</ModalBody>

						<ModalFooter>
							<Button colorScheme="blue" mr={3} w="100%" type="submit">
								Sauvegarder
							</Button>
						</ModalFooter>
					</chakra.form>
				</ModalContent>
			</Modal>
		</>
	);
};
export default ModalUpdateInfoFiscal;
