import { FC } from 'react';
import { BuildingInsights_2 } from '@carbon/icons-react';
import { Card, Divider, Heading, HStack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { RamifyTipCard } from 'components/cards/RamifyTipCard';
import InvestmentMetricCard from 'components/Metrics/InvestmentMetrics/Card';
import { useGetImpositionFiscaliteQuery } from 'services/requests/fiscalite';
import { displayMoneyNoDigits, displayPercentage } from 'utils/rendering';

const Imposition: FC = () => {
	const { data, isLoading } = useGetImpositionFiscaliteQuery();

	if (isLoading || !data) return null;

	return (
		<VStack align="start" w="100%" spacing="lg">
			<VStack align="start" spacing="sm">
				<Heading variant="Title-L-SemiBold">Mon imposition</Heading>
				<Text variant="Text-M-Regular">Un récapitulatif de vos impôts dûs sur l’année en cours.</Text>
			</VStack>

			<Wrap spacing="24px" w="100%" direction={{ base: 'column', xl: 'row' }}>
				<WrapItem flex="1">
					<Card w="100%">
						<VStack align="start" spacing="sm" w="100%">
							<VStack align="start">
								<Heading variant="Title-M-SemiBold">Impôt sur le revenu</Heading>
								<Text variant="Text-M-Regular">
									D'après nos estimations, votre taux de prélèvement à la source actuel est de :
								</Text>
							</VStack>
							<InvestmentMetricCard icon={<BuildingInsights_2 size="32" />} title="Taux de prélèvement à la source">
								<Heading variant="Title-L-Bold">{displayPercentage(data.tauxPrelevementSource)}</Heading>
							</InvestmentMetricCard>
							<RamifyTipCard w="100%" border="none" size="none">
								<Heading variant="Title-S-SemiBold">À quoi correspond ce taux ?</Heading>
								<Text variant="Text-M-Regular" color="grey.900">
									Le taux de prélèvement à la source correspond à votre taux d'imposition. En d'autres termes, il permet
									de déterminer le pourcentage de vos revenus prélevés dans le cadre de l'impôt.
								</Text>
							</RamifyTipCard>
						</VStack>
					</Card>
				</WrapItem>

				<WrapItem flex="1">
					<Card flex="1" h="100%">
						<VStack w="100%" align="start" spacing="sm">
							<VStack w="100%" align="start" spacing="xs">
								<Heading variant="Title-M-SemiBold">Impôts uniques</Heading>
								<Text variant="Text-M-Regular" color="grey.900">
									Ces impôts sont prélevés automatiquement chaque année selon votre situation et votre patrimoine.
								</Text>
							</VStack>
							<HStack w="100%" justify="space-between">
								<Text variant="Text-M-Medium">Impôt avant réduction et économie</Text>
								<Heading variant="Title-L-Bold" color="negative.900">
									{displayMoneyNoDigits(data.detailsImpositionIr.irAvantPerEtReductions)}
								</Heading>
							</HStack>
							<Divider w="100%" />
							<VStack w="100%" align="start" spacing="sm">
								<HStack w="100%" justify="space-between">
									<Text variant="Text-S-Medium" color="grey.900">
										Économie d'impôt grâce au PER
									</Text>
									<Heading variant="Title-M-SemiBold" color="informative.900">
										{displayMoneyNoDigits(data.detailsImpositionIr.economiesPER)}
									</Heading>
								</HStack>
								<HStack w="100%" justify="space-between">
									<Text variant="Text-S-Medium" color="grey.900">
										Réduction d’impôt FCPI
									</Text>
									<Heading variant="Title-M-SemiBold" color="informative.900">
										{displayMoneyNoDigits(data.detailsImpositionIr.reductions)}
									</Heading>
								</HStack>
							</VStack>
							<Card w="100%" bg="positive.100" border="none">
								<VStack w="100%" align="end" spacing="sm">
									<Text variant="Text-S-Medium">Total impôt à payer</Text>
									<Heading variant="Title-L-Bold" color="positive.900">
										{displayMoneyNoDigits(data.detailsImpositionIr.ir)}
									</Heading>
								</VStack>
							</Card>
						</VStack>
					</Card>
				</WrapItem>
			</Wrap>
		</VStack>
	);
};

export default Imposition;
