import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { List, ListIcon, ListItem, Text, VStack } from '@chakra-ui/react';

import BottomStepBar from 'components/steppers/BottomStepBar';
import eventTracker from 'services/events/eventTracker';

const Confirmation = (): JSX.Element => {
	const navigate = useNavigate();

	useEffect(() => {
		eventTracker.mixpanel.track('PE Onboarding - Confirmation');
	}, []);

	return (
		<>
			<VStack align="start" spacing="24px">
				<VStack align="start" spacing="16px">
					<Text variant="title">
						Votre souscription a été transmise à <b>Eurazeo.</b>
					</Text>
					<Text>La finalisation de votre souscription se déroulera en plusieurs étapes :</Text>
					<List spacing="24px">
						<ListItem>
							<Text>
								<ListIcon as={CheckCircleIcon} color="red.500" />
								<Text as="span" fontWeight="bold">
									Etape 1 :{' '}
								</Text>
								Validation de votre dossier par nos équipes - en moyenne sous{' '}
								<Text as="span" fontWeight="bold">
									2 jours ouvrés.
								</Text>
							</Text>
						</ListItem>

						<ListItem>
							<Text>
								<ListIcon as={CheckCircleIcon} color="red.500" />
								<Text as="span" fontWeight="bold">
									Etape 2 :{' '}
								</Text>
								Signature du bulletin de souscription - envoyé via <b>Docusign.</b>
							</Text>
						</ListItem>

						<ListItem>
							<Text>
								<ListIcon as={CheckCircleIcon} color="red.500" />
								<Text as="span" fontWeight="bold">
									Etape 3 :{' '}
								</Text>
								Réception des fonds - virement à effectuer sur le <b>RIB Eurazeo.</b>
								<br />À ce stade, il vous sera demandé de nous partager l'avis de virement pour valider l'opération.
							</Text>
						</ListItem>

						<ListItem>
							<Text>
								<ListIcon as={CheckCircleIcon} color="red.500" />
								<Text as="span" fontWeight="bold">
									Etape 4 :{' '}
								</Text>
								Finalisation de l'opération - vous pourrez <b>visualiser et suivre les performances</b> de votre
								investissement depuis votre espace investisseur.
							</Text>
						</ListItem>
					</List>
				</VStack>
			</VStack>
			<BottomStepBar
				showPrev={false}
				textNext="Revenir à l'accueil"
				onNext={() => navigate(`/private-equity/souscriptions`)}
			/>
		</>
	);
};

export default Confirmation;
