import { FC, useEffect } from 'react';
import { Card, Text, VStack } from '@chakra-ui/react';

import { useFoyerAnnualNetIncome } from 'hooks/useFoyerIncome';
import { useLazyGetUpdatedRepartitionQuery } from 'services/requests/fiscalite';
import { displayMoneyNoDigits } from 'utils/rendering';

const OptimizationInfoCard: FC = () => {
	const annualNetIncome = useFoyerAnnualNetIncome();
	const [getUpdatedRepartition, { data: repartition }] = useLazyGetUpdatedRepartitionQuery();

	useEffect(() => {
		const monthlySavingBasedOnIcome = Math.round(annualNetIncome / 12 / 10);
		if (monthlySavingBasedOnIcome > 0) getUpdatedRepartition({ monthlySavingCapacity: monthlySavingBasedOnIcome });
	}, [annualNetIncome, getUpdatedRepartition]);

	if (!annualNetIncome || !repartition) return null;

	return (
		<Card variant="blue">
			<VStack spacing="24px" align="start">
				<Text variant="body" color="grey.700">
					Ramify vous aide à déterminer les montants de versements programmés (Assurance vie et PER) qui maximisent
					votre rendement net d’impôt. En prenant en compte votre foyer fiscal et une épargne mensuelle de{' '}
					<Text as="span" color="red.500" fontWeight="bold">
						{displayMoneyNoDigits(annualNetIncome / 12 / 10)}
					</Text>{' '}
					(10% de vos revenus nets) par mois, votre allocation optimale est la suivante:{' '}
					<Text as="span" color="red.500" fontWeight="bold">
						{repartition?.avNewVersementProgramme} € sur l’assurance vie et{' '}
					</Text>
					<Text as="span" color="red.500" fontWeight="bold">
						{repartition?.perNewVersementProgramme} € sur le PER.
					</Text>
				</Text>
			</VStack>
		</Card>
	);
};

export default OptimizationInfoCard;
