import { ProposalDTO } from 'store/types/airtable.type';

const isProposalSubscription = (proposal: ProposalDTO | undefined, id: string): boolean => {
	if (!proposal) return false;
	return (
		proposal.PESubscriptionsIds.includes(id) ||
		proposal.SCPISubscriptionsIds.includes(id) ||
		proposal.investSubscriptionsIds.includes(id) ||
		proposal.cashSubscriptionsIds.includes(id)
	);
};

export default isProposalSubscription;
