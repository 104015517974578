import { useState } from 'react';
import { Button, Card, HStack, Tag, TagLabel, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { AttributeKycLabel } from 'components/badge/AttributeKycLabel';
import { UninformedBadge } from 'components/badge/UninformedBadge';
import PatrimonyForm from 'onboarding/KYC/Forms/PatrimonyForm';
import { KYC, patrimonySourcesLabels } from 'store/types/KYC.type';
import { displayMoney } from 'utils/rendering';

const PatrimonyEditable = ({ kyc }: { kyc?: KYC }): JSX.Element => {
	const [isEditing, setisEditing] = useState(false);

	return (
		<Card w="100%">
			<VStack w="100%" spacing="md" align="start">
				<HStack spacing="40px" w="100%" justify="space-between">
					<Text variant="title">Mon patrimoine</Text>
					<Button variant="secondary" size="md" onClick={() => setisEditing(!isEditing)}>
						{isEditing ? 'Annuler' : 'Modifier'}
					</Button>
				</HStack>
				{isEditing ? (
					<PatrimonyForm
						onSuccess={() => setisEditing(false)}
						formConfirmation={
							<Button type="submit" w="100%" borderRadius="5px" variant="primary">
								Mettre à jour
							</Button>
						}
					/>
				) : (
					<VStack align="start">
						<AttributeKycLabel
							title="Résidence principale"
							value={
								kyc?.patrimonyEstimatePrimaryRealEstate === undefined ||
								kyc?.patrimonyEstimatePrimaryRealEstate === null
									? null
									: displayMoney(kyc?.patrimonyEstimatePrimaryRealEstate)
							}
						/>
						<AttributeKycLabel
							title="Résidences secondaires"
							value={
								kyc?.patrimonyEstimateSecondaryRealEstate === undefined ||
								kyc?.patrimonyEstimateSecondaryRealEstate === null
									? null
									: displayMoney(kyc?.patrimonyEstimateSecondaryRealEstate)
							}
						/>
						<AttributeKycLabel
							title="Comptes bancaires, liquidités"
							value={
								kyc?.patrimonyEstimateBankAccount === undefined || kyc?.patrimonyEstimateBankAccount === null
									? null
									: displayMoney(kyc?.patrimonyEstimateBankAccount)
							}
						/>
						<AttributeKycLabel
							title="Contrats d’assurance-vie, capitalisation"
							value={
								kyc?.patrimonyEstimateCcontractAVCapitalisation === undefined ||
								kyc?.patrimonyEstimateCcontractAVCapitalisation === null
									? null
									: displayMoney(kyc?.patrimonyEstimateCcontractAVCapitalisation)
							}
						/>
						<AttributeKycLabel
							title="Portefeuille de valeurs mobilières (PEA, ...)"
							value={
								kyc?.patrimonyEstimatePortfolio === undefined || kyc?.patrimonyEstimatePortfolio === null
									? null
									: displayMoney(kyc?.patrimonyEstimatePortfolio)
							}
						/>
						<AttributeKycLabel
							title="Épargne retraite"
							value={
								kyc?.patrimonyEstimateRetirement === undefined || kyc?.patrimonyEstimateRetirement === null
									? null
									: displayMoney(kyc?.patrimonyEstimateRetirement)
							}
						/>
						<AttributeKycLabel
							title="Actifs professionnels"
							value={
								kyc?.patrimonyEstimateProfessionalAssets === undefined ||
								kyc?.patrimonyEstimateProfessionalAssets === null
									? null
									: displayMoney(kyc?.patrimonyEstimateProfessionalAssets)
							}
						/>
						<AttributeKycLabel
							title="Autres (ex: objets d’arts...)"
							value={
								kyc?.patrimonyEstimateOther === null || kyc?.patrimonyEstimateOther === undefined
									? null
									: displayMoney(kyc?.patrimonyEstimateOther)
							}
						/>

						<Text fontSize="14px" color="grey.600">
							Origine de votre patrimoine:{' '}
							{(!kyc?.patrimonySources || kyc?.patrimonySources.length === 0) && <UninformedBadge />}
						</Text>
						<Wrap w="100%">
							{kyc?.patrimonySources &&
								kyc?.patrimonySources.map((source) => (
									<WrapItem key={source}>
										<Tag size="md" key="patrimonySourceSavings" borderRadius="full" variant="subtle" colorScheme="blue">
											<TagLabel>{patrimonySourcesLabels[source]}</TagLabel>
										</Tag>
									</WrapItem>
								))}
						</Wrap>
					</VStack>
				)}
			</VStack>
		</Card>
	);
};

export default PatrimonyEditable;
