import { FC } from 'react';
import { Card, Text, VStack } from '@chakra-ui/react';

import { useAppResponsive } from 'hooks/useAppResponsive';
import { useGetKYCQuery } from 'services/requests/kyc';
import { IncomePeriod } from 'store/referentiels/incomePeriod';
import { IncomeType } from 'store/referentiels/incomeType';
import { displayMoneyNoDigits } from 'utils/rendering';

import CardInfo from './CardInfo';

const IncomeFields: FC = () => {
	const { data: kyc } = useGetKYCQuery();
	const isMobile = useAppResponsive({ base: true, md: false });

	const selfRevenu = kyc?.revenu
		? {
				income: kyc.revenu,
				incomeType: kyc.revenuType,
				incomePeriod: kyc.revenuPeriod,
		  }
		: undefined;

	const spouseRevenu =
		kyc?.familySituation === 'MARRIED' && kyc?.revenuSpouse
			? {
					income: kyc.revenuSpouse,
					incomeType: kyc.revenuSpouseType,
					incomePeriod: kyc.revenuSpousePeriod,
			  }
			: undefined;

	const displayPeriod = (period: IncomePeriod) => (period === IncomePeriod.MONTHLY ? 'par mois' : 'par an');
	const displayType = (period: IncomeType) => (period === IncomeType.GROSS ? 'brut' : 'net');

	if (!selfRevenu) return null;

	return (
		<VStack align="start" w="100%" spacing="16px">
			{isMobile && <CardInfo />}
			<Card>
				<Text variant="label">
					Vous avez indiqué que vos revenus étaient de{' '}
					<Text as="span" color="red.500" variant="label">
						{displayMoneyNoDigits(selfRevenu.income)} {displayType(selfRevenu.incomeType)}{' '}
						{displayPeriod(selfRevenu.incomePeriod)}
					</Text>
					{spouseRevenu && (
						<>
							{' '}
							et ceux de votre conjoint de{' '}
							<Text as="span" color="red.500" variant="label">
								{displayMoneyNoDigits(spouseRevenu.income)} {displayType(spouseRevenu.incomeType)}{' '}
								{displayPeriod(spouseRevenu.incomePeriod)}
							</Text>
						</>
					)}
					.
				</Text>
			</Card>
		</VStack>
	);
};

export default IncomeFields;
