import { useMemo } from 'react';
import { Card, Heading, HStack, SimpleGrid, Skeleton, Text, VStack, Wrap } from '@chakra-ui/react';

import { assetClassBadges } from 'components/badge/AssetClassBadges';
import SamyAdvisor from 'components/cards/SamyAdvisor';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { useRiskDisplay } from 'hooks/useRiskDisplay';
import { useTemperatureDisplay } from 'hooks/useTemperatureDisplay';
import type { OnboardingUpdateStepProps } from 'onboarding/Stepper/stepper.slice';
import { useGetCustomPortfolioQuery } from 'services/requests/invest/preferences';
import type { CustomAsset } from 'store/types/investment.type';
import { displayMoney } from 'utils/rendering';

const ProjectObjective = ({ object: onboardingObject, onNext }: OnboardingUpdateStepProps): JSX.Element => {
	const riskDisplay = useRiskDisplay(onboardingObject.investmentPreferences!.risk!);
	const { data: portfolio, isLoading: isPortfolioLoading } = useGetCustomPortfolioQuery({
		id: onboardingObject.id,
		entityName: onboardingObject.table,
	});

	const temperatureDisplay = useTemperatureDisplay(onboardingObject.investmentPreferences!.temperature);
	const assetTypes = useMemo(
		() =>
			portfolio?.assets.reduce(
				(acc, asset) => ({ ...acc, [asset.type]: true }),
				{} as Record<CustomAsset['type'], boolean>,
			),
		[portfolio],
	);

	return (
		<VStack spacing="48px">
			<VStack w="100%" align="start" spacing="16px">
				<Heading variant="bigTitle">Mon Projet</Heading>
				<SamyAdvisor
					bgColor="informative.100"
					text="Completez ce parcours pour souscrire avec le portefeuille proposé par votre conseiller"
				/>
			</VStack>
			<SimpleGrid w="100%" columns={{ base: 1, sm: 2 }} spacing="24px">
				<Card minWidth="250px">
					<VStack align="start" spacing="16px">
						<Text>Montant initial</Text>
						<Heading variant="Title-M-SemiBold">{displayMoney(onboardingObject.initialAUM)}</Heading>
					</VStack>
				</Card>
				<Card minWidth="250px">
					<VStack align="start" spacing="16px">
						<Text>Versements programmés</Text>
						<HStack spacing="16px">
							<Heading variant="Title-M-SemiBold">{displayMoney(onboardingObject.saving)}</Heading>
							<Text>Mensuel</Text>
						</HStack>
					</VStack>
				</Card>
				<Card minWidth="250px">
					<VStack align="start" spacing="16px">
						<Text>Classes d'actifs</Text>
						{isPortfolioLoading ? (
							<Skeleton w="100%" h="30px" />
						) : (
							<Wrap shouldWrapChildren>
								{assetTypes?.Actions && assetClassBadges.ACTIONS}
								{assetTypes?.Obligations && assetClassBadges.OBLIGATIONS}
								{assetTypes?.SCPI && assetClassBadges.IMMOBILIER}
								{assetTypes?.['Private Equity'] && assetClassBadges['PRIVATE EQUITY']}
								{assetTypes?.['Fonds Euros'] && assetClassBadges['FONDS EURO']}
							</Wrap>
						)}
					</VStack>
				</Card>
				<Card minWidth="250px">
					<VStack align="start" spacing="16px">
						<Text>Mon profil de risque</Text>
						<HStack spacing="16px">
							<Heading variant="Title-M-SemiBold">{riskDisplay.value}</Heading>
							<Text>{riskDisplay.name}</Text>
						</HStack>
					</VStack>
				</Card>
				{onboardingObject.investmentPreferences!.esg && (
					<Card minWidth="250px">
						<VStack align="start" spacing="16px">
							<Text>Ramify Green</Text>
							<HStack spacing="16px">
								<Heading variant="Title-M-SemiBold">
									<>{temperatureDisplay.name} °C</>
								</Heading>
								<Text>{temperatureDisplay.description}</Text>
							</HStack>
						</VStack>
					</Card>
				)}
			</SimpleGrid>

			<BottomStepBar showPrev={false} onNext={onNext} />
		</VStack>
	);
};

export default ProjectObjective;
