import { Controller, useFormContext } from 'react-hook-form';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Text,
	useBreakpointValue,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import NumberInput from 'components/inputs/NumberInput';
import ReferentielInput from 'components/inputs/ReferentielInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import FamilySituationReferentiel, { FamilySituation } from 'store/referentiels/familySituation';
import IncomePeriodReferentiel from 'store/referentiels/incomePeriod';
import IncomeTypeReferentiel from 'store/referentiels/incomeType';
import MatrimonialRegimeReferentiel from 'store/referentiels/matrimonial';
import ProfessionalSituationReferentiel from 'store/referentiels/professionalSituation';
import { FoyerFiscalType } from 'store/types/KYC.type';

const tooltipFoyerFiscal: Record<string, React.ReactNode> = {
	proSituation: (
		<Text variant="Text-S-Regular" color="white">
			En fonction de votre situation professionnelle, vous avez droit à certaines défiscalisations.
			<br />
			Les <b>TNS</b> (Travailleur Non Salarié) et les <b>professions libérales</b> sont inclus dans la catégorie
			<b> indépendant</b>.
		</Text>
	),
	familySituation: (
		<Text variant="Text-S-Regular" color="white">
			Cette question nous permet de calculer votre nombre de <b>parts fiscales</b>. C’est une information nécessaire
			pour vous offrir, au sein de votre investissement, une <b>optimisation fiscale pertinente</b>.
		</Text>
	),
	revenu: (
		<Text variant="Text-S-Regular" color="white">
			Cette question, combinée à la précédente, nous permet de modéliser <b>les impôts</b> que vous devez verser à
			l'État. Notre travail est notamment de diminuer ce montant, pour que vous puissiez l’investir à la place,{' '}
			<b>sans effort financier supplémentaire</b>.<br />
			<br />
			Remarque : nous utilisons les définitions de l’Urssaf. Par exemple, <b>‘net’</b> signifie{' '}
			<b>‘net avant imposition’</b>, en d’autres termes avant prélèvement de l'impôt sur le revenu.
		</Text>
	),
};

const FoyerFiscalFormContext = (): JSX.Element => {
	const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
	const {
		register,
		control,
		formState: { errors },
		watch,
	} = useFormContext<FoyerFiscalType>();
	const familySituation = watch('familySituation');
	const isMarried = familySituation === FamilySituation.MARRIED || familySituation === FamilySituation.CIVIL_UNION;

	return (
		<VStack w="100%" align={isMobile ? 'center' : 'start'} spacing="md">
			<Wrap w="100%" spacing="md">
				<WrapItem w={onboardingInputWidth}>
					<FormControl isInvalid={!!errors.familySituation}>
						<FormLabel>Situation familliale</FormLabel>
						<ReferentielInput
							name="familySituation"
							referentiel={FamilySituationReferentiel}
							register={register}
							options={{ required: true }}
						/>
						{errors.familySituation?.type === 'required' && (
							<FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>
						)}
					</FormControl>
				</WrapItem>
				{isMarried && (
					<WrapItem w={onboardingInputWidth}>
						<FormControl isInvalid={!!errors.regimeMatrimonial}>
							<FormLabel>Régime matrimonial</FormLabel>
							<ReferentielInput
								name="regimeMatrimonial"
								referentiel={MatrimonialRegimeReferentiel}
								register={register}
								options={{ required: true }}
							/>
							{errors.regimeMatrimonial && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
						</FormControl>
					</WrapItem>
				)}
			</Wrap>

			<FormControl w={onboardingInputWidth} isInvalid={!!errors.childNumber}>
				<FormLabel>Nombre d'enfant(s) ?</FormLabel>
				<Input
					type="number"
					placeholder="Nombre d'enfants à charge"
					onWheel={(event) => event.currentTarget.blur()}
					{...register('childNumber', { required: true, min: 0, max: 12, valueAsNumber: true })}
				/>

				{errors.childNumber?.type === 'required' && <FormErrorMessage>Ce champ est obligatoire</FormErrorMessage>}
				{errors.childNumber?.type === 'max' && (
					<FormErrorMessage>Le nombre d'enfants maximal a été dépassé</FormErrorMessage>
				)}
			</FormControl>
			<FormControl w={onboardingInputWidth} isInvalid={!!errors.professionalSituation}>
				<HStack spacing="sm" mb="8px">
					<FormLabel mb="0px">Situation professionnelle</FormLabel>
					<CustomTooltip content={tooltipFoyerFiscal.proSituation} />
				</HStack>
				<ReferentielInput
					name="professionalSituation"
					referentiel={ProfessionalSituationReferentiel}
					register={register}
					options={{ required: true }}
				/>
				{errors.professionalSituation?.type === 'required' && <FormErrorMessage children="Ce champ est nécessaire" />}
			</FormControl>

			<Wrap spacing="md" w="100%" overflow="visible">
				{/* Ask for income */}
				<WrapItem w={onboardingInputWidth}>
					<VStack align="start" w="100%">
						<FormControl isInvalid={!!errors.revenu}>
							<HStack spacing="sm" mb="8px">
								<FormLabel mb="0px">Vos revenus</FormLabel>
								<CustomTooltip content={tooltipFoyerFiscal.revenu} />
							</HStack>

							<VStack align="start" spacing="sm" w="100%">
								<FormControl isInvalid={!!errors.revenu}>
									<Controller
										name="revenu"
										control={control}
										rules={{ required: true, min: 10 }}
										render={({ field }) => <NumberInput {...field} placeholder="Minimum 10€ annuel" suffix=" €" />}
									/>
									{errors.revenu?.type === 'required' && <FormErrorMessage children="Ce champ est nécessaire" />}
									{errors.revenu?.type === 'min' && (
										<FormErrorMessage children="Votre revenu doit être égal ou supérieur à 10 € par an" />
									)}
								</FormControl>
								<HStack spacing="sm" w="100%" align="start">
									<VStack align="start" w="50%">
										<FormControl isInvalid={!!errors.revenuType}>
											<ReferentielInput
												name="revenuType"
												referentiel={IncomeTypeReferentiel}
												register={register}
												options={{ required: true }}
											/>
											{errors.revenuType?.type === 'required' && (
												<FormErrorMessage children="Ce champ est nécessaire" />
											)}
										</FormControl>
									</VStack>

									<VStack align="start" w="50%">
										<FormControl isInvalid={!!errors.revenuPeriod}>
											<ReferentielInput
												name="revenuPeriod"
												referentiel={IncomePeriodReferentiel}
												register={register}
												options={{ required: true }}
											/>
											{errors.revenuPeriod?.type === 'required' && (
												<FormErrorMessage children="Ce champ est nécessaire" />
											)}
										</FormControl>
									</VStack>
								</HStack>
							</VStack>
						</FormControl>
					</VStack>
				</WrapItem>

				{/* Ask for partner income if needed */}
				{isMarried && (
					<WrapItem w={onboardingInputWidth}>
						<VStack align="start" w="100%">
							<FormControl isInvalid={!!errors.revenuSpouse}>
								<HStack spacing="sm" mb="8px">
									<FormLabel mb="0px">Revenus de votre conjoint</FormLabel>
									<CustomTooltip content={tooltipFoyerFiscal.revenu} />
								</HStack>
								<VStack align="start" spacing="sm" w="100%">
									<FormControl isInvalid={!!errors.revenuSpouse}>
										<Controller
											name="revenuSpouse"
											control={control}
											rules={{ required: true, min: 0 }}
											render={({ field }) => <NumberInput {...field} placeholder="0 si sans revenus" suffix=" €" />}
										/>
										{errors.revenuSpouse?.type === 'required' && (
											<FormErrorMessage children="Ce champ est nécessaire" />
										)}
										{errors.revenuSpouse?.type === 'minMarriedIncomeError' && (
											<FormErrorMessage children="Le cumulé des revenus de vous et votre conjoint doit être supérieur à 1000 € annuel" />
										)}
									</FormControl>

									<HStack spacing="sm" w="100%" align="start">
										<VStack align="start" w="50%">
											<FormControl isInvalid={!!errors.revenuSpouseType}>
												<ReferentielInput
													name="revenuSpouseType"
													referentiel={IncomeTypeReferentiel}
													register={register}
													options={{ required: true }}
												/>

												{errors.revenuSpouseType?.type === 'required' && (
													<FormErrorMessage children="Ce champ est nécessaire" />
												)}
											</FormControl>
										</VStack>

										<VStack align="start" w="50%">
											<FormControl isInvalid={!!errors.revenuSpousePeriod}>
												<ReferentielInput
													name="revenuSpousePeriod"
													referentiel={IncomePeriodReferentiel}
													register={register}
													options={{ required: true }}
												/>
												{errors.revenuSpousePeriod?.type === 'required' && (
													<FormErrorMessage children="Ce champ est nécessaire" />
												)}
											</FormControl>
										</VStack>
									</HStack>
								</VStack>
							</FormControl>
						</VStack>
					</WrapItem>
				)}
			</Wrap>
		</VStack>
	);
};

export default FoyerFiscalFormContext;
