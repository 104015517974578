import { useMemo } from 'react';
import { Card, Heading, HStack, SimpleGrid, Skeleton, Text, VStack } from '@chakra-ui/react';

import PortfolioScenariosGraph from 'components/simulationResult/PortfolioScenariosGraph';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { useRiskDisplay } from 'hooks/useRiskDisplay';
import { useTemperatureDisplay } from 'hooks/useTemperatureDisplay';
import { getRoundedTimeHorizon } from 'onboarding/Invest/Shared/Objective/projectData';
import { OnboardingUpdateStepProps } from 'onboarding/Stepper/stepper.slice';
import { useGetProjectionsQuery } from 'services/requests/invest/subscription';
import { displayCapitalized, displayMoney } from 'utils/rendering';

const Recapitulatif = ({ object, onNext }: OnboardingUpdateStepProps): JSX.Element => {
	const riskDisplay = useRiskDisplay(object.investmentPreferences!.risk!);
	const timeHorizonPlan = useMemo(() => getRoundedTimeHorizon(object.timeHorizon!), [object.timeHorizon]);
	const { data: scenarios, isLoading: isProjectionLoading } = useGetProjectionsQuery({ subscriptionId: object.id });
	const temperatureDisplay = useTemperatureDisplay(object.investmentPreferences!.temperature);

	const contractData = [
		{
			title: 'Montant initial',
			value: displayMoney(object.initialAUM),
		},
		{
			title: 'Versements programmés',
			value: displayMoney(object.saving),
			description: 'Mensuel',
		},
		{
			title: 'Type de portefeuille',
			value: displayCapitalized(object.investmentPreferences!.portfolioType!),
		},
		{
			title: 'Mon profil de risque',
			value: riskDisplay.value,
			description: riskDisplay.name,
		},
		...(object.investmentPreferences?.esg
			? [
					{
						title: 'Ramify Green',
						value: temperatureDisplay.name,
						description: temperatureDisplay.description,
					},
			  ]
			: []),
		{
			title: 'Horizon d’investissement',
			value: timeHorizonPlan.label,
		},
	];

	return (
		<VStack align="start" spacing="48px" padding="0 0 48px 0">
			<Heading variant="Title-XL-Bold">Récapitulatif du contrat</Heading>

			<SimpleGrid w="100%" columns={{ base: 1, sm: 2 }} spacing="24px">
				{contractData.map((data) => (
					<Card key={data.title} minWidth="250px">
						<VStack align="start" spacing="16px">
							<Text variant="Title-S-SemiBold">{data.title}</Text>
							<HStack spacing="16px">
								<Text variant="Title-M-Bold" color="blue.900">
									{data.value}
								</Text>
								{data.description && (
									<Text variant="Text-S-Regular" color="grey.700">
										{data.description}
									</Text>
								)}
							</HStack>
						</VStack>
					</Card>
				))}
			</SimpleGrid>

			{isProjectionLoading ? (
				<Skeleton h="150px" w="100%" />
			) : (
				scenarios && <PortfolioScenariosGraph portfolioScenarios={scenarios} />
			)}

			<BottomStepBar onNext={onNext} showPrev={false} />
		</VStack>
	);
};

export default Recapitulatif;
