export const thinScrollbar = {
	/* total width */
	'&::-webkit-scrollbar': {
		backgroundColor: 'white',
		width: '16px',
		borderRadius: '16px',
	},
	/* background of the scrollbar except button or resizer */
	'&::-webkit-scrollbar-track': {
		backgroundColor: 'white',
		borderRadius: '16px',
	},
	/* scrollbar itself */
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: '#babac0',
		borderRadius: '16px',
		border: '4px solid white',
	},
	/* set button(top and bottom of the scrollbar) */
	'&::-webkit-scrollbar-button': {
		display: 'none',
	},
};

export const hiddenScrollBar = {
	'&::-webkit-scrollbar': {
		width: '0px',
		background: 'transparent',
	},
};
