import { FC } from 'react';
import { Heading, VStack } from '@chakra-ui/react';

import ApicilOperationCard from 'components/operations/ApicilOperationCard';
import { ApicilOperationType, useGetAllOperationsQuery } from 'services/requests/operations';

const depositOperationList: ApicilOperationType[] = [
	'VERSEMENT',
	'VERSEMENT_PROGRAMME',
	'SUPPRESSION', // Suppression VP
	'RACHAT_TOTAL',
	'RACHAT_PARTIEL',
];

// Affiche les opérations de type "VERSEMENT" en cours
const DepositOperations: FC = () => {
	const { data } = useGetAllOperationsQuery();

	if (!data) return null;

	const depositOperations = data.operations.filter(
		(operation) =>
			depositOperationList.includes(operation.typeOperation) && operation.codeStatut === 'TRAITEMENT_DOSSIER_EN_COURS',
	);

	if (depositOperations.length === 0) return null;

	return (
		<VStack w="100%" align="start" spacing="16px">
			<Heading variant="Title-L-SemiBold">Opérations en cours de traitement</Heading>
			{depositOperations.map(({ typeOperation, montant, dateSaisie, envelopeType, id }) => (
				<ApicilOperationCard
					key={id}
					typeOperation={typeOperation}
					dateSaisie={dateSaisie}
					envelopeType={envelopeType}
					montant={montant}
				/>
			))}
		</VStack>
	);
};

export default DepositOperations;
