import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EarthFilled, Idea } from '@carbon/icons-react';
import {
	Card,
	Center,
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Image,
	ImageProps,
	Radio,
	RadioGroup,
	Skeleton,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import Leaf from 'assets/icons/LeafGrey.svg';
import GreenLarge20 from 'assets/onboarding/green/Large2,0.png';
import GreenLarge25 from 'assets/onboarding/green/Large2,5.png';
import GreenSmall20 from 'assets/onboarding/green/Small2,0.png';
import GreenSmall25 from 'assets/onboarding/green/Small2,5.png';
import { ArrowButton } from 'components/Button/Button';
import CustomTooltip from 'components/CustomTooltip';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { useAppResponsive } from 'hooks/useAppResponsive';
import useThemedToast from 'hooks/useThemedToast';
import { OnboardingUpdateStepProps } from 'onboarding/Stepper/stepper.slice';
import {
	GreenPreferenceParams,
	useLazyGetParisAlignedFundsRangeQuery,
	useUpdatePortfolioGreenMutation,
} from 'services/requests/invest/preferences';
import { isNotNone } from 'utils/functions';
import { displayPercentage } from 'utils/rendering';

const PortfolioStyle = ({ object: onboardingObject, onNext, onPrev }: OnboardingUpdateStepProps): JSX.Element => {
	const [updatePortfolioGreen] = useUpdatePortfolioGreenMutation();
	const toast = useThemedToast();
	const [triggerParisRange, { data: parisRange, isFetching: isFetchingParisRange }] =
		useLazyGetParisAlignedFundsRangeQuery();

	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
	} = useForm<GreenPreferenceParams>();

	const [ESG, temperature] = watch(['ESG', 'temperature']);
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;

	useEffect(() => {
		if (isNotNone(onboardingObject.investmentPreferences?.esg)) {
			setValue('ESG', onboardingObject.investmentPreferences!.esg);
			if (
				onboardingObject.investmentPreferences?.esg === true &&
				isNotNone(onboardingObject.investmentPreferences?.temperature)
			)
				setValue('temperature', onboardingObject.investmentPreferences.temperature);
		}
	}, [setValue, onboardingObject.investmentPreferences]);

	useEffect(() => {
		if (ESG && temperature)
			triggerParisRange({ temperature, id: onboardingObject.id, entityName: onboardingObject.table });
	}, [ESG, onboardingObject.id, onboardingObject.table, temperature, triggerParisRange]);

	const graphImageResponsive = useAppResponsive({
		base: {
			2: GreenSmall20,
			2.5: GreenSmall25,
		},
		md: {
			2: GreenLarge20,
			2.5: GreenLarge25,
		},
	});

	const graphImage = useMemo<ImageProps['src']>(() => {
		if (!ESG) return undefined;
		if (temperature === 2) return graphImageResponsive?.[2];
		if (temperature === 2.5) return graphImageResponsive?.[2.5];
		return undefined;
	}, [graphImageResponsive, temperature, ESG]);

	const onSubmit = handleSubmit((data: GreenPreferenceParams) => {
		updatePortfolioGreen({ ...data, id: onboardingObject.id, entityName: onboardingObject.table })
			.unwrap()
			.then(() => {
				onNext();
			})
			.catch(() => {
				toast({
					title: "Une erreur s'est produite.",
					status: 'error',
					duration: 4500,
					isClosable: true,
				});
			});
	});

	return (
		<VStack w="100%" align="start" spacing="24px">
			<Heading variant="Title-XL-Bold">Mon style d’investissement</Heading>
			<chakra.form onSubmit={onSubmit}>
				<VStack align="start" spacing="24px">
					<FormControl isInvalid={!!errors.ESG} w={onboardingInputWidth}>
						<FormLabel>
							<HStack>
								<Text variant="Text-S-Medium">Êtes-vous interessés par l’investissement durable&nbsp;?</Text>
								<CustomTooltip text="L'investissement durable est l'ensemble des stratégies d'investissement qui intègrent des critères extra-financiers, liées à des problématiques environnementales, sociales et de Gouvernance (ESG), au-delà des critères financiers classiques." />
							</HStack>
						</FormLabel>

						<Controller
							name="ESG"
							control={control}
							rules={{ validate: { required: (value) => isNotNone(value) } }}
							render={({ field: { onChange, value, ...rest } }) => (
								<RadioGroup
									variant="single"
									size="sm"
									onChange={(newValue) => onChange(newValue === 'true')}
									value={isNotNone(value) ? (value ? 'true' : 'false') : undefined}
									{...rest}
								>
									<VStack spacing="8px">
										<Radio value="true">Oui</Radio>
										<Radio value="false">Non</Radio>
									</VStack>
								</RadioGroup>
							)}
						/>

						{errors.ESG?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					</FormControl>

					<VStack w="100%" align="start" spacing="24px">
						{ESG === true && (
							<Wrap spacing="24px" align="stretch">
								<WrapItem w={onboardingInputWidth}>
									<VStack align="start" spacing="16px" flex={1} w="100%">
										<FormControl isInvalid={!!errors.temperature}>
											<FormLabel>
												<VStack align="start" w="100%" spacing="0px">
													<HStack>
														<>Choisissez l’impact en température de votre portefeuille</>
														<CustomTooltip text="L'objectif à long terme de l'accord de Paris en matière de température est de maintenir l'augmentation de la température moyenne de la planète bien en dessous de 2 °C par rapport aux niveaux préindustriels." />
													</HStack>
													<Text variant="Text-XS-Medium" color="grey.700">
														L’objectif de l'accord de Paris est fixé à +2,0°C d’augmentation d’ici 2050
													</Text>
												</VStack>
											</FormLabel>

											<Controller
												name="temperature"
												control={control}
												rules={{ validate: { required: (value) => isNotNone(value) } }}
												render={({ field: { onChange, value, ...rest } }) => (
													<RadioGroup
														variant="single"
														size="sm"
														{...rest}
														onChange={(newValue) => onChange(+newValue)}
														value={`${value}`}
													>
														<VStack spacing="8px" w="100%">
															<Radio value="2">
																<HStack>
																	<>+ 2,0°C</>
																	<Image src={Leaf} />
																</HStack>
															</Radio>
															<Radio value="2.5">+2,5°C</Radio>
														</VStack>
													</RadioGroup>
												)}
											/>

											{errors.temperature?.type === 'required' && (
												<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
											)}
										</FormControl>
									</VStack>
								</WrapItem>

								{temperature && (
									<WrapItem w={onboardingInputWidth}>
										<Card padding="16px" h="100%">
											<VStack align="start">
												<Skeleton isLoaded={!isFetchingParisRange}>
													<HStack>
														<Center bg="brand.RAMIFYGREEN" borderRadius="50%" w="40px" h="40px" flexShrink="0">
															<EarthFilled size="24" color="white" />
														</Center>
														{parisRange && (
															<Text variant="Button-SemiBold" color="brand.RAMIFYGREEN">
																{displayPercentage(parisRange[0])}{' '}
																{parisRange[1] !== parisRange[0] && <>- {displayPercentage(parisRange[1])}</>} de fonds
																actions alignés avec l’Accord de Paris
															</Text>
														)}
													</HStack>
												</Skeleton>
												<Text variant="Text-S-Regular" color="brand.RAMIFYGREEN">
													Produits financiers qui ont pour objectif une exposition à de faibles émissions de carbone en
													vue d’atteindre les objectifs à long terme de l’Accord de Paris en matière de réchauffement
													climatique. (cf. Article 9 SFDR)
												</Text>
											</VStack>
										</Card>
									</WrapItem>
								)}
							</Wrap>
						)}

						{graphImage && (
							<VStack align="start">
								<Image src={graphImage} w="100%" maxW="1660px" />
								{!isMobile && (
									<Text variant="Text-S-Regular" color="grey.900">
										Ces scénarios représentent une moyenne des fourchettes d’incertitude estimées, prenant en compte les
										incertitudes des impacts du changement climatique et la mise en œuvre des contributions nationales.
										Par exemple : le scénario 2 °C correspond à un scénario à moindre coût avec 66 % de chance de rester
										en dessous des 2 °C.
										<br />
									</Text>
								)}
								<Text variant="Text-S-Regular" color="grey.900">
									Source : Rapport de synthèse de la CCNUCC, 2016
								</Text>
							</VStack>
						)}
						{graphImage && (
							<Card
								size="sm"
								cursor="pointer"
								bg="green.100"
								_hover={{ borderColor: 'green.900' }}
								onClick={() => window.open('https://ramify.fr/livre-vert')}
							>
								<HStack spacing="16px" align="start">
									<Center borderRadius="full" bg="green.900" p="8px">
										<Idea size="24" color="white" />
									</Center>
									<VStack align="start" w="100%" spacing="8px">
										<Text variant="Title-S-SemiBold">Comment est calculé l'impact en température ?</Text>
										<Text variant="Text-S-Regular" color="grey.900">
											Nous mesurons l'alignement des fonds actions et obligataires avec les objectifs climatiques de
											l'Accords de Paris. L'impact de la température est calculé à l'échelle de chaque fonds à partir
											d'indicateurs d'intensité d'émissions de gaz à effet de serre (ou "intensité carbone" après
											conversion en équivalent CO2) des émetteurs auxquels le fonds est exposé.
										</Text>
										<ArrowButton variant="tertiary">Détails du calcul</ArrowButton>
									</VStack>
								</HStack>
							</Card>
						)}
					</VStack>
				</VStack>
			</chakra.form>
			<BottomStepBar onPrev={onPrev} onNext={onSubmit} showNext />
		</VStack>
	);
};

export default PortfolioStyle;
