import { useMemo } from 'react';
import { ArrowLeft, CarbonIconType, Cube, Lifesaver, Money, Security, WarningAlt } from '@carbon/icons-react';
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	HStack,
	IconButton,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import InformationBanner from 'components/Banners/InformationBanner';
import MetricCard from 'components/cards/MetricCard';
import { CalendlyStep } from 'pages/OffreBlack/DiscoverBlack/Modal/Calendly';

type CreditLomardDrawerType = {
	icon: CarbonIconType;
	title: string;
	label: string;
};

const CreditLomardDrawer = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }): JSX.Element => {
	const {
		isOpen: isOpenAppointementModal,
		onOpen: onOpenAppointementModal,
		onClose: isCloseAppointementModal,
	} = useDisclosure();
	const advantageList: CreditLomardDrawerType[] = useMemo(
		() => [
			{
				icon: Lifesaver,
				title: 'Type',
				label: 'Prêt bancaire amortissable',
			},
			{
				icon: Money,
				title: 'Montant',
				label: '6.000 € à 200.000 €, maximum 50% du contrat d’assurance-vie',
			},
			{
				icon: Cube,
				title: 'Durée',
				label: '60 à 180 mois (5 à 15 ans)',
			},
			{
				icon: Security,
				title: 'Taux',
				label: 'Taux et mensualités fixes',
			},
		],
		[],
	);

	return (
		<VStack align="start" w="100%">
			{isOpenAppointementModal && (
				<CalendlyStep
					url="https://calendly.com/d/y4s-6xr-tf4/echange-avec-un-conseiller-black-ramify"
					onClose={isCloseAppointementModal}
				/>
			)}
			<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isOpen} onClose={onClose}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader>
						<HStack w="100%" justify="space-between">
							<HStack>
								<IconButton
									onClick={onClose}
									variant="icon"
									aria-label="back"
									icon={
										<Box>
											<ArrowLeft size="32" />
										</Box>
									}
								/>
							</HStack>
							<Button
								variant="primary"
								onClick={() => {
									onOpenAppointementModal();
								}}
							>
								Prendre rendez-vous
							</Button>
						</HStack>
					</DrawerHeader>
					<DrawerBody>
						<VStack align="start" spacing="56px">
							<VStack align="start" spacing="16px">
								<Text variant="Title-L-SemiBold">Qu'est-ce-que le crédit Lombard ?</Text>
								<Text variant="Text-M-Regular">
									Le crédit lombard est un type de prêt en échange de la mise en garantie de certains actifs financiers
									du demandeur, tels que des titres, des actions, ou des obligations. Ce type de crédit permet à
									l'emprunteur d'obtenir des liquidités tout en conservant ses investissements en{' '}
									<Text variant="Text-M-Bold" color="primary.black" as="span">
										assurance vie
									</Text>
									. Les taux d'intérêt du crédit lombard sont généralement compétitifs, et il offre une flexibilité
									financière en permettant d'utiliser les actifs comme garantie sans les vendre.
								</Text>
							</VStack>
							<VStack align="start" spacing="32px" w="100%">
								<Text variant="Title-L-SemiBold">Les caractéristiques</Text>
								<HStack overflowX="auto" width="100%" spacing="24px" alignItems="stretch">
									{advantageList.map((data) => (
										<MetricCard key={data.title} {...data} />
									))}
								</HStack>
							</VStack>
							<VStack align="start" spacing="8px" w="100%">
								<Text variant="Title-L-SemiBold">Qu'est-ce que le nantissement ?</Text>
								<Text variant="Text-M-Regular">
									Le nantissement est l'acte qui rend possible l'acceptation de votre crédit lombard, en contrepartie
									des actifs apportés en garantie. En cas de non-paiement du solde du prêt à son terme, le nantissement
									nous permet de vendre vos titres pour rembourser le prêt. Chez nous, il faut apporter un nantissement
									égal à 200% du montant de votre emprunt.
								</Text>
							</VStack>

							<InformationBanner
								description="Le ratio de couverture de 200% de vos avoirs nantis doit être maintenu durant toute la vie de votre crédit. Pour le calcul de ce ratio, nous prenons en compte : la valorisation de votre Assurance-vie. En cas de diminution de la valeur de vos avoirs, nous vous demanderons de reconstituer votre couverture afin que la valeur des comptes nantis soit toujours égale au double du montant emprunté."
								icon={WarningAlt}
							/>
						</VStack>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</VStack>
	);
};

export default CreditLomardDrawer;
