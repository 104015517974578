import { useDisclosure, VStack } from '@chakra-ui/react';

import {
	CharacteristicsCATSection,
	CharacteristicsCERSection,
} from 'pages/Cash/Discover/Sections/CharacteristicsSection';
import DiscoverSection from 'pages/Cash/Discover/Sections/DiscoverSection';
import SimulationSection from 'pages/Cash/Discover/Sections/SimulationSection';

import AdvantageSection from './Sections/AdvantageSection';
import { CatDrawer } from './Sections/Drawers/Cat';
import { CerDrawer } from './Sections/Drawers/Cer';

const Discover = (): JSX.Element => {
	const { isOpen: isCerOpen, onOpen: onCerOpen, onClose: onCerClose } = useDisclosure();
	const { isOpen: isCatOpen, onOpen: onCatOpen, onClose: onCatClose } = useDisclosure();

	return (
		<>
			<VStack align="start" spacing="56px" w="100%">
				<DiscoverSection onCerDetails={onCerOpen} onCatDetails={onCatOpen} />
				<AdvantageSection />
				<SimulationSection />
				<CharacteristicsCERSection onDetails={onCerOpen} />
				<CharacteristicsCATSection onDetails={onCatOpen} />
			</VStack>

			<CerDrawer isOpen={isCerOpen} onClose={onCerClose} />
			<CatDrawer isOpen={isCatOpen} onClose={onCatClose} />
		</>
	);
};

export default Discover;
