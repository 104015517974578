import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useGotoCashOnboarding from 'hooks/useGotoCashOnboarding';
import { useProducts } from 'hooks/useProducts';

import { ProductActions } from './type';

type UseGetActionsCashProps = {
	showAppointments?: boolean;
	onCalendly?: () => void;
	onOpenVersement: () => void;
};

const useGetActionsCash = ({
	showAppointments = false,
	onCalendly,
	onOpenVersement,
}: UseGetActionsCashProps): { actions: ProductActions[] } => {
	const navigate = useNavigate();
	const { savings } = useProducts();
	const [state, setState] = useState<ProductActions[]>([]);
	const { goToOnboarding } = useGotoCashOnboarding();

	useEffect(() => {
		const options = [
			{
				label: "Ouvrir un livret d'épargne",
				onClick: () => goToOnboarding(),
				isAvailable: !savings.isOpen,
			},
			{
				label: 'Faire un nouveau versement',
				onClick: () => onOpenVersement(),
				isAvailable: savings.isOpen,
			},
			{
				label: 'Prendre rendez-vous avec un conseiller',
				onClick: onCalendly || (() => {}),
				isAvailable: showAppointments,
			},
		];

		setState(options.filter((o) => o.isAvailable).map((o) => ({ label: o.label, onClick: o.onClick })));
		// !!
		// Ne surtout pas mettre onOpenVersement et goToOnboarding dans le tableau de dépendances !
		// Pas le temps de faire ça proprement car contrainte de temps
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showAppointments, onCalendly, navigate, savings.isOpen]);

	return { actions: state };
};

export default useGetActionsCash;
