export enum PatrimonyOrigin {
	SAVINGS = 'SAVINGS',
	DONATION = 'DONATION',
	REAL_ESTATE_DISPOSAL = 'REAL_ESTATE_DISPOSAL',
	ASSET_DISPOSAL = 'ASSET_DISPOSAL',
	PROFESSIONAL_DISPOSAL = 'PROFESSIONAL_DISPOSAL',
	GAMES_WINNINGS = 'GAMES_WINNINGS',
	OTHER = 'OTHER',
}

const patrimonyOriginReferentiel: Record<PatrimonyOrigin, string> = {
	SAVINGS: 'Epargne, revenus',
	DONATION: 'Succession / Donation',
	REAL_ESTATE_DISPOSAL: 'Cession d’actif(s) immobilier(s)',
	ASSET_DISPOSAL: 'Cession d’actif(s) mobilier(s)',
	PROFESSIONAL_DISPOSAL: 'Cession d’actif(s) professionnel(s)',
	GAMES_WINNINGS: 'Gain aux jeux',
	OTHER: 'Autre',
};

export default patrimonyOriginReferentiel;
