import { useState } from 'react';
import { Button, Card, Heading, HStack, VStack } from '@chakra-ui/react';

import { AttributeKycLabel } from 'components/badge/AttributeKycLabel';
import EtatCivilForm from 'onboarding/KYC/Forms/EtatCivilForm';
import CivilStatusReferentiel from 'store/referentiels/civilStatus';
import FamilySituationReferentiel, { FamilySituation } from 'store/referentiels/familySituation';
import MatrimonialRegimeReferentiel from 'store/referentiels/matrimonial';
import CitizenshipReferentiel, { Citizenship } from 'store/referentiels/pays/ciziten';
import BirthCountryReferentiel from 'store/referentiels/pays/naissance';
import { KYC } from 'store/types/KYC.type';

const EtatCivilEditable = ({ kyc }: { kyc?: KYC }): JSX.Element => {
	const [isEditing, setisEditing] = useState(false);

	const birthCountry = Object.entries(BirthCountryReferentiel).find(([key]) => key === kyc?.birthCountry)?.[1]; // [0 => country key, 1 => country name]

	return (
		<Card w="100%">
			<VStack w="100%" spacing="md" align="start">
				<HStack spacing="40px" w="100%" justify="space-between">
					<Heading variant="Title-S-SemiBold">État civil</Heading>
					<Button variant="secondary" onClick={() => setisEditing(!isEditing)}>
						{isEditing ? 'Annuler' : 'Modifier'}
					</Button>
				</HStack>
				{isEditing ? (
					<EtatCivilForm
						product="kyc-update"
						onSuccess={() => setisEditing(false)}
						formConfirmation={
							<Button type="submit" w="100%" borderRadius="5px" variant="primary">
								Mettre à jour
							</Button>
						}
					/>
				) : (
					<VStack align="start">
						<AttributeKycLabel title="Prénom" value={kyc?.firstName && kyc.firstName.toString()} />
						<AttributeKycLabel title="Nom" value={kyc?.lastName && kyc.lastName.toString()} />
						{kyc?.civilStatus &&
							CivilStatusReferentiel[kyc?.civilStatus].toString() === CivilStatusReferentiel.MADAME.toString() && (
								<AttributeKycLabel title="Nom de Naissance" value={kyc?.birthName && kyc.birthName.toString()} />
							)}

						<AttributeKycLabel
							title="Date de naissance"
							value={kyc?.birthDate && kyc?.birthDate.toString().split('T')[0].split('-').reverse().join('/')}
						/>
						<AttributeKycLabel
							title="Civilité"
							value={kyc?.civilStatus && CivilStatusReferentiel[kyc?.civilStatus].toString()}
						/>
						<AttributeKycLabel
							title="Nationalité"
							value={kyc?.citizenship && CitizenshipReferentiel[kyc?.citizenship as Citizenship].toString()}
						/>
						<AttributeKycLabel
							title="Situation familiale"
							value={kyc?.familySituation && FamilySituationReferentiel[kyc?.familySituation].toString()}
						/>
						{(kyc?.familySituation === FamilySituation.MARRIED ||
							kyc?.familySituation === FamilySituation.CIVIL_UNION) && (
							<AttributeKycLabel
								title="Régime matrimonial"
								value={kyc?.matrimonialRegime && MatrimonialRegimeReferentiel[kyc?.matrimonialRegime].toString()}
							/>
						)}
						<AttributeKycLabel title="Pays de naissance" value={kyc?.birthCountry && birthCountry?.toString()} />
						<AttributeKycLabel title="Ville de naissance" value={kyc?.birthCity && kyc?.birthCity} />
					</VStack>
				)}
			</VStack>
		</Card>
	);
};

export default EtatCivilEditable;
