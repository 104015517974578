import { useEffect } from 'react';

import { useAppResponsive } from 'hooks/useAppResponsive';

export const usePositionIntercomBubble = (layout: 'onboarding' | 'app'): void => {
	const isMobile = useAppResponsive({ base: true, lg: false });

	const setIntercomBubbleVisibility = (isVisible: boolean) => {
		window.Intercom('update', {
			hide_default_launcher: !isVisible,
		});
	};

	useEffect(() => {
		if (layout === 'app') {
			setIntercomBubbleVisibility(true);
			window.Intercom('update', {
				horizontal_padding: 20,
				vertical_padding: 20,
			});
		} else if (layout === 'onboarding') {
			if (isMobile) {
				setIntercomBubbleVisibility(false);
			} else {
				setIntercomBubbleVisibility(true);
				window.Intercom('update', {
					horizontal_padding: 20,
					vertical_padding: 90,
				});
			}
		}
	}, [layout, isMobile]);
};
