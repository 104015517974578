import { useMemo } from 'react';
import { Grid, GridItem, HStack, Image, Text, useDisclosure, VStack } from '@chakra-ui/react';

import AVlux from 'assets/icons/OffreBlack/AVlux.png';
import clubDeal from 'assets/icons/OffreBlack/clubDeal.png';
import creditLombard from 'assets/icons/OffreBlack/creditLombard.png';
import Exoneration from 'assets/icons/OffreBlack/exoneration.png';

import { CalendlyModalBlack } from '../Modal';

type ServicesProductsOffreBlackCardProps = {
	title: string;
	icon: string;
	description: string;
};

const ServicesProductsOffreBlack = (): JSX.Element => {
	const { isOpen: isCalendlyOpen, onClose: onCalendlyClose, onOpen: onCalendlyOpen } = useDisclosure();

	const ServicesProductsOffreBlackCard: ServicesProductsOffreBlackCardProps[] = useMemo(
		() => [
			{
				title: 'Assurance-vie Luxembourgeoise',
				icon: AVlux,
				description:
					'Ce contrat offre de nombreux avantages, tels que la neutralité fiscale et la possibilité de choisir vos devises (€, $, £, CHF). Votre capital est protégé et garanti sans plafond par le Triangle de Sécurité.',
			},
			{
				title: 'Crédit Lombard',
				icon: creditLombard,
				description:
					'Optimisez vos investissements : investissez plus et utilisez vos placements comme garantie. En positionnant une partie de vos actifs financiers comme garantie de prêt, le Crédit Lombard vous octroie rapidement une marge de manœuvre financière confortable.',
			},
			{
				title: 'Club Deal',
				icon: clubDeal,
				description:
					'Investissez via des Clubs Deals, sur des produits réservés à une minorité d’investisseurs. Bénéficiez également d’un accès prioritaire aux offres promotionnelles limitées et valorisez vos placements.',
			},
			{
				title: 'Exonération 150-0 B Ter',
				icon: Exoneration,
				description:
					"Avec notre catalogue d’opportunités d’investissements éligibles à l’article 150-0 B ter, différez une redevance en l’investissant sur des fonds performants. Nous sélectionnons les meilleurs fonds éligibles à l'article 150-0 B ter.",
			},
		],
		[],
	);

	return (
		<VStack w="100%" align="start" spacing="40px">
			<CalendlyModalBlack isOpen={isCalendlyOpen} onClose={onCalendlyClose} />

			<Grid w="100%" templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }} gap={10}>
				{ServicesProductsOffreBlackCard.map((card) => (
					<GridItem bg="primary.black" key={card.title} flex="1" p="40px" borderRadius="8px">
						<VStack w="100%" align="start" spacing="40px">
							<HStack w="100%" justify="space-between">
								<Text variant="Title-L-SemiBold" color="white" maxW="218px">
									{card.title}
								</Text>
								<Image w="80px" src={card.icon} alt={card.title} />ƒ{' '}
							</HStack>
							<Text variant="Text-M-Regular" color="white">
								{card.description}
							</Text>
							<Text
								variant="Text-M-Regular"
								cursor="pointer"
								color="primary.yellow"
								alignSelf="end"
								onClick={onCalendlyOpen}
							>
								Devenir membre
							</Text>
						</VStack>
					</GridItem>
				))}
			</Grid>
		</VStack>
	);
};
export default ServicesProductsOffreBlack;
