import { useMemo } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Tab, TabList, Tabs, VStack } from '@chakra-ui/react';

import AirtableBanner from 'components/Banners/AirtableBanner';
import InvestHeaderProductPage from 'components/HeaderProductPage/InvestHeaderProduct';
import { useProducts } from 'hooks/useProducts';
import Contracts from 'pages/Invest/Contrats';
import DashboardInvest from 'pages/Invest/Dashboard';
import DiscoverInvest from 'pages/Invest/Discover';
import BannerActualization from 'pages/Invest/features/Actualization/Banner';
import Versements from 'pages/Invest/Versements';
import { useGetActualizationMandatoryQuery } from 'services/requests/kyc';

type MenuItem = {
	label: string;
	route: string;
	active: boolean;
};

function InvestLayout(): JSX.Element {
	const { invest } = useProducts();

	const navigate = useNavigate();
	const location = useLocation();

	const menu = useMemo<MenuItem[]>(
		() =>
			[
				{
					label: 'Découvrir',
					route: `/invest`,
				},
				...(invest.isOpen
					? [
							{
								label: 'Dashboard',
								route: `/invest/dashboard`,
							},
							{
								label: 'Contrats',
								route: `/invest/contrats`,
							},
							{
								label: 'Versements',
								route: `/invest/versements`,
							},
					  ]
					: []),
			].map((item) => ({
				active: item.route === '/invest' ? location.pathname === item.route : location.pathname.startsWith(item.route),
				...item,
			})),
		[invest.isOpen, location.pathname],
	);

	const tabIndex = useMemo(() => menu.findIndex((item) => item.active), [menu]);

	return (
		<>
			<VStack w="100%" spacing="24px" position="sticky" top="0px" zIndex={2} pt="46px" bg="white">
				<InvestHeaderProductPage />

				<Tabs w="100%" index={tabIndex}>
					<TabList>
						{menu.map((item) => (
							<Tab key={item.label} onClick={() => navigate(item.route)}>
								{item.label}
							</Tab>
						))}
					</TabList>
				</Tabs>
			</VStack>

			<AirtableBanner />

			<Outlet />
		</>
	);
}

const InvestRouter = (): JSX.Element => {
	const { data: needToUpdateKYC } = useGetActualizationMandatoryQuery();

	return (
		<VStack w="100%" spacing="32px" align="start" pb="56px">
			{needToUpdateKYC && <BannerActualization />}
			<Routes>
				{/* 4 base invest routes */}
				{/* Onboarding Invest needed */}
				<Route element={<InvestLayout />}>
					<Route index element={<DiscoverInvest />} />
					<Route path="dashboard" element={<DashboardInvest />} />
					<Route path="versements" element={<Versements />} />
					<Route path="contrats/*" element={<Contracts />} />
				</Route>

				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</VStack>
	);
};

export default InvestRouter;
