import { Card, HStack, StackProps, Text } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';

import { StepStatus } from './type';

type InnerStepProps = {
	status: StepStatus;
	name: string;
	tooltipText?: React.ReactNode;
	onClick: () => void;
} & StackProps;

const InnerStep = ({ status, name, tooltipText, onClick }: InnerStepProps): JSX.Element => {
	const isCurrentStep = status === StepStatus.Active;

	return (
		<Card
			ml="16px"
			cursor="pointer"
			border="none"
			bg={isCurrentStep ? 'yellow.100' : 'transparent'}
			padding="4px 16px"
			onClick={onClick}
		>
			<HStack spacing="16px">
				<Text
					variant={isCurrentStep ? 'Text-M-Bold' : 'Text-M-Medium'}
					color={isCurrentStep ? 'primary.black' : status === StepStatus.Completed ? 'grey.900' : 'grey.700'}
				>
					{name}
				</Text>
				{tooltipText && <CustomTooltip text={tooltipText} />}
			</HStack>
		</Card>
	);
};

export default InnerStep;
