import { FundSubscriptionStatus } from 'store/types/airtable.type';
import { ProductType } from 'store/types/global.type';
import { SavingsAccountStatus } from 'store/types/savingsAccount.type';
import { SubscriptionStatus } from 'store/types/subscription.type';

export interface SubscriptionStepDetails {
	label: string;
	description: string;
	tooltip?: React.ReactNode;
}

export interface SubscriptionStep<T> {
	label: string;
	value: T;
	details: SubscriptionStepDetails;
}

import automatedFundsSteps from 'utils/automatedFunds/steps';
import {
	LABEL_FINALISATION,
	LABEL_INVEST_CONTRACT_OPENED,
	LABEL_INVEST_CONTRACT_SIGNED,
	LABEL_INVEST_CONTRACT_VISUALISATION,
	LABEL_PENDING,
	LABEL_RECEPTION,
	LABEL_REQUESTED,
	LABEL_SAVINGS_ACCOUNT_FINALISATION,
	LABEL_SIGNED,
} from 'utils/automatedFunds/steps/steps';

import { isFundFoundAutomated } from './CurrentSubscription';

const peScpiSubscriptionSteps = (
	fundName: string,
	projectType: ProductType,
	status: FundSubscriptionStatus,
): SubscriptionStep<FundSubscriptionStatus>[] =>
	Object.keys(automatedFundsSteps).includes(fundName)
		? automatedFundsSteps[fundName]
		: isFundFoundAutomated(fundName, projectType)
		? [
				{
					label: 'Demande de souscription',
					value: FundSubscriptionStatus.REQUESTED,
					details: LABEL_REQUESTED,
				},
				{
					label: 'Signature du bulletin',
					value: FundSubscriptionStatus.CONTRACT_SENT,
					details: LABEL_SIGNED,
				},
				{
					label: 'Validation du dossier',
					value: FundSubscriptionStatus.SIGNED,
					details: LABEL_PENDING,
				},
				{
					label: 'Réception des fonds',
					value: FundSubscriptionStatus.PENDING,
					details: LABEL_RECEPTION,
				},
				{
					label: 'Finalisation',
					value:
						status === FundSubscriptionStatus.REGISTERED
							? FundSubscriptionStatus.REGISTERED
							: FundSubscriptionStatus.RECEIVED_FUNDS,
					details: LABEL_FINALISATION,
				},
		  ]
		: [
				{
					label: 'Demande de souscription',
					value: FundSubscriptionStatus.REQUESTED,
					details: LABEL_REQUESTED,
				},
				{
					label: 'Validation du dossier',
					value: FundSubscriptionStatus.PENDING,
					details: LABEL_PENDING,
				},
				{
					label: 'Signature du bulletin',
					value: FundSubscriptionStatus.CONTRACT_SENT,
					details: LABEL_SIGNED,
				},
				{
					label: 'Réception des fonds',
					value: FundSubscriptionStatus.SIGNED,
					details: LABEL_RECEPTION,
				},
				{
					label: 'Finalisation',
					value:
						status === FundSubscriptionStatus.REGISTERED
							? FundSubscriptionStatus.REGISTERED
							: FundSubscriptionStatus.RECEIVED_FUNDS,
					details: LABEL_FINALISATION,
				},
		  ];

const savingsAccountSubscriptionSteps: SubscriptionStep<SavingsAccountStatus>[] = [
	{
		label: 'Signature du bulletin',
		value: SavingsAccountStatus.SIGNED,
		details: LABEL_REQUESTED,
	},
	{
		label: 'Réception de votre dossier',
		value: SavingsAccountStatus.SENT_TO_PARTNER,
		details: LABEL_PENDING,
	},
	{
		label: 'Alimentation de votre livret',
		value: SavingsAccountStatus.WAITING_FOR_PAYMENT,
		details: LABEL_RECEPTION,
	},
	{
		label: 'Finalisation',
		value: SavingsAccountStatus.COMPLETED,
		details: LABEL_SAVINGS_ACCOUNT_FINALISATION,
	},
];

const investSubscriptionSteps: SubscriptionStep<null>[] = [
	{
		label: 'Après la signature',
		value: null,
		details: LABEL_INVEST_CONTRACT_SIGNED,
	},
	{
		label: "Après l'ouverture",
		value: null,
		details: LABEL_INVEST_CONTRACT_OPENED,
	},
	{
		label: 'Visualisation du contrat',
		value: null,
		details: LABEL_INVEST_CONTRACT_VISUALISATION,
	},
];

export const productSubscriptionSteps = (
	fundName: string,
	status: FundSubscriptionStatus | SavingsAccountStatus | SubscriptionStatus,
): Record<ProductType, SubscriptionStep<SavingsAccountStatus | FundSubscriptionStatus | null>[]> => {
	return {
		[ProductType.PE]: peScpiSubscriptionSteps(fundName, ProductType.PE, status as FundSubscriptionStatus),
		[ProductType.SCPI]: peScpiSubscriptionSteps(fundName, ProductType.SCPI, status as FundSubscriptionStatus),
		[ProductType.CASH]: savingsAccountSubscriptionSteps,
		[ProductType.INVEST]: investSubscriptionSteps,
	};
};

export default productSubscriptionSteps;
