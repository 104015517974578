import { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Heading, Skeleton, Text, useDisclosure, VStack } from '@chakra-ui/react';

import Banner from 'components/Banners/Banner';
import { ArrowButton } from 'components/Button/Button';
import { FAQ } from 'components/faq/FAQ';
import { CalendlyModal } from 'components/Modals/Calendly';
import { IntestedProducts } from 'components/Modals/Calendly/useBuildIframeUrl';
import { SubscriptionConfirmModal } from 'components/products/SCPI-PE/SubscriptionConfirmModal';
import { SubscriptionDoneModal } from 'components/products/SCPI-PE/SubscriptionDoneModal';
import { useRealEstateSubscribe } from 'hooks/subscriptions/useRealEstateSubscribe';
import useScpiFundRepartition from 'hooks/useScpiFundRepartition';
import RealEstateDetails from 'pages/RealEstate/Details';
import eventTracker from 'services/events/eventTracker';
import { useGetSCPIFundsQuery } from 'services/requests/realEstate/funds';
import {
	RealEstateSimulationDto,
	SCPISubscriptionRepartition,
	useGetRealEstateSimulationsByIdQuery,
	useGetRealEstateSimulationsResultQuery,
	useUpdateRealEstateSimulationsObjectiveMutation,
} from 'services/requests/realEstate/simulations';
import { isNone } from 'utils/functions';
import { displayMoneyNoDigits } from 'utils/rendering';

import AllocationSimulationSCPI from './Sections/Allocation';
import ImmoSimulationMetrics from './Sections/Metrics';
import CapitalProjection from './Sections/Projection';

const SCPIRecommandationResult = (): JSX.Element => {
	const { subscribe, isSubscribing, isSubscribed } = useRealEstateSubscribe();
	const { data: funds } = useGetSCPIFundsQuery();

	const { id } = useParams<{ id: string }>();
	const { data, isFetching: isLoading, isSuccess } = useGetRealEstateSimulationsResultQuery(id!, { skip: isNone(id) });
	const { data: answers } = useGetRealEstateSimulationsByIdQuery(id!, { skip: isNone(id) });
	const fundsInfos = useScpiFundRepartition(data, funds);

	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isSubConfirmOpen, onOpen: onSubConfirmOpen, onClose: onSubConfirmClose } = useDisclosure();
	const { isOpen: isCalendlyModalOpen, onOpen: onOpenCalendlyModal, onClose: onCloseCalendlyModal } = useDisclosure();

	const [updateObjective] = useUpdateRealEstateSimulationsObjectiveMutation();

	const [timeHorizon, setTimeHorizon] = useState(0);
	const [initialAUM, setinitialAUM] = useState(0);
	const [simulateOnInputChange, setSimulateOnInputChange] = useState(false);

	useEffect(() => {
		if (simulateOnInputChange === false && answers?.payload) {
			const { timeHorizon: simulationTimeHorizon, initialAUM: simulationInitialAUM } = answers.payload;
			setTimeHorizon(simulationTimeHorizon);
			setinitialAUM(simulationInitialAUM);
			simulate({ timeHorizon: simulationTimeHorizon, initialAUM: simulationInitialAUM })
				.unwrap()
				.finally(() => setSimulateOnInputChange(true));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [answers, simulateOnInputChange]);

	useEffect(() => {
		if (simulateOnInputChange && answers && (initialAUM || 0) >= 1000) {
			const timer = setTimeout(() => {
				simulate({ timeHorizon, initialAUM });
			}, 600);
			return () => clearTimeout(timer);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeHorizon, initialAUM]);

	const simulate = (params: Partial<RealEstateSimulationDto>) =>
		updateObjective({
			id: id || '',
			data: {
				timeHorizon: params.timeHorizon as number,
				initialAUM: params.initialAUM as number,
			},
		});

	useEffect(() => {
		eventTracker.mixpanel.resultSimulationPage('SCPI');
	}, []);

	const onSubscribe = () => {
		eventTracker.mixpanel.track('SimulationSCPI-PoursuivreSouscription');
		onOpen();
	};

	const onConfirmSubscription = () => {
		if (data && answers) {
			const repartition = Object.keys(data.allocation)
				.map<SCPISubscriptionRepartition[]>((fundName) => [
					{
						fundName,
						fundId: data.allocation[fundName].id_airtable,
						amountVersement: data.allocation[fundName].montant_nu,
						nbParts: data.allocation[fundName].parts_nue,
						type: 'nue',
						fundCover: data.allocation[fundName].fundCover,
						partner: data.allocation[fundName].partner,
					},
					{
						fundName,
						fundId: data.allocation[fundName].id_airtable,
						amountVersement: data.allocation[fundName].montant_plein,
						nbParts: data.allocation[fundName].parts_plein,
						type: 'plein',
						fundCover: data.allocation[fundName].fundCover,
						partner: data.allocation[fundName].partner,
					},
				])
				.flat()
				.filter((sub) => sub.nbParts > 0);
			subscribe({ timeHorizon: answers.payload.timeHorizon, repartition });
		}
	};

	// we must cache this callback, or else SubscriptionConfirmModal useEffect will be recalled, and SubscriptionDoneModal will be reopened
	const onConfirmSuccess = useCallback(() => {
		onClose();
		onSubConfirmOpen();
	}, [onClose, onSubConfirmOpen]);

	return (
		<VStack spacing="lg" mt="24px" mb="80px" align="start" w="100%">
			<CalendlyModal isOpen={isCalendlyModalOpen} onClose={onCloseCalendlyModal} product={IntestedProducts.SCPI} />

			{data && (
				<SubscriptionConfirmModal
					isOpen={isOpen}
					onCancel={onClose}
					onSuccess={onConfirmSuccess}
					onConfirm={onConfirmSubscription}
					isLoading={isSubscribing}
					isSuccess={isSubscribed}
					type="SCPI"
				/>
			)}
			<SubscriptionDoneModal
				productName="SCPI"
				isOpen={isSubConfirmOpen}
				onClose={onSubConfirmClose}
				redirectToWhenDone="/immobilier/souscriptions"
			/>

			<ArrowButton left variant="secondary" size="lg" onClick={() => navigate(`/immobilier/simulation/${id}/modifier`)}>
				Modifier mes informations
			</ArrowButton>

			<VStack align="start" w="100%" spacing="16px">
				<Heading variant="Title-XL-SemiBold">Résultat de votre simulation</Heading>

				<VStack align="start" spacing="12px">
					{answers && (
						<Text>
							En investissant <b>{displayMoneyNoDigits(initialAUM)}</b> sur une durée de <b>{timeHorizon}</b> ans, vous
							pouvez constituer un capital de :
						</Text>
					)}
					<Skeleton w="100%" isLoaded={!isLoading && isSuccess}>
						<Text color="grey.900">
							<b>
								{displayMoneyNoDigits(
									(data?.valo[data?.valo.length - 1] ?? 0) + (data?.div.reduce((acc, val) => acc + val, 0) ?? 0),
								)}{' '}
							</b>
							Selon la performance des SCPI (calcul basé sur les performances historiques et projetées).
						</Text>
					</Skeleton>
				</VStack>
			</VStack>
			<Skeleton w="100%" isLoaded={!isLoading && isSuccess}>
				{data && (
					<ImmoSimulationMetrics
						capital_evolution={data.stats.capital_evolution}
						tri={data.stats.tri}
						annual_dividends_net_of_fees_and_tax={data.stats.annual_dividends_net_of_fees_and_tax}
					/>
				)}
			</Skeleton>

			<Skeleton w="100%" isLoaded={!isLoading && isSuccess}>
				{data && <AllocationSimulationSCPI allocation={fundsInfos} onSubscribe={onSubscribe} />}
			</Skeleton>

			<Skeleton w="100%" isLoaded={!isLoading && isSuccess}>
				<Banner
					title="Envie d'investir dans ce portefeuille ?"
					description="Un de nos conseillers vous accompagnera dans la réalisation de votre projet."
					variant="information"
					avatar="olivier"
					cta="En discuter avec un conseiller"
					onClick={() => {
						eventTracker.mixpanel.resultSimulationPageClickedOnRDV('SCPI');
						onOpenCalendlyModal();
					}}
				/>
			</Skeleton>

			<Skeleton w="100%" isLoaded={!isLoading && isSuccess}>
				{data && <CapitalProjection projectionData={{ ...data }} />}
			</Skeleton>

			<FAQ
				questions={[
					{
						question: <Heading variant="Title-M-SemiBold">Quelle est la fiscalité appliquée aux SCPI ?</Heading>,
						answer: (
							<Text color="grey.900">
								Les dividendes distribués de sources française sont fiscalisés en tant que revenus fonciers. Ils sont
								soumis aux charges sociales et à l’impôt sur le revenu. En pratique cela correspond à 17.2% plus votre
								TMI (taux marginal d'imposition). Les plus-values réalisées lors de la revente des parts sont soumises
								au régime fiscal des plus-values immobilières, soit 30% d'imposition. Ce taux d'imposition diminue
								progressivement avec la durée de détention des parts (diminution à partir de 6 ans de détention,
								l'imposition atteint 0% au bout de 30 ans.)
							</Text>
						),
					},
					{
						question: <Heading variant="Title-M-SemiBold">Comment fonctionne le moteur de recommandations ?</Heading>,
						answer: (
							<Text color="grey.900">
								Parmi les SCPIs disponibles dans notre catalogue, nous identifions la sélection optimisant votre
								rendement <b>net de frais et net d'imposition</b> tout en respectant les spécificités de votre projet
								d'investissement ainsi que vos préférences. Nous nous assurons également de vous proposer un
								portefeuille de SCPI diversifié afin de mitiger votre risque.
							</Text>
						),
					},
				]}
			/>
		</VStack>
	);
};

export const SCPIRecommandationResultRouter = () => (
	<>
		<SCPIRecommandationResult />
		<Routes>
			<Route path=":id" element={<RealEstateDetails />} />
		</Routes>
	</>
);

export default SCPIRecommandationResultRouter;
