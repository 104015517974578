import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, FlashFilled, Gamification, Gift, LicenseDraft, Model, Template, UserAvatar } from '@carbon/icons-react';
import { Box, Icon, Image, Text, VStack } from '@chakra-ui/react';

import CashIconColored from 'assets/icons/ProductsColoredIcons/Cash.svg';
import ImmoIconColored from 'assets/icons/ProductsColoredIcons/Immo.svg';
import InvestIconColored from 'assets/icons/ProductsColoredIcons/Invest.svg';
import PeIconColored from 'assets/icons/ProductsColoredIcons/Pe.svg';
import CashIconGrey from 'assets/icons/SideBar/Cash.svg';
import ImmoIconGrey from 'assets/icons/SideBar/Immo.svg';
import InvestIconGrey from 'assets/icons/SideBar/Invest.svg';
import PeIconGrey from 'assets/icons/SideBar/Pe.svg';
import { useProducts } from 'hooks/useProducts';
import useSubscriptionList from 'hooks/useSubscriptionList';
import isProposalSubscription from 'pages/Subscriptions/features/isPropalSubscription';
import { useGetLatestProposalQuery } from 'services/requests/proposal';
import { useGetUserQuery } from 'services/requests/user';
import colors from 'theme/foundations/colors';
import { hiddenScrollBar } from 'theme/scrollbar';

import { BuildSideBarType } from './BuildSideBar';
import SideBarLower from './SideBarLower';
import SideBarUpper from './SideBarUpper';

export type SideBarType = BuildSideBarType & {
	onClick: () => void;
};

const SideBar: FC<{ onClose?: () => void }> = ({ onClose }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { invest, pe, scpi, savings } = useProducts();
	const { data: user } = useGetUserQuery();
	const { subscriptionList, isLoading } = useSubscriptionList();
	const { data: proposal } = useGetLatestProposalQuery();

	const isBlack = user?.isBlack ?? false;
	const proposalSubscriptions = useMemo(
		() => subscriptionList.filter((sub) => isProposalSubscription(proposal, sub.id)),
		[proposal, subscriptionList],
	);

	const isLinkActive = useCallback(
		(link: string): boolean => {
			if (link === '/') return location.pathname === link;
			if (link === '/souscriptions/propositions') return location.pathname.startsWith('/souscriptions');
			return location.pathname.startsWith(link);
		},
		[location],
	);

	const sideBarLinks = useMemo<SideBarType[]>(
		() =>
			[
				{
					icon: <Icon as={Template} boxSize="20px" />,
					activeIcon: <Icon as={Template} boxSize="20px" color="white" />,
					title: 'Accueil',
					url: '/',
				},

				{
					icon: <Icon as={Model} boxSize="20px" />,
					activeIcon: <Icon as={Model} boxSize="20px" color="white" />,
					title: 'Offre Black ',
					url: '/offre-black',
					badge: isBlack ? <Badge color="#FFBB2C" size="24" /> : <FlashFilled color="#FFBB2C" size="16" />,
					activeBadge: isBlack ? <Badge color="#FFBB2C" size="24" /> : <FlashFilled color="#FFBB2C" size="16" />,
				},
				...(!isLoading && subscriptionList.length > 0
					? [
							{
								icon: <Icon as={LicenseDraft} boxSize="20px" />,
								activeIcon: <Icon as={LicenseDraft} boxSize="20px" color="white" />,
								title: 'Souscriptions',
								url: proposalSubscriptions?.length ?? 0 > 0 ? '/souscriptions/propositions' : '/souscriptions',
								badge: (
									<Text variant="Text-S-Bold" color="primary.black" p="2px 8px" borderRadius="sm" bg="primary.yellow">
										{subscriptionList.length}
									</Text>
								),
								activeBadge: (
									<Text
										variant="Text-S-Bold"
										color="white"
										p="2px 8px"
										borderRadius="sm"
										borderWidth="1px"
										borderColor="primary.yellow"
									>
										{subscriptionList.length}
									</Text>
								),
							},
					  ]
					: []),
				{
					icon: <Icon as={Gamification} boxSize="20px" />,
					activeIcon: <Icon as={Gamification} boxSize="20px" color="white" />,
					title: 'Fiscalité',
					url: '/fiscalite',
				},
				{
					icon: <Icon as={Gift} boxSize="20px" />,
					activeIcon: <Icon as={Gift} boxSize="20px" color="white" />,
					title: 'Parrainage',
					url: '/parrainage',
				},
				{
					icon: <Icon as={UserAvatar} boxSize="20px" />,
					activeIcon: <Icon as={UserAvatar} boxSize="20px" color="white" />,
					title: 'Compte',
					url: '/compte',
				},
			].map(({ title, ...part }) => {
				const isActive = isLinkActive(part.url);
				return {
					onClick() {
						navigate(part.url);
						if (onClose) onClose();
					},
					icon: isActive ? part.activeIcon : part.icon,
					isActive,
					title,
					badge: isActive ? part.activeBadge : part.badge,
				};
			}),
		[isLinkActive, isLoading, navigate, onClose, proposalSubscriptions?.length, subscriptionList.length, isBlack],
	);

	const sideBarProducts = useMemo<SideBarType[]>(
		() =>
			[
				{
					icon: <Image boxSize="20px" src={InvestIconGrey} />,
					activeIcon: <Image boxSize="20px" src={InvestIconColored} />,
					title: 'Invest',
					url: invest.isOpen ? '/invest/dashboard' : '/invest',
					baseUrl: '/invest',
				},
				{
					icon: <Image boxSize="20px" src={ImmoIconGrey} />,
					activeIcon: <Image boxSize="20px" src={ImmoIconColored} />,
					title: 'Immobilier',
					url: scpi.isOpen ? '/immobilier/dashboard' : '/immobilier',
					baseUrl: '/immobilier',
				},
				{
					icon: <Image boxSize="20px" src={PeIconGrey} />,
					activeIcon: <Image boxSize="20px" src={PeIconColored} />,
					title: 'Private Equity',
					url: pe.isOpen ? '/private-equity/dashboard' : '/private-equity',
					baseUrl: '/private-equity',
				},
				{
					icon: <Image boxSize="20px" src={CashIconGrey} />,
					activeIcon: <Image boxSize="20px" src={CashIconColored} />,
					title: 'Cash',
					url: savings.isOpen ? '/cash/dashboard' : '/cash',
					baseUrl: '/cash',
				},
			].map(({ title, ...part }) => {
				const isActive = isLinkActive(part.baseUrl);
				return {
					onClick() {
						navigate(part.url);
						if (onClose) onClose();
					},
					icon: isActive ? part.activeIcon : part.icon,
					isActive,
					title,
				};
			}),
		[invest.isOpen, isLinkActive, navigate, onClose, pe.isOpen, savings.isOpen, scpi.isOpen],
	);

	return (
		<Box w={{ base: '100vw', sm: '288px' }} height="100vh">
			<Box padding="16px" position="fixed" left="0" top="0" h="100%" w={{ base: '100vw', sm: '288px' }}>
				<Box
					as="nav"
					w="100%"
					h="100%"
					bg="grey.100"
					borderRadius="8px"
					border={`1px solid ${colors.grey[300]}`}
					overflowY="auto"
					css={hiddenScrollBar}
				>
					<VStack w="100%" align="start" px="24px" py="40px" spacing="40px">
						<SideBarUpper sideBarParts={sideBarLinks} onClose={onClose} />
						<SideBarLower sideBarParts={sideBarProducts} />
					</VStack>
				</Box>
			</Box>
		</Box>
	);
};
export default SideBar;
