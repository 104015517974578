import { ReactNode } from 'react';
import { Image } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { NavBarProjectType } from 'app/Layout/App/NavBar.type';
import BspceIcon from 'assets/icons/SideBar/Bspce.svg';
import CashIcon from 'assets/icons/SideBar/Cash.svg';
import ImmoIcon from 'assets/icons/SideBar/Immo.svg';
import InvestIcon from 'assets/icons/SideBar/Invest.svg';
import PeIcon from 'assets/icons/SideBar/Pe.svg';
import { EnvelopeType } from 'store/types/global.type';

type ProjectTypeRender = {
	icon: ReactNode;
	label: string;
};

export const RenderFromProjectType = (type: NavBarProjectType): ProjectTypeRender => {
	if (type === 'IMMO') return { icon: <Image boxSize="24px" src={ImmoIcon} />, label: 'Immobilier' };
	if (type === 'PE') return { icon: <Image boxSize="24px" src={PeIcon} />, label: 'Private Equity' };
	if (type === 'BSPCE') return { icon: <Image boxSize="24px" src={BspceIcon} />, label: 'BSPCE' };
	if (type === 'CASH') return { icon: <Image boxSize="24px" src={CashIcon} />, label: 'Cash' };

	// All the legacy projets 'RETIREMENT' | 'OTHER'
	return { icon: <Image boxSize="24px" src={InvestIcon} />, label: 'Invest' };
};

//
// Number related display
//

export const formatNumber = (number: number, decimals = 0): string => {
	if (typeof number === 'number') {
		return number.toLocaleString('fr-FR', { minimumFractionDigits: 0, maximumFractionDigits: decimals });
	}
	return '';
};

export const displayPercentage = (number: number): string => {
	if (typeof number === 'number') {
		return number.toLocaleString('fr-FR', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 });
	}
	return '';
};

// To avoid error  for out of range: https://stackoverflow.com/a/41045289 + https://github.com/andyearnshaw/Intl.js/issues/123 the minimumFractionDigits is set to 2 by default
export const displayMoney = (number: number | string, precision = 1): string => {
	if (typeof number === 'number') {
		return number.toLocaleString('fr-FR', {
			style: 'currency',
			currency: 'EUR',
			minimumFractionDigits: 0,
			maximumFractionDigits: precision,
		});
	}
	if (typeof number === 'string') return displayMoney(Number(number));
	return '';
};

// Reason for "minimumFractionDigits: 0": https://stackoverflow.com/a/41045289
export const displayMoneyNoDigits = (number: number): string => {
	if (typeof number === 'number') {
		return number.toLocaleString('fr-FR', {
			style: 'currency',
			currency: 'EUR',
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		});
	}
	return '';
};

export const displayNumberRoundedThousands = (number: number): number => {
	if (number > 10000000000) {
		// 10B (will never happen)
		return Math.round(number / 1000000000) * 1000000000; // round to billion
	}
	if (number > 10000000) {
		// 10M
		return Math.round(number / 1000000) * 1000000; // round to million
	}
	if (number > 10000) {
		// 10K
		return Math.round(number / 1000) * 1000; // round to thousand
	}
	return number;
};

export const maskSensibleIban = (iban: string): string => {
	const slice1 = iban.slice(0, 4);
	const slice2 = iban.slice(-4);
	return `${slice1}-XXXXXXXX-${slice2}`;
};

export const formatDateStandart = (date: Date | string | null | undefined): string => {
	return dayjs(date).format('D MMMM YYYY');
};

export const getEnvelopeNameFromType = (type: EnvelopeType): string => {
	if (type === 'AV') return 'Assurance vie';
	if (type === 'PER') return 'PER individuel';
	return 'Assurance vie'; // Should never happen
};

export const displayCapitalized = (str: string): string => {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
