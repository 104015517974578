import { FC } from 'react';
import { Heading, VStack } from '@chakra-ui/react';

import FinalizingSubscription from 'components/cards/subscriptions/FinalizingSubscription';
import subscriptionSort from 'pages/Subscriptions/features/subscriptionSort';
import { ProductSubscriptionType } from 'store/types/global.type';

const FinalizingSubscriptionList: FC<{ subscriptions: ProductSubscriptionType[] | undefined }> = ({
	subscriptions,
}) => {
	const subscriptionsSorted = subscriptionSort(subscriptions);

	if (!subscriptionsSorted || subscriptionsSorted.length < 1) return null;

	return (
		<VStack w="100%" align="start" spacing="lg">
			<Heading variant="Title-L-SemiBold">Mes souscriptions à finaliser</Heading>
			<VStack w="100%" align="start" spacing="md">
				{subscriptionsSorted.map((sub) => (
					<FinalizingSubscription canBeDeleted key={sub.id} {...sub} showAmount />
				))}
			</VStack>
		</VStack>
	);
};

export default FinalizingSubscriptionList;
