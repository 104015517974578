import { useCallback } from 'react';
import { Button, Heading, Text, VStack } from '@chakra-ui/react';

import CustomModal from 'components/Modal';
import { useGetUserQuery } from 'services/requests/user';

interface HighIncomeModalProps {
	onClose: () => void;
	isOpen: boolean;
}

const HighIncomeModal = ({ isOpen, onClose }: HighIncomeModalProps): JSX.Element => {
	const { data: user } = useGetUserQuery();

	const openAppointment = useCallback(() => {
		window.open(
			new URL(
				'https://calendly.com/d/hxd-ppp-c78/echange-avec-un-conseiller-dedie-ramify?' +
					new URLSearchParams({
						email: user?.email ?? '',
					} as Record<string, string>),
			),
		);
		onClose();
	}, [user?.email, onClose]);

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			headersProps={{ children: <Heading>Revenus élevés</Heading> }}
		>
			<VStack spacing="24px">
				<Text variant="body">
					Si vos revenus annuels dépassent 2M d’€, vous ne pourrez pas souscrire à nos produits de manière digitalisée.
					Nous vous proposons de vous rapprocher d'un conseiller afin d'être accompagné.
				</Text>
				<Button onClick={openAppointment} variant="tertiary">
					Prendre rendez-vous
				</Button>
			</VStack>
		</CustomModal>
	);
};

export default HighIncomeModal;
