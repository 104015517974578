import { Outlet, useLocation } from 'react-router-dom';
import { Box, Stack } from '@chakra-ui/react';

import offreBlack from 'assets/icons/OffreBlack/offreBlack.png';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { usePositionIntercomBubble } from 'hooks/usePositionIntercomBubble';

import NavBar from './NavBar';

const AppLayout = (): JSX.Element => {
	usePositionIntercomBubble('app');
	const location = useLocation();
	const isBlackOffer = location.pathname.includes('offre-black');

	const gridSize = { base: 'calc(100vw - 40px)', sm: '540px', md: '720px', lg: '`900px', '2xl': '1100px' };
	const direction: 'column' | 'row' = useAppResponsive({ base: 'column', xl: 'row' }) || 'row';

	return (
		// do not change bg black to primary.black ( not the same color)
		<Stack direction={direction} spacing={0} bg={isBlackOffer ? 'black' : 'primary.white'} minH="100vh">
			<Box zIndex={51}>
				<NavBar />
			</Box>
			<Box
				as="main"
				flex="1"
				zIndex={50}
				overflow="hidden"
				position="relative"
				backgroundImage={isBlackOffer ? offreBlack : ''}
				backgroundRepeat="no-repeat"
				backgroundSize="contain"
			>
				<Box w={gridSize} mx="auto">
					<Outlet />
				</Box>
			</Box>
		</Stack>
	);
};

export default AppLayout;
