import { backendApi } from 'services/apiService';
import { Envelope } from 'store/types/global.type';

type ProductStatus = 'AVAILABLE' | 'SIGNED' | 'OPENED';

export interface ProductData {
	status: ProductStatus;
	awaitingAUM?: number;
	valuation?: number;
	investedAmount?: number;
	performance?: {
		value: number;
		percentage?: number;
	};
}

export interface GlobalPerformance {
	tri: number;
	annualizedTri: number;
	winLoss: number;
}

export interface ProductInfosResponse {
	invest: ProductData;
	scpi: ProductData;
	pe: ProductData;
	savings: ProductData;
	globalPerformance?: GlobalPerformance;
}

export interface InvestEnvelopesOpenedResponse {
	isPerOpen: boolean;
	isAvOpen: boolean;
}

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getProductsData: builder.query<ProductInfosResponse, void>({
			query: () => '/products',
			providesTags: ['Product'],
		}),
		getProductPerAVOpen: builder.query<InvestEnvelopesOpenedResponse, void>({
			query: () => '/products/invest/envelopes-opened',
			providesTags: ['Product'],
		}),
		getEnvelopes: builder.query<Envelope[], void>({
			query: () => '/envelopes',
		}),
	}),
});

export const { useGetProductsDataQuery, useGetProductPerAVOpenQuery, useGetEnvelopesQuery } = extendedApi;
