import { Card, Heading, HStack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import { useFees } from 'hooks/useFees';
import colors from 'theme/foundations/colors';

type FeeCardProps = {
	title: React.ReactNode;
	fee: string;
	txtTooltip?: string;
};

export const FeeCard = ({ title, fee, txtTooltip }: FeeCardProps) => {
	return (
		<Card w="100%" h="100%">
			<VStack w="100%" spacing="8px" align="start">
				<HStack w="100%">
					<Text variant="Text-M-Medium">{title}</Text>
					{txtTooltip && <CustomTooltip text={txtTooltip} />}
				</HStack>
				<Heading variant="Title-L-Bold">{fee}</Heading>
			</VStack>
		</Card>
	);
};

type FeeEnterExitProps = {
	enterFee: number;
	exitFee: number;
};
export const FeeEnterExit = ({ enterFee, exitFee }: FeeEnterExitProps) => {
	return (
		<Card w="100%" p="24px" h="100%">
			<Wrap w="100%" spacing="24px" align="center">
				<WrapItem flex="1" minW="100px">
					<VStack w="100%" spacing="8px" align="start">
						<Text variant="Text-M-Medium" minW={{ base: '100px', md: '180px' }}>
							Frais d'entrée
						</Text>
						<Heading variant="Title-L-Bold" color={colors.positive[900]}>
							{enterFee}&nbsp;%
						</Heading>
					</VStack>
				</WrapItem>
				<WrapItem flex="1" minW="100px">
					<VStack w="100%" spacing="8px" align="start">
						<Text variant="Text-M-Medium" minW={{ base: '100px', md: '180px' }}>
							Frais de sortie
						</Text>
						<Heading variant="Title-L-Bold" color={colors.positive[900]}>
							{exitFee}&nbsp;%
						</Heading>
					</VStack>
				</WrapItem>
			</Wrap>
		</Card>
	);
};

const OurFees = (): JSX.Element => {
	const fees = useFees();

	return (
		<VStack w="100%" align="start" spacing="32px">
			<VStack w="100%" align="start" spacing="8px">
				<Heading variant="Title-L-SemiBold">Nos tarifs</Heading>
				<Text variant="Text-M-Regular">
					Vous retrouverez ici le tableau des tarifs disponibles dans la catégorie Invest de Ramify
				</Text>
			</VStack>
			<VStack spacing="56px" w="100%">
				<VStack w="100%" align="start" spacing="24px">
					<Heading variant="Title-M-SemiBold">
						Frais du contrat en unité de compte : {fees.ramifyGestion + fees.envelope} %{' '}
						<Text variant="label" as="span">
							(à vie)
						</Text>
					</Heading>
					<Wrap w="100%" spacing="24px">
						<WrapItem flex="1">
							<FeeCard
								title="Frais d'enveloppe"
								txtTooltip="Ce sont les frais prélevés par notre partenaire assureur pour la sécurisation de vos fonds"
								fee={`${fees.envelope} %`}
							/>
						</WrapItem>
						<WrapItem flex="1">
							<FeeCard
								title="Frais de gestion Ramify"
								txtTooltip="C’est la rémunération de Ramify, en tant que conseiller de vos investissements financiers"
								fee={`${fees.ramifyGestion} %`}
							/>
						</WrapItem>
						<WrapItem flex="1">
							<FeeEnterExit enterFee={fees.entryExit} exitFee={fees.entryExit} />
						</WrapItem>
					</Wrap>
				</VStack>
				<VStack w="100%" align="start" spacing="24px">
					<Heading variant="Title-M-SemiBold">
						Frais du contrat en fonds euros : {fees.fondEuro} %{' '}
						<Text variant="label" as="span">
							(à vie)
						</Text>
					</Heading>
					<Wrap w="100%" spacing="24px">
						<WrapItem flex="1">
							<FeeCard title="Frais tout inclus" fee={`${fees.fondEuro} %`} />
						</WrapItem>
						<WrapItem flex="1">
							<FeeEnterExit enterFee={fees.fondEuroEntryExit} exitFee={fees.fondEuroEntryExit} />
						</WrapItem>
					</Wrap>
				</VStack>
			</VStack>
		</VStack>
	);
};

export default OurFees;
