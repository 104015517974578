import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import StepperLayout from 'app/Layout/Onboarding/StepperLayout';
import { useAppDispatch } from 'hooks/useStore';
import { setLastStepAvailable } from 'onboarding/Stepper/stepper.slice';
import RealEstateDetails from 'pages/RealEstate/Details';
import { useGetSignedDocumentStatusQuery } from 'services/requests/phoenix';
import { useGetSCPIKYCQuery, useGetSCPIKYCTestQuery } from 'services/requests/realEstate/kyc';

import { isDocumentBothSigned, isDocumentHalfSigned, isScpiKycAndTestCompleted } from './Test/utils';
import Confirmation from './Confirmation';
import Documents from './Documents';
import Introduction from './Introduction';
import steps, { ReOnboardingSteps } from './steps';
import Test from './Test';

const PhoenixOnboardingRoutes = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const [collapsed, setCollapsed] = useState(false);
	const { subId } = useParams<{ subId: string }>();

	// Informations produit
	const { data: scpiKyc } = useGetSCPIKYCQuery();

	// Test d'adéquation
	const { data: scpiKycTest } = useGetSCPIKYCTestQuery();

	// document signés
	const { data: documentSigned } = useGetSignedDocumentStatusQuery(subId!);

	useEffect(() => {
		if (isScpiKycAndTestCompleted(scpiKyc, scpiKycTest)) {
			dispatch(setLastStepAvailable(ReOnboardingSteps.TEST));
		} else if (isDocumentBothSigned(documentSigned)) {
			dispatch(setLastStepAvailable(ReOnboardingSteps.DOCUMENTS));
		} else if (isDocumentHalfSigned(documentSigned)) {
			dispatch(setLastStepAvailable(ReOnboardingSteps.INTRODUCTION));
		} else {
			dispatch(setLastStepAvailable(ReOnboardingSteps.INTRODUCTION));
		}
	}, [dispatch, scpiKyc, scpiKycTest, documentSigned]);

	if (!subId) return <Navigate to="/phoenix" />;

	return (
		<Routes>
			<Route
				element={
					<StepperLayout
						headerTitle="Novaxia Neo"
						headerSubtitle="Finalisation"
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						steps={steps}
						basePath={`/phoenix/onboarding/${subId}`}
					/>
				}
			>
				{/* Guard redirect last step */}
				<Route
					path="introduction/*"
					element={
						// wrapping in router to allow the details to open (see useDrawer)
						<Routes>
							<Route
								path=":id?"
								element={
									<>
										<Introduction />
										<RealEstateDetails hideInvestButton hideAdvisor />
									</>
								}
							/>
						</Routes>
					}
				/>
				<Route path="documents" element={<Documents />} />
				<Route path="test" element={<Test />} />
				<Route path="confirmation" element={<Confirmation />} />
			</Route>
			<Route path="*" element={<Navigate to="introduction" replace />} />
		</Routes>
	);
};

export default PhoenixOnboardingRoutes;
