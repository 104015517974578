export enum PortfolioSteps {
	INTRODUCTION = 'introduction',
	RISK = 'risque',
	STYLE = 'style',
	TYPE = 'type',
	RECAPITULATIF = 'recapitulatif',
}

export const introductionStep = {
	id: PortfolioSteps.INTRODUCTION,
	name: '',
	url: '',
};

export const riskAversionStep = {
	id: PortfolioSteps.RISK,
	name: 'Mon profil de risque',
	url: 'risque',
};

export const styleStep = {
	id: PortfolioSteps.STYLE,
	name: 'Ramify Green',
	url: 'style',
};

export const typeStep = {
	id: PortfolioSteps.TYPE,
	name: 'Mon type de portefeuille',
	url: 'type',
};

export const recapitulatifStep = {
	id: PortfolioSteps.RECAPITULATIF,
	name: 'Portefeuille proposé',
	url: 'recapitulatif',
};
