import { useMemo } from 'react';
import { ArrowLeft, CarbonIconType, Cube, Money, Security } from '@carbon/icons-react';
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	HStack,
	IconButton,
	Image,
	Stack,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import SECURITYTRIANGLE from 'assets/icons/OffreBlack/securityTriangle.png';
import MetricCard from 'components/cards/MetricCard';
import { CalendlyStep } from 'pages/OffreBlack/DiscoverBlack/Modal/Calendly';

type AvLuxDrawerType = {
	icon: CarbonIconType;
	title: string;
	label: string;
};

const AvLuxDrawer = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }): JSX.Element => {
	const {
		isOpen: isOpenAppointementModal,
		onOpen: onOpenAppointementModal,
		onClose: isCloseAppointementModal,
	} = useDisclosure();
	const advantageList: AvLuxDrawerType[] = useMemo(
		() => [
			{
				icon: Security,
				title: 'Sécurisation de votre capital',
				label:
					'Les fonds de votre contrat luxembourgois sont conservés auprès de nos partenaires financiers de premier plan',
			},
			{
				icon: Cube,
				title: 'Régime de protection unique',
				label:
					'Les souscripteurs portent le statut de “super privilégié” en cas de défaut (faillite) de l’assureur, les supers privilégiés sont payés en premier pour récupérer leurs fonds',
			},
			{
				icon: Money,
				title: 'Neutralité fiscale',
				label: 'Conservation des avantages fiscaux et de transmission d’un contrat d’assurance vie français',
			},
		],
		[],
	);
	return (
		<VStack align="start" w="100%">
			{isOpenAppointementModal && (
				<CalendlyStep
					url="https://calendly.com/d/y4s-6xr-tf4/echange-avec-un-conseiller-black-ramify"
					onClose={isCloseAppointementModal}
				/>
			)}
			<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isOpen} onClose={onClose}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader>
						<HStack w="100%" justify="space-between">
							<HStack>
								<IconButton
									onClick={onClose}
									variant="icon"
									aria-label="back"
									icon={
										<Box>
											<ArrowLeft size="32" />
										</Box>
									}
								/>
							</HStack>
							<Button
								variant="primary"
								onClick={() => {
									onOpenAppointementModal();
								}}
							>
								Prendre rendez-vous
							</Button>
						</HStack>
					</DrawerHeader>
					<DrawerBody>
						<VStack align="start" spacing="56px">
							<VStack align="start" spacing="8px">
								<Text variant="Title-L-SemiBold">Assurance Vie Luxembourgeoise</Text>
								<Text variant="Text-M-Regular">
									Assurez la pérennité de vos placements grâce à l’assurance vie luxembourgeoise. Avec la sécurisation
									de votre capital et la diversité des actifs éligibles, l’assurance vie luxembourgeoise est un
									incontournable des stratégies patrimoniales haut de gamme.
								</Text>
							</VStack>
							<VStack align="start" spacing="32px" w="100%">
								<Text variant="Title-L-SemiBold">Les avantages</Text>
								<HStack w="100%" spacing="24px" alignItems="stretch" overflowX="auto">
									{advantageList.map((data) => (
										<MetricCard key={data.title} {...data} />
									))}
								</HStack>
							</VStack>

							<Stack align="start" spacing="8px" direction={{ base: 'column', xl: 'row' }}>
								<VStack align="start" spacing="8px" w="100%">
									<Text variant="Title-L-SemiBold">Le triangle de sécurité</Text>
									<Text variant="Text-M-Regular">
										Le droit luxembourgeois protege extrêmement bien vos placements. 3 organismes vous apportent la
										garantie, sans limite, de vos capitaux : la compagnie d’assurance, le commissariat aux assurances
										(CAA, le Luxembourg en étant le garant), et la banque dépositaire. En terme de sécurité, il n’y a
										pas d’équivalent.
									</Text>
								</VStack>
								<Image src={SECURITYTRIANGLE} alt="security triangle" />
							</Stack>
						</VStack>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</VStack>
	);
};

export default AvLuxDrawer;
