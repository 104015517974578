import { Controller, useFormContext } from 'react-hook-form';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Radio,
	RadioGroup,
	useBreakpointValue,
	VStack,
} from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import { CommonReglementaryQuestionsType } from 'store/types/KYC.type';
import { isNotNone } from 'utils/functions';

const CommonReglementaryFormContext = (): JSX.Element => {
	const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
	const {
		control,
		formState: { errors },
	} = useFormContext<CommonReglementaryQuestionsType>();

	return (
		<VStack w="100%" align={isMobile ? 'center' : 'start'} spacing="24px">
			<FormControl isInvalid={!!errors.isPoliticallyExposed}>
				{/* <HStack align="center" spacing="0px"> */}
				<HStack mb="8px">
					<FormLabel mb="0px">
						Êtes-vous une personne politiquement exposée ou êtes-vous proche d'une personne politiquement exposée
					</FormLabel>
					<CustomTooltip
						text="Les PPE (Personnes Politiquement Exposées) sont des personnes physiques qui occupent ou ont occupé une fonction publique importante, pas
						nécessairement politique, liée à un pouvoir de décision significatif. Ces personnes peuvent être de chefs
						d'État, ou d'un membre de leur famille. Les personnes connues pour être étroitement associées à un client
						PPE sont également incluses."
					/>
				</HStack>

				<Controller
					name="isPoliticallyExposed"
					control={control}
					rules={{ validate: { required: (value) => isNotNone(value) } }}
					render={({ field: { onChange, value, ...rest } }) => (
						<RadioGroup
							w={onboardingInputWidth}
							variant="single"
							size="sm"
							onChange={(newValue) => onChange(newValue === 'true')}
							value={isNotNone(value) ? (value ? 'true' : 'false') : undefined}
							{...rest}
						>
							<VStack spacing="8px">
								<Radio value="true">Oui</Radio>
								<Radio value="false">Non</Radio>
							</VStack>
						</RadioGroup>
					)}
				/>

				{errors.isPoliticallyExposed?.type === 'required' && (
					<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
				)}
			</FormControl>

			<FormControl isInvalid={!!errors.isUSPerson}>
				<FormLabel>Etes-vous citoyen américain ou résident fiscal des Etats-Unis d’Amérique ?</FormLabel>

				<Controller
					name="isUSPerson"
					control={control}
					rules={{ validate: { required: (value) => isNotNone(value) } }}
					render={({ field: { onChange, value, ...rest } }) => (
						<RadioGroup
							w={onboardingInputWidth}
							variant="single"
							size="sm"
							onChange={(newValue) => onChange(newValue === 'true')}
							value={isNotNone(value) ? (value ? 'true' : 'false') : undefined}
							{...rest}
						>
							<VStack spacing="8px">
								<Radio value="true">Oui</Radio>
								<Radio value="false">Non</Radio>
							</VStack>
						</RadioGroup>
					)}
				/>

				{errors.isUSPerson?.type === 'required' && <FormErrorMessage children="Ce champ est nécessaire" />}
			</FormControl>
		</VStack>
	);
};

export default CommonReglementaryFormContext;
