import { Dispatch, useState } from 'react';
import { Card, HStack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import YearsFilters, { YearDataProp } from 'components/FilterSelect';
import { displayMoneyNoDigits } from 'utils/rendering';

import ProjectGraph, { calculateSaving } from './ProjectGraph';

const ProjectGraphData = ({
	initialAUM,
	saving,
	performance,
}: {
	initialAUM: number;
	saving: number;
	performance: number;
}): JSX.Element => {
	const yearsData: YearDataProp<number>[] = [
		{ label: '5 ans', labelValue: 5 },
		{ label: '10 ans', labelValue: 10 },
		{ label: '20 ans', labelValue: 20 },
		{ label: '30 ans', labelValue: 30 },
	];

	const [yearLengthValue, setYearLengthValue] = useState(5);

	const capitals = calculateSaving(initialAUM, yearLengthValue * 12, 0.07, saving);
	const lastMonthCapital = capitals[capitals.length - 1].y;
	const totalVersement = initialAUM + yearLengthValue * 12 * saving;

	return (
		<VStack w="100%" spacing="16px" align="start">
			<Wrap justify="space-between" w="100%" spacing="16px">
				<WrapItem alignItems="center">
					<YearsFilters
						data={yearsData}
						label={{ filterValue: yearLengthValue, setFilterValue: setYearLengthValue as Dispatch<unknown> }}
					/>
				</WrapItem>
				<WrapItem>
					<Card size="sm">
						<Wrap spacing={{ base: '8px', md: '24px' }}>
							<WrapItem>
								<HStack>
									<Text variant="Text-S-Regular">Plus values</Text>
									<Text variant="Text-M-Medium" color="positive.900">
										+ {displayMoneyNoDigits(lastMonthCapital - totalVersement)}
									</Text>
								</HStack>
							</WrapItem>
							<WrapItem>
								<HStack>
									<Text variant="Text-S-Regular">Capital total</Text>
									<Text variant="Text-M-Medium" color={saving > 0 ? 'primary.yellow' : 'grey.900'}>
										{displayMoneyNoDigits(lastMonthCapital)}
									</Text>
								</HStack>
							</WrapItem>
						</Wrap>
					</Card>
				</WrapItem>
			</Wrap>
			<ProjectGraph
				initialAUM={initialAUM}
				saving={saving}
				monthLength={yearLengthValue * 12}
				performance={performance}
			/>
		</VStack>
	);
};

export default ProjectGraphData;
