import { VStack } from '@chakra-ui/react';

import Article from 'components/Articles/Article';
import Catalog from 'pages/PrivateEquity/Discover/Catalog';

const DiscoverPrivateEquity = (): JSX.Element => (
	<VStack w="100%" align="start" spacing="lg">
		<VStack w="100%" align="start" spacing="md">
			<Article
				imageURL="https://assets.website-files.com/63c027c9ecb9a193a6b225ea/640b1651d7d422bdedc76419_6406f869e3e106e6800df967_9ac2a6408adb95431489f25ed0ff98a2-p-500.jpg"
				title="Tout savoir sur le Private Equity et comment investir"
				description="Le private Equity est un placement essentiel qui permet d'investir dans des entreprises, dans tous les domaines. Aussi appelé capital-investissement, il paraît encore relativement inaccessible aux particuliers."
				tags={[{ label: 'Guide', variant: 'coloredBgYellow' }]}
				btnCallback={() => window.open('https://www.ramify.fr/private-equity')}
			/>
		</VStack>
		<Catalog />
	</VStack>
);
export default DiscoverPrivateEquity;
