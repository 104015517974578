import { SubscriptionStep } from 'components/products/SCPI-PE/subscriptionSteps';
import { FundSubscriptionStatus } from 'store/types/airtable.type';
import {
	LABEL_FINALISATION,
	LABEL_PENDING,
	LABEL_RECEPTION,
	LABEL_REQUESTED,
	LABEL_SIGNED,
} from 'utils/automatedFunds/steps/steps';

const eurazeoFundsSteps: SubscriptionStep<FundSubscriptionStatus>[] = [
	{
		label: 'Demande de souscription',
		value: FundSubscriptionStatus.REQUESTED,
		details: LABEL_REQUESTED,
	},
	{
		label: 'Validation du dossier',
		value: FundSubscriptionStatus.PENDING,
		details: LABEL_PENDING,
	},
	{
		label: 'Signature du bulletin',
		value: FundSubscriptionStatus.CONTRACT_SENT,
		details: LABEL_SIGNED,
	},
	{
		label: 'Réception des fonds',
		value: FundSubscriptionStatus.SIGNED,
		details: LABEL_RECEPTION,
	},
	{
		label: 'Finalisation',
		value: FundSubscriptionStatus.RECEIVED_FUNDS,
		details: LABEL_FINALISATION,
	},
];

export default eurazeoFundsSteps;
