import { ProductSubscriptionType } from 'store/types/global.type';

// Sort the subscriptions by the order of the typeProject Invest > SCPI > Private Equity
const subscriptionSort = (subscriptions: ProductSubscriptionType[] | undefined) =>
	subscriptions?.sort((subA, subB) => {
		if (subA.typeProject === 'INVEST') return -1;
		if (subA.typeProject === 'SCPI') {
			if (subB.typeProject === 'INVEST') return 1;
			return -1;
		}
		if (subA.typeProject === 'PE') return 1;
		return 0;
	});

export default subscriptionSort;
