import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { VStack } from '@chakra-ui/react';
import { AxisBottom } from '@visx/axis';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { Bar, LinePath } from '@visx/shape';
import { useTooltip } from '@visx/tooltip';
import { bisector, extent } from 'd3-array';

import CursorBar from 'components/Graphs/visx/CursorBar';
import TooltipDesktop from 'components/Graphs/visx/ToolTipGraphDesktop';
import TooltipGraphMobile from 'components/Graphs/visx/ToolTipGraphMobile';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { PrivateEquitySubscriptionMetrics } from 'services/requests/privateEquity';
import { ImmobilierSubscriptionMetrics } from 'services/requests/realEstate';
import colors from 'theme/foundations/colors';
import { displayMoney } from 'utils/rendering';

type LineProps = {
	setCurrentValue: Dispatch<SetStateAction<{ date: Date; cashflow: number; cumulative_cashflow: number } | undefined>>;
	width: number;
	height: number;
	margin: { top: number; right: number; bottom: number; left: number };
	data: ImmobilierSubscriptionMetrics | PrivateEquitySubscriptionMetrics;
};

function getPreviousWeek(date: Date): Date {
	const previous = new Date(date.getTime());
	previous.setDate(date.getDate() - 5);

	return previous;
}

function getNextMonth(date: Date): Date {
	const previous = new Date(date.getTime());
	previous.setDate(date.getDate() + 30);
	return previous;
}

const InternalGraph = ({ width, height, data, setCurrentValue, margin }: LineProps) => {
	// bounds
	const xMax = width - margin.left - margin.right;
	const yMax = height - margin.top - margin.bottom;
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;
	const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<{
		date: Date;
		cashflow: number;
		cumulative_cashflow: number;
	}>({
		tooltipOpen: true,
	});

	const timeScale = scaleTime<number>({
		domain: [
			getPreviousWeek(new Date(data.cash_flow_evolution_dictionary.dates[0])),
			getNextMonth(
				new Date(data.cash_flow_evolution_dictionary.dates[data.cash_flow_evolution_dictionary.dates.length - 1]),
			),
		],
		range: [0, xMax],
	});

	const filteredData = useMemo(
		() =>
			data.cash_flow_evolution_dictionary.dates.map((d, i) => ({
				date: d,
				cashflow: data.cash_flow_evolution_dictionary.cashflow[i],
				cumulative_cashflow: data.cash_flow_evolution_dictionary.cumulative_cashflow[i],
			})),
		[data.cash_flow_evolution_dictionary],
	);

	const domainCumulativeCashflow = extent(filteredData, (d) => d.cumulative_cashflow) as [number, number];
	const domainCashflow = extent(filteredData, (d) => d.cashflow) as [number, number];

	const scale = scaleLinear<number>({
		domain: [
			Math.min(domainCumulativeCashflow[0], domainCashflow[0]),
			Math.max(domainCumulativeCashflow[1], domainCashflow[1]),
		],
		range: [yMax, 0],
	});

	const handleMouseMove = useCallback<
		React.MouseEventHandler<SVGPathElement> & React.TouchEventHandler<SVGRectElement>
	>(
		(event) => {
			const { x } = localPoint(event) || { x: xMax };
			const realX = x - margin.left;
			const x0 = timeScale.invert(realX);
			const index = bisector((d: { date: Date }) => d.date).left(filteredData, x0, 1);

			const d0 = filteredData[index - 1];
			const d1 = filteredData[index];
			let d = d0 && d0.date ? d0 : d1;
			if (d0 && d0.date && d1 && d1.date) {
				d = x0.valueOf() - new Date(d0.date).valueOf() > new Date(d1.date).valueOf() - x0.valueOf() ? d1 : d0;
			}

			setCurrentValue(d);
			if (d) {
				showTooltip({
					tooltipData: d,
					tooltipLeft: timeScale(new Date(d.date)),
					tooltipTop: scale(d.cashflow),
				});
			}
		},
		[showTooltip, timeScale, scale, margin.left, xMax, filteredData, setCurrentValue],
	);

	return (
		<>
			<svg width={width} height={isMobile ? 222 : height}>
				<Group top={margin.top}>
					<LinearGradient from="#FDAC02" to="#FDAC02" toOpacity={0.1} fromOpacity={0.4} id="bgColor" />
					<LinePath
						data={filteredData}
						x={(d) => timeScale(new Date(d.date).valueOf())}
						y={(d) => scale(d.cumulative_cashflow)}
						stroke="#FDAC02"
					/>

					<LinePath
						data={filteredData}
						x={(d) => timeScale(new Date(d.date).valueOf())}
						y={scale(0)}
						stroke={colors.grey[300]}
					/>
					<Group>
						{filteredData.map((d, i) => {
							return (
								<Bar
									// eslint-disable-next-line react/no-array-index-key
									key={i}
									// move left width / 2
									x={timeScale(new Date(d.date).valueOf()) - 8}
									width={16}
									y={d.cashflow > 0 ? scale(d.cashflow) : scale(0)}
									height={d.cashflow > 0 ? Math.abs(scale(0) - scale(d.cashflow)) : Math.abs(yMax - scale(0))}
									fill={
										d.cashflow > 0 ? (filteredData.length - 1 === i ? 'white' : colors.blue[500]) : colors.grey[300]
									}
									stroke={
										d.cashflow > 0
											? filteredData.length - 1 === i
												? colors.grey[500]
												: colors.blue[500]
											: colors.grey[300]
									}
									strokeDasharray={filteredData.length - 1 === i ? '3' : '0'}
									strokeWidth={2}
								/>
							);
						})}
					</Group>

					{!isMobile && (
						<AxisBottom
							top={yMax}
							scale={timeScale}
							labelOffset={10}
							tickFormat={(d) =>
								(d as Date).toLocaleDateString('fr-FR', {
									year: 'numeric',
									month: 'short',
									day: 'numeric',
								})
							}
							numTicks={2}
							tickStroke={colors.grey[300]}
							tickLabelProps={() => ({
								fill: colors.grey[500],
								fontSize: 12,
								textAnchor: 'middle',
								fontFamily: 'poppins',
							})}
							hideAxisLine
						/>
					)}

					<Bar
						width={xMax}
						height={yMax}
						rx={0}
						fill="transparent"
						onTouchStart={handleMouseMove}
						onTouchMove={handleMouseMove}
						onMouseEnter={handleMouseMove}
						onMouseMove={handleMouseMove}
						onMouseLeave={() => hideTooltip()}
					/>
					<CursorBar
						tooltipLeft={tooltipLeft ?? 0}
						tooltipTop={tooltipTop ?? 0}
						tooltipData={tooltipData}
						yMax={yMax}
						margin={margin}
					/>
				</Group>
			</svg>

			{tooltipOpen && tooltipData && !isMobile && (
				<TooltipDesktop
					tooltipData={[
						{ title: 'Cashflow :', value: displayMoney(tooltipData?.cashflow) },
						{ title: 'Cashflow cumulé :', value: displayMoney(tooltipData?.cumulative_cashflow) },
						{
							title: 'Date :',
							value: new Date(tooltipData?.date).toLocaleDateString('fr-FR', {
								year: 'numeric',
								month: 'long',
								day: 'numeric',
							}),
						},
					]}
					tooltipLeft={tooltipLeft ?? 0}
					tooltipTop={tooltipTop ?? 0}
				/>
			)}
		</>
	);
};

export const CashflowGraph = ({ data }: { data: ImmobilierSubscriptionMetrics | PrivateEquitySubscriptionMetrics }) => {
	const [currentValue, setCurrentValue] = React.useState<{
		date: Date;
		cashflow: number;
		cumulative_cashflow: number;
	}>();

	const isMobile = useAppResponsive({ base: true, xl: false }) || false;
	const margin = { top: 10, right: 0, bottom: 20, left: 0 };

	return (
		<VStack
			w="100%"
			h="100%"
			align="start"
			position="relative" // relative position for the tooltip
		>
			{isMobile && currentValue && (
				<TooltipGraphMobile
					tooltipProps={[
						{ title: 'Cashflow :', value: displayMoney(currentValue?.cashflow) },
						{ title: 'Cashflow cumulé :', value: displayMoney(currentValue?.cumulative_cashflow) },
						{
							title: 'Date :',
							value: new Date(currentValue?.date).toLocaleDateString('fr-FR', {
								year: 'numeric',
								month: 'long',
								day: 'numeric',
							}),
						},
					]}
				/>
			)}

			<ParentSize>
				{({ width, height }) => (
					<InternalGraph
						width={width}
						// first render force height from 0 to margin.top + margin.bottom
						height={height || margin.top + margin.bottom}
						data={data}
						margin={margin}
						setCurrentValue={setCurrentValue}
					/>
				)}
			</ParentSize>
		</VStack>
	);
};
