import { ReactNode } from 'react';
import { HStack, Text } from '@chakra-ui/react';

export type BuildSideBarType = {
	icon: ReactNode;
	title: string;
	isActive: boolean;
	badge?: ReactNode;
};

const BuildSideBar = ({ icon, title, isActive, badge }: BuildSideBarType) => (
	<HStack
		spacing="sm"
		py="10px"
		px="sm"
		bg={isActive ? 'primary.black' : 'transparent'}
		borderRadius="sm"
		cursor="pointer"
		_hover={{ bg: isActive ? 'primary.black' : 'grey.300' }}
	>
		{icon}
		<HStack justify="space-between" w="100%">
			<Text color={isActive ? 'primary.white' : 'inherit'}>{title}</Text>
			{badge}
		</HStack>
	</HStack>
);

export default BuildSideBar;
