import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormControl, FormErrorMessage, FormLabel, useToast, VStack } from '@chakra-ui/react';

import ReferentielInput from 'components/inputs/ReferentielInput';
// Components
import TextInput from 'components/inputs/TextInput';
import { useGetKYCQuery, useUpdateKYCProfessionelleMutation } from 'services/requests/kyc';
import ProfessionalCategoryReferentiel from 'store/referentiels/professionalCategory';
import ProfessionalSectorReferentiel from 'store/referentiels/professionalSector';
import ProfessionalSituationReferentiel, { ProfessionalSituation } from 'store/referentiels/professionalSituation';
import { SituationProfessionnelleType } from 'store/types/KYC.type';

import { KYCFormProps } from './type';

type ProfessionalSituationWithPrivate = ProfessionalSituation | 'CIVIL_SERVANT';

const { EMPLOYEE, ...ProfessionalSituationReferentielWithoutEmployee } = ProfessionalSituationReferentiel;

const obfuscatedProfessionalSituationReferentiel: Record<ProfessionalSituationWithPrivate, string> = {
	EMPLOYEE: 'Salarié(e) du secteur privé',
	CIVIL_SERVANT: 'Salarié(e) du secteur public',
	...ProfessionalSituationReferentielWithoutEmployee,
};

type FormDataType = Omit<SituationProfessionnelleType, 'situationActuelle'> & {
	situationActuelle: ProfessionalSituationWithPrivate;
};

const ProfessionalForm = ({ onSuccess, formConfirmation }: KYCFormProps): JSX.Element => {
	const toast = useToast();
	const { data: kycData, isSuccess } = useGetKYCQuery();
	const [triggerUpdateProfessionelle] = useUpdateKYCProfessionelleMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		setValue,
	} = useForm<FormDataType>({
		shouldUnregister: true,
	});

	const situationPro = watch('situationActuelle');
	const askProfessionalSector =
		situationPro === 'EMPLOYEE' || situationPro === 'INDEPENDANT' || situationPro === 'CIVIL_SERVANT';
	const askCompanyName = situationPro === 'EMPLOYEE' || situationPro === 'INDEPENDANT';
	const askSiret = situationPro === 'INDEPENDANT';
	const askProfessionalCategory =
		situationPro === 'EMPLOYEE' ||
		situationPro === 'INDEPENDANT' ||
		situationPro === 'RETIRED' ||
		situationPro === 'CIVIL_SERVANT';
	const labelProfessionInput =
		situationPro === 'EMPLOYEE' || situationPro === 'INDEPENDANT' ? 'Profession' : 'Dernière profession exercée';
	const askActualProfession = situationPro !== 'CIVIL_SERVANT';

	const onSubmit = handleSubmit((data: FormDataType) => {
		triggerUpdateProfessionelle({
			...data,
			professionActuelle: data.situationActuelle === 'CIVIL_SERVANT' ? 'Fonctionnaire' : data.professionActuelle,
			situationActuelle:
				data.situationActuelle === 'CIVIL_SERVANT' ? ProfessionalSituation.EMPLOYEE : data.situationActuelle,
		})
			.unwrap()
			.then(() => onSuccess())
			.catch(() =>
				toast({
					title: 'Une erreur est survenue',
					status: 'error',
					duration: 9000,
					isClosable: true,
				}),
			);
	});

	useEffect(() => {
		if (kycData && isSuccess) {
			if (kycData.professionalSituation)
				setValue(
					'situationActuelle',
					kycData.profession && kycData.profession === 'Fonctionnaire'
						? 'CIVIL_SERVANT'
						: kycData.professionalSituation,
				);

			if (kycData.professionalCategory) setValue('categorieSocioProfessionnelle', kycData.professionalCategory);
			if (kycData.professionalSector) setValue('secteurActivite', kycData.professionalSector);
			if (kycData.selfEmployed) setValue('travailleurNonSalarie', kycData.selfEmployed);
			if (kycData.profession) setValue('professionActuelle', kycData.profession);
			if (kycData.companyName) setValue('nomEntreprise', kycData.companyName);
			if (kycData.siret) setValue('siret', kycData.siret);
		}
	}, [kycData, isSuccess, setValue]);

	return (
		<form onSubmit={onSubmit}>
			<VStack spacing="24px" align="start" w="100%">
				<VStack align="start" w="100%">
					<FormControl isInvalid={!!errors.situationActuelle}>
						<FormLabel>Situation professionnelle</FormLabel>
						<ReferentielInput
							name="situationActuelle"
							referentiel={obfuscatedProfessionalSituationReferentiel}
							register={register}
							options={{ required: true }}
							data-cy="kyc-situationActuelle"
						/>
						{errors.situationActuelle && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
					</FormControl>
				</VStack>

				{situationPro && (
					<VStack spacing="24px" align="start" w="100%">
						{/* SIRET - TNS uniquement */}
						{askSiret && (
							<VStack align="start" w="100%">
								<FormControl isInvalid={!!errors.siret}>
									<FormLabel>Numéro de SIRET</FormLabel>
									<TextInput placeholder="..." data-cy="kyc-siret" {...register('siret', { required: true })} />
									{errors.siret && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
								</FormControl>
							</VStack>
						)}

						{/* Catégorie professionnelle */}
						{askProfessionalCategory && (
							<VStack align="start" w="100%">
								<FormControl isInvalid={!!errors.categorieSocioProfessionnelle}>
									<FormLabel>Catégorie Professionnelle</FormLabel>
									<ReferentielInput
										name="categorieSocioProfessionnelle"
										referentiel={ProfessionalCategoryReferentiel}
										register={register}
										options={{ required: true }}
										data-cy="kyc-categorieSocioProfessionnelle"
									/>
									{errors.categorieSocioProfessionnelle && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
								</FormControl>
							</VStack>
						)}

						{/* Secteur d'activité */}
						{askProfessionalSector && (
							<VStack align="start" w="100%">
								<FormControl isInvalid={!!errors.secteurActivite}>
									<FormLabel>Secteur d'activité</FormLabel>
									<ReferentielInput
										name="secteurActivite"
										referentiel={ProfessionalSectorReferentiel}
										register={register}
										options={{ required: true }}
										data-cy="kyc-secteurProfessionnel"
									/>
									{errors.secteurActivite && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
								</FormControl>
							</VStack>
						)}

						{/* Profession actuelle */}
						{askActualProfession && (
							<VStack align="start" w="100%">
								<FormControl isInvalid={!!errors.professionActuelle}>
									<FormLabel>{labelProfessionInput}</FormLabel>
									<TextInput
										data-cy="kyc-professionActuelle"
										placeholder="..."
										{...register('professionActuelle', { required: true, maxLength: 50 })}
									/>
									{errors.professionActuelle?.type === 'required' && (
										<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
									)}
									{errors.professionActuelle?.type === 'maxLength' && (
										<FormErrorMessage>Ce champ ne doit pas faire plus de 50 caractères</FormErrorMessage>
									)}
								</FormControl>
							</VStack>
						)}

						{/* Nom de l'entreprise */}
						{askCompanyName && (
							<VStack align="start" w="100%">
								<FormControl isInvalid={!!errors.nomEntreprise}>
									<FormLabel>Nom de l'entreprise</FormLabel>
									<TextInput
										{...register('nomEntreprise', { required: true })}
										placeholder="..."
										data-cy="kyc-nomEntreprise"
									/>
									{errors.nomEntreprise?.type === 'required' && (
										<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
									)}
								</FormControl>
							</VStack>
						)}
					</VStack>
				)}
				{formConfirmation}
			</VStack>
		</form>
	);
};

export default ProfessionalForm;
