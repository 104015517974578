import { CopyFile } from '@carbon/icons-react';
import { Box, Button, Card, Heading, HStack, Show, Stack, Text, VStack } from '@chakra-ui/react';

import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useGetSponsorshipCodeQuery } from 'services/requests/sponsorship';

const AffiliatesCodes = (): JSX.Element => {
	const { data: promoCode, isLoading } = useGetSponsorshipCodeQuery();

	const toast = useThemedToast();
	const copyCode = (code: string | undefined) => {
		if (code) {
			void navigator.clipboard.writeText(code);
			eventTracker.mixpanel.copySponsorCode(promoCode?.code);
			toast({
				title: 'Code ajouté au presse-papier',
				duration: 3000,
				isClosable: true,
			});
		} else {
			toast({
				title: 'Code invalide, veuillez réessayer',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<VStack align="start" w="100%" spacing="lg">
			<Heading variant="Title-L-SemiBold">Mon code de parrainage</Heading>
			<Card w="100%">
				<VStack w="100%" align="start" spacing="sm">
					<Stack direction={{ base: 'column', sm: 'row' }} w="100%">
						<VStack align="start" flex="1">
							<Heading variant="Title-M-SemiBold">Donner mon code de parrainage</Heading>
							<Text variant="Text-M-Regular" color="grey.900">
								Envoyez ce code à la personne que vous souhaitez parrainer. Elle pourra ainsi le renseigner lors de son
								inscription
							</Text>
						</VStack>

						<Show above="lg">
							<Box w="1px" h="100%" bg="red.100" color="red.100" />
						</Show>

						<VStack align="end" justify="center" flex="1">
							<HStack>
								<Text textAlign="end" variant="Text-S-Bold" textDecoration="underline" flex="1">
									{promoCode?.code}
								</Text>
								<Button
									variant="secondary"
									isDisabled={isLoading || !promoCode?.code}
									onClick={() => copyCode(promoCode?.code)}
									rightIcon={<CopyFile size="16" />}
								>
									Copier
								</Button>
							</HStack>
							<HStack>
								<Text textAlign="end" variant="Text-S-Bold" textDecoration="underline" flex="1">
									app.ramify.fr/bienvenue?code={promoCode?.code}
								</Text>
								<Button
									variant="secondary"
									isDisabled={isLoading || !promoCode?.code}
									onClick={() => copyCode(`app.ramify.fr/bienvenue?code=${promoCode?.code}`)}
									rightIcon={<CopyFile size="16" />}
								>
									Copier
								</Button>
							</HStack>
						</VStack>
					</Stack>
					<Card w="100%" bg="warning.100" border="none" size="sm" borderRadius="0">
						<Text variant="Text-S-Medium" color="warning.900">
							La prime de parrainage s'applique uniquement sur le premier produit souscrit. Offre à durée limitée.
						</Text>
					</Card>
				</VStack>
			</Card>
		</VStack>
	);
};

export default AffiliatesCodes;
