import { backendApi } from 'services/apiService';
import {
	CoordonneesDTO,
	EtatCivilType,
	FoyerFiscalType,
	KYC,
	PatrimoineDTO,
	PeScpiKyc,
	RequestUpdateKyc,
	SignUpdateKyc,
	SituationProfessionnelleType,
	UpdateKycFromScpiPeDTO,
	UpdateNifDTO,
} from 'store/types/KYC.type';

export type TypeIdentite = 'passeport' | 'carteIdentite' | 'carteSejourResidence' | '';

export type UploadedDocumentStatus = {
	uploaded: boolean;
	originalName: string;
	typeIdentity?: TypeIdentite;
	modifiedDate?: string;
	key: string;
	contentType: string;
};
export type StatusPieceJustificatives = Record<string, UploadedDocumentStatus>;

const extendedApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		validateKyc: builder.mutation<KYC, void>({
			query: () => ({
				url: '/kyc/validate',
				method: 'PUT',
			}),
			invalidatesTags: ['User'],
		}),
		getKYC: builder.query<KYC | undefined, void>({
			query: () => '/kyc',
			providesTags: ['KYC'],
		}),
		updateKycFoyerFiscal: builder.mutation<KYC, FoyerFiscalType>({
			query: (body) => ({
				url: '/kyc/foyerFiscal',
				method: 'PUT',
				body,
			}),
			invalidatesTags: [
				'InvestSimulations',
				'Fiscalite',
				'Fiscalite/Imposition',
				'Fiscalite/UpdatedRepartition',
				'KYC',
			],
		}),
		updateKYCEtatCivil: builder.mutation<KYC, EtatCivilType>({
			query: (params) => ({
				url: '/kyc/etatCivil',
				method: 'PUT',
				body: params,
			}),
			invalidatesTags: ['KYC'],
		}),
		updateKYCProfessionelle: builder.mutation<KYC, SituationProfessionnelleType>({
			query: (params) => ({
				url: '/kyc/professionelle',
				method: 'PUT',
				body: params,
			}),
			invalidatesTags: ['KYC'],
		}),
		updatekycFromScpiPe: builder.mutation<void, UpdateKycFromScpiPeDTO>({
			query: (params) => ({
				url: '/kyc/kycFromScpiPe',
				method: 'PUT',
				body: params,
			}),
			invalidatesTags: ['KYC'],
		}),
		updateKYCCoordonnees: builder.mutation<KYC, CoordonneesDTO>({
			query: (params) => ({
				url: '/kyc/coordonnees',
				method: 'PUT',
				body: params,
			}),
			invalidatesTags: ['KYC'],
		}),
		updateKYCPatrimoine: builder.mutation<KYC, PatrimoineDTO>({
			query: (params) => ({
				url: '/kyc/patrimoine',
				method: 'PUT',
				body: params,
			}),
			invalidatesTags: ['KYC'],
		}),
		updateKYCNif: builder.mutation<KYC, UpdateNifDTO>({
			query: (params) => ({
				url: '/kyc/nif',
				method: 'PUT',
				body: params,
			}),
			invalidatesTags: ['KYC'],
		}),
		getKYCJustificatif: builder.query<StatusPieceJustificatives, void>({
			query: () => '/documents/kyc-status',
			providesTags: ['KYC/Justificatif'],
		}),
		updateKYCJustificatif: builder.mutation<void, FormData>({
			query: (formData) => ({
				url: '/documents/kyc',
				method: 'POST',
				body: formData,
			}),
			invalidatesTags: ['KYC/Justificatif'],
		}),
		// KYC common
		getPESCPIKYC: builder.query<PeScpiKyc, void>({
			query: () => '/kyc/pe-scpi-kyc',
			providesTags: ['PE-SCPI-KYC'],
		}),
		updatePESCPIKYC: builder.mutation<void, PeScpiKyc>({
			query: (params) => ({
				url: `/kyc/pe-scpi-kyc`,
				method: 'POST',
				body: params,
			}),
			invalidatesTags: ['PE-SCPI-KYC', 'KYC'],
		}),
		requestUpdateApicilKYC: builder.mutation<void, RequestUpdateKyc>({
			query: (params) => ({
				url: '/kyc/update',
				method: 'POST',
				body: params,
			}),
		}),
		signUpdateApicilKYC: builder.mutation<void, SignUpdateKyc>({
			query: (params) => ({
				url: '/kyc/sign',
				method: 'POST',
				body: params,
			}),
			invalidatesTags: ['KYC'],
		}),
		getActualizationMandatory: builder.query<boolean, void>({
			query: () => '/kyc/actualization-mandatory',
			providesTags: ['KYC'],
		}),
	}),
});

export const {
	useValidateKycMutation,
	useGetPESCPIKYCQuery,
	useUpdatePESCPIKYCMutation,
	useGetKYCQuery,
	useUpdateKYCEtatCivilMutation,
	useUpdateKYCProfessionelleMutation,
	useUpdateKYCCoordonneesMutation,
	useUpdateKYCPatrimoineMutation,
	useGetKYCJustificatifQuery,
	useUpdateKYCJustificatifMutation,
	useUpdateKycFoyerFiscalMutation,
	useUpdatekycFromScpiPeMutation, // KYC common
	useUpdateKYCNifMutation,
	useRequestUpdateApicilKYCMutation,
	useSignUpdateApicilKYCMutation,
	useGetActualizationMandatoryQuery,
} = extendedApi;
