import { Dispatch, FC, useState } from 'react';
import { Card, Heading, HStack, Image, Skeleton, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import Leaf from 'assets/icons/Leaf.svg';
import RisqueProfileCard from 'components/cards/ProfileRisqueCard';
import FilterSelect from 'components/FilterSelect';
import RepartitionBar from 'components/Graphs/RepartitionBar';
import RiskSlider from 'components/Graphs/RisqueSliderBar';
import { useGetCompositionPortfolioQuery } from 'services/requests/investment';
import { InvestFundType } from 'store/types/investment.type';
import { PortfolioType } from 'store/types/profilage.type';
import { toLowerCase } from 'utils/functions';

const ResultCompositionPortofolio: FC<{
	risk: number;
	portfolioType: Exclude<PortfolioType, 'CUSTOM'>;
}> = ({ risk, portfolioType }) => {
	const { data, isLoading } = useGetCompositionPortfolioQuery();
	const colorSectors = ['#D2C6EC', '#C9D5E8', '#EFE1C3', '#CFE2D9', '#FFD7C9'];

	if (isLoading || !data) return <Skeleton height="400px" />;

	const computedDisplay = Object.entries(data[toLowerCase(portfolioType)][risk]).map(([, obj], idx) => ({
		name: obj.name as InvestFundType,
		value: obj.value,
		color: colorSectors[idx],
	}));

	return (
		<Card w="100%">
			<Wrap spacing="48px">
				<WrapItem minW="250px" flex={{ base: undefined, md: '0.5' }}>
					<VStack spacing="16px" align="start" w="100%">
						<Heading variant="Title-M-SemiBold">Profil de risque</Heading>
						<RisqueProfileCard risque={risk} />
					</VStack>
				</WrapItem>
				<WrapItem minW="250px" flex="1">
					<VStack spacing="16px" align="start" w="100%">
						<Heading variant="Title-M-SemiBold">Actifs du portefeuille</Heading>
						<HStack spacing="4px" align="center" w="100%">
							<Image src={Leaf} alt="feuille" w="20px" />
							<Text variant="label" color="green.700">
								Portefeuilles compatibles avec {'  '}
								<Text color="green.700" fontWeight="600" as="span">
									Ramify Green
								</Text>
							</Text>
						</HStack>
						<RepartitionBar data={computedDisplay} directionLegend="column" />
					</VStack>
				</WrapItem>
			</Wrap>
		</Card>
	);
};

// this is terrible, to rework
const ComparePortfoliosCards = (): JSX.Element => {
	const [selectedFilter, setSelectedFilter] = useState<Exclude<PortfolioType, 'CUSTOM'>>('ELITE');

	const portfolioType = [
		{ label: 'Essential', labelValue: 'ESSENTIAL' },
		{ label: 'Flagship', labelValue: 'FLAGSHIP' },
		{ label: 'Elite', labelValue: 'ELITE' },
	] satisfies { label: string; labelValue: Exclude<PortfolioType, 'CUSTOM'> }[];

	const [risk, updateRisk] = useState(5);

	return (
		<VStack spacing="32px" align="start" w="100%">
			<Heading variant="Title-L-SemiBold">Comparez les différents portefeuilles Ramify</Heading>
			<Wrap spacing="45px" w="100%">
				<WrapItem minW="250px">
					<VStack spacing="16px" align="start" w="100%">
						<Text variant="Text-M-Medium">Type</Text>
						<FilterSelect
							data={portfolioType}
							label={{ filterValue: selectedFilter, setFilterValue: setSelectedFilter as Dispatch<unknown> }}
						/>
					</VStack>
				</WrapItem>
				<WrapItem minW="250px" flex="0.7">
					<VStack spacing="16px" align="start" w="100%">
						<Text variant="Text-M-Medium">Niveau de risque</Text>
						<RiskSlider value={risk} onChange={updateRisk} step={1} />
					</VStack>
				</WrapItem>
			</Wrap>
			<ResultCompositionPortofolio risk={risk} portfolioType={selectedFilter} />
		</VStack>
	);
};
export default ComparePortfoliosCards;
