import { Launch } from '@carbon/icons-react';
import { Badge, BadgeProps, Heading, Image, Show, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { IconButton } from 'components/Button/Button';

type ArticleProps = {
	imageURL: string;
	title: string;
	description: string;
	tags: { label: string; variant: BadgeProps['variant'] }[];
	btnCallback: () => void;
};

const Article = ({ imageURL, title, description, tags, btnCallback }: ArticleProps) => (
	<Wrap spacing="24px">
		<WrapItem minW={{ base: '100%', md: '360px' }}>
			<Image
				src={imageURL}
				alt="article photo"
				w={{ base: '100%', xl: '360px' }}
				h={{ base: '100%', xl: '100%' }}
				borderRadius="base"
				objectFit="cover"
			/>
		</WrapItem>
		<WrapItem flex="1">
			<VStack align="end" justify="space-between" w="100%" h="100%">
				<VStack align="start" w="100%">
					<Wrap spacing="8px">
						{tags.map(({ label }) => (
							<WrapItem key={label}>
								<Badge variant="outlineYellow">{label}</Badge>
							</WrapItem>
						))}
					</Wrap>
					<Heading variant="Title-M-SemiBold">{title}</Heading>
					<Show above="md">
						<Text variant="Text-M-Regular" color="grey.900">
							{description}
						</Text>
					</Show>
				</VStack>
				<IconButton onClick={btnCallback} variant="secondary" right icon={<Launch />}>
					Voir l'article
				</IconButton>
			</VStack>
		</WrapItem>
	</Wrap>
);
export default Article;
