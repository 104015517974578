import { useLocation } from 'react-router-dom';
import { Card, Heading, HStack, Image, Link, Text, VStack } from '@chakra-ui/react';

import ShieldIcon from 'assets/icons/shield.svg';
import { ArrowButton } from 'components/Button/Button';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { KycProduct } from 'onboarding/KYC';
import { KycStepProps } from 'onboarding/KYC/utils';

const Introduction = ({ onNext, product }: KycStepProps & { product: KycProduct }): JSX.Element => {
	const location = useLocation();
	const inParcoursInvest = location.pathname.includes('invest');

	return (
		<VStack w="100%" align="start" spacing="24px">
			<Heading variant="Title-XL-Bold">Informations personnelles</Heading>
			<VStack spacing="24px" align="start">
				{product === 'kyc-update' ? (
					<Text variant="Text-M-Regular" color="grey.900">
						L'actualisation de vos données personnelles est <strong>obligatoire</strong>. Veuillez vérifier que les
						informations communiquées sont toujours valides.
					</Text>
				) : (
					<Text variant="Text-M-Regular" color="grey.900">
						Durant cette étape, vous allez renseigner les informations administratives nécessaires à la finalisation de
						votre souscription. Il s’agit d’une obligation légale avant de pouvoir signer vos contrats.
					</Text>
				)}

				<Card>
					<HStack align="center" spacing="16px">
						<Image src={ShieldIcon} alt="bouclier" />

						<Text variant="Text-S-Regular">
							Les informations récoltées sont soumises à la Réglementation Générale sur la Protection des Données (RGPD)
							et ne seront en aucun cas vendues à d’autres sociétés.
						</Text>
					</HStack>
				</Card>
			</VStack>

			{product === 'invest' ? (
				<ArrowButton variant="primary" size="lg" data-cy="cy-intro-btn" onClick={onNext}>
					Remplir mes informations
				</ArrowButton>
			) : (
				<BottomStepBar showPrev={false} onNext={onNext} />
			)}

			{inParcoursInvest && (
				<Text variant="Text-S-Regular" color="gray.400">
					Les informations recueillies sur ce document sont enregistrées dans un fichier informatisé par APICIL Epargne
					pour la gestion des contrats. La base légale du traitement est l’exécution d’un contrat. Des traitements sont
					également mis en oeuvre dans le cadre de l’exécution des dispositions légales, réglementaires et
					administratives en vigueur ou basés sur notre intérêt légitime, comme la lutte contre le blanchiment et le
					financement du terrorisme ainsi que la lutte contre la fraude. Les données collectées pourront être
					communiquées aux entités du Groupe APICIL, à ses partenaires et sous traitants éventuels ainsi qu’aux tiers
					dûment habilités lorsque cette communication est strictement nécessaire pour les finalités déclarées. Les
					données sont conservées pour une durée de 30 ans à compter de l’échéance de votre contrat, à laquelle
					s’ajoutent les règles de prescription applicables. Vous pouvez accéder aux données vous concernant, les
					rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données. Vous
					pouvez également retirer votre consentement, vous opposer au traitement de vos données, exercer votre droit à
					la portabilité ou définir des directives post mortem. Pour exercer ces droits ou pour toute question sur le
					traitement de vos données, vous pouvez écrire à dpo@apicil.com ou à l’adresse : Groupe APICIL, Délégué à la
					protection des données (DPO), 38 rue François Peissel, 69300 Caluire et Cuire. Si vous estimez que vos droits
					ne sont pas respectés, vous pouvez adresser une réclamation auprès de la CNIL. Plus de détails sur {'	'}
					<Link as="span" href=" https://mon.apicil.com/protection%20des%20donnees%20personnelles/">
						https://mon.apicil.com/protection des donnees personnelles/
					</Link>
				</Text>
			)}
		</VStack>
	);
};

export default Introduction;
