import { Link as ReachLink } from 'react-router-dom';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Button,
	Card,
	Heading,
	HStack,
	Link,
	Text,
	Tooltip,
	VStack,
	Wrap,
} from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import AssetBadge from 'components/Tags';
import { FeesType } from 'hooks/useFees';
import { LabOfferComparaisonResponse } from 'services/requests/invest/preferences';
import { InvestFundType } from 'store/types/investment.type';
import { PortfolioType, portfolioTypeLabels } from 'store/types/profilage.type';
import colors from 'theme/foundations/colors';
import { isNotNone, toLowerCase } from 'utils/functions';
import { displayMoneyNoDigits } from 'utils/rendering';

// const OnboardingPortfolioTypeCard = ({
// 	type,
// 	content,
// 	tooltipTextTitle,
// 	tooltipTextPerformance,
// 	tooltipTextPerformanceGross,
// 	onClick,
// 	cy,
// 	recommended = false,
// 	isSelected,
// 	potentialPortfolioPerformances,
// 	disabled = false,
// 	underButtonInfo,
// 	fees,
// }: {
// 	type: Exclude<PortfolioType, 'CUSTOM'>;
// 	content: string;
// 	tooltipTextTitle: React.ReactNode;
// 	tooltipTextPerformance: React.ReactNode;
// 	tooltipTextPerformanceGross: React.ReactNode;
// 	onClick: () => void;
// 	cy: string;
// 	recommended?: boolean;
// 	isSelected: boolean;
// 	potentialPortfolioPerformances?: LabOfferComparaisonResponse;
// 	disabled?: boolean;
// 	underButtonInfo?: React.ReactNode;
// 	fees: Record<FeesType, number>;
// }): JSX.Element => (
// 	<Card
// 		overflow="visible"
// 		w="100%"
// 		borderColor={isSelected ? `${colors.red[500]}` : 'grey.300'}
// 		boxSizing="border-box"
// 		p="16px"
// 		bg={disabled ? 'grey.100' : 'white'}
// 	>
// 		<VStack w="100%" h="100%" overflow="visible">
// 			{recommended && (
// 				<Box
// 					position="absolute"
// 					top="-4%"
// 					right="7%"
// 					w="126px"
// 					h="37px"
// 					bg="#DAFCDB"
// 					border="1px solid #46D684"
// 					boxSizing="border-box"
// 					borderRadius="5px"
// 					p={1}
// 				>
// 					<Text
// 						as="span"
// 						fontSize="14px"
// 						fontWeight={500}
// 						color="#239A6E"
// 						w="100%"
// 						h="100%"
// 						marginLeft="8px"
// 						marginTop="2px"
// 					>
// 						Recommandé
// 					</Text>
// 				</Box>
// 			)}
// 			<VStack w="100%" h="100%" mt="8px !important" spacing="16px">
// 				<VStack w="100%">
// 					<HStack spacing="8px">
// 						<Heading as="span" variant="title" fontWeight={600} maxW="100%" color="grey.700">
// 							{portfolioTypeLabels[type]}
// 						</Heading>
// 						<CustomTooltip text={tooltipTextTitle} />
// 					</HStack>
// 					<Text variant="label" textAlign="center" minH="64px">
// 						{content}
// 					</Text>
// 				</VStack>
// 				<VStack w="100%" minHeight="100px">
// 					<Text fontSize="14px" fontWeight={500} color="grey.700">
// 						Classes d'actifs
// 					</Text>
// 					<Wrap justify="center" w="100%" spacing="8px">
// 						{Object.entries(badgesList)
// 							.filter(([, value]) => {
// 								if (type === 'ESSENTIAL' && value === assetClassBadges.IMMOBILIER) return false;
// 								if ((type === 'ESSENTIAL' || type === 'FLAGSHIP') && value === assetClassBadges['PRIVATE EQUITY'])
// 									return false;
// 								return true;
// 							})
// 							.map(([key, value]) => {
// 								return <WrapItem key={key}>{value}</WrapItem>;
// 							})}
// 					</Wrap>
// 				</VStack>

// 				<VStack w="100%">
// 					{isNotNone(potentialPortfolioPerformances) && (
// 						<Box p="2">
// 							<HStack>
// 								<Text flex={1} variant="label" fontSize="12px">
// 									Performance annuelle espérée, nette de frais
// 								</Text>
// 								<CustomTooltip text={tooltipTextPerformance} />
// 								<Text fontWeight="700" color="green.700" fontSize="18px">
// 									+{(potentialPortfolioPerformances[toLowerCase(type)].annualized_return * 100).toFixed(2)}%
// 								</Text>
// 							</HStack>

// 							<HStack mt="8px">
// 								<Text variant="detail" flex={1} fontSize="10px">
// 									Performance annuelle espérée, brute de frais de contrat et nette de frais de supports
// 								</Text>
// 								<CustomTooltip text={tooltipTextPerformanceGross} />
// 								<Text fontWeight="semibold" color="green.700" fontSize="14px" textAlign="center">
// 									+{(potentialPortfolioPerformances[toLowerCase(type)].annualized_return * 100 + 1.3).toFixed(2)}%
// 								</Text>
// 							</HStack>
// 						</Box>
// 					)}

// 					<Accordion allowToggle w="100%">
// 						<AccordionItem>
// 							<h2>
// 								<AccordionButton>
// 									<HStack flex="1" alignContent={'flex-start'}>
// 										<Text textAlign="left" as="u" variant="detail">
// 											Frais du contrat
// 										</Text>
// 										<AccordionIcon />
// 									</HStack>
// 									<Text textAlign="right" variant="title">
// 										max {fees.ramifyGestion + fees.envelope} %
// 									</Text>
// 								</AccordionButton>
// 							</h2>
// 							<AccordionPanel pb={4}>
// 								<Text mt="8px" variant="detail" color="grey.700" fontWeight="semibold">
// 									Frais du contrat en unités de compte {fees.ramifyGestion + fees.envelope} %
// 								</Text>
// 								<Text variant="detail" color="grey.700">
// 									Frais d’enveloppe : {fees.envelope} %
// 								</Text>
// 								<Text variant="detail" color="grey.700">
// 									Frais de gestion : {fees.ramifyGestion} %
// 								</Text>
// 								<Text variant="detail" color="grey.700">
// 									Frais d’entrée et sortie : {fees.entryExit} %
// 								</Text>

// 								<Text mt="8px" variant="detail" color="grey.700" fontWeight="semibold">
// 									Frais du contrat avec fonds euros {fees.fondEuro} %
// 								</Text>
// 								<Text variant="detail" color="grey.700">
// 									dont frais d’entrée et sortie : {fees.fondEuroEntryExit} %
// 								</Text>
// 							</AccordionPanel>
// 						</AccordionItem>
// 					</Accordion>
// 					<Button
// 						w="100%"
// 						variant="primary"
// 						isDisabled={disabled}
// 						onClick={disabled ? undefined : onClick}
// 						data-cy={cy}
// 					>
// 						Choisir ce portefeuille
// 					</Button>
// 				</VStack>
// 				{underButtonInfo && underButtonInfo}
// 			</VStack>
// 		</VStack>
// 	</Card>
// );

const AssetClassFromPortfolioType: Record<Exclude<PortfolioType, 'CUSTOM'>, InvestFundType[]> = {
	ESSENTIAL: ['Actions', 'Obligations', 'Fonds Euros'],
	FLAGSHIP: ['Actions', 'Obligations', 'Fonds Euros', 'SCPI'],
	ELITE: ['Actions', 'Obligations', 'Fonds Euros', 'SCPI', 'Private Equity'],
};

const OnboardingPortfolioTypeCard = ({
	type,
	content,
	tooltipTextTitle,
	tooltipTextPerformance,
	tooltipTextPerformanceGross,
	onClick,
	cy,
	isSelected,
	potentialPortfolioPerformances,
	disabled = false,
	minimumTicket,
	fees,
}: {
	type: Exclude<PortfolioType, 'CUSTOM'>;
	content: string;
	tooltipTextTitle: React.ReactNode;
	tooltipTextPerformance: React.ReactNode;
	tooltipTextPerformanceGross: React.ReactNode;
	onClick: () => void;
	cy: string;
	isSelected: boolean;
	potentialPortfolioPerformances?: LabOfferComparaisonResponse;
	disabled?: boolean;
	minimumTicket: number;
	fees: Record<FeesType, number>;
}): JSX.Element => (
	<Card w="100%" h="100%" borderColor={isSelected ? `${colors.primary.yellow}` : 'grey.300'} p="16px">
		<VStack w="100%" h="100%" spacing="24px">
			<VStack w="100%" spacing="16px" alignItems="center" textAlign="center">
				<HStack spacing="8px">
					<Heading variant="Title-S-SemiBold">{portfolioTypeLabels[type]}</Heading>
					<CustomTooltip text={tooltipTextTitle} />
				</HStack>
				<Text variant="Text-S-Regular" color="grey.900">
					{content}
				</Text>
			</VStack>
			<VStack w="100">
				<Text variant="Text-S-Medium">Classes d'actifs</Text>
				<Wrap justify="center" w="100%" spacing="8px" minH="70px">
					{AssetClassFromPortfolioType[type].map((assetClass) => (
						<AssetBadge variant={assetClass} key={assetClass} />
					))}
				</Wrap>
			</VStack>

			{isNotNone(potentialPortfolioPerformances) && (
				<VStack w="100%" align="start">
					<HStack justify="space-between" spacing="24px" w="100%">
						<HStack>
							<Text variant="Text-S-Medium">Performance annuelle espérée, nette de frais</Text>
							<CustomTooltip text={tooltipTextPerformance} />
						</HStack>
						<Text variant="Title-S-Bold" color="positive.900">
							+{(potentialPortfolioPerformances[toLowerCase(type)].annualized_return * 100).toFixed(2)}%
						</Text>
					</HStack>

					<HStack justify="space-between" spacing="24px" w="100%">
						<Text variant="Text-S-Regular" maxW="180px">
							Performance annuelle espérée, brute de frais de contrat et nette de frais de supports
						</Text>
						<CustomTooltip text={tooltipTextPerformanceGross} />
						<Text variant="Title-S-Bold" color="positive.900">
							+{(potentialPortfolioPerformances[toLowerCase(type)].annualized_return * 100 + 1.3).toFixed(2)}%
						</Text>
					</HStack>
				</VStack>
			)}

			<Accordion allowToggle w="100%">
				<AccordionItem>
					<h2>
						<AccordionButton padding="24px 8px">
							{/* <AccordionButton padding="24px 8px" _hover={{ bg: 'grey.100' }}> */}
							<HStack flex="1" alignContent="flex-start">
								<Text textAlign="left" as="u" variant="Text-S-Regular">
									Frais du contrat
								</Text>
								<AccordionIcon />
							</HStack>
							<Text textAlign="right" variant="Title-S-Bold">
								max {fees.ramifyGestion + fees.envelope} %
							</Text>
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4}>
						<VStack w="100%" align="start" spacing="16px">
							<VStack w="100%" align="start" spacing="8px">
								<Text variant="Text-S-Medium">
									Frais du contrat en unités de compte {fees.ramifyGestion + fees.envelope} %
								</Text>
								<Text variant="Text-S-Regular">Frais d’enveloppe : {fees.envelope} %</Text>
								<Text variant="Text-S-Regular">Frais de gestion : {fees.ramifyGestion} %</Text>
								<Text variant="Text-S-Regular">Frais d’entrée et sortie : {fees.entryExit} %</Text>
							</VStack>

							<VStack w="100%" align="start" spacing="8px">
								<Text variant="Text-S-Medium">Frais du contrat avec fonds euros {fees.fondEuro} %</Text>
								<Text variant="Text-S-Regular">dont frais d’entrée et sortie : {fees.fondEuroEntryExit} %</Text>
							</VStack>
						</VStack>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>
			<VStack w="100%" align="center" spacing="16px">
				<Tooltip
					padding="8px 16px"
					bg="primary.black"
					hasArrow
					label={
						disabled
							? `Ce portefeuille est seulement accessible à partir de ${displayMoneyNoDigits(minimumTicket)}`
							: undefined
					}
				>
					<Button
						w="100%"
						variant="primary"
						isDisabled={disabled}
						{...(disabled ? { _hover: { bg: 'yellow.100' } } : {})}
						onClick={disabled ? undefined : onClick}
						data-cy={cy}
					>
						Choisir ce portefeuille
					</Button>
				</Tooltip>
				{/* {underButtonInfo && underButtonInfo} */}
				<VStack w="100%" align="center" spacing="8px">
					<Text variant="Text-S-Medium" color={disabled ? 'negative.900' : 'primary.black'}>
						Montant minimum {displayMoneyNoDigits(minimumTicket)}
					</Text>
					{disabled && (
						<Link as={ReachLink} to="../../projet">
							<Text variant="Text-S-Regular" textDecoration="underline">
								Modifier mon projet
							</Text>
						</Link>
					)}
				</VStack>
			</VStack>
		</VStack>
	</Card>
);

export default OnboardingPortfolioTypeCard;
