import { Text } from '@chakra-ui/react';

const MentionUC = () => (
	<Text variant="Text-S-Regular" color="primary.black">
		Le Souscripteur/Titulaire prend acte que l’organisme Assureur ne s’engage que sur le nombre d’unités de compte mais
		pas sur leur valeur. La valeur des unités de compte, qui reflète la valeur d’actifs sous-jacents, n’est pas garantie
		mais est sujette à des fluctuations à la hausse ou à la baisse dépendant en particulier de l’évolution des marchés
		financiers. Les performances passées ne préjugent pas des performances futures.
	</Text>
);

export { MentionUC };
