import { useMemo } from 'react';
import { Box, Heading, VStack } from '@chakra-ui/react';

import BottomStepBar from 'components/steppers/BottomStepBar';
import { SimulationStepProps } from 'onboarding/Stepper/stepper.slice';
import { UpdateInvestSimulationEnvelope } from 'services/requests/invest/simulation';
import { useGetKYCQuery } from 'services/requests/kyc';
import { ProfessionalSituation } from 'store/referentiels/professionalSituation';
import { SimulationEnvelopeType } from 'store/types/investSimulation';

import EnvelopeCard from './Sections/OfferCard';

type EnvelopesType = {
	key: SimulationEnvelopeType;
	component: JSX.Element;
};

const Envelope = ({ onNext, onPrev, simulation }: SimulationStepProps<UpdateInvestSimulationEnvelope>): JSX.Element => {
	const { data: kyc } = useGetKYCQuery();
	const isRetired = useMemo(
		() => kyc?.professionalSituation === ProfessionalSituation.RETIRED,
		[kyc?.professionalSituation],
	);

	const optim = useMemo(
		() => ({
			key: SimulationEnvelopeType.OPTIM,
			component: (
				<EnvelopeCard
					isRetired={isRetired}
					recommended
					envelope={SimulationEnvelopeType.OPTIM}
					selected={simulation.envelope === SimulationEnvelopeType.OPTIM}
					onClick={() => onNext({ envelope: SimulationEnvelopeType.OPTIM })}
				/>
			),
		}),
		[isRetired, onNext, simulation.envelope],
	);

	const av = useMemo(
		() => ({
			key: SimulationEnvelopeType.AV,
			component: (
				<EnvelopeCard
					isRetired={isRetired}
					envelope={SimulationEnvelopeType.AV}
					selected={simulation.envelope === SimulationEnvelopeType.AV}
					onClick={() => onNext({ envelope: SimulationEnvelopeType.AV })}
				/>
			),
		}),
		[isRetired, onNext, simulation.envelope],
	);

	const per = useMemo(
		() => ({
			key: SimulationEnvelopeType.PER,
			component: (
				<EnvelopeCard
					isRetired={isRetired}
					envelope={SimulationEnvelopeType.PER}
					selected={simulation.envelope === SimulationEnvelopeType.PER}
					onClick={() => onNext({ envelope: SimulationEnvelopeType.PER })}
				/>
			),
		}),
		[isRetired, onNext, simulation.envelope],
	);

	const envelopes = useMemo(() => {
		const e: EnvelopesType[] = [];
		if (simulation.initialAUM >= 2000 && simulation.investmentPreferences?.portfolioType !== 'ELITE') e.push(optim);
		if (simulation.type === 'OTHER') e.push(av, per);
		else e.push(per, av);
		return e;
	}, [av, optim, per, simulation.initialAUM, simulation.investmentPreferences?.portfolioType, simulation.type]);

	return (
		<VStack align="center" w="100%" spacing="48px">
			<VStack w="100%" spacing="48px" align="start" maxW="880px">
				<Heading variant="Title-XL-Bold">Choisissez votre enveloppe</Heading>
			</VStack>
			<VStack w="100%" spacing="24px" align="start" maxW="880px">
				{envelopes.map((envelope) => (
					<Box w="100%" key={envelope.key}>
						{envelope.component}
					</Box>
				))}
			</VStack>
			<BottomStepBar onPrev={onPrev} showNext={false} />
		</VStack>
	);
};

export default Envelope;
