import { Button, Card, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';

import { useGetKYCQuery } from 'services/requests/kyc';
import { useGetUserQuery } from 'services/requests/user';

const MoralPersonBlockerCard = () => {
	const { data: user } = useGetUserQuery();
	const { data: kyc } = useGetKYCQuery();

	return (
		<Card variant="blue" w="100%">
			<VStack align="start" justify="space-between" spacing="16px">
				<Text variant="Title-S-Bold">Un message de votre conseiller :</Text>
				<Text variant="Text-M-Regular" color="grey.900">
					Pour souscrire en tant que personne morale, nous vous invitons à prendre rendez-vous avec un membre de
					l'équipe Ramify. Pour une souscription efficace et rapide, nous vous prions de préparer les éléments listés:
				</Text>
				<UnorderedList pl="20px" pb="8px">
					<ListItem>
						<Text variant="Text-M-Regular" color="grey.900">
							Kbis à jour
						</Text>
					</ListItem>
					<ListItem>
						<Text variant="Text-M-Regular" color="grey.900">
							Statuts à jour
						</Text>
					</ListItem>
					<ListItem>
						<Text variant="Text-M-Regular" color="grey.900">
							Un document d'identité valide du représentant de la personne morale (Carte d'identité, Passeport, Permis
							de conduire biométrique)
						</Text>
					</ListItem>
					<ListItem>
						<Text variant="Text-M-Regular" color="grey.900">
							RIB et IBAN du compte qui sera prélevé pour la souscription
						</Text>
					</ListItem>
				</UnorderedList>
				<Button
					variant="primary"
					size="lg"
					w="100%"
					onClick={() => {
						// const phoneNumber = `${kyc?.phoneNumberCode ?? ''} ${kyc?.phoneNumber ?? ''}`;
						return window.open(
							new URL(
								'https://calendly.com/d/dt5-wj5-rk3/echange-avec-un-conseiller-prive-ramify?' +
									new URLSearchParams({
										name: `${kyc?.firstName ?? ''} ${kyc?.lastName ?? ''}`,
										email: user?.email ?? '',
										// a1: phoneNumber,
										// a2: completedData.amountVersement.toString(),
										// a3: fund.name,
									} as Record<string, string>),
							),
						);
					}}
				>
					Prendre rendez-vous
				</Button>
			</VStack>
		</Card>
	);
};

export default MoralPersonBlockerCard;
