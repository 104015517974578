export const LABEL_REQUESTED = {
	label: 'Demande de souscription',
	description: 'Votre demande de souscription a été reçue par notre équipe et sera traitée sous 2 jours ouvrés.',
};

export const LABEL_PENDING = {
	label: 'Validation du dossier',
	description:
		'Votre dossier a été transmis à notre partenaire pour validation. Cette étape dure en moyenne 2 à 3 jours ouvrés (jusqu’à 2 semaines pour certains partenaires).',
};

export const LABEL_SIGNED = {
	label: 'Signature du bulletin',
	description:
		'Votre bulletin de souscription est en attente de signature. Vous le trouverez dans la boîte mail liée à votre compte Ramify. Si vous n’avez pas reçu d’email, contactez nous via le chat.',
	tooltip: <>Le bulletin de souscription est envoyé par email une fois que le dossier a été validé.</>,
};

export const LABEL_RECEPTION = {
	label: 'Réception des fonds',
	description:
		'Nous sommes en attente de votre virement ou prélèvement. Une fois cette étape validée, votre souscription sera finalisée.',
	tooltip: (
		<>
			Enregistrement de votre opération <b>(virement ou prélèvement) </b>. En moyenne sous <b>1 à 2 semaines </b>selon
			le produit et partenaire.
		</>
	),
};

export const LABEL_FINALISATION = {
	label: 'Finalisation',
	description:
		'Votre virement a bien été effectué et votre opération est enregistrée. Nous vous transmettrons votre attestation de parts sous 1 à 2 semaines environ (jusqu’à trimestriellement pour certains).',
	tooltip: (
		<>
			Réception de l’attestation de votre opération. En moyenne sous <b>2 semaines. </b>
		</>
	),
};

export const LABEL_SAVINGS_ACCOUNT_FINALISATION = {
	label: 'Finalisation',
	description:
		'Votre virement a bien été effectué et votre opération est enregistrée. Votre livret épargne sera ouvert sous peu et disponible dans votre espace client.',
	tooltip: (
		<>
			Réception de l’attestation de votre opération. En moyenne sous <b>1 semaine. </b>
		</>
	),
};

export const LABEL_INVEST_CONTRACT_SIGNED = {
	label: 'Après la signature',
	description:
		'Validation de votre dossier sous deux jours ouvrés en moyenne, une fois ouvert vous pouvez consulter votre contrat depuis votre espace client',
};
export const LABEL_INVEST_CONTRACT_OPENED = {
	label: "Après l'ouverture",
	description:
		'Votre virement a bien été effectué et votre opération est enregistrée. Votre contrat sera ouvert sous peu et disponible dans votre espace client.',
};
export const LABEL_INVEST_CONTRACT_VISUALISATION = {
	label: 'Visualisation du contrat',
	description:
		'Votre virement a bien été effectué et votre opération est enregistrée. Votre contrat sera ouvert sous peu et disponible dans votre espace client.',
};
