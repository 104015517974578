import { Route, Routes, useNavigate } from 'react-router-dom';

import AddBankAccountDrawer from './Sections/AddBankAccountDrawer';
import SelectBankAccount from './Sections/SelectBankAccount';

// This component must be used with a <FormProvider> component, and a /* parent route
const SelectOrAddBankAccountFormContext = (): JSX.Element => {
	const navigate = useNavigate();
	const urlPathAddBankAccountDrawer = 'ajouter-compte-bancaire';

	return (
		<Routes>
			<Route
				path=":id?"
				element={
					<>
						<SelectBankAccount onClickAdd={() => navigate(urlPathAddBankAccountDrawer, { preventScrollReset: true })} />
						<AddBankAccountDrawer
							urlPath={urlPathAddBankAccountDrawer}
							onClose={() => navigate('', { preventScrollReset: true })}
						/>
					</>
				}
			/>
		</Routes>
	);
};

export default SelectOrAddBankAccountFormContext;
