import { FC, ReactNode, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, BuildingInsights_2, Diagram, PiggyBankSlot, TextLinkAnalysis } from '@carbon/icons-react';
import {
	Box,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	HStack,
	IconButton,
	Image,
	Text,
	useDisclosure,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import AvIcon from 'assets/icons/ProductsColoredIcons/Av.svg';
import CatIcon from 'assets/icons/ProductsColoredIcons/Cat.svg';
import CerIcon from 'assets/icons/ProductsColoredIcons/Cer.svg';
import PerIcon from 'assets/icons/ProductsColoredIcons/Per.svg';
import InvestmentMetricCard from 'components/Metrics/InvestmentMetrics/Card';
import productSubscriptionSteps, { SubscriptionStep } from 'components/products/SCPI-PE/subscriptionSteps';
import SubscriptionDocuments from 'components/SubscriptionDocuments';
import { getRoundedTimeHorizon } from 'onboarding/Invest/Shared/Objective/projectData';
import { FundSubscriptionStatus, PrivateEquitySubscription, SCPISubscription } from 'store/types/airtable.type';
import { EnvelopeType, ProductSubscriptionType, ProductType } from 'store/types/global.type';
import { SavingsAccountStatus, SavingsAccountType, savingsAccountTypeLabels } from 'store/types/savingsAccount.type';
import { isNotNone } from 'utils/functions';
import { displayCapitalized, displayMoney } from 'utils/rendering';

import { HeaderCard } from './HeaderCard';
import { Step } from './Step';

export type DetailType = {
	label: string;
	value: string | number;
};

export type HeaderProps = {
	heading: string;
	type: ProductType;
	picture?: string;
	title: string | JSX.Element;
	info?: ReactNode;
};

const icons: Record<EnvelopeType | SavingsAccountType, string> = {
	PER: PerIcon,
	AV: AvIcon,
	[SavingsAccountType.CAT]: CatIcon,
	[SavingsAccountType.CER]: CerIcon,
};

export const bgColors: Record<ProductType.INVEST | ProductType.CASH, string> = {
	[ProductType.INVEST]: 'blue.50',
	[ProductType.CASH]: 'orange.50',
};

const productToTitle = (data: ProductSubscriptionType): string | JSX.Element => {
	switch (data.typeProject) {
		case ProductType.CASH:
			return (
				<HStack>
					<Image src={icons[data.type]} />
					<Heading variant="Title-L-Bold">{savingsAccountTypeLabels[data.type]}</Heading>
				</HStack>
			);
		case ProductType.INVEST:
			return (
				<HStack>
					<Image src={icons[data.envelope.type]} />
					<Heading variant="Title-L-Bold">{data.envelope.type === 'AV' ? 'Assurance vie' : 'PER Individuel'}</Heading>
				</HStack>
			);
		case ProductType.PE:
		case ProductType.SCPI:
			return data.fundName;
	}
};

const productToHeading = (data: ProductSubscriptionType): string => {
	switch (data.typeProject) {
		case ProductType.CASH:
			return 'Cash Ramify';
		case ProductType.INVEST:
			return 'Invest Ramify';
		case ProductType.PE:
		case ProductType.SCPI:
			return data.fundPartnerName ?? '';
	}
};

export type StepProps = {
	current: number;
	index: number;
	title: string;
	description: string;
	tooltip?: ReactNode;
	last: boolean;
	show?: boolean;
};

interface SubscriptionStepsProps {
	current: number;
	steps: SubscriptionStep<SavingsAccountStatus | FundSubscriptionStatus | null>[];
	showInnerSteps?: boolean;
}

const SubscriptionSteps = ({ steps, current, showInnerSteps = false }: SubscriptionStepsProps) => (
	<VStack align="start" maxW="400px">
		{steps.map(({ details: { label, description, tooltip } }, index, array) => (
			<Step
				key={label}
				title={label}
				tooltip={tooltip}
				description={description}
				current={current}
				index={index}
				last={index === array.length - 1}
				show={showInnerSteps}
			/>
		))}
	</VStack>
);

const CurrentSubscriptionDetails: FC<ProductSubscriptionType> = (data): JSX.Element => {
	const timeHorizonPlan = useMemo(
		() => ('timeHorizon' in data && isNotNone(data.timeHorizon) ? getRoundedTimeHorizon(data.timeHorizon) : undefined),
		[data],
	);
	const navigate = useNavigate();
	const { id } = useParams();

	// use useDrawer
	const { isOpen, onClose } = useDisclosure({
		isOpen: isNotNone(id) && data.id === id, // listen for param on url
		onClose: () => navigate('..', { preventScrollReset: true }), // remove the id from the url
	});

	return (
		<Drawer size={{ base: 'full', md: 'xl' }} isOpen={isOpen} onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerHeader>
					<HStack>
						<IconButton
							onClick={onClose}
							variant="icon"
							aria-label="back"
							icon={
								<Box>
									<ArrowLeft size="32" />
								</Box>
							}
						/>
						<Heading variant="Title-M-SemiBold">Suivre ma souscription</Heading>
					</HStack>
				</DrawerHeader>
				<DrawerBody>
					<VStack align="start" spacing="48px">
						<HeaderCard
							heading={productToHeading(data)}
							title={productToTitle(data)}
							type={data.typeProject}
							picture={'fundCoverPicture' in data ? data.fundCoverPicture : undefined}
							info={
								data.typeProject === ProductType.INVEST ? (
									<Text variant="Text-M-Medium">
										{data.investmentPreferences?.portfolioType && (
											<>{displayCapitalized(data.investmentPreferences.portfolioType)} - </>
										)}
										{data.investmentPreferences?.risk && <>Risque {data.investmentPreferences.risk} - </>}
										{data.investmentPreferences?.temperature && (
											<>Ramify Green {data.investmentPreferences.temperature} °C - </>
										)}
										{timeHorizonPlan && <>Horizon {timeHorizonPlan.label}</>}
									</Text>
								) : null
							}
						/>
						<Heading variant="Title-M-SemiBold">
							{data.typeProject === ProductType.CASH ? 'Mon épargne' : 'Mon investissement'}
						</Heading>
						<Wrap w="100%">
							<WrapItem w="250px">
								<InvestmentMetricCard
									title={data.typeProject === ProductType.CASH ? 'Montant épargné' : 'Montant investi'}
									icon={
										data.typeProject === ProductType.CASH ? <PiggyBankSlot size="32" /> : <TextLinkAnalysis size="32" />
									}
								>
									<Heading variant="Title-L-Bold">
										{data.typeProject === ProductType.PE || data.typeProject === ProductType.SCPI
											? displayMoney(data.amountVersement)
											: data.typeProject === ProductType.CASH
											? displayMoney(data.initialAUM!)
											: displayMoney(data.initialDepositAmount)}
									</Heading>
								</InvestmentMetricCard>
							</WrapItem>
							{data.typeProject === ProductType.SCPI && (
								<WrapItem w="250px">
									<InvestmentMetricCard title="Nombre de parts" icon={<Diagram size="32" />}>
										<Heading variant="Title-L-Bold">{data.nbShare.toString()}</Heading>
									</InvestmentMetricCard>
								</WrapItem>
							)}
							{data.typeProject === ProductType.INVEST && data.recurrentDepositAmount && (
								<WrapItem w="250px">
									<InvestmentMetricCard title="Versements programmés" icon={<BuildingInsights_2 size="32" />}>
										<Heading variant="Title-L-Bold">{displayMoney(data.recurrentDepositAmount)} / mois</Heading>
									</InvestmentMetricCard>
								</WrapItem>
							)}
							{data.typeProject === ProductType.SCPI && (
								<WrapItem w="250px">
									<InvestmentMetricCard title="Type" icon={<BuildingInsights_2 size="32" />}>
										<Heading variant="Title-L-Bold">
											{data.projectType === 'NUE_PROPRIETE' ? 'Nue propriété' : 'Plein propriété'}
										</Heading>
									</InvestmentMetricCard>
								</WrapItem>
							)}
						</Wrap>
						<Heading variant="Title-M-SemiBold">Suivi de ma souscription</Heading>
						<SubscriptionSteps
							showInnerSteps={data.typeProject === ProductType.INVEST}
							steps={
								productSubscriptionSteps(
									(data as SCPISubscription | PrivateEquitySubscription)?.fundName ?? '',
									data.status,
								)[data.typeProject as Exclude<ProductType, ProductType.INVEST>]
							}
							current={productSubscriptionSteps(
								(data as SCPISubscription | PrivateEquitySubscription)?.fundName ?? '',
								data.status,
							)[data.typeProject as Exclude<ProductType, ProductType.INVEST>].findIndex(
								(step) => step.value === data.status,
							)}
						/>

						<SubscriptionDocuments subscription={data} />
					</VStack>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default CurrentSubscriptionDetails;
