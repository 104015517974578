import { PEFundTaxAdvantageType } from 'pages/PrivateEquity/Discover/options.constants';
import { PEFund, SCPIFund } from 'store/types/airtable.type';

type PEFundAttributeType = keyof Pick<PEFund, 'partner' | 'sectors' | 'strategy' | 'regions' | 'fundOpen' | 'FundType'>;

export type SCPIFilterType = {
	name: string;
	sorting: string;
	maxTicket: number;
	tags: string[];
	sectors: string[];
	geographies: string[];
};

export type SCPIFilterKeyType = keyof Pick<SCPIFilterType, 'geographies' | 'sectors' | 'tags'>;

export type PEFilterType = {
	name: string;
	sorting: string;
	maxTicket: number;
	taxAdvantages: string[];
	sectors: string[];
	strategies: string[];
	geographies: string[];
	status: string[];
	type: string[];
};

export type PEFilterKeyType = keyof Pick<
	PEFilterType,
	'taxAdvantages' | 'sectors' | 'geographies' | 'status' | 'strategies' | 'type'
>;

export const filterByName = <T extends SCPIFund | PEFund>(funds: T[], nameFilter: string): T[] => {
	return funds.filter((fund) => {
		const nameMatch = fund.name.toLowerCase().includes(nameFilter.toLowerCase());
		const partnerMatch = fund.partner.toLowerCase().includes(nameFilter.toLowerCase());
		return nameMatch || partnerMatch;
	});
};

export const filterByTicketMinimum = <T extends SCPIFund | PEFund>(
	newFilteredFunds: T[],
	filterChoice: SCPIFilterType | PEFilterType,
): T[] => {
	if (filterChoice.maxTicket > 0) {
		return newFilteredFunds?.filter((fund) => {
			if ('ticket' in fund) return fund.ticket <= filterChoice.maxTicket;
			const ticket = fund.lastShareValuation * fund.minPPShares;
			return ticket <= filterChoice.maxTicket;
		});
	}
	return newFilteredFunds;
};

export const filterByTags = (newFilteredFunds: SCPIFund[], filterChoice: SCPIFilterType) => {
	if (filterChoice.tags.length > 0) {
		return newFilteredFunds?.filter((fund) => filterChoice.tags.every((tag) => fund.tags?.includes(tag)));
	}
	return newFilteredFunds;
};

export const filterBySector = (newFilteredFunds: SCPIFund[], filterChoice: SCPIFilterType) => {
	if (filterChoice.sectors.length > 0) {
		return newFilteredFunds?.filter((fund) =>
			filterChoice.sectors
				.map((s) => s.toLowerCase())
				.some((sector) => fund.sectors?.map((s) => s.toLowerCase()).includes(sector)),
		);
	}
	return newFilteredFunds;
};

export const filterByGeography = (newFilteredFunds: SCPIFund[], filterChoice: SCPIFilterType) => {
	if (filterChoice.geographies.length > 0) {
		return newFilteredFunds?.filter((fund) =>
			filterChoice.geographies
				.map((g) => g.toLowerCase())
				.some((geography) => fund.regions.map((g) => g.toLowerCase()).includes(geography)),
		);
	}
	return newFilteredFunds;
};

export const filterByTaxAdvantages = (newFilteredFunds: PEFund[], filterChoice: PEFilterType) => {
	if (filterChoice.taxAdvantages.length > 0) {
		return newFilteredFunds?.filter((fund) => {
			return filterChoice.taxAdvantages.every((taxAdvantage) => {
				if (taxAdvantage === 'noRevenueTaxAdvantage') return !fund.revenueTaxAdvantage;
				return fund[taxAdvantage as PEFundTaxAdvantageType];
			});
		});
	}
	return newFilteredFunds;
};

export const filterByCategory = (
	category: PEFilterKeyType,
	attribute: PEFundAttributeType,
	newFilteredFunds: PEFund[],
	filterChoice: PEFilterType,
) => {
	if (filterChoice[category].length > 0) {
		return newFilteredFunds?.filter((fund) => filterChoice[category].some((elem) => fund[attribute]?.includes(elem)));
	}
	return newFilteredFunds;
};

export const filterByOpenStatus = (
	category: PEFilterKeyType,
	attribute: PEFundAttributeType,
	newFilteredFunds: PEFund[],
	filterChoice: PEFilterType,
) => {
	if (filterChoice[category].length > 0) {
		return newFilteredFunds?.filter((fund) =>
			filterChoice[category].some((elem: string) => {
				return (
					(fund[attribute] === 'Oui' && elem === 'Fonds ouverts') ||
					(fund[attribute] === 'Non' && elem === 'Fonds fermés')
				);
			}),
		);
	}
	return newFilteredFunds;
};
