import { FC, useEffect, useState } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { Button, useDisclosure, VStack } from '@chakra-ui/react';

import { AddBankAccountModal } from 'components/bankingInformations/AddBankAccountModal';
import { BankAccountDetailsCard } from 'components/bankingInformations/BankAccountDetailsCard';
import { DeleteBankAccountModal } from 'components/bankingInformations/DeleteBankAccountModal';
import FullscreenLoader from 'components/FullscreenLoader';
import { useGetBankAccountsQuery } from 'services/requests/bank';
import { useGetContractsQuery } from 'services/requests/invest/contracts';
import { BankInformations } from 'store/types/subscription.type';

export type AccountUsage = {
	usage: string;
	type: 'PER' | 'AV';
	programmedVersement: boolean;
};

type SelectedAccount = { bankAccount: BankInformations; accountUsage: AccountUsage[] | null };

const BankAccountManager: FC = () => {
	const [selectedAccount, setSelectedAccount] = useState<SelectedAccount | null>(null);
	const [accountsUsage, setAccountsUsage] = useState<(AccountUsage[] | null)[] | null>(null);

	const { data: bankAccounts, isLoading } = useGetBankAccountsQuery();
	const { data: contracts } = useGetContractsQuery();

	const { isOpen: isAddModalOpen, onOpen: onAddModalOpen, onClose: onAddModalClose } = useDisclosure();
	const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();

	useEffect(() => {
		if (contracts && bankAccounts) {
			setAccountsUsage(
				bankAccounts.map((bankAccount) => {
					const contractsWithThisAccount = contracts.filter(
						(contract) => contract.bankInformationId === bankAccount.id,
					);

					return contractsWithThisAccount.length > 0
						? contractsWithThisAccount.map((contract) => ({
								usage: 'contract',
								type: contract.envelope.type,
								programmedVersement: !!contract.programmedVersement,
						  }))
						: null;
				}),
			);
		}
	}, [contracts, bankAccounts]);

	if (isLoading && !contracts) return <FullscreenLoader />;

	return (
		<>
			{selectedAccount && (
				<DeleteBankAccountModal
					accountDetails={selectedAccount}
					isOpen={isDeleteModalOpen}
					onClose={() => {
						setSelectedAccount(null);
						onDeleteModalClose();
					}}
				/>
			)}

			<VStack spacing="32px" align="start" w="100%" maxW="720px" alignSelf="center">
				<AddBankAccountModal isOpen={isAddModalOpen} onClose={onAddModalClose} />

				<VStack align="start" w="100%">
					{bankAccounts &&
						bankAccounts?.map((bankAccount, idx) => (
							<BankAccountDetailsCard
								key={bankAccount.id}
								accountDetails={bankAccount}
								accountUsage={accountsUsage?.at(idx) ?? null}
								onDelete={(accountUsage) => {
									setSelectedAccount({ bankAccount, accountUsage });
									onDeleteModalOpen();
								}}
							/>
						))}
				</VStack>

				<VStack align="end" w="100%">
					<Button variant="primary" mb="24px" rightIcon={<AddIcon />} onClick={onAddModalOpen}>
						Ajouter un compte
					</Button>
				</VStack>
			</VStack>
		</>
	);
};

export default BankAccountManager;
