import React, { Dispatch } from 'react';
import { Card, VStack } from '@chakra-ui/react';
import { ParentSize } from '@visx/responsive';

import FilterSelect from 'components/FilterSelect';
import TooltipGraphMobile from 'components/Graphs/visx/ToolTipGraphMobile';
import { useAppResponsive } from 'hooks/useAppResponsive';
import { useAppSelector } from 'hooks/useStore';
import Line from 'pages/Invest/Dashboard/sections/Graph/Line';
import { LoadingPerformance, PlaceholderPerformances } from 'pages/Invest/Dashboard/sections/Graph/LoadingPerformances';
import { FilterPeriode } from 'pages/Invest/Dashboard/sections/Graph/Performances';
import { useGetInvestmentHistoryQuery } from 'services/requests/invest/contracts';
import { displayMoney } from 'utils/rendering';

const PerformanceGraph = () => {
	const { inactiveList } = useAppSelector((state) => state.investContractFilter);
	const { data, isLoading } = useGetInvestmentHistoryQuery(inactiveList);

	const [selectedFilter, setSelectedFilter] = React.useState<FilterPeriode>(FilterPeriode.all);
	const isMobile = useAppResponsive({ base: true, xl: false }) || false;
	const [currentValue, setCurrentValue] = React.useState<{
		date: Date;
		valuation: number;
		montantVersements: number;
	}>();

	if (isLoading || !data) return <LoadingPerformance />;
	if (!data.success)
		return <PlaceholderPerformances message={data?.message || "Votre historique n'est pas encore disponible"} />;

	const today = new Date();
	const oneWeekAgo = new Date();
	oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
	const oneMonthAgo = new Date();
	oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
	const threeMonthsAgo = new Date();
	threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
	const sixMonthsAgo = new Date();
	sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
	const oneYearAgo = new Date();
	oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
	const startOfYear = new Date(today.getFullYear(), 0, 1);

	const oldestDate = new Date(data.data[0].date);
	const yearsData = [
		{ label: '1 semaine', labelValue: FilterPeriode.oneWeek, firstDate: oneWeekAgo },
		{ label: '1 mois', labelValue: FilterPeriode.oneMonth, firstDate: oneMonthAgo },
		{ label: '3 mois', labelValue: FilterPeriode.threeMonths, firstDate: threeMonthsAgo },
		{ label: '6 mois', labelValue: FilterPeriode.sixMonths, firstDate: sixMonthsAgo },
		{ label: '1 an', labelValue: FilterPeriode.oneYear, firstDate: oneYearAgo },
		{ label: '1er janvier', labelValue: FilterPeriode.YearToDate, firstDate: startOfYear },
		{ label: 'Tout', labelValue: FilterPeriode.all, firstDate: undefined },
	].filter((year) => !year.firstDate || year.firstDate > oldestDate);

	return (
		<Card w="100%" h="400px" p="12px" borderColor="transparent">
			<VStack w="100%" spacing="24px" align="start" minH={isMobile ? '163px' : 'Opx'}>
				<FilterSelect
					data={yearsData}
					label={{ filterValue: selectedFilter, setFilterValue: setSelectedFilter as Dispatch<unknown> }}
				/>

				{isMobile && currentValue && (
					<TooltipGraphMobile
						tooltipProps={[
							{ title: 'Capital :', value: displayMoney(currentValue?.valuation ?? 0) },
							{
								title: 'Date :',
								value: new Date(currentValue?.date).toLocaleDateString('fr-FR', {
									year: 'numeric',
									month: 'long',
									day: 'numeric',
								}),
							},
						]}
					/>
				)}
			</VStack>

			<ParentSize>
				{({ width, height }) => (
					<Line
						width={width}
						height={height}
						data={data}
						firstDate={yearsData.find((y) => selectedFilter === y.labelValue)?.firstDate}
						setCurrentValue={setCurrentValue}
					/>
				)}
			</ParentSize>
		</Card>
	);
};
export default PerformanceGraph;
