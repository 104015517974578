import colors from 'theme/foundations/colors';

const PinInput = {
	variants: {
		default: {
			// border: `1px solid ${colors.grey[500]}`,
			// background: colors.grey[100],
			border: `1px solid ${colors.grey[900]}`,
			background: 'white',
			borderRadius: '4px',
			// _placeholder: {
			// 	background: colors.grey[900],
			// 	width: '6px',
			// 	height: '6px',
			// 	borderRadius: 'full',
			// 	position: 'absolute',
			// 	left: '50%',
			// 	top: '50%',
			// 	transform: `translate(-50%, -50%)`,
			// },
			_focus: {
				// borderColor: colors.warning[900],
				borderColor: colors.primary.yellow,
			},
			_invalid: {
				borderColor: colors.negative[900],
			},
			_disabled: {
				backgroundColor: 'grey.300',
				cursor: 'not-allowed',
				_placeholder: {
					color: colors.primary.black,
				},
			},
		},
	},
	// border: `1px solid ${colors.grey[700]}`,
	defaultProps: {
		size: 'md',
		variant: 'default',
	},
};

export default PinInput;
