import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Card, HStack, Slider, SliderThumb, SliderTrack, Text, useToken, VStack } from '@chakra-ui/react';

import { TemperatureSliderMark } from 'components/Graphs/SliderMark';
import { useTemperatureDisplay } from 'hooks/useTemperatureDisplay';
import colors from 'theme/foundations/colors';
import { isNone, isNotNone } from 'utils/functions';
import { temperatureMax, temperatureMin } from 'utils/rendering/temperatureDisplay';

import { TemperatureSliderButton } from './SliderButton';

const TemperatureSlider = ({
	value,
	onChange,
}: {
	value: number | null | undefined;
	onChange: (value: number) => void;
}): JSX.Element => {
	const dTempMin = useTemperatureDisplay(temperatureMin);
	// const dTempMedium = useTemperatureDisplay(temperatureMid);
	const dTempMax = useTemperatureDisplay(temperatureMax);
	const dTempActual = useTemperatureDisplay(value);
	const [posTriangle, setPosTriangle] = useState(0);

	const lowTempRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
	// const mediumTempRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
	const highRiskRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

	const sliderThumbRectRef = useRef() as React.MutableRefObject<HTMLDivElement>;

	const handleTextArrowPos = useCallback(() => {
		if (
			lowTempRef.current == null ||
			// || mediumTempRef.current == null
			highRiskRef.current == null
		)
			return;

		const parentElement = sliderThumbRectRef.current.offsetParent as HTMLDivElement;

		if (value === dTempMin.value) {
			setPosTriangle(lowTempRef.current.offsetLeft + lowTempRef.current.offsetWidth / 2 - parentElement.offsetLeft);
		}
		// if (value === dTempMedium.value) {
		// 	setPosTriangle(
		// 		mediumTempRef.current.offsetLeft + mediumTempRef.current.offsetWidth / 2 - parentElement.offsetLeft,
		// 	);
		// }
		if (value === dTempMax.value) {
			setPosTriangle(highRiskRef.current.offsetLeft + highRiskRef.current.offsetWidth / 2 - parentElement.offsetLeft);
		}
	}, [
		value,
		lowTempRef,
		// mediumTempRef,
		highRiskRef,
		dTempMin,
		// dTempMedium,
		dTempMax,
		sliderThumbRectRef,
	]);

	useEffect(() => {
		handleTextArrowPos();
		window.addEventListener('resize', handleTextArrowPos, false);
		return () => window.removeEventListener('resize', handleTextArrowPos, false);
	}, [dTempActual, handleTextArrowPos]);

	const [deepBlue, lightBlue, lightRed, deepRed] = useToken('colors', ['blue.900', 'blue.500', 'red.300', 'red.500']);

	const thumbColor = useMemo(() => {
		// if (value === temperatureMin) return deepBlue;
		if (value === temperatureMin) return lightBlue; // use `temperatureMid` when we change min to 1.5
		return deepRed;
	}, [value, lightBlue, deepRed]);

	return (
		<VStack align="center" spacing="24px" w="100%">
			<VStack pt="24px" w="100%" spacing="24px">
				<Slider
					w="100%"
					// use `temperatureMin` when we change it to 1.5
					min={1.5 - 0.05}
					max={temperatureMax + 0.05}
					step={0.5}
					value={value ?? undefined}
					onChange={(v) => {
						// clamp the value
						onChange(Math.min(Math.max(v, temperatureMin), temperatureMax));
					}}
				>
					<SliderTrack
						bgGradient={`linear-gradient(90deg, ${deepBlue} -0.17%, ${lightBlue} 45.66%, ${lightRed} 69.1%, ${deepRed} 99.83%)`}
						h={{ base: '12px', md: '6px' }}
					/>
					<TemperatureSliderMark value={temperatureMin} />
					{/* <TemperatureSliderMark value={temperatureMid} /> */}
					<TemperatureSliderMark value={temperatureMax} />

					<SliderThumb
						ref={sliderThumbRectRef}
						w={{ base: 8, md: 6 }}
						h={{ base: 8, md: 6 }}
						bg={thumbColor}
						visibility={isNone(value) ? 'hidden' : 'visible'}
						border="2px"
						borderColor="#fff"
						_focus={{ boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.25)` }}
					/>
				</Slider>
				<HStack w="100%" spacing="12px" justify="space-between">
					<TemperatureSliderButton
						ref={lowTempRef}
						selected={value === temperatureMin}
						onClick={() => onChange(dTempMin.value)}
						name={dTempMin.name}
					/>
					{/* <TemperatureSliderButton
						ref={mediumTempRef}
						selected={value === temperatureMid}
						onClick={() => onChange(dTempMedium.value)}
						name={dTempMedium.name}
					/> */}
					<TemperatureSliderButton
						ref={highRiskRef}
						selected={value === temperatureMax}
						onClick={() => onChange(dTempMax.value)}
						name={dTempMax.name}
					/>
				</HStack>
			</VStack>
			{isNotNone(value) && (
				<Card position="relative" alignSelf="flex-start" w="100%" bg="primary.black" size="sm">
					<Box
						h={0}
						w={0}
						top="-13px"
						position="absolute"
						left={`${posTriangle - 12}px`}
						transition="0.3s all ease-in"
						borderLeft="18px solid transparent"
						borderRight="18px solid transparent"
						borderBottom={`14px solid ${colors.primary.black}`}
					/>
					<Text variant="Text-S-Regular" color="white">
						{dTempActual.description}
					</Text>
				</Card>
			)}
		</VStack>
	);
};

export default TemperatureSlider;
