import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	Button,
	Card,
	chakra,
	Heading,
	Hide,
	HStack,
	Show,
	Stack,
	Text,
	useDisclosure,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';

import InformationBanner from 'components/Banners/InformationBanner';
import FullscreenLoader from 'components/FullscreenLoader';
import ProgrammedVersementCard from 'components/versements/ProgrammedVersementCard';
import useThemedToast from 'hooks/useThemedToast';
import CreateVersement from 'pages/Invest/Versements/CreateVersement';
import eventTracker from 'services/events/eventTracker';
import { useGetContractsQuery } from 'services/requests/invest/contracts';
import { useGetUserQuery } from 'services/requests/user';
import { useIsVersementEligibleQuery } from 'services/requests/versement';
import { PopulatedInvestContract } from 'store/types/contract.type';
import api from 'utils/api';
import { typeFormConstants } from 'utils/constants';
import { isNone } from 'utils/functions';

import CreateOptimizedVersement from './CreateOptimizedVersement';
import DeleteVP from './DeleteVP';
import VersementHistory from './History';
import DepositOperations from './Operations';
import OptimizationInfoCard from './OptimizationCard';
import UpdateVP from './UpdateVP';

const Versements = (): JSX.Element => {
	const toast = useThemedToast();
	const location = useLocation();
	const { data: user } = useGetUserQuery();
	const { data: contracts } = useGetContractsQuery();
	const [selectedContract, setSelectedContract] = useState<PopulatedInvestContract>();

	const { isOpen: isCreateOptiOpen, onOpen: onCreateOptiOpen, onClose: onCreateOptiClose } = useDisclosure();
	const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
	const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
	const { isOpen: isUpdateOpen, onOpen: onUpdateOpen, onClose: onUpdateClose } = useDisclosure();

	const requestTransfertContract = useCallback(() => {
		api.getOperationToken('transfert').then((token) => {
			const query = new URLSearchParams({ email: user!.email, token });
			eventTracker.askPERTransfer();
			window.open(`${typeFormConstants.transfertUrl}#${query}`, '_blank');
		});
	}, [user]);

	const { search } = useLocation();
	useEffect(() => {
		const params = new URLSearchParams(search);
		const getOperationOpen = params.get('open');
		if (getOperationOpen === 'nouveau-versement') onCreateOpen();
		if (getOperationOpen === 'modifier-versement') onUpdateOpen();
		if (getOperationOpen === 'supprimer-versement') onDeleteOpen();
		if (getOperationOpen === 'transfert') requestTransfertContract();
	}, [search, onCreateOpen, onUpdateOpen, onDeleteOpen, requestTransfertContract]);

	const { data: versementEligibility } = useIsVersementEligibleQuery(
		{
			contractId: selectedContract?.id ?? '',
			type: 'PROGRAMME',
		},
		{ skip: isNone(selectedContract) },
	);

	if (!contracts) return <FullscreenLoader />;
	const hasPER = contracts.some((c) => c.envelope.type === 'PER');

	// Create Versement Programmé
	const onClickCreateVp = (contract: PopulatedInvestContract) => {
		eventTracker.pipedream.versementInterest();
		setSelectedContract(contract);
		onCreateOpen();
	};
	// Create Versement Complémentaire (Ponctuel)
	const onClickCreateVc = () => {
		eventTracker.pipedream.versementInterest();
		onCreateOpen();
	};
	// Create Optimized Versement Programmé (complex modal)
	const onClickCreateOptimizedVersement = () => {
		eventTracker.pipedream.versementInterest();
		onCreateOptiOpen();
	};

	const onCreateVersementSuccess = () => {
		toast({
			status: 'success',
			title: 'Demande signée',
			description: 'Votre demande de versement a été prise en compte, nous la traitons dans les plus brefs délais',
		});
		onCreateClose();
	};

	// Update VP
	const onClickUpdateVp = (contract: PopulatedInvestContract) => {
		eventTracker.pipedream.versementInterest();
		setSelectedContract(contract);
		onUpdateOpen();
	};
	const onUpdateVpSuccess = () => {
		toast({
			status: 'success',
			title: 'Demande signée',
			description: 'Votre demande de modification a été prise en compte, nous la traitons dans les plus brefs délais',
		});
		onUpdateClose();
	};

	// Delete VP
	const onClickDeleteVp = (contract: PopulatedInvestContract) => {
		eventTracker.pipedream.versementInterest();
		setSelectedContract(contract);
		onDeleteOpen();
	};
	const onDeleteVpSuccess = () => {
		toast({
			status: 'success',
			title: 'Demande signée',
			description: 'Votre demande de suppression a été prise en compte, nous la traitons dans les plus brefs délais',
		});
		onDeleteClose();
	};

	return (
		<VStack align="start" w="100%" spacing="40px">
			{location.state && (location.state as { isRecommandationVpActive: boolean }).isRecommandationVpActive && (
				<OptimizationInfoCard />
			)}

			<DepositOperations />

			<VStack align="start" spacing="18px" w="100%">
				<VStack w="100%" align="start" spacing="16px">
					<Wrap justify="space-between" w="100%" align="center">
						<WrapItem>
							<Heading variant="Title-L-SemiBold">Versements Programmés</Heading>
						</WrapItem>
						{contracts.map((contract) => contract.programmedVersement).every((vp) => !vp) && (
							<WrapItem>
								<Button variant="secondary" onClick={onClickCreateOptimizedVersement}>
									Optimiser mes versements
								</Button>
							</WrapItem>
						)}
					</Wrap>

					<Show above="md">
						<InformationBanner description="Les versements programmés sont prélevés sur votre compte bancaire entre le 10 et le 12 de chaque mois." />
					</Show>
				</VStack>

				{contracts?.map((contract) => (
					<ProgrammedVersementCard
						key={contract.id}
						montant={contract.programmedVersement?.amount}
						type={contract.envelope.type}
						onClickCreate={() => onClickCreateVp(contract)}
						onClickModify={() => onClickUpdateVp(contract)}
						onClickDelete={() => onClickDeleteVp(contract)}
						startDate={contract.programmedVersement?.startDate}
						contractAmount={contract.amount}
					/>
				))}
			</VStack>

			{/* modals. position absolute, so position don't matter */}
			{isCreateOptiOpen && (
				<CreateOptimizedVersement
					isOpen={isCreateOptiOpen}
					onClose={onCreateOptiClose}
					versementEligibility={versementEligibility}
				/>
			)}
			{isCreateOpen && (
				<CreateVersement
					onSuccess={onCreateVersementSuccess}
					onClose={onCreateClose}
					vpSelectedContract={selectedContract}
				/>
			)}
			{isDeleteOpen && selectedContract && (
				<DeleteVP onSuccess={onDeleteVpSuccess} onClose={onDeleteClose} contract={selectedContract} />
			)}
			{isUpdateOpen && selectedContract && (
				<UpdateVP onSuccess={onUpdateVpSuccess} onClose={onUpdateClose} contract={selectedContract} />
			)}

			{hasPER && (
				<Card variant="yellow">
					<Stack direction={{ base: 'column', md: 'row' }} spacing="24px" align={{ base: 'start', md: 'center' }}>
						<Text variant="body" color="grey.600">
							Vous possédez un contrat{' '}
							<chakra.b fontWeight={600}>(PER, PERP, Madelin, Art 83, PEROB, PERCO...)</chakra.b> auprès d'un autre
							établissement financier ? Vous souhaitez{' '}
							<chakra.b fontWeight={600}>transférer les fonds de ce contrat chez Ramify</chakra.b> ? Vous pouvez le
							faire en remplissant une simple demande de transfert.
						</Text>
						<Button variant="secondary" minW="inherit" onClick={requestTransfertContract}>
							Transférer un contrat
						</Button>
					</Stack>
				</Card>
			)}

			<VStack w="100%" align="start" spacing="12px">
				<HStack w="100%" justify="space-between">
					<Heading variant="Title-L-SemiBold">Historique des versements</Heading>
					<Button onClick={onClickCreateVc} size={{ base: 'xl', md: 'md' }}>
						<Show above="md">Faire un versement</Show>
						<Hide above="md">Investir</Hide>
					</Button>
				</HStack>

				<VersementHistory />
			</VStack>
		</VStack>
	);
};

export default Versements;
