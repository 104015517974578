import { useMemo } from 'react';
import { useToken } from '@chakra-ui/react';

import { KYC } from 'store/types/KYC.type';
import { User } from 'store/types/user.type';

const calendlyUrls = {
	100_000: 'https://calendly.com/d/hxd-ppp-c78/echange-avec-un-conseiller-dedie-ramify',
	20_000: 'https://calendly.com/d/dt5-wj5-rk3/echange-avec-un-conseiller-prive-ramify',
	UNKNOWN: 'https://calendly.com/d/dvk-jm7-7d9/echange-avec-un-conseiller-ramify',
};

export enum IntestedProducts {
	'AV' = 1,
	'PER',
	'SCPI',
	'PE',
	'CASH',
	'UNKNOWN',
}

export const useBuildCalendlyUrl = (
	amount: number,
	product: IntestedProducts | undefined,
	user: User | undefined,
	kyc: KYC | undefined,
) => {
	const url = useMemo(() => {
		if (amount >= 100_000) return calendlyUrls[100_000];
		if (amount >= 20_000) return calendlyUrls[20_000];
		return calendlyUrls.UNKNOWN;
	}, [amount]);

	const [primaryColor, textColor] = useToken('colors', ['yellow.500', 'primary.black']);

	const search = useMemo(() => {
		const searchParamBuild = new URLSearchParams({
			// used to communicate with calendly that this is an embed
			embed_type: 'PopupWidget',
			embed_domain: '1',
			primary_color: primaryColor.replace('#', ''),
			text_color: textColor.replace('#', ''),
		});
		if (kyc) {
			searchParamBuild.append('name', `${kyc.firstName} ${kyc.lastName}`);
			searchParamBuild.append('a1', `${kyc.phoneNumberCode?.replace('00', '+')} ${kyc.phoneNumber}`); // Numéro de téléphone
		}
		if (user) {
			searchParamBuild.append('email', user.email);
		}
		if (product) {
			searchParamBuild.append('a2', product.toString()); // Quel produit vous intéresse ?
		}
		return searchParamBuild;
	}, [kyc, primaryColor, product, textColor, user]);

	return new URL(`${url}?${search}`).toString();
};
