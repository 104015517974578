import { FC, PropsWithChildren, ReactNode } from 'react';
import { Card, CardProps, HStack, StackProps, Text, VStack } from '@chakra-ui/react';

import CustomTooltip from 'components/CustomTooltip';
import colors from 'theme/foundations/colors';

interface InvestmentMetricCardProps extends CardProps {
	icon: React.ReactNode;
	title: string;
	spacing?: StackProps['spacing'];
	cta?: ReactNode;
	tooltip?: string;
}

const InvestmentMetricCard: FC<PropsWithChildren<InvestmentMetricCardProps>> = ({
	icon,
	title,
	spacing = 'sm',
	children,
	cta,
	tooltip,
	...rest
}) => {
	return (
		<Card w="100%" h="100%" {...rest}>
			<VStack w="100%" align="flex-start" spacing={spacing}>
				<HStack justify="space-between" w="100%">
					{icon}
					{cta}
				</HStack>
				<HStack>
					<Text color={colors.grey[900]} variant="Text-M-Medium">
						{title}
					</Text>
					{tooltip && <CustomTooltip text={tooltip} />}
				</HStack>
				{children}
			</VStack>
		</Card>
	);
};
export default InvestmentMetricCard;
