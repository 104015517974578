import { SavingsAccountInformation } from 'store/types/savingsAccount.type';

import { PrivateEquitySubscription, SCPISubscription } from './airtable.type';
import { PopulatedInvestSubscription } from './subscription.type';

export type EnvelopeType = 'PER' | 'AV';
export type VehiculeCodeApicil =
	| 'P2' // AV
	| 'IL' // PER
	| 'SV09'; // LUX Hybrid

export type ProjectType = 'RETIREMENT' | 'OTHER';

export interface ProgrammedVersement {
	id: string;
	contractId: string;
	amount: string;
	startDate: string;
	period: string;
}

export enum ProductType {
	'INVEST' = 'INVEST',
	'SCPI' = 'SCPI',
	'PE' = 'PE',
	'CASH' = 'CASH',
}

export interface Envelope {
	id: string;
	type: EnvelopeType;
	name: string;
	partnerCode: VehiculeCodeApicil;
}

export type ProductSubscriptionType = (
	| (SCPISubscription & { typeProject: ProductType.SCPI })
	| (PrivateEquitySubscription & { typeProject: ProductType.PE })
	| (PopulatedInvestSubscription & { typeProject: ProductType.INVEST })
	| (SavingsAccountInformation & { typeProject: ProductType.CASH })
) & {
	fundCoverPicture?: string;
};
