import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import onboardingReducer from 'onboarding/onboarding.slice';
import investContractFilterReducer from 'pages/Invest/features/ContractFilter/ContractFilter.slice';
import PEFiltersReducer from 'pages/PrivateEquity/PEFilters.slice';
import SCPIFiltersReducer from 'pages/RealEstate/SCPIFilters.slice';
import { backendApi } from 'services/apiService';

import authReducer, { logout } from './auth.slice';

const onBoardingPersistConfig = {
	key: 'onboarding',
	storage,
	whitelist: [],
};

const reducers = combineReducers({
	// RTK Query
	[backendApi.reducerPath]: backendApi.reducer,

	// Redux Slices
	onboarding: persistReducer(onBoardingPersistConfig, onboardingReducer),
	auth: authReducer,
	investContractFilter: investContractFilterReducer,
	scpiFilters: SCPIFiltersReducer,
	peFilters: PEFiltersReducer,
});

// reset store on logout
// @ts-ignore
const rootReducer = (state, action) => {
	if (logout.match(action)) {
		// eslint-disable-next-line no-param-reassign
		state = undefined;
	}
	return reducers(state, action);
};

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [],
	blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(backendApi.middleware),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { persistor, store };
