import { createRef, PropsWithChildren, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Image,
	Input,
	Stack,
	Text,
	UnorderedList,
	VStack,
} from '@chakra-ui/react';
import { electronicFormatIBAN, friendlyFormatIBAN, validateBIC, validateIBAN } from 'ibantools';

// Assets
import CheckedIllustration from 'assets/souscription/check.svg';
import DropZone from 'components/inputs/Dropzone';
import ErrorFieldMessage from 'components/inputs/ErrorMessage';
import { ValidationTextInput } from 'components/inputs/TextInput';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { useAddBankAccountMutation } from 'services/requests/bank';
import { BankInformations, DonneesBancairesForm } from 'store/types/subscription.type';

const checkIbanValidation = (iban: string): boolean => validateIBAN(electronicFormatIBAN(iban) || undefined).valid;

const CheckedInformation = ({ children }: PropsWithChildren): JSX.Element => (
	<HStack spacing="12px" align="start" alignItems="normal">
		<Image src={CheckedIllustration} alt="check" />
		{children}
	</HStack>
);

const AddBankAccountForm = ({ onSuccess }: { onSuccess: (x: BankInformations) => void }): JSX.Element => {
	const formRef = createRef<HTMLFormElement>();
	const [ibanUpload, setIbanUpload] = useState<File | null>(null);
	const [ibanUploadError, setIbanUploadError] = useState(false);
	const [runAddBankAccount, { isLoading }] = useAddBankAccountMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<DonneesBancairesForm>({ mode: 'onChange' });

	const onSubmit = handleSubmit((formData: DonneesBancairesForm) => {
		if (!ibanUpload) setIbanUploadError(true);
		const requestFormData = new FormData();
		const iban = electronicFormatIBAN(formData.IBAN) as string;

		if (ibanUpload) {
			requestFormData.append('file', ibanUpload);
			requestFormData.append('IBAN', iban);
			requestFormData.append('BIC', formData.BIC.replace(/\s/g, ''));
			requestFormData.append('holder', formData.holder);
			runAddBankAccount(requestFormData).unwrap().then(onSuccess);
		}
	});

	return (
		<chakra.form onSubmit={onSubmit} ref={formRef} w="100%" pb="96px">
			<VStack align="start" spacing="24px" w="100%">
				<FormControl w={onboardingInputWidth} isInvalid={!!errors.holder}>
					<FormLabel>Titulaire du compte</FormLabel>
					<Input data-cy="titulaire" type="text" placeholder="..." {...register('holder', { required: true })} />
					{errors.holder?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
				</FormControl>

				<Stack spacing="32px" align="start" w="100%" direction={{ base: 'column', lg: 'row' }}>
					<VStack align="start" alignItems="flex-start" w="100%">
						<FormControl w={onboardingInputWidth} isInvalid={!!errors.IBAN}>
							<ValidationTextInput
								label="IBAN du compte"
								data-cy="IBAN"
								validate={(str) => checkIbanValidation(str)}
								placeholder="ex : FRXX XXXX XXXX XXXX"
								formatOnChange={friendlyFormatIBAN}
								{...register('IBAN', { required: true, validate: (str) => checkIbanValidation(str) })}
							/>
							{errors.IBAN?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
							{errors.IBAN?.type === 'validate' && <FormErrorMessage>Votre Iban n'est pas reconnu</FormErrorMessage>}
						</FormControl>
					</VStack>
					<VStack align="start" alignItems="flex-start" w="100%">
						<FormControl w={onboardingInputWidth} isInvalid={!!errors.BIC}>
							<ValidationTextInput
								validate={(str) => validateBIC(str).valid}
								label="BIC"
								placeholder="Code BIC de votre banque"
								data-cy="BIC"
								{...register('BIC', {
									required: {
										value: true,
										message: 'Ce champ est nécessaire',
									},

									pattern: {
										value: /^[a-z]{6}[0-9a-z]{2}([0-9a-z]{3})?$/i,
										message: "Le BIC est constitué de 8 ou 11 caractères et ne doit pas contenir d'espaces",
									},
								})}
							/>
							{errors.BIC && <FormErrorMessage>{errors.BIC.message}</FormErrorMessage>}
						</FormControl>
					</VStack>
				</Stack>

				<VStack align="start" spacing="24px">
					<FormControl isInvalid={ibanUploadError}>
						<FormLabel>Justificatif (RIB ou IBAN)</FormLabel>
						<DropZone
							cy="RIB"
							filesUploaded={!!ibanUpload}
							filename={ibanUpload?.name}
							onDrop={(files) => {
								setIbanUploadError(false);
								setIbanUpload(files[0]);
							}}
						/>
					</FormControl>
					{ibanUploadError && <ErrorFieldMessage children="Une photo ou scan de votre IBAN est nécessaire" />}

					<UnorderedList spacing="18px">
						<CheckedInformation>
							<Text variant="body">
								Veuillez joindre un justificatif de votre RIB ou de votre <strong>IBAN</strong>
							</Text>
						</CheckedInformation>
						<CheckedInformation>
							<Text variant="body">
								Votre document doit être <strong>lisible</strong> et <strong>bien cadré</strong>
							</Text>
						</CheckedInformation>
						<CheckedInformation>
							<Text variant="body">Le RIB ou l’IBAN doit être identique à celui renseigné ci-dessus</Text>
						</CheckedInformation>
					</UnorderedList>
				</VStack>
			</VStack>
			<BottomStepBar nextSubmit showPrev={false} isNextLoading={isLoading} />
		</chakra.form>
	);
};

export default AddBankAccountForm;
