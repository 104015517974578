import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Stack, VStack } from '@chakra-ui/react';

import OnboardingProgressBar from 'app/Layout/Onboarding/elements/ProgressBar';
import OnboardingSideBar from 'app/Layout/Onboarding/elements/SideBar';
import ProgressTopBar from 'components/steppers/ProgressTopBar';
import { useAppResponsive } from 'hooks/useAppResponsive';
import Stepper, { StepperProps } from 'onboarding/Stepper/Stepper';
import useOnboardingProgress from 'onboarding/Stepper/useOnboardingProgess';

interface OnboardingLayoutProps extends StepperProps {
	collapsed: boolean;
	setCollapsed: (c: boolean) => void;
	basePath: string;
}

const StepperLayout = ({
	collapsed,
	setCollapsed,
	steps,
	basePath,
	...rest
}: // progressPercentage,
OnboardingLayoutProps): JSX.Element => {
	const { progress } = useOnboardingProgress({ basePath, steps });
	const isDesktop = useAppResponsive({ base: false, lg: true });
	const paddingxMain = `${useAppResponsive({
		base: '24px',
		sm: '64px',
		md: '80px',
		lg: '120px',
		'2xl': '130px',
	})} !important`;

	const paddingyMain = `${useAppResponsive({ base: '16px', md: '32px', lg: '56px' })} !important`;

	useEffect(() => {
		if (!isDesktop) {
			setCollapsed(true);
		}
	}, [isDesktop, setCollapsed]);

	const onboardingStepper = <Stepper setCollapsed={setCollapsed} steps={steps} {...rest} />;

	return (
		<VStack w="100%" spacing="0px">
			<OnboardingProgressBar progressPercentage={progress} />
			<Stack direction={{ base: 'column', lg: 'row' }} w="100%" h="100%" m="0px !important" spacing="0px" align="start">
				{isDesktop ? (
					<OnboardingSideBar>{onboardingStepper}</OnboardingSideBar>
				) : (
					<ProgressTopBar
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						px={paddingxMain}
						py={{ base: '16px', md: '24px' }}
					>
						{onboardingStepper}
					</ProgressTopBar>
				)}
				{/* Route view */}
				<Box as="main" px={paddingxMain} py={paddingyMain} h="100%" w="100%" pb="120px">
					<Outlet />
				</Box>
			</Stack>
		</VStack>
	);
};

export default StepperLayout;
