import { Link } from 'react-router-dom';
import { Button, Heading, List, ListItem, Text, useDisclosure, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import CustomModal from 'components/Modal';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { useAppResponsive } from 'hooks/useAppResponsive';
import ContactInformationsEditable from 'pages/Account/PersonalInformations/ContactInformationsEditable';
import EtatCivilEditable from 'pages/Account/PersonalInformations/EtatCivilEditable';
import PatrimonyEditable from 'pages/Account/PersonalInformations/PatrimonyEditable';
import ProfessionalSituationEditable from 'pages/Account/PersonalInformations/ProfessionalSituationEditable';
import { useGetKYCQuery, useValidateKycMutation } from 'services/requests/kyc';

import { KycStepProps } from '../../utils';

const Recapitulatif = ({ onNext, onPrev }: KycStepProps): JSX.Element => {
	const [triggerValidation] = useValidateKycMutation();
	const { data: kycData, isSuccess: isSuccessGetKyc } = useGetKYCQuery();
	const { isOpen, onClose, onOpen } = useDisclosure();

	const onSubmit = () => {
		triggerValidation()
			.unwrap()
			.then(() => {
				onNext();
			});
	};

	const handleOnNext = () => {
		// pas de step justificatifs pour l'actualisation kyc
		if (location.pathname.startsWith('/actualisation-situation/connaissance-client')) onSubmit();
		else onOpen();
	};

	const textConfirmation = useAppResponsive({ base: 'Confirmer', md: 'Je confirme ces informations' });

	if (!isSuccessGetKyc) return <div>Loading...</div>;

	return (
		<VStack align="start" w="100%" spacing="24px">
			<Heading variant="Title-XL-Bold">Récapitulatif de vos informations</Heading>
			<EtatCivilEditable kyc={kycData} />
			<ProfessionalSituationEditable kyc={kycData} />
			<ContactInformationsEditable kyc={kycData} />
			<PatrimonyEditable kyc={kycData} />

			{isOpen && (
				<CustomModal isCentered isOpen={isOpen} onClose={onClose}>
					<VStack spacing="24px" align="start">
						<Heading variant="Title-L-Bold">
							Avant de passer à l'étape suivante, veuillez confirmer la validité des justificatifs renseignés :
						</Heading>
						<List>
							<ListItem>
								<Text variant="Text-M-Regular">- Justificatif de domicile datant de moins de 3 mois</Text>
							</ListItem>
							<ListItem>
								<Text variant="Text-M-Regular">- Pièce d'identité</Text>
							</ListItem>
						</List>
						<Wrap w="100%" justify="space-between">
							<WrapItem>
								<Link to="../justificatif" replace>
									<Button size="lg" variant="secondary">
										Vérifier mes justificatifs
									</Button>
								</Link>
							</WrapItem>
							<WrapItem>
								<ArrowButton size="lg" variant="primary" onClick={onSubmit}>
									Je confirme ces informations
								</ArrowButton>
							</WrapItem>
						</Wrap>
					</VStack>
				</CustomModal>
			)}

			<BottomStepBar showPrev onPrev={onPrev} showNext onNext={handleOnNext} textNext={textConfirmation} />
		</VStack>
	);
};

export default Recapitulatif;
