import { useMemo } from 'react';
import { ChartPie, Events, Money, Partnership } from '@carbon/icons-react';
import { Button, Heading, HStack, Skeleton, Text, useDisclosure, VStack, Wrap } from '@chakra-ui/react';

import ModalSituationFiscalite from 'pages/Fiscalite/FiscalSituation/ModalFiscalite';
import { useGetImpositionFiscaliteQuery } from 'services/requests/fiscalite';
import { useGetKYCQuery } from 'services/requests/kyc';
import FamilySituationReferentiel, { FamilySituation } from 'store/referentiels/familySituation';
import { IncomePeriod } from 'store/referentiels/incomePeriod';
import { IncomeType } from 'store/referentiels/incomeType';
import { displayMoneyNoDigits } from 'utils/rendering';

const FiscalSituation = (): JSX.Element => {
	const { data, isLoading } = useGetImpositionFiscaliteQuery();
	const { data: kyc } = useGetKYCQuery();
	const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();

	const isFoyerFiscalEmpty =
		kyc?.familySituation === undefined || kyc?.professionalSituation === undefined || kyc?.revenu === undefined;

	const fiscalInformationToDisplay = useMemo(
		() => [
			{
				label: 'Situation familiale',
				metric: FamilySituationReferentiel[kyc?.familySituation as keyof typeof FamilySituation] || '?',
				icon: <Partnership size="32" />,
				isDisplay: true,
			},
			{
				label: kyc
					? `${kyc.revenuType === IncomeType.GROSS ? 'brut' : 'net'} ${
							kyc.revenuPeriod === IncomePeriod.ANNUALLY ? 'annuel' : 'mensuel'
					  }`
					: 'Revenu brut',
				metric: kyc
					? // On pourrait prendre en compte les revenus du conjoint si on convertissait
					  // les 2 revenus sur la même base brut/net mois/année
					  displayMoneyNoDigits(kyc.revenu || 0)
					: '€ / an',
				icon: <Money size="32" />,
				isDisplay: true,
			},
			{
				label: kyc
					? `${kyc.revenuSpouseType === IncomeType.GROSS ? 'brut' : 'net'} ${
							kyc.revenuSpousePeriod === IncomePeriod.ANNUALLY ? 'annuel' : 'mensuel' + ' ' + 'Conjoint'
					  }`
					: 'Revenu brut',
				metric: kyc ? displayMoneyNoDigits(kyc.revenuSpouse || 0) : '€ / an',
				icon: <Money size="32" />,
				isDisplay: kyc?.familySituation === FamilySituation.MARRIED,
			},
			{
				metric: `${kyc?.childNumber || '0'} enfant(s)`,
				label: 'à charge',
				icon: <Events size="32" />,
				isDisplay: true,
			},
			{
				metric: 'Calculé sur vos informations',
				label: `${data?.partsFiscales || ''} parts fiscales`,
				icon: <ChartPie size="32" />,
				isDisplay: true,
			},
		],
		[data?.partsFiscales, kyc],
	);

	if (isLoading && !data) {
		return (
			<VStack w="100%" spacing="16px">
				<Skeleton w="100%" h="170px" />
			</VStack>
		);
	}

	return (
		<VStack w="100%" align="start" spacing="lg">
			<VStack align="start" spacing="md">
				<Heading variant="Title-L-SemiBold">Ma situation fiscale</Heading>
				<Text variant="Text-M-Medium" color="grey.900">
					Voici le détail de votre situation fiscale, telle qu'elle a été renseignée à ce jour sur Ramify. Veuillez la
					mettre jour chaque année ou à chaque changement de situation
				</Text>
			</VStack>

			<Wrap shouldWrapChildren spacing="sm" w="100%" justify="space-between">
				{fiscalInformationToDisplay
					.filter((item) => item.isDisplay)
					.map(({ metric, label, icon }: { metric: string; label: string; icon: JSX.Element }) => (
						<HStack spacing="24px" key={label}>
							{icon}
							<VStack align="start" spacing={0}>
								<Text variant="Text-S-Medium">{metric}</Text>
								<Text variant="Text-M-Medium">{label}</Text>
							</VStack>
						</HStack>
					))}
			</Wrap>

			<VStack align="start" spacing="xs">
				{!isFoyerFiscalEmpty && <Text variant="Text-XS-Medium">L’une de ces informations est incorrecte ?</Text>}
				<Button variant="secondary" onClick={onModalOpen}>
					{isFoyerFiscalEmpty ? 'Renseigner ma situation fiscale' : 'Mettre à jour ma situation fiscale'}
				</Button>
			</VStack>

			{isModalOpen && <ModalSituationFiscalite onClose={onModalClose} isOpen={isModalOpen} />}
		</VStack>
	);
};
export default FiscalSituation;
