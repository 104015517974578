import { Link } from 'react-router-dom';
import { Button, HStack, Image } from '@chakra-ui/react';

import LogoBlack from 'assets/logo/Full Logo.svg';

import { ZIndexOnboardingLayout } from '../constants';

import TopBarMenu from './TopBarMenu';

const TopBar = ({ showMenu }: { showMenu?: boolean }): JSX.Element => (
	<HStack
		w="100%"
		justify="space-between"
		alignItems="center"
		bg="white"
		padding={{ base: '16px 16px', md: '16px 32px' }}
		position="sticky"
		top="0px"
		zIndex={ZIndexOnboardingLayout}
	>
		<Link to="/">
			<Button variant="tertiary" _hover={{ bg: 'grey.100' }} size="lg">
				<Image src={LogoBlack} h="24px" />
			</Button>
		</Link>
		<HStack spacing="24px" alignItems="center">
			<Button
				variant="secondary"
				onClick={() => {
					window.Intercom('showMessages');
				}}
			>
				Besoin d’aide ?
			</Button>

			<TopBarMenu show={showMenu ?? true} />
		</HStack>
	</HStack>
);

export default TopBar;
