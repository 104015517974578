import { useMemo } from 'react';

import { useGetCorporateDashboardQuery } from 'services/requests/corporate';
import { ProductData, useGetProductsDataQuery } from 'services/requests/product';
import { isNotNone } from 'utils/functions';

const isProductOpened = (d: ProductData): boolean => d.status === 'OPENED';

export const useProducts = () => {
	const { data: products, isLoading: isProductsLoading } = useGetProductsDataQuery();
	const { data: corporateInfo, isLoading: isCorporateLoading } = useGetCorporateDashboardQuery();

	const isInvestOpened = useMemo<boolean>(() => isNotNone(products) && isProductOpened(products.invest), [products]);
	const isScpiOpened = useMemo<boolean>(() => isNotNone(products) && isProductOpened(products.scpi), [products]);
	const isPeOpened = useMemo<boolean>(() => isNotNone(products) && isProductOpened(products.pe), [products]);
	const isSavingsOpened = useMemo<boolean>(() => isNotNone(products) && isProductOpened(products.savings), [products]);
	const isCorporateOpened = useMemo<boolean>(
		() => isNotNone(corporateInfo) && corporateInfo.some((c) => isNotNone(c.data)),
		[corporateInfo],
	);

	const isAnyProductOpened = useMemo<boolean>(
		() => isInvestOpened || isScpiOpened || isPeOpened || isCorporateOpened || isSavingsOpened,
		[isInvestOpened, isScpiOpened, isPeOpened, isCorporateOpened, isSavingsOpened],
	);

	const investmentValuation = useMemo(
		() =>
			(products?.invest.valuation ?? 0) +
			(products?.scpi.valuation ?? 0) +
			(products?.pe.valuation ?? 0) +
			(products?.savings.valuation ?? 0),
		[products],
	);

	return {
		invest: {
			isLoading: isProductsLoading,
			isOpen: isInvestOpened,
			data: products?.invest,
		},
		scpi: {
			isLoading: isProductsLoading,
			isOpen: isScpiOpened,
			data: products?.scpi,
		},
		pe: {
			isLoading: isProductsLoading,
			isOpen: isPeOpened,
			data: products?.pe,
		},
		savings: {
			isLoading: isProductsLoading,
			isOpen: isSavingsOpened,
			data: products?.savings,
		},
		corporate: {
			isLoading: isCorporateLoading,
			isOpen: isCorporateOpened,
			data: corporateInfo,
		},
		anyProduct: {
			isLoading: isProductsLoading || isCorporateLoading,
			isOpen: isAnyProductOpened,
		},
		allProducts: {
			isLoading: isProductsLoading || isCorporateLoading,
			valuation: investmentValuation,
			performance: products?.globalPerformance,
		},
	};
};
