import { Card, Heading, Image, Text, VStack } from '@chakra-ui/react';

type ContractCardProps = {
	icon: string;
	title: string;
	description: string;
	footer: JSX.Element;
};

const ContractCard = ({ icon, title, description, footer }: ContractCardProps): JSX.Element => {
	return (
		<Card w="100%" p="24px" h="100%">
			<VStack spacing="24px" align="start">
				<Image src={icon} w="40px" />
				<VStack spacing="16px" align="start">
					<Heading variant="Title-M-SemiBold">{title}</Heading>
					<Text minH="40px" variant="Text-M-Regular">
						{description}
					</Text>
				</VStack>
				{footer}
			</VStack>
		</Card>
	);
};

export default ContractCard;
