import { Card, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react';

import BlackRamifyIcon from 'assets/logoRamify/black_ico.svg';
import InformationBanner from 'components/Banners/InformationBanner';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { useFees } from 'hooks/useFees';
import { SouscriptionStepProps } from 'onboarding/Stepper/stepper.slice';
import { portfolioTypeLabels } from 'store/types/profilage.type';

import { OurFees } from './OurFees';

const Explanation = ({ subscription, onNext }: SouscriptionStepProps): JSX.Element => {
	const fees = useFees();

	return (
		<VStack alignItems="flex-start" spacing="32px" pb="40px">
			<HStack align="center" spacing="24px">
				<HStack align="center" spacing="6px">
					<Image src={BlackRamifyIcon} alt="Contract illustration" w="20px" />
					<Heading variant="Title-M-Bold">{subscription.envelope.type === 'AV' ? 'Ramify Vie' : 'Ramify PER'}</Heading>
				</HStack>
				{subscription.investmentPreferences!.portfolioType && (
					<Card size="sm" bg="grey.300" border="0" padding="8px 16px">
						<Text variant="Button-SemiBold">
							Stratégie {portfolioTypeLabels[subscription.investmentPreferences!.portfolioType]}
						</Text>
					</Card>
				)}
			</HStack>
			<VStack align="start" spacing="40px">
				<VStack w="100%" align="start" spacing="16px">
					<InformationBanner
						title="Trois fois moins cher que les services d’une banque privée"
						description="Compliqué de se repérer parmi tous les frais traditionnels, nous avons donc supprimé tous les intermédiaires
					inutiles. Nos estimations de performance sont toutes nettes de frais. Ce que vous voyez, c’est ce que vous
					gagnez."
					/>
				</VStack>

				<OurFees fees={fees} />

				<VStack w="100%" align="start" spacing="16px">
					<Heading variant="Title-M-SemiBold">Une solution fiable et sécurisée</Heading>
					<InformationBanner description="Lorsque vous investissez chez Ramify, vos fonds sont hébergés par Apicil, 3e groupe de protection sociale en France. Passer par un acteur de confiance nous permet de garantir la sécurité de votre patrimoine." />
				</VStack>
			</VStack>

			<BottomStepBar onNext={onNext} showPrev={false} />
		</VStack>
	);
};

export default Explanation;
