import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Heading, Show, Skeleton, Text, VStack } from '@chakra-ui/react';

import eventTracker from 'services/events/eventTracker';
import { useGetKYCQuery } from 'services/requests/kyc';
import { useGetRealEstateSimulationsByIdQuery } from 'services/requests/realEstate/simulations';
import { isNone } from 'utils/functions';

import RecommandationSCPIForm from './Survey';

const FormSkeleton = () => (
	<VStack w="100%" align="start" spacing="40px">
		{Array(5).map((_, index) => (
			// eslint-disable-next-line react/no-array-index-key
			<VStack spacing="16px" w="100%" align="start" key={index}>
				<Skeleton w="50%" h="24px" />
				<Skeleton w="100%" h="48px" />
			</VStack>
		))}
	</VStack>
);

const SCPIRecommandationSurvey = (): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const { data: userKyc, isLoading: isKycLoading } = useGetKYCQuery();
	const { data: currentSimulation, isLoading: isCurrentSimulationLoading } = useGetRealEstateSimulationsByIdQuery(id!, {
		skip: isNone(id),
	});

	useEffect(() => {
		eventTracker.mixpanel.inputSimulationPage('SCPI');
	}, []);

	return (
		<VStack spacing="32px" align="start">
			<Heading variant="Title-L-SemiBold">Simuler mon investissement</Heading>

			<Show above="md">
				<Text>
					Répondez à quelques questions pour découvrir la sélection de fonds la plus adaptée à votre projet
					d’investissement.
					<br />
					Ce simulateur prend en compte votre objectif d’investissement, votre horizon de placement ainsi que vos
					préférences stratégiques.
					<br />
					<b>Tous nos simulateurs sont tenus à jour avec les dernières évolutions législatives et fiscales.</b>
				</Text>
			</Show>

			{isCurrentSimulationLoading || isKycLoading ? (
				<FormSkeleton />
			) : (
				<RecommandationSCPIForm userKyc={userKyc} currentSimulation={currentSimulation} />
			)}
		</VStack>
	);
};

export default SCPIRecommandationSurvey;
