import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';

import Banner from 'components/Banners/Banner';
import { CalendlyModal } from 'components/Modals/Calendly';
import eventTracker from 'services/events/eventTracker';
import { useGetBannerQuery } from 'services/requests/communication';
import { isNone, isNotNone } from 'utils/functions';

type AirtableBannerProps = {
	endpoint?: string;
};

const AirtableBanner: FC<AirtableBannerProps> = ({ endpoint }) => {
	// remove airtable id from url to find the correct banner by exact match
	const { data: bannerData, isLoading } = useGetBannerQuery(endpoint ?? window.location.pathname.split('/rec')[0]);

	const navigate = useNavigate();
	const { isOpen: isCalendlyOpen, onClose: onCalendlyClose, onOpen: onCalendlyOpen } = useDisclosure();

	useEffect(() => {
		if (!bannerData || isLoading) return;
		bannerData.map((b) => {
			if (isNone(b.title) || isNone(b.type) || isNone(b.face)) return null;
			eventTracker.pipedream.bannerImpression(b);
		});
	}, [isLoading, bannerData]);

	return (
		<>
			{bannerData?.some((b) => b.CTAType === 'calendly') && (
				<CalendlyModal isOpen={isCalendlyOpen} onClose={onCalendlyClose} />
			)}

			{isNotNone(bannerData) &&
				bannerData.length > 0 &&
				bannerData.map((b) => {
					if (isNone(b.title) || isNone(b.type) || isNone(b.face)) return null;

					return (
						<Banner
							key={b.name}
							title={b.title}
							description={b.description}
							variant={b.type}
							avatar={b.face}
							cta={b.CTAText}
							onClick={() => {
								eventTracker.pipedream.bannerEngagement(b);
								if (b.CTAType === 'calendly') onCalendlyOpen();
								if (b.CTAType === 'navigate') navigate(b.CTALink);
								if (b.CTAType === 'href') window.open(b.CTALink, '_blank');
								if (b.CTAType === 'pipedream') eventTracker.pipedream.triggerWithLink(b.CTALink);
							}}
						/>
					);
				})}
		</>
	);
};

export default AirtableBanner;
