import { useNavigate } from 'react-router-dom';
import { Box, Button, Heading, Image, Text, VStack } from '@chakra-ui/react';

import Immo from 'assets/products/Immo.svg';
import Pe from 'assets/products/Pe.svg';
import Modal from 'components/Modal';
import eventTracker from 'services/events/eventTracker';

type ProductNameType = 'SCPI' | 'Private Equity';

type InfoProductType = Record<
	ProductNameType,
	{
		navigateTo: string;
		productName: string;
		icon: string;
	}
>;

const infosProductType: InfoProductType = {
	SCPI: {
		navigateTo: '/immobilier/souscriptions',
		productName: 'Ramify Immobilier',
		icon: Immo,
	},
	'Private Equity': {
		navigateTo: '/private-equity/souscriptions',
		productName: 'Ramify Private Equity',
		icon: Pe,
	},
};

interface SubscriptionDoneModalProps {
	productName: ProductNameType;
	isOpen: boolean;
	onClose: () => void;
	redirectToWhenDone: string;
}

export const SubscriptionDoneModal = ({
	productName,
	isOpen,
	onClose,
	redirectToWhenDone,
}: SubscriptionDoneModalProps): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<VStack w="100%" align="center" p="32px 0" spacing="32px">
				<VStack align="center" w="100%" spacing="16px">
					<Image src={infosProductType[productName].icon} alt="maison" w="40px" h="40px" />
					<Heading variant="Title-S-SemiBold">Tout est prêt!</Heading>
				</VStack>
				<Box p="0 32px">
					<Text>
						Vous pouvez désormais finaliser vos souscriptions. Si vous êtes client Ramify, cette étape durera 2 minutes
						chrono. S'il s’agit de votre première souscription, certaines informations seront à compléter pour finaliser
						l’opération.
					</Text>
				</Box>

				<Button
					variant="primary"
					onClick={() => {
						window.sessionStorage.removeItem('realEstateSimulation');
						eventTracker.mixpanel.lookatWaitingSouscription(productName === 'SCPI' ? 'SCPI' : 'PE');
						navigate(redirectToWhenDone);
					}}
				>
					Finaliser mes souscriptions
				</Button>
			</VStack>
		</Modal>
	);
};
