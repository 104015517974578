import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Center, chakra, Heading, Spinner, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { DotWave } from '@uiball/loaders';
import SelectOrAddBankAccountFormContext from 'formContexts/BankAccount/BankAccountSelector';
import FundJustificatifFormContext from 'formContexts/FundJustificatifs';

import CustomModal from 'components/Modal';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { useAppDispatch } from 'hooks/useStore';
import useThemedToast from 'hooks/useThemedToast';
import { backendApi } from 'services/apiService';
import eventTracker from 'services/events/eventTracker';
import {
	SubmitPESubscription,
	useGetUserPESubscriptionByIdQuery,
	useLazyGeneratePESubscriptionPDFQuery,
	useSubmitPESubscriptionMutation,
} from 'services/requests/privateEquity/subscriptions';
import { automatedFundsPERoute } from 'utils/automatedFundTypes';
import scrollToErrorOnForm from 'utils/errors/FormScrollToError';
import { isNone, isNotNone } from 'utils/functions';
import { displayMoney } from 'utils/rendering';

type SubmitFormData = Omit<SubmitPESubscription, 'id'>;

const Souscription = (): JSX.Element => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { id } = useParams<{ id: string }>();
	const [signLink, setSignLink] = useState<string | null>(null);

	const { data: subscription } = useGetUserPESubscriptionByIdQuery({ id: id! });
	const [submitPESubscription, { isLoading: isUpdateLoading }] = useSubmitPESubscriptionMutation();
	const [generateSubscriptionPdf, { isLoading: isGenerateLoading }] = useLazyGeneratePESubscriptionPDFQuery();

	const { isOpen: isSignModalOpen, onOpen: onSignModalOpen, onClose: onSignModalClose } = useDisclosure();

	const methods = useForm<SubmitFormData>();
	const {
		handleSubmit,
		formState: { errors },
		setValue,
	} = methods;

	useEffect(() => {
		if (subscription) {
			if (isNotNone(subscription.fundsOrigin)) setValue('fundsOrigin', subscription.fundsOrigin);
			if (isNotNone(subscription.fundsOriginGeography))
				setValue('fundsOriginGeography', subscription.fundsOriginGeography);
			if (isNotNone(subscription.bankAccountId)) setValue('bankAccountId', subscription.bankAccountId);
			if (isNotNone(subscription.objectives)) setValue('objectives', subscription.objectives);
		}
	}, [subscription, setValue]);

	const isFundFoundAutomated = (autoFundList: Record<string, string>, fundName: string): boolean =>
		Object.keys(autoFundList).includes(fundName);

	// add event listener on iframe to know when the user has signed the document
	useEffect(() => {
		const handler = (e: MessageEvent<{ type: string; event: string }>) => {
			if (e.origin === 'https://yousign.app' && e.data.type === 'yousign') {
				if (e.data.event === 'started') {
					console.log('Signer has opened the signature');
				}
				if (e.data.event === 'success') {
					console.log('Signer has successfully signed');
					onSignModalClose();
					setTimeout(() => {
						dispatch(backendApi.util.invalidateTags(['PE_SUBSCRIPTIONS']));
					}, 3000);
					setTimeout(() => navigate(`/private-equity/onboarding/${id}/confirmation`), 500);
				}
				if (e.data.event === 'error') {
					console.log('Signer encountered an error when signing');
					toast({
						title: 'Erreur lors de la signature de votre bulletin de souscription',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				}
			}
		};
		window.addEventListener('message', handler);
		return () => window.removeEventListener('message', handler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = handleSubmit(
		async (data: SubmitFormData) => {
			if (isNone(subscription)) return;
			try {
				const requestFormData = new FormData();

				const isAutoFund = isFundFoundAutomated(automatedFundsPERoute, subscription.fundName);

				requestFormData.append('jof', data.jof);
				requestFormData.append('fundsOrigin', data.fundsOrigin!);
				requestFormData.append('objectives', JSON.stringify(data.objectives));
				requestFormData.append('fundsOriginGeography', data.fundsOriginGeography!);
				requestFormData.append('bankAccountId', data.bankAccountId!);
				requestFormData.append('subscriptionId', id!);
				requestFormData.append('isAutoFund', isAutoFund.toString());
				await submitPESubscription(requestFormData as unknown as SubmitPESubscription).unwrap();

				eventTracker.pipedream.peSubscription(id!);
				if (isAutoFund) {
					onSignModalOpen();
					const sub = await generateSubscriptionPdf({
						routeFundName: automatedFundsPERoute[subscription.fundName],
						id: id!,
					}).unwrap();
					new URL(sub.signLink); // check if received URL is valid
					setSignLink(sub.signLink);
					eventTracker.pipedream.bulletinGeneration(true, 'private-equity', subscription.fundName);
				} else {
					navigate(`/private-equity/onboarding/${id}/confirmation`);
				}
			} catch (e) {
				onSignModalClose();
				console.log(e);
				eventTracker.pipedream.bulletinGeneration(
					false,
					'private-equity',
					subscription?.fundName ?? '',
					JSON.stringify(e),
				);
				toast({
					title: 'Une erreur est survenue',
					status: 'error',
					duration: 9000,
					isClosable: true,
				});
			}
		},
		() => scrollToErrorOnForm(errors),
	);

	useEffect(() => {
		eventTracker.mixpanel.track('PE Onboarding - Souscription');
	}, []);

	if (!subscription) return <Spinner />;

	return (
		<form onSubmit={onSubmit}>
			<VStack align="start" spacing="40px">
				<Heading variant="Title-XL-Bold">Souscription</Heading>

				<Card w="100%">
					<VStack w="100%" align="start" spacing="24px">
						<Heading variant="Title-S-Bold">Récapitulatif de mon projet</Heading>
						<Text variant="Text-M-Regular">
							Vous allez investir dans le fonds <b>{subscription.fundName}</b> pour un montant total de{' '}
							<b>{displayMoney(subscription.amountVersement)}</b>.
						</Text>
						<Button size="lg" variant="secondary" onClick={() => navigate('../projet')}>
							Modifier mon projet
						</Button>
					</VStack>
				</Card>

				<FormProvider {...methods}>
					<FundJustificatifFormContext />
					<SelectOrAddBankAccountFormContext />
				</FormProvider>
			</VStack>

			<CustomModal
				isOpen={isSignModalOpen}
				onClose={onSignModalClose}
				size={{ base: 'full', md: '6xl' }} // TODO handle size on mobile
				contentProps={{ style: { display: 'flex', height: 900 } }}
			>
				{(isUpdateLoading || isGenerateLoading) && (
					<Center w="100%" h="100%" flexDirection="column">
						<Text mb="24px" fontSize="16px">
							Document(s) en cours de génération
						</Text>
						<DotWave size={70} />
					</Center>
				)}
				{signLink && (
					<chakra.iframe
						flexGrow={1}
						border="none"
						margin={0}
						padding="20px 20px 0px 0px"
						// disable_domain_validation=true is ignored in production, only prupose is dev/staging
						src={`${signLink}&disable_domain_validation=true`}
					/>
				)}
			</CustomModal>
			<BottomStepBar
				showPrev={false}
				textNext={
					isFundFoundAutomated(automatedFundsPERoute, subscription.fundName)
						? 'Signer mes documents'
						: 'Confirmer la souscription'
				}
				nextSubmit
				isNextLoading={isUpdateLoading || isGenerateLoading}
			/>
		</form>
	);
};

export default Souscription;
