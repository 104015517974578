import mixpanel from 'mixpanel-browser';

import { getAppEnvironment } from 'hooks/getAppEnvironment';
import { mixpanelConstants } from 'utils/constants';

const { isProductionOrStaging } = getAppEnvironment();

interface Metadata {
	[key: string]: string | boolean | number;
}

export class MixpanelService {
	private mixpanel = mixpanel;

	constructor() {
		const methods = Object.getOwnPropertyNames(MixpanelService.prototype);
		methods.forEach((methodName) => {
			// @ts-ignore
			if (methodName !== 'constructor' && typeof MixpanelService.prototype[methodName] === 'function') {
				// @ts-ignore
				const old = MixpanelService.prototype[methodName];
				// @ts-ignore
				MixpanelService.prototype[methodName] = (...args) => {
					if (isProductionOrStaging) {
						return old.apply(this, args);
					}
				};
			}
		});
	}

	init() {
		this.mixpanel.init(
			mixpanelConstants.token,
			{ api_host: 'https://api-eu.mixpanel.com', debug: false, ignore_dnt: true },
			'',
		);
	}

	track(action: string, metadata?: Metadata) {
		this.mixpanel.track(action, metadata);
	}

	identify(userId: string) {
		this.mixpanel.identify(userId);
	}

	contractSigned(metadata: { subscriptionId: string; montant: number; emailCustomer: string }) {
		this.mixpanel.track('Contract signed', metadata);
	}

	pageVisit(name: string, metadata?: Metadata) {
		this.mixpanel.track(`Page visited`, {
			page: name,
			...metadata,
		});
	}

	trackLogin(userId: string) {
		this.mixpanel.track('User logged in', {
			distinct_id: userId,
		});
	}

	trackRegister(userId: string, metadata?: Metadata) {
		this.mixpanel.track('User registered', {
			distinct_id: userId,
			...metadata,
		});
	}

	sponsorInvitationSent(to: string) {
		this.mixpanel.track('Sponsorship - Invitation sent', {
			to,
		});
	}

	copySponsorCode(code?: string) {
		this.mixpanel.track('Sponsorship - Copy Code', {
			code,
		});
	}

	//
	// APP ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//

	interestForProduct(product: 'SCPI' | 'PE' | 'INVEST') {
		this.mixpanel.track('Action - Interet Produit', { product });
	}

	//
	// INVEST ////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//

	addVersementPonctuel(montant: number) {
		this.mixpanel.track('Action - Ajout Versement Ponctuel', {
			montant,
		});
	}

	addVersementProgramme(montant: number) {
		this.mixpanel.track('Action - Ajout Versement Programme', {
			montant,
		});
	}

	deleteVersementProgramme(montant: number) {
		this.mixpanel.track('Action - Suppression Versement Programme', {
			montant,
		});
	}

	updateVersementProgramme(montant: number) {
		this.mixpanel.track('Action - Mis à jour versement programme', {
			montant,
		});
	}

	askPERTransfer() {
		this.mixpanel.track('Action - Demande Transfert PER');
	}

	backToInvestOnboarding(origin: 'menu' | 'card') {
		this.mixpanel.track('Action - Back to Invest', { origin });
	}

	//
	// SCPI //////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//

	discoverImmoPageOpenSimulation() {
		this.mixpanel.track('SimulationSCPI-CTA');
	}

	//
	// PE ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//

	//
	// PE / SPCI
	//

	confirmModalInvestisement(type: 'SCPI' | 'PE') {
		this.mixpanel.track(`${type} simulation - Invest in portfolio - Click on confirm`);
	}

	lookatWaitingSouscription(productName: 'SCPI' | 'PE') {
		this.mixpanel.track(`${productName} simulation - Invest in portfolio - Click on redirect after confirmation`);
	}

	souscriptionPotfolio(type: 'SCPI' | 'PE') {
		this.mixpanel.track(`${type} simulation - Invest in portfolio - Click initial CTA`);
	}

	resultSimulationPage(type: 'SCPI' | 'PE') {
		this.mixpanel.track(`${type} simulation - Visit page result simulation`);
	}

	inputSimulationPage(type: 'SCPI' | 'PE') {
		this.mixpanel.track(`${type} simulation - Visit page input simulation`);
	}

	newSimulation(type: 'SCPI' | 'PE') {
		this.mixpanel.track(`${type} simulation - Click CTA new simulation`);
	}

	resultSimulationPageClickedOnFondTable(type: 'SCPI' | 'PE') {
		this.mixpanel.track(`${type} simulation - Click Fond Table`);
	}

	resultSimulationPageClickedOnExtraInfo(type: 'SCPI' | 'PE') {
		this.mixpanel.track(`${type} simulation - Click Extra Info`);
	}

	resultSimulationPageClickedOnRDV(type: 'SCPI' | 'PE') {
		this.mixpanel.track(`${type} simulation - Click RDV`);
	}
}
