import { FC } from 'react';
import {
	Heading,
	HStack,
	Link,
	ListItem,
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	StackDivider,
	Text,
	UnorderedList,
	VStack,
} from '@chakra-ui/react';

import { QAViewer } from 'components/faq/FAQ';
import { useGetContractsQuery, useListContractDocumentsQuery } from 'services/requests/invest/contracts';
import { useGetAllVersementsQuery } from 'services/requests/mouvements';
import { useGetUserPEGuideDeclarationQuery } from 'services/requests/privateEquity';
import { displayMoney } from 'utils/rendering';

import HelpDeclarationSourcePer from './Sources/SourcePer';
import HelpDeclarationSourcePrivateEquity from './Sources/SourcePrivateEquity';

type SubscriptionModalProps = {
	isOpenModalDeclarationGuide: boolean;
	onCloseModalDeclarationGuide: () => void;
};

const ModalDeclarationGuide: FC<SubscriptionModalProps> = ({
	isOpenModalDeclarationGuide,
	onCloseModalDeclarationGuide,
}) => {
	const { data: peSouscriptionDeclarationGuide } = useGetUserPEGuideDeclarationQuery();
	const { data: contracts } = useGetContractsQuery();
	const { data: listVersements } = useGetAllVersementsQuery();

	const historyVersementPer = listVersements?.filter(
		(versement) =>
			new Date(versement?.dateOperation).getFullYear() === new Date().getFullYear() - 1 &&
			versement?.envelopeType === 'PER',
	);

	const perContract = (contracts || [])?.filter((contract) => contract?.envelope.type === 'PER')[0];

	const { data: documentsData } = useListContractDocumentsQuery(
		{
			contractId: perContract?.id,
		},
		{ skip: !perContract },
	);

	const DocumentFiscaleUnique = documentsData?.content.filter((document) => {
		return (
			new Date(document.dateNumerisation).getFullYear() === new Date().getFullYear() &&
			document.sousCategorieDocument === 'Fiscalité - IFU (Imprimé Fiscal Unique)'
		);
	});

	if (!peSouscriptionDeclarationGuide) return null;

	const hasFCPI = peSouscriptionDeclarationGuide.some((subscription) => subscription.fund.FundType === 'FCPI') || true;
	const hasFip = peSouscriptionDeclarationGuide.some((subscription) => subscription.fund.FundType === 'FIP') || true;
	const hasFipCorse =
		peSouscriptionDeclarationGuide.some((subscription) => subscription.fund.FundType === 'FIP Corse') || true;
	const hasFipOutreMer =
		peSouscriptionDeclarationGuide.some((subscription) => subscription.fund.FundType === 'FIP Outre-Mer') || true;

	const FCPISubscriptions = peSouscriptionDeclarationGuide?.filter(
		(subscription) => subscription.fund.FundType === 'FCPI',
	);
	const FIPSubscriptions = peSouscriptionDeclarationGuide?.filter(
		(subscription) => subscription.fund.FundType === 'FIP',
	);
	const FIPCorseSubscriptions = peSouscriptionDeclarationGuide?.filter(
		(subscription) => subscription.fund.FundType === 'FIP Corse',
	);
	const FIPOutreMerSubscriptions = peSouscriptionDeclarationGuide?.filter(
		(subscription) => subscription.fund.FundType === 'FIP Outre-Mer',
	);

	const totalFCPIFiscalReduction = displayMoney(
		FCPISubscriptions?.reduce(
			(acc, subscription) => acc + (subscription.fund.taxReduction || 1) * subscription.amountVersement,
			0,
		),
	);
	const totalFIPFiscalReduction = displayMoney(
		FIPSubscriptions?.reduce(
			(acc, subscription) => acc + (subscription.fund.taxReduction || 1) * subscription.amountVersement,
			0,
		),
	);
	const totalFIPCORSEFiscalReduction = displayMoney(
		FIPCorseSubscriptions?.reduce(
			(acc, subscription) => acc + (subscription.fund.taxReduction || 1) * subscription.amountVersement,
			0,
		),
	);
	const totalFIPOUTREMERFiscalReduction = displayMoney(
		FIPOutreMerSubscriptions?.reduce(
			(acc, subscription) => acc + (subscription.fund.taxReduction || 1) * subscription.amountVersement,
			0,
		),
	);

	const totalPERFiscalReduction = historyVersementPer?.reduce((acc, versement) => acc + versement.montantOperation, 0);

	return (
		<Modal isOpen={isOpenModalDeclarationGuide} onClose={onCloseModalDeclarationGuide} size="5xl">
			<ModalOverlay />
			<ModalContent p="24px">
				<VStack spacing="40px" w="100%" align="start">
					<VStack spacing="sm" w="100%" align="start">
						<HStack w="100%" justify="space-between">
							<Heading variant="Title-S-SemiBold">Guide à la declaration</Heading>
							<ModalCloseButton />
						</HStack>

						<Text variant="Text-S-Medium">
							Vos investissements fiscaux sur Ramify à déclarer sur le site impots.gouv.fr
							<br />
							<Link
								color="blue.600"
								href="https://help.ramify.fr/fr/articles/7415751-comment-renseigner-ma-souscription-dans-ma-declaration-d-impots"
								isExternal
								textDecoration="underline"
							>
								Consulter notre article
							</Link>
							{'  '}pour plus de détails sur la déclaration.
						</Text>
					</VStack>

					<VStack spacing="lg" w="100%" align="start" divider={<StackDivider />}>
						{perContract && totalPERFiscalReduction && totalPERFiscalReduction > 0 && (
							<HelpDeclarationSourcePer
								title="PER"
								description={
									<VStack align="start" spacing="xs">
										<Text color="grey.900" variant="Text-S-Regular">
											Les investissements sur un{'  '}
											<Text as="span" color="primary.black">
												PER{'  '}
											</Text>
											doivent être indiqués dans la case “charges déductibles” ou “charges et imputations diverses” à
											l’étape 3 pour faire apparaître les cases {'  '}
											<Text as="span" color="primary.black">
												6NS, 6NT et 6NU {'  '}
											</Text>
											sur votre déclaration.
											<br />
											<br />
											Votre montant total à déclarer pour les investissements Ramify est de :{'  '}
											<Text as="span" color="primary.black">
												{totalPERFiscalReduction} €
											</Text>
										</Text>
										<QAViewer
											variant="blue"
											question={<Text variant="Text-S-Medium">Mode d'emploi détailé</Text>}
											answer={
												<VStack align="start" spacing="xs" w="100%">
													<Text variant="Text-S-Regular" color="grey.900">
														Le versement sur un
														{'  '}
														<Text as="span" color="primary.black">
															PER {'  '}
														</Text>
														individuel permet de bénéficier d' une déduction fiscale. Pour en bénéficier, il est
														obligatoire de déclarer les versements effectués en {'  '}
														<Text as="span" color="primary.black">
															{new Date().getFullYear() - 1} {'  '}
														</Text>
														dans le formulaire 2042 de votre déclaration d’impôt. En cas de déclaration en ligne, vous
														devez sélectionner la case {'  '}
														<Text as="span" color="primary.black">
															“charges déductibles” ou “charges et imputations diverses”{'  '}
														</Text>
														à l’étape 3 pour faire apparaître les cases
														{'  '}
														<Text as="span" color="primary.black">
															6NS, 6NT et 6NU
														</Text>{' '}
														sur votre déclaration.
													</Text>
													<UnorderedList spacing="sm" p="24px">
														<ListItem as={Text} variant="Text-S-Regular" color="grey.900">
															{'  '}
															<Text as="span" color="primary.black">
																6NS :
															</Text>{' '}
															déclaration des sommes versées par le premier déclarant
														</ListItem>
														<ListItem fontSize="12">
															{'  '}
															<Text as="span" color="primary.black">
																6NT :
															</Text>
															{'  '}
															déclaration des sommes versées par le second déclarant
														</ListItem>
														<ListItem fontSize="12">
															<Text as="span" color="primary.black">
																6NU : {'  '}
															</Text>
															déclaration des sommes versées sur le plan d’épargne retraite d’une personne à charge
														</ListItem>
													</UnorderedList>

													<Text variant="Text-S-Regular">
														Note : afin de mutualiser votre plafond avec celui de votre conjoint ou de votre partenaire
														de PACS, vous devez cocher la case 6QR. Dans ce cas, votre couple bénéficie d'un plafond
														global de déduction.
													</Text>
												</VStack>
											}
										/>
									</VStack>
								}
								contract={{
									contract: perContract,
									totalYearlyDeposit: totalPERFiscalReduction || 0,
									DocumentFiscale: DocumentFiscaleUnique,
								}}
							/>
						)}

						{hasFCPI && (
							<HelpDeclarationSourcePrivateEquity
								title="FCPI"
								description={
									<Text color="grey.900" variant="Text-S-Regular">
										Les investissements en fonds
										{'  '}
										<Text as="span" color="primary.black">
											FCPI
										</Text>
										{'  '}
										de 2022 doivent être indiqués sur le formulaire
										{'  '}
										<Text as="span" color="primary.black">
											2042-C
										</Text>
										, selon la date des versements : {'  '}
										<UnorderedList>
											<ListItem>
												ligne
												{'  '}
												<Text as="span" color="primary.black">
													7GQ {'  '}
												</Text>
												pour les versements effectués entre le 01/01/ {new Date().getFullYear() - 1} et le 17/03/
												{new Date().getFullYear() - 1},
											</ListItem>
											<ListItem>
												ligne
												{'  '}
												<Text as="span" color="primary.black">
													7GR {'  '}
												</Text>
												pour les versements effectués entre le 18/03/ {new Date().getFullYear() - 1} et le 31/12/
												{new Date().getFullYear() - 1}.
											</ListItem>
										</UnorderedList>
										<br />
										Votre montant total à déclarer pour les investissements Ramify est de : {'  '}
										<Text as="span" color="primary.black">
											{totalFCPIFiscalReduction}
										</Text>
									</Text>
								}
								souscriptions={FCPISubscriptions}
							/>
						)}
						{hasFip && (
							<HelpDeclarationSourcePrivateEquity
								title="FIP"
								description={
									<Text color="grey.900" variant="Text-S-Regular">
										Les investissements en fonds
										{'  '}
										<Text as="span" color="primary.black">
											FIP
										</Text>
										{'  '}
										doivent être indiqués dans le formulaire
										{'  '}
										<Text as="span" color="primary.black">
											2042-C {'  '}
										</Text>
										à la ligne
										{'  '}
										<Text as="span" color="primary.black">
											7GQ {'  '}
										</Text>
										<br />
										<br />
										Votre montant total à déclarer pour les investissements Ramify est de : {'  '}
										<Text as="span" color="primary.black">
											{totalFIPFiscalReduction}{' '}
										</Text>
									</Text>
								}
								souscriptions={FIPSubscriptions}
							/>
						)}
						{hasFipCorse && (
							<HelpDeclarationSourcePrivateEquity
								title="FIP CORSE"
								description={
									<Text color="grey.600" variant="Text-S-Regular">
										Les investissements en fonds
										{'  '}
										<Text as="span" color="primary.black">
											FIP CORSE {'  '}
										</Text>
										doivent être indiqués dans le formulaire
										{'  '}
										<Text as="span" color="primary.black">
											2042-C {'  '}
										</Text>
										à la ligne
										{'  '}
										<Text as="span" color="primary.black">
											7FM
										</Text>
										.
										<br />
										<br />
										Votre montant total à déclarer pour les investissements Ramify est de : {'  '}
										<Text as="span" color="primary.black">
											{totalFIPCORSEFiscalReduction}{' '}
										</Text>
									</Text>
								}
								souscriptions={FIPCorseSubscriptions}
							/>
						)}
						{hasFipOutreMer && (
							<HelpDeclarationSourcePrivateEquity
								title="FIP Outre Mer"
								description={
									<Text color="grey.600" variant="Text-S-Regular">
										Les investissements en fonds
										{'  '}
										<Text as="span" color="primary.black">
											FIP OUTRE MER
										</Text>
										doivent être indiqués dans le formulaire
										{'  '}
										<Text as="span" color="primary.black">
											2042-C
										</Text>
										à la ligne
										{'  '}
										<Text as="span" color="primary.black">
											7FL
										</Text>
										.
										<br />
										<br />
										Votre montant total à déclarer pour les investissements Ramify est de : {'  '}
										<Text as="span" color="primary.black">
											{totalFIPOUTREMERFiscalReduction}
										</Text>
									</Text>
								}
								souscriptions={FIPOutreMerSubscriptions}
							/>
						)}
					</VStack>
				</VStack>
			</ModalContent>
		</Modal>
	);
};
export default ModalDeclarationGuide;
