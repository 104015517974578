import colors from 'theme/foundations/colors';

const style = {
	baseStyle: {
		text: {
			color: colors.negative[900],
		},
	},
};

export default style;
