import { useCallback, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import StepperLayout from 'app/Layout/Onboarding/StepperLayout';
import useThemedToast from 'hooks/useThemedToast';
import { MainStepType } from 'onboarding/Stepper/stepper.slice';
import { CreateInvestSimulation } from 'services/requests/invest/simulation';
import { useCreateMutation } from 'services/requests/invest/subscription';
import { EnvelopeType } from 'store/types/global.type';
import { isNotNone } from 'utils/functions';

import Project from '../Shared/Objective';

import { InvestSubscriptionSteps } from './steps';

type NewInvestSubscriptionWrapperProutProps = {
	envelope: EnvelopeType;
};

const NewInvestSubscriptionRoutesWrapper = ({ envelope }: NewInvestSubscriptionWrapperProutProps): JSX.Element => {
	const [collapsed, setCollapsed] = useState(false);
	const navigate = useNavigate();
	const toast = useThemedToast();

	const [createSubscription] = useCreateMutation();

	const fakeSteps = useMemo(() => {
		return [
			'Mon projet',
			'Portefeuille d’investissement',
			envelope === 'PER' ? 'Optimisation fiscale' : undefined,
			'Informations personnelles',
			'Signature',
		]
			.filter(isNotNone)
			.map((e, i) => ({
				id: i === 0 ? InvestSubscriptionSteps.PROJECT : i.toString(),
				name: e,
				url: '',
				type: i === 0 ? MainStepType.START : MainStepType.COURSE,
			}));
	}, [envelope]);

	const basePath = useMemo(
		() => `/invest/${envelope === 'AV' ? 'ouverture-assurance-vie' : 'ouverture-per-individuel'}`,
		[envelope],
	);

	const projectNext = useCallback(
		(data: CreateInvestSimulation) => {
			createSubscription({
				initialDepositAmount: data.initialAUM,
				projectType: data.type,
				recurrentDepositAmount: data.saving,
				envelope,
			})
				.unwrap()
				.then((createdSubscription) => {
					navigate(`/invest/souscription/${createdSubscription.id}`, { replace: true });
				})
				.catch(() => {
					toast({
						title: 'Une erreur est survenue.',
						description: "Nous n'avons pas pu valider vos informations.",
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				});
		},
		[createSubscription, envelope, navigate, toast],
	);

	return (
		<Routes>
			<Route
				element={
					<StepperLayout
						headerTitle={envelope === 'AV' ? 'Assurance Vie' : 'PER Individuel'}
						headerSubtitle="Ouverture Contrat"
						collapsed={collapsed}
						setCollapsed={setCollapsed}
						steps={fakeSteps}
						basePath={basePath}
					/>
				}
			>
				<Route path="projet" element={<Project onNext={projectNext} />} />

				<Route path="*" element={<Navigate to="projet" replace />} />
			</Route>
		</Routes>
	);
};

export default NewInvestSubscriptionRoutesWrapper;
