import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Badge, Box, HStack, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { useAppResponsive } from 'hooks/useAppResponsive';

import ActiveMenuIndicator from './ActiveMenuIndicator';

export type ProjectBarMenuItem = {
	label: string;
	active?: boolean;
	onclick?: () => void | undefined;
	badgeNumber?: number;
};

export type SubmenuNavigationProps = {
	projectBarMenuItems: ProjectBarMenuItem[];
	onClick?: (x: number) => void;
};

const MotionContainer = motion(Box);

const NumberBadge = ({ number }: { number: number }) => (
	<Badge bg="yellow.300" fontWeight={700} color="grey.700" fontSize="10px" p="2px 6px 2px 6px">
		{number}
	</Badge>
);

const SubmenuNavigation: FC<SubmenuNavigationProps> = ({ projectBarMenuItems, onClick = () => {} }) => {
	// Must be equal to Layout ! see app > Layout.tsx

	const paddingSideBar: boolean = useAppResponsive({ base: false, xl: true }) || false;
	const [activeLinkRect, setActiveLinkRect] = useState<DOMRect>();
	const [visible, setVisible] = useState(false);

	const isMobile = useAppResponsive({ base: true, sm: false }) || false;

	const scrollableTopbar = useRef() as React.MutableRefObject<HTMLDivElement>;
	const [topbarScrollOffset, setTopbarScrollOffset] = useState(0);

	const handleScrollTopbar = useCallback(() => {
		if (scrollableTopbar.current === undefined) return;
		setTopbarScrollOffset(scrollableTopbar.current.scrollLeft);
	}, []);

	const handleRect = useCallback((node: HTMLDivElement) => {
		if (node === null) return;
		const positionSelector = () => setActiveLinkRect(node?.getBoundingClientRect());
		window.addEventListener('resize', positionSelector);
		positionSelector();
		node.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
	}, []);

	useEffect(() => {
		setVisible(true);
	}, []);

	if (!visible) return null;

	return (
		<Box w="100%" h={isMobile ? '48px' : '72px'}>
			<MotionContainer
				position="absolute"
				animate={{ top: [-72, 0] }}
				transition={{ duration: 0.2, ease: 'easeOut' }}
				left="0"
				w="100%"
				bg="white"
				borderBottom="base"
				h={isMobile ? '48px' : '72px'}
				justify="center"
			>
				<HStack
					ref={scrollableTopbar}
					as="menu"
					w="100%"
					justify="center"
					overflowX="auto"
					h="100%"
					m="0px"
					p="0px"
					spacing={!isMobile ? '32px' : 0}
					position="relative"
					css={{
						'&::-webkit-scrollbar': {
							display: 'none',
							width: '0',
						},
					}}
				>
					{projectBarMenuItems.map((opt, idx) => (
						<HStack key={idx} spacing="0px">
							<Text
								ref={opt.active ? handleRect : undefined}
								onClick={
									onClick
										? () => {
												if (!opt.active) {
													handleScrollTopbar();
													onClick(idx);
												}
										  }
										: undefined
								}
								cursor="pointer"
								p="8px 16px"
								borderRadius="base"
								fontSize={isMobile ? '12px' : '14px'}
								whiteSpace="nowrap"
								color={opt.active ? 'grey.700' : 'grey.500'}
								fontWeight={opt.active ? 'semibold' : isMobile ? 'medium' : 'normal'}
								_hover={!opt.active ? { bg: 'grey.100' } : {}}
							>
								{opt.label}
							</Text>
							{opt.badgeNumber !== undefined && <NumberBadge number={opt.badgeNumber} />}
						</HStack>
					))}
					<ActiveMenuIndicator
						h={isMobile ? '4px' : '7px'}
						w={`calc(${activeLinkRect?.width}px - 10px)`}
						left={`calc(${activeLinkRect?.left}px - ${paddingSideBar ? '272px' : '0px'} + ${topbarScrollOffset + 5}px)`}
					/>
				</HStack>
			</MotionContainer>
		</Box>
	);
};

export default SubmenuNavigation;
