import { FC } from 'react';
import { Box, Heading, HStack, Stack, StackDivider, Text, VStack } from '@chakra-ui/react';

import AffiliatesListBadge from 'pages/Affiliates/List/AffiliatesListBadge';
import { useGetAffiliatesQuery } from 'services/requests/sponsorship';
import { isNone } from 'utils/functions';

import { AffiliatesListCardContent } from './AffiliatesListCardTypes';

const AffiliatesList: FC = () => {
	const { data: affiliatesList } = useGetAffiliatesQuery();

	if (isNone(affiliatesList) || affiliatesList?.length === 0) return null;

	return (
		<Stack w="100%" spacing="lg">
			<Heading variant="Title-L-SemiBold">Mes parrainages</Heading>
			<VStack w="100%" divider={<StackDivider borderColor="grey.500" />}>
				{affiliatesList.map((affiliate: AffiliatesListCardContent) => (
					<Stack
						align={{ base: undefined, lg: 'center' }}
						direction={{ base: 'column', lg: 'row' }}
						w="100%"
						key={affiliate.id}
						spacing={{ base: 'md', lg: undefined }}
						py="8px"
					>
						<HStack flex="1" spacing="16px">
							<VStack flex="1" spacing="0px" alignItems="left">
								<Text variant="Text-M-Medium">{affiliate.name}</Text>
								<Text variant="Text-S-Medium" color="grey.900">
									Création du compte : {new Date(affiliate.date).toLocaleDateString('fr-FR')}
								</Text>
							</VStack>

							<Box flex="1">
								<Text variant="Text-S-Medium" color="grey.900">
									{affiliate.email}
								</Text>
							</Box>
						</HStack>

						<Stack
							flex="1"
							spacing="16px"
							direction={{ base: 'column', lg: 'row' }}
							justify={{ base: 'space-between', lg: 'unset' }}
						>
							<HStack flex={{ base: undefined, lg: '1' }}>
								{affiliate.contracts.map((contract: string) => (
									<Text
										variant="Text-S-Medium"
										color="grey.900"
										bg="grey.100"
										borderColor="grey.500"
										borderWidth="1px"
										borderRadius="2px"
										p="4px 8px"
										key={contract}
										flexWrap="nowrap"
									>
										{contract}
									</Text>
								))}
							</HStack>

							<AffiliatesListBadge status={affiliate.status} />
						</Stack>
					</Stack>
				))}
			</VStack>
		</Stack>
	);
};

export default AffiliatesList;
