import { useEffect, useState } from 'react';

import { useGetAllSubscriptionsQuery } from 'services/requests/invest/subscription';
import { useGetUserPESubscriptionsQuery } from 'services/requests/privateEquity/subscriptions';
import { useGetUserSCPISubscriptionsQuery } from 'services/requests/realEstate/subscriptions';
import { useGetSavingsAccountsSubscriptionsQuery } from 'services/requests/savingsAccounts';
import { ProductSubscriptionType, ProductType } from 'store/types/global.type';
import { SavingsAccountInformation } from 'store/types/savingsAccount.type';
import { InvestSubscription, SubscriptionStatus } from 'store/types/subscription.type';

interface UseAuthenticationReturnType {
	subscriptionList: ProductSubscriptionType[];
	isLoading: boolean;
}

type InvestSubscriptionStatus = 'TO_COMPLETE' | 'SUBSCRIBING' | 'COMPLETED';

export const getInvestSubscriptionStatus = (investSubscription: InvestSubscription): InvestSubscriptionStatus => {
	if (investSubscription.status === SubscriptionStatus.GENERATED) return 'TO_COMPLETE';
	if (investSubscription.status === SubscriptionStatus.SIGNED) return 'SUBSCRIBING';
	if (investSubscription.status === SubscriptionStatus.SENT_TO_PARTNER) return 'SUBSCRIBING';
	if (investSubscription.status === SubscriptionStatus.COMPLETED) return 'COMPLETED';
	return 'TO_COMPLETE';
};

const useSubscriptionList = (): UseAuthenticationReturnType => {
	const { data: SCPISubscriptions, isFetching: isFetchingSCPISubscriptions } = useGetUserSCPISubscriptionsQuery();
	const { data: PESubscriptions, isFetching: isFetchingPESubscriptions } = useGetUserPESubscriptionsQuery();
	const { data: investSubscriptions, isFetching: isFetchingSubscriptions } = useGetAllSubscriptionsQuery();
	const { data: savingsAccountsSubscriptions, isFetching: isFetchingSavingsAccountsSubscriptions } =
		useGetSavingsAccountsSubscriptionsQuery();

	const [isLoading, setIsLoading] = useState(true);
	const [subscriptionList, setSubscriptions] = useState<ProductSubscriptionType[]>([]);

	useEffect(() => {
		if (
			!isFetchingSubscriptions &&
			!isFetchingPESubscriptions &&
			!isFetchingSCPISubscriptions &&
			!isFetchingSavingsAccountsSubscriptions
		) {
			let allSubscriptions: ProductSubscriptionType[] = [];
			if (SCPISubscriptions) {
				const scpiSubscriptionsPending = SCPISubscriptions.filter(
					(sub) => sub.status !== 'COMPLETED',
				).map<ProductSubscriptionType>((sub) => ({
					...sub,
					typeProject: ProductType.SCPI,
				}));
				allSubscriptions = allSubscriptions.concat(scpiSubscriptionsPending);
			}
			if (PESubscriptions) {
				const peSubscriptionsPending = PESubscriptions.filter(
					(sub) => sub.status !== 'COMPLETED',
				).map<ProductSubscriptionType>((sub) => ({
					...sub,
					typeProject: ProductType.PE,
				}));
				allSubscriptions = allSubscriptions.concat(peSubscriptionsPending);
			}
			if (investSubscriptions) {
				const investSubscriptionsPending = investSubscriptions
					.filter((sub) => sub.status !== SubscriptionStatus.COMPLETED)
					.map<ProductSubscriptionType>((sub) => ({ ...sub, typeProject: ProductType.INVEST }));
				allSubscriptions = allSubscriptions.concat(investSubscriptionsPending);
			}

			if (savingsAccountsSubscriptions) {
				allSubscriptions = allSubscriptions.concat(
					savingsAccountsSubscriptions.map<SavingsAccountInformation & { typeProject: ProductType.CASH }>(
						(account) => ({
							...account,
							typeProject: ProductType.CASH,
						}),
					),
				);
			}
			setSubscriptions(allSubscriptions.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)));
			setIsLoading(false);
		}
	}, [
		isFetchingSubscriptions,
		isFetchingPESubscriptions,
		isFetchingSCPISubscriptions,
		SCPISubscriptions,
		PESubscriptions,
		investSubscriptions,
		setSubscriptions,
		isFetchingSavingsAccountsSubscriptions,
		savingsAccountsSubscriptions,
	]);

	return { subscriptionList, isLoading };
};

export default useSubscriptionList;
