import { useCallback, useState } from 'react';
import { AlignBoxMiddleLeft, Finance, ToolBox } from '@carbon/icons-react';
import {
	Box,
	Button,
	Card,
	Heading,
	HStack,
	PinInput,
	PinInputField,
	Text,
	VStack,
	Wrap,
	WrapItem,
} from '@chakra-ui/react';
import { Ring } from '@uiball/loaders';

import Banner from 'components/Banners/Banner';
import ListCompositionPortfolio from 'components/ListCompositionPortfolio';
import eventTracker from 'services/events/eventTracker';
import { useCreateVersementMutation, useSignVersementMutation } from 'services/requests/versement';
import colors from 'theme/foundations/colors';
import { displayMoney, maskSensibleIban } from 'utils/rendering';

import { CreateVCData } from './Informations';

type DetailCardProps = {
	label: string;
	value?: React.ReactNode;
	customContent?: JSX.Element;
	icon?: JSX.Element;
};

const DetailCard = ({ label, value, icon, customContent }: DetailCardProps) => (
	<HStack w="100%" key={label} spacing="16px">
		{icon && <Box>{icon}</Box>}
		{customContent ? (
			customContent
		) : (
			<VStack w="100%" align="start" spacing="8px">
				<Text color="grey.900" variant="Text-S-Medium">
					{label}
				</Text>
				<Heading variant="Title-M-Bold">{value}</Heading>
			</VStack>
		)}
	</HStack>
);

type SignatureProps = {
	data: CreateVCData;
	versementType: 'programme' | 'ponctuel';
	edit: () => void;
	onSuccess: () => void;
	onError: () => void;
};

const Signature = ({ data, versementType, edit, onSuccess, onError }: SignatureProps): JSX.Element => {
	const [code, setCode] = useState('');

	const [
		triggerCreateVersement,
		{
			data: previewVersement,
			isLoading: isSmsRequestLoading,
			isSuccess: isSmsRequestSuccess,
			isError: isSmsRequestError,
			isUninitialized: isSmsRequestUninitialized,
		},
	] = useCreateVersementMutation();

	const [
		triggerSignVC,
		{ isLoading: isSignVCLoading, isError: isSignVCError, isUninitialized: isSignVCUninitialized },
	] = useSignVersementMutation();

	const handleSign = useCallback(() => {
		triggerSignVC({
			otp: code,
			versementId: previewVersement!.id,
		})
			.unwrap()
			.then(() => {
				if (versementType === 'programme') eventTracker.mixpanel.addVersementProgramme(data.amount);
				else eventTracker.mixpanel.addVersementPonctuel(data.amount);
				onSuccess();
			})
			.catch((e) => {
				if ('status' in e && e.status === 400) {
					// incorrect code. Don't do anything and let JSX display the error so the user can enter new code
					return;
				}
				eventTracker.signVersementError({
					amount: data.amount,
					envelopeType: data.contract!.envelope.type,
					operationType: versementType,
				});
				onError();
			});
	}, [code, data.amount, data.contract, onError, onSuccess, previewVersement, triggerSignVC, versementType]);

	const recap: DetailCardProps[] = [
		{
			icon: <ToolBox size="32" />,
			label: 'Contrat',
			value: <Text variant="label">{data.contract!.envelope.type === 'AV' ? 'Assurance vie' : 'PER individuel'}</Text>,
		},
		{
			label: 'Compte bancaire',
			icon: <Finance size="32" />,
			customContent: (
				<Wrap align="start" w="100%" spacing={{ base: '4px', md: '40px' }}>
					<WrapItem>
						<VStack w="100%" align="start" spacing="8px">
							<Text color="grey.900" variant="Text-S-Medium">
								Compte bancaire
							</Text>
							<Heading variant="Title-M-Bold">{data.bankInfo.holder}</Heading>
						</VStack>
					</WrapItem>
					<WrapItem h="100%">
						<VStack w="100%" align="start" spacing="8px" justify="space-between">
							<Text variant="Text-S-Medium" color={colors.grey[900]}>
								IBAN
							</Text>
							<Text variant="Text-S-Regular" color={colors.grey[900]}>
								{maskSensibleIban(data.bankInfo?.IBAN || '')}
							</Text>
						</VStack>
					</WrapItem>
				</Wrap>
			),
		},
		{
			icon: <AlignBoxMiddleLeft size="32" />,
			label: 'Montant',
			value: <Text variant="label">{displayMoney(data.amount || 0)}</Text>,
		},
	];

	return (
		<VStack spacing="32px" align="start" w="100%">
			<Card w="100%">
				<VStack align="start" spacing="24px" w="100%">
					<Heading variant="Title-M-SemiBold" color={colors.primary.black}>
						Récapitulatif
					</Heading>
					<VStack w="100%" spacing="24px">
						{recap.map(({ label, value, icon, customContent }) => (
							<DetailCard key={label} label={label} value={value} icon={icon} customContent={customContent} />
						))}
					</VStack>
				</VStack>
			</Card>

			{/* Request SMS */}
			{isSmsRequestError && (
				<Banner
					variant="information"
					title="Une erreur est survenue, vous ne pouvez pas réaliser de versement ponctuel sur votre contrat pour l'instant.
					Veuillez nous contacter pour plus d'informations."
				/>
			)}
			{(isSmsRequestUninitialized || isSmsRequestError) && (
				<VStack w="100%" align="end">
					<Text variant="body">Une validation SMS est requise pour cette opération.</Text>

					<HStack w="100%" justify="space-between">
						<Button variant="secondary" onClick={edit}>
							Modifier
						</Button>
						<Button
							variant="primary"
							onClick={() =>
								triggerCreateVersement({
									bankInfoId: data.bankInfo.id,
									contratId: data.contract!.id,
									montant: data.amount,
									programmed: versementType === 'programme',
									origineDesFonds: data.origineDesFonds,
								})
							}
						>
							{isSmsRequestError ? 'Réessayer' : 'Recevoir un code'}
						</Button>
					</HStack>
				</VStack>
			)}

			{/* Sms loading */}
			{isSmsRequestLoading && (
				<HStack spacing="16px">
					<Ring size={24} color={colors.grey[700]} />
					<Text fontWeight="medium" variant="body">
						Envoi du SMS de validation
						<br /> Cette opération peut prendre plusieurs dizaines de secondes
					</Text>
				</HStack>
			)}

			{isSmsRequestSuccess && (
				<>
					<Box overflowX="hidden" w="100%">
						<VStack align="start" spacing="16px">
							<Text variant="Title-M-SemiBold">Répartition du versement</Text>
							<ListCompositionPortfolio assets={previewVersement!.repartition} showPerformance={false} />
						</VStack>
					</Box>
					{(isSignVCUninitialized || isSignVCError) && (
						<>
							{isSignVCError && <Banner variant="information" title="Le code est incorrect" />}
							<VStack align="start" w="100%">
								<Text variant="Text-M-Regular">
									Veuillez confirmer le {'  '}
									<Text variant="Text-M-Bold" as="span">
										code à 6 chiffres {'  '}
									</Text>
									qui vous a été envoyé par SMS
								</Text>
								<HStack>
									<PinInput value={code} onChange={setCode} otp>
										<PinInputField />
										<PinInputField />
										<PinInputField />
										<PinInputField />
										<PinInputField />
										<PinInputField />
									</PinInput>
								</HStack>

								<VStack align="start" w="100%">
									<Text>Vous n'avez pas reçu de code au bout d'une minute ?</Text>
									<Text
										textDecoration="underline"
										cursor="pointer"
										onClick={() => {
											window.Intercom('showNewMessage', 'Bonjour, je ne reçois pas mon code de validation SMS');
										}}
									>
										Contactez nous via le chat
									</Text>
								</VStack>
							</VStack>
							<HStack w="100%" justify="space-between">
								<Button variant="secondary" onClick={edit}>
									Modifier
								</Button>
								<Button variant="primary" isDisabled={code.length !== 6} onClick={handleSign}>
									{isSignVCError ? 'Réessayer' : 'Valider le versement'}
								</Button>
							</HStack>
						</>
					)}
					{isSignVCLoading && (
						<HStack spacing="16px">
							<Ring size={24} color={colors.grey[700]} />
							<Text fontWeight="medium" variant="body">
								Validation en cours
								<br /> Cette opération peut prendre plusieurs dizaines de secondes
							</Text>
						</HStack>
					)}
				</>
			)}
		</VStack>
	);
};

export default Signature;
