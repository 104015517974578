import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Card,
	chakra,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	Image,
	Input,
	Radio,
	RadioGroup,
	Text,
	VStack,
} from '@chakra-ui/react';

import Valide from 'assets/icons/Valide.svg';
import Warning from 'assets/icons/Warning.svg';
import { onboardingInputWidth } from 'components/Onboarding/OnboardingConstants';
import BottomStepBar from 'components/steppers/BottomStepBar';
import useThemedToast from 'hooks/useThemedToast';
import eventTracker from 'services/events/eventTracker';
import { useGetPEKYCQuery, useUpdatePEKYCMutation } from 'services/requests/privateEquity/kyc';
import { PeKyc, UserPEKyc } from 'store/types/peKyc.type';
import scrollToErrorOnForm from 'utils/errors/FormScrollToError';
import { isNotNone } from 'utils/functions';

const riskPerceptionOptions = Object.entries({
	NORISK: 'Aucun risque',
	LOWRISK: 'Risque moyen',
	HIGHRISK: 'Risque élevé',
} as Record<PeKyc['riskPerception'], string>) as [PeKyc['riskPerception'], string][];

const lengthPerceptionOptions = Object.entries({
	SHORTERM: 'Court-terme',
	MIDTERM: 'Moyen-terme',
	LONGTERM: 'Long-terme',
} as Record<PeKyc['lengthPerception'], string>) as [PeKyc['lengthPerception'], string][];

export const InformationsProduit = (): JSX.Element => {
	const toast = useThemedToast();
	const navigate = useNavigate();
	const { id } = useParams<{ id: string }>();
	const [updateKyc] = useUpdatePEKYCMutation();
	const { data: peKyc } = useGetPEKYCQuery();

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		setValue,
		watch,
	} = useForm<UserPEKyc>();

	const [hasPEShares, hasPELinks, riskPerception, lengthPerception] = watch([
		'hasPEShares',
		'hasPELinks',
		'riskPerception',
		'lengthPerception',
	]);

	const isValidRisk = riskPerception === 'HIGHRISK';
	const isValidTerm = lengthPerception === 'MIDTERM' || lengthPerception === 'LONGTERM';

	useEffect(() => {
		if (peKyc) {
			if (isNotNone(peKyc.hasExercisedFinancialProfession))
				setValue('hasExercisedFinancialProfession', peKyc.hasExercisedFinancialProfession);
			if (isNotNone(peKyc.hasPELinks)) setValue('hasPELinks', peKyc.hasPELinks);
			if (isNotNone(peKyc.PELinksNames)) setValue('PELinksNames', peKyc.PELinksNames);
			if (isNotNone(peKyc.hasPEShares)) setValue('hasPEShares', peKyc.hasPEShares);
			if (isNotNone(peKyc.PESharesNames)) setValue('PESharesNames', peKyc.PESharesNames);
			if (isNotNone(peKyc.PELinksTypes)) setValue('PELinksTypes', peKyc.PELinksTypes);
			if (isNotNone(peKyc.lengthPerception)) setValue('lengthPerception', peKyc.lengthPerception);
			if (isNotNone(peKyc.riskPerception)) setValue('riskPerception', peKyc.riskPerception);
		}
	}, [peKyc, setValue]);

	const onSubmit = handleSubmit(
		(data: UserPEKyc) => {
			updateKyc(data)
				.unwrap()
				.then(() => {
					navigate(`/private-equity/onboarding/${id}/reglementaire/test`);
				})
				.catch(() =>
					toast({
						title: 'Une erreur est survenue',
						status: 'error',
						duration: 9000,
						isClosable: true,
					}),
				);
		},
		() => scrollToErrorOnForm(errors),
	);

	useEffect(() => {
		eventTracker.mixpanel.track('PE Onboarding - Informations produit');
	}, []);

	return (
		<chakra.form onSubmit={onSubmit}>
			<VStack align="start" spacing="24px">
				<Heading variant="Title-XL-Bold">Informations relatives au produit</Heading>
				<Text variant="Text-M-Regular" color="grey.900">
					Ces données sont confidentielles et nécessaires vis-à-vis de nos obligations réglementaires et en particulier
					concernant la lutte contre le blanchiment. Elles nous permettent, par ailleurs, de valider la bonne adéquation
					de votre souscription avec votre patrimoine et votre bonne information.
				</Text>

				<VStack w="100%" align="start" spacing="24px">
					<FormControl isInvalid={!!errors.hasExercisedFinancialProfession}>
						<FormLabel>Avez-vous exercé une profession dans le secteur financier durant plus d’un an ?</FormLabel>

						<Controller
							name="hasExercisedFinancialProfession"
							control={control}
							rules={{ validate: { required: (value) => isNotNone(value) } }}
							render={({ field: { onChange, value, ...rest } }) => (
								<RadioGroup
									size="sm"
									w={onboardingInputWidth}
									onChange={(newValue) => onChange(newValue === 'true')}
									value={isNotNone(value) ? (value ? 'true' : 'false') : undefined}
									{...rest}
								>
									<VStack spacing="8px">
										<Radio value="true">Oui</Radio>
										<Radio value="false">Non</Radio>
									</VStack>
								</RadioGroup>
							)}
						/>

						{errors.hasExercisedFinancialProfession?.type === 'required' && (
							<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
						)}
					</FormControl>

					<Card>
						<VStack w={onboardingInputWidth} align="start" spacing="16px">
							<FormControl isInvalid={!!errors.hasPEShares}>
								<FormLabel>Détenez-vous des parts ou actions dans des sociétés ? (plus de 25% du capital)</FormLabel>

								<Controller
									name="hasPEShares"
									control={control}
									rules={{ validate: { required: (value) => isNotNone(value) } }}
									render={({ field: { onChange, value, ...rest } }) => (
										<RadioGroup
											size="sm"
											onChange={(newValue) => onChange(newValue === 'true')}
											value={isNotNone(value) ? (value ? 'true' : 'false') : undefined}
											{...rest}
										>
											<VStack spacing="8px">
												<Radio value="true">Oui</Radio>
												<Radio value="false">Non</Radio>
											</VStack>
										</RadioGroup>
									)}
								/>

								{errors.hasPEShares?.type === 'required' && (
									<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
								)}
							</FormControl>

							{hasPEShares && (
								<FormControl isInvalid={!!errors.PESharesNames}>
									<FormLabel>Préciser quelles sociétés</FormLabel>

									<Input {...register('PESharesNames', { required: true })} placeholder="Noms des sociétés" />

									{errors.PESharesNames?.type === 'required' && (
										<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
									)}
								</FormControl>
							)}
						</VStack>
					</Card>

					<Card>
						<VStack w={onboardingInputWidth} align="start" spacing="16px">
							<FormControl isInvalid={!!errors.hasPELinks}>
								<FormLabel>Avez-vous des liens avec des sociétés côtées ?</FormLabel>

								<Controller
									name="hasPELinks"
									control={control}
									rules={{ validate: { required: (value) => isNotNone(value) } }}
									render={({ field: { onChange, value, ...rest } }) => (
										<RadioGroup
											size="sm"
											onChange={(newValue) => onChange(newValue === 'true')}
											value={isNotNone(value) ? (value ? 'true' : 'false') : undefined}
											{...rest}
										>
											<VStack spacing="8px">
												<Radio value="true">Oui</Radio>
												<Radio value="false">Non</Radio>
											</VStack>
										</RadioGroup>
									)}
								/>

								{errors.hasPELinks?.type === 'required' && <FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>}
							</FormControl>

							{hasPELinks && (
								<>
									<FormControl isInvalid={!!errors.PELinksNames}>
										<FormLabel>Préciser quelles sociétés</FormLabel>

										<Input {...register('PELinksNames', { required: true })} placeholder="Noms des sociétés" />

										{errors.PELinksNames?.type === 'required' && (
											<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
										)}
									</FormControl>
									<FormControl isInvalid={!!errors.PELinksTypes}>
										<FormLabel>Préciser le lien avec les sociétés</FormLabel>

										<Input {...register('PELinksTypes', { required: true })} placeholder="Lien avec les sociétés" />

										{errors.PELinksTypes?.type === 'required' && (
											<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
										)}
									</FormControl>
								</>
							)}
						</VStack>
					</Card>

					<VStack align="start" spacing="16px">
						<FormControl isInvalid={!!errors.riskPerception}>
							<FormLabel>
								Quelle est votre perception du risque de perte en capital pour ce produit d’investissement ?
							</FormLabel>

							<Controller
								name="riskPerception"
								control={control}
								rules={{ validate: { required: (value) => isNotNone(value) } }}
								render={({ field: { onChange, ...rest } }) => (
									<RadioGroup w={onboardingInputWidth} size="sm" onChange={onChange} {...rest}>
										<VStack spacing="8px">
											{riskPerceptionOptions.map(([key, display]) => (
												<Radio key={key} value={key}>
													{display}
												</Radio>
											))}
										</VStack>
									</RadioGroup>
								)}
							/>

							{errors.riskPerception?.type === 'required' && (
								<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
							)}
						</FormControl>
						{riskPerception && (
							<Card borderColor={isValidRisk ? 'grey.300' : 'red.500'} borderWidth="1px">
								<HStack align="center" spacing="16px">
									<Image src={isValidRisk ? Valide : Warning} alt={isValidRisk ? 'correct' : 'incorrect'} />

									<Text variant="label" fontWeight="400">
										Investir en Private Equity comporte des risques notamment de
										<Text color="primary.black" as="span" fontWeight="600">
											{' '}
											perte en capital et d’illiquidité{' '}
										</Text>
										des fonds. Le capital investi et les distributions ne sont pas garantis : ils sont liés aux
										évolutions du marché et à la performance des actifs du portefeuille.
									</Text>
								</HStack>
							</Card>
						)}
					</VStack>
					{isValidRisk && (
						<VStack align="start" spacing="16px">
							<FormControl isInvalid={!!errors.lengthPerception}>
								<FormLabel>Selon vous, investir en Private Equity est un investissement à horizon ?</FormLabel>
								<Controller
									name="lengthPerception"
									control={control}
									rules={{
										required: true,
										validate: { goodChoice: (value) => !(value === 'SHORTERM') },
									}}
									render={({ field: { onChange, ...rest } }) => (
										<RadioGroup w={onboardingInputWidth} size="sm" onChange={onChange} {...rest}>
											<VStack spacing="8px">
												{lengthPerceptionOptions.map(([key, display]) => (
													<Radio key={key} value={key}>
														{display}
													</Radio>
												))}
											</VStack>
										</RadioGroup>
									)}
								/>

								{errors.lengthPerception?.type === 'required' && (
									<FormErrorMessage>Ce champ est nécessaire</FormErrorMessage>
								)}
							</FormControl>
							{lengthPerception && (
								<Card borderColor={isValidTerm ? 'grey.300' : 'red.500'} borderWidth="1px">
									<HStack align="center" spacing="16px">
										<Image src={isValidTerm ? Valide : Warning} alt={isValidTerm ? 'correct' : 'incorrect'} />

										<Text variant="label" fontWeight="400">
											Investir en Private Equity doit être envisagé
											<Text color="primary.black" as="span" fontWeight="600">
												&nbsp;à minima sur du moyen-terme &nbsp;
											</Text>
											pour être adéquat. Le blocage des fonds standard sur cet actif est de 8 à 10 ans.
										</Text>
									</HStack>
								</Card>
							)}
						</VStack>
					)}
				</VStack>
			</VStack>
			<BottomStepBar
				nextSubmit
				onPrev={() => navigate(`/private-equity/onboarding/${id}/reglementaire/informations`)}
			/>
		</chakra.form>
	);
};
