import { backendApi } from 'services/apiService';
import { SCPIFund, SCPIFundDemembrement, SCPIFundHistory, SCPIFundPatrimoine } from 'store/types/airtable.type';

const realEstateApi = backendApi.injectEndpoints({
	endpoints: (builder) => ({
		getSCPIFunds: builder.query<SCPIFund[], void>({
			query: () => '/real-estate/funds',
			providesTags: ['SCPI'],
		}),
		getSCPIFundById: builder.query<SCPIFund, string>({
			query: (id) => `/real-estate/funds/${id}`,
			providesTags: ['SCPI'],
		}),
		getSCPIFundByName: builder.query<SCPIFund, string>({
			query: (name) => `/real-estate/funds/name/${name}`,
			providesTags: ['SCPI'],
		}),
		getSCPIFundHistory: builder.query<SCPIFundHistory[], string>({
			query: (id) => `/real-estate/funds/${id}/history`,
			providesTags: ['SCPI'],
		}),
		getSCPIFundPatrimoine: builder.query<SCPIFundPatrimoine[], string>({
			query: (id) => `/real-estate/funds/${id}/patrimoine`,
			providesTags: ['SCPI'],
		}),
		getSCPIFundDemembrement: builder.query<SCPIFundDemembrement, string>({
			query: (id) => `/real-estate/funds/${id}/demembrement`,
			providesTags: ['SCPI'],
		}),
	}),
});

export const {
	useGetSCPIFundsQuery,
	useGetSCPIFundByIdQuery,
	useGetSCPIFundByNameQuery,
	useGetSCPIFundHistoryQuery,
	useGetSCPIFundPatrimoineQuery,
	useGetSCPIFundDemembrementQuery,
} = realEstateApi;
