import { ProjectType } from 'store/types/global.type';

export type TimeHorizon = 5 | 10 | 15 | 20 | 30;

export const timeHorizonLabels = [
	{
		value: 5,
		label: '0 à 5 ans',
	},
	{
		value: 10,
		label: '5 à 10 ans',
	},
	{
		value: 15,
		label: '10 à 15 ans',
	},
	{
		value: 20,
		label: '15 à 20 ans',
	},
	{
		value: 30,
		label: '+ de 20 ans',
	},
] satisfies ReadonlyArray<{ value: TimeHorizon; label: string }>;

// from simulation.timeHorizon to number (5 -> 30)
export const getRoundedTimeHorizon = (timeHorizon: string) => {
	const goal = new Date(timeHorizon).getFullYear() - new Date().getFullYear();
	return timeHorizonLabels.reduce((prev, curr) =>
		Math.abs(curr.value - goal) < Math.abs(prev.value - goal) ? curr : prev,
	);
};

export const objectifLabels = [
	{
		value: 'OTHER',
		key: 'OTHER',
		label: 'Constituer un capital',
	},
	{
		value: 'RETIREMENT',
		key: 'RETIREMENT',
		label: 'Préparer ma retraite',
	},
] satisfies ReadonlyArray<{ value: ProjectType; key: ProjectType; label: string }>;
