import { FC, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Button, Card, Center, Image, Img, Spinner, Text, VStack } from '@chakra-ui/react';

import Email from 'assets/auth/email.svg';
import CorporateProductCard from 'components/cards/CorporateInvestmentProductCard';
import useThemedToast from 'hooks/useThemedToast';
import { useGetCorporateDashboardQuery } from 'services/requests/corporate';
import {
	useLazyRequestValidateCorporateQuery,
	useValidateEmailCorporateMutation,
} from 'services/requests/validateEmail';
import { sleep } from 'utils/functions';

const CorporateHome: FC = () => {
	const { search } = useLocation();
	const [, setSearchParams] = useSearchParams();
	const toast = useThemedToast();
	const { data, isLoading, isError, refetch } = useGetCorporateDashboardQuery();
	const [timeoutDone, setTimeoutDone] = useState(true);
	const [requestValidateEmail, { isLoading: isValisationLoading, error: errorValidation }] =
		useLazyRequestValidateCorporateQuery();
	const [validateEmail] = useValidateEmailCorporateMutation();

	const token = new URLSearchParams(search).get('token') ?? undefined;

	useEffect(() => {
		if (isError) toast({ title: 'Une erreur est survenue lors du chargement de vos informations', status: 'error' });
	}, [isError, toast]);

	useEffect(() => {
		if (!token) return;
		const validateAndRefresh = async () => {
			await validateEmail(token).unwrap();
			await sleep(1000); // TODO dirty trick, email isn't updated yet in db when reaching this code, weird
			await refetch().unwrap();
			setSearchParams(undefined, { preventScrollReset: true }); // removes the token from url to avoid revalidation
		};
		validateAndRefresh()
			.then(() => toast({ title: 'Email professionnel validé avec succès', status: 'success' }))
			.catch((error) => {
				if ('status' in error && error.status === 409) return;
				toast({ title: 'Erreur lors de la validation de votre email', status: 'error' });
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	const sendEmail = (email: string) => {
		setTimeoutDone(false);
		setTimeout(() => setTimeoutDone(true), 10000);
		requestValidateEmail(email)
			.unwrap()
			.then(() => {
				toast({
					title: 'Envoyé',
					description: 'Vous pouvez regarder dans votre boîte mail professionnelle !',
					status: 'success',
					duration: 9000,
					isClosable: true,
				});
			})
			.catch((error) => {
				toast({
					title: "Une erreur s'est produite.",
					description: (error.data as { message: string })?.message || "Nous n'avons pas pu envoyer l'email",
					status: 'error',
					duration: 9000,
					isClosable: true,
				});
			});
	};

	if (isLoading)
		return (
			<Center w="100%">
				<Spinner />
			</Center>
		);

	if (isError || !data) return null;

	return (
		<VStack spacing="40px" w="100%">
			{data
				.slice()
				.sort((a, b) => Number(b.isEmailValidated) - Number(a.isEmailValidated))
				.map((corporate) => {
					if (!corporate.isEmailValidated) {
						// email corpo non validé
						return (
							<Card key={corporate.email} bg="white" p="24px" w="100%" maxW="668px">
								<VStack align="center" spacing="32px">
									<Text fontSize="16px" color="grey.700" fontWeight={600}>
										Vérifiez votre adresse email professionnelle
									</Text>
									<Center bg="#DFECFE" w="72px" h="72px" borderRadius="50%">
										<Image src={Email} alt="Email" />
									</Center>
									<VStack align="center" spacing="16px" w="100%">
										{isValisationLoading ? (
											<Text variant="body" color="grey.700" lineHeight="21px" align="center">
												Nous vous envoyons un email...
											</Text>
										) : errorValidation ? (
											<Text variant="body" color="grey.700" lineHeight="21px" align="center">
												Nous n'avons pas pû vous envoyer d'email de vérification...
												<br />
												Veuillez nous contacter via la bulle de chat présente sur le site, un membre de l'équipe Ramify
												pourra vous aider à vérifier votre compte directement.
											</Text>
										) : (
											<Text variant="body" color="grey.700" lineHeight="21px" align="center">
												Un email de vérification doit être envoyé à &nbsp;
												<Text as="b" fontWeight={600} color="grey.700">
													{corporate.email}
												</Text>
												<br />
												Votre email professionnel doit être vérifié afin d’accéder à votre dashboard BSPCE.
											</Text>
										)}
									</VStack>
									<Button
										variant="primary"
										size="lg"
										w="50%"
										isDisabled={!timeoutDone || isValisationLoading}
										onClick={() => sendEmail(corporate.email)}
										isLoading={isValisationLoading}
									>
										Envoyer l’email de vérification
									</Button>
								</VStack>
							</Card>
						);
					} else if (corporate.isEmailValidated && !corporate.data) {
						// email corpo validé mais pas de données liées au compte présentes
						return (
							<Card w="100%" key={corporate.email}>
								<Text>
									Aucune attribution de BSPCE liée à <b>{corporate.email}</b>
								</Text>
								<Text>Contactez votre administrateur pour donner votre accord sur le partage de vos informations.</Text>
							</Card>
						);
					} else {
						// email corpo validé et données liées au compte présentes
						return (
							<CorporateProductCard
								key={corporate.data!.attributions[0].company.id}
								name={corporate.data!.attributions[0].company.name}
								icon={<Img h="40px" w="40px" src={corporate.data!.attributions[0].company.logo} />}
								data={{
									status: 'OPENED',
									valuation: corporate.data!.simulationData.valorisation,
									performance: { value: corporate.data!.simulationData.theoretical_gain },
								}}
								linkCTA={`/bspce/${corporate.data!.attributions[0].company.id}`}
							/>
						);
					}
				})}
		</VStack>
	);
};
export default CorporateHome;
