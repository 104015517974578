import { useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/useStore';
import { useGetUserQuery } from 'services/requests/user';
import { User } from 'store/types/user.type';

interface UseAuthenticationReturnType {
	isAuthenticated: boolean;
	isLoading: boolean;
	userData: User | undefined;
}

const useAuthentication = (): UseAuthenticationReturnType => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const { data: userData, isLoading: isUserLoading, isSuccess } = useGetUserQuery();
	const { token, isAuth } = useAppSelector((state) => state.auth);

	useEffect(() => {
		if (isUserLoading) {
			setIsLoading(true);
		} else if (userData) {
			setIsAuthenticated(isSuccess && !!token && isAuth);
			setIsLoading(false);
		} else {
			setIsLoading(false);
			setIsAuthenticated(false);
		}
	}, [isUserLoading, setIsLoading, setIsAuthenticated, isSuccess, token, isAuth, userData]);

	return { isAuthenticated, isLoading, userData };
};

export default useAuthentication;
