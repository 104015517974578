import { useCallback, useState } from 'react';
import { Box, Heading, Skeleton, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { MentionUC } from 'components/compliance/MentionUC';
import BottomStepBar from 'components/steppers/BottomStepBar';
import { useFees } from 'hooks/useFees';
import { useRiskDisplay } from 'hooks/useRiskDisplay';
import useThemedToast from 'hooks/useThemedToast';
import { OnboardingUpdateStepProps } from 'onboarding/Stepper/stepper.slice';
import {
	InvestmentPreferences,
	useGetPotentialPortfolioPerformancesQuery,
	useUpdateTypeMutation,
} from 'services/requests/invest/preferences';
import { PortfolioType } from 'store/types/profilage.type';

import PortfolioTypeCard from './OnboardingPortfolioTypeCard';

interface PreferencesProps {
	type: InvestmentPreferences['portfolioType'] | undefined;
}

interface TooltipPerformanceProps {
	hasEtfs?: boolean | undefined;
	hasScpi?: boolean | undefined;
	hasPrivateEquity?: boolean | undefined;
}

const TooltipPerformanceGross = ({ hasEtfs, hasScpi, hasPrivateEquity }: TooltipPerformanceProps): JSX.Element => {
	const textMedium = { variant: 'Text-S-Medium', color: 'white' };
	const textRegular = { variant: 'Text-S-Regular', color: 'white' };
	return (
		<VStack w="100%" align="start" spacing="16px">
			<Text variant="Text-S-Regular" color="white" pb="16px">
				Tous les frais de gestion des supports d’investissements sont inclus dans les performances.
			</Text>
			{hasEtfs && (
				<VStack w="100%" align="start" spacing="4px">
					<Text {...textMedium}>ETF actions et obligations :</Text>
					<Text {...textRegular}>- frais de souscriptions: 0%</Text>
					<Text {...textRegular}>- frais de gestion en moyenne: 0,2%</Text>
				</VStack>
			)}
			{hasScpi && (
				<VStack w="100%" align="start" spacing="4px">
					<Text {...textMedium}>SCPI immobilier :</Text>
					<Text {...textRegular}>- frais de souscriptions : entre 0% et 5% selon la composition du portefeuille</Text>
					<Text {...textRegular}>
						- frais de gestion en moyenne : 15% de loyer, ce qui représente 0,8% en frais de gestion
					</Text>
				</VStack>
			)}

			{hasPrivateEquity && (
				<VStack w="100%" align="start" spacing="4px">
					<Text {...textMedium}>Private Equity :</Text>
					<Text {...textRegular}>- frais de souscriptions : 0%</Text>
					<Text {...textRegular}>- frais de gestion: 1,95%</Text>
				</VStack>
			)}
			<Text {...textRegular}>Pour plus de référence, les documents DIC sont disponibles à la page suivante.</Text>
		</VStack>
	);
};

const TooltipTextPerformance = (): JSX.Element => {
	return (
		<>Performances moyennes calculées sur les 10 dernières années. Nettes de tous frais de gestion, avant fiscalité.</>
	);
};

const ChoosePortfolioType = ({ object: onboardingObject, onNext, onPrev }: OnboardingUpdateStepProps): JSX.Element => {
	const toast = useThemedToast();
	const [portfolioType, setPortoflioType] = useState<PreferencesProps['type']>(
		onboardingObject.investmentPreferences?.portfolioType,
	);

	const [updateType] = useUpdateTypeMutation();
	const { data: potentialPortfolioPerformances, isSuccess: perfLoaded } = useGetPotentialPortfolioPerformancesQuery({
		id: onboardingObject.id,
		entityName: onboardingObject.table,
	});

	const risk = useRiskDisplay(potentialPortfolioPerformances ? potentialPortfolioPerformances.client_risk_score : 0);
	const fees = useFees();

	const handlePortfolioClick = useCallback(
		(typeClicked: PortfolioType) => {
			setPortoflioType(typeClicked);
			updateType({
				portfolioType: typeClicked,
				id: onboardingObject.id,
				entityName: onboardingObject.table,
			})
				.then(() => {
					onNext();
				})
				.catch(() => {
					toast({
						title: 'Une erreur est survenue',
						status: 'error',
						duration: 9000,
						isClosable: true,
					});
				});
		},
		[onNext, onboardingObject.id, onboardingObject.table, toast, updateType],
	);

	return (
		<VStack w="100%" align="start" spacing="24px">
			<VStack align="start" spacing="24px">
				<Heading variant="Title-XL-Bold">Mon type de portefeuille</Heading>
				<Skeleton isLoaded={perfLoaded}>
					<Text variant="Text-M-Regular" color="grey.900">
						Voici les portefeuilles que nous vous proposons pour un <b>profil de risque {risk.name}</b> (
						{risk.value.toFixed(1)}/10).
					</Text>
				</Skeleton>
			</VStack>
			<VStack align="start" w="100%" spacing="8px">
				<Wrap spacing="16px" w="100%" overflow="visible">
					<WrapItem flex={1} maxW="380px" minW="272px">
						<PortfolioTypeCard
							fees={fees}
							disabled={onboardingObject.initialAUM < 50000}
							cy="Elite"
							type="ELITE"
							content="Diversification maximum avec fonds de private equity inclus dans le portefeuille"
							tooltipTextTitle={
								<Text variant="Text-S-Regular" color="white" align="start">
									Elite est le portefeuille ultime conçu par QIS (Quantitative Investment Solutions), l'équipe
									d'investissement interne à Ramify. Il s'agit de l'unique portefeuille combinant l'intégralité des
									classes d'actifs disponibles, à savoir:{' '}
									<Box as="span" fontWeight="bold">
										Actions, Obligations, Immobilier, Private Equity et Fonds Euros.{' '}
									</Box>
									Avec Elite, vous bénéficiez d'un portefeuille à forte diversification, offrant une performance espérée
									supérieure tout en profitant de la flexibilité des enveloppes d'investissement fiscalement optimisées
									(Assurance Vie & PER).
								</Text>
							}
							tooltipTextPerformance={<TooltipTextPerformance />}
							tooltipTextPerformanceGross={<TooltipPerformanceGross hasEtfs hasScpi hasPrivateEquity />}
							onClick={() => handlePortfolioClick('ELITE')}
							isSelected={portfolioType === 'ELITE'}
							potentialPortfolioPerformances={potentialPortfolioPerformances}
							minimumTicket={50000}
						/>
					</WrapItem>
					<WrapItem flex={1} maxW="380px" minW="272px">
						<PortfolioTypeCard
							cy="Flagship"
							type="FLAGSHIP"
							content="Un portefeuille multi-actifs incluant l'immobilier. Performant et résilient"
							tooltipTextTitle={
								<Text variant="Text-S-Regular" color="white" align="start">
									Le Flagship est un portefeuille d’investissement unique en France qui permet d’investir dans les
									meilleurs actifs financiers au sein d’une même Assurance vie ou Plan Epargne Retraite (PER). Fruit des
									recherches internes de Ramify, le Flagship a pour objectif de superformer les indices boursiers
									mondiaux par la combinaison d’
									<b>actions et obligations, SCPI et capital risque.</b> Avec le Flagship, vous investissez en même
									temps dans des sociétés comme Apple, et des parcs immobiliers.
								</Text>
							}
							tooltipTextPerformance={<TooltipTextPerformance />}
							tooltipTextPerformanceGross={<TooltipPerformanceGross hasEtfs hasScpi />}
							onClick={() => handlePortfolioClick('FLAGSHIP')}
							isSelected={portfolioType === 'FLAGSHIP'}
							potentialPortfolioPerformances={potentialPortfolioPerformances}
							fees={fees}
							minimumTicket={1000}
						/>
					</WrapItem>
					<WrapItem flex={1} maxW="380px" minW="272px">
						<PortfolioTypeCard
							cy="Essential"
							type="ESSENTIAL"
							content="Un portefeuille composé d'actions et d'obligations. Simple et performant."
							tooltipTextTitle={
								<Text variant="Text-S-Regular" color="white" align="start">
									Essential est une stratégie diversifiée composée d’ETFs <b>actions et obligations.</b> Notre
									philosophie est que les marchés financiers sont haussiers à long-terme, et que c’est aux Etats-Unis
									qu’ils performent le mieux. Nous investissons les capitaux principalement sur des entreprises comme
									Netflix, Amazon, Tesla.
								</Text>
							}
							tooltipTextPerformance={<TooltipTextPerformance />}
							tooltipTextPerformanceGross={<TooltipPerformanceGross hasEtfs />}
							onClick={() => handlePortfolioClick('ESSENTIAL')}
							isSelected={portfolioType === 'ESSENTIAL'}
							potentialPortfolioPerformances={potentialPortfolioPerformances}
							fees={fees}
							minimumTicket={1000}
						/>
					</WrapItem>
				</Wrap>
			</VStack>
			<MentionUC />
			<BottomStepBar onPrev={onPrev} onNext={() => handlePortfolioClick(portfolioType!)} showNext={!!portfolioType} />
		</VStack>
	);
};

export default ChoosePortfolioType;
