import { useState } from 'react';

import { backendApi } from 'services/apiService';
import eventTracker from 'services/events/eventTracker';
import { SCPISubscriptionRepartition } from 'services/requests/realEstate/simulations';

type RealEstateSubscriptionDto = {
	repartition: SCPISubscriptionRepartition[];
	timeHorizon: number;
};

export const useRealEstateSubscribe = () => {
	const [isSubscribing, setIsSubscribing] = useState(false);
	const [isSubscribed, setIsSubscribed] = useState(false);

	const subscribe = (dto: RealEstateSubscriptionDto) => {
		const horizon = new Date();

		horizon.setFullYear(horizon.getFullYear() + dto.timeHorizon);
		setIsSubscribing(true);
		eventTracker.pipedream
			.scpiSubscriptionSimulation(horizon.toISOString().slice(0, 10), dto.repartition)
			.then(() => {
				setIsSubscribed(true);
				backendApi.util.invalidateTags(['SCPI_SUBSCRIPTIONS']);
			})
			.finally(() => {
				setIsSubscribing(false);
			});
	};

	return {
		subscribe,
		isSubscribing,
		isSubscribed,
	};
};
