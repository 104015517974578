import { FC } from 'react';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';

import Amelie from 'assets/team/amelie.png';
import Arnaud from 'assets/team/arnaud.png';
import Hugo from 'assets/team/hugo.png';
import Mehdi from 'assets/team/mehdi.png';
import Olivier from 'assets/team/olivier.png';
import Samy from 'assets/team/samy.png';
import Virgile from 'assets/team/Virgile.jpg';
import { CPM } from 'store/types/airtable.type';

type CPMData = Record<CPM, { name: string; avatar: string }>;

const cpmData: CPMData = {
	Olivier: { name: 'Olivier Herbout', avatar: Olivier },
	Samy: { name: 'Samy Ouardini', avatar: Samy },
	Amélie: { name: 'Amélie Yuan', avatar: Amelie },
	Virgile: { name: 'Virgile Paulien', avatar: Virgile },
	Arnaud: { name: 'Arnaud Ton-That', avatar: Arnaud },
	Mehdi: { name: 'Mehdi Berrada', avatar: Mehdi },
	Hugo: { name: 'Hugo Toson', avatar: Hugo },
};

const CPMProfileCard: FC<{ cpm: CPM }> = ({ cpm }) => (
	<HStack spacing="16px">
		<Image src={cpmData[cpm].avatar} w="32px" h="50px" objectFit="cover" borderRadius="4px" />
		<VStack align="start" spacing="0px">
			<Text variant="Text-M-Medium" color="informative.900">
				{cpmData[cpm].name}
			</Text>
			<Text variant="Text-S-Regular">Client Portfolio Manager</Text>
		</VStack>
	</HStack>
);

export default CPMProfileCard;
