import { FC, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { Card, Heading, Skeleton, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';

import { OpenedFund } from 'components/cards/funds/pe-immo/OpenedFund';
import FullscreenLoader from 'components/FullscreenLoader';
import InvestmentMetrics from 'components/Metrics/InvestmentMetrics';
import { useProducts } from 'hooks/useProducts';
import DashboardFundImmo from 'pages/RealEstate/Dashboard/InvestementDetail';
import {
	useGetImmobilierDashboardQuery,
	useGetImmobilierDashboardSubscriptionsQuery,
} from 'services/requests/realEstate';
import { useGetUserSCPISubscriptionsQuery } from 'services/requests/realEstate/subscriptions';
import { isNotNone } from 'utils/functions';
import { displayMoney } from 'utils/rendering';

import KpisSubscriptions from './KpisSubscriptions';

const RentKPIs: FC = () => {
	const { data: allSub, isLoading, error } = useGetImmobilierDashboardQuery();

	const rent = useMemo(() => (isNotNone(allSub) ? allSub.rent : 0), [allSub]);

	const dividend = useMemo(() => (isNotNone(allSub) ? allSub.dividend : 0), [allSub]);
	const dividentColor = useMemo(
		() => (dividend == 0 ? undefined : dividend >= 0 ? 'positive.900' : 'negative.900'),
		[dividend],
	);

	if (error) return null;

	return (
		<Wrap w="100%">
			<WrapItem flex="1">
				<Card w="100%">
					<VStack align="start">
						<Text whiteSpace="nowrap" variant="Text-M-Medium">
							Loyers estimés
						</Text>
						<Skeleton isLoaded={!isLoading}>
							<Text whiteSpace="nowrap" variant="Title-L-Bold">
								{displayMoney(rent)}
								<Text as="span" variant="Title-S-SemiBold">
									{' '}
									(mensuel)
								</Text>
							</Text>
						</Skeleton>
					</VStack>
				</Card>
			</WrapItem>

			<WrapItem flex="1">
				<Card w="100%">
					<VStack align="start">
						<Text whiteSpace="nowrap" variant="Text-M-Medium">
							Loyers perçus cumulés
						</Text>
						<Skeleton isLoaded={!isLoading}>
							<Text variant="Title-L-Bold" color={dividentColor}>
								{displayMoney(dividend)}
							</Text>
						</Skeleton>
					</VStack>
				</Card>
			</WrapItem>
		</Wrap>
	);
};

const DashboardSCPI: FC = () => {
	const { scpi } = useProducts();
	const { data: subscriptions } = useGetUserSCPISubscriptionsQuery();
	const { data: subscriptionsMetrics } = useGetImmobilierDashboardSubscriptionsQuery();

	const openedSubscriptions = subscriptions?.filter((sub) => sub.status === 'COMPLETED');

	if (scpi.isLoading) return <FullscreenLoader />;
	if (!scpi.isOpen) return <Navigate replace to="/immobilier" />;

	return (
		<>
			<VStack w="100%" spacing="32px" align="start">
				<Heading variant="Title-L-SemiBold">Votre tableau de bord</Heading>
				{scpi.data && <InvestmentMetrics data={scpi.data} />}

				<KpisSubscriptions subscriptions={openedSubscriptions} />

				<RentKPIs />

				{openedSubscriptions && (
					<VStack w="100%">
						{openedSubscriptions.map((s) => (
							<OpenedFund key={s.id} subscription={s} metrics={subscriptionsMetrics?.find((sub) => sub.id === s.id)} />
						))}
					</VStack>
				)}
			</VStack>
			<DashboardFundImmo />
		</>
	);
};

export default DashboardSCPI;
