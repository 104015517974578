import { useNavigate } from 'react-router-dom';
import { ChevronRight } from '@carbon/icons-react';
import { Card, Hide, HStack, IconButton, Show, Text } from '@chakra-ui/react';

import { ArrowButton } from 'components/Button/Button';
import { ProductMetrics } from 'components/cards/InvestmentProductCard';
import { ProductData } from 'services/requests/product';

export enum ProductName {
	invest = 'Invest',
	immo = 'Immobilier',
	pe = 'Private Equity',
	savings = 'Cash',
}

type ProductCardProps = {
	name: string;
	icon: React.ReactNode;
	data: ProductData;
	linkCTA: string;
};

const CorporateInvestmentProductCard = ({ name, icon, data, linkCTA }: ProductCardProps): JSX.Element => {
	const navigate = useNavigate();

	return (
		<Card size="sm" w="100%" cursor="pointer" _hover={{ borderColor: 'grey.900' }} onClick={() => navigate(linkCTA)}>
			<HStack transition="all 0.3s ease-out" justify="space-between" spacing="16px" bg="white">
				<HStack
					spacing={{ base: '8px', md: '16px' }}
					minW={{ base: undefined, md: '200px' }}
					flex={{ base: 1, md: undefined }}
				>
					{icon}
					<Text variant="Text-M-Medium" fontWeight="semibold">
						{name}
					</Text>
				</HStack>

				<ProductMetrics data={data} />

				<Show above="md">
					<ArrowButton variant="tertiary">Voir</ArrowButton>
				</Show>

				<Hide above="md">
					<IconButton variant="tertiary" boxSize="24px" aria-label="details" as={ChevronRight} />
				</Hide>
			</HStack>
		</Card>
	);
};

export default CorporateInvestmentProductCard;
