import { UserScpiKyc, UserScpiKycTest } from 'store/types/scpiKyc.type';
import { isNotNone } from 'utils/functions';

export const isScpiKycAndTestCompleted = (scpiKyc: UserScpiKyc | undefined, scpiKycTest: UserScpiKycTest | undefined) =>
	!!scpiKycTest &&
	!!scpiKyc &&
	isNotNone(scpiKyc.lengthPerception) && // : LengthPerceptionType;
	isNotNone(scpiKyc.riskPerception) && // : RiskPerceptionType;
	isNotNone(scpiKycTest.investKnowledge) && // : InvestKnowledgeType;
	isNotNone(scpiKycTest.hasRealEstateInvest) && // : boolean;
	isNotNone(scpiKycTest.hasSCPIShares) && // : boolean;
	isNotNone(scpiKycTest.hasInvestedMoreThan100k) && // : boolean;
	isNotNone(scpiKycTest.hasLongTermInvestmentConscience) && // : boolean;
	isNotNone(scpiKycTest.investReturnPerception) && // : InvestReturnPerceptionType;
	isNotNone(scpiKycTest.investRevenusPerception) && // : InvestRevenusPerceptionType;
	isNotNone(scpiKycTest.investRevenusLengthPerception) && // : InvestRevenusLengthPerceptionType;
	isNotNone(scpiKycTest.agreeWithInvestment); // : boolean;

export const isDocumentBothSigned = (signedDocument: { fundOrigin: boolean; mandat: boolean } | undefined) =>
	signedDocument?.fundOrigin === true && signedDocument?.mandat === true;

export const isDocumentHalfSigned = (signedDocument: { fundOrigin: boolean; mandat: boolean } | undefined) =>
	signedDocument?.fundOrigin === true || signedDocument?.mandat === true;
